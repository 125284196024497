import React from 'react'
import styled from 'styled-components'
import {
  CheckboxAndInputContainer,
  CheckboxContainer,
} from '../../containers/TemplateFixture/index.screen'
import Checkbox from '../Checkbox'
import EditDisabled from '../EditDisabled'
import { Container } from '../GroupAdornment'
import PropTypes from 'prop-types'

const CustomContainer = styled(Container)`
  ${(props) => props.disabled && 'border-bottom-color: grey;'} input {
    background: none !important;
    text-align: center;
    margin-bottom: 3px;
  }
  label {
    border: none;
  }
  .editDisabled {
    label {
      ${(props) => props.disabled && 'margin-bottom: 5px !important'};
    }
  }
  .rdtPicker {
    width: 150px;
    box-shadow: 3px 3px 15px -2px;
  }
`

class BackupKeysLimit extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      // isOptionsVisible: props.checked,
      checked: props.checked,
    }
  }

  // componentDidUpdate(prevProps, prevState, snapshot) {
  //   console.log('componentDidUpdate', this.props, prevProps)
  //   if (this.state.isOptionsVisible !== this.props.checked) {
  //     this.setState({isOptionsVisible: this.props.checked})
  //   }
  // }

  onChange = ({ target: { checked } }) => {
    this.setState({ checked })
    this.props.onChange(checked)
  }

  onLimitChange = ({ target: { value } }) => {
    this.props.onChangeOption(value)
  }

  optionsUI = () => {
    // const { isOptionsVisible } = this.state
    const { disabled, limit } = this.props
    const { checked } = this.state

    if (disabled) {
      if (checked) {
        return <EditDisabled text={limit} grey />
      } else {
        return (
          <span className="editDisabled">
            <EditDisabled text="Backup Keys not allowed" grey />
          </span>
        )
      }
    } else {
      if (checked || limit) {
        return (
          <input
            className="form-control text-left"
            type="number"
            name="keys-limit"
            min={0}
            defaultValue={limit}
            onChange={this.onLimitChange}
          />
        )
      } else {
        return (
          <span className="editDisabled">
            <EditDisabled text="Unlimited - check to set limit" grey />
          </span>
        )
      }
    }
  }

  render() {
    const { disabled, checked, name } = this.props
    return (
      <CustomContainer disabled={disabled}>
        <CheckboxAndInputContainer>
          <CheckboxContainer>
            <Checkbox
              name={name}
              onChange={this.onChange}
              checked={checked}
              disabled={disabled}
            />
          </CheckboxContainer>
          {this.optionsUI()}
        </CheckboxAndInputContainer>
      </CustomContainer>
    )
  }
}

BackupKeysLimit.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  onChangeOption: PropTypes.func,
  onChange: PropTypes.func,
  limit: PropTypes.number,
}

export default BackupKeysLimit
