import { createSelector } from 'reselect'

const selectApp = state => state.get('app')

const organizationsSelector = createSelector(selectApp, app => app.get('organizations'))

const failedSelector = createSelector(selectApp, app => app.get('organizationsFailed'))

const loadingSelector = createSelector(selectApp, app => app.get('organizationsLoading'))

export {
  selectApp,
  organizationsSelector,
  failedSelector,
  loadingSelector,
}
