/**
 *
 * Linked Fixtures Selectors
 *
 */

import { createSelector } from 'reselect'
import { createSelector as createOrmSelector } from 'redux-orm'
import { orm } from 'mti-jsclient-shared'

const dbStateSelector = (state) => state.get('orm')
const selectLinkedFixtures = (state) => state.get('linkedFixtures')

const selectLinkedFixturesForTemplate = (templateId) =>
  createOrmSelector(orm, (session) =>
    session.Fixture.filter(
      ({ parentId }) => parentId === templateId
    ).toRefArray()
  )

const makeSelectLinkedFixturesForTemplate = (templateId) =>
  createSelector(dbStateSelector, (ormState) =>
    selectLinkedFixturesForTemplate(templateId)(ormState)
  )

const makeSelectLinkedFixturesFailed = () =>
  createSelector(selectLinkedFixtures, (state) => state.get('failed'))

const makeSelectLinkedFixturesLoading = () =>
  createSelector(selectLinkedFixtures, (state) => state.get('loading'))

export {
  makeSelectLinkedFixturesForTemplate,
  makeSelectLinkedFixturesFailed,
  makeSelectLinkedFixturesLoading,
}
