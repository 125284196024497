/**
 *
 * Canvas wrapper component
 *
 */

import React from 'react'
import PropTypes from 'prop-types'

class Canvas extends React.PureComponent {
  loadAndRender() {
    const { fabricInstance, canvasObject, selectedObject } = this.props

    // console.log('Canvas', canvasObject)

    fabricInstance.loadFromJSON(canvasObject)

    // if there is any previously active object, we need to re-set it after rendering canvas
    if (selectedObject > -1 && fabricInstance.getObjects().length) {
      const activeObject = fabricInstance.getObjects()[selectedObject]
      if (activeObject) fabricInstance.setActiveObject(activeObject)
    }

    fabricInstance.renderAll()
  }

  render() {
    this.loadAndRender()

    return (
      <canvas
        ref={(ref) => {
          this.objectsCanvas = ref
        }}
      />
    )
  }
}

Canvas.propTypes = {
  fabricInstance: PropTypes.object.isRequired,
  canvasObject: PropTypes.object.isRequired,
  selectedObject: PropTypes.number,
}

export default Canvas
