/**
 *
 * AreaPage
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'

import injectSaga from '../../utils/injectSaga'
import injectReducer from '../../utils/injectReducer'
import {
  getQueryVariable,
  loadingType,
  positionPath,
  safeUri,
  storeTopic,
} from '../../utils/utils'
import { orgUrl } from '../../utils/mtiUtils'
import {
  openModal,
  closeModal,
  notifyModal,
  subscribeOnTopic,
} from '../App/actions'
import {
  makeSelectMtiPermission,
  makeSelectStoreIsInDefultAreaMode,
  selectStore,
} from '../App/selectors'
import ConfirmModal from '../ModalContainer/ConfirmModal'
import {
  makeSelectCanvasObject,
  canvasSelectedIdSelector,
  makeSelectPositionsByArea,
  makeSelectAreaDimensions,
  makeSelectAreaName,
  failedSelector,
  loadingSelector,
  isStaticSelector,
  makeSelectFixtureName,
  makeSelectFixtureSviType,
  makeSelectFixtureSviSizeType,
  makeSelectFixtureIsFurniture,
  makeSelectFixtureCustomAngle,
  prototypesSelector,
  makeSelectFixtureNameShown,
  makeSelectFixtureApplyFutureTemplateUpdates,
  makeSelectNotCompliantFixturesForArea,
  makeSelectFixturesWithRulesForArea,
  makeSelectTemplate,
} from './selectors'
import {
  selectMissingSecuredProductsByAreaId,
  selectMissingSecuredProductsByFixtureId,
} from '../ExceptionsList/SecuredProducts/selectors'
import reducer from './reducer'
import saga from './sagas'
import {
  loadCanvas,
  saveFixture,
  removeObject,
  applyTemplate,
  areaCanvasChanged,
  areaCanvasNameChanged,
  areaCanvasIsFurnitureChanged,
  areaCanvasFixtureNameToggled,
  areaCanvasFixtureApplyFutureTemplateUpdatesToggled,
  areaCanvasFigureBeginTransformation,
  areaCanvasFigureSizeChanged,
  areaCanvasFigureBeginCustomRotation,
  areaCanvasFigureUnselectTemplate,
  deleteAllComplianceRules,
  loadFixtureTemplate,
  resetFixtureTemplate,
} from './actions'
import AreaScreen from './index.screen'
import ProgressIndicator from '../../components/ProgressIndicator'
import StoreLayoutPreloader from '../../components/Preloaders/StoreLayoutPreloader'
import SelectTemplateModal from './modals/selectTemplate'
import { fixtureIsFurnitureMessage } from '../FixturePage'
import FetchFailedAlert from '../../components/FetchFailed'
import MoveFixtureModal from './MoveFixtureModal/index'
import { areaLink } from '../ExceptionsPage/utils'
import { makeSelectAlertsByStoreId } from '../AlertContainer/selectors'

export const size = {
  width: 600,
  height: 400,
}

const permText = text =>
  `You don't have appropriate rights to access ${text}.\nPlease contact your store manager.`

export class AreaPage extends React.PureComponent {
  constructor(props) {
    super(props)
    const fixtureId = parseInt(getQueryVariable('fixtureId') || '-1', 10)
    const fixtureName = getQueryVariable('fixtureName')
    const isStatic = getQueryVariable('isStatic') !== 'false'
    this.state = {
      fixtureId,
      fixtureName,
      isStatic,
      fixtureEditMode: 'template',
    }
  }

  componentWillUnmount() {
    this.unlistenHistory()
  }

  componentDidMount() {
    this.loadCanvas(null, this.state.isStatic, -1)
    this.listenHistory()
  }

  componentDidUpdate(prevProps) {
    const { canvasObject } = this.props
    /* eslint-disable react/no-did-update-set-state */
    this.setState({ canvasObject })
    const { sId: prevSId, fId: prevFId, aId: prevAId } = prevProps.match.params
    const { sId: thisSId, fId: thisFId, aId: thisAId } = this.props.match.params

    const pathParams = window.location.pathname.split('/') || []
    const isMtiAdmin = pathParams[1] === 'organizations'
    const realSId = isMtiAdmin ? pathParams[5] : pathParams[2]
    const realFId = isMtiAdmin ? pathParams[8] : pathParams[5]
    const realAId = isMtiAdmin ? pathParams[11] : pathParams[8]
    if (prevSId !== thisSId || prevFId !== thisFId || prevAId !== thisAId) {
      this.loadCanvas(
        { sId: thisSId, fId: thisFId, aId: thisAId },
        this.props.isStatic,
        this.props.selectedId
      )
    } else if (
      realSId !== thisSId ||
      realFId !== thisFId ||
      realAId !== thisAId
    ) {
      // In case when we update path via react-router-redux push, extra history.push is required
      this.props.history.push({
        pathname: window.location.pathname,
        search: window.location.search,
      })
    }
  }

  listenHistory() {
    this.unlistenHistory = this.props.history.listen(() => {
      const fixtureId = parseInt(getQueryVariable('fixtureId') || '-1', 10)
      const fixtureName = getQueryVariable('fixtureName')
      const isStatic = getQueryVariable('isStatic') !== 'false'
      if (this.state.fixtureId !== fixtureId) {
        this.setState({ fixtureId, fixtureName, isStatic }, () =>
          this.loadCanvas(null, isStatic, -1)
        )
      } else {
        this.loadCanvas(null, isStatic, -1)
      }
    })
  }

  onMessage(msg) {
    const { notifyModal: doNotifyModal } = this.props
    doNotifyModal(msg)
  }

  onOpenFixture(obj) {
    const { id, name, isFurniture } = obj || {}
    const { isStatic, notifyModal } = this.props
    if (isFurniture) {
      notifyModal(fixtureIsFurnitureMessage)
      return
    }

    if (isStatic && id && name) {
      // in other case object doesn't exist at backend
      this.props.history.push(
        `${this.getCurrentPath()}/fixtures/${obj.id}/${safeUri(obj.name)}`
      )
    }
  }

  onRemoveFixture(canvasObject, selectedId) {
    const { openModal: doOpenModal, closeModal: doCloseModal } = this.props
    const positionsCount = canvasObject.objects.find(
      ({ id }) => id === selectedId
    ).positionsCount
    doOpenModal({
      id: 'delete',
      type: 'bootstrap',
      appearance: positionsCount ? 'modal-md' : 'modal-sm',
      content: (
        <ConfirmModal
          questionText={this.questionText(positionsCount)}
          id={'delete'}
          cancelText={'CANCEL'}
          confirmText={'DELETE FIXTURE'}
          onClose={() => doCloseModal({ id: 'delete' })}
          onConfirm={() => {
            this.props.resetFixtureTemplate()
            this.props.removeObject({
              canvasObject,
              sId: this.props.match.params.sId,
              fId: this.props.match.params.fId,
              aId: this.props.match.params.aId,
              selectedId,
            })
          }}
        />
      ),
    })
  }

  onDoubleCheckApplyFutureTemplateUpdates() {
    const {
      openModal: doOpenModal,
      closeModal: doCloseModal,
      areaCanvasFixtureApplyFutureTemplateUpdatesToggled: onFixtureApplyFutureTemplateUpdatesToggled,
    } = this.props
    doOpenModal({
      id: 'customFixturePreSave',
      type: 'bootstrap',
      appearance: 'modal-md',
      content: (
        <ConfirmModal
          id={'customFixturePreSave'}
          cancelText={'CANCEL'}
          confirmText={'CONFIRM'}
          questionText={
            'This fixture will not have a connection to the template it is created from. Are you sure you want to proceed?'
          }
          onClose={() => doCloseModal({ id: 'customFixturePreSave' })}
          onConfirm={() => {
            onFixtureApplyFutureTemplateUpdatesToggled(false)
          }}
        />
      ),
    })
  }

  onSaveFixture(canvasObject, selectedId, newFloorId, newAreaId) {
    this.props.saveFixture({
      canvasObject,
      sId: this.props.match.params.sId,
      fId: newFloorId || this.props.match.params.fId,
      aId: newAreaId || this.props.match.params.aId,
      selectedId,
      screen: size,
    })
  }

  onSelectTemplate = () => {
    const id = 'select-template'
    const {
      openModal: doOpenModal,
      closeModal: doCloseModal,
      selectedFixtureTemplate,
      applyTemplate: doApplyTemplate,
    } = this.props

    doOpenModal({
      id,
      type: 'bootstrap',
      content: (
        <SelectTemplateModal
          id={id}
          existingTemplate={selectedFixtureTemplate}
          onConfirm={template => {
            doCloseModal({ id })
            doApplyTemplate(template)
          }}
          onClose={() => {
            doCloseModal({ id })
          }}
        />
      ),
    })
  }

  onUnselectTemplate = () => {
    const { openModal: doOpenModal, closeModal: doCloseModal } = this.props
    doOpenModal({
      id: 'customFixtureCheck',
      type: 'bootstrap',
      appearance: 'modal-md',
      content: (
        <ConfirmModal
          id={'customFixtureCheck'}
          cancelText={'CANCEL'}
          confirmText={'UNSELECT TEMPLATE'}
          questionText={
            'By unselecting the template you will break the connection with the parent template and delete compliance rules. Are you sure you want to proceed? You will be able to Cancel this change until you click Save.'
          }
          onClose={() => doCloseModal({ id: 'customFixtureCheck' })}
          onConfirm={() => {
            const {
              fixturesWithRules,
              deleteAllComplianceRules,
              areaCanvasFigureUnselectTemplate,
            } = this.props
            const fixture = this.getSelectedFixtureObject()
            const fixtureWithRules = fixture && fixturesWithRules[fixture.id]
            deleteAllComplianceRules(fixtureWithRules)
            areaCanvasFigureUnselectTemplate()
            this.setState({ fixtureEditMode: 'customFixture' })
          }}
        />
      ),
    })
  }

  onFixtureEditingModeChange = fixtureEditMode => {
    const { canvasObject, selectedId } = this.props
    if (fixtureEditMode === 'customFixture') {
      const parentId = (this.getObjectById(canvasObject, selectedId) || {})
        .parentId
      if (parentId) {
        this.onUnselectTemplate()
        return
      }
    }
    const fixture = this.getObjectById(canvasObject, selectedId) || {}
    const isNew = isNaN(fixture.id)
    if (!isNew) {
      const { notifyModal: doNotifyModal } = this.props
      doNotifyModal(
        'Template selection is not allowed for already created fixtures'
      )
      return
    }
    this.setState({ fixtureEditMode })
  }

  /* eslint-disable no-undef, react/sort-comp */
  getCurrentPath() {
    const {
      match: { params: { sId, sName, fId, fName, aId, aName } = {} } = {},
    } = this.props
    const components = [
      '',
      'stores',
      sId,
      sName,
      'floors',
      fId,
      fName,
      'areas',
      aId,
      aName,
    ]
    const uriComponents = components.map(safeUri)

    return `${orgUrl()}${uriComponents.join('/')}`
  }

  getObjectById(canvasObject, selectedId) {
    return canvasObject.objects.find(({ id }) => id === selectedId)
  }

  setFixtureEditMode = () => {
    const { canvasObject, selectedId } = this.props
    const parentId = (this.getObjectById(canvasObject, selectedId) || {})
      .parentId
    if (parentId) {
      this.props.loadFixtureTemplate(parentId)
    }
    const fixtureEditMode =
      selectedId !== -1 && !parentId ? 'customFixture' : 'template'
    this.setState({ fixtureEditMode })
  }

  questionText(positionsCount) {
    if (positionsCount) {
      return `This fixture contains ${positionsCount} position(s). Deleting this fixture will also delete positions inside. Are you sure you want to proceed?`
    }
    return 'Are you sure you want to proceed?'
  }

  onOpenPosition = ({ positionId }) => {
    const { positions: { positionsArea }, history } = this.props
    const position = positionsArea.find(({ id }) => id === positionId)
    positionPath(position, history)
  }

  navigateToSecuredProducts = navigateToPath => {
    const { store, history } = this.props
    navigateToPath(store, history)
  }

  loadCanvas(params, isStatic, selectedId) {
    const newParams = params || this.props.match.params
    const fixtureId = this.state.fixtureId
    const id = selectedId !== -1 ? selectedId : fixtureId || -1
    const topic = storeTopic(newParams.sId)
    this.props.subscribeOnTopic(topic)
    this.props.loadCanvas(
      size,
      isStatic,
      id,
      newParams.sId,
      newParams.fId,
      newParams.aId
    )
  }

  /* eslint-disable no-undef */
  noPermission = (hasPermission, text) => {
    const { notifyModal: doNotifyModal } = this.props
    if (!hasPermission) doNotifyModal(text)
    return !hasPermission
  }

  getSelectedFixtureObject() {
    const { canvasObject, selectedId } = this.props
    return (
      selectedId !== -1 &&
      canvasObject.objects.find(({ id }) => id === selectedId)
    )
  }

  moveFixtureToArea = area => {
    const { canvasObject, selectedId } = this.props
    const { canvasObject: canvasResolved } = this.state
    const canvas =
      canvasResolved && canvasResolved.objects ? canvasResolved : canvasObject
    const { id: areaId, floorId } = area
    this.onSaveFixture(canvas, selectedId, floorId, areaId)
  }

  onGoToFixture = fixture => {
    const { sId, sName, fId, fName, aId, aName } = this.props.match.params
    const original = {
      area: { id: aId, name: aName },
      storeName: sName,
      storeId: sId,
      floor: { id: fId, name: fName },
    }
    const fixturePath = `${areaLink(original)}?fixtureId=${
      fixture.id
    }&fixtureName=${safeUri(fixture.name)}`
    this.props.history.push(fixturePath)
  }

  moveFixture = () => {
    const { openModal, closeModal } = this.props

    openModal({
      id: 'move-fixture',
      type: 'bootstrap',
      content: (
        <MoveFixtureModal
          areas={[]}
          onClose={() => {
            closeModal({ id: 'move-fixture' })
          }}
          onConfirm={area => {
            closeModal({ id: 'move-fixture' })
            this.moveFixtureToArea(area)
          }}
          currentAreaId={parseInt(this.props.match.params.aId, 10)}
          storeId={parseInt(this.props.match.params.sId, 10)}
          id={'move-fixture'}
        />
      ),
    })
  }

  render() {
    const {
      canvasObject,
      selectedId,
      isStatic,
      positions,
      missingSecuredProductsByAreaId,
      missingSecuredProductsByFixtureId,
      prototypes,
      areaDimensions,
      areaCanvasChanged: onChanged,
      areaCanvasNameChanged: onNameChanged,
      areaCanvasIsFurnitureChanged: onIsFurnitureChanged,
      areaCanvasFixtureNameToggled: onFixtureNameToggle,
      areaCanvasFixtureApplyFutureTemplateUpdatesToggled: onFixtureApplyFutureTemplateUpdatesToggled,
      areaCanvasFigureBeginTransformation: onFigureBeginTransformation,
      areaCanvasFigureSizeChanged: onFigureSizeChanged,
      areaCanvasFigureBeginCustomRotation: onFigureBeginCustomRotation,
      selectedFixtureTemplate,
      selectedFixtureName,
      selectedFixtureSviType,
      selectedFixtureSviSizeType,
      selectedFixtureIsFurniture,
      selectedFixtureNameHasShown,
      selectedFixtureApplyFutureTemplateUpdates,
      selectedFixtureAngle,
      hasStoreEditorPermission,
      areaName,
      loading,
      failed,
      match,
      notifyModal: doNotifyModal,
      isDefaultAreaMode,
      notCompliantFixtures,
      fixturesWithRules,
      alerts,
      match: { params: { aId } },
    } = this.props
    const { canvasObject: canvasResolved, fixtureEditMode } = this.state
    const canvas =
      canvasResolved && canvasResolved.objects ? canvasResolved : canvasObject
    let canvasObj = canvas
    if ((loading && loading === loadingType.visible) || loading === true) {
      return (
        <React.Fragment>
          <StoreLayoutPreloader positionFixure={false} />
          <ProgressIndicator text={'Loading...'} />
        </React.Fragment>
      )
    }
    if (failed) return <FetchFailedAlert failed={failed} />
    if (canvas && canvas.then) canvasObj = { objects: [], silent: true }
    const isSilent = loading && loading === loadingType.silent
    const isTemplateSelectable = hasStoreEditorPermission

    if (!prototypes) return <div />

    return (
      <AreaScreen
        match={match}
        path={this.getCurrentPath()}
        canvasSize={size}
        canvasObject={canvasObj}
        selectedId={selectedId}
        selectedFixtureTemplate={selectedFixtureTemplate}
        selectedFixtureName={selectedFixtureName}
        selectedFixtureSviType={selectedFixtureSviType}
        selectedFixtureSviSizeType={selectedFixtureSviSizeType}
        selectedFixtureIsFurniture={selectedFixtureIsFurniture}
        selectedFixtureNameHasShown={selectedFixtureNameHasShown}
        selectedFixtureApplyFutureTemplateUpdates={
          selectedFixtureApplyFutureTemplateUpdates
        }
        selectedFixtureAngle={selectedFixtureAngle}
        onFixtureRemove={id => this.onRemoveFixture(canvasObj, id)}
        onFixtureSave={id => {
          this.onSaveFixture(canvasObj, id)
          this.props.resetFixtureTemplate()
        }}
        areaName={areaName}
        isStatic={isStatic}
        isSilent={isSilent}
        isDefaultAreaMode={isDefaultAreaMode}
        positions={positions}
        missingSecuredProductsForArea={missingSecuredProductsByAreaId[aId]}
        missingSecuredProductsByFixtureId={missingSecuredProductsByFixtureId}
        onGoToSecuredProducts={this.navigateToSecuredProducts}
        areaDimensions={areaDimensions}
        prototypes={prototypes}
        isTemplateSelectable={isTemplateSelectable}
        onChanged={o => {
          if (!o.isStatic) {
            if (
              this.noPermission(
                hasStoreEditorPermission,
                permText('Store Editor')
              )
            ) {
              return
            }
          }
          onChanged(o)
          this.setFixtureEditMode()
        }}
        onNameChanged={onNameChanged}
        onIsFurnitureChanged={onIsFurnitureChanged}
        onFixtureNameToggle={onFixtureNameToggle}
        onFigureBeginTransformation={onFigureBeginTransformation}
        onFigureSizeChanged={onFigureSizeChanged}
        onFigureCustomAngleChanged={onFigureBeginCustomRotation}
        onOpenFixture={obj => this.onOpenFixture(obj)}
        onMessage={msg => this.onMessage(msg)}
        onEdit={() => {
          if (
            this.noPermission(
              hasStoreEditorPermission,
              permText('Store Editor')
            )
          ) {
            return
          }
          this.setFixtureEditMode()
          this.loadCanvas(null, false, selectedId)
        }}
        onCancel={() => {
          this.props.resetFixtureTemplate()
          this.loadCanvas(null, true, -1)
        }}
        onSelectTemplate={this.onSelectTemplate}
        onUnselectTemplate={this.onUnselectTemplate}
        onFixtureEditingModeChange={this.onFixtureEditingModeChange}
        fixtureEditMode={fixtureEditMode}
        onToggleTemplateUpdates={c => {
          if (!c) {
            this.onDoubleCheckApplyFutureTemplateUpdates()
          }
          onFixtureApplyFutureTemplateUpdatesToggled(c)
        }}
        openPosition={this.onOpenPosition}
        notCompliantFixtures={notCompliantFixtures}
        fixturesWithRules={fixturesWithRules}
        onMoveFixture={this.moveFixture}
        onGoToFixture={this.onGoToFixture}
        alerts={alerts}
      />
    )
  }
}

AreaPage.propTypes = {
  history: PropTypes.any,
  match: PropTypes.any,
  params: PropTypes.shape({
    sId: PropTypes.string.isRequired,
    sName: PropTypes.string.isRequired,
    fId: PropTypes.string.isRequired,
    fName: PropTypes.string.isRequired,
    aId: PropTypes.string.isRequired,
    aName: PropTypes.string.isRequired,
  }),
  loadCanvas: PropTypes.func.isRequired,
  saveFixture: PropTypes.func.isRequired,
  removeObject: PropTypes.func.isRequired,
  areaCanvasChanged: PropTypes.func.isRequired,
  areaCanvasNameChanged: PropTypes.func.isRequired,
  areaCanvasIsFurnitureChanged: PropTypes.func.isRequired,
  areaCanvasFixtureNameToggled: PropTypes.func.isRequired,
  areaCanvasFixtureApplyFutureTemplateUpdatesToggled: PropTypes.func.isRequired,
  areaCanvasFigureBeginTransformation: PropTypes.func.isRequired,
  areaCanvasFigureBeginCustomRotation: PropTypes.func.isRequired,
  areaCanvasFigureSizeChanged: PropTypes.func.isRequired,
  areaCanvasFigureUnselectTemplate: PropTypes.func.isRequired,
  deleteAllComplianceRules: PropTypes.func.isRequired,
  areaName: PropTypes.string,
  loading: PropTypes.any,
  failed: PropTypes.object,
  canvasObject: PropTypes.object.isRequired,
  selectedId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isStatic: PropTypes.bool,
  isDefaultAreaMode: PropTypes.bool,
  selectedFixtureTemplate: PropTypes.any,
  selectedFixtureName: PropTypes.string,
  selectedFixtureSviType: PropTypes.string,
  selectedFixtureSviSizeType: PropTypes.string,
  selectedFixtureIsFurniture: PropTypes.bool,
  selectedFixtureNameHasShown: PropTypes.bool,
  selectedFixtureApplyFutureTemplateUpdates: PropTypes.bool,
  positions: PropTypes.object,
  areaDimensions: PropTypes.object,
  applyTemplate: PropTypes.func,
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
  notifyModal: PropTypes.func,
  prototypes: PropTypes.object,
  hasStoreEditorPermission: PropTypes.bool,
  subscribeOnTopic: PropTypes.func,
  notCompliantFixtures: PropTypes.array,
  fixturesWithRules: PropTypes.object,
}

// TODO: Evaluate which of these makeSelects are not required and refactor
const mapStateToProps = (state, ownProps) => {
  const getCanvasObject = makeSelectCanvasObject(ownProps.match.params.aId)
  const getSelectedId = canvasSelectedIdSelector
  const getTemplate = makeSelectTemplate()
  const getFixtureName = makeSelectFixtureName()
  const getFixtureSviType = makeSelectFixtureSviType()
  const getFixtureSviSizeType = makeSelectFixtureSviSizeType()
  const getSelectedFixtureIsFurniture = makeSelectFixtureIsFurniture()
  const getSelectedFixtureNameHasShown = makeSelectFixtureNameShown()
  const getSelectedFixtureApplyFutureTemplateUpdates = makeSelectFixtureApplyFutureTemplateUpdates()
  const getSelectedFixtureAngle = makeSelectFixtureCustomAngle()
  const getPositions = makeSelectPositionsByArea(ownProps.match.params.aId)
  const getAreaDimensions = makeSelectAreaDimensions(ownProps.match.params.aId)
  const getAreaName = makeSelectAreaName(ownProps.match.params.aId)
  const getLoading = loadingSelector
  const getFailed = failedSelector
  const getIsStatic = isStaticSelector
  const getIsDefaultAreaMode = makeSelectStoreIsInDefultAreaMode(
    ownProps.match.params.sId
  )
  const getHasStoreEditorPermission = makeSelectMtiPermission(
    'Store: Editor',
    ownProps.match.params.sId
  )
  const getNotCompliantFixtures = makeSelectNotCompliantFixturesForArea(
    ownProps.match.params.aId
  )
  const getFixturesWithRules = makeSelectFixturesWithRulesForArea(
    ownProps.match.params.aId
  )
  const getAlerts = makeSelectAlertsByStoreId(ownProps.match.params.sId)

  return (state, ownProps) => ({
    canvasObject: getCanvasObject(state, ownProps),
    selectedId: getSelectedId(state),
    selectedFixtureTemplate: getTemplate(state),
    selectedFixtureName: getFixtureName(state),
    selectedFixtureSviType: getFixtureSviType(state),
    selectedFixtureSviSizeType: getFixtureSviSizeType(state),
    selectedFixtureIsFurniture: getSelectedFixtureIsFurniture(state),
    selectedFixtureNameHasShown: getSelectedFixtureNameHasShown(state),
    selectedFixtureApplyFutureTemplateUpdates: getSelectedFixtureApplyFutureTemplateUpdates(
      state
    ),
    selectedFixtureAngle: getSelectedFixtureAngle(state),
    positions: getPositions(state, ownProps),
    areaDimensions: getAreaDimensions(state, ownProps),
    areaName: getAreaName(state, ownProps),
    loading: getLoading(state),
    failed: getFailed(state),
    isStatic: getIsStatic(state),
    isDefaultAreaMode: getIsDefaultAreaMode(state, ownProps),
    prototypes: prototypesSelector(state),
    hasStoreEditorPermission: getHasStoreEditorPermission(state, ownProps),
    notCompliantFixtures: getNotCompliantFixtures(state, ownProps),
    fixturesWithRules: getFixturesWithRules(state, ownProps),
    alerts: getAlerts(state, ownProps),
    missingSecuredProductsByAreaId: selectMissingSecuredProductsByAreaId(
      state,
      ownProps.match.params.sId,
      ownProps.match.params.fId
    ),
    missingSecuredProductsByFixtureId: selectMissingSecuredProductsByFixtureId(
      state,
      ownProps.match.params.sId,
      ownProps.match.params.aId
    ),
    store: selectStore(state, ownProps.match.params.sId),
  })
}

const mapDispatchToProps = {
  areaCanvasChanged,
  areaCanvasNameChanged,
  areaCanvasIsFurnitureChanged,
  areaCanvasFixtureNameToggled,
  areaCanvasFixtureApplyFutureTemplateUpdatesToggled,
  areaCanvasFigureBeginTransformation,
  areaCanvasFigureBeginCustomRotation,
  areaCanvasFigureSizeChanged,
  areaCanvasFigureUnselectTemplate,
  deleteAllComplianceRules,
  loadCanvas,
  saveFixture,
  removeObject,
  applyTemplate,
  openModal,
  closeModal,
  notifyModal,
  subscribeOnTopic,
  loadFixtureTemplate,
  resetFixtureTemplate,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const withReducer = injectReducer({ key: 'areaCanvas', reducer })
const withSaga = injectSaga({ key: 'areaCanvas', saga })

export default compose(withReducer, withSaga, withConnect)(AreaPage)
