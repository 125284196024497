import React from 'react'
import PropTypes from 'prop-types'
import BackupKeysForStores from '../../../../components/BackupKeysForStores'
import BackupKeysLimit from '../../../../components/BackupKeysLimit'

class BackupKeys extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      backupKeyEnabled: props.backupKeyEnabled,
    }
  }

  onChangeAllowBackupKeys = (checked) => {
    const { onChange } = this.props

    let data = {
      backupKeyEnabled: checked,
    }

    if (!checked) {
      data.maxBackupKeysLimit = false
    }

    this.setState({ backupKeyEnabled: checked }, () => {
      onChange(data)
    })
  }

  render() {
    const { backupKeyEnabled } = this.state
    const {
      onChange,
      column: Column,
      container: ColumnContainer,
      maxBackupKeys,
    } = this.props

    return (
      <React.Fragment>
        <ColumnContainer className="form-group col-md-6 bmd-form-group">
          <Column>
            <div>Allow Backup Keys for Stores</div>
            <BackupKeysForStores
              name="backup-keys-for-stores"
              checked={backupKeyEnabled}
              onChange={this.onChangeAllowBackupKeys}
              onChangeOption={(keyType) => {
                onChange({ storesKeysType: keyType })
              }}
            />
          </Column>
        </ColumnContainer>
        <ColumnContainer className="form-group col-md-6 bmd-form-group">
          <Column>
            <div>Limit number of Backup Keys - check to set limit</div>
            <BackupKeysLimit
              name="limit-backup-keys"
              checked={!!maxBackupKeys && backupKeyEnabled}
              limit={maxBackupKeys ? parseInt(maxBackupKeys, 10) : 0}
              onChange={(checked) => onChange({ maxBackupKeysLimit: checked })}
              onChangeOption={(limit) => {
                onChange({ maxBackupKeys: limit })
              }}
              disabled={!backupKeyEnabled}
            />
          </Column>
        </ColumnContainer>
      </React.Fragment>
    )
  }
}

BackupKeys.propTypes = {
  backupKeyEnabled: PropTypes.bool,
  maxBackupKeys: PropTypes.number,
  onChange: PropTypes.func.isRequired,
}

export default BackupKeys
