import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ReactTable from 'react-table'
import 'react-datepicker/dist/react-datepicker.css'
import { fullTextFilter, getTableHeight, humanize } from '../../utils/utils'
import UserKey from '../../components/UserKey'
import MobileNotSupport from '../../components/MobileNotSupport'
import {
  HeaderContainer,
  Header,
  CellContainer,
  renderHeaderCell,
} from '../UsersPage/index.screen'
import { eventIcon, extractStoreName } from './utils'
import AsyncLoadingStatus from '../ExceptionsPage/asyncStatus'
import ProgressIndicator from '../../components/ProgressIndicator'
import TablePreloader from '../../components/Preloaders/TablePreloader'
import TooltipCell from '../../components/TooltipCell'
import { injectIntl } from 'react-intl'

const EventTypeIcon = styled.img`
  margin-right: 5px;
`

const HeaderButtonsContainer = styled.div`
  margin-right: 12px;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`

export const TextTop = styled.span`
  font-size: 14px;
  text-align: left;
`

export const TextBottom = styled.span`
  font-size: 12px;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

const FilterInput = styled.input`
  height: 100%;
  width: 100%;
`

const FilterInputComponent = (filter, onChange, id) => (
  <FilterInput
    type="text"
    placeholder="Filter"
    value={filter ? filter.value : ''}
    onChange={event => onChange(event.target.value)}
    id={`filter_${id}`}
  />
)
const Cell = copy => {
  if (copy === null || typeof copy === 'undefined') {
    return <React.Fragment />
  }
  var text, hovertext
  if (typeof copy === 'string') {
    text = hovertext = copy
  } else if (typeof copy === 'object' && copy.text) {
    text = copy.text
    hovertext = copy.hovertext
  } else {
    text = copy
    hovertext = null
  }
  if (!hovertext) {
    return <CellContainer>{ text }</CellContainer>
  }

  return (<TooltipCell
    text={hovertext || text}
    render={tooltip => (
      <CellContainer ref={ref => tooltip.target(ref)}>
        {text}
      </CellContainer>
    )}
  />)
}

const CellWithRows = copy => {
  const { textPrimary, textSecondary, hovertext } = copy
  return (
    Cell({
      text: (
        <Column>
          <TextTop>{textPrimary}</TextTop>
          <TextBottom>{textSecondary}</TextBottom>
        </Column>
      ),
      hovertext,
    })
  )
}

const CellWithEvent = event => {
  const { icon, textPrimary, textSecondary } = event
  const iconComponent = icon ? (<EventTypeIcon src={icon}/>) : (<React.Fragment />)
  var row1 = textPrimary
  var row2 = <React.Fragment />
  if (textSecondary) {
    row1 = (<TextTop style={{ color: 'black' }}>{row1}</TextTop>)
    row2 = (<TextBottom style={{color: 'black', whiteSpace: 'pre-wrap'}}>
      <b>Reason:</b> {textSecondary}
    </TextBottom>)
  }
  return (
    <div style={{ overflow: 'hidden' }} tabIndex="-1">
      <Row style={{ alignItems: 'center' }}>
        {iconComponent}
        <Column>
          {row1}
          {row2}
        </Column>
      </Row>
    </div>
  )
}

const CellWithUser = (user, onProfile) => {
  const { id, name, key } = user
  const needsTooltip = key && key.length > 8;

  return (
    <UserKey
      userId={id}
      userName={name === 'Realtime Event Listener' ? '' : name}
      keySerialNumber={
        needsTooltip ? (
          <TooltipCell
            text={key}
            render={tooltip => (
              <div ref={ref => tooltip.target(ref)}>
                {`${key.substr(0,14)}...`}
              </div>
            )}
          />
        ) : (
          key
        )
      }
      onProfile={() => {}}
    />
  )
}

// The following function constants are for the filterMethod of the columns array.
// The arguments are:
//   - an object with:
//     - id, the value of the 'accessor' parameter
//     - value, the value of the filter input control
//   - record: an object from the data array
//   - the actual filter control DOM object (not used here)
// it is expected to return a boolean

/** Just a simple case-insensitive text search */
const simpleFilter = ({id, value}, record) => (record[id] || '').toLocaleLowerCase().includes(value.toLocaleLowerCase())
const simpleSort = (l, r) => (l||'').localeCompare(r||'')

/** For records that have a main text and an alternate hovertext (tooltip) */
const hoverFilter = ({id, value}, record) => (
  (record[id].hovertext || '').toLocaleLowerCase().includes(value.toLocaleLowerCase()) ||
  (record[id].text || '').toLocaleLowerCase().includes(value.toLocaleLowerCase())
)
const hoverSort = (l, r) => (
  (l.text||'').localeCompare(r.text||'') ||
  (l.hovertext||'').localeCompare(r.hovertext||'')
)

/** For records that have two rows of text and an optional tooltip */
const rowsFilter =({id, value}, record) => (
  (record[id].textPrimary || '').toLocaleLowerCase().includes(value.toLocaleLowerCase()) ||
  (record[id].textSecondary || '').toLocaleLowerCase().includes(value.toLocaleLowerCase()) ||
  (record[id].hovertext || '').toLocaleLowerCase().includes(value.toLocaleLowerCase())
)
const rowsSort = (l, r) => (
  (l.textPrimary || '').localeCompare(r.textPrimary || '') ||
  (l.textSecondary || '').localeCompare(r.textSecondary || '') ||
  (l.hovertext || '').localeCompare(r.hovertext || '')
)

/** For records with a structured user object */
const userFilter = ({id, value}, record) => (
  (record[id].name || '').toLocaleLowerCase().includes(value.toLocaleLowerCase()) ||
  (record[id].key || '').toLocaleLowerCase().includes(value.toLocaleLowerCase())
)
const userSort = (l, r) => (
  (l.details.lastName || '').localeCompare(r.details.lastName || '') ||
  (l.details.firstName || '').localeCompare(r.details.firstName || '') ||
  (l.key || '').localeCompare(r.key || '')
)


export class ReportsPageScreen extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    window.setTimeout(() => this.handleResize(), 10)
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }
  /* eslint-disable no-undef */
  handleResize = () => this.setState({ tableHeight: getTableHeight() })

  csvDataLink = () => {
    const { loadingStatus, csvData, onExportAsCSV } = this.props
    const text = 'EXPORT AS CSV'
    const { currentName } = loadingStatus || {}
    if (currentName || csvData.length < 2) {
      return (
        <a
          role="button"
          className="btn btn-primary disabled mb-0"
          aria-disabled
        >
          {text}
        </a>
      )
    }

    return (
      <MobileNotSupport text={text}>
        <button
          type="button"
          className="btn btn-primary mb-0"
          onClick={onExportAsCSV}
        >
          {text}
        </button>
      </MobileNotSupport>
    )
  }

  /**
   * Users will want to sort and filter on the values as they appear in the table.
   * In order to do that, we'll have to take care of I18n and other transformations
   * before the record is passed to the table control, and not during the Cell render
   * call.
   */
  translatedReports() {
    const { reports, intl } = this.props
    return reports.map(report => (
      {
        store: {
          text: extractStoreName(report.storeDescription),
          hovertext: report.storeDescription,
        },
        fixture: report.fixtureName,
        resource: {
          textPrimary: intl.formatMessage({
            id: `resourceTypes.${report.resourcePrimaryInfo}`,
            defaultMessage: humanize(report.resourcePrimaryInfo),
          }),
          textSecondary: report.resourceSecondaryInfo,
          hovertext: report.resourceInfoDetails,
        },
        user: {
          id: report.userId,
          name: report.userName,
          key: report.keySerialNumber,
          details: report.user,
        },
        event: {
          icon: eventIcon(report.eventType),
          textPrimary: report.eventTypeMessage,
          textSecondary: report.noncompliantReason,
        },
        occurredAt: report.occurredAt,
        duration: report.duration,
      }
    ))
  }

  columns() {
    const { onProfile } = this.props
    return [
      {
        filterable: true,
        Header: renderHeaderCell('Store'),
        accessor: 'store',
        Cell: ({ original: { store } }) => Cell(store),
        Filter: ({ filter, onChange }) => FilterInputComponent(filter, onChange, 'store'),
        filterMethod: hoverFilter,
        sortMethod: hoverSort,
      },
      {
        filterable: true,
        Header: renderHeaderCell('Fixture'),
        accessor: 'fixture',
        Cell: ({ original: { fixture } }) => Cell(fixture),
        Filter: ({ filter, onChange }) => FilterInputComponent(filter, onChange, 'fixture'),
        filterMethod: simpleFilter,
        sortMethod: simpleSort,
      },
      {
        id: 'resource',
        filterable: true,
        Header: renderHeaderCell('Resource'),
        accessor: 'resource',
        Cell: ({ original: { resource } }) => CellWithRows(resource),
        Filter: ({ filter, onChange }) => FilterInputComponent(filter, onChange, 'resource'),
        filterMethod: rowsFilter,
        sortMethod: rowsSort,
      },
      {
        id: 'userName',
        sortable: true,
        filterable: true,
        accessor: 'user',
        Header: renderHeaderCell('User/Key'),
        Cell: ({ original: { user } }) => CellWithUser(user, onProfile),
        Filter: ({ filter, onChange }) => FilterInputComponent(filter, onChange, 'userName'),
        filterMethod: userFilter,
        sortMethod: userSort,
      },
      {
        filterable: true,
        Header: renderHeaderCell('Event Type and Message'),
        accessor: 'event',
        Cell: ({ original: { event } }) => CellWithEvent(event),
        Filter: ({ filter, onChange }) => FilterInputComponent(filter, onChange, 'eventType'),
        filterMethod: rowsFilter,
        sortMethod: rowsSort,
      },
      {
        filterable: true,
        Header: renderHeaderCell('Date', true),
        accessor: 'occurredAt',
        Cell: ({ original: { occurredAt } }) => (
          <CellContainer>{occurredAt}</CellContainer>
        ),
        Filter: ({ filter, onChange }) => FilterInputComponent(filter, onChange, 'date'),
        filterMethod: simpleFilter,
        sortMethod: simpleSort,
      },
      {
        filterable: true,
        Header: renderHeaderCell('Duration', true),
        accessor: 'duration',
        Filter: ({ filter, onChange }) => FilterInputComponent(filter, onChange, 'duration'),
        filterMethod: simpleFilter,
        sortMethod: simpleSort,
      },
    ]
  }

  render() {
    const {
      createFilter,
      onAsyncLoadingCancel,
      loadingStatus,
      loading,
      errors,
    } = this.props

    const { tableHeight } = this.state

    return (
      <div>
        <HeaderContainer className="border-bottom">
          <Header>Historical Logs</Header>
          <AsyncLoadingStatus
            current={loadingStatus}
            cancel={onAsyncLoadingCancel}
            errors={errors}
          />
          <HeaderButtonsContainer>
            <div className="form-inline">
              <div className="form-group mx-sm-3">{this.csvDataLink()}</div>
              <div className="form-group mx-sm-3">
                <button
                  type="button"
                  className="btn btn-primary btn-raised mb-0"
                  onClick={createFilter}
                >
                  Create Filter
                </button>
              </div>
            </div>
          </HeaderButtonsContainer>
        </HeaderContainer>
        {loading ? (
          <React.Fragment>
            <TablePreloader columnsCount={7} loaderWidth={1200} />
            <ProgressIndicator text={'Loading...'} />
          </React.Fragment>
        ) : (
          <ReactTable
            className="full-screen"
            style={{ height: tableHeight || 'auto' }}
            data={this.translatedReports()}
            columns={this.columns()}
            defaultFilterMethod={fullTextFilter}
            getTdProps={(state, rowInfo) => ({
              onClick: (e, handleOriginal) => {
                console.log('onRow >> ', rowInfo.original)

                if (handleOriginal) {
                  handleOriginal()
                }
              },
            })}
          />
        )}
      </div>
    )
  }
}

ReportsPageScreen.propTypes = {
  reports: PropTypes.array.isRequired,
  onProfile: PropTypes.func.isRequired,
  csvData: PropTypes.array.isRequired,
  createFilter: PropTypes.func.isRequired,
  loadingStatus: PropTypes.object,
  onAsyncLoadingCancel: PropTypes.func,
  errors: PropTypes.array,
  loading: PropTypes.bool,
  onExportAsCSV: PropTypes.func.isRequired,
}

export default injectIntl(ReportsPageScreen)
