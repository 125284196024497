/**
 * @module Sagas/User
 * @desc User
 */

import { all, call, put, takeLatest, select } from 'redux-saga/effects'
import {
  signIn,
  signOut,
  adminSignIn,
  getOrganization,
  getRegions,
  fwManagementSignIn,
  otpRequest,
} from '../../../api'
import { ActionTypes } from '../constants'
import { makeSelectToken, selectIsMTIUserFromRoles } from '../selectors'
import { restoreUser, saveUser, destroyUser } from '../localAuth'
import { resetOrm } from '../actions/orm'
import { loginSuccess } from '../actions/user'
import { fixApiRolesCache } from '../../../utils/mtiUtils'
import { storeImages, loadDataForOrganization } from '../actions'
import { errorToast } from '../../../utils/utils'

// const noPermissionText =
//   "You don't have appropriate rights to access Adming App.\nPlease contact your corporate manager."

/**
 * User login
 * @param payload
 */
function* login({ payload }) {
  try {
    const data = yield call(signIn, payload)
    const {
      authentications: [{ token, expiresAt, tokenId }],
      users: [
        {
          id,
          firstName,
          lastName,
          phoneNumber,
          employeeIdentifier,
          rolesCache,
          avatarId,
        },
      ],
      organizations,
      images,
    } = data
    const { id: organizationId, name: organizationName } = (organizations || [
      {},
    ])[0]
    const roles = fixApiRolesCache(rolesCache)

    let regions
    try {
      regions = yield call(getRegions, token)
    } catch (error) {
      console.error(error)
      errorToast('Get regions failed')
    }

    let orgId
    let orgName
    if (!organizationId) {
      try {
        const { organizations: orgs } = yield call(getOrganization, token)
        const org = (orgs || [{}])[0]
        orgId = org.id
        orgName = org.name
      } catch (error) {
        console.error(error)
        errorToast('Get organization failed')
      }
    }

    yield images && put(storeImages(images))
    const avatar = images && images.find(i => i.id === avatarId)

    const user = {
      id,
      token,
      tokenId,
      expiresAt,
      phoneNumber,
      firstName,
      lastName,
      employeeIdentifier,
      avatarId,
      avatar,
      name: `${firstName} ${lastName}`,
      mtiRoles: roles,
      regions: (regions || {}).regions,
      organizationId: organizationId || orgId,
      organizationName: organizationName || orgName,
    }

    saveUser(user)
    yield put(loginSuccess(user))
    yield put(loadDataForOrganization())
  } catch (err) {
    console.error(err)
    const res = err.response
    const responseBody = res ? (yield call([res, res.json])) : null
    destroyUser()
    yield put({
      type: ActionTypes.USER_LOGIN_FAILURE,
      payload: { message: 'Login failed, try again', body: responseBody },
    })
  }
}

/**
 * Admin login
 * @param payload
 */
function* adminLogin({ payload }) {
  try {
    const data = yield call(adminSignIn, payload)
    yield call(completeLogin, data)
  } catch (err) {
    yield call(loginError, err)
  }
}

/**
 * otp login completion
 * @param payload
 */
function* otpRequestSaga({ payload }) {
  try {
    const data = yield call(otpRequest, payload)
    yield call(completeLogin, data)
  } catch (err) {
    yield call(loginError, err)
  }
}

function* completeLogin(data) {
  try {
    const {
      authentications: [{ token, expiresAt, tokenId }],
      users: [
        {
          id,
          firstName,
          lastName,
          avatarId,
          roleKey,
        },
      ],
    } = data
    const rolesCache = data.users[0].rolesCache || []
    rolesCache[0] = rolesCache[0] || {
      name: 'mti_admin',
      storeId: null,
      resourceId: -100,
      resourceType: 'MTI',
    }
    rolesCache[0].name = data.users[0].roleKey
    const roles = fixApiRolesCache(rolesCache)

    // FW Management Authentication

    let saltAuthData = {}
    try {
      const authResult = yield call(fwManagementSignIn, token)
      saltAuthData = authResult.fwManagementAuthentications[0]
    } catch (e) { }

    const user = {
      id,
      token,
      tokenId,
      expiresAt,
      avatarId,
      avatar: null,
      saltToken: saltAuthData.token,
      saltTokenExpiresAt: saltAuthData.expiresAt,
      firstName,
      lastName,
      name: `${firstName} ${lastName}`,
      mtiRoles: roles,
      roleKey,
    }

    saveUser(user)
    yield put(loginSuccess(user))
  } catch (err) {
    yield call(loginError, err)
  }
}

function* loginError(err) {
  console.error(err)
  const res = err.response
  const responseBody = res ? (yield call([res, res.json])) : null
  destroyUser()
  yield put({
    type: ActionTypes.USER_LOGIN_FAILURE,
    payload: { message: 'Login failed, try again', body: responseBody },
  })
}

/**
 * User Logout
 */
function* logout() {
  try {
    const token = yield select(makeSelectToken())
    if (token) {
      yield call(signOut, token)
    }
    yield put(resetOrm())
    yield put({
      type: ActionTypes.USER_LOGOUT_SUCCESS,
    })
  } catch (err) {
    yield put({
      type: ActionTypes.USER_LOGOUT_FAILURE,
      payload: 'Logout failed, try again',
    })
    errorToast('Logout failed')
  } finally {
    destroyUser()
  }
}

/**
 * Restore user auth object from local storage
 */
function* restoreUserAuth() {
  try {
    const user = restoreUser()
    if (user) {
      yield put({
        type: ActionTypes.USER_RESTORE_SUCCESS,
        payload: user,
      })
    } else {
      yield put({
        type: ActionTypes.USER_RESTORE_FAILURE,
      })
    }
    const { mtiRoles } = user || {}
    const isMtiUser = selectIsMTIUserFromRoles(mtiRoles)
    if (isMtiUser && window.location.pathname === '/') return

    yield put(loadDataForOrganization())
  } catch (e) {
    console.error(e)
    destroyUser()
    yield put({
      type: ActionTypes.USER_RESTORE_FAILURE,
    })
  }
}

/**
 * User Sagas
 */
export default function* root() {
  yield all([
    takeLatest(ActionTypes.USER_RESTORE_REQUEST, restoreUserAuth),
    takeLatest(ActionTypes.USER_LOGIN_REQUEST, login),
    takeLatest(ActionTypes.ADMIN_LOGIN_REQUEST, adminLogin),
    takeLatest(ActionTypes.OTP_REQUEST, otpRequestSaga),
    takeLatest(ActionTypes.USER_LOGOUT_REQUEST, logout),
  ])
}
