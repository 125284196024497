import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import { hasAccess } from 'utils/mtiUtils'
import ConfirmModal from '../../containers/ModalContainer/ConfirmModal'
import { ActionTypes } from '../App/constants'
import {
  makeSelectMtiPermission,
  makeSelectRegionManagement,
  makeSelectRegionDetails,
  // makeSelectRegionEditFailed,
  // makeSelectRegionEditLoading,
  makeSelectRegionFound,
  makeSelectMtiPermissionAccessData,
} from '../App/selectors'
import {
  openModal,
  closeModal,
  notifyModal,
  editRegion,
  clearRegion,
  postRegion,
  deleteRegionAct,
  patchRegion,
  findRegionFoundHandled,
  resetRegionManagement,
  storeDetailsAction,
} from '../App/actions'
import RegionDetailsModal from './RegionDetailsModal'
import CreateEditRegionModal from './CreateEditRegionModal'

const permText = (text) =>
  `You don't have appropriate rights to access ${text}.\nPlease contact your store manager.`

export class RegionManagement extends React.PureComponent {
  componentDidUpdate() {
    const {
      regionManagement,
      regionDetails,
      resetRegionManagement: doResetRegionManagement,
      // regionEditFailed,
      // regionEditLoading,
      regionFound,
      showStore,
    } = this.props
    if (regionManagement === ActionTypes.REGION_DETAILS) {
      this.openRegionDetailsModal(regionDetails)
      doResetRegionManagement()
      return
    }
    if (regionManagement === ActionTypes.CREATE_REGION) {
      this.openCreateRegionDetailsModal()
      doResetRegionManagement()
      return
    }
    /*
    if (
      !regionEditFailed &&
      !regionEditLoading &&
      this.props.regionEditLoading &&
      this.createRegionDetailsModal
    ) {
      this.createRegionDetailsModal.close()
    }
    */
    // if (regionEditFailed && this.createRegionDetailsModal) {
    //   this.createregionModal.setServerValidity(regionEditFailed)
    // }
    if (regionFound) {
      setTimeout(() => {
        this.openEditExistingRegionModal(regionFound)
        this.props.findRegionFoundHandled()
      }, 500)
    }

    if (showStore) {
      this.props.storeDetailsAction(showStore)
    }
  }

  /* eslint-disable no-undef */
  onStoreSelection = (store) => {
    const { showStorePermissionData, loadStore } = this.props
    const hasShowStorePermission = hasAccess({
      ...showStorePermissionData,
      resourceId: store.id,
      resourceType: 'Store',
      regionId: store.regionId,
    })
    if (this.noPermission(hasShowStorePermission, permText('View Store'))) {
      return
    }

    loadStore(store.id)
  }

  /* eslint-disable no-undef */
  openRegionDetailsModal = (region) => {
    const {
      hasShowRegionPermission,
      hasCreateRegionPermission,
      hasEditRegionPermission,
      hasDestroyRegionPermission,
      openModal: doOpenModal,
      closeModal: doCloseModal,
      clearRegion: doClearRegion,
      deleteRegionAct: doDeleteRegion,
      onCloseRegionDetailsModal,
      onCloseEditManagers,
    } = this.props
    if (this.noPermission(hasShowRegionPermission, permText('View Region'))) {
      return
    }

    doClearRegion()
    doOpenModal({
      id: 'region',
      type: 'bootstrap',
      content: (
        <RegionDetailsModal
          id={'region'}
          region={region}
          hasCreateRegionPermission={hasCreateRegionPermission}
          isRegionEditLocked={!hasEditRegionPermission}
          title={region.name}
          dangerText={'DELETE REGION'}
          confirmText={'EDIT REGION'}
          onDanger={() =>
            setTimeout(() => {
              if (
                this.noPermission(
                  hasDestroyRegionPermission,
                  permText('Delete Region')
                )
              ) {
                return
              }
              doOpenModal({
                id: 'delete',
                type: 'bootstrap',
                appearance: 'modal-sm',
                content: (
                  <ConfirmModal
                    id={'delete'}
                    cancelText={'CANCEL'}
                    confirmText={'DELETE REGION'}
                    onClose={() => doCloseModal({ id: 'delete' })}
                    onConfirm={() => doDeleteRegion(region)}
                  />
                ),
              })
            }, 500)
          }
          onConfirm={() => {
            doCloseModal({ id: 'region' })
            doClearRegion()
            setTimeout(() => this.openEditExistingRegionModal(region), 500)
            if (onCloseRegionDetailsModal) onCloseRegionDetailsModal()
          }}
          onClose={() => {
            doCloseModal({ id: 'region' })
            if (onCloseRegionDetailsModal) onCloseRegionDetailsModal()
          }}
          onStore={(store) => {
            doCloseModal({ id: 'region' })
            this.onStoreSelection(store)
          }}
          onCloseEditManagers={onCloseEditManagers}
        />
      ),
    })
  }

  noPermission = (hasPermission, text) => {
    const { notifyModal: doNotifyModal } = this.props
    if (!hasPermission) doNotifyModal(text)
    return !hasPermission
  }

  openEditExistingRegionModal = (region) => {
    const {
      hasEditRegionPermission,
      openModal: doOpenModal,
      closeModal: doCloseModal,
      editRegion: doEditRegion,
      patchRegion: doPatchRegion,
    } = this.props
    if (this.noPermission(hasEditRegionPermission, permText('Edit Region'))) {
      return
    }

    doOpenModal({
      id: 'edit-region',
      type: 'bootstrap',
      content: (
        <CreateEditRegionModal
          ref={(ref) => {
            if (ref) {
              // this.createRegionModal = ref.getWrappedInstance()
              this.createRegionModal = ref.refs
            }
          }}
          id={'edit-region'}
          region={region}
          onChange={(o) => doEditRegion(o)}
          title={`Editing Region "${region.name}"`}
          confirmText={'SAVE REGION'}
          onClose={() => doCloseModal({ id: 'edit-region' })}
          onConfirm={() => doPatchRegion(region.id)}
        />
      ),
    })
  }

  openCreateRegionDetailsModal = () => {
    const {
      hasCreateRegionPermission,
      openModal: doOpenModal,
      closeModal: doCloseModal,
      postRegion: doPostRegion,
      clearRegion: doClearRegion,
      editRegion: doEditRegion,
    } = this.props
    if (
      this.noPermission(hasCreateRegionPermission, permText('Create Region'))
    ) {
      return
    }

    doClearRegion()
    doOpenModal({
      id: 'add-region',
      type: 'bootstrap',
      content: (
        <CreateEditRegionModal
          ref={(ref) => (this.createRegionModal = ref)}
          id={'add-region'}
          region={{}}
          onChange={(o) => doEditRegion(o)}
          title={'Add New Region'}
          confirmText={'CREATE REGION'}
          onClose={() => doCloseModal({ id: 'add-region' })}
          onConfirm={() => doPostRegion()}
        />
      ),
    })
  }

  render() {
    return <div />
  }
}

RegionManagement.propTypes = {
  regionManagement: PropTypes.string,
  regionDetails: PropTypes.object,
  // regionEditLoading: PropTypes.bool,
  // regionEditFailed: PropTypes.object,
  hasCreateRegionPermission: PropTypes.bool,
  hasShowRegionPermission: PropTypes.bool,
  hasEditRegionPermission: PropTypes.bool,
  hasDestroyRegionPermission: PropTypes.bool,
  notifyModal: PropTypes.func,
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
  postRegion: PropTypes.func,
  patchRegion: PropTypes.func,
  clearRegion: PropTypes.func,
  deleteRegionAct: PropTypes.func,
  editRegion: PropTypes.func,
  findRegionFoundHandled: PropTypes.func,
  regionFound: PropTypes.object,
  resetRegionManagement: PropTypes.func,
  onCloseRegionDetailsModal: PropTypes.func,
  showStorePermissionData: PropTypes.object,
  storeDetailsAction: PropTypes.func,
  showStore: PropTypes.object,
  loadStore: PropTypes.func.isRequired,
  onCloseEditManagers: PropTypes.func.isRequired,
}

const mapStateToProps = createStructuredSelector({
  regionManagement: makeSelectRegionManagement(),
  regionDetails: makeSelectRegionDetails(),
  // regionEditLoading: makeSelectRegionEditLoading(),
  // regionEditFailed: makeSelectRegionEditFailed(),
  regionFound: makeSelectRegionFound(),
  hasCreateRegionPermission: (state, ownProps) =>
    makeSelectMtiPermission('Region: Create New Objects', ownProps.storeId)(
      state,
      ownProps
    ),
  hasShowRegionPermission: (state, ownProps) =>
    makeSelectMtiPermission('Region: Read/Show Details', ownProps.storeId)(
      state,
      ownProps
    ),
  hasEditRegionPermission: (state, ownProps) =>
    makeSelectMtiPermission(
      'Region: Update Details of Existing Records',
      ownProps.storeId
    )(state, ownProps),
  hasDestroyRegionPermission: (state, ownProps) =>
    makeSelectMtiPermission(
      'Region: Destroy Existing Records',
      ownProps.storeId
    )(state, ownProps),
  showStorePermissionData: makeSelectMtiPermissionAccessData(
    'Store: Read/Show Details'
  ),
})

const mapDispatchToProps = {
  notifyModal,
  openModal,
  closeModal,
  postRegion,
  patchRegion,
  clearRegion,
  deleteRegionAct,
  editRegion,
  findRegionFoundHandled,
  resetRegionManagement,
  storeDetailsAction,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)
export default compose(withConnect)(RegionManagement)
