import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import {
  openModal,
  closeModal,
  resetApp,
  resetOrm,
  notifyModal,
} from '../../../App/actions'
import { loadOrganizations, selectOrganization } from './actions'
import StoresListScreen from './index.screen'
import ProgressIndicator from '../../../../components/ProgressIndicator'
import TablePreloader from '../../../../components/Preloaders/TablePreloader'
import {
  loadingSelector,
  failedSelector,
  organizationsSelector,
} from './selectors'
import { HeaderContainer, Header } from '../../../UsersPage/index.screen'
import { makeSelectSaltToken } from '../../../App/selectors'
import { safeUri } from '../../../../utils/utils'

class StoresList extends React.Component {
  constructor(props) {
    super(props)
    // Prevent control from hitting the API too many times.
    this.state = { antiFlutter: false }
  }

  componentDidMount() {
    const { resetApp, resetOrm, loadOrganizations } = this.props
    const { antiFlutter } = this.state
    if (antiFlutter) return
    this.setState({antiFlutter: true},
      function () {
        resetApp()
        resetOrm()
        loadOrganizations()
      }
    )
  }

  render() {
    const {
      loading,
      failed,
      organizations,
      selectOrganization: doSelectOrganization,
      selectedOrganizationId,
      height,
    } = this.props
    if (loading)
      return (
        <React.Fragment>
          <div style={{ height: '100%' }}>
            <HeaderContainer className="border-bottom">
              <Header>Organizations</Header>
            </HeaderContainer>
          </div>
          <TablePreloader
            columnsCount={3}
            loaderWidth={600}
            heightLoader={height}
          />
          <ProgressIndicator text={'Loading...'} />
        </React.Fragment>
      )
    if (failed) {
      return <div>{`Fetch Error: ${JSON.stringify(failed)}`}</div>
    }
    if (!organizations) return null

    return (
      <StoresListScreen
        organizations={organizations}
        selectedOrganizationId={selectedOrganizationId}
        onSelectOrganization={doSelectOrganization}
        height={height}
        onOrganization={({ id, name }) =>
          this.props.history.push(`/organizations/${id}/${safeUri(name)}`)
        }
        goToOta={() => {
          if (this.props.saltToken) {
            this.props.history.push('/admin/ota')
          } else {
            this.props.notifyModal('OTA Admin is not available right now.')
          }
        }}
      />
    )
  }
}

StoresList.propTypes = {
  organizations: PropTypes.array,
  selectedOrganizationId: PropTypes.string,
  loading: PropTypes.bool,
  failed: PropTypes.object,
  loadOrganizations: PropTypes.func,
  resetApp: PropTypes.func,
  resetOrm: PropTypes.func,
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
  selectOrganization: PropTypes.func,
  height: PropTypes.number,
  user: PropTypes.object,
  notifyModal: PropTypes.func.isRequired,
}

const mapStateToProps = createStructuredSelector({
  organizations: organizationsSelector,
  loading: loadingSelector,
  failed: failedSelector,
  saltToken: makeSelectSaltToken(),
})

const mapDispatchToProps = {
  loadOrganizations,
  resetApp,
  resetOrm,
  openModal,
  closeModal,
  selectOrganization,
  notifyModal,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect)(StoresList)
