/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Needed for redux-saga es6 generator support
import 'core-js/shim'
import 'regenerator-runtime/runtime'

// Import all the third party stuff
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'react-router-redux'
import { createBrowserHistory as createHistory } from 'history'
// import { BugsnagClient } from './utils/bugsnag'
import 'sanitize.css/sanitize.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'react-table/react-table.css'

// Import root app
import App from './containers/App'

// Import Language Provider
import LanguageProvider from './containers/LanguageProvider'

// Load the favicon, the manifest.json file and the .htaccess file
/* eslint-disable import/no-unresolved, import/extensions, import/first */
import '!file-loader?name=[name].[ext]!./images/favicon.ico'
import '!file-loader?name=[name].[ext]!./images/icon-72x72.png'
import '!file-loader?name=[name].[ext]!./images/icon-96x96.png'
import '!file-loader?name=[name].[ext]!./images/icon-128x128.png'
import '!file-loader?name=[name].[ext]!./images/icon-144x144.png'
import '!file-loader?name=[name].[ext]!./images/icon-152x152.png'
import '!file-loader?name=[name].[ext]!./images/icon-192x192.png'
import '!file-loader?name=[name].[ext]!./images/icon-384x384.png'
import '!file-loader?name=[name].[ext]!./images/icon-512x512.png'
// import '!file-loader?name=[name].[ext]!./manifest.json'
import 'file-loader?name=[name].[ext]!./.htaccess'
import '!file-loader?name=[name].[ext]!./styles/bootstrap-material-design.min.css'
import '!file-loader?name=[name].[ext]!./images/alphabetical_sorting.svg'
import '!file-loader?name=[name].[ext]!./images/alphabetical_sorting_2.svg'
import '!file-loader?name=[name].[ext]!./images/numerical_sorting_12.svg'
import '!file-loader?name=[name].[ext]!./images/numerical_sorting_21.svg'
import '!file-loader?name=[name].[ext]!./images/svg/buttons/info.svg'
import '!file-loader?name=[name].[ext]!./fonts/arial.ttf'
/* eslint-enable import/no-unresolved, import/extensions */

import configureStore from './configureStore'

// Import i18n messages
import { translationMessages } from './i18n'
import { updateInstance } from './components/Crosstab'

updateInstance()

// Create redux store with history
const initialState = {}
const history = createHistory()
const store = configureStore(initialState, history)
const MOUNT_NODE = document.getElementById('app')

const render = messages => {
  let rootElement = (
    <Provider store={store}>
      <LanguageProvider messages={messages}>
        <ConnectedRouter history={history}>
          <App />
        </ConnectedRouter>
      </LanguageProvider>
    </Provider>
  )

  // if we're not in development mode, use bugsnag
  // if (process.env.NODE_ENV != 'development') {
  //   const ErrorBoundary = BugsnagClient.getPlugin('react')
  //   rootElement = <ErrorBoundary>{rootElement}</ErrorBoundary>
  // }

  ReactDOM.render(rootElement, MOUNT_NODE)
}

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./i18n', 'containers/App'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE)
    render(translationMessages)
  })
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise(resolve => {
    resolve(import('intl'))
  })
    .then(() => Promise.all([import('intl/locale-data/jsonp/en.js')]))
    .then(() => render(translationMessages))
    .catch(err => {
      throw err
    })
} else {
  render(translationMessages)
}

// Install ServiceWorker and AppCache in the end since
// it's not most important operation and if main code fails,
// we do not want it installed

if (process.env.NODE_ENV === 'production') {
  const runtime = require('offline-plugin/runtime') // eslint-disable-line global-require
  runtime.install({
    onInstalled: () => {
      console.log('SW Event:', 'onInstalled')
    },
    onUpdating: () => {
      console.log('SW Event:', 'onUpdating')
    },
    onUpdateReady: () => {
      console.log('SW Event:', 'onUpdateReady')
      // Tells to new SW to take control immediately
      runtime.applyUpdate()
    },
    onUpdated: () => {
      console.log('SW Event:', 'onUpdated')
      // Reload the webpage to load into the new version
      window.location.reload()
    },

    onUpdateFailed: () => {
      console.log('SW Event:', 'onUpdateFailed')
    },
  })
}
