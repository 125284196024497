/**
 *
 * AreaCanvas component
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import { fabric } from 'fabric'
import { debounce } from 'lodash'
import { LongPressMilliseconds, LongTapResolver } from '../../utils/mtiUtils'
import {
  sviFigureType,
  sviFigureSizeType,
  incrementalObjectName,
  defaultName,
  onFigureBlinking,
  onClearBlinking,
} from '../../utils/mtiCanvasUtils'
import Canvas from '../Canvas'
import {
  minW,
  minH,
  propertiesToInclude,
  slicingFixture,
  createIcObject,
  updateIcObject,
  updateIcAngle,
  renderLabel,
} from './fixture'
import { getMaxSize } from './sizeUtils'
import { setOverlapMethod } from '../FabricCanvas/utils'
import {
  onScaleNewObject,
  onModifiedMeasurer,
  onScaleObject,
  getInitialCoords,
  isIntersectHandled,
} from '../FabricCanvas/measurer'
import { restoreCanvasOverlapEnabled } from '../../containers/App/localStorageOptions'
import { resizeToScale, applyMaxMinScale } from '../FloorCanvas/utils'

const canvas = new fabric.Canvas()
let preSelectedInstance
let selectedInstance
let updatedInstance

let isDown

class AreaCanvas extends React.PureComponent {
  /* eslint-disable no-undef, react/sort-comp, no-underscore-dangle */
  updateInstance() {
    this.updatedInstance = canvas.getActiveObject()
  }

  onDefault() {
    preSelectedInstance = undefined
    selectedInstance = undefined
    updatedInstance = undefined
  }

  initCanvas = (isEditable) => {
    const isCanvasOverlapEnabled = restoreCanvasOverlapEnabled()
    const { onChanged, size } = this.props
    const el = this.canvasContainer.objectsCanvas
    let rect
    let origX
    let origY
    let numObjectsOnCanvas

    const longTapResolver = new LongTapResolver()

    canvas.initialize(el, {
      width: size.width,
      height: size.height,
      selection: false,
      backgroundColor: null,
    })

    if (isEditable) {
      // Edit canvas listeners
      let initialCoords = {}

      // IC for editing selected object
      if ((preSelectedInstance || {}).id !== (selectedInstance || {}).id) {
        preSelectedInstance = selectedInstance
      }
      selectedInstance = canvas.getActiveObject()

      canvas.off('before:selection:cleared')
      canvas.on('before:selection:cleared', () => {
        updatedInstance = canvas.getActiveObject()
      })

      canvas.off('mouse:down')
      canvas.on('mouse:down', async (o) => {
        isDown = true
        const activeObject = canvas.getActiveObject()
        if (
          activeObject &&
          (preSelectedInstance || {}).id !== (selectedInstance || {}).id
        ) {
          preSelectedInstance = selectedInstance
        }
        selectedInstance = activeObject

        initialCoords = (o.target || {}).oCoords || getInitialCoords(o)

        numObjectsOnCanvas = canvas.getObjects().length

        if (
          canvas
            .getObjects()
            .find(({ id }) => id === (updatedInstance || {}).id)
        ) {
          if (
            JSON.stringify(updatedInstance) !==
            JSON.stringify(preSelectedInstance)
          ) {
            // If there was any changes in selected object
            if (
              (updatedInstance || {}).id === (preSelectedInstance || {}).id &&
              (updatedInstance || {}).id !== (selectedInstance || {}).id
            ) {
              // Edit object that exists
              this.props.onCanvasFixtureSave((updatedInstance || {}).id)
              preSelectedInstance = undefined
              if (!activeObject) {
                this.onDefault()
              }
              isDown = false
              return
            } else if (
              updatedInstance &&
              !preSelectedInstance &&
              (updatedInstance || {}).id !== (selectedInstance || {}).id &&
              isNaN(updatedInstance.id)
            ) {
              // Create new object
              this.props.onCanvasFixtureSave((updatedInstance || {}).id)
              updatedInstance = undefined
              if (!activeObject) {
                this.onDefault()
              }
              isDown = false
              return
            }
          }
        }

        if (selectedInstance) {
          return // Do not initiate new object draw, if there is an active object
        } else {
          this.onDefault()
        }

        const pointer = canvas.getPointer(o.e)

        origX = pointer.x
        origY = pointer.y

        const fixtureName = incrementalObjectName(
          this.props.canvasObject.objects,
          defaultName.fixture
        )

        rect = await slicingFixture(
          createIcObject(fixtureName, origX / size.width, origY / size.width),
          pointer,
          origX,
          origY
        )

        canvas.add(rect)
      })

      canvas.off('mouse:move')
      canvas.on('mouse:move', (o) => {
        if (!isDown || !rect) return

        const { x, y } = canvas.getPointer(o.e)
        if (origX > x || origY > y) return

        onScaleNewObject(
          { ...o, target: rect },
          canvas,
          size.width,
          size.height,
          initialCoords
        )

        if (origX > x) {
          rect.set({ left: Math.abs(x) })
        }

        if (origY > y) {
          rect.set({ top: Math.abs(y) })
        }

        const width = Math.abs(origX - x)
        const height = Math.abs(origY - y)

        const { width: fixedWidth, height: fixedHeight } = getMaxSize(
          width,
          height
        )

        const clone = fabric.util.object.clone(rect) // get cloned object
        clone.set({
          originX: 'left',
          originY: 'top',
          width: fixedWidth,
          height: fixedHeight,
        })
        const intersectClone = onScaleNewObject(
          { ...o, target: clone },
          canvas,
          size.width,
          size.height,
          initialCoords
        )

        const isInterXHandled = isIntersectHandled(intersectClone)
        const isInterYHandled = isIntersectHandled(intersectClone)
        const newWidth = isInterXHandled ? clone.width : fixedWidth
        const newHeight = isInterYHandled ? clone.height : fixedHeight
        const newLeft = isInterXHandled ? clone.left : rect.left
        const newTop = isInterYHandled ? clone.top : rect.top
        rect.set({
          originX: 'left',
          originY: 'top',
          left: newLeft,
          top: newTop,
          width: newWidth,
          height: newHeight,
        })
        rect.item(0).set({
          scaleX: newWidth / rect.item(0).width,
          scaleY: newHeight / rect.item(0).height,
        })
        console.warn('rect', rect.scaleX, rect.scaleY)
        renderLabel(rect, !isEditable)

        canvas.renderAll()
      })

      canvas.onBeforeScaleRotate = function lock(o) {
        o.set({ lockScalingX: false, lockScalingY: false })
      }

      canvas.off('object:scaling')
      canvas.on('object:scaling', (o) => {
        const group = o.target
        group.objectCaching = false
        if (isCanvasOverlapEnabled) {
          onScaleObject(o, canvas, size.width, size.height, initialCoords)
        }

        const actualW = group.scaleX * group.width
        const actualH = group.scaleY * group.height
        group.set({
          width: actualW,
          height: actualH,
          scaleX: 1,
          scaleY: 1,
        })
        const bgn = o.target.item(0)
        bgn.set({
          scaleX: actualW / bgn.width,
          scaleY: actualH / bgn.height,
        })

        const { width: maxW, height: maxH } = getMaxSize(actualW, actualH)
        const label = o.target.item(1)
        const text = o.target.item(2)

        applyMaxMinScale(group, actualW, actualH, maxW, maxH, minW, minH)
        resizeToScale(group, [label, text])
        renderLabel(group, !isEditable)

        group.set({
          sviType: sviFigureType.custom,
          sviSizeType: sviFigureSizeType.custom,
          __sviTypeOrigin: group.__sviTypeOrigin || group.sviType,
        })
        if (group.o && group.o.layoutPosition)
          group.o.layoutPosition.type = sviFigureType.custom
      })

      canvas.off('object:rotating')
      canvas.on('object:rotating', (options) => {
        const step = 5
        const angle = Math.round(options.target.angle / step) * step
        const angleFixed = angle === 360 ? 0 : angle
        options.target.angle = angleFixed
        updateIcAngle(options.target)
      })
    } else {
      // Static canvas listeners
      canvas.off('mouse:down')
      canvas.off('mouse:move')
      canvas.off('object:scaling')
      canvas.off('object:moving')

      canvas.off('mouse:down')
      canvas.on('mouse:down', (e) => {
        longTapResolver.mouseDown(e.target)

        setTimeout(() => {
          if (!longTapResolver.isLongTap(e.target)) {
            return
          }
          this.onOpenFixture(e.target)
        }, LongPressMilliseconds)
      })

      /**
       * uncomment to use click and drag functionality to create new fixture
       * skip for mobile
       */
      // if (!isMobile) {
      //   canvas.off('mouse:down')
      //   canvas.on('mouse:down', async (o) => {
      //     if (o && o.target) return
      //     isDown = true
      //     const pointer = canvas.getPointer(o.e)
      //     origX = pointer.x
      //     origY = pointer.y
      //     rect = fixtureRect({
      //       name: defaultName.fixture,
      //       deviceCount: '0',
      //       fixtureIssuesCount: '0',
      //       type: null,
      //       isEdit: true,
      //       isMobile: false,
      //       origX,
      //       origY,
      //       type: sviFigureType.custom,
      //       prototypes,
      //     })
      //     rect.set({ scaleX: 0, scaleY: 0 })
      //     canvas.add(rect)
      //   })
      //   canvas.off('object:moving')
      //   canvas.on('object:moving', (o) => {
      //     const { target } = o
      //     originScaleTop = target.top
      //     originScaleLeft = target.left
      //   })
      //   canvas.off('mouse:move')
      //   canvas.on('mouse:move', (o) => {
      //     // return
      //     if (!(isDown && rect)) return
      //     const { x, y } = canvas.getPointer(o.e)
      //     if (origX > x) {
      //       rect.set({ left: Math.abs(x) })
      //     }
      //     if (origY > y) {
      //       rect.set({ top: Math.abs(y) })
      //     }
      //     const width = Math.abs(origX - x)
      //     const height = Math.abs(origY - y)
      //     rect.set({
      //       originX: 'left',
      //       originY: 'top',
      //       scaleX: width / originFixtureRectWidth,
      //       scaleY: height / originFixtureRectHeight,
      //     })
      //     canvas.renderAll()
      //   })
      // }
    }

    canvas.off('mouse:up')
    canvas.on('mouse:up', (e) => {
      onModifiedMeasurer(canvas) // remove rulers from canvas
      longTapResolver.mouseUp()
      isDown = false
      let isStaticLocal = true
      let selectedId = (canvas.getActiveObject() || { id: -1 }).id
      // Remove zero-sized objects (when user accidentally clicked on canvas)
      if (rect && !(rect.width > minW && rect.height > minH)) {
        canvas.remove(rect)
        rect = undefined
      } else if (rect) {
        isStaticLocal = false
        // Selected fixture after drawing
        canvas.setActiveObject([...canvas.getObjects()].pop(), e)
        selectedInstance = canvas.getActiveObject()
        // Convert origins from {left, top} to {center, center}
        rect.set({
          top: rect.scaleY * rect.height / 2 + rect.top,
          left: rect.scaleX * rect.width / 2 + rect.left,
          originX: 'center',
          originY: 'center',
        })
        updateIcObject(rect, size)

        canvas.renderAll()

        if (numObjectsOnCanvas === canvas.getObjects().length) {
          selectedId = -1 // prevent existing object selecting if a new object was not created
        } else {
          selectedId = (canvas.getActiveObject() || { id: -1 }).id
        }
      }

      onChanged({
        canvasObject: canvas.toObject(propertiesToInclude),
        selectedId: selectedId,
        isStatic: isStaticLocal,
      })
      rect = undefined
    })

    canvas.off('mouse:dblclick')
    canvas.on('mouse:dblclick', (e) => {
      this.onOpenFixture(e.target)
    })

    if (isCanvasOverlapEnabled) {
      setOverlapMethod('measurer', canvas, size.width, size.height)
    }
    this.canvasContainer.loadAndRender()
  }

  constructor(props) {
    super(props)
    this.onOpenFixture = debounce(this.onOpenFixture, 100)
  }

  componentDidUpdate(prevProps) {
    const { isEditable } = this.props
    if (prevProps.isEditable !== isEditable) {
      this.initCanvas(isEditable)
    }
    this.updateInstance()
  }

  componentDidMount() {
    const { isEditable } = this.props
    this.initCanvas(isEditable)
  }

  componentWillUnmount() {
    onClearBlinking()
  }

  onMessage(msg) {
    const { onMessage } = this.props
    if (onMessage) onMessage(msg)
  }

  onOpenFixture(obj) {
    const { onOpenFixture } = this.props
    if (onOpenFixture) onOpenFixture(obj)
  }

  isInteractive() {
    return isDown
  }

  render() {
    const { canvasObject, selectedId, mtiJsclientShared } = this.props

    return (
      <Canvas
        ref={(ref) => {
          this.canvasContainer = ref

          setTimeout(() => {
            onFigureBlinking(canvas, mtiJsclientShared)
          }, 500)
        }}
        canvasObject={canvasObject}
        selectedId={selectedId}
        fabricInstance={canvas}
      />
    )
  }
}

AreaCanvas.propTypes = {
  selectedId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  canvasObject: PropTypes.object.isRequired,
  size: PropTypes.object.isRequired,
  isEditable: PropTypes.bool.isRequired,
  onChanged: PropTypes.func.isRequired,
  prototypes: PropTypes.object,
  mtiJsclientShared: PropTypes.object,
  onOpenFixture: PropTypes.func,
  onMessage: PropTypes.func,
  onCanvasFixtureSave: PropTypes.func,
}

export default AreaCanvas
