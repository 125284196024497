import { ActionTypes } from '../constants'

/**
 * Put taggings
 *
 * @returns {Object}
 */
export function putTaggings(taggings) {
  return {
    type: ActionTypes.PUT_TAGGINGS,
    payload: taggings,
  }
}

/**
 * Put tags
 *
 * @returns {Object}
 */
export function putTags(tags) {
  return {
    type: ActionTypes.PUT_TAGS,
    payload: tags,
  }
}

/**
 * Load tags
 *
 * @returns {Object}
 */
export function loadTags() {
  return {
    type: ActionTypes.LOAD_TAGS,
  }
}

/**
 * Post taggings
 *
 * @returns {Object}
 */
export function postTaggings() {
  return {
    type: ActionTypes.POST_TAGGINGS,
  }
}

/**
 * Post taggings pending
 *
 * @returns {Object}
 */
export function postTaggingsPending() {
  return {
    type: ActionTypes.POST_TAGGINGS_PENDING,
  }
}

/**
 * Post taggings fulfilled
 *
 * @returns {Object}
 */
export function postTaggingsFulfilled() {
  return {
    type: ActionTypes.POST_TAGGINGS_FULFILLED,
  }
}

/**
 * Clear tags
 *
 * @returns {Object}
 */
export function clearTags() {
  return {
    type: ActionTypes.CLEAR_TAGS,
  }
}

/**
 * Patch tags
 *
 * @returns {Object}
 */
export function patchTags() {
  return {
    type: ActionTypes.PATCH_TAGS,
  }
}

/**
 * Edit tags
 *
 * @returns {Object}
 */
export function editTags(payload) {
  return {
    type: ActionTypes.EDIT_TAGS,
    payload,
  }
}
