import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { ModalBody } from '../../StoresPage/CreateEditStoreModal/index.screen'
import ProgressIndicatorModal from '../../../components/ProgressIndicatorModal'
import ErrorModal from '../../../components/ErrorModal'
import { Icon } from '../PingHub/index.screen'

import CloseIcon from '../../../images/svg/buttons/close.svg'

const HeaderButtonsContainer = styled.div`
  justify-content: flex-end;
  margin-bottom: 8px;
`

const HubStatusScreen = ({
  id,
  hub,
  statusFetching,
  statusFetchError,
  onClose,
}) => {
  if (statusFetching) return <ProgressIndicatorModal text="Status Loading..." />

  if (statusFetchError) {
    return <ErrorModal text={`Fetch Error: ${statusFetchError.message}`} />
  }
  if (hub.otauStatus === false) {
    return <ErrorModal text="HUB is Offline" />
  }
  if (!hub.otauStatus) return null

  return (
    <div className="modal-content">
      <div className="modal-header border-bottom">
        <h5 className="modal-title" id="exampleModalLongTitle">
          {`${hub.identifier} Status`}
        </h5>
        <HeaderButtonsContainer>
          <Icon
            src={CloseIcon}
            onClick={() => {
              $(`#modal-${id}`).modal('hide') // eslint-disable-line
              onClose()
            }}
          />
        </HeaderButtonsContainer>
      </div>
      <div>
        <ModalBody className="modal-body">
          <div>
            <pre>{hub.otauStatus}</pre>
          </div>
        </ModalBody>
      </div>
    </div>
  )
}

HubStatusScreen.propTypes = {
  id: PropTypes.string.isRequired,
  hub: PropTypes.object.isRequired,
  statusFetching: PropTypes.bool,
  statusFetchError: PropTypes.object,
  onClose: PropTypes.func.isRequired,
}

export default HubStatusScreen
