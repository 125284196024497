import alarm from 'images/issues/alarming/alarm.png'
import ActionAlert from './ActionAlert'
import NotificationAlert from './NotificationAlert'

const alertTypes = {
  ALARMING: {
    // backgroundColor: 'rgba(255,232,204,0.9)', //orange
    backgroundColor: 'rgba(250,210,211,0.9)', //red
    icon: alarm,
    component: ActionAlert,
  }
}
export { alertTypes }
