import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ReactTable from 'react-table'
import checkboxHOC from 'react-table/lib/hoc/selectTable'
import Select from '../../../components/Select'

import {
  FixtureRuleSourceAttributesModel,
  sourceAttrName,
  conditionName,
  PARAMETER_TYPE_CONTROL,
} from './ruleConstants'
import {
  ColumnContainer,
  Column,
} from '../../StoresPage/CreateEditStoreModal/index.screen'
import {
  CellContainer,
  renderFilterInput,
  renderHeaderCell,
} from '../../UsersPage/index.screen'
import { fullTextFilter, showHidablePopover } from '../../../utils/utils'
import TrComponent from '../../../components/TrComponent'
import Chance from 'chance'
import { renderToString } from 'react-dom/server'

const chance = new Chance()

export const ModalBody = styled.div`
  padding: 10px 10px 10px 10px;
`

export const SelectionButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const TableWithButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const NumberOfPositionsContainer = styled.div`
  margin-top: 3px;
  display: flex;
  flex-direction: column;
  width: 100%;
`

const CheckboxTable = checkboxHOC(ReactTable)
export const StyledTable = styled(CheckboxTable)`
  max-height: 365px;
  width: 300px;
`

/* eslint-disable react/prop-types */
/**
 * Renders options for the rule type
 *
 * @returns {*}
 */
const renderRuleTypeOptions = () =>
  Object.keys(FixtureRuleSourceAttributesModel).map((attr) => (
    <option key={attr} value={attr}>
      {sourceAttrName[attr]}
    </option>
  ))

/**
 * Renders options for the rule conditions
 *
 * @param ruleType
 * @returns {*}
 */
const renderConditionOptions = (ruleType) => {
  const sourceAttribute = FixtureRuleSourceAttributesModel[ruleType] || {}
  const conditions = sourceAttribute.conditions || []

  return conditions.map((condition) => (
    <option key={condition} value={condition}>
      {conditionName[condition]}
    </option>
  ))
}

class FixtureRulesScreen extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      dataOnLeft: this.getData(props.products),
      dataOnRight: this.getData(props.selectedProducts),
      selectionOnLeft: [],
      selectionOnRight: [],
      selectAllLeft: false,
      selectAllRight: false,
    }
  }

  getData = (keys) =>
    (keys || []).map((item) => {
      const _id = chance.guid() // eslint-disable-line
      return {
        _id,
        ...item,
      }
    })

  isSelectedOnLeft = (key) => this.state.selectionOnLeft.includes(key)
  isSelectedOnRight = (key) => this.state.selectionOnRight.includes(key)

  toggleSelectionOnLeft = (key) => {
    let selection = [...this.state.selectionOnLeft]
    const keyIndex = selection.indexOf(key)
    if (keyIndex >= 0) {
      selection = [
        ...selection.slice(0, keyIndex),
        ...selection.slice(keyIndex + 1),
      ]
    } else {
      selection.push(key)
    }
    this.setState({ selectionOnLeft: selection })
  }

  toggleSelectionOnRight = (key) => {
    let selection = [...this.state.selectionOnRight]
    const keyIndex = selection.indexOf(key)
    if (keyIndex >= 0) {
      selection = [
        ...selection.slice(0, keyIndex),
        ...selection.slice(keyIndex + 1),
      ]
    } else {
      selection.push(key)
    }
    this.setState({ selectionOnRight: selection })
  }

  toggleAllLeft = () => {
    const selectAll = !this.state.selectAllLeft
    const selection = []
    if (selectAll) {
      const wrappedInstance = this.checkboxTableLeft.getWrappedInstance()
      const currentRecords = wrappedInstance.getResolvedState().sortedData
      currentRecords.forEach((item) => {
        selection.push(item._original._id) // eslint-disable-line
      })
    }
    this.setState({ selectAllLeft: selectAll, selectionOnLeft: selection })
  }

  toggleAllRight = () => {
    const selectAll = !this.state.selectAllRight
    const selection = []
    if (selectAll) {
      const wrappedInstance = this.checkboxTableRight.getWrappedInstance()
      const currentRecords = wrappedInstance.getResolvedState().sortedData
      currentRecords.forEach((item) => {
        selection.push(item._original._id) // eslint-disable-line
      })
    }
    this.setState({ selectAllRight: selectAll, selectionOnRight: selection })
  }

  onAddSelectedProducts = () => {
    const { onAddSelectedProducts } = this.props
    const { selectionOnLeft, dataOnLeft } = this.state

    if (selectionOnLeft.length === 0) return

    const dataOnRight = dataOnLeft.filter(({ _id }) =>
      selectionOnLeft.includes(_id)
    )

    onAddSelectedProducts(dataOnRight)
    this.setState({
      selectionOnRight: [...selectionOnLeft],
      dataOnRight,
    })
  }

  onRemoveSelectedProducts = () => {
    const { onRemoveSelectedProducts } = this.props
    const { dataOnRight, selectionOnRight } = this.state

    if (selectionOnRight.length === 0) return

    const uncheckedProducts = dataOnRight.filter(
      ({ _id }) => !selectionOnRight.includes(_id)
    )

    onRemoveSelectedProducts(uncheckedProducts)
    this.setState({
      dataOnRight: uncheckedProducts,
    })
  }

  onRemoveAllSelectedProducts = () => {
    const { onRemoveSelectedProducts } = this.props
    onRemoveSelectedProducts([])
    this.setState({
      selectionOnRight: [],
      dataOnRight: [],
    })
  }

  renderProductsList = () => {
    const {
      dataOnLeft,
      dataOnRight,
      selectAllLeft,
      selectAllRight,
    } = this.state

    const manufacturerColumn = {
      id: 'manufacturer',
      Header: renderHeaderCell('Manufacturer'),
      accessor: 'manufacturer.name',
      width: 130,
      Filter: ({ filter, onChange }) =>
        renderFilterInput({
          placeholder: 'Filter',
          filter,
          onChange,
        }),
    }
    const nameColumn = {
      Header: renderHeaderCell('Name'),
      accessor: 'name',
      Filter: ({ filter, onChange }) =>
        renderFilterInput({
          placeholder: 'Filter',
          filter,
          onChange,
        }),
      Cell: ({ original: { name } }) => {
        const component = () => <CellContainer>{name}</CellContainer>
        const html = renderToString(component())
        let element
        return (
          <div
            ref={(ref) => (element = ref)}
            style={{ overflow: 'hidden' }}
            role="button"
            tabIndex="-1"
            onClick={() => showHidablePopover(element, html, 'focus')}
            onMouseEnter={() => showHidablePopover(element, html, 'hover')}
          >
            {component()}
          </div>
        )
      },
    }

    const checkboxPropsLeft = {
      selectAll: selectAllLeft,
      isSelected: this.isSelectedOnLeft,
      toggleSelection: this.toggleSelectionOnLeft,
      toggleAll: this.toggleAllLeft,
      selectType: 'checkbox',
      getTrProps: (s, r) => {
        const selected = this.isSelectedOnLeft(((r || {}).original || {})._id)
        return {
          style: {
            backgroundColor: selected ? 'lightgreen' : 'inherit',
          },
        }
      },
    }

    const checkboxPropsRight = {
      selectAll: selectAllRight,
      isSelected: this.isSelectedOnRight,
      toggleSelection: this.toggleSelectionOnRight,
      toggleAll: this.toggleAllRight,
      selectType: 'checkbox',
      getTrProps: (s, r) => {
        const selected = this.isSelectedOnRight(((r || {}).original || {})._id)
        return {
          style: {
            backgroundColor: selected ? 'lightgreen' : 'inherit',
          },
        }
      },
    }

    const allProductsColumns = [manufacturerColumn, nameColumn]

    const selectedProductsColumns = [manufacturerColumn, nameColumn]

    return (
      <div className="d-flex">
        <StyledTable
          ref={(r) => {
            this.checkboxTableLeft = r
          }}
          className="full-screen"
          filterable
          minRows={10}
          data={dataOnLeft}
          columns={allProductsColumns}
          defaultFilterMethod={fullTextFilter}
          nextText={'>'}
          previousText={'<'}
          rowsText={''}
          showPageSizeOptions={false}
          TrComponent={TrComponent}
          {...checkboxPropsLeft}
        />

        <SelectionButtonsContainer>
          <button
            type="button"
            className="btn btn-primary"
            onClick={this.onAddSelectedProducts}
          >
            Add Selected
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={this.onRemoveSelectedProducts}
          >
            Delete Selected
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={this.onRemoveAllSelectedProducts}
          >
            Remove All
          </button>
        </SelectionButtonsContainer>

        <TableWithButtonContainer>
          <StyledTable
            ref={(r) => {
              this.checkboxTableRight = r
            }}
            className="full-screen"
            minRows={10}
            filterable
            data={dataOnRight}
            columns={selectedProductsColumns}
            defaultFilterMethod={fullTextFilter}
            nextText={'>'}
            previousText={'<'}
            rowsText={''}
            showPageSizeOptions={false}
            TrComponent={TrComponent}
            {...checkboxPropsRight}
          />
        </TableWithButtonContainer>
      </div>
    )
  }

  renderParamControl = () => {
    const { ruleType, onChange, numberOfPositions } = this.props
    const sourceAttribute = FixtureRuleSourceAttributesModel[ruleType] || {}
    const paramTypeControl = sourceAttribute.paramTypeControl

    switch (paramTypeControl) {
      case PARAMETER_TYPE_CONTROL.number:
        return (
          <NumberOfPositionsContainer>
            <label htmlFor="firstName">Number of Positions</label>
            <input
              type="number"
              className="form-control"
              style={{ display: 'flex', flex: 1 }}
              id="numberOfPositions"
              placeholder=""
              required=""
              defaultValue={numberOfPositions}
              onChange={({ target: { value } }) =>
                onChange({ numberOfPositions: value })
              }
            />
          </NumberOfPositionsContainer>
        )
      case PARAMETER_TYPE_CONTROL.products:
        return this.renderProductsList()
      default:
        return null
    }
  }

  renderRuleConfigurationOptions = () => {
    const { ruleType, condition, onChange } = this.props

    if (!ruleType) return null
    const sourceAttribute = FixtureRuleSourceAttributesModel[ruleType] || {}
    const paramTypeControl = sourceAttribute.paramTypeControl

    switch (paramTypeControl) {
      case PARAMETER_TYPE_CONTROL.number:
        return (
          <div className="form-row">
            <ColumnContainer className="form-group col-md-6 bmd-form-group">
              <Column>
                <Select
                  name="condition"
                  value={condition}
                  label={'Condition'}
                  customStyle="pt-0"
                  onChange={(e) => onChange({ condition: e.target.value })}
                >
                  {renderConditionOptions(ruleType)}
                </Select>
              </Column>
            </ColumnContainer>
            <ColumnContainer className="form-group col-md-6 bmd-form-group">
              <Column>{this.renderParamControl()}</Column>
            </ColumnContainer>
          </div>
        )
      case PARAMETER_TYPE_CONTROL.products:
        return (
          <div>
            <div className="form-row">
              <Select
                name="condition"
                value={condition}
                label={'Condition'}
                customStyle="pt-0"
                onChange={(e) => onChange({ condition: e.target.value })}
              >
                {renderConditionOptions(ruleType)}
              </Select>
            </div>
            {this.renderParamControl()}
          </div>
        )
      default:
        return null
    }
  }

  renderMainContent = () => {
    const { ruleType, onChange } = this.props
    return (
      <React.Fragment>
        <div className="form-row">
          <Select
            name="ruleType"
            value={ruleType}
            label={'Rule Type'}
            customStyle="pt-0"
            onChange={(e) =>
              onChange({
                ruleType: e.target.value,
                condition: FixtureRuleSourceAttributesModel[e.target.value]
                  ? FixtureRuleSourceAttributesModel[e.target.value]
                    .conditions[0]
                  : null,
                param: null,
              })
            }
          >
            <option value={''}>Please select rule type...</option>
            {renderRuleTypeOptions()}
          </Select>
        </div>
        {this.renderRuleConfigurationOptions()}
      </React.Fragment>
    )
  }

  render() {
    const { id, onConfirm, onClose, onDelete } = this.props

    return (
      <div className="modal-content">
        <div className="modal-header border-bottom">
          <h5 className="modal-title" id="exampleModalLongTitle">
            Add fixture compliance rules
          </h5>
          <div>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                $(`#modal-${id}`).modal('hide') // eslint-disable-line
                onClose()
              }}
            >
              Cancel
            </button>
            {onDelete ? (
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  $(`#modal-${id}`).modal('hide') // eslint-disable-line
                  onDelete()
                }}
              >
                Delete
              </button>
            ) : null}
            <button
              type="button"
              className="btn btn-raised"
              onClick={() => {
                $(`#modal-${id}`).modal('hide') // eslint-disable-line
                onConfirm()
              }}
            >
              Save
            </button>
          </div>
        </div>
        <ModalBody className="modal-body">{this.renderMainContent()}</ModalBody>
      </div>
    )
  }
}

FixtureRulesScreen.propTypes = {
  id: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  ruleType: PropTypes.string,
  condition: PropTypes.string,
  products: PropTypes.array.isRequired,
  selectedProducts: PropTypes.array.isRequired,
  numberOfPositions: PropTypes.string.isRequired,
  onAddSelectedProducts: PropTypes.func.isRequired,
  onRemoveSelectedProducts: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
}

export default FixtureRulesScreen
