import { createSelector } from 'reselect'

const selectReports = state => state.get('reports')

const makeSelectReports = storeId =>
  createSelector(selectReports, reports => {
    const obj = reports.get('reports').toJS()

    if (storeId) {
      const storeReports = obj[(storeId || -1).toString()]
      return storeReports || []
    }

    let reportsArr = []
    Object.keys(obj).forEach(key => {
      const storeReports = obj[key]
      reportsArr = reportsArr.concat(storeReports)
    })
    return reportsArr
  })

const makeSelectReportsCombination = () =>
  createSelector(selectReports, reports => reports.get('combination'))

const makeSelectAsyncLoadingStatus = () =>
  createSelector(selectReports, reports => ({
    total: reports.get('storesToLoadCount'),
    currentName: (reports.get('currentStoreLoading') || {}).name,
    currentNumber: reports.get('currentIndexLoading') + 1,
  }))

const makeSelectAsyncLoadingCanceling = () =>
  createSelector(selectReports, reports =>
    reports.get('asyncLoadingCanceling')
  )

const makeSelectAsyncLoadingErrors = () =>
  createSelector(selectReports, reports => {
    let errors = []
    const obj = reports.get('errors').toJS()
    Object.keys(obj).forEach(key => {
      const storeError = obj[key]
      errors = errors.concat(storeError)
    })
    return errors
  })

const makeSelectReportsDate = () =>
  createSelector(selectReports, reports => reports.get('date'))

export {
  makeSelectReports,
  makeSelectReportsCombination,
  makeSelectReportsDate,
  makeSelectAsyncLoadingStatus,
  makeSelectAsyncLoadingCanceling,
  makeSelectAsyncLoadingErrors,
}
