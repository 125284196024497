import React from 'react'
import ReactTable from 'react-table'
import { FormattedMessage, injectIntl } from 'react-intl'
import styled from 'styled-components'

const CellContainer = styled.div`
  display: flex;
  align-items: center;
`

const OnlineIcon = styled.div`
  display: inline-block;
  width: 18pt;
  height: 18pt;
  border-radius: 50%;
  background-color: #08c124;
  margin: 0pt 8pt;
`

const OfflineIcon = styled(OnlineIcon)`
  background-color: #C10824;
`

const TextContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: start;
`

const StatusText = styled.div`
`

const SubText = styled.div`
  font-size: small;
  color: slategrey;
`

class IOTDashboardScreen extends React.PureComponent {
  _fetchData(reactTableState) {
    let query = { per: reactTableState.pageSize, page: reactTableState.page }
    this.props.onFilter(query)
  }

  render() {
    const {
      data,
      pages,
      loading,
      defaultPageSize,
    } = this.props
    const columns = this._createColumnSchema()

    return (
      <div>
        <ReactTable
          className="full-screen"
          columns={columns}
          data={data}
          manual
          multiSort={false}
          pages={pages}
          defaultPageSize={defaultPageSize}
          loading={loading}
          onFetchData={(state, instance) => this._fetchData(state, instance)}
        />
      </div>
    )
  }

  _createColumnSchema() {
    const { intl } = this.props
    return [{
      id: 'store',
      accessor: 'name',
      Header: intl.formatMessage({ id: 'stores.name' }),
      filterable: false,
      sortable: false,
    },{
      id: 'meshController',
      Header: intl.formatMessage({ id: 'containers.iotDashboard.meshController' }),
      filterable: false,
      sortable: false,
      Cell: MeshCell,
    },{
      id: 'doorController',
      Header: intl.formatMessage({ id: 'containers.iotDashboard.doorController' }),
      filterable: false,
      sortable: false,
      Cell: DoorCell,
    },{
      id: 'pinpad',
      Header: intl.formatMessage({ id: 'containers.iotDashboard.pinpad' }),
      filterable: false,
      sortable: false,
      Cell: PinpadCell,
    }]
  }
}

const PinpadCell = function({ original: { pinpad } }) {
  return StatusCell(pinpad)
}

const MeshCell = function({ original: { meshController } }) {
  return StatusCell(meshController)
}

const DoorCell = function({ original: { doorController } }) {
  return StatusCell(doorController)
}

const StatusCell = function(lode) {
  if (!lode) return (<></>)

  const content = lode.online ? (
    <>
      <OnlineIcon />
      <TextContainer>
        <FormattedMessage id="containers.iotDashboard.online" />
      </TextContainer>
    </>
  ) : (
    <>
      <OfflineIcon />
      <TextContainer>
        <StatusText><FormattedMessage id="containers.iotDashboard.offline" /></StatusText>
        <SubText><FormattedMessage id="containers.iotDashboard.since" values={{ date: lode.since }} /></SubText>
      </TextContainer>
    </>
  )

  return (<CellContainer>{content}</CellContainer>)
}

export default injectIntl(IOTDashboardScreen)
