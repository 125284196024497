import React from 'react'
import PropTypes from 'prop-types'

import Checkbox from '../../../components/Checkbox'
import TableFilter from './TableFilter'

class CreateIndexModalScreen extends React.PureComponent {
  constructor(props) {
    super(props)

    const { selectedStoresIds: ids } = props
    const selectedRegionsIds =
      typeof ids !== 'undefined'
        ? this.regionsIdsForStoresIds(ids)
        : this.selectedRegionsIds(props.regionsFilter)
    const selectedStoresIds =
      typeof ids !== 'undefined' ? ids : this.selectedStoresIds(true)
    const isOrganizationChecked = this.organizationCheckedState(
      selectedRegionsIds
    )

    this.state = {
      isOrganizationChecked,
      selectedRegionsIds,
      selectedStoresIds,
    }
  }

  allRegions = () => {
    const { regions } = this.props
    return regions
  }

  regionsFilterItems = () => {
    const regions = this.allRegions().map(({ id, name }) => ({
      id,
      name,
    }))
    regions.push({ id: 0, name: 'No Region' })
    return regions
  }

  storesFilterItems = () => {
    const { stores } = this.props
    return stores.map((s) => {
      s.regionId = s.regionId ? s.regionId : 0
      return s
    })
  }

  selectedRegionsIds = (setAllChecked) => {
    if (setAllChecked) {
      return this.regionsFilterItems().map(({ id }) => id)
    }

    return []
  }

  selectedStoresIds = (setAllChecked) => {
    if (setAllChecked) {
      return this.storesFilterItems().map(({ id }) => id)
    }

    return []
  }

  storesIdsForRegionsIds = (ids) =>
    this.storesFilterItems()
      .filter(({ regionId }) => ids.includes(regionId))
      .map(({ id }) => id)

  regionsIdsForStoresIds = (ids) => {
    if (this.props.regionsFilter) {
      return this.storesFilterItems()
        .filter(({ id }) => ids.includes(id))
        .map(({ regionId }) => regionId)
        .filter((regionId, pos, arr) => arr.indexOf(regionId) === pos)
        .filter((id) => id !== null)
    }

    return []
  }

  onChangeOrganizationFilter = (event) => {
    const isOrganizationChecked = event.target.checked
    this.setState({
      isOrganizationChecked,
      selectedRegionsIds: this.selectedRegionsIds(isOrganizationChecked),
      selectedStoresIds: this.selectedStoresIds(isOrganizationChecked),
    })
  }

  onChangeSelectedRegions = (ids) => {
    const selectedStoresIds = ids.length ? this.storesIdsForRegionsIds(ids) : []
    const isOrganizationChecked = this.organizationCheckedState(ids)
    this.setState({
      selectedRegionsIds: ids,
      selectedStoresIds,
      isOrganizationChecked,
    })
  }

  onChangeSelectedStores = (ids) => {
    const selectedRegionsIds = this.regionsIdsForStoresIds(ids)
    const isOrganizationChecked = this.organizationCheckedState(
      selectedRegionsIds
    )
    this.setState({
      selectedRegionsIds,
      selectedStoresIds: ids,
      isOrganizationChecked,
    })
  }

  organizationCheckedState = (regionsIds) =>
    this.props.organizationFilter &&
    regionsIds.filter((v) => v).length === this.allRegions().length

  storesCol = () => {
    const { regionsFilter } = this.props
    if (regionsFilter) {
      return 'col-4'
    }
    return 'col'
  }

  extendedTable = () => {
    const { organizationFilter, regionsFilter } = this.props
    return organizationFilter && regionsFilter
  }

  renderContent = () => {
    const {
      organization: { organizations },
      organizationFilter,
      regionsFilter,
      dateFilters,
    } = this.props
    const { combination } = this.props
    const {
      isOrganizationChecked,
      selectedRegionsIds,
      selectedStoresIds,
    } = this.state
    const organization = organizations[0]
    return (
      <div className="container">
        <div className="row border-bottom">
          <div className="col-md-5">Select Type of Events to include:</div>
          <div className="col">
            <div className="form-inline">{combination}</div>
          </div>
        </div>
        {dateFilters && (
          <div className="row border-bottom">
            <div className="col-md-5 pt-3 pb-3">
              <p className="mb-0">Select Dates Range to include:</p>
              <p className="mb-0 small">
                Note: You can select up to 7 days range of data to be displayed
              </p>
            </div>
            <div className="col">
              <div className="pt-3 pb-3 d-flex">{dateFilters}</div>
            </div>
          </div>
        )}
        <div className="row">
          {this.extendedTable() && (
            <div className="col p-3 text-center">
              {organizationFilter && <strong>Organization</strong>}
            </div>
          )}
          {this.extendedTable() && (
            <div className="col p-3 text-center">
              {regionsFilter && <strong>Regions</strong>}
            </div>
          )}
          <div className={`${this.storesCol()} p-3 text-center`}>
            <strong>Stores</strong>
          </div>
        </div>
        <div className="row">
          {this.extendedTable() && (
            <div className="col">
              {organizationFilter && (
                <Checkbox
                  name={'organization-checkbox'}
                  label={organization.name}
                  checked={isOrganizationChecked}
                  disabled={false}
                  onChange={this.onChangeOrganizationFilter}
                />
              )}
              <div className="mt-5">
                {organizationFilter && (
                  <p className="text-muted">
                    <small>
                      <em>
                        - Selecting Organization will automatically select all
                        Stores in whole Organization
                      </em>
                    </small>
                  </p>
                )}
                {regionsFilter && (
                  <p className="text-muted">
                    <small>
                      <em>
                        - Selecting Region will automatically select all Stores
                        in this Region
                      </em>
                    </small>
                  </p>
                )}
              </div>
            </div>
          )}
          {this.extendedTable() && (
            <div className="col">
              {regionsFilter && (
                <TableFilter
                  items={this.regionsFilterItems(isOrganizationChecked)}
                  selectedIds={selectedRegionsIds}
                  onChange={this.onChangeSelectedRegions}
                />
              )}
            </div>
          )}
          <div className={this.storesCol()}>
            <TableFilter
              items={this.storesFilterItems(isOrganizationChecked)}
              selectedIds={selectedStoresIds}
              onChange={this.onChangeSelectedStores}
            />
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { onClose, onConfirm, id, combinationValues } = this.props
    const isApplyDisabled = !(
      this.state.selectedStoresIds.length &&
      Object.values(combinationValues).filter((v) => v).length
    )

    return (
      <div className="modal-content">
        <div className="modal-header border-bottom">
          <h5 className="modal-title">Select Filters</h5>
          <div>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                $(`#modal-${id}`).modal('hide') // eslint-disable-line
                onClose()
              }}
            >
              Cancel
            </button>
            <button
              disabled={isApplyDisabled}
              type="button"
              className="btn btn-primary btn-raised"
              onClick={() => {
                $(`#modal-${id}`).modal('hide') // eslint-disable-line
                onConfirm(this.state.selectedStoresIds)
              }}
            >
              Apply
            </button>
          </div>
        </div>
        <div className="modal-body">{this.renderContent()}</div>
      </div>
    )
  }
}

export default CreateIndexModalScreen

CreateIndexModalScreen.propTypes = {
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  combinationValues: PropTypes.object,
  organization: PropTypes.object,
  regions: PropTypes.array,
  stores: PropTypes.array,
  combination: PropTypes.object.isRequired,
  dateFilters: PropTypes.object,
  organizationFilter: PropTypes.bool.isRequired,
  regionsFilter: PropTypes.bool.isRequired,
  selectedStoresIds: PropTypes.array,
}
