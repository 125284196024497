import React from 'react'
import PropTypes from 'prop-types'
import StoresScreen from './stores.screen'
import SubmitWithHint, {
  defaultRequiredMessage,
} from '../../../components/SubmitWithHint'
import { Overlay } from '../../../styles/styled'
import ProgressIndicator from '../../../components/ProgressIndicator'
import { DeviceConfigurationList } from '../../../components/DeviceConfiguration'

export class CreateEditRegionScreen extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isCreateNewRegion: true,
      showValidationHint: false,
    }
  }

  componentWillMount() {
    if (this.name && this.name.popover) {
      this.name.popover('dispose')
    }
  }

  isValid = () => {
    if (this.formRef.checkValidity()) {
      this.setState({ showValidationHint: false })
      return true
    } else {
      this.formRef.reportValidity()
      this.setState({ showValidationHint: true })
      return false
    }
  }

  highlightInvalid = () => {
    if (!this.storeImg.isValid()) {
      this.storeImg.showInvalidMessage()
    }
  }

  setServerErrorDismiss(element) {
    const $element = $(element) // eslint-disable-line
    $element.off('keyup').on('keyup', () => {
      element.setCustomValidity('')
      $element.attr('data-content', '')
      $element.popover('dispose')
    })
  }

  setServerError(element, msg) {
    element.setCustomValidity(msg)
    const $element = $(element) // eslint-disable-line
    $element.attr('data-placement', 'bottom')
    $element.attr('data-content', msg)
    $element.popover('show')
    setTimeout(() => {
      $element.attr('data-content', '')
      $element.popover('dispose')
    }, 3000)
  }

  setValidationErrors(validationErrors) {
    if (validationErrors.name) {
      const msg = validationErrors.name[0]
      const element = this.name
      this.setServerError(element, msg)
    }
  }

  setServerValidity(error) {
    if (error && error.error) {
      const validationErrors = error.error.validationErrors
      if (validationErrors) {
        this.setValidationErrors(validationErrors)
        if (validationErrors.name) {
          const msg = validationErrors.name[0]
          const element = this.name
          this.setServerError(element, msg)
        }
      } else {
        // another server error
        console.log(error)
      }
    }
  }

  onChangeStores = (storeIds) => {
    const { onChange, region, stores } = this.props
    const existingStoreIds = region.stores
      ? region.stores.map(({ id }) => id)
      : []
    const removeStoreIds = existingStoreIds.filter(
      (id) => !storeIds.includes(id)
    )
    const addStoreIds = storeIds.filter((id) => !existingStoreIds.includes(id))

    const assignedStores = stores
      .filter(({ id }) => addStoreIds.includes(id))
      .map((store) => Object.assign({}, store, { regionId: region.id }))

    const removedStores = stores
      .filter(({ id }) => removeStoreIds.includes(id))
      .map((store) => Object.assign({}, store, { regionId: null }))

    onChange({ assignedStores, removedStores })
  }

  close = () => {
    const { id, onClose } = this.props
    $(`#modal-${id}`).modal('hide') // eslint-disable-line
    onClose()
  }

  renderFieldsCreateEditRegion = () => {
    const { onChange, region } = this.props
    const { name } = region
    const promptRegionText = name ? `Region "${name}"` : 'New Region'
    const promptActionText = name ? 'Save Region' : 'Create Region'
    return (
      <React.Fragment>
        <div className="row mb-4">
          <div className="form-group col-6 bmd-form-group pt-1">
            <label htmlFor="regionName">Name</label>
            <input
              ref={(ref) => {
                this.name = ref
                this.setServerErrorDismiss(ref)
              }}
              type="text"
              className="form-control"
              id="regionName"
              required
              defaultValue={name}
              onChange={(e) => onChange({ name: e.target.value })}
              onInvalid={defaultRequiredMessage}
              onInput={defaultRequiredMessage}
            />
            <div className="invalid-feedback">Please enter a valid name.</div>
          </div>
        </div>
        <div className="row mb-4">
          <DeviceConfigurationList
            resourceType="Region"
            resourceId={region.id}
          />
        </div>
        <div className="row mb-4">
          <div className="col">{`Select Stores to include in ${promptRegionText} and click ${promptActionText}`}</div>
        </div>
      </React.Fragment>
    )
  }

  /* eslint-disable no-undef */
  renderHeaderCreateEditRegion = () => {
    const { title, confirmText } = this.props
    return (
      <div className="modal-header">
        <h5 className="modal-title">{title}</h5>
        <div>
          <SubmitWithHint
            text={confirmText}
            showHint={this.state.showValidationHint}
            onClick={this.isValid}
            onFocus={this.isValid}
          />
          <button
            type="button"
            className="btn btn-primary"
            onClick={this.close}
          >
            Close
          </button>
        </div>
      </div>
    )
  }

  render() {
    const { onConfirm, stores, region, regionEditLoading } = this.props
    const { isCreateNewRegion } = this.state
    return (
      <div className="modal-content">
        <form
          ref={(ref) => (this.formRef = ref)}
          style={{ textAlign: 'left' }}
          className="needs-validation was-validated"
          noValidate
          onSubmit={(e) => {
            e.preventDefault()
            if (this.isValid() && isCreateNewRegion) {
              onConfirm()
            }
          }}
        >
          {this.renderHeaderCreateEditRegion()}
          <div className="modal-body">
            <div className="container">
              {this.renderFieldsCreateEditRegion()}
              <StoresScreen
                selectedStoreIds={
                  region.stores ? region.stores.map(({ id }) => id) : []
                }
                stores={stores}
                onStore={this.onChangeStores}
              />
            </div>
          </div>
        </form>
        {regionEditLoading && (
          <React.Fragment>
            <Overlay />
            <ProgressIndicator />
          </React.Fragment>
        )}
      </div>
    )
  }
}

CreateEditRegionScreen.propTypes = {
  onChange: PropTypes.func,
  id: PropTypes.string.isRequired,
  stores: PropTypes.array.isRequired,
  region: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  confirmText: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  regionEditLoading: PropTypes.bool,
}

export default CreateEditRegionScreen
