import { SOURCE_ATTRIBUTES } from '../TemplateFixture/modals/ruleConstants'

export const figureType = {
  extendedLeftQuarterCircle: 'Extended Left Quarter Circle',
  extendedRightQuarterCircle: 'Extended Right Quarter Circle',
  quarterCircle: 'Quarter Circle',
  circle: 'Circle',
  custom: 'Custom Rectangle',
  table: 'Custom Rectangle',
  square: 'Square',
  narrowRectangle: 'Narrow 1-Row Rectangle',
  wideRectangle: 'Wide 2-Row Rectangle',
  hurricane: 'Extended Quarter Circle',
}

export const figureSize = {
  extraSmall: 'Extra Small',
  small: 'Small',
  medium: 'Medium',
  large: 'Large',
  veryLarge: 'Very Large',
  custom: 'Custom',
}

export const isSourceAttribute = (attribute) => {
  switch (attribute) {
    case SOURCE_ATTRIBUTES.security_device_type:
    case SOURCE_ATTRIBUTES.security_device_assigned:
    case SOURCE_ATTRIBUTES.product_ids:
    case SOURCE_ATTRIBUTES.live_product_ids:
    case SOURCE_ATTRIBUTES.port_products:
    case SOURCE_ATTRIBUTES.product_live_products:
      return true
    default:
      return false
  }
}

export const isPortRule = (attribute) => {
  switch (attribute) {
    case SOURCE_ATTRIBUTES.port_products:
    case SOURCE_ATTRIBUTES.product_live_products:
      return true
    default:
      return false
  }
}
