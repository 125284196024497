/**
 *
 * Region Preloader for Modal
 *
 */

import React from 'react'
import ContentLoader from 'react-content-loader'
import PropTypes from 'prop-types'
import uuid from 'uuid'

function RegionModalPreloader({ regionDetails }) {
  const RegionModalPreloader = (props) => (
    <ContentLoader
      height={620}
      width={800}
      speed={2}
      primaryColor="#f3f3f3"
      secondaryColor="#ecebeb"
      {...props}
    >
      {regionDetails ? (
        <React.Fragment>
          <rect x="30" y="30" rx="0" ry="0" width="200" height="40" />
          <rect x="650" y="30" rx="0" ry="0" width="130" height="40" />
          <rect x="510" y="30" rx="0" ry="0" width="130" height="40" />
          <rect x="240" y="30" rx="0" ry="0" width="130" height="40" />

          <rect x="30" y="120" rx="6" ry="6" width="170" height="15" />
          <rect x="30" y="160" rx="6" ry="6" width="170" height="15" />
          <rect x="210" y="120" rx="6" ry="6" width="170" height="15" />
          <rect x="400" y="120" rx="6" ry="6" width="360" height="15" />
          <rect x="210" y="160" rx="6" ry="6" width="170" height="15" />
          <rect x="400" y="160" rx="6" ry="6" width="360" height="15" />

          <rect x="0" y="80" rx="0" ry="0" width="800" height="2" />
          <rect x="30" y="205" rx="0" ry="0" width="740" height="2" />

          <rect x="50" y="215" rx="0" ry="0" width="20" height="20" />
          <rect x="90" y="220" rx="6" ry="6" width="680" height="15" />
          <rect x="30" y="245" rx="0" ry="0" width="740" height="2" />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <rect x="30" y="40" rx="6" ry="6" width="250" height="15" />
          <rect x="650" y="30" rx="0" ry="0" width="120" height="40" />
          <rect x="510" y="30" rx="0" ry="0" width="120" height="40" />
          <rect x="30" y="100" rx="6" ry="6" width="160" height="15" />
          <rect x="30" y="150" rx="6" ry="6" width="280" height="15" />
          <rect x="30" y="200" rx="6" ry="6" width="280" height="15" />
          <rect x="30" y="245" rx="0" ry="0" width="740" height="2" />
        </React.Fragment>
      )}

      {Array(9)
        .fill('')
        .map((e, i) => (
          <React.Fragment key={`loader_${uuid.v4()}`}>
            <rect
              x="50"
              y={255 + 40 * i}
              rx="0"
              ry="0"
              width="20"
              height="20"
            />
            <rect
              x="90"
              y={260 + 40 * i}
              rx="6"
              ry="6"
              width="680"
              height="15"
            />
            <rect
              x="30"
              y={285 + 40 * i}
              rx="0"
              ry="0"
              width="740"
              height="2"
            />
          </React.Fragment>
        ))}
    </ContentLoader>
  )
  return <RegionModalPreloader />
}

RegionModalPreloader.propTypes = {
  regionDetails: PropTypes.bool,
}

export default RegionModalPreloader
