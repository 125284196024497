import { createSelector } from 'reselect'
const selectApp = (state) => state.get('app')

const makeSelectRegions = () =>
  createSelector(selectApp, (app) => app.get('regions'))

const makeSelectFailed = () =>
  createSelector(selectApp, (app) => app.get('regionsFailed'))

const makeSelectLoading = () =>
  createSelector(selectApp, (app) => app.get('regionsLoading'))

export { makeSelectRegions, makeSelectFailed, makeSelectLoading }
