/**
 *
 * Input File
 * TODO: Do not hesitate to improve me
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import styles from './css/style.css'
import EditDisabled from '../EditDisabled'

class InputFile extends React.PureComponent {
  /* eslint-disable no-undef */
  constructor(props) {
    super(props)
    this.state = {}
  }

  onChange = (e) => {
    const { onChange } = this.props
    onChange(e)
    this.setState({ fileName: (e.target.files[0] || {}).name })
  }

  addFocusClass = () => {
    this.formGroup.classList.add('is-focused')
  }

  removeFocusClass = () => {
    if (this.isFilled(this.props.value)) return
    this.formGroup.classList.remove('is-focused')
  }

  isFilled = (value) => !(value === null || value === undefined || value === '')

  render() {
    const {
      name,
      defaultFileName,
      label,
      onClick,
      type,
      value,
      readOnly,
      disabled,
      labelWidth,
    } = this.props
    const { fileName } = this.state

    return (
      <div
        className={`form-group bmd-form-group ${
          this.isFilled(value) ? 'is-focused' : null
        } ${styles.inputWrapper} ${readOnly ? styles.readonly : null} ${
          onClick ? styles.clickable : null
        }`}
        ref={(i) => {
          this.formGroup = i
        }}
      >
        <div id="upload_button" style={{ display: 'inline-block' }}>
          <label
            style={{ display: 'flex', alignItems: 'center' }}
            htmlFor={name}
          >
            <input
              style={{ display: 'none' }}
              key={name}
              type={type}
              className="form-control"
              id={name}
              defaultValue={value}
              onClick={onClick}
              onChange={this.onChange}
              onFocus={this.addFocusClass}
              onBlur={this.removeFocusClass}
              ref={(i) => {
                this.input = i
              }}
              disabled={disabled}
              readOnly={readOnly}
            />
            <div style={{ display: 'inline-block', paddingRight: '10px' }}>
              <EditDisabled
                header={label}
                text={fileName || defaultFileName || ''}
                grey
                labelWidth={labelWidth}
              />
            </div>
            <span className="btn btn-raised">Upload</span>
          </label>
        </div>
      </div>
    )
  }
}

InputFile.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.any,
  defaultFileName: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  labelWidth: PropTypes.number,
}

InputFile.defaultProps = {
  type: 'file',
}

export default InputFile
