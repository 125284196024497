import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { fetchMinions } from './actions'
import injectReducer from '../../utils/injectReducer'
import injectSaga from '../../utils/injectSaga'
import hubsReducer from './reducer'
import hubsSagas from './sagas'
import HubsListScreen from './index.screen'
import {
  makeSelectMinions,
  makeSelectFailed,
  makeSelectLoading,
} from './selectors'
import ProgressIndicator from '../../components/ProgressIndicator'

class HubsPage extends React.Component {
  componentDidMount() {
    this.props.fetchMinions()
  }

  render() {
    const { hubs, fetchLoading, fetchFailed } = this.props
    if (fetchLoading) return <ProgressIndicator text={'Loading...'} />
    if (fetchFailed) {
      return <div>{`Fetch Error: ${JSON.stringify(fetchFailed)}`}</div>
    }

    return <HubsListScreen hubs={hubs} onHubSelection={(d) => console.log(d)} />
  }
}

HubsPage.propTypes = {
  fetchMinions: PropTypes.func,
  hubs: PropTypes.array,
  fetchLoading: PropTypes.bool,
  fetchFailed: PropTypes.object,
}

const mapStateToProps = createStructuredSelector({
  hubs: makeSelectMinions(),
  fetchLoading: makeSelectLoading(),
  fetchFailed: makeSelectFailed(),
})

const mapDispatchToProps = {
  fetchMinions,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)
const withStoresReducer = injectReducer({
  key: 'hubsList',
  reducer: hubsReducer,
})
const withStoresSaga = injectSaga({
  key: 'hubsList',
  saga: hubsSagas,
})

export default compose(withStoresReducer, withStoresSaga, withConnect)(HubsPage)
