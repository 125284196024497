import React from 'react'
import TooltipCell from '../TooltipCell'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'

/*
 * Displays a little help icon that shows some in-line help
 * text when you hover over it.
 *
 * Intended Use:
 * <HelpIcon helpKey='helpAllowManagerOverride'/>
 *
 * Params:
 *   helpAllowManagerOverride: a key in app/translations/[locale].json
 */

class HelpIcon extends React.PureComponent {
  text() {
    const { intl, helpKey } = this.props
    return intl.formatMessage({ id: `help.${helpKey}` })
  }
  render() {
    return (
      <TooltipCell
        text={this.text()}
        render={(tooltip) => (
          <div className="help-icon" ref={(ref) => tooltip.target(ref)} />
        )}
      />
    )
  }
}

HelpIcon.propTypes = {
  helpKey: PropTypes.string.isRequired,
}

export default injectIntl(HelpIcon)
