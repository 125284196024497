import React from 'react'
import Slider from 'react-slick'
import styled from 'styled-components'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { PropTypes } from 'prop-types'
import FloorTile from '../FloorTile'

const Container = styled.div`
  padding-left: 38px;
  padding-right: 20px;
`

const FloorsSelection = ({ floors, onClickFloor }) => {
  const renderFloorTile = (floor) => {
    const { id, name, previewImageUrl } = floor

    return (
      <FloorTile
        onClickFloor={onClickFloor}
        key={id}
        id={id}
        title={name}
        previewImgUrl={previewImageUrl}
      />
    )
  }

  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 3,
    swipeToSlide: true,
    initialSlide: 0,
    draggable: false,
  }

  return (
    <Container>
      <Slider {...settings}>
        {floors.map((floor) => renderFloorTile(floor))}
      </Slider>
    </Container>
  )
}

FloorsSelection.propTypes = {
  floors: PropTypes.array.isRequired,
  onClickFloor: PropTypes.func,
}

export default FloorsSelection
