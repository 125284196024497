/**
 * Try to create separate component to show Hub Ping state
 * and handle hub loading(by SelectedStore) and ping request
 */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { fetchHub, pingHub } from '../../App/actions'
import PingHubScreen from './index.screen'
import {
  makeSelectHubByStore,
  makeSelectIsHubFetchErrorByStoreId,
  makeSelectIsHubFetchingByStoreId,
} from '../../App/selectors'

class PingHub extends React.Component {
  /* eslint-disable no-undef */
  onPing = () => {
    const {
      fetchHub: doFetchHub,
      pingHub: doPingHub,
      storeId,
      hub,
    } = this.props
    if (!hub) {
      doFetchHub(storeId)
    } else {
      doPingHub(hub.identifier)
    }
  }

  render() {
    const { hub, isHubFetching, hubFetchError } = this.props

    return (
      <PingHubScreen
        hub={hub}
        isHubFetching={isHubFetching}
        hubFetchError={hubFetchError}
        onPing={this.onPing}
      />
    )
  }
}

PingHub.propTypes = {
  storeId: PropTypes.number.isRequired,
  hub: PropTypes.object,
  isHubFetching: PropTypes.bool,
  hubFetchError: PropTypes.object,
  fetchHub: PropTypes.func,
  pingHub: PropTypes.func,
}

const mapStateToProps = createStructuredSelector({
  hub: (state, ownProps) =>
    makeSelectHubByStore(ownProps.storeId)(state, ownProps),
  isHubFetching: (state, ownProps) =>
    makeSelectIsHubFetchingByStoreId(ownProps.storeId)(state, ownProps),
  hubFetchError: (state, ownProps) =>
    makeSelectIsHubFetchErrorByStoreId(ownProps.storeId)(state, ownProps),
})

const mapDispatchToProps = {
  fetchHub,
  pingHub,
}

export default connect(mapStateToProps, mapDispatchToProps)(PingHub)
