import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import { makeSelectLinkedFixturesForTemplate } from '../LinkedFixturesModal/selectors'
import DeleteTemplateWithLinkedFixturesScreen from './index.screen'
import injectReducer from '../../../utils/injectReducer'
import injectSaga from '../../../utils/injectSaga'
import { loadLinkedFixturesForTemplate } from '../LinkedFixturesModal/actions'
import linkedFixturesReducer from '../LinkedFixturesModal/reducer'
import linkedFixturesSaga from '../LinkedFixturesModal/sagas'
import ConfirmModal from '../../ModalContainer/ConfirmModal'

class DeleteTemplateWithLinkedFixtures extends React.Component {
  componentDidMount() {
    const { template: { id } } = this.props
    this.props.loadLinkedFixturesForTemplate(id)
  }

  onClose = () => {
    const { onClose, id } = this.props
    $(`#modal-${id}`).modal('hide') // eslint-disable-line
    onClose()
  }

  render() {
    const {
      id,
      template,
      linkedFixtures,
      onDeleteTemplate,
      onViewLinkedFixtures,
      onClose,
    } = this.props
    console.log('linkedFixtures', linkedFixtures)
    if (linkedFixtures.length == 0) {
      const id = 'confirmDeleteTemplate'
      return (
        <ConfirmModal
          id={id}
          cancelText={'Cancel'}
          confirmText={'Delete Template'}
          onClose={() => {
            this.onClose()
          }}
          onConfirm={() => {
            // $(`#modal-${id}`).modal('hide') // eslint-disable-line
            this.onClose()
            onDeleteTemplate(template)
          }}
        />
      )
    }

    return (
      <DeleteTemplateWithLinkedFixturesScreen
        id={id}
        template={template}
        onViewLinkedFixtures={onViewLinkedFixtures}
        onClose={onClose}
      />
    )
  }
}

DeleteTemplateWithLinkedFixtures.propTypes = {
  id: PropTypes.string.isRequired,
  template: PropTypes.object.isRequired,
  onViewLinkedFixtures: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

const mapStateToProps = createStructuredSelector({
  linkedFixtures: (state, ownProps) =>
    makeSelectLinkedFixturesForTemplate(ownProps.template.id)(state, ownProps),
})

const mapDispatchToProps = {
  loadLinkedFixturesForTemplate,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const withReducer = injectReducer({
  key: 'linkedFixtures',
  reducer: linkedFixturesReducer,
})
const withSaga = injectSaga({
  key: 'linkedFixtures',
  saga: linkedFixturesSaga,
})

export default compose(withReducer, withSaga, withConnect)(
  DeleteTemplateWithLinkedFixtures
)
