import { fromJS } from 'immutable'
import { ActionTypes } from '../constants'

const initialState = fromJS({
  patchSecuredProductFailed: null,
  patchSecuredProductLoading: false,
  selectedSecuredProduct: null,
  securedProductModalTypeToShow: null,
})

export default function securedProductEditReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case ActionTypes.SECURED_PRODUCT_DETAILS:
      return state
        .set('selectedSecuredProduct', payload.securedProduct)
        .set('securedProductModalTypeToShow', type)
    case ActionTypes.RESET_SECURED_PRODUCT_MANAGEMENT:
      return state.set('securedProductModalTypeToShow', null)
    case ActionTypes.PATCH_SECURED_PRODUCT_FULFILLED:
      return state
        .set('patchSecuredProductFailed', null)
        .set('patchSecuredProductLoading', false)
    case ActionTypes.PATCH_SECURED_PRODUCT_PENDING:
      return state
        .set('patchSecuredProductFailed', null)
        .set('patchSecuredProductLoading', true)
    case ActionTypes.PATCH_SECURED_PRODUCT_FAILED:
      return state
        .set('patchSecuredProductFailed', payload.error)
        .set('patchSecuredProductLoading', false)
    case ActionTypes.SECURED_PRODUCT_EDIT:
      return state.set('selectedSecuredProduct', {
        ...state.get('selectedSecuredProduct'),
        ...payload,
      })
    default:
      return state
  }
}
