import React from 'react'
import PropTypes from 'prop-types'
import EditSecuredProductDetailsScreen from './index.screen'

class EditSecuredProductDetails extends React.Component {
  render() {
    const {
      id,
      securedProduct,
      title,
      confirmText,
      onConfirm,
      onClose,
      onChange,
      manufacturers,
      products,
    } = this.props
    return (
      <EditSecuredProductDetailsScreen
        id={id}
        ref={(ref) => {
          this.editSecuredProductModal = ref
        }}
        securedProduct={securedProduct}
        title={title}
        confirmText={confirmText}
        onConfirm={onConfirm}
        onClose={onClose}
        onChange={onChange}
        manufacturers={manufacturers}
        products={products}
      />
    )
  }
}

EditSecuredProductDetails.propTypes = {
  id: PropTypes.string.isRequired,
  securedProduct: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  confirmText: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  manufacturers: PropTypes.array,
  products: PropTypes.array,
}

export default EditSecuredProductDetails
