import {
  mtiFixtureType,
  sviFigureType,
  fixtureRatio,
  getNearestAspectRatio,
  toSviCoordStructure,
  getAlertParams,
  alertTypes,
} from '../../utils/mtiCanvasUtils'
import {
  getExtendedQuarterCircleObj,
  getQuarterCircleObj,
  getCircleObj,
  getRectObj,
} from '../../components/AreaCanvas/objectUtils'
import {
  slicingFixtureSync,
  renderLabel,
  propertiesToInclude,
} from '../../components/AreaCanvas/fixture'
import { getHCObject } from '../FloorPage/objectUtils'

export function toFabricSync(
  area,
  screen,
  isStatic,
  isMobile,
  selectedId,
  prototypes,
  mtiJsclientShared
) {
  if (!area) return area
  const fixtures = (area || {}).fixtures || []
  const os = fixtures.map((f) =>
    toFabricFixtureSync(
      f,
      screen,
      isStatic,
      isMobile,
      prototypes,
      mtiJsclientShared
    )
  )

  return {
    canvasObject: { objects: os, selectedId },
  }
}

export function toFabricFixtureSync(
  o,
  screen,
  isStatic,
  isMobile,
  prototypes,
  mtiJsclientShared
) {
  if (!o) return o

  const type =
    (o.layoutPosition || {}).type === mtiFixtureType.extendedQuarterCircle
      ? sviFigureType.extendedLeftQuarterCircle
      : (o.layoutPosition || {}).type
  return toFabricFixtureSvi(
    {
      ...toSviCoordStructure(o),
      theta: 0,
      isFurniture: o.noninteractive,
      nameHasShown: o.shouldDisplayName,
      angle: (o.layoutPosition || {}).theta,
      sviSizeType: (o.layoutPosition || {}).size,
      sviType: type,
      type: type,
    },
    screen,
    isStatic,
    isMobile,
    prototypes,
    mtiJsclientShared
  )
}

export function toFabricFixtureSvi(
  o,
  screen,
  isStatic,
  isMobile,
  prototypes,
  mtiJsclientShared
) {
  if (!o) return o

  const {
    pointer,
    leftMoved,
    topMoved,
    alertType,
    alertCount,
  } = getFixtureParams(o, screen, isStatic, isMobile)

  const {
    id,
    name,
    positionsCount,
    nameHasShown,
    parentId,
    noninteractive,
    layoutPosition,
  } = minimizeObject(o)

  const fixtureObj = slicingFixtureSync(
    id,
    name,
    positionsCount,
    nameHasShown,
    parentId,
    noninteractive,
    layoutPosition,
    pointer,
    leftMoved,
    topMoved,
    isStatic,
    isMobile,
    alertType,
    alertCount,
    prototypes,
    mtiJsclientShared
  )
  const fabricObject = renderLabel(fixtureObj, isStatic).toObject(
    propertiesToInclude
  )

  return fabricObject
}

export function getFixtureParams(o, screen, isStatic) {
  const { alertType, alertCount } = getAlertParams(o.state)
  return getFixtureParamsSvi(
    toSviCoordStructure(o),
    screen,
    isStatic,
    alertType,
    alertCount
  )
}

export function getFixtureParamsSvi(
  o,
  screen,
  isStatic,
  alertType,
  alertCount
) {
  if (!o) return o
  const { width: w, height: h, xCenter: xC, yCenter: yC, angle } = o
  const fixtureWidth = w > 1 ? 0.2 : w
  const fixtureHeight = h > 0.666 ? 0.1 : h
  const fixtureXCenter = xC > 1 ? 0.5 : xC
  const fixtureYCenter = yC > 1 ? 0.333 : yC

  const { width: screenWidth } = screen
  const width = (fixtureWidth || 0.2) * screenWidth
  const height = (fixtureHeight || 0.1) * screenWidth
  const xCenter = (fixtureXCenter || 0.5) * screenWidth
  const yCenter = (fixtureYCenter || 0.3333) * screenWidth
  // Fixture has origin at center center
  const left = xCenter
  const top = yCenter

  const pointer = {
    x: left + width / 2,
    y: top + height / 2,
  }
  return {
    pointer,
    leftMoved: left,
    topMoved: top,
    angle,
    alertType,
    alertCount,
  }
}

function minimizeObject(o) {
  const {
    id,
    name,
    positionsCount,
    nameHasShown,
    parentId,
    noninteractive,
    layoutPosition: { height, size, theta, type, width, xCenter, yCenter } = {},
  } = o
  return {
    id,
    name,
    positionsCount,
    nameHasShown,
    parentId,
    noninteractive,
    layoutPosition: {
      height,
      size,
      theta,
      type,
      width,
      xCenter,
      yCenter,
    },
  }
}

/**
 * Create fixture figure prototypes
 *
 * @returns {object}
 */
export async function getPrototypes() {
  return {
    [sviFigureType.custom]: await getRectObj(),
    [sviFigureType.circle]: await getCircleObj(),
    [sviFigureType.quarterCircle]: await getQuarterCircleObj(),
    [sviFigureType.extendedRightQuarterCircle]: await getExtendedQuarterCircleObj(
      '180'
    ),
    [sviFigureType.extendedLeftQuarterCircle]: await getExtendedQuarterCircleObj(
      '270'
    ),
    alert: await getHCObject(alertTypes.alert),
    lowAlert: await getHCObject(alertTypes.lowAlert),
    warning: await getHCObject(alertTypes.warning),
    normal: await getHCObject(alertTypes.normal),
  }
}

// TODO: API: as for now we have had to hardcode this data as
// we need the correct aspect ratio for extended quarter circle
// which is used for proper scaling of it's figure.
// API returns height: 200 width: 400
export const extendedQuarterCircleWidth = 333
export const extendedQuarterCircleHeight = 509
export const extendedQuarterCircleAspectRatio =
  fixtureRatio.extendedQuarterCircle // 1.528

/**
 * Compute relative coordinates recursively
 * @param object an ORM object that has a layoutPosition
 * @param screen screen dimensions
 */
export const getGeometry = (object, screen) => {
  const { parent } = object

  if (!parent) {
    const width = screen.width
    const height = screen.height

    if (
      object.type === sviFigureType.square ||
      object.type === mtiFixtureType.circle ||
      object.type === sviFigureType.circle ||
      object.type === mtiFixtureType.quarterCircle
    ) {
      return {
        width: Math.min(width, height),
        height: Math.min(width, height),
        left: width / 2,
        top: height / 2,
      }
    }
    if (
      object.type === mtiFixtureType.extendedQuarterCircle ||
      object.type === sviFigureType.extendedRightQuarterCircle ||
      object.type === sviFigureType.extendedLeftQuarterCircle
    ) {
      return {
        width: screen.width / extendedQuarterCircleAspectRatio,
        height: screen.width,
        left: width / 2,
        top: height / 2,
      }
    }
    if (object.type === sviFigureType.narrowRectangle) {
      return {
        width: screen.width,
        height: screen.width / fixtureRatio.narrowRectangle,
        left: width / 2,
        top: height / 2,
      }
    }
    if (object.type === sviFigureType.wideRectangle) {
      return {
        width: screen.width,
        height: screen.width / fixtureRatio.wideRectangle,
        left: width / 2,
        top: height / 2,
      }
    }
    if (object.type === sviFigureType.custom) {
      const nearestAspect = getNearestAspectRatio(object)

      switch (nearestAspect) {
        case 1.5:
        case 2:
        case fixtureRatio.wideRectangle:
          return {
            width: screen.width,
            height: screen.width / fixtureRatio.wideRectangle,
            left: width / 2,
            top: height / 2,
          }
        case 3:
        case fixtureRatio.narrowRectangle:
          return {
            width: screen.width,
            height: screen.width / fixtureRatio.narrowRectangle,
            left: width / 2,
            top: height / 2,
          }
        default:
          return {
            width,
            height,
            left: width / 2,
            top: height / 2,
            angle: object.theta,
          }
      }
    }

    return {
      width,
      height,
      left: width / 2,
      top: height / 2,
      angle: object.theta,
    }
  }

  const parentScaledGeometry = getGeometry(parent, screen)

  const {
    layoutPosition: { width: parentWidth, height: parentHeight } = {},
  } = parent
  const {
    layoutPosition: { width: w, height: h, xCenter, yCenter, theta } = {},
  } = object
  let width = w
  let height = h
  if (
    object.type === mtiFixtureType.extendedQuarterCircle ||
    object.type === sviFigureType.extendedRightQuarterCircle ||
    object.type === sviFigureType.extendedLeftQuarterCircle
  ) {
    width = extendedQuarterCircleWidth
    height = extendedQuarterCircleHeight
  }

  const scaledWidth = width / parentWidth * parentScaledGeometry.width
  const scaledHeight = height / parentHeight * parentScaledGeometry.height

  // In case we use originX: 'left'
  // const leftF =
  //   xCenter / parentWidth * parentScaledGeometry.width +
  //   parentScaledGeometry.width / 2 -
  //   scaledWidth / 2
  const leftF = xCenter / parentWidth * parentScaledGeometry.width

  // In case we use originY: 'top'
  // const topF =
  //   yCenter / parentHeight * parentScaledGeometry.height +
  //   parentScaledGeometry.height / 2 -
  //   scaledHeight / 2
  const topF = yCenter / parentHeight * parentScaledGeometry.height

  if (
    object.type === mtiFixtureType.extendedQuarterCircle ||
    object.type === sviFigureType.extendedRightQuarterCircle ||
    object.type === sviFigureType.extendedLeftQuarterCircle
  ) {
    return {
      height: scaledWidth,
      width: scaledWidth / extendedQuarterCircleAspectRatio,
      left: leftF,
      top: topF,
      angle: theta,
    }
  }

  if (
    object.type === mtiFixtureType.quarterCircle ||
    object.type === mtiFixtureType.circle ||
    object.type === sviFigureType.circle
  ) {
    return {
      height: Math.min(scaledWidth, scaledHeight),
      width: Math.min(scaledWidth, scaledHeight),
      left: leftF,
      top: topF,
      angle: theta,
    }
  }

  return {
    width: scaledWidth,
    height: scaledHeight,
    left: leftF,
    top: topF,
    angle: theta,
  }
}

export function isTemplateCloneableOnly(template) {
  return template && template.cloneable && !template.copyable
}

export function getFixtureObject(
  sId,
  fId,
  aId,
  fixture,
  organizationId,
  template
) {
  const {
    name,
    type,
    isFurniture,
    nameHasShown,
    applyFutureTemplateUpdates,
  } = fixture
  const {
    sviSizeType,
    sviType,
    width,
    height,
    xCenter,
    yCenter,
    angle,
  } = fixture // layoutPosition
  return {
    name,
    type,
    storeId: sId,
    organizationId,
    floorId: fId,
    areaId: aId,
    noninteractive: isFurniture,
    shouldDisplayName: nameHasShown,
    // clonedChild: true,
    parentId: applyFutureTemplateUpdates ? template.id : undefined,
    layoutPosition: {
      xCenter,
      yCenter,
      width,
      height,
      targetType: 'fixture',
      // targetId: 'rdbClientId1424',
      geometryType: 'layout_position',
    },
    geometry: {
      angle,
      height,
      resourceCollection: 'fixture',
      // resourceId: 'rdbClientId1424',
      size: sviSizeType,
      type: sviType,
      width,
      x: xCenter,
      y: yCenter,
      targetType: 'fixture',
      geometryType: 'geometry',
    },
  }
}
