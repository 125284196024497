import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import PingIcon from '../../../images/svg/buttons/ping.svg'

const PingContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-around;
  align-items: center;
`

const Status = styled.div`
  margin-bottom: 0px;
`

export const Icon = styled.img`
  display: block;
  flex-shrink: 0;
  width: 30px;
  height: 30px;
  overflow: hidden;
  object-fit: contain;
`

class PingHubScreen extends React.Component {
  /* eslint-disable no-undef */
  getStatus = () => {
    const { hub, isHubFetching, hubFetchError } = this.props
    if (isHubFetching) return 'Pinging'
    if (hubFetchError) return `${hubFetchError.message}`
    if (!hub) return 'NA'

    if (typeof hub.pingStatus === 'boolean') {
      return hub.pingStatus ? 'Online' : 'Offline'
    }

    return hub.pingStatus
  }

  render() {
    const { onPing } = this.props
    const status = this.getStatus()

    return (
      <PingContainer>
        <Icon src={PingIcon} onClick={onPing} />
        <Status>{status}</Status>
      </PingContainer>
    )
  }
}

PingHubScreen.propTypes = {
  hub: PropTypes.object,
  isHubFetching: PropTypes.bool,
  hubFetchError: PropTypes.object,
  onPing: PropTypes.func.isRequired,
}

export default PingHubScreen
