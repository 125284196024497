import { createSelector } from 'reselect'

const selectPassEdit = (state) => state.get('password')

const makeSelectFailed = () =>
  createSelector(selectPassEdit, (password) => password.get('failed'))

const makeSelectLoading = () =>
  createSelector(selectPassEdit, (password) => password.get('loading'))

const makeSelectPassword = () =>
  createSelector(selectPassEdit, (password) => password)

const makeSelectPincode = () =>
  createSelector(selectPassEdit, (pincode) => pincode)

const makeSelectSuccess = () =>
  createSelector(selectPassEdit, (password) => password.get('success'))

export {
  selectPassEdit,
  makeSelectFailed,
  makeSelectLoading,
  makeSelectPassword,
  makeSelectPincode,
  makeSelectSuccess,
}
