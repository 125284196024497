/**
 *
 * StoreTile
 *
 */

import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import alertIcon from '../../images/floors/alert-icon.png'

const Tile = styled.div`
  display: flex;
  flex-direction: column;
  width: 216px;
  height: 162px;
  border: 1px solid rgba(0, 0, 0, 0.24);
  background-color: white;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.24);
  cursor: pointer;
  padding-right: 20px;
  padding-left: 20px;
  margin-bottom: 20px;
  flex-shrink: 0;
`

const StorePreview = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 100%;
  height: 124px;
  background-image: ${(props) =>
    props.preview ? `url(${props.preview});` : 'none;'}
  background-repeat: no-repeat;
  background-size: cover;
`

const Caption = styled.div`
  display: flex;
  width: 100%;
  height: 38px;
  padding: 0 10px;
  align-items: center;
  justify-content: left;
  flex-shrink: 0;
`

const Title = styled.span`
  text-transform: uppercase;
  color: #4e92df;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const AlertIcon = styled.img`
  width: 60px;
  height: 60px;
  object-fit: contain;
  overflow: hidden;
`

function StoreTile({ title, previewImgUrl, showAlert, onSelect, active }) {
  const borders = active ? 'border border-primary' : 'border border-white'
  return (
    <Tile onClick={onSelect} className={borders}>
      <StorePreview preview={previewImgUrl}>
        {showAlert && <AlertIcon src={alertIcon} />}
      </StorePreview>
      <Caption>
        <Title>{title}</Title>
      </Caption>
    </Tile>
  )
}

StoreTile.propTypes = {
  title: PropTypes.string.isRequired,
  previewImgUrl: PropTypes.string,
  showAlert: PropTypes.bool,
  onSelect: PropTypes.func,
  active: PropTypes.bool,
}

StoreTile.defaultProps = {
  onSelect: () => {},
}

export default StoreTile
