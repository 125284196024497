import keyMirror from 'fbjs/lib/keyMirror'

/**
 * @constant {Object} ActionTypes
 * @memberof Constants
 */
export const ActionTypes = keyMirror({
  FETCH_MINIONS: undefined,
  FETCH_MINIONS_PENDING: undefined,
  FETCH_MINIONS_FULFILLED: undefined,
  FETCH_MINIONS_FAILED: undefined,
})
