import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { createStructuredSelector } from 'reselect'
import { selectFloorsCount } from '../selectors'

const FloorNameBreadcrumb = ({
  floorsCount,
  match: { key, params: { fName } }, // url,
}) =>
  floorsCount > 1 ? (
    <Link
      key={key}
      className="breadcrumb-item"
      to={'#'}
      onClick={(e) => e.preventDefault()}
    >
      {decodeURIComponent(fName)}
    </Link>
  ) : null

FloorNameBreadcrumb.propTypes = {
  match: PropTypes.object,
  floorsCount: PropTypes.number,
}

const mapStateToProps = createStructuredSelector({
  floorsCount: (state, ownProps) =>
    selectFloorsCount(ownProps.match.params.sId)(state, ownProps),
})

export default connect(mapStateToProps)(FloorNameBreadcrumb)
