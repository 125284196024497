/**
 * @module Sagas/MQTT
 * @desc MQTT
 */

import mqtt from 'mqtt'
import { eventChannel, END, delay } from 'redux-saga'
import {
  call,
  apply,
  take,
  put,
  takeEvery,
  select,
  all,
  cancelled,
  takeLatest,
} from 'redux-saga/effects'
import {
  processMQTTMessage,
  modelActionCreators as mac,
  MQTTActionTypes,
} from 'mti-jsclient-shared'

import { upsert } from './ormSaga'
import { ActionTypes } from '../constants'
import {
  makeSelectToken,
  makeSelectTokenId,
  makeSelectBaseSnByPuckSn,
  makeSelectPosition,
  makeSelectSecurityDevice,
} from '../selectors'
import {
  clientId,
  mqttHost,
  // mqtt
  getSecurityDevice, // securityDeviceDetails
  getSecuredProduct, // securedProductDetails
  getFixture, // fixtureDetails
  getPosition, // positionDetails
  getProduct, // productDetails
  getStoreById, // storeDetails
  getRule,
} from '../../../api'

import {
  loadKeys,
  securityDeviceIdentify,
  securityDeviceResetIdentify,
} from '../actions'
import { errorToast } from '../../../utils/utils'

export const MqttCodes = {
  SUCCESS: Symbol('success'),
  FAILURE: Symbol('failure'),
  MESSAGE: Symbol('message'),
  CLOSE: Symbol('close'),
}

const mqttOptions = {
  protocolId: 'MQTT',
  protocolVersion: 4,
  clean: true,
  keepalive: 10,
  reconnectPeriod: 1000,
  connectTimeout: 30 * 1000,
  rejectUnauthorized: true,
}

let mqttClient = null
let mqttChannel = null
let mqttTopic = null

const ResourceUpdatedAction = {
  // proto.mti.ResourceUpdated.Action
  CREATED: 0,
  UPDATED: 1,
  DELETED: 2,
}

/**
 * Wrap MQTT.js client in MQTT "saga" channel to improve effects management
 * @returns {*}
 */
function* initMqttChannel() {
  console.warn('initMqttChannel')
  try {
    const username = yield select(makeSelectTokenId())
    const password = yield select(makeSelectToken())

    mqttChannel = eventChannel((emit) => {
      mqttClient = mqtt.connect(
        mqttHost,
        Object.assign(mqttOptions, {
          username,
          password,
        })
      )

      mqttClient.on('connect', () => {
        console.log('MQTT Client: CONNECTED')
        emit({ type: MqttCodes.SUCCESS, payload: clientId })
      })

      mqttClient.on('error', (err) => {
        console.log('MQTT Client error:', err)
        mqttClient.end()
        emit(END)
      })

      mqttClient.on('message', (topic, message, packet) => {
        console.log('MQTT Client message > topic:', topic)
        // console.log('MQTT Client message > message:', message)
        // console.log('MQTT Client message > packet:', packet)
        emit({ type: MqttCodes.MESSAGE, payload: { topic, message, packet } })
      })

      mqttClient.on('close', () => {
        console.log('MQTT Client: CLOSED')
        emit(END)
      })

      return () => {
        mqttClient.end()
      }
    })

    return mqttChannel
  } catch (err) {
    console.log(err)
    return null
  }
}

/**
 * Observe MQTT topic
 * @param topicToObserve
 */
function* observeMqttTopic(topicToObserve) {
  if (!(mqttClient || mqttChannel)) {
    mqttChannel = yield call(initMqttChannel)
  }
  yield apply(mqttClient, mqttClient.subscribe, [topicToObserve])

  /* eslint-disable no-constant-condition */
  try {
    while (true) {
      // eslint-disable-line
      const { payload } = yield take(mqttChannel)
      yield put({ type: ActionTypes.MQTT_MESSAGE_RECEIVE, payload })
    }
  } catch (err) {
    yield put({
      type: ActionTypes.MQTT_ERROR,
      payload: err,
    })
  } finally {
    if (yield cancelled()) {
      yield call(close)
    }
  }
}

function* handleMQTTMessage({ payload }) {
  // console.log('handleMQTTMessage', payload)
  const { topic, message } = payload
  if (!topic || !message) {
    console.log('MQTT Client: undefined topic || message')
    return
  }
  const action = processMQTTMessage(topic, [].slice.call(message))
  if (!action) {
    console.log('MQTT Client: undefined action')
    return
  }
  console.log('MQTT Client: action', action)
  const token = yield select(makeSelectToken())
  try {
    switch (action.type) {
      case MQTTActionTypes.UPDATE_STORE_INQUIRY:
        {
          const { storeId } = action.payload
          const data = yield call(getStoreById, token, storeId, [])
          if (data.stores && data.stores.length > 0) {
            const newStoreData = data.stores[0]
            // Only update the fields we care about (the ones that can trigger this notification to
            // begin with), and leave the rest of our data alone
            const store = {
              id: newStoreData.id,
              state: newStoreData.state,
              name: newStoreData.name,
              branchCode: newStoreData.branchCode,
            }
            yield put(mac.upsertStore(store))
          }
        }
        break
      case MQTTActionTypes.UPDATE_POSITION_INQUIRY:
        {
          const { positionId, action: nativeAction } = action.payload
          if (nativeAction === ResourceUpdatedAction.DELETED) {
            yield put(mac.deletePositionWithId(positionId))
          } else {
            const p = yield select(makeSelectPosition(positionId))
            const { securityDevice: securityDeviceId } = p || {}
            yield call(updateSecurityDevice, {
              payload: { securityDeviceId, token },
            })
            const data = yield call(getPosition, token, positionId)
            yield call(upsert, { payload: data })
            yield call(updateSecurityDevice, {
              payload: {
                securityDeviceId: ((data.positions || [])[0] || {})
                  .securityDeviceId,
                token,
              },
            })
          }
        }
        break
      case MQTTActionTypes.UPDATE_FIXTURE_INQUIRY:
        {
          const { fixtureId, action: nativeAction } = action.payload
          if (nativeAction === ResourceUpdatedAction.DELETED) {
            yield put(mac.deleteFixtureWithId(fixtureId))
          } else {
            const data = yield call(getFixture, token, fixtureId)
            yield call(upsert, { payload: data })
          }
        }
        break
      case MQTTActionTypes.UPDATE_SECURITY_DEVICE_INQUIRY:
        {
          const { securityDeviceId } = action.payload
          yield call(updateSecurityDevice, {
            payload: { securityDeviceId, token },
          })
        }
        break
      case MQTTActionTypes.UPDATE_SECURED_PRODUCT_INQUIRY:
        {
          const { securedProductId } = action.payload
          const { securedProducts } = yield call(
            getSecuredProduct,
            token,
            securedProductId
          )
          yield put(mac.upsertSecuredProducts(securedProducts))
        }
        break
      case MQTTActionTypes.UPDATE_CX_FLEX_STATE:
        {
          const {
            serialNumber,
            ramStatus: { keyInserted } = action.payload,
          } = action.payload
          // Handle reverse identify
          if (keyInserted) {
            yield call(securityDeviceReverseIdentify, serialNumber)
          } else {
            yield call(securityDeviceRemoveIdentify, serialNumber)
          }
          yield put(action) // in case other handlers like shared library
        }
        break
      case MQTTActionTypes.UPDATE_SECURE_PLUG_STATE:
        {
          const {
            serialNumber,
            ramStatus: { keyInserted } = {},
          } = action.payload
          // Handle reverse identify
          if (keyInserted) {
            yield call(securityDeviceReverseIdentify, serialNumber)
          } else {
            yield call(securityDeviceRemoveIdentify, serialNumber)
          }
          yield put(action) // in case other handlers like shared library
        }
        break
      case MQTTActionTypes.UPDATE_PUCK_STATE:
      case MQTTActionTypes.UPDATE_PROXIMITY_PUCK_STATE:
      case MQTTActionTypes.UPDATE_ALARM_MODULE_STATE:
      case MQTTActionTypes.UPDATE_NXDI_PUCK_STATE:
        {
          const { serialNumber, keyInserted } = action.payload
          // Handle reverse identify
          if (keyInserted) {
            yield call(securityDeviceReverseIdentify, serialNumber)
          } else {
            yield call(securityDeviceRemoveIdentify, serialNumber)
          }
          yield put(action) // in case other handlers like shared library
        }
        break
      case MQTTActionTypes.UPDATE_LOCK_STATE:
      case MQTTActionTypes.UPDATE_FM20_STATE:
        {
          const { keySerialNumber, authorizedUserKeyUsed } = action.payload
          const serialNumber =
            action.payload.baseSerialNumber || action.payload.serialNumber
          // Handle reverse identify
          if (
            authorizedUserKeyUsed ||
            (keySerialNumber && !/^0*$/.test(keySerialNumber))
          ) {
            yield call(securityDeviceReverseIdentify, serialNumber)
          } else {
            yield call(securityDeviceRemoveIdentify, serialNumber)
          }
          yield put(action) // in case other handlers like shared library
        }
        break
      default: {
        console.log(
          'MQTT Client: action can be handled by shared client',
          action
        )
        yield put(action)
      }
    }
  } catch (error) {
    console.log("MQTT Client: Can't update", action)
    console.log(error)
    errorToast('MQTT message Error')
  }
}

/**
 * Update Security Device
 */
export function* updateSecurityDevice({
  payload: { securityDeviceId: sdId, token },
}) {
  if (!sdId) return
  const oldSd = yield select(makeSelectSecurityDevice(sdId))

  const sd = yield call(updateOneSecurityDevice, { id: sdId, token })
  yield call(updateSecuredProducts, { ports: (sd || {}).ports, token })

  const positionId = (sd || {}).positionId
  if (positionId) {
    try {
      const positionData = yield call(getPosition, token, positionId)
      yield call(upsert, { payload: positionData })
    } catch (e) {
      console.log(e)
    }
  }

  const ids = []

  const prntId1 = (oldSd || {}).parentId
  if (!ids.includes(prntId1)) {
    const prnt1 = yield call(updateOneSecurityDevice, { id: prntId1, token })
    yield call(updateSecuredProducts, { ports: (prnt1 || {}).ports, token })
    ids.push(prntId1)
  }

  const prntId2 = (sd || {}).parentId
  if (!ids.includes(prntId2)) {
    const prnt2 = yield call(updateOneSecurityDevice, { id: prntId2, token })
    yield call(updateSecuredProducts, { ports: (prnt2 || {}).ports, token })
    ids.push(prntId2)
  }

  const puckId1 = (oldSd || {}).puckId
  if (!ids.includes(puckId1)) {
    const puck1 = yield call(updateOneSecurityDevice, { id: puckId1, token })
    yield call(updateSecuredProducts, { ports: (puck1 || {}).ports, token })
    ids.push(puckId1)
  }

  const puckId2 = (sd || {}).puckId
  if (!ids.includes(puckId2)) {
    const puck2 = yield call(updateOneSecurityDevice, { id: puckId2, token })
    yield call(updateSecuredProducts, { ports: (puck2 || {}).ports, token })
    ids.push(puckId2)
  }

  const baseId1 = (oldSd || {}).baseId
  if (!ids.includes(baseId1)) {
    const base1 = yield call(updateOneSecurityDevice, { id: baseId1, token })
    yield call(updateSecuredProducts, { ports: (base1 || {}).ports, token })
    ids.push(baseId1)
  }

  const baseId2 = (sd || {}).baseId
  if (!ids.includes(baseId2)) {
    const base2 = yield call(updateOneSecurityDevice, { id: baseId2, token })
    yield call(updateSecuredProducts, { ports: (base2 || {}).ports, token })
    ids.push(baseId2)
  }

  // handle unassign of SecuredProduct, triggered by UPDATE_SECURITY_DEVICE_INQUIRY
  yield call(updateSecuredProducts, { ports: (oldSd || {}).ports, token })
}

/**
 * Update Individual Security Device
 */
export function* updateOneSecurityDevice({ id, token }) {
  if (!id) return
  try {
    const { securityDevices } = yield call(getSecurityDevice, token, id)
    yield put(mac.upsertSecurityDevices(securityDevices))
    return (securityDevices || [])[0]
  } catch (e) {
    console.log(e)
  }
}

/**
 * Update Secured Products
 */
export function* updateSecuredProducts({ ports = [], token }) {
  for (let i = 0; i < ports.length; i += 1) {
    const securedProductId = (ports[i] || {}).securedProductId
    if (securedProductId) {
      try {
        const { securedProducts } = yield call(
          getSecuredProduct,
          token,
          securedProductId
        )
        yield put(mac.upsertSecuredProducts(securedProducts))
      } catch (e) {
        console.log(e)
      }
    }
  }
}

/**
 * Security Device Reverse Identify
 */
export function* securityDeviceReverseIdentify(serialNumber) {
  const baseSN = yield select(makeSelectBaseSnByPuckSn(serialNumber))
  yield put(securityDeviceIdentify(serialNumber))
  if (baseSN) yield put(securityDeviceIdentify(baseSN))
  yield delay(15000)
  yield put(securityDeviceResetIdentify(serialNumber))
  if (baseSN) yield put(securityDeviceResetIdentify(baseSN))
}

/**
 * Security Device Remove Identify
 */
export function* securityDeviceRemoveIdentify(serialNumber) {
  const baseSN = yield select(makeSelectBaseSnByPuckSn(serialNumber))
  yield put(securityDeviceResetIdentify(serialNumber))
  if (baseSN) yield put(securityDeviceResetIdentify(baseSN))
}

/**
 * Subscribe on MQTT topic
 */
export function* subscribe({ payload: { topic } }) {
  if (mqttTopic === topic) {
    return
  }
  if (mqttTopic !== null) {
    // Unsubscribe
    yield call(unsubscribe, { payload: { topic: mqttTopic } })
  }
  console.log('MQTT Client: Going to subscribe to', topic)
  mqttTopic = topic

  // Initialize mqtt channel if needed
  if (!(mqttClient || mqttChannel)) {
    mqttChannel = yield call(initMqttChannel)
  }

  // Subscribe
  yield call(observeMqttTopic, topic)
}

/**
 * Unsubscribe from MQTT topic
 * @param topic
 */
export function* unsubscribe({ payload: { topic } }) {
  if (!(mqttClient || mqttChannel)) {
    return
  }
  console.log(`MQTT Client: Unsubscribed from ${topic}`)
  yield apply(mqttClient, mqttClient.unsubscribe, [topic])
}

/**
 * Reqonnect MQTT
 */
export function* reconnect() {
  if (!mqttTopic) return

  // Initialize mqtt channel if needed
  if (!(mqttClient || mqttChannel)) {
    mqttChannel = yield call(initMqttChannel)
  }

  yield call(observeMqttTopic, mqttTopic)
}

/**
 * Close MQTT Channel
 */
export function* close() {
  if (!(mqttClient || mqttChannel)) {
    return
  }
  yield call(mqttChannel.close)
  mqttChannel = undefined
  mqttClient = undefined
  yield put({
    type: ActionTypes.MQTT_CHANNEL_CLOSED,
  })
}

/**
 * MQTT Sagas
 */
export default function* root() {
  // TODO: ActionTypes.MQTT_CHANNEL_CLOSED -> subscribeAll could cause infinite loop, should be tested well
  yield all([
    // takeLatest(ActionTypes.MQTT_CHANNEL_CLOSED, reconnect),
    takeEvery(ActionTypes.MQTT_TOPIC_SUBSCRIBE, subscribe),
    takeEvery(ActionTypes.MQTT_TOPIC_UNSUBSCRIBE, unsubscribe),
    takeLatest(
      [
        ActionTypes.USER_LOGOUT_FAILURE,
        ActionTypes.USER_LOGOUT_SUCCESS,
        ActionTypes.USER_RESTORE_FAILURE,
      ],
      close
    ),
    takeEvery(ActionTypes.MQTT_MESSAGE_RECEIVE, handleMQTTMessage),
  ])
}
