import React from 'react'
import PropTypes from 'prop-types'
import {
  ButtonsContainer,
  WarningContainer,
} from '../../OTAHomePage/RebootHubModal'
import { ModalBody } from '../../StoresPage/CreateEditStoreModal/index.screen'

const DeleteFirmwareWarning = ({ id, firmwareName, onCancel, onDelete }) => (
  <div className="modal-content">
    <div className="modal-header border-bottom">
      <ButtonsContainer>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            $(`#modal-${id}`).modal('hide') // eslint-disable-line
            onCancel()
          }}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-primary btn-raised"
          onClick={() => {
            $(`#modal-${id}`).modal('hide') // eslint-disable-line
            onDelete()
          }}
        >
          Yes, I want to Delete
        </button>
      </ButtonsContainer>
    </div>
    <div>
      <ModalBody className="modal-body">
        <WarningContainer>
          {`WARNING: This action will DELETE ${firmwareName}`}
        </WarningContainer>
      </ModalBody>
    </div>
  </div>
)

DeleteFirmwareWarning.propTypes = {
  id: PropTypes.string.isRequired,
  firmwareName: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}

export default DeleteFirmwareWarning
