import React from 'react'
import PropTypes from 'prop-types'
import CreateEditStoreScreen from './index.screen'

class CreateEditStore extends React.PureComponent {
  // have to remove isSaving due to conflicts with validation errors handling, adding back is welcomed
  setServerValidity(error) {
    if (this.createEditStoreModal) {
      this.createEditStoreModal.setServerValidity(error)
    }
  }

  close() {
    if (this.createEditStoreModal) this.createEditStoreModal.close()
  }

  render() {
    const {
      id,
      store,
      regions,
      organization,
      onChange,
      onConfirm,
      onClose,
      onRemoveAll,
      storeEditLoading,
      title,
      hasMtiAdminRole,
      hasOrganizationAdminRole,
      hasRegionalManagerRole,
      hasStoreManagerRole,
    } = this.props

    return (
      <CreateEditStoreScreen
        ref={(ref) => (this.createEditStoreModal = ref)}
        id={id}
        store={store}
        regions={regions}
        organization={organization}
        onConfirm={onConfirm}
        onClose={onClose}
        onChange={onChange}
        onRemoveAll={onRemoveAll}
        storeEditLoading={storeEditLoading}
        title={title}
        hasMtiAdminRole={hasMtiAdminRole}
        hasOrganizationAdminRole={hasOrganizationAdminRole}
        hasRegionalManagerRole={hasRegionalManagerRole}
        hasStoreManagerRole={hasStoreManagerRole}
      />
    )
  }
}

CreateEditStore.propTypes = {
  id: PropTypes.string.isRequired,
  store: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onRemoveAll: PropTypes.func,
  organization: PropTypes.object,
  regions: PropTypes.array,
  storeEditLoading: PropTypes.func,
  title: PropTypes.string.isRequired,
  hasMtiAdminRole: PropTypes.bool,
  hasOrganizationAdminRole: PropTypes.bool,
  hasRegionalManagerRole: PropTypes.bool,
  hasStoreManagerRole: PropTypes.bool,
}

export default CreateEditStore
