import React from 'react'

export default class FormGroup extends React.PureComponent {
  className() {
    const { className: upstream } = this.props
    if (typeof upstream === 'undefined') return 'form-group'

    return `form-group ${upstream}`
  }

  render = () => <div className={this.className()}>{this.props.children}</div>
}
