import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { batchActions } from 'redux-batched-actions'
import { selectToken } from '../../containers/App/selectors'
import { getStoresDashboard } from '../../api'
import { selectOrgID } from './selectors'
import { ActionTypes, cache } from './actions'

function* requestSaga({ payload: query }) {
  let response = null
  try {
    const orgID = yield select(selectOrgID)
    const token = yield select(selectToken)
    response = yield call(getStoresDashboard, token, orgID, query)
  } finally {
    yield put(cache(response))
  }
}

export default function* root() {
  yield all([
    yield takeLatest(ActionTypes.Request, requestSaga),
  ])
}
