import { fabric } from 'fabric'
import uuid from 'uuid'
import { alertTypes } from '../../utils/mtiCanvasUtils'
import { iconScale } from '../../utils/screen'
import { truncate, getTruncatedTextParams } from './utils'
import {
  getObject,
  getObjectSync,
} from '../../containers/FloorPage/objectUtils'

export const editColor = {
  weak: 'rgba(0,122,255,0.2)',
  high: 'rgba(0,122,255,0.3)',
  main: 'rgba(0,122,255,1)',
}
export const normalColor = {
  weak: 'rgba(58,179,74,0.2)',
  main: 'rgba(58,179,74,1)',
}
export const warningColor = {
  weak: 'rgba(255,150,0,0.2)',
  main: 'rgba(255,150,0,1)',
}
export const alertColor = {
  weak: 'rgba(235,37,42,0.2)',
  main: 'rgba(235,37,42,1)',
}
export const emptyColor = {
  weak: 'rgba(100,100,100,0.2)',
  main: 'rgba(100,100,100,1)',
}

export const padding = 0
export const minLabelW = 88
export const labelH = 24
export const minW = 20
export const minH = 20
export const minTruncateN = 9
export const cornerSize = 18
export const borderScaleFactor = 4

export const TruncatedText = fabric.util.createClass(fabric.Text, {
  type: 'truncatedText',

  initialize(text, options) {
    const label = options.fullText || text || ''
    const truncatedTextLength = options.truncatedTextLength || minTruncateN

    this.callSuper('initialize', label, options || {})
    this.set('truncatedTextLength', truncatedTextLength)
    this.set('fullText', label)
    this.set('text', truncate(truncatedTextLength, label))
    this.set('name', options.name)
  },

  toObject() {
    return fabric.util.object.extend(this.callSuper('toObject'), {
      name: this.get('name'),
      fullText: this.get('fullText'),
      truncatedTextLength: this.get('truncatedTextLength'),
    })
  },

  _render(ctx) {
    const truncatedTextLength = this.get('truncatedTextLength') || minTruncateN
    this.set('text', truncate(truncatedTextLength, this.get('fullText')))
    this.callSuper('_render', ctx)
  },
})

fabric.TruncatedText = TruncatedText
fabric.TruncatedText.fromObject = (options, cb) => {
  if (cb) cb(new fabric.TruncatedText(options.text, options))
}

const lock = {
  lockMovementX: true,
  lockMovementY: true,
  lockScalingX: true,
  lockScalingY: true,
}

export const slicingAreaSync = (
  text,
  pointer,
  origX,
  origY,
  angle,
  id,
  areaNameDisplayed = false,
  isStatic = false,
  alertType = alertTypes.normal,
  alertCount,
  fixturesCount = 0,
  isMobile = false,
  prototypes,
  mtiJsclientShared
) => {
  const statusIcon = getObjectSync(
    alertType,
    alertCount,
    prototypes,
    mtiJsclientShared
  )
  return createArea(
    text,
    pointer,
    origX,
    origY,
    angle,
    id,
    areaNameDisplayed,
    isStatic,
    alertType,
    alertCount,
    fixturesCount,
    isMobile,
    statusIcon,
    prototypes,
    mtiJsclientShared
  )
}

export const slicingArea = async (
  text,
  pointer,
  origX,
  origY,
  angle,
  id,
  areaNameDisplayed = true,
  isStatic = false,
  alertType = alertTypes.normal,
  alertCount,
  fixturesCount = 0,
  isMobile = false
) => {
  const statusIcon = await getObject(alertType, alertCount)
  return createArea(
    text,
    pointer,
    origX,
    origY,
    id,
    angle,
    areaNameDisplayed,
    isStatic,
    alertType,
    alertCount,
    fixturesCount,
    isMobile,
    statusIcon
  )
}

export const createArea = (
  text,
  pointer,
  origX,
  origY,
  angle = 0,
  id,
  areaNameDisplayed = false,
  isStatic = false,
  alertType = alertTypes.normal,
  alertCount,
  fixturesCount = 0,
  isMobile = false,
  statusIcon,
  prototypes,
  mtiJsclientShared = {}
) => {
  const { ArmState = {} } = mtiJsclientShared
  const width = (pointer.x - origX) * 2
  const height = (pointer.y - origY) * 2
  const { textLength, textSize, textFamily } = getTruncatedTextParams(
    text,
    width,
    minLabelW,
    padding
  )
  const isAlert =
    alertType === alertTypes.alert ||
    alertType === ArmState.ALARMING ||
    alertType === alertTypes.lowAlert ||
    alertType === ArmState.DISARMED ||
    alertType === ArmState.UNLOCKED ||
    alertType === alertTypes.warning
  const { weakColor, mainColor } = getAreaColor(
    isStatic ? alertType : 'edit',
    fixturesCount === 0,
    mtiJsclientShared
  )
  const isControlShown = !isStatic
  statusIcon.set({ targetable: true })
  const icon =
    isStatic && isAlert ? statusIcon : new fabric.Rect({ width: 0, height: 0 })
  const spaceForAreaNameRequired = isStatic && isAlert && areaNameDisplayed

  // Move status icon to have some place for area label
  if (spaceForAreaNameRequired) {
    icon.set({ top: -25 * iconScale })
  }

  const bgn = new fabric.Rect({
    name: 'rect',
    originX: 'center',
    originY: 'center',
    width,
    height,
    fill: weakColor,
    stroke: mainColor,
    strokeWidth: 0,
  })
  // const pattern = getPatternImg(prototypes, alertType, isStatic)
  // if (pattern) { // TODO: uncoment and fix issue with object selection
  //   bgn.setPatternFill({
  //     source: pattern,
  //     repeat: 'repeat',
  //   })
  // }

  return new fabric.Group(
    [
      bgn,
      new fabric.TruncatedText(text, {
        truncatedTextLength: textLength,
        name: 'label',
        fontSize: textSize,
        fill: '#000000',
        fontFamily: textFamily,
        originX: 'center',
        originY: 'center',
        fontWeight: isMobile ? 400 : 200,
        top: spaceForAreaNameRequired ? 25 * iconScale : 0,
        opacity: areaNameDisplayed ? 1 : 0,
      }),
      icon,
    ],
    {
      id: id || uuid.v4(),
      name: text,
      left: origX,
      top: origY,
      originX: 'center',
      originY: 'center',
      width,
      height,
      angle,
      alertType,
      transparentCorners: false,
      hasRotatingPoint: true,
      cornerSize,
      borderScaleFactor,
      hasBorders: !isMobile,
      cornerColor: mainColor,
      borderColor: mainColor,
      lockRotation: false,
      lockMovementX: false,
      lockMovementY: false,
      lockScalingX: false,
      lockScalingY: false,
      lockScalingFlip: true,
      ...(isStatic ? lock : {}),
      fixturesCount,
      displayAreaName: areaNameDisplayed,
      // This is undocumented property be careful using this
      _controlsVisibility: {
        bl: isControlShown,
        br: isControlShown,
        mb: isControlShown,
        ml: isControlShown,
        mr: isControlShown,
        mt: isControlShown,
        mtr: isControlShown,
        tl: isControlShown,
        tr: isControlShown,
      },
    }
  )
}

export const renderLabel = (group) => {
  const text = group.item(1)
  const textWidth = group.width * group.scaleX
  const { textLength, textSize, textFamily } = getTruncatedTextParams(
    text.fullText,
    textWidth,
    textWidth,
    padding
  )

  text.set({
    width: textWidth,
    truncatedTextLength: textLength,
    fontSize: textSize,
    fontFamily: textFamily,
  })

  return group
}

export const getAreaColor = (type, isEmpty = false, mtiJsclientShared = {}) => {
  const { ArmState = {} } = mtiJsclientShared
  if (isEmpty) return { weakColor: emptyColor.weak, mainColor: emptyColor.main }

  if (type === 'edit') {
    return { weakColor: editColor.weak, mainColor: editColor.main }
  }

  switch (type) {
    case alertTypes.normal:
      return { weakColor: normalColor.weak, mainColor: normalColor.main }
    case alertTypes.warning:
      return { weakColor: warningColor.weak, mainColor: warningColor.main }
    case alertTypes.alert:
    case ArmState.ALARMING:
    case alertTypes.lowAlert:
    case ArmState.DISARMED:
    case ArmState.UNLOCKED:
      return { weakColor: alertColor.weak, mainColor: alertColor.main }
    default:
      return { weakColor: '#000000', mainColor: '#000000' }
  }
}

export function getPatternImg(prototypes, alertType, isStatic) {
  if (!isStatic) return undefined

  switch (alertType) {
    case alertTypes.alert:
    case alertTypes.lowAlert:
      return prototypes.patternAlert
    case alertTypes.warning:
      return prototypes.patternWarning
    default:
      return undefined
  }
}

export const propertiesToInclude = [
  'id',
  'name',
  'hasRotatingPoint',
  'lockRotation',
  'cornerSize',
  'cornerColor',
  'transparentCorners',
  'hasBorders',
  'borderScaleFactor',
  'borderColor',
  'origStrokeWidth',
  'lockMovementX',
  'lockMovementY',
  'lockScalingX',
  'lockScalingY',
  'fixturesCount',
  'alertType',
  'displayAreaName',
  'fullText',
  'truncatedTextLength',
  'lockScalingFlip',
  'targetable',
  'image',
  '_controlsVisibility',
]

export const handleOver = (o) => {
  if (!o || !o.fixturesCount) return
  const { _objects: objects } = o

  if (!objects || !objects[0]) return
  objects[0].set('fill', editColor.high)
}

export const handleOut = (o) => {
  if (!o || !o.fixturesCount) return
  const { _objects: objects } = o
  if (!objects || !objects[0]) return
  objects[0].set('fill', editColor.weak)
}
