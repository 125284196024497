import React from 'react'
import PropTypes from 'prop-types'
import ListOfLinks from '../../../components/ListOfLinks'
import { injectIntl } from 'react-intl'

const DrawerLinksScreen = ({ quickActionsItems, onItem, history, intl }) => (
  <ListOfLinks
    items={quickActionsItems}
    headerTitle={intl.formatMessage({ id: 'components.navigation.title' })}
    onItem={onItem}
    history={history}
  />
)

DrawerLinksScreen.propTypes = {
  quickActionsItems: PropTypes.array.isRequired,
  onItem: PropTypes.func,
}

export default injectIntl(DrawerLinksScreen)
