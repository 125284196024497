/**
 *
 * Templates constants
 *
 */

import keyMirrow from 'fbjs/lib/keyMirror'

/**
 * @constant {Object} ActionTypes
 * @memberof Constants
 */
export const ActionTypes = keyMirrow({
  DELETE_TEMPLATE: undefined,
})
