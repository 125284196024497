export const statuses = {
  statuses: [
    {
      id: 1,
      devices: 'HUB 1 ID, HUB 2 ID',
      date: '11/27/18',
      status: 'Success',
    },
    {
      id: 2,
      devices: 'HUB 3 ID, HUB 4 ID',
      date: '11/27/18',
      status: 'Fail',
    },
    {
      id: 3,
      devices: 'HUB 5 ID, HUB 6 ID',
      date: '11/26/18',
      status: 'Success',
    },
    {
      id: 4,
      devices: 'HUB 7 ID, HUB 8 ID',
      date: '11/26/18',
      status: 'Success',
    },
    {
      id: 5,
      devices: 'HUB 9 ID, HUB 10 ID',
      date: '11/25/18',
      status: 'Success',
    },
  ],
}
