import { fabric } from 'fabric'
import { mtiFixtureType, sviFigureType } from '../../utils/mtiCanvasUtils'

import rect from '../../images/svg/fixtures/rect.svg'
import circle from '../../images/svg/fixtures/circle.svg'
import arc from '../../images/svg/fixtures/arc.svg'
import arcExtended from '../../images/svg/fixtures/arc-extended.svg'

export function getFixtureObjectSync(type, prototypes) {
  switch (type) {
    case 'rect':
    case 'square':
    case 'custom':
      return prototypes['custom']
    case 'circle':
      return prototypes[type]
    case 'quarterCircle':
      return prototypes[type]
    case 'extendedRightQuarterCircle':
      return prototypes[type]
    case 'extendedLeftQuarterCircle':
      return prototypes[type]
    case 'extendedQuarterCircle':
      return prototypes['extendedLeftQuarterCircle']
    default:
      return prototypes['custom']
  }
}

export function getObject(type) {
  switch (type) {
    case sviFigureType.custom:
    case sviFigureType.square:
    case mtiFixtureType.table:
      return getFabricObject(rect)
    case sviFigureType.circle:
    case mtiFixtureType.circle:
      return getFabricObject(circle)
    case mtiFixtureType.quarterCircle:
      return getFabricObject(arc)
    case mtiFixtureType.extendedQuarterCircle:
      return getFabricObject(arcExtended)
    case sviFigureType.extendedRightQuarterCircle:
      return getFabricObject(arcExtended)
    case sviFigureType.extendedLeftQuarterCircle:
      return getFabricObject(arcExtended)
    case sviFigureType.narrowRectangle:
      return getFabricObject(rect)
    case sviFigureType.wideRectangle:
      return getFabricObject(rect)
    default:
      return getFabricObject(rect)
  }
}

export function getFabricObject(resource) {
  return new Promise((resolve) => {
    fabric.loadSVGFromURL(resource, (objects, options) => {
      const loadedObjects = fabric.util.groupSVGElements(objects, options)
      loadedObjects.scaleToWidth(60)
      resolve(loadedObjects.toObject())
    })
  })
}
