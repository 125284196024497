import React from 'react'

import { getTableHeight } from '../../utils/utils'
import { HeaderContainer, Header } from '../UsersPage/index.screen'

class PrivacyPolicy extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    window.setTimeout(() => this.handleResize(), 10)
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  handleResize = () => this.setState({ tableHeight: getTableHeight() })

  renderPrivacyPolicy() {
    const { tableHeight } = this.state
    return (
      <div
        style={{
          textAlign: 'justify',
          padding: '8px',
          overflowY: 'scroll',
          height: tableHeight || 'auto',
        }}
      >
        {/*<p style={{ textAlign: 'center' }}>
          <b>Privacy Notice</b>
        </p>*/}
        <p>
          <b>Effective Date: April 23, 2019 </b>
        </p>
        <p>
          This Privacy Notice (this “<b>Notice</b>”) describes the types of
          information that Mobile Technologies, Inc. (“<b>MTI</b>,” “<b>we</b>,”
          or “<b>our</b>”) collects through your use of MTI’s MTI Connect Mobile
          Application (the “Application”). This Notice does not apply to
          information collected about you through any other means, including
          information collected by third parties or through non-electronic or
          offline means.
        </p>
        <p>This Notice covers:</p>
        <ul>
          <li>
            What Personal Information is collected by MTI through the
            Application.
          </li>
          <li>How MTI uses this information.</li>
          <li>With whom MTI may share this information.</li>
          <li>
            What choices are available to you with respect to the collection,
            use and sharing of this information.
          </li>
          <li>
            What types of security procedures are in place to protect against
            the loss or misuse of this information under our control.
          </li>
        </ul>
        <p>
          By downloading, accessing or using the Application, or by clicking the
          “accept” button when you register for an account to use the
          Application (or any part thereof), you consent to our use of your
          Personal Information as described in this Notice. “Personal
          Information” for purposes of this Notice is the information that
          identifies you as an individual or relates to an identifiable person.
        </p>
        <p>
          This Notice is part of, and is governed by, the MTI Connect End User
          License Agreement (the “EULA”).
        </p>
        <p>
          <b>A. Information We Collect</b>
        </p>
        <p>
          We collect information from you in two ways. First, you provide us
          with certain information when you download the Application, register
          for an account to use the Application, or otherwise access and use the
          Application. Second, we collect certain information automatically as
          you access and use the Application.
        </p>
        <p>
          <b>1. Information You Provide to Us</b>
        </p>
        <p>
          We may collect Personal Information from you when you download the
          Application, register for an account to use the Application, or
          otherwise access and use the Application. If you do not want us to
          collect this information, please do not provide it. We directly
          collect the following types of information that you provide to us:
        </p>
        <ul>
          <li>
            <u>Identifying information</u>. We may collect Personal Information,
            including your name, email address, credit card information, phone
            number, employer name and work title/position.
          </li>
          <li>
            <u>Customer service interactions</u>. To ensure the quality of your
            interactions with our customer service representatives, we may
            monitor and record phone calls or other communications between you
            and our representatives.
          </li>
        </ul>
        <p>
          We may use third-party payment processors to handle payment
          transactions. Any payments you make will also be subject to their
          privacy practices as set forth in their privacy policies.
        </p>
        <p>
          <b>
            2. Information We Automatically Collect from You When You Use the
            Application
          </b>
        </p>
        <p>
          We (or service providers on our behalf) may use technologies,
          including cookies, log files, web beacons, pixels, dynamic tag
          management, and other technical means, to automatically collect
          information from you when you download, access, or use the
          Application. This information may include, without limitation:
        </p>
        <ul>
          <li>the date and time of your use of the Application;</li>
          <li>usage and activity data related to the Application;</li>
          <li>browser type;</li>
          <li>
            the Internet Protocol (IP) address used to connect your computer or
            mobile device to the Internet;
          </li>
          <li>
            your computer or mobile device and connection information, such as
            the type of mobile device, operating system, and platform you use to
            access the Application; and
          </li>
          <li>your mobile device identifier and call information.</li>
        </ul>
        <p>
          <b>3. Use of Cookies</b>
        </p>
        <p>
          The Application uses automatic data collection technologies including
          first- and third-party cookies, log files, web beacons, pixels,
          dynamic tags, and other technical means. We may use these technologies
          to collect information about your online activities over time and
          across third-party websites or other online services in order to
          deliver content and advertising tailored to your interests, both on
          the Application and on third-party applications (including but not
          limited to search engines and social media applications).
        </p>
        <p>
          The third parties who provide us with third-party cookies, web
          beacons, pixels, dynamic tags, and other tracking technologies, may
          use these technologies to collect information about you when you use
          the Application in order to provide you with advertising based on your
          use of the Application and to track performance of their
          advertising. Third parties may have the ability to link the
          information they collect about you when you use the Application with
          other information they collect about you elsewhere on the Internet,
          including but not limited to your Personal Information or information
          about the mobile device you are using. Third parties may also collect
          information about your online or application activities over time and
          across different websites, applications, and other online services and
          may use this information to provide you with advertising tailored to
          your interests or other targeted content.
        </p>
        <p>
          We encourage you to research and learn about cookies and other
          technical means, including web beacons, pixels, and dynamic tags,
          through which information about you may be collected through the
          applications you use and websites you visit. You may set your mobile
          device to disable or refuse all or some cookies. If you disable or
          refuse cookies, please note that some parts of the Application may no
          longer function properly, or may no longer be accessible.
        </p>
        <p>
          <b>B. “Do Not Track” Requests</b>
        </p>
        <p>
          Your Internet browser and mobile device may allow you to adjust your
          settings so that “do not track” requests are sent to the websites or
          web services you use or visit. We do not process “do not track”
          signals. Our third party service providers may also not respond to “do
          not track” signals.
        </p>
        <p>
          <b>C. How We Use the Information We Collect</b>
        </p>
        <p>
          We may use information collected from or about you for any of the
          following purposes:
        </p>
        <ul>
          <li>
            developing, providing and improving our products and the
            Application;
          </li>
          <li>
            managing and verifying your account and the identity of users of the
            Application;
          </li>
          <li>
            contacting you when necessary about your account or your use of the
            Application;
          </li>
          <li>
            sending you information and promotional materials about our products
            and the Application, as well as our company in general;
          </li>
          <li>responding to support requests;</li>
          <li>
            complying with regulatory requirements for the maintenance of
            records;
          </li>
          <li>
            conducting internal reviews of our Application to help us better
            understand users’ use of the Application;
          </li>
          <li>protecting the security and integrity of the Application;</li>
          <li>
            complying with court orders and legal process, and to enforce our
            EULA and this Notice; and
          </li>
          <li>
            any other legal, business or marketing purposes that are not
            inconsistent with the terms of this Notice.
          </li>
        </ul>
        <p>
          We may combine information we collect about you through the
          Application with other information about you that we receive from
          third party sources. By way of example and not limitation, we may use
          a change of address or other list service to ensure that our records
          for your account are accurate.
        </p>
        <p>
          <b>D. How We Share Your Information with Third Parties.</b>
        </p>
        <p>
          We may share the Personal Information we collect in connection with
          the Application with third parties, as described below:
        </p>
        <p>
          <b>
            1. Third Parties Who Provide Services or Information Through the
            Application
          </b>
        </p>
        <p>
          If you are a user, we may share your Personal Information with third
          parties who provide third party services or information through the
          Application. Such third parties may use the information to provide
          products or services, develop and improve their products or services,
          and contact you about product or service orders, future products or
          services, or their company in general.
        </p>
        <p>
          <b>2. Service Providers</b>
        </p>
        <p>
          We may share your Personal Information with our third party
          distributors, vendors, suppliers and other service providers who
          provide services to us or on our behalf, such as operating and
          supporting the Application, mobile app developers, hosting service
          providers, auditors, payment processors, performing marketing or
          consulting services, and assisting us with the preparation and mailing
          of our business and marketing communications and with processing and
          fulfillment functions. These third party service providers may use
          your Personal Information for their own marketing purposes.
        </p>
        <p>
          <b>3. Third Party Analytics Providers</b>
        </p>
        <p>
          We may share your information and Personal Information with our
          advertising and analytics partners, such as Google Analytics. The
          privacy policy of these third party companies applies to their
          collection, use and disclosure of your information. These third
          parties may combine your information with the information of other
          consumers for purposes of conducting these studies and/or analytics.
          For more information about Google Analytics, including how to opt out,
          please go to:{' '}
          <a href={'https://tools.google.com/dlpage/gaoptout'}>
            {'https://tools.google.com/dlpage/gaoptout'}
          </a>.
        </p>
        <p>
          <b>4. Our Affiliates</b>
        </p>
        <p>
          We may share some or all of your information with our subsidiaries and
          corporate affiliates, joint ventures, or other companies that are or
          may become under common control with us. We will require these
          entities to comply with the terms of this Notice with regard to their
          use of your information.
        </p>
        <p>
          <b>
            5. Transfer or Assignment in Connection with Business Transfers or
            Bankruptcy
          </b>
        </p>
        <p>
          In the event of a merger, acquisition, reorganization, bankruptcy or
          other sale of all or a portion of our assets, any user information
          owned or controlled by us may be one of the assets transferred to
          third parties. We reserve the right, as part of this type of
          transaction, to transfer or assign your Personal Information and other
          information we have collected from users of the Application to third
          parties. Other than to the extent ordered by a bankruptcy or other
          court, or as otherwise agreed to by you, the use and disclosure of all
          transferred user information will be subject to this Notice. However,
          any information you submit or that is collected after this type of
          transfer may be subject to a new privacy policy adopted by the
          successor entity.
        </p>
        <p>
          <b>
            6. Response to Subpoenas or Court Orders, or Protection of Our
            Rights
          </b>
        </p>
        <p>
          We may disclose your information to government authorities or third
          parties if:
        </p>
        <ul>
          <li>you have given us permission to share your information;</li>
          <li>
            we are required to do so by law, or in response to a subpoena or
            court order;
          </li>
          <li>
            we believe in our sole discretion that disclosure is reasonably
            necessary to protect against fraud, or to protect our property or
            other rights, or those of other users of the Application, third
            parties, or the public at large; or
          </li>
          <li>
            we believe that you have abused the Application by using the
            Application to attack or gain unauthorized access to a system or to
            engage in spamming or other conduct that violates applicable laws or
            the EULA.
          </li>
        </ul>
        <p>
          <b>7. Aggregate Information</b>
        </p>
        <p>
          We may share aggregate or de-identified information without
          restriction.
        </p>
        <p>
          <b>E. Your Choices</b>
        </p>
        <p>
          We provide the opportunity to opt out of receiving communications from
          us and our partners at the point where we request information about
          you. In addition, you may unsubscribe or opt out of receiving
          communications from us by clicking the unsubscribe link on any email
          marketing communication you receive.
        </p>
        <p>
          As noted, above you can also set your mobile device to refuse all or
          some cookies, or to alert you when cookies are being sent. If you
          disable or refuse cookies, please note that some parts of the
          Application may then be inaccessible or not function properly.
        </p>
        <p>
          You may also change certain details in your account by logging in and
          revising or deleting your account information.
        </p>
        <p>
          <b>F. How We Protect Your Information</b>
        </p>
        <p>
          We have implemented commercially reasonable measures designed to
          secure your Personal Information from unauthorized access, use,
          alteration and disclosure. However, the transmission of information
          via the Internet is not completely secure. You acknowledge that: (a)
          the limitations of the Internet are beyond our control; (b) the
          security, integrity and privacy of information and data exchanged
          between you and us cannot be guaranteed; and (c) any such information
          and data may be viewed or tampered with in transit by a third party.
          MTI has no responsibility or liability for the security of information
          transmitted via the Internet.
        </p>
        <p>
          <b>G. European Union Privacy</b>
        </p>
        <p>
          If the processing of your Personal Information is subject to the
          General Data Protection Regulation 2016/679 (“<b>GDPR</b>”), we have
          additional obligations and you have additional rights under the GDPR.
          These rights and obligations are as follows:
        </p>
        <p>
          We will process your Personal Information in order to support our
          provision of the Application.  The lawful bases for processing your
          Personal Information may include:
        </p>
        <ul>
          <li>When you have given consent to our processing;</li>
          <li>
            When processing is necessary for the performance of a contract to
            which you are a party or in order to take steps at your request
            prior to entering into a contract;
          </li>
          <li>
            When processing is necessary to protect our legitimate interests or
            that of a third party, except where such interests are overridden by
            your interests or fundamental rights;
          </li>
          <li>
            When processing is necessary for compliance with a legal obligation
            to which we are subject; and
          </li>
          <li>
            When processing is necessary to protect the vital interests of the
            data subject or of another natural person.
          </li>
        </ul>
        <p>
          You have many rights with respect to our processing of your Personal
          Information. Depending upon the lawful basis of processing, you may
          have the right to withdraw consent or object to our processing of your
          Personal Information.  You may further have the right to be informed
          about your Personal Information and how it is being processed, to
          access, correct and erase your Personal Information, to restrict
          further processing, and to obtain and reuse your Personal Information
          for your own purposes.  You also have the right to lodge a complaint
          with the relevant European Union supervisory authority.
        </p>
        <p>
          If we transfer your Personal Information to a recipient in a country
          outside the European Economic Area, we will do so in compliance with
          applicable laws with respect to such transfer.
        </p>
        <p>
          <b>H. California’s Shine the Light Law</b>
        </p>
        <p>
          California Civil Code Section 1798.83, known as the “Shine The Light”
          law, permits any users who are California residents to request and
          obtain from us a list of what personal information (if any) we
          disclosed to third parties for direct marketing purposes in the
          preceding calendar year and the names and addresses of those third
          parties. Requests may be made only once a year and are free of charge.
        </p>
        <p>
          <b>I. Correcting/Updating Your Information</b>
        </p>
        <p>
          You may have the right to access the Personal Information we collect
          about you. You may also have the right to correct any errors contained
          in that information. For details, please email us at
          connectsupport@mtigs.com.
        </p>
        <p>
          <b>J. No Use by Children</b>
        </p>
        <p>
          The Application is not intended for users younger than 13. We do not
          knowingly collect contact information from children under the age of
          13 without verifiable parental consent. If we become aware that a
          visitor under the age of 13 has submitted Personal Information without
          verifiable parental consent, we will remove his or her information
          from our files.
        </p>
        <p>
          <b>K. Use of Information Outside Your Country of Residence</b>
        </p>
        <p>
          The Application is directed to users located in the United States. If
          you are located outside of the United States and choose to use the
          Application or provide your information to us, you should be aware
          that we may transfer your information to the United States and process
          it there. The privacy laws in the United States may not be as
          protective as those in your jurisdiction. Your consent to this Notice
          followed by your submission of information to us through or in
          connection with the Application represents your agreement to the
          transfer of your information to the United States.
        </p>
        <p>
          <b>L. Changes to This Privacy Notice</b>
        </p>
        <p>
          We may change this Notice from time to time. If we decide to make a
          material change in this Notice, we will post it on this page. Your
          continued use of the Application after the posting of changes to this
          Notice will mean you accept these changes. In some cases, we may
          attempt to contact you through an email or other address you have
          provided, so we can give you choices about our using your information
          in a manner different from that stated at the time of collection. If
          we make any material changes in our privacy practices that affect your
          Personal Information that is already in our possession, we will apply
          those changes to that older information only with your consent or as
          otherwise allowed by law.
        </p>
        <p>
          <b>M. Contact Us</b>
        </p>
        <p>
          If you have any questions about this Notice or our use of the
          information we collect from you in connection with the Application,
          email us at connectsupport@mtigs.com.
        </p>
      </div>
    )
  }

  render() {
    return (
      <div>
        <HeaderContainer className="border-bottom">
          <Header>Privacy Notice</Header>
        </HeaderContainer>
        {this.renderPrivacyPolicy()}
      </div>
    )
  }
}

export default PrivacyPolicy
