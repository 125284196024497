import React from 'react'
import PropTypes from 'prop-types'
import ReactTable from 'react-table'
import { fullTextFilter, getTableHeight } from '../../utils/utils'
import {
  CellContainer,
  Header,
  HeaderContainer,
  renderHeaderCell,
  renderFilterInput,
} from '../UsersPage/index.screen'

class StatusListScreen extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    window.setTimeout(() => this.handleResize(), 10)
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  /* eslint-disable no-undef */
  handleResize = () => this.setState({ tableHeight: getTableHeight() })

  render() {
    const { statuses, onDevicesSelection } = this.props
    const { tableHeight } = this.state

    const columns = [
      {
        id: 'devices',
        Header: () => renderHeaderCell('Devices Updated'),
        Cell: ({ original: { devices } }) => (
          <CellContainer onClick={() => onDevicesSelection(devices)}>
            <button type="button" className="btn btn-primary">
              {devices}
            </button>
          </CellContainer>
        ),
        accessor: 'devices',
        filterable: true,
        Filter: ({ filter, onChange }) =>
          renderFilterInput({ placeholder: 'Filter', filter, onChange }),
      },
      {
        id: 'date',
        Header: () => renderHeaderCell('Date Updated'),
        Cell: ({ original: { date } }) => <CellContainer>{date}</CellContainer>,
        accessor: 'date',
        filterable: true,
        Filter: ({ filter, onChange }) =>
          renderFilterInput({ placeholder: 'Filter', filter, onChange }),
      },
      {
        id: 'status',
        Header: () => renderHeaderCell('Status'),
        Cell: ({ original: { status } }) => (
          <CellContainer>{status}</CellContainer>
        ),
        accessor: 'status',
        filterable: true,
        Filter: ({ filter, onChange }) =>
          renderFilterInput({ placeholder: 'Filter', filter, onChange }),
      },
    ]

    return (
      <div>
        <HeaderContainer className="border-bottom">
          <Header>Updates</Header>
        </HeaderContainer>
        <ReactTable
          className="full-screen"
          style={{ height: tableHeight || 'auto' }}
          data={statuses}
          columns={columns}
          defaultFilterMethod={fullTextFilter}
        />
      </div>
    )
  }
}

StatusListScreen.propTypes = {
  statuses: PropTypes.array.isRequired,
  onDevicesSelection: PropTypes.func.isRequired,
}

export default StatusListScreen
