const snap = 10 // Pixels to snap

export function findNewPos(distX, distY, target, obj) {
  const oScaledHeight = obj.get('height') * obj.get('scaleY')
  const oScaledWidth = obj.get('width') * obj.get('scaleX')
  if (Math.abs(distX) > Math.abs(distY)) {
    if (Math.abs(distY) < oScaledHeight / 2) {
      target.set(
        'top',
        obj.get('top') -
          (obj.get('height') * obj.get('scaleY') / 2 +
            target.get('height') * target.get('scaleY') / 2)
      )
      return
    }
    if (distX > 0) {
      target.set(
        'left',
        obj.get('left') -
          obj.get('width') * obj.get('scaleX') / 2 -
          target.get('width') * target.get('scaleX') / 2
      )
    } else {
      target.set(
        'left',
        obj.get('left') +
          obj.get('width') * obj.get('scaleX') / 2 +
          target.get('width') * target.get('scaleX') / 2
      )
    }
  } else if (distY > 0) {
    target.set(
      'top',
      obj.get('top') - target.get('height') * target.get('scaleY')
    )
  } else {
    if (Math.abs(distX) < oScaledWidth / 2) {
      target.set(
        'left',
        obj.get('left') -
          obj.get('width') * obj.get('scaleX') / 2 -
          target.get('width') * target.get('scaleX') / 2
      )
    } else {
      target.set('top', obj.get('top') + obj.get('height') * obj.get('scaleY'))
    }
  }
}

export function onOverlapInteractive(
  options,
  fabricCanvas,
  canvasWidth,
  canvasHeight
) {
  // Sets corner position coordinates based on current angle, width and height
  options.target.setCoords()
  const left = options.target.get('left')
  const top = options.target.get('top')
  const scaleX = options.target.get('scaleX')
  const scaleY = options.target.get('scaleY')
  const width = options.target.get('width')
  const height = options.target.get('height')
  const scaledWidth = width * scaleX
  const scaledHeight = height * scaleY
  const topLeft = {
    x: 0 - (scaledWidth / 2 - left),
    y: 0 - (scaledHeight / 2 - top),
  }
  const bottomRight = { x: scaledWidth / 2 + left, y: scaledHeight / 2 + top }

  // Don't allow objects off the canvas
  if (topLeft.x < snap) {
    options.target.set('left', scaledWidth / 2)
  }

  if (topLeft.y < snap) {
    options.target.set('top', scaledHeight / 2)
  }

  if (bottomRight.x > canvasWidth - snap) {
    options.target.set('left', canvasWidth - scaledWidth / 2)
  }

  if (bottomRight.y > canvasHeight - snap) {
    options.target.set('top', canvasHeight - scaledHeight / 2)
  }

  // Loop through objects
  fabricCanvas.forEachObject((obj) => {
    if (obj === options.target) return

    const oLeft = obj.get('left')
    const oTop = obj.get('top')
    const oScaleX = obj.get('scaleX')
    const oScaleY = obj.get('scaleY')
    const oWidth = obj.get('width')
    const oHeight = obj.get('height')
    const oScaledWidth = oWidth * oScaleX
    const oScaledHeight = oHeight * oScaleY
    const oTopLeft = {
      x: 0 - (oScaledWidth / 2 - oLeft),
      y: 0 - (oScaledHeight / 2 - oTop),
    }
    // const oBottomRight = {x: oScaledWidth/2 + oLeft, y: oScaledHeight / 2 + oTop }

    // If objects intersect
    if (
      options.target.isContainedWithinObject(obj) ||
      options.target.intersectsWithObject(obj) ||
      obj.isContainedWithinObject(options.target)
    ) {
      const distX = oTopLeft.x - bottomRight.x
      const distY = oTopLeft.y - bottomRight.y

      // Set new position
      findNewPos(distX, distY, options.target, obj)
    }

    // Snap objects to each other horizontally

    // If bottom points are on same Y axis
    if (Math.abs(top + scaledHeight - (oTop + oScaledHeight)) < snap) {
      // Snap target BL to object BR
      if (Math.abs(left - (oLeft + oScaledWidth)) < snap) {
        options.target.set('left', oLeft + oScaledWidth)
        options.target.set('top', oTop + oScaledHeight - scaledHeight)
      }

      // Snap target BR to object BL
      if (Math.abs(left + scaledWidth - oLeft) < snap) {
        options.target.set('left', oLeft - scaledWidth)
        options.target.set('top', oTop + oScaledHeight - scaledHeight)
      }
    }

    // If top points are on same Y axis
    if (Math.abs(top - oTop) < snap) {
      // Snap target TL to object TR
      if (Math.abs(left - (oLeft + oScaledWidth)) < snap) {
        options.target.set('left', oLeft + oScaledWidth)
        options.target.set('top', oTop)
      }

      // Snap target TR to object TL
      if (Math.abs(left + scaledWidth - oLeft) < snap) {
        options.target.set('left', oLeft - scaledWidth)
        options.target.set('top', oTop)
      }
    }

    // // Snap objects to each other vertically

    // If right points are on same X axis
    if (Math.abs(left + scaledWidth - (oLeft + oScaledWidth)) < snap) {
      // Snap target TR to object BR
      if (Math.abs(top - (oTop + oScaledHeight)) < snap) {
        options.target.set('left', oLeft + oScaledWidth - scaledWidth)
        options.target.set('top', oTop + oScaledHeight)
      }

      // Snap target BR to object TR
      if (Math.abs(top + scaledHeight - oTop) < snap) {
        options.target.set('left', oLeft + oScaledWidth - scaledWidth)
        options.target.set('top', oTop - scaledHeight)
      }
    }

    // // If left points are on same X axis
    if (Math.abs(left - oLeft) < snap) {
      // Snap target TL to object BL
      if (Math.abs(top - (oTop + oScaledHeight)) < snap) {
        options.target.set('left', oLeft)
        options.target.set('top', oTop + oScaledHeight)
      }

      // Snap target BL to object TL
      if (Math.abs(top + scaledHeight - oTop) < snap) {
        options.target.set('left', oLeft)
        options.target.set('top', oTop - scaledHeight)
      }
    }
  })

  options.target.setCoords()

  // If objects still overlap

  // let outerAreaLeft = null
  // let outerAreaTop = null
  // let outerAreaRight = null
  // let outerAreaBottom = null

  // fabricCanvas.forEachObject((obj) => {
  //   if (obj === options.target) return
  //
  //   if (
  //     options.target.isContainedWithinObject(obj) ||
  //     options.target.intersectsWithObject(obj) ||
  //     obj.isContainedWithinObject(options.target)
  //   ) {
  //     let intersectLeft = null
  //     let intersectTop = null
  //     let intersectWidth = null
  //     let intersectHeight = null
  //     let intersectSize = null
  //     const targetLeft = options.target.get('left')
  //     const targetRight =
  //       targetLeft + options.target.get('width') * options.target.get('scaleX')
  //     const targetTop = options.target.get('top')
  //     const targetBottom =
  //       targetTop + options.target.get('height') * options.target.get('scaleY')
  //     const objectLeft = obj.get('left')
  //     const objectRight = objectLeft + obj.get('width') * obj.get('scaleX')
  //     const objectTop = obj.get('top')
  //     const objectBottom = objectTop + obj.get('height') * obj.get('scaleY')
  //
  //     // Find intersect information for X axis
  //     if (targetLeft >= objectLeft && targetLeft <= objectRight) {
  //       intersectLeft = targetLeft
  //       intersectWidth =
  //         obj.get('width') * obj.get('scaleX') - (intersectLeft - objectLeft)
  //     } else if (objectLeft >= targetLeft && objectLeft <= targetRight) {
  //       intersectLeft = objectLeft
  //       intersectWidth =
  //         options.target.get('width') * options.target.get('scaleX') -
  //         (intersectLeft - targetLeft)
  //     }
  //
  //     // Find intersect information for Y axis
  //     if (targetTop >= objectTop && targetTop <= objectBottom) {
  //       intersectTop = targetTop
  //       intersectHeight =
  //         obj.get('height') * obj.get('scaleY') - (intersectTop - objectTop)
  //     } else if (objectTop >= targetTop && objectTop <= targetBottom) {
  //       intersectTop = objectTop
  //       intersectHeight =
  //         options.target.get('height') * options.target.get('scaleY') -
  //         (intersectTop - targetTop)
  //     }
  //
  //     // Find intersect size (this will be 0 if objects are touching but not overlapping)
  //     if (intersectWidth > 0 && intersectHeight > 0) {
  //       intersectSize = intersectWidth * intersectHeight
  //     }
  //
  //     // Set outer snapping area
  //     if (obj.get('left') < outerAreaLeft || outerAreaLeft == null) {
  //       outerAreaLeft = obj.get('left')
  //     }
  //
  //     if (obj.get('top') < outerAreaTop || outerAreaTop == null) {
  //       outerAreaTop = obj.get('top')
  //     }
  //
  //     if (
  //       obj.get('left') + obj.get('width') * obj.get('scaleX') >
  //         outerAreaRight ||
  //       outerAreaRight == null
  //     ) {
  //       outerAreaRight = obj.get('left') + obj.get('width') * obj.get('scaleX')
  //     }
  //
  //     if (
  //       obj.get('top') + obj.get('height') * obj.get('scaleY') >
  //         outerAreaBottom ||
  //       outerAreaBottom == null
  //     ) {
  //       outerAreaBottom = obj.get('top') + obj.get('height') * obj.get('scaleY')
  //     }
  //
  //     // If objects are intersecting, reposition outside all shapes which touch
  //     if (intersectSize) {
  //       const distX =
  //         outerAreaRight / 2 -
  //         (options.target.get('left') +
  //           options.target.get('width') * options.target.get('scaleX')) /
  //           2
  //       const distY =
  //         outerAreaBottom / 2 -
  //         (options.target.get('top') +
  //           options.target.get('height') * options.target.get('scaleY')) /
  //           2
  //
  //       // Set new position
  //       findNewPos(distX, distY, options.target, obj)
  //     }
  //   }
  // })
}
