/**
 * Exceptions Sagas
 */
import { all, call, put, takeLatest, select } from 'redux-saga/effects'
import { getReports } from '../../api'
import { selectToken } from '../../containers/App/selectors'
// import { loadStores } from './CreateFiltersModal/sagas'
import { makeSelectAsyncLoadingCanceling } from './selectors'
import {
  makeSelectSelectedStores,
  makeSelectCombination,
} from './CreateFiltersModal/selectors'
import { ActionTypes } from './constants'
import {
  fetchExceptionsFulfilled,
  fetchExceptionsFailed,
  clearExceptions,
  asyncLoadingStoresToLoad,
  asyncLoadingCurrentStore,
  asyncLoadingFinished,
} from './actions'
import { mergeExceptions, getDateFormatted } from './utils'
import { createExceptions } from './exceptions'
import { errorToast } from '../../utils/utils'
import { makeSelectStores } from '../StoresPage/selectors'

export function* loadExceptionsAsync() {
  let stores = yield select(makeSelectSelectedStores())
  // Handle initialization case
  if (!stores) {
    stores = yield select(makeSelectStores())
  }
  yield put(clearExceptions())
  const storesCount = (stores || []).length
  yield put(asyncLoadingStoresToLoad(storesCount))
  for (let i = 0; i < storesCount; i += 1) {
    const store = stores[i]
    const isCanceling = yield select(makeSelectAsyncLoadingCanceling())
    if (isCanceling) {
      yield put(asyncLoadingFinished())
      break
    }
    yield put(asyncLoadingCurrentStore(store, i))
    try {
      yield call(loadExceptions, {
        payload: { storeId: (store || {}).id },
      })
    } catch (error) {
      errorToast('Load exceptions failed')
      try {
        const res = error.response
        const errorObj = res ? yield call([res, res.json]) : error
        console.log(errorObj)
        yield put(
          fetchExceptionsFailed((store || {}).id, (store || {}).name, errorObj)
        )
      } catch (e) {
        console.log(e)
        yield put(
          fetchExceptionsFailed((store || {}).id, (store || {}).name, error)
        )
      }
    }
  }
  yield put(asyncLoadingFinished())
}

export function* loadExceptions({ payload }) {
  const { storeId } = payload
  const token = yield select(selectToken)
  const combination = yield select(makeSelectCombination())
  const {
    health: cHealth,
    operations: cOperations,
  } = combination
  // Inn case dates are needed, use ReportsPage example of dates selelcted
  const date = {
    startDate: null,
    endDate: null,
  }
  const { startDate, endDate } = getDateFormatted(date)
  const reportsTypes = [
    cHealth && 'health_exception',
    cOperations && 'operations_exception',
  ].filter((e) => e)
  const data = yield call(
    getReports,
    token,
    storeId,
    startDate,
    endDate,
    reportsTypes
  )

  const {
    operationsException: operations = {},
    healthException: health = {},
  } = data

  const exceptions = mergeExceptions(
    combination,
    health,
    operations,
  )

  yield put(fetchExceptionsFulfilled(storeId, createExceptions(exceptions)))
}

export default function* root() {
  yield all([
    yield takeLatest(ActionTypes.LOAD_EXCEPTIONS_ASYNC, loadExceptionsAsync),
  ])
}
