import { createSelector } from 'reselect'

const selectUpdateNodesStatusReducer = (state) => state.get('updateNodesStatus')

export const makeSelectStatus = () =>
  createSelector(selectUpdateNodesStatusReducer, (state) =>
    state.get('updateStatus')
  )

export const makeSelectLoading = () =>
  createSelector(selectUpdateNodesStatusReducer, (state) =>
    state.get('loading')
  )

export const makeSelectError = () =>
  createSelector(selectUpdateNodesStatusReducer, (state) => state.get('error'))
