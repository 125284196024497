import React from 'react'
import PropTypes from 'prop-types'

class DeleteTemplateWithLinkedFixturesScreen extends React.Component {
  onClose = () => {
    const { onClose, id } = this.props
    $(`#modal-${id}`).modal('hide') // eslint-disable-line
    onClose()
  }

  render() {
    const { template, onViewLinkedFixtures, id } = this.props
    return (
      <div className="modal-content">
        <div className="modal-header">
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={() => {
              $(`#modal-${id}`).modal('hide') // eslint-disable-line
              this.onClose()
            }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          This template has linked fixtures. Please unlink those fixtures before
          deleting template.
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary mr-2"
            onClick={() => {
              $(`#modal-${id}`).modal('hide') // eslint-disable-line
              this.onClose()
            }}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-primary btn-raised"
            onClick={() => {
              onViewLinkedFixtures(template)
            }}
          >
            Linked Fixtures
          </button>
        </div>
      </div>
    )
  }
}

DeleteTemplateWithLinkedFixturesScreen.propTypes = {
  id: PropTypes.string.isRequired,
  template: PropTypes.object.isRequired,
  onViewLinkedFixtures: PropTypes.func.isRequired,
}

export default DeleteTemplateWithLinkedFixturesScreen
