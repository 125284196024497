import { ActionTypes } from '../constants'

/**
 * Schedule Details Action
 *
 * @returns {Object}
 */
export function scheduleDetailsAction(schedule) {
  return {
    type: ActionTypes.SCHEDULE_DETAILS,
    payload: schedule,
  }
}

/**
 * Schedule Details Action
 *
 * @returns {Object}
 */
export function createScheduleAction() {
  return {
    type: ActionTypes.CREATE_SCHEDULE,
  }
}

/**
 * Reset Schedule Management
 *
 * @returns {Object}
 */
export function resetScheduleManagement() {
  return {
    type: ActionTypes.RESET_SCHEDULE_MANAGEMENT,
  }
}

/**
 * Edit Schedule
 *
 * @returns {Object}
 */
export function editSchedule(schedule) {
  return {
    type: ActionTypes.SCHEDULE_EDIT,
    payload: schedule,
  }
}

/**
 * Clear Schedule
 *
 * @returns {Object}
 */
export function clearSchedule() {
  return {
    type: ActionTypes.SCHEDULE_CLEAR,
  }
}

/**
 * Post Schedule Failed
 *
 * @returns {Object}
 */
export function postScheduleFailed(error) {
  return {
    type: ActionTypes.POST_SCHEDULE_FAILED,
    payload: error,
  }
}

/**
 * Post Schedule Fulfilled
 *
 * @returns {Object}
 */
export function postScheduleFulfilled() {
  return {
    type: ActionTypes.POST_SCHEDULE_FULFILLED,
  }
}

/**
 * Post Schedule Pending
 *
 * @returns {Object}
 */
export function postSchedulePending() {
  return {
    type: ActionTypes.POST_SCHEDULE_PENDING,
  }
}

/**
 * Post Schedule
 *
 * @returns {Object}
 */
export function postSchedule(payload) {
  return {
    type: ActionTypes.POST_SCHEDULE,
    payload,
  }
}

/**
 * Delete Schedule
 *
 * @returns {Object}
 */
export function deleteSchedule(payload) {
  return {
    type: ActionTypes.DELETE_SCHEDULE,
    payload,
  }
}

/**
 * Delete Schedule Failed
 *
 * @returns {Object}
 */
export function deleteScheduleFailed(error) {
  return {
    type: ActionTypes.DELETE_SCHEDULE_FAILED,
    payload: error,
  }
}

/**
 * Delete Schedule Fulfilled
 *
 * @returns {Object}
 */
export function deleteScheduleFinished() {
  return {
    type: ActionTypes.DELETE_SCHEDULE_FINISHED,
  }
}

/**
 * Delete Schedule Pending
 *
 * @returns {Object}
 */
export function deleteSchedulePending() {
  return {
    type: ActionTypes.DELETE_SCHEDULE_PENDING,
  }
}

/**
 * Patch Schedule Failed
 *
 * @returns {Object}
 */
export function patchScheduleFailed(error) {
  return {
    type: ActionTypes.PATCH_SCHEDULE_FAILED,
    payload: error,
  }
}

/**
 * Patch Schedule Fulfilled
 *
 * @returns {Object}
 */
export function patchScheduleFulfilled() {
  return {
    type: ActionTypes.PATCH_SCHEDULE_FULFILLED,
  }
}

/**
 * Patch Schedule Pending
 *
 * @returns {Object}
 */
export function patchSchedulePending() {
  return {
    type: ActionTypes.PATCH_SCHEDULE_PENDING,
  }
}

/**
 * Patch Schedule
 *
 * @returns {Object}
 */
export function patchSchedule(id) {
  return {
    type: ActionTypes.PATCH_SCHEDULE,
    payload: id,
  }
}
