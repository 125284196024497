import { toast } from 'react-toastify'

const toastConfig = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
}

export function selectStoreIDFromURL() {
  let path = window.location.pathname
  const pathParams = path.split('/') || []
  const storeIDIndex = pathParams.indexOf('stores') + 1
  return storeIDIndex ? pathParams[storeIDIndex] : null
}

export function isDebugMode() {
  // TODO: This can get fancier in the future,
  // perhaps by looking at an environment variable or something.
  return !!localStorage.getItem('debugMode')
}

export function errorToast(text, errorObj) {
  if (location.pathname === '/login') {
    return
  }
  if (errorObj?.error?.validationErrors) {
    const validationErrors = JSON.stringify(errorObj.error.validationErrors)
      .replace(/["|{|}|[|\]]/g, '')
      .replace(/:/, ': ')
    text = `${text.toUpperCase()}: ${validationErrors}`
  }
  toast.error(text, toastConfig)
}

export function successToast(text) {
  toast.success(text, toastConfig)
}

export function warnToast(text) {
  toast.warn(text, toastConfig)
}

export const loadingType = {
  off: undefined,
  silent: 'silent',
  visible: 'visible',
}

/*
import { ReactHeight } from 'react-height'
import { getTableHeight } from '../../utils/utils'

componentDidMount() {
  if (!window) return
  window.setTimeout(() => this.handleResize(), 10)
  window.addEventListener('resize', this.handleResize)
}

componentWillUnmount () {
  window && window.removeEventListener('resize', this.handleResize)
}

handleResize = () => this.setState({ tableHeight: getTableHeight(this) })

<ReactHeight
  onHeightReady={(height) => this.setState({ headerHeight: height })}
>
</ReactHeight>

style={{ height: tableHeight || 'auto' }}
*/
export const PanelHeight = 58
export const PageHeaderHeight = 60
export const BreadcrumbHeight = 49
export const HeaderHeight = PanelHeight + BreadcrumbHeight

export function getTableHeight() {
  const height = window.innerHeight
  const tableHeight = height - (HeaderHeight + PageHeaderHeight)
  if (isNaN(tableHeight)) return undefined
  return tableHeight
}

export function getQueryVariable(variable) {
  const query = window.location.search.substring(1)
  const vars = query.split('&')
  for (let i = 0; i < vars.length; i += 1) {
    const pair = vars[i].split('=')
    if (decodeURIComponent(pair[0]) === variable) {
      return decodeURIComponent(pair[1])
    }
  }
  return undefined
}

/**
 * Default full text filter for the react-table
 *
 * @param filter
 * @param row
 * @returns {boolean}
 */
export function fullTextFilter(filter, row) {
  const id = filter.pivotId || filter.id
  const reg = new RegExp(filter.value, 'i')
  const testString = row[id] || ' '
  return reg.test(testString)
}
// TODO: move to mtiUtils
export function storeTopic(storeId) {
  return `store:${storeId}/#`
}

export function getBase64File(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(removeBase64Type(reader.result))
    reader.onerror = error => reject(error)
  })
}

export function removeBase64Type(data) {
  return data.split(',').pop()
}
// TODO: move to mtiUtils
export function getFirmwareFolderName(firmwareName) {
  const prefix = firmwareName.split('_')[0]
  const loverCasePrefix = prefix.toLowerCase()
  if (
    loverCasePrefix === 'cs' ||
    loverCasePrefix === '600' ||
    loverCasePrefix === 'dev'
  ) {
    return loverCasePrefix
  }
  return undefined
}

/**
 * Remove Modal Manually, use as last resort
 *
 * @param modalId
 */
export function removeModalManually(modalId) {
  $(`#modal-${modalId}`).modal('hide') // eslint-disable-line
  $('body').removeClass('modal-open') // eslint-disable-line
  $('.modal-backdrop').remove() // eslint-disable-line
}

export const popoverHideMilliseconds = 4000

export function showHidablePopover(element, html, trigger) {
  let initTime
  $(element).attr('data-content', html) // eslint-disable-line
  // eslint-disable-next-line
  $(element).popover({
    trigger: trigger || 'focus',
    html: true,
    content: html,
  })
  // eslint-disable-next-line
  $(element).on('shown.bs.popover', () => {
    initTime = Date.now()
    setTimeout(() => {
      if (Date.now() - initTime >= popoverHideMilliseconds - 100) {
        $(element).popover('hide') // eslint-disable-line
      }
    }, popoverHideMilliseconds)
  })
  $(element).popover('show') // eslint-disable-line
}

export function removeDuplicates(arr, prop) {
  return arr.filter(
    (obj, pos, arr) =>
      arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos
  )
}

export function removeDuplicateStrings(arr) {
  return arr.filter((obj, pos, arr) => arr.indexOf(obj) === pos)
}

// TODO: Temporary
/**
 * Creates organization url if the user has a MTI Admin role
 * or an emty String in all other cases
 *
 * @returns {String}
 */
export function orgUrl() {
  const pathParams = window.location.pathname.split('/') || []
  const url =
    pathParams[1] === 'organizations'
      ? `/organizations/${pathParams[2]}/${pathParams[3]}`
      : ''
  return url
}

// TODO: move to mtiUtils
export function positionPath(position, history) {
  const {
    storeId,
    storeName,
    floorId,
    floorName,
    areaId,
    areaName,
    fixtureId,
    fixtureName,
    id: positionId,
    name: positionName,
  } = position

  const areaComponents = [
    '',
    'stores',
    storeId,
    storeName,
    'floors',
    floorId,
    floorName,
    'areas',
    areaId,
    areaName,
  ]
  const fixtureComponents = ['fixtures', fixtureId, fixtureName]
  const uriComponents = areaComponents
    .concat(fixtureComponents)
    .map(safeUri)
    .join('/')
  const fullPath = `${uriComponents}?positionId=${positionId}&positionName=${safeUri(
    positionName
  )}`

  history.push(`${orgUrl()}${fullPath}`)
}
// TODO: move to mtiUtils
export function fixturePath(fixture, history) {
  const {
    storeId,
    storeName,
    floorId,
    floorName,
    areaId,
    areaName,
    fixtureId,
    fixtureName,
  } = fixture

  const areaComponents = [
    '',
    'stores',
    storeId,
    storeName,
    'floors',
    floorId,
    floorName,
    'areas',
    areaId,
    areaName,
  ]
  const uriComponents = areaComponents.map(safeUri).join('/')
  const fullPath = `${uriComponents}?fixtureId=${fixtureId}&fixtureName=${safeUri(
    fixtureName
  )}`

  history.push(`${orgUrl()}${fullPath}`)
}
// TODO: move to mtiUtils
export function getFirmwareVersion(firmwareName) {
  const regex = /^[///\w_]+Rev_?([\w-]+)\.bin$/
  const result = firmwareName.match(regex)

  /* eslint-disable no-nested-ternary */
  return result ? (result.length === 2 ? result[1] : '') : ''
}
// TODO: move to mtiUtils
export function getFirmwareName(name) {
  const result = name.split('/')
  return result && result.pop()
}

export function isUrl(s) {
  var regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/
  return regexp.test(s)
}

export const emailValidationPattern =
  '[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z][a-zA-Z\\d-]{0,61}[a-zA-Z\\d]$'
export const safeUrlCharactersPattern = '^[^%]*$'
export const invalidUrlCharactersMessage = '% symbol is not allowed'
export const safeRegexStr = str => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')

export function CSVOptions(filename) {
  const options = {
    fieldSeparator: ',',
    filename: filename,
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: false,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: false,
  }

  return options
}

export function safeUri(str) {
  return encodeURIComponent(filterUriCharacters(str))
}

export function filterUriCharacters(str) {
  return (str || '')
    .toString()
    .replace(/[:\\?#\[\]@!$&'"№%±§|`^()*\+,;=,_~\-<>]/gi, '') // eslint-disable-line
}

export function kebabToUppercase(str) {
  return (str || '')
    .split('_')
    .map(s => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ')
}

// From https://github.com/formatjs/react-intl/blob/aefc81f9375081bdff3942fadd25da7652a1090e/docs/Upgrade-Guide-2.x.md#flatten-messages-object
export function flattenMessages(nestedMessages, prefix = '') {
  return Object.keys(nestedMessages).reduce((messages, key) => {
    let value = nestedMessages[key]
    let prefixedKey = prefix ? `${prefix}.${key}` : key

    if (typeof value === 'string') {
      messages[prefixedKey] = value
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey))
    }

    return messages
  }, {})
}

export function humanize(underscored) {
  const cameled = underscored.replace(
    /(^|_)(.)/g,
    (_x, _y, z) => ` ${z.toUpperCase()}`
  )
  const titleized = cameled.replace(/([^ ])([A-Z])/g, (_x, y, z) => `${y} ${z}`)
  return titleized
    .replace(
      /\b(Id|Api|Usb|Ram|Mti|Fw|Ui|Csv|Url|Ii|Iii)(s?)\b/g,
      (_x, ac, pl) => ac.toUpperCase() + pl
    )
    .trim()
}

export function normalizeSentence(sentence) {
  return sentence.trim().replace(/\.?$/, '.')
}

export function deepEqual(x, y) {
  if (x === y) { return true }
  else if ((typeof x == 'object' && x != null) && (typeof y == 'object' && y != null)) {
    if (Object.keys(x).length != Object.keys(y).length) { return false }
    for (var prop in x) {
      if (y.hasOwnProperty(prop)) {
        if (!deepEqual(x[prop], y[prop])) { return false }
      } else { return false }
    }
    return true
  } else { return false }
}
