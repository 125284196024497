/**
 * Me Sagas
 */
import { call, put, select } from 'redux-saga/effects'
import { getMe, getAdminMe } from 'api'
import {
  makeSelectToken,
  makeSelectData,
  makeSelectMe,
  makeSelectMeLoading as makeSelectLoading,
} from '../selectors'
import { fetchMePending, fetchMeFulfilled, fetchMeFailed } from '../actions'
import { fixApiRolesCache } from '../../../utils/mtiUtils'
import { errorToast } from '../../../utils/utils'

export function* loadMeData() {
  const token = yield select(makeSelectToken())
  const data = yield call(getMe, token)
  // TODO: parse
  return data
}

export function* loadAdminMeData() {
  const token = yield select(makeSelectToken())
  const data = yield call(getAdminMe, token)
  const {
    authentications: [{ /* token,*/ expiresAt, tokenId }],
    adminUsers: [
      {
        id,
        firstName,
        lastName,
        avatarId,
        rolesCache = [
          {
            name: 'mti_admin',
            storeId: null,
            resourceId: -100,
            resourceType: 'MTI',
          },
        ],
      },
    ], // phoneNumber, employeeIdentifier }],
    // images,
    // organizations,
  } = data

  const roles = fixApiRolesCache(rolesCache)
  const user = {
    id,
    token,
    tokenId,
    expiresAt,
    avatarId,
    // avatar,
    // saltToken: saltAuthData.token,
    // saltTokenExpiresAt: saltAuthData.expiresAt,
    // phoneNumber,
    firstName,
    lastName,
    // employeeIdentifier,
    name: `${firstName} ${lastName}`,
    mtiRoles: roles,
    // organizationId: organizationId || orgId,
    // organizationName: organizationName || orgName,
  }

  return user
}

export function* loadMe({ payload = {} } = {}) {
  const token = yield select(makeSelectToken())
  if (!token) {
    console.log('Skipping get Me')
    return
  }

  const isFetchMePending = yield select(makeSelectLoading())
  if (isFetchMePending) return
  try {
    yield put(fetchMePending())
    const data = yield call(loadMeData, { payload })
    yield put(fetchMeFulfilled((data.users || [])[0]))
  } catch (error) {
    console.error(error)
    yield put(fetchMeFailed(error))
    errorToast('Load Me failed')
  }
}
