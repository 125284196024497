/**
 *
 * FloorPage
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'

import injectSaga from 'utils/injectSaga'
import injectReducer from 'utils/injectReducer'
import {
  openModal,
  closeModal,
  notifyModal,
  subscribeOnTopic,
} from 'containers/App/actions'
import ConfirmModal from 'containers/ModalContainer/ConfirmModal'
import {
  makeSelectMtiPermission,
  makeSelectStoreIsInDefultAreaMode,
  makeSelectFetchAndUpsertStoreLoading,
  makeSelectFetchAndUpsertGeometryLoading,
  selectStore,
} from '../App/selectors'
import {
  makeSelectAreaNameShownState,
  makeSelectCanvasObject,
  makeSelectCanvasSelectedId,
  makeSelectPositionsByFloor,
  makeSelectAreas,
  makeSelectFloorName,
  makeSelectedAreaName,
  makeSelectedAreaAngle,
  makeSelectFailed,
  makeSelectLoading,
  makeSelectIsStatic,
  makeSelectAreasWithFixtureComplianceIssuesByFloorId,
  makeSelectFloorExist,
  makeSelectPrototypes,
} from './selectors'
import { makeSelectAlertsByStoreId } from '../AlertContainer/selectors'
import reducer from './reducer'
import saga from './sagas'
import {
  floorCanvasChanged,
  removeObject,
  loadCanvas,
  saveCanvas,
  saveArea,
  changeArea,
} from './actions'
import { restoreInitialGeometryCall } from '../App/localStorageOptions'
import FloorScreen from './index.screen'
import ProgressIndicator from '../../components/ProgressIndicator'
import {
  positionPath,
  storeTopic,
  getQueryVariable,
  fixturePath,
  safeUri,
} from '../../utils/utils'
import { orgUrl } from '../../utils/mtiUtils'
import FetchFailedAlert from '../../components/FetchFailed'
import StoreLayoutPreloader from '../../components/Preloaders/StoreLayoutPreloader'
import {
  selectMissingSecuredProductsByFloorId,
  selectMissingSecuredProductsByAreaId,
} from '../ExceptionsList/SecuredProducts/selectors'
import { loadSecuredProducts } from '../SecuredProducts/actions'

export const size = {
  width: 600,
  height: 400,
}

const permText = (text) =>
  `You don't have appropriate rights to access ${text}.\nPlease contact your store manager.`

export class FloorPage extends React.PureComponent {
  constructor(props) {
    super(props)
    const areaId = parseInt(getQueryVariable('areaId') || '-1', 10)
    const areaName = getQueryVariable('areaName')
    const isStatic = getQueryVariable('isStatic') !== 'false'
    const isInitialGeometryCall = restoreInitialGeometryCall()
    this.state = { areaId, areaName, isStatic, isInitialGeometryCall }
  }

  componentDidMount() {
    this.loadCanvas(null, this.state.isStatic, -1)
    this.listenHistory()
    this.props.loadSecuredProducts(this.props.match.params.sId)
  }

  componentDidUpdate(prevProps) {
    const { sId: prevSId, fId: prevFId } = prevProps.match.params
    const { sId: thisSId, fId: thisFId } = this.props.match.params
    //
    const pathParams = window.location.pathname.split('/') || []
    const isMtiAdmin = pathParams[1] === 'organizations'
    const realSId = isMtiAdmin ? pathParams[5] : pathParams[2]
    const realFId = isMtiAdmin ? pathParams[8] : pathParams[5]
    if (prevSId !== thisSId || prevFId !== thisFId) {
      this.loadCanvas(
        { sId: thisSId, fId: thisFId },
        this.props.isStatic,
        this.props.selectedId
      )
      this.props.loadSecuredProducts(this.props.match.params.sId)
    } else if (realSId !== thisSId || realFId !== thisFId) {
      // In case when we update path via react-router-redux push, extra history.push is required
      this.props.history.push(window.location.pathname)
    }
  }

  componentWillUnmount() {
    this.unlistenHistory()
  }

  onSaveCanvas(canvasObject) {
    this.props.saveCanvas({
      canvasObject,
      sId: this.props.match.params.sId,
      fId: this.props.match.params.fId,
    })
  }

  floorThumbnailPayload = (currentImageUrl, thumbnailBlob) => {
    const { floor, match } = this.props
    const isOldThumbnailImage = /canvas_auto_thumbnail\.png$/.test(
      currentImageUrl
    )
    if (!thumbnailBlob) {
      return null
    }
    if (currentImageUrl && !isOldThumbnailImage) {
      return null
    }

    const formData = new FormData()
    const filename = `floor_${floor.id}_canvas_auto_thumbnail.png`
    formData.append('image', thumbnailBlob, filename)
    const imageWithName = formData.get('image')

    const floorWithImage = Object.assign({}, floor, { image: imageWithName })
    const payload = {
      sId: match.params.sId,
      selectedObject: 0, // Store Level
      canvasObject: {
        objects: [floorWithImage],
      },
    }
    return payload
  }

  onPreRemoveArea(canvasObject, selectedId, floorThumbnailBlob) {
    const { openModal: doOpenModal, closeModal: doCloseModal } = this.props
    const fixturesCount = canvasObject.objects.find(
      ({ id }) => id === selectedId
    ).fixturesCount
    doOpenModal({
      id: 'delete',
      type: 'bootstrap',
      appearance: fixturesCount ? 'modal-md' : 'modal-sm',
      content: (
        <ConfirmModal
          id={'delete'}
          questionText={this.questionText(fixturesCount)}
          cancelText={'CANCEL'}
          confirmText={'DELETE AREA'}
          onClose={() => doCloseModal({ id: 'delete' })}
          onConfirm={() => {
            const floorThumbnailPayload = this.floorThumbnailPayload(
              canvasObject.image,
              floorThumbnailBlob
            )
            if (this.state.areaId !== -1) {
              this.setState(
                { areaId: -1 },
                this.onRemoveArea(
                  canvasObject,
                  selectedId,
                  floorThumbnailPayload
                )
              )
              return
            }
            this.onRemoveArea(canvasObject, selectedId, floorThumbnailPayload)
          }}
        />
      ),
    })
  }

  onRemoveArea(canvasObject, selectedId, floorThumbnailPayload) {
    this.props.removeObject({
      canvasObject,
      sId: this.props.match.params.sId,
      fId: this.props.match.params.fId,
      selectedId,
      floorThumbnailPayload,
    })
  }

  onPreSaveArea(canvasObject, selectedId, floorThumbnailBlob) {
    const floorThumbnailPayload = this.floorThumbnailPayload(
      canvasObject.image,
      floorThumbnailBlob
    )
    if (this.state.areaId !== -1) {
      this.setState(
        { areaId: -1 },
        this.onSaveArea(canvasObject, selectedId, null)
      )
      return
    }

    this.onSaveArea(canvasObject, selectedId, floorThumbnailPayload)
  }

  onSaveArea(canvasObject, selectedId, floorThumbnailPayload) {
    this.props.saveArea({
      canvasObject,
      sId: this.props.match.params.sId,
      fId: this.props.match.params.fId,
      selectedId,
      screen: size,
      floorThumbnailPayload,
    })
  }

  onCancel() {
    if (!this.props.isStatic) {
      const { id: sAreaId, name: sAreaName } = this.getSelectedObject()
      const { areaId } = this.state
      if (areaId === sAreaId) {
        const { canvasObject: { objects } = {} } = this.props
        if ((objects || []).length === 1) {
          // Return back case, after Add Areas To Store navigation
          this.props.history.push(
            `${this.getCurrentPath()}/areas/${sAreaId}/${safeUri(sAreaName)}`
          )
          return
        }
      }
    }
    this.loadCanvas(null, true, -1)
  }

  onMessage(msg) {
    const { notifyModal: doNotifyModal } = this.props
    doNotifyModal(msg)
  }

  onOpenArea(obj) {
    const { isStatic } = this.props
    const { id, name } = obj || {}

    if (isStatic && id && name) {
      this.props.history.push(
        `${this.getCurrentPath()}/areas/${id}/${safeUri(name)}`
      )
    }
  }

  navigateToSecuredProducts = (navigateToPath) => {
    const { store, history } = this.props
    navigateToPath(store, history)
  }

  getCurrentPath() {
    const {
      match: { params: { sId, sName, fId, fName } = {} } = {},
    } = this.props
    const components = ['', 'stores', sId, sName, 'floors', fId, fName]
    const uriComponents = components.map(safeUri)

    return `${orgUrl()}${uriComponents.join('/')}`
  }

  getSelectedObject() {
    const { canvasObject, selectedId } = this.props
    return (
      selectedId !== -1 &&
      canvasObject.objects.find(({ id }) => id === selectedId)
    )
  }

  questionText(fixturesCount) {
    if (fixturesCount) {
      return `This area contains ${fixturesCount} fixture(s), that may contain positions. Deleting this area will also delete all fixtures and positions inside. Are you sure you want to proceed?`
    }
    return 'Are you sure you want to proceed?'
  }

  listenHistory() {
    this.unlistenHistory = this.props.history.listen(() => {
      const areaId = parseInt(getQueryVariable('areaId') || '-1', 10)
      const areaName = getQueryVariable('areaName')
      const isStatic = getQueryVariable('isStatic') !== 'false'
      if (this.state.areaId !== areaId) {
        this.setState({ areaId, areaName, isStatic }, () =>
          this.loadCanvas(null, isStatic, -1)
        )
      } else {
        this.loadCanvas(null, isStatic, -1)
      }
    })
  }

  loadCanvas(params, isStatic, selectedId) {
    const newParams = params || this.props.match.params
    const areaId = this.state.areaId
    const id = selectedId !== -1 ? selectedId : areaId || -1
    const topic = storeTopic(newParams.sId)
    this.props.subscribeOnTopic(topic)
    this.props.loadCanvas(size, isStatic, id, newParams.sId, newParams.fId)
  }

  onOpenPosition = ({ positionId }) => {
    const { positions: { positionsFloor }, history } = this.props
    const position = positionsFloor.find(({ id }) => id === positionId)
    positionPath(position, history)
  }

  onOpenFixture = ({ areaId, areaName, id: fixtureId, name: fixtureName }) => {
    const {
      sId: storeId,
      sName: storeName,
      fId: floorId,
      fName: floorName,
    } = this.props.match.params
    const fixture = {
      storeId,
      storeName,
      floorId,
      floorName,
      areaId,
      areaName,
      fixtureId,
      fixtureName,
    }

    fixturePath(fixture, this.props.history)
  }

  onGoToArea = (areaWithIssue) => {
    const { isStatic } = this.props
    const { id, name } = areaWithIssue || {}

    if (isStatic && id && name) {
      this.setState({ areaId: id })
      this.props.history.push(
        `${this.getCurrentPath()}?areaId=${id}&areaName=${safeUri(name)}`
      )
    }
  }

  noPermission = (hasPermission, text) => {
    const { notifyModal: doNotifyModal } = this.props
    if (!hasPermission) doNotifyModal(text)
    return !hasPermission
  }

  render() {
    const {
      floorCanvasChanged: onChanged,
      canvasObject,
      selectedId,
      positions,
      areas,
      floor,
      loading,
      storeLoading,
      geometryLoading,
      floorExists,
      failed,
      isStatic,
      changeArea: onChange,
      selectedAreaName,
      selectedAreaAngle,
      selectedAreaShownState,
      hasStoreEditorPermission,
      isDefaultAreaMode,
      prototypes,
      areasWithIssues,
      alerts,
      missingSecuredProductsByFloorId,
      missingSecuredProductsByAreaId,
      match: { params: { fId } },
    } = this.props
    const localLoading = this.state.isInitialGeometryCall
      ? geometryLoading && !floorExists
      : loading || (storeLoading && !floorExists)
    if (localLoading)
      return (
        <React.Fragment>
          <StoreLayoutPreloader />
          <ProgressIndicator text={'Loading...'} />
        </React.Fragment>
      )
    if (failed) return <FetchFailedAlert failed={failed} />

    return (
      <FloorScreen
        path={this.getCurrentPath()}
        positions={positions}
        areas={areas}
        floor={floor}
        canvasSize={size}
        isDefaultAreaMode={isDefaultAreaMode}
        prototypes={prototypes}
        onSave={() => this.onSaveCanvas(canvasObject)}
        onCanvasChanged={(o) => {
          if (!o.isStatic) {
            if (
              this.noPermission(
                hasStoreEditorPermission,
                permText('Store Editor')
              )
            ) {
              return
            }
          }
          onChanged(o)
        }}
        canvasObject={canvasObject}
        selectedId={selectedId}
        isStatic={isStatic}
        onRemove={(selectedId, blob) =>
          this.onPreRemoveArea(canvasObject, selectedId, blob)
        }
        onAreaChange={onChange}
        onAreaSave={(selectedId, blob) =>
          this.onPreSaveArea(canvasObject, selectedId, blob)
        }
        onOpenArea={(obj) => this.onOpenArea(obj)}
        onMessage={(msg) => this.onMessage(msg)}
        onEdit={() => {
          if (
            this.noPermission(
              hasStoreEditorPermission,
              permText('Store Editor')
            )
          ) {
            return
          }
          this.loadCanvas(null, false, selectedId)
        }}
        onCancel={() => this.onCancel()}
        selectedAreaName={selectedAreaName}
        selectedAreaAngle={selectedAreaAngle}
        selectedAreaHasShown={selectedAreaShownState}
        openPosition={this.onOpenPosition}
        areasWithIssues={areasWithIssues}
        openFixture={this.onOpenFixture}
        goToArea={this.onGoToArea}
        alerts={alerts}
        missingSecuredProductsForFloor={missingSecuredProductsByFloorId[fId]}
        missingSecuredProductsByAreaId={missingSecuredProductsByAreaId}
        onGoToSecuredProducts={this.navigateToSecuredProducts}
      />
    )
  }
}

FloorPage.propTypes = {
  match: PropTypes.any,
  history: PropTypes.any,
  params: PropTypes.shape({
    sId: PropTypes.string.isRequired,
    fId: PropTypes.string.isRequired,
  }),
  loadCanvas: PropTypes.func.isRequired,
  saveCanvas: PropTypes.func.isRequired,
  saveArea: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  storeLoading: PropTypes.bool,
  geometryLoading: PropTypes.bool,
  floorExists: PropTypes.bool,
  failed: PropTypes.object,
  isStatic: PropTypes.bool,
  isDefaultAreaMode: PropTypes.bool,
  prototypes: PropTypes.object,
  floorCanvasChanged: PropTypes.func.isRequired,
  removeObject: PropTypes.func.isRequired,
  changeArea: PropTypes.func.isRequired,
  canvasObject: PropTypes.object.isRequired,
  areas: PropTypes.array.isRequired,
  selectedId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  positions: PropTypes.object,
  floor: PropTypes.object,
  selectedAreaName: PropTypes.string,
  selectedAreaAngle: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  selectedAreaShownState: PropTypes.bool,
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
  notifyModal: PropTypes.func,
  hasStoreEditorPermission: PropTypes.bool,
  subscribeOnTopic: PropTypes.func,
  areasWithIssues: PropTypes.array,
}

// TODO: Evaluate which of these makeSelects are not required and refactor
const mapStateToProps = (state, ownProps) => {
  const getCanvasObject = makeSelectCanvasObject(ownProps.match.params.fId)
  const getSelectedId = makeSelectCanvasSelectedId()
  const getPositionsByFloor = makeSelectPositionsByFloor(
    ownProps.match.params.fId
  )
  const getAlertsByStoreId = makeSelectAlertsByStoreId(
    ownProps.match.params.sId
  )
  const getAreas = makeSelectAreas()
  const getAreasWithFixtureComplianceIssuesByFloorId = makeSelectAreasWithFixtureComplianceIssuesByFloorId(
    ownProps.match.params.fId
  )
  const getFloorName = makeSelectFloorName()
  const getLoading = makeSelectLoading()
  const getFetchAndUpsertStoreLoading = makeSelectFetchAndUpsertStoreLoading(
    ownProps.match.params.sId
  )
  const getFetchAndUpsertGeometryLoading = makeSelectFetchAndUpsertGeometryLoading(
    ownProps.match.params.sId
  )
  const getFloorExists = makeSelectFloorExist(ownProps.match.params.fId)
  const getFailed = makeSelectFailed()
  const getIsStatic = makeSelectIsStatic()
  const getSelectedAreaName = makeSelectedAreaName()
  const getSelectedAreaAngle = makeSelectedAreaAngle()
  const getAreaNameShownState = makeSelectAreaNameShownState()
  const getStoreIsInDefultAreaMode = makeSelectStoreIsInDefultAreaMode(
    ownProps.match.params.sId
  )
  const getPrototypes = makeSelectPrototypes()
  const getSelectMtiPermission = makeSelectMtiPermission(
    'Store: Editor',
    ownProps.match.params.sId
  )

  return (state, ownProps) => ({
    canvasObject: getCanvasObject(state, ownProps),
    selectedId: getSelectedId(state),
    positions: getPositionsByFloor(state, ownProps),
    alerts: getAlertsByStoreId(state),
    areas: getAreas(state),
    areasWithIssues: getAreasWithFixtureComplianceIssuesByFloorId(
      state,
      ownProps
    ),
    floor: getFloorName(state),
    loading: getLoading(state),
    storeLoading: getFetchAndUpsertStoreLoading(state, ownProps),
    geometryLoading: getFetchAndUpsertGeometryLoading(state, ownProps),
    floorExists: getFloorExists(state, ownProps),
    failed: getFailed(state),
    isStatic: getIsStatic(state),
    selectedAreaName: getSelectedAreaName(state),
    selectedAreaAngle: getSelectedAreaAngle(state),
    selectedAreaShownState: getAreaNameShownState(state),
    isDefaultAreaMode: getStoreIsInDefultAreaMode(state, ownProps),
    prototypes: getPrototypes(state),
    hasStoreEditorPermission: getSelectMtiPermission(state, ownProps),
    missingSecuredProductsByFloorId: selectMissingSecuredProductsByFloorId(
      state,
      ownProps.match.params.sId
    ),
    missingSecuredProductsByAreaId: selectMissingSecuredProductsByAreaId(
      state,
      ownProps.match.params.sId,
      ownProps.match.params.fId
    ),
    store: selectStore(state, ownProps.match.params.sId),
  })
}

const mapDispatchToProps = {
  floorCanvasChanged,
  removeObject,
  loadCanvas,
  saveCanvas,
  saveArea,
  changeArea,
  openModal,
  closeModal,
  notifyModal,
  subscribeOnTopic,
  loadSecuredProducts,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const withReducer = injectReducer({ key: 'floorCanvas', reducer })
const withSaga = injectSaga({ key: 'floorCanvas', saga })

export default compose(withReducer, withSaga, withConnect)(FloorPage)
