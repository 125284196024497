import React from 'react'
import PropTypes from 'prop-types'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { makeSelectRegions } from '../selectors'
import {
  makeSelectFailed as makeSelectStoresFailed,
  makeSelectLoading as makeSelectStoresLoading,
  makeSelectStores,
} from '../../StoresPage/selectors'
import {
  makeSelectMtiPermissionAnyResource,
  makeSelectRegionEditFailed,
  makeSelectRegionEditLoading,
} from '../../App/selectors'
import ProgressIndicator from '../../../components/ProgressIndicator'
import RegionModalPreloader from '../../../components/Preloaders/RegionModalPreloader'
import CreateEditRegionScreen from './index.screen'
import FetchFailedAlert from '../../../components/FetchFailed'
import { loadStores } from '../../StoresPage/actions'

export class CreateEditRegionModal extends React.PureComponent {
  componentDidMount() {
    this.loadStores()
  }

  componentDidUpdate() {
    const { regionEditFailed } = this.props

    if (regionEditFailed && this.createRegionModal) {
      this.createRegionModal.setServerValidity(regionEditFailed)
    }
  }

  setServerValidity(error) {
    if (this.createRegionModal) this.createRegionModal.setServerValidity(error)
  }

  close() {
    if (this.createRegionModal) this.createRegionModal.close()
  }

  loadStores = () => {
    this.props.loadStores()
  }

  render() {
    const {
      id,
      region,
      stores,
      loading,
      failed,
      onChange,
      title,
      confirmText,
      onClose,
      onConfirm,
      regionEditLoading,
    } = this.props
    if (loading) {
      return (
        <div className="modal-content">
          <React.Fragment>
            <RegionModalPreloader />
            <ProgressIndicator />
          </React.Fragment>
        </div>
      )
    }
    if (failed) return <FetchFailedAlert failed={failed} />

    return (
      <CreateEditRegionScreen
        ref={(ref) => {
          this.createRegionModal = ref
        }}
        id={id}
        region={region}
        stores={(stores || []).map((store) => ({ ...store }))}
        onChange={onChange}
        title={title}
        confirmText={confirmText}
        onClose={onClose}
        onConfirm={onConfirm}
        regionEditLoading={regionEditLoading}
      />
    )
  }
}

CreateEditRegionModal.propTypes = {
  regionEditLoading: PropTypes.bool,
  regionEditFailed: PropTypes.object,
  onChange: PropTypes.func,
  id: PropTypes.string.isRequired,
  region: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  confirmText: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  loadStores: PropTypes.func.isRequired,
  stores: PropTypes.array,
  loading: PropTypes.bool,
  failed: PropTypes.object,
}

const mapStateToProps = createStructuredSelector({
  regionEditLoading: makeSelectRegionEditLoading(),
  regionEditFailed: makeSelectRegionEditFailed(),
  stores: makeSelectStores(),
  regions: makeSelectRegions(),
  loading: makeSelectStoresLoading(),
  failed: makeSelectStoresFailed(),
  hasViewRegionsPermission: makeSelectMtiPermissionAnyResource(
    'Store: Index (list) Records'
  ),
})

const mapDispatchToProps = {
  loadStores,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect)(CreateEditRegionModal)
