import { ActionTypes } from '../constants'

/**
 * Secured Product Details Action
 *
 * @returns {Object}
 */
export function securedProductDetailsAction(securedProduct) {
  return {
    type: ActionTypes.SECURED_PRODUCT_DETAILS,
    payload: { securedProduct },
  }
}

/**
 * Reset Secured Product Management
 *
 * @returns {Object}
 */
export function resetSecuredProductManagement() {
  return {
    type: ActionTypes.RESET_SECURED_PRODUCT_MANAGEMENT,
  }
}

/**
 * Edit Secured Product
 *
 * @returns {Object}
 */
export function editSecuredProduct(changedData) {
  return {
    type: ActionTypes.SECURED_PRODUCT_EDIT,
    payload: changedData,
  }
}

/**
 * Patch Secured Product Failed
 *
 * @returns {Object}
 */
export function patchSecuredProductFailed(error) {
  return {
    type: ActionTypes.PATCH_SECURED_PRODUCT_FAILED,
    payload: { error },
  }
}

/**
 * Patch Secured Product Fulfilled
 *
 * @returns {Object}
 */
export function patchSecuredProductFulfilled() {
  return {
    type: ActionTypes.PATCH_SECURED_PRODUCT_FULFILLED,
  }
}

/**
 * Patch Secured Product Pending
 *
 * @returns {Object}
 */
export function patchSecuredProductPending() {
  return {
    type: ActionTypes.PATCH_SECURED_PRODUCT_PENDING,
  }
}

/**
 * Patch Secured Product
 *
 * @returns {Object}
 */
export function patchSecuredProduct(id) {
  return {
    type: ActionTypes.PATCH_SECURED_PRODUCT,
    payload: id,
  }
}
