import styled from 'styled-components'
import ReactTable from 'react-table'

export const TableInModal = styled(ReactTable)`
  max-height: 525px;
`

export const BorderedButton = styled.button`
  border: 1px solid #64b5f6 !important;
`

export const FlatBorderedButton = styled.button`
  border: 1px solid #64b5f6;
  color: #4e92df;
  cursor: pointer;
  position: relative;
  margin-bottom: 0.3125rem;
  font-size: 0.875rem;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0;
  outline: 0;
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1),
    background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1),
    color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  will-change: box-shadow, transform;
  display: inline-block;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  padding: 0.46875rem 1rem;
  line-height: 1.5;
  border-radius: 0.125rem;
`

export const Overlay = styled.div`
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  opacity: 0.8;
  z-index: 1;
`

export const CellContainerFixed = styled.div`
  overflow: hidden;
  max-height: 32px;
  -webkit-mask-image: -webkit-gradient(
    linear,
    left 90%,
    left bottom,
    from(rgba(0, 0, 0, 1)),
    to(rgba(0, 0, 0, 0))
  );
`

export const TopContainer = styled.div`
  overflow-y: scroll;
`
TopContainer.defaultProps = {
  flex: 1,
}

export const SidebarBody = styled.div`
  flex: 1 1 0;
  overflow: auto;
  & > div {
    overflow: auto;
    flex-shrink: 0;
  }
`
export const dottedBackground = `
  background-image: linear-gradient(to right, transparent 80%, #223049 50%);
  background-size: 3px 1px;
  background-repeat: repeat-x;
  background-position: bottom;
`

export const ModalHeader = styled.div`
  border-bottom: 1px solid lightgray;
  padding-bottom: 1rem;
  text-align: center;
  justify-content: center;
`

export const AlertIcon = styled.img`
  text-align: right;
  width: 20px;
  margin-right: 8px;
`

export const AlertButton = styled.button`
  margin-left: 20px;
  border: 2px;
  cursor: pointer;
  color: white;
  text-transform: uppercase;
  background-color: rgba(0, 128, 0, 0.75);
`
const ButtonStoreAction = styled.button`
  width: 165px;
  user-select: none;
  font-weight: 500;
  cursor: pointer;
  position: relative;
  letter-spacing: 0;
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1),
    background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1),
    color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  font-size: 0.875rem;
  text-decoration: none;
  text-transform: uppercase;
  outline: 0;
  will-change: box-shadow, transform;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  line-height: 1.5;
  border-radius: 0.125rem;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-right: 30px;
`

export const StoreActionButton = styled(ButtonStoreAction)`
  background-color: rgba(255, 255, 255, 0.85);
  border: 1px solid rgba(255, 255, 255, 0.85);
  color: #000000;
  &:hover {
    color: #ffffff;
    background-color: rgba(255, 255, 255, 0.15);
    border: 1px solid rgba(255, 255, 255, 0.15);
  }
`

export const StoreActionButtonActive = styled(ButtonStoreAction)`
  background-color: rgba(255, 255, 255, 0.15);
  color: #ffffff;
  &:hover {
    color: #000000;
    background-color: rgba(255, 255, 255, 0.85);
    border: 1px solid rgba(255, 255, 255, 0.85);
  }
`
