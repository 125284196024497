import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import EditDisabled from '../../../../components/EditDisabled'
import { injectIntl } from 'react-intl'

const intlPath = (string) => `securedProduct.${string}`

const renderMainContent = (securedProduct, intl) => {
  const {
    productName,
    imageSerialNumber,
    serialNumber,
    updatedAt,
    createdAt,
    position,
    manufacturer,
    notes,
    category,
    retiredReason,
  } = securedProduct
  const formattedCreatedAt = createdAt
    ? moment(createdAt).format('MMM D YYYY hh:mm A')
    : ''
  const formattedUpdatedAt = updatedAt
    ? moment(updatedAt).format('MMM D YYYY hh:mm A')
    : ''
  const retiredReasonText = retiredReason
    ? intl.formatMessage({ id: intlPath(`retiredReasons.${retiredReason}`) })
    : ''
  const categoryText = category
    ? intl.formatMessage({ id: intlPath(`categories.${category}`) })
    : ''
  return (
    <div className="col-xs-6">
      <div className="form-row">
        <div className="form-group col-md-6 bmd-form-group">
          <EditDisabled
            header={intl.formatMessage({ id: intlPath('manufacturer') })}
            text={manufacturer ? manufacturer.name : ''}
          />
        </div>
        <div className="form-group col-md-6 bmd-form-group">
          <EditDisabled
            header={intl.formatMessage({ id: intlPath('model') })}
            text={productName || ''}
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md-6 bmd-form-group">
          <EditDisabled
            header={intl.formatMessage({ id: 'position' })}
            text={position.name || ''}
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md-6 bmd-form-group">
          <EditDisabled
            header={intl.formatMessage({ id: 'serialNumber' })}
            text={serialNumber || ''}
          />
        </div>
        <div className="form-group col-md-6 bmd-form-group">
          <EditDisabled
            header={intl.formatMessage({
              id: intlPath('imageClassSerialNumber'),
            })}
            text={imageSerialNumber || ''}
          />
        </div>
      </div>

      <div className="form-row">
        <div className="form-group col-md-6 bmd-form-group">
          <EditDisabled
            header={intl.formatMessage({ id: intlPath('category') })}
            text={categoryText}
          />
        </div>
        <div className="form-group col-md-6 bmd-form-group">
          <EditDisabled
            header={intl.formatMessage({ id: intlPath('retiredReason') })}
            text={retiredReasonText}
          />
        </div>
      </div>

      <div className="form-row">
        <div className="form-group col-md-12 bmd-form-group">
          <EditDisabled
            header={intl.formatMessage({ id: intlPath('notes') })}
            text={notes || ''}
          />
        </div>
      </div>

      <div className="form-row">
        <div className="form-group col-md-6 bmd-form-group">
          <EditDisabled
            header={intl.formatMessage({ id: 'createdAt' })}
            text={formattedCreatedAt}
          />
        </div>
        <div className="form-group col-md-6 bmd-form-group">
          <EditDisabled
            header={intl.formatMessage({ id: 'updatedAt' })}
            text={formattedUpdatedAt}
          />
        </div>
      </div>
    </div>
  )
}

function SecuredProductDetailsScreen({
  id,
  securedProduct,
  title,
  confirmText,
  onClose,
  onConfirm,
  intl,
}) {
  return (
    <div className="modal-content">
      <div className="modal-header border-bottom">
        <h5 className="modal-title" id="exampleModalLongTitle">
          {title}
        </h5>
        <div>
          {onConfirm && (
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                $(`#modal-${id}`).modal('hide') // eslint-disable-line
                onConfirm()
              }}
            >
              {confirmText}
            </button>
          )}
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              $(`#modal-${id}`).modal('hide') // eslint-disable-line
              onClose()
            }}
          >
            Close
          </button>
        </div>
      </div>
      <div className="modal-body">
        {renderMainContent(securedProduct, intl)}
      </div>
    </div>
  )
}

SecuredProductDetailsScreen.propTypes = {
  securedProduct: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  confirmText: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
}

export default injectIntl(SecuredProductDetailsScreen)
