import { ActionTypes } from '../App/constants'

/**
 * Load Regions
 *
 * @returns {Object}
 */
export function loadRegions() {
  return {
    type: ActionTypes.LOAD_REGIONS,
  }
}

/**
 * Fetch Regions Failed
 *
 * @returns {Object}
 */
export function loadRegionWithStoresAction(id) {
  return {
    type: ActionTypes.FETCH_REGION_WITH_STORE,
    id: id,
  }
}

/**
 * Fetch Regions Fulfilled
 *
 * @returns {Object}
 */
export function fetchRegionWithStoresFulfilled(data) {
  return {
    type: ActionTypes.FETCH_REGION_WITH_STORE_FULFILLED,
    payload: data,
  }
}

/**
 * Fetch Regions Pending
 *
 * @returns {Object}
 */
export function fetchRegionsPending() {
  return {
    type: ActionTypes.FETCH_REGIONS_PENDING,
  }
}

/**
 * Fetch Regions Fulfilled
 *
 * @returns {Object}
 */
export function fetchRegionsFulfilled(data) {
  return {
    type: ActionTypes.FETCH_REGIONS_FULFILLED,
    payload: data,
  }
}

/**
 * Fetch Regions Failed
 *
 * @returns {Object}
 */
export function fetchRegionsFailed(error) {
  return {
    type: ActionTypes.FETCH_REGIONS_FAILED,
    payload: error,
  }
}
