import React from 'react'
import PropTypes from 'prop-types'
import {
  closeModal,
  notifyModal,
  openModal,
} from '../../containers/App/actions'
import connect from 'react-redux/es/connect/connect'
import { compose } from 'redux'
import { isIos } from '../../utils/screen'

class MobileNotSupport extends React.PureComponent {
  render() {
    const { text, notifyModal, children } = this.props
    if (isIos()) {
      return (
        <a
          role="button"
          aria-disabled
          className="btn btn-primary mb-0"
          onClick={() =>
            notifyModal(
              'Export to CSV is not supported on iPad. Please use a device with a different Operation System.'
            )
          }
        >
          {text || 'OK'}
        </a>
      )
    }

    return children
  }
}

MobileNotSupport.propTypes = {
  text: PropTypes.string,
  notifyModal: PropTypes.func.isRequired,
  children: PropTypes.node,
}

const mapDispatchToProps = {
  openModal,
  closeModal,
  notifyModal,
}

const withConnect = connect(null, mapDispatchToProps)

export default compose(withConnect)(MobileNotSupport)
