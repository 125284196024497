import { ActionTypes } from './constants'

/**
 * Update Nodes Failed
 *
 * @returns {Object}
 */
export function updateNodesFailed(error) {
  return {
    type: ActionTypes.UPDATE_NODES_FAILED,
    payload: { error },
  }
}

/**
 * Update Nodes Fulfilled
 *
 * @returns {Object}
 */
export function updateNodesFulfilled(status) {
  return {
    type: ActionTypes.UPDATE_NODES_FULFILLED,
    payload: { status },
  }
}

/**
 * Update Nodes Pending
 *
 * @returns {Object}
 */
export function updateNodesPending() {
  return {
    type: ActionTypes.UPDATE_NODES_PENDING,
  }
}

/**
 * Update Nodes
 *
 * @returns {Object}
 */
export function updateNodes(data) {
  return {
    type: ActionTypes.UPDATE_NODES,
    payload: data,
  }
}
