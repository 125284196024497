import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const FixtureNameBreadcrumb = (
  { match: { key, params: { fxName } } } // url,
) => (
  <Link
    key={key}
    className="breadcrumb-item"
    to={'#'}
    onClick={(e) => e.preventDefault()}
  >
    {decodeURIComponent(fxName)}
  </Link>
)

FixtureNameBreadcrumb.propTypes = {
  match: PropTypes.object,
}

export default FixtureNameBreadcrumb
