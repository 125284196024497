import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import injectReducer from '../../utils/injectReducer'
import injectSaga from '../../utils/injectSaga'
import { deepEqual } from '../../utils/utils'
import saga from './sagas'
import reducer from './reducers'
import { Namespace, request } from './actions'
import StoresDashboardScreen from './index.screen'
import { selectLoading, selectData } from './selectors'

const defaultPageSize = 25
export class StoresDashboard extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      query: { },
    }
  }

  componentDidMount() {
    const { request } = this.props
    request({ per: defaultPageSize })
  }

  // This is what will be called whenever the user clicks to go to the
  // next page (which will be a type of filter on the filters object), or
  // if they change the filters in some way, causing the page to refresh.
  _loadPage(query) {
    const { request } = this.props
    if (!deepEqual(this.state.query, query)) {
      this.setState({ query })
      request(query)
    }
  }

  render() {
    const {
      isLoading, // This is a boolean representing whether we're currently fetching data
      data, // The latest API response
    } = this.props

    return (
      <StoresDashboardScreen
        isLoading={isLoading}
        onQuery={query => this._loadPage(query)}
        data={data}
        defaultPageSize={defaultPageSize}
      />
    )
  }
}

const mapStateToProps = state => ({
  isLoading: selectLoading(state),
  data: selectData(state),
})

const mapDispatchToProps = {
  request,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)
const withReducer = injectReducer({ key: Namespace, reducer })
const withSaga = injectSaga({ key: Namespace, saga })

export default compose(withReducer, withSaga, withConnect)(StoresDashboard)
