import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { PropTypes } from 'prop-types'
import StoreTile from '../StoreTile'
import styled from 'styled-components'

const Container = styled.div`
  margin-left: 60px;
  margin-right: 50px;
`

const StoresSelection = ({ stores, onSelect, activeIndex }) => {
  let isSliding = false

  const onClick = (index) => {
    if (isSliding) {
      return
    }

    onSelect(index)
  }

  const imageUrl = (store) => {
    if (!store.floors || !store.floors.length) return null
    return store.floors[0].previewImageUrl
  }

  const renderStoreTile = (store, index) => {
    const { id, name } = store
    return (
      <StoreTile
        key={id}
        title={name}
        onSelect={() => onClick(index)}
        active={activeIndex === index}
        previewImgUrl={imageUrl(store)}
      />
    )
  }
  const switchArrowSize = 36
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    swipeToSlide: true,
    draggable: false,
    initialSlide: activeIndex,
    responsive: [
      {
        breakpoint: 1921 + switchArrowSize * 2,
        settings: {
          slidesToShow: 8,
        },
      },
      {
        breakpoint: 1781 + switchArrowSize * 2,
        settings: {
          slidesToShow: 7,
        },
      },
      {
        breakpoint: 1621 + switchArrowSize * 2,
        settings: {
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 1351 + switchArrowSize * 2,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 1230 + switchArrowSize * 2,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 600 + switchArrowSize * 2,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480 + switchArrowSize * 2,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  }

  return (
    <Container>
      <Slider
        {...settings}
        beforeChange={() => {
          isSliding = true
        }}
        afterChange={() => {
          isSliding = false
        }}
      >
        {stores.map((store, index) => renderStoreTile(store, index))}
      </Slider>
    </Container>
  )
}

StoresSelection.propTypes = {
  stores: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  activeIndex: PropTypes.number,
}

export default StoresSelection
