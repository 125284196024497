import React from 'react'
import QrReader from 'react-qr-reader'
import { isUrl } from '../../utils/utils'

export default class QRReader extends React.Component {
  state = {
    result: null,
  }

  handleScan = (data) => {
    if (data) {
      console.log('TCL: QRReader -> data', data)
      if (isUrl(data)) {
        this.setState({ result: null })
        location.href = data
      } else {
        this.setState({
          result: data,
        })
      }
    }
  }

  handleError = (err) => {
    this.setState({ result: `${err}` })
    console.warn(err)
  }

  render() {
    const { result } = this.state
    return (
      <div style={{ width: '300px' }}>
        <QrReader
          delay={1000}
          onError={this.handleError}
          onScan={this.handleScan}
          style={{ width: '300px' }}
        />
        <p className="m-2">{result || 'No result yet, scan QR'}</p>
      </div>
    )
  }
}

QRReader.propTypes = {}
