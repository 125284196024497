import React from 'react'
import PropTypes from 'prop-types'
import MulticardColumn from '../../components/MulticardColumn'
import {
  Container,
  ColumnsContainer,
} from '../HomePage/StoreManagerOrStaff/index.screen'
import Firmwares from '../FirmwaresPage'
import Status from '../StatusPage'
import StoresList from './StoresList'

const OTAHomeScreen = ({ history, height }) => (
  <Container>
    <ColumnsContainer className="row no-gutters justify-content-md-center">
      <MulticardColumn
        height={height}
        minWidth={600}
        items={[
          {
            component: <StoresList history={history} />,
          },
        ]}
      />
      <MulticardColumn
        height={height}
        minWidth={400}
        items={[
          {
            component: <Firmwares isOTAHome history={history} />,
          },
          {
            component: <Status isOTAHome history={history} />,
          },
        ]}
      />
    </ColumnsContainer>
  </Container>
)

OTAHomeScreen.propTypes = {
  height: PropTypes.number,
  history: PropTypes.object.isRequired,
}

export default OTAHomeScreen
