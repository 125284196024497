/*
 * Stores Constants
 */

import keyMirror from 'fbjs/lib/keyMirror'

/**
 * @constant {Object} ActionTypes
 * @memberof Constants
 */
export const ActionTypes = keyMirror({
  EDIT_PASSWORD: undefined,
  POST_PASSWORD_FAILED: undefined,
  POST_PASSWORD_FULFILLED: undefined,
  POST_PASSWORD_PENDING: undefined,
  POST_PASSWORD_SUCCESS: undefined,
  POST_PASSWORD: undefined,
})
