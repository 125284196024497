import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import {
  HeaderContainer,
  CellContainer,
} from '../UsersPage/index.screen'
import ReactTable from 'react-table';
import { FormattedMessage, injectIntl } from 'react-intl'
import { DeviceType, HealthStateValue, ArmState } from 'mti-jsclient-shared'
import TooltipCell from '../../components/TooltipCell'

const intlID = str => `containers.orphanedDevices.${str}`

const puckDevices = [
  DeviceType.PUCK,
  DeviceType.PUCK_V2,
  DeviceType.PROXIMITY_PUCK,
]

const healthStates = {
  [HealthStateValue.HEALTHY]: 'healthy',
  [HealthStateValue.UNHEALTHY]: 'unhealthy',
}

class OrphanedDevicesScreen extends React.PureComponent {
  _fetchData(reactTableState) {
    // pagination
    const pagination = {
      per: reactTableState.pageSize,
      page: reactTableState.page+1,
    }

    // dispatch a call to the API to update the table
    this.props.onFilter(pagination)
  }

  render() {
    const {
      tableViewData: {
        securityDevices,
        meta, // currentPage, perPage, total
      },
      loading,
      defaultPageSize,
    } = this.props
    const columns = this._createColumnSchema()
    const totalPages = Math.ceil(meta.total / meta.perPage)

    return (
      <div>
        <HeaderContainer className="border-bottom">
        </HeaderContainer>
        <ReactTable
          className="-striped"
          columns={columns}
          data={securityDevices}
          manual // Forces table not to paginate or sort automatically, so we can handle it server-side
          multiSort={false}
          pages={totalPages} // Display the total number of pages
          defaultPageSize={defaultPageSize}
          loading={loading} // Display the loading overlay when we need it
          onFetchData={(state, instance) => this._fetchData(state, instance)} // Request new data when things change
        />
      </div>
    )
  }

  _createColumnSchema() {
    const { intl } = this.props
    return [{
      id: 'serialNumber',
      accessor: 'serialNumber',
      Header: intl.formatMessage({ id: 'serialNumber' }),
      filterable: false,
      sortable: false,
    },{
      id: 'deviceType',
      accessor: 'deviceType',
      Header: intl.formatMessage({ id: intlID('deviceType') }),
      filterable: false,
      sortable: false,
      Cell: DeviceTypeCell,
    }]
  }
}

const DeviceTypeCell = function({ original: { deviceType } }) {
  let message = `deviceTypes.${deviceType}`
  return (
    <CellContainer>
      <FormattedMessage id={message} />
    </CellContainer>
  )
}

OrphanedDevicesScreen.propTypes = {
  tableViewData: PropTypes.shape({
    securityDevices: PropTypes.array.isRequired,
  }),
  onFilter: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  defaultPageSize: PropTypes.number.isRequired,
}

export default injectIntl(OrphanedDevicesScreen)
