export function isIos() {
  const IS_IPAD = navigator.userAgent.match(/iPad/i) != null
  const IS_IPHONE =
    navigator.userAgent.match(/iPhone/i) != null ||
    navigator.userAgent.match(/iPod/i) != null
  return IS_IPAD || IS_IPHONE
}

export function isAndroid() {
  return navigator.userAgent.match(/android/i) != null
}

export function isMobile() {
  return isIos() || isAndroid()
}

// width model
// 360 samsung S8
// 375 iphone8
// 375 iphoneX
// 414 iphone8+
// 600 Nexus7 7inches
// 813 iPadPro 10.5inch //768
// 1024 iPadPro 12.9inch
function fontSizeForMobile() {
  const width = window.innerWidth
  return width <= 360 // eslint-disable-line
    ? 8
    : width <= 414 // eslint-disable-line
      ? 9
      : width <= 480 // eslint-disable-line
        ? 10
        : width <= 540 // eslint-disable-line
          ? 11
          : width <= 600 // eslint-disable-line
            ? 14
            : 18 // eslint-disable-line
}

const defaultIconSize = 40
function iconSizeForMobile() {
  const width = window.innerWidth
  return width <= 360 // eslint-disable-line
    ? 24
    : width <= 414 // eslint-disable-line
      ? 25
      : width <= 480 // eslint-disable-line
        ? 28
        : width <= 540 // eslint-disable-line
          ? 31
          : width <= 600 // eslint-disable-line
            ? defaultIconSize
            : 51 // eslint-disable-line
}

function largeFontSizeForMobile() {
  const width = window.innerWidth
  return width <= 360 // eslint-disable-line
    ? 12
    : width <= 414 // eslint-disable-line
      ? 14
      : width <= 480 // eslint-disable-line
        ? 10
        : width <= 540 // eslint-disable-line
          ? 16
          : width <= 600 // eslint-disable-line
            ? 22
            : 28 // eslint-disable-line
}

//
export const textSize = !isMobile() ? 14 : fontSizeForMobile()
export const iconSize = !isMobile() ? defaultIconSize : iconSizeForMobile()
export const iconScale = iconSize / defaultIconSize
//
export const largeTextSize = !isMobile() ? 22 : largeFontSizeForMobile()
