import React from 'react'
import PropTypes from 'prop-types'
import ReactTable from 'react-table'
import { fullTextFilter, getTableHeight } from '../../utils/utils'
import {
  CellContainer,
  HeaderContainer,
  Header,
  renderHeaderCell,
  renderFilterInput,
} from '../UsersPage/index.screen'
import FileSelectionButton from '../../components/FileSelectionButton'

class FirmwaresListScreen extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    window.setTimeout(() => this.handleResize(), 10)
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  /* eslint-disable no-undef */
  handleResize = () => this.setState({ tableHeight: getTableHeight() })

  render() {
    const {
      firmwares,
      onFirmwareSelection,
      onUploadNewFirmwareFile,
      onDeleteFirmware,
    } = this.props
    const { tableHeight } = this.state

    const columns = [
      {
        id: 'name',
        accessor: 'name',
        Header: () => renderHeaderCell('Name'),
        Cell: ({ original }) => (
          <CellContainer onClick={() => onFirmwareSelection(original)}>
            {original.name}
          </CellContainer>
        ),
        filterable: true,
        Filter: ({ filter, onChange }) =>
          renderFilterInput({ placeholder: 'Filter', filter, onChange }),
      },
      {
        id: 'delete',
        Cell: ({ original }) => (
          <button
            onClick={() => onDeleteFirmware(original)}
            type="button"
            className="btn btn-primary"
          >
            Delete
          </button>
        ),
      },
    ]
    return (
      <div>
        <HeaderContainer className="border-bottom">
          <Header>Firmwares</Header>
          <FileSelectionButton
            title={'Upload New'}
            onFileSelection={onUploadNewFirmwareFile}
          />
        </HeaderContainer>
        <ReactTable
          className="full-screen"
          style={{ height: tableHeight || 'auto' }}
          data={firmwares}
          columns={columns}
          defaultFilterMethod={fullTextFilter}
        />
      </div>
    )
  }
}

FirmwaresListScreen.propTypes = {
  firmwares: PropTypes.array.isRequired,
  onFirmwareSelection: PropTypes.func.isRequired,
  onUploadNewFirmwareFile: PropTypes.func.isRequired,
  onDeleteFirmware: PropTypes.func.isRequired,
}

export default FirmwaresListScreen
