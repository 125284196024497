import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import placeholder from 'images/icon-user-placeholder.png'
import FileSelectionButton from '../FileSelectionButton'

export const Avatar = styled.img`
  width: 160px;
  height: 160px;
  border-radius: 80px;
`

class AvatarPicker extends React.PureComponent {
  constructor(props) {
    super(props)
    const { value, defaultValue } = props
    this.state = {
      isValid: true,
      avatarUrl: value || defaultValue || placeholder,
    }
  }

  onAvatarChange = ({ target: { files } }) => {
    if (files.length < 1) return

    const { onChange, ratioTooltip } = this.props
    const file = files[0]
    const avatarUrl = URL.createObjectURL(file)
    ratioTooltip.validateImageRatio(file)

    this.setState({ avatarUrl })
    onChange({ avatarUrl, avatar: file })
  }

  setCustomValidity(msg) {
    const element = this.container
    if (msg === '') {
      this.setServerErrorDismiss(element)
    } else {
      this.setServerError(element, msg)
    }
  }

  setServerErrorDismiss(element) {
    // eslint-disable-next-line
    $(element).keyup(() => {
      $(element).attr('data-content', '') // eslint-disable-line
      $(element).popover('hide') // eslint-disable-line
    })
  }

  setServerError(element, msg) {
    $(element).attr('data-placement', 'bottom') // eslint-disable-line
    $(element).attr('data-content', msg) // eslint-disable-line
    $(element).popover('show') // eslint-disable-line
    setTimeout(() => {
      $(element).attr('data-content', '') // eslint-disable-line
      $(element).popover('disable') // eslint-disable-line
    }, 3000)
  }

  isCustom() {
    return true
  }

  isValid() {
    return this.state.isValid
  }

  validationMessage = () => {
    const { value, isValid } = this.state

    if (!value) {
      return this.props.requiredMsg || 'Avatar required'
    }

    if (!isValid) {
      return this.props.invalidMsg || 'Invalid avatar'
    }

    return undefined
  }

  render() {
    const { avatarUrl } = this.state
    const { ratioTooltip } = this.props
    return (
      <div
        className="thumbnail mt-4"
        ref={(ref) => {
          this.container = ref
          this.setServerErrorDismiss(ref)
        }}
      >
        <Avatar src={avatarUrl} alt="Lights" />
        <div className="m-2 d-flex align-items-center">
          <FileSelectionButton
            title={'ADD AVATAR'}
            onFileSelection={this.onAvatarChange}
          />
          {ratioTooltip.tooltipIcon()}
        </div>
      </div>
    )
  }
}

AvatarPicker.propTypes = {
  defaultValue: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  invalidMsg: PropTypes.string,
  requiredMsg: PropTypes.string,
  ratioTooltip: PropTypes.object,
}

export default AvatarPicker
