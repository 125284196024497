import React from 'react'
import PropTypes from 'prop-types'
import ReactTable from 'react-table'
import { fullTextFilter } from '../../utils/utils'
import {
  CellContainer,
  HeaderContainer,
  Header,
  renderHeaderCell,
  renderFilterInput,
} from '../UsersPage/index.screen'
import styles from '../../styles/App.css'

class StatusListScreen extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { height, statuses, onSeeAll, onDevicesSelection } = this.props
    const { headerHeight } = this.state

    const columns = [
      {
        id: 'devices',
        Header: () => renderHeaderCell('Devices Updated'),
        Cell: ({ original: { devices } }) => (
          <CellContainer onClick={() => onDevicesSelection(devices)}>
            <button type="button" className="btn btn-primary">
              {devices}
            </button>
          </CellContainer>
        ),
        accessor: 'devices',
        filterable: true,
        Filter: ({ filter, onChange }) =>
          renderFilterInput({ placeholder: 'Filter', filter, onChange }),
      },
      {
        id: 'date',
        Header: () => renderHeaderCell('Date Updated'),
        Cell: ({ original: { date } }) => <CellContainer>{date}</CellContainer>,
        accessor: 'date',
        filterable: true,
        Filter: ({ filter, onChange }) =>
          renderFilterInput({ placeholder: 'Filter', filter, onChange }),
      },
      {
        id: 'status',
        Header: () => renderHeaderCell('Status'),
        Cell: ({ original: { status } }) => (
          <CellContainer>{status}</CellContainer>
        ),
        accessor: 'status',
        filterable: true,
        Filter: ({ filter, onChange }) =>
          renderFilterInput({ placeholder: 'Filter', filter, onChange }),
      },
    ]
    console.warn('statuses', statuses)
    return (
      <div>
        <div className={styles.overlay} />
        <HeaderContainer className="border-bottom">
          <Header>Updates</Header>
          <button
            style={{ marginTop: '8px' }}
            onClick={onSeeAll}
            type="button"
            className="btn btn-primary"
          >
            See All
          </button>
        </HeaderContainer>
        <ReactTable
          className="full-screen"
          style={{
            height: height && headerHeight ? height - headerHeight : 'auto',
          }}
          data={statuses}
          columns={columns}
          defaultFilterMethod={fullTextFilter}
        />
      </div>
    )
  }
}

StatusListScreen.propTypes = {
  height: PropTypes.number,
  statuses: PropTypes.array.isRequired,
  onSeeAll: PropTypes.func.isRequired,
  onDevicesSelection: PropTypes.func.isRequired,
}

export default StatusListScreen
