import { ActionTypes } from './constants'

/**
 * Fetch Exceptions Failed
 *
 * @returns {Object}
 */
export function fetchExceptionsFailed(storeId, storeName, error) {
  return {
    type: ActionTypes.FETCH_EXCEPTIONS_FAILED,
    payload: { storeId, storeName, error },
  }
}

/**
 * Fetch Exceptions Fulfilled
 *
 * @returns {Object}
 */
export function fetchExceptionsFulfilled(storeId, data) {
  return {
    type: ActionTypes.FETCH_EXCEPTIONS_FULFILLED,
    payload: {
      storeId,
      exceptions: data,
    },
  }
}

/**
 * Load Exceptions
 *
 * @returns {Object}
 */
export function loadExceptions(storeId) {
  return {
    type: ActionTypes.LOAD_EXCEPTIONS,
    payload: { storeId },
  }
}

/**
 * Load Exceptions Async
 *
 * @returns {Object}
 */
export function loadExceptionsAsync() {
  return {
    type: ActionTypes.LOAD_EXCEPTIONS_ASYNC,
  }
}

/**
 * Async Loading Stores To Load
 *
 * @returns {Object}
 */
export function asyncLoadingStoresToLoad(storesCount) {
  return {
    type: ActionTypes.LOAD_EXCEPTIONS_ASYNC_COUNT,
    payload: storesCount,
  }
}

/**
 * Async Loading Current Store
 *
 * @returns {Object}
 */
export function asyncLoadingCurrentStore(store, index) {
  return {
    type: ActionTypes.LOAD_EXCEPTIONS_ASYNC_CURRENT,
    payload: { store, index },
  }
}

/**
 * Async Loading Finished
 *
 * @returns {Object}
 */
export function asyncLoadingFinished() {
  return {
    type: ActionTypes.LOAD_EXCEPTIONS_ASYNC_FINISHED,
  }
}

/**
 * Async Loading Cancel
 *
 * @returns {Object}
 */
export function asyncLoadingCancel() {
  return {
    type: ActionTypes.LOAD_EXCEPTIONS_ASYNC_CANCEL,
  }
}

/**
 * Clear Exceptions
 *
 * @returns {Object}
 */
export function clearExceptions() {
  return {
    type: ActionTypes.EXCEPTIONS_CLEAR,
  }
}
