import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import uuid from 'uuid'
import styles from '../../styles/App.css'
import PromptTable from '../../components/PromptTable'
import SidebarTitle from '../../components/SidebarTitle'
import Input from '../../components/Input'
import InputFile from '../../components/InputFile'
import Checkbox from '../../components/Checkbox'
import Issue from '../../components/Issue'
import Item from '../../components/ListItems/item'
import SidebarSection from '../../components/SidebarSection'
import FloorTile from '../../components/FloorTile'
import {
  DbClickMilliseconds,
  LongPressMilliseconds,
} from '../../utils/mtiUtils'
import { defaultName } from '../../utils/mtiCanvasUtils'
import { getFloorName } from './utils'
import { BorderedButton } from '../../styles/styled'
import {
  invalidUrlCharactersMessage,
  safeUrlCharactersPattern,
  HeaderHeight,
  safeUri,
} from '../../utils/utils'
import { SecuredProductsExceptions } from '../ExceptionsList/SecuredProducts'

export const CheckboxAndInputContainer = styled.div`
  align-items: center;
  flex-direction: row;
  display: flex;
`

export const CheckboxContainer = styled.div`
  margin-right: 10px;
`

export const DescriptionHead = styled.span`
  font-style: italic;
`

export const DescriptionItem = styled.p`
  margin-bottom: 0;
  font-style: italic;
`

class FloorsPageScreen extends React.PureComponent {
  state = {
    isSaveDisabled: false,
  }

  onChangeValidity = (isValid) => {
    this.setState({ isSaveDisabled: !isValid })
  }

  onNameChange = (name) => {
    const {
      onFloorChange,
      selectedFloorOrder,
      selectedFloorImage,
      selectedFloorShouldDisplayBackground,
    } = this.props
    onFloorChange({
      floorName: name,
      floorOrder: selectedFloorOrder,
      floorImage: selectedFloorImage,
      floorShouldDisplayBackground: selectedFloorShouldDisplayBackground,
    })
  }

  onOrderChange = (order) => {
    const {
      onFloorChange,
      selectedFloorName,
      selectedFloorImage,
      selectedFloorShouldDisplayBackground,
    } = this.props
    onFloorChange({
      floorName: selectedFloorName,
      floorOrder: order,
      floorImage: selectedFloorImage,
      floorShouldDisplayBackground: selectedFloorShouldDisplayBackground,
    })
  }

  onImageChange = (image) => {
    const {
      onFloorChange,
      selectedFloorName,
      selectedFloorOrder,
      selectedFloorShouldDisplayBackground,
      ratioTooltip,
    } = this.props
    onFloorChange({
      floorName: selectedFloorName,
      floorOrder: selectedFloorOrder,
      floorImage: image,
      floorShouldDisplayBackground: selectedFloorShouldDisplayBackground,
    })
    ratioTooltip.validateImageRatio(image)
  }

  onShouldDisplayBackground = (checked) => {
    const {
      onFloorChange,
      selectedFloorName,
      selectedFloorOrder,
      selectedFloorImage,
    } = this.props
    onFloorChange({
      floorName: selectedFloorName,
      floorOrder: selectedFloorOrder,
      floorImage: selectedFloorImage,
      floorShouldDisplayBackground: !!(checked && selectedFloorImage),
    })
  }

  getSelectedFloorObject() {
    const { canvasObject, selectedObject } = this.props
    return selectedObject > -1 && canvasObject.objects[selectedObject]
  }

  getPromptText() {
    const {
      isStatic,
      canvasObject,
      selectedObject,
      isDefaultFloorMode,
    } = this.props
    const hasFloors =
      canvasObject && canvasObject.objects && canvasObject.objects.length !== 0
    const hasSelected = selectedObject > -1
    let data = []
    if (isStatic) {
      if (hasSelected) {
        data = [
          {
            func: 'EDIT SELECTED FLOOR',
            desc: 'Click on EDIT FLOOR in SIDE PANEL',
          },
          {
            func: 'DESELECT FLOOR',
            desc: 'Click on empty space OR another FLOOR in CANVAS',
          },
          {
            func: 'WORK ON ISSUES',
            desc: 'Click on POSITION in SIDE PANEL',
          },
        ]
      } else if (!hasSelected) {
        data = hasFloors
          ? [
            {
              func: 'CREATE NEW FLOOR',
              desc: 'Click on CREATE NEW FLOOR in SIDE PANEL',
            },
            {
              func: 'EDIT EXISTING FLOOR',
              desc: 'Select floor in CANVAS',
            },
            {
              func: 'WORK ON ISSUES',
              desc: 'Click on POSITION in SIDE PANEL',
            },
          ]
          : [
            {
              func: 'CREATE NEW FLOOR',
              desc: 'Click on CREATE NEW FLOOR in SIDE PANEL',
            },
          ]
      }
    } else if (!isStatic) {
      if (hasSelected) {
        if (isDefaultFloorMode) {
          data = [
            {
              func: 'CREATE NEW FLOOR',
              desc:
                'Use SIDE PANEL to set attributes of FLOOR and click on SAVE',
            },
            {
              func: 'RETURN TO AREAS',
              desc: 'Click on CANCEL in SIDE PANEL',
            },
          ]
        } else if (!isDefaultFloorMode) {
          const isNew = isNaN((this.getSelectedFloorObject() || {}).id)
          data = isNew
            ? [
              {
                func: 'CREATE NEW FLOOR',
                desc:
                    'Use SIDE PANEL to set attributes of FLOOR and click on SAVE',
              },
              {
                func: 'CANCEL',
                desc: 'Click on CANCEL in SIDE PANEL',
              },
            ]
            : [
              {
                func: 'MODIFY FLOOR',
                desc:
                    'Use SIDE PANEL to set attributes of FLOOR and click on SAVE',
              },
              {
                func: 'DELETE FLOOR',
                desc: 'Click on DELETE in SIDE PANEL',
              },
              {
                func: 'CANCEL',
                desc: 'Click on CANCEL in SIDE PANEL',
              },
            ]
        }
      } else if (!hasSelected) {
        data = [
          {
            func: 'CREATE NEW FLOOR',
            desc: 'Click and drag in CANVAS to start creating NEW FLOOR',
          },
          {
            func: 'CANCEL',
            desc: 'Click on CANCEL in SIDE PANEL',
          },
        ]
      }
    }
    return data
  }

  renderFloorTile(floor, index) {
    const {
      onCanvasChanged,
      canvasObject,
      isStatic,
      selectedObject,
    } = this.props
    const {
      name,
      alertType,
      alertCount,
      image,
      isStatic: isFloorStatic,
    } = floor

    const handleFloorPress = () => {
      const floorPressTime = new Date().getTime()
      if (floorPressTime - this.floorPressTime < DbClickMilliseconds) {
        this.props.onOpenFloor(floor)
        return
      }
      this.floorPressTime = floorPressTime
      //
      this.floorPressTimer = setTimeout(() => {
        this.props.onOpenFloor(floor)
      }, LongPressMilliseconds)
    }

    const handleFloorRelease = () => {
      clearTimeout(this.floorPressTimer)
      if (!isStatic && selectedObject !== -1 && selectedObject !== index) {
        // onMessage('Save, Delete or Cancel edits for selected Floor') // handle silently
        return
      }
      onCanvasChanged({
        canvasObject,
        selectedObject: index,
        isStatic,
      })
    }
    const imageUrl = image && image.size ? URL.createObjectURL(image) : image
    return (
      <div
        style={{ padding: '10px' }}
        key={uuid.v4()}
        tabIndex={-1}
        role="button"
        onTouchStart={handleFloorPress}
        onTouchEnd={handleFloorRelease}
        onMouseDown={handleFloorPress}
        onMouseUp={handleFloorRelease}
        onClick={(e) => e.stopPropagation()}
        onDoubleClick={() => this.props.onOpenFloor(floor)}
      >
        <FloorTile
          title={name}
          previewImgUrl={imageUrl}
          alertType={alertType}
          alertCount={alertCount}
          selected={selectedObject === index}
          isStatic={isFloorStatic}
        />
      </div>
    )
  }

  renderSidebarForSelectedFloorEdit() {
    const {
      canvasObject,
      selectedObject,
      selectedFloorName,
      selectedFloorOrder,
      selectedFloorImage: selectedFloorImageCanBeGenerated,
      selectedFloorShouldDisplayBackground,
      isDefaultFloorMode,
      onCancel,
      onRemove,
      onFloorSave,
      onImageUpload,
      onImageCheckChange,
      ratioTooltip,
    } = this.props

    const floor = selectedObject > -1 && canvasObject.objects[selectedObject]
    const isCreation = floor ? isNaN(floor.id) : false
    const { imageName: imageNameCanBeGenerated } = floor
    // Need to hide presence of auto generated image of the floor
    const isImageManuallyAdded =
      (selectedFloorImageCanBeGenerated || {}).name ||
      !(selectedFloorImageCanBeGenerated || { includes: () => false }).includes(
        'canvas_auto_thumbnail'
      )
    const selectedFloorImage = isImageManuallyAdded
      ? selectedFloorImageCanBeGenerated
      : undefined
    const imageName = isImageManuallyAdded ? imageNameCanBeGenerated : undefined

    return (
      <div>
        <SidebarTitle>
          <BorderedButton
            type="button"
            className="btn btn-primary w-100 mr-2"
            onClick={() => onCancel()}
          >
            Cancel
          </BorderedButton>
          {!isCreation &&
            !isDefaultFloorMode && (
            <BorderedButton
              type="button"
              className="btn btn-primary w-100 mr-2"
              onClick={() => onRemove(selectedObject)}
            >
                Delete
            </BorderedButton>
          )}
          <button
            type="button"
            className="btn btn-raised w-100"
            disabled={this.state.isSaveDisabled}
            onClick={() => onFloorSave(selectedObject)}
          >
            Save
          </button>
        </SidebarTitle>
        <SidebarSection>
          <Input
            name={'floorName'}
            label={'Floor name'}
            value={selectedFloorName}
            onChange={(e) => {
              this.onNameChange(e.target.value)
            }}
            pattern={safeUrlCharactersPattern}
            validationMessage={invalidUrlCharactersMessage}
            onChangeValidity={this.onChangeValidity}
          />
          <Input
            name={'floorOrder'}
            label={'Floor order'}
            value={selectedFloorOrder}
            type={'number'}
            onChange={(e) => {
              this.onOrderChange(e.target.value)
            }}
          />
          <CheckboxAndInputContainer>
            <CheckboxContainer>
              <Checkbox
                name={'floorImageCheck'}
                checked={!!selectedFloorImage}
                disabled={false}
                onChange={({ target: { checked } }) =>
                  onImageCheckChange(checked)
                }
              />
            </CheckboxContainer>

            <InputFile
              name={'floorImage'}
              label={'Use Custom Image'}
              defaultFileName={imageName}
              onChange={(e) => {
                this.onImageChange(e.target.files[0])
              }}
              onUpload={() => onImageUpload()}
              labelWidth={142}
            />
            {ratioTooltip.tooltipIcon()}
          </CheckboxAndInputContainer>
          <Checkbox
            name={'selectedFloorShouldDisplayBackground'}
            label={'Display as Background?'}
            checked={selectedFloorShouldDisplayBackground}
            onChange={(e) => {
              this.onShouldDisplayBackground(e.target.checked)
            }}
          />
          <DescriptionHead>Custom Image will be used:</DescriptionHead>
          <DescriptionItem>- as a thumbnail for floors list</DescriptionItem>
          <DescriptionItem>
            - as a background image for floor level canvas
          </DescriptionItem>
        </SidebarSection>
      </div>
    )
  }

  renderSidebarForSelectedFloorSecurity() {
    const {
      path,
      canvasObject,
      selectedObject,
      positions: { positionsByFloorId = [] } = {},
      isStatic,
      onEdit,
      openPosition,
      missingSecuredProductsByFloorId,
    } = this.props
    const floor = selectedObject > -1 && canvasObject.objects[selectedObject]
    const fTitle = floor ? getFloorName(floor) : defaultName.floor
    const fTo = floor && `${path}/${floor.id}/${safeUri(floor.name)}`
    return (
      <div>
        <SidebarTitle title={fTitle} titleTo={fTo}>
          <div />
          {isStatic && (
            <button
              type="button"
              className="btn btn-raised"
              onClick={() => onEdit()}
            >
              Edit Floor
            </button>
          )}
        </SidebarTitle>
        {this.renderSecurityList(
          floor ? positionsByFloorId[floor.id] : [],
          true,
          openPosition,
          missingSecuredProductsByFloorId[floor.id]
        )}
      </div>
    )
  }

  renderSidebarForSelectedFloor() {
    const { isStatic } = this.props
    return isStatic
      ? this.renderSidebarForSelectedFloorSecurity()
      : this.renderSidebarForSelectedFloorEdit()
  }

  renderSidebarForStore() {
    const {
      positions: { positionsStore = [] } = {},
      floors,
      store,
      isStatic,
      onCancel,
      onCreateNewFloor,
      openPosition,
      missingSecuredProductsForStore,
    } = this.props

    return (
      <div>
        <SidebarTitle title={store && store.name}>
          <div />
          {isStatic ? (
            <button
              type="button"
              className="btn btn-raised"
              onClick={() => onCreateNewFloor()}
            >
              Create New Floor
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-raised"
              onClick={() => onCancel()}
            >
              Cancel
            </button>
          )}
        </SidebarTitle>
        {isStatic
          ? this.renderSecurityList(
            positionsStore,
            false,
            openPosition,
            missingSecuredProductsForStore
          )
          : this.renderFloorsList(floors)}
      </div>
    )
  }

  renderFloorsList(floors) {
    return (
      floors &&
      floors.map((i) => (
        <Item
          key={i.id}
          onMouseEnter={(id) => {
            if (this.canvas) this.canvas.onOver(id)
          }}
          onMouseLeave={(id) => {
            if (this.canvas) this.canvas.onOut(id)
          }}
          item={{
            id: i.id,
            title: 'Floor',
            subTitle: `${i.name} - ${i.fixturesCount} Fixtures`,
          }}
        />
      ))
    )
  }

  // TODO: Add positions exceptions component in app/containers/ExceptionsList
  // positions exceptions is used in several containers
  renderSecurityList(
    positions,
    isFloorSelected,
    openPosition,
    securedProducts
  ) {
    const noIssuesText = isFloorSelected
      ? 'There are no Security or Health/Compliance Issues for the selected Floor'
      : 'There are no Security or Health/Compliance Issues for any of these Floors'

    const hasPositionExceptions = positions && positions.length
    const hasSecuredProductsExceptions =
      securedProducts && securedProducts.length
    if (!hasPositionExceptions && !hasSecuredProductsExceptions) {
      return (
        <div style={{ textAlign: 'center', margin: '10px' }}>
          <span>{noIssuesText}</span>
        </div>
      )
    }

    const { onGoToSecuredProducts } = this.props
    let exceptionsList = []

    const positionsExceptions = positions.map((i) => (
      <Issue
        key={i.id}
        issue={{
          id: i.id,
          title: i.path,
          device: i.name,
          status: i.status.type,
          statusTitle: i.status.title,
          armStateAction: i.status.states.armStateAction,
          armStateActionTitle: i.status.states.armStateActionTitle,
          deviceType: i.deviceType,
          securityDeviceId: i.securityDevice,
        }}
        openPosition={openPosition}
      />
    ))
    exceptionsList = exceptionsList.concat(
      positionsExceptions,
      <SecuredProductsExceptions
        securedProducts={securedProducts}
        onGoToSecuredProducts={onGoToSecuredProducts}
      />
    )
    return exceptionsList
  }

  render() {
    const {
      canvasObject,
      selectedObject,
      onCanvasChanged,
      isStatic,
    } = this.props
    const height = window.innerHeight - HeaderHeight
    return (
      <article className={styles.storeContainer}>
        <PromptTable data={this.getPromptText()} />
        <div
          className={styles.canvasContainer}
          tabIndex={-1}
          role="button"
          onClick={() => {
            if (!isStatic) {
              // onMessage('Save, Delete or Cancel edits for selected Floor') // handle silently
              return
            }
            onCanvasChanged({
              canvasObject,
              selectedObject: -1,
              isStatic,
            })
          }}
        >
          <div className={styles.floorsContainer}>
            {(canvasObject.objects || []).map((item, index) =>
              this.renderFloorTile(item, index)
            )}
          </div>
        </div>
        <div className={styles.rightStoreColumn}>
          <div style={{ height: height }}>
            {selectedObject > -1
              ? this.renderSidebarForSelectedFloor()
              : this.renderSidebarForStore()}
          </div>
        </div>
      </article>
    )
  }
}

FloorsPageScreen.propTypes = {
  path: PropTypes.string.isRequired,
  canvasObject: PropTypes.object.isRequired,
  positions: PropTypes.object,
  floors: PropTypes.array,
  store: PropTypes.object,
  selectedObject: PropTypes.number,
  isStatic: PropTypes.bool,
  isDefaultFloorMode: PropTypes.bool,
  onCanvasChanged: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onFloorChange: PropTypes.func.isRequired,
  onFloorSave: PropTypes.func.isRequired,
  onCreateNewFloor: PropTypes.func.isRequired,
  onOpenFloor: PropTypes.func.isRequired,
  onMessage: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onImageUpload: PropTypes.func.isRequired,
  onImageCheckChange: PropTypes.func.isRequired,
  selectedFloorName: PropTypes.string.isRequired,
  selectedFloorOrder: PropTypes.any.isRequired,
  selectedFloorImage: PropTypes.any,
  selectedFloorShouldDisplayBackground: PropTypes.bool,
  openPosition: PropTypes.func.isRequired,
  ratioTooltip: PropTypes.object,
  missingSecuredProductsForStore: PropTypes.array,
  missingSecuredProductsByFloorId: PropTypes.array,
  onGoToSecuredProducts: PropTypes.func,
}

export default FloorsPageScreen
