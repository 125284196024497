/**
 *
 * TemplateFixture selectors
 *
 */

import { createSelector } from 'reselect'
import { createSelector as createOrmSelector } from 'redux-orm'
import { orm } from 'mti-jsclient-shared'
import { canvasSize } from '../../utils/mtiUtils'
import { toFabricSync } from '../AreaPage/utils'

const dbStateSelector = (state) => state.get('orm')

const ormAreaStateSelector = (state) => ({
  orm: state.get('orm'),
  templateFixture: state.get('templateFixture'),
})

const selectFixtureById = (fId) =>
  createOrmSelector(orm, (session) => {
    try {
      const f = session.Fixture.withId(fId)
      return {
        ...f.ref,
        layoutPosition: (f.layoutPositionId || {}).ref,
      }
    } catch (e) {
      return {}
    }
  })

const makeSelectCanvasObject = (tId) =>
  createSelector(ormAreaStateSelector, (ormArea) => {
    const isStatic = ormArea.templateFixture.get('isStatic')
    const prototypes = ormArea.templateFixture.get('prototypes')
    if (!(isStatic && prototypes)) {
      return ormArea.templateFixture.get('canvasObject').toJS()
    }
    let area
    if (tId) {
      area = { fixtures: [selectFixtureById(tId)(ormArea.orm)] }
    }
    const screen = ormArea.templateFixture.get('screen') || canvasSize
    const { canvasObject } = toFabricSync(
      area || {},
      screen,
      isStatic,
      false,
      undefined,
      prototypes
    )

    return canvasObject
  })

const selectCanvas = (state) => state.get('templateFixture')

const makeSelectCanvasSelectedObject = () =>
  createSelector(selectCanvas, (canvasState) =>
    canvasState.get('selectedObject')
  )

const makeSelectFailed = () =>
  createSelector(selectCanvas, (canvas) => canvas.get('failed'))

const makeSelectLoading = () =>
  createSelector(selectCanvas, (canvas) => canvas.get('loading'))

const makeSelectIsStatic = () =>
  createSelector(selectCanvas, (canvas) => canvas.get('isStatic'))

const makeSelectPrototypes = () =>
  createSelector(selectCanvas, (canvas) => canvas.get('prototypes'))

const makeSelectSavedTemplate = () =>
  createSelector(selectCanvas, (canvas) => canvas.get('savedTemplate'))

const makeSelectInheritanceRule = () =>
  createSelector(selectCanvas, (canvas) => canvas.get('inheritanceRule').toJS())

const makeSelectCloneable = () =>
  createSelector(selectCanvas, (canvas) => canvas.get('cloneable'))

const makeSelectCopyable = () =>
  createSelector(selectCanvas, (canvas) => canvas.get('copyable'))

const getCanvasData = (canvas) => {
  const selectedObj = canvas.get('selectedObject')
  const objects = canvas.get('canvasObject').get('objects')
  return { selectedObj, objects }
}

export const makeSelectTemplateRules = (templateId) =>
  createSelector(dbStateSelector, (ormState) =>
    createOrmSelector(orm, (session) => {
      try {
        const template = session.Fixture.withId(templateId)
        const rules = template.ruleIds.toRefArray()
        return rules
      } catch (e) {
        return []
      }
    })(ormState)
  )

const makeSelectTemplateFixture = () =>
  createSelector(selectCanvas, (canvas) => {
    const { selectedObj, objects } = getCanvasData(canvas)
    if (selectedObj === -1 || !objects.count()) return undefined

    return objects.find((i, idx) => idx === selectedObj).toJS()
  })

const makeSelectTemplateFixtureName = () =>
  createSelector(selectCanvas, (canvas) => {
    const { selectedObj, objects } = getCanvasData(canvas)
    if (selectedObj === -1 || !objects.count()) return ''

    return objects.find((i, idx) => idx === selectedObj).get('name')
  })

const makeSelectTemplateFixtureSviType = () =>
  createSelector(selectCanvas, (canvas) => {
    const { selectedObj, objects } = getCanvasData(canvas)
    if (selectedObj === -1 || !objects.count()) return ''

    return objects.find((i, idx) => idx === selectedObj).get('sviType')
  })

const makeSelectTemplateFixtureSviSizeType = () =>
  createSelector(selectCanvas, (canvas) => {
    const { selectedObj, objects } = getCanvasData(canvas)
    if (selectedObj === -1 || !objects.count()) return null

    return objects.find((i, idx) => idx === selectedObj).get('sviSizeType')
  })

const makeSelectTemplateFixtureIsFurniture = () =>
  createSelector(selectCanvas, (canvas) => {
    const { selectedObj, objects } = getCanvasData(canvas)
    if (selectedObj === -1 || !objects.count()) return null

    return objects.find((i, idx) => idx === selectedObj).get('isFurniture')
  })

const makeSelectTemplateFixtureNameShown = () =>
  createSelector(selectCanvas, (canvas) => {
    const { selectedObj, objects } = getCanvasData(canvas)
    if (selectedObj === -1 || !objects.count()) return null

    return objects.find((i, idx) => idx === selectedObj).get('nameHasShown')
  })

const makeSelectTemplateFixtureSelectedObject = () =>
  createSelector(selectCanvas, (canvas) => {
    const { selectedObj, objects } = getCanvasData(canvas)
    if (selectedObj === -1 || !objects.count()) return null

    return objects.find((i, idx) => idx === selectedObj)
  })

const makeSelectTemplateFixtureAngle = () =>
  createSelector(selectCanvas, (canvas) => {
    const { selectedObj, objects } = getCanvasData(canvas)
    if (selectedObj === -1 || !objects.count()) return null

    const angle = objects.find((i, idx) => idx === selectedObj).get('angle')

    return angle ? angle.toString() : '0'
  })

const makeSelectTemplateNames = () =>
  createSelector(dbStateSelector, (ormState) =>
    createOrmSelector(orm, (session) =>
      session.Fixture.filter(({ template }) => template === true)
        .toRefArray()
        .map(({ name }) => name)
    )(ormState)
  )

const makeSelectCreatedTemplateFixtureName = (templateId) =>
  createSelector(dbStateSelector, (ormState) =>
    createOrmSelector(orm, (session) => {
      try {
        const template = session.Fixture.withId(templateId)
        const templateName = template.name
        return templateName
      } catch (e) {
        return []
      }
    })(ormState)
  )

export {
  makeSelectCanvasObject,
  makeSelectCanvasSelectedObject,
  makeSelectFailed,
  makeSelectLoading,
  makeSelectIsStatic,
  makeSelectPrototypes,
  makeSelectSavedTemplate,
  makeSelectInheritanceRule,
  makeSelectCloneable,
  makeSelectCopyable,
  makeSelectTemplateFixture,
  makeSelectTemplateFixtureName,
  makeSelectTemplateFixtureSviType,
  makeSelectTemplateFixtureSviSizeType,
  makeSelectTemplateFixtureIsFurniture,
  makeSelectTemplateFixtureNameShown,
  makeSelectTemplateFixtureSelectedObject,
  makeSelectTemplateFixtureAngle,
  makeSelectTemplateNames,
  makeSelectCreatedTemplateFixtureName,
}
