import React from 'react'
import PropTypes from 'prop-types'
import Datetime from 'react-datetime'
import 'react-datetime/css/react-datetime.css'
import styled from 'styled-components'
import moment from 'moment'

const FormControl = styled.div`
  padding: 0;
`

const StyledDatetime = styled(Datetime)`
  input.form-control:read-only {
    background-image: none !important;
  }
`

class TimePickerInput extends React.PureComponent {
  setCustomValidity(msg) {
    const element = this.container
    if (msg === '') {
      this.setServerErrorDismiss(element)
    } else {
      this.setServerError(element, msg)
    }
  }

  setServerErrorDismiss(element) {
    element.keyup(() => {
      element.attr('data-content', '')
      element.popover('hide')
    })
  }

  disposePopover() {
    const element = this.container
    element.popover('dispose')
  }

  setServerError(element, msg) {
    element.attr('data-placement', 'bottom')
    element.attr('data-trigger', 'focus')

    element.attr('data-content', msg)
    element.popover('show')
    setTimeout(() => {
      element.attr('data-content', '')
      element.popover('dispose')
    }, 3000)
  }

  isCustom() {
    return true
  }

  hidePopover() {
    const element = this.container
    element.popover('hide')
  }

  isValid() {
    return this.state.isValid
  }

  _getDefaultValue() {
    const { defaultValue: propsDefaultValue, getDefaultValue } = this.props
    if (propsDefaultValue) return propsDefaultValue
    if (getDefaultValue && typeof getDefaultValue === 'function') {
      return getDefaultValue()
    }
    console.warn('TimePickerInput, defaultValue undefined')
    const defaultDate = new Date()
    defaultDate.setHours(21)
    defaultDate.setMinutes(0)
    return defaultDate
  }

  render() {
    return (
      <div
        ref={(ref) => {
          this.container = $(ref) // eslint-disable-line
          this.setServerErrorDismiss(this.container)
        }}
        name={this.props.name}
      >
        <StyledDatetime
          inputProps={{ readOnly: true, disabled: this.props.disabled }}
          dateFormat={false}
          disableOnClickOutside
          timeFormat="HH:mm"
          timeConstraints={{ minutes: { step: 10 } }}
          defaultValue={this._getDefaultValue()}
          onFocus={() => this.hidePopover()}
          onBlur={this.props.onBlur}
          onChange={(value) => {
            if (typeof value === 'string') {
              const date = moment(value)

              if (date.isValid()) {
                this.props.onChange(date)
              }
            } else {
              this.props.onChange(value)
            }
          }}
        />
        {false && (
          <div>
            <FormControl className="form-control is-invalid" />
            <div className="invalid-feedback">
              Example invalid feedback text
            </div>
          </div>
        )}
      </div>
    )
  }
}

TimePickerInput.defaultProps = {
  onChange: () => {},
  onBlur: () => {},
}

TimePickerInput.propTypes = {
  defaultValue: PropTypes.object,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  getDefaultValue: PropTypes.func,
}

export default TimePickerInput
