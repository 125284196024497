import { ActionTypes } from '../constants'

/**
 * Post Store Action
 *
 * @returns {Object}
 */
export function postStoreAction(payload) {
  return {
    type: ActionTypes.POST_STORE_ACTION,
    payload,
  }
}

/**
 * Store Lockdown Action
 *
 * @returns {Object}
 */
export function storeLockdown(payload) {
  return {
    type: ActionTypes.STORE_LOCKDOWN,
    payload,
  }
}
