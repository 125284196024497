import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import { ActionTypes } from '../App/constants'
import {
  openModal,
  closeModal,
  notifyModal,
  editOrganization,
  clearOrganization,
  patchOrganization,
  resetOrganizationManagement,
} from '../App/actions'
import {
  makeSelectMtiPermissionAccessData,
  makeSelectOrganizationManagement,
  makeSelectOrganizationDetails,
  makeSelectOrganizationLoading,
  makeSelectOrganizationPatchLoading,
  makeSelectOrganizationPatchFailed,
} from '../App/selectors'
import OrganizationDetailsModal from './OrganizationDetailsModal'
import EditOrganizationModal from './EditOrganizationModal'

const permText = (text) =>
  `You don't have appropriate rights to access ${text}.\nPlease contact your store manager.`

export class OrganizationManagement extends React.PureComponent {
  componentDidUpdate(prevProps) {
    const {
      organizationManagement,
      organizationDetails,
      organizationLoading,
      resetOrganizationManagement: doResetOrganizationManagement,
      patchOrganizationLoading,
      patchOrganizationFailed,
    } = this.props

    if (organizationManagement === ActionTypes.ORGANIZATION_DETAILS) {
      this.openOrganizationDetailsModal(organizationDetails)
      doResetOrganizationManagement()
      return
    }

    if (
      (organizationDetails || {}).createdAt &&
      !(prevProps.organizationDetails || {}).createdAt &&
      this.organizationDetailsModal
    ) {
      this.openOrganizationDetailsModal(organizationDetails)
      doResetOrganizationManagement()
      return
    }

    if (
      !organizationLoading &&
      prevProps.organizationLoading &&
      this.organizationDetailsModal
    ) {
      this.organizationDetailsModal.setLoading(false)
    }

    if (patchOrganizationFailed && this.editOrganizationModal) {
      this.editOrganizationModal.setServerValidity(patchOrganizationFailed)
      this.editOrganizationModal.setLoading(false)
    }

    if (
      !patchOrganizationLoading &&
      !patchOrganizationFailed &&
      prevProps.patchOrganizationLoading &&
      this.editOrganizationModal
    ) {
      this.editOrganizationModal.close()
    }

    if (
      patchOrganizationLoading &&
      !patchOrganizationFailed &&
      !prevProps.patchOrganizationLoading &&
      this.editOrganizationModal
    ) {
      this.editOrganizationModal.setLoading(true)
    }
  }

  openOrganizationDetailsModal = (organization) => {
    const {
      openModal: doOpenModal,
      closeModal: doCloseModal,
      clearOrganization: doClearOrganization,
      onClose,
    } = this.props
    const hasShowOrganizationPermission = true // Add access check if needed
    if (
      this.noPermission(
        hasShowOrganizationPermission,
        permText('View Organization')
      )
    ) {
      return
    }

    const organizationDetailsId = 'organization-details'

    doClearOrganization()
    doOpenModal({
      id: organizationDetailsId,
      type: 'bootstrap',
      content: (
        <OrganizationDetailsModal
          ref={(ref) => {
            this.organizationDetailsModal = ref
            if (this.organizationDetailsModal)
              this.organizationDetailsModal.setLoading(
                this.props.organizationLoading
              )
          }}
          id={organizationDetailsId}
          organization={organization}
          onConfirm={() => {
            doCloseModal({ id: organizationDetailsId })
            doClearOrganization()
            setTimeout(
              () => this.openEditExistingOrganizationModal(organization),
              500
            )
          }}
          onClose={() => {
            doCloseModal({ id: organizationDetailsId })

            if (onClose) {
              onClose()
            }
          }}
        />
      ),
    })
  }

  openEditExistingOrganizationModal = (organization) => {
    const {
      openModal: doOpenModal,
      closeModal: doCloseModal,
      editOrganization: doEditOrganization,
      patchOrganization: doPatchOrganization,
    } = this.props
    const hasUpdateOrganizationPermission = true // Add access check if needed
    if (
      this.noPermission(
        hasUpdateOrganizationPermission,
        permText('Edit Organization')
      )
    ) {
      return
    }

    const editOrganizationId = 'edit-organization'

    this.initAddress(organization)
    doOpenModal({
      id: editOrganizationId,
      type: 'bootstrap',
      content: (
        <EditOrganizationModal
          ref={(ref) => (this.editOrganizationModal = ref)}
          title={organization.name}
          id={editOrganizationId}
          organization={organization}
          onChange={(o) => doEditOrganization(o)}
          onConfirm={() => doPatchOrganization(organization.id)}
          onClose={() => doCloseModal({ id: editOrganizationId })}
        />
      ),
    })
  }

  // TODO: check PATCH api/organizations, is it required to send address if there was no changes?
  initAddress = (organization) => {
    const { editOrganization: doEditOrganization } = this.props
    const {
      address: { country, state, address1, city, address2, address3, zip } = {},
    } = organization
    doEditOrganization({ address: { country } })
    doEditOrganization({ address: { state } })
    doEditOrganization({ address: { address1 } })
    doEditOrganization({ address: { city } })
    doEditOrganization({ address: { address2 } })
    doEditOrganization({ address: { address3 } })
    doEditOrganization({ address: { zip } })
  }

  noPermission = (hasPermission, text) => {
    const { notifyModal: doNotifyModal } = this.props
    if (!hasPermission) doNotifyModal(text)
    return !hasPermission
  }

  render() {
    return <div />
  }
}

OrganizationManagement.propTypes = {
  organizationManagement: PropTypes.string,
  organizationDetails: PropTypes.object,
  organizationLoading: PropTypes.bool,
  resetOrganizationManagement: PropTypes.func,
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
  notifyModal: PropTypes.func,
  organization: PropTypes.object,
  clearOrganization: PropTypes.func,
  editOrganization: PropTypes.func,
  patchOrganization: PropTypes.func,
  patchOrganizationLoading: PropTypes.bool,
  patchOrganizationFailed: PropTypes.object,
  onClose: PropTypes.func,
}

const mapStateToProps = createStructuredSelector({
  organizationManagement: makeSelectOrganizationManagement(),
  organizationDetails: makeSelectOrganizationDetails(),
  organizationLoading: makeSelectOrganizationLoading(),
  patchOrganizationLoading: makeSelectOrganizationPatchLoading(),
  patchOrganizationFailed: makeSelectOrganizationPatchFailed(),
})

const mapDispatchToProps = {
  resetOrganizationManagement,
  openModal,
  closeModal,
  notifyModal,
  clearOrganization,
  editOrganization,
  patchOrganization,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect)(OrganizationManagement)
