import React from 'react'
import { connect } from 'react-redux'
import { postSecurityDeviceAction } from '../App/actions/securityDevice'
import { FormattedMessage, injectIntl } from 'react-intl'
import { AlertIcon, AlertButton } from '../../styles/styled'

class ActionAlert extends React.PureComponent {
  onAction = () => {
    const { action, resourceId, postSecurityDeviceAction } = this.props
    postSecurityDeviceAction({ id: resourceId, action })
  }

  render() {
    const { alertType, messagePath, resourceName, action, intl } = this.props

    return (
      <div>
        <AlertIcon src={alertType.icon} />
        <FormattedMessage
          id={messagePath}
          values={{ resource: intl.formatMessage({ id: resourceName }) }}
        />
        <AlertButton type="button" onClick={this.onAction}>
          {action}
        </AlertButton>
      </div>
    )
  }
}

ActionAlert.propTypes = {}
const mapDispatchToProps = {
  postSecurityDeviceAction,
}

export default connect(null, mapDispatchToProps)(injectIntl(ActionAlert))
