import { modelActionCreators } from 'mti-jsclient-shared'
import { call, all, put, takeLatest, select } from 'redux-saga/effects'
import { patchSecuredProduct as patchSecuredProductRequest } from '../../../api'
import { makeSelectToken, makeSelectSelectedSecuredProduct } from '../selectors'
import {
  patchSecuredProductFailed,
  patchSecuredProductPending,
  patchSecuredProductFulfilled,
} from '../actions'
import { ActionTypes } from '../constants'
import { errorToast, successToast } from '../../../utils/utils'

function* patchSecuredProduct() {
  const editedSecuredProduct = yield select(makeSelectSelectedSecuredProduct())
  const token = yield select(makeSelectToken())
  try {
    yield put(patchSecuredProductPending())
    const { securedProducts } = yield call(
      patchSecuredProductRequest,
      token,
      editedSecuredProduct
    )
    successToast('Secured Product Updated')
    yield securedProducts &&
      put(modelActionCreators.upsertSecuredProducts(securedProducts))
    yield put(patchSecuredProductFulfilled())
  } catch (error) {
    yield put(patchSecuredProductFailed(error))
    errorToast('Update secure product failed')
  }
}

export default function* root() {
  yield all([
    yield takeLatest(ActionTypes.PATCH_SECURED_PRODUCT, patchSecuredProduct),
  ])
}
