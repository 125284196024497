import { ActionTypes } from './constants'

/**
 * Fetch Reports Failed
 *
 * @returns {Object}
 */
export function fetchReportsFailed(storeId, storeName, error) {
  return {
    type: ActionTypes.FETCH_REPORTS_FAILED,
    payload: { storeId, storeName, error },
  }
}

/**
 * Fetch Reports Fulfilled
 *
 * @returns {Object}
 */
export function fetchReportsFulfilled(storeId, data) {
  return {
    type: ActionTypes.FETCH_REPORTS_FULFILLED,
    payload: {
      storeId,
      reports: data,
    },
  }
}

/**
 * Load Reports
 *
 * @returns {Object}
 */
export function loadReports(storeId) {
  return {
    type: ActionTypes.LOAD_REPORTS,
    payload: { storeId },
  }
}

/**
 * Load Reports Async
 *
 * @returns {Object}
 */
export function loadReportsAsync() {
  return {
    type: ActionTypes.LOAD_REPORTS_ASYNC,
  }
}

/**
 * Async Loading Stores To Load
 *
 * @returns {Object}
 */
export function asyncLoadingStoresToLoad(storesCount) {
  return {
    type: ActionTypes.LOAD_REPORTS_ASYNC_COUNT,
    payload: storesCount,
  }
}

/**
 * Async Loading Current Store
 *
 * @returns {Object}
 */
export function asyncLoadingCurrentStore(store, index) {
  return {
    type: ActionTypes.LOAD_REPORTS_ASYNC_CURRENT,
    payload: { store, index },
  }
}

/**
 * Async Loading Finished
 *
 * @returns {Object}
 */
export function asyncLoadingFinished() {
  return {
    type: ActionTypes.LOAD_REPORTS_ASYNC_FINISHED,
  }
}

/**
 * Async Loading Cancel
 *
 * @returns {Object}
 */
export function asyncLoadingCancel() {
  return {
    type: ActionTypes.LOAD_REPORTS_ASYNC_CANCEL,
  }
}

/**
 * Clear Reports
 *
 * @returns {Object}
 */
export function clearReports() {
  return {
    type: ActionTypes.REPORTS_CLEAR,
  }
}
