import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { batchActions } from 'redux-batched-actions'
import { makeSelectToken } from '../../containers/App/selectors'
import { getOrphanedDevicesData } from '../../api'
import {
  orphanedDevicesLoadingAction,
  orphanedDevicesDataReducerAction,
} from './actions'
import { ActionTypes } from './constants'

function* fetchOrphanedDevicesData({ payload }) {
  const { filters } = payload
  yield put(orphanedDevicesLoadingAction(true))

  const token = yield select(makeSelectToken())
  const apiResponse = yield call(getOrphanedDevicesData, token, filters)
  yield all(batchActions([
    put(orphanedDevicesLoadingAction(false)),
    put(orphanedDevicesDataReducerAction(apiResponse))
  ]))
}

export default function* root() {
  yield all([
    yield takeLatest(ActionTypes.FETCH_ORPHANED_DEVICES_DATA, fetchOrphanedDevicesData),
  ])
}
