import _ from 'lodash'
import { createSelector } from 'reselect'
import { createSelector as createOrmSelector } from 'redux-orm'
import { orm } from 'mti-jsclient-shared'
import { getTagsByStore } from '../../../utils/mtiUtils'
import { getImage } from '../../../utils/mtiCanvasUtils'

const dbStateSelector = (state) => state.get('orm')
const selectTags = (state) => state.get('tags')
const selectApp = (state) => state.get('app')
const selectStore = (state) => state.get('editStores')

const selectAllStores = createOrmSelector(orm, (session, tags, app) => {
  const tagsByStore = getTagsByStore(tags.get('taggings'))
  const images = app.get('images')
  return session.Store.all()
    .toModelArray()
    .map((store) => {
      const obj = store.ref
      const address = store.addressId
      const addressObj = (address && address.ref) || {}
      // const floors = store.floorIds.toRefArray()
      const floors = store.floorIds
        .toRefArray()
        .map((f) => ({
          ...f,
          previewImageUrl: getImage(images, f.previewImageId, 'thumbLink')
            .image,
        }))
        .sort((a, b) => a.order - b.order)

      const areas = _.flatten(
        store.floorIds.toModelArray().map((floor) => floor.areaIds.toRefArray())
      )

      return {
        ...obj,
        floors,
        areas,
        address: addressObj,
        tags: (tagsByStore || [])
          .filter((storeTag) => storeTag.id === store.id)
          .map((storeTag) => storeTag.taggings),
      }
    })
})

const makeSelectStores = () =>
  createSelector(
    dbStateSelector,
    selectTags,
    selectApp,
    (ormState, tags, app) => selectAllStores(ormState, tags, app)
  )

const makeSelectFailed = () =>
  createSelector(selectApp, (stores) => stores.get('storesFailed'))

const makeSelectLoading = () =>
  createSelector(selectApp, (stores) => stores.get('storesLoading'))

// TODO: deprecated
const makeSelectPatchLoading = () =>
  createSelector(selectApp, (stores) => stores.get('storesLoading'))

// TODO: deprecated
const makeSelectPatchFailed = () =>
  createSelector(selectApp, (stores) => stores.get('storesFailed'))

const makeSelectStore = () =>
  createSelector(selectStore, (store) => store.get('store'))

const makeSelectIsDataExists = () =>
  createSelector(dbStateSelector, (ormState) => {
    const stores = selectAllStores(ormState)
    return stores.length > 0
  })

export {
  makeSelectFailed,
  makeSelectLoading,
  makeSelectStores,
  makeSelectIsDataExists,
  makeSelectStore,
  makeSelectPatchFailed,
  makeSelectPatchLoading,
}
