import { Map } from 'immutable'
import { ActionTypes } from './constants'

const initialState = new Map({
  minions: [],
  loading: false,
  failed: null,
})

export default function(state = initialState, { type, payload }) {
  switch (type) {
    case ActionTypes.FETCH_MINIONS_FULFILLED:
      return state
        .set('loading', false)
        .set('failed', null)
        .set('minions', payload.minions)
    case ActionTypes.FETCH_MINIONS_PENDING:
      return state.set('loading', true).set('failed', null)
    case ActionTypes.FETCH_MINIONS_FAILED:
      return state.set('loading', false).set('failed', payload.error)
    default:
      return state
  }
}
