/**
 *
 * ToastContainer
 *
 */

import React from 'react'
import { ToastContainer as ToastifyToastContainer } from 'react-toastify'
// import 'react-toastify/dist/ReactToastify.css'
// minified version is also included
import 'react-toastify/dist/ReactToastify.min.css'

function ToastContainer() {
  return (
    <ToastifyToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop
      closeOnClick
      rtl={false}
      pauseOnVisibilityChange
      draggable
      pauseOnHover
    />
  )
}

export default ToastContainer
