import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import SecurityDeviceScreen from './securityDevice.screen'
import { makeSelectSecurityDeviceIdentifySerialNumber } from '../../App/selectors'

class SecurityDevice extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      selectedDevice: props.existingDevice,
      devices: props.devices,
      showUnassigned: true,
    }
  }

  componentDidUpdate(prevProps) {
    this.updateReverseIdentify(prevProps)
  }

  onSelection = (data) => {
    const { selectedDevice } = data
    this.setState({ selectedDevice: [selectedDevice] })
  }

  onConfirm = () => {
    const { onConfirm } = this.props
    const { selectedDevice } = this.state
    onConfirm(selectedDevice[0])
  }

  onUnassign = () => {
    const { onConfirm } = this.props
    onConfirm(null)
  }

  reverseIdentify = (selectedDeviceSerialNumber) => {
    const reverseIdentifyDevice = this.props.devices.filter(
      ({ serialNumber }) => selectedDeviceSerialNumber.includes(serialNumber)
    )
    this.setState({
      selectedDevice: reverseIdentifyDevice,
      devices: reverseIdentifyDevice,
    })
  }

  restoreIdentify = () => {
    this.setState({
      selectedDevice: this.props.existingDevice,
      devices: this.props.devices,
    })
  }

  updateReverseIdentify = (prevProps) => {
    const { securityDeviceIdentifySerialNumber } = this.props
    if (
      JSON.stringify(securityDeviceIdentifySerialNumber) !==
      JSON.stringify(prevProps.securityDeviceIdentifySerialNumber)
    ) {
      console.warn(
        'Update Reverse Identify UI serialNumber >',
        securityDeviceIdentifySerialNumber
      )
      if (securityDeviceIdentifySerialNumber.length) {
        this.reverseIdentify(securityDeviceIdentifySerialNumber)
      } else {
        this.restoreIdentify()
      }
    }
  }

  onShowUnassignedChanged = () => {
    const { showUnassigned } = this.state
    this.setState({ showUnassigned: !showUnassigned })
  }

  render() {
    const { id, onClose, existingDevice, onIdentify } = this.props
    const { selectedDevice, devices, showUnassigned } = this.state

    return (
      <SecurityDeviceScreen
        id={id}
        devices={devices}
        selectedDevice={selectedDevice}
        existingDevice={existingDevice}
        onConfirm={this.onConfirm}
        onUnassign={this.onUnassign}
        onClose={onClose}
        onSelection={this.onSelection}
        onIdentify={onIdentify}
        onShowUnassignedChanged={this.onShowUnassignedChanged}
        showUnassigned={showUnassigned}
      />
    )
  }
}

SecurityDevice.propTypes = {
  id: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onIdentify: PropTypes.func.isRequired,
  devices: PropTypes.array,
  existingDevice: PropTypes.array,
  securityDeviceIdentifySerialNumber: PropTypes.array,
}

SecurityDevice.defaultProps = {
  devices: [],
  selectedDevice: [],
}

const mapStateToProps = createStructuredSelector({
  securityDeviceIdentifySerialNumber: makeSelectSecurityDeviceIdentifySerialNumber(),
})

const withConnect = connect(mapStateToProps)

export default compose(withConnect)(SecurityDevice)
