import moment from 'moment'
import React from 'react'
import PropTypes from 'prop-types'
import ReactTable from 'react-table'
import { fullTextFilter, getTableHeight } from '../../utils/utils'
import {
  CellContainer,
  renderFilterInput,
  HeaderContainer,
  Header,
  renderHeaderCell,
} from '../UsersPage/index.screen'
import {
  storeLinkFromPosition,
  areaLinkFromPosition,
  fixtureLinkFromPosition,
  positionLinkFromPosition,
} from '../ExceptionsPage/utils'
import ProgressIndicator from '../../components/ProgressIndicator'
import TablePreloader from '../../components/Preloaders/TablePreloader'
import TooltipCell from '../../components/TooltipCell'
import HelpIcon from '../../components/HelpIcon'
import { FormattedMessage, injectIntl } from 'react-intl'
import Select from 'react-select'
import styled from 'styled-components'

export const SecuredProductHeader = styled(Header)`
  flex: 3;
`

export const SecuredProductHeaderItem = styled(Header)`
  margin: 12px;
`

const intlPath = (string) => `containers.securedProducts.${string}`

export class SecuredProductsScreen extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    window.setTimeout(() => this.handleResize(), 10)
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }
  /* eslint-disable no-undef */
  handleResize = () => this.setState({ tableHeight: getTableHeight() })

  getSecuredProductOptions = () => {
    const { categories, intl } = this.props

    const options = Object.values(categories).map((category) => ({
      value: category,
      label: intl.formatMessage({
        id: `securedProduct.categories.${category}`,
      }),
    }))

    return options
  }

  render() {
    const {
      securedProducts,
      onSecuredProductSelection,
      onChangeCategory,
      selectedCategory,
      loading,
      categories,
      intl,
    } = this.props
    const { tableHeight } = this.state
    const subtextStyle = {
      textAlign: 'left',
      fontSize: 'small',
      color: 'slategrey',
      margin: '-4px 0px 0px 0px',
    }

    const columns = [
      {
        id: 'product',
        filterable: true,
        Header: renderHeaderCell(intl.formatMessage({ id: 'product' })),
        accessor: (original) =>
          `${original.manufacturerName} ${original.productName}`,
        Cell: ({ original }) => (
          <CellContainer>
            <TooltipCell
              text={original.productName}
              render={(tooltip) => (
                <a ref={(ref) => tooltip.target(ref)} href={'#'}>
                  {original.productName}
                </a>
              )}
            />
            <TooltipCell
              text={original.manufacturerName}
              render={(tooltip) => (
                <div ref={(ref) => tooltip.target(ref)} style={subtextStyle}>
                  {original.manufacturerName}
                </div>
              )}
            />
          </CellContainer>
        ),
        Filter: ({ filter, onChange }) =>
          renderFilterInput({
            placeholder: intl.formatMessage({ id: 'filter' }),
            filter,
            onChange,
            id: 'product',
          }),
      },
      {
        id: 'imageSerialNumber',
        filterable: true,
        Header: renderHeaderCell(intl.formatMessage({ id: 'serialNumbers' })),
        accessor: (original) =>
          `${original.imageSerialNumber} ${original.serialNumber}`,
        Cell: ({ original: { imageSerialNumber, serialNumber } }) => (
          <CellContainer>
            <TooltipCell
              text={`USB: ${imageSerialNumber || '--'}`}
              render={(tooltip) => (
                <div ref={(ref) => tooltip.target(ref)}>
                  {imageSerialNumber || '--'}
                </div>
              )}
            />
            <TooltipCell
              text={`SN: ${serialNumber || '--'}`}
              render={(tooltip) => (
                <div ref={(ref) => tooltip.target(ref)}>
                  {serialNumber || '--'}
                </div>
              )}
            />
          </CellContainer>
        ),
        Filter: ({ filter, onChange }) =>
          renderFilterInput({
            placeholder: intl.formatMessage({ id: 'filter' }),
            filter,
            onChange,
            id: 'serialNumbers',
          }),
      },
      {
        id: 'position',
        filterable: true,
        Header: renderHeaderCell(intl.formatMessage({ id: 'position' })),
        accessor: ({ position }) => position && position.name,
        Cell: ({ original: { position } }) =>
          position && (
            <CellContainer>
              <a href={positionLinkFromPosition(position)} target="_blank">
                {position.name}
              </a>
            </CellContainer>
          ),
        Filter: ({ filter, onChange }) =>
          renderFilterInput({
            placeholder: intl.formatMessage({ id: 'filter' }),
            filter,
            onChange,
            id: 'position',
          }),
      },
      {
        id: 'fixture',
        filterable: true,
        Header: renderHeaderCell(intl.formatMessage({ id: 'fixture' })),
        accessor: ({ position }) => position && position.fixtureName,
        Cell: ({ original: { position } }) =>
          position && (
            <CellContainer>
              <a href={fixtureLinkFromPosition(position)} target="_blank">
                {position.fixtureName}
              </a>
            </CellContainer>
          ),
        Filter: ({ filter, onChange }) =>
          renderFilterInput({
            placeholder: intl.formatMessage({ id: 'filter' }),
            filter,
            onChange,
            id: 'fixture',
          }),
      },
      {
        id: 'area',
        filterable: true,
        Header: renderHeaderCell(
          intl.formatMessage({ id: intlPath`areaFloor` })
        ),
        accessor: ({ position }) => position && position.areaName,
        Cell: ({ original: { position } }) =>
          position && (
            <CellContainer style={{ textAlign: 'left' }}>
              <div>
                <a href={areaLinkFromPosition(position)} target="_blank">
                  {position.areaName}
                </a>
              </div>
              <div style={subtextStyle}>
                <a href={storeLinkFromPosition(position)} target="_blank">
                  {position.floorName}
                </a>
              </div>
            </CellContainer>
          ),
        Filter: ({ filter, onChange }) =>
          renderFilterInput({
            placeholder: intl.formatMessage({ id: 'filter' }),
            filter,
            onChange,
            id: 'areaFloor',
          }),
      },
      {
        id: 'status',
        Header: renderHeaderCell(intl.formatMessage({ id: intlPath`status` })),
        accessor: (original) => `${original.category}`,
        Cell: ({ original: { category } }) => (
          <CellContainer>
            <TooltipCell
              text={intl.formatMessage({
                id: `securedProduct.categories.${category}`,
              })}
              render={(tooltip) => (
                <div ref={(ref) => tooltip.target(ref)}>
                  {intl.formatMessage({
                    id: `securedProduct.categories.${category}`,
                  })}
                </div>
              )}
            />
          </CellContainer>
        ),
      },
      {
        id: 'reason',
        filterable: true,
        Header: renderHeaderCell(
          intl.formatMessage({ id: 'securedProduct.retiredReason' })
        ),
        accessor: (original) => `${original.retiredReason}`,
        Cell: ({ original: { retiredReason } }) => (
          <CellContainer>
            <TooltipCell
              text={intl.formatMessage({
                id: `securedProduct.retiredReasons.${retiredReason}`,
              })}
              render={(tooltip) => (
                <div ref={(ref) => tooltip.target(ref)}>
                  {intl.formatMessage({
                    id: `securedProduct.retiredReasons.${retiredReason}`,
                  })}
                </div>
              )}
            />
          </CellContainer>
        ),
      },
      {
        id: 'firstIdentified',
        filterable: false,
        Header: renderHeaderCell(
          intl.formatMessage({ id: intlPath`firstIdentified` })
        ),
        accessor: (original) => moment(original.createdAt),
        Cell: ({ original: { createdAt } }) => (
          <CellContainer>
            <div>
              {moment(createdAt)
                .utcOffset(0)
                .format('MM/DD/YYYY h:mm')}
            </div>
          </CellContainer>
        ),
      },
      {
        id: 'lastIdentified',
        filterable: false,
        Header: renderHeaderCell(
          intl.formatMessage({ id: intlPath`lastIdentified` })
        ),
        accessor: (original) =>
          moment(original.lastSeenAt || original.createdAt),
        Cell: ({ original: { lastSeenAt, createdAt } }) => (
          <CellContainer>
            <div>
              {moment(lastSeenAt || createdAt)
                .utcOffset(0)
                .format('MM/DD/YYYY h:mm')}
            </div>
          </CellContainer>
        ),
      },
    ]
    const width = window.innerWidth

    const options = this.getSecuredProductOptions()
    const categoryValue = options.find(
      (option) => option.value === selectedCategory
    )

    let defaultFiltered = [{ id: 'status', value: selectedCategory }]
    if (selectedCategory === categories.ALL) {
      defaultFiltered = []
    }

    return (
      <div>
        <HeaderContainer className="border-bottom">
          <SecuredProductHeader>
            <FormattedMessage id="securedProducts" />
          </SecuredProductHeader>
          <HelpIcon helpKey={`securedProductCategories.${selectedCategory}`} />
          <SecuredProductHeaderItem>
            <Select
              name="securedProductCategories"
              value={categoryValue}
              onChange={onChangeCategory}
              options={options}
            />
          </SecuredProductHeaderItem>
        </HeaderContainer>
        {loading ? (
          <React.Fragment>
            <TablePreloader columnsCount={7} loaderWidth={width} />
            <ProgressIndicator text={intl.formatMessage({ id: 'loading' })} />
          </React.Fragment>
        ) : (
          <ReactTable
            className="full-screen"
            style={{ height: tableHeight || 'auto' }}
            data={securedProducts}
            columns={columns}
            defaultFilterMethod={fullTextFilter}
            defaultFiltered={defaultFiltered}
            getTdProps={(_blank, row, column) => ({
              onClick: (_blank, handleOriginal) => {
                if (
                  column.id === 'product' ||
                  column.id === 'imageSerialNumber'
                ) {
                  onSecuredProductSelection(row.original)
                }
                if (handleOriginal) {
                  handleOriginal()
                }
              },
            })}
          />
        )}
      </div>
    )
  }
}

SecuredProductsScreen.propTypes = {
  securedProducts: PropTypes.array.isRequired,
  onSecuredProductSelection: PropTypes.func.isRequired,
  onChangeCategory: PropTypes.func.isRequired,
  selectedCategory: PropTypes.string.isRequired,
  categories: PropTypes.object.isRequired,
}

export default injectIntl(SecuredProductsScreen)
