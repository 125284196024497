/*
 *
 * Organization Edit reducer
 *
 */

import { Map } from 'immutable'
import { ActionTypes } from './constants'

const initialState = new Map({
  patchLoading: false,
  patchFailed: null,
})

function organizationReducer(state = initialState, { type, payload }) {
  switch (type) {
    case ActionTypes.ORGANIZATION_DETAILS:
      return state
        .set('organizationDetails', payload)
        .set('management', ActionTypes.ORGANIZATION_DETAILS)
    case ActionTypes.RESET_ORGANIZATION_MANAGEMENT:
      return state
        .set('organizationDetails', undefined)
        .set('management', undefined)
    case ActionTypes.ORGANIZATION_EDIT:
      return state.mergeDeep(state, payload)
    case ActionTypes.ORGANIZATION_CLEAR:
      return state
        .delete('name')
        .delete('contactName')
        .delete('contactEmail')
        .delete('contactPhone')
        .delete('keyExpTimeIntervalEnabled')
        .delete('keyExpTimeInterval')
        .delete('timeZone')
        .delete('supportPhone')
        .delete('address') // .country .state .address1 .city .address2 .address3 .zip
        .delete('allowedKeyTypes')
    case ActionTypes.PATCH_ORGANIZATION_PENDING:
      return state.set('patchFailed', null).set('patchLoading', true)
    case ActionTypes.PATCH_ORGANIZATION_FULFILLED:
      return state.set('patchFailed', null).set('patchLoading', false)
    case ActionTypes.PATCH_ORGANIZATION_FAILED:
      return state.set('patchFailed', payload).set('patchLoading', false)
    default:
      return state
  }
}

export default organizationReducer
