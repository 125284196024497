import React from 'react'
import PropTypes from 'prop-types'
import { StyledTable } from './FixtureRules.screen'
import styled from 'styled-components'
import {
  renderFilterInput,
  renderHeaderCell,
} from '../../UsersPage/index.screen'
import { fullTextFilter } from '../../../utils/utils'
import TrComponent from '../../../components/TrComponent'
import Chance from 'chance'

const chance = new Chance()

export const SelectionButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
export const TableWithButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

class SelectProducts extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      dataOnLeft: this.getData(props.products),
      dataOnRight: this.getData(props.selectedProducts),
      selectionOnLeft: [],
      selectionOnRight: [],
      selectAllLeft: false,
      selectAllRight: false,
    }
  }

  getData = (keys) =>
    (keys || []).map((item) => {
      const _id = chance.guid() // eslint-disable-line
      return {
        _id,
        ...item,
      }
    })

  isSelectedOnLeft = (key) => this.state.selectionOnLeft.includes(key)
  isSelectedOnRight = (key) => this.state.selectionOnRight.includes(key)

  toggleSelectionOnLeft = (key) => {
    let selection = [...this.state.selectionOnLeft]
    const keyIndex = selection.indexOf(key)
    if (keyIndex >= 0) {
      selection = [
        ...selection.slice(0, keyIndex),
        ...selection.slice(keyIndex + 1),
      ]
    } else {
      selection.push(key)
    }
    this.setState({ selectionOnLeft: selection })
  }

  toggleSelectionOnRight = (key) => {
    let selection = [...this.state.selectionOnRight]
    const keyIndex = selection.indexOf(key)
    if (keyIndex >= 0) {
      selection = [
        ...selection.slice(0, keyIndex),
        ...selection.slice(keyIndex + 1),
      ]
    } else {
      selection.push(key)
    }
    this.setState({ selectionOnRight: selection })
  }

  toggleAllLeft = () => {
    const selectAll = !this.state.selectAllLeft
    const selection = []
    if (selectAll) {
      const wrappedInstance = this.checkboxTableLeft.getWrappedInstance()
      const currentRecords = wrappedInstance.getResolvedState().sortedData
      currentRecords.forEach((item) => {
        selection.push(item._original._id) // eslint-disable-line
      })
    }
    this.setState({ selectAllLeft: selectAll, selectionOnLeft: selection })
  }

  toggleAllRight = () => {
    const selectAll = !this.state.selectAllRight
    const selection = []
    if (selectAll) {
      const wrappedInstance = this.checkboxTableRight.getWrappedInstance()
      const currentRecords = wrappedInstance.getResolvedState().sortedData
      currentRecords.forEach((item) => {
        selection.push(item._original._id) // eslint-disable-line
      })
    }
    this.setState({ selectAllRight: selectAll, selectionOnRight: selection })
  }

  onAddSelectedProducts = () => {
    const { onAddSelectedProducts } = this.props
    const { selectionOnLeft, dataOnLeft } = this.state

    if (selectionOnLeft.length === 0) return

    const dataOnRight = dataOnLeft.filter(({ _id }) =>
      selectionOnLeft.includes(_id)
    )

    onAddSelectedProducts(dataOnRight)
    this.setState({
      selectionOnRight: [...selectionOnLeft],
      dataOnRight,
    })
  }

  onRemoveSelectedProducts = () => {
    const { onRemoveSelectedProducts } = this.props
    const { dataOnRight, selectionOnRight } = this.state

    if (selectionOnRight.length === 0) return

    const uncheckedProducts = dataOnRight.filter(
      ({ _id }) => !selectionOnRight.includes(_id)
    )

    onRemoveSelectedProducts(uncheckedProducts)
    this.setState({
      dataOnRight: uncheckedProducts,
    })
  }

  onRemoveAllSelectedProducts = () => {
    const { onRemoveSelectedProducts } = this.props
    onRemoveSelectedProducts([])
    this.setState({
      selectionOnRight: [],
      dataOnRight: [],
    })
  }

  render() {
    const {
      dataOnLeft,
      dataOnRight,
      selectAllLeft,
      selectAllRight,
    } = this.state

    const manufacturerColumn = {
      id: 'manufacturer',
      Header: renderHeaderCell('Manufacturer'),
      accessor: 'manufacturer.name',
      width: 130,
      Filter: ({ filter, onChange }) =>
        renderFilterInput({
          placeholder: 'Filter',
          filter,
          onChange,
        }),
    }

    const nameColumn = {
      Header: renderHeaderCell('Name'),
      accessor: 'name',
      Filter: ({ filter, onChange }) =>
        renderFilterInput({
          placeholder: 'Filter',
          filter,
          onChange,
        }),
    }

    const checkboxPropsLeft = {
      selectAll: selectAllLeft,
      isSelected: this.isSelectedOnLeft,
      toggleSelection: this.toggleSelectionOnLeft,
      toggleAll: this.toggleAllLeft,
      selectType: 'checkbox',
      getTrProps: (s, r) => {
        const selected = this.isSelectedOnLeft(((r || {}).original || {})._id)
        return {
          style: {
            backgroundColor: selected ? 'lightgreen' : 'inherit',
          },
        }
      },
    }

    const checkboxPropsRight = {
      selectAll: selectAllRight,
      isSelected: this.isSelectedOnRight,
      toggleSelection: this.toggleSelectionOnRight,
      toggleAll: this.toggleAllRight,
      selectType: 'checkbox',
      getTrProps: (s, r) => {
        const selected = this.isSelectedOnRight(((r || {}).original || {})._id)
        return {
          style: {
            backgroundColor: selected ? 'lightgreen' : 'inherit',
          },
        }
      },
    }

    const allProductsColumns = [manufacturerColumn, nameColumn]

    const selectedProductsColumns = [manufacturerColumn, nameColumn]

    return (
      <div className="d-flex">
        <StyledTable
          ref={(r) => {
            this.checkboxTableLeft = r
          }}
          filterable
          minRows={10}
          data={dataOnLeft}
          columns={allProductsColumns}
          defaultFilterMethod={fullTextFilter}
          nextText={'>'}
          previousText={'<'}
          rowsText={''}
          showPageSizeOptions={false}
          TrComponent={TrComponent}
          {...checkboxPropsLeft}
        />

        <SelectionButtonsContainer>
          <button
            type="button"
            className="btn btn-primary"
            onClick={this.onAddSelectedProducts}
          >
            Add Selected
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={this.onRemoveSelectedProducts}
          >
            Delete Selected
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={this.onRemoveAllSelectedProducts}
          >
            Remove All
          </button>
        </SelectionButtonsContainer>

        <TableWithButtonContainer>
          <StyledTable
            ref={(r) => {
              this.checkboxTableRight = r
            }}
            minRows={10}
            filterable
            data={dataOnRight}
            columns={selectedProductsColumns}
            defaultFilterMethod={fullTextFilter}
            nextText={'>'}
            previousText={'<'}
            rowsText={''}
            showPageSizeOptions={false}
            TrComponent={TrComponent}
            {...checkboxPropsRight}
          />
        </TableWithButtonContainer>
      </div>
    )
  }
}

SelectProducts.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  ruleType: PropTypes.string,
  condition: PropTypes.string,
  products: PropTypes.array.isRequired,
  selectedProducts: PropTypes.array.isRequired,
  deviceType: PropTypes.string.isRequired,
  onAddSelectedProducts: PropTypes.func.isRequired,
  onRemoveSelectedProducts: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  onAddPortComplianceRules: PropTypes.func,
}

export default SelectProducts
