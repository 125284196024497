import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { createStructuredSelector } from 'reselect'
import { selectStoreById } from '../selectors'
import { storeDetailsAction } from '../../../containers/App/actions'
import StoreManagement from '../../../containers/StoresPage/storeManagement'

class ViewStoreDetails extends React.PureComponent {
  render() {
    const { params: { sId, sName }, store } = this.props

    return sId && sName ? (
      <div key="breadcrumb-control" className="breadcrumb-control ml-5">
        <StoreManagement history={this.props.history} />
        <Link
          to={'#'}
          onClick={(e) => {
            e.preventDefault()
            if (store) {
              const { storeDetailsAction: doStoreDetailsAction } = this.props
              doStoreDetailsAction(store)
            }
          }}
        >
          VIEW STORE DETAILS
        </Link>
      </div>
    ) : null
  }
}

ViewStoreDetails.propTypes = {
  history: PropTypes.any,
  params: PropTypes.object,
  store: PropTypes.object,
  storeDetailsAction: PropTypes.func,
}

const mapStateToProps = createStructuredSelector({
  store: (state, ownProps) => selectStoreById(ownProps.params)(state, ownProps),
})

const mapDispatchToProps = {
  storeDetailsAction,
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewStoreDetails)
