import { ActionTypes } from './constants'

export function orphanedDevicesDataReducerAction(apiResponse) {
  return {
    type: ActionTypes.ORPHANED_DEVICES_DATA_REDUCER,
    payload: apiResponse,
  }
}

export function orphanedDevicesLoadingAction(isLoading) {
  return {
    type: ActionTypes.LOADING_ORPHANED_DEVICES,
    payload: { isLoading },
  }
}

export function fetchOrphanedDevicesData(filters) {
  return {
    type: ActionTypes.FETCH_ORPHANED_DEVICES_DATA,
    payload: { filters },
  }
}
