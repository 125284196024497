import { ActionTypes } from './constants'

const initialState = []
export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_HUBS_RESPONSE:
      if (action.payload) return action.payload
      return state
    case ActionTypes.FETCH_HUBS_REQUEST:
    default:
      return state
  }
}
