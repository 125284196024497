import React from 'react'
import PropTypes from 'prop-types'
import { ModalBody } from '../../StoresPage/CreateEditStoreModal/index.screen'
import { ButtonsContainer, WarningContainer } from '../RebootHubModal'

const RestartWarning = ({ id, onCancel, onRestart }) => (
  <div className="modal-content">
    <div className="modal-header border-bottom">
      <ButtonsContainer>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            $(`#modal-${id}`).modal('hide') // eslint-disable-line
            onCancel()
          }}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-primary btn-raised"
          onClick={() => {
            $(`#modal-${id}`).modal('hide') // eslint-disable-line
            onRestart()
          }}
        >
          Yes, I want to Restart
        </button>
      </ButtonsContainer>
    </div>
    <div>
      <ModalBody className="modal-body">
        <WarningContainer>
          WARNING: This action will restart the Lode
        </WarningContainer>
      </ModalBody>
    </div>
  </div>
)

RestartWarning.propTypes = {
  id: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onRestart: PropTypes.func.isRequired,
}

export default RestartWarning
