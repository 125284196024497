/*
 * AppConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

import keyMirror from 'fbjs/lib/keyMirror'

/**
 * @namespace Constants
 * @desc App constants
 */

/**
 * @constant {Object} ActionTypes
 * @memberof Constants
 */
export const ActionTypes = keyMirror({
  USER_RESTORE_REQUEST: undefined,
  USER_RESTORE_SUCCESS: undefined,
  USER_RESTORE_FAILURE: undefined,

  USER_LOGIN_REQUEST: undefined,
  USER_LOGIN_SUCCESS: undefined,
  USER_LOGIN_FAILURE: undefined,
  ADMIN_LOGIN_REQUEST: undefined,
  OTP_REQUEST: undefined,

  USER_LOGOUT_REQUEST: undefined,
  USER_LOGOUT_SUCCESS: undefined,
  USER_LOGOUT_FAILURE: undefined,

  MQTT_CONNECT_SUCCESS: undefined,
  MQTT_CONNECT_FAILURE: undefined,
  MQTT_ERROR: undefined,
  MQTT_CHANNEL_CLOSED: undefined,
  MQTT_MESSAGE_RECEIVE: undefined,
  MQTT_SUBSCRIBE_TO_ALL: undefined,
  MQTT_TOPIC_SUBSCRIBE: undefined,
  MQTT_TOPIC_UNSUBSCRIBE: undefined,
  MQTT_TOPIC_SUBSCRIBED: undefined,

  // Users
  FETCH_USERS_FAILED: undefined,
  FETCH_USERS_FULFILLED: undefined,
  FETCH_USERS_PENDING: undefined,
  LOAD_USERS: undefined,

  // Personae
  FETCH_ME_FAILED: undefined,
  FETCH_ME_FULFILLED: undefined,
  FETCH_ME_PENDING: undefined,
  LOAD_ME: undefined,

  // Personae
  FETCH_PERSONAE_FAILED: undefined,
  FETCH_PERSONAE_FULFILLED: undefined,
  FETCH_PERSONAE_PENDING: undefined,
  LOAD_PERSONAE: undefined,

  // Organizations
  FETCH_ORGANIZATIONS_FAILED: undefined,
  FETCH_ORGANIZATIONS_FULFILLED: undefined,
  FETCH_ORGANIZATIONS_PENDING: undefined,
  LOAD_ORGANIZATIONS: undefined,

  // Stores
  FETCH_STORES_FAILED: undefined,
  FETCH_STORES_FULFILLED: undefined,
  FETCH_STORES_PENDING: undefined,
  LOAD_STORES: undefined,

  // Templates
  FETCH_TEMPLATES_FAILED: undefined,
  FETCH_TEMPLATES_FULFILLED: undefined,
  FETCH_TEMPLATES_PENDING: undefined,
  LOAD_TEMPLATES: undefined,
  FETCH_TEMPLATES_START_TIME: undefined,

  // Store Levels
  LOAD_FLOORS: undefined,
  LOAD_FLOOR_CANVAS: undefined,
  LOAD_AREA_CANVAS: undefined,
  LOAD_FIXTURE_CANVAS: undefined,

  SECURITY_DEVICE_IDENTIFY: undefined,
  SECURITY_DEVICE_RESET_IDENTIFY: undefined,

  ORM_FETCH_AND_UPSERT_STORE: undefined,
  ORM_FETCH_AND_UPSERT_STORE_PENDING: undefined,
  ORM_FETCH_AND_UPSERT_STORE_FULFILLED: undefined,
  ORM_FETCH_AND_UPSERT_STORE_FAILED: undefined,

  ORM_FETCH_AND_UPSERT_GEOMETRY_PENDING: undefined,
  ORM_FETCH_AND_UPSERT_GEOMETRY_FULFILLED: undefined,
  ORM_FETCH_AND_UPSERT_GEOMETRY_FAILED: undefined,

  LOAD_SECURED_PRODUCTS: undefined,
  FETCH_SECURED_PRODUCTS_PENDING: undefined,
  FETCH_SECURED_PRODUCTS_FULFILLED: undefined,
  FETCH_SECURED_PRODUCTS_FAILED: undefined,

  // Schedules
  LOAD_SCHEDULES: undefined,
  FETCH_SCHEDULES_PENDING: undefined,
  FETCH_SCHEDULES_FULFILLED: undefined,
  FETCH_SCHEDULES_FAILED: undefined,

  ORM_UPSERT: undefined,
  ORM_RESET: undefined,
  ORM_UPSERT_MANUFACTURERS: undefined,
  ORM_UPSERT_SECURED_PRODUCTS: undefined,
  ORM_UPDATE_SECURED_PRODUCTS: undefined,

  OPEN_MODAL: undefined,
  CLOSE_MODAL: undefined,

  LOAD_TAGS: undefined,
  PUT_TAGGINGS: undefined,
  PUT_TAGS: undefined,
  POST_TAGGINGS: undefined,
  POST_TAGGINGS_PENDING: undefined,
  POST_TAGGINGS_FULFILLED: undefined,
  CLEAR_TAGS: undefined,
  PATCH_TAGS: undefined,
  EDIT_TAGS: undefined,

  STORE_IMAGES: undefined,

  LOAD_ORGANIZATION_REQUEST: undefined,
  LOAD_ORGANIZATION_SUCCESS: undefined,
  LOAD_ORGANIZATION_FAILURE: undefined,

  ORGANIZATION_DETAILS: undefined,
  RESET_ORGANIZATION_MANAGEMENT: undefined,
  ORGANIZATION_EDIT: undefined,
  ORGANIZATION_CLEAR: undefined,
  PATCH_ORGANIZATION_FAILED: undefined,
  PATCH_ORGANIZATION_FULFILLED: undefined,
  PATCH_ORGANIZATION_PENDING: undefined,
  PATCH_ORGANIZATION: undefined,

  POST_POSITION_ACTION: undefined,
  POST_POSITION_ACTION_PENDING: undefined,
  POST_POSITION_ACTION_FULFILLED: undefined,
  POST_POSITION_ACTION_FAILED: undefined,

  POST_SECURITY_DEVICE_ACTION: undefined,
  POST_SECURITY_DEVICE_ACTION_PENDING: undefined,
  POST_SECURITY_DEVICE_ACTION_FULFILLED: undefined,
  POST_SECURITY_DEVICE_ACTION_FAILED: undefined,

  POST_STORE_ACTION: undefined,
  STORE_LOCKDOWN: undefined,

  USER_DETAILS: undefined,
  CHANGE_USER_STATUS: undefined,
  CREATE_USER: undefined,
  RESET_USER_MANAGEMENT: undefined,
  USER_EDIT: undefined,
  USER_CLEAR: undefined,
  POST_USER_FAILED: undefined,
  POST_USER_FULFILLED: undefined,
  POST_USER_PENDING: undefined,
  POST_USER: undefined,
  DELETE_USER_FAILED: undefined,
  DELETE_USER_FINISHED: undefined,
  DELETE_USER_PENDING: undefined,
  DELETE_USER: undefined,
  PATCH_USER_FAILED: undefined,
  PATCH_USER_FULFILLED: undefined,
  PATCH_USER_PENDING: undefined,
  PATCH_USER: undefined,
  RESET_PASSWORD: undefined,
  RESET_PASSWORD_PENDING: undefined,
  RESET_PASSWORD_FULFILLED: undefined,
  RESET_PASSWORD_FAILED: undefined,
  FIND_USER_FAILED: undefined,
  FIND_USER_FULFILLED: undefined,
  FIND_USER_PENDING: undefined,
  FIND_USER: undefined,
  FIND_USER_FOUND_HANDLED: undefined,
  ADD_NEW_ORGANIZATION: undefined,
  DELETE_ORGANIZATION: undefined,
  ADD_NEW_REGION: undefined,
  DELETE_REGION: undefined,
  ADD_NEW_STORE: undefined,
  DELETE_STORE: undefined,
  EDIT_ROLE_CLEAR: undefined,

  SCHEDULE_DETAILS: undefined,
  CREATE_SCHEDULE: undefined,
  RESET_SCHEDULE_MANAGEMENT: undefined,
  SCHEDULE_EDIT: undefined,
  SCHEDULE_CLEAR: undefined,
  POST_SCHEDULE_FAILED: undefined,
  POST_SCHEDULE_FULFILLED: undefined,
  POST_SCHEDULE_PENDING: undefined,
  POST_SCHEDULE: undefined,
  DELETE_SCHEDULE: undefined,
  DELETE_SCHEDULE_FAILED: undefined,
  DELETE_SCHEDULE_FINISHED: undefined,
  DELETE_SCHEDULE_PENDING: undefined,
  PATCH_SCHEDULE_FAILED: undefined,
  PATCH_SCHEDULE_FULFILLED: undefined,
  PATCH_SCHEDULE_PENDING: undefined,
  PATCH_SCHEDULE: undefined,

  // Regions
  FETCH_REGIONS_FULFILLED: undefined,
  FETCH_REGIONS_FAILED: undefined,
  FETCH_REGIONS_PENDING: undefined,
  LOAD_REGIONS: undefined,
  FETCH_REGION_WITH_STORE: undefined,
  FETCH_REGION_WITH_STORE_FULFILLED: undefined,

  REGION_DETAILS: undefined,
  CREATE_REGION: undefined,
  RESET_REGION_MANAGEMENT: undefined,
  REGION_EDIT: undefined,
  REGION_CLEAR: undefined,
  POST_REGION_FAILED: undefined,
  POST_REGION_FULFILLED: undefined,
  POST_REGION_PENDING: undefined,
  POST_REGION: undefined,
  DELETE_REGION_FAILED: undefined,
  DELETE_REGION_FINISHED: undefined,
  DELETE_REGION_PENDING: undefined,
  DELETE_REGION_ACT: undefined,
  PATCH_REGION_FAILED: undefined,
  PATCH_REGION_FULFILLED: undefined,
  PATCH_REGION_PENDING: undefined,
  PATCH_REGION: undefined,
  FIND_REGION_FAILED: undefined,
  FIND_REGION_FULFILLED: undefined,
  FIND_REGION_PENDING: undefined,
  FIND_REGION: undefined,
  FIND_REGION_FOUND_HANDLED: undefined,

  LOAD_KEYS: undefined,
  FETCH_KEYS_FAILED: undefined,
  FETCH_KEYS_FULFILLED: undefined,
  FETCH_KEYS_PENDING: undefined,
  ADD_KEY: undefined,
  ADD_KEYS: undefined,
  DELETE_KEY: undefined,
  DELETE_KEYS: undefined,
  DELETE_KEY_PENDING: undefined,
  DELETE_KEY_FINISHED: undefined,
  DELETE_KEY_FAILED: undefined,
  POST_KEY_FAILED: undefined,
  POST_KEY_FINISHED: undefined,
  POST_KEY_PENDING: undefined,
  ACTIVATE_KEYS: undefined,
  UPDATE_KEY: undefined,
  UPDATE_KEY_FAILED: undefined,
  UPDATE_KEY_FINISHED: undefined,
  UPDATE_KEY_PENDING: undefined,
  ADD_NEW_KEY: undefined,
  UPDATE_NEW_KEY: undefined,
  DELETE_NEW_KEY: undefined,
  DELETE_NEW_KEYS: undefined,
  ADD_DEFAULT_KEY: undefined,

  STORE_DETAILS: undefined,
  CREATE_STORE: undefined,
  RESET_STORE_MANAGEMENT: undefined,
  STORE_EDIT: undefined,
  STORE_CLEAR: undefined,
  POST_STORE_FAILED: undefined,
  POST_STORE_FULFILLED: undefined,
  POST_STORE_PENDING: undefined,
  POST_STORE: undefined,
  PATCH_STORE: undefined,
  PATCH_STORE_FAILED: undefined,
  PATCH_STORE_FULFILLED: undefined,
  PATCH_STORE_PENDING: undefined,
  STORE_CREATE_DEFAULT_FLOOR_AND_AREA: undefined,

  // Hubs (SALT)
  FETCH_FIRMWARES_FAILED: undefined,
  FETCH_FIRMWARES_FULFILLED: undefined,
  FETCH_FIRMWARES_PENDING: undefined,
  LOAD_FIRMWARES: undefined,

  UPLOAD_FIRMWARE: undefined,
  UPLOAD_FIRMWARE_PENDING: undefined,
  UPLOAD_FIRMWARE_FULFILLED: undefined,
  UPLOAD_FIRMWARE_FAILED: undefined,

  DELETE_FIRMWARE: undefined,
  DELETE_FIRMWARE_PENDING: undefined,
  DELETE_FIRMWARE_FULFILLED: undefined,
  DELETE_FIRMWARE_FAILED: undefined,

  FETCH_HUB: undefined,
  FETCH_HUB_PENDING: undefined,
  FETCH_HUB_FULFILLED: undefined,
  FETCH_HUB_FAILED: undefined,

  FETCH_HUB_STATUS: undefined,
  FETCH_HUB_STATUS_PENDING: undefined,
  FETCH_HUB_STATUS_FAILED: undefined,
  FETCH_HUB_STATUS_FULFILLED: undefined,

  PING_HUB: undefined,
  PING_HUB_PENDING: undefined,
  PING_HUB_FULFILLED: undefined,
  PING_HUB_FAILED: undefined,

  REBOOT_HUB: undefined,
  RESTART_LODE: undefined,

  SECURED_PRODUCT_DETAILS: undefined,
  RESET_SECURED_PRODUCT_MANAGEMENT: undefined,
  SECURED_PRODUCT_EDIT: undefined,
  PATCH_SECURED_PRODUCT: undefined,
  PATCH_SECURED_PRODUCT_PENDING: undefined,
  PATCH_SECURED_PRODUCT_FAILED: undefined,
  PATCH_SECURED_PRODUCT_FULFILLED: undefined,

  LOAD_DATA_FOR_ORGANIZATION: undefined,
  APP_RESET: undefined,
})

/**
 * @constant {Object} XHR
 * @memberof Constants
 */
export const XHR = keyMirror({
  SUCCESS: undefined,
  FAIL: undefined,
})

export const DEFAULT_LOCALE = 'en'

export const Routes = {
  LOGIN: '/login',
  ADMIN_LOGIN: '/admin/login',
}
