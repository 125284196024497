import { fabric } from 'fabric'
import uuid from 'uuid'
import {
  alertTypes,
  sviFigureType,
  sviFigureSizeType,
} from '../../utils/mtiCanvasUtils'
import { iconScale, largeTextSize } from '../../utils/screen'
import { truncate, getTruncatedTextParams } from '../FloorCanvas/utils'
import { getFixtureColor } from './utils'
import { sviFigureSizes } from './sizeUtils'
import { getObjectSync } from '../../containers/FloorPage/objectUtils'
import { getFixtureObjectSync } from '../../containers/AreaPage/objectUtils'
import { editColor } from '../FloorCanvas/area'

export const padding = 0
export const minLabelW = 88
export const labelH = 24
export const minW = 20
export const minH = 20
export const minTruncateN = 9
export const cornerSize = 18
export const borderScaleFactor = 4

// Used for view mode
const Lock = {
  lockMovementX: true,
  lockMovementY: true,
  lockScalingX: true,
  lockScalingY: true,
  hasControls: false,
  hasRotatingPoint: false,
}

export const TruncatedText = fabric.util.createClass(fabric.Text, {
  type: 'truncatedText',

  initialize(text, options) {
    const label = options.fullText || text || ''
    const truncatedTextLength = options.truncatedTextLength || minTruncateN

    this.callSuper('initialize', label, options || {})
    this.set('truncatedTextLength', truncatedTextLength)
    this.set('fullText', label)
    this.set('text', truncate(truncatedTextLength, label))
    this.set('name', options.name)
  },

  toObject() {
    return fabric.util.object.extend(this.callSuper('toObject'), {
      name: this.get('name'),
      fullText: this.get('fullText'),
      truncatedTextLength: this.get('truncatedTextLength'),
    })
  },

  _render(ctx) {
    const truncatedTextLength = this.get('truncatedTextLength') || minTruncateN
    this.set('text', truncate(truncatedTextLength, this.get('fullText')))
    this.callSuper('_render', ctx)
  },
})

fabric.TruncatedText = TruncatedText
fabric.TruncatedText.fromObject = (options, cb) => {
  if (cb) cb(new fabric.TruncatedText(options.text, options))
}

export const slicingFixtureSync = (
  id,
  name,
  positionsCount,
  nameHasShown,
  parentId,
  noninteractive,
  layoutPosition = {},
  pointer,
  origX,
  origY,
  isStatic = false,
  isMobile = false,
  alertType = alertTypes.normal,
  alertCount,
  prototypes,
  mtiJsclientShared
) => {
  const type = layoutPosition.type || sviFigureType.custom

  const statusIcon = getObjectSync(
    alertType,
    alertCount,
    prototypes,
    mtiJsclientShared
  )
  const fixtureObject = getFixtureObjectSync(type, prototypes)

  return createFixture(
    id,
    name,
    positionsCount,
    nameHasShown,
    parentId,
    noninteractive,
    layoutPosition,
    pointer,
    origX,
    origY,
    isStatic,
    isMobile,
    alertType,
    alertCount,
    statusIcon,
    fixtureObject,
    mtiJsclientShared
  )
}

export const slicingFixture = async (o, pointer, origX, origY) => {
  const {
    id,
    name,
    positionsCount,
    nameHasShown,
    parentId,
    noninteractive,
    layoutPosition,
  } = o

  return createFixture(
    id,
    name,
    positionsCount,
    nameHasShown,
    parentId,
    noninteractive,
    layoutPosition,
    pointer,
    origX,
    origY,
    false
  )
}

const createFixture = (
  id,
  name,
  positionsCount = 0,
  nameHasShown,
  parentId,
  noninteractive,
  layoutPosition = {},
  pointer,
  origX,
  origY,
  isStatic = false,
  isMobile = false,
  alertType = alertTypes.normal,
  alertCount = 0,
  statusIcon = new fabric.Rect({ width: 0, height: 0 }),
  fixtureObj = new fabric.Rect({ width: 1, height: 1, fill: editColor.weak }),
  mtiJsclientShared = {}
) => {
  const { ArmState = {} } = mtiJsclientShared

  const text = name
  const shouldDisplayName = nameHasShown

  const isFurniture = !!noninteractive
  const type = layoutPosition.type || sviFigureType.custom
  const angle = layoutPosition.theta || 0

  const width = (pointer.x - origX) * 2
  const height = (pointer.y - origY) * 2
  const isVertical = getIsVertical({ width, height })
  const { textLength, textSize, textFamily } = getTruncatedTextParams(
    text,
    isVertical ? height : width,
    minLabelW,
    padding
  )

  const positionsCountObject = getPositionsCountObject(positionsCount, isMobile)

  const isAlert =
    alertType === alertTypes.alert ||
    alertType === ArmState.ALARMING ||
    alertType === alertTypes.lowAlert ||
    alertType === ArmState.DISARMED ||
    alertType === ArmState.UNLOCKED ||
    alertType === alertTypes.warning

  const titleObject = getTitleObject(
    text,
    textLength,
    textSize,
    textFamily,
    isMobile,
    shouldDisplayName
  )

  statusIcon.set({ targetable: true })
  const iconObject =
    isStatic && isAlert ? statusIcon : new fabric.Rect({ width: 0, height: 0 })

  iconObject.set({
    left: 0,
    top: 0,
    originX: 'center',
    originY: 'center',
  })

  positionDecorations(
    {
      width,
      height,
      id,
      name,
      positionsCount,
      nameHasShown,
      parentId,
      noninteractive,
      layoutPosition,
    },
    [fixtureObject, titleObject, iconObject, positionsCountObject],
    isStatic,
    true
  )

  const { weakColor, mainColor } = getFixtureColor(
    alertType,
    isStatic,
    isFurniture,
    positionsCount,
    mtiJsclientShared
  )
  const fixtureObject = configureFixtureObject(
    fixtureObj,
    width,
    height,
    weakColor,
    mainColor
  )

  const controlsVisibility = getControlsVisibility(type, isStatic)

  return new fabric.Group(
    [fixtureObject, titleObject, iconObject, positionsCountObject],
    {
      id,
      name,
      positionsCount,
      nameHasShown,
      parentId,
      noninteractive,
      layoutPosition,
      left: origX,
      top: origY,
      originX: 'center',
      originY: 'center',
      width,
      height,
      sviType: type,
      sviSizeType: layoutPosition.size,
      angle,
      transparentCorners: false,
      hasRotatingPoint: true,
      cornerSize,
      borderScaleFactor,
      hasBorders: !isMobile,
      cornerColor: mainColor,
      borderColor: mainColor,
      lockRotation: false,
      lockMovementX: false,
      lockMovementY: false,
      lockScalingX: false,
      lockScalingY: false,
      lockScalingFlip: true,
      fixtureIssuesCount: alertCount,
      isFurniture,
      applyFutureTemplateUpdates: !!parentId,
      ...(isStatic ? Lock : {}),
      // TODO: Turn this off, since we *want* to be able to edit the fixture the way the library intends.
      // This is undocumented property be careful using this
      _controlsVisibility: controlsVisibility,
    }
  )
}

function positionDecorations(group, decorations, isStatic) {
  const {
    width,
    height,
    positionsCount = 0,
    nameHasShown: shouldDisplayName,
    layoutPosition = {},
  } = group

  const angle = layoutPosition.theta || 0

  const isVertical = getIsVertical({ width, height })

  const titleObject = decorations[1]
  const iconObject = decorations[2]
  const positionsCountObject = decorations[3]

  const spaceForFixtureNameRequired = shouldDisplayName && positionsCount !== 0

  if (spaceForFixtureNameRequired) {
    titleObject.set({ left: 0, top: 15 * iconScale })
  }
  if (isVertical) {
    if (spaceForFixtureNameRequired) {
      titleObject.set({ left: 15 * iconScale, top: 0 })
    }
  }

  // Move status icon to have some place for fixture label
  if (spaceForFixtureNameRequired) {
    iconObject.set({ top: -9 * iconScale })
    if (isStatic) {
      positionsCountObject.set({ top: iconObject.top })
    }
  }

  // Move status icon left and positions count right
  if (isStatic) {
    iconObject.set({ left: -10 * iconScale })
    positionsCountObject.set({
      left: iconObject.left + iconObject.width / 2 + 10 * iconScale,
    })
  }

  const isRightAngle = angle === '90' || angle === '270'
  const isUpsideDown = angle === '180'

  if (isRightAngle) {
    iconObject.set({ angle: -angle })
    positionsCountObject.set({ angle: -angle })
  } else if (isUpsideDown) {
    iconObject.set({ angle: -angle })
    positionsCountObject.set({ angle: -angle })
    titleObject.set({ flipY: true, flipX: true })
  }

  if (isVertical) {
    if (isStatic) {
      if (shouldDisplayName) {
        if (iconObject.height) {
          positionsCountObject.set({
            left: iconObject.left,
            top: iconObject.top + iconObject.height / 2 + 15 * iconScale,
          })
        } else {
          positionsCountObject.set({
            left: positionsCountObject.top + 5 * iconScale,
            top: positionsCountObject.left,
          })
        }
      } else {
        if (iconObject.height) {
          iconObject.set({ top: -10 * iconScale })
          positionsCountObject.set({
            left: 0,
            top: iconObject.top + iconObject.height / 2 + 15 * iconScale,
          })
        }
      }
    } else {
      if (spaceForFixtureNameRequired) {
        positionsCountObject.set({ left: -10 * iconScale })
      } else {
        positionsCountObject.set({ left: 0 })
      }
    }
  } else {
    if (isStatic) {
      // Static
    } else {
      if (spaceForFixtureNameRequired) {
        positionsCountObject.set({
          top: -6 * iconScale,
        })
      } else {
        positionsCountObject.set({ left: 0 })
      }
    }
  }

  if (isVertical) {
    titleObject.set({ angle: -90 })
    titleObject.set({ width: height })
    if (isStatic) {
      if (iconObject.width > 0) {
        if (!shouldDisplayName) {
          iconObject.set({ left: 0 })
        }
        titleObject.set({
          left: iconObject.left + iconObject.width / 2 + 10 * iconScale,
        })
      } else if (positionsCount) {
        titleObject.set({
          left:
            positionsCountObject.left +
            positionsCountObject.width / 2 +
            10 * iconScale,
        })
      }
    } else {
      if (positionsCount) {
        titleObject.set({
          left:
            positionsCountObject.left +
            positionsCountObject.width / 2 +
            10 * iconScale,
        })
      }
    }
  } else {
    // horizontal
    titleObject.set({ angle: 0 })
    titleObject.set({ width: width })
    if (iconObject.height) {
      titleObject.set({
        top: iconObject.top + iconObject.height / 2 + 10 * iconScale,
      })
    } else if (positionsCount) {
      titleObject.set({
        top:
          positionsCountObject.top +
          positionsCountObject.height / 2 +
          5 * iconScale,
      })
    }
  }
  if (!shouldDisplayName) {
    titleObject.set({ left: 0, top: 0 })
  }
  if (!positionsCount) {
    positionsCountObject.set({ left: 0, top: 0 })
  }
}

function configureFixtureObject(fixture, width, height, weakColor, mainColor) {
  fixture.set({
    left: 0,
    top: 0,
    name: 'rect',
    originX: 'center',
    originY: 'center',
    scaleX: width / fixture.width,
    scaleY: height / fixture.height,
    fill: weakColor,
    stroke: mainColor,
    strokeWidth: 0,
  })
  return fixture
}

function getTitleObject(
  text,
  textLength,
  textSize,
  textFamily,
  isMobile,
  shouldDisplayName
) {
  return new fabric.TruncatedText(text, {
    truncatedTextLength: textLength,
    name: 'label',
    fontSize: textSize,
    fill: '#000000',
    fontFamily: textFamily,
    originX: 'center',
    originY: 'center',
    fontWeight: isMobile ? 400 : 200,
    left: 0,
    top: 0,
    opacity: shouldDisplayName ? 1 : 0,
  })
}

function getPositionsCountObject(positionsCount, isMobile) {
  if (positionsCount === 0) {
    return new fabric.Rect({ width: 0, height: 0 })
  }
  return new fabric.TruncatedText(positionsCount.toString(), {
    left: 0,
    top: 0,
    truncatedTextLength: 3,
    name: 'label',
    fontSize: largeTextSize,
    fill: '#000000',
    fontFamily: 'Arial',
    fontStyle: 'normal',
    originX: 'center',
    originY: 'center',
    fontWeight: isMobile ? 400 : 200,
  })
}

function getControlsVisibility(type, isStatic) {
  const ic = {
    bl: false,
    br: false,
    mb: false,
    ml: false,
    mr: false,
    mt: false,
    mtr: false,
    tl: false,
    tr: false,
  }
  if (isStatic) {
    return ic
  }
  switch (type) {
    case 'rect':
    case 'table':
    case sviFigureType.custom:
      return {
        bl: true,
        br: true,
        mb: true,
        ml: true,
        mr: true,
        mt: true,
        mtr: true,
        tl: true,
        tr: true,
      }
    case sviFigureType.square:
      return { ...ic, bl: true, br: true, mtr: true, tl: true, tr: true }
    case sviFigureType.circle:
    case sviFigureType.quarterCircle:
    case sviFigureType.extendedRightQuarterCircle:
    case sviFigureType.extendedLeftQuarterCircle:
    case sviFigureType.extendedQuarterCircle:
      return { ...ic, mtr: true }
    default:
      return { ...ic, mtr: true }
  }
}

export function getWidthHeight(type, sizeType = sviFigureSizeType.medium) {
  return { ...sviFigureSizes[type][sizeType], sizeType }
}

function getIsVertical({ width, height }) {
  return height > width * 1.1
}

export function renderLabel(group, isStatic) {
  const fixtureObject = group.item(0)
  const titleObject = group.item(1)
  const iconObject = group.item(2)
  const positionsCountObject = group.item(3)

  const isVertical = getIsVertical(group)
  const textWidth = isVertical ? group.height : group.width

  const { textLength, textSize, textFamily } = getTruncatedTextParams(
    titleObject.fullText,
    textWidth,
    textWidth,
    padding
  )

  titleObject.set({
    width: textWidth,
    truncatedTextLength: textLength,
    fontSize: textSize,
    fontFamily: textFamily,
  })

  positionDecorations(
    group,
    [fixtureObject, titleObject, iconObject, positionsCountObject],
    isStatic
  )

  return group
}

export function createIcObject(name, left, top, type = sviFigureType.custom) {
  return {
    id: uuid.v4(),
    name,
    nameHasShown: true,
    noninteractive: false,
    layoutPosition: {
      type,
      xCenter: left,
      yCenter: top,
      size: sviFigureSizeType.custom,
    },
  }
}

export function updateIcObject(rect, screen) {
  const { width: sW } = screen

  rect.layoutPosition.xCenter = rect.left
  rect.layoutPosition.yCenter = rect.top

  rect.layoutPosition.width = rect.width / sW
  rect.layoutPosition.height = rect.height / sW
}

export function updateIcAngle(rect) {
  rect.layoutPosition.theta = rect.angle
}

export const propertiesToInclude = [
  'id',
  'name',
  'positionsCount',
  'nameHasShown',
  'parentId',
  'noninteractive',
  'layoutPosition',
  'createdFromParentId',
  'sviType',
  'sviSizeType',
  'fixtureIssuesCount',
  'contextType',
  'isFurniture',
  'applyFutureTemplateUpdates',
  'selectable',
  'hasControls',
  'hasRotatingPoint',
  'lockRotation',
  'cornerSize',
  'cornerColor',
  'transparentCorners',
  'hasBorders',
  'borderScaleFactor',
  'borderColor',
  'origStrokeWidth',
  'lockMovementX',
  'lockMovementY',
  'lockScalingX',
  'lockScalingY',
  'lockUniScaling',
  'lockScalingFlip',
  'isEdit',
  'targetable',
  'o',
  '_controlsVisibility',
]
