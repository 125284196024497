import { Map } from 'immutable'
import { ActionTypes } from './constants'

export const initialState = new Map({
  stores: null,
  organizations: null,
  selectedOrganizationId: '',
  failed: null,
  loading: false,
})

function reducer(state = initialState, { payload, type }) {
  switch (type) {
    case ActionTypes.FETCH_STORES_LIST_FULFILLED:
      return state
        .set('stores', payload.stores)
        .set('organizations', payload.organizations)
        .set('loading', false)
        .set('failed', null)
    case ActionTypes.FETCH_STORES_LIST_PENDING:
      return state.set('failed', null).set('loading', true)
    case ActionTypes.FETCH_STORES_LIST_FAILED:
      return state.set('failed', payload.error).set('loading', false)
    case ActionTypes.SELECT_ORGANIZATION:
      return state.set('selectedOrganizationId', payload.organizationId)
    default:
      return state
  }
}

export default reducer
