/**
 *
 * Common Title
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

export const TitlePanel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  height: 64px;
  padding: 0 25px;
  border-bottom: 1px solid #d9d9d9;
`

export const Text = styled.span`
  font-size: 20px;
  text-align: left;
`

function Title({ title }) {
  return (
    <TitlePanel>
      <Text>{title}</Text>
    </TitlePanel>
  )
}

Title.propTypes = {
  title: PropTypes.string.isRequired,
}

export default Title
