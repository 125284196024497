/*
 *
 * TemplatePositionsScreen
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import mtiJsclientShared from '../../utils/mtiJsclientShared'
import SidebarTitle from '../../components/SidebarTitle'
import Input from '../../components/Input'
import EditDisabled from '../../components/EditDisabled'
import { SidebarSection } from '../../components/SidebarSection'
import SidebarSectionHighlight from '../../components/SidebarSectionHighlight'
import Checkbox from '../../components/Checkbox'
import ComplianceRule from '../../components/ComplianceRule'
import EmptyCanvasLabel from '../../components/EmptyCanvasLabel'
import {
  CheckboxAndInputContainer,
  CheckboxContainer,
  ComplianceBody,
  LoaderWrapper,
  Separator,
} from '../TemplateFixture/index.screen'
import styles from '../../styles/App.css'
import FixtureCanvas from '../../components/FixtureCanvas'
import PromptTable from '../../components/PromptTable'
import { isTemplateCloneableOnly } from '../AreaPage/utils'
import { figureSize, figureType, isSourceAttribute, isPortRule } from './utils'
import {
  SOURCE_ATTRIBUTES,
  MULTI_PORT_COMPLIANCE_DEVICE,
} from '../TemplateFixture/modals/ruleConstants'
import { PropagateLoader } from 'react-spinners'
import { BorderedButton, SidebarBody, TopContainer } from '../../styles/styled'
import { defaultName } from '../../utils/mtiCanvasUtils'
import { IssuesContainer } from '../../components/NotCompliantFixtures'

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

class TemplatePositionsScreen extends React.PureComponent {
  onNameChanged = (name) => {
    const { onNameChanged } = this.props
    onNameChanged(name)
  }

  onPositionCountChanged = (count) => {
    const { onPositionCountChanged } = this.props
    onPositionCountChanged(count)
  }

  getSelectedPositionObject() {
    const { canvasObject, selectedId } = this.props
    return (
      selectedId !== -1 &&
      selectedId !== 0 &&
      canvasObject.objects.find(({ id }) => id === selectedId)
    )
  }

  getPromptText() {
    const { isStatic, canvasObject, selectedId } = this.props
    const hasPositions =
      canvasObject && canvasObject.objects && canvasObject.objects.length > 1
    const hasSelected = selectedId !== -1 && selectedId !== 0
    let data = []
    if (isStatic) {
      if (hasPositions) {
        data = [
          {
            func: 'EDIT FIXTURE',
            desc: 'Click on EDIT FIXTURE in SIDE PANEL',
          },
          {
            func: 'CREATE NEW POSITION',
            desc: 'Click on CREATE NEW POSITION in SIDE PANEL',
          },
          {
            func: 'EDIT EXISTING POSITION',
            desc: 'Click on EDIT near POSITION in SIDE PANEL',
          },
        ]
      } else if (!hasPositions) {
        data = [
          {
            func: 'EDIT FIXTURE',
            desc: 'Click on EDIT FIXTURE in SIDE PANEL',
          },
          {
            func: 'CREATE NEW POSITION',
            desc: 'Click on CREATE NEW POSITION in SIDE PANEL',
          },
        ]
      }
    } else if (!isStatic) {
      if (hasSelected) {
        const isNew = isNaN((this.getSelectedPositionObject() || {}).id)
        if (isNew) {
          data = [
            {
              func: 'CREATE NEW POSITION',
              desc:
                'Use SIDE PANEL to set attributes of POSITION and click on SAVE',
            },
            {
              func: 'CANCEL',
              desc: 'Click on CANCEL in SIDE PANEL',
            },
          ]
        } else if (!isNew) {
          data = [
            {
              func: 'MODIFY POSITION',
              desc:
                'Use SIDE PANEL to set attributes of POSITION and click on SAVE',
            },
            {
              func: 'DELETE POSITION',
              desc: 'Click on DELETE in SIDE PANEL',
            },
            {
              func: 'CANCEL',
              desc: 'Click on CANCEL in SIDE PANEL',
            },
          ]
        }
      } else if (!hasSelected) {
        data = [
          {
            func: 'CREATE NEW POSITION',
            desc:
              'Click in CANVAS to start creating NEW POSITION and drag to move',
          },
          {
            func: 'CANCEL',
            desc: 'Click on CANCEL in SIDE PANEL',
          },
        ]
      }
    }
    return data
  }

  getSelectedPositionName = () => {
    const { selectedPosition = {}, selectedPositionName } = this.props
    return (
      selectedPositionName ||
      (selectedPosition ? selectedPosition.mtiName : defaultName.position)
    )
  }

  renderSidebarHeaderForPositions() {
    const {
      onCancel,
      onSaveAll,
      selectedId,
      isAutoplacement,
      canvasObject: { objects } = {},
    } = this.props

    if (isAutoplacement) {
      const hasPositions = objects && objects.length > 1
      return (
        <SidebarTitle
          title={
            selectedId !== -1 && selectedId !== 0
              ? this.getSelectedPositionName()
              : 'Fixture'
          }
          titleTo={null}
        >
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => onCancel()}
          >
            Cancel
          </button>
          {hasPositions && (
            <button
              type="button"
              className="btn btn-raised"
              onClick={() => onSaveAll()}
            >
              Save All
            </button>
          )}
        </SidebarTitle>
      )
    }
    return (
      <SidebarTitle
        title={
          selectedId !== -1 && selectedId !== 0
            ? this.getSelectedPositionName()
            : 'Fixture'
        }
        titleTo={null}
      >
        <button
          type="button"
          className="btn btn-raised"
          onClick={() => onCancel()}
        >
          Done
        </button>
      </SidebarTitle>
    )
  }

  renderEditSidebarForPositions() {
    const {
      selectedPositionCount = 0,
      selectedAutoplacementMax,
      isAutoplacement,
    } = this.props

    return (
      <div>
        {this.renderSidebarHeaderForPositions()}
        <SidebarSection>
          {isAutoplacement && (
            <Input
              name={'positionCount'}
              label={'# Positions'}
              value={selectedPositionCount}
              type={'number'}
              min={'0'}
              max={selectedAutoplacementMax.toString()}
              onChange={(e) => {
                this.onPositionCountChanged(parseInt(e.target.value, 10))
              }}
            />
          )}
        </SidebarSection>
      </div>
    )
  }

  renderTemplateViewSidebar = () => {
    const {
      isStatic,
      onEditMode,
      onEditTemplate,
      onAddFixtureComplianceRule,
      onDeleteAllFixtureComplianceRules,
      onEditFixtureComplianceRule,
      onLinkedFixtures,
      template,
      template: { name, type, layoutPosition: { size } = {}, rules } = {},
    } = this.props
    const isCloneableOnly = isTemplateCloneableOnly(template)
    const isDeleteAllFixtureRulesEnabled =
      (rules || []).filter((r) => !r.loading).length === (rules || []).length

    return (
      <React.Fragment>
        <SidebarTitle>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => onLinkedFixtures(template)}
          >
            Linked Fixtures
          </button>
          <button
            type="button"
            className="btn btn-primary btn-raised"
            onClick={onEditTemplate}
          >
            Edit Fixture
          </button>
        </SidebarTitle>
        <SidebarBody className="h-100 flex-column d-flex">
          <TopContainer flex="1.3">
            <SidebarSection>
              <Input
                name={'fixtureName'}
                label={'Name'}
                value={name}
                readOnly
              />
              <Input
                name={'fixtureType'}
                label={'Shape'}
                value={figureType[type] || type}
                readOnly
              />
              <Input
                name={'fixtureSize'}
                label={'Size'}
                value={figureSize[size] || 'No size'}
                readOnly
              />
              {/*<Input
                name={'fixtureRotation'}
                label={'Rotation'}
                value={angle || 'No rotation'}
                readOnly
              />*/}
              <CheckboxAndInputContainer>
                <CheckboxContainer>
                  <Checkbox
                    name={'cloneableCheck'}
                    checked={isCloneableOnly}
                    disabled
                    onChange={() => {}}
                  />
                </CheckboxContainer>
                <EditDisabled
                  text={
                    'Edits to this template will auto-update all linked fixtures'
                  }
                  grey
                />
              </CheckboxAndInputContainer>
            </SidebarSection>
          </TopContainer>
          <IssuesContainer>
            <Separator />
            <SidebarSection>
              <ButtonContainer>
                <button
                  type="button"
                  className="btn btn-primary "
                  onClick={onAddFixtureComplianceRule}
                >
                  Add Fixture Compliance Rule
                </button>
              </ButtonContainer>

              {(rules || []).map((rule) => (
                <ComplianceRule key={rule.id}>
                  {rule.loading ? (
                    <LoaderWrapper>
                      <PropagateLoader size={12} color={'#4E92DF'} />
                    </LoaderWrapper>
                  ) : (
                    <ComplianceBody>
                      <span>{rule.description}</span>
                      <button
                        type="button"
                        className={`btn btn-primary ${
                          rule.loading ? 'disabled' : ''
                        }`}
                        onClick={() => {
                          if (!rule.loading) {
                            onEditFixtureComplianceRule(rule)
                          }
                        }}
                      >
                        Edit
                      </button>
                    </ComplianceBody>
                  )}
                </ComplianceRule>
              ))}
              {(rules || []).length > 0 && (
                <ButtonContainer>
                  <button
                    type="button"
                    className="btn btn-primary "
                    disabled={!isDeleteAllFixtureRulesEnabled}
                    onClick={onDeleteAllFixtureComplianceRules}
                  >
                    Delete All Compliance Rules
                  </button>
                </ButtonContainer>
              )}
            </SidebarSection>
            <Separator />
            <SidebarSection className="pb-0">
              <ButtonContainer>
                <button
                  type="button"
                  className="btn btn-primary btn-raised"
                  onClick={onEditMode}
                >
                  Create New Position
                </button>
              </ButtonContainer>
            </SidebarSection>
            {isStatic &&
              (template.positions || []).length > 0 &&
              this.renderPositionsSidebar()}
          </IssuesContainer>
        </SidebarBody>
      </React.Fragment>
    )
  }

  renderPositionItem = (position) => {
    const { onEdit, onListItem } = this.props
    return (
      <SidebarSectionHighlight
        ref={(issue) => {
          this[`issue${position.id}`] = issue
        }}
        onMouseEnter={(id) => {
          if (this.canvas) this.canvas.onOver(id)
        }}
        onMouseLeave={(id) => {
          if (this.canvas) this.canvas.onOut(id)
        }}
        id={position.id}
        key={position.id}
        content={
          <div
            onClick={() => {
              if (this.canvas) this.canvas.onOut(position.id)
              onListItem(position)
            }}
            tabIndex="-1"
            role="menuitem"
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Input
                name={'positionName'}
                label={'Name'}
                value={position.name}
                readOnly
              />
              {
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => onEdit(position)}
                >
                  Edit
                </button>
              }
            </div>
            {this.renderComplianceRulesSection(position)}
          </div>
        }
      />
    )
  }

  renderPositionsSidebar = () => {
    const { template: { positions }, selectedPosition } = this.props
    const positionObject = this.getSelectedPositionObject()

    return (
      <div>
        {positionObject && this.renderPositionItem(selectedPosition)}
        {!positionObject &&
          positions.map((position) => this.renderPositionItem(position))}
      </div>
    )
  }

  renderAddEditPositionSidebar = () => {
    const {
      selectedId,
      selectedPosition,
      selectedPositionName,
      onCancel,
      onPositionSave,
      onPositionRemove,
      isStatic,
    } = this.props

    const position = this.getSelectedPositionObject()
    const isCreation = position ? isNaN(position.id) : false

    return (
      <div>
        <SidebarTitle>
          <BorderedButton
            type="button"
            className="btn btn-primary w-100 mr-2"
            onClick={onCancel}
          >
            Cancel
          </BorderedButton>
          {!isStatic && !isCreation && selectedPosition ? (
            <BorderedButton
              type="button"
              className="btn btn-primary w-100 mr-2"
              onClick={() => {
                onPositionRemove(selectedId)
                if (this.canvas) this.canvas.onDefault()
              }}
            >
              Delete
            </BorderedButton>
          ) : null}
          <button
            type="button"
            disabled={!position}
            className="btn btn-raised w-100"
            onClick={() => {
              onPositionSave(selectedId)
              if (this.canvas) this.canvas.onDefault()
            }}
          >
            Save
          </button>
        </SidebarTitle>
        <SidebarSection>
          <Input
            disabled={!position}
            name={'positionName'}
            label={'Position Name'}
            placeholder="Position Name"
            value={selectedPositionName}
            onChange={(e) => {
              this.onNameChanged(e.target.value)
            }}
          />

          {!isStatic &&
            selectedPosition &&
            this.renderComplianceRulesSection(selectedPosition)}
        </SidebarSection>
      </div>
    )
  }

  renderComplianceRulesSection = (position) => {
    const {
      onAddPositionComplianceRule,
      onEditPositionComplianceRule,
      onDeleteAllPositionComplianceRules,
      onAddPortComplianceRules,
    } = this.props
    const rules = position.rules.filter(({ sourceAttribute }) =>
      isSourceAttribute(sourceAttribute)
    )
    const isDeleteAllPositionRulesEnabled =
      (rules || []).filter((r) => !r.loading).length === (rules || []).length

    return (
      <div>
        {
          <ButtonContainer>
            <button
              type="button"
              className="btn btn-primary "
              onClick={() => onAddPositionComplianceRule(position)}
            >
              Add Compliance Rule
            </button>
          </ButtonContainer>
        }
        {rules.map((rule) => (
          <ComplianceRule key={rule.id}>
            {rule.loading ? (
              <LoaderWrapper>
                <PropagateLoader size={12} color={'#4E92DF'} />
              </LoaderWrapper>
            ) : (
              <ComplianceBody>
                <span>{rule.description}</span>
                <button
                  type="button"
                  className={`btn btn-primary ${
                    rule.loading ? 'disabled' : ''
                  }`}
                  onClick={() => {
                    if (!rule.loading) {
                      if (isPortRule(rule.sourceAttribute)) {
                        const deviceTypeRule = position.rules.find(
                          (rule) =>
                            rule.sourceAttribute ===
                            SOURCE_ATTRIBUTES.security_device_type
                        )
                        const deviceType = deviceTypeRule.parameter
                        if (MULTI_PORT_COMPLIANCE_DEVICE.includes(deviceType)) {
                          onAddPortComplianceRules(deviceTypeRule.parameter)
                        }
                      } else {
                        onEditPositionComplianceRule(position, rule)
                      }
                    }
                  }}
                >
                  Edit
                </button>
              </ComplianceBody>
            )}
          </ComplianceRule>
        ))}
        {rules.length > 0 ? (
          <ButtonContainer>
            <button
              type="button"
              className="btn btn-primary "
              disabled={!isDeleteAllPositionRulesEnabled}
              onClick={() => onDeleteAllPositionComplianceRules(position)}
            >
              Delete All Compliance Rules
            </button>
          </ButtonContainer>
        ) : null}
      </div>
    )
  }

  render() {
    const {
      canvasObject,
      selectedId,
      onChanged,
      canvasSize,
      isStatic,
      isSilent,
      onMessage,
      onPositionSave,
      selectedAutoplacementMax,
      positions,
      prototypes,
      onMaxPositions,
    } = this.props
    const hasPositions = positions && positions.length !== 0
    const hasCanvasPositions =
      canvasObject && canvasObject.objects && canvasObject.objects.length > 1
    const hasNoGeometry =
      hasCanvasPositions &&
      canvasObject.objects[1] &&
      !canvasObject.objects[1].left

    return (
      <article className={styles.storeContainer}>
        <PromptTable data={this.getPromptText()} />
        <div className={styles.canvasContainer}>
          <div className={styles.fixtureCanvasFrame}>
            {!isSilent &&
              !hasPositions &&
              isStatic && (
              <EmptyCanvasLabel>
                  This Fixture has no Position data
              </EmptyCanvasLabel>
            )}
            {!isSilent &&
              hasNoGeometry &&
              isStatic && (
              <EmptyCanvasLabel>
                  Probably this Fixture has been created with different
                  environment
              </EmptyCanvasLabel>
            )}
            <div style={{ backgroundColor: '#D8D8D8' }}>
              <FixtureCanvas
                ref={(canvas) => {
                  this.canvas = canvas
                }}
                size={canvasSize}
                canvasObject={canvasObject}
                selectedId={selectedId}
                prototypes={prototypes}
                mtiJsclientShared={mtiJsclientShared}
                maxPositions={selectedAutoplacementMax}
                isEditable={!isStatic}
                onChanged={onChanged}
                onMessage={onMessage}
                onMaxPositions={onMaxPositions}
                onMouseEnter={(id) => {
                  if (this[`issue${id}`]) this[`issue${id}`].onOver()
                }}
                onMouseLeave={(id) => {
                  if (this[`issue${id}`]) this[`issue${id}`].onOut()
                }}
                onCanvasPositionSave={(id) => onPositionSave(id)}
              />
            </div>
          </div>
        </div>
        <div className={styles.rightStoreColumn}>
          <div className="h-100 flex-column d-flex">
            {isStatic && this.renderTemplateViewSidebar()}
            {!isStatic &&
              (selectedId !== -1 && selectedId !== 0
                ? this.renderAddEditPositionSidebar()
                : this.renderEditSidebarForPositions())}
          </div>
        </div>
      </article>
    )
  }
}

TemplatePositionsScreen.propTypes = {
  template: PropTypes.object.isRequired,
  onEditTemplate: PropTypes.func.isRequired,
  onEditFixtureComplianceRule: PropTypes.func.isRequired,
  onEditPositionComplianceRule: PropTypes.func.isRequired,
  onAddFixtureComplianceRule: PropTypes.func.isRequired,
  onDeleteAllFixtureComplianceRules: PropTypes.func.isRequired,
  onLinkedFixtures: PropTypes.func.isRequired,
  onAddPositionComplianceRule: PropTypes.func.isRequired,
  onDeleteAllPositionComplianceRules: PropTypes.func.isRequired,
  onAddPortComplianceRules: PropTypes.func.isRequired,
  canvasSize: PropTypes.object.isRequired,
  canvasObject: PropTypes.object.isRequired,
  selectedId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  positions: PropTypes.array,
  prototypes: PropTypes.object,
  isStatic: PropTypes.bool,
  isSilent: PropTypes.bool,
  onChanged: PropTypes.func.isRequired,
  onNameChanged: PropTypes.func.isRequired,
  onMessage: PropTypes.func.isRequired,
  onEditMode: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onListItem: PropTypes.func.isRequired,
  selectedPosition: PropTypes.object,
  selectedPositionName: PropTypes.string,
  selectedPositionCount: PropTypes.number,
  selectedAutoplacementMax: PropTypes.number,
  onCancel: PropTypes.func.isRequired,
  onPositionRemove: PropTypes.func.isRequired,
  onPositionSave: PropTypes.func.isRequired,
  onMaxPositions: PropTypes.func.isRequired,
  isAutoplacement: PropTypes.bool.isRequired,
  onSaveAll: PropTypes.func.isRequired,
  onPositionCountChanged: PropTypes.func.isRequired,
}

export default TemplatePositionsScreen
