import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { createStructuredSelector } from 'reselect'
import { selectStoreById } from '../selectors'

const StoreNameBreadcrumb = ({ store, match: { key, params: { sName } } }) => {
  if (!store) return null

  return (
    <Link
      key={key}
      className="breadcrumb-item"
      to={'#'}
      onClick={(e) => e.preventDefault()}
    >
      {sName}
    </Link>
  )
}

StoreNameBreadcrumb.propTypes = {
  match: PropTypes.object,
  store: PropTypes.object,
}

const mapStateToProps = createStructuredSelector({
  store: (state, ownProps) =>
    selectStoreById({ sId: ownProps.match.params.sId })(state, ownProps),
})

export default connect(mapStateToProps)(StoreNameBreadcrumb)
