/*
 *
 * StoreManager constants
 *
 */

import keyMirror from 'fbjs/lib/keyMirror'

/**
 * @constant {Object} ActionTypes
 * @memberof Constants
 */
export const ActionTypes = keyMirror({
  SET_STORE: undefined,
  FETCH_STORES_FAILED: undefined,
  FETCH_STORES_FULFILLED: undefined,
  FETCH_STORES_PENDING: undefined,
  LOAD_STORES: undefined,
})
