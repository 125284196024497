export const namespace = 'layoutTableView'

/**
 * @constant {Object} ActionTypes
 * @memberof Constants
 */
export const ActionTypes = {
  FETCH_LAYOUT_TABLE_VIEW_DATA: 'FETCH_LAYOUT_TABLE_VIEW_DATA',
  LAYOUT_TABLE_DATA_REDUCER: 'LAYOUT_TABLE_DATA_REDUCER',
  LOADING_TABLE_VIEW: 'LOADING_TABLE_VIEW',
  AREAS_FILTER_REDUCER: 'AREAS_FILTER_REDUCER',
  FIXTURES_FILTER_REDUCER: 'FIXTURES_FILTER_REDUCER',
}
