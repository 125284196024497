// Edit
import circleEmpty from 'images/svg/positions/edit/empty-position.svg'
import circleSelected from 'images/svg/positions/edit/selected-position.svg'
import circleFilled from 'images/svg/positions/edit/filled-position.svg'
import securePlugFilled from 'images/svg/positions/edit/filled-secure-plug.svg'
import lockFilled from 'images/svg/positions/edit/filled-lock.svg'
import cxFlexFourPortFilled from 'images/svg/positions/edit/filled-cx-flex-four-port.svg'
// Undefined
import securePlugUndefined from 'images/svg/positions/Undefined-Secure-Plug.svg'
import cxFlexUndefined from 'images/svg/positions/Undefined-CX-Flex-four-port.svg'
import lockUndefined from 'images/svg/positions/Undefined-Lock.svg'
import circleUndefined from 'images/svg/positions/Undefined.svg'

import { getFabricObject } from './objectUtils'
import { editType } from './utilsEditTypes'

export function getEditObject(type, isToObj = true) {
  switch (type) {
    case editType.empty:
      return getFabricObject(circleEmpty, isToObj)
    case editType.unselected:
      return getFabricObject(circleUndefined, isToObj)
    case editType.unselectedSecurePlug:
      return getFabricObject(securePlugUndefined, isToObj)
    case editType.unselectedCXFlexFourPort:
      return getFabricObject(cxFlexUndefined, isToObj)
    case editType.unselectedLock:
      return getFabricObject(lockUndefined, isToObj)
    case editType.filled:
      return getFabricObject(circleFilled, isToObj)
    case editType.filledSecurePlug:
      return getFabricObject(securePlugFilled, isToObj)
    case editType.filledLock:
      return getFabricObject(lockFilled, isToObj)
    case editType.filledCXFlexFourPort:
      return getFabricObject(cxFlexFourPortFilled, isToObj)
    case editType.selected:
      return getFabricObject(circleSelected, isToObj)
    default:
      return getFabricObject(circleFilled, isToObj)
  }
}
