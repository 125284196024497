import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import injectReducer from '../../../utils/injectReducer'
import injectSaga from '../../../utils/injectSaga'
import reducer from './reducer'
import saga from './sagas'
import {
  makeSelectStatus,
  makeSelectLoading,
  makeSelectError,
} from './selectors'
import { updateNodes } from './actions'
import UpdateNodesStatusScreen from './index.screen'

class UpdateNodes extends React.Component {
  componentDidMount() {
    const { updateNodes: doUpdateNodes, data } = this.props
    doUpdateNodes(data)
  }

  render() {
    const { id, updateNodesStatus, updating, updateError, onClose } = this.props

    return (
      <UpdateNodesStatusScreen
        id={id}
        updateNodesStatus={updateNodesStatus}
        updating={updating}
        updateError={updateError}
        onClose={onClose}
      />
    )
  }
}

UpdateNodes.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  updateNodesStatus: PropTypes.any,
  updating: PropTypes.bool,
  updateError: PropTypes.object,
  updateNodes: PropTypes.func,
}

const mapStateToProps = createStructuredSelector({
  updateNodesStatus: makeSelectStatus(),
  updating: makeSelectLoading(),
  updateError: makeSelectError(),
})

const mapDispatchToProps = {
  updateNodes,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const withReducer = injectReducer({
  key: 'updateNodesStatus',
  reducer,
})

const withSaga = injectSaga({
  key: 'updateNodesStatus',
  saga,
})

export default compose(withReducer, withSaga, withConnect)(UpdateNodes)
