import { createSelector } from 'reselect'

const selectKeys = (state) => state.get('keys')

const makeSelectKeys = () =>
  createSelector(selectKeys, (keys) => keys.get('keys'))

const makeSelectFailed = () =>
  createSelector(selectKeys, (keys) => keys.get('failed'))

const makeSelectLoading = () =>
  createSelector(selectKeys, (keys) => keys.get('loading'))

const makeSelectNewKeys = () =>
  createSelector(selectKeys, (keys) => keys.get('newKeys').toArray())

const makeSelectFocusedKeyId = () =>
  createSelector(selectKeys, (keys) => keys.get('focusedKeyId'))

const selectUserEdit = (state) => state.get('userEdit')

const makeSelectUserEditFailed = () =>
  createSelector(selectUserEdit, (userEdit) => userEdit.get('failed'))

const makeSelectUserEditLoading = () =>
  createSelector(selectUserEdit, (userEdit) => userEdit.get('loading'))

export {
  makeSelectFailed,
  makeSelectLoading,
  makeSelectKeys,
  makeSelectNewKeys,
  makeSelectFocusedKeyId,
  makeSelectUserEditFailed,
  makeSelectUserEditLoading,
}
