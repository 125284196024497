import React from 'react'
import PropTypes from 'prop-types'
import InfoIcon from '../../images/svg/buttons/info.svg'
import ErrorIcon from '../../images/svg/buttons/error.svg'

class DoublePincodeInput extends React.Component {
  constructor(props) {
    super(props)
    // const { pincode } = props
    this.state = {
      confirmPincode: '',
      invalidMatch: false,
      // isValid: pincode && confirmPincode == pincode,
    }
  }

  onChange = (confirmPincode) => {
    const pincode = this.state.pincode
    if (pincode && confirmPincode.confirmPincode == pincode) {
      this.setState({ isValid: true, pincode, invalidMatch: false })
      this.props.onChange(pincode)
    } else {
      this.setState({ isValid: false, pincode, invalidMatch: true })
    }
  }

  setCustomValidity(msg) {
    const element = this.passwordInputContainer
    if (msg === '') {
      this.setServerErrorDismiss(element)
    } else {
      this.setServerError(element, msg)
    }
  }

  setServerErrorDismiss(element) {
    // eslint-disable-next-line
    $(element).keyup(() => {
      $(element).attr('data-content', '') // eslint-disable-line
      $(element).popover('hide') // eslint-disable-line
    })
  }

  setServerError(element, msg) {
    $(element).attr('data-placement', 'bottom') // eslint-disable-line
    $(element).attr('data-content', msg) // eslint-disable-line
    $(element).popover('show') // eslint-disable-line
    setTimeout(() => {
      $(element).attr('data-content', '') // eslint-disable-line
      $(element).popover('disable') // eslint-disable-line
    }, 3000)
  }

  isCustom() {
    return true
  }

  isValid() {
    return this.state.isValid
  }

  infoTooltip = (element, msg) => {
    $(element) // eslint-disable-line
      .popover('dispose')
      .popover({
        placement: 'bottom',
        trigger: 'hover focus',
        content: msg,
      })
  }

  render() {
    const textPincode =
      '- Pincode should be a 4 digits number\n - Pincode is used in mobile application'
    const wrongTextPincode = 'Pincodes do not match'

    return (
      <div>
        <div className="mb-3-mti">
          <label htmlFor="pincode">Create your pincode</label>
          <div style={{ position: 'relative' }} className="form-group p-0">
            <input
              ref={(ref) => {
                this.pincode = ref
                this.setServerErrorDismiss(ref)
              }}
              type="password"
              className="form-control"
              style={{ float: 'left' }}
              id="pincode"
              placeholder=""
              required="required"
              pattern="\d{4}"
              minLength="4"
              maxLength="4"
              autoComplete="new-pincode"
              onChange={(e) => this.setState({ pincode: e.target.value })}
            />
            {this.state.pincode == null && (
              <img
                ref={(ref) => this.infoTooltip(ref, textPincode)}
                style={{ position: 'absolute', right: '1%' }}
                src={InfoIcon}
                height="26"
                alt="Aspect ratio"
              />
            )}
            <div className="invalid-feedback">Should be 4 characters.</div>
          </div>
        </div>

        <div className="mb-3-mti">
          <label htmlFor="confirmPassword">Reenter your pincode</label>
          <div style={{ position: 'relative' }} className="form-group p-0">
            <input
              type="password"
              className="form-control"
              style={{ float: 'left' }}
              id="confirmPincode"
              placeholder=""
              required="required"
              pattern="\d{4}"
              minLength="4"
              maxLength="4"
              autoComplete="confirm-pincode"
              onChange={(e) =>
                this.onChange({ confirmPincode: e.target.value })
              }
            />
            {this.state.invalidMatch && (
              <img
                ref={(ref) => this.infoTooltip(ref, wrongTextPincode)}
                style={{ position: 'absolute', right: '1%' }}
                className="ml-2 mb-2"
                src={ErrorIcon}
                height="26"
                alt="Aspect ratio"
              />
            )}
          </div>
        </div>
      </div>
    )
  }
}

DoublePincodeInput.defaultProps = {
  onInvalid: () => {},
  onRequired: () => {},
  onChange: () => {},
  required: false,
}

DoublePincodeInput.propTypes = {
  pincode: PropTypes.string,
  confirmPincode: PropTypes.string,
  onInvalid: PropTypes.func,
  onRequired: PropTypes.func,
  onChange: PropTypes.func,
  invalidMsg: PropTypes.string,
  requiredMsg: PropTypes.string,
  required: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}

export default DoublePincodeInput
