import React from 'react'
import PropTypes from 'prop-types'
import styles from '../Input/css/style.css'
import { showHidablePopover } from '../../utils/utils'
import Suggestions from './suggestions'

class InputWithSuggestions extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      isSuggestionsVisible: false,
      data: props.data || [],
      value: props.value,
    }
  }

  suggestions = () => {
    const search = this.state.value.toLowerCase()
    const { data } = this.props

    return data
      .filter(
        ({ name }) =>
          search.length ? name.toLowerCase().indexOf(search) !== -1 : true
      )
      .map(({ id, name }) => ({ id, title: name }))
  }

  addFocusClass = () => {
    this.setState({ isSuggestionsVisible: true }, () => {
      this.formGroup.classList.add('is-focused')
    })
  }

  removeFocusClass = () => {
    setTimeout(() => {
      this.setState({ isSuggestionsVisible: false }, () => {
        if (this.isFilled(this.state.value)) return
        this.formGroup.classList.remove('is-focused')
      })
    }, 300)
  }

  isFilled = (value) => !(value === null || value === undefined || value === '')

  invalidInputHandler = (event) => {
    const input = event.target

    if (input.checkValidity()) {
      $(input).popover('hide') // eslint-disable-line
    } else {
      showHidablePopover(input, this.props.validationMessage, 'focus')
    }

    this.props.onChangeValidity(input.checkValidity())
  }

  patternProp = () => {
    const { pattern } = this.props
    if (pattern) {
      return { pattern }
    }

    return null
  }

  minMaxProps = () => {
    const { type, min, max } = this.props

    if (type === 'number') {
      return { min, max }
    }

    return null
  }

  onSelect = (item) => {
    this.setState({ isSuggestionsVisible: false, value: item.title }, () => {
      this.props.onChange({ selection: item })
    })
  }

  onChange = (event) => {
    const { target: { value } } = event
    this.setState({ value }, () => {
      this.props.onChange(value)
    })
  }

  render() {
    const {
      name,
      label,
      onClick,
      type,
      readOnly,
      disabled,
      autoComplete,
    } = this.props

    const { isSuggestionsVisible, value } = this.state

    return (
      <div
        className={`form-group bmd-form-group ${
          this.isFilled(value) ? 'is-focused' : null
        } ${styles.inputWrapper} ${readOnly ? styles.readonly : null} ${
          onClick ? styles.clickable : null
        }`}
        ref={(i) => {
          this.formGroup = i
        }}
      >
        <label htmlFor={name} className="bmd-label-floating">
          {label}
        </label>
        <input
          autoComplete={autoComplete}
          key={name}
          type={type}
          className="form-control"
          id={name}
          value={value}
          onClick={onClick}
          onChange={this.onChange}
          onFocus={this.addFocusClass}
          onBlur={this.removeFocusClass}
          disabled={disabled}
          readOnly={readOnly}
          {...this.patternProp()}
          {...this.minMaxProps()}
        />
        <Suggestions
          data={this.suggestions()}
          isExpanded={isSuggestionsVisible}
          onSelect={this.onSelect}
        />
      </div>
    )
  }
}

InputWithSuggestions.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
  min: PropTypes.string,
  max: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  pattern: PropTypes.string,
  validationMessage: PropTypes.string,
  onChangeValidity: PropTypes.func,
  data: PropTypes.array,
  autoComplete: PropTypes.string,
}

InputWithSuggestions.defaultProps = {
  type: 'text',
  min: '0',
  max: '100000',
  validationMessage: 'Please check text',
  onChangeValidity: () => {},
}

export default InputWithSuggestions
