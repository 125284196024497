import { defaultName } from '../../utils/mtiCanvasUtils'

export function fromFabricPosition(o, screen) {
  if (!o) return o
  const w = o.width * o.scaleX
  const h = o.height * o.scaleY
  const cX = o.left
  const cY = o.top

  const attrs = {
    id: o.id,
    height: h / screen.width,
    width: w / screen.width,
    theta: o.angle,
    xCenter: cX / screen.width,
    yCenter: cY / screen.width,
    name: o.name || defaultName.position,
    price: o.price || '0',
    contextType: 'position',
    // securedProduct: o.securedProduct, // TODO
    // securityDevice: o.securityDevice, // TODO
  }
  return attrs
}
