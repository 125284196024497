/**
 *
 * Breadcrumbs
 * based on https://github.com/icd2k3/react-router-breadcrumbs-hoc
 */

import _ from 'lodash'
import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import withBreadcrumbs from 'react-router-breadcrumbs-hoc'
import styled from 'styled-components'
import {
  FloorNameBreadcrumb,
  AreaNameBreadcrumb,
  FixtureNameBreadcrumb,
  StoreNameBreadcrumb,
  ViewStoreDetails,
  AddFloorsToStore,
  AddAreasToStore,
  TemplateNameBreadcrumb,
  OrganizationNameBreadcrumb,
  // StoreModeButton,
} from './crumbs'
import { openModal, closeModal } from '../../containers/App/actions'
import ConfirmModal from '../../containers/ModalContainer/ConfirmModal'
import { selectStoreById } from './selectors'
import {
  makeSelectCanvasSelectedId as makeSelectPositionId,
  makeSelectIsStatic as makeSelectIsStaticFixture,
} from '../../containers/FixturePage/selectors'
import {
  canvasSelectedIdSelector as fixtureIdSelector,
  isStaticSelector as isStaticAreaSelector,
} from '../../containers/AreaPage/selectors'
import {
  makeSelectCanvasSelectedId as makeSelectAreaId,
  makeSelectIsStatic as makeSelectIsStaticFloor,
} from '../../containers/FloorPage/selectors'
import { makeSelectIsStatic as makeSelectIsStaticStore } from '../../containers/FloorsPage/selectors'
import { createSmartStoreLayoutLink, orgUrlCrumbs } from '../../utils/mtiUtils'
import DrawerButton from '../Drawer/Button'

// Exclude paths from breadcrumbs
function getRouteMap(isMtiAdmin) {
  return [
    { path: '/organizations', breadcrumb: null },
    { path: '/organizations/:oId', breadcrumb: null },
    {
      path: '/organizations/:oId/:oName',
      breadcrumb: OrganizationNameBreadcrumb,
    },
    { path: `${orgUrlCrumbs(isMtiAdmin)}/users/:id`, breadcrumb: null },
    { path: `${orgUrlCrumbs(isMtiAdmin)}/stores/:id`, breadcrumb: null },
    {
      path: `${orgUrlCrumbs(isMtiAdmin)}/stores/:sId/:sName`,
      breadcrumb: StoreNameBreadcrumb,
    },
    {
      path: `${orgUrlCrumbs(isMtiAdmin)}/stores/:sId/:sName/floors`,
      breadcrumb: null,
    },
    {
      path: `${orgUrlCrumbs(isMtiAdmin)}/stores/:sId/:sName/floors/:fId`,
      breadcrumb: null,
    },
    {
      path: `${orgUrlCrumbs(isMtiAdmin)}/stores/:sId/:sName/floors/:fId/:fName`,
      breadcrumb: FloorNameBreadcrumb,
    },
    {
      path: `${orgUrlCrumbs(
        isMtiAdmin
      )}/stores/:sId/:sName/floors/:fId/:fName/areas`,
      breadcrumb: null,
    },
    {
      path: `${orgUrlCrumbs(
        isMtiAdmin
      )}/stores/:sId/:sName/floors/:fId/:fName/areas/:aId`,
      breadcrumb: null,
    },
    {
      path: `${orgUrlCrumbs(
        isMtiAdmin
      )}/stores/:sId/:sName/floors/:fId/:fName/areas/:aId/:aName`,
      breadcrumb: AreaNameBreadcrumb,
    },
    {
      path: `${orgUrlCrumbs(
        isMtiAdmin
      )}/stores/:sId/:sName/floors/:fId/:fName/areas/:aId/:aName/fixtures`,
      breadcrumb: null,
    },
    {
      path: `${orgUrlCrumbs(
        isMtiAdmin
      )}/stores/:sId/:sName/floors/:fId/:fName/areas/:aId/:aName/fixtures/:fxId`,
      breadcrumb: null,
    },
    {
      path: `${orgUrlCrumbs(
        isMtiAdmin
      )}/stores/:sId/:sName/floors/:fId/:fName/areas/:aId/:aName/fixtures/:fxId/:fxName`,
      breadcrumb: FixtureNameBreadcrumb,
    },
    {
      path: `${orgUrlCrumbs(isMtiAdmin)}/templates/:tId/`,
      breadcrumb: null,
    },
    {
      path: `${orgUrlCrumbs(isMtiAdmin)}/templates/:tId/:tName`,
      breadcrumb: TemplateNameBreadcrumb,
    },
  ]
}

const BreadcrumbList = styled.div`
  margin-bottom: 0;
  border-bottom: 1px solid #d9d9d9;
  background-color: #212e42;
  flex: 1;
`

const DrawerIconContainer = styled(BreadcrumbList)`
  flex: none;
`

const Crumbs = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: auto;
`

export function navigatingConfirm(url, props) {
  const { openModal: doOpenModal, closeModal: doCloseModal } = props
  const text = (
    <React.Fragment>
      <p>Note: Recent edits have not yet been saved!</p>
      <p className="m-0">
        Do you wish to “proceed and discard recent edits” or do you want to
        “stay on this page”?
      </p>
    </React.Fragment>
  )
  doOpenModal({
    id: 'navigatingConfirm',
    type: 'bootstrap',
    appearance: 'modal-md',
    content: (
      <ConfirmModal
        questionText={text}
        id={'navigatingConfirm'}
        cancelText={'Stay on this page'}
        confirmText={'Proceed & Discard edits'}
        onClose={() => doCloseModal({ id: 'navigatingConfirm' })}
        onConfirm={() => {
          props.history.push(url)
        }}
      />
    ),
  })
}

function getIsCanvasEdit(props) {
  const {
    history,
    isStaticFixture,
    isStaticArea,
    isStaticFloor,
    isStaticStore,
    selectedAreaId,
    selectedFixtureId,
    selectedPositionId,
  } = props
  const pathParams = history.location.pathname.split('/') || []
  const isMtiAdmin = pathParams[1] === 'organizations'

  const isStore = (isMtiAdmin ? pathParams[4] : pathParams[1]) === 'stores'
  const isFloor = (isMtiAdmin ? pathParams[7] : pathParams[4]) === 'floors'
  const isArea = (isMtiAdmin ? pathParams[10] : pathParams[7]) === 'areas'
  const isFixture =
    (isMtiAdmin ? pathParams[13] : pathParams[10]) === 'fixtures'
  if (isFixture) {
    if (!isStaticFixture && (selectedPositionId && selectedPositionId !== -1))
      return true
    return false
  }
  if (isArea) {
    if (!isStaticArea && (selectedFixtureId && selectedFixtureId !== -1))
      return true
    return false
  }
  if (isFloor) {
    if (!isStaticFloor && (selectedAreaId && selectedAreaId !== -1)) return true
    return false
  }
  if (isStore) {
    if (!isStaticStore) return true
    return false
  }
  return false
}

const Breadcrumbs = props => {
  const { breadcrumbs, history, store } = props
  // TODO: not the best way to collect parameters, pls improve if you can
  const params = breadcrumbs
    .map(b => b.props.match.params)
    .reduce((a, b) => Object.assign({}, a, b), {})

  if (
    history.location.pathname === '/login' ||
    history.location.pathname === '/admin/login' ||
    history.location.pathname === '/forgotPassword' ||
    history.location.pathname === '/passwordSet'
  )
    return null

  const isCanvasEdit = getIsCanvasEdit(props)
  return (
    <nav aria-label="breadcrumb" style={{ height: '49px' }}>
      <div className="d-flex flex-row">
        <DrawerIconContainer className="d-flex">
          <DrawerButton />
        </DrawerIconContainer>
        <BreadcrumbList className="breadcrumb">
          {breadcrumbs &&
            breadcrumbs.map(breadcrumb => {
              const url =
                breadcrumb.key === `${orgUrlCrumbs()}/stores/:sId/:sName`
                  ? createSmartStoreLayoutLink(store).path
                  : breadcrumb.props.match.url
              return _.isFunction(breadcrumb.type) ? (
                <span
                  key={breadcrumb.key}
                  role="link"
                  tabIndex="0"
                  className="breadcrumb-item"
                  onClick={() => {
                    if (isCanvasEdit) {
                      navigatingConfirm(url, props)
                    } else {
                      history.push(url)
                    }
                  }}
                >
                  {breadcrumb}
                </span>
              ) : (
                <Link
                  key={breadcrumb.key}
                  className="breadcrumb-item"
                  to={'#'}
                  onClick={e => {
                    if (isCanvasEdit) {
                      e.preventDefault()
                      navigatingConfirm(url, props)
                    } else {
                      history.push(url)
                    }
                  }}
                  style={{ textTransform: 'capitalize' }}
                >
                  {breadcrumb}
                </Link>
              )
            })}
          <Crumbs>
            <AddFloorsToStore params={params} />
            <AddAreasToStore params={params} />
            <ViewStoreDetails params={params} history={history} />
          </Crumbs>
        </BreadcrumbList>
      </div>
    </nav>
  )
}

Breadcrumbs.propTypes = {
  history: PropTypes.any,
  breadcrumbs: PropTypes.array,
  isStaticFixture: PropTypes.bool,
  isStaticArea: PropTypes.bool,
  isStaticFloor: PropTypes.bool,
  isStaticStore: PropTypes.bool,
  selectedAreaId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  selectedFixtureId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  selectedPositionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
}

const mapStateToProps = createStructuredSelector({
  isStaticFixture: makeSelectIsStaticFixture(),
  isStaticArea: isStaticAreaSelector,
  isStaticFloor: makeSelectIsStaticFloor(),
  isStaticStore: makeSelectIsStaticStore(),
  selectedAreaId: makeSelectAreaId(),
  selectedFixtureId: fixtureIdSelector,
  selectedPositionId: makeSelectPositionId(),
  store: (state, ownProps) => {
    const pathParams = ownProps.history.location.pathname.split('/') || []
    const isMtiAdmin = pathParams[1] === 'organizations'
    return selectStoreById({
      sId: (
        ownProps.breadcrumbs[isMtiAdmin ? 4 : 2] || {
          props: { match: { params: {} } },
        }
      ).props.match.params.sId,
    })(state, ownProps)
  },
})

const mapDispatchToProps = {
  openModal,
  closeModal,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(
  withBreadcrumbs(getRouteMap().concat(getRouteMap(true))),
  withConnect
)(Breadcrumbs)
