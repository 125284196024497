import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import { ActionTypes } from '../App/constants'
import {
  makeSelectSelectedSecuredProduct,
  makeSelectSecuredProductModalTypeToShow,
} from '../App/selectors'
import {
  openModal,
  closeModal,
  resetSecuredProductManagement,
  editSecuredProduct,
  patchSecuredProduct,
} from '../App/actions'
import SecuredProductDetails from './modals/SecuredProductDetails'
import EditSecuredProductDetails from './modals/EditSecuredProductDetails'
import {
  makeSelectManufacturers,
  makeSelectAllProducts,
} from '../FixturePage/selectors'

class SecuredProductManagement extends React.Component {
  componentDidUpdate() {
    const {
      securedProduct,
      securedProductModalTypeToShow,
      resetSecuredProductManagement: doResetSecuredProductManagement,
    } = this.props
    if (securedProductModalTypeToShow === ActionTypes.SECURED_PRODUCT_DETAILS) {
      this.openSecuredProductDetailsModal(securedProduct)
      doResetSecuredProductManagement()
      return
    }
  }

  openSecuredProductDetailsModal = (securedProduct) => {
    const { openModal: doOpenModal, closeModal: doCloseModal } = this.props
    const id = 'secured-product-details'

    doOpenModal({
      id,
      type: 'bootstrap',
      content: (
        <SecuredProductDetails
          id={id}
          securedProduct={securedProduct}
          title={'Secured Product Details'}
          confirmText={'Edit Product'}
          onConfirm={() => {
            doCloseModal({ id })
            setTimeout(
              () => this.openEditSecuredProductModal(securedProduct),
              500
            )
          }}
          onClose={() => {
            doCloseModal({ id })
          }}
        />
      ),
    })
  }

  openEditSecuredProductModal = (securedProduct) => {
    const {
      openModal: doOpenModal,
      closeModal: doCloseModal,
      editSecuredProduct: doEditSecuredProduct,
      patchSecuredProduct: doPatchSecuredProduct,
      manufacturers,
      products,
    } = this.props
    const id = 'edit-secured-product'

    doOpenModal({
      id,
      type: 'bootstrap',
      content: (
        <EditSecuredProductDetails
          ref={(ref) => (this.editSecuredProductModal = ref)}
          id={id}
          securedProduct={securedProduct}
          onChange={(changedData) => doEditSecuredProduct(changedData)}
          title={'Edit Product'}
          confirmText={'Save Product'}
          onClose={() => doCloseModal({ id })}
          onConfirm={() => doPatchSecuredProduct(securedProduct.id)}
          manufacturers={manufacturers}
          products={products}
        />
      ),
    })
  }

  render() {
    return <div />
  }
}

SecuredProductManagement.propTypes = {
  securedProduct: PropTypes.object,
  securedProductModalTypeToShow: PropTypes.string,
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
  resetSecuredProductManagement: PropTypes.func,
  editSecuredProduct: PropTypes.func,
  patchSecuredProduct: PropTypes.func,
  manufacturers: PropTypes.array,
  products: PropTypes.array,
}

const mapStateToProps = createStructuredSelector({
  securedProduct: makeSelectSelectedSecuredProduct(),
  securedProductModalTypeToShow: makeSelectSecuredProductModalTypeToShow(),
  manufacturers: makeSelectManufacturers(),
  products: makeSelectAllProducts(),
})

const mapDispatchToProps = {
  openModal,
  closeModal,
  resetSecuredProductManagement,
  editSecuredProduct,
  patchSecuredProduct,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)
export default compose(withConnect)(SecuredProductManagement)
