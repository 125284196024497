import React from 'react'
import moment from 'moment-timezone'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import TimezonePicker from './index.screen'
import acceptableTimeZones from './acceptableTimeZones'

const Container = styled.div`
  width: 100%;
`

class TimezonePickerContainer extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      timeZones: [],
      value: props.value,
      validation: '',
    }
  }

  isCustom = true
  // popoverElement = () => $(this.container) // eslint-disable-line

  componentWillMount() {
    const timeZones = acceptableTimeZones
      .split(' ')
      .map((name) => {
        const timezone = moment().tz(name)
        const Z = timezone.format('Z')
        const offset = parseInt(Z.split(':').join(''), 10)
        const z = timezone.format('z')
        const abbr = !/^[+-]/.test(z) ? z : ''

        return { name, abbr, offset, Z }
      })
      .sort((a, b) => a.offset - b.offset)
      .map((e) => ({ value: e.name, label: `${e.name} ${e.abbr} ${e.Z}` }))

    this.setState({ timeZones })
  }

  setCustomValidity(msg) {
    if (msg === '') {
      // Dismiss or keep as is
    } else {
      this.setState({ validation: msg })
    }
  }

  getValue() {
    const { value: timeZoneName, timeZones } = this.state
    const timeZone = timeZones.find((t) => t.value === timeZoneName)
    if (!timeZone) {
      console.warn(
        `TimezonePicker: probably your timezone ${timeZoneName} was not included to the list acceptableTimeZones.js`
      )
    }
    return timeZone
  }

  handleChange = (value) => {
    this.setState({ value: value.value, validation: '' })
    this.props.onChange(value)
  }

  render() {
    const { className } = this.props
    const { timeZones, validation } = this.state

    return (
      <Container ref={(ref) => (this.container = ref)}>
        <TimezonePicker
          value={this.getValue()}
          options={timeZones}
          validation={validation}
          handleChange={this.handleChange}
          className={className}
        />
      </Container>
    )
  }
}

TimezonePickerContainer.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
}

export default TimezonePickerContainer
