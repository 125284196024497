import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import injectReducer from '../../utils/injectReducer'
import injectSaga from '../../utils/injectSaga'
import { deepEqual } from '../../utils/utils'
import { namespace } from './constants'
import saga from './sagas'
import reducer from './reducer'
import { fetchOrphanedDevicesData } from './actions'
import OrphanedDevicesScreen from './index.screen'
import {
  selectIsOrphanedDevicesLoading,
  selectOrphanedDevicesData,
} from './selectors'

const defaultPageSize = 25
export class OrphanedDevicesPage extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = { filters: { } }
  }

  componentDidMount() {
    const {
      storeID,
      fetchOrphanedDevicesData,
    } = this.props
    fetchOrphanedDevicesData({ storeId: storeID, per: defaultPageSize })
  }

  // This is what will be called whenever the user clicks to go to the
  // next page (which will be a type of filter on the filters object), or
  // if they change the filters in some way, causing the page to refresh.
  _loadPage(filters) {
    const {
      storeID,
      fetchOrphanedDevicesData,
    } = this.props

    if (!deepEqual(this.state.filters, filters)) {
      this.setState({ filters })
      fetchOrphanedDevicesData({ ...filters, storeId: storeID })
    }
  }

  render() {
    const {
      isOrphanedDevicesLoading, // This is a boolean representing whether we're currently fetching data
      apiResponse, // The latest API response
    } = this.props

    return (
      <OrphanedDevicesScreen
        loading={isOrphanedDevicesLoading}
        onFilter={filters => this._loadPage(filters)}
        tableViewData={apiResponse}
        defaultPageSize={defaultPageSize}
      />
    )
  }
}

OrphanedDevicesPage.propTypes = {
  storeID: PropTypes.number.isRequired,
}

const mapStateToProps = (state, ownProps) => ({
  storeID: Number(ownProps.match.params.sId),
  isOrphanedDevicesLoading: selectIsOrphanedDevicesLoading(state),
  apiResponse: selectOrphanedDevicesData(state),
})

const mapDispatchToProps = {
  fetchOrphanedDevicesData,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)
const withReducer = injectReducer({ key: namespace, reducer })
const withSaga = injectSaga({ key: namespace, saga })

export default compose(withReducer, withSaga, withConnect)(OrphanedDevicesPage)
