// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2S-XS8ev7A8Ui5oxLA3M_0 {\n  width: 100%;\n}\n\n._1xaDxhXiFpBhUA5qhyrVCP label {\n  color: rgba(0,0,0,.50) !important;\n}\n\n._1xaDxhXiFpBhUA5qhyrVCP input {\n  background-color: white !important;\n}\n\n._2QdtmXmQXabhJAqRi_2fRb input {\n  cursor: pointer !important;\n}\n", "",{"version":3,"sources":["webpack://app/components/Input/css/style.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,iCAAiC;AACnC;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[".inputWrapper {\n  width: 100%;\n}\n\n.readonly label {\n  color: rgba(0,0,0,.50) !important;\n}\n\n.readonly input {\n  background-color: white !important;\n}\n\n.clickable input {\n  cursor: pointer !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputWrapper": "_2S-XS8ev7A8Ui5oxLA3M_0",
	"readonly": "_1xaDxhXiFpBhUA5qhyrVCP",
	"clickable": "_2QdtmXmQXabhJAqRi_2fRb"
};
export default ___CSS_LOADER_EXPORT___;
