import React from 'react'
import PropTypes from 'prop-types'

class TooltipCell extends React.PureComponent {
  $element = null

  componentWillUnmount() {
    if (this.$element && this.$element.popover) {
      this.$element.popover('dispose')
      this.$element = null
    }
  }

  tooltip = ($element, msg) => {
    $element.popover('dispose').popover({
      placement: 'bottom',
      trigger: 'hover focus',
      content: msg || '',
    })
  }

  target = (element) => {
    this.$element = $(element) // eslint-disable-line
    this.tooltip(this.$element, this.props.text)
  }

  render() {
    if (!this.props.text) {
      return null
    }
    return this.props.render(this)
  }

  text = () => this.props.text
}

TooltipCell.propTypes = {
  render: PropTypes.func.isRequired,
  text: PropTypes.string,
}

export default TooltipCell
