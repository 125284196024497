/**
 * Reports Sagas
 */
import { all, call, put, takeLatest, select } from 'redux-saga/effects'
import { getReports } from '../../api'
import { makeSelectToken } from '../../containers/App/selectors'
import {
  makeSelectSelectedStores,
  makeSelectCombination,
  makeSelectDate,
} from '../ExceptionsPage/CreateFiltersModal/selectors'
import { makeSelectAsyncLoadingCanceling } from './selectors'
import { ActionTypes } from './constants'
import {
  fetchReportsFulfilled,
  fetchReportsFailed,
  clearReports,
  asyncLoadingStoresToLoad,
  asyncLoadingCurrentStore,
  asyncLoadingFinished,
} from './actions'
import { mergeReports, getDateFormatted } from './utils'
import { createReports } from './reports'
import { errorToast } from '../../utils/utils'
import { makeSelectStores } from '../StoresPage/selectors'

export function* loadReportsAsync() {
  let stores = yield select(makeSelectSelectedStores())
  // Handle initialization case
  if (!stores) {
    stores = yield select(makeSelectStores())
  }
  yield put(clearReports())
  const storesCount = (stores || []).length
  yield put(asyncLoadingStoresToLoad(storesCount))
  for (let i = 0; i < storesCount; i += 1) {
    const store = stores[i]
    const isCanceling = yield select(makeSelectAsyncLoadingCanceling())
    if (isCanceling) {
      yield put(asyncLoadingFinished())
      break
    }
    yield put(asyncLoadingCurrentStore(store, i))
    try {
      yield call(loadReports, {
        payload: { storeId: (store || {}).id },
      })
    } catch (error) {
      errorToast('Load reports failed')
      try {
        const res = error.response
        const errorObj = res ? yield call([res, res.json]) : error
        console.log(errorObj)
        yield put(
          fetchReportsFailed((store || {}).id, (store || {}).name, errorObj)
        )
      } catch (e) {
        console.log(e)
        yield put(
          fetchReportsFailed((store || {}).id, (store || {}).name, error)
        )
      }
    }
  }
  yield put(asyncLoadingFinished())
}

export function* loadReports({ payload }) {
  const { storeId } = payload
  const token = yield select(makeSelectToken())
  const combination = yield select(makeSelectCombination())
  const {
    health: cHealth,
    operations: cOperations,
    compliance: cCompliance,
    marketing: cMarketing,
  } = combination
  const date = yield select(makeSelectDate()) || {}
  const { startDate, endDate } = getDateFormatted(date) || {}
  const reportsTypes = [
    cHealth && 'health',
    cOperations && 'operations',
    cCompliance && 'compliance',
    cMarketing && 'marketing',
  ].filter(e => e)

  let data = yield call(
    getReports,
    token,
    storeId,
    startDate,
    endDate,
    reportsTypes,
  )

  // TODO: implement a long term solution that will likely involve redesigning the reports on both
  // the front end as well as the API.
  // For now, this is a workaround for large data sets with well over 100 results per store. It will
  // continue fetching pages until all the data is fetched.
  if (reportsTypes.length == 1) {
    let type = reportsTypes[0]

    // just a null check for the api results
    // this shouldn't be necessary, but just in case
    let report = data[type] || {
      results: [],
      meta: {
        total: 0,
        per_page: 100, // this is the default
      },
    }

    let totalResults = []
    totalResults = totalResults.concat(report.results)

    // Keep fetching pages until we've reached the end.
    let currentPage = 1
    let pageCount = Math.ceil(report.meta.total / report.meta.per_page)
    while (currentPage < pageCount) {
      data = yield call(
        getReports,
        token,
        storeId,
        startDate,
        endDate,
        reportsTypes,
        ++currentPage
      )

      let results = (data[type] || {}).results || []
      totalResults = totalResults.concat(results)
    }

    data[type] = data[type] || {}
    data[type].results = totalResults
  }

  const { operations = {}, health = {}, compliance = {}, marketing = {} } = data

  const reports = mergeReports(
    combination,
    health,
    operations,
    compliance,
    marketing
  )
  yield put(fetchReportsFulfilled(storeId, createReports(reports)))
}

export default function* root() {
  yield all([
    yield takeLatest(ActionTypes.LOAD_REPORTS_ASYNC, loadReportsAsync),
  ])
}
