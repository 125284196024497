/*
 *
 * Organization reducer
 *
 */

import { Map } from 'immutable'
import { ActionTypes } from './constants'

const initialState = new Map({
  organization: null,
  isLoading: false,
  error: null,
})

function organizationReducer(state = initialState, { type, payload }) {
  switch (type) {
    case ActionTypes.LOAD_ORGANIZATION_REQUEST:
      return state.set('isLoading', true)
    case ActionTypes.LOAD_ORGANIZATION_SUCCESS:
      return state
        .set('organization', payload)
        .set('isLoading', false)
        .set('error', null)
    case ActionTypes.LOAD_ORGANIZATION_FAILURE:
      return state
        .set('organization', null)
        .set('isLoading', false)
        .set('error', payload)
    case ActionTypes.PATCH_ORGANIZATION_FULFILLED:
      return state.set('organization', payload)
    default:
      return state
  }
}

export default organizationReducer
