import { all, takeLatest, call, put, select } from 'redux-saga/effects'
import { getMinions as getMinionsRequest } from '../../api'
import { ActionTypes } from './constants'
import {
  fetchMinionsFailed,
  fetchMinionsPending,
  fetchMinionsFulfilled,
} from './actions'
import { makeSelectSaltToken } from '../App/selectors'

function* getMinions() {
  const saltToken = yield select(makeSelectSaltToken())
  try {
    yield put(fetchMinionsPending())
    const { return: [minions] } = yield call(getMinionsRequest, saltToken)

    const minionsArrayy = Object.values(minions)
    yield put(fetchMinionsFulfilled(minionsArrayy))
  } catch (error) {
    yield put(fetchMinionsFailed(error))
  }
}

export default function* root() {
  yield all([yield takeLatest(ActionTypes.FETCH_MINIONS, getMinions)])
}
