import React from 'react'
import { Alert } from '../../components/Alert'

const AlertContainer = ({ alerts, style }) => {
  style = style || {
    position: 'absolute',
    top: '0px',
    right: '2px',
    width: '400px',
    zIndex: 3,
  }

  const alertsList =
    alerts &&
    alerts.map((alert) => {
      const { props } = alert
      return <Alert key={`alert-${alert.key}`} content={alert} alert={props} />
    })
  return <div style={style}>{alertsList}</div>
}

export default AlertContainer
