/*
 * StoreManager reducer
 */
import { Map } from 'immutable'
import { ActionTypes } from './constants'
import { ActionTypes as AppAT } from '../../App/constants'

export const initialState = new Map({
  failed: null,
  loading: false,
  patchFailed: null,
  patchLoading: false,
})

function reducer(state = initialState, { payload, type }) {
  switch (type) {
    case ActionTypes.SET_STORE:
      return state.set('store', payload)
    case ActionTypes.FETCH_STORES_FULFILLED:
      return state.set('loading', false).set('failed', null)
    case ActionTypes.FETCH_STORES_PENDING:
      return state.set('failed', null).set('loading', true)
    case ActionTypes.FETCH_STORES_FAILED:
      return state.set('failed', payload).set('loading', false)
    case AppAT.PATCH_STORE_PENDING:
      return state.set('patchFailed', null).set('patchLoading', true)
    case AppAT.PATCH_STORE_FULFILLED:
      return state.set('patchFailed', null).set('patchLoading', false)
    case AppAT.PATCH_STORE_FAILED:
      return state.set('patchFailed', payload).set('patchLoading', false)
    case ActionTypes.LOAD_STORES:
    default:
      return state
  }
}

export default reducer
