import React from 'react'
import PropTypes from 'prop-types'
import ReactTable from 'react-table'
import styled from 'styled-components'
import Chance from 'chance'
import checkboxHOC from 'react-table/lib/hoc/selectTable'
import { fullTextFilter } from '../../../../utils/utils'
import {
  CellContainer,
  renderFilterInput,
} from '../../../UsersPage/index.screen'
import TrComponent from '../../../../components/TrComponent'

const CheckboxTable = checkboxHOC(ReactTable)
const StyledTable = styled(CheckboxTable)`
  max-height: 300px;
`

const chance = new Chance()

class FiltersTableScreen extends React.PureComponent {
  constructor(props) {
    super(props)
    const columns = this.getColumns()
    const data = this.getData(this.props.items)
    const selectedIds = props.selectedIds
    const selection = data
      .filter(({ id }) => selectedIds.includes(id))
      .map(({ _id }) => _id)
    this.state = {
      data,
      columns,
      selection,
      selectAll: data.length === selection.length,
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const isSelectedIdsChanged =
      JSON.stringify(prevProps.selectedIds) !==
      JSON.stringify(this.props.selectedIds)
    const isSelectedItemsChanged =
      JSON.stringify(prevProps.items) !== JSON.stringify(this.props.items)
    if (isSelectedIdsChanged || isSelectedItemsChanged) {
      const columns = this.getColumns()
      const data = this.getData(this.props.items)
      const itemIds = this.props.selectedIds
      const selection = data
        .filter(({ id }) => itemIds.includes(id))
        .map(({ _id }) => _id)

      /* eslint-disable react/no-did-update-set-state */
      this.setState({
        data,
        columns,
        selection,
        selectAll: data.length === selection.length,
      })
    }

    const { selection, data } = this.state
    if (prevState.selection !== selection) {
      const itemIds = data
        .filter(({ _id }) => selection.includes(_id))
        .map((s) => s.id)
      if (
        JSON.stringify([...itemIds].sort()) !==
        JSON.stringify([...this.props.selectedIds].sort())
      ) {
        this.props.onChange(itemIds)
      }
    }
  }

  getData(keys) {
    return (keys || []).map((item) => {
      const _id = chance.guid() // eslint-disable-line
      return {
        _id,
        ...item,
      }
    })
  }

  getColumns = () => {
    const columns = [
      {
        sortable: true,
        filterable: true,
        accessor: 'name',
        Cell: ({ original: { name } }) => <CellContainer>{name}</CellContainer>,
        Filter: ({ filter, onChange }) =>
          renderFilterInput({ placeholder: 'Filter', filter, onChange }),
      },
    ]

    return columns
  }

  getSelecteditems = () => {
    const { data, selection } = this.state
    return data.filter((key) => !!selection.find((id) => id === key._id)) // eslint-disable-line
  }

  toggleAll = () => {
    /*
    Selection of visible (filtered) rows.
    */
    const selectAll = !this.state.selectAll
    const selection = []
    if (selectAll) {
      const wrappedInstance = this.checkboxTable.getWrappedInstance()
      const currentRecords = wrappedInstance.getResolvedState().sortedData
      currentRecords.forEach((item) => {
        selection.push(item._original._id) // eslint-disable-line
      })
    }
    this.setState({ selectAll, selection })
  }

  isSelected = (key) => this.state.selection.includes(key)

  toggleSelection = (key) => {
    let selection = [...this.state.selection]
    const keyIndex = selection.indexOf(key)
    if (keyIndex >= 0) {
      selection = [
        ...selection.slice(0, keyIndex),
        ...selection.slice(keyIndex + 1),
      ]
    } else {
      selection.push(key)
    }
    this.setState({ selection })
  }

  render() {
    const { toggleSelection, toggleAll, isSelected } = this
    const { selectAll, data, columns } = this.state

    const checkboxProps = {
      selectAll,
      isSelected,
      toggleSelection,
      toggleAll,
      selectType: 'checkbox',
      getTrProps: (s, r) => {
        // background color change
        const selected = this.isSelected(((r || {}).original || {})._id) // eslint-disable-line
        return {
          style: {
            backgroundColor: selected ? 'lightgreen' : 'inherit',
          },
        }
      },
    }

    return (
      <StyledTable
        ref={(r) => {
          this.checkboxTable = r
        }}
        data={data}
        columns={columns}
        className="-highlight"
        defaultFilterMethod={fullTextFilter}
        defaultPageSize={10}
        TrComponent={TrComponent}
        nextText={'>'}
        previousText={'<'}
        rowsText={''}
        showPageSizeOptions={false}
        {...checkboxProps}
      />
    )
  }
}

export default FiltersTableScreen

FiltersTableScreen.propTypes = {
  items: PropTypes.array.isRequired,
  selectedIds: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
}
