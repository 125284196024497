/**
 *
 * ProgressOverlay
 *
 */

import React from 'react'
import { Overlay } from '../../styles/styled'
import ProgressIndicator from '../ProgressIndicator'

class ProgressOverlay extends React.PureComponent {
  setLoading(loading) {
    if (loading && this.progressOverlay) {
      this.progressOverlay.style.display = 'initial'
    } else if (!loading && this.progressOverlay) {
      this.progressOverlay.style.display = 'none'
    }
  }

  render() {
    return (
      <div
        ref={(ref) => (this.progressOverlay = ref)}
        style={{ display: 'none' }}
      >
        <Overlay />
        <ProgressIndicator />
      </div>
    )
  }
}

export default ProgressOverlay
