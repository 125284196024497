import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import moment from 'moment'
import EditDisabled from '../../../components/EditDisabled'
import { scheduleDateFormat, scheduleTimeFormat } from '../utils'

export const BackHeader = styled.th`
  font-weight: bold !important;
  color: black !important;
`

const renderDays = (schedule) => {
  const days = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
  ]

  return days.map((day, index) => (
    <tr key={day}>
      <th className="pl-0" scope="row">
        {day.charAt(0).toUpperCase() + day.substr(1)}
      </th>
      <td className="pl-0" name={`day${index}_open`}>
        {schedule[`${day}Open`]
          ? scheduleTimeFormat(schedule[`${day}Open`])
          : ''}
      </td>
      <td className="pl-0" name={`day${index}_close`}>
        {schedule[`${day}Close`]
          ? scheduleTimeFormat(schedule[`${day}Close`])
          : ''}
      </td>
    </tr>
  ))
}

const renderContent = (schedule) => {
  const { effectiveOn, createdAt, updatedAt } = schedule
  return (
    <div>
      <div className="row mb-4">
        <div className="form-group col-md-4 bmd-form-group">
          <EditDisabled
            header={'Effective On'}
            text={scheduleDateFormat(effectiveOn)}
          />
        </div>
        <div className="form-group col-md-4 bmd-form-group">
          <EditDisabled
            header={'Created At'}
            text={scheduleDateFormat(createdAt)}
          />
        </div>
        <div className="form-group col-md-4 bmd-form-group">
          <EditDisabled
            header={'Last Updated'}
            text={scheduleDateFormat(updatedAt)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <table className="table">
            <thead>
              <tr>
                <BackHeader className="pl-0" scope="col">
                  Day Of Week
                </BackHeader>
                <BackHeader className="pl-0" scope="col">
                  Open Time
                </BackHeader>
                <BackHeader className="pl-0" scope="col">
                  Close Time
                </BackHeader>
              </tr>
            </thead>
            <tbody>{renderDays(schedule)}</tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

function ScheduleScreen({
  id,
  schedule,
  title,
  dangerText,
  confirmText,
  onDanger,
  onClose,
  onConfirm,
  isScheduleEditLocked,
}) {
  const isEditDisabled = () => {
    if (schedule) {
      return schedule.effectiveOn.isBefore(moment())
    }

    return false
  }
  return (
    <div className="modal-content">
      <div className="modal-header border-bottom">
        <h5 className="modal-title" id="exampleModalLongTitle">
          {title}
        </h5>
        <div style={{ flex: 1, marginLeft: 20 }}>
          {!isScheduleEditLocked &&
            onDanger && (
            <button
              disabled={isEditDisabled()}
              type="button"
              className="btn btn-primary"
              onClick={() => {
                  $(`#modal-${id}`).modal('hide') // eslint-disable-line
                onDanger()
              }}
            >
              {dangerText}
            </button>
          )}
        </div>
        <div>
          {!isScheduleEditLocked &&
            onConfirm && (
            <button
              disabled={isEditDisabled()}
              type="button"
              className="btn btn-primary btn-raised"
              onClick={() => {
                  $(`#modal-${id}`).modal('hide') // eslint-disable-line
                onConfirm()
              }}
            >
              {confirmText}
            </button>
          )}
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              $(`#modal-${id}`).modal('hide') // eslint-disable-line
              onClose()
            }}
          >
            Close
          </button>
        </div>
      </div>
      <div className="modal-body">{renderContent(schedule)}</div>
    </div>
  )
}

ScheduleScreen.propTypes = {
  schedule: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  dangerText: PropTypes.string,
  confirmText: PropTypes.string,
  onDanger: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
  isScheduleEditLocked: PropTypes.bool,
}

export default ScheduleScreen
