/*
 *
 * User reducer
 *
 */

import { fromJS } from 'immutable'
import { ActionTypes } from './constants'

const initialState = fromJS({
  data: null,
  isLoading: false,
  error: null,
  topic: null,
})

function userReducer(state = initialState, { type, payload }) {
  switch (type) {
    case ActionTypes.USER_RESTORE_SUCCESS:
      return state.set('data', payload).set('isLoading', false)
    case ActionTypes.USER_RESTORE_FAILURE:
      return state.set('data', null).set('isLoading', false)
    case ActionTypes.USER_LOGIN_REQUEST:
    case ActionTypes.ADMIN_LOGIN_REQUEST:
      return state.set('isLoading', true)
    case ActionTypes.USER_LOGIN_SUCCESS:
      return state
        .set('data', payload)
        .set('isLoading', false)
        .set('error', null)
        .set('errorBody', null)
    case ActionTypes.USER_LOGIN_FAILURE:
      return state
        .set('data', null)
        .set('isLoading', false)
        .set('error', payload.message)
        .set('errorBody', payload.body)
    case ActionTypes.USER_LOGOUT_REQUEST:
      return state.set('isLoading', true)
    case ActionTypes.USER_LOGOUT_SUCCESS:
      return state
        .set('data', null)
        .set('isLoading', false)
        .set('error', null)
        .set('errorBody', null)
    case ActionTypes.USER_LOGOUT_FAILURE:
      return state
        .set('data', null)
        .set('isLoading', false)
        .set('error', payload)
        .set('errorBody', null)
    case ActionTypes.MQTT_TOPIC_SUBSCRIBED:
      return state.set('topic', payload.topic)
    default:
      return state
  }
}

export default userReducer
