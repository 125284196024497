/**
 *
 * EmptyCanvasLabel
 *
 */

import styled from 'styled-components'

export default styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  z-index: 100;
  font-size: 18px;
`
