import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import FileSelectionButton from '../FileSelectionButton'

export const StoreImageContainer = styled.div`
  height: 250px;
  background-color: lightgray;
  background-image: url(${(props) => `${props.imageUrl}`});
  background-repeat: no-repeat;
  background-size: cover;
  ${({ invalid }) =>
    invalid ? 'box-shadow: inset 0px -2px 0px 0px #d50000;' : ''};
`

const ButtonContainer = styled.div`
  display: flex;
  padding-top: 10px;
  padding-left: 22px;
`

const MB = 1024 * 1024

class StoreImgPicker extends React.PureComponent {
  constructor(props) {
    super(props)
    const { value, defaultValue, required } = props
    this.state = {
      isValid: !required ? true : !!(value || defaultValue),
      imageUrl: value || defaultValue,
    }
  }

  popoverElement = () => $(this.container) // eslint-disable-line

  onImageChange = ({ target: { files } }) => {
    if (files.length < 1) return

    const { onChange, ratioTooltip } = this.props
    const file = files[0]

    const imageUrl = URL.createObjectURL(file)
    ratioTooltip.validateImageRatio(file)
    const isFileSizeTooBig = this.isFileSizeExceeds(file)

    this.setState({ file, imageUrl, isValid: !!imageUrl && !isFileSizeTooBig })
    onChange({ imageUrl, image: file })
    this.popoverElement().popover('dispose')
  }

  setCustomValidity(msg) {
    const element = this.popoverElement()
    if (msg === '') {
      this.setServerErrorDismiss(element)
    } else {
      this.setServerError(element, msg)
    }
  }

  setServerErrorDismiss() {
    // $(element).popover('dispose') // eslint-disable-line
  }

  setServerError(element, msg) {
    element.attr('data-placement', 'right')
    element.attr('title', 'Image invalid')
    element.attr('data-content', msg)
    element.popover('show')
    setTimeout(() => {
      element.popover('dispose')
    }, 3000)
  }

  isCustom() {
    return true
  }

  isValid() {
    return this.state.isValid
  }

  validationMessage = () => {
    const { value, isValid, file } = this.state

    if (!isValid && file && this.isFileSizeExceeds(file)) {
      return this.props.exceedsMsg || 'Store image exceeds one megabyte'
    }

    if (!value) {
      return this.props.requiredMsg || 'Store image required'
    }

    if (!isValid) {
      return this.props.invalidMsg || 'Invalid store image'
    }

    return undefined
  }

  showInvalidMessage = () => this.setCustomValidity(this.validationMessage())

  isFileSizeExceeds(file) {
    return (file || {}).size > MB
  }

  render() {
    const { imageUrl } = this.state
    const { ratioTooltip } = this.props
    return (
      <StoreImageContainer imageUrl={imageUrl} invalid={!this.state.isValid}>
        <ButtonContainer>
          <span
            ref={(ref) => (this.container = ref)}
            className="align-items-center d-inline-flex"
          >
            <FileSelectionButton
              title={imageUrl ? 'Edit Image' : 'Add Image'}
              onFileSelection={this.onImageChange}
              name="editStoreImage"
            />
            {ratioTooltip.tooltipIcon()}
          </span>
        </ButtonContainer>
      </StoreImageContainer>
    )
  }
}

StoreImgPicker.propTypes = {
  defaultValue: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  exceedsMsg: PropTypes.string,
  invalidMsg: PropTypes.string,
  requiredMsg: PropTypes.string,
  ratioTooltip: PropTypes.object,
}

export default StoreImgPicker
