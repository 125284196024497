import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

export const Dropdown = styled.div`
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  flex: 1;
`

export const CellContainer = styled.div`
  display: flex;
  flex: 1;
`
class DropdownButton extends React.PureComponent {
  render() {
    return (
      <Dropdown className="btn-group dropup">
        <CellContainer
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {this.props.children}
        </CellContainer>
        <div
          className="dropdown-menu dropdown-menu-right"
          aria-labelledby="dropdownMenuButton"
        >
          {this.props.dropdownChildren}
        </div>
      </Dropdown>
    )
  }
}

DropdownButton.propTypes = {
  children: PropTypes.object,
  dropdownChildren: PropTypes.object,
}

export default DropdownButton
