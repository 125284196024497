import React from 'react'

import PropTypes from 'prop-types'
import { FormRow, Col } from '../Bootstrap'
import Checkbox from '../Checkbox'
import { CheckboxContainer } from '../../containers/TemplateFixture/index.screen'
import { humanize } from '../../utils/utils'

// Copy
import { injectIntl, FormattedMessage } from 'react-intl'

const intlPath = (strings) =>
  `components.deviceConfiguration.form.row.${strings[0]}`

class DeviceConfigurationParameterFormRow extends React.Component {
  constructor(props) {
    super(props)
    this.handleOverrideChange = this.handleOverrideChange.bind(this)
  }

  /** Whether to disable editing of the control.
   * @return {boolean}
   */
  readOnly() {
    const {
      onChange,
      deviceConfigurationParameter: dcProp,
      allowOverride,
      showOverride,
    } = this.props

    // If there's no onChange callback, don't allow
    // changes.
    if (typeof onChange !== 'function') return true

    // If we're not showing overrides, we're at the
    // Org level. Allow edits.
    if (!showOverride) return false

    // If the org has not allowed us to override, don't
    // allow edits.
    if (!allowOverride) return true

    // If the user has not selected the property for override,
    // don't allow edits.
    if (!dcProp.override) return true

    // Allow edits.
    return false
  }

  /**
   * Finds an array of matching translation keys in the
   * translation messages, using `default` as an alternative
   * if a part of the path is missing.
   *
   * That is, instead of requiring translations for
   *     deviceConfigurationParameters.puck_v2.00008.volume
   * This method will be satisfied with
   *     deviceConfigurationParameters.puck_v2.default.volume
   * Or even
   *     deviceconfigurationParameters.default.default.default
   *
   * Memoized.
   *
   * @returns [Object] A dictionary indexed on the last element
   *   of the key.
   */
  translationKeys() {
    // Memoize
    if (typeof this._translationKeys !== 'undefined') {
      return this._translationKeys
    }

    const {
      deviceConfigurationParameter: { name },
      deviceType,
      firmwareVersion,
      intl, // from injectIntl
    } = this.props

    // Start with all keys
    var keys = Object.keys(intl.messages)

    // Build the prefix a segment at a time, using
    // 'default' if we can't find a portion.
    var prefix = 'deviceConfigurationParameters'

    const parts = [deviceType, firmwareVersion, name]
    parts.forEach((part) => {
      if (keys.some((x) => x.startsWith(`${prefix}.${part}`))) {
        prefix = `${prefix}.${part}`
      } else {
        prefix = `${prefix}.default`
      }
      // Filter keys further
      keys = keys.filter((x) => x.startsWith(prefix))
    })

    // Build the dictionary based on the last segment.
    const dict = keys.reduce((d, k) => {
      const end = k.split('.').pop()
      d[end] = k
      return d
    }, {})

    // Memoize
    this._translationKeys = dict
    return dict
  }

  /**
   * Finds I18nized text based on the fallback logic
   * defined in translationKeys().
   *
   * @param {string} field the last segment of the key to find
   * @param {object} values optional parameters for the translation.
   * @return {string} the translated text or an empty string if the
   *     key is not defined.
   */
  translations(field, values = {}) {
    const { intl } = this.props
    const id = this.translationKeys()[field]
    if (typeof id === 'undefined') return ''

    return intl.formatMessage({ id, defaultMessage: '' }, values)
  }

  /** Returns the translated label for the parameter, or else
   * the parameter name titleized.
   * @return {string}
   */
  label() {
    const { deviceConfigurationParameter: dcParam } = this.props
    return this.translations('label') || humanize(dcParam.name)
  }

  handleOverrideChange(checked) {
    const {
      onOverrideChange,
      deviceConfigurationParameter: dcParam,
    } = this.props
    onOverrideChange(dcParam.name, checked)
  }

  /** renders the control that allows managers to override a
   * particular property, if it is allowed.
   * @param {boolean} override - the current value of the override field.
   */
  overrideControl(override) {
    const { allowOverride, deviceConfigurationParameter: dcParam } = this.props
    if (!allowOverride) return <label>{this.label()}</label>

    return (
      <CheckboxContainer>
        <Checkbox
          name={`override-${dcParam.name}`}
          checked={override}
          onChange={(evt) => this.handleOverrideChange(evt.target.checked)}
          label={this.label()}
        />
      </CheckboxContainer>
    )
  }

  /** Handle a change event if a range of values has been
   * specified in the translation file.
   * @param {number} value
   */
  handleValueChange(value) {
    this.handleChange(value)
  }

  /** Handle a change event if a simple checkbox is displayed.
   * @param {boolean} checked
   */
  handleCheckChange(checked) {
    this.handleChange(checked ? 1 : 0)
  }

  /** Handle a change event if a numeric textbox has been displayed.
   * @param {number} number
   */
  handleNumberChange(number) {
    this.handleChange(number)
  }

  /** Handle a change event in case the fallback control is displayed,
   *  and also the underlying behavior for the other handlers.
   *  @param {number} value
   */
  handleChange(value) {
    const { deviceConfigurationParameter: dcParam, onChange } = this.props
    onChange(dcParam.name, 1 * value)
  }

  controlWithValues(key) {
    const { deviceConfigurationParameter: { name, value }, intl } = this.props

    /* The `values` translation should be in the form:
     *     {value, select, 1 { first option } 2 { second option }}
     * (The words "value" and "select" are literal; the number is the
     * value of the control; and the text in the inner brackets is
     * the label to put on the option.)
     *
     * For example, for the "volume" parameter, you might have
     *     {value, select, 0 { off } 64 { quiet } 128 { medium } 255 { loud }}
     *
     * For boolean options, use "0" and "1":
     *     {value, select, 0 { Silent } 1 { Sounds }}
     *
     * You can put text outside of that format, too:
     *     Alarm will be {value, select, 0 { silent } 255 { loud }}
     *
     * See https://formatjs.io/guides/message-syntax/#select-format
     */
    const xlation = intl.messages[key]
    const optsIter = xlation.matchAll(/(\d+)\s*\{.*?\}/g)
    const controls = []
    for (const opts of optsIter) {
      const optValue = opts[1]
      /* FormattedMessage can take a function as its child, which
       * receives an array of translated text (as possibly mixed strings
       * and React components) as its argument. There shouldn't be
       * any React components unless you pass them in to its 'values'
       * property.
       */
      controls.push(
        <div className="form-check form-check-inline" key={optValue}>
          <input
            type="radio"
            className="form-check-input"
            name={name}
            value={optValue}
            checked={value * 1 == optValue * 1}
            onChange={(event) => this.handleValueChange(event.target.value)}
            disabled={this.readOnly()}
          />
          <FormattedMessage
            key={optValue}
            id={key}
            values={{ value: optValue }}
          >
            {(text) => <label className="form-check-label">{text}</label>}
          </FormattedMessage>
        </div>
      )
    }
    return controls
  }

  /** Renders the control appropriate to the dataType and translation file.
   */
  control() {
    const valuesKey = this.translationKeys().values
    if (valuesKey) {
      return this.controlWithValues(valuesKey)
    }

    const {
      deviceConfigurationParameter: { dataType, name, value },
    } = this.props
    switch (dataType) {
      case 'boolean':
        return (
          <div className="form-check form-check-inline">
            <input
              type="checkbox"
              className="form-check-input"
              name={name}
              checked={value === 1}
              onChange={(event) => this.handleCheckChange(event.target.checked)}
              disabled={this.readOnly()}
            />
            <label className="form-check-label">
              <FormattedMessage id={intlPath`boolean`} />
            </label>
          </div>
        )
      case 'integer':
        return (
          <input
            type="number"
            className="form-control"
            min="0"
            max="255"
            name={name}
            value={value}
            onChange={(event) => this.handleNumberChange(event.target.value)}
            disabled={this.readOnly()}
          />
        )
      default:
        return (
          <input
            type="text"
            className="form-control"
            name={name}
            value={value}
            onChange={(event) => this.handleChange(event.target.value)}
            disabled={this.readOnly()}
          />
        )
    }
  }

  /** Renders the help-text, if any has been supplied in the translation file. */
  helpText() {
    const helpText = this.translations('help')
    if (helpText === '') return ''

    return <small className="form-text text-muted">{helpText}</small>
  }

  render() {
    const { deviceConfigurationParameter: dcParam, showOverride } = this.props

    return (
      <FormRow>
        <Col span={4}>
          {showOverride ? (
            this.overrideControl(dcParam.override)
          ) : (
            <label className="form-label">{this.label()}</label>
          )}
        </Col>
        <Col span={8}>
          {this.control()}
          {this.helpText()}
        </Col>
      </FormRow>
    )
  }
}

DeviceConfigurationParameterFormRow.propTypes = {
  deviceConfigurationParameter: PropTypes.object.isRequired,
  deviceType: PropTypes.string.isRequired,
  firmwareVersion: PropTypes.string,
  allowOverride: PropTypes.bool,
  showOverride: PropTypes.bool,
  onChange: PropTypes.func,
  onOverrideChange: PropTypes.func,
}

DeviceConfigurationParameterFormRow.defaultProps = {
  allowOverride: false,
  showOverride: false,
}

export default injectIntl(DeviceConfigurationParameterFormRow)
