import React from 'react'
import PropTypes from 'prop-types'
import ReactTable from 'react-table'
import styled from 'styled-components'
import { fullTextFilter, PageHeaderHeight } from '../../../utils/utils'
import {
  CellContainer,
  HeaderContainer,
  Header,
  renderHeaderCell,
  renderFilterInput,
} from '../../UsersPage/index.screen'
import { Dropdown } from '../../../components/Issue/DropdownButton'
import { Icon } from '../PingHub/index.screen'
import Select from '../../../components/ControlledSelect'
import styles from '../../../components/Select/css/style.css'

import MoreIcon from '../../../images/svg/buttons/more.svg'
import RebootIcon from '../../../images/svg/buttons/reboot.svg'
import RestartIcon from '../../../images/svg/buttons/restart.svg'
import OTAUIcon from '../../../images/svg/buttons/otau.svg'
import UpdateIcon from '../../../images/svg/buttons/update.svg'
import PingHub from '../PingHub'

export const DropDownButtonContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-around;
`

export const Button = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 8px;
`

const PingContainer = styled.div`
  overflow: hidden;
  flex: 1;
`

const SelectContainer = styled.div`
  margin-bottom: 5px;
  margin-right: 12px;
  margin-top: 16px;
  width: 270px;
`

class StoresListScreen extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  /* eslint-disable no-undef */
  renderMoreButton = (store) => {
    const { onReboot, onRestart, onHubStatus } = this.props
    return (
      <Dropdown className="btn-group dropup">
        <DropDownButtonContainer
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <Icon src={MoreIcon} />
        </DropDownButtonContainer>
        <div
          className="dropdown-menu dropdown-menu-right"
          aria-labelledby="dropdownMenuButton"
        >
          <ButtonsContainer>
            <Button onClick={() => onHubStatus(store)}>
              <Icon src={OTAUIcon} />
              OTAU
            </Button>
            <Button onClick={() => onReboot(store)}>
              <Icon src={RebootIcon} />
              Reboot
            </Button>
            <Button onClick={() => onRestart(store)}>
              <Icon src={RestartIcon} />
              Restart
            </Button>
          </ButtonsContainer>
        </div>
      </Dropdown>
    )
  }

  render() {
    const {
      organizations,
      stores,
      onUpdate,
      selectedOrganizationId,
      onSelectOrganization,
      height,
    } = this.props

    const columns = [
      {
        id: 'name',
        Header: () => renderHeaderCell('Name'),
        Cell: ({ original }) => <CellContainer>{original.name}</CellContainer>,
        accessor: 'name',
        sortable: true,
        filterable: true,
        Filter: ({ filter, onChange }) =>
          renderFilterInput({ placeholder: 'Filter', filter, onChange }),
      },
      {
        id: 'ping',
        width: 100,
        Header: 'Ping',
        Cell: ({ original }) => (
          <PingContainer>
            <PingHub storeId={original.id} />
          </PingContainer>
        ),
      },
      {
        id: 'update',

        width: 80,
        Header: 'Update',
        Cell: ({ original }) => (
          <Icon src={UpdateIcon} onClick={() => onUpdate(original)} />
        ),
      },
      {
        id: 'more',
        width: 100,
        Header: 'More',
        Cell: ({ original }) => this.renderMoreButton(original),
      },
    ]
    return (
      <div style={{ height: '100%' }}>
        <HeaderContainer className="border-bottom">
          <Header>Stores</Header>
          <SelectContainer>
            <Select
              customStyle={styles.fixZeroPadding}
              name="organizationSelector"
              value={selectedOrganizationId}
              onChange={({ target: { value } }) => onSelectOrganization(value)}
            >
              <option value={''}>Select Organization</option>
              {organizations.map(({ id, name }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </Select>
          </SelectContainer>
        </HeaderContainer>
        <ReactTable
          className="full-screen"
          style={{
            height: height - PageHeaderHeight,
          }}
          data={stores}
          columns={columns}
          sortable={false}
          defaultFilterMethod={fullTextFilter}
          getTdProps={(_, __, column) => {
            if (column.id === 'name') return {}

            if (column.id === 'more') {
              return {
                style: {
                  overflow: 'visible',
                },
              }
            }

            return {
              style: {
                display: 'flex',
                justifyContent: 'center',
              },
            }
          }}
        />
      </div>
    )
  }
}

StoresListScreen.propTypes = {
  organizations: PropTypes.array.isRequired,
  selectedOrganizationId: PropTypes.string,
  stores: PropTypes.array.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onReboot: PropTypes.func.isRequired,
  onRestart: PropTypes.func.isRequired,
  onHubStatus: PropTypes.func.isRequired,
  onSelectOrganization: PropTypes.func.isRequired,
  height: PropTypes.number,
}

export default StoresListScreen
