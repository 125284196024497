import React from 'react'
import styled from 'styled-components'

const AlertStyle = styled.div`
  color: rgba(0, 0, 0, 0.6);
  text-transform: uppercase;
  border-radius: 3px;
  display: flex;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  margin-top: 2px;
`

const Alert = ({ alert, content }) => {
  const { alertType } = alert
  return (
    <AlertStyle style={{ backgroundColor: alertType.backgroundColor }}>
      {content}
    </AlertStyle>
  )
}

Alert.propTypes = {}

export { Alert }
