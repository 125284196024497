/* eslint-disable */
import _ from 'lodash'
import { fabric } from 'fabric'
import {
  originFixtureRectHeight,
  originFixtureRectWidth,
} from '../AreaCanvas/sizeUtils'

const edgeDetection = 8 // pixels to snap
const gap = 2 // gap in pixels
const highlightColor = 'rgb(91,35,138)'

const rulers = {
  outLineFromLeft: null,
  outLineFromRight: null,
  outLineFromTop: null,
  outLineFromBottom: null,
  inLineFromLeft: null,
  inLineFromRight: null,
  inLineFromTop: null,
  inLineFromBottom: null,
}

export function onScaleNewObject(
  options,
  canvas,
  canvasWidth,
  canvasHeight,
  initialCoords,
  isScale
) {
  console.warn('onScaleNewObject isScale', isScale)
  const obj = options.target
  obj.setCoords() //Sets corner position coordinates based on current angle, width and height

  const activeObject = obj // canvas.getActiveObject()
  if (!activeObject) return
  const activeOCoords = activeObject.get('oCoords')

  const objects = canvas.getObjects()

  // Reset to IC
  // Remove rulers
  removeRulers(canvas)
  setAll(rulers, null)
  // Remove shadows
  objects.map((o) => o.setShadow(null))

  const intersectOverlap = onScaleNewOverlap(
    canvas,
    options,
    activeObject,
    canvasWidth,
    canvasHeight,
    initialCoords,
    isScale
  )

  // Canvas border detection
  const { pointer: { x, y } = {} } = options
  const intersectCanvasEdges = handleScaleNewCanvasEdges(
    canvas,
    x,
    y,
    activeObject,
    canvasWidth,
    canvasHeight,
    initialCoords
  )
  const intersect = { ...intersectOverlap, ...intersectCanvasEdges }

  return intersect
}

export function onScaleObject(
  options,
  canvas,
  canvasWidth,
  canvasHeight,
  initialCoords,
  isScale
) {
  console.log('onScaleObject')
  const obj = options.target
  // The control of the object
  // const {
  //   isRightCorner,
  //   isLeftCorner,
  //   isTopCorner,
  //   isBottomCorner,
  // } = getCorner(options)
  obj.setCoords() //Sets corner position coordinates based on current angle, width and height

  const activeObject = canvas.getActiveObject()
  if (!activeObject) return
  // const activeOCoords = activeObject.get('oCoords')

  // const {
  //   w,
  //   h,
  //   cX,
  //   cY,
  //   width: activeWidth,
  //   height: activeHeight,
  //   left: activeLeft,
  //   right: activeRight,
  //   bottom: activeBottom,
  //   top: activeTop,
  //   left1: activeLeft1,
  //   right1: activeRight1,
  //   bottom1: activeBottom1,
  //   top1: activeTop1,
  //   left2: activeLeft2,
  //   right2: activeRight2,
  //   bottom2: activeBottom2,
  //   top2: activeTop2,
  // } = getEdges(activeObject)

  // const {
  //   // Outer rotational bounds
  //   distancesFromLeftTop,
  //   distancesFromLeftBottom,
  //   distancesFromRightBottom,
  //   distancesFromRightTop,
  // } = getDistances(canvas, activeObject, 1)

  const objects = canvas.getObjects()

  // Reset to IC
  // Remove rulers
  removeRulers(canvas)
  setAll(rulers, null)
  // Remove shadows
  objects.map((o) => o.setShadow(null))

  // // Outer rotational bounds
  // const distanceFromLeftTop = distancesFromLeftTop[0] || {}
  // const distanceFromLeftBottom = distancesFromLeftBottom[0] || {}
  // const distanceFromRightBottom = distancesFromRightBottom[0] || {}
  // const distanceFromRightTop = distancesFromRightTop[0] || {}

  const intersect = {
    outFromLeftTop: null,
    outFromLeftBottom: null,
    outFromRightBottom: null,
    outFromRightTop: null,
  }

  // drawRulers(canvas, intersect, rulers, objects, canvasWidth, canvasHeight)

  onScaleOverlap(
    canvas,
    options,
    activeObject,
    canvasWidth,
    canvasHeight,
    initialCoords,
    isScale
  )

  // Canvas border detection
  const { pointer: { x, y } = {} } = options
  handleScaleCanvasEdges(
    canvas,
    x,
    y,
    activeObject,
    canvasWidth,
    canvasHeight,
    initialCoords
  )
  return intersect
}

export function handleScaleCanvasEdges(
  canvas,
  x,
  y,
  activeObject,
  canvasWidth,
  canvasHeight,
  initialCoords
) {
  const {
    w,
    h,
    cX,
    cY,
    width: activeWidth,
    height: activeHeight,
    left: activeLeft,
    right: activeRight,
    bottom: activeBottom,
    top: activeTop,
    left1: activeLeft1,
    right1: activeRight1,
    bottom1: activeBottom1,
    top1: activeTop1,
    left2: activeLeft2,
    right2: activeRight2,
    bottom2: activeBottom2,
    top2: activeTop2,
  } = getEdges(activeObject)

  const { aAngle, tAngle } = getNormalizedAngles(activeObject, { angle: 0 })

  if (activeLeft < 0) {
    const targetTop1 = { x: 0, y: 0 }
    const targetRight1 = {
      x: 0,
      y: canvasHeight,
    }
    // to border from right top
    if (aAngle < 45) {
      const { newCX, newCY, length } = getScaleParams(
        targetRight1,
        targetTop1,
        activeLeft1,
        activeTop1,
        aAngle > tAngle,
        activeBottom1,
        activeRight1
      )
      setScale(activeObject, newCX, newCY, length, undefined, initialCoords)
    } else {
      const { newCX, newCY, length } = getScaleParams(
        targetRight1,
        targetTop1,
        activeLeft1,
        activeBottom1,
        aAngle > tAngle,
        activeTop1,
        activeRight1
      )
      setScale(activeObject, newCX, newCY, length, undefined, initialCoords)
    }
  }
  if (activeTop < 0) {
    const targetBottom1 = {
      x: canvasWidth,
      y: 0,
    }
    const targetLeft1 = { x: 0, y: 0 }
    // to border from left bottom
    if (aAngle < 45) {
      const { newCX, newCY, length } = getScaleParams(
        targetBottom1,
        targetLeft1,
        activeTop1,
        activeRight1,
        aAngle > tAngle,
        activeLeft1,
        activeBottom1
      )
      setScale(activeObject, newCX, newCY, length, undefined, initialCoords)
    } else {
      const { newCX, newCY, length } = getScaleParams(
        targetBottom1,
        targetLeft1,
        activeTop1,
        activeLeft1,
        aAngle > tAngle,
        activeRight1,
        activeBottom1
      )
      setScale(activeObject, newCX, newCY, length, undefined, initialCoords)
    }
  }
  if (activeRight > canvasWidth) {
    const targetTop1 = { x: canvasWidth, y: 0 }
    const targetLeft1 = {
      x: canvasWidth,
      y: canvasHeight,
    }
    // to border from left top
    if (aAngle < 45) {
      const { newCX, newCY, length } = getScaleParams(
        targetLeft1,
        targetTop1,
        activeRight1,
        activeBottom1,
        aAngle > tAngle,
        activeTop1,
        activeLeft1
      )
      setScale(activeObject, newCX, newCY, length, undefined, initialCoords)
    } else {
      const { newCX, newCY, length } = getScaleParams(
        targetLeft1,
        targetTop1,
        activeRight1,
        activeTop1,
        aAngle > tAngle,
        activeBottom1,
        activeLeft1
      )
      setScale(activeObject, newCX, newCY, length, undefined, initialCoords)
    }
  }
  if (activeBottom > canvasHeight) {
    const targetTop1 = { x: 0, y: canvasHeight }
    const targetRight1 = {
      x: canvasWidth,
      y: canvasHeight,
    }
    // to border from right top
    if (aAngle < 45) {
      const { newCX, newCY, length } = getScaleParams(
        targetRight1,
        targetTop1,
        activeBottom1,
        activeLeft1,
        aAngle > tAngle,
        activeRight1,
        activeTop1
      )
      setScale(activeObject, newCX, newCY, length, undefined, initialCoords)
    } else {
      const { newCX, newCY, length } = getScaleParams(
        targetRight1,
        targetTop1,
        activeBottom1,
        activeRight1,
        aAngle > tAngle,
        activeLeft1,
        activeTop1
      )
      setScale(activeObject, newCX, newCY, length, undefined, initialCoords)
    }
  }
}

export function handleScaleNewCanvasEdges(
  canvas,
  x,
  y,
  activeObject,
  canvasWidth,
  canvasHeight,
  initialCoords
) {
  const {
    w,
    h,
    cX,
    cY,
    width: activeWidth,
    height: activeHeight,
    left: activeLeft,
    right: activeRight,
    bottom: activeBottom,
    top: activeTop,
    left1: activeLeft1,
    right1: activeRight1,
    bottom1: activeBottom1,
    top1: activeTop1,
    left2: activeLeft2,
    right2: activeRight2,
    bottom2: activeBottom2,
    top2: activeTop2,
  } = getEdges(activeObject)

  const { aAngle, tAngle } = getNormalizedAngles(activeObject, { angle: 0 })
  const intersect = {}
  if (activeRight > canvasWidth) {
    const targetTop1 = { x: canvasWidth, y: 0 }
    const targetLeft1 = {
      x: canvasWidth,
      y: canvasHeight,
    }
    // to border new from left
    const { newCX, newCY, length } = getScaleParams(
      targetLeft1,
      targetTop1,
      activeRight1,
      activeBottom1,
      aAngle > tAngle,
      activeTop1,
      activeLeft1
    )
    setNewScale(activeObject, newCX, newCY, length, undefined)
    intersect.outFromLeftBottom = true
  }
  if (activeBottom > canvasHeight) {
    const targetTop1 = { x: 0, y: canvasHeight }
    const targetRight1 = {
      x: canvasWidth,
      y: canvasHeight,
    }
    // to border new from right
    const { newCX, newCY, length } = getScaleParams(
      targetRight1,
      targetTop1,
      activeBottom1,
      activeLeft1,
      aAngle > tAngle,
      activeRight1,
      activeTop1
    )
    setNewScale(activeObject, newCX, newCY, undefined, length)
    intersect.outFromLeftTop = true
  }
  return intersect
}

let lastX = 0
let lastY = 0
let lastNonOverlapX = 0
let lastNonOverlapY = 0
export function onMoveObject(
  options,
  canvas,
  canvasWidth,
  canvasHeight,
  disableOverlap
) {
  console.log('onMoveObject')
  const { pointer: { x, y } = {} } = options
  if (!lastX) {
    lastX = x
    lastY = y
  }
  //

  var obj = options.target
  obj.setCoords() //Sets corner position coordinates based on current angle, width and height

  const activeObject = canvas.getActiveObject()
  if (!activeObject) return

  const aX = activeObject.left
  const aY = activeObject.top

  if (!lastNonOverlapX) {
    lastNonOverlapX = aX
    lastNonOverlapY = aY
  }

  const {
    w,
    h,
    cX,
    cY,
    width: activeWidth,
    height: activeHeight,
    left: activeLeft,
    right: activeRight,
    bottom: activeBottom,
    top: activeTop,
    left1: activeLeft1,
    right1: activeRight1,
    bottom1: activeBottom1,
    top1: activeTop1,
    left2: activeLeft2,
    right2: activeRight2,
    bottom2: activeBottom2,
    top2: activeTop2,
  } = getEdges(activeObject)

  const {
    // Outer rotational bounds
    distancesFromLeftTop,
    distancesFromLeftBottom,
    distancesFromRightBottom,
    distancesFromRightTop,
  } = getDistances(canvas, activeObject, 1)

  const objects = canvas.getObjects()
  // objects.map((o) => console.log(o.id, o.name))

  // Reset to IC
  // Remove rulers
  removeRulers(canvas)
  setAll(rulers, null)
  // Remove shadows
  objects.map((o) => o.setShadow(null))

  // Outer rotational bounds
  const distanceFromLeftTop = distancesFromLeftTop[0] || {}
  const distanceFromLeftBottom = distancesFromLeftBottom[0] || {}
  const distanceFromRightBottom = distancesFromRightBottom[0] || {}
  const distanceFromRightTop = distancesFromRightTop[0] || {}

  const intersect = {
    outFromLeftTop: null,
    outFromLeftBottom: null,
    outFromRightBottom: null,
    outFromRightTop: null,
  }

  // Outer bounds
  if (Math.abs(distanceFromLeftTop.distance) < edgeDetection) {
    // from left top
    console.log('from left top')
    const target = objects.find(({ id }) => id === distanceFromLeftTop.id)

    const {
      left: targetLeft,
      right: targetRight,
      bottom: targetBottom,
      top: targetTop,
      left1: targetLeft1,
      right1: targetRight1,
      bottom1: targetBottom1,
      top1: targetTop1,
      left2: targetLeft2,
      right2: targetRight2,
      bottom2: targetBottom2,
      top2: targetTop2,
    } = getEdges(target)

    const { dx, dy } = getDeltasPointsDistances(
      targetLeft1,
      targetTop1,
      activeRight1,
      activeBottom1
    )
    activeObject.set('left', cX + dx)
    activeObject.set('top', cY + dy)
    intersect.outFromLeftTop = target.id
    //
  }

  if (Math.abs(distanceFromLeftBottom.distance) < edgeDetection) {
    // from left bottom
    console.log('from left bottom')
    const target = objects.find(({ id }) => id === distanceFromLeftBottom.id)

    const {
      left: targetLeft,
      right: targetRight,
      bottom: targetBottom,
      top: targetTop,
      left1: targetLeft1,
      right1: targetRight1,
      bottom1: targetBottom1,
      top1: targetTop1,
      left2: targetLeft2,
      right2: targetRight2,
      bottom2: targetBottom2,
      top2: targetTop2,
    } = getEdges(target)

    const { dx, dy } = getDeltasPointsDistances(
      targetLeft1,
      targetBottom1,
      activeRight1,
      activeTop1
    )
    activeObject.set('left', cX + dx)
    activeObject.set('top', cY + dy)
    intersect.outFromLeftBottom = target.id
    //
  }

  if (Math.abs(distanceFromRightBottom.distance) < edgeDetection) {
    // from right bottom
    console.log('from right bottom')
    const target = objects.find(({ id }) => id === distanceFromRightBottom.id)

    const {
      left: targetLeft,
      right: targetRight,
      bottom: targetBottom,
      top: targetTop,
      left1: targetLeft1,
      right1: targetRight1,
      bottom1: targetBottom1,
      top1: targetTop1,
      left2: targetLeft2,
      right2: targetRight2,
      bottom2: targetBottom2,
      top2: targetTop2,
    } = getEdges(target)

    const { dx, dy } = getDeltasPointsDistances(
      targetRight1,
      targetBottom1,
      activeLeft1,
      activeTop1
    )
    activeObject.set('left', cX + dx)
    activeObject.set('top', cY + dy)
    intersect.outFromRightBottom = target.id
    //
  }

  if (Math.abs(distanceFromRightTop.distance) < edgeDetection) {
    // from right top
    console.log('from right top')
    const target = objects.find(({ id }) => id === distanceFromRightTop.id)

    const {
      left: targetLeft,
      right: targetRight,
      bottom: targetBottom,
      top: targetTop,
      left1: targetLeft1,
      right1: targetRight1,
      bottom1: targetBottom1,
      top1: targetTop1,
      left2: targetLeft2,
      right2: targetRight2,
      bottom2: targetBottom2,
      top2: targetTop2,
    } = getEdges(target)

    const { dx, dy } = getDeltasPointsDistances(
      targetRight1,
      targetTop1,
      activeLeft1,
      activeBottom1
    )
    activeObject.set('left', cX + dx)
    activeObject.set('top', cY + dy)
    intersect.outFromRightTop = target.id
    //
  }

  drawRulers(canvas, intersect, rulers, objects, canvasWidth, canvasHeight)

  let processed
  if (!disableOverlap) {
    processed = onMoveOverlap(
      canvas,
      options,
      activeObject,
      canvasWidth,
      canvasHeight
    )
  }

  //drawRectLine(canvas, activeObject, 'rgba(0,255,0,0.5)')

  // Canvas border detection
  handleCanvasEdges(canvas, x, y, activeObject, canvasWidth, canvasHeight)

  if (
    isCustomOverlap(
      canvas,
      compileActive(activeObject, aX, aY),
      processed,
      intersect
    ) ||
    isCustomCanvasEdgesOverlap(
      {
        x: activeObject.left,
        y: activeObject.top,
        width: activeWidth,
        height: activeHeight,
      },
      canvasWidth,
      canvasHeight
    )
  ) {
    activeObject.set('left', lastX)
    activeObject.set('top', lastY)
  }
}

export function onMoveOverlap(
  canvas,
  options,
  activeObject,
  canvasWidth,
  canvasHeight
) {
  const {
    w,
    h,
    cX,
    cY,
    width: activeWidth,
    height: activeHeight,
    left: activeLeft,
    right: activeRight,
    bottom: activeBottom,
    top: activeTop,
    left1: activeLeft1,
    right1: activeRight1,
    bottom1: activeBottom1,
    top1: activeTop1,
    left2: activeLeft2,
    right2: activeRight2,
    bottom2: activeBottom2,
    top2: activeTop2,
  } = getEdges(activeObject)

  const intersect = {
    outFromLeftTop: null,
    outFromLeftBottom: null,
    outFromRightBottom: null,
    outFromRightTop: null,
  }

  let processed
  // loop canvas objects
  canvas.forEachObject((target) => {
    if (target === null) return
    if (target.type === 'line') return
    if (target === activeObject) return // bypass self
    if (target.id === activeObject.id) return

    // check intersections with every object in canvas
    if (
      activeObject.intersectsWithObject(target) ||
      activeObject.isContainedWithinObject(target) ||
      target.isContainedWithinObject(activeObject)
    ) {
      const {
        // w,
        // h,
        cX: tCx,
        cY: tCy,
        width: targetWidth,
        height: targetHeight,
        left: targetLeft,
        right: targetRight,
        bottom: targetBottom,
        top: targetTop,
        left1: targetLeft1,
        right1: targetRight1,
        bottom1: targetBottom1,
        top1: targetTop1,
        left2: targetLeft2,
        right2: targetRight2,
        bottom2: targetBottom2,
        top2: targetTop2,
      } = getEdges(target)

      const { aAngle, tAngle } = getNormalizedAngles(activeObject, target)

      const { pointer: { x, y } } = options

      const dLeftTop = distancePointToLine(
        targetLeft1.x,
        targetLeft1.y,
        targetTop1.x,
        targetTop1.y,
        x,
        y
      )
      const dLeftBottom = distancePointToLine(
        targetBottom1.x,
        targetBottom1.y,
        targetLeft1.x,
        targetLeft1.y,
        x,
        y
      )
      const dRightBottom = distancePointToLine(
        targetRight1.x,
        targetRight1.y,
        targetBottom1.x,
        targetBottom1.y,
        x,
        y
      )
      const dRightTop = distancePointToLine(
        targetTop1.x,
        targetTop1.y,
        targetRight1.x,
        targetRight1.y,
        x,
        y
      )

      const iLeftTop = getLinesIntersection(
        targetLeft1.x,
        targetLeft1.y,
        targetTop1.x,
        targetTop1.y,
        tCx,
        tCy,
        x,
        y
      )
      const iLeftBottom = getLinesIntersection(
        targetBottom1.x,
        targetBottom1.y,
        targetLeft1.x,
        targetLeft1.y,
        tCx,
        tCy,
        x,
        y
      )
      const iRightBottom = getLinesIntersection(
        targetRight1.x,
        targetRight1.y,
        targetBottom1.x,
        targetBottom1.y,
        tCx,
        tCy,
        x,
        y
      )
      const iRightTop = getLinesIntersection(
        targetTop1.x,
        targetTop1.y,
        targetRight1.x,
        targetRight1.y,
        tCx,
        tCy,
        x,
        y
      )

      const isLeftTop = targetTop1.y < iLeftTop.y && iLeftTop.y < targetLeft1.y
      const isLeftBottom =
        targetLeft1.y < iLeftBottom.y && iLeftBottom.y < targetBottom1.y
      const isRightBottom =
        targetRight1.y < iRightBottom.y && iRightBottom.y < targetBottom1.y
      const isRightTop =
        targetTop1.y < iRightTop.y && iRightTop.y < targetRight1.y

      let side
      if (isLeftTop && isRightBottom) {
        if (dLeftTop < dRightBottom) {
          side = 'isLeftTop'
        } else {
          side = 'isRightBottom'
        }
      } else if (isLeftBottom && isRightTop) {
        if (dLeftBottom < dRightTop) {
          side = 'isLeftBottom'
        } else {
          side = 'isRightTop'
        }
      }

      // Outer bounds
      switch (side) {
        case 'isLeftTop': {
          // from left top
          console.log('from left top')
          const { dx, dy } = getDeltasFromPoints(
            targetLeft1,
            targetTop1,
            activeRight1,
            activeBottom1,
            aAngle > tAngle
          )
          activeObject.set('left', cX + dx)
          activeObject.set('top', cY + dy)
          intersect.outFromLeftTop = target.id
          processed = target
          break
        }

        case 'isLeftBottom': {
          // from left bottom
          console.log('from left bottom')
          const { dx, dy } = getDeltasFromPoints(
            targetLeft1,
            targetBottom1,
            activeRight1,
            activeTop1,
            aAngle < tAngle
          )
          activeObject.set('left', cX + dx)
          activeObject.set('top', cY + dy)
          intersect.outFromLeftBottom = target.id
          processed = target
          break
        }

        case 'isRightBottom': {
          // from right bottom
          console.log('from right bottom')
          const { dx, dy } = getDeltasFromPoints(
            targetRight1,
            targetBottom1,
            activeLeft1,
            activeTop1,
            aAngle > tAngle
          )
          activeObject.set('left', cX + dx)
          activeObject.set('top', cY + dy)
          intersect.outFromRightBottom = target.id
          processed = target
          break
        }

        case 'isRightTop': {
          // from right top
          console.log('from right top')
          const { dx, dy } = getDeltasFromPoints(
            targetRight1,
            targetTop1,
            activeLeft1,
            activeBottom1,
            aAngle < tAngle
          )
          activeObject.set('left', cX + dx)
          activeObject.set('top', cY + dy)
          intersect.outFromRightTop = target.id
          processed = target
          break
        }
      }
    }
  })

  const objects = canvas.getObjects()
  drawRulers(canvas, intersect, rulers, objects, canvasWidth, canvasHeight)
  return processed
}

export function isCustomCanvasEdgesOverlap(
  activeObject,
  canvasWidth,
  canvasHeight
) {
  const { width, height, x, y } = activeObject
  const activeLeft = x - width / 2
  const activeRight = x + width / 2
  const activeTop = y - height / 2
  const activeBottom = y + height / 2

  if (
    activeLeft < 0 ||
    activeTop < 0 ||
    activeRight > canvasWidth ||
    activeBottom > canvasHeight
  ) {
    return true
  }
  return false
}

export function handleCanvasEdges(
  canvas,
  x,
  y,
  activeObject,
  canvasWidth,
  canvasHeight
) {
  const {
    width: activeWidth,
    height: activeHeight,
    left: activeLeft,
    right: activeRight,
    bottom: activeBottom,
    top: activeTop,
  } = getEdges(activeObject)

  const handleOverlap = (canvas, active) => {
    if (isOverlap(canvas, active)) {
      activeObject.set('left', lastNonOverlapX)
      activeObject.set('top', lastNonOverlapY)
    }
  }

  if (activeLeft < edgeDetection) {
    const newLeft = activeWidth / 2
    activeObject.set('left', newLeft)
    handleOverlap(canvas, activeObject)
  }
  if (activeTop < edgeDetection) {
    const newTop = activeHeight / 2
    activeObject.set('top', newTop)
    handleOverlap(canvas, activeObject)
  }
  if (activeRight > canvasWidth - edgeDetection) {
    const newLeft = canvasWidth - activeWidth / 2
    activeObject.set('left', newLeft)
    handleOverlap(canvas, activeObject)
  }
  if (activeBottom > canvasHeight - edgeDetection) {
    const newTop = canvasHeight - activeHeight / 2
    activeObject.set('top', newTop)
    handleOverlap(canvas, activeObject)
  }
}

export function getDefaultEdges(activeObject) {
  const activeOCoords = activeObject.get('oCoords')
  const activeWidth = activeOCoords.tr.x - activeOCoords.tl.x
  const activeHeight = activeOCoords.br.y - activeOCoords.tl.y
  const activeLeft = activeOCoords.ml.x
  const activeRight = activeOCoords.mr.x
  const activeBottom = activeOCoords.mb.y
  const activeTop = activeOCoords.mt.y
  return {
    activeWidth,
    activeHeight,
    activeLeft,
    activeRight,
    activeBottom,
    activeTop,
  }
}

export function compareByX(a, b) {
  return a.x - b.x
}

export function compareByY(a, b) {
  return a.y - b.y
}

export function getEdges(activeObject) {
  const activeOCoords = activeObject.oCoords

  const {
    bl: { x: x1, y: y1 },
    br: { x: x2, y: y2 },
    tl: { x: x3, y: y3 },
    tr: { x: x4, y: y4 },
  } = activeOCoords

  const xArrs = [x1, x2, x3, x4]
  const yArrs = [y1, y2, y3, y4]
  const minX = Math.min(...xArrs)
  const maxX = Math.max(...xArrs)
  const minY = Math.min(...yArrs)
  const maxY = Math.max(...yArrs)

  const width = maxX - minX
  const height = maxY - minY
  const left = minX
  const right = maxX
  const bottom = maxY
  const top = minY

  const return1 = {
    width,
    height,
    left,
    right,
    bottom,
    top,
  }

  // New
  const { bl, br, tl, tr } = activeOCoords

  const coords = [
    { x: bl.x, y: bl.y, id: 'bl' },
    { x: br.x, y: br.y, id: 'br' },
    { x: tl.x, y: tl.y, id: 'tl' },
    { x: tr.x, y: tr.y, id: 'tr' },
  ]

  const xs = [...coords]
  const ys = [...coords]

  xs.sort((a, b) => compareByX(a, b))
  ys.sort((a, b) => compareByY(a, b))
  const minX1 = xs[0]
  const maxX1 = xs[3]
  const minY1 = ys[0]
  const maxY1 = ys[3]
  const minX2 = xs[1]
  const maxX2 = xs[2]
  const minY2 = ys[1]
  const maxY2 = ys[2]

  const left1 = minX1
  const right1 = maxX1
  const bottom1 = maxY1
  const top1 = minY1
  const left2 = minX2
  const right2 = maxX2
  const bottom2 = maxY2
  const top2 = minY2

  const w = Math.sqrt(
    (bottom1.x - bottom2.x) * (bottom1.x - bottom2.x) +
      (bottom1.y - bottom2.y) * (bottom1.y - bottom2.y)
  )
  const h = Math.sqrt(
    (right1.x - right2.x) * (right1.x - right2.x) +
      (right1.y - right2.y) * (right1.y - right2.y)
  )

  const cX = (left1.x + right1.x) / 2
  const cY = (left1.y + right1.y) / 2

  const return2 = {
    w,
    h,
    cX,
    cY,
    left1,
    right1,
    bottom1,
    top1,
    left2,
    right2,
    bottom2,
    top2,
  }

  return { ...return1, ...return2 }
}

export function isOutsideOfCanvas(activeObject, canvasWidth, canvasHeight) {
  const activeOCoords = activeObject.get('oCoords')
  const activeLeft = activeOCoords.ml.x
  const activeRight = activeOCoords.mr.x
  const activeBottom = activeOCoords.mb.y
  const activeTop = activeOCoords.mt.y

  if (
    activeLeft < edgeDetection ||
    activeTop < edgeDetection ||
    activeRight > canvasWidth - edgeDetection ||
    activeBottom > canvasHeight - edgeDetection
  ) {
    return true
  }
  return false
}

export function isOverlap(canvas, activeObject, processed) {
  // loop canvas objects
  let isOverlap = false
  canvas.forEachObject((target) => {
    if (isOverlap) return
    if (target === null) return
    if (target.type === 'line') return
    if (target === activeObject) return // bypass self
    if (target.id === activeObject.id) return
    if (target.id === (processed || {}).id) return

    if (
      activeObject.intersectsWithObject(target) ||
      activeObject.isContainedWithinObject(target) ||
      target.isContainedWithinObject(activeObject)
    ) {
      isOverlap = true
    } else {
      isOverlap = false
    }
  })
  return isOverlap
}

export function isCursorOutside(
  activeLeft,
  activeTop,
  activeRight,
  activeBottom,
  x,
  y
) {
  const halfWidth = (activeRight - activeLeft) / 2
  const halfHeight = (activeBottom - activeTop) / 2
  if (
    x < activeLeft - halfWidth ||
    x > activeRight + halfWidth ||
    y < activeTop - halfHeight ||
    y > activeBottom + halfHeight
  ) {
    return true
  }
  return false
}

export function compileActive(activeObject, icX, icY) {
  const { left, top } = activeObject
  const dx = left - icX
  const dy = top - icY

  const activeOCoords = activeObject.oCoords

  const {
    bl: { x: blX, y: blY },
    br: { x: brX, y: brY },
    tl: { x: tlX, y: tlY },
    tr: { x: trX, y: trY },
  } = activeOCoords

  return {
    ...activeObject,
    oCoords: {
      bl: { x: blX + dx, y: blY + dy },
      br: { x: brX + dx, y: brY + dy },
      tl: { x: tlX + dx, y: tlY + dy },
      tr: { x: trX + dx, y: trY + dy },
    },
  }
}

// export function compileActive(activeWidth, activeHeight, x, y) {
//   return {
//     left: x,
//     top: y,
//     oCoords: {
//       tr: { x: x + activeWidth / 2, y: y - activeHeight / 2 },
//       bl: { x: x - activeWidth / 2, y: y + activeHeight / 2 },
//     },
//   }
// }

function isUndefined(a) {
  return a === undefined
}

export function doObjectsIntersect(o1, o2) {
  const {
    left1: aLeft,
    right1: aRight,
    bottom1: aBottom,
    top1: aTop,
  } = getEdges(o1)
  const {
    left1: bLeft,
    right1: bRight,
    bottom1: bBottom,
    top1: bTop,
  } = getEdges(o2)
  const a = [
    { x: aLeft.x, y: aLeft.y },
    { x: aTop.x, y: aTop.y },
    { x: aRight.x, y: aRight.y },
    { x: aBottom.x, y: aBottom.y },
  ]
  const b = [
    { x: bLeft.x, y: bLeft.y },
    { x: bTop.x, y: bTop.y },
    { x: bRight.x, y: bRight.y },
    { x: bBottom.x, y: bBottom.y },
  ]
  return doPolygonsIntersect(a, b)
}

/**
 * Helper function to determine whether there is an intersection between the two polygons described
 * by the lists of vertices. Uses the Separating Axis Theorem
 *
 * @param a an array of connected points [{x:, y:}, {x:, y:},...] that form a closed polygon
 * @param b an array of connected points [{x:, y:}, {x:, y:},...] that form a closed polygon
 * @return true if there is any intersection between the 2 polygons, false otherwise
 */
function doPolygonsIntersect(a, b) {
  const polygons = [a, b]
  let minA, maxA, projected, i, i1, j, minB, maxB

  for (i = 0; i < polygons.length; i++) {
    // for each polygon, look at each edge of the polygon, and determine if it separates
    // the two shapes
    let polygon = polygons[i]
    for (i1 = 0; i1 < polygon.length; i1++) {
      // grab 2 vertices to create an edge
      let i2 = (i1 + 1) % polygon.length
      let p1 = polygon[i1]
      let p2 = polygon[i2]

      // find the line perpendicular to this edge
      let normal = { x: p2.y - p1.y, y: p1.x - p2.x }

      minA = maxA = undefined
      // for each vertex in the first shape, project it onto the line perpendicular to the edge
      // and keep track of the min and max of these values
      for (j = 0; j < a.length; j++) {
        projected = normal.x * a[j].x + normal.y * a[j].y
        if (isUndefined(minA) || projected < minA) {
          minA = projected
        }
        if (isUndefined(maxA) || projected > maxA) {
          maxA = projected
        }
      }

      // for each vertex in the second shape, project it onto the line perpendicular to the edge
      // and keep track of the min and max of these values
      minB = maxB = undefined
      for (j = 0; j < b.length; j++) {
        projected = normal.x * b[j].x + normal.y * b[j].y
        if (isUndefined(minB) || projected < minB) {
          minB = projected
        }
        if (isUndefined(maxB) || projected > maxB) {
          maxB = projected
        }
      }

      // if there is no overlap between the projects, the edge we are looking at separates the two
      // polygons, and we know there is no overlap
      if (maxA < minB || maxB < minA) {
        // console.log('polygons don't intersect!')
        return false
      }
    }
  }
  return true
}

export function doObjsOverlap(o1, o2) {
  const o1Coords = o1.oCoords
  const o2Coords = o2.oCoords
  const o1Width = o1Coords.tr.x - o1Coords.bl.x
  const o1Height = o1Coords.bl.y - o1Coords.tr.y
  const o2Width = o2Coords.tr.x - o2Coords.bl.x
  const o2Height = o2Coords.bl.y - o2Coords.tr.y
  const o1X = o1.left
  const o1Y = o1.top
  const o2X = o2.left
  const o2Y = o2.top

  const l1 = { x: o1X - o1Width / 2, y: o1Y - o1Height / 2 }
  const r1 = { x: o1X + o1Width / 2, y: o1Y + o1Height / 2 }
  const l2 = { x: o2X - o2Width / 2, y: o2Y - o2Height / 2 }
  const r2 = { x: o2X + o2Width / 2, y: o2Y + o2Height / 2 }
  return doOverlap(l1, r1, l2, r2) ? o2.id : false
}

export function doOverlap(l1, r1, l2, r2) {
  // If one rectangle is on left side of other
  if (l1.x > r2.x || l2.x > r1.x) {
    return false
  }

  // If one rectangle is above other
  if (l1.y > r2.y || l2.y > r1.y) {
    return false
  }
  return true
}

export function isCustomOverlap(canvas, activeObject, processed, intersect) {
  const {
    outFromLeftTop,
    outFromLeftBottom,
    outFromRightBottom,
    outFromRightTop,
  } =
    intersect || {}
  let isOverlap = false
  canvas.forEachObject((target) => {
    if (isOverlap) return
    if (target === null) return
    if (target.type === 'line') return
    if (target === activeObject) return // bypass self
    if (target.id === activeObject.id) return
    if (target.id === (processed || {}).id) return
    //
    // if (target.id === outFromLeftTop) return
    // if (target.id === outFromLeftBottom) return
    // if (target.id === outFromRightBottom) return
    // if (target.id === outFromRightTop) return

    isOverlap = doObjectsIntersect(activeObject, target)
  })
  return isOverlap
}

function getLinesIntersection(x1, y1, x2, y2, x3, y3, x4, y4) {
  const { A: A1, B: B1, C: C1 } = getLineParams(x1, y1, x2, y2)
  const { A: A2, B: B2, C: C2 } = getLineParams(x3, y3, x4, y4)
  return getIntersection(A1, B1, C1, A2, B2, C2)
}

function getIntersection(A1, B1, C1, A2, B2, C2) {
  // Ax + By + C = 0
  return {
    x: -(C1 * B2 - C2 * B1) / (A1 * B2 - A2 * B1),
    y: -(A1 * C2 - A2 * C1) / (A1 * B2 - A2 * B1),
  }
}

function getNormalLineParams(A, B, x0, y0) {
  // Ax + By + C = 0
  return {
    A: -B,
    B: A,
    C: B * x0 - A * y0,
  }
}

function getPointAtDistance(x1, y1, x2, y2, distance) {
  const R12 = Math.sqrt((x2 - x1) * (x2 - x1) + (y2 - y1) * (y2 - y1))
  const k = distance / R12
  const x = x1 + (x2 - x1) * k
  const y = y1 + (y2 - y1) * k
  return {
    x,
    y,
    dx: x - x2,
    dy: y - y2,
  }
}

function getLineParams(x1, y1, x2, y2) {
  // Ax + By + C = 0
  return {
    A: y1 - y2,
    B: x2 - x1,
    C: x1 * y2 - x2 * y1,
  }
}

function getLineX(A, B, C, y) {
  // Ax + By + C = 0
  return (-B * y - C) / A
}

function getLineY(A, B, C, x) {
  // Ax + By + C = 0
  return (-A * x - C) / B
}

function getActDeltas(At, Bt, Ct, Aa, Ba, Ca, actX, actY, gap) {
  const { x: interX, y: interY } = getIntersection(At, Bt, Ct, Aa, Ba, Ca)
  const { dx, dy } = getPointAtDistance(interX, interY, actX, actY, gap)
  return { dx, dy }
}

function getDeltas(At, Bt, Ct, actX, actY, gap) {
  const { A: Aa, B: Ba, C: Ca } = getNormalLineParams(At, Bt, actX, actY)
  const { x: interX, y: interY } = getIntersection(At, Bt, Ct, Aa, Ba, Ca)
  const { dx, dy } = getPointAtDistance(interX, interY, actX, actY, gap)
  return { dx, dy }
}

export function getDeltasPointsDistances(target1, target2, active1, active2) {
  const distanceFromLeftTop1 = distancePointToLine(
    target1.x,
    target1.y,
    target2.x,
    target2.y,
    active1.x,
    active1.y
  )
  const distanceFromLeftTop2 = distancePointToLine(
    target1.x,
    target1.y,
    target2.x,
    target2.y,
    active2.x,
    active2.y
  )
  const { A: At, B: Bt, C: Ct } = getLineParams(
    target1.x,
    target1.y,
    target2.x,
    target2.y
  )
  let activePointX = 0
  let activePointY = 0
  if (distanceFromLeftTop1 < distanceFromLeftTop2) {
    activePointX = active1.x
    activePointY = active1.y
  } else {
    activePointX = active2.x
    activePointY = active2.y
  }
  const { dx, dy } = getDeltas(
    At || 0.1,
    Bt || 0.1,
    Ct,
    activePointX,
    activePointY,
    gap
  )
  return { dx, dy }
}

export function getActDeltasFromPoints(
  target1,
  target2,
  active1,
  active2,
  isActive1,
  active11,
  active22
) {
  const { A: At, B: Bt, C: Ct } = getLineParams(
    target1.x,
    target1.y,
    target2.x,
    target2.y
  )
  let activePointX
  let activePointY
  let activePoint2 // pair to active point
  if (isActive1) {
    activePointX = active1.x
    activePointY = active1.y
    activePoint2 = active11
  } else {
    activePointX = active2.x
    activePointY = active2.y
    activePoint2 = active22
  }
  const { A: Aa, B: Ba, C: Ca } = getLineParams(
    activePointX,
    activePointY,
    activePoint2.x,
    activePoint2.y
  )
  const { dx, dy } = getActDeltas(
    At,
    Bt,
    Ct,
    Aa,
    Ba,
    Ca,
    activePointX,
    activePointY,
    -gap
  )
  return { dx, dy }
}

export function getDeltasFromPoints(
  target1,
  target2,
  active1,
  active2,
  isActive1
) {
  const { A: At, B: Bt, C: Ct } = getLineParams(
    target1.x,
    target1.y,
    target2.x,
    target2.y
  )
  let activePointX
  let activePointY
  if (isActive1) {
    activePointX = active1.x
    activePointY = active1.y
  } else {
    activePointX = active2.x
    activePointY = active2.y
  }
  const { dx, dy } = getDeltas(At, Bt, Ct, activePointX, activePointY, -gap)
  return { dx, dy }
}

export function getScaleParams(
  target1,
  target2,
  active1,
  active2,
  isActive1,
  active11,
  active22
) {
  const { newActive1, newActive2 } = getScaleDeltasFromPoints(
    target1,
    target2,
    active1,
    active2,
    isActive1,
    active11,
    active22
  )
  const activePoint = isActive1 ? newActive1 : newActive2
  const oposideActive = isActive1 ? active22 : active11
  const newCX = (activePoint.x + oposideActive.x) / 2
  const newCY = (activePoint.y + oposideActive.y) / 2
  const activePoint2 = isActive1 ? active11 : active22
  const width = Math.sqrt(
    (activePoint.x - activePoint2.x) * (activePoint.x - activePoint2.x) +
      (activePoint.y - activePoint2.y) * (activePoint.y - activePoint2.y)
  )

  return { newCX, newCY, length: width, aX: activePoint.x, aY: activePoint.y }
}

export function getScaleDeltasFromPoints(
  target1,
  target2,
  active1,
  active2,
  isActive1,
  active11,
  active22
) {
  const { dx, dy } = getActDeltasFromPoints(
    target1,
    target2,
    active1,
    active2,
    isActive1,
    active11,
    active22
  )
  const newActive1 = { x: active1.x + dx, y: active1.y + dy }
  const newActive2 = { x: active2.x + dx, y: active2.y + dy }

  return { newActive1, newActive2 }
}

function distancePointToLine(x1, y1, x2, y2, x0, y0) {
  const numerator = Math.abs(
    (y2 - y1) * x0 - (x2 - x1) * y0 + x2 * y1 - y2 * x1
  )
  const denominator = Math.sqrt((y2 - y1) * (y2 - y1) + (x2 - x1) * (x2 - x1))
  return numerator / denominator
}

function intersectionNormalFromPointToLine(x1, y1, x2, y2, x0, y0) {
  const { A: At, B: Bt, C: Ct } = getLineParams(x1, y1, x2, y2)
  const { A: Aa, B: Ba, C: Ca } = getNormalLineParams(At, Bt, x0, y0)
  return getIntersection(At, Bt, Ct, Aa, Ba, Ca)
}

function allowedDistancePointToLine(x1, y1, x2, y2, x0, y0, from) {
  const { x, y } = intersectionNormalFromPointToLine(x1, y1, x2, y2, x0, y0)
  switch (from) {
    case 'fromLeftTop': {
      if (x0 > x || y0 > y) return undefined
      break
    }
    case 'fromLeftBottom': {
      if (x0 > x || y0 < y) return undefined
      break
    }
    case 'fromRightBottom': {
      if (x0 < x || y0 < y) return undefined
      break
    }
    case 'fromRightTop': {
      if (x0 < x || y0 > y) return undefined
      break
    }
  }

  return distancePointToLine(x1, y1, x2, y2, x0, y0)
}

function getNormalizedAngles(active, target) {
  let { angle: aAngle } = active
  let { angle: tAngle } = target
  while (aAngle > 90) {
    aAngle = aAngle - 90
  }
  while (tAngle > 90) {
    tAngle = tAngle - 90
  }
  return { aAngle, tAngle }
}

function getDistances(
  canvas,
  activeObject,
  sign,
  initialCoords = getInitialCoords({ absolutePointer: {} })
) {
  const {
    left: activeLeft,
    right: activeRight,
    bottom: activeBottom,
    top: activeTop,
    left1: activeLeft1,
    right1: activeRight1,
    bottom1: activeBottom1,
    top1: activeTop1,
    left2: activeLeft2,
    right2: activeRight2,
    bottom2: activeBottom2,
    top2: activeTop2,
  } = getEdges(activeObject)
  const {
    left: initialLeft,
    right: initialRight,
    bottom: initialBottom,
    top: initialTop,
  } = getEdges({ oCoords: initialCoords, get: () => initialCoords })

  // Outer bounds
  const distancesFromLeftTop = [] // [{id, distance}]
  const distancesFromLeftBottom = [] // [{id, distance}]
  const distancesFromRightBottom = [] // [{id, distance}]
  const distancesFromRightTop = [] // [{id, distance}]
  // Outer bounds
  const distancesFromLeft = [] // [{id, distance}]
  const distancesFromRight = [] // [{id, distance}]
  const distancesFromTop = [] // [{id, distance}]
  const distancesFromBottom = [] // [{id, distance}]
  // Inner bounds
  const distancesInFromLeft = [] // [{id, distance}]
  const distancesInFromRight = [] // [{id, distance}]
  const distancesInFromTop = [] // [{id, distance}]
  const distancesInFromBottom = [] // [{id, distance}]

  canvas.forEachObject((target) => {
    if (target === null) return
    if (target.type === 'line') return
    if (target === activeObject) return
    if (target.id === activeObject.id) return
    if (target.selectable === false) return

    const {
      left: targetLeft,
      right: targetRight,
      bottom: targetBottom,
      top: targetTop,
      left1: targetLeft1,
      right1: targetRight1,
      bottom1: targetBottom1,
      top1: targetTop1,
      left2: targetLeft2,
      right2: targetRight2,
      bottom2: targetBottom2,
      top2: targetTop2,
    } = getEdges(target)

    // Outer rotational bounds
    const distanceFromLeftTop1 =
      allowedDistancePointToLine(
        targetLeft1.x,
        targetLeft1.y,
        targetTop1.x,
        targetTop1.y,
        activeRight1.x,
        activeRight1.y,
        'fromLeftTop'
      ) * sign // (x1, y1, x2, y2, x0, y0)
    const distanceFromLeftTop2 =
      allowedDistancePointToLine(
        targetLeft1.x,
        targetLeft1.y,
        targetTop1.x,
        targetTop1.y,
        activeBottom1.x,
        activeBottom1.y,
        'fromLeftTop'
      ) * sign // (x1, y1, x2, y2, x0, y0)
    const distanceFromLeftTop = Math.min(
      distanceFromLeftTop1,
      distanceFromLeftTop2
    )
    if (distanceFromLeftTop >= 0) {
      const isAllowed =
        !isAlmostEqual(activeRight, initialRight) && !isNaN(distanceFromLeftTop)
      if (isAllowed) {
        const value = {
          id: target.id,
          distance: distanceFromLeftTop,
          name: target.name,
        }
        distancesFromLeftTop.splice(
          _.sortedIndexBy(distancesFromLeftTop, value, 'distance'),
          0,
          value
        )
      }
    }
    const distanceFromLeftBottom1 =
      allowedDistancePointToLine(
        targetLeft1.x,
        targetLeft1.y,
        targetBottom1.x,
        targetBottom1.y,
        activeRight1.x,
        activeRight1.y,
        'fromLeftBottom'
      ) * sign // (x1, y1, x2, y2, x0, y0)
    const distanceFromLeftBottom2 =
      allowedDistancePointToLine(
        targetLeft1.x,
        targetLeft1.y,
        targetBottom1.x,
        targetBottom1.y,
        activeTop1.x,
        activeTop1.y,
        'fromLeftBottom'
      ) * sign // (x1, y1, x2, y2, x0, y0)
    const distanceFromLeftBottom = Math.min(
      distanceFromLeftBottom1,
      distanceFromLeftBottom2
    )
    if (distanceFromLeftBottom >= 0) {
      const isAllowed = !isAlmostEqual(activeRight, initialRight)
      if (isAllowed) {
        const value = {
          id: target.id,
          distance: distanceFromLeftBottom,
          name: target.name,
        }
        distancesFromLeftBottom.splice(
          _.sortedIndexBy(distancesFromLeftBottom, value, 'distance'),
          0,
          value
        )
      }
    }
    const distanceFromRightBottom1 =
      allowedDistancePointToLine(
        targetRight1.x,
        targetRight1.y,
        targetBottom1.x,
        targetBottom1.y,
        activeLeft1.x,
        activeLeft1.y,
        'fromRightBottom'
      ) * sign // (x1, y1, x2, y2, x0, y0)
    const distanceFromRightBottom2 =
      allowedDistancePointToLine(
        targetRight1.x,
        targetRight1.y,
        targetBottom1.x,
        targetBottom1.y,
        activeTop1.x,
        activeTop1.y,
        'fromRightBottom'
      ) * sign // (x1, y1, x2, y2, x0, y0)
    const distanceFromRightBottom = Math.min(
      distanceFromRightBottom1,
      distanceFromRightBottom2
    )
    if (distanceFromRightBottom >= 0) {
      const isAllowed = !isAlmostEqual(activeRight, initialRight)
      if (isAllowed) {
        const value = {
          id: target.id,
          distance: distanceFromRightBottom,
          name: target.name,
        }
        distancesFromRightBottom.splice(
          _.sortedIndexBy(distancesFromRightBottom, value, 'distance'),
          0,
          value
        )
      }
    }
    const distanceFromRightTop1 =
      allowedDistancePointToLine(
        targetRight1.x,
        targetRight1.y,
        targetTop1.x,
        targetTop1.y,
        activeLeft1.x,
        activeLeft1.y,
        'fromRightTop'
      ) * sign // (x1, y1, x2, y2, x0, y0)
    const distanceFromRightTop2 =
      allowedDistancePointToLine(
        targetRight1.x,
        targetRight1.y,
        targetTop1.x,
        targetTop1.y,
        activeBottom1.x,
        activeBottom1.y,
        'fromRightTop'
      ) * sign // (x1, y1, x2, y2, x0, y0)
    const distanceFromRightTop = Math.min(
      distanceFromRightTop1,
      distanceFromRightTop2
    )
    if (distanceFromRightTop >= 0) {
      const isAllowed = !isAlmostEqual(activeRight, initialRight)
      if (isAllowed) {
        const value = {
          id: target.id,
          distance: distanceFromRightTop,
          name: target.name,
        }
        distancesFromRightTop.splice(
          _.sortedIndexBy(distancesFromRightTop, value, 'distance'),
          0,
          value
        )
      }
    }

    // Outer bounds
    const distanceFromLeft = (targetLeft - activeRight) * sign
    if (distanceFromLeft >= 0) {
      const isAllowed = !isAlmostEqual(activeRight, initialRight)
      if (isAllowed) {
        const value = {
          id: target.id,
          distance: distanceFromLeft,
          name: target.name,
        }
        distancesFromLeft.splice(
          _.sortedIndexBy(distancesFromLeft, value, 'distance'),
          0,
          value
        )
      }
    }
    const distanceFromRight = (activeLeft - targetRight) * sign
    if (distanceFromRight >= 0) {
      const isAllowed = !isAlmostEqual(activeLeft, initialLeft)
      if (isAllowed) {
        const value = {
          id: target.id,
          distance: distanceFromRight,
          name: target.name,
        }
        distancesFromRight.splice(
          _.sortedIndexBy(distancesFromRight, value, 'distance'),
          0,
          value
        )
      }
    }
    const distanceFromTop = (targetTop - activeBottom) * sign
    if (distanceFromTop >= 0) {
      const isAllowed = !isAlmostEqual(activeBottom, initialBottom)
      if (isAllowed) {
        const value = {
          id: target.id,
          distance: distanceFromTop,
          name: target.name,
        }
        distancesFromTop.splice(
          _.sortedIndexBy(distancesFromTop, value, 'distance'),
          0,
          value
        )
      }
    }
    const distanceFromBottom = (activeTop - targetBottom) * sign
    if (distanceFromBottom >= 0) {
      const isAllowed = !isAlmostEqual(activeTop, initialTop)
      if (isAllowed) {
        const value = {
          id: target.id,
          distance: distanceFromBottom,
          name: target.name,
        }
        distancesFromBottom.splice(
          _.sortedIndexBy(distancesFromBottom, value, 'distance'),
          0,
          value
        )
      }
    }

    // Inner bounds
    const distanceInFromLeft = (targetRight - activeRight) * sign
    if (distanceInFromLeft >= 0) {
      const isAllowed = !isAlmostEqual(activeRight, initialRight)
      if (isAllowed) {
        const value = {
          id: target.id,
          distance: distanceInFromLeft,
          name: target.name,
        }
        distancesInFromLeft.splice(
          _.sortedIndexBy(distancesInFromLeft, value, 'distance'),
          0,
          value
        )
      }
    }
    const distanceInFromRight = (activeLeft - targetLeft) * sign
    if (distanceInFromRight >= 0) {
      const isAllowed = !isAlmostEqual(activeLeft, initialLeft)
      if (isAllowed) {
        const value = {
          id: target.id,
          distance: distanceInFromRight,
          name: target.name,
        }
        distancesInFromRight.splice(
          _.sortedIndexBy(distancesInFromRight, value, 'distance'),
          0,
          value
        )
      }
    }
    const distanceInFromTop = (targetBottom - activeBottom) * sign
    if (distanceInFromTop >= 0) {
      const isAllowed = !isAlmostEqual(activeBottom, initialBottom)
      if (isAllowed) {
        const value = {
          id: target.id,
          distance: distanceInFromTop,
          name: target.name,
        }
        distancesInFromTop.splice(
          _.sortedIndexBy(distancesInFromTop, value, 'distance'),
          0,
          value
        )
      }
    }
    const distanceInFromBottom = (activeTop - targetTop) * sign
    if (distanceInFromBottom >= 0) {
      const isAllowed = !isAlmostEqual(activeTop, initialTop)
      if (isAllowed) {
        const value = {
          id: target.id,
          distance: distanceInFromBottom,
          name: target.name,
        }
        distancesInFromBottom.splice(
          _.sortedIndexBy(distancesInFromBottom, value, 'distance'),
          0,
          value
        )
      }
    }
  })

  return {
    // Outer bounds
    distancesFromLeftTop,
    distancesFromLeftBottom,
    distancesFromRightBottom,
    distancesFromRightTop,
    // Outer bounds
    distancesFromLeft,
    distancesFromRight,
    distancesFromTop,
    distancesFromBottom,
    // Inner bounds
    distancesInFromLeft,
    distancesInFromRight,
    distancesInFromTop,
    distancesInFromBottom,
  }
}

export function getTargetsDistances(
  target,
  activeObject,
  sign,
  initialCoords = getInitialCoords({ absolutePointer: {} })
) {
  const activeOCoords = activeObject.get('oCoords')

  // Outer bounds
  const distancesFromLeft = [] // [{id, distance}]
  const distancesFromRight = [] // [{id, distance}]
  const distancesFromTop = [] // [{id, distance}]
  const distancesFromBottom = [] // [{id, distance}]
  // Inner bounds
  const distancesInFromLeft = [] // [{id, distance}]
  const distancesInFromRight = [] // [{id, distance}]
  const distancesInFromTop = [] // [{id, distance}]
  const distancesInFromBottom = [] // [{id, distance}]

  if (target === null) return
  if (target.type === 'line') return
  if (target === activeObject) return
  if (target.id === activeObject.id) return
  if (target.selectable === false) return

  const targetOCoords = target.get('oCoords')

  // Outer bounds
  const distanceFromLeft = (targetOCoords.tl.x - activeOCoords.tr.x) * sign
  if (distanceFromLeft >= 0) {
    const isAllowed = !isAlmostEqual(activeOCoords.tr.x, initialCoords.tr.x)
    if (isAllowed) {
      const value = { id: target.id, distance: distanceFromLeft }
      distancesFromLeft.splice(
        _.sortedIndexBy(distancesFromLeft, value, 'distance'),
        0,
        value
      )
    }
  }
  const distanceFromRight = (activeOCoords.tl.x - targetOCoords.tr.x) * sign
  if (distanceFromRight >= 0) {
    const isAllowed = !isAlmostEqual(activeOCoords.tl.x, initialCoords.tl.x)
    if (isAllowed) {
      const value = { id: target.id, distance: distanceFromRight }
      distancesFromRight.splice(
        _.sortedIndexBy(distancesFromRight, value, 'distance'),
        0,
        value
      )
    }
  }
  const distanceFromTop = (targetOCoords.tr.y - activeOCoords.br.y) * sign
  if (distanceFromTop >= 0) {
    const isAllowed = !isAlmostEqual(activeOCoords.br.y, initialCoords.br.y)
    if (isAllowed) {
      const value = { id: target.id, distance: distanceFromTop }
      distancesFromTop.splice(
        _.sortedIndexBy(distancesFromTop, value, 'distance'),
        0,
        value
      )
    }
  }
  const distanceFromBottom = (activeOCoords.tr.y - targetOCoords.br.y) * sign
  if (distanceFromBottom >= 0) {
    const isAllowed = !isAlmostEqual(activeOCoords.tr.y, initialCoords.tr.y)
    if (isAllowed) {
      const value = { id: target.id, distance: distanceFromBottom }
      distancesFromBottom.splice(
        _.sortedIndexBy(distancesFromBottom, value, 'distance'),
        0,
        value
      )
    }
  }

  // Inner bounds
  const distanceInFromLeft = (targetOCoords.tr.x - activeOCoords.tr.x) * sign
  if (distanceInFromLeft >= 0) {
    const isAllowed = !isAlmostEqual(activeOCoords.tr.x, initialCoords.tr.x)
    if (isAllowed) {
      const value = { id: target.id, distance: distanceInFromLeft }
      distancesInFromLeft.splice(
        _.sortedIndexBy(distancesInFromLeft, value, 'distance'),
        0,
        value
      )
    }
  }
  const distanceInFromRight = (activeOCoords.tl.x - targetOCoords.tl.x) * sign
  if (distanceInFromRight >= 0) {
    const isAllowed = !isAlmostEqual(activeOCoords.tl.x, initialCoords.tl.x)
    if (isAllowed) {
      const value = { id: target.id, distance: distanceInFromRight }
      distancesInFromRight.splice(
        _.sortedIndexBy(distancesInFromRight, value, 'distance'),
        0,
        value
      )
    }
  }
  const distanceInFromTop = (targetOCoords.br.y - activeOCoords.br.y) * sign
  if (distanceInFromTop >= 0) {
    const isAllowed = !isAlmostEqual(activeOCoords.br.y, initialCoords.br.y)
    if (isAllowed) {
      const value = { id: target.id, distance: distanceInFromTop }
      distancesInFromTop.splice(
        _.sortedIndexBy(distancesInFromTop, value, 'distance'),
        0,
        value
      )
    }
  }
  const distanceInFromBottom = (activeOCoords.tr.y - targetOCoords.tr.y) * sign
  if (distanceInFromBottom >= 0) {
    const isAllowed = !isAlmostEqual(activeOCoords.tr.y, initialCoords.tr.y)
    if (isAllowed) {
      const value = { id: target.id, distance: distanceInFromBottom }
      distancesInFromBottom.splice(
        _.sortedIndexBy(distancesInFromBottom, value, 'distance'),
        0,
        value
      )
    }
  }

  return {
    // Outer bounds
    distancesFromLeft,
    distancesFromRight,
    distancesFromTop,
    distancesFromBottom,
    // Inner bounds
    distancesInFromLeft,
    distancesInFromRight,
    distancesInFromTop,
    distancesInFromBottom,
  }
}

function drawRulers(
  canvas,
  intersect,
  rulers,
  objects,
  canvasWidth,
  canvasHeight
) {
  const keys = Object.keys(intersect)
  keys.map((key) => {
    const tId = intersect[key]
    if (tId) {
      const target = objects.find(({ id }) => id === tId)
      if (target) {
        const {
          left: tLeft,
          right: tRight,
          bottom: tBottom,
          top: tTop,
          left1: tLeft1,
          right1: tRight1,
          bottom1: tBottom1,
          top1: tTop1,
          left2: tLeft2,
          right2: tRight2,
          bottom2: tBottom2,
          top2: tTop2,
        } = getEdges(target)

        switch (key) {
          case 'outFromLeftTop2':
          case 'outFromLeftTop': {
            const { A, B: Bi, C } = getLineParams(
              tLeft1.x,
              tLeft1.y,
              tTop1.x,
              tTop1.y
            )
            const B = Bi === 0 ? 0.1 : Bi
            rulers[key] = makeLine([
              getLineX(A, B, C, 0),
              0,
              0,
              getLineY(A, B, C, 0),
            ])
            break
          }
          case 'outFromLeftBottom2':
          case 'outFromLeftBottom': {
            const { A, B, C } = getLineParams(
              tLeft1.x,
              tLeft1.y,
              tBottom1.x,
              tBottom1.y
            )
            rulers[key] = makeLine([
              getLineX(A, B, C, 0),
              0,
              getLineX(A, B, C, canvasHeight),
              canvasHeight,
            ])
            break
          }
          case 'outFromRightBottom2':
          case 'outFromRightBottom': {
            const { A, B, C } = getLineParams(
              tRight1.x,
              tRight1.y,
              tBottom1.x,
              tBottom1.y
            )
            rulers[key] = makeLine([
              getLineX(A, B, C, 0),
              0,
              0,
              getLineY(A, B, C, 0),
            ])
            break
          }
          case 'outFromRightTop2':
          case 'outFromRightTop': {
            const { A: Ai, B, C } = getLineParams(
              tRight1.x,
              tRight1.y,
              tTop1.x,
              tTop1.y
            )
            const A = Ai === 0 ? 0.1 : Ai
            rulers[key] = makeLine([
              getLineX(A, B, C, 0),
              0,
              getLineX(A, B, C, canvasHeight),
              canvasHeight,
            ])
            break
          }
        }
        target.setShadow({
          color: highlightColor,
          blur: 20,
        })
      }
    }
  })
  //
  Object.values(rulers).forEach((ruler) => {
    if (ruler !== null) {
      canvas.add(ruler)
    }
  })
}

function drawRectLine(canvas, object, color = 'rgba(255,0,0,0.5)') {
  const { left1, right1, bottom1, top1 } = getEdges(object)

  canvas.add(makeLine([left1.x, left1.y, bottom1.x, bottom1.y], color))
  canvas.add(makeLine([bottom1.x, bottom1.y, right1.x, right1.y], color))
  canvas.add(makeLine([right1.x, right1.y, top1.x, top1.y], color))
  canvas.add(makeLine([top1.x, top1.y, left1.x, left1.y], color))
}

function drawRect(canvas, activeObject, color = 'rgba(255,0,0,0.5)') {
  const { left, top } = activeObject
  const { w: width, h: height } = getEdges(activeObject)
  const rect = new fabric.Rect({
    originX: 'center',
    originY: 'center',
    width,
    height,
    left,
    top,
    angle: activeObject.angle,
    fill: color,
    type: 'line',
  })
  //
  canvas.add(rect)
}

function getCorner(options) {
  const { transform: { corner = '' } = {} } = options
  return {
    isRightCorner: corner.includes('r'),
    isLeftCorner: corner.includes('l'),
    isTopCorner: corner.includes('t'),
    isBottomCorner: corner.includes('b'),
  }
}

function makeLine(coords, color = 'rgb(255,0,0)') {
  return new fabric.Line(coords, {
    fill: color,
    stroke: color,
    strokeWidth: 1,
    selectable: false,
    evented: false,
  })
}

function setAll(obj, val) {
  Object.keys(obj).forEach((index) => {
    obj[index] = val
  })
}

export function onModifiedMeasurer(canvas) {
  removeRulers(canvas)
  removeHighlight(canvas)
}

export function removeRulers(canvas) {
  const rulerObjects = canvas.getObjects('line')
  for (let i in rulerObjects) {
    canvas.remove(rulerObjects[i])
  }
}

export function removeHighlight(canvas) {
  const objects = canvas.getObjects()
  objects.map((o) => o.setShadow(null))
}

export function unlockActive(canvas) {
  const activeObject = canvas.getActiveObject()
  if (activeObject) {
    activeObject.set('lockMovementX', false)
    activeObject.set('lockMovementY', false)
  }
}
// Second impl
export function getTargetSize(target) {
  const {
    width: tWidth,
    height: tHeight,
    left: tLeft,
    right: tRight,
    bottom: tBottom,
    top: tTop,
    left1: tLeft1,
    right1: tRight1,
    bottom1: tBottom1,
    top1: tTop1,
    left2: tLeft2,
    right2: tRight2,
    bottom2: tBottom2,
    top2: tTop2,
  } = getEdges(target)

  return {
    tWidth,
    tHeight,
    tLeft,
    tRight,
    tBottom,
    tTop,
    tLeft1,
    tRight1,
    tBottom1,
    tTop1,
    tLeft2,
    tRight2,
    tBottom2,
    tTop2,
  }
}
// First impl
export function getTargetsSize(target) {
  const tOCoords = target.get('oCoords')
  const tWidth = tOCoords.tr.x - tOCoords.tl.x
  const tHeight = tOCoords.br.y - tOCoords.tl.y

  const tLeft = tOCoords.ml.x
  const tRight = tOCoords.mr.x
  const tBottom = tOCoords.mb.y
  const tTop = tOCoords.mt.y

  return { tWidth, tHeight, tLeft, tRight, tBottom, tTop }
}

export function getInitialCoords(o) {
  const { absolutePointer } = o
  return {
    bl: absolutePointer,
    br: absolutePointer,
    mb: absolutePointer,
    ml: absolutePointer,
    mr: absolutePointer,
    mt: absolutePointer,
    mtr: absolutePointer,
    tl: absolutePointer,
    tr: absolutePointer,
  }
}

export function isIntersectHandled(intersect) {
  const {
    outFromLeftTop,
    outFromLeftBottom,
    outFromRightBottom,
    outFromRightTop,
  } = intersect
  if (
    outFromLeftTop ||
    outFromLeftBottom ||
    outFromRightBottom ||
    outFromRightTop
  ) {
    return true
  }
  return false
}

export function onScaleNewOverlap(
  canvas,
  options,
  activeObject,
  canvasWidth,
  canvasHeight,
  initialCoords,
  isScale
) {
  const {
    w,
    h,
    cX,
    cY,
    width: activeWidth,
    height: activeHeight,
    left: activeLeft,
    right: activeRight,
    bottom: activeBottom,
    top: activeTop,
    left1: activeLeft1,
    right1: activeRight1,
    bottom1: activeBottom1,
    top1: activeTop1,
    left2: activeLeft2,
    right2: activeRight2,
    bottom2: activeBottom2,
    top2: activeTop2,
  } = getEdges(activeObject)

  const intersect = {
    outFromLeftTop: null,
    outFromLeftBottom: null,
    outFromRightBottom: null,
    outFromRightTop: null,
  }

  let processed
  // loop canvas objects
  canvas.forEachObject((target) => {
    if (target === null) return
    if (target.type === 'line') return
    if (target === activeObject) return // bypass self
    if (target.id === activeObject.id) return

    // check intersections with every object in canvas
    if (
      activeObject.intersectsWithObject(target) ||
      activeObject.isContainedWithinObject(target) ||
      target.isContainedWithinObject(activeObject)
    ) {
      const {
        // w,
        // h,
        cX: tCx,
        cY: tCy,
        width: targetWidth,
        height: targetHeight,
        left: targetLeft,
        right: targetRight,
        bottom: targetBottom,
        top: targetTop,
        left1: targetLeft1,
        right1: targetRight1,
        bottom1: targetBottom1,
        top1: targetTop1,
        left2: targetLeft2,
        right2: targetRight2,
        bottom2: targetBottom2,
        top2: targetTop2,
      } = getEdges(target)

      const { aAngle, tAngle } = getNormalizedAngles(activeObject, target)

      const { pointer: { x, y } } = options

      const dLeftTopCenter = distancePointToLine(
        targetLeft1.x,
        targetLeft1.y,
        targetTop1.x,
        targetTop1.y,
        cX,
        cX
      )
      const dLeftBottomCenter = distancePointToLine(
        targetBottom1.x,
        targetBottom1.y,
        targetLeft1.x,
        targetLeft1.y,
        cX,
        cY
      )
      const dRightBottomCenter = distancePointToLine(
        targetRight1.x,
        targetRight1.y,
        targetBottom1.x,
        targetBottom1.y,
        cX,
        cY
      )
      const dRightTopCenter = distancePointToLine(
        targetTop1.x,
        targetTop1.y,
        targetRight1.x,
        targetRight1.y,
        cX,
        cY
      )

      const dLeftTop = distancePointToLine(
        targetLeft1.x,
        targetLeft1.y,
        targetTop1.x,
        targetTop1.y,
        x,
        y
      )
      const dLeftBottom = distancePointToLine(
        targetBottom1.x,
        targetBottom1.y,
        targetLeft1.x,
        targetLeft1.y,
        x,
        y
      )
      const dRightBottom = distancePointToLine(
        targetRight1.x,
        targetRight1.y,
        targetBottom1.x,
        targetBottom1.y,
        x,
        y
      )
      const dRightTop = distancePointToLine(
        targetTop1.x,
        targetTop1.y,
        targetRight1.x,
        targetRight1.y,
        x,
        y
      )

      //cX cX
      // console.log('dLeftTopCenter', dLeftTopCenter)
      // console.log('dLeftBottomCenter', dLeftBottomCenter)
      // console.log('dRightBottomCenter', dRightBottomCenter)
      // console.log('dRightTopCenter', dRightTopCenter)

      const isFromTop = dRightBottomCenter < dLeftBottomCenter

      if (!isFromTop) {
        // from left top
        console.log('// from left top')
        const { newCX, newCY, length, aX, aY } = getScaleParams(
          targetLeft1,
          targetTop1,
          activeRight1,
          activeBottom1,
          aAngle > tAngle,
          activeTop1,
          activeLeft1
        )
        setNewScale(activeObject, newCX, newCY, length, undefined, isScale)
        if (intersect.outFromLeftTop) {
          intersect.outFromLeftTop2 = target.id
        } else {
          intersect.outFromLeftTop = target.id
        }

        processed = target
      } else {
        // isFromTop
        // from right top
        console.log('// from right top')
        const { newCX, newCY, length } = getScaleParams(
          targetRight1,
          targetTop1,
          activeBottom1,
          activeLeft1,
          aAngle > tAngle,
          activeRight1,
          activeTop1
        )
        setNewScale(activeObject, newCX, newCY, undefined, length, isScale)
        if (intersect.outFromRightTop) {
          intersect.outFromRightTop2 = target.id
        } else {
          intersect.outFromRightTop = target.id
        }
        processed = target
      }
    }
  })

  const objects = canvas.getObjects()
  drawRulers(canvas, intersect, rulers, objects, canvasWidth, canvasHeight)
  return intersect
}

export function printActiveObject(activeObject) {
  const { top, left, width, height, scaleX, scaleY } = activeObject
  console.warn('-----ActiveObject-----')
  console.warn('left', left, 'top', top)
  console.warn('width', width, 'height', height)
  console.warn('scaleX', scaleX, 'scaleY', scaleY)
}

function setNewScale(activeObject, newCX, newCY, width, height) {
  if (activeObject.scaleX !== 1 || activeObject.scaleY !== 1) {
    if (width) {
      const fixedScaleX = width / originFixtureRectWidth
      activeObject.set({
        scaleX: fixedScaleX,
      })
    } else {
      const fixedScaleY = height / originFixtureRectWidth
      activeObject.set({
        scaleY: fixedScaleY,
      })
    }
  } else {
    if (width) {
      if (activeObject.width > width) activeObject.set({ width })
    } else {
      if (activeObject.height > height) activeObject.set({ height })
    }
  }
}

function getSetScale(
  activeObject,
  initialCoords,
  tX,
  tY,
  aX1,
  aY1,
  a,
  aX2,
  aY2
) {
  const { newCX, newCY, length } = getScaleParams(tX, tY, aX1, aY1, a, aX2, aY2)
  setScale(activeObject, newCX, newCY, length, undefined, initialCoords)
}

function setScale(activeObject, newCX, newCY, length, unused, initialCoords) {
  const { scaleX, scaleY } = activeObject
  const { scaleX: icScaleX, scaleY: icScaleY } = initialCoords

  if (scaleX > 1 && scaleX !== icScaleX) {
    const { width: icWidth, height: icHeight } = getSize(initialCoords)

    if (length > icWidth) {
      activeObject.set({
        left: newCX,
        top: newCY,
        width: length,
        scaleX: 1,
      })
    } else {
      const { x: icX, y: icY } = getCenter(initialCoords)
      activeObject.set({
        left: icX,
        top: icY,
        width: icWidth,
        height: icHeight,
        scaleX: 1,
        scaleY: 1,
      })
    }
  }

  if (scaleY > 1 && scaleY !== icScaleY) {
    const { width: icWidth, height: icHeight } = getSize(initialCoords)

    if (length > icHeight) {
      activeObject.set({
        left: newCX,
        top: newCY,
        height: length,
        scaleY: 1,
      })
    } else {
      const { x: icX, y: icY } = getCenter(initialCoords)
      activeObject.set({
        left: icX,
        top: icY,
        width: icWidth,
        height: icHeight,
        scaleX: 1,
        scaleY: 1,
      })
    }
  }
}

export function normalCorner({ angle }, corner) {
  if (45 <= angle && angle < 135) {
    if (corner === 'mt') return 'mr'
    if (corner === 'mr') return 'mb'
    if (corner === 'mb') return 'ml'
    if (corner === 'ml') return 'mt'
  } else if (135 <= angle && angle < 225) {
    if (corner === 'mt') return 'mb'
    if (corner === 'mr') return 'ml'
    if (corner === 'mb') return 'mt'
    if (corner === 'ml') return 'mr'
  } else if (225 <= angle && angle < 315) {
    if (corner === 'mt') return 'ml'
    if (corner === 'mr') return 'mt'
    if (corner === 'mb') return 'mr'
    if (corner === 'ml') return 'mb'
  }
  return corner
}

let currentSide
export function onScaleOverlap(
  canvas,
  options,
  activeObject,
  canvasWidth,
  canvasHeight,
  initialCoords,
  isScale
) {
  const corner = normalCorner(activeObject, options.transform.corner)

  const {
    w,
    h,
    cX,
    cY,
    width: activeWidth,
    height: activeHeight,
    left: activeLeft,
    right: activeRight,
    bottom: activeBottom,
    top: activeTop,
    left1: activeLeft1,
    right1: activeRight1,
    bottom1: activeBottom1,
    top1: activeTop1,
    left2: activeLeft2,
    right2: activeRight2,
    bottom2: activeBottom2,
    top2: activeTop2,
  } = getEdges(activeObject)

  const intersect = {
    outFromLeftTop: null,
    outFromLeftBottom: null,
    outFromRightBottom: null,
    outFromRightTop: null,
  }

  let processed
  // loop canvas objects
  canvas.forEachObject((target) => {
    if (target === null) return
    if (target.type === 'line') return
    if (target === activeObject) return // bypass self
    if (target.id === activeObject.id) return

    // check intersections with every object in canvas
    if (
      activeObject.intersectsWithObject(target) ||
      activeObject.isContainedWithinObject(target) ||
      target.isContainedWithinObject(activeObject)
    ) {
      const {
        // w,
        // h,
        cX: tCx,
        cY: tCy,
        width: targetWidth,
        height: targetHeight,
        left: targetLeft,
        right: targetRight,
        bottom: targetBottom,
        top: targetTop,
        left1: targetLeft1,
        right1: targetRight1,
        bottom1: targetBottom1,
        top1: targetTop1,
        left2: targetLeft2,
        right2: targetRight2,
        bottom2: targetBottom2,
        top2: targetTop2,
      } = getEdges(target)

      const { aAngle, tAngle } = getNormalizedAngles(activeObject, target)

      const { pointer: { x, y } } = options

      const dLeftTopCenter = distancePointToLine(
        targetLeft1.x,
        targetLeft1.y,
        targetTop1.x,
        targetTop1.y,
        cX,
        cX
      )
      const dLeftBottomCenter = distancePointToLine(
        targetBottom1.x,
        targetBottom1.y,
        targetLeft1.x,
        targetLeft1.y,
        cX,
        cY
      )
      const dRightBottomCenter = distancePointToLine(
        targetRight1.x,
        targetRight1.y,
        targetBottom1.x,
        targetBottom1.y,
        cX,
        cY
      )
      const dRightTopCenter = distancePointToLine(
        targetTop1.x,
        targetTop1.y,
        targetRight1.x,
        targetRight1.y,
        cX,
        cY
      )

      const dLeftTop = distancePointToLine(
        targetLeft1.x,
        targetLeft1.y,
        targetTop1.x,
        targetTop1.y,
        x,
        y
      )
      const dLeftBottom = distancePointToLine(
        targetBottom1.x,
        targetBottom1.y,
        targetLeft1.x,
        targetLeft1.y,
        x,
        y
      )
      const dRightBottom = distancePointToLine(
        targetRight1.x,
        targetRight1.y,
        targetBottom1.x,
        targetBottom1.y,
        x,
        y
      )
      const dRightTop = distancePointToLine(
        targetTop1.x,
        targetTop1.y,
        targetRight1.x,
        targetRight1.y,
        x,
        y
      )

      const iLeftTop = getLinesIntersection(
        targetLeft1.x,
        targetLeft1.y,
        targetTop1.x,
        targetTop1.y,
        tCx,
        tCy,
        x,
        y
      )
      const iLeftBottom = getLinesIntersection(
        targetBottom1.x,
        targetBottom1.y,
        targetLeft1.x,
        targetLeft1.y,
        tCx,
        tCy,
        x,
        y
      )
      const iRightBottom = getLinesIntersection(
        targetRight1.x,
        targetRight1.y,
        targetBottom1.x,
        targetBottom1.y,
        tCx,
        tCy,
        x,
        y
      )
      const iRightTop = getLinesIntersection(
        targetTop1.x,
        targetTop1.y,
        targetRight1.x,
        targetRight1.y,
        tCx,
        tCy,
        x,
        y
      )

      // const minLength = Math.min(...[dLeftTopCenter, dLeftBottomCenter, dRightBottomCenter, dRightTopCenter])

      const { y: tTy } = targetTop1
      const { y: tLy } = targetLeft1
      const { y: tBy } = targetBottom1
      const { y: tRy } = targetRight1

      const isLeftTop = tTy < iLeftTop.y && iLeftTop.y < tLy
      const isLeftBottom =
        (tLy < iLeftBottom.y && iLeftBottom.y < tBy) ||
        (isAlmostEqual(tLy, iLeftBottom.y) && isAlmostEqual(iLeftBottom.y, tBy))
      const isRightBottom = tRy < iRightBottom.y && iRightBottom.y < tBy
      const isRightTop =
        (tTy < iRightTop.y && iRightTop.y < tRy) ||
        (isAlmostEqual(tTy, iRightTop.y) && isAlmostEqual(iRightTop.y, tRy))

      let side
      if (isLeftTop && isRightBottom) {
        if (dLeftTop < dRightBottom) {
          side = 'isLeftTop'
        } else {
          side = 'isRightBottom'
        }
      } else if (isLeftBottom && isRightTop) {
        if (dLeftBottom < dRightTop) {
          side = 'isLeftBottom'
        } else {
          side = 'isRightTop'
        }
      }
      currentSide = !!currentSide ? currentSide : side
      const interSide =
        !!currentSide && currentSide !== side ? currentSide : side

      const aO = activeObject
      const iC = initialCoords
      const a = aAngle > tAngle
      console.log('corner', corner, 'aAngle', aAngle)
      // Outer bounds
      switch (interSide) {
        case 'isLeftTop': {
          // from left top
          console.log('// from left top')
          if (corner === 'mb') {
            if (aAngle < 45) {
              console.warn('aAngle < 45')
              getSetScale(
                aO,
                iC,
                targetLeft1,
                targetTop1,
                activeLeft1,
                activeBottom1,
                a,
                activeTop1,
                activeRight1
              )
            } else {
              getSetScale(
                aO,
                iC,
                targetLeft1,
                targetTop1,
                activeRight1,
                activeBottom1,
                a,
                activeTop1,
                activeLeft1
              )
            }
          } else if (corner === 'mt') {
            if (aAngle < 45) {
              console.warn('aAngle < 45')
              getSetScale(
                aO,
                iC,
                targetLeft1,
                targetTop1,
                activeRight1,
                activeTop1,
                a,
                activeBottom1,
                activeLeft1
              )
            }
          } else if (corner === 'ml') {
            if (!(aAngle < 45)) {
              getSetScale(
                aO,
                iC,
                targetLeft1,
                targetTop1,
                activeLeft1,
                activeBottom1,
                a,
                activeTop1,
                activeRight1
              )
            }
          } else {
            if (aAngle < 45) {
              getSetScale(
                aO,
                iC,
                targetLeft1,
                targetTop1,
                activeRight1,
                activeBottom1,
                a,
                activeTop1,
                activeLeft1
              )
            } else {
              getSetScale(
                aO,
                iC,
                targetLeft1,
                targetTop1,
                activeRight1,
                activeTop1,
                a,
                activeBottom1,
                activeLeft1
              )
            }
          }
          intersect.outFromLeftTop = target.id
          processed = target
          break
        }

        case 'isLeftBottom': {
          // from left bottom
          console.log('// from left bottom')
          if (corner === 'mt') {
            if (aAngle < 45) {
              console.log('aAngle < 45')
              getSetScale(
                aO,
                iC,
                targetBottom1,
                targetLeft1,
                activeTop1,
                activeRight1,
                a,
                activeLeft1,
                activeBottom1
              )
            } else {
              getSetScale(
                aO,
                iC,
                targetBottom1,
                targetLeft1,
                activeTop1,
                activeLeft1,
                a,
                activeRight1,
                activeBottom1
              )
            }
          } else if (corner === 'ml') {
            if (aAngle < 45) {
              console.warn('aAngle < 45')
              getSetScale(
                aO,
                iC,
                targetBottom1,
                targetLeft1,
                activeTop1,
                activeLeft1,
                a,
                activeRight1,
                activeBottom1
              )
            } else {
              getSetScale(
                aO,
                iC,
                targetBottom1,
                targetLeft1,
                activeTop1,
                activeRight1,
                a,
                activeLeft1,
                activeBottom1
              )
            }
          } else if (corner === 'mb') {
            if (!(aAngle < 45)) {
              getSetScale(
                aO,
                iC,
                targetBottom1,
                targetLeft1,
                activeBottom1,
                activeRight1,
                a,
                activeLeft1,
                activeTop1
              )
            }
          } else {
            if (aAngle < 45) {
              getSetScale(
                aO,
                iC,
                targetBottom1,
                targetLeft1,
                activeBottom1,
                activeRight1,
                a,
                activeLeft1,
                activeTop1
              )
            } else {
              getSetScale(
                aO,
                iC,
                targetBottom1,
                targetLeft1,
                activeTop1,
                activeRight1,
                a,
                activeLeft1,
                activeBottom1
              )
            }
          }
          intersect.outFromLeftBottom = target.id
          processed = target
          break
        }

        case 'isRightBottom': {
          // from right bottom
          console.log('// from right bottom')
          if (corner === 'mt') {
            if (aAngle < 45) {
              console.warn('aAngle < 45')
              getSetScale(
                aO,
                iC,
                targetBottom1,
                targetRight1,
                activeRight1,
                activeTop1,
                a,
                activeBottom1,
                activeLeft1
              )
            } else {
              getSetScale(
                aO,
                iC,
                targetBottom1,
                targetRight1,
                activeLeft1,
                activeTop1,
                a,
                activeBottom1,
                activeRight1
              )
            }
          } else if (corner === 'mb') {
            if (aAngle < 45) {
              console.warn('aAngle < 45')
              getSetScale(
                aO,
                iC,
                targetBottom1,
                targetRight1,
                activeLeft1,
                activeBottom1,
                a,
                activeTop1,
                activeRight1
              )
            } else {
              getSetScale(
                aO,
                iC,
                targetBottom1,
                targetRight1,
                activeLeft1,
                activeBottom1,
                a,
                activeTop1,
                activeRight1
              )
            }
          } else if (corner === 'mr') {
            if (!(aAngle < 45)) {
              getSetScale(
                aO,
                iC,
                targetBottom1,
                targetRight1,
                activeRight1,
                activeTop1,
                a,
                activeBottom1,
                activeLeft1
              )
            }
          } else {
            if (aAngle < 45) {
              getSetScale(
                aO,
                iC,
                targetBottom1,
                targetRight1,
                activeLeft1,
                activeTop1,
                a,
                activeBottom1,
                activeRight1
              )
            } else {
              getSetScale(
                aO,
                iC,
                targetBottom1,
                targetRight1,
                activeLeft1,
                activeBottom1,
                a,
                activeTop1,
                activeRight1
              )
            }
          }
          intersect.outFromRightBottom = target.id
          processed = target
          break
        }
        case 'isRightTop': {
          // from right top
          console.log('// from right top')
          if (corner === 'mb') {
            if (aAngle < 45) {
              console.log('aAngle < 45')
              getSetScale(
                aO,
                iC,
                targetRight1,
                targetTop1,
                activeBottom1,
                activeLeft1,
                a,
                activeRight1,
                activeTop1
              )
            } else {
              getSetScale(
                aO,
                iC,
                targetRight1,
                targetTop1,
                activeBottom1,
                activeRight1,
                a,
                activeLeft1,
                activeTop1
              )
            }
          } else if (corner === 'mr') {
            if (aAngle < 45) {
              getSetScale(
                aO,
                iC,
                targetRight1,
                targetTop1,
                activeBottom1,
                activeRight1,
                a,
                activeLeft1,
                activeTop1
              )
            } else {
              getSetScale(
                aO,
                iC,
                targetRight1,
                targetTop1,
                activeBottom1,
                activeLeft1,
                a,
                activeRight1,
                activeTop1
              )
            }
          } else if (corner === 'mt') {
            if (!(aAngle < 45)) {
              getSetScale(
                aO,
                iC,
                targetRight1,
                targetTop1,
                activeTop1,
                activeLeft1,
                a,
                activeRight1,
                activeBottom1
              )
            }
          } else {
            if (aAngle < 45) {
              getSetScale(
                aO,
                iC,
                targetRight1,
                targetTop1,
                activeTop1,
                activeLeft1,
                a,
                activeRight1,
                activeBottom1
              )
            } else {
              getSetScale(
                aO,
                iC,
                targetRight1,
                targetTop1,
                activeBottom1,
                activeLeft1,
                a,
                activeRight1,
                activeTop1
              )
            }
          }
          intersect.outFromRightTop = target.id
          processed = target
          break
        }
      }
    }
  })
  currentSide = !!processed ? currentSide : undefined
  const objects = canvas.getObjects()
  drawRulers(canvas, intersect, rulers, objects, canvasWidth, canvasHeight)
  return processed
}

export function printDistances(distances) {
  const {
    // Outer bounds
    distancesFromLeft,
    distancesFromRight,
    distancesFromTop,
    distancesFromBottom,
  } = distances
  const distanceFromLeft = distancesFromLeft[0] || {}
  const distanceFromRight = distancesFromRight[0] || {}
  const distanceFromTop = distancesFromTop[0] || {}
  const distanceFromBottom = distancesFromBottom[0] || {}

  console.warn('from >>left', distanceFromLeft.name, distanceFromLeft.distance)
  console.warn(
    'from >right',
    distanceFromRight.name,
    distanceFromRight.distance
  )
  console.warn('from >>>top', distanceFromTop.name, distanceFromTop.distance)
  console.warn(
    'from bottom',
    distanceFromBottom.name,
    distanceFromBottom.distance
  )
}

function area(x1, y1, x2, y2, x3, y3) {
  return Math.abs((x1 * (y2 - y3) + x2 * (y3 - y1) + x3 * (y1 - y2)) / 2.0)
}

function isInside(x1, y1, x2, y2, x3, y3, x, y) {
  const A = area(x1, y1, x2, y2, x3, y3)

  const A1 = area(x, y, x2, y2, x3, y3)

  const A2 = area(x1, y1, x, y, x3, y3)

  const A3 = area(x1, y1, x2, y2, x, y)

  return A < A1 + A2 + A3 + 0.01 && A > A1 + A2 + A3 - 0.01
}

function isAlmostEqual(a, b) {
  return a < b + 0.01 && a > b - 0.01
}

export function isDeltaEqual(a, b, delta) {
  return a < b + delta && a > b - delta
}

function getBorders(activeOCoords) {
  const xs = []
  const ys = []
  Object.keys(activeOCoords).forEach((key) => {
    if (key === 'mtr') return
    const { x, y } = activeOCoords[key]
    xs.push(x)
    ys.push(y)
  })
  return {
    left: Math.min(...xs),
    top: Math.min(...ys),
    right: Math.max(...xs),
    bottom: Math.max(...ys),
  }
}

function getCenter(coords) {
  const { ml, mr, mt, mb } = coords
  const x = (ml.x + mr.x) / 2
  const y = (mt.y + mb.y) / 2
  return { x, y }
}

function distancePointToPoint(x1, y1, x2, y2) {
  const a = x1 - x2
  const b = y1 - y2
  return Math.sqrt(a * a + b * b)
}

function getSize(coords) {
  const { ml, mr, mt, mb } = coords
  const width = distancePointToPoint(ml.x, ml.y, mr.x, mr.y)
  const height = distancePointToPoint(mt.x, mt.y, mb.x, mb.y)
  return { width, height }
}
