import { createSelector } from 'reselect'
import { formatUserData } from './users'

const selectApp = state => state.get('app')
const selectUserSection = createSelector(
  selectApp, app => app.get('users')
)

const makeSelectUsers = () =>
  createSelector([selectApp, selectUserSection], (app, users) => {
    if (users) {
      const regions = app.get('regions')
      return formatUserData({ ...users, ...{ regions } })
    } else {
      return null
    }
  })

const usersSelector = makeSelectUsers()

const makeSelectFailed = () =>
  createSelector(selectApp, app => app.get('usersFailed'))

const failedSelector = makeSelectFailed()

const makeSelectLoading = () =>
  createSelector(selectApp, app => app.get('usersLoading'))

const loadingSelector = makeSelectLoading()

const metaSelector =
  createSelector(selectUserSection, users => (users && users['meta']) || {
    currentPage: 1,
    perPage: 20,
    total: 0,
  })

export {
  selectApp,
  makeSelectUsers,
  usersSelector,
  makeSelectFailed,
  failedSelector,
  makeSelectLoading,
  loadingSelector,
  metaSelector,
}
