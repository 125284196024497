/*
 * HomePage
 *
 * This is the first thing users see of our App, at the '/' route
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { selectIsMTIUser, selectIsCorporateUser } from '../App/selectors'
import MtiAdmin from './MtiAdmin'
import CorporateUserPage from './CorporateUser'
import StoreManagerOrStaff from './StoreManagerOrStaff'

export class HomePage extends React.PureComponent {
  checkUserRole = () => {
    const {
      isMTIUser,
      isCorporateUser,
    } = this.props

    if (isMTIUser) {
      return <MtiAdmin history={this.props.history} />
    }
    if (isCorporateUser) {
      return <CorporateUserPage history={this.props.history} />
    }
    return <StoreManagerOrStaff history={this.props.history} />
  }

  render() {
    const homeScreens = this.checkUserRole()

    return (
      <div className="Home-Page" style={{ flex: 1 }}>
        {homeScreens}
      </div>
    )
  }
}

HomePage.propTypes = {
  history: PropTypes.any,
  isMTIUser: PropTypes.bool,
  showCorporateDashboard: PropTypes.bool,
}

const mapStateToProps = createStructuredSelector({
  isMTIUser: selectIsMTIUser,
  isCorporateUser: selectIsCorporateUser,
})

export default connect(mapStateToProps)(HomePage)
