import React from 'react'
import PropTypes from 'prop-types'
import SecuredProductDetailsScreen from './index.screen'

function SecuredProductDetails({
  id,
  securedProduct,
  title,
  confirmText,
  onConfirm,
  onClose,
}) {
  return (
    <SecuredProductDetailsScreen
      id={id}
      securedProduct={securedProduct}
      title={title}
      confirmText={confirmText}
      onConfirm={onConfirm}
      onClose={onClose}
    />
  )
}

SecuredProductDetails.propTypes = {
  id: PropTypes.string.isRequired,
  securedProduct: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  confirmText: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
}

export default SecuredProductDetails
