import React from 'react'
import PropTypes from 'prop-types'

const NotifyModal = ({ id, notifyText, confirmText, onClose }) => (
  <div className="modal-content">
    <div className="modal-header">
      <button
        type="button"
        className="close"
        aria-label="Close"
        onClick={() => {
          $(`#modal-${id}`).modal('hide') // eslint-disable-line
          onClose()
        }}
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <span style={{ whiteSpace: 'pre-line' }} className="modal-body">
      {notifyText}
    </span>
    <div className="modal-footer">
      {confirmText && (
        <button
          type="button"
          className="btn btn-primary btn-raised"
          onClick={() => {
            $(`#modal-${id}`).modal('hide') // eslint-disable-line
            onClose()
          }}
        >
          {confirmText}
        </button>
      )}
    </div>
  </div>
)

NotifyModal.propTypes = {
  id: PropTypes.string.isRequired,
  notifyText: PropTypes.string.isRequired,
  confirmText: PropTypes.string,
  onClose: PropTypes.func,
}

export default NotifyModal
