import { ActionTypes } from '../App/constants'

/**
 * Load Secured Products
 *
 * @returns {Object}
 */
export function loadSecuredProducts(sId) {
  return {
    type: ActionTypes.LOAD_SECURED_PRODUCTS,
    payload: { sId },
  }
}

/**
 * Pending Fetch Secured Products
 *
 * @returns {Object}
 */
export function fetchSecuredProductsPending(sId) {
  return {
    type: ActionTypes.FETCH_SECURED_PRODUCTS_PENDING,
    payload: { sId },
  }
}

/**
 * Fulfilled Fetch Secured Products
 *
 * @returns {Object}
 */
export function fetchSecuredProductsFulfilled() {
  return {
    type: ActionTypes.FETCH_SECURED_PRODUCTS_FULFILLED,
  }
}

/**
 * Failed Fetch Secured Product
 *
 * @returns {Object}
 */
export function fetchSecuredProductsFailed(error) {
  return {
    type: ActionTypes.FETCH_SECURED_PRODUCTS_FAILED,
    payload: error,
  }
}
