/**
 *
 * PromptTable
 *
 */

import React from 'react'
import { renderToString } from 'react-dom/server'
import PropTypes from 'prop-types'
import uuid from 'uuid'
import styles from '../../styles/App.css'

class PromptTable extends React.PureComponent {
  moreRef = null

  componentWillUnmount() {
    if (this.moreRef && this.moreRef.popover) {
      this.moreRef.popover('dispose')
    }
  }

  popoverForExtraLines = (ref, html) => {
    if (!this.moreRef || !this.moreRef.popover) {
      this.moreRef = $(ref) // eslint-disable-line
    }

    this.moreRef.popover('dispose').popover({
      placement: 'bottom',
      trigger: 'hover focus',
      html: true,
      content: html,
    })
  }

  render() {
    const { data } = this.props
    const isShowMore = data.length > 3
    const visibleRows = isShowMore ? data.slice(0, 3) : data
    const invisibleRows = isShowMore ? data.slice(3) : []

    const table = (data) => (
      <table className="table-borderless">
        <tbody>
          {data.map((row) => (
            <tr key={uuid.v4()}>
              <td className={styles.promptTdLeft}>{row.func}&nbsp;</td>
              <td className={styles.promptTdRight}>
                {'-'}&nbsp;&nbsp;{row.desc}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    )
    const invisibleRowsHtml = renderToString(table(invisibleRows))
    return (
      <div className={styles.promptTableContainer}>
        {table(visibleRows)}
        <div
          ref={(ref) => {
            this.popoverForExtraLines(ref, invisibleRowsHtml)
          }}
        >
          {isShowMore && <a href="#">Show more</a>}
        </div>
      </div>
    )
  }
}

PromptTable.propTypes = {
  data: PropTypes.array,
}

PromptTable.defaultProps = {
  data: [],
}

export default PromptTable
