import _ from 'lodash'
import { fabric } from 'fabric'
import {
  originFixtureRectHeight,
  originFixtureRectWidth,
} from '../AreaCanvas/sizeUtils'

const edgeDetection = 8 // pixels to snap
const gap = 2 // gap in pixels
const highlightColor = 'rgb(91,35,138)'

const rulers = {
  outLineFromLeft: null,
  outLineFromRight: null,
  outLineFromTop: null,
  outLineFromBottom: null,
  inLineFromLeft: null,
  inLineFromRight: null,
  inLineFromTop: null,
  inLineFromBottom: null,
}

export function onScaleNewMeasurer(
  options,
  canvas,
  canvasWidth,
  canvasHeight,
  initialCoords,
  isScale
) {
  const obj = options.target
  obj.setCoords() //Sets corner position coordinates based on current angle, width and height

  const activeObject = obj // canvas.getActiveObject()
  if (!activeObject) return
  const activeOCoords = activeObject.get('oCoords')

  const intersect = {
    outFromLeft: null,
    outFromRight: null,
    outFromTop: null,
    outFromBottom: null,
    inFromLeft: null,
    inFromRight: null,
    inFromTop: null,
    inFromBottom: null,
  }

  // Canvas border detection
  if (activeOCoords.tl.x < edgeDetection) {
    // from right
    const targetRightBorderX = 0
    const width = initialCoords.tr.x - targetRightBorderX
    const left = targetRightBorderX
    if (isScale) {
      const fixedScaleX = width / originFixtureRectWidth
      activeObject.set({
        left,
        scaleX: fixedScaleX,
      })
    } else {
      activeObject.set({
        left,
        width,
        scaleX: 1,
      })
    }
    intersect.outFromRight = -1
  }
  if (activeOCoords.tl.y < edgeDetection) {
    // from bottom
    const targetBottomBorderY = 0
    const height = initialCoords.br.y - targetBottomBorderY
    const top = targetBottomBorderY
    if (isScale) {
      const fixedScaleY = height / originFixtureRectHeight
      activeObject.set({
        top,
        scaleY: fixedScaleY,
      })
    } else {
      activeObject.set({
        top,
        height,
        scaleY: 1,
      })
    }
    intersect.outFromBottom = -1
  }
  if (activeOCoords.br.x > canvasWidth - edgeDetection) {
    // from left
    const targetLeftBorderX = canvasWidth
    const width = targetLeftBorderX - initialCoords.tl.x
    const left = targetLeftBorderX - width
    if (isScale) {
      const fixedScaleX = width / originFixtureRectWidth
      activeObject.set({
        left,
        scaleX: fixedScaleX,
      })
    } else {
      activeObject.set({
        left,
        width,
        scaleX: 1,
      })
    }
    intersect.outFromLeft = -1
  }
  if (activeOCoords.br.y > canvasHeight - edgeDetection) {
    // from top
    const targetTopBorderY = canvasHeight
    const height = targetTopBorderY - initialCoords.tl.y
    const top = targetTopBorderY - height
    if (isScale) {
      const fixedScaleY = height / originFixtureRectHeight
      activeObject.set({
        top,
        scaleY: fixedScaleY,
      })
    } else {
      activeObject.set({
        top,
        height,
        scaleY: 1,
      })
    }
    intersect.outFromTop = -1
  }

  const {
    // Outer bounds
    distancesFromLeft,
    distancesFromRight,
    distancesFromTop,
    distancesFromBottom,
    // Inner bounds
    distancesInFromLeft,
    distancesInFromRight,
    distancesInFromTop,
    distancesInFromBottom,
  } = getDistances(canvas, activeObject, -1, initialCoords)

  const objects = canvas.getObjects()

  // Reset to IC
  // Remove rulers
  removeRulers(canvas)
  setAll(rulers, null)
  // Remove shadows
  objects.map((o) => o.setShadow(null))

  // Outer bounds
  const distanceFromLeft = distancesFromLeft[0] || {}
  const distanceFromRight = distancesFromRight[0] || {}
  const distanceFromTop = distancesFromTop[0] || {}
  const distanceFromBottom = distancesFromBottom[0] || {}

  // Inner bounds
  const distanceInFromLeft = distancesInFromLeft[0] || {}
  const distanceInFromRight = distancesInFromRight[0] || {}
  const distanceInFromTop = distancesInFromTop[0] || {}
  const distanceInFromBottom = distancesInFromBottom[0] || {}

  // Outer bounds
  if (Math.abs(distanceFromLeft.distance) < edgeDetection) {
    // from left
    const target = objects.find(({ id }) => id === distanceFromLeft.id)
    const { tWidth } = getTargetSize(target)
    const targetLeftBorderX = target.get('left') - tWidth / 2
    const width = targetLeftBorderX - initialCoords.tl.x - gap
    const left = targetLeftBorderX - width - gap
    if (isScale) {
      const fixedScaleX = width / originFixtureRectWidth
      activeObject.set({
        left,
        scaleX: fixedScaleX,
      })
    } else {
      activeObject.set({
        left,
        width,
        scaleX: 1,
      })
    }
    intersect.outFromLeft = target.id
  }
  if (Math.abs(distanceFromRight.distance) < edgeDetection) {
    // from right
    const target = objects.find(({ id }) => id === distanceFromRight.id)
    const { tWidth } = getTargetSize(target)
    const targetRightBorderX = target.get('left') + tWidth / 2
    const width = initialCoords.tr.x - targetRightBorderX - gap
    const left = targetRightBorderX + gap
    if (isScale) {
      const fixedScaleX = width / originFixtureRectWidth
      activeObject.set({
        left,
        scaleX: fixedScaleX,
      })
    } else {
      activeObject.set({
        left,
        width,
        scaleX: 1,
      })
    }
    intersect.outFromRight = target.id
  }
  if (Math.abs(distanceFromTop.distance) < edgeDetection) {
    // from top
    const target = objects.find(({ id }) => id === distanceFromTop.id)
    const { tHeight } = getTargetSize(target)
    const targetTopBorderY = target.get('top') - tHeight / 2
    const height = targetTopBorderY - initialCoords.tl.y - gap
    const top = targetTopBorderY - height - gap
    if (isScale) {
      const fixedScaleY = height / originFixtureRectHeight
      activeObject.set({
        top,
        scaleY: fixedScaleY,
      })
    } else {
      activeObject.set({
        top,
        height,
        scaleY: 1,
      })
    }
    intersect.outFromTop = target.id
  }
  if (Math.abs(distanceFromBottom.distance) < edgeDetection) {
    // from bottom
    const target = objects.find(({ id }) => id === distanceFromBottom.id)
    const { tHeight } = getTargetSize(target)
    const targetBottomBorderY = target.get('top') + tHeight / 2
    const height = initialCoords.br.y - targetBottomBorderY - gap
    const top = targetBottomBorderY + gap
    if (isScale) {
      const fixedScaleY = height / originFixtureRectHeight
      activeObject.set({
        top,
        scaleY: fixedScaleY,
      })
    } else {
      activeObject.set({
        top,
        height,
        scaleY: 1,
      })
    }
    intersect.outFromBottom = target.id
  }

  // Inner bounds
  if (Math.abs(distanceInFromLeft.distance) < edgeDetection) {
    // from left
    const target = objects.find(({ id }) => id === distanceInFromLeft.id)
    const { tWidth } = getTargetSize(target)
    const targetRightBorderX = target.get('left') + tWidth / 2
    const width = targetRightBorderX - initialCoords.tl.x
    const left = targetRightBorderX - width
    if (isScale) {
      const fixedScaleX = width / originFixtureRectWidth
      activeObject.set({
        left,
        scaleX: fixedScaleX,
      })
    } else {
      activeObject.set({
        left,
        width,
        scaleX: 1,
      })
    }
    intersect.inFromLeft = target.id
  }
  if (Math.abs(distanceInFromRight.distance) < edgeDetection) {
    // from right
    const target = objects.find(({ id }) => id === distanceInFromRight.id)
    const { tWidth } = getTargetSize(target)
    const targetLeftBorderX = target.get('left') - tWidth / 2
    const width = initialCoords.tr.x - targetLeftBorderX
    const left = targetLeftBorderX
    if (isScale) {
      const fixedScaleX = width / originFixtureRectWidth
      activeObject.set({
        left,
        scaleX: fixedScaleX,
      })
    } else {
      activeObject.set({
        left,
        width,
        scaleX: 1,
      })
    }
    intersect.inFromRight = target.id
  }
  if (Math.abs(distanceInFromTop.distance) < edgeDetection) {
    // from top
    const target = objects.find(({ id }) => id === distanceInFromTop.id)
    const { tHeight } = getTargetSize(target)
    const targetBottomBorderY = target.get('top') + tHeight / 2
    const height = targetBottomBorderY - initialCoords.tl.y
    const top = targetBottomBorderY - height
    if (isScale) {
      const fixedScaleY = height / originFixtureRectHeight
      activeObject.set({
        top,
        scaleY: fixedScaleY,
      })
    } else {
      activeObject.set({
        top,
        height,
        scaleY: 1,
      })
    }
    intersect.inFromTop = target.id
  }
  if (Math.abs(distanceInFromBottom.distance) < edgeDetection) {
    // from bottom
    const target = objects.find(({ id }) => id === distanceInFromBottom.id)
    const { tHeight } = getTargetSize(target)
    const targetTopBorderY = target.get('top') - tHeight / 2
    const height = initialCoords.br.y - targetTopBorderY
    const top = targetTopBorderY
    if (isScale) {
      const fixedScaleY = height / originFixtureRectHeight
      activeObject.set({
        top,
        scaleY: fixedScaleY,
      })
    } else {
      activeObject.set({
        top,
        height,
        scaleY: 1,
      })
    }
    intersect.inFromBottom = target.id
  }

  drawRulers(canvas, intersect, rulers, objects, canvasWidth, canvasHeight)

  const intersectOverlap = onScaleNewOverlap(
    options,
    activeObject,
    initialCoords,
    isScale
  )

  if (intersectOverlap && isIntersectHandled(intersectOverlap)) {
    return intersectOverlap
  }

  return intersect
}

export function onScaleMeasurer(
  options,
  canvas,
  canvasWidth,
  canvasHeight,
  initialCoords,
  isScale
) {
  const obj = options.target
  // The control of the object
  const {
    isRightCorner,
    isLeftCorner,
    isTopCorner,
    isBottomCorner,
  } = getCorner(options)
  obj.setCoords() //Sets corner position coordinates based on current angle, width and height

  const activeObject = canvas.getActiveObject()
  if (!activeObject) return
  const activeOCoords = activeObject.get('oCoords')

  // Canvas border detection
  if (activeOCoords.tl.x < edgeDetection && isLeftCorner) {
    const targetRightBorderX = 0
    const width = initialCoords.tr.x - targetRightBorderX
    const left = targetRightBorderX + width / 2
    if (isScale) {
      const fixedScaleX = width / originFixtureRectWidth
      activeObject.set({
        left,
        scaleX: fixedScaleX,
      })
    } else {
      activeObject.set({
        left,
        width,
        scaleX: 1,
      })
    }
  }
  if (activeOCoords.tl.y < edgeDetection && isTopCorner) {
    const targetBottomBorderY = 0
    const height = initialCoords.br.y - targetBottomBorderY
    const top = targetBottomBorderY + height / 2
    if (isScale) {
      const fixedScaleY = height / originFixtureRectHeight
      activeObject.set({
        top,
        scaleY: fixedScaleY,
      })
    } else {
      activeObject.set({
        top,
        height,
        scaleY: 1,
      })
    }
  }
  if (activeOCoords.br.x > canvasWidth - edgeDetection && isRightCorner) {
    const targetLeftBorderX = canvasWidth
    const width = targetLeftBorderX - initialCoords.tl.x
    const left = targetLeftBorderX - width / 2
    if (isScale) {
      const fixedScaleX = width / originFixtureRectWidth
      activeObject.set({
        left,
        scaleX: fixedScaleX,
      })
    } else {
      activeObject.set({
        left,
        width,
        scaleX: 1,
      })
    }
  }
  if (activeOCoords.br.y > canvasHeight - edgeDetection && isBottomCorner) {
    const targetBottomBorderY = canvasHeight
    const height = targetBottomBorderY - initialCoords.tl.y
    const top = targetBottomBorderY - height / 2
    if (isScale) {
      const fixedScaleY = height / originFixtureRectHeight
      activeObject.set({
        top,
        scaleY: fixedScaleY,
      })
    } else {
      activeObject.set({
        top,
        height,
        scaleY: 1,
      })
    }
  }

  const {
    // Outer bounds
    distancesFromLeft,
    distancesFromRight,
    distancesFromTop,
    distancesFromBottom,
    // Inner bounds
    distancesInFromLeft,
    distancesInFromRight,
    distancesInFromTop,
    distancesInFromBottom,
  } = getDistances(canvas, activeObject, -1)

  const objects = canvas.getObjects()

  // Reset to IC
  // Remove rulers
  removeRulers(canvas)
  setAll(rulers, null)
  // Remove shadows
  objects.map((o) => o.setShadow(null))

  // Outer bounds
  const distanceFromLeft = distancesFromLeft[0] || {}
  const distanceFromRight = distancesFromRight[0] || {}
  const distanceFromTop = distancesFromTop[0] || {}
  const distanceFromBottom = distancesFromBottom[0] || {}

  // Inner bounds
  const distanceInFromLeft = distancesInFromLeft[0] || {}
  const distanceInFromRight = distancesInFromRight[0] || {}
  const distanceInFromTop = distancesInFromTop[0] || {}
  const distanceInFromBottom = distancesInFromBottom[0] || {}

  const intersect = {
    outFromLeft: null,
    outFromRight: null,
    outFromTop: null,
    outFromBottom: null,
    inFromLeft: null,
    inFromRight: null,
    inFromTop: null,
    inFromBottom: null,
  }

  // Outer bounds
  if (Math.abs(distanceFromLeft.distance) < edgeDetection) {
    // from left
    if (activeObject.get('scaleX') > 1) {
      const target = objects.find(({ id }) => id === distanceFromLeft.id)
      const { tWidth } = getTargetSize(target)
      const targetLeftBorderX = target.get('left') - tWidth / 2
      const width = targetLeftBorderX - initialCoords.tl.x - gap
      const left = targetLeftBorderX - width / 2 - gap
      if (isScale) {
        const fixedScaleX = width / originFixtureRectWidth
        activeObject.set({
          left,
          scaleX: fixedScaleX,
        })
      } else {
        activeObject.set({
          left,
          width,
          scaleX: 1,
        })
      }
      intersect.outFromLeft = target.id
    }
  }
  if (Math.abs(distanceFromRight.distance) < edgeDetection) {
    // from right
    if (activeObject.get('scaleX') > 1) {
      const target = objects.find(({ id }) => id === distanceFromRight.id)
      const { tWidth } = getTargetSize(target)
      const targetRightBorderX = target.get('left') + tWidth / 2
      const width = initialCoords.tr.x - targetRightBorderX - gap
      const left = targetRightBorderX + width / 2 + gap
      if (isScale) {
        const fixedScaleX = width / originFixtureRectWidth
        activeObject.set({
          left,
          scaleX: fixedScaleX,
        })
      } else {
        activeObject.set({
          left,
          width,
          scaleX: 1,
        })
      }
      intersect.outFromRight = target.id
    }
  }
  if (Math.abs(distanceFromTop.distance) < edgeDetection) {
    // from top
    if (activeObject.get('scaleY') > 1) {
      const target = objects.find(({ id }) => id === distanceFromTop.id)
      const { tHeight } = getTargetSize(target)
      const targetTopBorderY = target.get('top') - tHeight / 2
      const height = targetTopBorderY - initialCoords.tl.y - gap
      const top = targetTopBorderY - height / 2 - gap
      if (isScale) {
        const fixedScaleY = height / originFixtureRectHeight
        activeObject.set({
          top,
          scaleY: fixedScaleY,
        })
      } else {
        activeObject.set({
          top,
          height,
          scaleY: 1,
        })
      }
      intersect.outFromTop = target.id
    }
  }
  if (Math.abs(distanceFromBottom.distance) < edgeDetection) {
    // from bottom
    if (activeObject.get('scaleY') > 1) {
      const target = objects.find(({ id }) => id === distanceFromBottom.id)
      const { tHeight } = getTargetSize(target)
      const targetBottomBorderY = target.get('top') + tHeight / 2
      const height = initialCoords.br.y - targetBottomBorderY - gap
      const top = targetBottomBorderY + height / 2 + gap
      if (isScale) {
        const fixedScaleY = height / originFixtureRectHeight
        activeObject.set({
          top,
          scaleY: fixedScaleY,
        })
      } else {
        activeObject.set({
          top,
          height,
          scaleY: 1,
        })
      }
      intersect.outFromBottom = target.id
    }
  }

  // Inner bounds
  if (Math.abs(distanceInFromLeft.distance) < edgeDetection) {
    // from left
    if (activeObject.get('scaleX') > 1 && isRightCorner) {
      const target = objects.find(({ id }) => id === distanceInFromLeft.id)
      const { tWidth } = getTargetSize(target)
      const targetRightBorderX = target.get('left') + tWidth / 2
      const width = targetRightBorderX - initialCoords.tl.x
      const left = targetRightBorderX - width / 2
      if (isScale) {
        const fixedScaleX = width / originFixtureRectWidth
        activeObject.set({
          left,
          scaleX: fixedScaleX,
        })
      } else {
        activeObject.set({
          left,
          width,
          scaleX: 1,
        })
      }
      intersect.inFromLeft = target.id
    }
  }
  if (Math.abs(distanceInFromRight.distance) < edgeDetection) {
    // from right
    if (activeObject.get('scaleX') > 1 && isLeftCorner) {
      const target = objects.find(({ id }) => id === distanceInFromRight.id)
      const { tWidth } = getTargetSize(target)
      const targetLeftBorderX = target.get('left') - tWidth / 2
      const width = initialCoords.tr.x - targetLeftBorderX
      const left = targetLeftBorderX + width / 2
      if (isScale) {
        const fixedScaleX = width / originFixtureRectWidth
        activeObject.set({
          left,
          scaleX: fixedScaleX,
        })
      } else {
        activeObject.set({
          left,
          width,
          scaleX: 1,
        })
      }
      intersect.inFromRight = target.id
    }
  }
  if (Math.abs(distanceInFromTop.distance) < edgeDetection) {
    // from top
    if (activeObject.get('scaleY') > 1 && isBottomCorner) {
      const target = objects.find(({ id }) => id === distanceInFromTop.id)
      const { tHeight } = getTargetSize(target)
      const targetBottomBorderY = target.get('top') + tHeight / 2
      const height = targetBottomBorderY - initialCoords.tl.y
      const top = targetBottomBorderY - height / 2
      if (isScale) {
        const fixedScaleY = height / originFixtureRectHeight
        activeObject.set({
          top,
          scaleY: fixedScaleY,
        })
      } else {
        activeObject.set({
          top,
          height,
          scaleY: 1,
        })
      }
      intersect.inFromTop = target.id
    }
  }
  if (Math.abs(distanceInFromBottom.distance) < edgeDetection) {
    // from bottom
    if (activeObject.get('scaleY') > 1 && isTopCorner) {
      const target = objects.find(({ id }) => id === distanceInFromBottom.id)
      const { tHeight } = getTargetSize(target)
      const targetTopBorderY = target.get('top') - tHeight / 2
      const height = initialCoords.br.y - targetTopBorderY
      const top = targetTopBorderY + height / 2
      if (isScale) {
        const fixedScaleY = height / originFixtureRectHeight
        activeObject.set({
          top,
          scaleY: fixedScaleY,
        })
      } else {
        activeObject.set({
          top,
          height,
          scaleY: 1,
        })
      }
      intersect.inFromBottom = target.id
    }
  }

  drawRulers(canvas, intersect, rulers, objects, canvasWidth, canvasHeight)

  onScaleOverlap(options, activeObject, initialCoords, isScale)

  return intersect
}

export function onPositionsOverlapMeasurer(
  options,
  canvas,
  canvasWidth,
  canvasHeight
  // disableOverlap
) {
  var obj = options.target
  obj.setCoords() //Sets corner position coordinates based on current angle, width and height

  const activeObject = canvas.getActiveObject()
  if (!activeObject) return
  const activeOCoords = activeObject.get('oCoords')
  const activeWidth = activeOCoords.tr.x - activeOCoords.tl.x
  const activeHeight = activeOCoords.br.y - activeOCoords.tl.y

  // Canvas border detection
  if (activeOCoords.tl.x < edgeDetection) {
    activeObject.set('left', activeWidth / 2)
  }
  if (activeOCoords.tl.y < edgeDetection) {
    activeObject.set('top', activeHeight / 2)
  }
  if (activeOCoords.br.x > canvasWidth - edgeDetection) {
    activeObject.set('left', canvasWidth - activeWidth / 2)
  }
  if (activeOCoords.br.y > canvasHeight - edgeDetection) {
    activeObject.set('top', canvasHeight - activeHeight / 2)
  }

  const {
    // Outer bounds
    distancesFromLeft,
    distancesFromRight,
    distancesFromTop,
    distancesFromBottom,
    // Inner bounds
    distancesInFromLeft,
    distancesInFromRight,
    distancesInFromTop,
    distancesInFromBottom,
  } = getDistances(canvas, activeObject, 1)

  const objects = canvas.getObjects()

  // Reset to IC
  // Remove rulers
  removeRulers(canvas)
  setAll(rulers, null)
  // Remove shadows
  objects.map((o) => o.setShadow(null))

  // Outer bounds
  const distanceFromLeft = distancesFromLeft[0] || {}
  const distanceFromRight = distancesFromRight[0] || {}
  const distanceFromTop = distancesFromTop[0] || {}
  const distanceFromBottom = distancesFromBottom[0] || {}

  // Inner bounds
  const distanceInFromLeft = distancesInFromLeft[0] || {}
  const distanceInFromRight = distancesInFromRight[0] || {}
  const distanceInFromTop = distancesInFromTop[0] || {}
  const distanceInFromBottom = distancesInFromBottom[0] || {}

  const intersect = {
    outFromLeft: null,
    outFromRight: null,
    outFromTop: null,
    outFromBottom: null,
    inFromLeft: null,
    inFromRight: null,
    inFromTop: null,
    inFromBottom: null,
  }

  // Outer bounds
  if (Math.abs(distanceFromLeft.distance) < edgeDetection) {
    // from left
    const target = objects.find(({ id }) => id === distanceFromLeft.id)
    const { tWidth } = getTargetSize(target)
    activeObject.set(
      'left',
      target.get('left') - tWidth / 2 - activeWidth / 2 - gap
    )
    intersect.outFromLeft = target.id
  }
  if (Math.abs(distanceFromRight.distance) < edgeDetection) {
    // from right
    const target = objects.find(({ id }) => id === distanceFromRight.id)
    const { tWidth } = getTargetSize(target)
    activeObject.set(
      'left',
      target.get('left') + tWidth / 2 + activeWidth / 2 + gap
    )
    intersect.outFromRight = target.id
  }
  if (Math.abs(distanceFromTop.distance) < edgeDetection) {
    // from top
    const target = objects.find(({ id }) => id === distanceFromTop.id)
    const { tHeight } = getTargetSize(target)
    activeObject.set(
      'top',
      target.get('top') - tHeight / 2 - activeHeight / 2 - gap
    )
    intersect.outFromTop = target.id
  }
  if (Math.abs(distanceFromBottom.distance) < edgeDetection) {
    // from bottom
    const target = objects.find(({ id }) => id === distanceFromBottom.id)
    const { tHeight } = getTargetSize(target)
    activeObject.set(
      'top',
      target.get('top') + tHeight / 2 + activeHeight / 2 + gap
    )
    intersect.outFromBottom = target.id
  }

  // Inner bounds
  if (Math.abs(distanceInFromLeft.distance) < edgeDetection) {
    // from left
    const target = objects.find(({ id }) => id === distanceInFromLeft.id)
    const { tWidth } = getTargetSize(target)
    activeObject.set('left', target.get('left') + tWidth / 2 - activeWidth / 2)
    intersect.inFromLeft = target.id
  }
  if (Math.abs(distanceInFromRight.distance) < edgeDetection) {
    // from right
    const target = objects.find(({ id }) => id === distanceInFromRight.id)
    const { tWidth } = getTargetSize(target)
    activeObject.set('left', target.get('left') - tWidth / 2 + activeWidth / 2)
    intersect.inFromRight = target.id
  }
  if (Math.abs(distanceInFromTop.distance) < edgeDetection) {
    // from top
    const target = objects.find(({ id }) => id === distanceInFromTop.id)
    const { tHeight } = getTargetSize(target)
    activeObject.set('top', target.get('top') + tHeight / 2 - activeHeight / 2)
    intersect.inFromTop = target.id
  }
  if (Math.abs(distanceInFromBottom.distance) < edgeDetection) {
    // from bottom
    const target = objects.find(({ id }) => id === distanceInFromBottom.id)
    const { tHeight } = getTargetSize(target)
    activeObject.set('top', target.get('top') - tHeight / 2 + activeHeight / 2)
    intersect.inFromBottom = target.id
  }

  // Canvas border detection
  if (activeOCoords.tl.x < edgeDetection) {
    activeObject.set('left', activeWidth / 2)
  }
  if (activeOCoords.tl.y < edgeDetection) {
    activeObject.set('top', activeHeight / 2)
  }
  if (activeOCoords.br.x > canvasWidth - edgeDetection) {
    activeObject.set('left', canvasWidth - activeWidth / 2)
  }
  if (activeOCoords.br.y > canvasHeight - edgeDetection) {
    activeObject.set('top', canvasHeight - activeHeight / 2)
  }

  drawRulers(canvas, intersect, rulers, objects, canvasWidth, canvasHeight)
  // if (!disableOverlap) {
  //   onMoveOverlap(canvas, options, activeObject, canvasWidth, canvasHeight)
  // }
}

let lastX = 0
let lastY = 0
let lastNonOverlapX = 0
let lastNonOverlapY = 0
let timeoutTriggered = false
export function onOverlapMeasurer(options, canvas, canvasWidth, canvasHeight) {
  let skipLocking = false
  const { pointer: { x, y } = {} } = options
  if (!lastX) {
    lastX = x
    lastY = y
    skipLocking = true
  }
  //
  const isMovingLeft = lastX > x
  const isMovingRight = lastX < x
  const isMovingTop = lastY > y
  const isMovingBottom = lastY < y

  var obj = options.target
  obj.setCoords() //Sets corner position coordinates based on current angle, width and height

  const activeObject = canvas.getActiveObject()
  if (!activeObject) return

  const aX = activeObject.left
  const aY = activeObject.top

  if (!lastNonOverlapX) {
    lastNonOverlapX = aX
    lastNonOverlapY = aY
  }

  const activeOCoords = activeObject.get('oCoords')
  const activeWidth = activeOCoords.tr.x - activeOCoords.tl.x
  const activeHeight = activeOCoords.br.y - activeOCoords.tl.y

  const activeLeft = activeOCoords.ml.x
  const activeRight = activeOCoords.mr.x
  const activeBottom = activeOCoords.mb.y
  const activeTop = activeOCoords.mt.y

  const {
    // Outer bounds
    distancesFromLeft,
    distancesFromRight,
    distancesFromTop,
    distancesFromBottom,
    // Inner bounds
    distancesInFromLeft,
    distancesInFromRight,
    distancesInFromTop,
    distancesInFromBottom,
  } = getDistances(canvas, activeObject, 1)

  const objects = canvas.getObjects()
  // objects.map((o) => console.log(o.id, o.name))

  // Reset to IC
  // Remove rulers
  removeRulers(canvas)
  setAll(rulers, null)
  // Remove shadows
  objects.map((o) => o.setShadow(null))

  // Outer bounds
  const distanceFromLeft = distancesFromLeft[0] || {}
  const distanceFromRight = distancesFromRight[0] || {}
  const distanceFromTop = distancesFromTop[0] || {}
  const distanceFromBottom = distancesFromBottom[0] || {}

  // Inner bounds
  const distanceInFromLeft = distancesInFromLeft[0] || {}
  const distanceInFromRight = distancesInFromRight[0] || {}
  const distanceInFromTop = distancesInFromTop[0] || {}
  const distanceInFromBottom = distancesInFromBottom[0] || {}

  const intersect = {
    outFromLeft: null,
    outFromRight: null,
    outFromTop: null,
    outFromBottom: null,
    inFromLeft: null,
    inFromRight: null,
    inFromTop: null,
    inFromBottom: null,
  }

  // Outer bounds
  if (Math.abs(distanceFromLeft.distance) < edgeDetection) {
    // from left
    const target = objects.find(({ id }) => id === distanceFromLeft.id)
    const { tWidth, tBottom, tTop } = getTargetSize(target)
    const newLeft = target.get('left') - tWidth / 2 - activeWidth / 2 - gap
    activeObject.set('left', newLeft)
    intersect.outFromLeft = target.id
    //
    activeObject.set('lockMovementX', true)
    activeObject.set('lockMovementXfromLeft', x)
    activeObject.set('lockMovementXfromRight', undefined)
    if (isMovingLeft && x < aX) {
      activeObject.set('lockMovementX', false)
    }
    if (activeBottom < tTop || activeTop > tBottom) {
      activeObject.set('lockMovementX', false)
    }
  }
  if (Math.abs(distanceFromRight.distance) < edgeDetection) {
    // from right
    const target = objects.find(({ id }) => id === distanceFromRight.id)
    const { tWidth, tBottom, tTop } = getTargetSize(target)
    const newLeft = target.get('left') + tWidth / 2 + activeWidth / 2 + gap
    activeObject.set('left', newLeft)
    intersect.outFromRight = target.id
    //
    activeObject.set('lockMovementX', true)
    activeObject.set('lockMovementXfromRight', x)
    activeObject.set('lockMovementXfromLeft', undefined)
    if (isMovingRight && x > aX) {
      activeObject.set('lockMovementX', false)
    }
    if (activeBottom < tTop || activeTop > tBottom) {
      activeObject.set('lockMovementX', false)
    }
  }
  if (Math.abs(distanceFromTop.distance) < edgeDetection) {
    // from top
    const target = objects.find(({ id }) => id === distanceFromTop.id)
    const { tHeight, tLeft, tRight } = getTargetSize(target)
    const newTop = target.get('top') - tHeight / 2 - activeHeight / 2 - gap
    activeObject.set('top', newTop)
    intersect.outFromTop = target.id
    //
    activeObject.set('lockMovementY', true)
    activeObject.set('lockMovementXfromTop', y)
    activeObject.set('lockMovementXfromBottom', undefined)
    if (isMovingTop && y < aY) {
      activeObject.set('lockMovementY', false)
    }
    if (activeRight < tLeft || activeLeft > tRight) {
      activeObject.set('lockMovementY', false)
    }
  }
  if (Math.abs(distanceFromBottom.distance) < edgeDetection) {
    // from bottom
    const target = objects.find(({ id }) => id === distanceFromBottom.id)
    const { tHeight, tLeft, tRight } = getTargetSize(target)
    const newTop = target.get('top') + tHeight / 2 + activeHeight / 2 + gap
    activeObject.set('top', newTop)
    //
    activeObject.set('lockMovementY', true)
    activeObject.set('lockMovementXfromBottom', y)
    activeObject.set('lockMovementXfromTop', undefined)
    if (isMovingBottom && y > aY) {
      activeObject.set('lockMovementY', false)
    }
    if (activeRight < tLeft || activeLeft > tRight) {
      activeObject.set('lockMovementY', false)
    }
    intersect.outFromBottom = target.id
  }

  // Inner bounds
  if (Math.abs(distanceInFromLeft.distance) < edgeDetection) {
    // from left
    const target = objects.find(({ id }) => id === distanceInFromLeft.id)
    const { tWidth } = getTargetSize(target)
    activeObject.set('left', target.get('left') + tWidth / 2 - activeWidth / 2)
    intersect.inFromLeft = target.id
  }
  if (Math.abs(distanceInFromRight.distance) < edgeDetection) {
    // from right
    const target = objects.find(({ id }) => id === distanceInFromRight.id)
    const { tWidth } = getTargetSize(target)
    activeObject.set('left', target.get('left') - tWidth / 2 + activeWidth / 2)
    intersect.inFromRight = target.id
  }
  if (Math.abs(distanceInFromTop.distance) < edgeDetection) {
    // from top
    const target = objects.find(({ id }) => id === distanceInFromTop.id)
    const { tHeight } = getTargetSize(target)
    activeObject.set('top', target.get('top') + tHeight / 2 - activeHeight / 2)
    intersect.inFromTop = target.id
  }
  if (Math.abs(distanceInFromBottom.distance) < edgeDetection) {
    // from bottom
    const target = objects.find(({ id }) => id === distanceInFromBottom.id)
    const { tHeight } = getTargetSize(target)
    activeObject.set('top', target.get('top') - tHeight / 2 + activeHeight / 2)
    intersect.inFromBottom = target.id
  }

  drawRulers(canvas, intersect, rulers, objects, canvasWidth, canvasHeight)
  // if (!disableOverlap) {
  //   onMoveOverlap(canvas, options, activeObject, canvasWidth, canvasHeight)
  // }

  lastX = x
  lastY = y

  if (skipLocking) {
    activeObject.set('lockMovementX', false)
    activeObject.set('lockMovementY', false)
  }

  if (timeoutTriggered) {
    const {
      lockMovementXfromLeft,
      lockMovementXfromRight,
      lockMovementXfromTop,
      lockMovementXfromBottom,
    } = activeObject

    const lock = (activeObject) => {
      activeObject.set('lockMovementX', true)
      activeObject.set('lockMovementY', true)
      activeObject.set('left', activeObject.lastLeft)
      activeObject.set('top', activeObject.lastTop)
    }
    const unLock = (activeObject) => {
      activeObject.set('lockMovementX', false)
      activeObject.set('lockMovementY', false)
      //
      activeObject.set('lockMovementXfromLeft', undefined)
      activeObject.set('lockMovementXfromRight', undefined)
      activeObject.set('lockMovementXfromTop', undefined)
      activeObject.set('lockMovementXfromBottom', undefined)
    }
    if (lockMovementXfromLeft && lockMovementXfromTop) {
      if (lockMovementXfromLeft < x && lockMovementXfromTop < y) {
        lock(activeObject)
      } else {
        unLock(activeObject)
      }
    }
    if (lockMovementXfromLeft && lockMovementXfromBottom) {
      if (lockMovementXfromLeft < x && lockMovementXfromBottom > y) {
        lock(activeObject)
      } else {
        unLock(activeObject)
      }
    }
    if (lockMovementXfromRight && lockMovementXfromTop) {
      if (lockMovementXfromRight > x && lockMovementXfromTop < y) {
        lock(activeObject)
      } else {
        unLock(activeObject)
      }
    }
    if (lockMovementXfromRight && lockMovementXfromBottom) {
      if (lockMovementXfromRight > x && lockMovementXfromBottom > y) {
        lock(activeObject)
      } else {
        unLock(activeObject)
      }
    }
    timeoutTriggered = false
  }

  if (activeObject.lockMovementX && activeObject.lockMovementY) {
    if (!timeoutTriggered) {
      timeoutTriggered = true
      activeObject.set('lastLeft', activeObject.left)
      activeObject.set('lastTop', activeObject.top)
      setTimeout(() => {
        activeObject.set('lockMovementX', false)
        activeObject.set('lockMovementY', false)
        //timeoutTriggered = false
      }, 500)
    }
  }

  if (activeObject.lockMovementX || activeObject.lockMovementY) {
    if (
      isCursorOutside(activeLeft, activeTop, activeRight, activeBottom, x, y)
    ) {
      if (
        !isCustomOverlap(canvas, compileActive(activeWidth, activeHeight, x, y))
      ) {
        if (
          x > gap + activeWidth / 2 &&
          x < canvasWidth - gap + activeWidth / 2
        ) {
          activeObject.set('left', x)
          activeObject.set('lockMovementX', false)
        }
        if (
          y > gap + activeHeight / 2 &&
          y < canvasHeight - gap + activeHeight / 2
        ) {
          activeObject.set('top', y)
          activeObject.set('lockMovementY', false)
        }
      }
    }
  }

  // Canvas border detection
  handleCanvasEdges(canvas, x, y, activeObject, canvasWidth, canvasHeight)

  if (
    isOverlap(canvas, activeObject) ||
    isCustomOverlap(canvas, activeObject)
  ) {
    activeObject.set('left', lastNonOverlapX)
    activeObject.set('top', lastNonOverlapY)
  } else {
    const newLeft = activeObject.left
    if (
      newLeft > gap + activeWidth / 2 &&
      newLeft < canvasWidth - gap + activeWidth / 2
    ) {
      lastNonOverlapX = newLeft
    }
    const newTop = activeObject.top
    if (
      newTop > gap + activeHeight / 2 &&
      newTop < canvasHeight - gap + activeHeight / 2
    ) {
      lastNonOverlapY = newTop
    }
  }
}

export function handleCanvasEdges(
  canvas,
  x,
  y,
  activeObject,
  canvasWidth,
  canvasHeight
) {
  const activeOCoords = activeObject.get('oCoords')
  const activeWidth = activeOCoords.tr.x - activeOCoords.tl.x
  const activeHeight = activeOCoords.br.y - activeOCoords.tl.y
  const activeLeft = activeOCoords.ml.x
  const activeRight = activeOCoords.mr.x
  const activeBottom = activeOCoords.mb.y
  const activeTop = activeOCoords.mt.y

  const handleOverlap = (canvas, active) => {
    if (isOverlap(canvas, active)) {
      activeObject.set('left', lastNonOverlapX)
      activeObject.set('top', lastNonOverlapY)
    }
  }

  if (activeLeft < edgeDetection) {
    const newLeft = activeWidth / 2
    activeObject.set('left', newLeft)
    unlockActive(canvas)
    handleOverlap(canvas, activeObject)
  }
  if (activeTop < edgeDetection) {
    const newTop = activeHeight / 2
    activeObject.set('top', newTop)
    unlockActive(canvas)
    handleOverlap(canvas, activeObject)
  }
  if (activeRight > canvasWidth - edgeDetection) {
    const newLeft = canvasWidth - activeWidth / 2
    activeObject.set('left', newLeft)
    unlockActive(canvas)
    handleOverlap(canvas, activeObject)
  }
  if (activeBottom > canvasHeight - edgeDetection) {
    const newTop = canvasHeight - activeHeight / 2
    activeObject.set('top', newTop)
    unlockActive(canvas)
    handleOverlap(canvas, activeObject)
  }
}

export function isOutsideOfCanvas(activeObject, canvasWidth, canvasHeight) {
  const activeOCoords = activeObject.get('oCoords')
  const activeLeft = activeOCoords.ml.x
  const activeRight = activeOCoords.mr.x
  const activeBottom = activeOCoords.mb.y
  const activeTop = activeOCoords.mt.y

  if (
    activeLeft < edgeDetection ||
    activeTop < edgeDetection ||
    activeRight > canvasWidth - edgeDetection ||
    activeBottom > canvasHeight - edgeDetection
  ) {
    return true
  }
  return false
}

export function isOverlap(canvas, activeObject, processed) {
  // console.warn('TOP active', activeObject.get('top'))
  // loop canvas objects
  let isOverlap = false
  canvas.forEachObject((target) => {
    if (isOverlap) return
    if (target === null) return
    if (target.type === 'line') return
    if (target === activeObject) return // bypass self
    if (target.id === activeObject.id) return
    if (target.id === (processed || {}).id) return

    if (
      activeObject.intersectsWithObject(target) ||
      activeObject.isContainedWithinObject(target) ||
      target.isContainedWithinObject(activeObject)
    ) {
      isOverlap = true
    } else {
      isOverlap = false
    }
  })
  return isOverlap
}

export function isCursorOutside(
  activeLeft,
  activeTop,
  activeRight,
  activeBottom,
  x,
  y
) {
  const halfWidth = (activeRight - activeLeft) / 2
  const halfHeight = (activeBottom - activeTop) / 2
  if (
    x < activeLeft - halfWidth ||
    x > activeRight + halfWidth ||
    y < activeTop - halfHeight ||
    y > activeBottom + halfHeight
  ) {
    return true
  }
  return false
}

export function compileActive(activeWidth, activeHeight, x, y) {
  return {
    left: x,
    top: y,
    oCoords: {
      tr: { x: x + activeWidth / 2, y: y - activeHeight / 2 },
      bl: { x: x - activeWidth / 2, y: y + activeHeight / 2 },
    },
  }
}

export function doObjsOverlap(o1, o2) {
  //if (o2.name !== 'New Area 1') return false
  const o1Coords = o1.oCoords
  const o2Coords = o2.oCoords
  const o1Width = o1Coords.tr.x - o1Coords.bl.x
  const o1Height = o1Coords.bl.y - o1Coords.tr.y
  const o2Width = o2Coords.tr.x - o2Coords.bl.x
  const o2Height = o2Coords.bl.y - o2Coords.tr.y
  const o1X = o1.left
  const o1Y = o1.top
  const o2X = o2.left
  const o2Y = o2.top

  const l1 = { x: o1X - o1Width / 2, y: o1Y - o1Height / 2 }
  const r1 = { x: o1X + o1Width / 2, y: o1Y + o1Height / 2 }
  const l2 = { x: o2X - o2Width / 2, y: o2Y - o2Height / 2 }
  const r2 = { x: o2X + o2Width / 2, y: o2Y + o2Height / 2 }
  return doOverlap(l1, r1, l2, r2) ? o2.id : false
}

export function doOverlap(l1, r1, l2, r2) {
  // If one rectangle is on left side of other
  if (l1.x > r2.x || l2.x > r1.x) {
    return false
  }

  // If one rectangle is above other
  if (l1.y > r2.y || l2.y > r1.y) {
    return false
  }
  return true
}

export function isCustomOverlap(canvas, activeObject, processed) {
  let isOverlap = false
  canvas.forEachObject((target) => {
    if (isOverlap) return
    if (target === null) return
    if (target.type === 'line') return
    if (target === activeObject) return // bypass self
    if (target.id === activeObject.id) return
    if (target.id === (processed || {}).id) return
    isOverlap = doObjsOverlap(activeObject, target)
  })
  return isOverlap
}

function getDistances(
  canvas,
  activeObject,
  sign,
  initialCoords = getInitialCoords({ absolutePointer: {} })
) {
  const activeOCoords = activeObject.get('oCoords')

  // Outer bounds
  const distancesFromLeft = [] // [{id, distance}]
  const distancesFromRight = [] // [{id, distance}]
  const distancesFromTop = [] // [{id, distance}]
  const distancesFromBottom = [] // [{id, distance}]
  // Inner bounds
  const distancesInFromLeft = [] // [{id, distance}]
  const distancesInFromRight = [] // [{id, distance}]
  const distancesInFromTop = [] // [{id, distance}]
  const distancesInFromBottom = [] // [{id, distance}]

  canvas.forEachObject((target) => {
    if (target === null) return
    if (target.type === 'line') return
    if (target === activeObject) return
    if (target.id === activeObject.id) return
    if (target.selectable === false) return

    const targetOCoords = target.get('oCoords')

    // Outer bounds
    const distanceFromLeft = (targetOCoords.tl.x - activeOCoords.tr.x) * sign
    if (distanceFromLeft >= 0) {
      const isAllowed = !isAlmostEqual(activeOCoords.tr.x, initialCoords.tr.x)
      if (isAllowed) {
        const value = {
          id: target.id,
          distance: distanceFromLeft,
          name: target.name,
        }
        distancesFromLeft.splice(
          _.sortedIndexBy(distancesFromLeft, value, 'distance'),
          0,
          value
        )
      }
    }
    const distanceFromRight = (activeOCoords.tl.x - targetOCoords.tr.x) * sign
    if (distanceFromRight >= 0) {
      const isAllowed = !isAlmostEqual(activeOCoords.tl.x, initialCoords.tl.x)
      if (isAllowed) {
        const value = {
          id: target.id,
          distance: distanceFromRight,
          name: target.name,
        }
        distancesFromRight.splice(
          _.sortedIndexBy(distancesFromRight, value, 'distance'),
          0,
          value
        )
      }
    }
    const distanceFromTop = (targetOCoords.tr.y - activeOCoords.br.y) * sign
    if (distanceFromTop >= 0) {
      const isAllowed = !isAlmostEqual(activeOCoords.br.y, initialCoords.br.y)
      if (isAllowed) {
        const value = {
          id: target.id,
          distance: distanceFromTop,
          name: target.name,
        }
        distancesFromTop.splice(
          _.sortedIndexBy(distancesFromTop, value, 'distance'),
          0,
          value
        )
      }
    }
    const distanceFromBottom = (activeOCoords.tr.y - targetOCoords.br.y) * sign
    if (distanceFromBottom >= 0) {
      const isAllowed = !isAlmostEqual(activeOCoords.tr.y, initialCoords.tr.y)
      if (isAllowed) {
        const value = {
          id: target.id,
          distance: distanceFromBottom,
          name: target.name,
        }
        distancesFromBottom.splice(
          _.sortedIndexBy(distancesFromBottom, value, 'distance'),
          0,
          value
        )
      }
    }

    // Inner bounds
    const distanceInFromLeft = (targetOCoords.tr.x - activeOCoords.tr.x) * sign
    if (distanceInFromLeft >= 0) {
      const isAllowed = !isAlmostEqual(activeOCoords.tr.x, initialCoords.tr.x)
      if (isAllowed) {
        const value = {
          id: target.id,
          distance: distanceInFromLeft,
          name: target.name,
        }
        distancesInFromLeft.splice(
          _.sortedIndexBy(distancesInFromLeft, value, 'distance'),
          0,
          value
        )
      }
    }
    const distanceInFromRight = (activeOCoords.tl.x - targetOCoords.tl.x) * sign
    if (distanceInFromRight >= 0) {
      const isAllowed = !isAlmostEqual(activeOCoords.tl.x, initialCoords.tl.x)
      if (isAllowed) {
        const value = {
          id: target.id,
          distance: distanceInFromRight,
          name: target.name,
        }
        distancesInFromRight.splice(
          _.sortedIndexBy(distancesInFromRight, value, 'distance'),
          0,
          value
        )
      }
    }
    const distanceInFromTop = (targetOCoords.br.y - activeOCoords.br.y) * sign
    if (distanceInFromTop >= 0) {
      const isAllowed = !isAlmostEqual(activeOCoords.br.y, initialCoords.br.y)
      if (isAllowed) {
        const value = {
          id: target.id,
          distance: distanceInFromTop,
          name: target.name,
        }
        distancesInFromTop.splice(
          _.sortedIndexBy(distancesInFromTop, value, 'distance'),
          0,
          value
        )
      }
    }
    const distanceInFromBottom =
      (activeOCoords.tr.y - targetOCoords.tr.y) * sign
    if (distanceInFromBottom >= 0) {
      const isAllowed = !isAlmostEqual(activeOCoords.tr.y, initialCoords.tr.y)
      if (isAllowed) {
        const value = {
          id: target.id,
          distance: distanceInFromBottom,
          name: target.name,
        }
        distancesInFromBottom.splice(
          _.sortedIndexBy(distancesInFromBottom, value, 'distance'),
          0,
          value
        )
      }
    }
  })

  return {
    // Outer bounds
    distancesFromLeft,
    distancesFromRight,
    distancesFromTop,
    distancesFromBottom,
    // Inner bounds
    distancesInFromLeft,
    distancesInFromRight,
    distancesInFromTop,
    distancesInFromBottom,
  }
}

export function getTargetsDistances(
  target,
  activeObject,
  sign,
  initialCoords = getInitialCoords({ absolutePointer: {} })
) {
  const activeOCoords = activeObject.get('oCoords')

  // Outer bounds
  const distancesFromLeft = [] // [{id, distance}]
  const distancesFromRight = [] // [{id, distance}]
  const distancesFromTop = [] // [{id, distance}]
  const distancesFromBottom = [] // [{id, distance}]
  // Inner bounds
  const distancesInFromLeft = [] // [{id, distance}]
  const distancesInFromRight = [] // [{id, distance}]
  const distancesInFromTop = [] // [{id, distance}]
  const distancesInFromBottom = [] // [{id, distance}]

  if (target === null) return
  if (target.type === 'line') return
  if (target === activeObject) return
  if (target.id === activeObject.id) return
  if (target.selectable === false) return

  const targetOCoords = target.get('oCoords')

  // Outer bounds
  const distanceFromLeft = (targetOCoords.tl.x - activeOCoords.tr.x) * sign
  if (distanceFromLeft >= 0) {
    const isAllowed = !isAlmostEqual(activeOCoords.tr.x, initialCoords.tr.x)
    if (isAllowed) {
      const value = { id: target.id, distance: distanceFromLeft }
      distancesFromLeft.splice(
        _.sortedIndexBy(distancesFromLeft, value, 'distance'),
        0,
        value
      )
    }
  }
  const distanceFromRight = (activeOCoords.tl.x - targetOCoords.tr.x) * sign
  if (distanceFromRight >= 0) {
    const isAllowed = !isAlmostEqual(activeOCoords.tl.x, initialCoords.tl.x)
    if (isAllowed) {
      const value = { id: target.id, distance: distanceFromRight }
      distancesFromRight.splice(
        _.sortedIndexBy(distancesFromRight, value, 'distance'),
        0,
        value
      )
    }
  }
  const distanceFromTop = (targetOCoords.tr.y - activeOCoords.br.y) * sign
  if (distanceFromTop >= 0) {
    const isAllowed = !isAlmostEqual(activeOCoords.br.y, initialCoords.br.y)
    if (isAllowed) {
      const value = { id: target.id, distance: distanceFromTop }
      distancesFromTop.splice(
        _.sortedIndexBy(distancesFromTop, value, 'distance'),
        0,
        value
      )
    }
  }
  const distanceFromBottom = (activeOCoords.tr.y - targetOCoords.br.y) * sign
  if (distanceFromBottom >= 0) {
    const isAllowed = !isAlmostEqual(activeOCoords.tr.y, initialCoords.tr.y)
    if (isAllowed) {
      const value = { id: target.id, distance: distanceFromBottom }
      distancesFromBottom.splice(
        _.sortedIndexBy(distancesFromBottom, value, 'distance'),
        0,
        value
      )
    }
  }

  // Inner bounds
  const distanceInFromLeft = (targetOCoords.tr.x - activeOCoords.tr.x) * sign
  if (distanceInFromLeft >= 0) {
    const isAllowed = !isAlmostEqual(activeOCoords.tr.x, initialCoords.tr.x)
    if (isAllowed) {
      const value = { id: target.id, distance: distanceInFromLeft }
      distancesInFromLeft.splice(
        _.sortedIndexBy(distancesInFromLeft, value, 'distance'),
        0,
        value
      )
    }
  }
  const distanceInFromRight = (activeOCoords.tl.x - targetOCoords.tl.x) * sign
  if (distanceInFromRight >= 0) {
    const isAllowed = !isAlmostEqual(activeOCoords.tl.x, initialCoords.tl.x)
    if (isAllowed) {
      const value = { id: target.id, distance: distanceInFromRight }
      distancesInFromRight.splice(
        _.sortedIndexBy(distancesInFromRight, value, 'distance'),
        0,
        value
      )
    }
  }
  const distanceInFromTop = (targetOCoords.br.y - activeOCoords.br.y) * sign
  if (distanceInFromTop >= 0) {
    const isAllowed = !isAlmostEqual(activeOCoords.br.y, initialCoords.br.y)
    if (isAllowed) {
      const value = { id: target.id, distance: distanceInFromTop }
      distancesInFromTop.splice(
        _.sortedIndexBy(distancesInFromTop, value, 'distance'),
        0,
        value
      )
    }
  }
  const distanceInFromBottom = (activeOCoords.tr.y - targetOCoords.tr.y) * sign
  if (distanceInFromBottom >= 0) {
    const isAllowed = !isAlmostEqual(activeOCoords.tr.y, initialCoords.tr.y)
    if (isAllowed) {
      const value = { id: target.id, distance: distanceInFromBottom }
      distancesInFromBottom.splice(
        _.sortedIndexBy(distancesInFromBottom, value, 'distance'),
        0,
        value
      )
    }
  }

  return {
    // Outer bounds
    distancesFromLeft,
    distancesFromRight,
    distancesFromTop,
    distancesFromBottom,
    // Inner bounds
    distancesInFromLeft,
    distancesInFromRight,
    distancesInFromTop,
    distancesInFromBottom,
  }
}

function drawRulers(
  canvas,
  intersect,
  rulers,
  objects,
  canvasWidth,
  canvasHeight
) {
  const keys = Object.keys(intersect)
  keys.map((key) => {
    const tId = intersect[key]
    if (tId) {
      const target = objects.find(({ id }) => id === tId)
      if (target) {
        const targetOCoords = target.get('oCoords')
        switch (key) {
          case 'outFromLeft': {
            const outFromLeftX = targetOCoords.tl.x
            rulers.outLineFromLeft = makeLine([
              outFromLeftX,
              0,
              outFromLeftX,
              canvasHeight,
            ])
            break
          }
          case 'outFromRight': {
            const outFromRightX = targetOCoords.tr.x
            rulers.outLineFromRight = makeLine([
              outFromRightX,
              0,
              outFromRightX,
              canvasHeight,
            ])
            break
          }
          case 'outFromTop': {
            const outFromTopY = targetOCoords.tl.y
            rulers.outLineFromTop = makeLine([
              0,
              outFromTopY,
              canvasWidth,
              outFromTopY,
            ])
            break
          }
          case 'outFromBottom': {
            const outFromBottomY = targetOCoords.bl.y
            rulers.outLineFromBottom = makeLine([
              0,
              outFromBottomY,
              canvasWidth,
              outFromBottomY,
            ])
            break
          }
          case 'inFromLeft': {
            const inFromLeftX = targetOCoords.tr.x
            rulers.inLineFromLeft = makeLine([
              inFromLeftX,
              0,
              inFromLeftX,
              canvasHeight,
            ])
            break
          }
          case 'inFromRight': {
            const inFromRightX = targetOCoords.tl.x
            rulers.inLineFromRight = makeLine([
              inFromRightX,
              0,
              inFromRightX,
              canvasHeight,
            ])
            break
          }
          case 'inFromTop': {
            const inFromTopY = targetOCoords.bl.y
            rulers.inLineFromTop = makeLine([
              0,
              inFromTopY,
              canvasWidth,
              inFromTopY,
            ])
            break
          }
          case 'inFromBottom': {
            const inFromBottomY = targetOCoords.tl.y
            rulers.inLineFromBottom = makeLine([
              0,
              inFromBottomY,
              canvasWidth,
              inFromBottomY,
            ])
            break
          }
        }
        target.setShadow({
          color: highlightColor,
          blur: 20,
        })
      }
    }
  })
  //
  Object.values(rulers).forEach((ruler) => {
    if (ruler !== null) {
      canvas.add(ruler)
    }
  })
}

function getCorner(options) {
  const { transform: { corner = '' } = {} } = options
  return {
    isRightCorner: corner.includes('r'),
    isLeftCorner: corner.includes('l'),
    isTopCorner: corner.includes('t'),
    isBottomCorner: corner.includes('b'),
  }
}

function makeLine(coords) {
  return new fabric.Line(coords, {
    fill: 'rgb(255,0,0)',
    stroke: 'rgb(255,0,0)',
    strokeWidth: 1,
    selectable: false,
    evented: false,
  })
}

function setAll(obj, val) {
  Object.keys(obj).forEach((index) => {
    obj[index] = val
  })
}

export function onModifiedMeasurer(canvas) {
  removeRulers(canvas)
  removeHighlight(canvas)
  unlockActive(canvas)
}

export function removeRulers(canvas) {
  const rulerObjects = canvas.getObjects('line')
  for (let i in rulerObjects) {
    canvas.remove(rulerObjects[i])
  }
}

export function removeHighlight(canvas) {
  const objects = canvas.getObjects()
  objects.map((o) => o.setShadow(null))
}

export function unlockActive(canvas) {
  const activeObject = canvas.getActiveObject()
  if (activeObject) {
    activeObject.set('lockMovementX', false)
    activeObject.set('lockMovementY', false)
  }
}

export function getTargetSize(target) {
  const tOCoords = target.get('oCoords')
  const tWidth = tOCoords.tr.x - tOCoords.tl.x
  const tHeight = tOCoords.br.y - tOCoords.tl.y

  const tLeft = tOCoords.ml.x
  const tRight = tOCoords.mr.x
  const tBottom = tOCoords.mb.y
  const tTop = tOCoords.mt.y

  return { tWidth, tHeight, tLeft, tRight, tBottom, tTop }
}

export function getInitialCoords(o) {
  const { absolutePointer } = o
  return {
    bl: absolutePointer,
    br: absolutePointer,
    mb: absolutePointer,
    ml: absolutePointer,
    mr: absolutePointer,
    mt: absolutePointer,
    mtr: absolutePointer,
    tl: absolutePointer,
    tr: absolutePointer,
  }
}

export function isIntersectHandled(intersect) {
  const {
    inFromBottom,
    inFromLeft,
    inFromRight,
    inFromTop,
    outFromBottom,
    outFromLeft,
    outFromRight,
    outFromTop,
  } = intersect
  if (
    inFromBottom ||
    inFromLeft ||
    inFromRight ||
    inFromTop ||
    outFromBottom ||
    outFromLeft ||
    outFromRight ||
    outFromTop
  ) {
    return true
  }
  return false
}

export function isIntersectXHandled(intersect) {
  const { inFromLeft, inFromRight, outFromLeft, outFromRight } = intersect
  if (inFromLeft || inFromRight || outFromLeft || outFromRight) {
    return true
  }
  return false
}

export function isIntersectYHandled(intersect) {
  const { inFromBottom, inFromTop, outFromBottom, outFromTop } = intersect
  if (inFromBottom || inFromTop || outFromBottom || outFromTop) {
    return true
  }
  return false
}

export function isIntersectOutHandled(intersect) {
  const { outFromBottom, outFromLeft, outFromRight, outFromTop } = intersect
  if (outFromBottom || outFromLeft || outFromRight || outFromTop) {
    return true
  }
  return false
}

export function onScaleNewOverlap(
  options,
  activeObject,
  initialCoords,
  isScale
) {
  const intersect = {
    outFromLeft: null,
    outFromRight: null,
    outFromTop: null,
    outFromBottom: null,
    inFromLeft: null,
    inFromRight: null,
    inFromTop: null,
    inFromBottom: null,
  }

  const canvas = activeObject.canvas

  activeObject.setCoords()
  if (typeof activeObject.refreshLast !== 'boolean') {
    activeObject.set('refreshLast', true)
  }

  // loop canvas objects
  canvas.forEachObject((target) => {
    if (target === null) return
    if (target.type === 'line') return
    if (target === activeObject) return // bypass self
    if (target.id === activeObject.id) return

    // check intersections with every object in canvas
    if (
      activeObject.intersectsWithObject(target) ||
      activeObject.isContainedWithinObject(target) ||
      target.isContainedWithinObject(activeObject)
    ) {
      // objects are intersecting - deny saving last non-intersection position and break loop
      if (typeof activeObject.lastLeft === 'number') {
        const { pointer: { x, y } = {} } = options

        const { tWidth, tHeight } = getTargetSize(target)
        if (initialCoords.tr.x < x) {
          // from left
          const targetLeftBorderX = target.get('left') - tWidth / 2
          const width = targetLeftBorderX - initialCoords.tl.x - gap
          const left = targetLeftBorderX - width - gap
          if (isScale) {
            const fixedScaleX = width / originFixtureRectWidth
            activeObject.set({
              left,
              scaleX: fixedScaleX,
            })
          } else {
            activeObject.set({
              left,
              width,
              scaleX: 1,
            })
          }
          intersect.outFromLeft = target.id
        } else if (initialCoords.tl.x > x) {
          // from right
          const targetRightBorderX = target.get('left') + tWidth / 2
          const width = initialCoords.tr.x - targetRightBorderX - gap
          const left = targetRightBorderX + gap
          if (isScale) {
            const fixedScaleX = width / originFixtureRectWidth
            activeObject.set({
              left,
              scaleX: fixedScaleX,
            })
          } else {
            activeObject.set({
              left,
              width,
              scaleX: 1,
            })
          }
          intersect.outFromRight = target.id
        } else if (initialCoords.bl.y < y) {
          // from top
          const targetTopBorderY = target.get('top') - tHeight / 2
          const height = targetTopBorderY - initialCoords.tl.y - gap
          const top = targetTopBorderY - height - gap
          if (isScale) {
            const fixedScaleY = height / originFixtureRectHeight
            activeObject.set({
              top,
              scaleY: fixedScaleY,
            })
          } else {
            activeObject.set({
              top,
              height,
              scaleY: 1,
            })
          }
          intersect.outFromTop = target.id
        } else if (initialCoords.tl.y > y) {
          // from bottom
          const targetBottomBorderY = target.get('top') + tHeight / 2
          const height = initialCoords.br.y - targetBottomBorderY - gap
          const top = targetBottomBorderY + gap
          if (isScale) {
            const fixedScaleY = height / originFixtureRectHeight
            activeObject.set({
              top,
              scaleY: fixedScaleY,
            })
          } else {
            activeObject.set({
              top,
              height,
              scaleY: 1,
            })
          }
          intersect.outFromBottom = target.id
        }
      }
    }
  })

  if (activeObject.refreshLast) {
    // save last non-intersecting position if possible
    activeObject.set('lastLeft', activeObject.left)
    activeObject.set('lastTop', activeObject.top)
  }

  return intersect
}

export function onScaleOverlap(options, activeObject, initialCoords, isScale) {
  const intersect = {
    outFromLeft: null,
    outFromRight: null,
    outFromTop: null,
    outFromBottom: null,
    inFromLeft: null,
    inFromRight: null,
    inFromTop: null,
    inFromBottom: null,
  }

  const canvas = activeObject.canvas

  activeObject.setCoords()
  if (typeof activeObject.refreshLast !== 'boolean') {
    activeObject.set('refreshLast', true)
  }

  // loop canvas objects
  canvas.forEachObject((target) => {
    if (target === null) return
    if (target.type === 'line') return
    if (target === activeObject) return // bypass self
    if (target.id === activeObject.id) return

    // check intersections with every object in canvas
    if (
      activeObject.intersectsWithObject(target) ||
      activeObject.isContainedWithinObject(target) ||
      target.isContainedWithinObject(activeObject)
    ) {
      // objects are intersecting - deny saving last non-intersection position and break loop
      if (typeof activeObject.lastLeft === 'number') {
        const { pointer: { x, y } = {} } = options

        const { tWidth, tHeight } = getTargetSize(target)
        if (initialCoords.tr.x < x) {
          // from left
          if (activeObject.get('scaleX') > 1) {
            const targetLeftBorderX = target.get('left') - tWidth / 2
            const width = targetLeftBorderX - initialCoords.tl.x - gap
            const left = targetLeftBorderX - width / 2 - gap
            if (isScale) {
              const fixedScaleX = width / originFixtureRectWidth
              activeObject.set({
                left,
                scaleX: fixedScaleX,
              })
            } else {
              activeObject.set({
                left,
                width,
                scaleX: 1,
              })
            }
            intersect.outFromLeft = target.id
          }
        } else if (initialCoords.tl.x > x) {
          // from right
          if (activeObject.get('scaleX') > 1) {
            const targetRightBorderX = target.get('left') + tWidth / 2
            const width = initialCoords.tr.x - targetRightBorderX - gap
            const left = targetRightBorderX + width / 2 + gap
            if (isScale) {
              const fixedScaleX = width / originFixtureRectWidth
              activeObject.set({
                left,
                scaleX: fixedScaleX,
              })
            } else {
              activeObject.set({
                left,
                width,
                scaleX: 1,
              })
            }
            intersect.outFromRight = target.id
          }
        } else if (initialCoords.bl.y < y) {
          // from top
          if (activeObject.get('scaleY') > 1 || isScale) {
            const targetTopBorderY = target.get('top') - tHeight / 2
            const height = targetTopBorderY - initialCoords.tl.y - gap
            const top = targetTopBorderY - height / 2 - gap
            if (isScale) {
              const fixedScaleY = height / activeObject.height
              activeObject.set({
                top,
                scaleY: fixedScaleY,
              })
            } else {
              activeObject.set({
                top,
                height,
                scaleY: 1,
              })
            }
            intersect.outFromTop = target.id
          }
        } else if (initialCoords.tl.y > y) {
          // from bottom
          if (activeObject.get('scaleY') > 1) {
            const targetBottomBorderY = target.get('top') + tHeight / 2
            const height = initialCoords.br.y - targetBottomBorderY - gap
            const top = targetBottomBorderY + height / 2 + gap
            if (isScale) {
              const fixedScaleY = height / activeObject.height
              activeObject.set({
                top,
                scaleY: fixedScaleY,
              })
            } else {
              activeObject.set({
                top,
                height,
                scaleY: 1,
              })
            }
            intersect.outFromBottom = target.id
          }
        }
      }
    }
  })

  if (activeObject.refreshLast) {
    // save last non-intersecting position if possible
    activeObject.set('lastLeft', activeObject.left)
    activeObject.set('lastTop', activeObject.top)
  }
}

export function printDistances(distances) {
  const {
    // Outer bounds
    distancesFromLeft,
    distancesFromRight,
    distancesFromTop,
    distancesFromBottom,
  } = distances
  const distanceFromLeft = distancesFromLeft[0] || {}
  const distanceFromRight = distancesFromRight[0] || {}
  const distanceFromTop = distancesFromTop[0] || {}
  const distanceFromBottom = distancesFromBottom[0] || {}

  console.warn('from >>left', distanceFromLeft.name, distanceFromLeft.distance)
  console.warn(
    'from >right',
    distanceFromRight.name,
    distanceFromRight.distance
  )
  console.warn('from >>>top', distanceFromTop.name, distanceFromTop.distance)
  console.warn(
    'from bottom',
    distanceFromBottom.name,
    distanceFromBottom.distance
  )
}

// TODO: Deprecated
export function onMoveOverlapInteractive(options, activeObject) {
  const activeOCoords = activeObject.get('oCoords')
  const activeWidth = activeOCoords.tr.x - activeOCoords.tl.x
  const activeHeight = activeOCoords.br.y - activeOCoords.tl.y

  const intersect = {
    outFromLeft: null,
    outFromRight: null,
    outFromTop: null,
    outFromBottom: null,
    inFromLeft: null,
    inFromRight: null,
    inFromTop: null,
    inFromBottom: null,
  }

  const canvas = activeObject.canvas

  activeObject.setCoords()
  if (typeof activeObject.refreshLast !== 'boolean') {
    activeObject.set('refreshLast', true)
  }

  // loop canvas objects
  canvas.forEachObject((target) => {
    if (target === null) return
    if (target.type === 'line') return
    if (target === activeObject) return // bypass self
    if (target.id === activeObject.id) return

    // check intersections with every object in canvas
    if (
      activeObject.intersectsWithObject(target) ||
      activeObject.isContainedWithinObject(target) ||
      target.isContainedWithinObject(activeObject)
    ) {
      // objects are intersecting - deny saving last non-intersection position and break loop
      if (typeof activeObject.lastLeft === 'number') {
        const targetOCoords = target.get('oCoords')
        const activeOCoords = activeObject.get('oCoords')

        const { pointer: { x, y } = {} } = options
        const { bl: { x: x1, y: y2 }, tr: { x: x2, y: y1 } } = targetOCoords

        const { bl, tl, tr, br } = activeOCoords

        const xC = (x2 + x1) / 2
        const yC = (y2 + y1) / 2

        const pointInLeftTriangle =
          isInside(x1, y2, x1, y1, xC, yC, x, y) ||
          isInside(x1, y2, x1, y1, xC, yC, bl.x, bl.y) ||
          isInside(x1, y2, x1, y1, xC, yC, tl.x, tl.y) ||
          isInside(x1, y2, x1, y1, xC, yC, tr.x, tr.y) ||
          isInside(x1, y2, x1, y1, xC, yC, br.x, bl.y)
        const pointInRightTriangle =
          isInside(x2, y2, xC, yC, x2, y1, x, y) ||
          isInside(x2, y2, xC, yC, x2, y1, bl.x, bl.y) ||
          isInside(x2, y2, xC, yC, x2, y1, tl.x, tl.y) ||
          isInside(x2, y2, xC, yC, x2, y1, tr.x, tr.y) ||
          isInside(x2, y2, xC, yC, x2, y1, br.x, bl.y)
        const pointInTopTriangle =
          isInside(xC, yC, x1, y1, x2, y1, x, y) ||
          isInside(xC, yC, x1, y1, x2, y1, bl.x, bl.y) ||
          isInside(xC, yC, x1, y1, x2, y1, tl.x, tl.y) ||
          isInside(xC, yC, x1, y1, x2, y1, tr.x, tr.y) ||
          isInside(xC, yC, x1, y1, x2, y1, br.x, bl.y)
        const pointInBottomTriangle =
          isInside(x1, y2, xC, yC, x2, y2, x, y) ||
          isInside(x1, y2, xC, yC, x2, y2, bl.x, bl.y) ||
          isInside(x1, y2, xC, yC, x2, y2, tl.x, tl.y) ||
          isInside(x1, y2, xC, yC, x2, y2, tr.x, tr.y) ||
          isInside(x1, y2, xC, yC, x2, y2, br.x, bl.y)

        const { tWidth, tHeight } = getTargetSize(target)
        if (pointInLeftTriangle) {
          // from left
          activeObject.set(
            'left',
            target.get('left') - tWidth / 2 - activeWidth / 2 - gap
          )
          intersect.outFromLeft = target.id
        } else if (pointInRightTriangle) {
          // from right
          activeObject.set(
            'left',
            target.get('left') + tWidth / 2 + activeWidth / 2 + gap
          )
          intersect.outFromRight = target.id
        } else if (pointInTopTriangle) {
          // from top
          activeObject.set(
            'top',
            target.get('top') - tHeight / 2 - activeHeight / 2 - gap
          )
          intersect.outFromTop = target.id
        } else if (pointInBottomTriangle) {
          // from bottom
          activeObject.set(
            'top',
            target.get('top') + tHeight / 2 + activeHeight / 2 + gap
          )
          intersect.outFromBottom = target.id
        }
      }
    }
  })

  if (activeObject.refreshLast) {
    // save last non-intersecting position if possible
    activeObject.set('lastLeft', activeObject.left)
    activeObject.set('lastTop', activeObject.top)
  }
}

function area(x1, y1, x2, y2, x3, y3) {
  return Math.abs((x1 * (y2 - y3) + x2 * (y3 - y1) + x3 * (y1 - y2)) / 2.0)
}

function isInside(x1, y1, x2, y2, x3, y3, x, y) {
  const A = area(x1, y1, x2, y2, x3, y3)

  const A1 = area(x, y, x2, y2, x3, y3)

  const A2 = area(x1, y1, x, y, x3, y3)

  const A3 = area(x1, y1, x2, y2, x, y)

  return A < A1 + A2 + A3 + 0.01 && A > A1 + A2 + A3 - 0.01
}

function isAlmostEqual(a, b) {
  return a < b + 0.01 && a > b - 0.01
}

export function isDeltaEqual(a, b, delta) {
  return a < b + delta && a > b - delta
}
