/*
 *
 * FloorsPage actions
 *
 */

import { ActionTypes } from './constants'

/**
 * Post Canvas Failed
 *
 * @returns {Object}
 */
export function postStoreCanvasFailed(error) {
  return {
    type: ActionTypes.POST_FLOORS_CANVAS_FAILED,
    payload: error,
  }
}

/**
 * Post Canvas Fulfilled
 *
 * @returns {Object}
 */
export function postStoreCanvasFinished(payload) {
  return {
    type: ActionTypes.POST_FLOORS_CANVAS_FINISHED,
    payload,
  }
}

/**
 * Post Canvas Pending
 *
 * @returns {Object}
 */
export function postStoreCanvasPending(canvasId) {
  return {
    type: ActionTypes.POST_FLOORS_CANVAS_PENDING,
    payload: {
      canvasId,
    },
  }
}

/**
 * Fetch Floors Failed
 *
 * @returns {Object}
 */
export function fetchFloorsFailed(error) {
  return {
    type: ActionTypes.FETCH_FLOORS_FAILED,
    payload: error,
  }
}

/**
 * Fetch Floors Fulfilled
 *
 * @returns {Object}
 */
export function fetchFloorsFulfilled(data, store, sObject, isStatic) {
  return {
    type: ActionTypes.FETCH_FLOORS_FULFILLED,
    payload: {
      canvas: data,
      store,
      sObject,
      isStatic,
    },
  }
}

/**
 * Fetch Floors Pending
 *
 * @returns {Object}
 */
export function fetchFloorsPending(storeId) {
  return {
    type: ActionTypes.FETCH_FLOORS_PENDING,
    payload: {
      storeId,
    },
  }
}

/**
 * Load Canvas
 *
 * @returns {Object}
 */
export function loadCanvas(
  isStatic,
  sObject, // selectedIndex
  selectedId, // selectedFloorId
  sId
) {
  return {
    type: ActionTypes.LOAD_FLOORS,
    payload: {
      isStatic,
      sObject,
      selectedId,
      sId,
    },
  }
}

/**
 * Save Canvas
 *
 *
 */
export function saveCanvas(canvas) {
  return {
    type: ActionTypes.SAVE_FLOORS_CANVAS,
    payload: {
      canvas,
    },
  }
}

/**
 * Save Floor
 *
 *
 */
export function saveFloor(payload) {
  return {
    type: ActionTypes.SAVE_FLOORS_FLOOR,
    payload,
  }
}

/**
 * Floor Canvas Changed
 *
 * @returns {Object}
 */
export function storeCanvasChanged(payload) {
  return {
    type: ActionTypes.FLOORS_CANVAS_CHANGED,
    payload,
  }
}

/**
 * Remove area
 *
 * @returns {Object}
 */
export function removeObject(payload) {
  return {
    type: ActionTypes.FLOORS_CANVAS_FLOOR_REMOVE,
    payload,
  }
}

/**
 * Floor is changed
 *
 * @returns {Object}
 */
export function changeFloor(payload) {
  return {
    type: ActionTypes.FLOORS_CANVAS_FLOOR_CHANGED,
    payload,
  }
}

/**
 * Create New Floor
 *
 * @returns {Object}
 */
export function createNewFloor(payload) {
  return {
    type: ActionTypes.FLOORS_CANVAS_FLOOR_CREATE,
    payload,
  }
}

/**
 * Image upload
 *
 * @returns {Object}
 */
export function imageUpload(payload) {
  return {
    type: ActionTypes.FLOORS_CANVAS_FLOOR_IMAGE_UPLOAD,
    payload,
  }
}
