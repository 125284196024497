/*
 *
 * Schedule reducer
 *
 */

import { fromJS } from 'immutable'
import { ActionTypes } from './constants'

const initialState = fromJS({
  schedules: null,
  failed: null,
  loading: false,
})

function schedulesEditReducer(state = initialState, { type, payload }) {
  switch (type) {
    case ActionTypes.SCHEDULE_DETAILS:
      console.log('ActionTypes.SCHEDULE_DETAILS', payload)
      return state
        .set('scheduleDetails', payload)
        .set('management', ActionTypes.SCHEDULE_DETAILS)
        .set('loading', false)
        .set('failed', null)
    case ActionTypes.CREATE_SCHEDULE:
      return state
        .set('scheduleDetails', undefined)
        .set('management', ActionTypes.CREATE_SCHEDULE)
        .set('failed', null)
    case ActionTypes.RESET_SCHEDULE_MANAGEMENT:
      return state
        .set('scheduleDetails', undefined)
        .set('management', undefined)
        .set('loading', false)
        .set('failed', null)
    case ActionTypes.DELETE_SCHEDULE_FAILED:
      return state.set('failed', payload).set('loading', false)

    case ActionTypes.POST_SCHEDULE_FULFILLED:
    case ActionTypes.PATCH_SCHEDULE_FULFILLED:
    case ActionTypes.DELETE_SCHEDULE_FINISHED:
      return state.set('failed', null).set('loading', false)

    case ActionTypes.DELETE_SCHEDULE_PENDING:
    case ActionTypes.POST_SCHEDULE_PENDING:
    case ActionTypes.PATCH_SCHEDULE_PENDING:
      return state.set('failed', null).set('loading', true)

    case ActionTypes.POST_SCHEDULE_FAILED:
    case ActionTypes.PATCH_SCHEDULE_FAILED:
      return state.set('failed', payload).set('loading', false)

    case ActionTypes.SCHEDULE_EDIT:
      return state.mergeDeep(state, payload)
    case ActionTypes.SCHEDULE_CLEAR:
      return state
        .delete('effectiveOn')
        .delete('mondayOpen')
        .delete('mondayClose')
        .delete('tuesdayOpen')
        .delete('tuesdayClose')
        .delete('wednesdayOpen')
        .delete('wednesdayClose')
        .delete('thursdayOpen')
        .delete('thursdayClose')
        .delete('fridayOpen')
        .delete('fridayClose')
        .delete('saturdayOpen')
        .delete('saturdayClose')
        .delete('sundayOpen')
        .delete('sundayClose')
        .set('failed', null)
    default:
      return state
  }
}

export default schedulesEditReducer
