import React from 'react'
import PropTypes from 'prop-types'
import Chance from 'chance'
import { fullTextFilter } from '../../../utils/utils'
import TrComponent from '../../../components/TrComponent'
import checkboxHOC from 'react-table/lib/hoc/selectTable'
import {
  CellContainer,
  renderFilterInput,
  renderHeaderCell,
} from '../../UsersPage/index.screen'
import { TableInModal } from '../../../styles/styled'
import Radio from '../../../components/Radio'

const CheckboxTable = checkboxHOC(TableInModal)

const chance = new Chance()

class MoveFixtureModalScreen extends React.PureComponent {
  constructor(props) {
    super(props)
    const columns = this.getColumns()
    const data = this.getData(this.props.areas)
    this.state = {
      data,
      columns,
      selection: [],
      selectAll: false,
    }
  }

  getData(keys) {
    return (keys || []).map((item) => {
      const _id = chance.guid() // eslint-disable-line
      return {
        _id,
        ...item,
      }
    })
  }

  floorName = (floorId) => {
    const { floors } = this.props
    return floors.find(({ id }) => id === floorId).name
  }

  getColumns = () => {
    const { floors } = this.props
    const columns = [
      {
        filterable: true,
        Header: renderHeaderCell('Area Name'),
        accessor: 'name',
        Cell: ({ original: { name } }) => <CellContainer>{name}</CellContainer>,
        Filter: ({ filter, onChange }) =>
          renderFilterInput({
            placeholder: 'Filter',
            filter,
            onChange,
          }),
      },
    ]

    const floorColumn = {
      id: 'floorColumn',
      filterable: true,
      Header: renderHeaderCell('Floor Name'),
      accessor: ({ floorId }) => this.floorName(floorId),
      Cell: ({ original: { floorId } }) => (
        <CellContainer>{this.floorName(floorId)}</CellContainer>
      ),
      Filter: ({ filter, onChange }) =>
        renderFilterInput({
          placeholder: 'Filter',
          filter,
          onChange,
        }),
    }

    if (floors.length > 1) {
      columns.push(floorColumn)
    }

    return columns
  }

  isSelected = (key) => this.state.selection.includes(key)

  toggleSelection = (key) => {
    this.setState({ selection: [key] })
  }

  renderContent = () => {
    const { toggleSelection, toggleAll, isSelected } = this
    const { selectAll, data, columns } = this.state

    const checkboxProps = {
      selectAll,
      isSelected,
      toggleSelection,
      toggleAll,
      SelectInputComponent: (props) => (
        <Radio
          id={`device-${props.id}`}
          name={'security-device'}
          onChange={(e) => {
            var shiftKey = e.shiftKey

            e.stopPropagation()
            props.onClick(props.id, shiftKey, props.row)
          }}
          checked={props.checked}
        />
      ),
      selectType: 'radio',
      getTrProps: (s, r) => {
        // background color change
        const selected = this.isSelected(((r || {}).original || {})._id) // eslint-disable-line
        return {
          style: {
            backgroundColor: selected ? 'lightgreen' : 'inherit',
          },
        }
      },
    }

    return (
      <CheckboxTable
        ref={(r) => {
          this.checkboxTable = r
        }}
        data={data}
        columns={columns}
        className="-highlight"
        defaultFilterMethod={fullTextFilter}
        defaultPageSize={10}
        TrComponent={TrComponent}
        nextText={'>'}
        previousText={'<'}
        rowsText={''}
        showPageSizeOptions={false}
        defaultSorted={[
          {
            id: 'name',
          },
        ]}
        {...checkboxProps}
      />
    )
  }

  onConfirm = () => {
    const { selection, data } = this.state
    const area = data.find(({ _id }) => _id === selection[0])
    this.props.onConfirm(area)
  }

  render() {
    const { onClose, id } = this.props
    return (
      <div className="modal-content">
        <div className="modal-header border-bottom">
          <h5 className="modal-title">Choose new Area for Fixture</h5>
          <div>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                $(`#modal-${id}`).modal('hide') // eslint-disable-line
                onClose()
              }}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary btn-raised"
              onClick={() => {
                $(`#modal-${id}`).modal('hide') // eslint-disable-line
                this.onConfirm()
              }}
            >
              Move
            </button>
          </div>
        </div>
        <div className="modal-body">{this.renderContent()}</div>
      </div>
    )
  }
}

export default MoveFixtureModalScreen

MoveFixtureModalScreen.propTypes = {
  areas: PropTypes.array.isRequired,
  floors: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
}
