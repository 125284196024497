/**
 *
 * TemplateFixture sagas
 *
 */

import { call, put, select, all, takeLatest } from 'redux-saga/effects'
import { modelActionCreators } from 'mti-jsclient-shared'
import {
  postTemplate,
  patchTemplate,
  getTemplate,
  deleteFixtureRule as deleteFixtureRuleRequest,
  getProducts,
  postFixtureRule,
  patchFixtureRule,
} from '../../api'
import { upsert } from '../App/sagas/ormSaga'
import { ActionTypes } from './constants'
import {
  clearCachedData,
  storePrototypes,
  storeParams,
  setTemplateFixtureCanvasError,
  setLoading,
  fetchTemplateFixtureCanvasFulfilled,
  templateSaved,
  updateInheritance,
  updateCloneableCopyable,
} from './actions'
import {
  makeSelectTemplateFixture,
  makeSelectInheritanceRule,
  makeSelectTemplateNames,
} from './selectors'
import { makeSelectFixtureTemplate as makeSelectFixtureTemplateById } from '../TemplatePositions/selectors'
import {
  errorToast,
  loadingType as loading,
  successToast,
} from '../../utils/utils'
import { makeSelectToken } from '../../containers/App/selectors'
import { getPrototypes, toFabricSync } from '../../containers/AreaPage/utils'
import {
  fromFabricFixtureSave,
  addMtiType,
} from '../../containers/AreaPage/utilsSave'
import {
  filterLayoutPositionGeometry,
  apiFixGeometryResourceId,
  fixFixtureRulesFieldName,
} from '../../utils/mtiUtils'
import { defaultName } from '../../utils/mtiCanvasUtils'
import { loadTemplates } from '../Templates/actions'

export function* prepareTemplateFixtureCanvas({ payload }) {
  const { screen, isStatic, tId } = payload

  try {
    yield put(clearCachedData())
    const prototypes = yield getPrototypes()
    yield put(storePrototypes(prototypes))
    yield put(storeParams({ screen, isStatic }))
    if (tId) {
      yield put(setLoading(loading.visible))
      const token = yield select(makeSelectToken())

      const data = yield call(getTemplate, token, tId)
      const store = fixFixtureRulesFieldName(
        filterLayoutPositionGeometry(apiFixGeometryResourceId(data, 'fixtures'))
      )
      yield call(upsert, { payload: store })
      const fixture = yield select(makeSelectFixtureTemplateById(tId))
      // Update inheritance
      const { layoutPosition: { theta, size } = {}, cloneable, copyable } =
        fixture || {}
      yield put(updateInheritance({ size: !!size, angle: !!theta }))
      // Update Cloneable Copyable
      yield put(updateCloneableCopyable({ cloneable, copyable }))

      const products = yield call(getProducts, token)
      yield put(modelActionCreators.upsertManufacturers(products.manufacturers))
      yield put(modelActionCreators.upsertProducts(products.products))

      yield put(
        fetchTemplateFixtureCanvasFulfilled(
          toFabricSync(
            { fixtures: [fixture] },
            screen,
            isStatic,
            false,
            undefined,
            prototypes
          ),
          0,
          isStatic
        )
      )
    }
    yield put(setLoading(loading.off))
    if (!(yield select(makeSelectTemplateNames()))) {
      yield put(loadTemplates(false))
    }
  } catch (error) {
    console.warn(error)
    const res = error.response
    const errorObj = res ? yield call([res, res.json]) : error
    yield put(setTemplateFixtureCanvasError(errorObj))
    yield put(setLoading(loading.off))
    errorToast('Prepare fixture template failed')
  }
}

export function* saveTemplate({ payload: { template, screen } }) {
  try {
    yield put(setLoading(loading.silent))
    const token = yield select(makeSelectToken())
    const { size: inheritSize, angle: inheritAngle } = yield select(
      makeSelectInheritanceRule()
    ) || {}

    const fixture = fromFabricFixtureSave(
      addMtiType(yield select(makeSelectTemplateFixture())),
      screen
    )

    const { name, cloneable, copyable, template: isTemplate } = template

    const {
      id,
      height,
      angle,
      sviSizeType,
      sviType,
      // theta,
      type,
      width,
      // xCenter,
      // yCenter,
    } = fixture
    const fixtureTemplate = {
      id,
      name: name || defaultName.fixture,
      type,
      shouldDisplayName: true,
      cloneable,
      copyable,
      template: isTemplate,
      geometry: {
        angle: inheritAngle ? angle : undefined,
        height,
        resourceCollection: 'fixture',
        // resourceId: 'rdbClientId1424',
        size: inheritSize ? sviSizeType : undefined,
        type: sviType,
        width,
        x: null, // xCenter, // It was decided by MTI management that the fixture will not inherit coordinates from the template
        y: null, // yCenter, // It was decided by MTI management that the fixture will not inherit coordinates from the template
        targetType: 'fixture',
        geometryType: 'geometry',
      },
    }
    let data
    if (isNaN(fixtureTemplate.id)) {
      data = yield call(postTemplate, token, fixtureTemplate)
      successToast('Template Created')
    } else {
      data = yield call(patchTemplate, token, fixtureTemplate)
      successToast('Template Updated')
    }
    // Update orm with seved template
    const store = filterLayoutPositionGeometry(
      apiFixGeometryResourceId(data, 'fixtures')
    )
    yield call(upsert, { payload: store })

    const { fixtures: [newTemplate] } = data
    yield put(setLoading(loading.off))
    yield put(loadTemplates(true)) // Sync templates for usage as parents of the store fixtures
    yield put(templateSaved(newTemplate))
  } catch (error) {
    console.warn(error)
    const res = error.response
    const errorObj = res ? yield call([res, res.json]) : error
    yield put(setTemplateFixtureCanvasError(errorObj))
    yield put(setLoading(loading.off))
    errorToast('Save template failed')
  }
}

function* createFixtureRule({ payload }) {
  const { fixtureId, rule } = payload
  try {
    yield put(setLoading(loading.silent))
    const token = yield select(makeSelectToken())
    yield call(postFixtureRule, token, fixtureId, rule)

    yield put(setLoading(loading.off))
    yield put(loadTemplates(true)) // Sync templates for usage as parents of the store fixtures
  } catch (error) {
    console.warn(error)
    yield put(setLoading(loading.off))
    errorToast('Create fixture failed')
  }
}

function* editFixtureRule({ payload }) {
  const { fixtureId, rule } = payload
  try {
    yield put(setLoading(loading.silent))
    const token = yield select(makeSelectToken())
    yield call(patchFixtureRule, token, fixtureId, rule)

    yield put(setLoading(loading.off))
    yield put(loadTemplates(true)) // Sync templates for usage as parents of the store fixtures
  } catch (error) {
    console.warn(error)
    yield put(setLoading(loading.off))
    errorToast('Update fixture failed')
  }
}

function* deleteFixtureRule({ payload }) {
  const { fixtureId, rule } = payload
  try {
    yield put(setLoading(loading.silent))
    const token = yield select(makeSelectToken())
    yield call(deleteFixtureRuleRequest, token, fixtureId, rule.id)

    yield put(setLoading(loading.off))
    yield put(loadTemplates(true)) // Sync templates for usage as parents of the store fixtures
  } catch (error) {
    console.warn(error)
    yield put(setLoading(loading.off))
    errorToast('Delete fixture failed')
  }
}

function* deleteAllFixtureRules({ payload }) {
  try {
    yield put(setLoading(loading.silent))
    const token = yield select(makeSelectToken())
    const { fixtureComplianceRules, fixtureId } = payload
    yield all(
      fixtureComplianceRules.map((rule) =>
        call(deleteFixtureRuleRequest, token, fixtureId, rule.id)
      )
    )
    yield put(setLoading(loading.off))
    yield put(loadTemplates(true)) // Sync templates for usage as parents of the store fixtures
  } catch (e) {
    console.warn(e)
    yield put(setLoading(loading.off))
    errorToast('Delete fixtures rules failed')
  }
}

export default function* root() {
  yield all([
    yield takeLatest(
      ActionTypes.PREPARE_TEMPLATE_FIXTURE_CANVAS,
      prepareTemplateFixtureCanvas
    ),
    yield takeLatest(ActionTypes.SAVE_TEMPLATE_FIXTURE_CANVAS, saveTemplate),
    yield takeLatest(ActionTypes.CREATE_FIXTURE_RULE, createFixtureRule),
    yield takeLatest(ActionTypes.EDIT_FIXTURE_RULE, editFixtureRule),
    yield takeLatest(ActionTypes.DELETE_FIXTURE_RULE, deleteFixtureRule),
    yield takeLatest(
      ActionTypes.DELETE_ALL_FIXTURE_RULES,
      deleteAllFixtureRules
    ),
  ])
}
