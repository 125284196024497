/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux-immutable'
import { fromJS } from 'immutable'
import { LOCATION_CHANGE } from 'react-router-redux'
import { orm } from 'mti-jsclient-shared'

import languageProviderReducer from './containers/LanguageProvider/reducer'
import { ActionTypes } from './containers/App/constants'

/*
 * routeReducer
 *
 * The reducer merges route location changes into our immutable state.
 * The change is necessitated by moving to react-router-redux@4
 *
 */

// Initial routing state
const routeInitialState = fromJS({
  location: null,
})

/**
 * Merge route into the global application state
 */
function routeReducer(state = routeInitialState, action) {
  switch (action.type) {
    /* istanbul ignore next */
    case LOCATION_CHANGE:
      return state.merge({
        location: action.payload,
      })
    default:
      return state
  }
}

export function defaultUpdater(session, action) {
  session.sessionBoundModels.forEach((modelClass) => {
    if (typeof modelClass.reducer === 'function') {
      // This calls this.applyUpdate to update this.state
      modelClass.reducer(action, modelClass, session)
    }
  })
}

export const createReducerOrm = (ORM, updater = defaultUpdater) => (
  state,
  action
) => {
  let ormState = state || ORM.getEmptyState()

  switch (action.type) {
    case ActionTypes.ORM_RESET:
      ormState = ORM.getEmptyState()
      break

    default:
      break
  }

  const session = ORM.session(ormState)
  updater(session, action)
  return session.state
}

/**
 * Creates the main reducer with the dynamically injected ones
 */
export const createReducer = (injectedReducers) => (state, action) =>
  combineReducers({
    route: routeReducer,
    language: languageProviderReducer,
    orm: createReducerOrm(orm),
    ...injectedReducers,
  })(
    action.type === ActionTypes.USER_LOGOUT_SUCCESS ? undefined : state,
    action
  )
