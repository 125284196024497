export const namespace = 'orphanedDevices'

/**
 * @constant {Object} ActionTypes
 * @memberof Constants
 */
export const ActionTypes = {
  FETCH_ORPHANED_DEVICES_DATA: 'FETCH_ORPHANED_DEVICES_DATA',
  ORPHANED_DEVICES_DATA_REDUCER: 'ORPHANED_DEVICES_DATA_REDUCER',
  LOADING_ORPHANED_DEVICES: 'LOADING_ORPHANED_DEVICES',
}
