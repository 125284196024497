import { createSelector } from 'reselect'

const selectFirmwaresReducer = (state) => state.get('firmwaresReducer')

const makeSelectFirmwares = () =>
  createSelector(selectFirmwaresReducer, (state) => state.get('firmwares'))

const makeSelectFirmwaresExist = () =>
  createSelector(selectFirmwaresReducer, (state) => {
    const firmwares = state.get('firmwares')
    return firmwares.length > 0
  })

const makeSelectFetchFailed = () =>
  createSelector(selectFirmwaresReducer, (state) => state.get('fetchFailed'))

const makeSelectFetchLoading = () =>
  createSelector(selectFirmwaresReducer, (state) => state.get('fetchLoading'))

const makeSelectUploadFailed = () =>
  createSelector(selectFirmwaresReducer, (state) => state.get('uploadFailed'))

const makeSelectUploadLoading = () =>
  createSelector(selectFirmwaresReducer, (state) => state.get('uploadLoading'))

const makeSelectDeleteFailed = () =>
  createSelector(selectFirmwaresReducer, (state) => state.get('deleteFailed'))

const makeSelectDeleteLoading = () =>
  createSelector(selectFirmwaresReducer, (state) => state.get('deleteLoading'))

export {
  makeSelectFetchFailed,
  makeSelectFetchLoading,
  makeSelectFirmwares,
  makeSelectUploadFailed,
  makeSelectUploadLoading,
  makeSelectDeleteFailed,
  makeSelectDeleteLoading,
  makeSelectFirmwaresExist,
}
