import React from 'react'
import { all, call, put, takeLatest, select } from 'redux-saga/effects'
import { forgotPassword } from '../../api/'
import { ActionTypes } from './constants'
import {
  postForgotPasswordFailed,
  postForgotPasswordPending,
  postForgotPasswordFulfilled,
} from './actions'
import { makeSelectForgotPasswordEmail } from './selectors'
import { closeModal, openModal } from '../App/actions'
import NotifyModal from '../ModalContainer/NotifyModal'
import { errorToast } from '../../utils/utils'

export function* postForgotPassword({ payload }) {
  const email = yield select(makeSelectForgotPasswordEmail())
  if (email) {
    try {
      yield put(postForgotPasswordPending())
      const params = {
        subdomain: process.env.SUBDOMAIN || 'develop.beta',
      }
      const { meta: { message } } = yield call(forgotPassword, email, params)
      yield put(postForgotPasswordFulfilled())
      yield put(
        openModal({
          id: 'notify-forgot',
          type: 'bootstrap',
          appearance: 'modal-sm',
          content: (
            <NotifyModal
              id={'notify-forgot'}
              notifyText={message}
              onClose={() => {
                payload.history.push('/')
                closeModal({ id: 'notify-forgot' })
              }}
            />
          ),
        })
      )
    } catch (error) {
      const res = error.response
      const errorObj = res ? yield call([res, res.json]) : error
      yield put(postForgotPasswordFailed(errorObj))
      errorToast('Forgot password failed')
    }
  }
}

export default function* root() {
  yield all([
    yield takeLatest(ActionTypes.POST_FORGOT_PASSWORD, postForgotPassword),
  ])
}
