import React from 'react'
import MoveFixtureModalScreen from './index.screen'
import PropTypes from 'prop-types'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { closeModal, openModal } from '../../App/actions'
import { makeSelectAreasInStore } from '../selectors'
import { makeSelectLoading, makeSelectStores } from '../../StoresPage/selectors'

class MoveFixtureModal extends React.PureComponent {
  render() {
    const { storesLoading, stores, storeId, currentAreaId } = this.props
    if (storesLoading) {
      return <div />
    }
    const store = stores.find(({ id }) => id == storeId)
    const areas = store.areas.filter(({ id }) => id !== currentAreaId)

    return (
      <MoveFixtureModalScreen
        areas={areas}
        floors={store.floors}
        onClose={() => {
          closeModal({ id: 'move-fixture' })
        }}
        onConfirm={(area) => {
          this.props.onConfirm(area)
        }}
        id={'move-fixture'}
      />
    )
  }
}

const mapStateToProps = createStructuredSelector({
  stores: makeSelectStores(),
  store: (state, ownProps) =>
    makeSelectAreasInStore(ownProps.storeId)(state, ownProps),
  storesLoading: makeSelectLoading(),
})

const mapDispatchToProps = {
  openModal,
  closeModal,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect)(MoveFixtureModal)

MoveFixtureModal.propTypes = {
  storeId: PropTypes.number.isRequired,
  currentAreaId: PropTypes.number.isRequired,
}
