import request, { requestWithTimeout } from '../utils/request'
import { restoreStoreCallWithInclude } from '../containers/App/localStorageOptions'

// TODO: Look into why the mqtt client is using the clientId, since it
// shouldn't need it.
export const clientId = process.env.CLIENT_ID || '_dZc23hPbNZUEbwAoa7LlQ'
export const mqttHost = process.env.MQTT_HOST || 'wss://realtime.sviconnectedstore.com:8083/ws'

const ApiRoot = process.env.API_ROOT || 'https://qaconnectedstore.com'
const clientSecret = process.env.CLIENT_SECRET || 'uEcOdNRHLyztcF-3qc9iNTWRLr5Mi4gXJo9P_vzJqR8'
const saltAPI = process.env.API_SALT_ROOT || 'https://api.salt.sviconnectedstore.com:443'
const SubDomain = process.env.SUBDOMAIN || 'develop.beta'

const api = `${ApiRoot}/api`
const adminApi = `${ApiRoot}/admin_api`
const authenticationsUrl = `${api}/authentications`
const me = `${api}/me`
const permissionsUrl = `${api}/me/access`
const personaeRoles = `${api}/personae/`
const personaeAssignedToUrl = id => `${api}/personae/assigned_to/${id}`
const personaeAssigningUrl = id => `${api}/personae/${id}/apply`
const personaeUnassigningUrl = id => `${api}/personae/${id}/remove`
const keysUrl = `${api}/physical_keys/`
const keyUrl = id => `${api}/physical_keys/${id}`
const usersUrl = `${api}/users`
const userUrl = id => `${api}/users/${id}`
const storesUrl = `${api}/stores/`
const storeUrl = id => `${api}/stores/${id}`
const floorsUrl = `${api}/floors`
const floorUrl = id => `${api}/floors/${id}`
const areasUrl = `${api}/areas`
const areaUrl = id => `${api}/areas/${id}`
const fixturesUrl = `${api}/fixtures`
const fixtureUrl = id => `${api}/fixtures/${id}`
const positionsUrl = `${api}/positions`
const positionUrl = id => `${api}/positions/${id}`
const positionActionUrl = (id, action) => `${api}/positions/${id}/${action}`
const adminOrganizationsUrl = `${adminApi}/organizations`
const organizationsUrl = `${api}/organization`
const products = `${api}/products`
const securityDevices = `${api}/security_devices`
const securityDeviceActionUrl = (id, action) => `${api}/security_devices/${id}/${action}`
const storeActionUrl = (id, action) => `${api}/stores/${id}/${action}`
const storeLockdownUrl = id => `${api}/stores/${id}/lockdown`

const hubsURL = (storeId, hubId = '') => `${storeUrl(storeId)}/connect_hubs/${hubId}`
const provisionHubURL = (storeId, hubId) => `${hubsURL(storeId, hubId)}/provision`

const securedProductsUrl = `${api}/secured_products`
const securedProductUrl = id => `${api}/secured_products/${id}`
const manufacturers = `${api}/manufacturers`
const templatesUrl = `${api}/templates`
const templateUrl = id => `${api}/templates/${id}`
const fixtureRulesUrl = fixtureId => `${api}/fixtures/${fixtureId}/rules`
const fixtureRuleUrl = (fixtureId, ruleId) => `${api}/fixtures/${fixtureId}/rules/${ruleId}`
const positionRulesUrl = positionId => `${api}/positions/${positionId}/rules`
const positionRuleUrl = (positionId, ruleId) => `${api}/positions/${positionId}/rules/${ruleId}`
const layoutTableViewUrl = `${api}/table_view`

// Reports
const healthUrl = `${api}/reports/health`
const operationsUrl = `${api}/reports/operations`
const complianceUrl = `${api}/reports/compliance`
const marketingUrl = `${api}/reports/marketing`
const reportsUrl = `${api}/reports`
const healthExceptionUrl = `${api}/reports/health_exception`
const complianceExceptionUrl = `${api}/reports/compliance_exception`
const operationsExceptionUrl = `${api}/reports/operations_exception`

// Tags
const taggingsUrl = `${api}/taggings`
const taggingUrl = id => `${api}/taggings/${id}`
const tagsUrl = `${api}/tags`

// MTI Admin
const adminSignInUrl = `${ApiRoot}/admin_api/authentications`
const adminMe = `${ApiRoot}/admin_api/me`
const organizationUrl = id => `${ApiRoot}/api/organization?organization_id=${id}`

// OTA
const fmManagementSignInUrl = `${ApiRoot}/admin_api/fw_management_authentications`
const firmwaresUrl = `${api}/firmware_images`
const minionsUrl = `${saltAPI}/minions`

// Regions
const regionsUrl = `${api}/regions/`
const regionUrl = id => `${api}/regions/${id}`

// Schedules
const schedulesUrl = `${api}/schedules/`
const scheduleUrl = id => `${api}/schedules/${id}`

// Device Configurations
const deviceConfigurationsUrl = `${api}/device_configurations`
const deviceConfigurationUrl = id => `${deviceConfigurationsUrl}/${id}`

const SALT_COMMANDS_TIMEOUT = 60 * 1000 * 3

/**
 * Get the query string from an {...}
 * @param obj query
 * @returns {string}
 */
function queryString(obj = {}) {
  return Object.keys(obj)
    .filter(key => !(obj[key] === undefined || obj[key] === null))
    .map(key => `${key}=${encodeURIComponent(obj[key])}`)
    .join('&')
}

/**
 * Get the query FormData from an {...}
 * @param obj query
 * @returns {string}
 */
function queryFormData(obj = {}) {
  const data = new FormData()
  Object.keys(obj)
    .filter(key => obj[key])
    .forEach(key => data.append(key, obj[key]))
  return data
}

export function orgIdUrl() {
  const pathParams = window.location.pathname.split('/') || []
  const isMtiAdmin = pathParams[1] === 'organizations'
  return isMtiAdmin ? pathParams[2] : undefined
}

/**
 * User sign in
 * @param payload data
 * @returns {Object}
 */
export function signIn(payload) {
  const data = {
    authentication: {
      authType: 'password',
      clientId,
      clientSecret,
      username: payload.email,
      password: payload.password,
    },
  }

  return request(`${authenticationsUrl}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(data),
  })
}

/**
 * Admin sign in
 * @param payload data
 * @returns {Object}
 */
export function adminSignIn({ email, password }) {
  return request(adminSignInUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify({
      authentication: {
        authType: 'password',
        username: email,
        password,
        clientId,
        clientSecret,
      },
    }),
  })
}

/**
 * OTP login follow-up
 * @param payload data
 * @returns {Object}
 */
export function otpRequest({ otp, token }) {
  return request(`${adminApi}/onetime_code`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json; charset=utf-8' },
    body: JSON.stringify({
      onetime_code: {
        token,
        onetime_code: otp,
      },
    }),
  })
}

/**
 * Get admin me
 * @param token
 * @returns {Object}
 */
export function getAdminMe(token) {
  const query = {
    organizationId: orgIdUrl(),
  }
  return request(`${adminMe}?${queryString(query)}`, {
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` },
  })
}

/**
 * User sign out
 * @param token
 * @returns {Object}
 */
export function signOut(token) {
  return request(authenticationsUrl, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

/**
 * Get list of stores
 * @param token
 * @param include
 * @returns {Object}
 */
export function getStores(token, include) {
  const query = {
    include,
    organizationId: orgIdUrl(),
    // page: 0,
    per: 10000, // TODO: Pagination
  }
  return request(`${storesUrl}?${queryString(query)}`, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

/**
 * Get list of stores for stores dashboard
 * @param token
 * @param filters
 * @returns {Object}
 */
export function getStoresDashboard(token, orgID, queryParams) {
  const query = {
    organizationId: orgID,
    include: [],
    ...queryParams,
  }
  return request(`${api}/stores?${queryString(query)}`, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

/** Get list of stores managed by the current user
 * @param token
 * @returns {Object}
 */
export function getManagedStores(token) {
  const query = {
    include: 'stores',
    managed: true,
    organizationId: orgIdUrl(),
    // page: 0,
    per: 10000, // TODO: Pagination
  }
  return request(`${storesUrl}?${queryString(query)}`, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

/**
 * Get store data by Id
 * @param token
 * @param id
 * @param include
 * @returns {Object}
 */
export function getStoreById(token, id, include) {
  const isStoreCallWithInclude = restoreStoreCallWithInclude()
  const query = {
    include:
      include ||
      (isStoreCallWithInclude
        ? [
          'image,store_rules,floors,floors.preview_image,areas,areas.geometry,fixtures,fixtures.geometry,fixtures.rules,fixtures.compliance_rules,fixtures.positions,fixtures.positions.geometry,fixtures.positions.rules,security_devices',
        ]
        : undefined),
    organizationId: orgIdUrl(),
  }
  return request(`${storeUrl(id)}?${queryString(query)}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

/**
 * Post Store
 * @param token
 * @param store
 * @return {Object}
 */
export function postStore(token, store) {
  const query = {
    organizationId: orgIdUrl(),
    include: [],
  }
  return request(`${storesUrl}?${queryString(query)}`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify({
      store: {
        name: store.name,
        branch_code: store.branchCode,
        contact_name: store.contactName,
        contact_email: store.contactEmail,
        contact_phone: store.contactPhone,
        store_phone: store.storePhone,
        time_zone: store.timeZone,
        latitude: null,
        longitude: null,
        region_id: store.regionId,
        address: {
          address_1: (store.address || {}).address1,
          address_2: (store.address || {}).address2,
          address_3: (store.address || {}).address3,
          city: (store.address || {}).city,
          state: (store.address || {}).state,
          zip: (store.address || {}).zip,
          country: (store.address || {}).country,
        },
        image: store.image,
        allowed_key_types: store.allowedKeyTypes,
      },
    }),
  })
}

/**
 * Patch Store
 * @param token
 * @param store
 * @return {Object}
 */
export function patchStore(token, id, store) {
  const query = {
    organizationId: orgIdUrl(),
    include: [],
  }
  return request(`${storeUrl(id)}?${queryString(query)}`, {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify({
      store: {
        name: store.name,
        branchCode: store.branchCode,
        contactName: store.contactName,
        contactEmail: store.contactEmail,
        contactPhone: store.contactPhone,
        storePhone: store.storePhone,
        timeZone: store.timeZone,
        regionId: store.regionId,
        address: store.address && {
          city: (store.address || {}).city,
          state: (store.address || {}).state,
          zip: (store.address || {}).zip,
          country: (store.address || {}).country,
          address1: (store.address || {}).address1,
          address2: (store.address || {}).address2,
          address3: (store.address || {}).address3,
        },
        image: store.image,
        allowedKeyTypes: store.allowedKeyTypes,
      },
    }),
  })
}

/**
 * Patch Store
 * @param token
 * @param store
 * @return {Object}
 */
export function storeLockdown(token, id, mode) {
  const query = {
    organizationId: orgIdUrl(),
    include: [],
  }
  return request(`${storeLockdownUrl(id)}?${queryString(query)}`, {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify({
      store: { mode },
    }),
  })
}

/**
 * Provision Hub
 * @param token
 * @param store
 * @return {Object}
 */
export function patchProvisionHub(token, storeId, hubId) {
  const query = {
    organizationId: orgIdUrl(),
    include: [],
  }
  return request(`${provisionHubURL(storeId, hubId)}?${queryString(query)}`, {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: null,
  })
}

/**
 * Get Hub(s)
 * @param token
 * @param store
 * @return {Object}
 */
export function getHubs(token, storeId, hubId = '') {
  const query = {
    organizationId: orgIdUrl(),
    include: [],
  }
  return request(`${hubsURL(storeId, hubId)}?${queryString(query)}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: null,
  })
}

/**
 * Get list of Schedules
 * @param token
 * @param storeId
 * @returns {Object}
 */
export function getSchedules(token, storeId) {
  const query = {
    store_id: storeId,
    organizationId: orgIdUrl(),
    // page: 0,
    per: 10000, // TODO: Pagination
    include: [],
  }
  return request(`${schedulesUrl}?${queryString(query)}`, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

/**
 * Get list of regions
 * @param token
 * @param include
 * @returns {Object}
 */
export function getRegions(token, include) {
  const query = {
    include: include || [],
    organizationId: orgIdUrl(),
    // page: 0,
    per: 10000, // TODO: Pagination
  }
  return request(`${regionsUrl}?${queryString(query)}`, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

/**
 * Get store data by Id
 * @param token
 * @param id
 * @returns {Object}
 */
export function getRegionById(token, id, include = []) {
  const query = {
    organizationId: orgIdUrl(),
    include,
  }
  return request(`${regionUrl(id)}?${queryString(query)}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

/**
 * Post region
 * @param token
 * @param region
 * @returns {Object}
 */
export function postRegion(token, region) {
  const query = {
    organizationId: orgIdUrl(),
    include: [],
  }
  const body = {
    region: {
      name: region.name,
    },
  }
  return request(`${regionsUrl}?${queryString(query)}`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(body),
  })
}

/**
 * Delete region data by Id
 * @param token
 * @param id
 * @returns {Object}
 */
export function deleteRegionById(token, id) {
  const query = {
    organizationId: orgIdUrl(),
    include: [],
  }
  return request(`${regionUrl(id)}?${queryString(query)}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

/**
 * Patch region
 * @param token
 * @param id
 * @param region
 * @returns {Object}
 */
export function patchRegion(token, id, region) {
  const query = {
    organizationId: orgIdUrl(),
    include: [],
  }
  const body = {
    region: {
      name: region.name,
      storeIds: region.storeIds,
    },
  }
  return request(`${regionUrl(id)}?${queryString(query)}`, {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(body),
  })
}

/**
 * Get me
 * @param token
 * @returns {Object}
 */
export function getMe(token) {
  const query = {
    organizationId: orgIdUrl(),
    include: 'roleResources',
  }
  return request(`${me}?${queryString(query)}`, {
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` },
  })
}

/**
 * Get the list of user permissions
 * @param token
 * @returns {Object}
 */
/** Deprecated */
export function getUserPermissions(token) {
  const query = {
    organizationId: orgIdUrl(),
    include: [],
  }
  return request(`${permissionsUrl}?${queryString(query)}`, {
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` },
  })
}

/**
 * Get Personae Roles
 * @param token
 * @param include
 * @returns {Object}
 */
export function getPersonaeRoles(token, include) {
  const query = {
    forOrganization: orgIdUrl(),
    include: include || [],
  }
  return request(`${personaeRoles}?${queryString(query)}`, {
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` },
  })
}

/**
 * Get Personae Assigned To
 * @param token
 * @param id
 * @returns {Object}
 */
export function getPersonaeAssignedTo(token, id) {
  const query = {
    organizationId: orgIdUrl(),
    include: [],
  }
  return request(`${personaeAssignedToUrl(id)}?${queryString(query)}`, {
    method: 'GET',
    headers: { Authorization: `Bearer ${token}` },
  })
}

/**
 * Assign Personae Role
 * @param token
 * @param id
 * @param resourceId
 * @param personaeId
 * @param resourceType
 * @returns {Object}
 */
export function assignPersonaeRole(
  token,
  id,
  resourceId,
  personaeId,
  resourceType
) {
  const query = {
    organizationId: orgIdUrl(),
    include: [],
  }
  const body = {
    userId: id,
    resourceType,
    resourceId,
  }
  return request(`${personaeAssigningUrl(personaeId)}?${queryString(query)}`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(body),
  })
}

/**
 * Unassign Personae Role
 * @param token
 * @param id
 * @param resourceId
 * @param personaeId
 * @param resourceType
 * @returns {Object}
 */
export function unassignPersonaeRole(
  token,
  id,
  resourceId,
  personaeId,
  resourceType
) {
  const query = {
    organizationId: orgIdUrl(),
    include: [],
  }
  const body = {
    userId: id,
    resourceType,
    resourceId,
  }
  return request(
    `${personaeUnassigningUrl(personaeId)}?${queryString(query)}`,
    {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify(body),
    }
  )
}

/**
 * Post user
 * @param token
 * @param user
 * @returns {Object}
 */
export function postUser(token, user) {
  const query = {
    subdomain: SubDomain,
    organizationId: orgIdUrl(),
    include: [],
  }
  const body = {
    user: {
      password: user.password,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      phoneNumber: user.phoneNumber,
      passcode: user.passcode,
      employeeIdentifier: user.employeeIdentifier,
      welcomeEmail: `${user.welcomeEmail}`,
      avatar: user.avatar,
    },
    persona: user.persona,
    resourceIds: user.resourceIds,
  }
  return request(`${usersUrl}?${queryString(query)}`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(body),
  })
}

/** Get list of roles the user can assign and the resources they can be applied to
 * @param token
 * @returns {Object}
 */
export function getAssignableRoles(token) {
  const query = {
    organizationId: orgIdUrl(),
    // page: 0,
    per: 10000, // TODO: Pagination
    include: [],
  }
  return request(`${usersUrl}/assignable_roles?${queryString(query)}`, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

/**
 * Patch user
 * @param token
 * @param id
 * @param user
 * @returns {Object}
 */
export function patchUser(token, id, user) {
  const query = {
    organizationId: orgIdUrl(),
    include: [],
  }
  const body = {
    user: {
      password: user.password,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      phoneNumber: user.phoneNumber,
      passcode: user.passcode,
      employeeIdentifier: user.employeeIdentifier,
      avatar: user.avatar,
    },
    persona: user.persona,
    resourceIds: user.resourceIds,
  }
  return request(`${userUrl(id)}?${queryString(query)}`, {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(body),
  })
}

/**
 * Get users data sorted by attribute with direction
 * @param token
 * @param attribute
 * @param direction
 * @param email
 * @param employeeIdentifier
 * @returns {Object}
 */
export function getUsers(
  token,
  attribute,
  direction,
  email,
  employeeIdentifier,
  withInactive,
  page,
  per,
  filters = {},
) {

  const query = {
    'sort[attribute]': attribute,
    'sort[direction]': direction,
    email,
    employee_identifier: employeeIdentifier,
    organizationId: orgIdUrl(),
    page,
    per,

    // false: (default) no inactive or deleted users,
    // only: only inactive users
    // true: all users except deleted users
    with_inactive: withInactive,
    include: 'roleResources',
    ...filters,
  }

  return request(`${usersUrl}?${queryString(query)}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

/**
 * Activate a user
 * @param token
 * @param user id
 * @returns {Object}
 */
export function activateUser(token, userId) {
  const query = {
    include: [],
  }
  return request(`${userUrl(userId)}/activate?${queryString(query)}`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

/**
 * Deactivate a user
 * @param token
 * @param user id
 * @returns {Object}
 */
export function deactivateUser(token, userId) {
  const query = {
    include: [],
  }
  return request(`${userUrl(userId)}/deactivate?${queryString(query)}`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

/**
 * Delete user data by (s)he's Id
 * @param token
 * @param id
 * @returns {Object}
 */
export function deleteUserById(token, id) {
  const query = {
    organizationId: orgIdUrl(),
    include: [],
  }
  return request(`${userUrl(id)}?${queryString(query)}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

/**
 * Post Schedule
 * @param token
 * @param schedule
 * @returns {Object}
 */
export function postSchedule(token, schedule, storeId) {
  const query = {
    organizationId: orgIdUrl(),
    include: [],
  }
  const body = {
    schedule: {
      storeId: storeId,
      effectiveOn: schedule.effectiveOn,
      mondayOpen: schedule.mondayOpen,
      mondayClose: schedule.mondayClose,
      tuesdayOpen: schedule.tuesdayOpen,
      tuesdayClose: schedule.tuesdayClose,
      wednesdayOpen: schedule.wednesdayOpen,
      wednesdayClose: schedule.wednesdayClose,
      thursdayOpen: schedule.thursdayOpen,
      thursdayClose: schedule.thursdayClose,
      fridayOpen: schedule.fridayOpen,
      fridayClose: schedule.fridayClose,
      saturdayOpen: schedule.saturdayOpen,
      saturdayClose: schedule.saturdayClose,
      sundayOpen: schedule.sundayOpen,
      sundayClose: schedule.sundayClose,
    },
  }

  return request(`${schedulesUrl}?${queryString(query)}`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(body),
  })
}

/**
 * Patch schedule
 * @param token
 * @param id
 * @param schedule
 * @returns {Object}
 */
export function patchSchedule(token, id, schedule) {
  const query = {
    organizationId: orgIdUrl(),
    include: [],
  }
  const body = {
    schedule: {
      effectiveOn: schedule.effectiveOn,
      mondayOpen: schedule.mondayOpen,
      mondayClose: schedule.mondayClose,
      tuesdayOpen: schedule.tuesdayOpen,
      tuesdayClose: schedule.tuesdayClose,
      wednesdayOpen: schedule.wednesdayOpen,
      wednesdayClose: schedule.wednesdayClose,
      thursdayOpen: schedule.thursdayOpen,
      thursdayClose: schedule.thursdayClose,
      fridayOpen: schedule.fridayOpen,
      fridayClose: schedule.fridayClose,
      saturdayOpen: schedule.saturdayOpen,
      saturdayClose: schedule.saturdayClose,
      sundayOpen: schedule.sundayOpen,
      sundayClose: schedule.sundayClose,
    },
  }

  return request(`${scheduleUrl(id)}?${queryString(query)}`, {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(body),
  })
}

/**
 * Delete schedule data by Id
 * @param token
 * @param id
 * @returns {Object}
 */
export function deleteScheduleById(token, id) {
  const query = {
    organizationId: orgIdUrl(),
    include: [],
  }
  return request(`${scheduleUrl(id)}?${queryString(query)}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

/**
 * Get keys
 * @param token
 * @param storeId
 * @returns {Object}
 */
export function getKeys(token, storeId) {
  const query = {
    store_id: storeId,
    'sort[attribute]': 'updated_at',
    'sort[direction]': 'desc',
    organizationId: orgIdUrl(),
    // page: 0,
    per: 10000, // TODO: Pagination
    include: ['user'],
  }
  return request(`${keysUrl}?${queryString(query)}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

/**
 * Post key
 * @param token
 * @param status
 * @param organizationId
 * @param storeId
 * @param serialNumber
 * @param userId
 * @returns {Object}
 */
export function postKey(
  token,
  status,
  organizationId,
  storeId,
  serialNumber,
  userId,
  backupKey
) {
  const query = {
    organizationId: orgIdUrl(),
    include: [],
  }
  return request(`${keysUrl}?${queryString(query)}`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify({
      physical_key: {
        status,
        storeId,
        organizationId,
        serialNumber,
        userId,
        backupKey: !!backupKey,
      },
    }),
  })
}

/**
 * Put Key
 * @param token
 * @param id
 * @param status
 * @param storeId
 * @param serialNumber
 * @param userId
 * @returns {Object}
 */
export function putKey(token, id, status, storeId, serialNumber, userId) {
  const query = {
    organizationId: orgIdUrl(),
    include: [],
  }
  return request(`${keyUrl(serialNumber)}?${queryString(query)}`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify({
      physical_key: {
        status,
        serialNumber,
        storeId,
        userId,
      },
    }),
  })
}
/**
 * Delete Key
 * @param token
 * @param id
 * @returns {Object}
 */
export function deleteKey(token, id) {
  const query = {
    organizationId: orgIdUrl(),
    include: [],
  }
  return request(`${keyUrl(id)}?${queryString(query)}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json; charset=utf-8',
    },
  })
}

/**
 * Put floor
 * @param token
 * @param floorId
 * @param storeWithFloor
 * @returns {Object}
 */
export function putFloor(token, floorId, storeWithFloor) {
  const query = {
    organizationId: orgIdUrl(),
    include: [],
  }
  return request(`${floorUrl(floorId)}?${queryString(query)}`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(storeWithFloor),
  })
}

/**
 * Post floor
 * @param token
 * @param floor
 * @param storeId
 * @returns {Object}
 */
export function postFloor(token, floor, storeId) {
  const query = {
    organizationId: orgIdUrl(),
    include: [],
  }
  const body = {
    floor: {
      storeId,
      name: floor.name,
      order: floor.order,
      shouldDisplayBackground: floor.shouldDisplayBackground,
    },
  }
  return request(`${floorsUrl}?${queryString(query)}`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(body),
  })
}

/**
 * Patch floor
 * @param token
 * @param floor
 * @param storeId
 * @returns {Object}
 */
export function patchFloor(token, floor, storeId) {
  const query = {
    organizationId: orgIdUrl(),
    include: [],
  }
  const body = {
    floor: {
      storeId,
      name: floor.name,
      order: floor.order,
      previewImage: floor.image ? undefined : { _destroy: true },
      shouldDisplayBackground: floor.shouldDisplayBackground,
    },
  }
  return request(`${floorUrl(floor.id)}?${queryString(query)}`, {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(body),
  })
}

/**
 * Patch floor image
 * @param token
 * @param floor
 * @param storeId
 * @returns {Object}
 */
export function patchFloorImage(token, floor) {
  const query = {
    organizationId: orgIdUrl(),
    include: [],
  }
  const data = queryFormData({
    'floor[previewImage][file]': floor.image,
  })
  return request(`${floorUrl(floor.id)}?${queryString(query)}`, {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: data,
  })
}

/**
 * Delete floor
 * @param token
 * @param floor
 * @returns {Object}
 */
export function deleteFloor(token, floor) {
  const query = {
    organizationId: orgIdUrl(),
    include: [],
  }
  return request(`${floorUrl(floor.id)}?${queryString(query)}`, {
    method: 'DELETE',
    headers: { Authorization: `Bearer ${token}` },
  })
}

/**
 * Get floor
 * @param token
 * @param id
 * @returns {Object}
 */
export function getFloor(token, id) {
  const query = {
    organizationId: orgIdUrl(),
    include: [],
  }
  return request(`${floorUrl(id)}?${queryString(query)}`, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

/**
 * Get floors
 * @param token
 * @param storeId
 * @param include
 */
export function getFloors(token, storeId, include) {
  const query = {
    include: include || [],
    store_id: storeId,
    organizationId: orgIdUrl(),
  }
  return request(`${floorsUrl}?${queryString(query)}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

/**
 * Post area
 * @param token
 * @param area
 * @param storeId
 * @param floorId
 * @returns {Object}
 */
export function postArea(token, area, storeId, floorId) {
  const query = {
    organizationId: orgIdUrl(),
    include: [],
  }
  const body = {
    area: {
      storeId,
      floorId,
      name: area.name,
      shouldDisplayName: area.displayAreaName,
      geometry: {
        x: area.xCenter, // layout_position
        y: area.yCenter,
        width: area.width,
        height: area.height,
        angle: area.theta,
      },
    },
  }
  return request(`${areasUrl}?${queryString(query)}`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(body),
  })
}

/**
 * Patch area
 * @param token
 * @param area
 * @param storeId
 * @param floorId
 * @returns {Object}
 */
export function patchArea(token, area, storeId, floorId) {
  const query = {
    organizationId: orgIdUrl(),
    include: [],
  }
  const body = {
    area: {
      storeId,
      floorId,
      name: area.name,
      shouldDisplayName: area.displayAreaName ? true : false,
      geometry: {
        x: area.xCenter, // layout_position
        y: area.yCenter,
        width: area.width,
        height: area.height,
        angle: area.theta,
      },
    },
  }
  return request(`${areaUrl(area.id)}?${queryString(query)}`, {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(body),
  })
}

/**
 * Delete area
 * @param token
 * @param area
 * @returns {Object}
 */
export function deleteArea(token, area) {
  const query = {
    organizationId: orgIdUrl(),
    include: [],
  }
  return request(`${areaUrl(area.id)}?${queryString(query)}`, {
    method: 'DELETE',
    headers: { Authorization: `Bearer ${token}` },
  })
}

/**
 * Get area
 * @param token
 * @param id
 * @returns {Object}
 */
export function getArea(token, id) {
  const query = {
    organizationId: orgIdUrl(),
    include: [],
  }
  return request(`${areaUrl(id)}?${queryString(query)}`, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

/**
 * Get areas
 * @param token
 * @param storeId
 * @param include
 */
export function getAreas(token, storeId, include) {
  const query = {
    include: include || [],
    store_id: storeId,
    organizationId: orgIdUrl(),
  }
  return request(`${areasUrl}?${queryString(query)}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

/**
 * Post fixture
 * @param token
 * @param fixture
 * @param storeId
 * @param floorId
 * @param areaId
 * @returns {Object}
 */
export function postFixture(token, fixture, storeId, floorId, areaId) {
  const query = {
    organizationId: orgIdUrl(),
    include: [],
  }
  const geometry = fixture.geometry || {}
  const body = {
    fixture: {
      storeId,
      floorId,
      areaId,
      parentId: fixture.parentId,
      name: fixture.name,
      shouldDisplayName: fixture.nameHasShown || fixture.shouldDisplayName,
      type: 'table', //fixture.type, (some fixture types created in gen2 are not compatible with gen1) Note: gen2 relies on geometry type
      noninteractive: fixture.isFurniture || fixture.noninteractive,
      geometry: {
        x: fixture.xCenter || geometry.x,
        y: fixture.yCenter || geometry.y,
        width: fixture.width || geometry.width,
        height: fixture.height || geometry.height,
        angle: fixture.angle || geometry.angle,
        size: fixture.sviSizeType || geometry.size,
        type: fixture.sviType || geometry.type,
      },
    },
  }
  return request(`${fixturesUrl}?${queryString(query)}`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(body),
  })
}

/**
 * Patch fixture
 * @param token
 * @param fixture
 * @param storeId
 * @param floorId
 * @param areaId
 * @returns {Object}
 */
export function patchFixture(token, fixture, storeId, floorId, areaId) {
  const query = {
    organizationId: orgIdUrl(),
    include: [],
  }
  const geometry = fixture.geometry || {}
  const body = {
    fixture: {
      storeId,
      floorId,
      areaId,
      parentId: fixture.parentId,
      name: fixture.name,
      shouldDisplayName:
        fixture.nameHasShown || fixture.shouldDisplayName ? true : false,
      // type: 'table', // fixture.type,
      noninteractive:
        fixture.isFurniture || fixture.noninteractive ? true : false,
      geometry: {
        x: fixture.xCenter || geometry.x,
        y: fixture.yCenter || geometry.y,
        width: fixture.width || geometry.width,
        height: fixture.height || geometry.height,
        angle: fixture.angle || geometry.angle,
        size: fixture.sviSizeType || geometry.size,
        type: fixture.sviType || geometry.type,
      },
    },
  }
  return request(`${fixtureUrl(fixture.id)}?${queryString(query)}`, {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(body),
  })
}

/**
 * Delete fixture
 * @param token
 * @param fixture
 * @returns {Object}
 */
export function deleteFixture(token, fixture) {
  const query = {
    organizationId: orgIdUrl(),
    include: [],
  }
  return request(`${fixtureUrl(fixture.id)}?${queryString(query)}`, {
    method: 'DELETE',
    headers: { Authorization: `Bearer ${token}` },
  })
}

/**
 * Get fixture
 * @param token
 * @param fixtureId
 */
export function getFixture(token, fixtureId) {
  const query = {
    organizationId: orgIdUrl(),
    include: [],
  }
  return request(`${api}/fixtures/${fixtureId}}?${queryString(query)}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

/**
 * Get fixtures
 * @param token
 * @param storeId
 * @param include
 */
export function getFixtures(token, storeId, include) {
  const query = {
    include: include || [],
    store_id: storeId,
    organizationId: orgIdUrl(),
  }
  return request(`${fixturesUrl}?${queryString(query)}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

/**
 * Post position
 * @param token
 * @param position
 * @param fixtureId
 * @returns {Object}
 */
export function postPosition(token, position, fixtureId) {
  const query = {
    organizationId: orgIdUrl(),
    include: [],
  }
  const geometry = position.geometry || {}
  const body = {
    position: {
      fixtureId,
      name: position.name,
      parentId: position.parentId,
      // price: position.price,
      // securedProduct: position.securedProduct,
      // securityDevice: position.securityDevice,
      geometry: {
        x: position.xCenter || geometry.x,
        y: position.yCenter || geometry.y,
        width: position.width || geometry.width,
        height: position.height || geometry.height,
        angle: position.theta || geometry.angle,
      },
    },
  }
  return request(`${positionsUrl}?${queryString(query)}`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(body),
  })
}

/**
 * Patch position
 * @param token
 * @param position
 * @param fixtureId
 * @returns {Object}
 */
export function patchPosition(token, position, fixtureId) {
  const query = {
    organizationId: orgIdUrl(),
    include: [],
  }
  const geometry = position.geometry || {}
  const body = {
    position: {
      fixtureId,
      name: position.name,
      // parentId: position.parentId,
      // price: position.price,
      // securedProduct: position.securedProduct,
      // securityDevice: position.securityDevice,
      geometry: {
        x: position.xCenter || geometry.x,
        y: position.yCenter || geometry.y,
        width: position.width || geometry.width,
        height: position.height || geometry.height,
        angle: position.theta || geometry.angle,
      },
    },
  }
  return request(`${positionUrl(position.id)}?${queryString(query)}`, {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(body),
  })
}

/**
 * Assign security device to the position
 *
 * @param token
 * @param position
 * @param securityDevice
 */
export function assignSecurityDevice(token, position, securityDevice) {
  const query = {
    organizationId: orgIdUrl(),
    include: [],
  }
  const body = {
    position: {
      securityDeviceId: (securityDevice || { id: null }).id,
    },
  }
  return request(`${positionUrl(position.id)}?${queryString(query)}`, {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(body),
  })
}

/**
 * Assign secured product to the securityDevice port
 *
 * @param token
 * @param securityDevice
 * @param port
 * @param securedProduct
 */
export function assignPortSecuredProduct(
  token,
  securityDevice,
  port,
  securedProduct
) {
  const query = {
    organizationId: orgIdUrl(),
    include: [],
  }
  const body = {
    securedProduct: {
      id: securedProduct.id,
      port: {
        // passing up null for either of these will now unassign the secured product
        securityDeviceId: securityDevice ? securityDevice.id : null,
        address: port.address,
      },
    },
  }
  return request(
    `${securedProductUrl(securedProduct.id)}?${queryString(query)}`,
    {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify(body),
    }
  )
}

/**
 * Create & add secured product to the store
 *
 * @param token
 * @param payload
 * @returns {Object}
 */

export function addSecuredProduct(token, payload) {
  const query = {
    organizationId: orgIdUrl(),
    include: [],
  }
  const {
    manufacturerId,
    otherManufacturerName,
    otherProductName,
    productId,
    storeId,
    usbCapable,
    port,
  } = payload
  const body = {
    securedProduct: {
      manufacturerId,
      otherManufacturerName,
      otherProductName,
      productId,
      storeId,
      usbCapable,
      port,
    },
  }
  return request(`${securedProductsUrl}?${queryString(query)}`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(body),
  })
}

export function patchSecuredProduct(token, securedProduct) {
  const query = {
    organizationId: orgIdUrl(),
    include: [],
  }
  return request(
    `${securedProductUrl(securedProduct.id)}?${queryString(query)}`,
    {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify({
        securedProduct: {
          id: securedProduct.id,
          storeId: securedProduct.storeId,
          manufacturerId: securedProduct.manufacturer.id,
          productId: securedProduct.productId,
          notes: securedProduct.notes,
          category: securedProduct.category,
          retiredReason: securedProduct.retiredReason,
        },
      }),
    }
  )
}

/**
 * Delete secured device
 *
 * @param token
 * @param payload
 * @returns {Object}
 */
export function deleteSecuredProduct(token, payload) {
  const query = {
    organizationId: orgIdUrl(),
    include: [],
  }
  return request(`${securedProductUrl(payload.id)}?${queryString(query)}`, {
    method: 'DELETE',
    headers: { Authorization: `Bearer ${token}` },
  })
}

/**
 * Delete position
 * @param token
 * @param position
 * @returns {Object}
 */
export function deletePosition(token, position) {
  const query = {
    organizationId: orgIdUrl(),
    include: [],
  }
  return request(`${positionUrl(position.id)}?${queryString(query)}`, {
    method: 'DELETE',
    headers: { Authorization: `Bearer ${token}` },
  })
}

/**
 * Get Position
 * @param token
 * @param positionId
 */
export function getPosition(token, positionId) {
  const query = {
    organizationId: orgIdUrl(),
    include: [],
  }
  return request(`${api}/positions/${positionId}?${queryString(query)}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

/**
 * Get positions
 * @param token
 * @param storeId
 * @param include
 */
export function getPositions(token, storeId, include) {
  const query = {
    include: include || [],
    store_id: storeId,
    organizationId: orgIdUrl(),
  }
  return request(`${positionsUrl}?${queryString(query)}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

/**
 * Post position action
 * @param token
 * @param positionId
 * @param action
 * @returns {Object}
 */
export function postPositionAction(token, positionId, action) {
  const query = {
    organizationId: orgIdUrl(),
    include: [],
  }
  return request(
    `${positionActionUrl(positionId, action)}?${queryString(query)}`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json; charset=utf-8',
      },
    }
  )
}

/**
 * Post Security Device action
 * @param token
 * @param securityDeviceId
 * @param action
 * @returns {Object}
 */
export function postSecurityDeviceAction(token, sdId, action) {
  const query = {
    organizationId: orgIdUrl(),
    include: [],
  }
  return request(
    `${securityDeviceActionUrl(sdId, action)}?${queryString(query)}`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json; charset=utf-8',
      },
    }
  )
}

/**
 * Post Store action
 * @param token
 * @param storeId
 * @param action
 * @returns {Object}
 */
export function postStoreAction(token, storeId, action) {
  const query = {
    organizationId: orgIdUrl(),
    include: [],
  }
  return request(`${storeActionUrl(storeId, action)}?${queryString(query)}`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json; charset=utf-8',
    },
  })
}

/**
 * Get organization by id
 * @param token
 */
export function getOrganizationById(token, id) {
  const query = {
    include: ['address'],
  }
  return request(`${organizationUrl(id)}?${queryString(query)}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export function getOrganizations(token) {
  return request(`${adminOrganizationsUrl}?include=`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

/**
 * Get organization based on user auth token
 * @param token
 */
export function getOrganization(token) {
  const query = {
    include: ['address'],
  }
  return request(`${organizationsUrl}?${queryString(query)}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

/**
 * Patch organization
 * @param token
 * @param organization
 */
export function patchOrganization(token, organizationData) {
  const query = {
    organizationId: orgIdUrl(),
    include: ['address'],
  }
  return request(`${organizationsUrl}?${queryString(query)}`, {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify({ organization: organizationData }),
  })
}

/**
 * Post fixture rule
 * @param token
 * @param fixtureId
 * @param rule
 */
export function postFixtureRule(token, fixtureId, rule) {
  const query = {
    organizationId: orgIdUrl(),
    include: [],
  }
  return request(`${fixtureRulesUrl(fixtureId)}?${queryString(query)}`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify({ rule }),
  })
}

/**
 * Patch fixture rule
 * @param token
 * @param fixtureId
 * @param rule
 */
export function patchFixtureRule(token, fixtureId, rule) {
  const query = {
    organizationId: orgIdUrl(),
    include: [],
  }
  return request(
    `${fixtureRuleUrl(fixtureId, rule.id)}?${queryString(query)}`,
    {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify({ rule }),
    }
  )
}

/**
 * Delete fixture rule
 *
 * @param token
 * @param fixtureId
 * @param ruleId
 * @returns {Object}
 */
export function deleteFixtureRule(token, fixtureId, ruleId) {
  const query = {
    organizationId: orgIdUrl(),
    include: [],
  }
  return request(`${fixtureRuleUrl(fixtureId, ruleId)}?${queryString(query)}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

/**
 * Post position rule
 * @param token
 * @param positionId
 * @param rule
 */
export function postPositionRule(token, positionId, rule) {
  const query = {
    organizationId: orgIdUrl(),
    include: [],
  }
  return request(`${positionRulesUrl(positionId)}?${queryString(query)}`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify({ rule }),
  })
}

/**
 * Patch position rule
 * @param token
 * @param positionId
 * @param rule
 */
export function patchPositionRule(token, positionId, rule) {
  const query = {
    organizationId: orgIdUrl(),
    include: [],
  }
  return request(
    `${positionRuleUrl(positionId, rule.id)}?${queryString(query)}`,
    {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify({ rule }),
    }
  )
}

/**
 * Delete position rule
 * @param token
 * @param positionId
 * @param ruleId
 */
export function deletePositionRule(token, positionId, ruleId) {
  const query = {
    organizationId: orgIdUrl(),
    include: [],
  }
  return request(
    `${positionRuleUrl(positionId, ruleId)}?${queryString(query)}`,
    {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
}

/**
 * Get store rule
 * @param token
 * @param storeId
 */
export function getStoreRule(token, storeId) {
  const query = {
    store_id: storeId,
    organizationId: orgIdUrl(),
    include: [],
  }
  return request(`${api}/rule_stores?${queryString(query)}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

/**
 * Get security devices for the current organization
 * @param token
 * @param page
 */
export function getSecurityDevices(token, page = 0, queryParams = {}) {
  const query = {
    page,
    organizationId: orgIdUrl(),
    include: [],
    ...queryParams,
  }
  return request(`${securityDevices}?${queryString(query)}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export function getOrphanedDevicesData(token, filters) {
  return getSecurityDevices(token, null, { orphaned: true, ...filters })
}

/**
 * Get Security Device
 * @param token
 * @param id
 */
export function getSecurityDevice(token, id) {
  const query = {
    organizationId: orgIdUrl(),
    include: [],
  }
  return request(`${api}/security_devices/${id}?${queryString(query)}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

/**
 * Get secured products for the current store
 * @param token
 * @param sId
 * @param page
 */
export function getSecuredProducts(token, sId, page = 0, include) {
  const storeId = sId ? sId : undefined
  const query = {
    store_id: storeId,
    page,
    organizationId: orgIdUrl(),
    // page: 0,
    per: 10000, // TODO: Pagination
    include: include || ['product'],
  }
  return request(`${securedProductsUrl}?${queryString(query)}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export function getProductsByIds(token, ids, orgId) {
  const query = {
    by_ids: ids,
    organizationId: orgId,
    include: [],
  }
  return request(`${products}?${queryString(query)}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

/**
 * Get Secured Product
 * @param token
 * @param id
 */
export function getSecuredProduct(token, id) {
  const query = {
    organizationId: orgIdUrl(),
    include: [],
  }
  return request(`${api}/secured_products/${id}?${queryString(query)}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

/**
 * Get the entire products database. Products are not org specific.
 * TODO: This probably shouldn't be called directly, as this database is going to grow tremendously over time.
 * @param token
 * @param page
 */
export function getProducts(token, manufacturerId, page = 0, orgId) {
  const query = {
    organizationId: orgId,
    page,
    manufacturer_id: manufacturerId,
    // page: 0,
    per: 10000, // TODO: Pagination
    include: [],
  }
  return request(`${products}?${queryString(query)}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

/**
 * Get products for the current organization
 * @param token
 * @param page
 */
export function getManufacturers(token, page = 0) {
  const query = {
    page,
    organizationId: orgIdUrl(),
    // page: 0,
    per: 10000, // TODO: Pagination
    include: [],
  }
  return request(`${manufacturers}?${queryString(query)}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

/**
 * Get Product
 * @param token
 * @param productId
 */
export function getProduct(token, productId) {
  const query = {
    organizationId: orgIdUrl(),
    include: [],
  }
  return request(`${api}/products/${productId}?${queryString(query)}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

/**
 * Get Rule
 * @param token
 * @param id
 */
export function getRule(token, id) {
  const query = {
    include: [],
  }
  return request(`${api}/compliance_rules/${id}?${queryString(query)}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

/**
 * Get the reports query
 * @param rangeStart
 * @param rangeEnd
 * @param contextIdentifier
 * @param page
 * @param per
 * @returns {query}
 */
function reportsQuery(
  storeId,
  rangeStart,
  rangeEnd,
  contextIdentifier,
  page = 0,
  per = 100
) {
  return {
    'reporting_report_filters[range_start]': rangeStart,
    'reporting_report_filters[range_end]': rangeEnd,
    'reporting_report_filters[context_identifiers]': contextIdentifier, // []
    page,
    per,
    store_id: storeId,
    organizationId: orgIdUrl(),
  }
}

/**
 * Get health
 * @param token
 * @param rangeStart
 * @param rangeEnd
 * @param contextIdentifier
 * @param page
 * @param per
 */
export function getHealth(
  token,
  storeId,
  rangeStart,
  rangeEnd,
  contextIdentifier,
  page = 0,
  per = 100
) {
  const query = reportsQuery(
    storeId,
    rangeStart,
    rangeEnd,
    contextIdentifier,
    page,
    per
  )
  return request(`${healthUrl}?${queryString(query)}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

/**
 * Get operations
 * @param token
 * @param rangeStart
 * @param rangeEnd
 * @param contextIdentifier
 * @param resourceOwnerIdentifier
 * @param page
 * @param per
 */
export function getOperations(
  token,
  storeId,
  rangeStart,
  rangeEnd,
  contextIdentifier,
  resourceOwnerIdentifier,
  page = 0,
  per = 100
) {
  const query = {
    ...reportsQuery(
      storeId,
      rangeStart,
      rangeEnd,
      contextIdentifier,
      page,
      per
    ),
    'reporting_report_filters[resource_owner_identifiers]': resourceOwnerIdentifier, // []
  }
  return request(`${operationsUrl}?${queryString(query)}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

/**
 * Get compliance
 * @param token
 * @param rangeStart
 * @param rangeEnd
 * @param contextIdentifier
 * @param page
 * @param per
 */
export function getCompliance(
  token,
  storeId,
  rangeStart,
  rangeEnd,
  contextIdentifier,
  page = 0,
  per = 100
) {
  const query = reportsQuery(
    storeId,
    rangeStart,
    rangeEnd,
    contextIdentifier,
    page,
    per
  )
  return request(`${complianceUrl}?${queryString(query)}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

/**
 * Get marketing
 * @param token
 * @param rangeStart
 * @param rangeEnd
 * @param contextIdentifier
 * @param page
 * @param per
 */
export function getMarketing(
  token,
  storeId,
  rangeStart,
  rangeEnd,
  contextIdentifier,
  page = 0,
  per = 100
) {
  const query = reportsQuery(
    storeId,
    rangeStart,
    rangeEnd,
    contextIdentifier,
    page,
    per
  )
  return request(`${marketingUrl}?${queryString(query)}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

/**
 * Get healthException
 * @param token
 * @param rangeStart
 * @param rangeEnd
 * @param contextIdentifier
 * @param page
 * @param per
 */
export function getHealthException(
  token,
  storeId,
  rangeStart,
  rangeEnd,
  contextIdentifier,
  page = 0,
  per = 100
) {
  const query = reportsQuery(
    storeId,
    rangeStart,
    rangeEnd,
    contextIdentifier,
    page,
    per
  )
  return request(`${healthExceptionUrl}?${queryString(query)}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

/**
 * Get complianceException
 * @param token
 * @param rangeStart
 * @param rangeEnd
 * @param contextIdentifier
 * @param page
 * @param per
 */
export function getComplianceException(
  token,
  storeId,
  rangeStart,
  rangeEnd,
  contextIdentifier,
  page = 0,
  per = 100
) {
  const query = reportsQuery(
    storeId,
    rangeStart,
    rangeEnd,
    contextIdentifier,
    page,
    per
  )
  return request(`${complianceExceptionUrl}?${queryString(query)}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

/**
 * Get operationsException
 * @param token
 * @param rangeStart
 * @param rangeEnd
 * @param contextIdentifier
 * @param page
 * @param per
 */
export function getOperationsException(
  token,
  storeId,
  rangeStart,
  rangeEnd,
  contextIdentifier,
  page = 0,
  per = 100
) {
  const query = reportsQuery(
    storeId,
    rangeStart,
    rangeEnd,
    contextIdentifier,
    page,
    per
  )
  return request(`${operationsExceptionUrl}?${queryString(query)}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

/**
 * Get Current Exceptions
 * @param token
 * @param storeId
 * @param rangeStart
 * @param rangeEnd
 * @param reports
 * @param page
 * @param per
 */
export function getReports(
  token,
  storeId,
  rangeStart,
  rangeEnd,
  reports,
  page = 0,
  per = 100
) {
  const query = {
    'reporting_report_filters[reports][]': reports,
    'reporting_report_filters[range_start]': rangeStart,
    'reporting_report_filters[range_end]': rangeEnd,
    page,
    per,
    store_id: storeId,
    organizationId: orgIdUrl(),
  }

  return request(`${reportsUrl}?${queryString(query)}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

/**
 * Get taggings
 * @param token
 * @returns {Object}
 */
export function getTaggings(token) {
  const query = {
    organizationId: orgIdUrl(),
    include: [],
  }
  return request(`${taggingsUrl}?${queryString(query)}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

/**
 * Get tags
 * @param token
 * @returns {Object}
 */
export function getTags(token) {
  const query = {
    organizationId: orgIdUrl(),
    include: [],
  }
  return request(`${tagsUrl}?${queryString(query)}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

/**
 * Post tagging
 * @param token
 * @param storeId
 * @param tagId
 * @returns {Object}
 */
export function postTagging(token, storeId, tagId) {
  const query = {
    organizationId: orgIdUrl(),
    include: [],
  }
  const body = {
    tagging: {
      storeId,
      tagId,
    },
  }
  return request(`${taggingsUrl}?${queryString(query)}`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(body),
  })
}

/**
 * Delete tagging
 *
 * @param token
 * @param id
 * @returns {Object}
 */
export function deleteTagging(token, id) {
  const query = {
    organizationId: orgIdUrl(),
    include: [],
  }
  return request(`${taggingUrl(id)}?${queryString(query)}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

/**
 * Forgot password
 * @param email
 */
export function forgotPassword(email, params) {
  const query = {
    ...params,
    organizationId: orgIdUrl(),
  }
  const body = {
    user: {
      username: email,
    },
  }
  return request(`${api}/passwords/forgot?${queryString(query)}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(body),
  })
}

/**
 * Request Reset Password
 * @paramuser
 */
export function requestPassword(token, id) {
  const query = {
    subdomain: SubDomain,
    organizationId: orgIdUrl(),
  }
  return request(`${userUrl(id)}/reset_password?${queryString(query)}`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

/**
 * Reset Password
 * @param token
 * @param password
 */
export function resetPassword(token, password, passcode) {
  const query = {
    organizationId: orgIdUrl(),
  }
  const body = {
    user: {
      reset_password_token: token,
      password,
      passcode,
    },
  }
  return request(`${api}/passwords/reset?${queryString(query)}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(body),
  })
}

/**
 * Get all Templates
 * @param token
 * @param subdomain
 */
export function getTemplates(token, subdomain) {
  const query = {
    organizationId: orgIdUrl(),
    subdomain: subdomain,
    // page: 0,
    per: 10000, // TODO: Pagination
    include: ['positions.geometry'], // TODO: Remove positions.geometry include and figure out why that data is ignored when getTemplate() fetches it
  }
  return request(`${templatesUrl}?${queryString(query)}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

/**
 * Get Template by ID
 * @param token
 * @param id
 */
export function getTemplate(token, id, include) {
  const query = {
    organizationId: orgIdUrl(),
    include:
      include ||
      'fixture_rules,compliance_rules,geometry,modal_position,positions,positions.rules,positions.geometry',
  }
  return request(`${templateUrl(id)}?${queryString(query)}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

/**
 * Delete template
 *
 * @param token
 * @param id
 * @returns {Object}
 */
export function deleteTemplate(token, id) {
  const query = {
    organizationId: orgIdUrl(),
    include: [],
  }
  return request(`${templateUrl(id)}?${queryString(query)}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

/**
 * Post template
 *
 * @param token
 * @param template
 * @return {Object}
 */
export function postTemplate(token, template) {
  const query = {
    organizationId: orgIdUrl(),
    include: [],
  }
  return request(`${templatesUrl}?${queryString(query)}`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify({ template }),
  })
}

/**
 * Patch template
 *
 * @param token
 * @param template
 * @return {Object}
 */
export function patchTemplate(token, template) {
  const query = {
    organizationId: orgIdUrl(),
    include: [],
  }
  return request(`${templateUrl(template.id)}?${queryString(query)}`, {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify({ template }),
  })
}

/**
 * Get all Linked Fixtures for Template
 * @param token
 * @param templateId
 */
export function getLinkedFixtureForTemplate(token, templateId) {
  const query = {
    organizationId: orgIdUrl(),
    include: [],
  }
  return request(
    `${fixturesUrl}?parentId=${templateId}?${queryString(query)}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
}

/**
 * Get layout table view for store. This is a temporary,
 * rushed feature that will *hopefully* go away as soon as it's built.
 *
 * @param token
 * @param storeId
 * @param filters
 */
export function getLayoutTableViewData(token, storeId, filters) {
  const query = {
    ...filters,
    storeId: storeId,
  }
  return request(`${layoutTableViewUrl}?${queryString(query)}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

/**
 * Get iot device statuses for org.
 *
 * @param token
 */
export function getIOTStatus(token, orgID, filters) {
  const query = {
    organizationId: orgID,
    ...filters,
  }
  return request(`${api}/offline_report?${queryString(query)}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

// DeviceConfigurations
/**
 * Get list of all accessible DeviceConfigurations
 */
export function getDeviceConfigurations(token) {
  const query = {
    include: ['device_configuration_parameters'],
  }
  return request(`${deviceConfigurationsUrl}?${queryString(query)}`, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

/**
 * Get a list of all DeviceConfigurations for an org.
 * @param orgID The ID of the organization.
 */
export function getDeviceConfigurationsForOrg(token, orgId) {
  const query = {
    include: ['device_configuration_parameters'],
    for_org: orgId,
    organizationId: orgId,
  }
  return request(`${deviceConfigurationsUrl}?${queryString(query)}`, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

/**
 * Get a list of all DeviceConfigurations for a region.
 * @param regID The ID of the region.
 */
export function getDeviceConfigurationsForRegion(token, regId, orgId) {
  const query = {
    include: ['device_configuration_parameters'],
    for_region: regId,
    organizationId: orgId,
  }
  return request(`${deviceConfigurationsUrl}?${queryString(query)}`, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

/**
 * Get a list of all DeviceConfigurations for a store.
 * @param storeID The ID of the store.
 */
export function getDeviceConfigurationsForStore(token, storeId, orgId) {
  const query = {
    include: ['device_configuration_parameters'],
    for_store: storeId,
    organizationId: orgId,
  }
  return request(`${deviceConfigurationsUrl}?${queryString(query)}`, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

export function updateDeviceConfiguration(token, dcId, data) {
  const query = {
    include: [],
  }
  return request(`${deviceConfigurationUrl(dcId)}?${queryString(query)}`, {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(data),
  })
}

/**
 * Get list of firmwares
 * @param token
 */
export function getFirmwares(token) {
  const query = {
    include: [],
  }
  return request(`${firmwaresUrl}?${queryString(query)}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Accept-Encoding': '',
    },
  })
}

/**
 * Post new firmware
 * @param token
 * @param base64Firmware
 */
export function postFirmware(token, filename, data) {
  const query = {
    include: [],
  }
  return request(`${firmwaresUrl}?${queryString(query)}`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify({
      firmwareImage: {
        filename,
        data,
      },
    }),
  })
}

export function getFile(token, filepath) {
  return request(`${firmwaresUrl}${filepath}`, {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  })
}

export function deleteFirmware(token, firmware) {
  return request(`${firmwaresUrl}/${firmware}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  })
}

export function getMinions(saltToken) {
  return request(minionsUrl, {
    headers: {
      'X-Auth-Token': saltToken,
    },
  })
}

export function runSaltCommandWithArg(saltToken, hubIdentifier, arg) {
  return requestWithTimeout(
    saltAPI,
    {
      method: 'POST',
      headers: { 'X-Auth-Token': saltToken },
      body: JSON.stringify({
        client: 'local',
        tgt: `${hubIdentifier}*`,
        fun: 'cmd.run',
        arg,
      }),
    },
    SALT_COMMANDS_TIMEOUT
  )
}

export function runSaltStateAppyFirmware600(saltToken, hubIdentifier, kwarg) {
  return requestWithTimeout(
    saltAPI,
    {
      method: 'POST',
      headers: { 'X-Auth-Token': saltToken },
      body: JSON.stringify({
        client: 'local',
        tgt: `${hubIdentifier}*`,
        fun: 'state.apply',
        arg: 'firmware600',
        kwarg,
      }),
    },
    SALT_COMMANDS_TIMEOUT
  )
}

export function pingHub(saltToken, hubIdentifier) {
  return request(saltAPI, {
    method: 'POST',
    headers: { 'X-Auth-Token': saltToken },
    body: JSON.stringify({
      client: 'local',
      tgt: `${hubIdentifier}*`,
      fun: 'test.ping',
    }),
  })
}

// FW Management Authentication
export function fwManagementSignIn(token) {
  return request(fmManagementSignInUrl, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
    },
  })
}
