/**
 *
 * FloorCanvas component
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import { fabric } from 'fabric'
import { debounce } from 'lodash'
import Canvas from '../Canvas'
import {
  minW,
  minH,
  slicingArea,
  propertiesToInclude,
  renderLabel,
  handleOver,
  handleOut,
} from './area'
import {
  resizeToScale,
  setMinSize,
  getMaxSize,
  applyMaxMinScale,
} from './utils'
import { setOverlapMethod } from '../FabricCanvas/utils'
import {
  onScaleNewObject,
  onModifiedMeasurer,
  onScaleObject,
  getInitialCoords,
  isIntersectHandled,
} from '../FabricCanvas/measurer'
import { restoreCanvasOverlapEnabled } from '../../containers/App/localStorageOptions'
import { LongPressMilliseconds, LongTapResolver } from '../../utils/mtiUtils'
import { incrementalObjectName, defaultName } from '../../utils/mtiCanvasUtils'

const canvas = new fabric.Canvas()
let preSelectedInstance
let selectedInstance
let updatedInstance

let isDown

class FloorCanvas extends React.PureComponent {
  /* eslint-disable no-undef, react/sort-comp, no-underscore-dangle */
  onDefault() {
    preSelectedInstance = undefined
    selectedInstance = undefined
    updatedInstance = undefined
  }

  initCanvas = (isEditable) => {
    const isCanvasOverlapEnabled = restoreCanvasOverlapEnabled()
    const { onChanged, size } = this.props
    const el = this.canvasContainer.objectsCanvas
    let rect
    let origX
    let origY
    let numObjectsOnCanvas

    const longTapResolver = new LongTapResolver()

    canvas.initialize(el, {
      width: size.width,
      height: size.height,
      selection: false,
      backgroundColor: null,
    })

    if (isEditable) {
      // Edit canvas listeners
      let initialCoords = {}

      // IC for editing selected object
      if ((preSelectedInstance || {}).id !== (selectedInstance || {}).id) {
        preSelectedInstance = selectedInstance
      }
      selectedInstance = canvas.getActiveObject()

      canvas.off('before:selection:cleared')
      canvas.on('before:selection:cleared', () => {
        updatedInstance = canvas.getActiveObject()
      })
      canvas.off('mouse:down')
      canvas.on('mouse:down', async (o) => {
        isDown = true
        const activeObject = canvas.getActiveObject()
        if (
          activeObject &&
          (preSelectedInstance || {}).id !== (selectedInstance || {}).id
        ) {
          preSelectedInstance = selectedInstance
        }
        selectedInstance = activeObject

        initialCoords = (o.target || {}).oCoords || getInitialCoords(o)

        numObjectsOnCanvas = canvas.getObjects().length

        if (
          canvas
            .getObjects()
            .find(({ id }) => id === (updatedInstance || {}).id)
        ) {
          if (
            JSON.stringify(updatedInstance) !==
            JSON.stringify(preSelectedInstance)
          ) {
            // If there was any changes in selected object
            if (
              (updatedInstance || {}).id === (preSelectedInstance || {}).id &&
              (updatedInstance || {}).id !== (selectedInstance || {}).id
            ) {
              // Edit object that exists
              this.props.onCanvasAreaSave((updatedInstance || {}).id)
              preSelectedInstance = undefined
              if (!activeObject) {
                this.onDefault()
              }
              isDown = false
              return
            } else if (
              updatedInstance &&
              !preSelectedInstance &&
              (updatedInstance || {}).id !== (selectedInstance || {}).id &&
              isNaN(updatedInstance.id)
            ) {
              // Create new object
              this.props.onCanvasAreaSave((updatedInstance || {}).id)
              updatedInstance = undefined
              if (!activeObject) {
                this.onDefault()
              }
              isDown = false
              return
            }
          }
        }

        if (selectedInstance) {
          return // Do not initiate new object draw, if there is an active object
        } else {
          this.onDefault()
        }

        const pointer = canvas.getPointer(o.e)

        origX = pointer.x
        origY = pointer.y

        const areaName = incrementalObjectName(
          this.props.canvasObject.objects,
          defaultName.area
        )
        rect = await slicingArea(areaName, pointer, origX, origY)
        canvas.add(rect)
      })

      canvas.off('mouse:move')
      canvas.on('mouse:move', (o) => {
        if (!isDown || !rect) return

        const { x, y } = canvas.getPointer(o.e)
        if (origX > x || origY > y) return

        onScaleNewObject(
          { ...o, target: rect },
          canvas,
          size.width,
          size.height,
          initialCoords
        )

        if (origX > x) {
          rect.set({ left: Math.abs(x) })
        }

        if (origY > y) {
          rect.set({ top: Math.abs(y) })
        }

        const width = Math.abs(origX - x)
        const height = Math.abs(origY - y)

        const { width: fixedWidth, height: fixedHeight } = getMaxSize(
          width,
          height,
          canvas.width,
          canvas.height
        )

        const clone = fabric.util.object.clone(rect) // get cloned object
        clone.set({
          originX: 'left',
          originY: 'top',
          width: fixedWidth,
          height: fixedHeight,
        })
        const intersectClone = onScaleNewObject(
          { ...o, target: clone },
          canvas,
          size.width,
          size.height,
          initialCoords
        )

        const isInterXHandled = isIntersectHandled(intersectClone)
        const isInterYHandled = isIntersectHandled(intersectClone)
        const newWidth = isInterXHandled ? clone.width : fixedWidth
        const newHeight = isInterYHandled ? clone.height : fixedHeight
        const newLeft = isInterXHandled ? clone.left : rect.left
        const newTop = isInterYHandled ? clone.top : rect.top
        rect.set({
          originX: 'left',
          originY: 'top',
          left: newLeft,
          top: newTop,
          width: newWidth,
          height: newHeight,
        })
        rect.item(0).set({ width: newWidth, height: newHeight })
        renderLabel(rect)

        canvas.renderAll()
      })

      canvas.onBeforeScaleRotate = function lock(o) {
        o.set({ lockScalingX: false, lockScalingY: false })
      }

      canvas.off('object:scaling')
      canvas.on('object:scaling', (o) => {
        const group = o.target
        group.objectCaching = false
        if (isCanvasOverlapEnabled) {
          onScaleObject(o, canvas, size.width, size.height, initialCoords)
        }

        const actualW = group.scaleX * group.width
        const actualH = group.scaleY * group.height
        group.set({
          width: actualW,
          height: actualH,
          scaleX: 1,
          scaleY: 1,
        })
        const bgn = o.target.item(0)
        bgn.set({
          width: actualW,
          height: actualH,
        })

        const maxW = actualW > actualH ? canvas.width : canvas.width / 2
        const maxH = actualW > actualH ? canvas.height / 2 : canvas.height
        const label = o.target.item(1)
        const text = o.target.item(2)

        applyMaxMinScale(group, actualW, actualH, maxW, maxH, minW, minH)
        resizeToScale(group, [label, text])
        // resizeStrokeToScale(o)
        renderLabel(group)
      })

      canvas.off('mouse:over')
      canvas.on('mouse:over', (e) => {
        handleOver(e.target)
        canvas.renderAll()
      })

      canvas.off('mouse:out')
      canvas.on('mouse:out', (e) => {
        handleOut(e.target)
        canvas.renderAll()
      })

      canvas.off('object:rotating')
      canvas.on('object:rotating', (options) => {
        const step = 5
        const angle = Math.round(options.target.angle / step) * step
        const angleFixed = angle === 360 ? 0 : angle
        options.target.angle = angleFixed
      })
    } else {
      // Static canvas listeners
      canvas.off('mouse:down')
      canvas.off('mouse:move')
      canvas.off('object:scaling')
      canvas.off('mouse:over')
      canvas.off('mouse:out')

      canvas.off('mouse:down')
      canvas.on('mouse:down', (e) => {
        longTapResolver.mouseDown(e.target)

        setTimeout(() => {
          if (!longTapResolver.isLongTap(e.target)) {
            return
          }
          this.onOpenArea(e.target)
        }, LongPressMilliseconds)
      })

      /**
       * uncomment to use click and drag functionality to create new area
       * skip for mobile
       */
      // if (!isMobile) {
      //   canvas.off('mouse:down')
      //   canvas.on('mouse:down', async (o) => {
      //     if (o && o.target) return
      //     isDown = true
      //     const pointer = canvas.getPointer(o.e)

      //     origX = pointer.x
      //     origY = pointer.y

      //     rect = await slicingArea(defaultName.area, pointer, origX, origY)
      //     canvas.add(rect)
      //   })

      //   canvas.off('mouse:move')
      //   canvas.on('mouse:move', (o) => {
      //     // return
      //     if (!isDown || !rect) return

      //     const { x, y } = canvas.getPointer(o.e)

      //     if (origX > x) {
      //       rect.set({ left: Math.abs(x) })
      //     }

      //     if (origY > y) {
      //       rect.set({ top: Math.abs(y) })
      //     }

      //     const width = Math.abs(origX - x)
      //     const height = Math.abs(origY - y)

      //     rect.set({ width, height })
      //     rect.item(0).set({ width, height })
      //     renderLabel(rect)

      //     canvas.renderAll()
      //   })
      // }
    }

    canvas.off('mouse:up')
    canvas.on('mouse:up', (e) => {
      onModifiedMeasurer(canvas) // remove rulers from canvas
      longTapResolver.mouseUp()
      isDown = false
      let isStaticLocal = true
      let selectedId = (canvas.getActiveObject() || { id: -1 }).id

      if (rect && !(rect.width && rect.height)) {
        // Remove zero-sized objects (when user accidentally clicked on canvas)
        canvas.remove(rect)
        rect = undefined
      } else if (rect) {
        isStaticLocal = false
        setMinSize([rect, rect.item(0)], minW, minH)
        renderLabel(rect)
        // Select area after drawing
        canvas.setActiveObject([...canvas.getObjects()].pop(), e)
        selectedInstance = canvas.getActiveObject()
        // Convert origins from {left, top} to {center, center}
        rect.set({
          top: rect.height / 2 + rect.top,
          left: rect.width / 2 + rect.left,
          originX: 'center',
          originY: 'center',
        })

        if (numObjectsOnCanvas === canvas.getObjects().length) {
          selectedId = -1 // prevent existing object selecting if a new object was not created
        } else {
          selectedId = (canvas.getActiveObject() || { id: -1 }).id
        }
      } else if (!rect) {
        const activeObject = canvas.getActiveObject()
        if (activeObject)
          setMinSize([activeObject, activeObject.item(0)], minW, minH)
      }

      onChanged({
        canvasObject: canvas.toObject(propertiesToInclude),
        selectedId: selectedId,
        isStatic: isStaticLocal,
      })
      rect = undefined
      const activeObject = canvas.getActiveObject()
      if (activeObject) {
        activeObject.lockScalingX = false
        activeObject.lockScalingY = false
      }
    })

    canvas.off('mouse:dblclick')
    canvas.on('mouse:dblclick', (e) => {
      this.onOpenArea(e.target)
    })

    if (isCanvasOverlapEnabled) {
      setOverlapMethod('measurer', canvas, size.width, size.height)
    }
    this.canvasContainer.loadAndRender()
  }

  constructor(...props) {
    super(...props)
    this.onOpenArea = debounce(this.onOpenArea, 100)
  }

  componentDidMount() {
    const { isEditable } = this.props
    this.initCanvas(isEditable)
  }

  componentDidUpdate(prevProps) {
    const { isEditable } = this.props
    if (prevProps.isEditable !== isEditable) {
      this.initCanvas(isEditable)
    }
  }

  onMessage(msg) {
    const { onMessage } = this.props
    if (onMessage) onMessage(msg)
  }

  onOpenArea(obj) {
    const { onOpenArea } = this.props
    if (onOpenArea) onOpenArea(obj)
  }

  onOver(id) {
    handleOver(canvas.getObjects().find((o) => o.id === id))
    canvas.renderAll()
  }

  onOut(id) {
    handleOut(canvas.getObjects().find((o) => o.id === id))
    canvas.renderAll()
  }

  discardActiveObject() {
    canvas.discardActiveObject()
    canvas.renderAll()
  }

  removeActiveObject() {
    canvas.remove(canvas.getActiveObject())
    canvas.renderAll()
  }

  isInteractive() {
    return isDown
  }

  render() {
    const { canvasObject, selectedId } = this.props

    return (
      <Canvas
        ref={(ref) => {
          this.canvasContainer = ref
        }}
        canvasObject={canvasObject}
        selectedId={selectedId}
        fabricInstance={canvas}
      />
    )
  }
}

FloorCanvas.propTypes = {
  selectedId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  canvasObject: PropTypes.object.isRequired,
  size: PropTypes.object.isRequired,
  isEditable: PropTypes.bool.isRequired,
  prototypes: PropTypes.object,
  onChanged: PropTypes.func.isRequired,
  onOpenArea: PropTypes.func,
  onMessage: PropTypes.func,
  onCanvasAreaSave: PropTypes.func,
}

export default FloorCanvas
