import { ActionTypes } from '../constants'

/**
 * Organization Details Action
 *
 * @returns {Object}
 */
export function organizationDetailsAction(organization) {
  return {
    type: ActionTypes.ORGANIZATION_DETAILS,
    payload: organization,
  }
}

/**
 * Reset Organization Management
 *
 * @returns {Object}
 */
export function resetOrganizationManagement() {
  return {
    type: ActionTypes.RESET_ORGANIZATION_MANAGEMENT,
  }
}

/**
 * Edit Organization
 *
 * @returns {Object}
 */
export function editOrganization(o) {
  return {
    type: ActionTypes.ORGANIZATION_EDIT,
    payload: o,
  }
}

/**
 * Clear Organization
 *
 * @returns {Object}
 */
export function clearOrganization() {
  return {
    type: ActionTypes.ORGANIZATION_CLEAR,
  }
}

/**
 * Patch Organization Failed
 *
 * @returns {Object}
 */
export function patchOrganizationFailed(error) {
  return {
    type: ActionTypes.PATCH_ORGANIZATION_FAILED,
    payload: error,
  }
}

/**
 * Patch Organization Fulfilled
 *
 * @returns {Object}
 */
export function patchOrganizationFulfilled(organization) {
  return {
    type: ActionTypes.PATCH_ORGANIZATION_FULFILLED,
    payload: organization,
  }
}

/**
 * Patch Organization Pending
 *
 * @returns {Object}
 */
export function patchOrganizationPending() {
  return {
    type: ActionTypes.PATCH_ORGANIZATION_PENDING,
  }
}

/**
 * Patch Organization
 *
 * @returns {Object}
 */
export function patchOrganization(id) {
  return {
    type: ActionTypes.PATCH_ORGANIZATION,
    payload: id,
  }
}
