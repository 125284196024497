/**
 * Personae Sagas
 */
import { call, put, select } from 'redux-saga/effects'
import { getPersonaeRoles, orgIdUrl } from 'api'
import {
  makeSelectToken,
  makeSelectIsMtiAdmin,
  makeSelectPersonae,
  makeSelectPersonaeLoading as makeSelectLoading,
} from '../selectors'
import {
  fetchPersonaePending,
  fetchPersonaeFulfilled,
  fetchPersonaeFailed,
} from '../actions'
import { errorToast } from '../../../utils/utils'
import { fixPersonae } from '../../../utils/mtiUtils'

export function* loadPersonaeData() {
  const token = yield select(makeSelectToken())
  const data = yield call(getPersonaeRoles, token, 'personae')

  return fixPersonae(data)
}

export function* loadPersonae({ payload = {} } = {}) {
  const token = yield select(makeSelectToken())
  if (!token) {
    console.log('Skipping get Personae')
    return
  } else {
    // console.log('get Personae')
  }
  const isMtiAdmin = yield select(makeSelectIsMtiAdmin())
  if (isMtiAdmin && !orgIdUrl()) return
  const isFetchPersonaePending = yield select(makeSelectLoading())
  if (isFetchPersonaePending) return
  const personaeLoaded = yield select(makeSelectPersonae())
  const isPersonaeLoaded = personaeLoaded && personaeLoaded.length > 0
  try {
    if (!isPersonaeLoaded) {
      yield put(fetchPersonaePending())
    }

    const data = yield call(loadPersonaeData, { payload })
    yield put(fetchPersonaeFulfilled(data))
  } catch (error) {
    console.error(error)
    yield put(fetchPersonaeFailed(error))
    errorToast('Load Personae failed')
  }
}
