/**
 *
 * SidebarSectionHighlight
 *
 */
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

export const SidebarSectionStyle = styled.section`
  padding: 25px;
  text-align: left;
`

class SidebarSectionHighlight extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }

  onOver() {
    this.setState({ isHighlighted: true })
  }

  onOut() {
    this.setState({ isHighlighted: false })
  }

  render() {
    const { id, onMouseEnter, onMouseLeave, content } = this.props
    const { isHighlighted } = this.state
    return (
      <SidebarSectionStyle
        style={isHighlighted ? { backgroundColor: '#F0F0F0' } : {}}
        onMouseEnter={() => {
          if (onMouseEnter) {
            onMouseEnter(id)
            this.setState({ isHighlighted: true })
          }
        }}
        onMouseLeave={() => {
          if (onMouseLeave) {
            onMouseLeave(id)
            this.setState({ isHighlighted: false })
          }
        }}
      >
        {content}
      </SidebarSectionStyle>
    )
  }
}

SidebarSectionHighlight.propTypes = {
  id: PropTypes.any.isRequired,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  content: PropTypes.any,
}

export default SidebarSectionHighlight
