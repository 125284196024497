import React from 'react'
import PropTypes from 'prop-types'
export default class Col extends React.PureComponent {
  render() {
    return (
      <div className={`col-sm-${this.props.span}`}>{this.props.children}</div>
    )
  }
}

Col.propTypes = {
  span: PropTypes.number,
}

Col.defaultProps = {
  span: 1,
}
