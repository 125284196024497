import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Icon } from '../NotCompliantFixtures'

import NOT_COMPLIANT_ICON from '../../images/svg/not_compliant.svg'
import ALL_OK_ICON from '../../images/svg/compliant.svg'

export const CompliantContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  min-height: 48px;
  padding: 0 25px;
`

const RuleStatusContainer = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  padding: 10px 0px;
`

export const StatusContainer = styled.div`
  width: 250px;
  margin-left: 12px;
`

export const StatusTitle = styled.div`
  font-size: 12px;
  opacity: 0.54;
  font-weight: bold;
`

export const InfoText = styled.span`
  font-weight: normal;
`

export function CompliantRule({ rule }) {
  return (
    <CompliantContainer>
      <RuleStatusContainer>
        <Icon src={ALL_OK_ICON} />
        <StatusContainer>
          <StatusTitle>
            Rule:
            <InfoText>{` ${rule.description}`}</InfoText>
          </StatusTitle>
        </StatusContainer>
      </RuleStatusContainer>
    </CompliantContainer>
  )
}

CompliantRule.propTypes = {
  rule: PropTypes.object.isRequired,
}

export function NotCompliantRule({ rule }) {
  return (
    <CompliantContainer>
      <RuleStatusContainer>
        <Icon src={NOT_COMPLIANT_ICON} />
        <StatusContainer>
          <StatusTitle>
            Rule:
            <InfoText>{` ${rule.description}`}</InfoText>
          </StatusTitle>
          <StatusTitle>
            Currently:
            <InfoText>{` ${rule.actual}`}</InfoText>
          </StatusTitle>
          <StatusTitle>
            Since:
            <InfoText>{` ${rule.updatedAt}`}</InfoText>
          </StatusTitle>
        </StatusContainer>
      </RuleStatusContainer>
    </CompliantContainer>
  )
}

NotCompliantRule.propTypes = {
  rule: PropTypes.object.isRequired,
}
