import React from 'react'
import PropTypes from 'prop-types'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { compose } from 'redux'
import {
  notifyModal,
  openModal,
  closeModal,
  loadOrganization,
  organizationDetailsAction,
  loadDataForOrganization,
} from 'containers/App/actions'
import {
  makeSelectMtiPermission,
  makeSelectOrganizationDetails,
  selectIsMTIUser,
} from '../../App/selectors'
import { getTableHeight } from '../../../utils/utils'
import { orgUrl } from '../../../utils/mtiUtils'
import CorporateUserScreen from './index.screen'
import Stores from '../../StoresPage'
import { selectStoreById } from '../../../components/Breadcrumbs/selectors'
import { getStoreId } from '../../../components/Drawer/StoreManagerOrStaff'
import { selectStoreHasMissingSecuredProducts } from '../../ExceptionsList/SecuredProducts/selectors'
import warningAlert from '../../../images/warning-alert.png'

const noPermissionTextKeys =
  "You don't have appropriate rights to access the Keys.\nPlease contact your store manager."
  const noPermissionTextReports =
    "You don't have appropriate rights to access the Store Reports.\nPlease contact your store manager."
    const inDevelopment = 'This feature is still in development'

export function getQuickActionsItems(store, props) {
  const { hasMissingSecuredProducts } = props
  let storeItems = []
  if (store) {
    storeItems = [
      {
        storeName: (store || {}).name,
      },
      {
        name: 'Keys',
        id: 5,
        navigateTo: `${orgUrl()}/stores/${(store || {}).id}/${
          (store || {}).name
        }/keys`,
      },
      {
        name: 'Secured Products',
        id: 6,
        navigateTo: `${orgUrl()}/stores/${(store || {}).id}/${
          (store || {}).name
        }/secured_products`,
        icon: hasMissingSecuredProducts ? warningAlert : null,
        historyState: hasMissingSecuredProducts
                    ? { category: 'missing' }
                    : null,
      },
      {
        name: 'Schedules',
        id: 7,
        navigateTo: `${orgUrl()}/stores/${(store || {}).id}/${
          (store || {}).name
        }/schedules`,
      },
      {
        name: 'Table View',
        id: 8,
        navigateTo: `${orgUrl()}/stores/${(store || {}).id}/${
          (store || {}).name
        }/layout_table_view`,
      },
      {
        name: 'Orphaned Devices',
        id: 9,
        navigateTo: `${orgUrl()}/stores/${(store || {}).id}/${
          (store || {}).name
        }/orphaned_devices`,
      },
    ]
  }

  return [
    { name: 'Organization Details', id: 0, navigateTo: '' },
    { name: 'Regions', id: 1, navigateTo: `${orgUrl()}/regions` },
    // { name: 'Templates', id: 2, navigateTo: `${orgUrl()}/templates` },
    { name: 'Stores', id: 3, navigateTo: `${orgUrl()}/stores` },
    { name: 'Store Status Dashboard', id: 9, navigateTo: `${orgUrl()}/stores/dashboard` },
    { name: 'Users', id: 4, navigateTo: `${orgUrl()}/users` },
    { name: 'IOT Status Dashboard', id: 10, navigateTo: `${orgUrl()}/iot_dashboard` },
    ...storeItems,
  ]
}

function getReportsItems() {
  return [
    { name: 'Historical Logs', id: 0, navigateTo: `${orgUrl()}` },
    { name: 'Current Exceptions', id: 1, navigateTo: `${orgUrl()}` },
    { name: 'Advanced Reports', id: 2, navigateTo: '' },
  ]
}

export class CorporateUser extends React.Component {
  componentWillMount() {
    this.loadOrganizationDataForMtiAdmin()
  }

  loadOrganizationDataForMtiAdmin() {
    const {
      loadDataForOrganization,
      match: { params: { oId } = {} } = {},
    } = this.props
    if (oId) {
      loadDataForOrganization()
    }
  }

  onSelectStore = (onStoreSelected) => {
    const { openModal: doOpenModal, closeModal: doCloseModal } = this.props

    doOpenModal({
      id: 'select-store',
      type: 'bootstrap',
      content: (
        <Stores
          ref={(ref) => (this.selectStoreModal = ref)}
          isSelectStoreModal
          id={'select-store'}
          onClose={() => {
              doCloseModal({ id: 'select-store' })
          }}
          onStoreSelected={(store) => {
              doCloseModal({ id: 'select-store' })
              setTimeout(() => onStoreSelected(store), 500)
          }}
        />
      ),
    })
  }

  static onItem = (event, item, props) => {
    if (!item || !item.name) return
    const {
      hasViewKeysPermission,
      hasViewStoreReportsPermission,
      notifyModal: doNotifyModal,
      history,
      organization,
    } = props
    switch (item.name) {
      case 'Keys': {
        if (!hasViewKeysPermission) {
          doNotifyModal(noPermissionTextKeys)
        }
        break
      }
      case 'Historical Logs': {
        if (hasViewStoreReportsPermission) {
          setTimeout(() => {
            const path = `${orgUrl()}/historical_logs`
            if (history) {
              history.push(path)
            } else {
              location.href = path
            }
          }, 100)
        } else {
          doNotifyModal(noPermissionTextReports)
        }
        break
      }
      case 'Current Exceptions': {
        if (hasViewStoreReportsPermission) {
          setTimeout(() => {
            const path = `${orgUrl()}/current_exceptions`
            if (history) {
              history.push(path)
            } else {
              location.href = path
            }
          }, 100)
        } else {
          doNotifyModal(noPermissionTextReports)
        }
        break
      }
      case 'Advanced Reports': {
        const path = 'https://sso.online.tableau.com/public/idp/SSO'
        window.open(path, '_blank')
        break
      }
      case 'Organization Details':
        event.preventDefault()
        CorporateUser.onOrganizationSelection(organization, props)
        break
      case 'Stores':
      case 'Store Status Dashboard':
      case 'View Store':
      case 'View Store Details':
      case 'Users':
      // case 'Templates':
      case 'Regions':
      case 'Schedules':
      case 'Secured Products':
      case 'Table View':
      case 'Orphaned Devices':
      case 'IOT Status Dashboard':
        break
      default:
        doNotifyModal(inDevelopment)
        break
    }
  }

  static onOrganizationSelection = (organization, props) => {
    const {
      organizationDetailsAction,
      loadOrganization,
      match: { params: { oId } = {} } = {},
    } = props
    if (oId) {
      loadOrganization()
    }
    organizationDetailsAction(organization)
  }

  getStore = () => {
    if (!this.props.hasMtiAdminRole) {
      return this.props.store
    }
  }

  render() {
    return (
      <div>
        <CorporateUserScreen
          quickActionsItems={getQuickActionsItems(this.getStore(), this.props)}
          reportsItems={getReportsItems()}
          height={getTableHeight()}
          onItem={(event, item) =>
            CorporateUser.onItem(event, item, this.props)
          }
          history={this.props.history}
        />
      </div>
    )
  }
}

CorporateUser.propTypes = {
  match: PropTypes.any,
  notifyModal: PropTypes.func,
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
  organizationDetailsAction: PropTypes.func,
  loadDataForOrganization: PropTypes.func,
  loadOrganization: PropTypes.func,
  history: PropTypes.object,
  hasViewKeysPermission: PropTypes.bool,
  hasViewStoreReportsPermission: PropTypes.bool,
}

const mapStateToProps = createStructuredSelector({
  hasViewKeysPermission: (state, ownProps) =>
    makeSelectMtiPermission(
      'Physical Key: Index (list) Records',
      (ownProps.store || {}).id
    )(state, ownProps), // deprecated name 'viewKeys'
  hasViewStoreReportsPermission: (state, ownProps) =>
    makeSelectMtiPermission(
      'Saved Report: Index (list) Records',
      (ownProps.store || {}).id
    )(state, ownProps), // deprecated name 'viewStoreReports',
  hasMtiAdminRole: selectIsMTIUser,
  organization: makeSelectOrganizationDetails(),
  store: (state, ownProps) =>
    selectStoreById({
      sId: getStoreId(state),
    })(state, ownProps),
  hasMissingSecuredProducts: (state) =>
    selectStoreHasMissingSecuredProducts(state, getStoreId(state)),
})

const mapDispatchToProps = {
  notifyModal,
  openModal,
  closeModal,
  organizationDetailsAction,
  loadOrganization,
  loadDataForOrganization,
}

export const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect)(CorporateUser)
