import React from 'react'
import Select from 'react-select'
import PropTypes from 'prop-types'

const TimezonePickerScreen = (props) => {
  const { value, options, handleChange, className, validation } = props

  const customStyles = {
    container: () => ({
      width: '100%',
    }),
  }

  return (
    <React.Fragment>
      <Select
        ref={(ref) => {
          if (ref && ref.select && ref.select.inputRef) {
            ref.select.inputRef.setAttribute('readonly', 'readonly')
          }
        }}
        value={value}
        onChange={handleChange}
        options={options}
        className={`timezone-picker ${
          validation ? 'p-0 form-control is-invalid' : ''
        }  ${className ? className : ''}`}
        styles={customStyles}
        defaultValue={{ label: 'America/Los_Angeles', value: 0 }}
      />
      <div className="invalid-feedback">Please select a valid time zone.</div>
    </React.Fragment>
  )
}

TimezonePickerScreen.propTypes = {
  value: PropTypes.object,
  validation: PropTypes.any,
  options: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
  className: PropTypes.string,
}

export default TimezonePickerScreen
