import React from 'react'
import PropTypes from 'prop-types'
import DatePicker from 'react-datepicker/es'
import { CustomInput } from './CustomInput'

class DatePickerInput extends React.PureComponent {
  constructor(props) {
    super(props)
    const { value } = props
    this.state = {
      value,
    }
  }

  onChange = (value) => {
    if (value) {
      this.setState({ isValid: true, value })
      this.props.onChange(value)
    } else {
      this.setState({ isValid: false, value })
    }
  }

  setCustomValidity(msg) {
    const element = this.container
    if (msg === '') {
      this.setServerErrorDismiss(element)
    } else {
      this.setServerError(element, msg)
    }
  }

  setServerErrorDismiss(element) {
    element.keyup(() => {
      element.attr('data-content', '')
      element.popover('hide')
    })
  }

  setServerError(element, msg) {
    element.attr('data-placement', 'bottom')
    element.attr('data-trigger', 'focus')
    element.attr('data-content', msg)
    element.popover('show')
    setTimeout(() => {
      element.attr('data-content', '') // eslint-disable-line
      element.popover('dispose') // eslint-disable-line
    }, 3000)
  }

  inputValue = () => this.state.value

  isCustom() {
    return true
  }

  isValid() {
    return this.state.isValid
  }

  hidePopover = () => {
    this.container.popover('dispose')
  }

  showInvalidMessage = (msg) => this.setCustomValidity(msg)

  render() {
    return (
      <div
        ref={(ref) => {
          this.container = $(ref) // eslint-disable-line
          this.setServerErrorDismiss(this.container)
        }}
      >
        <DatePicker
          customInput={<CustomInput />}
          onFocus={this.hidePopover}
          selected={this.state.value}
          onChange={(d) => this.onChange(d)}
        />
      </div>
    )
  }
}

DatePickerInput.defaultProps = {
  onChange: () => {},
}

DatePickerInput.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
}

export default DatePickerInput
