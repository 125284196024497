import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import moment from 'moment'
import styles from '../../../styles/App.css'
import Radio from '../../../components/Radio'
import { fullTextFilter } from '../../../utils/utils'
import { renderHeaderCell } from '../../UsersPage/index.screen'
import { figureType } from '../../TemplatePositions/utils'
import { TableInModal } from '../../../styles/styled'
import ProgressIndicator from '../../../components/ProgressIndicator'
import TemplateSelectPreloader from '../../../components/Preloaders/TemplateSelectPreloader'

export const ModalBody = styled.div`
  padding: 0 !important;
`
export const CellContainer = styled.div`
  overflow: hidden;
`

/* eslint-disable react/prop-types, no-underscore-dangle */
const renderMainContent = (
  selectedTemplate,
  existingTemplate,
  templates,
  onSelection
) => {
  const { id: selectedId } = selectedTemplate || {}
  const { id: existingId } = existingTemplate || {}

  const columns = [
    {
      id: 'select',
      width: 75,
      style: { justifyContent: 'center' },
      accessor: (d) => d.id !== existingId && d.assigned,
      Cell: ({ original: { id, assigned }, original }) => (
        <CellContainer>
          <Radio
            id={`template-${id}`}
            name={'template'}
            onChange={() => onSelection({ selectedTemplate: original })}
            checked={id === selectedId}
            disabled={id !== existingId && assigned}
          />
        </CellContainer>
      ),
    },
    {
      id: 'templateName',
      Header: renderHeaderCell('Template Name'),
      accessor: 'name',
      filterable: true,
      Cell: ({ original }) => <CellContainer>{original.name}</CellContainer>,
    },
    {
      id: 'templateShape',
      Header: renderHeaderCell('Template Shape'),
      accessor: 'type',
      filterable: true,
      Cell: ({ original }) => (
        <CellContainer>{figureType[original.type]}</CellContainer>
      ),
    },
    {
      id: 'numOfPositions',
      Header: renderHeaderCell('# of Positions', true),
      accessor: 'positionsCount',
      Cell: ({ original: { positionsCount } }) => (
        <CellContainer>{positionsCount}</CellContainer>
      ),
      width: 75,
      filterable: true,
    },
    {
      id: 'createdAt',
      Header: renderHeaderCell('Date Created', true),
      width: 120,
      accessor: 'createdAt',
      Cell: ({ original: { createdAt } }) => (
        <CellContainer>
          {createdAt
            ? moment(createdAt)
              .utcOffset(0)
              .format('MM/DD/YYYY')
            : ''}
        </CellContainer>
      ),
      filterable: true,
    },
  ]

  return (
    <TableInModal
      data={templates}
      className="full-screen"
      style={{ height: '525px' || 'auto' }}
      columns={columns}
      pageSizeOptions={[5, 10, 20, 25, 50, 100]}
      defaultPageSize={25}
      defaultFilterMethod={fullTextFilter}
      defaultSorted={[
        {
          id: 'createdAt',
          desc: true,
        },
      ]}
    />
  )
}

const SelectTemplateScreen = ({
  id,
  templates,
  selectedTemplate,
  existingTemplate,
  onConfirm,
  onClose,
  onSelection,
  onResetTemplate,
  templatesLoading,
}) => (
  <div className="modal-content">
    <div className="modal-header border-bottom">
      <h5 className="modal-title" id="exampleModalLongTitle">
        Templates
      </h5>
      <div>
        {onResetTemplate && (
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              $(`#modal-${id}`).modal('hide') // eslint-disable-line
              onResetTemplate()
            }}
          >
            Unselect & Delete Compliance Rules
          </button>
        )}
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            $(`#modal-${id}`).modal('hide') // eslint-disable-line
            onClose()
          }}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-raised"
          onClick={() => {
            $(`#modal-${id}`).modal('hide') // eslint-disable-line
            onConfirm()
          }}
        >
          Select Template
        </button>
      </div>
    </div>
    {templatesLoading ? (
      <React.Fragment>
        <TemplateSelectPreloader />
        <ProgressIndicator />
      </React.Fragment>
    ) : (
      <ModalBody className="modal-body">
        <React.Fragment>
          {templates ? (
            <div className={styles.modalContentWrapper}>
              {renderMainContent(
                selectedTemplate,
                existingTemplate,
                templates,
                onSelection
              )}
            </div>
          ) : (
            <div
              style={{
                flex: 1,
                textAlign: 'center',
                marginTop: '20px',
                height: '525px',
              }}
            >
              {' '}
              No templates available in this organization.
            </div>
          )}
        </React.Fragment>
      </ModalBody>
    )}
  </div>
)

SelectTemplateScreen.propTypes = {
  id: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onResetTemplate: PropTypes.func,
  onSelection: PropTypes.func.isRequired,
  templates: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  selectedTemplate: PropTypes.object,
  existingTemplate: PropTypes.object,
  templatesLoading: PropTypes.bool.isRequired,
}

export default SelectTemplateScreen
