import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import ReactTable from 'react-table'
import { Link } from 'react-router-dom'
import styles from '../../styles/App.css'
import { fullTextFilter } from '../../utils/utils'
import {
  CellContainer,
  renderFilterInput,
  renderHeaderCell,
} from '../UsersPage/index.screen'

/* eslint-disable react/prop-types, no-underscore-dangle */
const renderContent = (users, onUser, modalId) => {
  const columns = [
    {
      sortable: true,
      filterable: true,
      Header: renderHeaderCell('Name'),
      accessor: 'name',
      Cell: ({ original: { name } }) => (
        <CellContainer>
          <Link className={styles.link} to={'#'}>
            {name}
          </Link>
        </CellContainer>
      ),
      Filter: ({ filter, onChange }) =>
        renderFilterInput({ placeholder: 'Filter', filter, onChange }),
    },
    {
      sortable: true,
      filterable: true,
      Header: renderHeaderCell('Role'),
      accessor: 'rolesTitle',
      Filter: ({ filter, onChange }) =>
        renderFilterInput({ placeholder: 'Filter', filter, onChange }),
    },
    {
      sortable: true,
      filterable: true,
      Header: renderHeaderCell('Last signed in at', true),
      accessor: 'lastSignedInAt',
      Cell: ({ original: { lastSignedInAt } }) => (
        <span>
          {lastSignedInAt &&
            moment(lastSignedInAt)
              .utcOffset(0)
              .format('MM/DD/YYYY H:mm')}
        </span>
      ),
      Filter: ({ filter, onChange }) =>
        renderFilterInput({
          placeholder: 'Filter',
          filter,
          onChange,
        }),
    },
  ]

  return (
    <div>
      <ReactTable
        data={users || []}
        columns={columns}
        defaultFilterMethod={fullTextFilter}
        getTdProps={(state, rowInfo) => ({
          onClick: (e, handleOriginal) => {
            $(`#modal-${modalId}`).modal('hide') // eslint-disable-line
            onUser(rowInfo.original)

            if (handleOriginal) {
              handleOriginal()
            }
          },
        })}
      />
    </div>
  )
}

function UsersPageModalScreen({ users, onUser, id, onClose }) {
  return (
    <div className="modal-content">
      <div className="modal-header border-bottom">
        <h5 className="modal-title" id="exampleModalLongTitle">
          Assign User
        </h5>
        <div>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              $(`#modal-${id}`).modal('hide') // eslint-disable-line
              onClose()
            }}
          >
            Close
          </button>
        </div>
      </div>
      <div className="modal-body">{renderContent(users, onUser, id)}</div>
    </div>
  )
}

UsersPageModalScreen.propTypes = {
  users: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
  onUser: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default UsersPageModalScreen
