import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Select from '../../../components/Select'
import {
  PositionRuleSourceAttributesModel,
  sourceAttrName,
  conditionName,
  PARAMETER_TYPE_CONTROL,
  SECURITY_DEVICES,
  SOURCE_ATTRIBUTES,
  CONDITIONS,
  MULTI_PORT_COMPLIANCE_DEVICE,
} from './ruleConstants'
import {
  ColumnContainer,
  Column,
} from '../../StoresPage/CreateEditStoreModal/index.screen'
import SelectProducts from './SelectProducts'
import { injectIntl } from 'react-intl'

export const ModalBody = styled.div`
  padding: 10px 10px 10px 10px;
`

export const SelectionButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const TableWithButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

/* eslint-disable react/prop-types */
/**
 * Renders options for the rule type
 *
 * @returns {*}
 */
const renderRuleTypeOptions = () =>
  Object.keys(PositionRuleSourceAttributesModel).map((attr) => (
    <option key={attr} value={attr}>
      {sourceAttrName[attr]}
    </option>
  ))

/**
 * Renders options for the rule conditions
 *
 * @param ruleType
 * @returns {*}
 */
const renderConditionOptions = (ruleType) => {
  const sourceAttribute = PositionRuleSourceAttributesModel[ruleType] || {}
  const conditions = sourceAttribute.conditions || []

  return conditions.map((condition) => (
    <option key={condition} value={condition}>
      {conditionName[condition]}
    </option>
  ))
}

class PositionRulesScreen extends React.Component {
  constructor(props) {
    super(props)
  }

  renderProductsList = () => {
    const {
      products,
      onAddSelectedProducts,
      onRemoveSelectedProducts,
      selectedProducts,
    } = this.props

    return (
      <SelectProducts
        products={products}
        onAddSelectedProducts={onAddSelectedProducts}
        onRemoveSelectedProducts={onRemoveSelectedProducts}
        selectedProducts={selectedProducts}
      />
    )
  }

  renderParamControl = () => {
    const { ruleType, onChange, deviceType: device, intl } = this.props
    const sourceAttribute = PositionRuleSourceAttributesModel[ruleType] || {}
    const paramTypeControl = sourceAttribute.paramTypeControl

    switch (paramTypeControl) {
      case PARAMETER_TYPE_CONTROL.deviceType:
        return (
          <Select
            customStyle="pt-0"
            name="security-type"
            label="Security Device Type"
            value={device}
            onChange={({ target: { value } }) =>
              onChange({ deviceType: value })
            }
          >
            <option value={''}>Please select rule type...</option>
            {SECURITY_DEVICES.map((key) => (
              <option key={key} value={key}>
                {intl.formatMessage({ id: `deviceTypes.${key}` })}
              </option>
            ))}
          </Select>
        )
      case PARAMETER_TYPE_CONTROL.products:
        return this.renderProductsList()
      default:
        return null
    }
  }

  renderRuleConfigurationOptions = () => {
    const { ruleType, condition, onChange } = this.props

    if (!ruleType) return null
    const sourceAttribute = PositionRuleSourceAttributesModel[ruleType] || {}
    const paramTypeControl = sourceAttribute.paramTypeControl

    switch (paramTypeControl) {
      case PARAMETER_TYPE_CONTROL.deviceType:
        return (
          <div className="form-row">
            <ColumnContainer className="form-group col-md-6 bmd-form-group">
              <Column>
                <Select
                  customStyle="pt-0"
                  name="condition"
                  value={condition}
                  label={'Condition'}
                  onChange={(e) => onChange({ condition: e.target.value })}
                >
                  {renderConditionOptions(ruleType)}
                </Select>
              </Column>
            </ColumnContainer>
            <ColumnContainer className="form-group col-md-6 bmd-form-group">
              <Column>{this.renderParamControl()}</Column>
            </ColumnContainer>
          </div>
        )
      case PARAMETER_TYPE_CONTROL.products:
        return (
          <div>
            <div className="form-row">
              <Select
                customStyle="pt-0"
                name="condition"
                value={condition}
                label={'Condition'}
                onChange={(e) => onChange({ condition: e.target.value })}
              >
                {renderConditionOptions(ruleType)}
              </Select>
            </div>
            {this.renderParamControl()}
          </div>
        )
      default:
        return null
    }
  }

  renderMainContent = () => {
    const { ruleType, onChange } = this.props
    return (
      <div className="col-xs-6">
        <div className="form-row">
          <Select
            customStyle="pt-0"
            name="ruleType"
            value={ruleType}
            label={'Rule Type'}
            onChange={(e) =>
              onChange({
                ruleType: e.target.value,
                condition: PositionRuleSourceAttributesModel[e.target.value]
                  ? PositionRuleSourceAttributesModel[e.target.value]
                    .conditions[0]
                  : null,
                param: null,
              })
            }
          >
            <option value={''}>Please select rule type...</option>
            {renderRuleTypeOptions()}
          </Select>
        </div>
        {this.renderRuleConfigurationOptions()}
      </div>
    )
  }

  hasMultiPortCompliance = () => {
    const { ruleType, condition, deviceType } = this.props
    if (
      ruleType === SOURCE_ATTRIBUTES.security_device_type &&
      condition === CONDITIONS.eq &&
      MULTI_PORT_COMPLIANCE_DEVICE.includes(deviceType)
    ) {
      return true
    }
    return false
  }

  render() {
    const {
      id,
      onConfirm,
      onClose,
      onDelete,
      onAddPortComplianceRules,
      deviceType,
    } = this.props

    let enablePortCompliance = this.hasMultiPortCompliance()
    return (
      <div className="modal-content">
        <div className="modal-header border-bottom">
          <h5 className="modal-title" id="exampleModalLongTitle">
            Add position compliance rules
          </h5>
          <div>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                $(`#modal-${id}`).modal('hide') // eslint-disable-line
                onConfirm()
                setTimeout(() => onAddPortComplianceRules(deviceType), 1000)
              }}
            >
              {enablePortCompliance ? 'Add port compliance rules' : null}
            </button>
          </div>
          <div>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                $(`#modal-${id}`).modal('hide') // eslint-disable-line
                onClose()
              }}
            >
              Cancel
            </button>
            {onDelete ? (
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  $(`#modal-${id}`).modal('hide') // eslint-disable-line
                  onDelete()
                }}
              >
                Delete
              </button>
            ) : null}
            <button
              type="button"
              className="btn btn-raised"
              onClick={() => {
                $(`#modal-${id}`).modal('hide') // eslint-disable-line
                onConfirm()
              }}
            >
              Save
            </button>
          </div>
        </div>
        <ModalBody className="modal-body">{this.renderMainContent()}</ModalBody>
      </div>
    )
  }
}

PositionRulesScreen.propTypes = {
  id: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  ruleType: PropTypes.string,
  condition: PropTypes.string,
  products: PropTypes.array.isRequired,
  selectedProducts: PropTypes.array.isRequired,
  deviceType: PropTypes.string.isRequired,
  onAddSelectedProducts: PropTypes.func.isRequired,
  onRemoveSelectedProducts: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  onAddPortComplianceRules: PropTypes.func,
}

export default injectIntl(PositionRulesScreen)
