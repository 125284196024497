import {
  getAreaColor as getStatusColor,
  normalColor,
  editColor,
} from '../FloorCanvas/area'

export const furnitureColor = {
  weak: 'rgba(102,102,102,0.2)',
  main: 'rgba(102,102,102,1)',
}

/**
 * Get the proper fill color for the fixture
 *
 * @param alertType
 * @param isStatic
 * @param isFurniture
 * @param positionsCount
 * @returns {string}
 */
export function getFixtureColor(
  alertType,
  isStatic = true,
  isFurniture = false,
  positionsCount,
  mtiJsclientShared
) {
  if (isFurniture)
    return { weakColor: furnitureColor.weak, mainColor: furnitureColor.main }
  if (!isStatic) return { weakColor: editColor.weak, mainColor: editColor.main }
  if (positionsCount === 0)
    return { weakColor: normalColor.weak, mainColor: normalColor.main }

  const { weakColor, mainColor } = getStatusColor(
    isStatic ? alertType : 'edit',
    positionsCount === 0,
    mtiJsclientShared
  )

  return { weakColor, mainColor }
}
