import { createSelector } from 'reselect'
import { createSelector as createOrmSelector } from 'redux-orm'
import { orm } from 'mti-jsclient-shared'

const dbStateSelector = state => state.get('orm')

export const selectStoreIDFromORM = createOrmSelector(
  orm,
  dbStateSelector,
  session => {
    try {
      return session.Store.all().first().id
    } catch (e) {
      return undefined
    }
  }
)

export const selectStoreID = state => {
  // try to get the store id from the user first, in case the stores aren't loaded
  const me = state.get('app').get('me')
  if (me && me.resourceType === 'Store') {
    return me.roleResourceIds[0]
  }

  // if this is not a store user, then try to select the store id from the first store in redux instead
  return selectStoreIDFromORM(state)
}
