import React from 'react'
import PropTypes from 'prop-types'
import CreateEditOrganizationScreen from './index.screen'

class CreateEditOrganization extends React.PureComponent {
  setServerValidity(error) {
    if (this.createEditOrganizationModal) {
      this.createEditOrganizationModal.setServerValidity(error)
    }
  }

  close() {
    if (this.createEditOrganizationModal)
      this.createEditOrganizationModal.close()
  }

  setLoading(loading) {
    this.setState({ loading })
  }

  render() {
    const {
      id,
      organization,
      onChange,
      onConfirm,
      onClose,
      onRemoveAll,
      title,
    } = this.props
    const { loading } = this.state || {}

    return (
      <CreateEditOrganizationScreen
        ref={(ref) => (this.createEditOrganizationModal = ref)}
        id={id}
        organization={organization}
        onConfirm={onConfirm}
        onClose={onClose}
        onChange={onChange}
        onRemoveAll={onRemoveAll}
        loading={loading}
        title={title}
      />
    )
  }
}

CreateEditOrganization.propTypes = {
  id: PropTypes.string.isRequired,
  organization: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
}

export default CreateEditOrganization
