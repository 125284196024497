/*
 *
 * AreaPage constants
 *
 */

import keyMirror from 'fbjs/lib/keyMirror'

/**
 * @constant {Object} ActionTypes
 * @memberof Constants
 */
export const ActionTypes = keyMirror({
  STORE_AREA_PROTOTYPES: undefined,
  STORE_AREA_FB: undefined,
  STORE_PARAMS: undefined,
  POST_AREA_CANVAS_FAILED: undefined,
  POST_AREA_CANVAS_FINISHED: undefined,
  POST_AREA_CANVAS_PENDING: undefined,
  FETCH_AREA_CANVAS_FAILED: undefined,
  FETCH_AREA_CANVAS_FULFILLED: undefined,
  FETCH_AREA_CANVAS_PENDING: undefined,
  LOAD_AREA_CANVAS: undefined,
  SAVE_AREA_CANVAS: undefined,
  SAVE_AREA_FIXTURE: undefined,
  AREA_CANVAS_APPLY_TEMPLATE: undefined,
  AREA_CANVAS_FIXTURE_REMOVE: undefined,
  AREA_CANVAS_CHANGED: undefined,
  AREA_CANVAS_NAME_CHANGED: undefined,
  AREA_CANVAS_IS_FURNITURE_CHANGED: undefined,
  AREA_CANVAS_POSITION_CHANGED: undefined,
  AREA_CANVAS_FIGURE_BEGIN_TRANSFORMATION: undefined,
  AREA_CANVAS_FIGURE_BEGIN_CUSTOM_ROTATION: undefined,
  AREA_CANVAS_FIGURE_SIZE_CHANGED: undefined,
  AREA_CANVAS_FIXTURE_NAME_TOGGLED: undefined,
  AREA_CANVAS_APPLY_FUTURE_TEMPLATE_UPDATES_TOGGLED: undefined,
  AREA_CANVAS_FIGURE_UNSELECT_TEMPLATE: undefined,
  STORE_AREA_LOADING: undefined,
  DELETE_ALL_COMPLIANCE_RULES: undefined,
  RESET_FIXTURE_TEMPLATE: undefined,
  LOAD_FIXTURE_TEMPLATE: undefined,
  LOAD_FIXTURE_TEMPLATE_PENDING: undefined,
  LOAD_FIXTURE_TEMPLATE_FULFILLED: undefined,
  LOAD_FIXTURE_TEMPLATE_FAILED: undefined,
})
