/**
 *
 * ActionButton
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

export const ActionButtonStyle = styled.button`
  margin-left: 5%;
  margin-right: 5%;
  width: 90%;
  height: 100%;
`

function ActionButton({ text, onClick }) {
  return (
    <ActionButtonStyle
      style={{
        backgroundColor: 'white',
      }}
      type="button"
      onClick={onClick}
    >
      <span style={{ color: 'black' }}>{text}</span>
    </ActionButtonStyle>
  )
}

ActionButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default ActionButton
