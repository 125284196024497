import { getAreaName } from './utils'

export function fromFabricAreaFb(o, screen) {
  if (!o) return o
  const w = o.width * o.scaleX
  const h = o.height * o.scaleY
  const cX = o.left
  const cY = o.top

  const attrs = {
    id: o.id,
    height: h / screen.width,
    width: w / screen.width,
    theta: o.angle,
    xCenter: cX / screen.width,
    yCenter: cY / screen.width,
    name: getAreaName(o),
    displayAreaName: o.displayAreaName,
  }
  return attrs
}
