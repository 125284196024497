import { ActionTypes } from '../constants'

/**
 * Fetch And Upsert Store
 *
 * @returns {Object}
 */
export function fetchAndUpsertStore(sId, force) {
  return {
    type: ActionTypes.ORM_FETCH_AND_UPSERT_STORE,
    payload: { sId, force },
  }
}

/**
 * Fetch And Upsert Store Pending
 *
 * @returns {Object}
 */
export function fetchAndUpsertStorePending(sId) {
  return {
    type: ActionTypes.ORM_FETCH_AND_UPSERT_STORE_PENDING,
    payload: { sId },
  }
}

/**
 * Fetch And Upsert Store Fulfilled
 *
 * @returns {Object}
 */
export function fetchAndUpsertStoreFulfilled(sId) {
  return {
    type: ActionTypes.ORM_FETCH_AND_UPSERT_STORE_FULFILLED,
    payload: { sId },
  }
}

/**
 * Fetch And Upsert Store Failed
 *
 * @returns {Object}
 */
export function fetchAndUpsertStoreFailed(sId, error) {
  return {
    type: ActionTypes.ORM_FETCH_AND_UPSERT_STORE_FAILED,
    payload: { sId, error },
  }
}

/**
 * Fetch And Upsert Geometry Pending
 *
 * @returns {Object}
 */
export function fetchAndUpsertGeometryPending(sId) {
  return {
    type: ActionTypes.ORM_FETCH_AND_UPSERT_GEOMETRY_PENDING,
    payload: { sId },
  }
}

/**
 * Fetch And Upsert Geometry Fulfilled
 *
 * @returns {Object}
 */
export function fetchAndUpsertGeometryFulfilled(sId) {
  return {
    type: ActionTypes.ORM_FETCH_AND_UPSERT_GEOMETRY_FULFILLED,
    payload: { sId },
  }
}

/**
 * Fetch And Upsert Geometry Failed
 *
 * @returns {Object}
 */
export function fetchAndUpsertGeometryFailed(sId, error) {
  return {
    type: ActionTypes.ORM_FETCH_AND_UPSERT_GEOMETRY_FAILED,
    payload: { sId, error },
  }
}

/**
 * Upsert Orm
 *
 * @returns {Object}
 */
export function upsertStore(store) {
  return {
    type: ActionTypes.ORM_UPSERT,
    payload: store,
  }
}

/**
 * Upsert manufacturers
 *
 * @returns {Object}
 */
export function upsertManufacturers(store) {
  return {
    type: ActionTypes.ORM_UPSERT_MANUFACTURERS,
    payload: store,
  }
}

/**
 * Upsert secured products
 *
 * @returns {Object}
 */
export function upsertSecuredProducts(store) {
  return {
    type: ActionTypes.ORM_UPSERT_SECURED_PRODUCTS,
    payload: store,
  }
}

/**
 * Update secured products
 *
 * @returns {Object}
 */
export function updateSecuredProducts(store) {
  return {
    type: ActionTypes.ORM_UPDATE_SECURED_PRODUCTS,
    payload: store,
  }
}

/**
 * Reset Orm
 *
 * @returns {Object}
 */
export function resetOrm() {
  return {
    type: ActionTypes.ORM_RESET,
    payload: {},
  }
}
