import moment from 'moment'
import { createSelector } from 'reselect'
import { createSelector as createOrmSelector } from 'redux-orm'
import {
  orm,
  sortedPositionsByState,
  areaCanvasById,
} from 'mti-jsclient-shared'
import mtiJsclientShared from '../../utils/mtiJsclientShared'
import {
  filterIssues,
  getPositionItemDataExtended,
  canvasSize,
  sortRules,
} from '../../utils/mtiUtils'
import { toFabricSync } from './utils'
import { getNotCompliantFixturesForArea } from '../FloorPage/selectors'

const dbStateSelector = state => state.get('orm')

const selectIsAreaById = aId =>
  createOrmSelector(orm, session => {
    try {
      if (!session.Area.hasId(aId)) {
        return false
      }
      return true
    } catch (e) {
      return false
    }
  })

const makeSelectAreaExist = aId =>
  createSelector(dbStateSelector, ormState => selectIsAreaById(aId)(ormState))

const makeSelectFixturesWithLayoutExist = aId =>
  createSelector(dbStateSelector, ormState =>
    createOrmSelector(orm, session => {
      try {
        const fixturesReadyToBeDrawn = session.Area.withId(aId)
          .fixtureIds.toModelArray()
          .filter(i => i.layoutPositionId)
        return !!fixturesReadyToBeDrawn.length
      } catch (e) {
        return false
      }
    })(ormState)
  )

const makeSelectAreaById = aId =>
  createOrmSelector(orm, session => {
    try {
      const area = areaCanvasById(session, aId)

      return area
    } catch (e) {
      return {}
    }
  })

const makeSelectArea = aId =>
  createSelector(dbStateSelector, ormState => makeSelectAreaById(aId)(ormState))

const selectAreasInStore = storeId => {
  try {
    console.log('makeSelectAreasInStore', storeId)
    return createOrmSelector(orm, session => {
      if (session.Store.hasId(storeId)) {
        const store = session.Store.withId(storeId)
        console.log('store', store)
        return store
      }
      return undefined
    })
  } catch (e) {
    return {}
  }
}

const makeSelectAreasInStore = storeId =>
  createSelector(dbStateSelector, ormState =>
    selectAreasInStore(storeId)(ormState)
  )

const selectPositionsByAreaId = aId =>
  createOrmSelector(orm, session => {
    try {
      // Issues for area
      const area = session.Area.withId(aId)
      const floor = session.Floor.withId(area.ref.floorId)
      const { id: storeId, name: storeName } = session.Store.withId(
        floor.ref.storeId
      ).ref
      const areaPsitions = area.fixtureIds
        .toModelArray()
        .map(fixture => fixture.positionIds.toModelArray())
      const areaPositionsFlatterd = [].concat(...areaPsitions)
      const areaPositionsSorted = sortedPositionsByState(areaPositionsFlatterd)
      const areaPositionsExtended = filterIssues(
        areaPositionsSorted
          .map(p => getPositionItemDataExtended(p, storeId, storeName))
          .filter(n => n)
      )

      // Issues for fixture selected
      const positionsByFixtureId = {}
      area.fixtureIds.toModelArray().map(fixture => {
        const fixturePositions = fixture.positionIds.toModelArray()
        const fixturePositionsFlatterd = [].concat(...fixturePositions)
        const fixturePositionsSorted = sortedPositionsByState(
          fixturePositionsFlatterd
        )
        const fixturePositionsExtended = filterIssues(
          fixturePositionsSorted
            .map(p => getPositionItemDataExtended(p, storeId, storeName))
            .filter(n => n)
        )

        positionsByFixtureId[fixture.ref.id] = fixturePositionsExtended
        return fixturePositionsExtended
      })
      return {
        positionsArea: areaPositionsExtended,
        positionsByFixtureId,
      }
    } catch (e) {
      return {}
    }
  })

const makeSelectPositionsByArea = aId =>
  createSelector(dbStateSelector, ormState =>
    selectPositionsByAreaId(aId)(ormState)
  )

const selectAreaDimensionsById = aId =>
  createOrmSelector(orm, session => {
    try {
      const area = session.Area.withId(aId)
      const { width, height } = area.layoutPositionId.ref
      return { layoutPosition: { width, height } }
    } catch (e) {
      return { layoutPosition: { width: 1, height: 0.667 } }
    }
  })

const makeSelectAreaDimensions = aId =>
  createSelector(dbStateSelector, ormState =>
    selectAreaDimensionsById(aId)(ormState)
  )

const selectCanvas = state => state.get('areaCanvas')

const ormAreaStateSelector = state => ({
  orm: state.get('orm'),
  area: state.get('areaCanvas'),
})

const makeSelectCanvasObject = aId =>
  createSelector(ormAreaStateSelector, ormArea => {
    const isStatic = ormArea.area.get('isStatic')
    const prototypes = ormArea.area.get('prototypes')

    if (!(isStatic && prototypes)) {
      return ormArea.area.get('canvasObject').toJS()
    }

    const area = makeSelectAreaById(aId)(ormArea.orm)
    const screen = ormArea.area.get('screen') || canvasSize
    const { selectedId } = ormArea.area.get('canvasObject').toJS() || {}

    const { canvasObject } = toFabricSync(
      area,
      screen,
      isStatic,
      false,
      selectedId || -1,
      prototypes,
      mtiJsclientShared
    )

    return canvasObject
  })

/*
const makeSelectCanvasObject = () =>
  createSelector(selectCanvas, (canvasState) =>
    canvasState.get('canvasObject').toJS()
  )
*/

const canvasSelectedIdSelector =
  createSelector(
    selectCanvas,
    canvasState => canvasState && canvasState.get('selectedId')
  )

const failedSelector =
  createSelector(selectCanvas, canvas => canvas.get('failed'))

const loadingSelector =
  createSelector(selectCanvas, canvas => canvas.get('loading'))

const isStaticSelector =
  createSelector(selectCanvas, canvas =>
    (canvas || { get: () => true }).get('isStatic')
  )

const prototypesSelector =
  createSelector(selectCanvas, canvas => canvas.get('prototypes'))

const fixtureToRemoveRulesSelector =
  createSelector(selectCanvas, canvas => canvas.get('fixtureToRemoveRules'))

const getCanvasData = canvas => {
  const selectedId = canvas.get('selectedId')
  const objects = canvas.get('canvasObject').get('objects')
  return { selectedId, objects }
}

const makeSelectSelectedTemplate = () =>
  createSelector(selectCanvas, canvas => canvas.get('selectedTemplate'))

const selectTemplatesFixtures = createOrmSelector(orm, session => {
  try {
    return session.Fixture.filter(({ template }) => template === true)
      .toModelArray()
      .map(f => {
        // Fixture Rules data
        const fixtureRules = f.ruleIds.toRefArray()
        // Positions data
        let layoutPosition
        if (session.FixtureLayoutPosition.hasId(f.ref.layoutPositionId)) {
          layoutPosition = (f.layoutPositionId || {}).ref
        }
        const fixtureObj = {
          ...f.ref,
          layoutPosition,
          parent: null,
        }
        const positions = f.positionIds.toModelArray()
        const positionsExtended = positions.map(p => {
          const rules = p.ruleIds.toRefArray()
          let positionLayoutPosition
          if (session.PositionLayoutPosition.hasId(f.ref.layoutPositionId)) {
            positionLayoutPosition = (p.layoutPositionId || {}).ref
          }
          return {
            ...p.ref,
            rules,
            layoutPosition: positionLayoutPosition,
            parent: fixtureObj,
            path: fixtureObj.name,
          }
        })

        return {
          ...f.ref,
          rules: fixtureRules,
          layoutPosition,
          positions: positionsExtended,
        }
      })
  } catch (e) {
    return []
  }
})

const makeSelectNotCompliantFixturesForArea = areaId =>
  createSelector(dbStateSelector, ormState =>
    createOrmSelector(orm, session => {
      try {
        const areaModel = session.Area.withId(areaId)
        return getNotCompliantFixturesForArea(areaModel).filter(f => f)
      } catch (error) {
        return []
      }
    })(ormState)
  )

const makeSelectFixturesWithRulesForArea = areaId =>
  createSelector(dbStateSelector, ormState =>
    createOrmSelector(orm, session => {
      try {
        const area = session.Area.withId(areaId)
        const fixtureModels = area.fixtureIds.toModelArray()
        if (!fixtureModels || fixtureModels.length === 0) return {}

        return fixtureModels.reduce((obj, fixtureModel) => {
          // Position Rules
          const positions = fixtureModel.positionIds
            .toRefArray()
            .map(position => {
              const rules = position.rules.map(rId => {
                const rule = session.Rule.withId(rId).ref
                return {
                  ...rule,
                  updatedAt: moment(rule.updatedAt).format(
                    'MMM D YYYY hh:mm A'
                  ),
                }
              })
              return {
                ...position,
                rules,
              }
            })
          // Fixture Rules
          const rules = sortRules(
            fixtureModel.ruleIds.toRefArray().map(rule => ({
              ...rule,
              updatedAt: moment(rule.updatedAt).format('MMM D YYYY hh:mm A'),
            }))
          )
          const fixture = fixtureModel.ref
          return { ...obj, [fixture.id]: { ...fixture, rules, positions } }
        }, {})
      } catch (error) {
        return {}
      }
    })(ormState)
  )

const makeSelectTemplateFixtures = () =>
  createSelector(dbStateSelector, ormState =>
    selectTemplatesFixtures(ormState)
  )

const makeSelectFixtureTemplate = () =>
  createSelector(selectCanvas, dbStateSelector, (canvas, ormState) => {
    const { selectedId, objects } = getCanvasData(canvas)
    if (selectedId === -1 || !objects.count()) return []
    const parentId = objects
      .find(i => i.get('id') === selectedId)
      .get('parentId')
    return createOrmSelector(orm, session => {
      try {
        const templateFound = (session.Fixture.filter(
          ({ template }) => template === true
        )
          .filter(({ id: tId }) => tId === parentId)
          .toRefArray() || [])[0]
        const layoutId = templateFound ? templateFound.layoutPositionId : null
        const layoutPosition = layoutId
          ? session.LayoutPosition.withId(templateFound.layoutPositionId).ref
          : null
        return { ...templateFound, layoutPosition }
      } catch (e) {
        return {}
      }
    })(ormState)
  })

const selectApp = state => state.get('app')

const makeSelectFixtureTemplateFromApp = () =>
  createSelector(selectCanvas, selectApp, (canvas, appState) => {
    const selectedTemplate = canvas.get('selectedTemplate')
    if (selectedTemplate) return selectedTemplate

    const { selectedId, objects } = getCanvasData(canvas)
    if (selectedId === -1 || !objects.count()) return []
    const parentId = objects
      .find(i => i.get('id') === selectedId)
      .get('parentId')
    const templates = appState.get('templates')
    const templateFound = (templates || []).find(
      ({ id: tId }) => tId === parentId
    )
    return templateFound
  })

const makeSelectPositionById = pId =>
  createOrmSelector(orm, session => {
    try {
      if (!session.Position.hasId(pId)) {
        console.warn('No Position with id:', pId)
        return {}
      }
      const p = session.Position.withId(pId)
      const rules = p.ruleIds.toRefArray().map(r => ({
        ...r,
        updatedAt: moment(r.updatedAt).format('MMM D YYYY hh:mm A'),
      }))
      return {
        ...p.ref,
        rules,
        layoutPosition: (p.layoutPositionId || {}).ref,
      }
    } catch (e) {
      console.error(e)
      return {}
    }
  })

const makeSelectPosition = pId =>
  createSelector(dbStateSelector, ormState =>
    makeSelectPositionById(pId)(ormState)
  )

const makeSelectFixtureName = () =>
  createSelector(selectCanvas, canvas => {
    const { selectedId, objects } = getCanvasData(canvas)
    if (selectedId === -1 || !objects.count()) return ''
    const object = objects.find(i => i.get('id') === selectedId)
    if (!object) return ''
    return object.get('name')
  })

const makeSelectFixtureSviType = () =>
  createSelector(selectCanvas, canvas => {
    const { selectedId, objects } = getCanvasData(canvas)
    if (selectedId === -1 || !objects.count()) return ''
    const object = objects.find(i => i.get('id') === selectedId)
    if (!object) return ''
    return object.get('sviType')
  })

const makeSelectFixtureSviSizeType = () =>
  createSelector(selectCanvas, canvas => {
    const { selectedId, objects } = getCanvasData(canvas)
    if (selectedId === -1 || !objects.count()) return null
    const object = objects.find(i => i.get('id') === selectedId)
    if (!object) return ''
    return object.get('sviSizeType')
  })

const makeSelectFixtureIsFurniture = () =>
  createSelector(selectCanvas, canvas => {
    const { selectedId, objects } = getCanvasData(canvas)
    if (selectedId === -1 || !objects.count()) return null
    const object = objects.find(i => i.get('id') === selectedId)
    if (!object) return false
    return object.get('isFurniture')
  })

const makeSelectFixtureNameShown = () =>
  createSelector(selectCanvas, canvas => {
    const { selectedId, objects } = getCanvasData(canvas)
    if (selectedId === -1 || !objects.count()) return null
    const object = objects.find(i => i.get('id') === selectedId)
    if (!object) return false
    return object.get('nameHasShown')
  })

const makeSelectFixtureApplyFutureTemplateUpdates = () =>
  createSelector(selectCanvas, canvas => {
    const { selectedId, objects } = getCanvasData(canvas)
    if (selectedId === -1 || !objects.count()) return null
    const object = objects.find(i => i.get('id') === selectedId)
    if (!object) return false
    return object.get('applyFutureTemplateUpdates')
  })

const makeSelectFixtureSelectedObject = () =>
  createSelector(selectCanvas, canvas => {
    const { selectedId, objects } = getCanvasData(canvas)
    if (selectedId === -1 || !objects.count()) return null
    const object = objects.find(i => i.get('id') === selectedId)

    return object
  })

const makeSelectFixtureCustomAngle = () =>
  createSelector(selectCanvas, canvas => {
    const selectedId = canvas.get('selectedId')
    const objects = canvas.get('canvasObject').get('objects')

    if (selectedId === -1 || !objects.count()) {
      return ''
    }
    const obj = objects.find(i => i.get('id') === selectedId)
    if (!obj) return 0
    return obj.get('angle')
  })

const makeSelectTemplate = () =>
  createSelector(selectCanvas, canvas => canvas.get('selectedTemplate'))

const selectFloorName = fId =>
  createOrmSelector(orm, session => {
    try {
      if (!session.Floor.hasId(fId)) {
        return
      }
      return session.Floor.withId(fId).ref.name
    } catch (e) {
      return
    }
  })

const makeSelectFloorName = fId =>
  createSelector(dbStateSelector, ormState => selectFloorName(fId)(ormState))

const selectAreaName = aId =>
  createOrmSelector(orm, session => {
    try {
      if (!session.Area.hasId(aId)) {
        return
      }
      return session.Area.withId(aId).ref.name
    } catch (e) {
      return
    }
  })

const makeSelectAreaName = aId =>
  createSelector(dbStateSelector, ormState => selectAreaName(aId)(ormState))

export {
  makeSelectAreaExist,
  makeSelectArea,
  makeSelectCanvasObject,
  canvasSelectedIdSelector,
  makeSelectPositionsByArea,
  makeSelectAreaDimensions,
  failedSelector,
  loadingSelector,
  isStaticSelector,
  makeSelectFixtureTemplateFromApp,
  makeSelectFixtureTemplate,
  makeSelectFixtureName,
  makeSelectFixtureSviType,
  makeSelectFixtureSelectedObject,
  makeSelectFixtureSviSizeType,
  makeSelectFixtureIsFurniture,
  makeSelectFixtureCustomAngle,
  prototypesSelector,
  fixtureToRemoveRulesSelector,
  makeSelectFixturesWithLayoutExist,
  makeSelectFixtureNameShown,
  makeSelectFixtureApplyFutureTemplateUpdates,
  makeSelectSelectedTemplate,
  makeSelectTemplateFixtures,
  makeSelectPosition,
  makeSelectNotCompliantFixturesForArea,
  makeSelectFixturesWithRulesForArea,
  makeSelectTemplate,
  makeSelectAreasInStore,
  makeSelectFloorName,
  makeSelectAreaName,
}
