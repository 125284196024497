import { createSelector } from 'reselect'
import { createSelector as createOrmSelector } from 'redux-orm'
import {
  orm,
  floorCanvasById,
  sortedPositionsByState,
  fixtureComplianceStateData,
  ComplianceStatus,
} from 'mti-jsclient-shared'
import mtiJsclientShared from '../../utils/mtiJsclientShared'
import { filterIssues, getPositionItemData } from 'utils/mtiUtils'
import { toFabricSync } from './utils'
import { appSelector } from '../App/selectors'

const dbStateSelector = (state) => state.get('orm')

const selectIsFloorById = (fId) =>
  createOrmSelector(orm, (session) => {
    try {
      if (!session.Floor.hasId(fId)) {
        return false
      }
      const floor = session.Floor.withId(fId)
      if (floor.ref.areasCount === 0) {
        return true
      }
      const areasGeometryIds = session.Area.filter(
        ({ floorId }) => floorId.toString() === fId
      )
        .toRefArray()
        .map(({ layoutPositionId }) => layoutPositionId)
      const geometriesExist = areasGeometryIds.map((gId) =>
        session.LayoutPosition.hasId(gId)
      )
      const hasAllGeometries = geometriesExist.every(
        (exists) => exists === true
      )
      if (!hasAllGeometries) {
        return false
      }
      return true
    } catch (e) {
      console.error(e)
      return false
    }
  })

const makeSelectFloorExist = (fId) =>
  createSelector(dbStateSelector, (ormState) =>
    selectIsFloorById(fId)(ormState)
  )

const makeSelectAreasWithLayoutExist = (fId) =>
  createSelector(dbStateSelector, (ormState) =>
    createOrmSelector(orm, (session) => {
      try {
        const areasReadyToBeDrawn = session.Floor.withId(fId)
          .areaIds.toModelArray()
          .filter((i) => i.layoutPositionId)
        return !!areasReadyToBeDrawn.length
      } catch (e) {
        return false
      }
    })(ormState)
  )

const selectFloorById = (fId) =>
  createOrmSelector(orm, (session) => {
    try {
      const floor = floorCanvasById(session, fId)

      return floor
    } catch (e) {
      return {}
    }
  })

const makeSelectFloor = (fId) =>
  createSelector(dbStateSelector, (ormState) => selectFloorById(fId)(ormState))

export const getNotCompliantFixturesForArea = (areaModel) =>
  areaModel.fixtureIds
    .toModelArray()
    .map((fixtureModel) => {
      const complianceData = fixtureComplianceStateData(fixtureModel)
      // Return only Non Compliant Fixture
      if (
        !complianceData ||
        complianceData.complianceStateType === ComplianceStatus.COMPLIANT
      ) {
        return undefined
      }

      return { ...fixtureModel.ref, complianceData }
    })
    .filter((f) => f)

const makeSelectAreasWithFixtureComplianceIssuesByFloorId = (fId) =>
  createSelector(dbStateSelector, (ormState) =>
    createOrmSelector(orm, (session) => {
      try {
        const floor = session.Floor.withId(fId)

        return floor.areaIds
          .toModelArray()
          .map((areaModel) => {
            const fixtures = getNotCompliantFixturesForArea(areaModel)
            if (fixtures.length === 0) return undefined

            return {
              ...areaModel.ref,
              fixtures,
            }
          })
          .filter((n) => n)
      } catch (e) {
        return undefined
      }
    })(ormState)
  )

const selectPositionsByFloorId = (fId) =>
  createOrmSelector(orm, (session) => {
    try {
      // Issues for floor
      const floor = session.Floor.withId(fId)
      const { id: storeId, name: storeName } = session.Store.withId(
        floor.ref.storeId
      ).ref
      const floorPositions = floor.fixtureIds
        .toModelArray()
        .map((fixture) => fixture.positionIds.toModelArray())
      const floorFlatterdPositions = [].concat(...floorPositions)
      const floorSortedPositions = sortedPositionsByState(
        floorFlatterdPositions
      )
      const floorPositionsExtended = filterIssues(
        floorSortedPositions
          .map((p) => getPositionItemData(p, storeId, storeName))
          .filter((n) => n)
      )
      // Issues for area selected
      const positionsWithStatusesByAreaId = {}
      floor.areaIds.toModelArray().map((area) => {
        const areaPsitions = area.fixtureIds
          .toModelArray()
          .map((fixture) => fixture.positionIds.toModelArray())
        const areaPsitionsFlatterd = [].concat(...areaPsitions)
        const areaPsitionsSorted = sortedPositionsByState(areaPsitionsFlatterd)
        const areaPsitionsSortedExtended = filterIssues(
          areaPsitionsSorted
            .map((p) => getPositionItemData(p, storeId, storeName))
            .filter((n) => n)
        )
        positionsWithStatusesByAreaId[area.ref.id] = areaPsitionsSortedExtended
        return areaPsitionsSortedExtended
      })
      return {
        positionsFloor: floorPositionsExtended,
        positionsByAreaId: positionsWithStatusesByAreaId,
      }
    } catch (e) {
      return {}
    }
  })

const makeSelectPositionsByFloor = (fId) =>
  createSelector(dbStateSelector, (ormState) =>
    selectPositionsByFloorId(fId)(ormState)
  )

const makeSelectFloorName = () =>
  createSelector(selectCanvas, (canvasState) => canvasState.get('floor'))

const selectCanvas = (state) => state.get('floorCanvas')

const ormFloorStateSelector = (state) => ({
  orm: state.get('orm'),
  floor: state.get('floorCanvas'),
})

const makeSelectCanvasObject = (fId) =>
  createSelector(ormFloorStateSelector, appSelector, (ormFloor, appState) => {
    try {
      const isStatic = ormFloor.floor.get('isStatic')
      const prototypes = ormFloor.floor.get('prototypes')

      if (!isStatic || !prototypes) {
        const canvas = ormFloor.floor.get('canvasObject').toJS()
        return canvas
      }

      const floor = selectFloorById(fId)(ormFloor.orm)
      const images = appState.get('images')
      const screen = ormFloor.floor.get('screen')
      const { selectedId } = ormFloor.floor.get('canvasObject').toJS() || {}
      const { canvasObject } = toFabricSync(
        floor,
        images,
        screen,
        isStatic,
        false,
        selectedId || -1,
        prototypes,
        mtiJsclientShared
      )
      return canvasObject
    } catch (e) {
      return {}
    }
  })

const makeSelectObjects = () =>
  createSelector(selectCanvas, (canvasState) =>
    canvasState
      .get('canvasObject')
      .get('objects')
      .toJS()
  )

const makeSelectCanvasSelectedId = () =>
  createSelector(
    selectCanvas,
    (canvasState) => canvasState && canvasState.get('selectedId')
  )

const makeSelectCanvasSelectedObject = () =>
  createSelector(selectCanvas, (canvasState) =>
    canvasState
      .get('canvasObject')
      .get('objects')
      .findIndex((item) => item.get('id') === canvasState.get('selectedId'))
  )

const makeSelectPrototypesMobile = () =>
  createSelector(selectCanvas, (canvas) => canvas.get('prototypesMobile'))

const makeSelectPrototypes = () =>
  createSelector(selectCanvas, (canvas) => canvas.get('prototypes'))

const makeSelectFailed = () =>
  createSelector(selectCanvas, (canvas) => canvas.get('failed'))

const makeSelectLoading = () =>
  createSelector(selectCanvas, (canvas) => canvas.get('loading'))

const makeSelectIsStatic = () =>
  createSelector(selectCanvas, (canvas) =>
    (canvas || { get: () => true }).get('isStatic')
  )

const makeSelectedAreaName = () =>
  createSelector(selectCanvas, (canvas) => {
    const selectedId = canvas.get('selectedId')
    const objects = canvas.get('canvasObject').get('objects')

    if (selectedId === -1 || !objects.count()) {
      return ''
    }
    const obj = objects.find((i) => i.get('id') === selectedId)
    if (!obj) return ''
    return obj
      .get('objects')
      .find((i) => i.get('name') === 'label')
      .get('fullText')
  })

const makeSelectedAreaAngle = () =>
  createSelector(selectCanvas, (canvas) => {
    const selectedId = canvas.get('selectedId')
    const objects = canvas.get('canvasObject').get('objects')

    if (selectedId === -1 || !objects.count()) {
      return ''
    }
    const obj = objects.find((i) => i.get('id') === selectedId)
    if (!obj) return 0
    return obj.get('angle')
  })

const makeSelectAreaNameShownState = () =>
  createSelector(selectCanvas, (canvas) => {
    const selectedId = canvas.get('selectedId')
    const objects = canvas.get('canvasObject').get('objects')

    if (selectedId === -1 || !objects.count()) {
      return false
    }
    const obj = objects.find((i) => i.get('id') === selectedId)
    if (!obj) return false
    return obj.get('displayAreaName')
  })

const makeSelectAreas = () =>
  createSelector(selectCanvas, (canvas) => {
    const canvasObject = canvas.get('canvasObject')
    if (!canvasObject) {
      return []
    }
    const objects = canvasObject.get('objects')
    if (!objects || !objects.count()) {
      return []
    }
    return objects
      .map((o) => ({
        id: o.get('id'),
        name: o
          .get('objects')
          .find((i) => i.get('name') === 'label')
          .get('fullText'),
        fixturesCount: o.get('fixturesCount'),
      }))
      .toArray()
  })

export {
  makeSelectFloorExist,
  makeSelectFloor,
  makeSelectAreas,
  makeSelectFloorName,
  makeSelectPositionsByFloor,
  makeSelectCanvasObject,
  makeSelectCanvasSelectedId,
  makeSelectCanvasSelectedObject,
  makeSelectPrototypesMobile,
  makeSelectPrototypes,
  makeSelectObjects,
  makeSelectFailed,
  makeSelectLoading,
  makeSelectIsStatic,
  makeSelectedAreaName,
  makeSelectedAreaAngle,
  makeSelectAreaNameShownState,
  makeSelectAreasWithLayoutExist,
  makeSelectAreasWithFixtureComplianceIssuesByFloorId,
}
