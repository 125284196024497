import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import PropTypes from 'prop-types'
import AddNewProductScreen from './addNewProduct.screen'
import injectReducer from '../../../utils/injectReducer'
import reducer from './reducer'
import saga from './sagas'
import { loadManufacturerProducts } from './actions'
import injectSaga from '../../../utils/injectSaga'
import {
  makeSelectManufacturerProducts,
  makeSelectLoading,
  makeSelectFailed,
} from './selectors'

class AddNewProduct extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      name: props.name,
      productId: null,
      otherProductName: '',
      manufacturerId: null,
      productShouldCharge: false,
      otherManufacturerName: '',
    }
  }

  manufacturerProducts = () => {
    const { manufacturerProducts } = this.props
    return manufacturerProducts || []
  }

  onChange = (data) => {
    if (
      data.manufacturerId &&
      data.manufacturerId !== this.state.manufacturerId
    ) {
      this.props.loadManufacturerProducts(
        data.manufacturerId,
        this.props.storeId
      )
    }
    this.setState({ ...data })
  }

  onAddNewProduct = () => {
    const { onAddNewProduct } = this.props
    const {
      otherProductName,
      manufacturerId,
      productShouldCharge,
      otherManufacturerName,
    } = this.state

    onAddNewProduct({
      otherProductName,
      manufacturerId,
      productShouldCharge,
      otherManufacturerName,
    })
  }

  onAddAndAssignProduct = () => {
    const { onAddAndAssignNewProduct } = this.props
    let {
      productId,
      otherProductName,
      manufacturerId,
      otherManufacturerName,
      productShouldCharge,
    } = this.state

    if (productId) {
      otherProductName = null
    } else {
      productId = null
    }

    if (manufacturerId) {
      otherManufacturerName = null
    } else {
      manufacturerId = null
    }

    onAddAndAssignNewProduct({
      productId,
      otherProductName,
      manufacturerId,
      productShouldCharge,
      otherManufacturerName,
    })
  }

  render() {
    const { id, manufacturers, onCancel } = this.props

    const {
      name,
      otherProductName,
      otherManufacturerName,
      productShouldCharge,
    } = this.state
    return (
      <AddNewProductScreen
        id={id}
        name={name}
        manufacturers={manufacturers}
        products={this.manufacturerProducts()}
        otherProductName={otherProductName}
        otherManufacturerName={otherManufacturerName}
        productShouldCharge={productShouldCharge}
        onChange={this.onChange}
        onAddNewProduct={this.onAddNewProduct}
        onAddAndAssignNewProduct={this.onAddAndAssignProduct}
        onCancel={onCancel}
      />
    )
  }
}

AddNewProduct.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  manufacturers: PropTypes.array,
  onAddNewProduct: PropTypes.func,
  onAddAndAssignNewProduct: PropTypes.func,
  onCancel: PropTypes.func,
}

const mapStateToProps = createStructuredSelector({
  manufacturerProducts: makeSelectManufacturerProducts(),
  loading: makeSelectLoading(),
  failed: makeSelectFailed(),
})

const mapDispatchToProps = {
  loadManufacturerProducts,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)
const withStoreReducer = injectReducer({
  key: 'manufacturer-products',
  reducer,
})
const withStoreSaga = injectSaga({ key: 'manufacturer-products', saga })

export default compose(withStoreReducer, withStoreSaga, withConnect)(
  AddNewProduct
)
