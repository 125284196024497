import { ActionTypes } from '../constants'

/**
 * Store Images
 *
 * @returns {Object}
 */
export function storeImages(images) {
  return {
    type: ActionTypes.STORE_IMAGES,
    payload: images,
  }
}

/**
 * Security Device Identify
 *
 * @returns {Object}
 */
export function securityDeviceIdentify(payload) {
  return {
    type: ActionTypes.SECURITY_DEVICE_IDENTIFY,
    payload,
  }
}

/**
 * Security Device Reset Identify
 *
 * @returns {Object}
 */
export function securityDeviceResetIdentify(payload) {
  return {
    type: ActionTypes.SECURITY_DEVICE_RESET_IDENTIFY,
    payload,
  }
}

/**
 * Create Default Floor And Area
 *
 * @returns {Object}
 */
export function createDefaultFloorAndArea(storeId) {
  return {
    type: ActionTypes.STORE_CREATE_DEFAULT_FLOOR_AND_AREA,
    payload: storeId,
  }
}

/**
 * Load Data For Organization
 *
 * @returns {Object}
 */
export function loadDataForOrganization() {
  return {
    type: ActionTypes.LOAD_DATA_FOR_ORGANIZATION,
  }
}

/**
 * Reset App
 *
 * @returns {Object}
 */
export function resetApp() {
  return {
    type: ActionTypes.APP_RESET,
  }
}
