/*
 *
 * TemplateFixture constants
 *
 */

import keyMirrow from 'fbjs/lib/keyMirror'

/**
 * @constant {Object} ActionTypes
 * @memberof Constants
 */
export const ActionTypes = keyMirrow({
  TEMPLATE_FIXTURE_CLEAR_CACHED_DATA: undefined,
  PREPARE_TEMPLATE_FIXTURE_CANVAS: undefined,
  FETCH_TEMPLATE_FIXTURE_CANVAS_FULFILLED: undefined,
  SAVE_TEMPLATE_FIXTURE_CANVAS: undefined,
  TEMPLATE_FIXTURE_SAVED: undefined,
  TEMPLATE_FIXTURE_CHANGED: undefined,
  TEMPLATE_FIXTURE_NAME_CHANGED: undefined,
  STORE_TEMPLATE_FIXTURE_PROTOTYPES: undefined,
  STORE_PARAMS: undefined,
  STORE_TEMPLATE_FIXTURE_LOADING: undefined,
  TEMPLATE_FIXTURE_CANVAS_ERROR: undefined,
  TEMPLATE_FIXTURE_UPDATE_INHERITANCE: undefined,
  TEMPLATE_FIXTURE_UPDATE_CC: undefined,
  TEMPLATE_FIXTURE_FIGURE_BEGIN_TRANSFORMATION: undefined,
  TEMPLATE_FIXTURE_FIGURE_BEGIN_ROTATION: undefined,
  TEMPLATE_FIXTURE_IS_FURNITURE_CHANGED: undefined,
  TEMPLATE_FIXTURE_FIXTURE_NAME_TOGGLED: undefined,
  TEMPLATE_FIXTURE_NAME_TOGGLED: undefined,
  TEMPLATE_FIXTURE_BEGIN_TRANSFORMATION: undefined,
  TEMPLATE_FIXTURE_SIZE_CHANGED: undefined,

  CREATE_FIXTURE_RULE: undefined,
  EDIT_FIXTURE_RULE: undefined,
  DELETE_FIXTURE_RULE: undefined,
  DELETE_ALL_FIXTURE_RULES: undefined,
})
