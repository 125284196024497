import React from 'react'
import PropTypes from 'prop-types'

/*
 * Just renders a Bootstrap .card component.
 *
 * Example use:
 * <Card title="Separate Things" subtitle="these things are different somehow">
 *   <span className="card-text">Stuff Goes Here</span>
 * </Card>
 *
 * Params
 *   title: (Optional) The title to show at the top.
 *   subtitle: (Optional) More title to show at the top.
 *   width: (Optional) the width of the component. Defaults to 100%
 *
 * See https://getbootstrap.com/docs/4.4/components/card/ for details.
 */

class Card extends React.PureComponent {
  render() {
    return (
      <div
        className={`card ${this.props.className}`}
        style={{ width: this.props.width || '100%' }}
      >
        <div className="card-body">
          {this.props.title && (
            <h5 className="card-title">{this.props.title}</h5>
          )}
          {this.props.subtitle && (
            <h6 className="card-title">{this.props.title}</h6>
          )}
          {this.props.children}
        </div>
      </div>
    )
  }
}

Card.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  width: PropTypes.string,
  className: PropTypes.string,
}

export default Card
