import { all, call, put, takeLatest, select } from 'redux-saga/effects'
import { modelActionCreators as mac } from 'mti-jsclient-shared'
import { postFloor, patchFloor, deleteFloor, patchFloorImage } from '../../api'
import { fetchAndUpsertStore, createDefaultFloorAndArea } from '../App/actions'
import { makeSelectToken, makeSelectImages } from '../App/selectors'
import { fetchAndUpsert, upsert } from '../App/sagas/ormSaga'
import { makeSelectStoreExist, makeSelectStore } from './selectors'
import { ActionTypes } from './constants'
import {
  postStoreCanvasPending,
  postStoreCanvasFinished,
  postStoreCanvasFailed,
  fetchFloorsPending,
  fetchFloorsFulfilled,
  fetchFloorsFailed,
} from './actions'
import { toObjectsSync } from './utils'
import { errorToast, successToast } from '../../utils/utils'

export function* loadFloors({ payload }) {
  const { isStatic, sObject, selectedId, sId } = payload

  try {
    // const prototypes = yield getPrototypes()
    // yield put(storePrototypes(prototypes))
    // yield put(storeParams({ screen, isStatic }))
    const isStoreExist = yield select(makeSelectStoreExist(sId))
    if (!isStoreExist) {
      yield put(fetchFloorsPending(sId))
      yield call(fetchAndUpsert, { payload: { sId } })
    }

    const store = yield select(makeSelectStore(sId))
    const images = yield select(makeSelectImages())
    yield put(
      fetchFloorsFulfilled(
        toObjectsSync(store, images, isStatic, selectedId),
        { id: store.id, name: store.name },
        sObject,
        isStatic
      )
    )

    if (isStoreExist && isStatic) {
      yield call(fetchAndUpsert, { payload: { sId } })
    }
    //
    yield put(createDefaultFloorAndArea(sId))
  } catch (error) {
    console.log(error)
    const res = error.response
    const errorObj = res ? yield call([res, res.json]) : error
    yield put(fetchFloorsFailed(errorObj))
    errorToast('Load floors failed')
  }
}

export function* saveFloor({ payload }) {
  const { canvasObject: { objects = [] }, sId, selectedObject } = payload
  const token = yield select(makeSelectToken())
  try {
    yield put(postStoreCanvasPending(sId))
    const floor = objects[selectedObject]
    let data
    if (isNaN(floor.id)) {
      data = yield call(postFloor, token, floor, sId)
      floor.id = data.floors[0].id
      successToast('Floor Created')
    } else {
      data = yield call(patchFloor, token, floor, sId)
      successToast('Floor Updated')
    }
    yield call(upsert, { payload: { ...data, stores: undefined } }) // undefine store, cause it doesn't have all the data

    yield put(postStoreCanvasFinished({ sId, fId: floor.id }))
    if (floor.image && floor.image.size) {
      yield call(imageUploadFloor, { payload })
    }
  } catch (error) {
    console.log(error)
    const res = error.response
    const errorObj = res ? yield call([res, res.json]) : error
    yield put(postStoreCanvasFailed(errorObj))
    errorToast('Save floor failed')
  }
}

export function* imageUploadFloor({ payload }) {
  const { canvasObject: { objects = [] }, sId, selectedObject } = payload
  const token = yield select(makeSelectToken())
  try {
    yield put(postStoreCanvasPending(sId))
    const floor = objects[selectedObject]
    let data
    if (isNaN(floor.id)) {
      console.warn('patching is only allowed at the moment')
    } else {
      data = yield call(patchFloorImage, token, floor, sId)
    }

    yield call(upsert, { payload: data })

    yield put(postStoreCanvasFinished({ sId, fId: floor.id }))
  } catch (error) {
    console.log(error)
    const res = error.response
    const errorObj = res ? yield call([res, res.json]) : error
    yield put(postStoreCanvasFailed(errorObj))
    errorToast('Upload floor image failed')
  }
}

export function* removeFloor({ payload }) {
  const { canvasObject: { objects = [] }, sId, selectedObject } = payload
  const floor = objects[selectedObject]
  const token = yield select(makeSelectToken())
  try {
    if (!isNaN(floor.id)) {
      yield put(postStoreCanvasPending(sId))
      const data = yield call(deleteFloor, token, floor)
      successToast('Floor Deleted')
      yield put(mac.deleteFloorWithId(floor.id))
      yield call(upsert, { payload: data })
      yield put(postStoreCanvasFinished(sId, data))
      yield put(fetchAndUpsertStore(sId, true)) // TODO: remove after api will return full store object
    }
  } catch (error) {
    console.log(error)
    const res = error.response
    const errorObj = res ? yield call([res, res.json]) : error
    yield put(postStoreCanvasFailed(errorObj))
    errorToast('Delete floor failed')
  }
}

/**
 * Floors Sagas
 */
export default function* root() {
  yield all([
    yield takeLatest(ActionTypes.SAVE_FLOORS_FLOOR, saveFloor),
    yield takeLatest(ActionTypes.FLOORS_CANVAS_FLOOR_REMOVE, removeFloor),
    yield takeLatest(
      ActionTypes.FLOORS_CANVAS_FLOOR_IMAGE_UPLOAD,
      imageUploadFloor
    ),
  ])
}
