import { fabric } from 'fabric'
import { alertTypes } from '../../utils/mtiCanvasUtils'
import circleAlarmHC from 'images/svg/positions/Circle-Alarm-and-health-and-compliance.svg'
import circleUnlockedHC from 'images/svg/positions/Circle-Unlocked-Health-and-compliance.svg'
import circleHC from 'images/svg/positions/Circle-Health-and-compliance.svg'
// import patternAlert from 'images/pattern-alert.png'
// import patternWarning from 'images/pattern-warning.png'

import { iconScale, textSize } from '../../utils/screen'

export async function getHCObject(type, mtiJsclientShared = {}) {
  const { ArmState = {} } = mtiJsclientShared
  switch (type) {
    case alertTypes.alert:
    case ArmState.ALARMING:
      return getCircleAlarmHC()
    case alertTypes.lowAlert:
    case ArmState.DISARMED:
    case ArmState.UNLOCKED:
      return getCircleDisarmedUnlockedHC()
    case alertTypes.warning:
      return getCircleWarning()
    default:
      return getCircleAlarmHC()
  }
}

export function getHCObjectSync(type, prototypes, mtiJsclientShared = {}) {
  const { ArmState = {} } = mtiJsclientShared
  switch (type) {
    case alertTypes.alert:
    case ArmState.ALARMING:
      return prototypes[alertTypes.alert]
    case alertTypes.lowAlert:
    case ArmState.DISARMED:
    case ArmState.UNLOCKED:
      return prototypes[alertTypes.lowAlert]
    case alertTypes.warning:
      return prototypes[alertTypes.warning]
    default:
      return prototypes[alertTypes.warning]
  }
}

export function getObjectSync(type, number, prototypes, mtiJsclientShared) {
  const hcObj = getHCObjectSync(type, prototypes, mtiJsclientShared)
  return createObject(type, number, hcObj)
}

export async function getObject(type, number, mtiJsclientShared) {
  const hcObj = await getHCObject(type, mtiJsclientShared)
  return createObject(type, number, hcObj)
}

export function createObject(type, number, hcObj) {
  let width = hcObj.get('width')
  let height = hcObj.get('height')
  hcObj.set({ scaleX: iconScale, scaleY: iconScale })
  width *= iconScale
  height *= iconScale
  // Prevents status icon twitching
  hcObj.set({ top: -height / 2, left: -width / 2 })
  return new fabric.Group(
    [
      hcObj,
      new fabric.TruncatedText(number, {
        truncatedTextLength: 10,
        name: 'label',
        fontSize: textSize,
        fill: '#ffffff',
        fontFamily: 'Arial',
        originX: 'center',
        originY: 'center',
        left: 0,
        top: -(height / 4),
        fontWeight: 200,
      }),
    ],
    {
      left: -1 * width / 2,
      top: -1 * height / 2,
    }
  )
}

export function getCircleAlarmHC() {
  return new Promise((resolve) => {
    fabric.loadSVGFromURL(circleAlarmHC, (objects, options) => {
      const loadedObjects = fabric.util.groupSVGElements(objects, options)
      resolve(loadedObjects)
    })
  })
}

export function getCircleDisarmedUnlockedHC() {
  return new Promise((resolve) => {
    fabric.loadSVGFromURL(circleUnlockedHC, (objects, options) => {
      const loadedObjects = fabric.util.groupSVGElements(objects, options)
      resolve(loadedObjects)
    })
  })
}

export function getCircleWarning() {
  return new Promise((resolve) => {
    fabric.loadSVGFromURL(circleHC, (objects, options) => {
      const loadedObjects = fabric.util.groupSVGElements(objects, options)
      resolve(loadedObjects)
    })
  })
}

// export async function getPattern(type) {
//   switch (type) {
//     case 'alert':
//       return getPatternObject(patternAlert)
//     case 'warning':
//       return getPatternObject(patternWarning)
//     default:
//       return getPatternObject(patternAlert)
//   }
// }
//
// function getPatternObject(resource) {
//   return new Promise((resolve) => {
//     fabric.util.loadImage(resource, (img) => {
//       resolve(img)
//     })
//   })
// }
