/**
 *
 * Table Preloader
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import ContentLoader from 'react-content-loader'
import uuid from 'uuid'

function TablePreloader({ columnsCount, loaderWidth, heightLoader, isModal }) {
  const Loader = (props) => {
    const random = Math.random() * (1 - 0.7) + 0.7
    const columnWidth = loaderWidth / columnsCount - 5
    const columnX = columnWidth + 4
    return (
      <ContentLoader
        height={37}
        width={loaderWidth}
        speed={2}
        primaryColor="#d9d9d9"
        secondaryColor="#ecebeb"
        {...props}
      >
        <React.Fragment>
          {Array(columnsCount)
            .fill('')
            .map((e, i) => (
              <rect
                key={`loader_${uuid.v4()}`}
                x={5 + columnX * i}
                y="10"
                rx="6"
                ry="6"
                width={columnWidth * random}
                height="14"
              />
            ))}
        </React.Fragment>
        <rect x="0" y="34" rx="6" ry="6" width={loaderWidth} height=".3" />
      </ContentLoader>
    )
  }

  const TableLoader = () => {
    const height = heightLoader ? heightLoader : window.innerHeight
    const heightHeader = isModal ? 140 : 80
    const countRows = heightLoader
      ? parseInt((height - heightHeader) / 36)
      : parseInt(height / 50)
    return (
      <div>
        <React.Fragment>
          {Array(2)
            .fill('')
            .map(() => (
              <Loader
                key={`loader_${uuid.v4()}`}
                primaryColor="#d6d4d7"
                secondaryColor="#c4c2c5"
              />
            ))}
        </React.Fragment>
        <React.Fragment>
          {Array(countRows)
            .fill('')
            .map((e, i) => (
              <Loader
                key={`loader_${uuid.v4()}`}
                style={{ opacity: Number(2 / i).toFixed(1) }}
              />
            ))}
        </React.Fragment>
      </div>
    )
  }

  return <TableLoader />
}

TablePreloader.propTypes = {
  columnsCount: PropTypes.number,
  loaderWidth: PropTypes.number,
}

export default TablePreloader
