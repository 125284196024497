import { all, call, put, takeLatest, select } from 'redux-saga/effects'
import { ActionTypes } from './constants'
import {
  updateLodePending,
  updateLodeFulfilled,
  updateLodeFailed,
} from './actions'
import { runSaltCommandWithArg } from '../../../api'
import { makeSelectSaltToken } from '../../App/selectors'

function* updateLode({ payload }) {
  const { hub: { identifier } } = payload
  const saltToken = yield select(makeSelectSaltToken())
  try {
    yield put(updateLodePending())
    const commandArg = payload.version
      ? `curl https://s3.amazonaws.com/connected-store-lode-staging-builds/update-lode.sh | sudo BUILD=${
        payload.version
      } bash`
      : 'curl https://s3.amazonaws.com/connected-store-lode-staging-builds/update-lode.sh | sudo bash'

    const { return: [data] } = yield call(
      runSaltCommandWithArg,
      saltToken,
      identifier,
      commandArg
    )
    yield put(updateLodeFulfilled(Object.values(data)[0]))
  } catch (error) {
    yield put(updateLodeFailed(error))
  }
}

export default function* root() {
  yield all([yield takeLatest(ActionTypes.UPDATE_LODE, updateLode)])
}
