import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import injectReducer from '../../utils/injectReducer'
import injectSaga from '../../utils/injectSaga'
import forgotPasswordReducer from './reducer'
import forgotPassowrdSaga from './sagas'
import ForgotPasswordScreen from './index.screen'
import { createStructuredSelector } from 'reselect'
import { makeSelectLoading, makeSelectFailed } from './selectors'
import { editEmail, postForgotPassword, clearEmail } from './actions'

class ForgotPassword extends React.Component {
  componentDidUpdate() {
    const { failed } = this.props
    if (failed) {
      this.forgotPasswordScreen.setServerValidity(failed)
    }
  }

  componentWillUnmount() {
    this.props.clearEmail()
  }

  render() {
    const {
      history,
      loading,
      editEmail: doEditEmail,
      postForgotPassword: doPostForgotPassword,
    } = this.props

    return (
      <ForgotPasswordScreen
        ref={(ref) => (this.forgotPasswordScreen = ref)}
        onChange={doEditEmail}
        onConfirm={() => doPostForgotPassword(history)}
        onCancel={() => history.push('/')}
        isLoading={loading}
      />
    )
  }
}

ForgotPassword.propTypes = {
  history: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  failed: PropTypes.object,
  editEmail: PropTypes.func.isRequired,
  postForgotPassword: PropTypes.func.isRequired,
  clearEmail: PropTypes.func.isRequired,
}

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
  failed: makeSelectFailed(),
})

const mapDispatchToProps = {
  editEmail,
  clearEmail,
  postForgotPassword,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const withReducer = injectReducer({
  key: 'forgotPassword',
  reducer: forgotPasswordReducer,
})

const withSaga = injectSaga({
  key: 'forgotPassword',
  saga: forgotPassowrdSaga,
})

export default compose(withReducer, withSaga, withConnect)(ForgotPassword)
