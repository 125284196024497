import { namespace } from './constants'

// The reason to store an empty array as a const is to reduce the number of page re-renders.
// Whenever a selector returns a different _instance_ of an object, it tells redux to
// re-render the component, even if that new object is equivalent.
const defaultArray = []
const defaultPageData = {
  meta: {
    total: 0,
    pages: 1,
    page: 1,
    per: 100,
  },
  rows: [],
}

export function selectIsTableViewLoading(state) {
  return state.get(namespace).get('layoutTableViewIsLoading') || false
}

export function selectLayoutTableViewData(state) {
  return state.get(namespace).get('layoutTableViewData') || defaultPageData
}

export function selectAreasFilterList(state) {
  const list = state.get(namespace).get('areasFilterList')
  return Array.isArray(list) ? list : defaultArray
}

export function selectFixturesFilterList(state) {
  const list = state.get(namespace).get('fixturesFilterList')
  return Array.isArray(list) ? list : defaultArray
}
