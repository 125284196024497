import { createSelector } from 'reselect'

const selectHubsReducer = (state) => state.get('hubsList')

export const makeSelectMinions = () =>
  createSelector(selectHubsReducer, (state) => state.get('minions'))

export const makeSelectLoading = () =>
  createSelector(selectHubsReducer, (state) => state.get('loading'))

export const makeSelectFailed = () =>
  createSelector(selectHubsReducer, (state) => state.get('failed'))
