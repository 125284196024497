import React from 'react'
import PropTypes from 'prop-types'
import FixtureRulesScreen from './FixtureRules.screen'
import {
  SOURCE_ATTRIBUTES,
  FixtureRuleSourceAttributesModel,
} from './ruleConstants'

class FixtureRules extends React.PureComponent {
  constructor(props) {
    super(props)
    const { rule, products } = props

    let selectedProducts = []
    let numberOfPositions = '0'
    if (
      rule &&
      rule.parameter &&
      rule.sourceAttribute === SOURCE_ATTRIBUTES.positions
    ) {
      numberOfPositions = String(rule.parameter)
    } else if (
      rule &&
      rule.parameter &&
      (rule.sourceAttribute === SOURCE_ATTRIBUTES.product_ids ||
        rule.sourceAttribute === SOURCE_ATTRIBUTES.live_product_ids)
    ) {
      selectedProducts = rule.parameter.map((productId) =>
        products.find(({ id }) => id === productId)
      )
    }

    this.state = {
      ruleType: rule ? rule.sourceAttribute : null,
      condition: rule ? rule.condition : null,
      param: null,
      selectedProducts,
      numberOfPositions,
    }
  }

  /* eslint-disable no-undef */
  onChange = (data) => {
    this.setState({ ...data })
  }

  onAddSelectedProducts = (products) => {
    const { selectedProducts } = this.state
    const productsToAdd = [...new Set([...selectedProducts, ...products])]
    this.setState({
      selectedProducts: productsToAdd,
    })
  }

  onRemoveSelectedProducts = (products) => {
    this.setState({ selectedProducts: products })
  }

  onConfirm = () => {
    const { onConfirm } = this.props
    const { ruleType, condition } = this.state
    const { paramType } = FixtureRuleSourceAttributesModel[ruleType]
    const data = this.getComplianceData()
    const rule = {
      condition,
      sourceAttribute: ruleType,
      parameterType: paramType,
      parameter: data,
    }

    onConfirm(rule)
  }

  getComplianceData = () => {
    const { ruleType, selectedProducts, numberOfPositions } = this.state
    if (ruleType === SOURCE_ATTRIBUTES.positions) {
      return Number(numberOfPositions)
    }

    return selectedProducts.map(({ id }) => id)
  }

  render() {
    const { id, products, onClose, onDelete } = this.props
    const {
      ruleType,
      condition,
      param,
      selectedProducts,
      numberOfPositions,
    } = this.state

    return (
      <FixtureRulesScreen
        id={id}
        ruleType={ruleType}
        condition={condition}
        products={products}
        selectedProducts={selectedProducts}
        numberOfPositions={numberOfPositions}
        param={param}
        onConfirm={this.onConfirm}
        onClose={onClose}
        onChange={this.onChange}
        onAddSelectedProducts={this.onAddSelectedProducts}
        onRemoveSelectedProducts={this.onRemoveSelectedProducts}
        onDelete={onDelete}
      />
    )
  }
}

FixtureRules.propTypes = {
  id: PropTypes.string.isRequired,
  products: PropTypes.array.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  rule: PropTypes.object,
  onDelete: PropTypes.func,
}

export default FixtureRules
