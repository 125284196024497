import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import styles from '../../styles/App.css'
import {
  getTableHeight,
  PageHeaderHeight,
  safeRegexStr,
} from '../../utils/utils'
import { userRoleComponent } from './utils'
import TablePreloader from '../../components/Preloaders/TablePreloader'
import ProgressIndicator from '../../components/ProgressIndicator'
import ServerManagedTable from '../../components/ServerManagedTable'
import { CellContainerFixed } from '../../styles/styled'
import TooltipCell from '../../components/TooltipCell'
import { injectIntl } from 'react-intl'

import Checkbox from '../../components/Switch'
const StyledCheckbox = styled(Checkbox)`
  top: 6px;
`
const Label = styled.span`
  color: ${({ disabled }) => (disabled ? '#666;' : '#4E92DF;')};
  font-size: 14px;
  line-height: 17px;
  height: 17px;
  vertical-align: text-top;
`

export const CellContainer = styled.div`
  overflow: hidden;
`

// TODO: consider moving to components
export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0px 0px 0px 0px;
  border-bottom-width: 1px;
  height: ${PageHeaderHeight}px;
`

export const Header = styled.h5`
  flex: 1;
  text-align: left;
  margin-top: 0px;
  margin-block-end: 0px;
  margin-left: 12px;
`

const FilterInput = styled.input`
  height: 100%;
  width: 100%;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const Row = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;

  & span {
    line-height: 100%;
  }

  & span::after {
    content: ', ';
    white-space: pre;
  }

  & span:last-of-type::after {
    content: '';
  }
`

export const TextTop = styled.span`
  font-size: 14px;
  text-align: left;
`

export const renderFilterInput = ({
  placeholder,
  filter,
  onChange,
  id,
} = {}) => (
  <FilterInput
    type="text"
    placeholder={placeholder}
    onChange={event => onChange(event.target.value)}
    id={`filter_${id}`}
  />
)

export const renderHeaderCell = (text, numericalSort) => (
  <span className="header-cell">
    {text}
    <i
      className={`sort-icon ${numericalSort ? 'sort-numerical' : 'sort-alphabetical'}`}
    />
  </span>
)

export const renderMultiLineCell = names => (
  <CellContainerFixed>
    <Row>
      {(names || []).map(name => <TextTop key={name}>{name}</TextTop>)}
    </Row>
  </CellContainerFixed>
)

export class UsersPageScreen extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    window.setTimeout(() => this.handleResize(), 10)
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  /* eslint-disable no-undef */
  handleResize = () => this.setState({ tableHeight: getTableHeight() })

  transformFilters = (id, value) => {
    switch (id) {
      case 'email':
        return { 'emailLike': value }
      case 'roleKey':
        return { 'role': value }
      default:
        return { [id]: value }
    }
  }

  transformSorts = key => (
    {
      roleKey: 'role_key',
    }[key] || key
  )

  columns() {
    const {
      onUser,
      changeUserStatus,
      intl,
    } = this.props

    return [
      {
        sortable: true,
        filterable: true,
        Header: renderHeaderCell('Name'),
        accessor: 'name',
        Cell: ({ original }) => (
          <CellContainer>
            <Link
              className={styles.link}
              to={'#'}
              onClick={() => onUser(original)}
              name={original.name}
            >
              {original.name}
            </Link>
          </CellContainer>
        ),
        Filter: ({ filter, onChange }) =>
          renderFilterInput({
            placeholder: 'Filter',
            filter,
            onChange,
            id: 'name',
          }),
      },
      {
        id: 'roleKey',
        sortable: true,
        filterable: true,
        Header: renderHeaderCell('Role', true),
        accessor: 'roleKey',
        Filter: ({ filter, onChange }) => userRoleComponent(filter, onChange),
        Cell: ({ original }) => (
          <CellContainer>{original.roleName}</CellContainer>
        ),
      },
      {
        id: 'resourceName',
        sortable: false,
        filterable: true,
        Header: renderHeaderCell('Location'),
        accessor: ({ resourceNames }) => (resourceNames || []).join(' '),
        Cell: ({ original }) => (
          <TooltipCell
            text={original.roleResources.map(rr => rr.name).join(', ')}
            render={tooltip => (
              <CellContainer ref={ref => tooltip.target(ref)}>
                {renderMultiLineCell(
                  original.roleResources.map(res => res.name)
                )}
              </CellContainer>
            )}
          />
        ),
        Filter: ({ filter, onChange }) =>
          renderFilterInput({
            placeholder: 'Filter',
            filter,
            onChange,
            id: 'stores',
          }),
      },
      {
        sortable: true,
        filterable: true,
        Header: renderHeaderCell('Email'),
        accessor: 'email',
        id: 'email',
        Cell: ({ original: { email } }) => (
          <CellContainer>
            <a
              className={styles.link}
              href={`mailto:${email}`}
              data-rel="external"
            >
              {email}
            </a>
          </CellContainer>
        ),
        Filter: ({ filter, onChange }) =>
          renderFilterInput({
            placeholder: 'Filter',
            filter,
            onChange,
            id: 'email',
          }),
      },
      {
        sortable: true,
        filterable: true,
        Header: renderHeaderCell('Phone Number', true),
        accessor: 'phoneNumber',
        Cell: ({ original: { phoneNumber } }) => (
          <CellContainer>{phoneNumber}</CellContainer>
        ),
        filterMethod: (filter, row) => {
          const id = filter.pivotId || filter.id
          const safeStr = safeRegexStr(filter.value)
          const reg = new RegExp(safeStr, 'i')
          const testString = (row[id] || ' ').replace(/ /g, '')
          return reg.test(testString)
        },
        Filter: ({ filter, onChange }) =>
          renderFilterInput({
            placeholder: 'Filter',
            filter,
            onChange,
            id: 'phoneNumber',
          }),
      },
      {
        sortable: true,
        filterable: false,
        Header: renderHeaderCell('Last signed in at', true),
        accessor: 'lastSignedInAt',
        Cell: ({ original: { lastSignedInAt } }) => (
          <span>
            {lastSignedInAt &&
              moment(lastSignedInAt)
                .utcOffset(0)
                .format('MM/DD/YYYY h:mm')}
          </span>
        ),
        Filter: ({ filter, onChange }) =>
          renderFilterInput({
            placeholder: 'Filter',
            filter,
            onChange,
            id: 'lastSignedInAt',
          }),
      },
      {
        sortable: false,
        filterable: false,
        Header: renderHeaderCell(intl.formatMessage({ id: 'status' })),
        accessor: 'status',
        Cell: ({ original }) => {
          const isActiveUser = original.status != 'inactive'
          return (
            <CellContainer style={{ textAlign: 'left' }}>
              <div>{intl.formatMessage({ id: `user.status.${original.status}` })}</div>
              <Link
                className={styles.smallLink}
                to={'#'}
                onClick={() => changeUserStatus(original, isActiveUser ? 'inactive' : 'active')}
                name={original.status}
              >
                {intl.formatMessage({ id: `user.${isActiveUser ? 'deactivate' : 'activate'}` })}
              </Link>
            </CellContainer>
          )
        },
      },
    ]
  }
  render() {
    const {
      users,
      meta,
      isAddUserLocked,
      onCreateUser,
      loading,
      onShowInactiveUsersToggled,
      showInactiveUsers = false,
      onFetchData,
      intl,
    } = this.props
    const width = window.innerWidth
    const { tableHeight } = this.state
    return (
      <div>
        <HeaderContainer className="border-bottom">
          <Header>Users</Header>
          <div style={{ display: 'flex' }}>
            <div>
              <Label className="mx-2">
                {intl.formatMessage({ id: 'containers.usersPage.showInactiveUsers' })}
              </Label>
              <StyledCheckbox
                id="show-inactive-users-switch"
                onChange={checked => onShowInactiveUsersToggled(checked)}
                checked={showInactiveUsers}
              />
            </div>

            {!isAddUserLocked && (
              <button
                name="createUser"
                className="btn btn-primary"
                onClick={onCreateUser}
              >
                {intl.formatMessage({ id: 'containers.usersPage.createUser' })}
              </button>
            )}
          </div>
        </HeaderContainer>
        <div style={{ position: 'relative' }}>
          <ServerManagedTable
            className="full-screen"
            style={{ height: tableHeight || 'auto' }}
            data={users || []}
            meta={meta}
            columns={this.columns()}
            loadData={onFetchData}
            transformFilters={this.transformFilters}
            transformSorts={this.transformSorts}
          />
          {loading && (
            <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, zIndex: 200, backgroundColor: 'white' }}>
              <TablePreloader columnsCount={6} loaderWidth={width} />
              <ProgressIndicator text={'Loading...'} />
            </div>
          )}
        </div>
      </div >
    )
  }
}

UsersPageScreen.propTypes = {
  users: PropTypes.array,
  meta: PropTypes.object,
  isAddUserLocked: PropTypes.bool,
  onCreateUser: PropTypes.func,
  onUser: PropTypes.func,
  changeUserStatus: PropTypes.func,
  showInactiveUsers: PropTypes.bool,
  onShowInactiveUsersToggled: PropTypes.func,
  onFetchData: PropTypes.func,
}

export default injectIntl(UsersPageScreen)
