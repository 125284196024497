import { ActionTypes } from './constants'

export function updateStores(stores) {
  return {
    type: ActionTypes.UPDATE_SELECTED_STORES,
    payload: stores,
  }
}

export function updateDates(dates) {
  return {
    type: ActionTypes.UPDATE_DATES,
    payload: { ...dates },
  }
}

/**
 * Update Combination
 *
 * @returns {Object}
 */
export function updateCombination(payload) {
  return {
    type: ActionTypes.UPDATE_REPORT_COMBINATION,
    payload,
  }
}

/**
 * Reset Filters
 *
 * @returns {Object}
 */
export function resetStoreToDefaults() {
  return {
    type: ActionTypes.RESET_STORE_TO_DEFAULTS,
  }
}
