import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { createStructuredSelector } from 'reselect'
import { makeSelectStoreIsInDefultFloorMode } from '../../../containers/App/selectors'
import { orgUrl } from '../../../utils/mtiUtils'

class AddFloorsToStore extends React.PureComponent {
  render() {
    const {
      params: { sId, sName, fId, fName, aId, fxId },
      isDefaultFloorMode,
    } = this.props
    const isShown = isDefaultFloorMode && sId && fId && !aId && !fxId
    return isShown ? (
      <div key="breadcrumb-control" className="breadcrumb-control  ml-5">
        <Link
          to={`${orgUrl()}/stores/${sId}/${sName}/floors?floorId=${fId}&floorName=${fName}&isStatic=false`}
        >
          ADD FLOORS TO STORE
        </Link>
      </div>
    ) : null
  }
}

AddFloorsToStore.propTypes = {
  params: PropTypes.object,
  isDefaultFloorMode: PropTypes.bool,
}

const mapStateToProps = createStructuredSelector({
  isDefaultFloorMode: (state, ownProps) =>
    makeSelectStoreIsInDefultFloorMode(ownProps.params.sId)(state, ownProps),
})

export default connect(mapStateToProps)(AddFloorsToStore)
