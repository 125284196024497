/**
 *
 * Common Disabled Edit Text enderlined with header
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { dottedBackground } from '../../styles/styled'

export const Column = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
`

export const BorderLabel = styled.label`
  width: 100%;
  min-height: 26px;
  display: inline-block;
  color: #000;
  margin: 5px 0 !important;
  line-height: 26px !important;
  text-decoration: none;
  &::before {
    content: '\00a0';
  }
  ${dottedBackground};
`

export const BorderLabelGrey = styled.label`
  width: 100%;
  min-height: 26px;
  display: inline-block;
  color: #aeaeae;
  margin: 5px 0 !important;
  line-height: 26px !important;
  text-decoration: none;
  &::before {
    content: '\00a0';
  }
  ${dottedBackground};
`

function EditDisabled({ header, text, grey, labelWidth }) {
  const greyLabel = () => {
    if (labelWidth) {
      const Label = styled(BorderLabelGrey)`
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: ${(props) => `${props.labelWidth}px`};
      `

      return (
        <Label labelWidth={labelWidth} id={text}>
          {text}
        </Label>
      )
    }

    return <BorderLabelGrey id={text}>{text}</BorderLabelGrey>
  }

  return (
    <Column>
      {header && <label htmlFor={header}>{header}</label>}
      {grey ? (
        greyLabel()
      ) : (
        <BorderLabel name={`${header}_value`} id={text}>
          {text}
        </BorderLabel>
      )}
    </Column>
  )
}

EditDisabled.propTypes = {
  header: PropTypes.string,
  text: PropTypes.string.isRequired,
  grey: PropTypes.bool,
  labelWidth: PropTypes.number,
}

export default EditDisabled
