/**
 *
 * FixturePage
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import {
  DeviceType,
  positionAction,
  SecuredProductCategory,
} from 'mti-jsclient-shared'
import { postSecurityDeviceAction } from '../App/actions/securityDevice'
import {
  getQueryVariable,
  loadingType,
  positionPath,
  storeTopic,
} from '../../utils/utils'
import injectSaga from '../../utils/injectSaga'
import injectReducer from '../../utils/injectReducer'
import {
  openModal,
  closeModal,
  notifyModal,
  subscribeOnTopic,
} from '../../containers/App/actions'
import ConfirmModal from '../../containers/ModalContainer/ConfirmModal'
import { makeSelectMtiPermission } from '../../containers/App/selectors'
import NotifyModal from '../../containers/ModalContainer/NotifyModal'
import {
  makeSelectCanvasObject,
  makeSelectCanvasSelectedId,
  makeSelectFailed,
  makeSelectLoading,
  makeSelectIsStatic,
  makeSelectPositionsByFixture,
  makeSelectPrototypes,
  makeSelectChosenPosition,
  makeSelectPositionName,
  makeSelectPositionCount,
  makeSelectFixtureOrmed,
  makeSelectAutoPlacementMax,
  makeSelectSecurityDevices,
  makeSelectSecuredProducts,
  makeSelectManufacturers,
  makeSelectFixture,
  makeSelectAllProducts,
} from './selectors'
import reducer from './reducer'
import saga from './sagas'
import {
  loadCanvas,
  savePosition,
  saveAll,
  removeObject,
  fixtureCanvasChanged,
  fixtureCanvasNameChanged,
  fixtureCanvasPositionCountChanged,
  assignSecurityDevice,
  assignPortSecuredProduct,
  addNewProduct,
  addAndAssignPortSecuredProduct,
  deleteProduct,
} from './actions'
import FixtureScreen from './index.screen'
import ProgressIndicator from '../../components/ProgressIndicator'
import StoreLayoutPreloader from '../../components/Preloaders/StoreLayoutPreloader'
import AddNewProductModal from './modals/addNewProduct'
import AssignProductModal from './modals/assignProduct'
import SecurityDeviceModal from './modals/securityDevice'
import FetchFailedAlert from '../../components/FetchFailed'
import { makeSelectAlertsByStoreId } from '../AlertContainer/selectors'

const noPermissionText =
  "You don't have appropriate rights to Edit position details.\nPlease contact your store manager."

export const size = {
  width: 600,
  height: 400,
}

export const fixtureIsFurnitureMessage =
  'This Fixture is identified as "Furniture" -  to add positions to this Fixture, Edit the Fixture and remove the "Furniture" identification.'

const permText = (text) =>
  `You don't have appropriate rights to access ${text}.\nPlease contact your store manager.`

export class FixturePage extends React.PureComponent {
  constructor(props) {
    super(props)
    const positionId = parseInt(getQueryVariable('positionId') || '-1', 10)
    this.state = {
      isAutoplacement: true,
      positionId,
      canvasObject: { objects: [] },
    }
  }

  componentDidMount() {
    this.loadCanvas(null, true, -1)
    this.listenHistory()
  }

  componentDidUpdate(prevProps) {
    /* eslint-disable react/no-did-update-set-state */
    if (
      this.props.selectedFixtureOrmed &&
      this.props.selectedFixtureOrmed.noninteractive
    ) {
      this.noPermissionToFurniture(this.props.selectedFixtureOrmed)
      return
    }
    const {
      sId: prevSId,
      fId: prevFId,
      aId: prevAId,
      fxId: prevFxId,
    } = prevProps.match.params
    const {
      sId: thisSId,
      fId: thisFId,
      aId: thisAId,
      fxId: thisFxId,
    } = this.props.match.params

    if (
      prevSId !== thisSId ||
      prevFId !== thisFId ||
      prevAId !== thisAId ||
      prevFxId !== thisFxId
    ) {
      this.loadCanvas(
        {
          sId: thisSId,
          fId: thisFId,
          aId: thisAId,
          fxId: thisFxId,
        },
        this.props.isStatic,
        this.props.selectedId
      )
      this.setState({ canvasObject: { objects: [] } })
    } else if (!this.props.loading) {
      this.setState({ canvasObject: this.props.canvasObject })
    }
  }

  componentWillUnmount() {
    this.unlistenHistory()
  }

  getSelectedPosition() {
    const { selectedPosition, positions } = this.props
    return (positions || []).find(
      (p) => (p || {}).id === (selectedPosition || {}).id
    )
  }

  noPermissionToFurniture = (fixture) => {
    const { notifyModal } = this.props
    notifyModal(fixtureIsFurnitureMessage)
    const { storeId, storeName, floorId, floorName, areaId, areaName } = fixture
    this.props.history.replace(
      `/stores/${storeId}/${storeName}/floors/${floorId}/${floorName}/areas/${areaId}/${areaName}`
    )
  }

  onMaxPositions() {
    const {
      selectedAutoplacementMax: max,
      openModal: doOpenModal,
      closeModal: doCloseModal,
    } = this.props
    doOpenModal({
      id: 'notify-max-positions',
      type: 'bootstrap',
      appearance: 'modal-md',
      content: (
        <NotifyModal
          id={'notify-max-positions'}
          notifyText={`Maximum number of positions for this fixture is ${max}.\nCan not create another new position.`}
          confirmText={'OK'}
          onClose={() => doCloseModal({ id: 'notify-max-positions' })}
        />
      ),
    })
  }

  onMessage(msg) {
    const { notifyModal: doNotifyModal } = this.props
    doNotifyModal(msg)
  }

  onRemovePosition(canvasObject, selectedId) {
    const { openModal: doOpenModal, closeModal: doCloseModal } = this.props
    doOpenModal({
      id: 'delete',
      type: 'bootstrap',
      appearance: 'modal-sm',
      content: (
        <ConfirmModal
          id={'delete'}
          cancelText={'CANCEL'}
          confirmText={'DELETE POSITION'}
          onClose={() => doCloseModal({ id: 'delete' })}
          onConfirm={() => {
            this.props.removeObject({
              canvasObject,
              sId: this.props.match.params.sId,
              fId: this.props.match.params.fId,
              aId: this.props.match.params.aId,
              fxId: this.props.match.params.fxId,
              selectedId: selectedId,
              screen: size,
            })
            this.setState({
              isAutoplacement: canvasObject.objects.length === 2,
            })
          }}
        />
      ),
    })
  }

  onRemoveSecuredProduct(
    confirmCb,
    armDevice,
    previouslyAssignedSecuredProduct,
    deletedSecuredProduct,
    port
  ) {
    const { openModal: doOpenModal, closeModal: doCloseModal } = this.props
    const id = 'deleteSecuredProduct'

    doOpenModal({
      id,
      type: 'bootstrap',
      appearance: 'modal-sm',
      content: (
        <ConfirmModal
          id={id}
          cancelText={'Cancel'}
          confirmText={'Delete Secured Product'}
          onClose={() => {
            doCloseModal({ id })
            setTimeout(
              () =>
                this.onAssignPortProduct(
                  armDevice,
                  previouslyAssignedSecuredProduct,
                  deletedSecuredProduct,
                  port
                ),
              500
            )
          }}
          onConfirm={() => {
            doCloseModal({ id })
            confirmCb()
          }}
        />
      ),
    })
  }

  onSavePosition(canvasObject, selectedId) {
    this.props.savePosition({
      canvasObject,
      sId: this.props.match.params.sId,
      fId: this.props.match.params.fId,
      aId: this.props.match.params.aId,
      fxId: this.props.match.params.fxId,
      selectedId: selectedId,
      screen: size,
    })
    this.setState({ isAutoplacement: false })
  }

  onSaveAll(canvasObject) {
    this.props.saveAll({
      canvasObject,
      sId: this.props.match.params.sId,
      fId: this.props.match.params.fId,
      aId: this.props.match.params.aId,
      fxId: this.props.match.params.fxId,
      screen: size,
    })
    this.setState({ isAutoplacement: false })
  }

  /* eslint-disable no-undef */
  onAddNewProduct = (onResultCancel, onResultAssign) => {
    const {
      openModal: doOpenModal,
      closeModal: doCloseModal,
      addNewProduct: doAddNewProduct,
      manufacturers,
      products,
      match: { params: { sId, fId, aId, fxId } },
    } = this.props

    const id = 'add-new-product'

    doOpenModal({
      id,
      type: 'bootstrap',
      content: (
        <AddNewProductModal
          id={id}
          name={'addNewProduct'}
          manufacturers={manufacturers}
          products={products}
          storeId={sId}
          onAddNewProduct={(payload) => {
            doAddNewProduct({
              ...payload,
              sId,
              fId,
              aId,
              fxId,
              cb: () => setTimeout(() => onResultCancel(), 500),
            })
          }}
          onAddAndAssignNewProduct={(payload) => onResultAssign(payload, id)}
          onCancel={() => {
            doCloseModal({ id })
            setTimeout(() => onResultCancel(), 500)
          }}
        />
      ),
    })
  }

  onAssignPortProduct = (
    securityDevice,
    previouslyAssignedSecuredProduct,
    securedProductToHideFromCache,
    port = { address: 1 }
  ) => {
    const {
      openModal: doOpenModal,
      closeModal: doCloseModal,
      assignPortSecuredProduct: doAssignPortSecuredProduct,
      addAndAssignPortSecuredProduct: doAddAndAssignPortSecuredProduct,
      deleteProduct: doDeleteProduct,
      securedProducts,
      selectedPosition,
      match: { params: { sId, fId, aId, fxId } },
      canvasObject,
      selectedId,
    } = this.props
    // TODO: here is a workaround to filter out the recently deleted secured product (securedProductToHideFromCache)
    // from the list of products. We should make assignProduct a logical component and subscribe for state updates instead.
    let sProducts = securedProducts
    sProducts = sProducts.filter(
      (sp) =>
        !sp.portId &&
        sp.category != SecuredProductCategory.MISSING &&
        sp.category != SecuredProductCategory.RETIRED &&
        (!securedProductToHideFromCache ||
          sp.id !== securedProductToHideFromCache.id)
    )

    const id = 'assign-product'
    const params = {
      sId,
      fId,
      aId,
      fxId,
      canvasObject,
      selectedId,
      selectedPosition,
      screen: size,
      securityDevice,
      port,
    }

    doOpenModal({
      id,
      type: 'bootstrap',
      content: (
        <AssignProductModal
          id={id}
          name={'assignProduct'}
          products={sProducts}
          selectedProduct={{ id: port.securedProductId }}
          selectedPosition={selectedPosition}
          onAddNewProduct={() =>
            this.onAddNewProduct(
              () =>
                this.onAssignPortProduct(
                  securityDevice,
                  previouslyAssignedSecuredProduct,
                  securedProductToHideFromCache,
                  port
                ),
              (payload, modalId) =>
                doAddAndAssignPortSecuredProduct({
                  ...payload,
                  sId,
                  fId,
                  aId,
                  fxId,
                  canvasObject,
                  selectedId,
                  selectedPosition,
                  securityDevice,
                  port,
                  screen: size,
                  cb: () => doCloseModal({ modalId }),
                })
            )
          }
          onConfirm={(selectedProduct) => {
            doCloseModal({ id })
            doAssignPortSecuredProduct({
              ...params,
              selectedProduct:
                selectedProduct || previouslyAssignedSecuredProduct,
              // a blank port address will unassign the secured product from whatever port it's currently assigned to
              port: selectedProduct ? port : { address: null },
            })
          }}
          onClose={() => doCloseModal({ id })}
          onDeleteProduct={(securedProductToDelete) => {
            doCloseModal({ id })
            setTimeout(
              () =>
                this.onRemoveSecuredProduct(
                  () => {
                    doDeleteProduct({
                      ...params,
                      selectedProduct: securedProductToDelete,
                    })
                  },
                  securityDevice,
                  previouslyAssignedSecuredProduct,
                  securedProductToDelete,
                  port
                ),
              500
            )
          }}
        />
      ),
    })
  }

  onSecurityDevice = (armDevice) => {
    const id = 'security-device'
    const {
      openModal: doOpenModal,
      closeModal: doCloseModal,
      assignSecurityDevice: doAssignSecurityDevice,
      securityDevices,
      selectedPosition,
      match: { params: { sId, fId, aId, fxId } },
      canvasObject,
      selectedId,
    } = this.props

    const expectedDeviceType = selectedPosition.wantedDeviceType
    const assignableSecurityDevices = securityDevices.filter((device) => {
      if (expectedDeviceType) {
        if (
          expectedDeviceType === DeviceType.CX_FLEX &&
          (device.deviceType === DeviceType.CX_FLEX_ONE_PORT ||
            device.deviceType === DeviceType.CX_FLEX_FOUR_PORT)
        ) {
          return true
        }
        return device.deviceType === expectedDeviceType
      }

      switch (device.deviceType) {
        case DeviceType.BASE:
        case DeviceType.PUCK:
        case DeviceType.ALARM_MODULE:
        case DeviceType.BASE_V2:
        case DeviceType.CX_FLEX:
        case DeviceType.CX_FLEX_ONE_PORT:
        case DeviceType.CX_FLEX_FOUR_PORT:
        case DeviceType.SECURE_PLUG:
        case DeviceType.LOCK:
        case DeviceType.PROXIMITY_BASE:
        case DeviceType.NXDI_RISER:
          return true
        default:
          return false // RAM, PUCK_V2, PROXIMITY_PUCK, and DUMB_PUCK cannot be assigned to positions
      }
    })

    // TODO: make sure that armDevice is updated after onConfirm executed
    doOpenModal({
      id,
      type: 'bootstrap',
      content: (
        <SecurityDeviceModal
          id={id}
          onConfirm={(selectedDevice) => {
            doAssignSecurityDevice({
              sId,
              fId,
              aId,
              fxId,
              canvasObject,
              selectedId,
              selectedPosition,
              selectedDevice,
              screen: size,
            })
            doCloseModal({ id })
          }}
          onIdentify={(selectedDevice) => {
            console.warn('selectedDevice', selectedDevice)
            const { postSecurityDeviceAction } = this.props
            const { id } = selectedDevice
            postSecurityDeviceAction({
              id: id,
              action: positionAction.IDENTIFY,
            })
          }}
          existingDevice={[armDevice]}
          devices={assignableSecurityDevices}
          onClose={() => doCloseModal({ id })}
        />
      ),
    })
  }

  listenHistory() {
    this.unlistenHistory = this.props.history.listen(() => {
      const positionId = parseInt(getQueryVariable('positionId') || '-1', 10)
      this.setState({ positionId }, () => this.loadCanvas(null, true, -1))
    })
  }

  checkCountAutoplacement(count) {
    const { selectedAutoplacementMax } = this.props
    if (isNaN(count)) {
      return 0
    } else if (count < 0) {
      return 0
    } else if (count > selectedAutoplacementMax) {
      return selectedAutoplacementMax
    }
    return count
  }

  loadCanvas(params, isStatic, selectedId = -1) {
    const newParams = params || this.props.match.params
    const { positionId } = this.state
    selectedId = selectedId !== -1 ? selectedId : positionId || -1
    const topic = storeTopic(newParams.sId)
    this.props.subscribeOnTopic(topic)
    this.props.loadCanvas(
      size,
      isStatic,
      selectedId,
      newParams.sId,
      newParams.fxId
    )
  }

  /* eslint-disable no-undef, react/sort-comp */
  noPermission = (hasPermission, text) => {
    const { notifyModal: doNotifyModal } = this.props
    if (!hasPermission) doNotifyModal(text)
    return !hasPermission
  }

  onOpenPosition = ({ positionId }) => {
    const { positions, history } = this.props
    const position = positions.find(({ id }) => id === positionId)
    positionPath(position, history)
  }

  render() {
    // TODO: We should move the toFabricSync logic here, assuming the
    // selectors all return with the necessary data (if not, then display
    // a default loading graphic instead) That way, the toFabricSync
    // logic will only be called when the page is actually loaded,
    // instead of being called numberous times before the data is loaded
    // and erroring out, like it is now.

    const {
      selectedId,
      fixtureCanvasChanged: onChanged,
      fixtureCanvasNameChanged: onNameChanged,
      fixtureCanvasPositionCountChanged: onCountChanged,
      notifyModal: doNotifyModal,
      loading,
      failed,
      isStatic,
      hasStoreEditorPermission,
      positions,
      prototypes,
      selectedPositionName,
      selectedPositionCount,
      selectedAutoplacementMax,
      selectedFixtureOrmed,
      selectedFixture: { rules = [], name: fixtureName } = {},
      alerts,
    } = this.props

    const { isAutoplacement, canvasObject } = this.state
    const positionId = getQueryVariable('positionId')
    if (loading && loading === loadingType.visible) {
      return (
        <React.Fragment>
          <StoreLayoutPreloader positionFixure={!!positionId} />
          <ProgressIndicator text={'Loading...'} />
        </React.Fragment>
      )
    }

    if (failed) return <FetchFailedAlert failed={failed} />
    const isSilent = loading && loading === loadingType.silent

    return (
      <FixtureScreen
        canvasSize={size}
        canvasObject={canvasObject}
        selectedId={selectedId}
        selectedPosition={this.getSelectedPosition()}
        selectedPositionName={selectedPositionName}
        selectedPositionCount={selectedPositionCount}
        selectedAutoplacementMax={selectedAutoplacementMax}
        hasEditPermission={hasStoreEditorPermission}
        fixtureName={fixtureName}
        isStatic={isStatic}
        isSilent={isSilent}
        positions={positions}
        prototypes={prototypes}
        isAutoplacement={
          !(positions && positions.length > 0) && isAutoplacement
        }
        onChanged={(o) => {
          if (!o.isStatic) {
            if (
              this.noPermission(
                hasStoreEditorPermission,
                permText('Store Editor')
              )
            ) {
              return
            }
          }
          onChanged(o)
        }}
        onNameChanged={onNameChanged}
        onPositionCountChanged={(count) =>
          onCountChanged({
            count: this.checkCountAutoplacement(count),
            fixture: selectedFixtureOrmed,
            screen: size,
          })
        }
        onMessage={(msg) => this.onMessage(msg)}
        onEdit={() => {
          if (
            this.noPermission(
              hasStoreEditorPermission,
              permText('Store Editor')
            )
          ) {
            return
          }
          this.loadCanvas(null, false, selectedId)
        }}
        onEditDenied={() => doNotifyModal(noPermissionText)}
        onCancel={() => {
          this.loadCanvas(null, true, -1)
        }}
        onPositionRemove={(sO) => this.onRemovePosition(canvasObject, sO)}
        onPositionSave={(sO) => this.onSavePosition(canvasObject, sO)}
        onSaveAll={() => this.onSaveAll(canvasObject)}
        onMaxPositions={() => this.onMaxPositions()}
        onAssignPortProduct={this.onAssignPortProduct}
        onSecurityDevice={this.onSecurityDevice}
        openPosition={this.onOpenPosition}
        fixtureRules={rules}
        alerts={alerts}
      />
    )
  }
}

FixturePage.propTypes = {
  history: PropTypes.any,
  match: PropTypes.any,
  params: PropTypes.shape({
    sId: PropTypes.string.isRequired,
    fId: PropTypes.string.isRequired,
    aId: PropTypes.string.isRequired,
  }),
  loadCanvas: PropTypes.func.isRequired,
  savePosition: PropTypes.func.isRequired,
  saveAll: PropTypes.func.isRequired,
  assignSecurityDevice: PropTypes.func.isRequired,
  assignPortSecuredProduct: PropTypes.func.isRequired,
  addNewProduct: PropTypes.func.isRequired,
  deleteProduct: PropTypes.func.isRequired,
  addAndAssignPortSecuredProduct: PropTypes.func.isRequired,
  removeObject: PropTypes.func.isRequired,
  fixtureCanvasChanged: PropTypes.func.isRequired,
  fixtureCanvasNameChanged: PropTypes.func.isRequired,
  fixtureCanvasPositionCountChanged: PropTypes.func.isRequired,
  notifyModal: PropTypes.func,
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
  loading: PropTypes.string,
  failed: PropTypes.object,
  isStatic: PropTypes.bool,
  canvasObject: PropTypes.object.isRequired,
  selectedId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hasStoreEditorPermission: PropTypes.bool,
  positions: PropTypes.any,
  prototypes: PropTypes.object,
  selectedPosition: PropTypes.object,
  selectedPositionName: PropTypes.string,
  selectedPositionCount: PropTypes.number,
  selectedAutoplacementMax: PropTypes.number,
  selectedFixtureOrmed: PropTypes.object,
  securityDevices: PropTypes.array,
  securedProducts: PropTypes.array,
  manufacturers: PropTypes.array,
  subscribeOnTopic: PropTypes.func,
  selectedFixture: PropTypes.object,
  postSecurityDeviceAction: PropTypes.func,
}

// TODO: Evaluate which of these makeSelects are not required and refactor
const mapStateToProps = (state, ownProps) => {
  const getCanvasObject = makeSelectCanvasObject(ownProps.match.params.fxId)
  const getSelectedId = makeSelectCanvasSelectedId()
  const getLoading = makeSelectLoading()
  const getFailed = makeSelectFailed()
  const getIsStatic = makeSelectIsStatic()
  const getHasStoreEditorPermission = makeSelectMtiPermission(
    'Store: Editor',
    ownProps.match.params.sId
  )
  const getPositions = makeSelectPositionsByFixture(ownProps.match.params.fxId)
  const getPrototypes = makeSelectPrototypes()
  const getSelectedPosition = makeSelectChosenPosition()
  const getSelectedPositionName = makeSelectPositionName()
  const getSelectedPositionCount = makeSelectPositionCount()
  const getSelectedAutoplacementMax = makeSelectAutoPlacementMax()
  const getSelectedFixtureOrmed = makeSelectFixtureOrmed()
  const getSecurityDevices = makeSelectSecurityDevices(
    ownProps.match.params.sId
  )
  const getSecuredProducts = makeSelectSecuredProducts(
    ownProps.match.params.sId
  )
  const getProducts = makeSelectAllProducts()
  const getManufacturers = makeSelectManufacturers()
  const getSelectedFixture = makeSelectFixture(ownProps.match.params.fxId)
  const getAlerts = makeSelectAlertsByStoreId(ownProps.match.params.sId)

  return (state, ownProps) => ({
    canvasObject: getCanvasObject(state, ownProps),
    selectedId: getSelectedId(state),
    loading: getLoading(state),
    failed: getFailed(state),
    isStatic: getIsStatic(state),
    hasStoreEditorPermission: getHasStoreEditorPermission(state, ownProps),
    positions: getPositions(state, ownProps),
    prototypes: getPrototypes(state),
    selectedPosition: getSelectedPosition(state),
    selectedPositionName: getSelectedPositionName(state),
    selectedPositionCount: getSelectedPositionCount(state),
    selectedAutoplacementMax: getSelectedAutoplacementMax(state),
    selectedFixtureOrmed: getSelectedFixtureOrmed(state),
    securityDevices: getSecurityDevices(state, ownProps),
    securedProducts: getSecuredProducts(state, ownProps),
    products: getProducts(state),
    manufacturers: getManufacturers(state, ownProps),
    selectedFixture: getSelectedFixture(state, ownProps),
    alerts: getAlerts(state, ownProps),
  })
}

const mapDispatchToProps = {
  fixtureCanvasChanged,
  fixtureCanvasNameChanged,
  fixtureCanvasPositionCountChanged,
  loadCanvas,
  savePosition,
  saveAll,
  removeObject,
  openModal,
  closeModal,
  notifyModal,
  assignSecurityDevice,
  assignPortSecuredProduct,
  addNewProduct,
  addAndAssignPortSecuredProduct,
  deleteProduct,
  subscribeOnTopic,
  postSecurityDeviceAction,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const withReducer = injectReducer({ key: 'fixtureCanvas', reducer })
const withSaga = injectSaga({ key: 'fixtureCanvas', saga })

export default compose(withReducer, withSaga, withConnect)(FixturePage)
