import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Link } from 'react-router-dom'
import styles from '../../styles/App.css'
import { fullTextFilter } from '../../utils/utils'
import ServerManagedTable from '../../components/ServerManagedTable'
import TablePreloader from '../../components/Preloaders/TablePreloader'
import ProgressIndicator from '../../components/ProgressIndicator'
import {
  CellContainer,
  renderFilterInput,
  renderHeaderCell,
} from './index.screen'

const transformFilters = (id, value) => {
  switch (id) {
    case 'email':
      return { 'emailLike': value }
    case 'roleKey':
      return { 'role': value }
    default:
      return { [id]: value }
  }
}

const transformSorts = key => ({
  roleKey: 'role_key',
}[key] || key)

const columns = [
  {
    sortable: true,
    filterable: true,
    Header: renderHeaderCell('Name'),
    accessor: 'name',
    Cell: ({ original: { name } }) => (
      <CellContainer>
        <Link name={`link_${name}`} className={styles.link} to={'#'}>
          {name}
        </Link>
      </CellContainer>
    ),
    Filter: ({ filter, onChange }) =>
      renderFilterInput({ placeholder: 'Filter', filter, onChange }),
  },
  {
    sortable: true,
    filterable: true,
    Header: renderHeaderCell('Role'),
    accessor: 'rolesTitle',
    Filter: ({ filter, onChange }) =>
      renderFilterInput({ placeholder: 'Filter', filter, onChange }),
  },
  {
    sortable: true,
    filterable: false,
    Header: renderHeaderCell('Last signed in at', true),
    accessor: 'lastSignedInAt',
    Cell: ({ original: { lastSignedInAt } }) => (
      <span>
        {lastSignedInAt &&
         moment(lastSignedInAt)
           .utcOffset(0)
           .format('MM/DD/YYYY H:mm')}
      </span>
    ),
    Filter: ({ filter, onChange }) =>
      renderFilterInput({
        placeholder: 'Filter',
        filter,
        onChange,
        id: 'lastSignedInAt',
      }),
  },
]

function UsersPageModalScreen({ users, meta, onFetchData, onUser, id, onClose, loading }) {
  return (
    <div className="modal-content">
      <div className="modal-header border-bottom">
        <h5 className="modal-title" id="exampleModalLongTitle">
          Assign User
        </h5>
        <div>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
                $(`#modal-${id}`).modal('hide') // eslint-disable-line
                onClose()
            }}
          >
            Close
          </button>
        </div>
      </div>
      <div className="modal-body">
        <div style={{ position: 'relative' }}>
          <ServerManagedTable
            className="full-screen"
            data={users || []}
            meta={meta}
            columns={columns}
            loadData={onFetchData}
            transformFilters={transformFilters}
            transformSorts={transformSorts}
            onRowClick={user => {
                $(`#modal-${id}`).modal('hide')
                onUser(user)
            }}
          />
          {loading && (
            <div style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, zIndex: 200, backgroundColor: 'white' }}>
              <TablePreloader columnsCount={6} loaderWidth={window.innerWidth} />
              <ProgressIndicator text={'Loading...'} />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

UsersPageModalScreen.propTypes = {
  users: PropTypes.array.isRequired,
  meta: PropTypes.object,
  id: PropTypes.string.isRequired,
  onUser: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onFetchData: PropTypes.func,
}

export default UsersPageModalScreen
