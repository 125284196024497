import { ActionTypes } from '../constants'

/**
 * Fetch Personae Failed
 *
 * @returns {Object}
 */
export function fetchPersonaeFailed(error) {
  return {
    type: ActionTypes.FETCH_PERSONAE_FAILED,
    payload: error,
  }
}

/**
 * Fetch Personae Fulfilled
 *
 * @returns {Object}
 */
export function fetchPersonaeFulfilled(data) {
  return {
    type: ActionTypes.FETCH_PERSONAE_FULFILLED,
    payload: {
      personae: data,
    },
  }
}

/**
 * Fetch Personae Pending
 *
 * @returns {Object}
 */
export function fetchPersonaePending() {
  return {
    type: ActionTypes.FETCH_PERSONAE_PENDING,
  }
}

/**
 * Load Personae
 *
 * @returns {Object}
 */
export function loadPersonae() {
  return {
    type: ActionTypes.LOAD_PERSONAE,
  }
}
