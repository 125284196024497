/*
 * Resource Filter reducer
 */
import { Map } from 'immutable'
import moment from 'moment'
import { ActionTypes } from './constants'

export const initialState = new Map({
  regions: null,
  stores: null,
  regionsFailed: null,
  regionsLoading: false,
  storesFailed: null,
  storesLoading: false,
  selectedStores: undefined,
  dates: {
    startDate: moment().subtract(6, 'days'),
    endDate: moment(),
  },
  combination: {
    health: true,
    operations: true,
    marketing: true,
  },
  isDefaultFilters: true,
})

function reducer(state = initialState, { payload, type }) {
  switch (type) {
    case ActionTypes.UPDATE_SELECTED_STORES:
      return state.set('selectedStores', payload)
    case ActionTypes.UPDATE_DATES: {
      const { startDate: newStartDate, endDate: newEndDate } = payload
      const current = state.get('dates')
      if (newStartDate) {
        if (
          current.endDate.diff(newStartDate, 'days') > 6 ||
          newStartDate.isAfter(current.endDate)
        ) {
          current.endDate = newStartDate.clone().add(6, 'days')
          if (current.endDate.isAfter(moment())) {
            const days = current.endDate.diff(moment(), 'days') + 1
            current.endDate.subtract(days, 'days')
          }
        }
      }
      if (newEndDate) {
        if (
          newEndDate.diff(current.startDate, 'days') > 6 ||
          current.startDate.isAfter(newEndDate)
        ) {
          current.startDate = newEndDate.clone().subtract(6, 'days')
        }
      }
      return state
        .set('dates', Object.assign({}, current, payload))
        .set('isDefaultFilters', false)
    }
    case ActionTypes.UPDATE_REPORT_COMBINATION: {
      const current = state.get('combination')
      return state
        .set('combination', Object.assign({}, current, payload))
        .set('isDefaultFilters', false)
    }
    case ActionTypes.RESET_STORE_TO_DEFAULTS:
      console.warn('RESET_STORE_TO_DEFAULTS')
      return state
        .set('isDefaultFilters', true)
        .set('soresLoading', undefined)
        .set('dates', {
          startDate: moment().subtract(6, 'days'),
          endDate: moment(),
        })
        .set('combination', {
          health: true,
          operations: true,
          marketing: true,
        })
    default:
      return state
  }
}

export default reducer
