import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Header = styled.div`
  border-bottom: 1px solid lightgray !important;
  padding-bottom: 1rem !important;
  text-align: center;
  justify-content: center;
`

const ConfirmModal = ({
  id,
  cancelText,
  confirmText,
  questionText,
  onClose,
  onConfirm,
  hideCloseInHeader,
  headerText,
}) => (
  <div className="modal-content">
    <Header className="modal-header">
      {headerText && <React.Fragment>{headerText}</React.Fragment>}
      {!hideCloseInHeader && (
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={() => {
            $(`#modal-${id}`).modal('hide') // eslint-disable-line
            onClose()
          }}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      )}
    </Header>
    <div className="modal-body">{questionText || 'Are you sure?'}</div>
    <div className="modal-footer">
      <button
        type="button"
        className="btn btn-secondary mr-2"
        onClick={() => {
          $(`#modal-${id}`).modal('hide') // eslint-disable-line
          onClose()
        }}
      >
        {cancelText}
      </button>
      <button
        type="button"
        className="btn btn-primary btn-raised"
        onClick={() => {
          $(`#modal-${id}`).modal('hide') // eslint-disable-line
          onConfirm()
        }}
      >
        {confirmText}
      </button>
    </div>
  </div>
)

ConfirmModal.propTypes = {
  id: PropTypes.string.isRequired,
  cancelText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  confirmText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  questionText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  headerText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  hideCloseInHeader: PropTypes.bool,
}

ConfirmModal.defaultProps = {
  hideCloseInHeader: false,
  headerText: undefined,
}
export default ConfirmModal
