import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Link } from 'react-router-dom'
import styles from '../../styles/App.css'
import { fullTextFilter } from '../../utils/utils'
import {
  CellContainer,
  renderFilterInput,
  renderHeaderCell,
} from '../UsersPage/index.screen'
import { TableInModal } from '../../styles/styled'

const renderContent = (regions, onRegion, modalId, showStores) => {
  let columns = [
    {
      sortable: true,
      filterable: true,
      Header: renderHeaderCell('Name'),
      accessor: 'name',
      Cell: ({ original: { name } }) => (
        <CellContainer>
          <Link name={name} className={styles.link} to={'#'}>
            {name}
          </Link>
        </CellContainer>
      ),
      Filter: ({ filter, onChange }) =>
        renderFilterInput({
          placeholder: 'Filter',
          filter,
          onChange,
          id: 'name',
        }),
    },
  ]

  if (showStores) {
    columns[0].width = 225

    const storeColumns = [
      {
        id: 'store',
        sortable: true,
        filterable: true,
        Header: renderHeaderCell('Stores'),
        accessor: ({ stores }) => stores.map(({ name }) => name).join(', '),
        Filter: ({ filter, onChange }) =>
          renderFilterInput({ placeholder: 'Filter', filter, onChange }),
      },
    ]

    columns = [...columns, ...storeColumns]
  } else {
    const userRoleColumns = [
      {
        sortable: true,
        filterable: true,
        Header: renderHeaderCell('Role'),
        accessor: 'rolesTitle',
        Filter: ({ filter, onChange }) =>
          renderFilterInput({ placeholder: 'Filter', filter, onChange }),
      },
      {
        sortable: true,
        filterable: true,
        Header: renderHeaderCell('Last signed in at', true),
        accessor: 'lastSignedInAt',
        Cell: ({ original: { lastSignedInAt } }) => (
          <span>
            {lastSignedInAt &&
              moment(lastSignedInAt)
                .utcOffset(0)
                .format('MM/DD/YYYY H:mm')}
          </span>
        ),
        Filter: ({ filter, onChange }) =>
          renderFilterInput({
            placeholder: 'Filter',
            filter,
            onChange,
            id: 'lastSignedInAt',
          }),
      },
    ]

    columns = [...columns, ...userRoleColumns]
  }

  return (
    <div>
      <TableInModal
        className="full-screen"
        data={regions || []}
        columns={columns}
        defaultFilterMethod={fullTextFilter}
        getTdProps={(state, rowInfo) => ({
          onClick: (e, handleOriginal) => {
            $(`#modal-${modalId}`).modal('hide') // eslint-disable-line
            onRegion(rowInfo.original.toJS())

            if (handleOriginal) {
              handleOriginal()
            }
          },
        })}
      />
    </div>
  )
}

function RegionsPageModalScreen({
  regions,
  onRegion,
  id,
  onClose,
  showStores,
}) {
  return (
    <div className="modal-content">
      <div className="modal-header border-bottom">
        <h5 className="modal-title" id="exampleModalLongTitle">
          Select Region
        </h5>
        <div>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              $(`#modal-${id}`).modal('hide') // eslint-disable-line
              onClose()
            }}
          >
            Close
          </button>
        </div>
      </div>
      <div className="modal-body">
        {renderContent(regions, onRegion, id, showStores)}
      </div>
    </div>
  )
}

RegionsPageModalScreen.propTypes = {
  regions: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
  onRegion: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  showStores: PropTypes.bool,
}

export default RegionsPageModalScreen
