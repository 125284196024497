import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const OrganizationNameBreadcrumb = ({ match: { key, params: { oName } } }) => (
  <Link
    key={key}
    className="breadcrumb-item"
    to={'#'}
    onClick={(e) => e.preventDefault()}
  >
    {decodeURIComponent(oName)}
  </Link>
)

OrganizationNameBreadcrumb.propTypes = {
  match: PropTypes.object,
}

export default OrganizationNameBreadcrumb
