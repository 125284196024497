import { call, put, select } from 'redux-saga/effects'
import { tokenSelector } from '../App/selectors'
import { selectLoading, selectSecuredProductsByStoreId, selectIsAuxiliaryDataLoaded } from './selectors'
import {
  createDefaultFloorAndArea,
  upsertManufacturers,
  upsertSecuredProducts,
  upsertStore,
} from '../App/actions'

import {
  fetchSecuredProductsFailed,
  fetchSecuredProductsFulfilled,
  fetchSecuredProductsPending,
} from './actions'

import { getManufacturers, getSecuredProducts, getStoreById } from '../../api'

export function* loadSecuredProductsData(sId) {
  const token = yield select(tokenSelector)

  // TODO: Make better use of cache control headers to make API calls faster if
  // nothing has changed. That's a BIG todo on the API side, and probably means
  // pre-caching the entire store graph somehow, and updating it when one of the
  // dependent objects update.
  let isLoaded = yield select(selectIsAuxiliaryDataLoaded, sId)
  if (!isLoaded) {
    // Store might be missing if we've navigated directly to the Secured Products
    // page or refreshed the page after loading.
    //
    // The ORM layer tries to be ACID (TODO: why?) and doesn't like it if the
    // graph isn't already cached before the page is rendered.
    const store = yield call(getStoreById, token, sId)
    yield put(upsertStore(store))
  }
  const products = yield call(getSecuredProducts, token, sId, 0, ['product', 'position'])
  const { manufacturers } = yield call(getManufacturers, token)
  // Inject in orm
  yield put(upsertSecuredProducts(products))
  yield put(upsertManufacturers(manufacturers))
  yield put(createDefaultFloorAndArea(sId))
}

export function* loadSecuredProducts({ payload }) {
  const { sId } = payload
  const token = yield select(tokenSelector)
  if (!token) return

  const isFetchSecuredProductsPending = yield select(selectLoading)
  if (isFetchSecuredProductsPending) return

  // TODO: Why is this calling a non-performant selector just to check if they're loaded?
  // Can't it just call makeSelectSecuredProducts?
  const securedProductsLoaded = yield select(
    selectSecuredProductsByStoreId,
    sId
  )
  const isSecuredProductsLoaded =
    securedProductsLoaded && securedProductsLoaded.length > 1

  try {
    if (!isSecuredProductsLoaded) {
      yield put(fetchSecuredProductsPending(sId))
    }

    yield call(loadSecuredProductsData, sId)
    yield put(fetchSecuredProductsFulfilled())
  } catch (e) {
    console.error(e)
    yield put(fetchSecuredProductsFailed(e))
  }
}
