/**
 *
 * Password Set
 *
 */
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'

import injectReducer from 'utils/injectReducer'
import injectSaga from 'utils/injectSaga'
import passwordReducer from './reducer'
import passwordSaga from './sagas'
import { edit, post } from './actions'
import { closeModal, openModal } from '../App/actions'
import {
  makeSelectFailed,
  makeSelectLoading,
  makeSelectSuccess,
} from './selectors'
import PasswordSetScreen from './index.screen'
import ProgressIndicator from '../../components/ProgressIndicator'
import { isIos, isMobile } from '../../utils/screen'
import { getQueryVariable } from '../../utils/utils'
const devLink = 'mtimobileapp-dev://'
export const schemeIos = process.env.SCHEME_IOS || devLink
export const schemeAndroid = process.env.SCHEME_ANDROID || devLink

export class PasswordSet extends React.PureComponent {
  constructor(props) {
    super(props)
    const query = window.location.search.substring(1)
    const scheme =
      getQueryVariable('scheme') || (isIos() ? schemeIos : schemeAndroid)

    this.state = { query, scheme }
  }

  componentDidUpdate() {
    const { failed } = this.props
    if (failed && this.passwordSetScreen) {
      // TODO: update API to return standardized (password, pincode) named input field related values
      const { error: { message } = {} } = failed
      if (message === 'Unprocessable Entity') {
        // Standardized error
        const failedStandard = {
          error: { validationErrors: { password: [message] } },
        }
        this.passwordSetScreen.setServerValidity(failedStandard)
      } else {
        this.passwordSetScreen.setServerValidity(failed)
      }
    }
  }

  returnToLogin = () => {
    this.props.history.push('/login')
  }

  render() {
    const {
      loading,
      success,
      openModal,
      closeModal,
      post: doPost,
      edit: doEdit,
    } = this.props
    const { scheme, query } = this.state
    if (loading) return <ProgressIndicator text={'Loading...'} />

    return (
      <PasswordSetScreen
        ref={(ref) => (this.passwordSetScreen = ref)}
        isMobile={isMobile()}
        onChange={(o) => doEdit(o)}
        onConfirm={() => doPost(query)}
        mobileUri={`${scheme}${query}`}
        onReturnToLogin={this.returnToLogin}
        success={success}
        openModal={openModal}
        closeModal={closeModal}
      />
    )
  }
}

PasswordSet.propTypes = {
  history: PropTypes.any,
  loading: PropTypes.bool,
  failed: PropTypes.object,
  post: PropTypes.func,
  edit: PropTypes.func,
}

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
  failed: makeSelectFailed(),
  success: makeSelectSuccess(),
})

const mapDispatchToProps = {
  post,
  edit,
  openModal,
  closeModal,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)
const withPasswordReducer = injectReducer({
  key: 'password',
  reducer: passwordReducer,
})
const withPasswordSaga = injectSaga({ key: 'password', saga: passwordSaga })

export default compose(withPasswordReducer, withPasswordSaga, withConnect)(
  PasswordSet
)
