import {
  toSviCoordStructure,
  getAlertParams,
  alertTypes,
  getImage,
} from '../../utils/mtiCanvasUtils'
import {
  slicingAreaSync,
  propertiesToInclude,
  renderLabel,
} from '../../components/FloorCanvas/area'
import {
  getHCObject,
  // getPattern
} from './objectUtils'

export function toFabricSync(
  floor,
  images,
  screen,
  isStatic,
  isMobile,
  selectedId,
  prototypes,
  mtiJsclientShared
) {
  if (!floor) return floor
  const { previewImageId, shouldDisplayBackground } = floor
  const { image } = shouldDisplayBackground
    ? getImage(images, previewImageId, '@2xLink')
    : { image: undefined }
  const floors = (floor || {}).areas || []
  const os = floors.map((a) =>
    toFabricAreaSync(
      a,
      floor.layoutPosition,
      screen,
      isStatic,
      isMobile,
      prototypes,
      mtiJsclientShared
    )
  )
  return {
    canvasObject: { objects: os, selectedId, image },
  }
}

export function toFabricAreaSync(
  o,
  parentLayout,
  screen,
  isStatic,
  isMobile,
  prototypes,
  mtiJsclientShared
) {
  if (!o) return o
  const {
    name,
    pointer,
    leftMoved,
    topMoved,
    angle,
    id,
    displayAreaName,
    alertType,
    alertCount,
    fixturesCount,
  } = getAreaParams(o, parentLayout, screen, isStatic, isMobile)

  const areaObj = slicingAreaSync(
    name,
    pointer,
    leftMoved,
    topMoved,
    angle,
    id,
    displayAreaName,
    isStatic,
    alertType,
    alertCount,
    fixturesCount,
    isMobile,
    prototypes,
    mtiJsclientShared
  )
  return renderLabel(areaObj).toObject(propertiesToInclude)
}

export function getAreaParams(o, parentLayout, screen, isStatic) {
  const { alertType, alertCount } = getAlertParams(o.state)
  return getAreaParamsSvi(
    { ...toSviCoordStructure(o), displayAreaName: o.shouldDisplayName },
    screen,
    isStatic,
    alertType,
    alertCount
  )
}

export function getAreaParamsSvi(o, screen, isStatic, alertType, alertCount) {
  if (!o) return o
  const id = o.id
  const { width: w, height: h, xCenter: xC, yCenter: yC, angle } = o
  const width = w * screen.width
  const height = h * screen.width
  const xCenter = xC * screen.width
  const yCenter = yC * screen.width
  // Area has origin at center center
  const left = xCenter
  const top = yCenter

  const fixturesCount = o.fixturesCount
  const name = o.name
  const displayAreaName = o.displayAreaName
  const pointer = {
    x: left + width / 2,
    y: top + height / 2,
  }
  return {
    name,
    pointer,
    leftMoved: left,
    topMoved: top,
    angle,
    id,
    displayAreaName,
    alertType,
    alertCount,
    fixturesCount,
  }
}

// used for debug purposes
// export function getGeometryRandom(o, parentLayout, screen) {
//   const { layoutPosition: { theta } = {} } = o
//
//   const widthF = parseFloat('100')
//   const heightF = parseFloat('100')
//   const randomLeft = Math.floor(Math.random() * screen.width)
//   const randomTop = Math.floor(Math.random() * screen.height)
//   const leftF = parseFloat(randomLeft) - widthF / 2
//   const topF = parseFloat(randomTop) - heightF / 2
//
//   return {
//     width: widthF,
//     height: heightF,
//     left: leftF,
//     top: topF,
//     angle: theta,
//   }
// }

export async function getPrototypes() {
  return {
    alert: await getHCObject(alertTypes.alert),
    lowAlert: await getHCObject(alertTypes.lowAlert),
    warning: await getHCObject(alertTypes.warning),
    normal: await getHCObject(alertTypes.normal),
    // patternAlert: await getPattern('alert'),
    // patternWarning: await getPattern('warning'),
  }
}

export function getAreaName(o) {
  if (!o) return o
  return o.objects[1].fullText
}
