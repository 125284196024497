/*
 *
 * FloorPage actions
 *
 */

import { ActionTypes } from './constants'

/**
 * Store Prototypes
 *
 * @returns {Object}
 */
export function storePrototypes(prototypes) {
  return {
    type: ActionTypes.STORE_FLOOR_PROTOTYPES,
    payload: prototypes,
  }
}

/**
 * Store Params
 *
 * @returns {Object}
 */
export function storeParams(params) {
  return {
    type: ActionTypes.STORE_PARAMS,
    payload: params,
  }
}

/**
 * Post Canvas Failed
 *
 * @returns {Object}
 */
export function postFloorCanvasFailed(error) {
  return {
    type: ActionTypes.POST_FLOOR_CANVAS_FAILED,
    payload: error,
  }
}

/**
 * Post Canvas Fulfilled
 *
 * @returns {Object}
 */
export function postFloorCanvasFinished(payload) {
  return {
    type: ActionTypes.POST_FLOOR_CANVAS_FINISHED,
    payload,
  }
}

/**
 * Post Canvas Pending
 *
 * @returns {Object}
 */
export function postFloorCanvasPending(canvasId) {
  return {
    type: ActionTypes.POST_FLOOR_CANVAS_PENDING,
    payload: {
      canvasId,
    },
  }
}

/**
 * Fetch Canvas Failed
 *
 * @returns {Object}
 */
export function fetchFloorCanvasFailed(error) {
  return {
    type: ActionTypes.FETCH_FLOOR_CANVAS_FAILED,
    payload: error,
  }
}

/**
 * Fetch Canvas Fulfilled
 *
 * @returns {Object}
 */
export function fetchFloorCanvasFulfilled(
  canvasId,
  data,
  floor,
  selectedId,
  isStatic
) {
  return {
    type: ActionTypes.FETCH_FLOOR_CANVAS_FULFILLED,
    payload: {
      canvas: data,
      floor,
      selectedId,
      isStatic,
    },
  }
}

/**
 * Fetch Canvas Pending
 *
 * @returns {Object}
 */
export function fetchFloorCanvasPending(canvasId) {
  return {
    type: ActionTypes.FETCH_FLOOR_CANVAS_PENDING,
    payload: {
      canvasId,
    },
  }
}

/**
 * Load Canvas
 *
 * @returns {Object}
 */
export function loadCanvas(screen, isStatic, selectedId, sId, fId) {
  return {
    type: ActionTypes.LOAD_FLOOR_CANVAS,
    payload: {
      isStatic,
      screen,
      selectedId,
      sId,
      fId,
    },
  }
}

/**
 * Save Canvas
 *
 *
 */
export function saveCanvas(canvas) {
  return {
    type: ActionTypes.SAVE_FLOOR_CANVAS,
    payload: {
      canvas,
    },
  }
}

/**
 * Save Area
 *
 *
 */
export function saveArea(payload) {
  return {
    type: ActionTypes.SAVE_FLOOR_AREA,
    payload,
  }
}

/**
 * Floor Canvas Changed
 *
 * @returns {Object}
 */
export function floorCanvasChanged(payload) {
  return {
    type: ActionTypes.FLOOR_CANVAS_CHANGED,
    payload,
  }
}

/**
 * Remove area
 *
 * @returns {Object}
 */
export function removeObject(payload) {
  return {
    type: ActionTypes.FLOOR_CANVAS_AREA_REMOVE,
    payload,
  }
}

/**
 * Area is changed
 *
 * @returns {Object}
 */
export function changeArea(payload) {
  return {
    type: ActionTypes.FLOOR_CANVAS_AREA_CHANGED,
    payload,
  }
}

/**
 * Floor background from canvas
 *
 * @returns {Object}
 */
export function changeFloorThumbnail(payload) {
  return {
    type: ActionTypes.FLOOR_THUMBNAIL_FROM_CANVAS_CHANGED,
    payload,
  }
}
