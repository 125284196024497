import { ActionTypes } from '../App/constants'

/**
 * Fetch Stores Failed
 *
 * @returns {Object}
 */
export function fetchStoresFailed(error) {
  return {
    type: ActionTypes.FETCH_STORES_FAILED,
    payload: error,
  }
}

/**
 * Fetch Stores Fulfilled
 *
 * @returns {Object}
 */
export function fetchStoresFulfilled() {
  return {
    type: ActionTypes.FETCH_STORES_FULFILLED,
  }
}

/**
 * Fetch Stores Pending
 *
 * @returns {Object}
 */
export function fetchStoresPending() {
  return {
    type: ActionTypes.FETCH_STORES_PENDING,
  }
}

/**
 * Load Stores
 *
 * @returns {Object}
 */
export function loadStores() {
  return {
    type: ActionTypes.LOAD_STORES,
  }
}
