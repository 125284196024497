import { all, call, put, takeLatest, select } from 'redux-saga/effects'
import { modelActionCreators } from 'mti-jsclient-shared'
import { tokenSelector } from '../selectors'
import { ActionTypes } from '../constants'
import {
  postStoreAction as postAction,
  storeLockdown as lockdown,
} from '../../../api'
import { errorToast, successToast } from '../../../utils/utils'

export function* postStoreAction({ payload }) {
  const { id, action } = payload
  const token = yield select(tokenSelector)
  try {
    yield call(postAction, token, id, action)
    successToast(`Store action ${action}`)
  } catch (error) {
    errorToast('Store action failed')
  }
}

export function* storeLockdown({ payload }) {
  const { id, action } = payload
  const token = yield select(tokenSelector)
  try {
    const data = yield call(lockdown, token, id, action)
    yield put(modelActionCreators.upsertStore(data.stores[0]))
    successToast(`Store ${action} mode`)
  } catch (error) {
    errorToast('Store lockdown failed')
  }
}

export default function* root() {
  yield all([
    yield takeLatest(ActionTypes.POST_STORE_ACTION, postStoreAction),
    yield takeLatest(ActionTypes.STORE_LOCKDOWN, storeLockdown),
  ])
}
