/**
 *
 * Radio
 *
 */

import React from 'react'
import PropTypes from 'prop-types'

class Radio extends React.PureComponent {
  componentDidUpdate() {
    const { checked } = this.props
    this.input.checked = checked
  }

  render() {
    const { id, name, label, onChange, checked, disabled } = this.props

    return (
      <div className="radio">
        <label htmlFor={id} style={!label ? { marginBottom: '.9em' } : null}>
          <input
            id={id}
            name={name}
            type="radio"
            disabled={disabled}
            onChange={onChange}
            defaultChecked={checked}
            ref={(i) => {
              this.input = i
            }}
          />
          <span
            className="bmd-radio"
            style={!label ? { margin: 0, padding: 0 } : null}
          />
          {label}
        </label>
      </div>
    )
  }
}

Radio.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default Radio
