import React from 'react'
import styled from 'styled-components'
import {
  CheckboxAndInputContainer,
  CheckboxContainer,
} from '../../containers/TemplateFixture/index.screen'
import Checkbox from '../Checkbox'
import { Container } from '../GroupAdornment'
import PropTypes from 'prop-types'

export const CheckboxInputContainer = styled.div`
  ${(props) => (props.editable ? 'height: 59px;' : 'height: 56px;')};
`
const CustomContainer = styled(Container)`
  height: 100%;

  ${(props) => props.disabled && 'border-bottom-color: #bbb;'} input {
    background: none !important;
    text-align: center;
    margin-bottom: 3px;
  }
  label {
    border: none;
  }
  .editDisabled {
    label {
      ${(props) => props.disabled && 'margin-bottom: 6px !important'};
    }
  }
  select.form-control {
    background-image: none;
  }
`

class BackupKeysForStore extends React.PureComponent {
  onChange = ({ target: { checked } }) => {
    this.setState({ isOptionsVisible: checked })
    this.props.onChange(checked)
  }

  render() {
    const { disabled, checked, name } = this.props
    return (
      <CustomContainer disabled={disabled}>
        <CheckboxAndInputContainer>
          <CheckboxContainer>
            <Checkbox
              name={name}
              onChange={this.onChange}
              checked={checked}
              disabled={disabled}
            />
          </CheckboxContainer>
          <span>Allow Backup Keys for this Store?</span>
        </CheckboxAndInputContainer>
      </CustomContainer>
    )
  }
}

BackupKeysForStore.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
}

export default BackupKeysForStore
