/**
 *
 * SidebarTitle
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { TitlePanel, Text } from '../Title'
import excerpt, { excerptStr } from '../../utils/excerpt'

export const Controls = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  flex-shrink: 0;
`

const Title = styled(Text)`
  width: 150px;
  ${excerptStr(16, 24, 2)};
`

class SidebarTitle extends React.PureComponent {
  titleRef = null

  componentWillUnmount() {
    if (this.titleRef && this.titleRef.popover) {
      this.titleRef.popover('dispose')
    }
  }

  handleClick = (e) => {
    const { callback } = this.props
    if (callback && typeof callback === 'function') {
      e.preventDefault()
      callback()
    }
  }

  popoverForLongText = (ref, msg) => {
    this.titleRef = $(ref) // eslint-disable-line

    this.titleRef.popover('dispose').popover({
      placement: 'left',
      trigger: 'hover focus',
      html: true,
      content: msg,
    })
  }

  render() {
    const { title, titleTo, children } = this.props

    return (
      <TitlePanel>
        {titleTo ? (
          <NavLink
            onClick={this.handleClick}
            className="nav-link"
            exact
            to={titleTo}
            style={Object.assign(
              { padding: 0, width: '150px' },
              excerpt(16, 24, 2)
            )}
          >
            <span
              name={'sidebarTitle'}
              ref={(ref) => {
                this.popoverForLongText(ref, title)
              }}
            >
              {title}
            </span>
          </NavLink>
        ) : (
          title && (
            <Title
              ref={(ref) => {
                this.popoverForLongText(ref, title)
              }}
              onClick={this.handleClick}
              name={'sidebarTitle'}
            >
              {title}
            </Title>
          )
        )}
        <Controls>{children}</Controls>
      </TitlePanel>
    )
  }
}

SidebarTitle.propTypes = {
  title: PropTypes.string,
  titleTo: PropTypes.string,
  children: PropTypes.any,
}

export default SidebarTitle
