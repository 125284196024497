/**
 *
 * ProgressIndicator
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { PropagateLoader } from 'react-spinners'

const CenterContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  z-index: 1;
`

function ProgressIndicator({ cancelText, onCancel }) {
  return (
    <CenterContainer>
      <div style={{ marginTop: '-5px' }}>
        <PropagateLoader size={12} color={'#4E92DF'} />
      </div>
      {onCancel && (
        <React.Fragment>
          <br />
          <button type="button" className="btn btn-primary" onClick={onCancel}>
            {cancelText}
          </button>
        </React.Fragment>
      )}
    </CenterContainer>
  )
}

ProgressIndicator.propTypes = {
  text: PropTypes.string,
  cancelText: PropTypes.string,
  onCancel: PropTypes.func,
}

export default ProgressIndicator
