import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { selectRoleResourceNames } from './selectors'

class UserRole extends React.PureComponent {
  render() {
    return (
      <>
      <h4><FormattedMessage id={`roles.${this.props.roleKey}`} /></h4>
        {this.props.resourceIDs ? (
          <h6>{this.props.resourceNames.join(', ')}</h6>
        ) : (
          null
        )}
      </>
    )
  }
}

UserRole.propTypes = {
  resourceType: PropTypes.string, // Organization, Region, Store
  roleKey: PropTypes.string, // regional_manager, organization_admin, etc
  resourceIDs: PropTypes.array, // the ids of the specific resources so we can find the name of them combined with the resourceType
  resourceNames: PropTypes.array, // the names of the orgs, regions or stores the user roles are tied to
}

const mapStateToProps = (state, ownProps) => ({
  resourceNames: selectRoleResourceNames(state, ownProps.resourceType, ownProps.resourceIDs)
})

export default connect(mapStateToProps)(UserRole)
