import { ActionTypes } from '../App/constants'

/**
 * Fetch Users Failed
 *
 * @returns {Object}
 */
export function fetchUsersFailed(error) {
  return {
    type: ActionTypes.FETCH_USERS_FAILED,
    payload: error,
  }
}

/**
 * Fetch Users Fulfilled
 *
 * @returns {Object}
 */
export function fetchUsersFulfilled(data) {
  return {
    type: ActionTypes.FETCH_USERS_FULFILLED,
    payload: {
      users: data,
    },
  }
}

/**
 * Fetch Users Pending
 *
 * @returns {Object}
 */
export function fetchUsersPending() {
  return {
    type: ActionTypes.FETCH_USERS_PENDING,
  }
}

/**
 * Load Users
 *
 * @returns {Object}
 */
export function loadUsers(withInactive = false, query = {}) {
  return {
    type: ActionTypes.LOAD_USERS,
    payload: { withInactive, query },
  }
}
