import { Record, List, fromJS } from 'immutable'
import moment from 'moment'
import { takeLatest, select, put, call, all } from 'redux-saga/effects'
import { selectToken, selectOrganizationID } from '../../containers/App/selectors'
import {
  getDeviceConfigurationsForOrg,
  getDeviceConfigurationsForRegion,
  getDeviceConfigurationsForStore,
  updateDeviceConfiguration,
} from '../../api'

/**
 * Immutable Record for DeviceConfigurations.
 */
class DeviceConfiguration extends (new Record({
  id: null,
  createdAt: null,
  updatedAt: null,
  deviceType: null,
  firmwareVersion: null,
  resourceId: null,
  resourceType: null,
  deviceConfigurationParameters: [],
})) {
  /* JSON from the API has side-loaded parameters.
   * Pass the object as the first argument, and the
   * parameters array as the second.
   */
  static fromAPI(objJs, paramsJs) {
    const dcp = paramsJs
      .filter((p) => objJs.deviceConfigurationParameterIds.includes(p.id))
      .sort((l, r) => l.id - r.id)
      .map((p) => new DeviceConfigurationParameter.fromAPI(p))
    return new DeviceConfiguration({
      ...objJs,
      createdAt: moment(objJs.createdAt)
        .utcOffset(0)
        .format('MM/DD/YYYY'),
      updatedAt: moment(objJs.updatedAt)
        .utcOffset(0)
        .format('MM/DD/YYYY'),
      deviceConfigurationParameters: List(dcp),
    })
  }

  static listFromAPI(js) {
    if (typeof js.deviceConfigurations === 'undefined') return List()
    const params = js.deviceConfigurationParameters
    return List(
      js.deviceConfigurations.map((objJs) =>
        DeviceConfiguration.fromAPI(objJs, params)
      )
    )
  }

  static reducer(
    state = fromJS({ status: 'PENDING', list: [], message: '' }),
    { type, payload }
  ) {
    const acts = DeviceConfiguration.actionTypes
    switch (type) {
      case acts.LOAD_DEVICE_CONFIGURATIONS_FULFILLED:
        return state
          .set('status', 'READY')
          .set('list', DeviceConfiguration.listFromAPI(payload))
      case acts.LOAD_DEVICE_CONFIGURATIONS_PENDING:
      case acts.UPDATE_DEVICE_CONFIGURATION_PENDING:
        return state.set('status', 'PENDING')
      case acts.LOAD_DEVICE_CONFIGURATIONS_FAILED:
      case acts.UPDATE_DEVICE_CONFIGURATION_FAILED:
        return state.set('status', 'FAILED').set('message', payload)
      case acts.UPDATE_DEVICE_CONFIGURATION_FULFILLED:
        return state.set('status', 'READY').set(
          'list',
          state
            // Copy existing confs
            .get('list')
            .filter((dc) => dc.id !== payload.deviceConfigurations[0].id)
            // Convert returned JS to model and append
            .push(
              DeviceConfiguration.fromAPI(
                payload.deviceConfigurations[0],
                payload.deviceConfigurationParameters
              )
            )
        )
      default:
        return state
    }
  }

  static *loadDeviceConfigsSaga({ payload }) {
    const { resourceType, resourceId } = payload
    yield put(DeviceConfiguration.actionMakers.loadDevConfsPending())

    const apiMethod = {
      Organization: getDeviceConfigurationsForOrg,
      Region: getDeviceConfigurationsForRegion,
      Store: getDeviceConfigurationsForStore,
    }[resourceType]

    const token = yield select(selectToken)
    const orgID = yield select(selectOrganizationID)
    try {
      const jsResponse = yield call(apiMethod, token, resourceId, orgID)
      yield put(
        DeviceConfiguration.actionMakers.loadDevConfsFulfilled(jsResponse)
      )
    } catch (error) {
      yield put(
        DeviceConfiguration.actionMakers.loadDevConfsFailed(error.toString())
      )
    }
  }

  static *updateDeviceConfigSaga({ payload }) {
    const devConf = payload
    yield put(DeviceConfiguration.actionMakers.updateDevConfPending())
    const token = yield select(selectToken)
    const data = {
      device_configuration: {
        device_configuration_parameters: devConf.deviceConfigurationParameters.map(
          (dcp) => ({
            id: dcp.id,
            value: dcp.value,
            override: dcp.override,
          })
        ),
      },
    }
    try {
      const response = yield call(
        updateDeviceConfiguration,
        token,
        devConf.id,
        data
      )
      yield put(
        DeviceConfiguration.actionMakers.updateDevConfFulfilled(response)
      )
    } catch (e) {
      yield put(
        DeviceConfiguration.actionMakers.updateDevConfFailed(e.toString())
      )
    }
  }

  static *saga() {
    yield all([
      yield takeLatest(
        DeviceConfiguration.actionTypes.LOAD_DEVICE_CONFIGURATIONS,
        DeviceConfiguration.loadDeviceConfigsSaga
      ),
      yield takeLatest(
        DeviceConfiguration.actionTypes.UPDATE_DEVICE_CONFIGURATION,
        DeviceConfiguration.updateDeviceConfigSaga
      ),
    ])
  }

  /** Sets a parameter value.
   * @param {string} name - the name of the parameter to change.
   * @param {number} value - the new value of the parameter.
   * @return {DeviceConfiguration} because this class is immutable,
   *   a brand new record is returned. Users must take care to replace
   *   their local copy with the returned value.
   */
  setParam(name, value) {
    const oldParam = this.deviceConfigurationParameters.find(
      (p) => p.name == name
    )
    if (typeof oldParam === 'undefined') return this
    const newParam = oldParam.set('value', value)
    return this.set(
      'deviceConfigurationParameters',
      List(
        this.deviceConfigurationParameters.map((param) => {
          if (param.name == name) return newParam
          return param
        })
      )
    )
  }

  /** Sets a parameter override.
   * @param {string} name - the name of the parameter to change.
   * @param {boolean} override - the new value of the override field
   * @return {DeviceConfiguration} because this class is immutable,
   *   a brand new record is returned. Users must take care to replace
   *   their local copy with the returned value.
   */
  overrideParam(name, override) {
    const oldParam = this.deviceConfigurationParameters.find(
      (p) => p.name == name
    )
    if (typeof oldParam === 'undefined') return this
    const value = override ? oldParam.value : oldParam.parentValue
    const newParam = oldParam.set('override', override).set('value', value)
    return this.set(
      'deviceConfigurationParameters',
      List(
        this.deviceConfigurationParameters.map((param) => {
          if (param.name == name) return newParam
          return param
        })
      )
    )
  }

  editFormId() {
    if (typeof this._editFormId === 'undefined') {
      this._editFormId = `edit-device-configuration-${this.id}`
    }
    return this._editFormId
  }
}

const acts = (DeviceConfiguration.actionTypes = {
  LOAD_DEVICE_CONFIGURATIONS: 'LOAD_DEVICE_CONFIGURATIONS',
  LOAD_DEVICE_CONFIGURATIONS_PENDING: 'LOAD_DEVICE_CONFIGURATION_PENDING',
  LOAD_DEVICE_CONFIGURATIONS_FAILED: 'LOAD_DEVICE_CONFIGURATION_FAILED',
  LOAD_DEVICE_CONFIGURATIONS_FULFILLED: 'LOAD_DEVICE_CONFIGURATION_FULFILLED',
  UPDATE_DEVICE_CONFIGURATION: 'UPDATE_DEVICE_CONFIGURATION',
  UPDATE_DEVICE_CONFIGURATION_PENDING: 'UPDATE_DEVICE_CONFIGURATION_PENDING',
  UPDATE_DEVICE_CONFIGURATION_FAILED: 'UPDATE_DEVICE_CONFIGURATION_FAILED',
  UPDATE_DEVICE_CONFIGURATION_FULFILLED:
    'UPDATE_DEVICE_CONFIGURATION_FULFILLED',
})

DeviceConfiguration.actionMakers = {
  loadDevConfs: (resourceType, resourceId) => ({
    type: acts.LOAD_DEVICE_CONFIGURATIONS,
    payload: { resourceType, resourceId },
  }),
  loadDevConfsPending: () => ({
    type: acts.LOAD_DEVICE_CONFIGURATIONS_PENDING,
    payload: null,
  }),
  loadDevConfsFailed: (msg) => ({
    type: acts.LOAD_DEVICE_CONFIGURATIONS_FAILED,
    payload: msg,
  }),
  loadDevConfsFulfilled: (js) => ({
    type: acts.LOAD_DEVICE_CONFIGURATIONS_FULFILLED,
    payload: js,
  }),
  updateDevConf: (dc) => ({
    type: acts.UPDATE_DEVICE_CONFIGURATION,
    payload: dc,
  }),
  updateDevConfPending: () => ({
    type: acts.UPDATE_DEVICE_CONFIGURATION_PENDING,
    payload: null,
  }),
  updateDevConfFailed: (msg) => ({
    type: acts.UPDATE_DEVICE_CONFIGURATION_FAILED,
    payload: msg,
  }),
  updateDevConfFulfilled: (js) => ({
    type: acts.UPDATE_DEVICE_CONFIGURATION_FULFILLED,
    payload: js,
  }),
}

DeviceConfiguration.resourceTypes = {
  ORGANIZATION: 'Organization',
  REGION: 'Region',
  STORE: 'Store',
}

/**
 * Immutable Record for DeviceConfigurationParameter
 */
class DeviceConfigurationParameter extends (new Record({
  id: null,
  name: null,
  value: null,
  parentValue: null,
  dataType: 'integer',
  override: false,
  createdAt: null,
  updatedAt: null,
})) {
  static fromAPI(objJs) {
    return new DeviceConfigurationParameter({
      ...objJs,
      createdAt: moment(objJs.createdAt)
        .utcOffset(0)
        .format('MM/DD/YYYY'),
      updatedAt: moment(objJs.updatedAt)
        .utcOffset(0)
        .format('MM/DD/YYYY'),
    })
  }
}

export { DeviceConfiguration, DeviceConfigurationParameter }
