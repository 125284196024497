/*
 *
 * FloorCanvas reducer
 *
 */

import { fromJS } from 'immutable'
import { ActionTypes } from './constants'

const initialState = fromJS({
  selectedId: -1,
  canvasObject: {
    objects: [],
    background: '',
  },
  isStatic: true,
})

function floorCanvasReducer(state = initialState, { type, payload }) {
  switch (type) {
    case ActionTypes.STORE_FLOOR_PROTOTYPES:
      return state.set('prototypes', payload)
    case ActionTypes.STORE_PARAMS:
      return state
        .set('screen', payload.screen)
        .set('isStatic', payload.isStatic)
    case ActionTypes.POST_FLOOR_CANVAS_FINISHED:
      return state
        .set('loading', false)
        .set('failed', null)
        .set('isStatic', false)
        .updateIn(['canvasObject', 'objects'], (objects) =>
          objects.update(
            objects.findIndex(
              (item) => item.get('id') === (payload.oldAId || payload.aId)
            ),
            (obj) => {
              if (!obj) return obj
              return obj.set('id', payload.aId || obj.get('id'))
            }
          )
        )
        .updateIn(['canvasObject', 'selectedId'], () => -1)
        .updateIn(['canvasObject', 'selectedIndex'], () => -1)
    //.set('selectedId', -1)
    case ActionTypes.POST_FLOOR_CANVAS_PENDING:
      return state.set('failed', null).set('loading', true)
    case ActionTypes.POST_FLOOR_CANVAS_FAILED:
      return state.set('failed', payload).set('loading', false)
    case ActionTypes.FETCH_FLOOR_CANVAS_FULFILLED: {
      const {
        canvas: {
          canvasObject = {},
          canvasObject: { selectedId: selectedIdentifier } = {},
        } = {},
        floor,
        selectedId: selId,
        isStatic,
      } = payload
      const selectedId =
        selId === undefined || selId === -1 ? selectedIdentifier : selId
      return state
        .set('canvasObject', fromJS(canvasObject))
        .set('selectedId', selectedId)
        .set('floor', floor)
        .set('loading', false)
        .set('failed', null)
        .set('isStatic', isStatic)
    }
    case ActionTypes.FETCH_FLOOR_CANVAS_PENDING:
      return state.set('failed', null).set('loading', true)
    case ActionTypes.FETCH_FLOOR_CANVAS_FAILED:
      return state.set('failed', payload).set('loading', false)
    case ActionTypes.FLOOR_CANVAS_CHANGED: {
      const isStaticPayload = payload.isStatic
      const prevSelectedId = state.get('selectedId')
      const isStatic = !isStaticPayload
        ? isStaticPayload
        : state.get('isStatic')
      // Deselect already selected areas
      const selectedId =
        prevSelectedId === payload.selectedId && isStatic
          ? -1
          : payload.selectedId

      return state
        .set('canvasObject', fromJS(payload.canvasObject))
        .set('selectedId', selectedId)
        .set('isStatic', isStatic)
    }
    case ActionTypes.SAVE_FLOOR_AREA:
      return state
        .updateIn(['canvasObject', 'selectedId'], () => -1)
        .updateIn(['canvasObject', 'selectedIndex'], () => -1)
        .set('selectedId', -1)
    case ActionTypes.FLOOR_CANVAS_AREA_REMOVE:
      return state
        .updateIn(['canvasObject', 'objects'], (objects) =>
          objects.filter((obj) => obj.get('id') !== payload.selectedId)
        )
        .updateIn(['canvasObject', 'selectedId'], () => -1)
        .updateIn(['canvasObject', 'selectedIndex'], () => -1)
        .set('selectedId', -1)
    case ActionTypes.FLOOR_CANVAS_AREA_CHANGED: {
      const opacity = () => (payload.areaLabelHasShown ? 1 : 0)

      return state.updateIn(['canvasObject', 'objects'], (objects) =>
        objects.update(
          objects.findIndex(
            (item) => item.get('id') === state.get('selectedId')
          ),
          (obj) =>
            obj
              .set('displayAreaName', payload.areaLabelHasShown)
              .set('angle', payload.areaAngle)
              .updateIn(['objects'], (items) =>
                items
                  .update(
                    items.findIndex((item) => item.get('name') === 'labelRect'),
                    (item) => item.set('opacity', opacity())
                  )
                  .update(
                    items.findIndex((item) => item.get('name') === 'label'),
                    (item) =>
                      item
                        .set('opacity', opacity())
                        .set('fullText', payload.areaName)
                        .set('text', payload.areaName)
                  )
              )
        )
      )
    }
    default:
      return state
  }
}

export default floorCanvasReducer
