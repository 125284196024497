import React from 'react'
import { figureType } from '../TemplatePositions/utils'
import { sviFigureType } from '../../utils/mtiCanvasUtils'

/**
 * Inherits updates filter for the Linked Fixture react-table
 *
 * @param filter
 * @param row
 * @returns {boolean}
 */
export function fixtureInheritsFilter(filter, row) {
  const cloneable = ((row || {})._original || {}).cloneable // eslint-disable-line
  if (filter.value === 'all') {
    return true
  }
  if (filter.value === 'true') {
    return cloneable === true
  }
  return cloneable === false
}

/**
 * Inherits updates component for filtering of the Fixtures react-table
 *
 * @param filter
 * @method onChange
 * @returns object
 */
export function fixtureInheritsComponent(filter, onChange) {
  return (
    <select
      onChange={(event) => onChange(event.target.value)}
      style={{ width: '100%' }}
      value={filter ? filter.value : 'all'}
    >
      <option value="all">Show All</option>
      <option value="true">YES</option>
      <option value="false">NO</option>
    </select>
  )
}

export function templateShapeComponent(filter, onChange) {
  return (
    <select
      onChange={(event) => onChange(event.target.value)}
      style={{ width: '100%' }}
      value={filter ? filter.value : 'all'}
    >
      <option value="all">Show All</option>
      <option value={sviFigureType.extendedQuarterCircle}>
        {figureType.extendedQuarterCircle}
      </option>
      <option value={sviFigureType.quarterCircle}>
        {figureType.quarterCircle}
      </option>
      <option value={sviFigureType.circle}>{figureType.circle}</option>
      <option value={sviFigureType.custom}>{figureType.custom}</option>
      <option value={sviFigureType.square}>{figureType.square}</option>
      <option value={sviFigureType.narrowRectangle}>
        {figureType.narrowRectangle}
      </option>
      <option value={sviFigureType.wideRectangle}>
        {figureType.wideRectangle}
      </option>
    </select>
  )
}
