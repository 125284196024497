import React from 'react'
import PropTypes from 'prop-types'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { makeSelectLoading, makeSelectStores } from '../../StoresPage/selectors'
import RegionDetailsModalScreen from './index.screen'
import ProgressIndicator from '../../../components/ProgressIndicator'
import RegionModalPreloader from '../../../components/Preloaders/RegionModalPreloader'
import { closeModal, openModal } from '../../App/actions'
import NotifyModal from '../../ModalContainer/NotifyModal'

class RegionDetailsModal extends React.PureComponent {
  /* eslint-disable no-undef, no-shadow */
  onStoreSelected = store => {
    const { id, onStore } = this.props
    $(`#modal-${id}`).modal('hide')
    setTimeout(() => onStore(store), 500)
  }

  onEditManager = () => {
    const { openModal, closeModal, onCloseEditManagers } = this.props
    openModal({
      id: 'edit-region-managers-in-dev',
      type: 'bootstrap',
      appearance: 'modal-sm',
      content: (
        <NotifyModal
          id={'edit-region-managers-in-dev'}
          notifyText="This feature is still in development"
          confirmText={'OK'}
          onClose={() => {
            closeModal({ id: 'edit-region-managers-in-dev' })
            onCloseEditManagers()
          }}
        />
      ),
    })
  }

  render() {
    const {
      id,
      region,
      loading,
      title,
      dangerText,
      confirmText,
      isRegionEditLocked,
      onDanger,
      onConfirm,
      onClose,
    } = this.props
    if (loading) {
      return (
        <div className="modal-content">
          <React.Fragment>
            <RegionModalPreloader regionDetails />
            <ProgressIndicator />
          </React.Fragment>
        </div>
      )
    }

    return (
      <RegionDetailsModalScreen
        id={id}
        region={region}
        title={title}
        dangerText={dangerText}
        confirmText={confirmText}
        onDanger={onDanger}
        onConfirm={onConfirm}
        onClose={onClose}
        onStore={this.onStoreSelected}
        isRegionEditLocked={isRegionEditLocked}
        editManager={this.onEditManager}
      />
    )
  }
}

RegionDetailsModal.propTypes = {
  region: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  isRegionEditLocked: PropTypes.bool,
  dangerText: PropTypes.string,
  confirmText: PropTypes.string,
  onDanger: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
  onStore: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  onCloseEditManagers: PropTypes.func.isRequired,
}

const mapStateToProps = createStructuredSelector({
  loading: makeSelectLoading(),
  stores: makeSelectStores(),
})

const mapDispatchToProps = {
  openModal,
  closeModal,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect)(RegionDetailsModal)
