import { push } from 'react-router-redux'
import { modelActionCreators as mac } from 'mti-jsclient-shared'
import mtiJsclientShared from '../../utils/mtiJsclientShared'
import { all, call, put, takeLatest, select } from 'redux-saga/effects'
import {
  postFixture,
  patchFixture,
  deleteFixture,
  postPosition,
  patchPosition,
  postFixtureRule,
  postPositionRule,
  deleteFixtureRule,
  deletePositionRule,
  getTemplate,
} from '../../api'
import { fetchAndUpsert, upsert } from '../App/sagas/ormSaga'
import { fetchAndUpsertStore, createDefaultFloorAndArea } from '../App/actions'
import { apiFixGeometryResourceId } from '../../utils/mtiUtils'
import {
  loadingType as loading,
  safeUri,
  successToast,
} from '../../utils/utils'
import { tokenSelector, makeSelectOrganizationId } from '../App/selectors'
import {
  makeSelectAreaExist,
  makeSelectArea,
  makeSelectFixturesWithLayoutExist,
  makeSelectSelectedTemplate,
  makeSelectPosition,
  fixtureToRemoveRulesSelector,
  makeSelectFixtureTemplateFromApp,
  makeSelectFloorName,
  makeSelectAreaName,
} from './selectors'
import { ActionTypes } from './constants'
import {
  storePrototypes,
  storeParams,
  //postAreaCanvasPending,
  postAreaCanvasFinished,
  postAreaCanvasFailed,
  fetchAreaCanvasPending,
  fetchAreaCanvasFulfilled,
  fetchAreaCanvasFailed,
  setLoading,
  loadFixtureTemplatePending,
  loadFixtureTemplateFulfilled,
  loadFixtureTemplateFailed,
} from './actions'
import { toFabricSync, getPrototypes, getFixtureObject } from './utils'
import { fromFabricFixtureSave, addMtiType } from './utilsSave'

export function* loadArea({ payload }) {
  const { screen, isStatic, selectedId, sId, fId, aId } = payload

  try {
    console.log(sId, fId, aId)
    const prototypes = yield getPrototypes()
    yield put(storePrototypes(prototypes))
    yield put(setLoading(loading.silent))
    yield put(storeParams({ screen, isStatic }))
    const isAreaExist = yield select(makeSelectAreaExist(aId))
    // TODO: hacky flag to distinguish if background fetch update can be executed
    // It based on assumption that fixtures for showing should have layout positions
    // it allows us to reduce amount of background fetches, please do not hesitate to improve / remove it.
    const hasFixturesWithLayout = yield select(
      makeSelectFixturesWithLayoutExist(aId)
    )

    if (!isAreaExist) {
      yield put(setLoading(loading.visible))
      yield put(fetchAreaCanvasPending(sId))
      yield call(fetchAndUpsert, { payload: { sId } })
    }

    const area = yield select(makeSelectArea(aId))

    yield put(
      fetchAreaCanvasFulfilled(
        sId,
        toFabricSync(
          area,
          screen,
          isStatic,
          false,
          selectedId,
          prototypes,
          mtiJsclientShared
        ),
        selectedId,
        isStatic
      )
    )

    yield put(setLoading(loading.off))
    console.log(hasFixturesWithLayout)
    /* if (isAreaExist && isStatic && !hasFixturesWithLayout) {
      yield put(fetchAndUpsertStore(sId))
    } */
    if (isAreaExist && isStatic) {
      yield call(fetchAndUpsert, { payload: { sId } })
    }
    //
    yield put(createDefaultFloorAndArea(sId))
  } catch (error) {
    console.log(error)
    const res = error.response
    const errorObj = res ? yield call([res, res.json]) : error
    yield put(fetchAreaCanvasFailed(errorObj))
    yield put(setLoading(loading.off))
  }
}

function* saveFixture({ payload }) {
  const { canvasObject: { objects = [] }, selectedId } = payload
  const fixture = objects.find(({ id }) => id === selectedId)
  if (!fixture) {
    console.error('saveFixture: No Fixture')
    return
  }
  if (fixture.parentId && isNaN(fixture.id)) {
    yield call(saveFixtureFromTemplate, { payload })
  } else {
    yield call(saveFixtureAlone, { payload })
  }
}

export function* saveFixtureFromTemplate({ payload }) {
  const {
    canvasObject: { objects = [] },
    sId,
    fId,
    aId,
    selectedId,
    screen,
  } = payload
  const token = yield select(tokenSelector)
  try {
    // yield put(setLoading(loading.visible))
    // yield put(postAreaCanvasPending(sId))
    const fixtureFromFabric = fromFabricFixtureSave(
      addMtiType(objects.find(({ id }) => id === selectedId)),
      screen
    )
    if (!fixtureFromFabric) {
      console.error('saveFixtureFromTemplate: No Fixture')
      return
    }
    const orgId = yield select(makeSelectOrganizationId())
    const selectedTemplate = yield select(makeSelectSelectedTemplate())
    const fixture = getFixtureObject(
      sId,
      fId,
      aId,
      fixtureFromFabric,
      orgId,
      selectedTemplate
    )

    // Save Fixture
    let data = yield call(postFixture, token, fixture, sId, fId, aId)
    fixture.oldId = fixture.id
    fixture.id = data.fixtures[0].id

    // Sanitize geometry resource IDs.
    data = apiFixGeometryResourceId(data, 'fixtures')

    yield call(upsert, { payload: data })
    successToast('Fixture Saved from Template')

    yield put(
      postAreaCanvasFinished({ sId, fxId: fixture.id, oldFId: fixture.oldId })
    )
    // yield put(setLoading(loading.off))
  } catch (error) {
    console.log(error)
    const res = error.response
    const errorObj = res ? yield call([res, res.json]) : error
    yield put(postAreaCanvasFailed(errorObj))
    // yield put(setLoading(loading.off))
  }
}

export function* saveFixtureAlone({ payload }) {
  const {
    canvasObject: { objects = [] },
    sId,
    fId,
    aId,
    selectedId,
    screen,
  } = payload
  const token = yield select(tokenSelector)
  const fixtureToRemoveRules = yield select(fixtureToRemoveRulesSelector)

  try {
    // yield put(setLoading(loading.visible))
    // yield put(postAreaCanvasPending(sId))
    const fixture = fromFabricFixtureSave(
      addMtiType(objects.find(({ id }) => id === selectedId)),
      screen
    )
    if (!fixture) {
      console.error('saveFixtureAlone: No Fixture')
      return
    }
    if (fixtureToRemoveRules) {
      const { rules, id: fixtureId } = fixtureToRemoveRules
      for (let i = 0; i < rules.length; i++) {
        const rule = rules[i]
        yield call(deleteFixtureRule, token, fixtureId, rule.id)
        yield put(mac.deleteRule(rule.id))
      }
      const { positions } = fixtureToRemoveRules
      for (let i = 0; i < positions.length; i++) {
        const { rules, id: positionId } = positions[i]
        for (let j = 0; j < rules.length; j++) {
          const rule = rules[j]
          yield call(deletePositionRule, token, positionId, rule.id)
          yield put(mac.deleteRule(rule.id))
        }
      }
    }
    let data
    if (isNaN(fixture.id)) {
      data = yield call(postFixture, token, fixture, sId, fId, aId)
      fixture.oldId = fixture.id
      fixture.id = data.fixtures[0].id
      successToast('Fixture Created')
    } else {
      data = yield call(patchFixture, token, fixture, sId, fId, aId)
      successToast('Fixture Updated')
    }
    yield call(upsert, { payload: { ...data, stores: undefined } }) // undefine store, cause it doesn't have all the data

    yield put(
      postAreaCanvasFinished({ sId, fxId: fixture.id, oldFxId: fixture.oldId })
    )
    // yield put(setLoading(loading.off))
    //
    yield call(redirectMoveFixtureToAnotherArea, {
      payload: { ...payload, fxId: fixture.id, fxName: fixture.name },
    })
  } catch (error) {
    console.log(error)
    const res = error.response
    const errorObj = res ? yield call([res, res.json]) : error
    yield put(postAreaCanvasFailed(errorObj))
    // yield put(setLoading(loading.off))
  }
}

export function* removeFixture({ payload }) {
  const { canvasObject: { objects = [] }, sId, selectedId } = payload
  const fixture = objects.find(({ id }) => id === selectedId)
  const token = yield select(tokenSelector)
  try {
    if (!isNaN(fixture.id)) {
      // yield put(postAreaCanvasPending(sId))
      const data = yield call(deleteFixture, token, fixture)
      successToast('Fixture Deleted')
      yield put(mac.deleteFixtureWithId(fixture.id))
      yield call(upsert, { payload: data })
      yield put(postAreaCanvasFinished(sId, data))
      yield put(fetchAndUpsertStore(sId, true)) // TODO: remove after api will return full store object
    }
  } catch (error) {
    console.log(error)
    const res = error.response
    const errorObj = res ? yield call([res, res.json]) : error
    yield put(postAreaCanvasFailed(errorObj))
  }
}

export function* savePosition(p, fxId) {
  const token = yield select(tokenSelector)
  let store
  const position = p
  store = yield call(postPosition, token, position, fxId)
  position.id = store.positions[0].id
  store = yield call(patchPosition, token, position, fxId) // TODO: temporary
  yield call(upsert, { payload: store })
}

export function* saveAllPositions(objects, fxId) {
  yield all((objects || []).map(object => call(savePosition, object, fxId)))
}

export function* saveStoredPosition(pId, fxId) {
  const token = yield select(tokenSelector)
  const positionStored = yield select(makeSelectPosition(pId))
  const { id, layoutPosition } = positionStored
  const position = { ...positionStored, ...layoutPosition, parentId: id }
  const store = yield call(postPosition, token, position, fxId)
  console.log('postPosition response', store)
  // Save Position Rules
  yield all(
    (position.rules || []).map(rule =>
      call(savePositionRule, rule, (store.positions || [{}])[0].id)
    )
  )
}

export function* saveFixtureRule(rule, fxId) {
  const token = yield select(tokenSelector)
  const data = yield call(postFixtureRule, token, fxId, rule)
  // yield call(upsert, { payload: data }) // TODO: uncomment when fixture in response will have full data
  console.log('postFixtureRule response', data)
}

export function* savePositionRule(rule, pId) {
  const token = yield select(tokenSelector)
  const data = yield call(postPositionRule, token, pId, rule)
  // yield call(upsert, { payload: data }) // TODO: uncomment when position in response will have full data
  console.log('postPositionRule response', data)
}

export function* loadFixtureTemplate({ payload }) {
  const { templateId } = payload
  try {
    const token = yield select(tokenSelector)
    const storedTemplate = yield select(makeSelectFixtureTemplateFromApp())
    if (storedTemplate) {
      yield put(loadFixtureTemplateFulfilled(storedTemplate))
    } else {
      yield put(loadFixtureTemplatePending())
      const data = yield call(getTemplate, token, templateId, 'fixture')
      const template = data.fixtures[0]
      yield put(loadFixtureTemplateFulfilled(template))
    }
  } catch (error) {
    console.log(error)
    const res = error.response
    yield put(loadFixtureTemplateFailed(res))
  }
}

// Redirect for Move Fixture to another Area feature
export function* redirectMoveFixtureToAnotherArea({ payload }) {
  const { fId, aId, fxId, fxName } = payload
  let path = window.location.pathname
  const pathParams = path.split('/') || []
  const realFId = pathParams[5]
  const realAId = pathParams[8]
  if (aId === realAId) return
  const realFName = yield select(makeSelectFloorName(realFId))
  const realAName = yield select(makeSelectAreaName(realAId))
  const fName = yield select(makeSelectFloorName(fId))
  const aName = yield select(makeSelectAreaName(aId))
  path = path.replace(
    `floors/${realFId}/${safeUri(realFName)}`,
    `floors/${fId}/${safeUri(fName)}`
  )
  path = path.replace(
    `areas/${realAId}/${safeUri(realAName)}`,
    `areas/${aId}/${safeUri(aName)}`
  )
  path = `${path}?fixtureId=${fxId}&fixtureName=${safeUri(fxName)}`
  yield put(push(path))
}

/**
 * Area Sagas
 */
export default function* root() {
  yield all([
    // yield takeLatest(ActionTypes.SAVE_AREA_CANVAS, saveArea),
    yield takeLatest(ActionTypes.SAVE_AREA_FIXTURE, saveFixture),
    yield takeLatest(ActionTypes.AREA_CANVAS_FIXTURE_REMOVE, removeFixture),
    yield takeLatest(ActionTypes.LOAD_FIXTURE_TEMPLATE, loadFixtureTemplate),
  ])
}
