import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import {
  loadFirmwares,
  uploadFirmware,
  deleteFirmware,
  openModal,
  closeModal,
} from '../App/actions'
import FirmwaresListScreen from './index.screen'
import FirmwaresHomeList from './firmwaresHomeList'
import {
  makeSelectFirmwares,
  makeSelectFetchFailed,
  makeSelectFetchLoading,
  makeSelectUploadFailed,
  makeSelectDeleteFailed,
} from './selectors'
import ProgressIndicator from '../../components/ProgressIndicator'
import DeleteFirmwareWarningModal from './DeleteFirmwareWarningModal'

class FirmwaresPage extends React.Component {
  componentDidMount() {
    this.props.loadFirmwares()
  }

  /* eslint-disable no-undef */
  onDeleteFirmware = (firmware) => {
    const {
      openModal: doOpenModal,
      closeModal: doCloseModal,
      deleteFirmware: doDeleteFirmware,
    } = this.props
    const id = 'delete-warning'

    doOpenModal({
      id,
      type: 'bootstrap',
      content: (
        <DeleteFirmwareWarningModal
          id={id}
          firmwareName={firmware.name}
          onCancel={() => doCloseModal({ id })}
          onDelete={() => {
            doCloseModal({ id })
            doDeleteFirmware(firmware)
          }}
        />
      ),
    })
  }

  handleselectedFile = ({ target: { files } }) => {
    if (files.length < 1) return
    this.props.uploadFirmware(files[0])
  }

  render() {
    const {
      history,
      firmwares,
      isOTAHome,
      height,
      fetchLoading,
      fetchFailed,
      deleteFailed,
      uploadFailed,
    } = this.props
    if (fetchLoading) return <ProgressIndicator text={'Loading...'} />
    if (fetchFailed) {
      return <div>{`Fetch Error: ${JSON.stringify(fetchFailed)}`}</div>
    }
    if (deleteFailed) {
      return <div>{`Delete Error: ${JSON.stringify(deleteFailed)}`}</div>
    }
    if (uploadFailed) {
      return <div>{`Upload Error: ${JSON.stringify(uploadFailed)}`}</div>
    }
    if (isOTAHome) {
      return (
        <FirmwaresHomeList
          height={height}
          firmwares={firmwares}
          onFirmwareSelection={(d) => console.log(d)}
          onUploadNewFirmwareFile={this.handleselectedFile}
          onSeeAll={() => history.push('/admin/ota/firmwares')}
        />
      )
    }

    return (
      <FirmwaresListScreen
        firmwares={firmwares}
        onFirmwareSelection={(d) => console.log(d)}
        onUploadNewFirmwareFile={this.handleselectedFile}
        onDeleteFirmware={this.onDeleteFirmware}
      />
    )
  }
}

FirmwaresPage.propTypes = {
  history: PropTypes.object.isRequired,
  height: PropTypes.number,
  isOTAHome: PropTypes.bool,
  loadFirmwares: PropTypes.func,
  firmwares: PropTypes.array,
  fetchLoading: PropTypes.bool,
  fetchFailed: PropTypes.object,
  uploadFirmware: PropTypes.func,
  deleteFirmware: PropTypes.func,
  uploadFailed: PropTypes.object,
  deleteFailed: PropTypes.object,
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
}

const mapStateToProps = createStructuredSelector({
  firmwares: makeSelectFirmwares(),
  fetchLoading: makeSelectFetchLoading(),
  fetchFailed: makeSelectFetchFailed(),
  uploadFailed: makeSelectUploadFailed(),
  deleteFailed: makeSelectDeleteFailed(),
})

const mapDispatchToProps = {
  loadFirmwares,
  uploadFirmware,
  deleteFirmware,
  openModal,
  closeModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(FirmwaresPage)
