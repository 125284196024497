import { call, all, put, select, takeEvery } from 'redux-saga/effects'
import { batchActions } from 'redux-batched-actions'
import { modelActionCreators as mac } from 'mti-jsclient-shared'
import { getStoreById, getPosition } from '../../../api'
import {
  fetchAndUpsertStorePending,
  fetchAndUpsertStoreFulfilled,
  fetchAndUpsertStoreFailed,
  fetchAndUpsertGeometryPending,
  fetchAndUpsertGeometryFulfilled,
  fetchAndUpsertGeometryFailed,
  storeImages,
} from '../actions'
import {
  makeSelectToken,
  makeSelectFetchAndUpsertStoreLoading,
  makeSelectFetchAndUpsertGeometryLoading,
} from '../selectors'
import { ActionTypes } from '../constants'
import {
  mutateStore,
  filterLayoutPositionGeometry,
  apiFixGeometryResourceId,
} from '../../../utils/mtiUtils'
import { errorToast } from '../../../utils/utils'

export function* fetchAndUpsert({ payload }) {
  const { sId, force } = payload
  const isFetchStoreAndUpsertPending = yield select(
    makeSelectFetchAndUpsertStoreLoading(sId)
  )
  if (isFetchStoreAndUpsertPending && !force) return
  const token = yield select(makeSelectToken())
  try {
    yield put(fetchAndUpsertStorePending(sId))
    const store = yield call(getStoreById, token, sId)
    console.warn('store >>>> ', store)
    yield put(fetchAndUpsertStoreFulfilled(sId))
    // Inject in orm
    yield call(upsert, { payload: store })
  } catch (e) {
    console.error(e)
    yield put(fetchAndUpsertStoreFailed(sId, e))
  }
}

export function* upsert({ payload }) {
  try {
    const store = mutateStore(payload)

    const {
      images = [],
      stores = [],
      floors = [],
      areas = [],
      fixtures = [],
      positions = [],
      rules = [],
      areaLayoutPositions = [],
      fixtureLayoutPositions = [],
      positionLayoutPositions = [],
      layoutPositions = [],
      securityDevices = [],
      securedProducts = [],
      products = [],
      manufacturers = [],
      addresses = [],
    } = store

    yield all(
      batchActions([
        put(storeImages(images)),
        // Inject in orm
        put(mac.upsertStores(stores)),
        put(mac.upsertFloors(floors)),
        put(mac.upsertAreas(areas)),
        put(mac.upsertFixtures(fixtures)),
        put(mac.upsertPositions(positions)),
        put(mac.upsertRules(rules)),
        put(mac.upsertAreaLayoutPositions(areaLayoutPositions)),
        put(mac.upsertFixtureLayoutPositions(fixtureLayoutPositions)),
        put(mac.upsertPositionLayoutPositions(positionLayoutPositions)),
        put(mac.upsertLayoutPositions(layoutPositions)),
        put(mac.upsertSecurityDevices(securityDevices)),
        put(mac.upsertSecuredProducts(securedProducts)),
        put(mac.upsertProducts(products)),
        put(mac.upsertManufacturers(manufacturers)),
        put(mac.upsertStoreAddresses(addresses)),
      ])
    )
  } catch (e) {
    console.error(e)
  }
}

export function* fetchGeometryAndUpsert({ payload }) {
  const { sId, force } = payload
  const isFetchGeometryAndUpsertPending = yield select(
    makeSelectFetchAndUpsertGeometryLoading(sId)
  )
  if (isFetchGeometryAndUpsertPending && !force) return
  const token = yield select(makeSelectToken())
  try {
    yield put(fetchAndUpsertGeometryPending(sId))
    const store = yield call(getStoreById, token, sId, [
      'areas.geometry,fixtures.geometry,fixtures.positions.geometry',
    ])
    console.warn('store geometry >>>> ', store)
    yield put(fetchAndUpsertGeometryFulfilled(sId))
    // Inject in orm
    yield call(upsert, { payload: store })
    //
  } catch (e) {
    console.error(e)
    yield put(fetchAndUpsertGeometryFailed(sId, e))
  }
}

export function* upsertOrgManufacturers({ payload }) {
  try {
    const manufacturers = payload
    // Inject in orm
    yield manufacturers && put(mac.upsertManufacturers(manufacturers))
  } catch (e) {
    console.error(e)
  }
}

export function* upsertStoreProducts({ payload }) {
  try {
    const { securityDevices, securedProducts, products } = payload
    // Inject in orm
    yield securityDevices && put(mac.upsertSecurityDevices(securityDevices))
    yield securedProducts && put(mac.upsertSecuredProducts(securedProducts))
    yield products && put(mac.upsertProducts(products))
  } catch (e) {
    console.error(e)
  }
}

export function* updateStoreProducts({ payload }) {
  try {
    const { securityDevices, securedProducts, products } = payload
    // Inject in orm
    yield securityDevices && put(mac.updateSecurityDevices(securityDevices))
    yield securedProducts && put(mac.updateSecuredProducts(securedProducts))
    yield products && put(mac.upsertProducts(products))
  } catch (e) {
    console.error(e)
  }
}

export function* fetchAndUpsertPosition({ payload }) {
  const token = yield select(makeSelectToken())
  try {
    const data = yield call(getPosition, token, payload)
    const store = filterLayoutPositionGeometry(
      apiFixGeometryResourceId(data, 'positions')
    )
    yield call(upsert, { payload: store })
  } catch (error) {
    console.error(error)
    errorToast('Save position failed')
  }
}

export default function* root() {
  yield all([
    takeEvery(ActionTypes.ORM_FETCH_AND_UPSERT_STORE, fetchAndUpsert),
    takeEvery(ActionTypes.ORM_UPSERT, upsert),
    takeEvery(ActionTypes.ORM_UPSERT_MANUFACTURERS, upsertOrgManufacturers),
    takeEvery(ActionTypes.ORM_UPSERT_SECURED_PRODUCTS, upsertStoreProducts),
    takeEvery(ActionTypes.ORM_UPDATE_SECURED_PRODUCTS, updateStoreProducts),
  ])
}
