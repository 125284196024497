import React from 'react'
import PropTypes from 'prop-types'
import { ButtonsContainer } from '../RebootHubModal'
import { ModalBody } from '../../StoresPage/CreateEditStoreModal/index.screen'
import ProgressIndicatorModal from '../../../components/ProgressIndicatorModal'
import ErrorModal from '../../../components/ErrorModal'

const UpdateLodeStatusScreen = ({
  id,
  updateLodeStatus,
  updating,
  updateError,
  onClose,
}) => {
  if (updating) return <ProgressIndicatorModal text="Updating..." />

  if (updateError) {
    return <ErrorModal text={`Update Error: ${updateError.message}`} />
  }
  if (updateLodeStatus === false) {
    return <ErrorModal text="HUB is Offline" />
  }
  if (!updateLodeStatus) return null

  return (
    <div className="modal-content">
      <div className="modal-header border-bottom">
        <h5 className="modal-title" id="exampleModalLongTitle">
          Update Lode Status
        </h5>
        <ButtonsContainer>
          <button
            type="button"
            className="btn btn-primary btn-raised"
            onClick={() => {
              $(`#modal-${id}`).modal('hide') // eslint-disable-line
              onClose()
            }}
          >
            Close
          </button>
        </ButtonsContainer>
      </div>
      <div>
        <ModalBody className="modal-body">
          <div>
            <pre>{updateLodeStatus}</pre>
          </div>
        </ModalBody>
      </div>
    </div>
  )
}

UpdateLodeStatusScreen.propTypes = {
  id: PropTypes.string.isRequired,
  updateLodeStatus: PropTypes.any,
  updating: PropTypes.bool,
  updateError: PropTypes.object,
  onClose: PropTypes.func.isRequired,
}

export default UpdateLodeStatusScreen
