/**
 *
 * FixtureCanvas component
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import { fabric } from 'fabric'
import { debounce } from 'lodash'

import { propsToInclude } from '../../containers/FixturePage/objectUtils'
import { editType } from '../../containers/FixturePage/utilsEditTypes'

import Canvas from '../Canvas'
import { getObjectSync, handleOut, unselectEdit, selectEdit } from './position'
import { handleOver } from './utils'
import { setOverlapMethod } from '../FabricCanvas/utilsOld'
import { restoreCanvasOverlapEnabled } from '../../containers/App/localStorageOptions'
import {
  isFixture,
  incrementalObjectName,
  defaultName,
  onFigureBlinking,
  onClearBlinking,
} from '../../utils/mtiCanvasUtils'

const canvas = new fabric.Canvas()
let preSelectedInstance
let selectedInstance
let updatedInstance

let isDown

class FixtureCanvas extends React.PureComponent {
  /* eslint-disable no-undef, react/sort-comp, no-underscore-dangle */
  onDefault() {
    const { onChanged, size, prototypes, mtiJsclientShared } = this.props
    unselectEdit(
      (updatedInstance || {}).id,
      canvas,
      size,
      prototypes,
      mtiJsclientShared
    )
    preSelectedInstance = undefined
    selectedInstance = undefined
    updatedInstance = undefined
    onChanged({
      canvasObject: canvas.toObject(propsToInclude),
      selectedId: -1,
      isStatic: false,
    })
  }

  isEqual(a, b) {
    if (!a && !b) {
      return true
    } else if (!a && b) {
      return false
    } else if (a && !b) {
      return false
    }
    // position
    if (
      !this.isAlmostEqual(a.left, b.left) ||
      !this.isAlmostEqual(a.top, b.top)
    ) {
      return false
    }
    //
    if (a.name !== b.name) {
      return false
    }
    if (a.deviceType !== b.deviceType) {
      return false
    }

    return true
  }

  isAlmostEqual(a, b) {
    return a < b + 0.01 && a > b - 0.01
  }

  initCanvas = (isEditable) => {
    const isCanvasOverlapEnabled = restoreCanvasOverlapEnabled()
    const {
      onChanged,
      size,
      onMaxPositions,
      onMouseEnter,
      onMouseLeave,
      prototypes,
      mtiJsclientShared,
    } = this.props
    const el = this.canvasContainer.objectsCanvas
    let filledObject
    let numObjectsOnCanvas
    let isAllSaved = false

    canvas.initialize(el, {
      width: size.width,
      height: size.height,
      selection: false,
      backgroundColor: null,
    })

    if (isEditable) {
      // IC for editing selected object
      if ((preSelectedInstance || {}).id !== (selectedInstance || {}).id) {
        preSelectedInstance = selectedInstance
      }
      selectedInstance = canvas.getActiveObject()

      canvas.off('before:selection:cleared')
      canvas.on('before:selection:cleared', () => {
        updatedInstance = canvas.getActiveObject()
      })
      canvas.off('mouse:down')
      canvas.on('mouse:down', (o) => {
        isDown = true
        if ((preSelectedInstance || {}).id !== (selectedInstance || {}).id) {
          preSelectedInstance = selectedInstance
        }
        selectedInstance = canvas.getActiveObject()

        numObjectsOnCanvas = canvas.getObjects().length

        if (!this.isEqual(updatedInstance, preSelectedInstance)) {
          // If there was any changes in selected object
          if (
            (updatedInstance || {}).id === (preSelectedInstance || {}).id &&
            (updatedInstance || {}).id !== (selectedInstance || {}).id
          ) {
            // Edit object that exists
            this.props.onCanvasPositionSave((updatedInstance || {}).id)
            preSelectedInstance = undefined
            isDown = false
            return
          }
        }
        if (
          updatedInstance &&
          (updatedInstance || {}).id !== (selectedInstance || {}).id &&
          isNaN((updatedInstance || {}).id)
        ) {
          // Create new object
          this.props.onCanvasPositionSave((updatedInstance || {}).id)
          updatedInstance = undefined
          isDown = false
          return
        }

        if (selectedInstance) {
          return // Do not initiate new object draw, if there is an active object
        }

        if (o.target && isFixture(o.target.mtiType)) {
          if (this.isExcededMaxPositions()) {
            onMaxPositions()
            isDown = false
            return
          }
          const { canvasObject: { scalingFactor } = {} } = this.props
          const { x, y } = canvas.getPointer(o.e)
          const positionName = incrementalObjectName(
            this.props.canvasObject.objects,
            defaultName.position
          )
          filledObject = getObjectSync(editType.filled, size, x, y, prototypes)
          filledObject.set({
            name: positionName,
            scaleX: filledObject.scaleX * scalingFactor, // depends on locks presence
            scaleY: filledObject.scaleY * scalingFactor,
            selectedForEdit: true,
          })
          canvas.add(filledObject)
          canvas.renderAll()
        }
      })

      canvas.off('mouse:move')
      canvas.on('mouse:move', (o) => {
        if (isDown && filledObject) {
          const { x, y } = canvas.getPointer(o.e)
          filledObject.set({
            originX: 'center',
            originY: 'center',
            left: Math.abs(x),
            top: Math.abs(y),
          })
          canvas.renderAll()
        } else {
          if (!isAllSaved) {
            isAllSaved = true
            let isNewPositions = true
            canvas.getObjects().forEach(({ id, contextType }) => {
              if (contextType === 'position' && !isNaN(id)) {
                isNewPositions = false
              }
            })
            if (canvas.getObjects().length === 1) {
              isNewPositions = false
            }
            if (isNewPositions) {
              console.log('SAVE ALL')
              this.props.onSaveAll()
            }
          }
        }
      })

      // Consider this code if something is going wrong with EDIT <-> VIEW switch
      canvas.off('mouse:over')
      canvas.off('mouse:out')
    } else {
      // Consider this code if something is going wrong with EDIT <-> VIEW switch
      canvas.off('mouse:move')

      canvas.off('mouse:down')
      canvas.on('mouse:down', async (o) => {
        const object = o.target
        if (object && object.contextType === 'position') {
          // unselect if already selected
          const { selectedId } = this.props
          if (selectedId !== -1 && selectedId !== 0) {
            if (
              selectedId === object.id &&
              this.pSelectionObject &&
              `selection-${object.id}` === this.pSelectionObject.id
            ) {
              this.removeSelection()
              canvas.discardActiveObject()
              return
            }
          }
          this.onOut(object.id)
          this.onSelect(object.id)
        } else {
          this.removeSelection()
          /**
           * uncomment to use click and drag functionality to create new position
           * skip for mobile
           */
          // const { selectedObject } = this.props
          // if (o.target && isFixture(o.target.mtiType) && selectedObject < 0) {
          //   if (this.isExcededMaxPositions()) {
          //     onMaxPositions()
          //     return
          //   }
          //   isDown = true
          //   this.disableHover = true
          //   const { x, y } = canvas.getPointer(o.e)
          //   filledObject = await getObject(editType.filled, size, x, y)
          //   canvas.add(filledObject)
          //   canvas.renderAll()
          // }
        }
      })

      /**
       * uncomment to use click and drag functionality to create new position
       * skip for mobile
       */
      //   canvas.off('mouse:move')
      //   canvas.on('mouse:move', (o) => {
      //     if (isDown && filledObject) {
      //       const { x, y } = canvas.getPointer(o.e)
      //       filledObject.set({
      //         originX: 'center',
      //         originY: 'center',
      //         left: Math.abs(x),
      //         top: Math.abs(y),
      //       })
      //       canvas.renderAll()
      //     }
      //   })

      canvas.off('mouse:over')
      canvas.on('mouse:over', (e) => {
        if (this.disableHover) return
        if (e.target && e.target.contextType === 'position') {
          this.onOver(e.target.id)
          if (onMouseEnter) onMouseEnter(e.target.id)
        }
      })

      canvas.off('mouse:out')
      canvas.on('mouse:out', (e) => {
        if (e.target && e.target.contextType === 'position') {
          this.onOut(e.target.id)
          if (onMouseLeave) onMouseLeave(e.target.id)
        }
      })
    }

    canvas.off('mouse:up')
    canvas.on('mouse:up', (e) => {
      isDown = false
      let isStaticLocal = !isEditable // true
      let selectedId = (canvas.getActiveObject() || { id: -1 }).id

      if (filledObject) {
        isStaticLocal = false
        canvas.setActiveObject([...canvas.getObjects()].pop(), e)

        if (numObjectsOnCanvas === canvas.getObjects().length) {
          selectedId = -1 // prevent existing object selecting if a new object was not created
        } else {
          selectedId = (canvas.getActiveObject() || { id: -1 }).id
        }
      }
      this.disableHover = false

      if (isEditable) {
        canvas.getObjects().forEach((obj) => {
          if (
            obj.contextType === 'position' &&
            obj.id !== selectedId &&
            obj.selectedForEdit
          ) {
            unselectEdit(obj.id, canvas, size, prototypes, mtiJsclientShared)
          }
        })
        if (selectedId && selectedId !== -1 && !isNaN(selectedId)) {
          selectEdit(selectedId, canvas, size, prototypes, mtiJsclientShared)
        }
      }

      onChanged({
        canvasObject: canvas.toObject(propsToInclude),
        selectedId: selectedId,
        isStatic: isStaticLocal,
      })
      filledObject = undefined
    })

    if (isCanvasOverlapEnabled) {
      setOverlapMethod('measurer', canvas, size.width, size.height, true, true)
    }
    this.canvasContainer.loadAndRender()
  }

  constructor(...props) {
    super(...props)
    this.onDbClick = debounce(this.onDbClick, 100)
    this.state = {}
  }

  async componentDidMount() {
    const { isEditable, selectedId } = this.props
    this.initCanvas(isEditable)

    if (!isEditable && selectedId !== -1) {
      setTimeout(() => {
        const objects = canvas.getObjects()
        if (objects && objects.length !== 0) {
          this.onSelect(selectedId)
        }
      }, 100)
    }
  }

  componentDidUpdate(prevProps) {
    const { props: thisProps } = this

    if (prevProps.isEditable !== thisProps.isEditable) {
      this.initCanvas(thisProps.isEditable)
      this.removeSelection()
    }

    if (!thisProps.isEditable && thisProps.selectedId !== -1) {
      setTimeout(() => {
        const objects = canvas.getObjects()
        if (objects && objects.length !== 0) {
          if (!this.props.isEditable && this.props.selectedId !== -1) {
            this.onSelect(this.props.selectedId)
          }
        }
      }, 100)
    }
  }

  componentWillUnmount() {
    onClearBlinking()
  }

  onMessage(msg) {
    const { onMessage } = this.props
    if (onMessage) onMessage(msg)
  }

  onDbClick(obj) {
    const { onDbClick } = this.props
    if (onDbClick) onDbClick(obj)
  }

  isExcededMaxPositions() {
    const { canvasObject, maxPositions } = this.props
    if (canvasObject.objects.length >= maxPositions + 1) {
      // +1 as 0 object is fixture at background
      return true
    }
    return false
  }

  removeSelections() {
    // eslint-disable-next-line
    canvas.getObjects().map((o) => {
      if (o.contextType === 'selection' || o.contextType === 'selectionText') {
        canvas.remove(o)
      }
    })

    canvas.renderAll()
  }

  removeSelection() {
    const { pLabel: oldLabel, pSelectionObject: oldSObject } = this
    if (oldLabel) {
      canvas.remove(canvas.getObjects().find((o) => o.id === oldLabel.id))
      this.pLabel = undefined
    }
    if (oldSObject) {
      canvas.remove(canvas.getObjects().find((o) => o.id === oldSObject.id))
      this.pSelectionObject = undefined
    }
    // eslint-disable-next-line
    canvas.getObjects().map((o) => {
      if (
        o.contextType === 'selectedObject' ||
        o.contextType === 'selectedText'
      ) {
        canvas.remove(o)
      }
    })

    canvas.renderAll()
    this.removeSelections()
  }

  onSelect(id) {
    if (isNaN(id)) return
    this.removeSelection()

    const { size, mtiJsclientShared } = this.props
    const { label, selectionObject } =
      handleOver(
        canvas.getObjects().find((o) => o.id === id),
        size,
        this.props.canvasObject.selection,
        mtiJsclientShared
      ) || {}
    if (label) {
      label.contextType = 'selectedText'
      canvas.add(label)
    }
    this.pLabel = label
    if (selectionObject) {
      selectionObject.contextType = 'selectedObject'
      canvas.add(selectionObject)
    }
    this.pSelectionObject = selectionObject

    canvas.renderAll()
  }

  onOver(id) {
    if (this.disableHover) return
    if (this.pSelectionObject) return
    this.removeSelections()
    const { label: oldLabel, selectionObject: oldSObject } = this
    if (oldLabel) {
      canvas.remove(canvas.getObjects().find((o) => o.id === oldLabel.id))
      this.label = undefined
    }
    if (oldSObject) {
      canvas.remove(canvas.getObjects().find((o) => o.id === oldSObject.id))
      this.selectionObject = undefined
    }
    const { size, mtiJsclientShared } = this.props
    const object = canvas.getObjects().find((o) => o.id === id)
    const { label, selectionObject } = handleOver(
      object,
      size,
      this.props.canvasObject.selection,
      mtiJsclientShared
    )
    if (label) canvas.add(label)
    this.label = label
    if (selectionObject) canvas.add(selectionObject)
    this.selectionObject = selectionObject

    canvas.renderAll()
  }

  onOut(id) {
    this.removeSelections()
    if (this.label) {
      handleOut(canvas.getObjects().find((o) => o.id === id))
      canvas.remove(this.label)
    }
    if (this.selectionObject) {
      canvas.remove(this.selectionObject)
      this.selectionObject = undefined
    }
    canvas.renderAll()
    this.label = undefined
  }

  isInteractive() {
    return isDown
  }

  render() {
    const { canvasObject, selectedId } = this.props

    return (
      <Canvas
        ref={(ref) => {
          this.canvasContainer = ref
          const { selectedId, isEditable, mtiJsclientShared } = this.props
          if (selectedId !== -1 && selectedId !== 0 && !isEditable) {
            this.onSelect(selectedId)
          }
          setTimeout(() => {
            onFigureBlinking(canvas, mtiJsclientShared)
          }, 500)
        }}
        canvasObject={canvasObject}
        selectedId={selectedId}
        fabricInstance={canvas}
      />
    )
  }
}

FixtureCanvas.propTypes = {
  selectedId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  canvasObject: PropTypes.object.isRequired,
  size: PropTypes.object.isRequired,
  isEditable: PropTypes.bool,
  onChanged: PropTypes.func.isRequired,
  onDbClick: PropTypes.func,
  onMessage: PropTypes.func,
  prototypes: PropTypes.object,
  mtiJsclientShared: PropTypes.object,
  maxPositions: PropTypes.number,
  onMaxPositions: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onCanvasPositionSave: PropTypes.func,
  onSaveAll: PropTypes.func,
}

export default FixtureCanvas
