import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import {
  HeaderContainer,
  CellContainer,
} from '../UsersPage/index.screen'
import ReactTable from 'react-table';
import { FormattedMessage, injectIntl } from 'react-intl'
import { DeviceType, HealthStateValue, ArmState } from 'mti-jsclient-shared'
import TooltipCell from '../../components/TooltipCell'

const puckDevices = [
  DeviceType.PUCK,
  DeviceType.PUCK_V2,
  DeviceType.PROXIMITY_PUCK,
]

const healthStates = {
  [HealthStateValue.HEALTHY]: 'healthy',
  [HealthStateValue.UNHEALTHY]: 'unhealthy',
}

class LayoutTableViewScreen extends React.PureComponent {
  _fetchData(reactTableState) {
    // convert table filters to something the api will understand

    // sorting
    let sorting = {
      sort_by: 'area',
      sort_order: 'asc',
    }
    const sortChoice = reactTableState.sorted[0]
    if (sortChoice) {
      sorting.sort_order = sortChoice.desc ? 'desc' : 'asc'
      sorting.sort_by = sortChoice.id // area, fixture or position
    }

    // pagination
    const pagination = {
      per: reactTableState.pageSize,
      page: reactTableState.page+1,
    }

    // current filter options allowed by API: floor_id, area_id, fixture_id
    const filters = { }
    reactTableState.filtered.filter(f => f.value && !isNaN(f.value))
      .forEach(f => {
        if (f.id === 'area') { filters.area_id = f.value }
        if (f.id === 'fixture') { filters.fixture_id = f.value }
      })

    // dispatch a call to the API to update the table
    this.props.onFilter({
      ...filters,
      ...sorting,
      ...pagination,
    })
  }

  render() {
    const {
      tableViewData: {
        rows,
        meta: pageData, // total, pages, page, per
      },
      loading,
      defaultPageSize,
    } = this.props
    const columns = this._createColumnSchema()

    return (
      <div>
        <HeaderContainer className="border-bottom">
        </HeaderContainer>
        <ReactTable
          className="-striped"
          columns={columns}
          data={rows}
          manual // Forces table not to paginate or sort automatically, so we can handle it server-side
          multiSort={false}
          pages={pageData.pages} // Display the total number of pages
          defaultPageSize={defaultPageSize}
          loading={loading} // Display the loading overlay when we need it
          onFetchData={(state, instance) => this._fetchData(state, instance)} // Request new data when things change
        />
      </div>
    )
  }

  _makeFilterResourceFunc(resourceList, intl) {
    return props => {
      const { onChange } = props
      let list = resourceList.map(resource => (
        <option key={`resource-${resource.id}`} value={resource.id}>{resource.name}</option>
      ))
      return (
        <select
          onChange={event => onChange(event.target.value)}
          style={{ width: '100%' }}
        >
          <option>{intl.formatMessage({ id: 'all' })}</option>
          {list}
        </select>
      )
    }
  }

  _createColumnSchema() {
    const {
      intl,
      areasFilterList,
      fixturesFilterList,
    } = this.props

    return [{
      id: 'area',
      Header: intl.formatMessage({ id: 'area' }),
      filterable: true,
      accessor: 'areaName',
      style: { overflow: 'hidden' },
      Filter: this._makeFilterResourceFunc(areasFilterList, intl),
    },{
      id: 'fixture',
      Header: intl.formatMessage({ id: 'fixture' }),
      accessor: 'fixtureName',
      filterable: true,
      style: { overflow: 'hidden' },
      Filter: this._makeFilterResourceFunc(fixturesFilterList, intl),
    },{
      Header: intl.formatMessage({ id: 'position' }),
      accessor: 'positionName',
      style: { overflow: 'hidden' },
      id: 'position',
    },{
      id: 'deviceType',
      sortable: false,
      Header: intl.formatMessage({ id: 'containers.layoutTableView.deviceType' }),
      Cell: DeviceTypeCell,
    },{
      id: 'securedProducts',
      Header: intl.formatMessage({ id: 'containers.layoutTableView.currentlyMerchandised' }),
      accessor: 'products',
      sortable: false,
      Cell: SecuredProductsCell,
    },{
      id: 'securityState',
      sortable: false,
      Header: intl.formatMessage({ id: 'containers.layoutTableView.securityState' }),
      Cell: makeSecurityStateCell(intl),
    },{
      id: 'healthState',
      sortable: false,
      Header: intl.formatMessage({ id: 'containers.layoutTableView.healthState' }),
      Cell: makeHealthStateCell(intl),
    },{
      id: 'lastUnlocked',
      sortable: false,
      Header: intl.formatMessage({ id: 'containers.layoutTableView.lastUnlocked' }),
      accessor: 'puckDisarmedAt',
      Cell: LastUnlockedCell,
    }]
  }
}

const getDisplayedDevice = function(baseType) {
  switch (baseType) {
    case DeviceType.BASE:
      return DeviceType.PUCK
    case DeviceType.BASE_V2:
      return DeviceType.PUCK_V2
    case DeviceType.NXDI_RISER:
      return DeviceType.NXDI_PUCK
    case DeviceType.PROXIMITY_BASE:
      return DeviceType.PROXIMITY_PUCK
    case DeviceType.LOCK:
    case DeviceType.ALARM_MODULE:
    case DeviceType.SECURE_PLUG:
    case DeviceType.CX_FLEX_ONE_PORT:
    case DeviceType.CX_FLEX_FOUR_PORT:
      return baseType
    default:
      return null
  }
}

const DeviceTypeCell = function({ original: { baseType } }) {
  const deviceType = getDisplayedDevice(baseType)
  let message = `deviceTypes.${deviceType}`
  if (!deviceType) { message = 'none' }
  return (
    <CellContainer>
      <FormattedMessage id={message} />
    </CellContainer>
  )
}

const SecuredProductsCell = function({ original: { products } }) {
  let productNames = products.filter(product => (
    product.productId
  )).map(product => product.productName)
  return (
    <CellContainer>
      <TooltipCell
        text={products.length > 0 ? productNames.join(', ') : null}
        render={tooltip => (
          <div ref={ref => tooltip.target(ref)}>
            {products.length > 0 ? productNames.join(', ') : null}
          </div>
        )}
      />
    </CellContainer>
  )
}

const makeSecurityStateCell = function(intl) {
  return function({ original }) {
    const {
      puckArmed,
      baseArmed,
      puckAlarming,
      baseAlarming,
      baseLocked,
      baseType,
    } = original
    const deviceType = getDisplayedDevice(baseType)
    let currentState = null

    if (!deviceType) {
      return intl.formatMessage({ id: 'notApplicable' })
    } 

    if (puckAlarming || baseAlarming) { // alarming takes precedence
      currentState = ArmState.ALARMING
    } else if (deviceType === DeviceType.LOCK) {
      currentState = baseLocked ? ArmState.LOCKED : ArmState.UNLOCKED // locks state stored in baseLocked
    } else if (puckDevices.includes(deviceType)) {
      currentState = puckArmed ? ArmState.ARMED : ArmState.DISARMED
    } else {
      currentState = baseArmed ? ArmState.ARMED : ArmState.DISARMED
    }
    return intl.formatMessage({ id: `securityStates.${currentState}` })
  }
}

const makeHealthStateCell = function(intl) {
  return function({ original }) {
    const {
      puckHealthStatus,
      baseHealthStatus,
      puckUnhealthyReason,
      baseUnhealthyReason,
      puckUnhealthyDetail,
      baseUnhealthyDetail,
      baseBatteryPercentage,
      baseType,
    } = original

    const deviceType = getDisplayedDevice(baseType)
    let currentHealth = intl.formatMessage({id: 'notApplicable'})
    if (healthStates[baseHealthStatus]) {
      currentHealth = intl.formatMessage({id: `healthStates.${healthStates[baseHealthStatus]}`})
    }
    let unhealthyReason = baseUnhealthyReason
    let unhealthyDetail = baseUnhealthyDetail

    if (!deviceType) {
      currentHealth = intl.formatMessage({id: 'notApplicable'})
    }
    if (puckDevices.includes(deviceType)) {
      currentHealth = intl.formatMessage({id: `healthStates.${healthStates[puckHealthStatus]}`})
      unhealthyReason = puckUnhealthyReason
      unhealthyDetail = puckUnhealthyDetail
    } else if (deviceType === DeviceType.LOCK) {
      if (!baseHealthStatus) {
        currentHealth = `${baseBatteryPercentage}%`
      }
    }

    const healthStateText = category => intl.formatMessage(
      { id: `healthStates.${category}.${deviceType}.${unhealthyReason}` },
      { detail: unhealthyDetail },
    )

    return (
      <CellContainer>
        { unhealthyReason ?
          <TooltipCell
            text={`${healthStateText('reasons')}.\n${healthStateText('actions')}`}
            render={tooltip => (
              <div ref={ref => tooltip.target(ref)}>
                {currentHealth}
              </div>
            )}
          />
          : currentHealth
        }
      </CellContainer>
    )
  }
}

const LastUnlockedCell = function({ original: {
  baseDisarmedAt,
  baseDisarmedBy,
  puckDisarmedAt,
  puckDisarmedBy,
  baseType,
}}) {
  let disarmedAt = baseDisarmedAt
  let disarmedBy = baseDisarmedBy

  const deviceType = getDisplayedDevice(baseType)
  if (puckDevices.includes(deviceType)) {
    disarmedAt = puckDisarmedAt
    disarmedBy = puckDisarmedBy
  }

  return (
    <CellContainer>
      <TooltipCell
        text={disarmedBy}
        render={tooltip => (
          <div ref={ref => tooltip.target(ref)}>
            { disarmedAt ?
              moment(disarmedAt)
                .utcOffset(0)
                .format('MM/DD/YYYY h:mm')
              : null
            }
          </div>
        )}
      />
    </CellContainer>
  )
}

LayoutTableViewScreen.propTypes = {
  tableViewData: PropTypes.shape({
    rows: PropTypes.array.isRequired,
  }),
  onFilter: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  areasFilterList: PropTypes.array.isRequired,
  fixturesFilterList: PropTypes.array.isRequired,
  defaultPageSize: PropTypes.number.isRequired,
}

export default injectIntl(LayoutTableViewScreen)
