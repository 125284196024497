/**
 * Tags Sagas
 */
import { delay } from 'redux-saga'
import { call, put, takeLatest, select } from 'redux-saga/effects'
import {
  getTaggings,
  getTags,
  postTagging,
  deleteTagging,
  orgIdUrl,
} from '../../../api'
import {
  makeSelectToken,
  makeSelectIsMtiAdmin,
  makeSelectEditedTaggings,
  makeSelectMtiPermissionAnyResource,
} from '../selectors'
import { ActionTypes } from '../constants'
import {
  postTaggingsFulfilled,
  postTaggingsPending,
  putTaggings,
  putTags,
  storeDetailsAction,
} from '../actions'
import { makeSelectStores } from '../../StoresPage/selectors'
import { errorToast, successToast } from '../../../utils/utils'

export function* loadTags() {
  const listTaggingsPermissionData = yield select(
    makeSelectMtiPermissionAnyResource('Tagging: Index (list) Records')
  )
  if (!listTaggingsPermissionData) {
    return
  }

  try {
    const token = yield select(makeSelectToken())
    if (!token) {
      console.log('Skipping get Tags')
      return
    } else {
      // console.log('get Tags')
    }
    const isMtiAdmin = yield select(makeSelectIsMtiAdmin())
    if (isMtiAdmin && !orgIdUrl()) return
    const taggings = yield call(getTaggings, token)
    const tags = yield call(getTags, token)
    yield put(putTaggings(taggings))
    yield put(putTags(tags))
  } catch (error) {
    console.error(error)
    errorToast('Load tags failed')
  }
}

export function* postTaggingGen({ payload }) {
  const { storeId, tagId } = payload
  try {
    const token = yield select(makeSelectToken())
    yield call(postTagging, token, storeId, tagId)
  } catch (error) {
    console.error(error)
    errorToast('Create tagging failed')
  }
}

export function* deleteTaggingGen({ payload }) {
  const { taggingId } = payload
  try {
    const token = yield select(makeSelectToken())
    yield call(deleteTagging, token, taggingId)
  } catch (error) {
    console.error(error)
    errorToast('Delete tagging failed')
  }
}

export function* postTaggings() {
  const editedTaggings = yield select(makeSelectEditedTaggings())
  yield put(postTaggingsPending())

  // Split array by two, for deletion and for creation of tagging
  let taggingsToDelete = editedTaggings.filter(({ isChecked }) => !isChecked)
  let taggingsToCreate = editedTaggings.filter(({ isChecked }) => isChecked)

  // Make sure that we will not request tagging deletion if there was no tagging created
  taggingsToDelete = taggingsToDelete.filter(
    ({ taggingId, isChecked }) => !(!taggingId && !isChecked)
  )

  // Make sure that we will not have duplicates of tagging deletion request
  taggingsToDelete = taggingsToDelete.filter(
    (thing, index) =>
      index ===
      taggingsToDelete.findIndex((obj) => obj.taggingId === thing.taggingId)
  )

  // Make sure that we will not have duplicates of tagging creation request
  taggingsToCreate = taggingsToCreate.filter(
    (thing, index) =>
      index === taggingsToCreate.findIndex((obj) => obj.tagId === thing.tagId)
  )

  // Make sure that we will delete all old taggings
  taggingsToDelete = taggingsToDelete.concat(
    taggingsToCreate
      .filter(({ taggingId }) => !!taggingId)
      .map((t) => ({ ...t, isChecked: false }))
  )

  // Make sure that we will not have duplicates of tagging deletion request
  taggingsToDelete = taggingsToDelete.filter(
    (thing, index) =>
      index ===
      taggingsToDelete.findIndex((obj) => obj.taggingId === thing.taggingId)
  )

  // Deletion should be executed in first order
  for (let i = 0; i < taggingsToDelete.length; i++) {
    const tagging = taggingsToDelete[i]
    yield call(deleteTaggingGen, { payload: tagging })
    yield delay(100) //TODO: we should add delay to be sure that api will have a time to finish previouse operation
  }

  // Creation should be executed in second order
  for (let i = 0; i < taggingsToCreate.length; i++) {
    const tagging = taggingsToCreate[i]
    yield call(postTaggingGen, { payload: tagging })
    yield delay(100) //TODO: we should add delay to be sure that api will have a time to finish previouse operation
  }

  successToast('Tags Updated')
  yield call(loadTags)
  yield put(postTaggingsFulfilled())

  const { storeId } = editedTaggings[0]
  const storesStored = yield select(makeSelectStores())
  const storeStored = storesStored.find(({ id: sId }) => sId === storeId)
  yield put(storeDetailsAction(storeStored)) // Open store details modal
}

export default function* root() {
  yield takeLatest(ActionTypes.LOAD_TAGS, loadTags)
  yield takeLatest(ActionTypes.POST_TAGGINGS, postTaggings)
}
