/*
 * Password reducer
 */
import { Map } from 'immutable'
import { ActionTypes } from './constants'

export const initialState = new Map({
  failed: null,
  loading: false,
  success: null,
})

function reducer(state = initialState, { payload, type }) {
  switch (type) {
    case ActionTypes.POST_PASSWORD_SUCCESS:
      return state.set('success', payload)
    case ActionTypes.POST_PASSWORD_FULFILLED:
      return state.set('failed', null).set('loading', false)
    case ActionTypes.POST_PASSWORD_PENDING:
      return state.set('failed', null).set('loading', true)
    case ActionTypes.POST_PASSWORD_FAILED:
      return state.set('failed', payload).set('loading', false)
    case ActionTypes.EDIT_PASSWORD:
      return state.merge(state, payload)
    default:
      return state
  }
}

export default reducer
