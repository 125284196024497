import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import styles from '../../../styles/App.css'
import { Link, NavLink } from 'react-router-dom'
import {
  userIsAuthenticated,
  userIsNotAuthenticated,
} from 'containers/App/auth'
import placeholder from '../../../images/icon-user-placeholder.png'
import MTIConnectedStore from '../../../images/svg/mti/MTI-Connect.svg'
// import {
//   saveStoreCallWithInclude,
//   restoreStoreCallWithInclude,
//   saveInitialGeometryCall,
//   restoreInitialGeometryCall,
//   saveCanvasOverlapEnabled,
//   restoreCanvasOverlapEnabled,
// } from '../localStorageOptions'

const SmallAvatar = styled.img`
  margin-left: 24px;
  width: 32px;
  height: 32px;
  border-radius: 16px;
`

// Only show login when the user is not logged in and logout when logged in
// Could have also done this with a single wrapper and `FailureComponent`
const UserDropdown = userIsAuthenticated((
  { user, doLogout, doNotifyModal, onShowUserDetails } // eslint-disable-line
) => (
  <div className="nav-item dropdown d-flex align-items-center">
    <SmallAvatar src={(user && user.avatarUrl) || placeholder} alt="Lights" />
    <button
      className="nav-link btn btn-secondary dropdown-toggle my-0"
      type="button"
      id="navbarUserDropdown"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      style={{ left: 'auto', right: 0 }}
    >
      {(user && user.name) || 'Not logged in'}
    </button>
    <div className="dropdown-menu" aria-labelledby="navbarUserDropdown">
      <a
        className="dropdown-item"
        name="showUserDetails"
        onClick={onShowUserDetails}
        role="button"
        tabIndex="-1"
      >
        {(user && user.name) || 'Not logged in'}
      </a>
      <a
        className="dropdown-item"
        onClick={doNotifyModal}
        role="button"
        tabIndex="-1"
      >
        Notifications
      </a>
      <div className="dropdown-divider" />
      <LogoutLink logout={doLogout} />
    </div>
  </div>
))

const SupportDropdown = (onShowSupportModal) => (
  // const isStoreCallWithInclude = restoreStoreCallWithInclude()
  // const isInitialGeometryCall = restoreInitialGeometryCall()
  // const isCanvasOverlapEnabled = restoreCanvasOverlapEnabled()

  <div className="nav-item dropdown">
    <button
      className="nav-link btn btn-secondary dropdown-toggle my-0"
      type="button"
      id="navbarSupportDropdown"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      Support
    </button>
    <div
      className="dropdown-menu"
      aria-labelledby="navbarSupportDropdown"
      style={{ left: 'auto', right: 0 }}
    >
      <a
        className="dropdown-item"
        onClick={onShowSupportModal}
        role="button"
        tabIndex="-1"
      >
        Support
      </a>
      {/*<a
          className="dropdown-item"
          onClick={() => saveStoreCallWithInclude(!isStoreCallWithInclude)}
          role="button"
          tabIndex="-1"
        >
          {isStoreCallWithInclude ? 'Without Include' : 'With Include'}
        </a>*/}
      {/*<a
          className="dropdown-item"
          onClick={() => saveInitialGeometryCall(!isInitialGeometryCall)}
          role="button"
          tabIndex="-1"
        >
          {isInitialGeometryCall
            ? 'Without Geometry Call'
            : 'With Geometry Call'}
        </a>*/}
      {/* <a
          className="dropdown-item"
          onClick={() => saveCanvasOverlapEnabled(!isCanvasOverlapEnabled)}
          role="button"
          tabIndex="-1"
        >
          {isCanvasOverlapEnabled
            ? 'Disable Canvas Overlap'
            : 'Enable Canvas Overlap'}
        </a>*/}
    </div>
  </div>
)

const LoginLink = userIsNotAuthenticated(() => (
  <NavLink className="nav-link" to="/login">
    Login
  </NavLink>
))

const LogoutLink = userIsAuthenticated((
  { logout } // eslint-disable-line
) => (
  <NavLink to="#" className="dropdown-item" onClick={() => logout()}>
    Logout
  </NavLink>
))

const Panel = ({
  user,
  doLogout,
  doNotifyModal,
  onShowUserDetails,
  onShowSupportModal,
}) => (
  <nav className={`${styles.navbar} navbar navbar-expand navbar-dark bg-dark`}>
    <Link className="navbar-brand" to="/">
      <img src={MTIConnectedStore} alt={'MTI Connected Store'} />
    </Link>
    <button
      className="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span className="navbar-toggler-icon" />
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav ml-auto">
        {SupportDropdown(onShowSupportModal)}
        <UserDropdown
          user={user}
          doLogout={doLogout}
          onShowUserDetails={() => onShowUserDetails(user)}
          doNotifyModal={doNotifyModal}
        />
        <LoginLink />
      </ul>
    </div>
  </nav>
)

Panel.propTypes = {
  user: PropTypes.object,
  doLogout: PropTypes.func,
  onShowUserDetails: PropTypes.func,
  onShowSupportModal: PropTypes.func,
  doNotifyModal: PropTypes.func,
}

export default Panel
