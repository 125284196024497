import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Container = styled.div`
  max-height: 400px;
  overflow-y: auto;
`

const Suggestions = ({ isExpanded, data, onSelect }) => {
  if (!isExpanded || !data.length) {
    return null
  }

  return (
    <Container className="show dropdown-menu">
      {data.map((item) => (
        <button
          key={item.id}
          className="dropdown-item"
          onClick={() => onSelect(item)}
          type="button"
        >
          {item.title}
        </button>
      ))}
    </Container>
  )
}

Suggestions.propTypes = {
  isExpanded: PropTypes.bool,
  data: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
}

export default Suggestions
