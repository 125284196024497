import {
  getObjectAttrs,
  toSelectionAttrs,
  getSelectionType,
  getSelectionByType,
} from '../../containers/FixturePage/utils'
import { createText } from './text'

export function getSelectionObjectSync(object, id, type, screen, origX, origY) {
  if (!object) return object
  object.set(
    toSelectionAttrs(
      getObjectAttrs(object, `selection-${id}`, screen, origX, origY)
    )
  )
  return object
}

export const handleOver = (o, size, objects, mtiJsclientShared = {}) => {
  if (!o || !objects) return undefined
  // console.log('handleOver', o.id)

  const { name, left, top, width, height, id, mtiType } = o
  // Create selection outline for the position
  const selectionObject = getSelectionObjectSync(
    getSelectionByType(o, objects, mtiJsclientShared),
    id,
    getSelectionType(mtiType, mtiJsclientShared),
    size,
    left,
    top
  )
  // Create description text under the position
  const selectionScaleFactor = selectionObject.scaleFactor || 1
  const scaleFactor =
    selectionScaleFactor < 1 ? selectionScaleFactor * 1.1 : selectionScaleFactor
  const verticalGap = height * scaleFactor * 2
  const label = createText(name, left, top, width, height, verticalGap)

  if (!selectionObject) return { label, selectionObject }
  selectionObject.selectable = false
  selectionObject.evented = false
  return { label, selectionObject }
}

export const handleOut = (o) => {
  if (!o) return false
  // console.log('handleOut', o.id)
  return true
}
