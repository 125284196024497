import { positionType } from '../../utils/mtiCanvasUtils'

export const editType = {
  empty: 'empty',
  unselected: 'unselected',
  unselectedSecurePlug: 'unselectedSecurePlug',
  unselectedCXFlexFourPort: 'unselectedCXFlexFourPort',
  unselectedLock: 'unselectedLock',
  filled: 'filled',
  filledSecurePlug: 'filledSecurePlug',
  filledLock: 'filledLock',
  filledCXFlexFourPort: 'filledCXFlexFourPort',
  selected: 'selected',
}

export function getEditTypeByDevice(
  { deviceType, wantedDeviceType },
  mtiJsclientShared = {}
) {
  const { DeviceType = {} } = mtiJsclientShared
  switch (deviceType || wantedDeviceType) {
    case DeviceType.SECURE_PLUG:
    case positionType.missingSecurePlugDevice:
      return editType.filledSecurePlug
    case DeviceType.CX_FLEX_FOUR_PORT:
    case positionType.missingCXFlexDevice:
      return editType.filledCXFlexFourPort
    case DeviceType.LOCK:
    case positionType.missingLockDevice:
      return editType.filledLock
    default:
      return editType.filled
  }
}

export function getEditUnselectedTypeByDevice(
  deviceType,
  wantedDeviceType,
  mtiJsclientShared = {}
) {
  const { DeviceType = {} } = mtiJsclientShared
  switch (deviceType || wantedDeviceType) {
    case DeviceType.SECURE_PLUG:
    case positionType.missingSecurePlugDevice:
      return editType.unselectedSecurePlug
    case DeviceType.CX_FLEX_FOUR_PORT:
    case positionType.missingCXFlexDevice:
      return editType.unselectedCXFlex
    case DeviceType.LOCK:
    case positionType.missingLockDevice:
      return editType.unselectedLock
    default:
      return editType.unselected
  }
}
