import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import HubStatusScreen from './index.screen'
import {
  makeSelectHubStatusError,
  makeSelectHubStatusLoading,
} from '../../App/selectors'

const HubStatusModal = ({
  id,
  hub,
  onClose,
  statusFetching,
  statusFetchError,
}) => (
  <HubStatusScreen
    id={id}
    hub={hub}
    statusFetching={statusFetching}
    statusFetchError={statusFetchError}
    onClose={onClose}
  />
)

HubStatusModal.propTypes = {
  id: PropTypes.string.isRequired,
  hub: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  statusFetching: PropTypes.bool,
  statusFetchError: PropTypes.object,
}

const mapStateToProps = createStructuredSelector({
  statusFetching: makeSelectHubStatusLoading(),
  statusFetchError: makeSelectHubStatusError(),
})

export default connect(mapStateToProps, {})(HubStatusModal)
