import { all, call, put, takeLatest, select } from 'redux-saga/effects'
import { getProducts } from '../../../api'
import { selectToken, selectOrganizationID } from '../../../containers/App/selectors'
import { ActionTypes } from './constants'
import {
  fetchManufacturerProductsPending,
  fetchManufacturerProductsFulfilled,
  fetchManufacturerProductsFailed,
} from './actions'
import { errorToast } from '../../../utils/utils'

export function* loadManufacturerProducts({ payload }) {
  const { manufacturerId } = payload
  const token = yield select(selectToken)
  try {
    yield put(fetchManufacturerProductsPending(manufacturerId))
    const orgID = yield select(selectOrganizationID)
    const productsData = yield call(getProducts, token, manufacturerId, null, orgID)

    yield put(
      fetchManufacturerProductsFulfilled(manufacturerId, productsData.products)
    )
  } catch (error) {
    yield put(fetchManufacturerProductsFailed(error))
    errorToast('Load products failed')
  }
}

/**
 * Store Sagas
 */
export default function* root() {
  yield all([
    yield takeLatest(
      ActionTypes.LOAD_MANUFACTURER_PRODUCTS,
      loadManufacturerProducts
    ),
  ])
}
