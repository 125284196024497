import React from 'react'
import PropTypes from 'prop-types'
import CreateEditUserScreen from './index.screen'

export class CreateEditUserModal extends React.Component {
  setLoading(loading) {
    if (this.createUserModal) this.createUserModal.setLoading(loading)
  }

  setServerValidity(error) {
    if (this.createUserModal) this.createUserModal.setServerValidity(error)
  }

  close() {
    if (this.createUserModal) this.createUserModal.close()
  }

  render() {
    const {
      id,
      user,
      onChange,
      title,
      confirmText,
      onClose,
      onConfirm,
      onFind,
      isEditProfileOnly,
      hasRegionalManagerRole,
      hasStoreManagerRole,
      hasOrganizationAdminRole,
      hasMtiAdminRole,
      userEditLoading,
    } = this.props
    return (
      <CreateEditUserScreen
        ref={(ref) => {
          this.createUserModal = ref
        }}
        id={id}
        user={user}
        onChange={onChange}
        title={title}
        confirmText={confirmText}
        onClose={onClose}
        onConfirm={onConfirm}
        onFind={onFind}
        isEditProfileOnly={isEditProfileOnly}
        hasRegionalManagerRole={hasRegionalManagerRole}
        hasStoreManagerRole={hasStoreManagerRole}
        hasOrganizationAdminRole={hasOrganizationAdminRole}
        hasMtiAdminRole={hasMtiAdminRole}
        userEditLoading={userEditLoading}
      />
    )
  }
}

CreateEditUserModal.propTypes = {
  id: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  title: PropTypes.string.isRequired,
  confirmText: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onFind: PropTypes.func,
  isEditProfileOnly: PropTypes.bool,
  hasRegionalManagerRole: PropTypes.bool,
  hasStoreManagerRole: PropTypes.bool,
  hasOrganizationAdminRole: PropTypes.bool,
  hasMtiAdminRole: PropTypes.bool,
  userEditLoading: PropTypes.bool,
}

export default CreateEditUserModal
