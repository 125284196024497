/*
 *
 * FixturePage constants
 *
 */

import keyMirror from 'fbjs/lib/keyMirror'

/**
 * @constant {Object} ActionTypes
 * @memberof Constants
 */
export const ActionTypes = keyMirror({
  STORE_FIXTURE_PROTOTYPES: undefined,
  STORE_PARAMS: undefined,
  POST_FIXTURE_CANVAS_FAILED: undefined,
  POST_FIXTURE_CANVAS_FINISHED: undefined,
  POST_FIXTURE_CANVAS_PENDING: undefined,
  FETCH_FIXTURE_CANVAS_FAILED: undefined,
  FETCH_FIXTURE_CANVAS_FULFILLED: undefined,
  FETCH_FIXTURE_CANVAS_PENDING: undefined,
  AUTO_PLACEMENT_FULFILLED: undefined,
  LOAD_FIXTURE_CANVAS: undefined,
  SAVE_FIXTURE_POSITION: undefined,
  SAVE_FIXTURE_ALL_POSITIONS: undefined,
  FIXTURE_CANVAS_POSITION_REMOVE: undefined,
  FIXTURE_CANVAS_CHANGED: undefined,
  FIXTURE_CANVAS_NAME_CHANGED: undefined,
  FIXTURE_CANVAS_POSITION_COUNT_CHANGED: undefined,
  STORE_FIXTURE_LOADING: undefined,
  LOAD_PRODUCTS_WITH_MANUFACTURERS: undefined,
  ASSIGN_SECURITY_DEVICE: undefined,
  ASSIGN_PORT_SECURED_PRODUCT: undefined,
  ADD_NEW_PRODUCT: undefined,
  ADD_AND_ASSIGN_PORT_NEW_PRODUCT: undefined,
  DELETE_PRODUCT: undefined,
  SELECT_POSITION: undefined,
  SAVE_FIXTURE_FROM_MOBILE: undefined,
})
