/**
 *
 * Store Layout Preloader
 *
 */

import React from 'react'
import ContentLoader from 'react-content-loader'
import PropTypes from 'prop-types'
import uuid from 'uuid'
import { HeaderHeight } from '../../../utils/utils'

function StoreLayoutPreloader({ positionFixure }) {
  const StoreLayoutPreloader = (props) => {
    const loaderWidth = window.innerWidth
    const loaderHeight = window.innerHeight
    const widthSidePanel = 340
    const widthCanvas = 600
    const heightCanvas = 400
    const widthInformationPanel = 355
    return (
      <ContentLoader
        height={loaderHeight - HeaderHeight}
        width={loaderWidth}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
        {...props}
      >
        {/* for Canvas */}
        <rect
          x={(loaderWidth - widthCanvas - widthSidePanel) / 2}
          y={(loaderHeight - HeaderHeight - heightCanvas) / 2}
          rx="0"
          ry="0"
          width="600"
          height="400"
        />
        {/* for Information Panel */}
        <rect
          x={(loaderWidth - widthInformationPanel - widthSidePanel) / 2}
          y="30"
          rx="6"
          ry="6"
          width="140"
          height="16"
        />
        <rect
          x={(loaderWidth - widthInformationPanel - widthSidePanel) / 2}
          y="55"
          rx="6"
          ry="6"
          width="140"
          height="16"
        />
        <rect
          x={(loaderWidth - widthInformationPanel - widthSidePanel) / 2}
          y="80"
          rx="6"
          ry="6"
          width="140"
          height="16"
        />
        <rect
          x={(loaderWidth - widthInformationPanel - widthSidePanel) / 2 + 155}
          y="30"
          rx="6"
          ry="6"
          width="200"
          height="16"
        />
        {/* 155 = width second part of Information Panel and padding = 15 */}
        <rect
          x={(loaderWidth - widthInformationPanel - widthSidePanel) / 2 + 155}
          y="55"
          rx="6"
          ry="6"
          width="200"
          height="16"
        />
        <rect
          x={(loaderWidth - widthInformationPanel - widthSidePanel) / 2 + 155}
          y="80"
          rx="6"
          ry="6"
          width="200"
          height="16"
        />
        {/* for Side Pannel buttons */}
        <rect
          x={loaderWidth - widthSidePanel}
          y="10"
          rx="6"
          ry="6"
          width="200"
          height="45"
        />
        <rect
          x={loaderWidth - 130}
          y="10"
          rx="6"
          ry="6"
          width="110"
          height="45"
        />
        {/* for Side Pannel first line */}
        <rect
          x={loaderWidth - widthSidePanel}
          y="65"
          rx="0"
          ry="0"
          width={widthSidePanel}
          height="1"
        />
        {/* for Side Pennel */}
        <React.Fragment>
          {positionFixure ? (
            <React.Fragment>
              <rect
                x={loaderWidth - widthSidePanel}
                y="80"
                rx="6"
                ry="6"
                width="200"
                height="10"
              />
              <rect
                x={loaderWidth - widthSidePanel}
                y="95"
                rx="6"
                ry="6"
                width="200"
                height="10"
              />
              <rect
                x={loaderWidth - widthSidePanel}
                y="110"
                rx="6"
                ry="6"
                width="200"
                height="10"
              />
              <rect
                x={loaderWidth - 130}
                y="80"
                rx="6"
                ry="6"
                width="110"
                height="10"
              />
              {/* 130 = width second part of Side Panel and padding = 20 */}
              <rect
                x={loaderWidth - 130}
                y="95"
                rx="6"
                ry="6"
                width="110"
                height="10"
              />
              <rect
                x={loaderWidth - 130}
                y="110"
                rx="6"
                ry="6"
                width="110"
                height="10"
              />
              <rect
                x={loaderWidth - widthSidePanel}
                y="130"
                rx="0"
                ry="0"
                width={widthSidePanel}
                height="1"
              />
              <rect
                x={loaderWidth - widthSidePanel}
                y="135"
                rx="6"
                ry="6"
                width="200"
                height="10"
              />
              <rect
                x={loaderWidth - widthSidePanel}
                y="150"
                rx="0"
                ry="0"
                width={widthSidePanel}
                height="1"
              />
              <rect
                x={loaderWidth - widthSidePanel}
                y="160"
                rx="0"
                ry="0"
                width="30"
                height="30"
              />
              <rect
                x={loaderWidth - widthSidePanel + 40}
                y="165"
                rx="6"
                ry="6"
                width="100"
                height="20"
              />
              <rect
                x={loaderWidth - widthSidePanel + 155}
                y="167"
                rx="6"
                ry="6"
                width="170"
                height="16"
              />
              {/* 110 = width second part of Side Panel and padding = 15 */}
              <rect
                x={loaderWidth - widthSidePanel}
                y="200"
                rx="0"
                ry="0"
                width={widthSidePanel}
                height="1"
              />
              <rect
                x={loaderWidth - widthSidePanel + 40}
                y="225"
                rx="6"
                ry="6"
                width="100"
                height="20"
              />
              <rect
                x={loaderWidth - widthSidePanel}
                y="220"
                rx="0"
                ry="0"
                width="30"
                height="30"
              />
              <rect
                x={loaderWidth - widthSidePanel + 155}
                y="227"
                rx="6"
                ry="6"
                width="170"
                height="16"
              />
              {/* 110 = width second part of Side Panel and padding = 15 */}
              <rect
                x={loaderWidth - widthSidePanel + 40}
                y="260"
                rx="6"
                ry="6"
                width="170"
                height="16"
              />
              <rect
                x={loaderWidth - widthSidePanel}
                y="300"
                rx="0"
                ry="0"
                width={widthSidePanel}
                height="1"
              />
              <rect
                x={loaderWidth - widthSidePanel}
                y="350"
                rx="6"
                ry="6"
                width="100"
                height="10"
              />
              <rect
                x={loaderWidth - widthSidePanel}
                y="370"
                rx="6"
                ry="6"
                width="225"
                height="16"
              />
              <rect
                x={loaderWidth - widthSidePanel + 255}
                y="350"
                rx="0"
                ry="0"
                width="70"
                height="40"
              />
              <rect
                x={loaderWidth - widthSidePanel}
                y="440"
                rx="6"
                ry="6"
                width="100"
                height="10"
              />
              <rect
                x={loaderWidth - widthSidePanel}
                y="460"
                rx="6"
                ry="6"
                width="320"
                height="16"
              />
              <rect
                x={loaderWidth - widthSidePanel}
                y="520"
                rx="6"
                ry="6"
                width="100"
                height="10"
              />
              <rect
                x={loaderWidth - widthSidePanel}
                y="540"
                rx="6"
                ry="6"
                width="225"
                height="16"
              />
              <rect
                x={loaderWidth - widthSidePanel + 255}
                y="520"
                rx="0"
                ry="0"
                width="70"
                height="40"
              />
              <rect
                x={loaderWidth - widthSidePanel}
                y="600"
                rx="6"
                ry="6"
                width="100"
                height="10"
              />
              <rect
                x={loaderWidth - widthSidePanel}
                y="620"
                rx="6"
                ry="6"
                width="320"
                height="16"
              />
            </React.Fragment>
          ) : (
            <React.Fragment>
              {Array(6)
                .fill('')
                .map((e, i) => (
                  <React.Fragment key={`loader_${uuid.v4()}`}>
                    <rect
                      x={loaderWidth - widthSidePanel}
                      y={90 + 60 * i}
                      rx="6"
                      ry="6"
                      width="200"
                      height="10"
                    />
                    <rect
                      x={loaderWidth - widthSidePanel}
                      y={105 + 60 * i}
                      rx="6"
                      ry="6"
                      width="200"
                      height="10"
                    />
                    <rect
                      x={loaderWidth - widthSidePanel}
                      y={120 + 60 * i}
                      rx="6"
                      ry="6"
                      width="200"
                      height="10"
                    />
                    <rect
                      x={loaderWidth - 130}
                      y={90 + 60 * i}
                      rx="6"
                      ry="6"
                      width="110"
                      height="10"
                    />
                    {/* 130 = width second part of Side Panel and padding = 20 */}
                    <rect
                      x={loaderWidth - 130}
                      y={105 + 60 * i}
                      rx="6"
                      ry="6"
                      width="110"
                      height="10"
                    />
                    <rect
                      x={loaderWidth - 130}
                      y={120 + 60 * i}
                      rx="6"
                      ry="6"
                      width="110"
                      height="10"
                    />
                    <rect
                      x={loaderWidth - widthSidePanel}
                      y={140 + 60 * i}
                      rx="0"
                      ry="0"
                      width={widthSidePanel}
                      height="1"
                    />
                  </React.Fragment>
                ))}
              <rect
                x={loaderWidth - widthSidePanel}
                y="470"
                rx="6"
                ry="6"
                width="240"
                height="16"
              />
              <rect
                x={loaderWidth - widthSidePanel}
                y="510"
                rx="6"
                ry="6"
                width="150"
                height="14"
              />
              <rect
                x={loaderWidth - widthSidePanel}
                y="560"
                rx="0"
                ry="0"
                width={widthSidePanel}
                height="1"
              />
            </React.Fragment>
          )}
        </React.Fragment>
      </ContentLoader>
    )
  }
  return <StoreLayoutPreloader />
}

StoreLayoutPreloader.propTypes = {
  positionFixure: PropTypes.bool,
}

export default StoreLayoutPreloader
