/*
 *
 * App reducer
 *
 */

import { fromJS, List } from 'immutable'
import { ActionTypes } from './constants'
import { createRegions, createRegion } from '../RegionsPage/regions'
import { createSchedules } from '../SchedulesPage/schedules'

const initialState = fromJS({
  // Users
  users: null,
  usersFailed: null,
  usersLoading: false,
  // Me
  me: null,
  meFailed: null,
  meLoading: false,
  // Personae
  personae: null,
  personaeFailed: null,
  personaeLoading: false,
  // Organizations
  organizations: null,
  organizationsFailed: null,
  organizationsLoading: false,
  // Stores
  storesFailed: null,
  storesLoading: false,
  // Templates
  templatesFailed: null,
  templatesLoading: false,
  templatesStartTime: 0,
  // Regions
  regions: null,
  regionsFailed: null,
  regionsLoading: false,
  // ORM
  storeUpsertLoading: new Map(),
  storeUpsertFailed: new Map(),
  geometryUpsertLoading: new Map(),
  geometryUpsertFailed: new Map(),
  //
  templates: [],
  images: new Map(),
  securityDeviceIdentifySerialNumber: List([]),
  // Secured Products
  securedProductsFailed: null,
  securedProductsLoading: false,
  // Schedules
  schedules: null,
  schedulesFailed: null,
  schedulesLoading: false,
})

function appReducer(state = initialState, { type, payload }) {
  switch (type) {
    // Users
    case ActionTypes.FETCH_USERS_FULFILLED:
      return state
        .set('users', payload.users)
        .set('usersLoading', false)
        .set('usersFailed', null)
    case ActionTypes.FETCH_USERS_PENDING:
      return state.set('usersFailed', null).set('usersLoading', true)
    case ActionTypes.FETCH_USERS_FAILED:
      return state.set('usersFailed', payload).set('usersLoading', false)
    // Me
    case ActionTypes.FETCH_ME_FULFILLED:
      return state
        .set('me', payload.data)
        .set('meLoading', false)
        .set('meFailed', null)
    case ActionTypes.FETCH_ME_PENDING:
      return state.set('meFailed', null).set('meLoading', true)
    case ActionTypes.FETCH_ME_FAILED:
      return state.set('meFailed', payload).set('meLoading', false)
    // Personae
    case ActionTypes.FETCH_PERSONAE_FULFILLED:
      return state
        .set('personae', (payload.personae || {}).personae)
        .set('personaeLoading', false)
        .set('personaeFailed', null)
    case ActionTypes.FETCH_PERSONAE_PENDING:
      return state.set('personaeFailed', null).set('personaeLoading', true)
    case ActionTypes.FETCH_PERSONAE_FAILED:
      return state.set('personaeFailed', payload).set('personaeLoading', false)
    // Organizations
    case ActionTypes.FETCH_ORGANIZATIONS_FULFILLED:
      return state
        .set('organizations', payload.organizations)
        .set('organizationsLoading', false)
        .set('organizationsFailed', null)
    case ActionTypes.FETCH_ORGANIZATIONS_PENDING:
      return state
        .set('organizationsFailed', null)
        .set('organizationsLoading', true)
    case ActionTypes.FETCH_ORGANIZATIONS_FAILED:
      return state
        .set('organizationsFailed', payload)
        .set('organizationsLoading', false)
    // Stores
    case ActionTypes.FETCH_STORES_FULFILLED:
      return state.set('storesLoading', false).set('storesFailed', null)
    case ActionTypes.FETCH_STORES_PENDING:
      return state.set('storesFailed', null).set('storesLoading', true)
    case ActionTypes.FETCH_STORES_FAILED:
      return state.set('storesFailed', payload).set('storesLoading', false)
    //
    case ActionTypes.STORE_IMAGES: {
      payload.forEach(i =>
        state.set('images', state.get('images').set(i.id, i))
      )
      return state
    }
    case ActionTypes.SECURITY_DEVICE_IDENTIFY:
      if (!state.get('securityDeviceIdentifySerialNumber').includes(payload)) {
        return state.set(
          'securityDeviceIdentifySerialNumber',
          List([payload]).concat(
            state.get('securityDeviceIdentifySerialNumber')
          )
        )
      }
      return state
    case ActionTypes.SECURITY_DEVICE_RESET_IDENTIFY: {
      if (state.get('securityDeviceIdentifySerialNumber').includes(payload)) {
        return state.set(
          'securityDeviceIdentifySerialNumber',
          state
            .get('securityDeviceIdentifySerialNumber')
            .filter(i => i !== payload)
        )
      }
      return state
    }
    // Regions
    case ActionTypes.FETCH_REGIONS_FULFILLED:
      const stores = payload.regions ? payload.regions.stores : []
      return state
        .set(
          'regions',
          createRegions(payload.regions, stores)
        )
        .set('regionsLoading', false)
        .set('regionsFailed', null)
    case ActionTypes.FETCH_REGION_WITH_STORE_FULFILLED: {
      const regions = state.get('regions').map(r => {
        if (r.id == payload.regions[0].id) return createRegion(payload.regions[0], payload.stores)
        return r
      })
      // Do not replace with state.merge(), it changes arrays into Lists and bugs react-table
      return state.set('regions', regions)
    }
    case ActionTypes.FETCH_REGIONS_PENDING:
      return state.set('regionsFailed', null).set('regionsLoading', true)
    case ActionTypes.FETCH_REGIONS_FAILED:
      return state.set('regionsFailed', payload).set('regionsLoading', false)
    case ActionTypes.PATCH_REGION_FULFILLED: {
      const storesIds = payload.regions[0].storeIds
      const regions = state.get('regions').map(region => {
        // remove store(s) from region if it was reassigned to another
        const ids = region
          .get('storeIds')
          .filter(storeId => !storesIds.includes(storeId))
        return region
          .set('storeIds', ids)
          .set(
            'stores',
            region
              .get('stores')
              ?.filter(({ id: storeId }) => ids.includes(storeId))
          )
          .set('storesCount', ids.length)
      })
      const region = createRegions(
        payload.regions,
        payload.stores
      )

      const existingRegion = regions.find(
        o => o.get('id') === payload.regions[0].id
      )

      if (existingRegion) {
        const index = regions.indexOf(existingRegion)
        regions.splice(index, 1, region[0])
      } else {
        regions.push(region[0])
      }

      return state
        .set('regions', regions.map(o => o))
        .set('regionsLoading', false)
        .set('regionsFailed', null)
    }
    // ORM Upsert
    case ActionTypes.ORM_FETCH_AND_UPSERT_STORE_FULFILLED:
      return state
        .set(
          'storeUpsertLoading',
          state.get('storeUpsertLoading').set(payload.sId.toString(), false)
        )
        .set(
          'storeUpsertFailed',
          state.get('storeUpsertFailed').set(payload.sId.toString(), null)
        )
    case ActionTypes.ORM_FETCH_AND_UPSERT_STORE_PENDING:
      return state
        .set(
          'storeUpsertLoading',
          state.get('storeUpsertLoading').set(payload.sId.toString(), true)
        )
        .set(
          'storeUpsertFailed',
          state.get('storeUpsertFailed').set(payload.sId.toString(), null)
        )
    case ActionTypes.ORM_FETCH_AND_UPSERT_STORE_FAILED:
      return state
        .set(
          'storeUpsertLoading',
          state.get('storeUpsertLoading').set(payload.sId.toString(), false)
        )
        .set(
          'storeUpsertFailed',
          state
            .get('storeUpsertFailed')
            .set(payload.sId.toString(), payload.error)
        )
    // ORM Upsert Geometry
    case ActionTypes.ORM_FETCH_AND_UPSERT_GEOMETRY_FULFILLED:
      return state
        .set(
          'geometryUpsertLoading',
          state.get('geometryUpsertLoading').set(payload.sId.toString(), false)
        )
        .set(
          'geometryUpsertFailed',
          state.get('geometryUpsertFailed').set(payload.sId.toString(), null)
        )
    case ActionTypes.ORM_FETCH_AND_UPSERT_GEOMETRY_PENDING:
      return state
        .set(
          'geometryUpsertLoading',
          state.get('geometryUpsertLoading').set(payload.sId.toString(), true)
        )
        .set(
          'geometryUpsertFailed',
          state.get('geometryUpsertFailed').set(payload.sId.toString(), null)
        )
    case ActionTypes.ORM_FETCH_AND_UPSERT_GEOMETRY_FAILED:
      return state
        .set(
          'geometryUpsertLoading',
          state.get('geometryUpsertLoading').set(payload.sId.toString(), false)
        )
        .set(
          'geometryUpsertFailed',
          state
            .get('geometryUpsertFailed')
            .set(payload.sId.toString(), payload.error)
        )
    //
    case ActionTypes.FETCH_TEMPLATES_PENDING:
      return state.set('templatesFailed', null).set('templatesLoading', true)
    case ActionTypes.FETCH_TEMPLATES_FULFILLED:
      return state.set('templatesFailed', null).set('templatesLoading', false)
    case ActionTypes.FETCH_TEMPLATES_FAILED:
      return state
        .set('templatesFailed', payload.error)
        .set('templatesLoading', false)
    case ActionTypes.FETCH_TEMPLATES_START_TIME:
      return state.set('templatesStartTime', payload.time)
    // Secured Products
    case ActionTypes.FETCH_SECURED_PRODUCTS_FULFILLED:
      return state
        .set('securedProductsLoading', false)
        .set('securedProductsFailed', null)
    case ActionTypes.FETCH_SECURED_PRODUCTS_PENDING: {
      return state
        .set('securedProductsFailed', null)
        .set('securedProductsLoading', true)
    }
    case ActionTypes.FETCH_SECURED_PRODUCTS_FAILED:
      return state
        .set('securedProductsFailed', payload)
        .set('securedProductsLoading', false)
    // Schedules
    case ActionTypes.FETCH_SCHEDULES_FULFILLED:
      return state
        .set('schedules', createSchedules(payload.schedules))
        .set('schedulesLoading', false)
        .set('schedulesFailed', null)
    case ActionTypes.FETCH_SCHEDULES_PENDING: {
      return state.set('schedulesFailed', null).set('schedulesLoading', true)
    }
    case ActionTypes.FETCH_SCHEDULES_FAILED:
      return state
        .set('schedulesFailed', payload)
        .set('schedulesLoading', false)
    case ActionTypes.APP_RESET:
      return initialState
    default:
      return state
  }
}

export default appReducer
