import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import uuid from 'uuid'
import styles from '../../../styles/App.css'
import Checkbox from '../../../components/Switch'
import AssignUser from '../../../components/AssignUser'
import AddBackupKey from '../../../components/BackupKeys/Keys/AddKey'
import KeyInput from './KeyInput'
const StyledCheckbox = styled(Checkbox)`
  top: 6px;
`

export const TextTop = styled.span`
  font-size: 14px;
  text-align: left;
  width: 200px;
`

export class AddKeyScreen extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = { ...this.props, isKeysSaved: false }
  }

  componentDidUpdate() {
    const { id } = this.props
    if (this.keyId && this[`serialNumber${this.keyId}`]) {
      this[`serialNumber${this.keyId}`].serialNumber.focus()
    }

    $(`#modal-${id}`) // eslint-disable-line
      .off('hide.bs.modal')
      .on('hide.bs.modal', () => {
        if (!this.state.isKeysSaved) {
          this.cancelAll()
        }
      })
  }

  setNewKeys(newKeys, focusedKeyId) {
    if (newKeys && newKeys.length > 0) {
      this.keyId = focusedKeyId
    }
    this.setState({ newKeys })
  }

  setServerError(element, msg) {
    element.setCustomValidity(msg)
    const $element = $(element) // eslint-disable-line
    $element.attr('data-placement', 'bottom')
    $element.attr('data-content', msg)
    $element.popover('show')
    setTimeout(() => {
      $element.attr('data-content', '')
      $element.popover('disable')
    }, 3000)
  }

  setValidationErrors(validationErrors) {
    if (validationErrors.serialNumber) {
      const msg = validationErrors.serialNumber[0]
      const element = this.serialNumber // TODO: in case server validation error, pick element by key id
      this.setServerError(element, msg)
    }
  }

  // TODO: remove this, as it never gets called, and doesn't work even if it did.
  setServerValidity(error) {
    if (error && error.error) {
      const validationErrors = error.error.validationErrors
      if (validationErrors) {
        this.setValidationErrors(validationErrors)
      } else {
        // another server error
        console.log(error)
      }
    }
  }

  /* eslint-disable no-undef */
  close = () => {
    const { id } = this.props
    $(`#modal-${id}`).modal('hide') // eslint-disable-line
  }

  cancelAll = () => {
    this.props.deleteNewKeys()
    this.props.onClose()
  }

  submit = (e) => {
    e.preventDefault()
    this.setState({ isKeysSaved: true }, () => {
      this.close()
      this.props.onConfirm()
    })
  }

  keyInUseChanged = (keyIsUsed) => {
    this.setState({ keyIsUsed })
  }

  renderKey(item) {
    const {
      updateNewKey,
      deleteNewKey,
      onAssign,
      onUnassign,
      onProfile,
      isKeyInUse,
      backupKeyEnabled,
      maxBackupKeys,
      isKeysLimited,
      addNewKey,
    } = this.props
    const { id, status, backupKey } = item

    return (
      <tr className="border-bottom" key={id}>
        <td className={styles.tableItem} style={{ width: '210px' }}>
          <div className="form-group bmd-form-group">
            <KeyInput
              ref={(ref) => (this[`serialNumber${id}`] = ref)}
              isKeyInUse={isKeyInUse}
              updateNewKey={updateNewKey}
              item={item}
              keyInUseChanged={this.keyInUseChanged}
              addNewKey={addNewKey}
            />
          </div>
        </td>
        <td className={styles.tableItem}>
          <div className="form-group  bmd-form-group">
            <AssignUser
              item={item}
              onAssign={onAssign}
              onUnassign={onUnassign}
              onProfile={onProfile}
            />
          </div>
        </td>
        <td className={styles.tableItem} style={{ width: '500px' }}>
          <div className="form-group  bmd-form-group">
            <StyledCheckbox
              id={`${id}-checkbox`}
              checked={status === 1}
              disabled={!!backupKey}
              onChange={(checked) =>
                updateNewKey({ ...item, status: checked ? 1 : 0 })
              }
            />
            {backupKeyEnabled && (
              <AddBackupKey
                id={id}
                disabled={this.state.keyIsUsed || isKeysLimited}
                onChange={(checked) => {
                  updateNewKey({
                    ...item,
                    backupKey: checked ? 1 : 0,
                    status: checked ? 1 : 0,
                    userName: null,
                    userId: null,
                  })
                }}
                maxBackupKeys={maxBackupKeys}
                isKeysLimited={isKeysLimited}
              />
            )}
          </div>
        </td>
        <td className={styles.tableItem}>
          <div className="form-group  bmd-form-group">
            <button
              type="button"
              className="btn btn-dark btn-lg"
              aria-label="Close"
              onClick={() => deleteNewKey(item.id)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </td>
      </tr>
    )
  }

  render() {
    const { addNewKey, deleteNewKeys } = this.props
    const { newKeys } = this.state

    return (
      <div className="modal-content">
        <form
          style={{ textAlign: 'left' }}
          className="needs-validation was-validated"
          noValidate=""
          onSubmit={this.submit}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLongTitle">
              Add Key
            </h5>
            <div>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  this.cancelAll()
                  this.close()
                }}
              >
                Cancel All
              </button>
              <button
                type="submit"
                name="submit"
                className="btn btn-primary btn-raised"
              >
                Save All
              </button>
            </div>
          </div>
          <div className="modal-body">
            <div className="m-3">
              <div className="container">
                <div className="row">
                  <button
                    type="button"
                    className="btn btn-primary"
                    name="addNewKey"
                    onClick={() => addNewKey({ id: uuid.v4(), status: 0 })}
                  >
                    Add Key
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary ml-auto"
                    name="deleteNewKeys"
                    onClick={deleteNewKeys}
                  >
                    Remove All
                  </button>
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th className={styles.tableHeading}>Serial Number</th>
                        <th className={styles.tableHeading}>User Name</th>
                        <th className={styles.tableHeading}>Status</th>
                        <th className={styles.tableHeading} />
                      </tr>
                    </thead>
                    <tbody>
                      {(newKeys || []).map((item) => this.renderKey(item))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    )
  }
}

AddKeyScreen.propTypes = {
  id: PropTypes.string.isRequired,
  addNewKey: PropTypes.func.isRequired,
  updateNewKey: PropTypes.func.isRequired,
  deleteNewKey: PropTypes.func.isRequired,
  deleteNewKeys: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onAssign: PropTypes.func.isRequired,
  onUnassign: PropTypes.func.isRequired,
  onProfile: PropTypes.func.isRequired,
  isKeyInUse: PropTypes.func.isRequired,
  backupKeyEnabled: PropTypes.bool,
  maxBackupKeys: PropTypes.number,
  isKeysLimited: PropTypes.bool.isRequired,
}

export default AddKeyScreen
