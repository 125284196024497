import { sviFigureType } from '../../utils/mtiCanvasUtils'

export const originFixtureRectWidth = 72
export const originFixtureRectHeight = 72
export const originFixtureCircleRadius = 45
export const originQuarterCircleWidth = 90
export const originQuarterCircleHeight = 90
export const originExtQuarterCircleWidth = 72
export const originExtQuarterCircleHeight = 47

/**
 * Expected canvas size
 */
const canvasSize = {
  width: 600,
  height: 400,
}

/**
 * Canvas size used in design mockups
 */
const originCanvasSize = {
  width: 360,
  height: 240,
}

/**
 * Figure borders
 */
const borders = {
  vertical: 6,
  horizontal: 6,
}

/**
 * Get maximum allowable size for a figure based on its size and type
 *
 * @param width
 * @param height
 * @param type
 * @returns {object}
 */
export function getMaxSize(width, height, type = sviFigureType.custom) {
  let fixedWidth = width
  let fixedHeight = height
  const size = sviFigureSizes[type].veryLarge
  const maxWidth = size.width + size.width * 0.15
  const maxHeight = size.height + size.height * 0.15

  if (!isNaN(maxWidth) && width > maxWidth) {
    fixedWidth = maxWidth
  }
  if (!isNaN(maxHeight) && height > maxHeight) {
    fixedHeight = maxHeight
  }
  return {
    width: fixedWidth,
    height: fixedHeight,
  }
}

/**
 * Helper method used for getting the sizes which correspond to the aspect ratio
 *
 * @param width
 * @param height
 * @returns {{width: number, height: number}}
 */
const getAspectSize = (width, height) => {
  const sizeMultiplier = canvasSize.width / originCanvasSize.width

  return {
    width: width * sizeMultiplier - borders.horizontal * sizeMultiplier,
    height: height * sizeMultiplier - borders.vertical * sizeMultiplier,
  }
}

/**
 * Fixture figure sizes
 */
export const sviFigureSizes = {
  custom: {
    small: getAspectSize(20, 20),
    medium: getAspectSize(72, 72),
    large: getAspectSize(90, 90),
    veryLarge: getAspectSize(120, 120),
  },
  circle: {
    extraSmall: getAspectSize(45, 45),
    small: getAspectSize(60, 60),
    medium: getAspectSize(72, 72),
    large: getAspectSize(90, 90),
    veryLarge: getAspectSize(120, 120),
  },
  square: {
    small: getAspectSize(60, 60),
    medium: getAspectSize(72, 72),
    large: getAspectSize(90, 90),
    veryLarge: getAspectSize(120, 120),
  },
  narrowRectangle: {
    small: getAspectSize(72, 26),
    medium: getAspectSize(90, 31.5),
    large: getAspectSize(110, 38.5),
    veryLarge: getAspectSize(120, 42),
  },
  wideRectangle: {
    small: getAspectSize(60, 30),
    medium: getAspectSize(72, 36.5),
    large: getAspectSize(89, 44.5),
    veryLarge: getAspectSize(120, 60),
  },
  quarterCircle: {
    small: getAspectSize(60, 60),
    medium: getAspectSize(72, 72),
    large: getAspectSize(90, 90),
    veryLarge: getAspectSize(120, 120),
  },
  extendedQuarterCircle: {
    small: getAspectSize(55, 36),
    medium: getAspectSize(72, 47),
    large: getAspectSize(94, 61),
    veryLarge: getAspectSize(122, 80),
  },
  extendedRightQuarterCircle: {
    small: getAspectSize(55, 36),
    medium: getAspectSize(72, 47),
    large: getAspectSize(94, 61),
    veryLarge: getAspectSize(122, 80),
  },
  extendedLeftQuarterCircle: {
    small: getAspectSize(55, 36),
    medium: getAspectSize(72, 47),
    large: getAspectSize(94, 61),
    veryLarge: getAspectSize(122, 80),
  },
}
