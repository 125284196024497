/**
 *
 * Input Values
 * TODO: Do not hesitate to improve me
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import styles from './css/style.css'

class InputValues extends React.PureComponent {
  constructor(props) {
    super(props)
    const { value, values, existingValue } = props
    this.state = {
      isValid:
        existingValue || (values && values.indexOf(value) == -1) ? true : false,
    }
  }

  componentDidUpdate() {
    const { value, values, existingValue } = this.props
    this.input.value = value
    if ((values && values.indexOf(value) == -1) || value == existingValue) {
      // eslint-disable-next-line
      this.setState({ isValid: true }, () => this.setCustomValidity(''))
    } else {
      // eslint-disable-next-line
      this.setState({ isValid: false }, () =>
        this.setCustomValidity(this.validationMessage())
      )
    }
  }

  onChange = (value, values, existingValue) => {
    this.props.onChange(value)
    if (values.indexOf(value) == -1 || value == existingValue) {
      this.setState({ isValid: true }, () => this.setCustomValidity(''))
    } else {
      this.setState({ isValid: false }, () =>
        this.setCustomValidity(this.validationMessage())
      )
    }
  }

  setCustomValidity(msg) {
    const element = this.input
    if (msg === '') {
      element.setCustomValidity(msg)
    } else {
      element.setCustomValidity(msg)
    }
  }

  // eslint-disable-next-line
  setServerErrorDismiss(element) {
    // eslint-disable-next-line
    $(element).keyup(() => {
      $(element).attr('data-content', '') // eslint-disable-line
      $(element).popover('hide') // eslint-disable-line
    })
  }

  // eslint-disable-next-line
  setServerError(element, msg) {
    $(element).attr('data-placement', 'bottom') // eslint-disable-line
    $(element).attr('data-content', msg) // eslint-disable-line
    $(element).popover('show') // eslint-disable-line
    setTimeout(() => {
      $(element).attr('data-content', '') // eslint-disable-line
      $(element).popover('disable') // eslint-disable-line
    }, 3000)
  }

  isCustom() {
    return true
  }

  isValid() {
    return this.state.isValid
  }

  validationMessage = () => {
    const { isValid } = this.state

    if (!isValid) {
      return this.props.invalidMsg || 'Name must be unique'
    }

    return undefined
  }

  showInvalidMessage = () => this.setCustomValidity(this.validationMessage())

  addFocusClass = () => {
    this.formGroup.classList.add('is-focused')
  }

  removeFocusClass = () => {
    if (this.isFilled(this.props.value)) return
    this.formGroup.classList.remove('is-focused')
  }

  isFilled = (value) => !(value === null || value === undefined || value === '')

  render() {
    const {
      name,
      label,
      onClick,
      type,
      value,
      readOnly,
      disabled,
      values,
      existingValue,
    } = this.props
    const isValid = this.state.isValid
    return (
      <div
        className={`form-group bmd-form-group ${
          this.isFilled(value) ? 'is-focused' : null
        } ${styles.inputWrapper} ${readOnly ? styles.readonly : null} ${
          onClick ? styles.clickable : null
        }`}
        ref={(i) => {
          this.formGroup = i
        }}
      >
        <label htmlFor={name} className="bmd-label-floating">
          {label}
        </label>
        <div className={` ${isValid ? styles.valid : null} `}>
          <input
            key={name}
            type={type}
            className="form-control"
            id={name}
            defaultValue={value}
            onClick={onClick}
            onChange={({ target: value }) =>
              this.onChange(value.value, values, existingValue)
            }
            onFocus={this.addFocusClass}
            onBlur={this.removeFocusClass}
            ref={(i) => {
              this.input = i
            }}
            error={this.validationMessage()}
            disabled={disabled}
            readOnly={readOnly}
          />
          <div className="invalid-feedback">Name must be unique.</div>
        </div>
      </div>
    )
  }
}

InputValues.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  onInvalid: PropTypes.func,
  invalidMsg: PropTypes.string,
}

InputValues.defaultProps = {
  type: 'text',
  onInvalid: () => {},
  onChange: () => {},
}

export default InputValues
