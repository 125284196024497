/*
 * Filters Modal Constants
 */

import keyMirror from 'fbjs/lib/keyMirror'

/**
 * @constant {Object} ActionTypes
 * @memberof Constants
 */
export const ActionTypes = keyMirror({
  UPDATE_REPORT_COMBINATION: undefined,
  UPDATE_SELECTED_STORES: undefined,
  UPDATE_DATES: undefined,
  RESET_STORE_TO_DEFAULTS: undefined,
})
