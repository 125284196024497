/**
 *
 * Template Select Preloader
 *
 */

import React from 'react'
import ContentLoader from 'react-content-loader'
import uuid from 'uuid'

function TemplateSelectPreloader() {
  const Loader = (props) => {
    const loaderWidth = 800
    const firstColumnWidth = 65
    const secondСolumnWidth = 255
    const thirdСolumnWidth = 110
    return (
      <ContentLoader
        height="35"
        width={loaderWidth}
        speed={2}
        primaryColor="#d9d9d9"
        secondaryColor="#ecebeb"
        {...props}
      >
        <rect x="5" y="8" rx="6" ry="6" width="65" height="14" />
        <rect
          x={firstColumnWidth + 15}
          y="8"
          rx="6"
          ry="6"
          width={secondСolumnWidth}
          height="14"
        />

        <rect
          x={secondСolumnWidth + firstColumnWidth + 25}
          y="8"
          rx="6"
          ry="6"
          width={secondСolumnWidth}
          height="14"
        />
        <rect
          x={2 * secondСolumnWidth + firstColumnWidth + 35}
          y="8"
          rx="6"
          ry="6"
          width={firstColumnWidth}
          height="14"
        />

        <rect
          x={2 * secondСolumnWidth + 2 * firstColumnWidth + 45}
          y="8"
          rx="6"
          ry="6"
          width={thirdСolumnWidth}
          height="14"
        />
        <rect x="0" y="32" rx="6" ry="6" width={loaderWidth} height=".3" />
      </ContentLoader>
    )
  }
  const TemplateSelectPreloader = () => {
    const height = 525
    const countRows = parseInt(height / 35)
    return (
      <React.Fragment>
        {Array(countRows)
          .fill('')
          .map((e, i) => (
            <Loader
              key={`loader_${uuid.v4()}`}
              style={{ opacity: Number(2 / i).toFixed(1) }}
            />
          ))}
      </React.Fragment>
    )
  }
  return <TemplateSelectPreloader />
}

export default TemplateSelectPreloader
