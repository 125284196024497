import { DeviceType } from 'mti-jsclient-shared'

export const SOURCE_ATTRIBUTES = {
  live_devices: 'live_devices',
  positions: 'positions',
  product_ids: 'product_ids',
  position_parents: 'position_parents',
  layout_size: 'layout_size',
  modal_size: 'modal_size',
  layout_position: 'layout_position',
  security_device_type: 'security_device_type',
  security_device_assigned: 'security_device_assigned',
  live_product_ids: 'live_product_ids',
  port_products: 'port_products',
  port_live_products: 'port_live_products',
}

export const sourceAttrName = {
  live_devices: 'Live Devices',
  positions: 'Positions',
  product_ids: 'Products',
  position_parents: 'Position Rules',
  layout_size: 'Layout Size',
  modal_size: 'Modal Size',
  layout_position: 'Layout Position',
  security_device_type: 'Security Device Type',
  live_product_ids: 'Live Products',
  port_products: 'Port Products',
  port_live_products: 'Port Live Products',
}

export const CONDITIONS = {
  eq: 'eq',
  neq: 'neq',
  gt: 'gt',
  gte: 'gte',
  lt: 'lt',
  lte: 'lte',
  in: 'in',
  not_in: 'not_in',
  any: 'any',
  none: 'none',
}

export const conditionName = {
  eq: 'Equal', // 'exactly',
  neq: 'Not Equal', // 'anything but',
  gt: 'more than',
  gte: 'at least',
  lt: 'less than',
  lte: 'at most',
  in: 'In the List ', // 'value in list of',
  not_in: 'Not in the List', // 'value not in list of',
  any: 'In the List',
  none: 'Not in the List',
}

export const PARAMETER_TYPES = {
  integer: 'integer',
  string: 'string',
  integer_array: 'integer_array',
  string_array: 'string_array',
}

export const PARAMETER_TYPE_CONTROL = {
  text: 'text',
  number: 'number',
  deviceType: 'deviceType',
  products: 'products',
}

export const PRODUCT_RULE_SOURCE_ATTRIBUTES = {
  live_product_ids: 'live_product_ids',
  product_ids: 'product_ids',
}

export const SECURITY_DEVICES = [
  'base',
  'base_v2',
  'lock',
  'secure_plug',
  'core_iii_one_port',
  'core_iii_four_port',
  'proximity_base',
  'di_riser',
]

export const MULTI_PORT_COMPLIANCE_DEVICE = [DeviceType.CX_FLEX_FOUR_PORT]

export const NUMBER_OF_PORTS = {
  core_iii_four_port: 4,
}

// TODO: [WIP] There is a big chance it does not cover all kind of rule types, it needs to be improved and extended
export const FixtureRuleSourceAttributesModel = {
  product_ids: {
    conditions: [CONDITIONS.in, CONDITIONS.not_in],
    paramType: PARAMETER_TYPES.integer_array,
    paramTypeControl: PARAMETER_TYPE_CONTROL.products,
  },
  live_product_ids: {
    conditions: [CONDITIONS.in, CONDITIONS.not_in],
    paramType: PARAMETER_TYPES.integer_array,
    paramTypeControl: PARAMETER_TYPE_CONTROL.products,
  },
  positions: {
    conditions: [
      CONDITIONS.eq,
      CONDITIONS.neq,
      CONDITIONS.gt,
      CONDITIONS.gte,
      CONDITIONS.lt,
      CONDITIONS.lte,
    ],
    paramType: PARAMETER_TYPES.integer,
    paramTypeControl: PARAMETER_TYPE_CONTROL.number,
  },
}

export const PositionRuleSourceAttributesModel = {
  product_ids: {
    conditions: [CONDITIONS.in, CONDITIONS.not_in],
    paramType: PARAMETER_TYPES.integer_array,
    paramTypeControl: PARAMETER_TYPE_CONTROL.products,
  },
  live_product_ids: {
    conditions: [CONDITIONS.in, CONDITIONS.not_in],
    paramType: PARAMETER_TYPES.integer_array,
    paramTypeControl: PARAMETER_TYPE_CONTROL.products,
  },
  // live_devices: {
  //   conditions: [
  //     CONDITIONS.eq,
  //     CONDITIONS.neq,
  //     CONDITIONS.gt,
  //     CONDITIONS.gte,
  //     CONDITIONS.lt,
  //     CONDITIONS.lte,
  //   ],
  //   paramType: PARAMETER_TYPES.integer,
  //   paramTypeControl: PARAMETER_TYPE_CONTROL.number,
  // },
  security_device_type: {
    conditions: [CONDITIONS.eq, CONDITIONS.neq],
    paramType: PARAMETER_TYPES.string,
    paramTypeControl: PARAMETER_TYPE_CONTROL.deviceType,
  },
}

export const PortRulesSourceAttributesModel = {
  port_products: {
    conditions: [CONDITIONS.any, CONDITIONS.none],
    paramType: PARAMETER_TYPES.integer_array,
    paramTypeControl: PARAMETER_TYPE_CONTROL.products,
  },
  port_live_products: {
    conditions: [CONDITIONS.any, CONDITIONS.none],
    paramType: PARAMETER_TYPES.integer_array,
    paramTypeControl: PARAMETER_TYPE_CONTROL.products,
  },
}
