// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2S1_IpKLcZsLe5v5ekPZxZ {\n  width: 100%;\n}\n\n._3BycRrtIkYdpBCeZTrzGoN label {\n  color: rgba(0,0,0,.50) !important;\n}\n\n._3BycRrtIkYdpBCeZTrzGoN input {\n  background-color: white !important;\n}\n\n._2f6LUGP9eYP32zzTj1ZThx input {\n  cursor: pointer !important;\n}\n\n._6jsdJqQMaW1qqFvjy-k9n input {\n  margin-bottom: 39px;\n}", "",{"version":3,"sources":["webpack://app/components/InputValues/css/style.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,iCAAiC;AACnC;;AAEA;EACE,kCAAkC;AACpC;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".inputWrapper {\n  width: 100%;\n}\n\n.readonly label {\n  color: rgba(0,0,0,.50) !important;\n}\n\n.readonly input {\n  background-color: white !important;\n}\n\n.clickable input {\n  cursor: pointer !important;\n}\n\n.valid input {\n  margin-bottom: 39px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputWrapper": "_2S1_IpKLcZsLe5v5ekPZxZ",
	"readonly": "_3BycRrtIkYdpBCeZTrzGoN",
	"clickable": "_2f6LUGP9eYP32zzTj1ZThx",
	"valid": "_6jsdJqQMaW1qqFvjy-k9n"
};
export default ___CSS_LOADER_EXPORT___;
