import React from 'react'
import PropTypes from 'prop-types'
import ReactTable from 'react-table'
import { ButtonsContainer } from '../RebootHubModal'
import { ModalBody } from '../../StoresPage/CreateEditStoreModal/index.screen'
import ProgressIndicatorModal from '../../../components/ProgressIndicatorModal'
import ErrorModal from '../../../components/ErrorModal'
import { CellContainer, renderHeaderCell } from '../../UsersPage/index.screen'

const UpdateNodesStatusScreen = ({
  id,
  updateNodesStatus,
  updating,
  updateError,
  onClose,
}) => {
  const renderStatusTable = () => {
    const columns = [
      {
        id: 'key',
        Header: () => renderHeaderCell('Key'),
        Cell: ({ original: { key } }) => <CellContainer>{key}</CellContainer>, // eslint-disable-line
        accessor: 'key',
      },
      {
        id: 'comment',
        Header: () => renderHeaderCell('Comment'),
        Cell: (
          { original: { comment } } // eslint-disable-line
        ) => <CellContainer>{comment}</CellContainer>,
        accessor: 'comment',
      },
      {
        id: 'duration',
        width: 80,
        Header: () => renderHeaderCell('Duration'),
        Cell: (
          { original: { duration } } // eslint-disable-line
        ) => <CellContainer>{duration}</CellContainer>,
        accessor: 'duration',
      },
      {
        id: 'name',
        Header: () => renderHeaderCell('Name'),
        Cell: (
          { original: { name } } // eslint-disable-line
        ) => <CellContainer>{name}</CellContainer>,
        accessor: 'name',
      },
      {
        id: 'result',
        width: 60,
        Header: () => renderHeaderCell('Result'),
        Cell: (
          { original: { result } } // eslint-disable-line
        ) => <CellContainer>{`${result ? 'True' : 'False'}`}</CellContainer>,
      },
    ]

    if (!updateNodesStatus || updateNodesStatus.length < 1) {
      return <div>Hub Is Offline</div>
    }
    return (
      <ReactTable
        data={updateNodesStatus}
        columns={columns}
        defaultPageSize={5}
      />
    )
  }

  if (updating) return <ProgressIndicatorModal text="Updating..." />

  if (updateError) {
    return <ErrorModal text={`Update Error: ${updateError.message}`} />
  }
  if (updateNodesStatus === false) {
    return <ErrorModal text="HUB is Offline" />
  }
  if (!updateNodesStatus) return null

  return (
    <div className="modal-content">
      <div className="modal-header border-bottom">
        <h5 className="modal-title" id="exampleModalLongTitle">
          Update Nodes Status
        </h5>
        <ButtonsContainer>
          <button
            type="button"
            className="btn btn-primary btn-raised"
            onClick={() => {
              $(`#modal-${id}`).modal('hide') // eslint-disable-line
              onClose()
            }}
          >
            Close
          </button>
        </ButtonsContainer>
      </div>
      <div>
        <ModalBody className="modal-body">{renderStatusTable()}</ModalBody>
      </div>
    </div>
  )
}

UpdateNodesStatusScreen.propTypes = {
  id: PropTypes.string.isRequired,
  updateNodesStatus: PropTypes.any,
  updating: PropTypes.bool,
  updateError: PropTypes.object,
  onClose: PropTypes.func.isRequired,
}

export default UpdateNodesStatusScreen
