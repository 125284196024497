/**
 *
 * ControlledSelect
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import styles from '../Select/css/style.css'

const ControlledSelect = ({
  name,
  label,
  onChange,
  value,
  children,
  disabled,
  customStyle,
}) => {
  const className = label
    ? `${className} ${customStyle ||
        styles.fixPadding} form-group bmd-form-group`
    : styles.selectWrapper
  const inputClassName = label
    ? `${inputClassName} ${styles.selectWithLabel} `
    : `form-control ${styles.select}`

  return (
    <div className={className}>
      {label && <label htmlFor={name}>{label}</label>}
      <select
        className={inputClassName}
        style={disabled ? { backgroundImage: 'none' } : {}}
        id={name}
        key={name}
        value={value}
        disabled={disabled}
        onChange={onChange}
      >
        {children}
      </select>
    </div>
  )
}

ControlledSelect.propTypes = {
  children: PropTypes.any.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.any,
  disabled: PropTypes.bool,
  customStyle: PropTypes.string,
  onChange: PropTypes.func,
}

export default ControlledSelect
