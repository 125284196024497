/*
 *
 * FixtureCanvas reducer
 *
 */

import { fromJS } from 'immutable'
import { loadingType as loading } from 'utils/utils'
import { ActionTypes } from './constants'

const initialState = fromJS({
  selectedId: -1,
  positions: [],
  fixture: {},
  canvasObject: {
    objects: [],
    background: '',
    isInitial: true,
  },
  isStatic: true,
  loading: loading.silent,
})

function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case ActionTypes.STORE_FIXTURE_LOADING:
      return state.set('loading', payload)
    case ActionTypes.STORE_FIXTURE_PROTOTYPES:
      return state.set('prototypes', payload)
    case ActionTypes.STORE_PARAMS:
      return state
        .set('screen', payload.screen)
        .set('isStatic', payload.isStatic)
    case ActionTypes.POST_FIXTURE_CANVAS_FINISHED: {
      return state
        .set('failed', null)
        .set('isStatic', payload.isStatic)
        .updateIn(['canvasObject', 'objects'], (objects) =>
          objects.update(
            objects.findIndex(
              (item) => item.get('id') === (payload.oldPId || payload.pId)
            ),
            (obj) => {
              if (!obj) return obj
              return obj.set('id', payload.pId || obj.get('id'))
            }
          )
        )
        .updateIn(['canvasObject', 'objects'], (objects) =>
          objects.update(
            objects.findIndex(
              (item) => item.get('id') === (payload.oldPId || payload.pId)
            ),
            (obj) => {
              if (!obj) return obj
              return obj.set('parentId', payload.parentId)
            }
          )
        )
      //.set('selectedId', selectedId || -1)
    }
    case ActionTypes.POST_FIXTURE_CANVAS_PENDING:
      return state.set('failed', null)
    case ActionTypes.POST_FIXTURE_CANVAS_FAILED:
      return state.set('failed', payload)
    case ActionTypes.SAVE_FIXTURE_POSITION:
      return state.set('selectedId', -1)
    case ActionTypes.FIXTURE_CANVAS_POSITION_REMOVE:
      return state
        .updateIn(['canvasObject', 'objects'], (objects) =>
          objects.filter((obj) => obj.get('id') !== payload.selectedId)
        )
        .set('selectedId', -1)
    case ActionTypes.AUTO_PLACEMENT_FULFILLED: {
      const { canvas: { canvasObject = {} } = {} } = payload
      return state.set('canvasObject', fromJS(canvasObject))
    }
    case ActionTypes.FETCH_FIXTURE_CANVAS_FULFILLED: {
      const {
        canvas: { canvasObject = {} } = {},
        selectedId,
        positions = [],
        fixture,
        isStatic,
      } = payload

      return state
        .set('canvasObject', fromJS(canvasObject))
        .set('selectedId', selectedId || -1)
        .set('positions', positions)
        .set('fixture', fixture)
        .set('failed', null)
        .set('isStatic', isStatic)
    }
    case ActionTypes.FETCH_FIXTURE_CANVAS_PENDING:
      return state.set('failed', null)
    case ActionTypes.FETCH_FIXTURE_CANVAS_FAILED:
      return state.set('failed', payload)
    case ActionTypes.FIXTURE_CANVAS_CHANGED: {
      const isStaticPayload = payload.isStatic
      // const prevSelectedId = state.get('selectedId')
      const isStatic = !isStaticPayload
        ? isStaticPayload
        : state.get('isStatic')
      const selectedId = payload.selectedId

      return state
        .updateIn(['canvasObject', 'objects'], () =>
          fromJS(payload.canvasObject).get('objects')
        )
        .set('selectedId', selectedId)
        .set('isStatic', isStatic)
    }
    case ActionTypes.FIXTURE_CANVAS_NAME_CHANGED:
      return state.updateIn(['canvasObject', 'objects'], (objects) =>
        objects.update(
          objects.findIndex(
            (item) => item.get('id') === state.get('selectedId')
          ),
          (obj) => obj.set('name', payload)
        )
      )
    case ActionTypes.SELECT_POSITION: {
      // TODO: remove
      console.warn('SELECT_POSITION', payload)
      break
    }
    case ActionTypes.SAVE_FIXTURE_FROM_MOBILE:
      return state.set('fixtureMobile', payload)
    default:
      return state
  }
}

export default reducer
