/*
 *
 * TemplatePositions sagas
 *
 */

import { call, put, takeLatest, select, all } from 'redux-saga/effects'
import {
  modelActionCreators,
  DeviceType,
  ArmState,
  PositionRuleStatus,
  HealthStatus,
} from 'mti-jsclient-shared'
import {
  postPosition,
  patchPosition,
  deletePosition,
  getTemplate,
  postPositionRule,
  patchPositionRule,
  deletePositionRule,
  getProducts,
  postFixtureRule,
  patchFixtureRule,
  deleteFixtureRule as deleteFixtureRuleRequest,
} from '../../api'
import { upsert } from '../App/sagas/ormSaga'
import { ActionTypes } from './constants'
import { ActionTypes as FixtureActionTypes } from '../TemplateFixture/constants'
import {
  fixtureTemplateDataFailed,
  fixtureTemplateDataFulfilled,
  fetchFixtureTemplateCanvasFulfilled,
  fixtureTemplateDataPending,
  postFixtureTemplateFinished,
  updateRulePending,
  updateRuleFinished,
  updateRuleFailed,
  storePrototypes,
  storeParams,
  templateAutoPlacementFulfilled,
} from './actions'
import { selectToken, selectOrganizationID } from '../App/selectors'
import {
  makeSelectFixtureTemplate,
  makeSelectPrototypes,
  makeSelectSelectedPosition,
} from './selectors'
import {
  toFabricSync,
  updateWithSviType,
  getPrototypes as getStaticPrototypes,
} from '../FixturePage/utils'
import { fromFabricPosition } from '../FixturePage/utilsSave'
import { getEditPrototypes } from '../FixturePage/utilsEdit'
import {
  getAutoplacement,
  setTypeBasedOnAspect,
} from '../FixturePage/utilsAutoplacement'
import {
  filterLayoutPositionGeometry,
  apiFixGeometryResourceId,
  fixFixtureRulesFieldName,
} from '../../utils/mtiUtils'
import { errorToast, successToast } from '../../utils/utils'
import { loadTemplates } from '../Templates/actions'

export function* loadFixtureTemplate({ payload }) {
  const { screen, isStatic, selectedId = -1, tId } = payload
  const token = yield select(selectToken)
  try {
    yield put(fixtureTemplateDataPending())
    const prototypes = yield getPrototypes()
    yield put(storeParams({ screen, isStatic }))
    yield put(storePrototypes(prototypes))

    const data = yield call(getTemplate, token, tId)
    const store = fixFixtureRulesFieldName(
      filterLayoutPositionGeometry(apiFixGeometryResourceId(data, 'fixtures'))
    )
    yield call(upsert, { payload: store })
    const orgID = yield select(selectOrganizationID)

    const products = yield call(getProducts, token, null, null, orgID)
    yield put(modelActionCreators.upsertManufacturers(products.manufacturers))
    yield put(modelActionCreators.upsertProducts(products.products))

    let fixture = yield select(makeSelectFixtureTemplate(tId))

    const canvas = yield toFabricSync(
      fixture,
      screen,
      isStatic,
      selectedId,
      prototypes,
      {
        DeviceType,
        ArmState,
        PositionRuleStatus,
        HealthStatus,
      }
    )

    yield put(
      fetchFixtureTemplateCanvasFulfilled(
        -1, // sId,
        canvas,
        selectedId,
        (fixture || {}).positions,
        updateWithSviType(fixture),
        isStatic
      )
    )
    yield put(fixtureTemplateDataFulfilled())
  } catch (error) {
    console.warn(error)
    const res = error.response
    const errorObj = res ? yield call([res, res.json]) : error
    yield put(fixtureTemplateDataFailed(errorObj))
    errorToast('Load fixture template failed')
  }
}

function* getPrototypes() {
  const protos = yield getStaticPrototypes()
  const protosEdit = yield getEditPrototypes()
  protos.positions = { ...protos.positions, ...protosEdit.positions }
  return protos
}

export function* saveAllPositions({ payload }) {
  const { canvasObject: { objects = [] } } = payload
  yield all(
    objects
      .slice(1)
      .map((object) =>
        call(savePosition, { payload: { ...payload, selectedId: object.id } })
      )
  )
}

export function* savePosition({ payload }) {
  const { canvasObject: { objects = [] }, tId, selectedId, screen } = payload
  const token = yield select(selectToken)
  try {
    // yield put(fixtureTemplateDataPending())
    const position = fromFabricPosition(
      objects.find(({ id }) => id === selectedId),
      screen
    )
    if (!position) {
      console.error('savePosition: No Position')
      return
    }
    let store
    if (isNaN(position.id)) {
      store = yield call(postPosition, token, position, tId)
      position.oldId = position.id
      position.id = store.positions[0].id
      successToast('Position Created')
    } else {
      store = yield call(patchPosition, token, position, tId)
      successToast('Position Updated')
    }

    yield call(upsert, { payload: store })
    yield put(
      postFixtureTemplateFinished({
        pId: position.id,
        oldPId: position.oldId,
      })
    )

    yield put(loadTemplates(true, tId)) // Sync templates for usage
  } catch (error) {
    console.warn(error)
    const res = error.response
    const errorObj = res ? yield call([res, res.json]) : error
    yield put(fixtureTemplateDataFailed(errorObj))
    errorToast('Save position failed')
  }
}

export function* removePosition({ payload }) {
  const { canvasObject: { objects = [] }, selectedId, tId } = payload
  const position = objects.find(({ id }) => id === selectedId)
  const token = yield select(selectToken)
  try {
    if (!isNaN(position.id)) {
      // yield put(fixtureTemplateDataPending())
      const data = yield call(deletePosition, token, position)
      successToast('Position Deleted')
      console.log('removePosition response:', data)
      // yield put(updateStore(yield call(getTemplates, token)))
      yield put(modelActionCreators.deletePositionWithId(position.id))
      // yield put(fixtureTemplateDataFulfilled())
    }

    yield put(loadTemplates(true, tId)) // Sync templates for usage
  } catch (error) {
    console.warn(error)
    const res = error.response
    const errorObj = res ? yield call([res, res.json]) : error
    yield put(fixtureTemplateDataFailed(errorObj))
    errorToast('Delete position failed')
  }
}

export function* createTemplatePositionRule({ payload }) {
  const { positionId, rule, tId } = payload
  const token = yield select(selectToken)
  try {
    yield put(fixtureTemplateDataPending())
    const data = yield call(postPositionRule, token, positionId, rule)
    successToast('Rule Created')
    yield call(upsert, { payload: data })

    yield put(fixtureTemplateDataFulfilled())
    yield call(loadFixtureTemplate, {
      payload: { ...payload, selectedId: -1, isStatic: true },
    })
    yield put(loadTemplates(true, tId)) // Sync templates for usage as parents of the store fixtures
  } catch (error) {
    console.warn(error)
    yield put(fixtureTemplateDataFailed(error))
    errorToast('Create rule failed')
  }
}

export function* editTemplatePositionRule({ payload }) {
  const { positionId, rule, tId } = payload
  const currentPosition = yield select(makeSelectSelectedPosition())
  const oldRules = (currentPosition.rules || []).filter(
    ({ id }) => id === rule.id
  )
  const token = yield select(selectToken)
  try {
    if (
      oldRules.length &&
      oldRules[0].sourceAttribute !== rule.sourceAttribute
    ) {
      // remove old
      yield put(updateRulePending(oldRules[0].id))
      const deleteRuleData = yield call(
        deletePositionRule,
        token,
        positionId,
        oldRules[0].id
      )
      successToast('Rule Deleted')
      yield call(upsert, { payload: deleteRuleData })
      yield put(updateRuleFinished(oldRules[0].id))

      // create
      yield put(fixtureTemplateDataPending())
      const data = yield call(postPositionRule, token, positionId, rule)
      successToast('Rule Created')
      yield call(upsert, { payload: data })

      yield put(fixtureTemplateDataFulfilled())
      yield call(loadFixtureTemplate, {
        payload: { ...payload, selectedId: -1, isStatic: true },
      })
      yield put(loadTemplates(true, tId)) // Sync templates for usage as parents of the store fixtures

      return
    }
    yield put(updateRulePending(rule.id))
    const data = yield call(patchPositionRule, token, positionId, rule)
    successToast('Rule Updated')
    yield call(upsert, { payload: data })

    yield put(updateRuleFinished(rule.id))
    yield call(loadFixtureTemplate, {
      payload: { ...payload, selectedId: -1, isStatic: true },
    })
    yield put(loadTemplates(true, tId)) // Sync templates for usage as parents of the store fixtures
  } catch (error) {
    console.warn(error)
    yield put(updateRuleFailed(error))
    errorToast('Update rule failed')
  }
}

export function* deleteTemplatePositionRule({ payload }) {
  const { positionId, rule, tId } = payload
  const token = yield select(selectToken)
  try {
    yield put(updateRulePending(rule.id))
    const data = yield call(deletePositionRule, token, positionId, rule.id)
    successToast('Rule Deleted')
    yield call(upsert, { payload: data })

    yield put(updateRuleFinished(rule.id))
    yield call(loadFixtureTemplate, {
      payload: { ...payload, selectedId: -1, isStatic: true },
    })
    yield put(loadTemplates(true, tId)) // Sync templates for usage as parents of the store fixtures
  } catch (error) {
    console.warn(error)
    yield put(updateRuleFailed(error))
    errorToast('Delete rule failed')
  }
}

export function* deleteTemplatePositionRules({ payload }) {
  const { position: { id, rules }, tId } = payload
  const token = yield select(selectToken)
  try {
    for (let i = 0; i < (rules || []).length; i++) {
      yield put(updateRulePending(rules[i].id))
    }
    for (let i = 0; i < (rules || []).length; i++) {
      const rule = rules[i]
      const data = yield call(deletePositionRule, token, id, rule.id)
      yield call(upsert, { payload: data })
      yield put(updateRuleFinished(rule.id))
    }
    successToast('Rules Deleted')
    yield call(loadFixtureTemplate, {
      payload: { ...payload, selectedId: -1, isStatic: true },
    })
    yield put(loadTemplates(true, tId)) // Sync templates for usage as parents of the store fixtures
  } catch (error) {
    console.warn(error)
    yield put(fixtureTemplateDataFailed(error))
    errorToast('Delete rules failed')
  }
}

function* createFixtureRule({ payload }) {
  const { fixtureId, rule } = payload
  try {
    yield put(fixtureTemplateDataPending())
    const token = yield select(selectToken)
    const data = yield call(postFixtureRule, token, fixtureId, rule)
    successToast('Rule Created')
    yield call(upsert, { payload: data })
    yield put(fixtureTemplateDataFulfilled())
    yield put(loadTemplates(true, fixtureId)) // Sync templates for usage as parents of the store fixtures
  } catch (error) {
    console.warn(error)
    yield put(fixtureTemplateDataFailed(error))
    errorToast('Create rule failed')
  }
}

function* editFixtureRule({ payload }) {
  const { fixtureId, rule } = payload

  let fixture = yield select(makeSelectFixtureTemplate(fixtureId))
  const oldRules = (fixture.rules || []).filter(({ id }) => id === rule.id)

  const token = yield select(selectToken)

  try {
    if (
      oldRules.length &&
      oldRules[0].sourceAttribute !== rule.sourceAttribute
    ) {
      // remove old
      yield put(updateRulePending(oldRules[0].id))
      const deleteRuleData = yield call(
        deleteFixtureRuleRequest,
        token,
        fixtureId,
        oldRules[0].id
      )
      successToast('Rule Deleted')
      yield call(upsert, { payload: deleteRuleData })
      yield put(updateRuleFinished(oldRules[0].id))

      // create
      yield put(fixtureTemplateDataPending())
      const data = yield call(postFixtureRule, token, fixtureId, rule)
      successToast('Rule Created')
      yield call(upsert, { payload: data })

      yield put(fixtureTemplateDataFulfilled())
      yield put(loadTemplates(true, fixtureId)) // Sync templates for usage as parents of the store fixtures

      return
    }

    yield put(updateRulePending(rule.id))
    const data = yield call(patchFixtureRule, token, fixtureId, rule)
    successToast('Rule Updated')
    yield call(upsert, { payload: data })
    yield put(updateRuleFinished(rule.id))
    yield put(loadTemplates(true, fixtureId)) // Sync templates for usage as parents of the store fixtures
  } catch (error) {
    console.warn(error)
    yield put(updateRuleFailed(rule.id, error))
    errorToast('Update rule failed')
  }
}

function* deleteFixtureRule({ payload }) {
  const { fixtureId, rule } = payload
  if (!rule) {
    console.warn('deleteFixtureRule rule === undefined')
    return
  }
  try {
    yield put(updateRulePending(rule.id))
    const token = yield select(selectToken)
    const data = yield call(deleteFixtureRuleRequest, token, fixtureId, rule.id)
    successToast('Rule Deleted')
    yield call(upsert, { payload: data })
    yield put(updateRuleFinished(rule.id))
    yield put(loadTemplates(true, fixtureId)) // Sync templates for usage as parents of the store fixtures
  } catch (error) {
    console.warn(error)
    yield put(updateRuleFailed(error))
    errorToast('Delete rule failed')
  }
}

function* deleteAllFixtureRules({ payload }) {
  try {
    const token = yield select(selectToken)
    const { fixtureComplianceRules: rules, fixtureId } = payload
    for (let i = 0; i < (rules || []).length; i++) {
      yield put(updateRulePending(rules[i].id))
    }
    for (let i = 0; i < (rules || []).length; i++) {
      const rule = rules[i]
      const data = yield call(
        deleteFixtureRuleRequest,
        token,
        fixtureId,
        rule.id
      )
      yield call(upsert, { payload: data })
      yield put(updateRuleFinished(rule.id))
    }
    successToast('Rules Deleted')
    yield put(loadTemplates(true, fixtureId)) // Sync templates for usage as parents of the store fixtures
  } catch (error) {
    console.warn(error)
    yield put(fixtureTemplateDataFailed(error))
    errorToast('Delete rules failed')
  }
}

export function* autoplacement({ payload }) {
  const { screen, count } = payload
  let prototypes = yield select(makeSelectPrototypes())
  if (!prototypes) {
    prototypes = yield getPrototypes()
  }
  const fixture = setTypeBasedOnAspect(payload.fixture)
  const canvas = yield getAutoplacement(
    fixture,
    screen,
    count,
    false,
    prototypes
  )
  yield put(templateAutoPlacementFulfilled(canvas))
}

export default function* root() {
  yield all([
    yield takeLatest(
      ActionTypes.LOAD_FIXTURE_TEMPLATE_CANVAS,
      loadFixtureTemplate
    ),
    yield takeLatest(ActionTypes.SAVE_FIXTURE_TEMPLATE_POSITION, savePosition),
    yield takeLatest(
      ActionTypes.SAVE_TEMPLATE_FIXTURE_ALL_POSITIONS,
      saveAllPositions
    ),
    yield takeLatest(
      ActionTypes.FIXTURE_TEMPLATE_CANVAS_POSITION_REMOVE,
      removePosition
    ),
    yield takeLatest(
      ActionTypes.CREATE_POSITION_RULE,
      createTemplatePositionRule
    ),
    yield takeLatest(ActionTypes.EDIT_POSITION_RULE, editTemplatePositionRule),
    yield takeLatest(
      ActionTypes.DELETE_POSITION_RULE,
      deleteTemplatePositionRule
    ),
    yield takeLatest(
      ActionTypes.DELETE_ALL_POSITION_RULES,
      deleteTemplatePositionRules
    ),
    yield takeLatest(FixtureActionTypes.CREATE_FIXTURE_RULE, createFixtureRule),
    yield takeLatest(FixtureActionTypes.EDIT_FIXTURE_RULE, editFixtureRule),
    yield takeLatest(FixtureActionTypes.DELETE_FIXTURE_RULE, deleteFixtureRule),
    yield takeLatest(
      FixtureActionTypes.DELETE_ALL_FIXTURE_RULES,
      deleteAllFixtureRules
    ),

    yield takeLatest(
      ActionTypes.TEMPLATE_CANVAS_POSITION_COUNT_CHANGED,
      autoplacement
    ),
  ])
}
