import { onOverlapInteractive } from './interactive'
import { onMoveObject, onModifiedMeasurer } from './measurer'

export function setOverlapMethod(
  type,
  canvas,
  canvasWidth,
  canvasHeight,
  disableOverlap
) {
  switch (type) {
    case 'highlight': {
      canvas.off('object:moving')
      canvas.off('object:scaling')
      canvas.off('object:rotating')
      canvas.on({
        'object:moving': (options) => onOverlapHighlight(options, canvas),
        'object:scaling': (options) => onOverlapHighlight(options, canvas),
        'object:rotating': (options) => onOverlapHighlight(options, canvas),
      })
      break
    }
    case 'simple': {
      canvas.off('object:moving')
      canvas.on('object:moving', (event) => onOverlapSimple(event.target))
      break
    }
    case 'interactive': {
      canvas.off('object:moving')
      canvas.on('object:moving', (options) =>
        onOverlapInteractive(options, canvas, canvasWidth, canvasHeight)
      )
      break
    }
    case 'measurer': {
      canvas.off('object:moving')
      canvas.on('object:moving', (options) =>
        onMoveObject(options, canvas, canvasWidth, canvasHeight, disableOverlap)
      )
      canvas.off('object:modified')
      canvas.on('object:modified', () => onModifiedMeasurer(canvas))
      break
    }
    default:
    // none
  }
}

export function onOverlapSimple(activeObject) {
  activeObject.setCoords()
  if (typeof activeObject.refreshLast !== 'boolean') {
    activeObject.set('refreshLast', true)
  }

  // loop canvas objects
  activeObject.canvas.forEachObject((targ) => {
    if (targ === activeObject) return // bypass self

    // check intersections with every object in canvas
    if (
      activeObject.intersectsWithObject(targ) ||
      activeObject.isContainedWithinObject(targ) ||
      targ.isContainedWithinObject(activeObject)
    ) {
      // objects are intersecting - deny saving last non-intersection position and break loop
      if (typeof activeObject.lastLeft === 'number') {
        activeObject.set('left', activeObject.lastLeft)
        activeObject.set('top', activeObject.lastTop)
        activeObject.set('refreshLast', false)
      }
    } else {
      activeObject.set('refreshLast', true)
    }
  })

  if (activeObject.refreshLast) {
    // save last non-intersecting position if possible
    activeObject.set('lastLeft', activeObject.left)
    activeObject.set('lastTop', activeObject.top)
  }
}

export function onOverlapHighlight(options, fabricCanvas) {
  options.target.setCoords()
  fabricCanvas.forEachObject((obj) => {
    if (obj === options.target) return
    obj.set('opacity', options.target.intersectsWithObject(obj) ? 0.5 : 1)
  })
}

export function rescale(canvasObject, factor) {
  return {
    ...canvasObject,
    objects: canvasObject.objects.map((o) => {
      const attrs = {
        scaleX: o.scaleX * factor,
        scaleY: o.scaleY * factor,
        left: o.left * factor,
        top: o.top * factor,
      }
      return { ...o, ...attrs }
    }),
  }
}
