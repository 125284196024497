import React from 'react'
import PropTypes from 'prop-types'
import ListOfLinks from '../../../components/ListOfLinks'
import { injectIntl } from 'react-intl'

const CorporateUserScreen = ({
  quickActionsItems,
  onItem,
  height,
  history,
  intl,
}) => (
  <ListOfLinks
    items={quickActionsItems}
    headerTitle={intl.formatMessage({ id: 'components.navigation.title' })}
    height={height}
    onItem={onItem}
    history={history}
  />
)

CorporateUserScreen.propTypes = {
  quickActionsItems: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  onItem: PropTypes.func,
  height: PropTypes.number,
}

export default injectIntl(CorporateUserScreen)
