import React from 'react'
import PropTypes from 'prop-types'
import styles from '../../styles/App.css'

function ErrorMessage(props) {
  return (
    <div
      className={`${styles.form__errorWrapper} ${styles.jsForm__errAnimation}`}
    >
      <p className={styles.form__error}>{props.error}</p>
    </div>
  )
}

ErrorMessage.propTypes = {
  error: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
}

export default ErrorMessage
