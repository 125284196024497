/*
 * Exceptions Constants
 */

import keyMirror from 'fbjs/lib/keyMirror'

/**
 * @constant {Object} ActionTypes
 * @memberof Constants
 */
export const ActionTypes = keyMirror({
  FETCH_EXCEPTIONS_FAILED: undefined,
  FETCH_EXCEPTIONS_FULFILLED: undefined,
  LOAD_EXCEPTIONS: undefined,
  LOAD_EXCEPTIONS_ASYNC: undefined,
  LOAD_EXCEPTIONS_ASYNC_COUNT: undefined,
  LOAD_EXCEPTIONS_ASYNC_CURRENT: undefined,
  LOAD_EXCEPTIONS_ASYNC_FINISHED: undefined,
  LOAD_EXCEPTIONS_ASYNC_CANCEL: undefined,
  EXCEPTIONS_CLEAR: undefined,
})
