import React from 'react'
import PropTypes from 'prop-types'
import StoreDetailsScreen from './index.screen'
import { orgUrl } from '../../../utils/mtiUtils'
import { safeUri } from '../../../utils/utils'

class StoreDetails extends React.Component {
  // TODO: Why is this being opened in a new tab? We should just redirect to the floor instead.
  openFloor = (id, name) => {
    window.open(`${this.getCurrentPath()}/${id}/${safeUri(name)}`, '_blank')
  }

  getCurrentPath() {
    const { store: { id, name } } = this.props
    return `${orgUrl()}/stores/${id}/${safeUri(name)}/floors`
  }

  render() {
    const {
      store,
      onEditStorePressed,
      onClose,
      onRedirectToLayout,
      onRedirectToSecuredProducts,
      onEditTags,
      hasListTaggingsPermission,
      id,
      organization,
      hideButtons,
      onRedirectToTableView,
      onRedirectToOrphanedDevices,
    } = this.props

    return (
      <StoreDetailsScreen
        id={id}
        store={store}
        onEditStorePressed={onEditStorePressed}
        onClose={onClose}
        onRedirectToLayout={onRedirectToLayout}
        onRedirectToSecuredProducts={onRedirectToSecuredProducts}
        onRedirectToTableView={onRedirectToTableView}
        onRedirectToOrphanedDevices={onRedirectToOrphanedDevices}
        onEditTags={onEditTags}
        hasListTaggingsPermission={hasListTaggingsPermission}
        organization={organization}
        hideButtons={hideButtons}
        onClickFloor={this.openFloor}
      />
    )
  }
}

StoreDetails.propTypes = {
  id: PropTypes.string.isRequired,
  store: PropTypes.object.isRequired,
  onEditStorePressed: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onEditTags: PropTypes.func.isRequired,
  hasListTaggingsPermission: PropTypes.bool,
  onRedirectToLayout: PropTypes.func,
  onRedirectToSecuredProducts: PropTypes.func,
  onRedirectToTableView: PropTypes.func,
  onRedirectToOrphanedDevices: PropTypes.func,
  organization: PropTypes.object,
  hideButtons: PropTypes.bool,
}

export default StoreDetails
