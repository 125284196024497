import React from 'react'
import PropTypes from 'prop-types'
import {
  CellContainer,
  renderFilterInput,
  renderHeaderCell,
} from '../UsersPage/index.screen'
import { NameTitle } from './index.screen'
import { fullTextFilter } from '../../utils/utils'
import { TableInModal } from '../../styles/styled'

/* eslint-disable react/prop-types, no-underscore-dangle */
const renderContent = (stores, onStore, modalId) => {
  const columns = [
    {
      filterable: true,
      Header: renderHeaderCell('Name'),
      accessor: 'name',
      Cell: ({ original: { name } }) => (
        <CellContainer>
          <NameTitle>{name}</NameTitle>
        </CellContainer>
      ),
      Filter: ({ filter, onChange }) =>
        renderFilterInput({ placeholder: 'Filter', filter, onChange }),
    },
    {
      filterable: true,
      Header: renderHeaderCell('State'),
      accessor: 'address.state',
      Filter: ({ filter, onChange }) =>
        renderFilterInput({ placeholder: 'Filter', filter, onChange }),
    },
    {
      filterable: true,
      Header: renderHeaderCell('City'),
      accessor: 'address.city',
      Filter: ({ filter, onChange }) =>
        renderFilterInput({ placeholder: 'Filter', filter, onChange }),
    },
  ]

  return (
    <div>
      <TableInModal
        className="full-screen"
        data={stores || []}
        columns={columns}
        defaultFilterMethod={fullTextFilter}
        getTdProps={(state, rowInfo) => ({
          onClick: (e, handleOriginal) => {
            $(`#modal-${modalId}`).modal('hide') // eslint-disable-line
            onStore(rowInfo.original)

            if (handleOriginal) {
              handleOriginal()
            }
          },
        })}
      />
    </div>
  )
}

function StoresPageModalScreen({ stores, onStore, id, onClose }) {
  return (
    <div className="modal-content">
      <div className="modal-header border-bottom">
        <h5 className="modal-title" id="exampleModalLongTitle">
          Select Store
        </h5>
        <div>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              $(`#modal-${id}`).modal('hide') // eslint-disable-line
              onClose()
            }}
          >
            Close
          </button>
        </div>
      </div>
      <div className="modal-body">{renderContent(stores, onStore, id)}</div>
    </div>
  )
}

StoresPageModalScreen.propTypes = {
  stores: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
  onStore: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default StoresPageModalScreen
