import React from 'react'
import PropTypes from 'prop-types'
import { emailValidationPattern } from '../../utils/utils'
import { Overlay } from '../../styles/styled'
import ProgressIndicator from '../../components/ProgressIndicator'

class ForgotPasswordScreen extends React.PureComponent {
  componentDidMount() {
    setTimeout(() => {
      this.email.focus()
    }, 500)
  }

  setServerErrorDismiss(element) {
    const $element = $(element) // eslint-disable-line
    $element.keyup(() => {
      element.setCustomValidity('')
      $element.attr('data-content', '')
      $element.popover('hide')
    })
  }

  setServerError(element, msg) {
    const $element = $(element) // eslint-disable-line
    element.setCustomValidity(msg)
    $element.attr('data-placement', 'bottom')
    $element.attr('data-content', msg)
    $element.popover('show')
    setTimeout(() => {
      $element.attr('data-content', '')
      $element.popover('disable')
    }, 3000)
  }

  setValidationErrors(validationErrors) {
    if (validationErrors.email) {
      const msg = validationErrors.email[0]
      const element = this.email
      this.setServerError(element, msg)
    }
  }

  setServerValidity(error) {
    if (error && error.error) {
      const validationErrors = error.error.validationErrors
      if (validationErrors) {
        this.setValidationErrors(validationErrors)
        if (validationErrors.email) {
          const msg = validationErrors.email[0]
          const element = this.email
          this.setServerError(element, msg)
        }
      } else {
        // another server error
        console.log(error)
      }
    }
  }

  renderMain() {
    const { onChange, onConfirm, onCancel } = this.props
    return (
      <div className="text-dark text-center center-block">
        <form
          style={{ width: '400px' }}
          className="form-signin needs-validation was-validated rounded p-3 bg-light m-auto"
          noValidate=""
          onSubmit={(e) => {
            e.preventDefault()
            onConfirm()
          }}
        >
          <p className="m-5">Reset your password</p>
          <div className="m-3">
            <input
              ref={(ref) => {
                this.email = ref
                this.setServerErrorDismiss(ref)
              }}
              type="email"
              className="form-control"
              id="email"
              placeholder="Email"
              required="required"
              pattern={emailValidationPattern}
              onKeyUp={() =>
                (this.email.value = this.email.value.toLowerCase())
              }
              onChange={({ target: { value } }) => onChange(value)}
            />
            <div className="invalid-feedback">
              Please enter a valid email address.
            </div>
          </div>
          <button type="submit" className="btn btn-primary btn-raised m-3">
            Send Reset instructions
          </button>
        </form>
        <button
          type="button"
          className="btn btn-primary m-2"
          onClick={onCancel}
        >
          Cancel
        </button>
      </div>
    )
  }

  render() {
    const { isLoading } = this.props
    return (
      <div className="text-white h-100">
        {this.renderMain()}
        {isLoading && (
          <React.Fragment>
            <Overlay />
            <ProgressIndicator />
          </React.Fragment>
        )}
      </div>
    )
  }
}

ForgotPasswordScreen.propTypes = {
  onChange: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
}

export default ForgotPasswordScreen
