import { fromJS } from 'immutable'
import { ActionTypes } from './constants'

const initialState = fromJS({
  areasFilterList: [],
  fixturesFilterList: [],
  layoutTableViewIsLoading: false,
  layoutTableViewData: null,
})

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case ActionTypes.LOADING_TABLE_VIEW:
      return state.set('layoutTableViewIsLoading', payload.isLoading)
    case ActionTypes.LAYOUT_TABLE_DATA_REDUCER:
      return state.set('layoutTableViewData', payload)
    case ActionTypes.AREAS_FILTER_REDUCER:
      return state.set('areasFilterList', payload.areas.map(a => ({ name: a.name, id: a.id })))
    case ActionTypes.FIXTURES_FILTER_REDUCER:
      return state.set('fixturesFilterList', payload.fixtures.map(f => ({ name: f.name, id: f.id })))
    default:
      return state
  }
}
