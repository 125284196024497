import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper'
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect'
import connectedAuthWrapper from 'redux-auth-wrapper/connectedAuthWrapper'

import Loading from '../Loading'
import { Routes } from './constants'

const locationHelper = locationHelperBuilder({})

const userIsAuthenticatedDefaults = {
  authenticatedSelector: (state) => state.get('user').get('data') !== null,
  authenticatingSelector: (state) => state.get('user').get('isLoading'),
  wrapperDisplayName: 'UserIsAuthenticated',
}

export const userIsAuthenticated = connectedAuthWrapper(
  userIsAuthenticatedDefaults
)

export const userIsAuthenticatedRedir = connectedRouterRedirect({
  ...userIsAuthenticatedDefaults,
  AuthenticatingComponent: Loading,
  redirectPath: (state) => {
    const jsState = state.toJS() || {}
    const { location } = jsState.route || {}

    if (location && location.pathname === Routes.ADMIN_LOGIN)
      return Routes.ADMIN_LOGIN
    return Routes.LOGIN
  },
  allowRedirectBack: false,
})

// Not used
// export const userIsAdminRedir = connectedRouterRedirect({
//   redirectPath: '/',
//   allowRedirectBack: false,
//   authenticatedSelector: (state) =>
//     state.get('user').get('data') !== null &&
//     state.get('user').get('data').isAdmin,
//   predicate: (user) => user.isAdmin,
//   wrapperDisplayName: 'UserIsAdmin',
// })

const userIsNotAuthenticatedDefaults = {
  // Want to redirect the user when they are done loading and authenticated
  authenticatedSelector: (state) =>
    state.get('user').get('data') === null &&
    state.get('user').get('isLoading') === false,
  wrapperDisplayName: 'UserIsNotAuthenticated',
}

export const userIsNotAuthenticated = connectedAuthWrapper(
  userIsNotAuthenticatedDefaults
)

export const userIsNotAuthenticatedRedir = connectedRouterRedirect({
  ...userIsNotAuthenticatedDefaults,
  redirectPath: (state, ownProps) =>
    locationHelper.getRedirectQueryParam(ownProps) || '/',
  // redirectPath: (state, ownProps) => {
  //   const jsState = state.toJS() || {}
  //   const { location } = jsState.route || {}
  //
  //   if (location && location.pathname === Routes.ADMIN_LOGIN)
  //     return '/admin/ota'
  //   return locationHelper.getRedirectQueryParam(ownProps) || '/'
  // },
  allowRedirectBack: false,
})
