/*
 *
 * TemplatePositions reducer
 *
 */

import { fromJS, List } from 'immutable'
import { ActionTypes } from './constants'

const initialState = fromJS({
  selectedId: -1,
  positions: [],
  fixture: {},
  canvasObject: {
    objects: [],
    background: '',
  },
  isStatic: true,
  loading: true,
  rulesLoading: [],
})

function templatePositionsReducer(state = initialState, { type, payload }) {
  switch (type) {
    case ActionTypes.STORE_FIXTURE_TEMPLATE_PROTOTYPES:
      return state.set('prototypes', payload)
    case ActionTypes.STORE_FIXTURE_TEMPLATE_PARAMS:
      return state
        .set('screen', payload.screen)
        .set('isStatic', payload.isStatic)
    case ActionTypes.POST_FIXTURE_TEMPLATE_FINISHED: {
      return state
        .set('failed', null)
        .set('isStatic', false)
        .updateIn(['canvasObject', 'objects'], (objects) =>
          objects.update(
            objects.findIndex(
              (item) => item.get('id') === (payload.oldPId || payload.pId)
            ),
            (obj) => {
              if (!obj) return obj
              return obj.set('id', payload.pId || obj.get('id'))
            }
          )
        )
      //.set('selectedId', selectedId || -1)
    }
    case ActionTypes.FIXTURE_TEMPLATE_DATA_PENDING:
      return state.set('loading', true).set('failed', null)
    case ActionTypes.FIXTURE_TEMPLATE_DATA_FAILED:
      return state.set('loading', false).set('failed', payload.error)
    case ActionTypes.FETCH_FIXTURE_TEMPLATE_CANVAS_FULFILLED: {
      const {
        canvas: { canvasObject = {} } = {},
        selectedId,
        positions = [],
        fixture,
        isStatic,
      } = payload

      return state
        .set('canvasObject', fromJS(canvasObject))
        .set('selectedId', selectedId || -1)
        .set('positions', positions)
        .set('fixture', fixture)
        .set('failed', null)
        .set('isStatic', isStatic)
    }
    case ActionTypes.FIXTURE_TEMPLATE_DATA_FULFILLED:
      return state.set('loading', false).set('failed', null)
    case ActionTypes.FIXTURE_TEMPLATE_CANVAS_CHANGED: {
      const isStaticPayload = payload.isStatic
      // const prevSelectedId = state.get('selectedId')
      const isStatic = !isStaticPayload
        ? isStaticPayload
        : state.get('isStatic')
      const selectedId = payload.selectedId

      return state
        .updateIn(['canvasObject', 'objects'], () =>
          fromJS(payload.canvasObject).get('objects')
        )
        .set('selectedId', selectedId)
        .set('isStatic', isStatic)
    }
    case ActionTypes.SAVE_FIXTURE_TEMPLATE_POSITION:
      return state.set('selectedId', -1)
    case ActionTypes.FIXTURE_TEMPLATE_CANVAS_POSITION_REMOVE:
      return state
        .updateIn(['canvasObject', 'objects'], (objects) =>
          objects.filter((obj) => obj.get('id') !== payload.selectedId)
        )
        .set('selectedId', -1)
    case ActionTypes.FIXTURE_TEMPLATE_CANVAS_NAME_CHANGED:
      return state.updateIn(['canvasObject', 'objects'], (objects) =>
        objects.update(
          objects.findIndex(
            (item) => item.get('id') === state.get('selectedId')
          ),
          (obj) => obj.set('name', payload)
        )
      )
    case ActionTypes.UPDATE_RULE_FAILED:
      return state.set('failed', payload).set('loading', false)
    case ActionTypes.UPDATE_RULE_FINISHED:
      return state.set(
        'rulesLoading',
        state.get('rulesLoading').filter((o) => o !== payload.ruleId)
      )
    case ActionTypes.UPDATE_RULE_PENDING:
      return state.set(
        'rulesLoading',
        state.get('rulesLoading').concat(List([payload.ruleId]))
      )
    case ActionTypes.TEMPLATE_AUTO_PLACEMENT_FULFILLED: {
      const { canvas: { canvasObject = {} } = {} } = payload
      return state.set('canvasObject', fromJS(canvasObject))
    }
    case ActionTypes.STORE_TEMPLATE_FIXTURE_PROTOTYPES:
      return state.set('prototypes', payload)
    case ActionTypes.LOAD_FIXTURE_TEMPLATE_CANVAS:
    default:
      return state
  }
}

export default templatePositionsReducer
