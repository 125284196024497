import React from 'react'
import PropTypes from 'prop-types'
import ReactTable from 'react-table'
import Radio from '../../../components/Radio'
import { ModalBody } from '../../StoresPage/CreateEditStoreModal/index.screen'
import {
  renderHeaderCell,
  renderFilterInput,
  CellContainer,
} from '../../UsersPage/index.screen'
import { fullTextFilter, getTableHeight } from '../../../utils/utils'

class SelectFirmwareScreen extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    window.setTimeout(() => this.handleResize(), 10)
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }
  /* eslint-disable no-undef */
  handleResize = () => this.setState({ tableHeight: getTableHeight() })

  renderMainContent = () => {
    const { firmwares, toggleSelection, isSelected } = this.props
    const { tableHeight } = this.state

    const columns = [
      {
        id: 'radio',
        width: 30,
        Cell: ({ original }) => (
          <Radio
            id={original.name}
            name={original.name}
            onChange={() => toggleSelection(original)}
            checked={isSelected(original)}
          />
        ),
      },
      {
        id: 'name',
        Header: () => renderHeaderCell('Name'),
        Cell: ({ original }) => (
          <CellContainer onClick={() => toggleSelection(original)}>
            {original.name}
          </CellContainer>
        ),
        accessor: 'name',
        filterable: true,
        Filter: ({ filter, onChange }) =>
          renderFilterInput({ placeholder: 'Filter', filter, onChange }),
      },
      {
        id: 'uploaded',
        Header: () => renderHeaderCell('Date Updated'),
        accessor: 'uploaded',
        filterable: true,
        Filter: ({ filter, onChange }) =>
          renderFilterInput({ placeholder: 'Filter', filter, onChange }),
      },
      {
        id: 'uploadedBy',
        Header: () => renderHeaderCell('Uploded By'),
        accessor: 'uploadedBy',
        filterable: true,
        Filter: ({ filter, onChange }) =>
          renderFilterInput({ placeholder: 'Filter', filter, onChange }),
      },
    ]
    return (
      <div>
        <ReactTable
          className="full-screen"
          style={{
            height: tableHeight - 150 || 'auto',
          }}
          data={firmwares}
          columns={columns}
          defaultFilterMethod={fullTextFilter}
        />
      </div>
    )
  }

  renderInput = () => {
    const { firmwareVersionToIgnore, onVersionChange } = this.props

    return (
      <div>
        <label htmlFor="firmwareVersion">
          Firmware version of nodes to ignore the update:
        </label>
        <input
          type="text"
          className="form-control"
          id="firmwareVersion"
          placeholder=""
          required=""
          value={firmwareVersionToIgnore}
          onChange={({ target: { value } }) =>
            onVersionChange({ firmwareVersionToIgnore: value })
          }
        />
      </div>
    )
  }

  render() {
    const { id, onCancel, onUpdate, selectedFirmware } = this.props

    return (
      <div className="modal-content">
        <div className="modal-header border-bottom">
          <h5 className="modal-title" id="exampleModalLongTitle">
            Firmwares
          </h5>
          <div>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                $(`#modal-${id}`).modal('hide') // eslint-disable-line
                onCancel()
              }}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary btn-raised"
              disabled={!selectedFirmware}
              onClick={() => {
                $(`#modal-${id}`).modal('hide') // eslint-disable-line
                onUpdate()
              }}
            >
              Update
            </button>
          </div>
        </div>
        <div>
          <ModalBody className="modal-body">
            {this.renderMainContent()}
          </ModalBody>
          <ModalBody className="modal-body">{this.renderInput()}</ModalBody>
        </div>
      </div>
    )
  }
}

SelectFirmwareScreen.propTypes = {
  id: PropTypes.string.isRequired,
  firmwares: PropTypes.array.isRequired,
  onCancel: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  toggleSelection: PropTypes.func.isRequired,
  isSelected: PropTypes.func.isRequired,
  selectedFirmware: PropTypes.object,
  firmwareVersionToIgnore: PropTypes.string.isRequired,
  onVersionChange: PropTypes.func.isRequired,
}

export default SelectFirmwareScreen
