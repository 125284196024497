import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import {
  makeSelectFailed,
  makeSelectLoading,
  makeSelectRegions,
} from './selectors'
import RegionsPageScreen from './index.screen'
import {
  regionDetailsAction,
  createRegionAction,
  notifyModal,
} from '../App/actions'
import { loadRegions, loadRegionWithStoresAction } from './actions'
import ProgressIndicator from '../../components/ProgressIndicator'
import {
  makeSelectMtiPermission,
  makeSelectMtiPermissionAnyResource,
} from '../App/selectors'
import {
  makeSelectStores,
  makeSelectLoading as makeSelectStoresLoading,
  makeSelectFailed as makeSelectStoresFailed,
} from '../StoresPage/selectors'
import RegionsPageModalScreen from './index.modal.screen'
import RegionManagement from './regionManagement'
import StoreManagement from '../StoresPage/storeManagement'
import FetchFailedAlert from '../../components/FetchFailed'

const permText = text =>
  `You don't have appropriate rights to access ${text}.\nPlease contact your store manager.`

export class RegionsPage extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = { showStoreId: undefined, store: null }
  }
  componentDidMount() {
    const { hasViewRegionsPermission, history, loadRegions } = this.props
    if (this.noPermission(hasViewRegionsPermission, permText('the Regions'))) {
      history.push('/')
      return
    }
    loadRegions()
  }

  onShowStore = storeId => {
    const { stores } = this.props
    const store = (stores || []).find(({ id }) => id === storeId)
    if (!store) {
      console.warn(`At this stage store with id ${storeId} should be defined`)
      return
    }
    this.setState({ showStoreId: storeId, store })
  }

  selectedRegion = () => this.state.selectedRegion

  noPermission = (hasPermission, text) => {
    const { notifyModal: doNotifyModal } = this.props
    if (!hasPermission) doNotifyModal(text)
    return !hasPermission
  }

  render() {
    const {
      regions,
      loading,
      failed,
      regionCreateNewObjects,
      createRegionAction,
      regionDetailsAction,
      loadRegionWithStoresAction,
      isSelectRegionModal,
      onRegionSelected,
      id,
      onClose,
      hasViewRegionsPermission,
    } = this.props

    if (loading)
      return (
        <React.Fragment>
          <RegionsPageScreen loading={loading} />
          <ProgressIndicator text={'Loading...'} />
        </React.Fragment>
      )
    if (failed) return <FetchFailedAlert failed={failed} />
    if (!regions) return null

    const regionsChecked = hasViewRegionsPermission ? regions : []

    if (isSelectRegionModal) {
      return (
        <RegionsPageModalScreen
          id={id}
          regions={regionsChecked}
          onRegion={onRegionSelected}
          onClose={() => onClose()}
          showStores
        />
      )
    }

    return (
      <div>
        <RegionManagement
          showStore={this.state.store}
          loadStore={this.onShowStore}
          onCloseEditManagers={() =>
            setTimeout(() => {
              regionDetailsAction(this.selectedRegion())
            }, 500)
          }
        />
        <StoreManagement
          history={this.props.history}
          onClose={() => {
            this.setState({ showStoreId: undefined, store: null }, () => {
              setTimeout(() => {
                regionDetailsAction(this.selectedRegion())
              }, 500)
            })
          }}
          hideButtons
        />
        <RegionsPageScreen
          regions={regionsChecked}
          isAddRegionLocked={!regionCreateNewObjects}
          onCreateRegion={() =>
            this.setState(
              {
                selectedRegion: undefined,
                showStoreId: undefined,
                store: undefined,
              },
              () => {
                createRegionAction()
              }
            )
          }
          onRegion={region => {
            this.setState(
              {
                selectedRegion: region,
                showStoreId: undefined,
                store: undefined,
              },
              () => {
                loadRegionWithStoresAction(region.id)
                regionDetailsAction(region)
              }
            )
          }}
        />
      </div>
    )
  }
}

function getRegionId(ownProps) {
  return ((ownProps.match || {}).params || {}).sId
}

RegionsPage.propTypes = {
  history: PropTypes.any,
  loadRegions: PropTypes.func.isRequired,
  regions: PropTypes.array,
  loading: PropTypes.bool,
  failed: PropTypes.object,
  regionDetailsAction: PropTypes.func,
  createRegionAction: PropTypes.func,
  hasViewRegionsPermission: PropTypes.bool,
  regionCreateNewObjects: PropTypes.bool,
  stores: PropTypes.array,
  storesLoading: PropTypes.bool,
  storesFailed: PropTypes.object,
  isSelectRegionModal: PropTypes.bool,
  onRegionSelected: PropTypes.func,
  onClose: PropTypes.func,
  id: PropTypes.string,
  notifyModal: PropTypes.func,
  loadRegionWithStoresAction: PropTypes.func.isRequired,
}

let mapStateToProps
mapStateToProps = createStructuredSelector({
  regions: makeSelectRegions(),
  loading: makeSelectLoading(),
  failed: makeSelectFailed(),
  stores: makeSelectStores(),
  storesLoading: makeSelectStoresLoading(),
  storesFailed: makeSelectStoresFailed(),
  hasViewRegionsPermission: makeSelectMtiPermissionAnyResource(
    'Region: Index (list) Records'
  ),
  regionCreateNewObjects: (state, ownProps) =>
    makeSelectMtiPermission(
      'Region: Create New Objects',
      getRegionId(ownProps)
    )(state, ownProps),
})

const mapDispatchToProps = {
  loadRegions,
  regionDetailsAction,
  createRegionAction,
  notifyModal,
  loadRegionWithStoresAction,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect)(RegionsPage)
