import React from 'react'
import PropTypes from 'prop-types'

function Selector({ title, data, onChange, selectedId, containerClassName }) {
  return (
    <div className={containerClassName}>
      <label htmlFor={title}>{title}</label>
      <select
        id={title}
        value={selectedId}
        className="form-control"
        onChange={({ target: { value } }) => onChange(Number(value))}
      >
        <option>{`Select ${title}`}</option>
        {data.map((item) => (
          <option key={item.id} value={item.id}>
            {item.name}
          </option>
        ))}
      </select>
    </div>
  )
}

Selector.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  selectedId: PropTypes.any,
  containerClassName: PropTypes.string.isRequired,
}

const getProductsForSelectedManufacturer = (products, selectedManufacturerId) =>
  products
    .filter(({ manufacturerId }) => manufacturerId === selectedManufacturerId)
    .map((p) => ({ id: p.id, name: p.model })) || []

export default class ManufacturerAndProductSelector extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedManufacturerId: props.selectedManufacturerId,
      selectedProductId: props.selectedProductId,
    }
  }

  render() {
    const {
      containerClassName,
      manufacturersContainerClassName,
      productsContainerClassName,
      manufacturers,
      products,
      onChange,
    } = this.props
    const { selectedManufacturerId, selectedProductId } = this.state
    const productsForSelectedManufacturer = getProductsForSelectedManufacturer(
      products,
      selectedManufacturerId
    )

    return (
      <div className={containerClassName}>
        <Selector
          title="Manufacturer"
          data={manufacturers}
          onChange={(selectedId) => {
            const manufacturer = manufacturers.find((m) => m.id === selectedId)
            this.setState({ selectedManufacturerId: manufacturer.id })
            onChange({ manufacturerId: manufacturer.id, manufacturer })
          }}
          selectedId={selectedManufacturerId}
          containerClassName={manufacturersContainerClassName}
        />
        <Selector
          title="Product"
          data={productsForSelectedManufacturer}
          selectedId={selectedProductId}
          containerClassName={productsContainerClassName}
          onChange={(selectedId) => {
            this.setState({ selectedProductId: selectedId })
            onChange({ productId: selectedId })
          }}
        />
      </div>
    )
  }
}

ManufacturerAndProductSelector.propTypes = {
  manufacturers: PropTypes.array.isRequired,
  products: PropTypes.array.isRequired,
  selectedManufacturerId: PropTypes.any,
  selectedProductId: PropTypes.any,
  containerClassName: PropTypes.string.isRequired,
  manufacturersContainerClassName: PropTypes.string.isRequired,
  productsContainerClassName: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}
