/**
 * Schedules Sagas
 */
import { call, put, select } from 'redux-saga/effects'
import {
  makeSelectToken,
  makeSelectMtiPermissionAnyResource,
} from 'containers/App/selectors'
import { makeSelectSchedules, makeSelectLoading } from './selectors'
import { getSchedules } from '../../api'
import {
  fetchSchedulesPending,
  fetchSchedulesFulfilled,
  fetchSchedulesFailed,
} from './actions'
import { errorToast } from '../../utils/utils'

export function* loadSchedulesData({ payload } = {}) {
  const token = yield select(makeSelectToken())
  const data = yield call(getSchedules, token, payload.storeId)
  return data
}

export function* loadSchedules({ payload }) {
  const listSchedulesPermissionData = yield select(
    makeSelectMtiPermissionAnyResource('Schedule: Index (list) Records')
  )
  if (!listSchedulesPermissionData) return

  const token = yield select(makeSelectToken())
  if (!token) {
    console.log('Skipping get Schedules')
    return
  }
  const isFetchSchedulesPending = yield select(makeSelectLoading())
  if (isFetchSchedulesPending) return
  const schedulesLoaded = yield select(makeSelectSchedules())
  const isScheduleLoaded = schedulesLoaded && schedulesLoaded.length > 0

  try {
    if (!isScheduleLoaded) {
      yield put(fetchSchedulesPending())
    }
    const data = yield call(loadSchedulesData, { payload })
    yield put(fetchSchedulesFulfilled(data))
  } catch (error) {
    yield put(fetchSchedulesFailed(error))
    errorToast('Load schedules failed')
  }
}
