/*
 *
 * Linked Fixtures sagas
 *
 */

import { call, put, takeLatest, select, all } from 'redux-saga/effects'
import { modelActionCreators } from 'mti-jsclient-shared'
import { upsertStore } from '../../App/actions'
import { ActionTypes } from './constants'
import {
  fetchLinkedFixturesFailed,
  fetchLinkedFixturesFulfilled,
  fetchLinkedFixturesPending,
} from './actions'
import { getLinkedFixtureForTemplate } from '../../../api'
import { makeSelectToken } from '../../App/selectors'
import { errorToast } from '../../../utils/utils'

export function* loadLinkedFixtures({ payload }) {
  const { templateId } = payload
  const token = yield select(makeSelectToken())
  try {
    yield put(fetchLinkedFixturesPending())

    const data = yield call(getLinkedFixtureForTemplate, token, templateId)

    yield put(modelActionCreators.upsertStores(data.stores))
    yield put(upsertStore(data))

    yield put(fetchLinkedFixturesFulfilled())
  } catch (error) {
    yield put(fetchLinkedFixturesFailed(error))
    errorToast('Load linked fixtures failed')
  }
}

export default function* root() {
  yield all([
    yield takeLatest(ActionTypes.LOAD_LINKED_FIXTURES, loadLinkedFixtures),
  ])
}
