import { ActionTypes } from './constants'

/**
 * Fetch Manufacturer Products Failed
 *
 * @returns {Object}
 */
export function fetchManufacturerProductsFailed(error) {
  return {
    type: ActionTypes.FETCH_MANUFACTURER_PRODUCTS_FAILED,
    payload: error,
  }
}

/**
 * Fetch Manufacturer Products Fulfilled
 *
 * @returns {Object}
 */
export function fetchManufacturerProductsFulfilled(manufacturerId, data) {
  return {
    type: ActionTypes.FETCH_MANUFACTURER_PRODUCTS_FULFILLED,
    payload: {
      manufacturerId,
      products: data,
    },
  }
}

/**
 * Fetch Manufacturer Products Pending
 *
 * @returns {Object}
 */
export function fetchManufacturerProductsPending(manufacturerId) {
  return {
    type: ActionTypes.FETCH_MANUFACTURER_PRODUCTS_PENDING,
    payload: {
      manufacturerId,
    },
  }
}

/**
 * Load Manufacturer Products
 *
 * @returns {Object}
 */
export function loadManufacturerProducts(manufacturerId, storeId) {
  return {
    type: ActionTypes.LOAD_MANUFACTURER_PRODUCTS,
    payload: {
      manufacturerId,
      storeId,
    },
  }
}
