import React from 'react'
import PropTypes from 'prop-types'
import UserModalScreen from './index.screen'

function UserModal({
  id,
  user,
  title,
  dangerText,
  confirmText,
  isUserEditLocked,
  onDanger,
  onConfirm,
  onClose,
  isEditProfileOnly,
  resetPasswordText,
  onResetPassword,
}) {
  return (
    <UserModalScreen
      id={id}
      user={user}
      isEditProfileOnly={isEditProfileOnly}
      title={title}
      dangerText={dangerText}
      confirmText={confirmText}
      resetPasswordText={resetPasswordText}
      onDanger={onDanger}
      onConfirm={onConfirm}
      onClose={onClose}
      isUserEditLocked={isUserEditLocked}
      onResetPassword={onResetPassword}
    />
  )
}

UserModal.propTypes = {
  user: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  isUserEditLocked: PropTypes.bool,
  dangerText: PropTypes.string,
  resetPasswordText: PropTypes.string,
  confirmText: PropTypes.string,
  onDanger: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
  onResetPassword: PropTypes.func,
  isEditProfileOnly: PropTypes.bool,
}

export default UserModal
