import React from 'react'
import PropTypes from 'prop-types'
import ListOfLinks from '../../../components/ListOfLinks'
import Stores from '../../StoresPage'
import { injectIntl } from 'react-intl'

const CorporateUserScreen = ({
  quickActionsItems,
  reportsItems,
  onItem,
  height,
  history,
  intl,
}) => (
  <div
    style={{
      flex: 1,
      height: '100%',
    }}
  >
    <div
      className="row no-gutters justify-content-md-center"
      style={{
        height: '100%',
      }}
    >
      <div
        className="col-md-auto col"
        style={{ marginLeft: 6, marginRight: 6 }}
      >
        <ListOfLinks
          items={quickActionsItems}
          headerTitle={intl.formatMessage({
            id: 'components.navigation.title',
          })}
          height={height}
          onItem={onItem}
          history={history}
        />
      </div>
      <div
        className="col-md-auto col"
        style={{ marginLeft: 6, marginRight: 6 }}
      >
        <ListOfLinks
          items={reportsItems}
          headerTitle={'Reports'}
          height={height}
          onItem={onItem}
          history={history}
        />
      </div>
      <div
        className="col-md-auto col"
        style={{
          marginLeft: 6,
          marginRight: 6,
          minWidth: '530px',
        }}
      >
        <div
          className="card"
          style={{
            minWidth: '530px',
            overflow: 'auto',
          }}
        >
          <div className="card-header text-left font-weight-bold">Stores</div>
          <Stores isHomePage height={height} history={history} />
        </div>
      </div>
    </div>
  </div>
)

CorporateUserScreen.propTypes = {
  quickActionsItems: PropTypes.array.isRequired,
  reportsItems: PropTypes.array.isRequired,
  onItem: PropTypes.func,
  height: PropTypes.number,
  history: PropTypes.object,
}

export default injectIntl(CorporateUserScreen)
