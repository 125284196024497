export const userKey = '__user'

/**
 * Restore authenticated user from local storage
 * @returns {*}
 */
export function restoreUser() {
  try {
    const user = JSON.parse(localStorage.getItem(userKey))
    if (new Date() >= new Date(user.expiresAt)) {
      destroyUser()
      return null
    }
    if (
      user.saltTokenExpiresAt &&
      new Date() >= new Date(user.saltTokenExpiresAt)
    ) {
      destroyUser()
      return null
    }
    return user
  } catch (e) {
    return null
  }
}

/**
 * Save authenticated user to the local storage
 * @param user
 * @returns {boolean}
 */
export function saveUser(user) {
  try {
    const userStr = JSON.stringify(user)
    localStorage.setItem(userKey, userStr)
    return true
  } catch (e) {
    return false
  }
}

/**
 * Destroy authenticated user data
 * @returns {boolean}
 */
export function destroyUser() {
  try {
    localStorage.removeItem(userKey)
    return true
  } catch (e) {
    return false
  }
}
