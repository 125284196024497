/**
 *
 * Limked Fixtures actions
 *
 */

import { ActionTypes } from './constants'

/**
 * Load Linked Fixtures
 *
 * @returns {Object}
 */
export function loadLinkedFixturesForTemplate(templateId) {
  return {
    type: ActionTypes.LOAD_LINKED_FIXTURES,
    payload: { templateId },
  }
}

/**
 * Fetch Linked Fixtures Pending
 *
 * @returns {Object}
 */
export function fetchLinkedFixturesPending() {
  return {
    type: ActionTypes.FETCH_LINKED_FIXTURES_PENDING,
  }
}

/**
 * Fetch Linked Fixtures Fulfilled
 *
 * @returns {Object}
 */
export function fetchLinkedFixturesFulfilled() {
  return {
    type: ActionTypes.FETCH_LINKED_FIXTURES_FULFILLED,
  }
}

/**
 * Fetch Linked Fixtures Failed
 *
 * @returns {Object}
 */
export function fetchLinkedFixturesFailed(error) {
  return {
    type: ActionTypes.FETCH_LINKED_FIXTURES_FAILED,
    payload: { error },
  }
}
