import React from 'react'
import NotifyModal from 'containers/ModalContainer/NotifyModal'
import { ActionTypes } from '../constants'

/**
 * Open Modal
 *
 * @returns {Object}
 */
export function openModal(obj) {
  return {
    type: ActionTypes.OPEN_MODAL,
    obj,
  }
}

/**
 * Close Modal
 *
 * @returns {Object}
 */
export function closeModal(obj) {
  return {
    type: ActionTypes.CLOSE_MODAL,
    obj,
  }
}

/**
 * Notify Modal
 *
 * @returns {Object}
 */
export function notifyModal(text) {
  return {
    type: ActionTypes.OPEN_MODAL,
    obj: {
      id: 'notify',
      type: 'bootstrap',
      appearance: 'modal-sm',
      content: (
        <NotifyModal
          id={'notify'}
          notifyText={text}
          onClose={() => closeModal({ id: 'notify' })}
        />
      ),
    },
  }
}
