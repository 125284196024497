import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import injectReducer from '../../../utils/injectReducer'
import injectSaga from '../../../utils/injectSaga'
import reducer from './reducer'
import saga from './sagas'
import {
  makeSelectStatus,
  makeSelectLoading,
  makeSelectError,
} from './selectors'
import { updateLode } from './actions'
import UpdateLodeStatusScreen from './index.screen'

class UpdateLodeStatus extends React.Component {
  componentDidMount() {
    const { updateLode: doUpdateLode, data } = this.props
    doUpdateLode(data)
  }

  render() {
    const { id, updateLodeStatus, updating, updateError, onClose } = this.props

    return (
      <UpdateLodeStatusScreen
        id={id}
        updateLodeStatus={updateLodeStatus}
        updating={updating}
        updateError={updateError}
        onClose={onClose}
      />
    )
  }
}

UpdateLodeStatus.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  updateLodeStatus: PropTypes.any,
  updating: PropTypes.bool,
  updateError: PropTypes.object,
  updateLode: PropTypes.func,
}

const mapStateToProps = createStructuredSelector({
  updateLodeStatus: makeSelectStatus(),
  updating: makeSelectLoading(),
  updateError: makeSelectError(),
})

const mapDispatchToProps = {
  updateLode,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const withReducer = injectReducer({
  key: 'updateLodeStatus',
  reducer,
})

const withSaga = injectSaga({
  key: 'updateLodeStatus',
  saga,
})

export default compose(withReducer, withSaga, withConnect)(UpdateLodeStatus)
