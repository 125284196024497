import { ActionTypes } from '../../../App/constants'

/**
 * Fetch Organizations Failed
 *
 * @returns {Object}
 */
export function fetchOrganizationsFailed(error) {
  return {
    type: ActionTypes.FETCH_ORGANIZATIONS_FAILED,
    payload: { error },
  }
}

/**
 * Fetch Organizations Fulfilled
 *
 * @returns {Object}
 */
export function fetchOrganizationsFulfilled(organizations) {
  return {
    type: ActionTypes.FETCH_ORGANIZATIONS_FULFILLED,
    payload: { organizations },
  }
}

/**
 * Fetch Organizations Pending
 *
 * @returns {Object}
 */
export function fetchOrganizationsPending() {
  return {
    type: ActionTypes.FETCH_ORGANIZATIONS_PENDING,
  }
}

/**
 * Load Organizations
 *
 * @returns {Object}
 */
export function loadOrganizations() {
  return {
    type: ActionTypes.LOAD_ORGANIZATIONS,
  }
}

/**
 * Select Organization
 *
 * @returns {Object}
 */
export function selectOrganization(organizationId) {
  return {
    type: ActionTypes.SELECT_ORGANIZATION,
    payload: { organizationId },
  }
}
