import { fabric } from 'fabric'
import { mtiFixtureType, sviFigureType } from '../../utils/mtiCanvasUtils'

import rectAutoPlacement from '../../images/svg/fixtures/rect.auto-placement.svg'
import circleAutoPlacement from '../../images/svg/fixtures/circle.auto-placement.svg'
import arcAutoPlacement from '../../images/svg/fixtures/arc.auto-placement.svg'
import arcExtendedAutoPlacement from '../../images/svg/fixtures/arc-extended.auto-placement.svg'
import narrowRectangleAutoPlacement from '../../images/svg/fixtures/narrow-rectangle.auto-placement.svg'
import wideRectangleAutoPlacement from '../../images/svg/fixtures/wide-rectangle.auto-placement.svg'
import squareAutoPlacement from '../../images/svg/fixtures/square.auto-placement.svg'

export function getAutoPlacementCoords(type, count) {
  switch (type) {
    case sviFigureType.custom:
    case mtiFixtureType.table:
      return getFabricAutoPlacement(rectAutoPlacement, getPathValues, count)
    case sviFigureType.circle:
    case mtiFixtureType.circle:
      return getFabricAutoPlacement(circleAutoPlacement, getPathValues, count)
    case mtiFixtureType.extendedQuarterCircle:
    case sviFigureType.extendedRightQuarterCircle:
    case sviFigureType.extendedLeftQuarterCircle:
      return getFabricAutoPlacement(
        arcExtendedAutoPlacement,
        getPathValues,
        count
      )
    case mtiFixtureType.quarterCircle:
      return getFabricAutoPlacement(arcAutoPlacement, getPathValues, count)
    case sviFigureType.narrowRectangle:
      return getFabricAutoPlacement(
        narrowRectangleAutoPlacement,
        getPathValues,
        count
      )
    case sviFigureType.wideRectangle:
      return getFabricAutoPlacement(
        wideRectangleAutoPlacement,
        getDoublePathValues,
        count
      )
    case sviFigureType.square:
      return getFabricAutoPlacement(
        squareAutoPlacement,
        getSquarePathValues,
        count
      )
    default:
      return getFabricAutoPlacement(circleAutoPlacement, getPathValues, count)
  }
}

export function getFabricAutoPlacement(resource, pathMethod, count) {
  return new Promise((resolve) => {
    fabric.loadSVGFromURL(resource, (objects, options) => {
      const loadedObjects = fabric.util.groupSVGElements(objects, options)
      const points = pathMethod(loadedObjects, count)
      resolve(points)
    })
  })
}

function getDoublePathValues(loadedObjects, count = 1) {
  const { _objects: objects = [] } = loadedObjects
  let points = []
  if (count === 1) {
    points = getPathValues(objects[0], count)
  } else if (count === 3 || count === 4) {
    const topCount = Math.ceil(count / 2)
    const bottomCount = count - topCount
    points = getPathValues(objects[2], topCount).concat(
      getPathValues(objects[3], bottomCount)
    )
  } else {
    const topCount = Math.ceil(count / 2)
    const bottomCount = count - topCount
    points = getPathValues(objects[0], topCount).concat(
      getPathValues(objects[1], bottomCount)
    )
  }
  return points
}

function getSquarePathValues(loadedObjects, count = 1) {
  const { _objects: objects = [] } = loadedObjects
  if (count === 12) {
    return getPathValues(objects[1], count)
  }
  return getPathValues(objects[0], count)
}

/* https://groups.google.com/d/msg/fabricjs/Eh_zMDsSTIk/wSyHTehEPWAJ */
function getPathValues(objects, samples = 1) {
  const { d: pathVal } = objects
  const path = document.createElementNS('http://www.w3.org/2000/svg', 'path')
  const points = []
  let step = 1
  let i
  let p

  path.setAttribute('d', pathVal)
  const len = path.getTotalLength()
  step = len / samples
  for (i = 0; i < len; i += step) {
    p = path.getPointAtLength(i)
    points.push({
      x: p.x,
      y: p.y,
    })
    if (points.length === samples) break
  }
  return points
}
