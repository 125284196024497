import { ActionTypes } from '../App/constants'

/**
 * Fetch Schedules Failed
 *
 * @returns {Object}
 */
export function fetchSchedulesFailed(error) {
  return {
    type: ActionTypes.FETCH_SCHEDULES_FAILED,
    payload: error,
  }
}

/**
 * Fetch Schedules Fulfilled
 *
 * @returns {Object}
 */
export function fetchSchedulesFulfilled(data) {
  return {
    type: ActionTypes.FETCH_SCHEDULES_FULFILLED,
    payload: {
      schedules: data,
    },
  }
}

/**
 * Fetch Schedules Pending
 *
 * @returns {Object}
 */
export function fetchSchedulesPending() {
  return {
    type: ActionTypes.FETCH_SCHEDULES_PENDING,
  }
}

/**
 * Load Schedules
 *
 * @returns {Object}
 */
export function loadSchedules(data) {
  return {
    type: ActionTypes.LOAD_SCHEDULES,
    payload: data,
  }
}
