import React from 'react'
import PropTypes from 'prop-types'

class SubmitWithHint extends React.PureComponent {
  componentWillUnmount() {
    this.removePopover()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.showHint !== this.props.showHint) {
      if (this.props.showHint) {
        this.addPopover()
      } else {
        this.removePopover()
      }
    }
  }

  removePopover = (force = true) => {
    if (this.buttonRef && this.buttonRef.popover && force) {
      this.buttonRef.popover('dispose')
    }
  }

  addPopover = () => {
    const { showHint, hintMessage } = this.props
    if (showHint) {
      this.buttonRef
        .popover('dispose')
        .popover({
          placement: 'left',
          trigger: 'hover focus',
          content: hintMessage,
        })
        .popover('show')
    }
  }

  render() {
    const { text, onFocus, onClick, className, name } = this.props
    return (
      <button
        ref={(ref) => {
          this.buttonRef = $(ref) // eslint-disable-line
          this.addPopover()
        }}
        onFocus={onFocus}
        onClick={onClick}
        onMouseEnter={onClick}
        type="submit"
        className={className}
        name={name || 'submit'}
      >
        {text}
      </button>
    )
  }
}

SubmitWithHint.defaultProps = {
  text: 'Submit',
  showHint: false,
  hintMessage: 'Please fill out all required fields.',
  className: 'btn btn-primary btn-raised',
}

SubmitWithHint.propTypes = {
  text: PropTypes.string,
  showHint: PropTypes.bool,
  hintMessage: PropTypes.string,
  onFocus: PropTypes.func,
  onClick: PropTypes.func,
  className: PropTypes.string,
}

export default SubmitWithHint

export const defaultRequiredMessage = () => {
  // TODO Restore to some custom validation messages
  // const { target, target: { validity: { valid } } } = event
  // if (valid) {
  //   target.setCustomValidity('')
  // } else {
  //   target.setCustomValidity('Please fill out this field.')
  // }
}
