/*
 *
 * TemplateFixture reducer
 *
 */

import { fromJS } from 'immutable'
import { ActionTypes } from './constants'
import {
  transformFixtureName,
  transformFixtureToggleName,
  transformFixtureType,
  transformFixtureSize,
} from '../../containers/AreaPage/reducer'

export const initialState = fromJS({
  selectedObject: -1,
  canvasObject: {
    objects: [],
    background: '',
  },
  screen: null,
  isStatic: false,
  prototypes: null,
  inheritanceRule: {
    size: false,
    angle: false,
  },
  cloneable: true,
  copyable: false,
})

function templateFixtureReducer(state = initialState, { type, payload }) {
  switch (type) {
    case ActionTypes.TEMPLATE_FIXTURE_CLEAR_CACHED_DATA:
      return initialState
    case ActionTypes.STORE_TEMPLATE_FIXTURE_PROTOTYPES:
      return state.set('prototypes', payload)
    case ActionTypes.STORE_PARAMS:
      return state
        .set('screen', payload.screen)
        .set('isStatic', payload.isStatic)
    case ActionTypes.STORE_TEMPLATE_FIXTURE_LOADING:
      return state.set('loading', payload)
    case ActionTypes.TEMPLATE_FIXTURE_CANVAS_ERROR:
      return state.set('failed', payload).set('loading', false)
    case ActionTypes.FETCH_TEMPLATE_FIXTURE_CANVAS_FULFILLED: {
      const {
        canvas: { canvasObject = {} } = {},
        selectedObject,
        isStatic,
      } = payload
      return state
        .set('canvasObject', fromJS(canvasObject))
        .set('selectedObject', selectedObject)
        .set('loading', false)
        .set('failed', null)
        .set('isStatic', isStatic)
    }
    case ActionTypes.TEMPLATE_FIXTURE_SAVED:
      return state.set('savedTemplate', payload)
    case ActionTypes.TEMPLATE_FIXTURE_UPDATE_INHERITANCE:
      return state.set(
        'inheritanceRule',
        fromJS({ ...state.get('inheritanceRule').toJS(), ...payload })
      )
    case ActionTypes.TEMPLATE_FIXTURE_UPDATE_CC:
      return state
        .set('cloneable', payload.cloneable)
        .set('copyable', payload.copyable)
    case ActionTypes.TEMPLATE_FIXTURE_CHANGED: {
      const isStaticPayload = payload.isStatic
      const prevSelectedIdx = state.get('selectedObject')
      const isStatic = !isStaticPayload
        ? isStaticPayload
        : state.get('isStatic')
      const selectedIdx =
        prevSelectedIdx === payload.selectedObject && isStatic
          ? -1
          : payload.selectedObject

      return state
        .set('canvasObject', fromJS(payload.canvasObject))
        .set('selectedObject', selectedIdx)
        .set('isStatic', isStatic)
    }
    case ActionTypes.TEMPLATE_FIXTURE_NAME_CHANGED: {
      const selectedObject = state.get('selectedObject')

      if (selectedObject < 0) {
        return state
      }

      return state.updateIn(['canvasObject', 'objects'], (objects) =>
        objects.update(
          objects.findIndex((item) => item.get('id') === selectedObject),
          (obj) => transformFixtureName(obj, payload)
        )
      )
    }
    case ActionTypes.TEMPLATE_FIXTURE_NAME_TOGGLED: {
      const selectedObject = state.get('selectedObject')

      if (selectedObject < 0) {
        return state
      }

      return state.updateIn(['canvasObject', 'objects'], (objects) =>
        objects.update(selectedObject, (obj) =>
          transformFixtureToggleName(state, obj, payload)
        )
      )
    }
    case ActionTypes.TEMPLATE_FIXTURE_BEGIN_TRANSFORMATION: {
      const selectedObject = state.get('selectedObject')

      if (selectedObject < 0) {
        return state
      }

      return state.updateIn(['canvasObject', 'objects'], (objects) =>
        objects.update(selectedObject, (obj) =>
          transformFixtureType(state, obj, payload)
        )
      )
    }
    case ActionTypes.TEMPLATE_FIXTURE_SIZE_CHANGED: {
      const selectedObject = state.get('selectedObject')

      if (selectedObject < 0) {
        return state
      }

      return state.updateIn(['canvasObject', 'objects'], (objects) =>
        objects.update(selectedObject, (obj) =>
          transformFixtureSize(state, obj, payload)
        )
      )
    }
    default:
      return state
  }
}

export default templateFixtureReducer
