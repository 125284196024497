import { ActionTypes } from './constants'

/**
 * Provision Hub
 *
 * @returns {Object}
 */
export function provisionHub(payload) {
  return {
    type: ActionTypes.PROVISION_HUB,
    payload,
  }
}

/**
 * Fetch Hubs
 *
 * @returns {Object}
 */
export function fetchHubs(payload) {
  return {
    type: ActionTypes.FETCH_HUBS_REQUEST,
    payload,
  }
}
