/**
 *
 * Templates Details Preloader
 *
 */

import React from 'react'
import ContentLoader from 'react-content-loader'
import uuid from 'uuid'
import { HeaderHeight } from '../../../utils/utils'

const TemplatesDetailsPreloader = (props) => {
  const loaderWidth = window.innerWidth
  const loaderHeight = window.innerHeight
  const widthSidePanel = 340
  const widthCanvas = 600
  const heightCanvas = 400
  const widthInformationPanel = 355
  return (
    <ContentLoader
      height={loaderHeight - HeaderHeight}
      width={loaderWidth}
      speed={2}
      primaryColor="#f3f3f3"
      secondaryColor="#ecebeb"
      {...props}
    >
      {/* for Canvas */}
      <rect
        x={(loaderWidth - widthCanvas - widthSidePanel) / 2}
        y={(loaderHeight - HeaderHeight - heightCanvas) / 2}
        rx="0"
        ry="0"
        width="600"
        height="400"
      />
      {/* for Information Panel */}
      <rect
        x={(loaderWidth - widthInformationPanel - widthSidePanel) / 2}
        y="30"
        rx="6"
        ry="6"
        width="140"
        height="16"
      />
      <rect
        x={(loaderWidth - widthInformationPanel - widthSidePanel) / 2}
        y="55"
        rx="6"
        ry="6"
        width="140"
        height="16"
      />
      <rect
        x={(loaderWidth - widthInformationPanel - widthSidePanel) / 2}
        y="80"
        rx="6"
        ry="6"
        width="140"
        height="16"
      />
      <rect
        x={(loaderWidth - widthInformationPanel - widthSidePanel) / 2 + 155}
        y="30"
        rx="6"
        ry="6"
        width="200"
        height="16"
      />
      {/* 155 = width second part of Information Panel and padding = 15 */}
      <rect
        x={(loaderWidth - widthInformationPanel - widthSidePanel) / 2 + 155}
        y="55"
        rx="6"
        ry="6"
        width="200"
        height="16"
      />
      <rect
        x={(loaderWidth - widthInformationPanel - widthSidePanel) / 2 + 155}
        y="80"
        rx="6"
        ry="6"
        width="200"
        height="16"
      />
      {/* for Side Pannel buttons */}
      <rect
        x={loaderWidth - widthSidePanel}
        y="10"
        rx="6"
        ry="6"
        width="200"
        height="45"
      />
      <rect
        x={loaderWidth - 130}
        y="10"
        rx="6"
        ry="6"
        width="110"
        height="45"
      />
      {/* for Side Pannel first line */}
      <rect
        x={loaderWidth - widthSidePanel}
        y="65"
        rx="0"
        ry="0"
        width={widthSidePanel}
        height="1"
      />
      {/* for Side Pennel */}

      {Array(4)
        .fill('')
        .map((e, i) => (
          <React.Fragment key={`loader_${uuid.v4()}`}>
            <rect
              x={loaderWidth - widthSidePanel + 10}
              y={80 + 75 * i}
              rx="6"
              ry="6"
              width="220"
              height="12"
            />
            <rect
              x={loaderWidth - widthSidePanel + 10}
              y={110 + 75 * i}
              rx="6"
              ry="6"
              width="310"
              height="18"
            />
          </React.Fragment>
        ))}
      <rect
        x={loaderWidth - widthSidePanel + 60}
        y="400"
        rx="6"
        ry="6"
        width="260"
        height="16"
      />
      <rect
        x={loaderWidth - widthSidePanel + 60}
        y="430"
        rx="6"
        ry="6"
        width="260"
        height="16"
      />
      <rect
        x={loaderWidth - widthSidePanel + 10}
        y="410"
        rx="0"
        ry="0"
        width="30"
        height="30"
      />
      <rect
        x={loaderWidth - widthSidePanel}
        y="480"
        rx="0"
        ry="0"
        width={widthSidePanel}
        height="1"
      />

      <rect
        x={loaderWidth - widthSidePanel + 60 + 30}
        y="510"
        rx="6"
        ry="6"
        width="230"
        height="30"
      />
      <rect
        x={loaderWidth - widthSidePanel + 10}
        y="570"
        rx="6"
        ry="6"
        width="220"
        height="10"
      />
      <rect
        x={loaderWidth - widthSidePanel + 250}
        y="560"
        rx="6"
        ry="6"
        width="70"
        height="30"
      />

      <rect
        x={loaderWidth - widthSidePanel + 60 + 30}
        y="610"
        rx="6"
        ry="6"
        width="230"
        height="30"
      />

      <rect
        x={loaderWidth - widthSidePanel}
        y="660"
        rx="0"
        ry="0"
        width={widthSidePanel}
        height="1"
      />
    </ContentLoader>
  )
}
export default TemplatesDetailsPreloader
