import React from 'react'
import PropTypes from 'prop-types'
import InfoIcon from '../../images/svg/buttons/info.svg'
import ErrorIcon from '../../images/svg/buttons/error.svg'

class DoublePasswordsInput extends React.Component {
  constructor(props) {
    super(props)
    // const { password } = props
    this.state = {
      confirmPassword: '',
      invalidMatch: false,
      // isValid: password && confirmPassword == password,
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.password.focus()
    }, 500)
  }

  onChange = (confirmPassword) => {
    const password = this.state.password
    if (password && confirmPassword.confirmPassword == password) {
      this.setState({ isValid: true, password, invalidMatch: false })
      this.props.onChange(password)
    } else {
      this.setState({ isValid: false, password, invalidMatch: true })
    }
  }

  setCustomValidity(msg) {
    const element = this.password
    if (msg === '') {
      this.setServerErrorDismiss(element)
    } else {
      this.setServerError(element, msg)
    }
  }

  setServerErrorDismiss(element) {
    // eslint-disable-next-line
    $(element).keyup(() => {
      $(element).attr('data-content', '') // eslint-disable-line
      $(element).popover('hide') // eslint-disable-line
    })
  }

  setServerError(element, msg) {
    $(element).attr('data-placement', 'bottom') // eslint-disable-line
    $(element).attr('data-content', msg) // eslint-disable-line
    $(element).popover('show') // eslint-disable-line
    setTimeout(() => {
      $(element).attr('data-content', '') // eslint-disable-line
      $(element).popover('disable') // eslint-disable-line
    }, 3000)
  }

  isCustom() {
    return true
  }

  isValid() {
    return this.state.isValid
  }

  infoTooltip = (element, msg) => {
    $(element) // eslint-disable-line
      .popover('dispose')
      .popover({
        placement: 'bottom',
        trigger: 'hover focus',
        content: msg,
      })
  }

  render() {
    const textPassword =
      'Use capital letters, special symbols and number to make password stronger'
    const wrongTextPassword = 'Passwords do not match'

    return (
      <div>
        <div className="mb-3-mti">
          <label htmlFor="password">Create your password</label>
          <div style={{ position: 'relative' }}>
            <input
              ref={(ref) => {
                this.password = ref
                this.setServerErrorDismiss(ref)
              }}
              type="password"
              className="form-control"
              style={{ float: 'left' }}
              id="password"
              placeholder=""
              required="required"
              minLength="8"
              autoComplete="new-password"
              onChange={(e) => this.setState({ password: e.target.value })}
            />
            {this.state.password == null && (
              <img
                ref={(ref) => this.infoTooltip(ref, textPassword)}
                style={{ position: 'absolute', right: '1%' }}
                src={InfoIcon}
                height="26"
                alt="Aspect ratio"
              />
            )}
            <div className="invalid-feedback">
              Should be 8 or more characters.
            </div>
          </div>
        </div>

        <div className="mb-3-mti">
          <label htmlFor="confirmPassword">Reenter your password</label>
          <div style={{ position: 'relative' }}>
            <input
              type="password"
              className="form-control"
              style={{ float: 'left' }}
              id="confirmPassword"
              placeholder=""
              required="required"
              minLength="8"
              autoComplete="confirm-password"
              onChange={(e) =>
                this.onChange({ confirmPassword: e.target.value })
              }
            />
            {this.state.invalidMatch && (
              <img
                ref={(ref) => this.infoTooltip(ref, wrongTextPassword)}
                style={{ position: 'absolute', right: '1%' }}
                className="ml-2 mb-2"
                src={ErrorIcon}
                height="26"
                alt="Aspect ratio"
              />
            )}
          </div>
        </div>
      </div>
    )
  }
}

DoublePasswordsInput.defaultProps = {
  onInvalid: () => {},
  onRequired: () => {},
  onChange: () => {},
  required: false,
}

DoublePasswordsInput.propTypes = {
  password: PropTypes.string,
  confirmPassword: PropTypes.string,
  onInvalid: PropTypes.func,
  onRequired: PropTypes.func,
  onChange: PropTypes.func,
  invalidMsg: PropTypes.string,
  requiredMsg: PropTypes.string,
  required: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}

export default DoublePasswordsInput
