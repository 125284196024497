/**
 *
 * ComplianceRule
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { PositionRuleStatus } from 'mti-jsclient-shared'

import allOk from '../../images/svg/health-check/all-ok.svg'
import lowUrgency from '../../images/svg/health-check/low-urgency.svg'

const Rule = styled.div`
  display: flex;
  color: rgba(0, 0, 0, 0.38);
  font-size: 14px;
  margin-bottom: 10px;
  align-items: center;
`

const StatusIcon = styled.img`
  display: block;
  flex-shrink: 0;
  width: 25px;
  height: 25px;
  margin-right: 10px;
  overflow: hidden;
  object-fit: contain;
`

const getStatusIcon = (status) => {
  switch (status) {
    case PositionRuleStatus.OK:
      return allOk
    case PositionRuleStatus.VIOLATION:
      return lowUrgency
    default:
      return lowUrgency
  }
}

function ComplianceRule({ children, status }) {
  return (
    <Rule>
      {status ? <StatusIcon src={getStatusIcon(status)} /> : null}
      {children}
    </Rule>
  )
}

ComplianceRule.propTypes = {
  children: PropTypes.any,
  status: PropTypes.number,
}

export default ComplianceRule
