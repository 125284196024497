export default (fontSize, lineHeight = 14, linesToShow = 3) => {
  const lineH = Math.ceil(lineHeight)

  return {
    display: '-webkit-box',
    maxHeight: `${lineH * linesToShow}px`,
    fontSize: `${fontSize}px`,
    lineHeight: `${lineH}px`,
    WebkitLineClamp: linesToShow,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }
}

export const excerptStr = (fontSize, lineHeight = 14, linesToShow = 3) => {
  const lineH = Math.ceil(lineHeight)

  return `
    display: block;
    display: -webkit-box;
    max-height: ${lineH * linesToShow}px;
    font-size: ${fontSize}px;
    line-height: ${lineH}px;
    -webkit-line-clamp: ${linesToShow};
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  `
}
