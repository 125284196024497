import React from 'react'
import styled from 'styled-components'
import {
  CheckboxAndInputContainer,
  CheckboxContainer,
} from '../../containers/TemplateFixture/index.screen'
import Checkbox from '../Checkbox'
import EditDisabled from '../EditDisabled'
import { Container } from '../GroupAdornment'
import PropTypes from 'prop-types'
import Select from '../Select'

const CustomContainer = styled(Container)`
  ${(props) =>
    props.disabled && 'border-bottom-color: grey;'} padding-bottom: 12px;
  input {
    background: none !important;
    text-align: center;
    margin-bottom: 3px;
  }
  label {
    border: none;
  }
  .editDisabled {
    label {
      ${(props) => props.disabled && 'margin-bottom: 5px !important'};
    }
  }
  select.form-control {
    background-image: none;
  }
`

class BackupKeysForStores extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isOptionsVisible: props.checked && props.enableType,
    }
  }

  onChange = ({ target: { checked } }) => {
    this.setState({ isOptionsVisible: checked })
    this.props.onChange(checked)
  }

  onEnableTypeChange = ({ target: { value } }) => {
    this.setState({ enableType: value })
    this.props.onChangeOption(value)
  }

  optionsUI = () => {
    const { isOptionsVisible } = this.state
    const { disabled, enableType } = this.props
    const enableTypeOption = {
      byDefault: 'byDefault',
      manually: 'manually',
    }

    if (disabled) {
      if (isOptionsVisible) {
        return <span>{enableTypeOption[enableType]}</span>
      } else {
        return (
          <span className="editDisabled">
            <EditDisabled text="Check to set default" grey />
          </span>
        )
      }
    } else {
      if (isOptionsVisible) {
        return (
          <Select
            name="enableType"
            value={enableType || enableTypeOption.byDefault}
            onChange={this.onEnableTypeChange}
          >
            <option value={enableTypeOption.byDefault}>
              Enable by default for each store
            </option>
            <option value={enableTypeOption.manually}>
              Enable manually for each store
            </option>
          </Select>
        )
      } else {
        return (
          <span className="editDisabled">
            <EditDisabled text="Check to set default" grey />
          </span>
        )
      }
    }
  }

  render() {
    const { disabled, checked, name } = this.props
    return (
      <CustomContainer disabled={disabled}>
        <CheckboxAndInputContainer>
          <CheckboxContainer>
            <Checkbox
              name={name}
              onChange={this.onChange}
              checked={checked}
              disabled={disabled}
            />
          </CheckboxContainer>
          {/*{this.optionsUI()}*/}
        </CheckboxAndInputContainer>
      </CustomContainer>
    )
  }
}

BackupKeysForStores.propTypes = {
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  onChangeOption: PropTypes.func,
  onChange: PropTypes.func,
  enableType: PropTypes.string,
}

export default BackupKeysForStores
