import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { storeLockdown } from '../../../containers/App/actions'
import { FormattedMessage, injectIntl } from 'react-intl'
import { createStructuredSelector } from 'reselect'
import { selectStoreMode } from '../selectors'
import { StoreMode as storeModes } from 'mti-jsclient-shared'
import {
  StoreActionButton,
  StoreActionButtonActive,
} from '../../../styles/styled'

const intlPath = (string) => `components.breadcrumbs.${string}`

class StoreModeButton extends React.PureComponent {
  _getStoreAction = () => {
    const { params: { sId }, storeMode, storeLockdown } = this.props

    let actionMode = null
    if (storeMode === storeModes.NORMAL) {
      actionMode = storeModes.EMERGENCY
    } else {
      actionMode = storeModes.NORMAL
    }

    let action = {
      name: actionMode,
      callback: () => storeLockdown({ id: sId, action: actionMode }),
    }

    return action
  }

  render() {
    const { params: { sId } } = this.props
    if (sId) {
      let action = this._getStoreAction()
      let ModeButton =
        action.name === storeModes.EMERGENCY
          ? StoreActionButtonActive
          : StoreActionButton
      return (
        <ModeButton type="button" onClick={action.callback}>
          <FormattedMessage
            id={intlPath('actionItem.storeMode')}
            values={{ mode: action.name }}
          />
        </ModeButton>
      )
    }
    return null
  }
}

StoreModeButton.propTypes = {
  params: PropTypes.object,
}

const mapStateToProps = createStructuredSelector({
  storeMode: (state, ownProps) =>
    selectStoreMode(ownProps.params.sId)(state, ownProps),
})

const mapDispatchToProps = { storeLockdown }

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(StoreModeButton)
)
