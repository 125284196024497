/**
 *
 * FloorsPage
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import uuid from 'uuid'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose } from 'redux'
import injectSaga from 'utils/injectSaga'
import injectReducer from 'utils/injectReducer'
import {
  openModal,
  closeModal,
  notifyModal,
  subscribeOnTopic,
} from 'containers/App/actions'
import ConfirmModal from 'containers/ModalContainer/ConfirmModal'
import {
  makeSelectMtiPermission,
  makeSelectStoreIsInDefultFloorMode,
} from '../App/selectors'
import {
  makeSelectCanvasObject,
  makeSelectCanvasSelectedObject,
  makeSelectPositionsByStore,
  makeSelectFloors,
  makeSelectIsStatic,
  makeSelectedFloorName,
  makeSelectedFloorOrder,
  makeSelectedFloorImage,
  makeSelectedFloorShouldDisplayBackground,
  makeSelectFailed,
  makeSelectLoading,
  selectStore,
} from './selectors'
import reducer from './reducer'
import saga from './sagas'
import FloorsPageScreen from './index.screen'
import {
  storeCanvasChanged,
  removeObject,
  loadCanvas,
  saveCanvas,
  saveFloor,
  changeFloor,
  createNewFloor,
  imageUpload,
} from './actions'
import ProgressIndicator from '../../components/ProgressIndicator'
import StoreLayoutPreloader from '../../components/Preloaders/StoreLayoutPreloader'
import {
  positionPath,
  storeTopic,
  getQueryVariable,
  safeUri,
} from '../../utils/utils'
import { orgUrl } from '../../utils/mtiUtils'
import { incrementalObjectName, defaultName } from '../../utils/mtiCanvasUtils'
import AspectRatioTooltip from '../../components/AspectRatioTooltip'
import FetchFailedAlert from '../../components/FetchFailed'
import { loadSecuredProducts } from '../SecuredProducts/actions'
import {
  selectMissingSecuredProductsForStore,
  selectMissingSecuredProductsByFloorId,
} from '../ExceptionsList/SecuredProducts/selectors'

const permText = (text) =>
  `You don't have appropriate rights to access ${text}.\nPlease contact your store manager.`

export class FloorsPage extends React.PureComponent {
  /* eslint-disable no-undef, react/sort-comp */
  constructor(props) {
    super(props)
    const floorId = parseInt(getQueryVariable('floorId') || '-1', 10)
    const floorName = getQueryVariable('floorName')
    const isStatic = getQueryVariable('isStatic') !== 'false'
    this.state = { floorId, floorName, isStatic }
  }

  componentDidMount() {
    this.props.loadSecuredProducts(this.props.match.params.sId)
    this.loadCanvas(null, this.state.isStatic)
    this.listenHistory()
  }

  componentDidUpdate(prevProps) {
    const { sId: prevSId } = prevProps.match.params
    const { sId: thisSId } = this.props.match.params
    //
    const pathParams = window.location.pathname.split('/') || []
    const isMtiAdmin = pathParams[1] === 'organizations'
    const realSId = isMtiAdmin ? pathParams[5] : pathParams[2]
    if (prevSId !== thisSId) {
      this.props.loadSecuredProducts(this.props.match.params.sId)
      this.loadCanvas({ sId: thisSId }, this.props.isStatic)
    } else if (realSId !== thisSId) {
      // In case when we update path via react-router-redux push, extra history.push is required
      this.props.history.push(window.location.pathname)
    }
  }

  componentWillUnmount() {
    this.unlistenHistory()
  }

  onSaveCanvas(canvasObject) {
    this.props.saveCanvas({
      canvasObject,
      sId: this.props.match.params.sId,
    })
  }

  listenHistory() {
    this.unlistenHistory = this.props.history.listen(() => {
      const floorId = parseInt(getQueryVariable('floorId') || '-1', 10)
      const floorName = getQueryVariable('floorName')
      const isStatic = getQueryVariable('isStatic') !== 'false'
      if (this.state.floorId !== floorId) {
        this.setState({ floorId, floorName, isStatic }, () =>
          this.loadCanvas(null, isStatic, -1)
        )
      }
    })
  }

  loadCanvas(params, isStatic, sObject) {
    const newParams = params || this.props.match.params
    const topic = storeTopic(newParams.sId)
    this.props.subscribeOnTopic(topic)
    const { floorId } = this.state
    this.props.loadCanvas(isStatic, sObject, floorId, newParams.sId)
  }

  questionText(areasCount) {
    if (areasCount) {
      return `This floor contains ${areasCount} area(s), that may contain fixtures with positions. Deleting this floor will also delete all areas, fixtures and positions inside. Are you sure you want to proceed?`
    }
    return 'Are you sure you want to proceed?'
  }

  onPreRemoveFloor(canvasObject, sObject) {
    const { openModal: doOpenModal, closeModal: doCloseModal } = this.props
    console.log('onPreRemoveFloor', canvasObject.objects[sObject].areasCount)
    const areasCount = canvasObject.objects[sObject].areasCount
    doOpenModal({
      id: 'delete',
      type: 'bootstrap',
      appearance: areasCount ? 'modal-md' : 'modal-sm',
      content: (
        <ConfirmModal
          id={'delete'}
          questionText={this.questionText(areasCount)}
          cancelText={'CANCEL'}
          confirmText={'DELETE FLOOR'}
          onClose={() => doCloseModal({ id: 'delete' })}
          onConfirm={() => {
            if (this.state.floorId !== -1) {
              this.setState(
                { floorId: -1 },
                this.onRemoveFloor(canvasObject, sObject)
              )
              return
            }
            this.onRemoveFloor(canvasObject, sObject)
          }}
        />
      ),
    })
  }

  onRemoveFloor(canvasObject, sObject) {
    this.props.removeObject({
      canvasObject,
      sId: this.props.match.params.sId,
      fId: this.props.match.params.fId,
      selectedObject: sObject,
    })
  }

  onPreSaveFloor(canvasObject, sObject) {
    if (this.state.floorId !== -1) {
      this.setState({ floorId: -1 }, this.onSaveFloor(canvasObject, sObject))
      return
    }
    this.onSaveFloor(canvasObject, sObject)
  }

  onSaveFloor(canvasObject, sObject) {
    this.props.saveFloor({
      canvasObject,
      sId: this.props.match.params.sId,
      selectedObject: sObject,
    })
  }

  onPreCreateNewFloor() {
    this.onCreateNewFloor()
  }

  onCreateNewFloor() {
    const floorName = incrementalObjectName(
      this.props.canvasObject.objects,
      defaultName.floor
    )
    this.props.createNewFloor({
      id: uuid.v4(),
      name: floorName,
      order: 1,
    })
  }

  onImageUpload(canvasObject, sObject) {
    this.props.imageUpload({
      canvasObject,
      sId: this.props.match.params.sId,
      selectedObject: sObject,
    })
  }

  onCancel() {
    if (!this.props.isStatic) {
      const { id: sFloorId, name: sFloorName } = this.getSelectedObject()
      const { floorId } = this.state
      if (floorId === sFloorId) {
        const { canvasObject: { objects } = {} } = this.props
        if ((objects || []).length === 1) {
          // Return back case, after Add Floors To Store navigation
          this.props.history.push(
            `${this.getCurrentPath()}/${sFloorId}/${safeUri(sFloorName)}`
          )
          return
        }
      }
    }
    this.loadCanvas(null, true, -1)
  }

  onMessage(msg) {
    const { notifyModal: doNotifyModal } = this.props
    doNotifyModal(msg)
  }

  onOpenFloor(obj) {
    const { isStatic } = this.props
    const { id, name } = obj || {}

    if (isStatic && id && name) {
      this.props.history.push(`${this.getCurrentPath()}/${id}/${safeUri(name)}`)
    }
  }

  getCurrentPath() {
    const { match: { params: { sId, sName } = {} } = {} } = this.props
    return `${orgUrl()}/stores/${sId}/${safeUri(sName)}/floors`
  }

  getSelectedObject() {
    const { canvasObject, selectedObject } = this.props
    return selectedObject > -1 && canvasObject.objects[selectedObject]
  }

  /* eslint-disable no-undef, react/sort-comp */
  onOpenPosition = ({ positionId }) => {
    const { positions: { positionsStore }, history } = this.props
    const position = positionsStore.find(({ id }) => id === positionId)
    positionPath(position, history)
  }

  navigateToSecuredProducts = (navigateToPath) => {
    const { store, history } = this.props
    navigateToPath(store, history)
  }

  noPermission = (hasPermission, text) => {
    const { notifyModal: doNotifyModal } = this.props
    if (!hasPermission) doNotifyModal(text)
    return !hasPermission
  }

  onPreImageCheckChange(checked) {
    const { openModal: doOpenModal, closeModal: doCloseModal } = this.props
    if (checked) {
      this.onImageCheckChange(checked)
      return
    }
    doOpenModal({
      id: 'image-unchecked',
      type: 'bootstrap',
      appearance: 'modal-md',
      content: (
        <ConfirmModal
          id={'image-unchecked'}
          questionText={
            'By unselecting the image you will delete and remove it from the floor. Are you sure you want to proceed? You will be able to Cancel this change until you click Save.'
          }
          cancelText={'CANCEL'}
          confirmText={'UNSELECT IMAGE'}
          onClose={() => doCloseModal({ id: 'image-unchecked' })}
          onConfirm={() => this.onImageCheckChange(checked)}
        />
      ),
    })
  }

  /* eslint-disable no-undef */
  onImageCheckChange(checked) {
    const {
      changeFloor: onChange,
      selectedFloorName,
      selectedFloorOrder,
      selectedFloorImage,
      selectedFloorShouldDisplayBackground,
    } = this.props
    onChange({
      floorName: selectedFloorName,
      floorOrder: selectedFloorOrder,
      floorImage:
        !checked && selectedFloorImage ? undefined : selectedFloorImage,
      floorShouldDisplayBackground:
        !checked && selectedFloorShouldDisplayBackground
          ? false
          : selectedFloorShouldDisplayBackground,
    })
  }

  render() {
    const {
      storeCanvasChanged: onChanged,
      canvasObject,
      selectedObject,
      positions,
      floors,
      store,
      loading,
      failed,
      isStatic,
      changeFloor: onChange,
      selectedFloorName,
      selectedFloorOrder,
      selectedFloorImage,
      selectedFloorShouldDisplayBackground,
      hasStoreEditorPermission,
      isDefaultFloorMode,
      missingSecuredProductsForStore,
      missingSecuredProductsByFloorId,
    } = this.props

    if (loading)
      return (
        <React.Fragment>
          <StoreLayoutPreloader />
          <ProgressIndicator text={'Loading...'} />
        </React.Fragment>
      )
    if (failed) return <FetchFailedAlert failed={failed} />

    return (
      <AspectRatioTooltip
        ratio={3 / 2}
        ratioText="3:2"
        render={(ratioTooltip) => (
          <FloorsPageScreen
            path={this.getCurrentPath()}
            positions={positions}
            floors={floors}
            store={store}
            isDefaultFloorMode={isDefaultFloorMode}
            onSave={() => this.onSaveCanvas(canvasObject)}
            onCanvasChanged={(o) => {
              if (!o.isStatic) {
                if (
                  this.noPermission(
                    hasStoreEditorPermission,
                    permText('Store Editor')
                  )
                ) {
                  return
                }
              }
              onChanged(o)
            }}
            canvasObject={canvasObject}
            selectedObject={selectedObject}
            isStatic={isStatic}
            onRemove={(sObject) => this.onPreRemoveFloor(canvasObject, sObject)}
            onFloorChange={onChange}
            onFloorSave={(sObject) =>
              this.onPreSaveFloor(canvasObject, sObject)
            }
            onCreateNewFloor={() => {
              if (
                this.noPermission(
                  hasStoreEditorPermission,
                  permText('Store Editor')
                )
              ) {
                return
              }
              this.onPreCreateNewFloor()
            }}
            onOpenFloor={(obj) => this.onOpenFloor(obj)}
            onMessage={(msg) => this.onMessage(msg)}
            onEdit={() => {
              if (
                this.noPermission(
                  hasStoreEditorPermission,
                  permText('Store Editor')
                )
              ) {
                return
              }
              this.loadCanvas(null, false, selectedObject)
            }}
            onCancel={() => this.onCancel()}
            onImageUpload={() =>
              this.onImageUpload(canvasObject, selectedObject)
            }
            onImageCheckChange={(checked) =>
              this.onPreImageCheckChange(checked)
            }
            selectedFloorName={selectedFloorName}
            selectedFloorOrder={selectedFloorOrder}
            selectedFloorImage={selectedFloorImage}
            selectedFloorShouldDisplayBackground={
              selectedFloorShouldDisplayBackground
            }
            openPosition={this.onOpenPosition}
            ratioTooltip={ratioTooltip}
            missingSecuredProductsForStore={missingSecuredProductsForStore}
            missingSecuredProductsByFloorId={missingSecuredProductsByFloorId}
            onGoToSecuredProducts={this.navigateToSecuredProducts}
          />
        )}
      />
    )
  }
}

FloorsPage.propTypes = {
  match: PropTypes.any,
  history: PropTypes.any,
  params: PropTypes.shape({
    sId: PropTypes.string.isRequired,
  }),
  loadCanvas: PropTypes.func.isRequired,
  saveCanvas: PropTypes.func.isRequired,
  saveFloor: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  failed: PropTypes.object,
  isStatic: PropTypes.bool,
  isDefaultFloorMode: PropTypes.bool,
  storeCanvasChanged: PropTypes.func.isRequired,
  removeObject: PropTypes.func.isRequired,
  changeFloor: PropTypes.func.isRequired,
  createNewFloor: PropTypes.func.isRequired,
  imageUpload: PropTypes.func.isRequired,
  canvasObject: PropTypes.object.isRequired,
  floors: PropTypes.array,
  selectedObject: PropTypes.number,
  positions: PropTypes.object,
  store: PropTypes.object,
  selectedFloorName: PropTypes.string,
  selectedFloorOrder: PropTypes.any,
  selectedFloorImage: PropTypes.any,
  selectedFloorShouldDisplayBackground: PropTypes.bool,
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
  notifyModal: PropTypes.func,
  hasStoreEditorPermission: PropTypes.bool,
  subscribeOnTopic: PropTypes.func,
}

const mapStateToProps = createStructuredSelector({
  canvasObject: (state, ownProps) =>
    makeSelectCanvasObject(ownProps.match.params.sId)(state, ownProps),
  selectedObject: makeSelectCanvasSelectedObject(),
  positions: (state, ownProps) =>
    makeSelectPositionsByStore(ownProps.match.params.sId)(state, ownProps),
  store: (state, ownProps) => selectStore(state, ownProps.match.params.sId),
  floors: makeSelectFloors(),
  loading: makeSelectLoading(),
  failed: makeSelectFailed(),
  isStatic: makeSelectIsStatic(),
  selectedFloorName: makeSelectedFloorName(),
  selectedFloorOrder: makeSelectedFloorOrder(),
  selectedFloorImage: makeSelectedFloorImage(),
  selectedFloorShouldDisplayBackground: makeSelectedFloorShouldDisplayBackground(),
  isDefaultFloorMode: (state, ownProps) =>
    makeSelectStoreIsInDefultFloorMode(ownProps.match.params.sId)(
      state,
      ownProps
    ),
  hasStoreEditorPermission: (state, ownProps) =>
    makeSelectMtiPermission('Store: Editor', ownProps.match.params.sId)(
      state,
      ownProps
    ),
  missingSecuredProductsForStore: (state, ownProps) =>
    selectMissingSecuredProductsForStore(state, ownProps.match.params.sId),
  missingSecuredProductsByFloorId: (state, ownProps) =>
    selectMissingSecuredProductsByFloorId(state, ownProps.match.params.sId),
})

const mapDispatchToProps = {
  loadCanvas,
  storeCanvasChanged,
  removeObject,
  saveCanvas,
  saveFloor,
  changeFloor,
  createNewFloor,
  imageUpload,
  openModal,
  closeModal,
  notifyModal,
  subscribeOnTopic,
  loadSecuredProducts,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const withReducer = injectReducer({ key: 'floorsPage', reducer })
const withSaga = injectSaga({ key: 'floorsPage', saga })

export default compose(withReducer, withSaga, withConnect)(FloorsPage)
