import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import SelectProducts from './SelectProducts'
import Select from '../../../components/Select'
import {
  PortRulesSourceAttributesModel,
  sourceAttrName,
  conditionName,
} from './ruleConstants'

export const ModalBody = styled.div`
  padding: 10px 10px 10px 10px;
`
const PortRule = styled.div`
  display: flex;
  color: rgba(0, 0, 0, 0.38);
  font-size: 14px;
  margin-bottom: 10px;
  align-items: center;
`
const SaveButton = styled.div`
  margin-top: 10px;
`

const PortSelected = styled.span`
  font-size: 1.2rem;
`

class PortRulesScreen extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedPort: null,
    }
  }

  editPort = (port) => {
    this.setState({ selectedPort: port })
  }

  deleteRule = (port) => {
    const { onDelete } = this.props

    onDelete(port)
  }

  onAddSelectedProducts = (data) => {
    const { onAddSelectedProducts } = this.props
    const { selectedPort } = this.state

    onAddSelectedProducts({
      selectedProducts: data,
      port: selectedPort,
    })
  }

  onRemoveSelectedProducts = (data) => {
    const { onRemoveSelectedProducts } = this.props
    const { selectedPort } = this.state

    onRemoveSelectedProducts({
      selectedProducts: data,
      port: selectedPort,
    })
  }

  renderProductList = () => {
    const { products, selectedProducts } = this.props

    const { selectedPort } = this.state

    return (
      <div>
        <SelectProducts
          products={products}
          onAddSelectedProducts={this.onAddSelectedProducts}
          onRemoveSelectedProducts={this.onRemoveSelectedProducts}
          selectedProducts={selectedProducts[selectedPort]}
        />
      </div>
    )
  }

  renderRuleTypeConfig = () => {
    const { onChangeRuleType, ruleTypes } = this.props
    const { selectedPort } = this.state
    return (
      <div className="col-xs-6">
        <div className="form-row">
          <Select
            customStyle="pt-0"
            name="ruleType"
            value={ruleTypes[selectedPort]}
            label={'Rule Type'}
            onChange={(e) =>
              onChangeRuleType({
                ruleType: e.target.value,
                port: selectedPort,
              })
            }
          >
            <option value={''}>Please select rule type...</option>
            {this.renderRuleTypeOptions()}
          </Select>
        </div>
      </div>
    )
  }

  renderRuleTypeOptions = () =>
    Object.keys(PortRulesSourceAttributesModel).map((attr) => (
      <option key={attr} value={attr}>
        {sourceAttrName[attr]}
      </option>
    ))

  renderConditions = () => {
    const { onChangeCondition, conditions, ruleTypes } = this.props
    const { selectedPort } = this.state

    let ruleType = ruleTypes[selectedPort]
    let condition = conditions[selectedPort]
    let initialValue = null
    if (condition) {
      initialValue = condition
    } else if (ruleType) {
      initialValue = PortRulesSourceAttributesModel[ruleType].conditions[0]
      onChangeCondition({ condition: initialValue, port: selectedPort })
    }

    return (
      <div className="form-row">
        <Select
          customStyle="pt-0"
          name="condition"
          value={initialValue}
          label={'Condition'}
          onChange={(e) =>
            onChangeCondition({ condition: e.target.value, port: selectedPort })
          }
        >
          {this.renderConditionOptions(ruleType)}
        </Select>
      </div>
    )
  }

  renderConditionOptions = (ruleType) => {
    const conditions = ruleType
      ? PortRulesSourceAttributesModel[ruleType].conditions
      : []

    return conditions.map((condition) => (
      <option key={condition} value={condition}>
        {conditionName[condition]}
      </option>
    ))
  }

  paramsInvalid = () => {
    const { ruleTypes, conditions, selectedProducts } = this.props
    const { selectedPort } = this.state

    let invalid = true

    let productsSelected = selectedProducts[selectedPort]
    let condition = conditions[selectedPort]
    let ruleType = ruleTypes[selectedPort]

    if (productsSelected.length > 0 && !!condition && !!ruleType) {
      invalid = false
    }

    return invalid
  }

  renderEditParams = (editing) => {
    const { id, onConfirm } = this.props
    const { selectedPort } = this.state

    let paramsInvalid = this.paramsInvalid()

    return (
      <div>
        {this.renderRuleTypeConfig()}
        {this.renderConditions()}
        {this.renderProductList()}
        <SaveButton>
          <button
            type="button"
            className="btn btn-raised"
            disabled={paramsInvalid}
            onClick={() => {
              $(`#modal-${id}`).modal('hide') // eslint-disable-line
              onConfirm(selectedPort, editing)
            }}
          >
            Save
          </button>
        </SaveButton>
      </div>
    )
  }

  renderMainContent = () => {
    const { numberOfPorts, rules } = this.props
    const { selectedPort } = this.state

    let ports = []

    for (let i = 0; i < numberOfPorts; i++) {
      let rule = rules[i]
      let selected = selectedPort === i
      let editing = !!rule

      let deleteButton = (
        <span>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              this.deleteRule(i)
            }}
          >
            DELETE
          </button>
        </span>
      )

      let portText = `Port ${i + 1}`
      if (selected) {
        portText = <PortSelected>{portText}</PortSelected>
      }

      ports.push(
        <div>
          <div key={`port-${i}`}>
            {portText}
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                this.editPort(i)
              }}
              disabled={selected}
            >
              {rule ? 'Edit' : 'Add'}
            </button>
            {rule ? deleteButton : null}
            <PortRule>{rule ? rule.description : null}</PortRule>
            {selected ? this.renderEditParams(editing) : null}
          </div>
        </div>
      )
    }

    return <div>{ports}</div>
  }
  render() {
    const { id, onClose } = this.props

    return (
      <div className="modal-content">
        <div className="modal-header border-bottom">
          <h5 className="modal-title">Add Port Compliance Rules</h5>
          <div>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                $(`#modal-${id}`).modal('hide') // eslint-disable-line
                onClose()
              }}
            >
              Cancel
            </button>
          </div>
        </div>
        <ModalBody className="modal-body">{this.renderMainContent()}</ModalBody>
      </div>
    )
  }
}

PortRulesScreen.propTypes = {
  id: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  numberOfPorts: PropTypes.number.isRequired,
  products: PropTypes.array.isRequired,
  onAddSelectedProducts: PropTypes.func.isRequired,
  onRemoveSelectedProducts: PropTypes.func.isRequired,
  onChangeCondition: PropTypes.func.isRequired,
  onChangeRuleType: PropTypes.func.isRequired,
  ruleTypes: PropTypes.array,
  rules: PropTypes.array,
  conditions: PropTypes.array,
  selectedProducts: PropTypes.array,
}

export default PortRulesScreen
