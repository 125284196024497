import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import { ExportToCsv } from 'export-to-csv'
import injectReducer from 'utils/injectReducer'
import injectSaga from 'utils/injectSaga'
import { openModal, closeModal, notifyModal } from '../App/actions'
import { makeSelectMtiPermissionAnyResource } from '../App/selectors'
import exceptionsReducer from './reducer'
import exceptionsSaga from './sagas'
import {
  loadExceptions,
  loadExceptionsAsync,
  asyncLoadingCancel,
} from './actions'
import { loadStores } from '../StoresPage/actions'
import {
  makeSelectExceptions,
  makeSelectAsyncLoadingStatus,
  makeSelectAsyncLoadingErrors,
} from './selectors'
import filterReducer from './CreateFiltersModal/reducer'
import { makeSelectCombination } from './CreateFiltersModal/selectors'
import {
  makeSelectLoading as makeSelectStoresLoading,
  makeSelectStores,
} from '../StoresPage/selectors'
import ExceptionsPageScreen from './index.screen'
import { formattedDuration } from './utils'
import CheckboxFilter from './CreateFiltersModal/CheckboxFilter'
import CreateFiltersModal from './CreateFiltersModal'
import { resetStoreToDefaults as resetFiltersInModal } from './CreateFiltersModal/actions'
import { CSVOptions } from '../../utils/utils'

const permText = (text) =>
  `You don't have appropriate rights to access ${text}.\nPlease contact your store manager.`

export class ExceptionsPage extends React.Component {
  componentWillUnmount() {
    this.props.resetFiltersInModal()
  }

  componentDidMount() {
    const { hasViewReportsPermission, history } = this.props
    if (this.noPermission(hasViewReportsPermission, permText('the Exceptions'))) {
      history.push('/')
      return
    }

    if (this.props.stores && this.props.stores.length) {
      this.props.loadExceptionsAsync()
    } else {
      this.props.loadStores()
    }
  }

  componentDidUpdate(prevProps) {
    // 'loading' represents whether stores are loading, not exceptions
    // so if the stores were still loading when componentDidMount was fired, then load the exceptions after they're finished.
    if (!this.props.loading && prevProps.loading) {
      this.props.loadExceptionsAsync()
    }
  }

  noPermission = (hasPermission, text) => {
    const { notifyModal: doNotifyModal } = this.props
    if (!hasPermission) doNotifyModal(text)
    return !hasPermission
  }

  exportAsCSV = () => {
    const data = this.onExportExceptions()
    const options = CSVOptions(this.fileName())
    const csvExporter = new ExportToCsv(options)

    csvExporter.generateCsv(data)
  }

  onExportExceptions = () => {
    const { exceptions: data } = this.props
    const exceptionsData = data.map(r => [
      r.id,
      r.fixtureId,
      r.storeName,
      r.area ? r.area.name : '',
      r.fixtureName,
      r.resourceTypeResource,
      r.eventTypeMessage,
      r.occurredAt,
      r.duration,
    ])

    const columnName = [
      'id',
      'fixtureId',
      'storeName',
      'areaName',
      'fixtureName',
      'resourceTypeResource',
      'eventTypeMessage',
      'occurredAt',
      'duration',
    ]

    exceptionsData.unshift(columnName)

    return exceptionsData
  }

  fileName() {
    const date = moment().format('MM-DD-YYYY-HH-mm-ss')
    return `Exceptions_${date}`
  }

  onCreateFilter = () => {
    const { openModal: doOpenModal, closeModal: doCloseModal } = this.props

    const initialCombination = {
      health: true,
      operations: true,
      marketing: false,
    }

    doOpenModal({
      id: 'create-filters',
      type: 'bootstrap',
      content: (
        <CreateFiltersModal
          id={'create-filters'}
          initialCombination={initialCombination}
          combinationNodes={this.combination}
          onClose={() => {
            doCloseModal({ id: 'create-filters' })
          }}
          onConfirm={() => {
            this.props.loadExceptionsAsync()
          }}
        />
      ),
    })
  }

  combination = (combination, onChange) => (
    <React.Fragment>
      <div className="form-group mb-2 mx-sm-3">
        <CheckboxFilter
          name="health"
          label="Health"
          isChecked={combination.health}
          onChange={onChange}
        />
      </div>
      <div className="form-group mb-2 mx-sm-3">
        <CheckboxFilter
          name="operations"
          label="Operations"
          isChecked={combination.operations}
          onChange={onChange}
        />
      </div>
    </React.Fragment>
  )

  render() {
    const {
      exceptions,
      status,
      errors,
      asyncLoadingCancel: onAsyncLoadingCancel,
      loading,
    } = this.props
    if (status.currentName) {
      console.warn(
        `Loading ${status.currentName} (${status.currentNumber} of ${
          status.total
        })`
      )
    }

    if (errors && errors.length) {
      errors.map((e) => {
        const { error = {} } = e
        const msg = error.message ? error.message : (error.error || {}).message
        console.warn(msg, e.storeName)
        return { name: e.storeName, message: msg }
      })
    }

    return (
      <div>
        <ExceptionsPageScreen
          exceptions={exceptions}
          onAsyncLoadingCancel={
            status.currentName ? () => onAsyncLoadingCancel() : undefined
          }
          csvData={this.onExportExceptions()}
          onExportAsCSV={this.exportAsCSV}
          createFilter={this.onCreateFilter}
          loadingStatus={status}
          loading={loading}
          errors={errors}
        />
      </div>
    )
  }
}

ExceptionsPage.propTypes = {
  loadStores: PropTypes.func.isRequired,
  loadExceptionsAsync: PropTypes.func.isRequired,
  asyncLoadingCancel: PropTypes.func.isRequired,
  exceptions: PropTypes.array,
  combination: PropTypes.object,
  status: PropTypes.object,
  errors: PropTypes.array,
  loading: PropTypes.bool,
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
  notifyModal: PropTypes.func,
  resetFiltersInModal: PropTypes.func,
  hasViewReportsPermission: PropTypes.bool,
}

const mapStateToProps = createStructuredSelector({
  exceptions: makeSelectExceptions(),
  status: makeSelectAsyncLoadingStatus(),
  errors: makeSelectAsyncLoadingErrors(),
  loading: makeSelectStoresLoading(),
  combination: makeSelectCombination(),
  stores: makeSelectStores(),
  hasViewReportsPermission: makeSelectMtiPermissionAnyResource(
    'Saved Report: Index (list) Records'
  ),
})

const mapDispatchToProps = {
  openModal,
  closeModal,
  loadExceptions,
  loadExceptionsAsync,
  asyncLoadingCancel,
  notifyModal,
  resetFiltersInModal,
  loadStores,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)
const withExceptionsReducer = injectReducer({
  key: 'exceptions',
  reducer: exceptionsReducer,
})

const withExceptionsSaga = injectSaga({
  key: 'exceptions',
  saga: exceptionsSaga,
})

const withFilterReducer = injectReducer({
  key: 'resourceFilter',
  reducer: filterReducer,
})

export default compose(
  withExceptionsReducer,
  withExceptionsSaga,
  withFilterReducer,
  withConnect
)(ExceptionsPage)
