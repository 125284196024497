import React from 'react'
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector'
import PropTypes from 'prop-types'

const CountryRegionScreen = (props) => {
  const {
    country,
    region,
    selectCountry,
    selectRegion,
    validation,
    container: Container,
  } = props

  const cssClass = (field) =>
    `form-control ${validation[field] ? 'is-invalid' : ''}`

  const countryContainer = () => (
    <div className="form-group col-md-4 bmd-form-group">
      <Container>
        <div>Country</div>
        <CountryDropdown
          className={cssClass('address.country')}
          value={country}
          onChange={selectCountry}
          showDefaultOption={false}
        />
        <div className="invalid-feedback">Please enter a valid country.</div>
      </Container>
    </div>
  )

  const regionContainer = () => (
    <div className="form-group col-md-4 bmd-form-group">
      <Container>
        <div>State/Province</div>
        <RegionDropdown
          className={cssClass('address.state')}
          country={country}
          value={region}
          onChange={selectRegion}
          showDefaultOption={false}
        />
        <div className="invalid-feedback">
          Please enter a valid state/province.
        </div>
      </Container>
    </div>
  )

  return (
    <React.Fragment>
      {countryContainer()}
      {regionContainer()}
    </React.Fragment>
  )
}

CountryRegionScreen.propTypes = {
  country: PropTypes.string,
  region: PropTypes.string,
  selectCountry: PropTypes.func.isRequired,
  selectRegion: PropTypes.func.isRequired,
  validation: PropTypes.object,
  container: PropTypes.object,
}

export default CountryRegionScreen
