import { Record, List } from 'immutable'
import moment from 'moment'
import { formatUserData } from '../UsersPage/users'

export const Key = new Record({
  id: null,
  createdAt: null,
  updatedAt: null,
  storeId: null,
  serialNumber: null,
  status: null,
  expirationDate: null,
  showExpirationDate: null,
  // user
  userName: null,
  userId: null,
  // user for modal
  user: null,
  // loading
  loading: null,
  backupKey: null,
})

function immutableKeyRecord({ key, user = {}, storeId }) {

  const createdAt = key.createdAt
  const updatedAt = key.updatedAt
  const attrs = {
    id: key.id,
    createdAt:
      createdAt &&
      moment(createdAt)
        .utcOffset(0)
        .format('MM/DD/YYYY'),
    updatedAt:
      updatedAt &&
      moment(updatedAt)
        .utcOffset(0)
        .format('MM/DD/YYYY H:mm'),
    storeId: storeId,
    serialNumber: key.serialNumber,
    status: key.status,
    userName: `${user.firstName || ''} ${user.lastName || ''}`,
    userId: user.id,
    expirationDate: key.expirationDate,
    showExpirationDate: key.showExpirationDate,
    user,
    loading: false,
    backupKey: key.backupKey,
  }
  return new Key(attrs)
}

/**
 * Uses key data and sideloaded user data to return formatted
 * list of keys suitable to put in the redux store
 *
 * @param {*} data
 * @param {*} storeId
 * @returns
 */
export function formatKeys(data = {}, storeId) {
  const users = formatUserData(data)
  const keys = data.physicalKeys || []
  return List(
    keys.map(key => {
      const user = users.find(u => u.id === key.userId)
      return immutableKeyRecord({ key, user, storeId })
    })
  )
}

export function formatKey(data = {}, storeId, user) {
  const key = data.physicalKeys[0]
  return immutableKeyRecord({ key, storeId, user })
}

export function createKey(attrs) {
  return new Key(attrs)
}
