/**
 *
 * OTA Stores Preloader
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import ContentLoader from 'react-content-loader'
import uuid from 'uuid'

import {
  HeaderContainer,
  Header,
} from '../../../containers/UsersPage/index.screen'

function OTAStoresPreloader(height) {
  const Loader = (props) => {
    const random = Math.random() * (1 - 0.7) + 0.7
    const loaderWidth = 600
    return (
      <ContentLoader
        height={40}
        width={loaderWidth}
        speed={2}
        primaryColor="#d9d9d9"
        secondaryColor="#ecebeb"
        {...props}
      >
        <rect x="5" y="10" rx="6" ry="6" width={315 * random} height="14" />
        <rect x="325" y="10" rx="6" ry="6" width={95 * random} height="14" />
        <rect x="425" y="10" rx="6" ry="6" width={75 * random} height="14" />
        <rect x="505" y="10" rx="6" ry="6" width={95 * random} height="14" />

        <rect x="0" y="38" rx="6" ry="6" width={loaderWidth} height=".3" />
      </ContentLoader>
    )
  }

  const OTAStoresPreloader = () => {
    const countRows = parseInt(height.height / 40, 10)
    return (
      <React.Fragment>
        <div style={{ width: '60' }}>
          <HeaderContainer className="border-bottom">
            <Header>Stores</Header>
          </HeaderContainer>
        </div>
        {Array(countRows)
          .fill('')
          .map((e, i) => (
            <Loader
              key={`loader_${uuid.v4()}`}
              style={{ opacity: Number(2 / i).toFixed(1) }}
            />
          ))}
      </React.Fragment>
    )
  }

  return <OTAStoresPreloader />
}

OTAStoresPreloader.propTypes = {
  height: PropTypes.number,
}

export default OTAStoresPreloader
