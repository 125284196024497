import { getAlertParams, getImage } from 'utils/mtiCanvasUtils'

export function toObjectsSync(store, images, isStatic, selectedId) {
  if (!store) return store
  let selectedIndex = -1
  const os = ((store || {}).floors || []).map((f, i) => {
    selectedIndex = f.id === selectedId ? i : -1
    return toObjectsFloorSync(f, isStatic, images)
  })
  return {
    canvasObject: { objects: os, selectedId, selectedIndex },
  }
}

export function toObjectsFloorSync(o, isStatic, images) {
  if (!o) return o
  const {
    name,
    order,
    id,
    alertType,
    alertCount,
    areasCount,
    image,
    imageName,
    shouldDisplayBackground,
  } = getFloorParams(o, images)
  return {
    name,
    order,
    id,
    alertType,
    alertCount,
    areasCount,
    image,
    imageName,
    shouldDisplayBackground,
    isStatic,
  }
}

export function getFloorParams(o, images) {
  const { alertType, alertCount } = getAlertParams(o.state)
  const { previewImageId } = o
  const { image, imageName } = getImage(images, previewImageId, 'thumbLink')
  return { ...o, alertType, alertCount, image, imageName }
}

export function getFloorName(o) {
  if (!o) return o
  return o.name
}
