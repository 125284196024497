import { Record } from 'immutable'
import moment from 'moment'

export const Schedule = new Record({
  id: null,
  storeId: null,
  effectiveOn: null,
  createdAt: null,
  updatedAt: null,
  sundayClose: null,
  sundayOpen: null,
  mondayClose: null,
  mondayOpen: null,
  wednesdayClose: null,
  wednesdayOpen: null,
  tuesdayClose: null,
  tuesdayOpen: null,
  thursdayClose: null,
  thursdayOpen: null,
  fridayClose: null,
  fridayOpen: null,
  saturdayClose: null,
  saturdayOpen: null,
})

export function createSchedules(schedulesData) {
  if (!schedulesData || !schedulesData.schedules) return []
  return schedulesData.schedules.map((data) => {
    const date = moment().format('YYYY-MM-DD')
    const attrs = {
      id: data.id,
      storeId: data.storeId,
      effectiveOn: data.effectiveOn ? moment(data.effectiveOn) : '',
      createdAt: data.createdAt,
      updatedAt: data.updatedAt,
      sundayClose: fullDateString(date, data.sundayClose),
      sundayOpen: fullDateString(date, data.sundayOpen),
      mondayClose: fullDateString(date, data.mondayClose),
      mondayOpen: fullDateString(date, data.mondayOpen),
      wednesdayClose: fullDateString(date, data.wednesdayClose),
      wednesdayOpen: fullDateString(date, data.wednesdayOpen),
      tuesdayClose: fullDateString(date, data.tuesdayClose),
      tuesdayOpen: fullDateString(date, data.tuesdayOpen),
      thursdayClose: fullDateString(date, data.thursdayClose),
      thursdayOpen: fullDateString(date, data.thursdayOpen),
      fridayClose: fullDateString(date, data.fridayClose),
      fridayOpen: fullDateString(date, data.fridayOpen),
      saturdayClose: fullDateString(date, data.saturdayClose),
      saturdayOpen: fullDateString(date, data.saturdayOpen),
    }
    return new Schedule(attrs)
  })
}

const fullDateString = (dateString, timeString) => {
  if (timeString) {
    return `${dateString} ${timeString}`
  }

  return timeString
}
