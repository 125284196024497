import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import mtiJsclientShared from '../../utils/mtiJsclientShared'
import CanvasUpdate from '../../components/CanvasUpdate'
import AreaCanvas from '../../components/AreaCanvas'
import PromptTable from '../../components/PromptTable'
import SidebarTitle from '../../components/SidebarTitle'
import Issue from '../../components/Issue'
import Input from '../../components/Input'
import Select from '../../components/Select'
import {
  SidebarInputSection,
  SidebarSection,
} from '../../components/SidebarSection'
import Checkbox from '../../components/Checkbox'
import EmptyCanvasLabel from '../../components/EmptyCanvasLabel'
import { sviFigureType, sviFigureSizeType } from '../../utils/mtiCanvasUtils'
import { overlayDimensions } from '../../utils/mtiUtils'
import { isTemplateCloneableOnly } from './utils'
import styles from '../../styles/App.css'
import Radio from '../../components/Radio'
import {
  SidebarBody,
  TopContainer,
  BorderedButton,
} from '../../../app/styles/styled'
import { figureSize, figureType } from '../TemplatePositions/utils'
import InfoIcon from '../../images/svg/buttons/info.svg'
import GroupAdornment, {
  EditDisabled as Label,
} from '../../components/GroupAdornment'
import {
  invalidUrlCharactersMessage,
  safeUrlCharactersPattern,
} from '../../utils/utils'
import { Separator } from '../TemplateFixture/index.screen'
import { displayAlerts } from '../AlertContainer/alerts'
import { SecuredProductsExceptions } from '../ExceptionsList/SecuredProducts'

const HorizontalContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
`

const Button = styled.button`
  margin-top: 15px;
  margin-left: 10px;
`
class AreaScreen extends React.PureComponent {
  state = {
    isSaveDisabled: false,
  }

  onChangeValidity = isValid => {
    this.setState({ isSaveDisabled: !isValid })
  }

  onNameChanged = name => {
    const { onNameChanged } = this.props
    onNameChanged(name)
  }

  onFigureTransformed = sviType => {
    const { onFigureBeginTransformation } = this.props
    onFigureBeginTransformation(sviType)
  }

  onFigureSizeChanged = sviSizeType => {
    const { onFigureSizeChanged } = this.props
    onFigureSizeChanged(sviSizeType)
  }

  onFigureCustomAngleChanged = angle => {
    const { onFigureCustomAngleChanged } = this.props
    onFigureCustomAngleChanged(angle)
  }

  onToggle = isFurniture => {
    const { onIsFurnitureChanged } = this.props
    onIsFurnitureChanged(isFurniture)
  }

  onFixtureNameToggle = hasShown => {
    const { onFixtureNameToggle } = this.props
    onFixtureNameToggle(hasShown)
  }

  getSelectedFixtureObject() {
    const { canvasObject, selectedId } = this.props
    return (
      selectedId !== -1 &&
      canvasObject.objects.find(({ id }) => id === selectedId)
    )
  }

  getPromptText() {
    const { isStatic, canvasObject, selectedId } = this.props
    const hasFixtures =
      canvasObject && canvasObject.objects && canvasObject.objects.length !== 0
    const hasSelected = selectedId !== -1
    let data = []
    if (isStatic) {
      if (hasFixtures) {
        data = hasSelected
          ? [
            {
              func: 'EDIT SELECTED FIXTURE',
              desc: 'Click on EDIT FIXTURE in SIDE PANEL',
            },
            {
              func: 'DESELECT FIXTURE',
              desc: 'Click on empty space OR another FIXTURE in CANVAS',
            },
            {
              func: 'WORK ON ISSUES',
              desc: 'Click on POSITION in SIDE PANEL',
            },
          ]
          : [
            {
              func: 'CREATE NEW FIXTURE',
              desc: 'Click on CREATE NEW FIXTURE in SIDE PANEL',
            },
            {
              func: 'EDIT EXISTING FIXTURE',
              desc: 'Select fixture in CANVAS',
            },
            {
              func: 'WORK ON ISSUES',
              desc: 'Click on POSITION in SIDE PANEL',
            },
          ]
      } else {
        data = [
          {
            func: 'CREATE NEW FIXTURE',
            desc: 'Click on CREATE NEW FIXTURE in SIDE PANEL',
          },
        ]
      }
    } else if (hasSelected) {
      const isNew = isNaN((this.getSelectedFixtureObject() || {}).id)
      data = isNew
        ? [
          {
            func: 'CREATE NEW FIXTURE',
            desc:
                'Use SIDE PANEL to set attributes of FIXTURE and click on SAVE',
          },
          {
            func: 'CANCEL',
            desc: 'Click on CANCEL in SIDE PANEL',
          },
        ]
        : [
          {
            func: 'MODIFY FIXTURE',
            desc:
                'Use SIDE PANEL to set attributes of FIXTURE and click on SAVE',
          },
          {
            func: 'DELETE FIXTURE',
            desc: 'Click on DELETE in SIDE PANEL',
          },
          {
            func: 'CANCEL',
            desc: 'Click on CANCEL in SIDE PANEL',
          },
        ]
    } else {
      data = [
        {
          func: 'CREATE NEW FIXTURE',
          desc: 'Click and drag in CANVAS to start creating NEW FIXTURE',
        },
        {
          func: 'CANCEL',
          desc: 'Click on CANCEL in SIDE PANEL',
        },
      ]
    }
    return data
  }

  isSizeChangingEnabled = () => {
    const {
      selectedFixtureSviType,
      fixtureEditMode,
      selectedFixtureTemplate,
    } = this.props
    const isTemplate = fixtureEditMode === 'template'

    if (isTemplate) {
      return (
        selectedFixtureTemplate &&
        selectedFixtureTemplate.type &&
        selectedFixtureTemplate.type !== sviFigureType.custom
      )
    }
    return (
      selectedFixtureSviType && selectedFixtureSviType !== sviFigureType.custom
    )
  }

  furnitureTooltipIcon(positionsCount) {
    if (!positionsCount) {
      return null
    }

    const msg = `<p class="mb-0">This fixture contains ${positionsCount} position(s) and can't be set as furniture.</p>
      <p class="mb-0">If you want to set it as furniture, please delete positions inside.</p>`
    const icon = (
      <img
        ref={ref => this.tooltipForIcon(ref, msg)}
        className="ml-2"
        src={InfoIcon}
        height="26"
        alt="The fixture has positions inside"
      />
    )

    return icon
  }

  tooltipForIcon(element, msg) {
    // eslint-disable-next-line
    $(element).popover({
      placement: 'bottom',
      trigger: 'hover focus',
      html: true,
      content: msg,
    })
  }

  renderSidebarForSelectedFixtureSecurity() {
    const {
      path,
      positions: { positionsByFixtureId = [] } = {},
      isStatic,
      onEdit,
      onCancel,
      openPosition,
      onOpenFixture,
      missingSecuredProductsByFixtureId,
    } = this.props

    const fixture = this.getSelectedFixtureObject()
    const fTitle = fixture && fixture.name ? fixture.name : 'Fixture'
    const fTo =
      isStatic && fixture && `${path}/fixtures/${fixture.id}/${fixture.name}`
    const fxPositions = fixture ? positionsByFixtureId[fixture.id] || [] : []

    return (
      <div className="h-100 flex-column d-flex">
        <SidebarTitle
          title={fTitle}
          titleTo={fTo}
          callback={() => onOpenFixture(fixture)}
        >
          {isStatic ? (
            <button
              type="button"
              className="btn btn-raised"
              onClick={() => onEdit()}
              name="editFixture"
            >
              Edit Fixture
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => onCancel()}
            >
              Cancel
            </button>
          )}
        </SidebarTitle>
        <SidebarBody className="h-100 flex-column d-flex">
          <TopContainer>
            {this.renderSecurityList(
              fixture ? fxPositions : [],
              true,
              openPosition,
              missingSecuredProductsByFixtureId[fixture.id]
            )}
          </TopContainer>
        </SidebarBody>
      </div>
    )
  }

  renderSidebarForSelectedFixture() {
    return this.renderSidebarForSelectedFixtureSecurity()
  }

  renderSidebarForArea() {
    const {
      positions: { positionsArea = [] } = {},
      areaName = '',
      isStatic,
      onEdit,
      onCancel,
      openPosition,
      isDefaultAreaMode,
      missingSecuredProductsForArea,
    } = this.props

    return (
      <div className="h-100 flex-column d-flex">
        <SidebarTitle title={isDefaultAreaMode ? '' : areaName}>
          <div />
          {isStatic ? (
            <button
              type="button"
              className="btn btn-raised"
              onClick={() => onEdit()}
            >
              Create New Fixture
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => onCancel()}
            >
              Cancel
            </button>
          )}
        </SidebarTitle>
        <SidebarBody className="h-100 flex-column d-flex">
          <TopContainer>
            {this.renderSecurityList(
              positionsArea,
              false,
              openPosition,
              missingSecuredProductsForArea
            )}
          </TopContainer>
        </SidebarBody>
      </div>
    )
  }

  renderFixtureEditingMode = () => {
    const {
      isTemplateSelectable,
      fixtureEditMode,
      onFixtureEditingModeChange,
    } = this.props

    return (
      isTemplateSelectable && (
        <HorizontalContainer>
          <Radio
            id="template-radio"
            name="template-radio"
            onChange={({ target: { checked } }) =>
              checked ? onFixtureEditingModeChange('template') : null
            }
            label="Use Template"
            checked={fixtureEditMode === 'template'}
          />
          <Radio
            id="custom-radio"
            name="custom-radio"
            onChange={({ target: { checked } }) =>
              checked ? onFixtureEditingModeChange('customFixture') : null
            }
            label="Custom Fixture"
            checked={fixtureEditMode === 'customFixture'}
          />
        </HorizontalContainer>
      )
    )
  }

  templateSectionLabel = name => {
    const header = name ? 'Selected Template' : 'Specify Template'
    const text = name ? name : 'Unspecified'

    return <Label labelWidth={170} header={header} text={text} />
  }

  templateSection = () => {
    const {
      selectedFixtureTemplate,
      onUnselectTemplate,
      onSelectTemplate,
    } = this.props
    const { name } = selectedFixtureTemplate || {}
    const text = name ? 'Unselect' : 'Select'
    const handler = name ? onUnselectTemplate : onSelectTemplate

    return (
      <GroupAdornment
        Adornment={
          <Button type="button" className="btn btn-raised" onClick={handler}>
            {text}
          </Button>
        }
        AdornmentProps={{
          text: 'SELECT',
          className: 'ml-4',
        }}
      >
        {this.templateSectionLabel(name)}
      </GroupAdornment>
    )
  }

  renderTemplateSection() {
    const { isTemplateSelectable, fixtureEditMode } = this.props
    if (fixtureEditMode !== 'template') return null

    return (
      isTemplateSelectable && (
        <HorizontalContainer>{this.templateSection()}</HorizontalContainer>
      )
    )
  }

  renderTemplateUpdatesToggle = () => {
    const {
      onToggleTemplateUpdates,
      selectedFixtureTemplate,
      selectedFixtureApplyFutureTemplateUpdates,
    } = this.props
    const isCloneableOnly = isTemplateCloneableOnly(selectedFixtureTemplate)
    return (
      <Checkbox
        name={'templateUpdates'}
        label={
          isCloneableOnly
            ? 'Future Template updates will be automatically applied'
            : 'Apply future Template updates?'
        }
        checked={selectedFixtureApplyFutureTemplateUpdates}
        disabled={isCloneableOnly}
        onChange={({ target: { checked } }) => {
          onToggleTemplateUpdates(checked)
        }}
      />
    )
  }

  renderFixtureSizeSelector = () => {
    const { selectedFixtureSviSizeType, selectedFixtureTemplate } = this.props
    const isSizeChangingEnabled = this.isSizeChangingEnabled()
    const fixture = this.getSelectedFixtureObject()
    const typeFixture = fixture && (fixture.mtiType || fixture.sviType)
    if (!isSizeChangingEnabled) {
      return (
        <Input
          name={'fixtureSizeWarn'}
          label={'Fixture Size'}
          value={'Resize by dragging handles in canvas'}
          readOnly
        />
      )
    }

    if (
      selectedFixtureTemplate &&
      selectedFixtureTemplate.layoutPosition &&
      selectedFixtureTemplate.layoutPosition.size
    ) {
      const { layoutPosition: { size } } = selectedFixtureTemplate
      return (
        <Input
          name={'fixtureSizeWarn'}
          label={'Fixture Size'}
          value={figureSize[size]}
          readOnly
        />
      )
    }

    return (
      <Select
        name={'fixtureSize'}
        floatingLabel={'Fixture Size'}
        value={selectedFixtureSviSizeType}
        onChange={e => this.onFigureSizeChanged(e.target.value)}
      >
        {typeFixture == 'circle' && (
          <option value={sviFigureSizeType.extraSmall}>
            {figureSize[sviFigureSizeType.extraSmall]}
          </option>
        )}
        <option value={sviFigureSizeType.small}>
          {figureSize[sviFigureSizeType.small]}
        </option>
        <option value={sviFigureSizeType.medium}>
          {figureSize[sviFigureSizeType.medium]}
        </option>
        <option value={sviFigureSizeType.large}>
          {figureSize[sviFigureSizeType.large]}
        </option>
        <option value={sviFigureSizeType.veryLarge}>
          {figureSize[sviFigureSizeType.veryLarge]}
        </option>
      </Select>
    )
  }

  renderFixtureCustomRotationSelector = () => {
    const { selectedFixtureAngle } = this.props

    return (
      <Input
        name={'fixtureAngle'}
        label={'Fixture Angle'}
        value={selectedFixtureAngle}
        type={'number'}
        min={0}
        max={360}
        onChange={e => {
          const value = parseInt(e.target.value, 10)
          this.onFigureCustomAngleChanged(value === 360 ? 0 : value)
        }}
        pattern={safeUrlCharactersPattern}
        validationMessage={invalidUrlCharactersMessage}
        onChangeValidity={this.onChangeValidity}
      />
    )
  }

  renderMoveToAreaFixture = () => (
    <BorderedButton
      type="button"
      className="btn btn-primary w-100 mr-2"
      onClick={() => {
        this.props.onMoveFixture()
      }}
    >
      MOVE FIXTURE TO ANOTHER AREA
    </BorderedButton>
  )

  renderEditSidebarForSelectedFixture() {
    const {
      onCancel,
      onFixtureSave,
      onFixtureRemove,
      selectedFixtureName,
      selectedFixtureSviType,
      selectedFixtureIsFurniture,
      selectedFixtureNameHasShown,
      fixtureEditMode,
    } = this.props
    const fixture = this.getSelectedFixtureObject()
    const { positionsCount = 0 } = fixture || {}
    const isCreation = fixture ? isNaN(fixture.id) : false
    const isTemplateMode = fixtureEditMode === 'template'

    return (
      <div>
        <SidebarTitle>
          <BorderedButton
            type="button"
            className="btn btn-primary w-100 mr-2"
            onClick={() => {
              onCancel()
              if (this.canvas) this.canvas.onDefault()
            }}
          >
            Cancel
          </BorderedButton>
          {!isCreation && (
            <BorderedButton
              type="button"
              className="btn btn-primary w-100 mr-2"
              onClick={() => {
                onFixtureRemove(fixture.id)
                if (this.canvas) this.canvas.onDefault()
              }}
            >
              Delete
            </BorderedButton>
          )}
          <button
            type="button"
            className="btn btn-raised w-100"
            disabled={this.state.isSaveDisabled}
            onClick={() => {
              onFixtureSave(fixture.id)
              if (this.canvas) this.canvas.onDefault()
            }}
            name="save"
          >
            Save
          </button>
        </SidebarTitle>
        <SidebarSection>
          <Input
            name={'fixtureName'}
            label={'Fixture Name'}
            value={selectedFixtureName}
            onChange={e => {
              this.onNameChanged(e.target.value)
            }}
            pattern={safeUrlCharactersPattern}
            validationMessage={invalidUrlCharactersMessage}
            onChangeValidity={this.onChangeValidity}
          />
          {this.renderFixtureEditingMode()}
          {this.renderTemplateSection()}
          {isTemplateMode ? (
            this.renderTemplateUpdatesToggle()
          ) : (
            <div className="d-flex flex-row">
              <Checkbox
                name={'isFurniture'}
                label={'Is furniture'}
                checked={selectedFixtureIsFurniture}
                disabled={positionsCount > 0}
                onChange={e => {
                  this.onToggle(e.target.checked)
                }}
              />
              {this.furnitureTooltipIcon(parseInt(positionsCount, 10))}
            </div>
          )}
          <Checkbox
            name={'fixtureNameHasShown'}
            label={'Display fixture name'}
            checked={selectedFixtureNameHasShown}
            onChange={e => {
              this.onFixtureNameToggle(e.target.checked)
            }}
          />
          {!isTemplateMode ? (
            <Select
              name={'fixtureType'}
              floatingLabel={'Fixture Shape'}
              value={selectedFixtureSviType}
              onChange={e => this.onFigureTransformed(e.target.value)}
            >
              <option value={sviFigureType.custom}>
                {figureType[sviFigureType.custom]}
              </option>
              <option value={sviFigureType.narrowRectangle}>
                {figureType[sviFigureType.narrowRectangle]}
              </option>
              <option value={sviFigureType.wideRectangle}>
                {figureType[sviFigureType.wideRectangle]}
              </option>
              <option value={sviFigureType.circle}>
                {figureType[sviFigureType.circle]}
              </option>
              <option value={sviFigureType.square}>
                {figureType[sviFigureType.square]}
              </option>
              <option value={sviFigureType.quarterCircle}>
                {figureType[sviFigureType.quarterCircle]}
              </option>
              <option value={sviFigureType.extendedRightQuarterCircle}>
                {figureType[sviFigureType.extendedRightQuarterCircle]}
              </option>
              <option value={sviFigureType.extendedLeftQuarterCircle}>
                {figureType[sviFigureType.extendedLeftQuarterCircle]}
              </option>
            </Select>
          ) : null}
          {this.renderFixtureSizeSelector()}
          {this.renderFixtureCustomRotationSelector()}
        </SidebarSection>
        <Separator />
        <SidebarSection>{this.renderMoveToAreaFixture()}</SidebarSection>
      </div>
    )
  }

  renderEditSidebarForArea() {
    const {
      onCancel,
      canvasObject,
      match: { params: { aName = 'Area' } = {} } = {},
    } = this.props

    return (
      <div className="h-100 flex-column d-flex">
        <SidebarTitle title={decodeURIComponent(aName)}>
          <button
            type="button"
            className="btn btn-raised"
            onClick={() => onCancel()}
          >
            Exit Edit Mode
          </button>
        </SidebarTitle>
        <SidebarBody className="h-100 flex-column d-flex">
          {canvasObject.objects.map(o => (
            <SidebarInputSection key={o.id}>
              <Input
                name={'fixture'}
                label={'Fixture'}
                value={`${o.name} (${o.positionsCount} Positions)`}
                readOnly
              />
            </SidebarInputSection>
          ))}
        </SidebarBody>
      </div>
    )
  }

  // TODO: Add positions exceptions component in app/containers/ExceptionsList
  // positions exceptions is used in several containers
  renderSecurityList(
    positions,
    isFixtureSelected,
    openPosition,
    securedProducts
  ) {
    const noIssuesText = isFixtureSelected
      ? 'There are no Security or Health Issues for the selected Fixture'
      : 'There are no Security or Health Issues for any of these Fixtures'

    const hasPositionExceptions = positions && positions.length
    const hasSecuredProductsExceptions =
      securedProducts && securedProducts.length
    if (!hasPositionExceptions && !hasSecuredProductsExceptions) {
      return (
        <div style={{ textAlign: 'center', margin: '10px' }}>
          <span>{noIssuesText}</span>
        </div>
      )
    }

    const { onGoToSecuredProducts } = this.props
    let exceptionsList = []

    const positionsExceptions = positions.map(i => (
      <Issue
        key={i.id}
        issue={{
          id: i.id,
          title: i.path,
          device: i.name,
          status: i.status.type,
          statusTitle: i.status.title,
          armStateAction: i.status.states.armStateAction,
          armStateActionTitle: i.status.states.armStateActionTitle,
          deviceType: i.deviceType,
          securityDeviceId: i.securityDevice,
        }}
        openPosition={openPosition}
      />
    ))
    exceptionsList = exceptionsList.concat(
      positionsExceptions,
      <SecuredProductsExceptions
        key="secured-products-exceptions"
        securedProducts={securedProducts}
        onGoToSecuredProducts={onGoToSecuredProducts}
      />
    )
    return exceptionsList
  }

  render() {
    const {
      canvasObject,
      selectedId,
      onChanged,
      onOpenFixture,
      canvasSize,
      isStatic,
      isSilent,
      onMessage,
      onFixtureSave,
      prototypes,
      areaDimensions,
      alerts,
    } = this.props
    const isSilentCombo = isSilent || (canvasObject && canvasObject.silent)
    const hasFixtures =
      canvasObject && canvasObject.objects && canvasObject.objects.length !== 0
    const hasNoGeometry =
      hasFixtures && canvasObject.objects[0] && !canvasObject.objects[0].left

    const overlay = overlayDimensions(areaDimensions, canvasSize)
    const { height: heightOverlay, width: widthOverlay } = overlay

    return (
      <article className={styles.storeContainer}>
        <div className={styles.canvasContainer}>
          <PromptTable data={this.getPromptText()} />
          {isStatic && displayAlerts(alerts)}
          <div className={styles.canvasFrame}>
            {!isSilentCombo &&
              !hasFixtures &&
              isStatic && (
              <EmptyCanvasLabel>
                  This Area has no Fixtures with data
              </EmptyCanvasLabel>
            )}
            {!isSilentCombo &&
              hasNoGeometry &&
              isStatic && (
              <EmptyCanvasLabel>
                  Probably this Area has been created with different environment
              </EmptyCanvasLabel>
            )}
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#D8D8D8',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  position: 'absolute',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  background: 'white',
                  width: widthOverlay,
                  height: heightOverlay,
                }}
              />
              <CanvasUpdate
                component={AreaCanvas}
                size={canvasSize}
                canvasObject={canvasObject}
                selectedId={selectedId}
                onChanged={onChanged}
                onOpenFixture={onOpenFixture}
                onMessage={onMessage}
                isEditable={!isStatic}
                prototypes={prototypes}
                mtiJsclientShared={mtiJsclientShared}
                onCanvasFixtureSave={id => onFixtureSave(id)}
              />
            </div>
          </div>
        </div>
        <div className={styles.rightStoreColumn}>
          {isStatic // eslint-disable-line
            ? selectedId !== -1
              ? this.renderSidebarForSelectedFixture()
              : this.renderSidebarForArea()
            : selectedId !== -1
              ? this.renderEditSidebarForSelectedFixture()
              : this.renderEditSidebarForArea()}
        </div>
      </article>
    )
  }
}

AreaScreen.propTypes = {
  match: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  canvasSize: PropTypes.object.isRequired,
  canvasObject: PropTypes.object.isRequired,
  selectedId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  positions: PropTypes.object,
  missingSecuredProductsForArea: PropTypes.object,
  missingSecuredProductsByFixtureId: PropTypes.object,
  onGoToSecuredProducts: PropTypes.func,
  areaDimensions: PropTypes.object,
  prototypes: PropTypes.object,
  areaName: PropTypes.string,
  isStatic: PropTypes.bool,
  isSilent: PropTypes.bool,
  isDefaultAreaMode: PropTypes.bool,
  onChanged: PropTypes.func.isRequired,
  onNameChanged: PropTypes.func.isRequired,
  onIsFurnitureChanged: PropTypes.func.isRequired,
  onFigureBeginTransformation: PropTypes.func.isRequired,
  onFigureSizeChanged: PropTypes.func.isRequired,
  onFigureCustomAngleChanged: PropTypes.func.isRequired,
  onOpenFixture: PropTypes.func.isRequired,
  onMessage: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onFixtureRemove: PropTypes.func.isRequired,
  onFixtureSave: PropTypes.func.isRequired,
  onFixtureNameToggle: PropTypes.func.isRequired,
  selectedFixtureTemplate: PropTypes.any,
  selectedFixtureName: PropTypes.string,
  selectedFixtureApplyFutureTemplateUpdates: PropTypes.bool,
  selectedFixtureSviType: PropTypes.string,
  selectedFixtureSviSizeType: PropTypes.string,
  selectedFixtureIsFurniture: PropTypes.bool,
  selectedFixtureAngle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  selectedFixtureNameHasShown: PropTypes.bool,
  onSelectTemplate: PropTypes.func,
  onUnselectTemplate: PropTypes.func,
  isTemplateSelectable: PropTypes.bool,
  onFixtureEditingModeChange: PropTypes.func.isRequired,
  fixtureEditMode: PropTypes.string.isRequired,
  onToggleTemplateUpdates: PropTypes.func.isRequired,
  openPosition: PropTypes.func.isRequired,
}

export default AreaScreen
