import React from 'react'
import PropTypes from 'prop-types'
import ErrorIcon from '../../images/svg/buttons/error.svg'
import InfoIcon from '../../images/svg/buttons/info.svg'

class AspectRatioTooltip extends React.PureComponent {
  state = { showImageAspectInvalid: false }

  validateImageRatio = (image) => {
    const { ratio = 1 } = this.props
    const fr = new FileReader()
    fr.onload = () => {
      const img = new Image()
      img.onload = () => {
        const imgRatio = img.width / img.height
        const isValid = parseFloat(imgRatio) === parseFloat(ratio)
        this.setState({ showImageAspectInvalid: !isValid })
      }
      img.src = fr.result
    }

    fr.readAsDataURL(image)
  }

  tooltipIcon = () => {
    const { showImageAspectInvalid } = this.state
    if (showImageAspectInvalid) {
      return this.warnIconForUpload()
    }

    return this.infoForForUpload()
  }

  fileUploadTooltip = (element, msg) => {
    $(element) // eslint-disable-line
      .popover('dispose')
      .popover({
        placement: 'bottom',
        trigger: 'hover focus',
        content: msg,
      })
  }

  warnIconForUpload() {
    const { warnMsg } = this.props
    const text = this.tooltipText(warnMsg)
    return (
      <img
        ref={(ref) => this.fileUploadTooltip(ref, text)}
        className="ml-2 mb-2"
        src={ErrorIcon}
        height="26"
        alt="Aspect ratio"
      />
    )
  }

  infoForForUpload() {
    const { infoMsg } = this.props
    const text = this.tooltipText(infoMsg)
    return (
      <img
        ref={(ref) => this.fileUploadTooltip(ref, text)}
        className="ml-2 mb-2"
        src={InfoIcon}
        height="26"
        alt="Aspect ratio"
      />
    )
  }

  tooltipText = (template) => {
    const { ratioText } = this.props
    if (ratioText) {
      return template.replace('%correct%', ratioText)
    }

    return template.replace('%', '')
  }

  render() {
    return this.props.render(this)
  }
}

AspectRatioTooltip.propTypes = {
  render: PropTypes.func.isRequired,
  ratioText: PropTypes.string,
  ratio: PropTypes.number,
  infoMsg: PropTypes.string,
  warnMsg: PropTypes.string,
}

AspectRatioTooltip.defaultProps = {
  infoMsg:
    "Make sure uploaded image has a %correct% Aspect ratio, else it won't be properly displayed",
  warnMsg:
    "Warning: Selected image doesn't have %correct% Aspect ratio,  so it won't be properly display. Please select a different image.",
}

export default AspectRatioTooltip
