/*
 *
 * Tags reducer
 *
 */

import { fromJS, Map, List } from 'immutable'
import { ActionTypes } from './constants'

const initialState = fromJS({
  taggings: null,
  tags: null,
  editedTaggings: [],
  editIsLoading: false,
})

function tagsReducer(state = initialState, { type, payload }) {
  switch (type) {
    case ActionTypes.PUT_TAGGINGS:
      return state.set('taggings', payload)
    case ActionTypes.PUT_TAGS:
      return state.set('tags', payload)
    case ActionTypes.EDIT_TAGS: {
      let list = state.get('editedTaggings')
      const index = list.findIndex(
        (item) => item.get('tagId') === payload.tagging.tagId
      )
      if (index === -1) {
        list = list.push(Map(payload.tagging))
      } else {
        list = list.set(index, Map(payload.tagging))
      }
      return state.set('editedTaggings', list)
    }
    case ActionTypes.CLEAR_TAGS:
      return state.set('editedTaggings', List([]))
    case ActionTypes.POST_TAGGINGS_PENDING:
      return state.set('editIsLoading', true)
    case ActionTypes.POST_TAGGINGS_FULFILLED:
      return state.set('editIsLoading', false)
    default:
      return state
  }
}

export default tagsReducer
