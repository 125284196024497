import React from 'react'
import PropTypes from 'prop-types'
import ErrorIcon from '../../../../images/svg/buttons/error.svg'
import uuid from 'uuid'

class KeyInput extends React.PureComponent {
  state = {
    showIcon: false,
  }

  imgRef = null
  serialNumber = null

  componentWillUnmount() {
    this.hideKeyExistsWarn()
  }

  showKeyExistsWarn = () => {
    const text = 'This key is already in use'
    return (
      <img
        ref={(ref) => {
          this.imgRef = $(ref) // eslint-disable-line
          this.tooltip(text)
        }}
        src={ErrorIcon}
        height="26"
        alt={text}
      />
    )
  }

  hideKeyExistsWarn = () => {
    if (this.imgRef && this.imgRef.popover) {
      this.imgRef.popover('dispose')
    }
  }

  tooltip = (msg) => {
    this.imgRef.popover('dispose').popover({
      placement: 'bottom',
      trigger: 'hover focus',
      html: true,
      content: msg,
    })
  }

  onInput = (event) => {
    const { target, target: { validity: { valid } } } = event

    if (valid) {
      this.setState({ showIcon: this.props.isKeyInUse(target.value) })
      this.props.keyInUseChanged(this.props.isKeyInUse(target.value))
    } else {
      this.setState({ showIcon: false })
    }
  }

  setCustomValidity = (msg) => {
    this.serialNumber.setCustomValidity(msg)
  }

  handleKeyPress = (event) => {
    const { addNewKey } = this.props
    if (event.key === 'Enter') {
      event.preventDefault()
      addNewKey({ id: uuid.v4(), status: 0 })
    }
  }

  setServerErrorDismiss(element) {
    const $element = $(element) // eslint-disable-line
    $element.keyup(() => {
      element.setCustomValidity('')
      $element.attr('data-content', '')
      $element.popover('hide')
    })
  }

  render() {
    const { updateNewKey, item, item: { id, serialNumber } } = this.props
    const { showIcon } = this.state

    return (
      <div className="d-flex align-items-center">
        <div className="position-relative">
          <input
            ref={(ref) => {
              this[`serialNumber${id}`] = ref
              this.serialNumber = ref
              this.setServerErrorDismiss(ref)
            }}
            type="text"
            className="form-control"
            id="serialNumber"
            pattern="[0-9a-fA-F]{8}"
            minLength={8}
            maxLength={8}
            placeholder=""
            required=""
            defaultValue={serialNumber}
            onInput={this.onInput}
            onChange={(e) =>
              updateNewKey({ ...item, serialNumber: e.target.value })
            }
            onKeyPress={this.handleKeyPress}
          />
          <div
            className="invalid-feedback position-absolute"
            style={{ width: '300%' }}
          >
            Please enter a valid Serial Number (8 hex symbols that can include
            0-9, A-F)
          </div>
        </div>
        {showIcon ? this.showKeyExistsWarn() : this.hideKeyExistsWarn()}
      </div>
    )
  }
}

KeyInput.propTypes = {
  updateNewKey: PropTypes.func.isRequired,
  isKeyInUse: PropTypes.func.isRequired,
  item: PropTypes.object,
  keyInUseChanged: PropTypes.func,
}

export default KeyInput
