/*
 *
 * TemplatePositions constants
 *
 */

import keyMirrow from 'fbjs/lib/keyMirror'

/**
 * @constant {Object} ActionTypes
 * @memberof Constants
 */
export const ActionTypes = keyMirrow({
  STORE_FIXTURE_TEMPLATE_PROTOTYPES: undefined,
  STORE_FIXTURE_TEMPLATE_PARAMS: undefined,
  LOAD_FIXTURE_TEMPLATE_CANVAS: undefined,
  FIXTURE_TEMPLATE_CANVAS_NAME_CHANGED: undefined,
  FIXTURE_TEMPLATE_CANVAS_CHANGED: undefined,
  SAVE_FIXTURE_TEMPLATE_POSITION: undefined,
  POST_FIXTURE_TEMPLATE_FINISHED: undefined,
  FIXTURE_TEMPLATE_CANVAS_POSITION_REMOVE: undefined,
  FIXTURE_TEMPLATE_DATA_PENDING: undefined,
  FIXTURE_TEMPLATE_DATA_FAILED: undefined,
  FETCH_FIXTURE_TEMPLATE_CANVAS_FULFILLED: undefined,
  FIXTURE_TEMPLATE_DATA_FULFILLED: undefined,

  TEMPLATE_CANVAS_POSITION_COUNT_CHANGED: undefined,
  TEMPLATE_AUTO_PLACEMENT_FULFILLED: undefined,
  STORE_TEMPLATE_FIXTURE_PROTOTYPES: undefined,
  SAVE_TEMPLATE_FIXTURE_ALL_POSITIONS: undefined,

  CREATE_POSITION_RULE: undefined,
  EDIT_POSITION_RULE: undefined,
  DELETE_POSITION_RULE: undefined,
  DELETE_ALL_POSITION_RULES: undefined,
  UPDATE_RULE_FAILED: undefined,
  UPDATE_RULE_FINISHED: undefined,
  UPDATE_RULE_PENDING: undefined,
})
