import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { renderToString } from 'react-dom/server'
import ErrorIcon from '../../images/svg/buttons/error.svg'
import CloseIcon from '../../images/svg/buttons/close.svg'
import { showHidablePopover } from '../../utils/utils'

const Flex1 = styled.div`
  flex: 1;
`

const Flex2 = styled.div`
  flex: 2;
`

const Flex10 = styled.div`
  flex: 10;
`

class AsyncLoadingStatus extends React.PureComponent {
  /* eslint-disable react/no-array-index-key, no-undef, react/sort-comp */
  progress = () => {
    const { current: { currentNumber, total } } = this.props
    return Math.ceil(100 * currentNumber / total)
  }

  color = () => {
    if (this.props.errors && this.props.errors.length) {
      return 'bg-danger'
    }

    return 'bg-info'
  }

  onClose = () => {
    const { cancel } = this.props
    cancel()
  }

  showErrors = () => {
    const { errors: errorsInput } = this.props
    const errors = errorsInput.map((e) => {
      const { error = {} } = e
      const msg = error.message ? error.message : (error.error || {}).message
      console.warn(msg, e.storeName)
      return { name: e.storeName, message: msg }
    })

    const errorsHtml = (errorsItems) => (
      <ul className="list-group">
        {errorsItems.map((error, index) => (
          <li key={index} className="list-group-item">
            <span className="text-danger">{`${error.name}: ${
              error.message
            }`}</span>
          </li>
        ))}
      </ul>
    )
    // console.log('text', renderToString(errorsHtml(errors)))
    showHidablePopover(this.errorButton, renderToString(errorsHtml(errors)))
  }

  errorsButton = () => {
    const { errors } = this.props

    if (errors.length) {
      return (
        <button
          type="button"
          ref={(ref) => (this.errorButton = ref)}
          onClick={this.showErrors}
        >
          <img src={ErrorIcon} height="26" alt="Error" />
        </button>
      )
    }

    return null
  }

  errorsPrompt = () => {
    const { errors } = this.props

    if (errors.length) {
      return (
        <span
          role="button"
          tabIndex="-1"
          onClick={this.showErrors}
          className="text-danger small"
        >
          {`Cannot access data from ${
            errors.length
          } stores, click to see details`}
        </span>
      )
    }

    return null
  }

  cancelButton = () => (
    <button type="button" onClick={this.onClose}>
      <img src={CloseIcon} height="26" alt="Cancel" />
    </button>
  )

  render() {
    const {
      errors = [],
      current: { currentName, currentNumber, total },
    } = this.props
    const currentProgress = this.progress()

    if (!currentName && !errors.length) {
      return null
    }

    if (!currentName && errors.length) {
      return (
        <div>
          {this.errorsPrompt()}
          {this.errorsButton()}
        </div>
      )
    }

    return (
      <Flex2>
        <div className="d-flex flex-row align-items-center">
          <Flex1>{this.errorsButton()}</Flex1>
          <Flex10>
            <div className="mb-1 progress" style={{ height: '10px' }}>
              <div
                className={`progress-bar ${this.color()}`}
                role="progressbar"
                style={{ width: `${currentProgress}%` }}
                aria-valuenow={currentProgress}
                aria-valuemin="0"
                aria-valuemax="100"
              />
            </div>
            <div className="d-flex flex-row align-items-center">
              <Flex1 className="text-left mr-md-1 mr-xl-3">
                {currentNumber && (
                  <small className="text-muted text-truncate d-inline-block align-bottom">{`Loading Stores ${currentNumber} of ${total}`}</small>
                )}
              </Flex1>
              <Flex1 className="text-left ml-md-1 ml-xl-3">
                {currentName && (
                  <small className="text-info text-truncate d-inline-block align-bottom">{`Current: "${currentName}"`}</small>
                )}
              </Flex1>
            </div>
          </Flex10>
          <Flex1>{this.cancelButton()}</Flex1>
        </div>
      </Flex2>
    )
  }
}

export default AsyncLoadingStatus

AsyncLoadingStatus.propTypes = {
  current: PropTypes.object,
  cancel: PropTypes.func,
  errors: PropTypes.array,
}
