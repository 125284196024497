import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import InputWithSuggestions from '../../../components/InputWithSuggestions'
import Checkbox from '../../../components/Checkbox'

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 10px;
`

export const Header = styled.h5`
  padding-top: 10px;
  padding-bottom: 10px;
`

export const ModalBody = styled.div`
  padding: 10px;
`

export const ColumnContainer = styled.div`
  padding-top: 0;
`

const onManufacturerChange = (onChange, data) => {
  let args = {}
  if (data.selection) {
    args.manufacturerId = data.selection.id
  } else {
    args.otherManufacturerName = data
  }
  onChange(args)
}

const onProductChange = (onChange, data) => {
  let args = {}
  if (data.selection) {
    args.otherProductName = data.selection.title
    args.productId = data.selection.id
  } else {
    args.otherProductName = data
  }
  onChange(args)
}

const productsList = (products) =>
  products.map((p) => ({
    id: p.id,
    name: p.model,
  }))

const renderMainContent = (
  name,
  manufacturers,
  otherProductName,
  otherManufacturerName,
  productShouldCharge,
  onChange,
  products
) => (
  <div className="container">
    <div className="row">
      <div className="col-xs-6 w-75" style={{ flex: 1 }}>
        <div className="form-row">
          <InputWithSuggestions
            name={'manufacturer'}
            label={'*Manufacturer'}
            value={otherManufacturerName}
            className="form-control"
            autoComplete="off"
            onChange={(val) => onManufacturerChange(onChange, val)}
            data={manufacturers}
          />
        </div>
        <div className="form-row">
          <InputWithSuggestions
            name={'productLabel'}
            label={'Product label'}
            value={otherProductName}
            className="form-control"
            autoComplete="off"
            onChange={(val) => onProductChange(onChange, val)}
            data={productsList(products)}
          />
        </div>
        <div className="form-row" style={{ paddingTop: 15 }}>
          <Checkbox
            name={'should_charge'}
            label={'Product should charge'}
            checked={productShouldCharge}
            onChange={(e) =>
              onChange({ productShouldCharge: e.target.checked })
            }
          />
        </div>
      </div>
    </div>
  </div>
)

const AddNewProductScreen = ({
  id,
  onChange,
  onAddNewProduct,
  onAddAndAssignNewProduct,
  onCancel,
  name,
  manufacturers,
  otherProductName,
  otherManufacturerName,
  productShouldCharge,
  products,
}) => (
  <div className="modal-content">
    <div className="modal-header border-bottom">
      <h5 className="modal-title" id="exampleModalLongTitle">
        Add New Product
      </h5>
      <div style={{ flex: 1, marginLeft: 20 }} />
      <div>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            $(`#modal-${id}`).modal('hide') // eslint-disable-line
            onCancel()
          }}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            $(`#modal-${id}`).modal('hide') // eslint-disable-line
            onAddNewProduct()
          }}
        >
          Add Product to List
        </button>
        <button
          type="button"
          className="btn btn-raised"
          onClick={() => {
            $(`#modal-${id}`).modal('hide') // eslint-disable-line
            onAddAndAssignNewProduct()
          }}
        >
          Add Product & Assign to Position
        </button>
      </div>
    </div>
    <ModalBody className="modal-body">
      {renderMainContent(
        name,
        manufacturers,
        otherProductName,
        otherManufacturerName,
        productShouldCharge,
        onChange,
        products
      )}
    </ModalBody>
  </div>
)

AddNewProductScreen.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onAddNewProduct: PropTypes.func,
  onAddAndAssignNewProduct: PropTypes.func,
  onCancel: PropTypes.func,
  manufacturers: PropTypes.array,
  products: PropTypes.array.isRequired,
  otherProductName: PropTypes.string,
  otherManufacturerName: PropTypes.string,
  productShouldCharge: PropTypes.bool,
}

export default AddNewProductScreen
