import { createSelector } from 'reselect'

const selectFilter = (state) => state.get('resourceFilter')

const makeSelectSelectedStores = () =>
  createSelector(selectFilter, (filter) => filter.get('selectedStores'))

const makeSelectCombination = () =>
  createSelector(selectFilter, (filter) => filter.get('combination'))

const makeSelectDate = () =>
  createSelector(selectFilter, (filter) => filter.get('dates'))

const makeSelectIsDefaultFilters = () =>
  createSelector(selectFilter, (filter) => filter.get('isDefaultFilters'))

export {
  selectFilter,
  makeSelectSelectedStores,
  makeSelectCombination,
  makeSelectDate,
  makeSelectIsDefaultFilters,
}
