import React from 'react'
import PropTypes from 'prop-types'
import ScheduleModalScreen from './index.screen'

function ScheduleModal({
  id,
  schedule,
  title,
  dangerText,
  confirmText,
  isScheduleEditLocked,
  onDanger,
  onConfirm,
  onClose,
}) {
  return (
    <ScheduleModalScreen
      id={id}
      schedule={schedule}
      title={title}
      dangerText={dangerText}
      confirmText={confirmText}
      onDanger={onDanger}
      onConfirm={onConfirm}
      onClose={onClose}
      isScheduleEditLocked={isScheduleEditLocked}
    />
  )
}

ScheduleModal.propTypes = {
  schedule: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  isScheduleEditLocked: PropTypes.bool,
  dangerText: PropTypes.string,
  confirmText: PropTypes.string,
  onDanger: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
}

export default ScheduleModal
