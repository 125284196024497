import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import injectReducer from '../../../utils/injectReducer'
import injectSaga from '../../../utils/injectSaga'
import reducer from './reducer'
import saga from './sagas'
import {
  openModal,
  closeModal,
  rebootHub,
  restartLode,
} from '../../App/actions'
import { fetchStoresList, selectOrganization } from './actions'
import StoresListScreen from './index.screen'
import ProgressIndicator from '../../../components/ProgressIndicator'
import OTAStoresPreloader from '../../../components/Preloaders/OTAStoresPreloader'
import {
  makeSelectLoading,
  makeSelectError,
  makeSelectStoresByOrganization,
  makeSelectOrganizations,
  makeSelectSelectedOrganization,
} from './selectors'
import SelectFirmwareModal from '../SelectFirmwareModal'
import HubDetails from '../UpdateHubModal/hubDetails'
import HubStatusModal from '../HubStatusModal'
import UpdateHubModal from '../UpdateHubModal'
import UpdateLodeStatusModal from '../UpdateLodeStatusModal'
import UpdateNodesModal from '../UpdateNodesModal'
import RebootWarningModal from '../RebootHubModal'
import RestartWarningModal from '../RestartLodeModal'

class StoresList extends React.Component {
  componentDidMount() {
    this.props.fetchStoresList()
  }

  /* eslint-disable no-undef */
  openRebootWarningModal = (data) => {
    const {
      openModal: doOpenModal,
      closeModal: doCloseModal,
      rebootHub: doRebootHub,
    } = this.props
    const id = 'reboot-warning'

    doOpenModal({
      id,
      type: 'bootstrap',
      content: data.hub ? (
        <RebootWarningModal
          id={id}
          onCancel={() => doCloseModal({ id })}
          onReboot={() => {
            doRebootHub(data.hub.identifier)
            doCloseModal({ id })
          }}
        />
      ) : (
        <HubDetails
          selectedStore={data.selectedStore}
          render={({ hub }) => (
            <RebootWarningModal
              id={id}
              onCancel={() => doCloseModal({ id })}
              onReboot={() => {
                doRebootHub(hub.identifier)
                doCloseModal({ id })
              }}
            />
          )}
        />
      ),
    })
  }

  openRestartWarningModal = (data) => {
    const {
      openModal: doOpenModal,
      closeModal: doCloseModal,
      restartLode: doRestartLode,
    } = this.props
    const id = 'restart-warning'

    doOpenModal({
      id,
      type: 'bootstrap',
      content: data.hub ? (
        <RestartWarningModal
          id={id}
          onCancel={() => doCloseModal({ id })}
          onRestart={() => {
            doRestartLode(data.hub.identifier)
            doCloseModal({ id })
          }}
        />
      ) : (
        <HubDetails
          selectedStore={data.selectedStore}
          render={({ hub }) => (
            <RestartWarningModal
              id={id}
              onCancel={() => doCloseModal({ id })}
              onRestart={() => {
                doRestartLode(hub.identifier)
                doCloseModal({ id })
              }}
            />
          )}
        />
      ),
    })
  }

  openUpdateHubModal = (selectedStore) => {
    const { openModal: doOpenModal, closeModal: doCloseModal } = this.props
    const id = 'update-hub'

    doOpenModal({
      id,
      type: 'bootstrap',
      content: (
        <HubDetails
          selectedStore={selectedStore}
          render={({ hub }) => (
            <UpdateHubModal
              id={id}
              selectedStore={selectedStore}
              hub={hub}
              onClose={() => doCloseModal({ id })}
              onReboot={() => {
                doCloseModal({ id })
                setTimeout(() => this.openRebootWarningModal({ hub }), 500)
              }}
              onRestart={() => {
                doCloseModal({ id })
                setTimeout(() => this.openRestartWarningModal({ hub }), 500)
              }}
              onNodesUpdate={(data) => {
                doCloseModal({ id })
                setTimeout(() => this.openFirmwareSelectionModal(data), 500)
              }}
              onLodeUpdate={(data) => {
                doCloseModal({ id })
                setTimeout(() => this.openUpdateLodeStatusModal(data), 500)
              }}
            />
          )}
        />
      ),
    })
  }

  openUpdateLodeStatusModal = (data) => {
    const { openModal: doOpenModal, closeModal: doCloseModal } = this.props
    const id = 'update-lode-status'

    doOpenModal({
      id,
      type: 'bootstrap',
      content: (
        <UpdateLodeStatusModal
          id={id}
          data={data}
          onClose={() => doCloseModal({ id })}
        />
      ),
    })
  }

  openUpdateNodesModal = (data) => {
    const { openModal: doOpenModal, closeModal: doCloseModal } = this.props
    const id = 'update-nodes-status'

    doOpenModal({
      id,
      type: 'bootstrap',
      content: (
        <UpdateNodesModal
          id={id}
          data={data}
          onClose={() => doCloseModal({ id })}
        />
      ),
    })
  }

  openFirmwareSelectionModal = (data) => {
    const { openModal: doOpenModal, closeModal: doCloseModal } = this.props
    const id = 'select-firmware'

    doOpenModal({
      id,
      type: 'bootstrap',
      content: (
        <SelectFirmwareModal
          id={id}
          onCancel={() => doCloseModal({ id })}
          onUpdate={(firmwareData) => {
            doCloseModal({ id })
            const updateData = { ...firmwareData, ...data }
            setTimeout(() => this.openUpdateNodesModal(updateData), 500)
          }}
        />
      ),
    })
  }

  openHubStatusModal = (selectedStore) => {
    const { openModal: doOpenModal, closeModal: doCloseModal } = this.props
    const id = 'hub-status'

    doOpenModal({
      id,
      type: 'bootstrap',
      content: (
        <HubDetails
          selectedStore={selectedStore}
          render={({ hub }) => (
            <HubStatusModal
              id={id}
              hub={hub}
              onClose={() => doCloseModal({ id })}
            />
          )}
        />
      ),
    })
  }

  render() {
    const {
      stores,
      fetching,
      fetchError,
      organizations,
      selectOrganization: doSelectOrganization,
      selectedOrganizationId,
      height,
    } = this.props
    if (fetching)
      return (
        <React.Fragment>
          <OTAStoresPreloader height={height} />
          <ProgressIndicator text={'Loading...'} />
        </React.Fragment>
      )
    if (fetchError) {
      return <div>{`Fetch Error: ${JSON.stringify(fetchError)}`}</div>
    }
    if (!stores) return null

    return (
      <StoresListScreen
        organizations={organizations}
        stores={stores}
        onUpdate={this.openUpdateHubModal}
        onReboot={(selectedStore) =>
          this.openRebootWarningModal({ selectedStore })
        }
        onRestart={(selectedStore) =>
          this.openRestartWarningModal({ selectedStore })
        }
        onHubStatus={this.openHubStatusModal}
        selectedOrganizationId={selectedOrganizationId}
        onSelectOrganization={doSelectOrganization}
        height={height}
      />
    )
  }
}

StoresList.propTypes = {
  organizations: PropTypes.array,
  selectedOrganizationId: PropTypes.string,
  stores: PropTypes.array,
  fetching: PropTypes.bool,
  fetchError: PropTypes.object,
  fetchStoresList: PropTypes.func,
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
  rebootHub: PropTypes.func,
  restartLode: PropTypes.func,
  selectOrganization: PropTypes.func,
  height: PropTypes.number,
}

const mapStateToProps = createStructuredSelector({
  organizations: makeSelectOrganizations(),
  selectedOrganizationId: makeSelectSelectedOrganization(),
  stores: makeSelectStoresByOrganization(),
  fetching: makeSelectLoading(),
  fetchError: makeSelectError(),
})

const mapDispatchToProps = {
  fetchStoresList,
  openModal,
  closeModal,
  rebootHub,
  restartLode,
  selectOrganization,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const withReducer = injectReducer({
  key: 'otaStoresList',
  reducer,
})

const withSaga = injectSaga({
  key: 'otaStoresList',
  saga,
})

export default compose(withReducer, withSaga, withConnect)(StoresList)
