import _ from 'lodash'
import { createSelector } from 'redux-orm'
import { orm } from 'mti-jsclient-shared'
import { getTagsByStore } from 'utils/mtiUtils'
import { getImage } from 'utils/mtiCanvasUtils'

const dbStateSelector = (state) => state.get('orm')
const selectTags = (state) => state.get('tags')
const appSelector = (state) => state.get('app')

const selectStoreById = (params) =>
  createSelector(
    orm,
    dbStateSelector,
    selectTags,
    appSelector,
    (session, tags, app) => {
      if (!params.sId) {
        return undefined
      }

      try {
        const tagsByStore = getTagsByStore(tags.get('taggings'))
        const images = app.get('images')
        const store = session.Store.withId(params.sId)
        const obj = store.ref
        const address = store.addressId
        const addressObj = (address && address.ref) || {}
        const floors = store.floorIds.toRefArray().map((f) => ({
          ...f,
          previewImageUrl: getImage(images, f.previewImageId, 'thumbLink')
            .image,
        }))
        const areas = _.flatten(
          store.floorIds
            .toModelArray()
            .map((floor) => floor.areaIds.toRefArray())
        )
        const imageObj = getImage(images, obj.imageId, '@3xLink')

        return {
          ...obj,
          areas,
          floors,
          address: addressObj,
          tags: (tagsByStore || [])
            .filter((storeTag) => storeTag.id === store.id)
            .map((storeTag) => storeTag.taggings),
          imageUrl: imageObj.image,
        }
      } catch (e) {
        return undefined
      }
    }
  )

const selectFloorsCount = (sId) =>
  createSelector(orm, dbStateSelector, (session) => {
    try {
      const store = session.Store.withId(sId)
      return store.floorsCount
    } catch (e) {
      return 0
    }
  })

const selectAreasCount = (sId) =>
  createSelector(orm, dbStateSelector, (session) => {
    try {
      const store = session.Store.withId(sId)
      return store.areasCount
    } catch (e) {
      return 0
    }
  })

const selectStoreMode = (sId) =>
  createSelector(orm, dbStateSelector, (session) => {
    try {
      const store = session.Store.withId(sId)
      return store.mode
    } catch (e) {
      return undefined
    }
  })

export { selectFloorsCount, selectAreasCount, selectStoreById, selectStoreMode }
