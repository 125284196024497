/**
 *
 * Input
 * TODO: Do not hesitate to improve me
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import styles from './css/style.css'
import { showHidablePopover } from '../../utils/utils'

class Input extends React.PureComponent {
  componentDidUpdate() {
    const { value } = this.props
    this.input.value = value
  }

  componentWillUnmount() {
    if (this.input) {
      this.input.removeEventListener('input', this.invalidInputHandler)
    }
  }

  addFocusClass = () => {
    this.formGroup.classList.add('is-focused')
  }

  removeFocusClass = () => {
    if (this.isFilled(this.props.value)) return
    this.formGroup.classList.remove('is-focused')
  }

  isFilled = (value) => !(value === null || value === undefined || value === '')

  addEventListener = (input) => {
    if (!this.props.pattern) {
      return
    }

    if (input) {
      input.addEventListener('input', this.invalidInputHandler)
    }
  }

  invalidInputHandler = (event) => {
    const input = event.target

    if (input.checkValidity()) {
      $(input).popover('hide') // eslint-disable-line
    } else {
      showHidablePopover(input, this.props.validationMessage, 'focus')
    }

    this.props.onChangeValidity(input.checkValidity())
  }

  patternProp = () => {
    const { pattern } = this.props
    if (pattern) {
      return { pattern }
    }

    return null
  }

  minMaxProps = () => {
    const { type, min, max } = this.props

    if (type === 'number') {
      return { min, max }
    }

    return null
  }

  render() {
    const {
      name,
      label,
      onChange,
      onClick,
      type,
      value,
      readOnly,
      disabled,
    } = this.props

    return (
      <div
        className={`form-group bmd-form-group ${
          this.isFilled(value) ? 'is-focused' : null
        } ${styles.inputWrapper} ${readOnly ? styles.readonly : null} ${
          onClick ? styles.clickable : null
        }`}
        ref={(i) => {
          this.formGroup = i
        }}
      >
        <label htmlFor={name} className="bmd-label-floating">
          {label}
        </label>
        <input
          key={name}
          type={type}
          className="form-control"
          id={name}
          defaultValue={value}
          onClick={onClick}
          onChange={onChange}
          onFocus={this.addFocusClass}
          onBlur={this.removeFocusClass}
          ref={(i) => {
            this.input = i
            this.addEventListener(i)
          }}
          disabled={disabled}
          readOnly={readOnly}
          {...this.patternProp()}
          {...this.minMaxProps()}
        />
      </div>
    )
  }
}

Input.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.any,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  pattern: PropTypes.string,
  validationMessage: PropTypes.string,
  onChangeValidity: PropTypes.func,
}

Input.defaultProps = {
  type: 'text',
  min: '0',
  max: '100000',
  validationMessage: 'Please check text',
  onChangeValidity: () => {},
}

export default Input
