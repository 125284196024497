import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { DeviceType, positionAction, PowerState } from 'mti-jsclient-shared'
import { postPositionAction } from '../../../containers/App/actions/position'
import { postSecurityDeviceAction } from '../../../containers/App/actions/securityDevice'

export const Dropdown = styled.div`
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  flex: 1;
`

export const CellContainer = styled.div`
  display: flex;
  flex: 1;
`

export const ButtonsContainer = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: row;
`

export const DropdownMenu = styled.div`
  min-width: 10px;
`

export const ActionButton = styled.button`
  // margin-left: 10px;
  margin-right: 10px;
  // width: 90%;
  // height: 100%;
`

class DropdownButton extends React.PureComponent {
  /* eslint-disable no-undef */
  onArmStateAction = () => {
    const {
      postSecurityDeviceAction,
      issue: { securityDeviceId: id, armStateAction },
    } = this.props
    postSecurityDeviceAction({ id, action: armStateAction })
  }

  onIdentifyAction = () => {
    const {
      postSecurityDeviceAction,
      issue: { securityDeviceId: id },
    } = this.props
    postSecurityDeviceAction({ id, action: positionAction.IDENTIFY })
  }

  onPowerAction = () => {
    const { postPositionAction, issue: { id } } = this.props
    postPositionAction({ id, action: positionAction.POWER })
  }

  onUnpowerAction = () => {
    const { postPositionAction, issue: { id } } = this.props
    postPositionAction({ id, action: positionAction.UNPOWER })
  }

  identifyAction = () => {
    const {
      issue: { id: positionId, title: positionName },
      openPosition,
    } = this.props
    openPosition({ positionId, positionName })
  }

  render() {
    const {
      color,
      issue: {
        status,
        armStateActionTitle,
        deviceType,
        hideGoTo,
        armStateAction,
      },
    } = this.props

    const identifyAction = deviceType !== DeviceType.LOCK && armStateAction
    const powerAction =
      deviceType === DeviceType.SECURE_PLUG && status === PowerState.UNPOWERED
    const unpowerAction =
      deviceType === DeviceType.SECURE_PLUG && status !== PowerState.UNPOWERED

    const displayDropdown =
      identifyAction ||
      armStateActionTitle ||
      powerAction ||
      unpowerAction ||
      !hideGoTo

    return (
      <Dropdown className="btn-group dropup">
        <CellContainer
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {this.props.children}
        </CellContainer>
        {displayDropdown && (
          <DropdownMenu
            className="dropdown-menu dropdown-menu-right"
            aria-labelledby="dropdownMenuButton"
          >
            <ButtonsContainer>
              {identifyAction && (
                <ActionButton
                  onClick={this.onIdentifyAction}
                  style={{
                    backgroundColor: '#4E92DF',
                  }}
                >
                  <span style={{ color: 'white' }} name="identify">
                    Identify
                  </span>
                </ActionButton>
              )}
              {armStateActionTitle && (
                <ActionButton
                  onClick={this.onArmStateAction}
                  style={{
                    backgroundColor: color,
                  }}
                >
                  <span style={{ color: 'white' }} name={armStateActionTitle}>
                    {armStateActionTitle}
                  </span>
                </ActionButton>
              )}
              {powerAction && (
                <ActionButton
                  onClick={this.onPowerAction}
                  style={{
                    backgroundColor: '#3AB34A',
                  }}
                >
                  <span style={{ color: 'white' }} name="power">
                    Power
                  </span>
                </ActionButton>
              )}
              {unpowerAction && (
                <ActionButton
                  onClick={this.onUnpowerAction}
                  style={{
                    backgroundColor: '#3AB34A',
                  }}
                >
                  <span style={{ color: 'white' }} name="unpower">
                    Unpower
                  </span>
                </ActionButton>
              )}
              {!hideGoTo && (
                <ActionButton
                  onClick={this.identifyAction}
                  style={{
                    backgroundColor: '#4E92DF',
                  }}
                >
                  <span style={{ color: 'white' }}>Go&nbsp;To</span>
                </ActionButton>
              )}
            </ButtonsContainer>
          </DropdownMenu>
        )}
      </Dropdown>
    )
  }
}

DropdownButton.propTypes = {
  issue: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  postPositionAction: PropTypes.func,
  postSecurityDeviceAction: PropTypes.func,
  openPosition: PropTypes.func.isRequired,
  children: PropTypes.object,
}

const mapDispatchToProps = {
  postPositionAction,
  postSecurityDeviceAction,
}

const withConnect = connect(null, mapDispatchToProps)
export default compose(withConnect)(DropdownButton)
