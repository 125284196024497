import { fabric } from 'fabric'
import { getTruncatedTextParams } from '../FloorCanvas/utils'
import { isMobile } from '../../utils/screen'

export const padding = 0
export const minLabelW = 240

export const createText = (
  text,
  targetLeft,
  targetTop,
  targetWidth,
  targetHeight,
  verticalGap,
  screenWidth,
  screenHeight,
  width = 240,
  height = 50
) => {
  const {
    textLength,
    textSize,
    textFamily /* , textBoxWidth */,
  } = getTruncatedTextParams(text, width, minLabelW, padding)
  // console.log('textBoxWidth', textBoxWidth)

  return new fabric.Group(
    [
      new fabric.TruncatedText(text, {
        truncatedTextLength: textLength,
        name: 'label',
        fontSize: textSize,
        fill: '#000000',
        fontFamily: textFamily,
        originX: 'center',
        originY: 'center',
        fontWeight: isMobile() ? 400 : 200,
      }),
    ],
    {
      contextType: 'selectionText',
      left: targetLeft - width / 2,
      top: targetTop - verticalGap,
      originX: 'left',
      originY: 'top',
      width,
      height,
      transparentCorners: false,
      hasRotatingPoint: false,
      hasBorders: false,
      hasControls: false,
      lockRotation: true,
      lockMovementX: false,
      lockMovementY: false,
      lockScalingX: false,
      lockScalingY: false,
      // This is undocumented property be careful using this
      _controlsVisibility: {
        bl: false,
        br: false,
        mb: false,
        ml: false,
        mr: false,
        mt: false,
        mtr: false,
        tl: false,
        tr: false,
      },
    }
  )
}
