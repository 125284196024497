export const Namespace = 'storesDashboard'

/**
 * @constant {Object} ActionTypes
 * @memberof Constants
 */
export const ActionTypes = {
  Request: `${Namespace}Request`,
  Cache: `${Namespace}Cache`,
}

export function request(query) {
  return {
    type: ActionTypes.Request,
    payload: query,
  }
}

export function cache(data) {
  return {
    type: ActionTypes.Cache,
    payload: data,
  }
}
