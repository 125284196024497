import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import {
  makeSelectMtiPermission,
  makeSelectOrganizationId,
  makeSelectKeys,
  makeSelectKeysFailed as makeSelectFailed,
  makeSelectKeysLoading as makeSelectLoading,
  makeSelectOrganization,
  makeSelectHasMtiRole,
  selectIsMTIUser,
} from '../App/selectors'
import { makeSelectNewKeys, makeSelectFocusedKeyId } from './selectors'
import {
  loadKeys,
  openModal,
  closeModal,
  notifyModal,
  activateKeys,
  updateKey,
  deleteKeys,
  addNewKey,
  updateNewKey,
  deleteNewKey,
  deleteNewKeys,
  addKeys,
  addDefaultKey,
  userDetailsAction,
  createUserAction,
  subscribeOnTopic,
} from '../App/actions'
import KeysPageScreen from './index.screen'
import KeysListForHomePage from './keysListForHomePage.screen'
import ProgressIndicator from '../../components/ProgressIndicator'
import AddKeyModal from './AddKeyModal/index.screen'
import Users from '../UsersPage'
import UserManagement from '../UsersPage/userManagement'
import { storeTopic } from '../../utils/utils'
import FetchFailedAlert from '../../components/FetchFailed'
import ConfirmModal from '../ModalContainer/ConfirmModal'
import TablePreloader from '../../components/Preloaders/TablePreloader'

const inDevelopment = 'This feature is still in development'
const permText = text =>
  `You don't have appropriate rights to access ${text}.\nPlease contact your store manager.`

class KeysPage extends React.PureComponent {
  componentDidMount() {
    const { hasViewKeysPermission, history } = this.props
    if (this.noPermission(hasViewKeysPermission, permText('the Keys'))) {
      history.push('/')
      return
    }
    this.loadKeys()
  }

  componentDidUpdate(prevProps) {
    const prevSId = prevProps.match.params.sId
    const thisSId = this.props.match.params.sId
    if (prevSId !== thisSId) {
      this.loadKeys({ sId: thisSId })
    }
    this.addKeyModalWillReceiveProps(this.props)
  }

  isKeyInUse = key => {
    const { keys } = this.props
    const keysJS = (keys || { toArray: () => [] })
      .toArray()
      .map(key => key.toJS())
    return keysJS.filter(({ serialNumber }) => serialNumber === key).length
  }

  /* eslint-disable no-undef */
  onActivate = (item, doActivate) => {
    const { hasEditKeyPermission } = this.props
    if (this.noPermission(hasEditKeyPermission, permText('Edit Key'))) return
    const { id, storeId, serialNumber, userId, user } = item
    this.props.updateKey({
      id,
      status: doActivate ? 1 : 0,
      storeId,
      serialNumber,
      userId,
      user,
    })
  }

  /* eslint-disable no-undef */
  onActivateAll = (items, doActivate) => {
    const { hasEditKeyPermission } = this.props
    if (this.noPermission(hasEditKeyPermission, permText('Edit Key'))) return
    this.props.activateKeys({
      items,
      status: doActivate ? 1 : 0,
      storeId: this.props.match.params.sId,
    })
  }

  /* eslint-disable no-undef */
  onDeleteAll = keys => {
    const {
      hasDestroyKeyPermission,
      hasDestroyBackupKeyPermission,
    } = this.props
    if (this.noPermission(hasDestroyKeyPermission, permText('Key Deletion')))
      return

    const containsBackupKeys =
      keys.filter(({ backupKey }) => backupKey).length > 0
    if (
      containsBackupKeys &&
      this.noPermission(
        hasDestroyBackupKeyPermission,
        permText('Backup Key Deletion')
      )
    )
      return

    this.showDeleteKeysDialog(keys)
  }

  /* eslint-disable no-undef */
  onAddKey = () => {
    const {
      newKeys,
      focusedKeyId,
      organizationId,
      addNewKey: onAddNewKey,
      updateNewKey: onUpdateNewKey,
      deleteNewKey: onDeleteNewKey,
      deleteNewKeys: onDeleteNewKeys,
      openModal: doOpenModal,
      closeModal: doCloseModal,
      addKeys: doAddKeys,
      addDefaultKey: doAddDefaultKey,
      hasCreateKeyPermission,
      organization: org,
      keys,
    } = this.props
    if (this.noPermission(hasCreateKeyPermission, permText('Create Key'))) {
      return
    }

    const organization = org && org.organizations.length && org.organizations[0]

    const backupKeysLength = (keys || { toArray: () => [] })
      .toArray()
      .map(key => key.toJS())
      .filter(({ backupKey }) => backupKey).length
    doOpenModal({
      id: 'add-keys',
      type: 'bootstrap',
      content: (
        <AddKeyModal
          ref={ref => {
            this.addKeyModal = ref
            doAddDefaultKey()
            setTimeout(() => {
              if (this.addKeyModal) {
                this.addKeyModal.setNewKeys(this.props.newKeys, focusedKeyId)
              }
            }, 500)
          }}
          id={'add-keys'}
          newKeys={newKeys}
          addNewKey={key => onAddNewKey(key)}
          updateNewKey={key => onUpdateNewKey(key)}
          deleteNewKey={id => onDeleteNewKey(id)}
          deleteNewKeys={() => onDeleteNewKeys()}
          onClose={() => doCloseModal({ id: 'add-keys' })}
          onConfirm={() => {
            doCloseModal({ id: 'add-keys' })
            this.addKeyModal = undefined
            doAddKeys({ organizationId, storeId: this.props.match.params.sId })
          }}
          onAssign={key => {
            doCloseModal({ id: 'add-keys' })
            this.addKeyModal = undefined
            this.onAssign(key)
          }}
          onUnassign={key => {
            onUpdateNewKey({ ...key, userName: null, userId: null })
          }}
          onProfile={item => this.onProfile(item, true)}
          isKeyInUse={this.isKeyInUse}
          backupKeyEnabled={organization.backupKeyEnabled}
          maxBackupKeys={organization.maxBackupKeys}
          isKeysLimited={
            organization.maxBackupKeys &&
            organization.maxBackupKeys <= backupKeysLength
          }
        />
      ),
    })
  }

  /* eslint-disable no-undef */
  onAssign = (key, isOpenedFromPage) => {
    const {
      openModal: doOpenModal,
      closeModal: doCloseModal,
      updateNewKey: onUpdateNewKey,
    } = this.props
    const { hasEditKeyPermission } = this.props
    if (this.noPermission(hasEditKeyPermission, permText('Edit Key'))) {
      return
    }
    const storeId = this.props.match.params.sId
    doOpenModal({
      id: 'assign-user',
      type: 'bootstrap',
      content: (
        <Users
          ref={ref => (this.modalUserAssign = ref)}
          match={{
            params: { sId: storeId ? storeId.toString() : storeId },
          }}
          isModalUserSelect
          id={'assign-user'}
          onClose={() => {
            doCloseModal({ id: 'assign-user' })
            if (!isOpenedFromPage) {
              setTimeout(() => this.onAddKey(), 500)
            }
          }}
          onAssignSelectedUser={user => {
            if (isOpenedFromPage) {
              doCloseModal({ id: 'assign-user' })
              const { id, serialNumber, status } = key
              this.props.updateKey({
                id,
                status,
                storeId,
                serialNumber,
                user,
                userId: user.id,
              })
            } else {
              onUpdateNewKey({
                ...key,
                userName: user.name,
                userId: user.id,
                user,
              })
              doCloseModal({ id: 'assign-user' })
              setTimeout(() => this.onAddKey(), 500)
            }
          }}
        />
      ),
    })
  }

  /* eslint-disable no-undef */
  onUnassign = key => {
    const { hasEditKeyPermission } = this.props
    if (this.noPermission(hasEditKeyPermission, permText('Edit Key'))) return
    const { id, storeId, serialNumber } = key
    this.props.updateKey({
      id,
      status: 0,
      storeId,
      serialNumber,
      userId: null,
    })
  }

  /* eslint-disable no-undef */
  onKey = item => {
    console.log('item', item)
    const { notifyModal: doNotifyModal } = this.props
    doNotifyModal(inDevelopment)
  }

  /* eslint-disable no-undef */
  onProfile = (item, isOpenedFromKeysModal) => {
    console.log('isOpenedFromKeysModal', isOpenedFromKeysModal)
    const { user } = item

    const { userDetailsAction: doUserDetailsAction } = this.props
    doUserDetailsAction(user)
  }

  noPermission = (hasPermission, text) => {
    const { notifyModal: doNotifyModal } = this.props
    if (!hasPermission) doNotifyModal(text)
    return !hasPermission
  }

  logPermissions = () => {
    const {
      hasCreateKeyPermission,
      hasShowKeyPermission,
      hasEditKeyPermission,
      hasDestroyKeyPermission,
      hasDestroyBackupKeyPermission,
      hasViewKeysPermission,
    } = this.props
    console.log(
      hasCreateKeyPermission,
      hasShowKeyPermission,
      hasEditKeyPermission,
      hasDestroyKeyPermission,
      hasDestroyBackupKeyPermission,
      hasViewKeysPermission
    )
  }

  addKeyModalWillReceiveProps(nextProps) {
    const { newKeys, focusedKeyId } = nextProps
    // if (!failed && !loading && this.props.loading && this.addKeyModal) {
    //      this.addKeyModal.close()
    //    }
    if (newKeys && this.addKeyModal) {
      this.addKeyModal.setNewKeys(newKeys, focusedKeyId)
    }
  }

  loadKeys(params) {
    const newParams = params || this.props.match.params
    if (newParams.sId) {
      const topic = storeTopic(newParams.sId)
      this.props.subscribeOnTopic(topic)
      this.props.loadKeys(newParams.sId)
    }
  }

  showExpirationDateColumn() {
    const { organization } = this.props
    if (!organization) return false
    const keyExpTimeIntervalEnabled =
      organization.organizations[0].keyExpTimeIntervalEnabled

    return keyExpTimeIntervalEnabled
  }

  showDeleteKeysDialog = keys => {
    const { openModal, closeModal, deleteKeys } = this.props
    const id = 'delete-backup-keys-modal'
    const multipleKeys = keys.length > 1
    const text = (
      <React.Fragment>
        {multipleKeys && (
          <p className="text-left">
            THESE KEYS WILL BE PERMANENTLY DELETED AND YOU WILL NOT BE ABLE TO
            REUSE THEM LATER.
          </p>
        )}
        {!multipleKeys && (
          <p className="text-left">
            THIS KEY WILL BE PERMANENTLY DELETED AND YOU WILL NOT BE ABLE TO
            REUSE IT LATER.
          </p>
        )}
        <p className="text-left">ARE YOU SURE YOU WANT TO PROCEED?</p>
      </React.Fragment>
    )
    openModal({
      id: id,
      type: 'bootstrap',
      appearance: 'modal-md',
      content: (
        <ConfirmModal
          questionText={text}
          id={id}
          cancelText="CANCEL"
          confirmText={
            multipleKeys ? 'PERMANENTLY DELETE KEYS' : 'PERMANENTLY DELETE KEY'
          }
          onClose={() => closeModal({ id })}
          onConfirm={() => deleteKeys({ keys: keys })}
          hideCloseInHeader
          headerText="WARNING"
        />
      ),
    })
  }

  backupKeysDisabledMessage() {
    const {
      hasStoreManagerRole,
      hasStoreAssociateRole,
      hasRegionalManagerRole,
      hasOrganizationAdminRole,
      hasMtiAdminRole,
    } = this.props

    if (hasOrganizationAdminRole || hasMtiAdminRole) {
      return 'Backup Keys are NOT enabled for this Store.<br>You can enable Backup Keys in Organization Details.'
    }
    if (
      hasStoreManagerRole ||
      hasStoreAssociateRole ||
      hasRegionalManagerRole
    ) {
      return 'Backup Keys are NOT enabled for this Store.<br>Contact your Organization Administrator to enable Backup Keys.'
    }
    console.warn('Check permission use case to show correct message')
    return 'Backup Keys are NOT enabled for this Store.'
  }

  render() {
    const {
      loading,
      failed,
      keys,
      hasViewKeysPermission,
      hasDestroyKeyPermission,
      hasDestroyBackupKeyPermission,
      isHomePage,
      height,
      match,
      createUserAction: doCreateUserAction,
      organization: org,
    } = this.props
    const organization = org && org.organizations.length && org.organizations[0]

    if (loading && isHomePage)
      return (
        <React.Fragment>
          <TablePreloader columnsCount={3} loaderWidth={431} noHeader />
          <ProgressIndicator text={'Loading...'} />
        </React.Fragment>
      )
    if (failed) return <FetchFailedAlert failed={failed} />
    // this.logPermissions()

    if (isHomePage) {
      const homePageKeys =
        hasViewKeysPermission && keys
          ? keys.filter(key => key.backupKey != true).toArray()
          : []
      return (
        <div>
          <UserManagement storeId={((match || {}).params || {}).sId} />
          <KeysListForHomePage
            keys={homePageKeys}
            height={height}
            onKey={this.onKey}
            onAssign={key => {
              this.onAssign(key, true)
            }}
            onUnassign={key => {
              this.onUnassign(key, true)
            }}
            onActivate={this.onActivate}
            onProfile={this.onProfile}
          />
        </div>
      )
    }

    const keysJS = (keys || { toArray: () => [] })
      .toArray()
      .map(key => key.toJS())
    return (
      <div>
        <UserManagement
          storeId={((match || {}).params || {}).sId}
          onCloseUserModal={() => {
            if (this.addKeyModal) {
              this.addKeyModal = undefined
              setTimeout(() => this.onAddKey(), 500)
            }
          }}
        />
        <KeysPageScreen
          keys={hasViewKeysPermission ? keysJS : []}
          onAddKey={this.onAddKey}
          loading={loading}
          onAssign={key => {
            this.onAssign(key, true)
          }}
          onUnassign={key => {
            this.onUnassign(key, true)
          }}
          onActivate={this.onActivate}
          onProfile={this.onProfile}
          onCreateUser={() => doCreateUserAction()}
          onActivateAll={this.onActivateAll}
          onDeleteAll={this.onDeleteAll}
          hasDestroyKeyPermission={hasDestroyKeyPermission}
          hasDestroyBackupKeyPermission={hasDestroyBackupKeyPermission}
          showExpirationDate={this.showExpirationDateColumn()}
          isBackupKeyEnabled={organization && organization.backupKeyEnabled}
          backupKeysDisabledMessage={this.backupKeysDisabledMessage()}
        />
      </div>
    )
  }
}

KeysPage.propTypes = {
  match: PropTypes.any,
  params: PropTypes.shape({
    sId: PropTypes.string.isRequired,
  }),
  keys: PropTypes.any,
  newKeys: PropTypes.array,
  focusedKeyId: PropTypes.any,
  addKeys: PropTypes.func,
  addDefaultKey: PropTypes.func,
  loading: PropTypes.bool,
  failed: PropTypes.object,
  loadKeys: PropTypes.func,
  activateKeys: PropTypes.func,
  updateKey: PropTypes.func,
  addNewKey: PropTypes.func,
  deleteKeys: PropTypes.func,
  updateNewKey: PropTypes.func,
  deleteNewKey: PropTypes.func,
  deleteNewKeys: PropTypes.func,
  notifyModal: PropTypes.func,
  organizationId: PropTypes.number,
  organization: PropTypes.object,
  isHomePage: PropTypes.bool,
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
  height: PropTypes.number,
  hasCreateKeyPermission: PropTypes.bool,
  hasShowKeyPermission: PropTypes.bool,
  hasEditKeyPermission: PropTypes.bool,
  hasDestroyKeyPermission: PropTypes.bool,
  hasDestroyBackupKeyPermission: PropTypes.bool,
  hasViewKeysPermission: PropTypes.bool,
  userDetailsAction: PropTypes.func,
  createUserAction: PropTypes.func,
  subscribeOnTopic: PropTypes.func,
  hasStoreManagerRole: PropTypes.bool,
  hasStoreAssociateRole: PropTypes.bool,
  hasRegionalManagerRole: PropTypes.bool,
  hasOrganizationAdminRole: PropTypes.bool,
  hasMtiAdminRole: PropTypes.bool,
}

const mapStateToProps = createStructuredSelector({
  // TODO: this is bad, because every time the state changes, this selector creates a new memoized keys selector, so it's not actually being memoized.
  keys: (state, ownProps) =>
    makeSelectKeys(ownProps.match.params.sId)(state, ownProps),
  loading: makeSelectLoading(),
  failed: makeSelectFailed(),
  newKeys: makeSelectNewKeys(),
  focusedKeyId: makeSelectFocusedKeyId(),
  organizationId: makeSelectOrganizationId(),
  organization: makeSelectOrganization(),
  hasCreateKeyPermission: (state, ownProps) =>
    makeSelectMtiPermission(
      'Physical Key: Create New Objects',
      ownProps.match.params.sId
    )(state, ownProps),
  hasShowKeyPermission: (state, ownProps) =>
    makeSelectMtiPermission(
      'Physical Key: Read/Show Details',
      ownProps.match.params.sId
    )(state, ownProps),
  hasEditKeyPermission: (state, ownProps) =>
    makeSelectMtiPermission(
      'Physical Key: Update Details of Existing Records',
      ownProps.match.params.sId
    )(state, ownProps),
  hasDestroyKeyPermission: (state, ownProps) =>
    makeSelectMtiPermission(
      'Physical Key: Destroy Existing Records',
      ownProps.match.params.sId
    )(state, ownProps),
  hasDestroyBackupKeyPermission: (state, ownProps) =>
    makeSelectMtiPermission(
      'Physical Key Backup: Destroy Existing Records',
      ownProps.match.params.sId
    )(state, ownProps),
  hasViewKeysPermission: (state, ownProps) =>
    makeSelectMtiPermission(
      'Physical Key: Index (list) Records',
      ownProps.match.params.sId
    )(state, ownProps),
  hasStoreManagerRole: (state, ownProps) =>
    makeSelectHasMtiRole('Store Manager', ownProps.match.params.sId)(
      state,
      ownProps
    ),
  hasStoreAssociateRole: (state, ownProps) =>
    makeSelectHasMtiRole('Store Associate', ownProps.match.params.sId)(
      state,
      ownProps
    ),
  hasRegionalManagerRole: (state, ownProps) =>
    makeSelectHasMtiRole('Regional Manager', ownProps.match.params.sId)(
      state,
      ownProps
    ),
  hasOrganizationAdminRole: makeSelectHasMtiRole('Organization Administrator'),
  hasMtiAdminRole: selectIsMTIUser,
})

const mapDispatchToProps = {
  loadKeys,
  activateKeys,
  updateKey,
  deleteKeys,
  addNewKey,
  updateNewKey,
  deleteNewKey,
  deleteNewKeys,
  addKeys,
  addDefaultKey,
  notifyModal,
  openModal,
  closeModal,
  userDetailsAction,
  createUserAction,
  subscribeOnTopic,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect)(KeysPage)
