import { createSelector } from 'reselect'

const selectUpdateLodeStatusReducer = (state) => state.get('updateLodeStatus')

export const makeSelectStatus = () =>
  createSelector(selectUpdateLodeStatusReducer, (state) =>
    state.get('updateStatus')
  )

export const makeSelectLoading = () =>
  createSelector(selectUpdateLodeStatusReducer, (state) => state.get('loading'))

export const makeSelectError = () =>
  createSelector(selectUpdateLodeStatusReducer, (state) => state.get('error'))
