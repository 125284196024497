import keyMirror from 'fbjs/lib/keyMirror'

/**
 * @constant {Object} ActionTypes
 * @memberof Constants
 */
export const ActionTypes = keyMirror({
  UPDATE_NODES: undefined,
  UPDATE_NODES_PENDING: undefined,
  UPDATE_NODES_FULFILLED: undefined,
  UPDATE_NODES_FAILED: undefined,
})
