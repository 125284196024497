import React from 'react'
import PropTypes from 'prop-types'

import OrganizationDetailsScreen from './index.screen'

class OrganizationDetails extends React.Component {
  setLoading(loading) {
    if (this.organizationDetailsModal)
      this.organizationDetailsModal.setLoading(loading)
  }

  render() {
    const { organization, onConfirm, onClose, id } = this.props

    return (
      <OrganizationDetailsScreen
        ref={(ref) => (this.organizationDetailsModal = ref)}
        id={id}
        organization={organization}
        onConfirm={onConfirm}
        onClose={onClose}
      />
    )
  }
}

OrganizationDetails.propTypes = {
  id: PropTypes.string.isRequired,
  organization: PropTypes.object.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default OrganizationDetails
