import keyMirror from 'fbjs/lib/keyMirror'

/**
 * @constant {Object} ActionTypes
 * @memberof Constants
 */
export const ActionTypes = keyMirror({
  FETCH_STORES_LIST_FAILED: undefined,
  FETCH_STORES_LIST_FULFILLED: undefined,
  FETCH_STORES_LIST_PENDING: undefined,
  FETCH_STORES_LIST: undefined,

  SELECT_ORGANIZATION: undefined,
})
