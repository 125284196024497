import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { createStructuredSelector } from 'reselect'
import { selectFloorsCount } from '../selectors'

const FloorsBreadcrumb = ({ floorsCount, match: { url, key } }) =>
  floorsCount > 1 ? (
    <Link key={key} className="breadcrumb-item" to={url}>
      Floors
    </Link>
  ) : null

FloorsBreadcrumb.propTypes = {
  floorsCount: PropTypes.number,
  match: PropTypes.object,
}

const mapStateToProps = createStructuredSelector({
  floorsCount: (state, ownProps) =>
    selectFloorsCount(ownProps.match.params.sId)(state, ownProps),
})

export default connect(mapStateToProps)(FloorsBreadcrumb)
