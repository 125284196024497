export function setServerError(element, msg) {
  if (element && element.isCustom && element.setCustomValidity) {
    element.setCustomValidity(msg)
    return
  }

  element.setCustomValidity(msg)

  const $element = $(element) // eslint-disable-line

  $element.attr('data-placement', 'bottom')
  $element.attr('data-content', msg)
  $element.popover('show')
  setTimeout(() => {
    $element.popover('dispose')
  }, 3000)
}

export function setServerErrorDismiss(element) {
  if (element && element.isCustom && element.setCustomValidity) {
    element.setCustomValidity('')
    return
  }

  const $element = $(element) // eslint-disable-line
  $element.off('keyup').on('keyup', () => {
    element.setCustomValidity('')
    $element.attr('data-content', '')
    $element.popover('hide')
  })
}
