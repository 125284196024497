/**
 *
 * TemplateFixtureScreen
 *
 */
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { PropagateLoader } from 'react-spinners'
import SidebarTitle from '../../components/SidebarTitle'
import InputValues from '../../components/InputValues'
import Input from '../../components/Input'
import Select from '../../components/Select'
import { SidebarSection } from '../../components/SidebarSection'
import Checkbox from '../../components/Checkbox'
import EditDisabled from '../../components/EditDisabled'
// import { sviFigureRotationAngles } from '../../utils/mtiUtils'
import { sviFigureType, sviFigureSizeType } from '../../utils/mtiCanvasUtils'
import styles from '../../styles/App.css'
import CanvasUpdate from '../../components/CanvasUpdate'
import AreaCanvas from '../../components/AreaCanvas/index.template'
import PromptTable from '../../components/PromptTable'
import { isTemplateCloneableOnly } from '../AreaPage/utils'
import ComplianceRule from '../../components/ComplianceRule'
import { BorderedButton } from '../../styles/styled'

export const LoaderWrapper = styled.div`
  height: 42px;
  position: relative;
  left: 50%;
  div {
    top: 40%;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

export const CheckboxAndInputContainer = styled.div`
  align-items: center;
  flex-direction: row;
  display: flex;
`

export const CheckboxContainer = styled.div`
  margin-right: 10px;
`

export const ComplianceBody = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Separator = styled.div`
  border-bottom: 1px solid #d9d9d9;
`

class TemplateFixtureScreen extends React.PureComponent {
  getSelectedFixtureObject() {
    const { canvasObject, selectedObject } = this.props
    return selectedObject > -1 && canvasObject.objects[selectedObject]
  }

  getPromptText() {
    const { selectedObject } = this.props
    let data = []
    const fixtureHasDrawn = selectedObject > -1
    if (!fixtureHasDrawn) {
      data = [
        {
          func: 'CREATE FIXTURE',
          desc:
            'Click and drag in CANVAS to start creating FIXTURE FOR TEMPLATE',
        },
        {
          func: 'CANCEL',
          desc: 'Click on CANCEL in SIDE PANEL',
        },
      ]
    } else if (fixtureHasDrawn) {
      const isNew = isNaN((this.getSelectedFixtureObject() || {}).id)
      if (isNew) {
        data = [
          {
            func: 'CREATE FIXTURE',
            desc:
              'Use SIDE PANEL to set attributes of FIXTURE and click on NEXT',
          },
          {
            func: 'CREATE POSITIONS',
            desc: 'Proceed to the next screen by clicking on NEXT',
          },
          {
            func: 'CANCEL',
            desc: 'Click on CANCEL in SIDE PANEL',
          },
        ]
      } else if (!isNew) {
        data = [
          {
            func: 'MODIFY FIXTURE',
            desc:
              'Use SIDE PANEL to set attributes of FIXTURE and click on NEXT',
          },
          {
            func: 'CANCEL',
            desc: 'Click on CANCEL in SIDE PANEL',
          },
        ]
      }
    }
    return data
  }

  /**
   * Client side validation
   *
   * @returns {boolean}
   */
  isValid = () => {
    const customInputIsValid = this.name.isValid()
    if (this.formRef.checkValidity() && customInputIsValid) {
      return true
    } else {
      this.formRef.reportValidity()
      return false
    }
  }

  highlightInvalid = () => {
    if (!this.name.isValid()) {
      this.name.showInvalidMessage()
    }
  }

  /* eslint-disable no-undef */
  renderSidebar = () => {
    const {
      onCancel,
      onTemplateChange,
      isTemplateCreated,
      isFixtureSizeAvailable,
      // isFixtureRotationAvailable,
      onSizeCheck,
      onRotationCheck,
      saveTemplateFixture,
      selectedTemplateFixtureName,
      selectedTemplateFixtureSviType,
      selectedTemplateFixtureSviSizeType,
      // selectedTemplateFixtureSviAngle,
      selectedObject,
      fixtureComplianceRules,
      onDeleteAllFixtureComplianceRules,
      copyable,
      cloneable,
      canvasObject,
      onAddFixtureComplianceRule,
      onEditFixtureComplianceRule,
      templateNames,
      templateName,
    } = this.props
    const isCloneableOnly = isTemplateCloneableOnly({ copyable, cloneable })
    const templateObj = canvasObject.objects[selectedObject]
    const isTemplateCreation = templateObj && typeof templateObj.id === 'number'
    const isDisabled = selectedObject < 0
    // const rotationEnabled =
    //   templateObj &&
    //   (templateObj.sviType === sviFigureType.quarterCircle ||
    //     templateObj.sviType === sviFigureType.extendedQuarterCircle)
    const isSizeChangingEnabled =
      selectedTemplateFixtureSviType &&
      selectedTemplateFixtureSviType !== sviFigureType.custom
    return (
      <React.Fragment>
        <form
          ref={(ref) => (this.formRef = ref)}
          style={{ textAlign: 'left' }}
          className="m-0 needs-validation was-validated"
          noValidate
          onSubmit={(e) => {
            e.preventDefault()
            if (this.isValid()) {
              saveTemplateFixture()
            } else {
              this.highlightInvalid()
            }
          }}
        >
          <SidebarTitle>
            <BorderedButton
              type="button"
              className="btn btn-primary w-100 mr-2"
              onClick={onCancel}
            >
              Cancel
            </BorderedButton>

            <button
              type="submit"
              className="btn btn-raised w-100"
              disabled={isDisabled}
            >
              Next
            </button>
          </SidebarTitle>
          <SidebarSection>
            <InputValues
              ref={(ref) => {
                this.name = ref
              }}
              name={'templateName'}
              label={'Template Name'}
              value={selectedTemplateFixtureName}
              placeholder="Template Name"
              disabled={isDisabled}
              values={templateNames}
              existingValue={templateName}
              onChange={(value) => {
                onTemplateChange({ name: value })
              }}
            />

            <Select
              name={'fixtureType'}
              floatingLabel={'Fixture Shape for Template'}
              value={selectedTemplateFixtureSviType}
              disabled={isDisabled}
              onChange={({ target: { value } }) => {
                onTemplateChange({ type: value })
                if (value === sviFigureType.custom) {
                  onSizeCheck(false)
                  onRotationCheck(false)
                }
              }}
            >
              <option value={sviFigureType.custom}>Custom Rectangle</option>
              <option value={sviFigureType.narrowRectangle}>
                Narrow 1-Row Rectangle
              </option>
              <option value={sviFigureType.wideRectangle}>
                Wide 2-Row Rectangle
              </option>
              <option value={sviFigureType.circle}>Circle</option>
              <option value={sviFigureType.square}>Square</option>
              <option value={sviFigureType.quarterCircle}>
                Quarter Circle
              </option>
              <option value={sviFigureType.extendedLeftQuarterCircle}>
                Extended Left Quarter Circle
              </option>
            </Select>

            <CheckboxAndInputContainer>
              <CheckboxContainer>
                <Checkbox
                  name={'fixtureSizeCheck'}
                  checked={isFixtureSizeAvailable}
                  disabled={!isSizeChangingEnabled || isDisabled}
                  onChange={({ target: { checked } }) => {
                    onSizeCheck(checked)
                  }}
                />
              </CheckboxContainer>

              {isSizeChangingEnabled ? (
                <Select
                  disabled={
                    !isSizeChangingEnabled ||
                    !isFixtureSizeAvailable ||
                    isDisabled
                  }
                  name={'fixtureSize'}
                  floatingLabel={'Fixture Size for Template'}
                  value={selectedTemplateFixtureSviSizeType}
                  onChange={({ target: { value } }) =>
                    onTemplateChange({ size: value })
                  }
                >
                  <option value={sviFigureSizeType.small}>Small</option>
                  <option value={sviFigureSizeType.medium}>Medium</option>
                  <option value={sviFigureSizeType.large}>Large</option>
                  <option value={sviFigureSizeType.veryLarge}>
                    Very Large
                  </option>
                </Select>
              ) : (
                <Input
                  name={'fixtureSizeWarn'}
                  label={'Fixture Size for Template'}
                  value={'Resize by dragging handles in canvas'}
                  readOnly
                />
              )}
            </CheckboxAndInputContainer>

            {/*<CheckboxAndInputContainer>
              <CheckboxContainer>
                <Checkbox
                  name={'fixtureRotationCheck'}
                  checked={isFixtureRotationAvailable}
                  disabled={!rotationEnabled}
                  onChange={({ target: { checked } }) => {
                    onRotationCheck(checked)
                  }}
                />
              </CheckboxContainer>
              {isFixtureRotationAvailable ? (
                <Select
                  disabled={!isFixtureRotationAvailable || isDisabled}
                  name={'fixtureRotation'}
                  floatingLabel={'Specify Fixture Rotation for Template'}
                  value={selectedTemplateFixtureSviAngle}
                  onChange={({ target: { value } }) =>
                    onTemplateChange({ rotation: value })
                  }
                >
                  {sviFigureRotationAngles.map((angle) => (
                    <option key={angle} value={angle}>
                      {angle}&#176;
                    </option>
                  ))}
                </Select>
              ) : (
                <Input
                  name={'fixtureRotationWarn'}
                  label={'Specify Fixture Rotation for Template'}
                  value={
                    rotationEnabled
                      ? 'Check to Set Rotation'
                      : 'Is not available for this Fixture'
                  }
                  readOnly
                />
              )}
            </CheckboxAndInputContainer>*/}

            <CheckboxAndInputContainer>
              <CheckboxContainer>
                <Checkbox
                  name={'cloneableCheck'}
                  checked={isCloneableOnly}
                  disabled={isDisabled || isTemplateCreated}
                  onChange={({ target: { checked } }) => {
                    if (checked) {
                      onTemplateChange({ cloneable: true, copyable: false })
                    } else {
                      onTemplateChange({ cloneable: true, copyable: true })
                    }
                  }}
                />
              </CheckboxContainer>
              <EditDisabled
                text={
                  'Edits to this template will auto-update all linked fixtures'
                }
                grey={isDisabled || isTemplateCreated}
              />
            </CheckboxAndInputContainer>
          </SidebarSection>
        </form>
        <SidebarSection>
          {isTemplateCreation && (
            <React.Fragment>
              <ButtonContainer>
                <button
                  type="button"
                  className="btn btn-primary text-right"
                  onClick={onAddFixtureComplianceRule}
                >
                  Add Fixture Compliance Rule
                </button>
              </ButtonContainer>
              {(fixtureComplianceRules || []).map((rule) => (
                <ComplianceRule key={rule.id}>
                  {rule.loading ? (
                    <LoaderWrapper>
                      <PropagateLoader size={12} color={'#4E92DF'} />
                    </LoaderWrapper>
                  ) : (
                    <ComplianceBody>
                      <span>{rule.description}</span>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => onEditFixtureComplianceRule(rule)}
                      >
                        Edit
                      </button>
                    </ComplianceBody>
                  )}
                </ComplianceRule>
              ))}
              {fixtureComplianceRules.length > 0 ? (
                <ButtonContainer>
                  <button
                    type="button"
                    className="btn btn-primary text-right"
                    onClick={() =>
                      onDeleteAllFixtureComplianceRules({
                        fixtureId: templateObj.id,
                        fixtureComplianceRules,
                      })
                    }
                  >
                    Delete All Compliance Rules
                  </button>
                </ButtonContainer>
              ) : null}
            </React.Fragment>
          )}
        </SidebarSection>
      </React.Fragment>
    )
  }

  render() {
    const {
      canvasObject,
      selectedObject,
      onChanged,
      onOpenFixture,
      canvasSize,
      isStatic,
      onMessage,
      prototypes,
    } = this.props

    return (
      <article className={styles.storeContainer}>
        <PromptTable data={this.getPromptText()} />
        <div className={styles.canvasContainer}>
          <div className={styles.canvasFrame}>
            <div style={{ backgroundColor: 'white' }}>
              <CanvasUpdate
                component={AreaCanvas}
                size={canvasSize}
                canvasObject={canvasObject}
                selectedObject={selectedObject}
                onChanged={onChanged}
                onOpenFixture={onOpenFixture}
                onMessage={onMessage}
                isEditable={!isStatic}
                prototypes={prototypes}
              />
            </div>
          </div>
        </div>
        <div className={styles.rightStoreColumn}>{this.renderSidebar()}</div>
      </article>
    )
  }
}

TemplateFixtureScreen.propTypes = {
  isTemplateCreated: PropTypes.bool,
  isFixtureSizeAvailable: PropTypes.bool.isRequired,
  isFixtureRotationAvailable: PropTypes.bool.isRequired,
  cloneable: PropTypes.bool.isRequired,
  copyable: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onTemplateChange: PropTypes.func.isRequired,
  onSizeCheck: PropTypes.func.isRequired,
  onRotationCheck: PropTypes.func.isRequired,
  canvasSize: PropTypes.object.isRequired,
  canvasObject: PropTypes.object.isRequired,
  selectedObject: PropTypes.number,
  prototypes: PropTypes.object,
  isStatic: PropTypes.bool,
  onChanged: PropTypes.func.isRequired,
  onOpenFixture: PropTypes.func.isRequired,
  onMessage: PropTypes.func.isRequired,
  saveTemplateFixture: PropTypes.func.isRequired,
  selectedTemplateFixtureName: PropTypes.string,
  selectedTemplateFixtureSviType: PropTypes.string,
  selectedTemplateFixtureSviSizeType: PropTypes.string,
  selectedTemplateFixtureSviAngle: PropTypes.string,
  fixtureComplianceRules: PropTypes.any,
  onDeleteAllFixtureComplianceRules: PropTypes.func.isRequired,
  onAddFixtureComplianceRule: PropTypes.func.isRequired,
  onEditFixtureComplianceRule: PropTypes.func.isRequired,
  templateNames: PropTypes.array,
}

export default TemplateFixtureScreen
