/**
 *
 * Organization Details Preloader
 *
 */

import React from 'react'
import ContentLoader from 'react-content-loader'

function OrganizationDetailsPreloader() {
  const OrganizationDetailsPreloader = (props) => {
    const heightLoader = 740
    const widthLoader = 800
    return (
      <ContentLoader
        height={heightLoader}
        width={widthLoader}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
        {...props}
      >
        <rect x="30" y="25" rx="0" ry="0" width="200" height="30" />
        <rect x="650" y="25" rx="0" ry="0" width="130" height="30" />
        <rect x="510" y="25" rx="0" ry="0" width="130" height="30" />
        <rect x="0" y="65" rx="0" ry="0" width="800" height="2" />

        <rect x="30" y="140" rx="6" ry="6" width="490" height="15" />
        <rect x="530" y="110" rx="6" ry="6" width="240" height="15" />
        <rect x="530" y="140" rx="6" ry="6" width="240" height="15" />
        <rect x="30" y="110" rx="6" ry="6" width="490" height="15" />

        <rect x="30" y="190" rx="6" ry="6" width="240" height="15" />
        <rect x="30" y="220" rx="6" ry="6" width="240" height="15" />
        <rect x="280" y="190" rx="6" ry="6" width="240" height="15" />
        <rect x="280" y="220" rx="6" ry="6" width="240" height="15" />
        <rect x="530" y="190" rx="6" ry="6" width="240" height="15" />
        <rect x="530" y="220" rx="6" ry="6" width="240" height="15" />

        <rect x="30" y="270" rx="6" ry="6" width="240" height="15" />
        <rect x="30" y="300" rx="6" ry="6" width="240" height="15" />
        <rect x="280" y="270" rx="6" ry="6" width="240" height="15" />
        <rect x="280" y="300" rx="6" ry="6" width="240" height="15" />
        <rect x="530" y="270" rx="6" ry="6" width="240" height="15" />
        <rect x="530" y="300" rx="6" ry="6" width="240" height="15" />

        <rect x="280" y="350" rx="6" ry="6" width="240" height="15" />
        <rect x="280" y="380" rx="6" ry="6" width="240" height="15" />
        <rect x="30" y="350" rx="6" ry="6" width="240" height="15" />
        <rect x="30" y="380" rx="6" ry="6" width="240" height="15" />

        <rect x="30" y="465" rx="6" ry="6" width="490" height="15" />
        <rect x="30" y="495" rx="6" ry="6" width="490" height="15" />
        <rect x="30" y="550" rx="6" ry="6" width="490" height="15" />
        <rect x="30" y="580" rx="6" ry="6" width="490" height="15" />

        <rect x="530" y="465" rx="6" ry="6" width="240" height="15" />
        <rect x="530" y="495" rx="6" ry="6" width="240" height="15" />
        <rect x="530" y="550" rx="6" ry="6" width="240" height="15" />
        <rect x="530" y="580" rx="6" ry="6" width="240" height="15" />

        <rect x="335" y="640" rx="6" ry="6" width="290" height="15" />
        <rect x="640" y="640" rx="6" ry="6" width="120" height="15" />
        <rect x="30" y="640" rx="6" ry="6" width="290" height="15" />
        <rect x="640" y="670" rx="6" ry="6" width="120" height="15" />
        <rect x="335" y="670" rx="6" ry="6" width="290" height="15" />
        <rect x="30" y="670" rx="6" ry="6" width="290" height="15" />
      </ContentLoader>
    )
  }
  return <OrganizationDetailsPreloader />
}

export default OrganizationDetailsPreloader
