/**
 *
 * Checkbox
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import styles from '../../styles/App.css'

class Checkbox extends React.PureComponent {
  componentDidUpdate() {
    const { checked } = this.props
    this.input.checked = checked
  }

  render() {
    const { name, label, onChange, checked, disabled, isBlue } = this.props
    const decorationStyle = !label ? { margin: 0, padding: 0 } : { top: '0' }
    const disabledStyle = disabled
      ? { opacity: '.50', filter: 'alpha(opacity=50)', cursor: 'default' }
      : {}

    return (
      <div className="checkbox" style={{ width: 'auto' }}>
        <label htmlFor={name} style={!label ? { marginBottom: '.9em' } : null}>
          <input
            id={name}
            type="checkbox"
            disabled={disabled}
            onChange={onChange}
            defaultChecked={checked}
            ref={(i) => {
              this.input = i
            }}
          />
          <span
            className="checkbox-decorator"
            style={{ ...decorationStyle, ...disabledStyle }}
          >
            <span className="check" />
            <div className="ripple-container" />
          </span>
          {!isBlue ? (
            <span className="checkbox-title">{label}</span>
          ) : (
            <Link
              className={styles.link}
              to={'#'}
              onClick={() => onChange({ target: { checked: !checked } })}
            >
              {label}
            </Link>
          )}
        </label>
      </div>
    )
  }
}

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  isBlue: PropTypes.bool,
}

export default Checkbox
