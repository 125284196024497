/**
 * @module Actions/MQTT
 * @desc MQTT Actions
 */
import { ActionTypes } from '../constants'

/**
 * Subscribe on topic
 *
 * @returns {Object}
 */
export function subscribeOnTopic(topic) {
  return {
    type: ActionTypes.MQTT_TOPIC_SUBSCRIBE,
    payload: { topic },
  }
}

/**
 * Unsubscribe from topic
 *
 * @returns {Object}
 */
export function unsubscribeFromTopic(topic) {
  return {
    type: ActionTypes.MQTT_TOPIC_UNSUBSCRIBE,
    payload: { topic },
  }
}
