/**
 *
 * ProgressIndicatorModal
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ProgressIndicator from '../ProgressIndicator'

export const HeaderContainer = styled.div`
  display: flex;
  margin: 16px 0px 0px 0px;
  border-bottom-width: 1px;
`

export const Header = styled.h5`
  flex: 1;
  text-align: left;
  margin-top: 4px;
  margin-left: 12px;
`

function ProgressIndicatorModal({ text }) {
  return (
    <div className="modal-content">
      <HeaderContainer className="border-bottom m-0">
        <Header className="py-4 m-0">
          <ProgressIndicator text={text} />
        </Header>
      </HeaderContainer>
    </div>
  )
}

ProgressIndicatorModal.propTypes = {
  text: PropTypes.string,
}

export default ProgressIndicatorModal
