import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import moment from 'moment'
import 'react-datetime/css/react-datetime.css'
import { BackHeader } from '../ScheduleModal/index.screen'
import DatePickerInput from '../../../components/DatePickerInput/'
import TimePickerInput from '../../../components/TimePickerInput/'
import SubmitWithHint from '../../../components/SubmitWithHint'
import { Overlay } from '../../../styles/styled'
import ProgressIndicator from '../../../components/ProgressIndicator'

const Td = styled.td`
  padding-bottom: 0.55rem !important;
  padding-top: 0.55rem !important;
`
const Th = styled.th`
  padding-bottom: 0.55rem !important;
  padding-top: 0.55rem !important;
`

export const days = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
]

export class CreateEditScheduleScreen extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isCreateNewSchedule: true,
      showValidationHint: false,
    }
  }

  componentWillUnmount() {
    if (this.effectiveOn) {
      this.effectiveOn.hidePopover()
    }
    this.removeDaysPopovers()
  }

  isValid = () => {
    const { schedule } = this.props
    const effectiveOnValue = this.effectiveOn.inputValue()
    const effectiveOnError = this.props.effectiveOnError(
      effectiveOnValue,
      schedule.id
    )
    const daysError = this.props.daysError()
    const customInputIsValid = !(daysError.errors || effectiveOnError)

    if (this.formRef.checkValidity() && customInputIsValid) {
      this.setState({ showValidationHint: false })
      return true
    } else {
      this.formRef.reportValidity()
      this.setState({ showValidationHint: true })

      if (effectiveOnError) {
        this.effectiveOn.showInvalidMessage(effectiveOnError)
      } else {
        this.effectiveOn.hidePopover()
      }

      if (daysError.errors) {
        this.setServerValidity(daysError)
      }

      return false
    }
  }

  removeDaysPopovers = () => {
    days.forEach((day) => {
      const openKey = `${day}Open`
      const closeKey = `${day}Close`

      if (this[openKey]) {
        this[openKey].disposePopover()
      }
      if (this[closeKey]) {
        this[closeKey].disposePopover()
      }
    })
  }

  setServerErrorDismiss(element) {
    if (element && element.isCustom && element.setCustomValidity) {
      element.setCustomValidity('')
      return
    }
    // eslint-disable-next-line
    $(element).keyup(() => {
      element.setCustomValidity('')
      $(element).attr('data-content', '') // eslint-disable-line
      $(element).popover('hide') // eslint-disable-line
    })
  }

  setServerError(element, msg) {
    if (element && element.isCustom && element.setCustomValidity) {
      element.setCustomValidity(msg)
      return
    }
    element.setCustomValidity(msg)
    $(element).attr('data-placement', 'bottom') // eslint-disable-line
    $(element).attr('data-content', msg) // eslint-disable-line
    $(element).popover('show') // eslint-disable-line
    setTimeout(() => {
      $(element).attr('data-content', '') // eslint-disable-line
      $(element).popover('disable') // eslint-disable-line
    }, 3000)
  }

  setValidationErrors(validationErrors) {
    if (validationErrors.effectiveOn) {
      const msg = validationErrors.effectiveOn[0]
      const element = this.effectiveOn
      this.setServerError(element, msg)
    }
  }

  setServerValidity(error) {
    if (error && error.error) {
      const validationErrors = error.error.validationErrors
      if (validationErrors) {
        this.setValidationErrors(validationErrors)
        days.forEach((day) => {
          if (validationErrors[`${day}Open`]) {
            const msg = validationErrors[`${day}Open`][0]
            const element = this[`${day}Open`]
            this.setServerError(element, msg)
          }
          if (validationErrors[`${day}Close`]) {
            const msg = validationErrors[`${day}Close`][0]
            const element = this[`${day}Close`]
            this.setServerError(element, msg)
          }
        })
      } else {
        // another server error
        console.log(error)
      }
    }
  }

  close = () => {
    const { id, onClose } = this.props
    $(`#modal-${id}`).modal('hide') // eslint-disable-line
    onClose()
  }

  datetimeValue = (string) => {
    if (string) {
      return moment(string)
    }
    return undefined
  }

  renderDays = (schedule, onChange) =>
    days.map((day, index) => (
      <tr key={day}>
        <Th className="pl-0" scope="row">
          {day.charAt(0).toUpperCase() + day.substr(1)}
        </Th>
        <Td className="pl-0">
          <TimePickerInput
            ref={(ref) => {
              this[`${day}Open`] = ref
            }}
            defaultValue={this.datetimeValue(schedule[`${day}Open`])}
            getDefaultValue={() => {}}
            onChange={(time) => {
              onChange({ [`${day}Open`]: time.format('HH:mm:ss') })
            }}
            name={`day${index}_open`}
          />
        </Td>
        <Td className="pl-0">
          <TimePickerInput
            ref={(ref) => {
              this[`${day}Close`] = ref
            }}
            defaultValue={this.datetimeValue(schedule[`${day}Close`], true)}
            getDefaultValue={() => {}}
            onChange={(time) => {
              onChange({ [`${day}Close`]: time.format('HH:mm:ss') })
            }}
            name={`day${index}_close`}
          />
        </Td>
      </tr>
    ))

  renderFieldsCreateEditSchedule = () => {
    const { onChange, schedule } = this.props
    return (
      <React.Fragment>
        <div className="row mb-1">
          <div className="form-group col-6 bmd-form-group pt-1">
            <label htmlFor="effectiveOn">Effective On</label>
            <div className="form-control">
              <DatePickerInput
                ref={(ref) => (this.effectiveOn = ref)}
                value={schedule.effectiveOn}
                onChange={(d) =>
                  onChange({ effectiveOn: d.format('YYYY-MM-DD') })
                }
              />
            </div>
            <div className="invalid-feedback">Please enter a valid date.</div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <table className="table">
              <thead>
                <tr>
                  <BackHeader className="pl-0" scope="col">
                    Day Of Week
                  </BackHeader>
                  <BackHeader className="pl-0" scope="col">
                    Open Time
                  </BackHeader>
                  <BackHeader className="pl-0" scope="col">
                    Close Time
                  </BackHeader>
                </tr>
              </thead>
              <tbody>{this.renderDays(schedule, onChange)}</tbody>
            </table>
          </div>
        </div>
      </React.Fragment>
    )
  }

  renderHeaderCreateEditSchedule = () => {
    const { title, confirmText } = this.props
    return (
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLongTitle">
          {title}
        </h5>
        <div>
          <SubmitWithHint
            text={confirmText}
            showHint={this.state.showValidationHint}
            onClick={this.isValid}
            onFocus={this.isValid}
          />
          <button
            type="button"
            className="btn btn-primary"
            onClick={this.close}
          >
            Close
          </button>
        </div>
      </div>
    )
  }

  render() {
    const { onConfirm, scheduleEditLoading } = this.props
    const { isCreateNewSchedule } = this.state
    return (
      <div className="modal-content">
        <form
          ref={(ref) => (this.formRef = ref)}
          style={{ textAlign: 'left' }}
          className="needs-validation was-validated mb-0"
          noValidate
          onSubmit={(e) => {
            e.preventDefault()
            if (isCreateNewSchedule) {
              if (this.isValid()) {
                // Custom components validation check
                onConfirm()
                this.forceUpdate()
              }
            }
          }}
        >
          {this.renderHeaderCreateEditSchedule()}
          <div className="modal-body">
            {this.renderFieldsCreateEditSchedule()}
          </div>
        </form>
        {scheduleEditLoading() && (
          <React.Fragment>
            <Overlay />
            <ProgressIndicator />
          </React.Fragment>
        )}
      </div>
    )
  }
}

CreateEditScheduleScreen.propTypes = {
  onChange: PropTypes.func,
  id: PropTypes.string.isRequired,
  schedule: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  confirmText: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  effectiveOnError: PropTypes.func,
  scheduleEditLoading: PropTypes.func,
}

export default CreateEditScheduleScreen
