import React from 'react'
import { FormattedMessage } from 'react-intl'
import { AlertIcon } from '../../styles/styled'

class NotificationAlert extends React.PureComponent {
  render() {
    const { alertType, messagePath, resourceName } = this.props

    return (
      <div>
        <AlertIcon src={alertType.icon} />
        <FormattedMessage
          id={messagePath}
          values={{ resource: resourceName }}
        />
      </div>
    )
  }
}

NotificationAlert.propTypes = {}

export default NotificationAlert
