/**
 *
 * Templates Selectors
 *
 */

import { createSelector } from 'reselect'
import { createSelector as createOrmSelector } from 'redux-orm'
import { orm } from 'mti-jsclient-shared'

const dbStateSelector = (state) => state.get('orm')
const selectApp = (state) => state.get('app')

const selectTemplatesFixtures = createOrmSelector(orm, (session) =>
  session.Fixture.filter(({ template }) => template === true).toRefArray()
)

const templatesFixturesExist = createOrmSelector(
  orm,
  (session) =>
    session.Fixture.filter(({ template }) => template === true).toRefArray()
      .length > 1
)

const makeSelectTemplateFixtures = () =>
  createSelector(dbStateSelector, (ormState) =>
    selectTemplatesFixtures(ormState)
  )

const makeSelectTemplatesExist = () =>
  createSelector(dbStateSelector, (ormState) =>
    templatesFixturesExist(ormState)
  )

const makeSelectTemplatesFailed = () =>
  createSelector(selectApp, (app) => app.get('templatesFailed'))

const makeSelectTemplatesLoading = () =>
  createSelector(selectApp, (app) => app.get('templatesLoading'))

const makeSelectTemplatesFetchStartTime = () =>
  createSelector(selectApp, (app) => app.get('templatesStartTime'))

export {
  makeSelectTemplateFixtures,
  makeSelectTemplatesFailed,
  makeSelectTemplatesLoading,
  makeSelectTemplatesFetchStartTime,
  makeSelectTemplatesExist,
}
