import { all, call, put, takeLatest, select } from 'redux-saga/effects'
import { modelActionCreators as mac } from 'mti-jsclient-shared'
import mtiJsclientShared from '../../utils/mtiJsclientShared'
import { postArea, patchArea, deleteArea } from 'api'
import { fetchAndUpsertStore, createDefaultFloorAndArea } from '../App/actions'
import { makeSelectToken, makeSelectImages } from '../App/selectors'
import { fetchAndUpsert, upsert } from '../App/sagas/ormSaga'
import {
  makeSelectFloorExist,
  makeSelectFloor,
  // makeSelectAreasWithLayoutExist,
} from './selectors'
import { ActionTypes } from './constants'
import {
  storePrototypes,
  storeParams,
  //postFloorCanvasPending,
  postFloorCanvasFinished,
  postFloorCanvasFailed,
  fetchFloorCanvasPending,
  fetchFloorCanvasFulfilled,
  fetchFloorCanvasFailed,
  changeFloorThumbnail,
} from './actions'
import { toFabricSync, getPrototypes } from './utils'
import { fromFabricAreaFb } from './utilsSave'
import { imageUploadFloor } from '../FloorsPage/sagas'
import { errorToast, successToast } from '../../utils/utils'

export function* loadFloor({ payload }) {
  const { screen, isStatic, selectedId = -1, sId, fId } = payload
  console.log(sId, fId)

  try {
    const prototypes = yield getPrototypes()
    yield put(storePrototypes(prototypes))
    yield put(storeParams({ screen, isStatic }))
    const isFloorExist = yield select(makeSelectFloorExist(fId))
    // TODO: hacky flag to distinguish if background fetch update can be executed
    // It based on assumption that areas for showing should have layout positions
    // it allows us to reduce amount of background fetches, please do not hesitate to improve / remove it.
    // const hasAreasWithLayout = yield select(makeSelectAreasWithLayoutExist(fId))

    if (!isFloorExist) {
      yield put(fetchFloorCanvasPending(sId))
      yield call(fetchAndUpsert, { payload: { sId } })
    }

    const floor = yield select(makeSelectFloor(fId))
    const images = yield select(makeSelectImages())

    console.log('floor', floor)
    yield put(
      fetchFloorCanvasFulfilled(
        sId,
        toFabricSync(
          floor,
          images,
          screen,
          isStatic,
          false,
          selectedId,
          prototypes,
          mtiJsclientShared
        ),
        { id: floor.id, name: floor.name },
        selectedId,
        isStatic
      )
    )

    /* if (isFloorExist && isStatic && !hasAreasWithLayout) {
      yield put(fetchAndUpsertStore(sId))
    } */
    if (isFloorExist && isStatic) {
      yield call(fetchAndUpsert, { payload: { sId } })
    }
    //
    yield put(createDefaultFloorAndArea(sId))
  } catch (error) {
    console.log(error)
    const res = error.response
    const errorObj = res ? yield call([res, res.json]) : error
    yield put(fetchFloorCanvasFailed(errorObj))
    errorToast('Load floor failed')
  }
}

export function* saveArea({ payload }) {
  const {
    canvasObject: { objects = [] },
    sId,
    fId,
    selectedId,
    screen,
    floorThumbnailPayload,
  } = payload
  const token = yield select(makeSelectToken())
  try {
    // yield put(postFloorCanvasPending(sId))
    const area = fromFabricAreaFb(
      objects.find(({ id }) => id === selectedId),
      screen
    )
    if (!area) {
      console.error('saveArea: No Area')
      return
    }

    let data
    if (isNaN(area.id)) {
      data = yield call(postArea, token, area, sId, fId)
      area.oldId = area.id
      area.id = data.areas[0].id
      successToast('Area Created')
    } else {
      data = yield call(patchArea, token, area, sId, fId)
      successToast('Area Updated')
    }
    yield call(upsert, { payload: { ...data, stores: undefined } }) // undefine store, cause it doesn't have all the data

    yield put(
      postFloorCanvasFinished({ sId, aId: area.id, oldAId: area.oldId })
    )
    if (floorThumbnailPayload) {
      yield put(changeFloorThumbnail(floorThumbnailPayload))
    }
  } catch (error) {
    console.log(error)
    const res = error.response
    const errorObj = res ? yield call([res, res.json]) : error
    yield put(postFloorCanvasFailed(errorObj))
    errorToast('Save area failed')
  }
}

export function* removeArea({ payload }) {
  const {
    canvasObject: { objects = [] },
    sId,
    selectedId,
    floorThumbnailPayload,
  } = payload
  const area = objects.find(({ id }) => id === selectedId)
  const token = yield select(makeSelectToken())
  try {
    if (!isNaN(area.id)) {
      //yield put(postFloorCanvasPending(sId))
      const data = yield call(deleteArea, token, area)
      successToast('Area Deleted')
      yield put(mac.deleteAreaWithId(area.id))
      yield call(upsert, { payload: data })
      yield put(postFloorCanvasFinished(sId, data))
      if (floorThumbnailPayload) {
        yield put(changeFloorThumbnail(floorThumbnailPayload))
      }
      yield put(fetchAndUpsertStore(sId, true)) // TODO: remove after api will return full store object
    }
  } catch (error) {
    console.log(error)
    const res = error.response
    const errorObj = res ? yield call([res, res.json]) : error
    yield put(postFloorCanvasFailed(errorObj))
    errorToast('Delete area failed')
  }
}

/**
 * Floor Sagas
 */
export default function* root() {
  yield all([
    yield takeLatest(ActionTypes.SAVE_FLOOR_AREA, saveArea),
    yield takeLatest(ActionTypes.FLOOR_CANVAS_AREA_REMOVE, removeArea),
    yield takeLatest(
      ActionTypes.FLOOR_THUMBNAIL_FROM_CANVAS_CHANGED,
      imageUploadFloor
    ),
  ])
}
