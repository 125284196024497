import { List } from 'immutable'

import Lock from 'images/lock.png'
import Unlock from 'images/unlock.png'
import Cross from 'images/remove.png'
import Warn from 'images/warn.png'
import Check from 'images/check.png'

export function getDateFormatted(date) {
  const { startDate, endDate } = date || {}
  return {
    startDate: startDate ? startDate.format('YYYY-MM-DD') : null,
    endDate: endDate ? endDate.format('YYYY-MM-DD') : null,
  }
}

export function mergeReports(
  combination,
  health,
  operations,
  compliance,
  marketing
) {
  console.log('combination >>>> ', combination)
  const {
    health: cHealth,
    operations: cOperations,
    compliance: cCompliance,
    marketing: cMarketing,
  } = combination
  const results = []
  if (cHealth) {
    results.push(...addReportType((health || {}).results || [], 'health'))
  }
  if (cOperations) {
    results.push(
      ...addReportType((operations || {}).results || [], 'operations')
    )
  }
  if (cCompliance) {
    results.push(
      ...addReportType((compliance || {}).results || [], 'compliance')
    )
  }
  if (cMarketing) {
    results.push(...addReportType((marketing || {}).results || [], 'marketing'))
  }
  console.log('results >>>> ', results)
  return List(results)
}

function addReportType(reports, reportType) {
  return reports.map((e) => {
    e.reportyType = reportType
    return e
  })
}

export function eventIcon(type) {
  switch (type) {
    case 'arm': // operations
    case 'armed': // operations
    case 'disarm':
    case 'silence':
    case 'identify':
    case 'alarming':
    case 'physical_key_inactive':
    case 'physical_key_active':
      return Lock
    case 'disarmed':
    case 'unlock':
      return Unlock
    case 'compliance_warning': // Warnings
    case 'health_warning':
    case 'Product powered, but not properly secured':
      return Warn
    case 'in_compliance': // OK
    case 'healthy':
      return Check
    case 'out_of_compliance': // Problems
    case 'unhealthy':
      return Cross
    default:
      // console.log(`Unhandlen event type for icon ${type}`)
      return null
  }
}

export function extractStoreName(text) {
  return text.replace(/\s*\(.*\)/, '').trim()
}