import React from 'react'
import PropTypes from 'prop-types'
import ReactTable from 'react-table'
import { fullTextFilter, getTableHeight } from '../../utils/utils'
import {
  CellContainer,
  HeaderContainer,
  Header,
  renderHeaderCell,
} from '../UsersPage/index.screen'

class HubsListScreen extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    window.setTimeout(() => this.handleResize(), 10)
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  /* eslint-disable no-undef */
  handleResize = () => this.setState({ tableHeight: getTableHeight() })

  render() {
    const { hubs, onHubSelection } = this.props
    const { tableHeight } = this.state

    const columns = [
      {
        id: 'name',
        Header: () => renderHeaderCell('Name'),
        Cell: ({ original }) => (
          <CellContainer onClick={() => onHubSelection(original)}>
            {original.id}
          </CellContainer>
        ),
        accessor: 'id',
      },
    ]
    return (
      <div>
        <HeaderContainer className="border-bottom">
          <Header>Hubs</Header>
        </HeaderContainer>
        <ReactTable
          className="full-screen"
          style={{ height: tableHeight || 'auto' }}
          data={hubs}
          columns={columns}
          defaultFilterMethod={fullTextFilter}
        />
      </div>
    )
  }
}

HubsListScreen.propTypes = {
  hubs: PropTypes.array.isRequired,
  onHubSelection: PropTypes.func.isRequired,
}

export default HubsListScreen
