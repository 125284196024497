import React from 'react'
import PropTypes from 'prop-types'
import ManufacturerAndProductSelector from '../../../../components/ManufacturerAndProductSelector'
import EditDisabled from '../../../../components/EditDisabled'
import styled from 'styled-components'
import { FormattedMessage, injectIntl } from 'react-intl'
import Select from 'react-select'
import ErrorIcon from '../../../../images/svg/buttons/error.svg'

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`
export const ColumnContainer = styled.div`
  padding-top: 0px;
`
const intlPath = (string) => `securedProduct.${string}`

const allowedCategoryTransitions = {
  seen: ['seen', 'current', 'retired'],
  current: ['current', 'retired'],
  missing: ['missing', 'current', 'retired'],
}

const retiredReasons = ['not_retired', 'theft', 'eol', 'unintentional', 'other']

class SecuredProductDetailsScreen extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      notes: props.securedProduct.notes,
      category: props.securedProduct.category,
      retiredReason: props.securedProduct.retiredReason,
    }
  }

  _handleNotesChange = (e) => {
    const { onChange } = this.props
    let notes = e.target.value
    this.setState({ notes })
    onChange({ notes })
  }

  _handleCategoryChange = (selectedOption) => {
    const { onChange } = this.props
    let category = selectedOption.value

    let retiredReason = this.state.retiredReason
    if (category == 'retired' && retiredReason == 'not_retired') {
      retiredReason = 'other'
    }
    this.setState({ category, retiredReason })
    onChange({ category, retiredReason })
  }

  _handleRetiredReasonChange = (selectedOption) => {
    const { onChange } = this.props
    let retiredReason = selectedOption.value
    this.setState({ retiredReason })
    onChange({ retiredReason })
  }

  _errorIcon(msg) {
    return (
      <img
        ref={(ref) => {
          this.imgRef = $(ref) // eslint-disable-line
          this._tooltip(msg)
        }}
        src={ErrorIcon}
        height="26"
        alt={msg}
      />
    )
  }

  _tooltip = (msg) => {
    this.imgRef.popover('dispose').popover({
      placement: 'bottom',
      trigger: 'hover focus',
      html: true,
      content: msg,
    })
  }

  renderMainContent = () => {
    const {
      securedProduct: { serialNumber, manufacturerId, productId, category },
      onChange,
      manufacturers,
      products,
      intl,
    } = this.props

    // always get the options based on the original category value, not whatever is currently selected.
    const categoryOptions = (
      allowedCategoryTransitions[category] || [category]
    ).map((c) => ({
      value: c,
      label: intl.formatMessage({ id: `securedProduct.categories.${c}` }),
    }))
    const selectedCategory = categoryOptions.find(
      (o) => o.value == this.state.category
    )
    const retiredReasonOptions = retiredReasons
      .filter((r) => this.state.category != 'retired' || r != 'not_retired')
      .map((r) => ({
        value: r,
        label: intl.formatMessage({ id: `securedProduct.retiredReasons.${r}` }),
      }))
    const selectedRetiredReason = retiredReasonOptions.find(
      (r) => r.value == this.state.retiredReason
    )
    const notes = this.state.notes || ''

    // TODO: I think this, along with other clientside validation,
    // could get a lot fancier, but this works for now and informs the
    // user that they need to fill out the notes.
    let notesError = null
    if (
      this.state.category == 'retired' &&
      this.state.retiredReason == 'other' &&
      notes == ''
    ) {
      notesError = intl.formatMessage({ id: 'help.retiredNotesValidation' })
    }

    return (
      <div className="col-xs-6">
        <ManufacturerAndProductSelector
          manufacturers={manufacturers}
          products={products}
          selectedManufacturerId={manufacturerId || ''}
          selectedProductId={productId || ''}
          onChange={onChange}
          containerClassName={'form-row'}
          manufacturersContainerClassName={'form-group col-md-6 bmd-form-group'}
          productsContainerClassName={'form-group col-md-6 bmd-form-group'}
        />
        <div className="form-row">
          <div className="form-group col-md-6 bmd-form-group">
            <EditDisabled
              header={intl.formatMessage({ id: 'serialNumber' })}
              text={serialNumber || ''}
            />
          </div>
        </div>
        <div className="form-row">
          <ColumnContainer className="form-group col-md-12 bmd-form-group">
            <Column>
              <div>
                <FormattedMessage id={intlPath('notes')} />
                {notesError ? this._errorIcon(notesError) : ''}
              </div>
              <input
                value={notes}
                type="text"
                className="form-control"
                id="securedProductNotes"
                placeholder=""
                onChange={this._handleNotesChange}
              />
            </Column>
          </ColumnContainer>
        </div>

        <div className="form-row">
          <ColumnContainer className="form-group col-md-6 bmd-form-group">
            <Column>
              <div>
                <FormattedMessage id={intlPath('category')} />
              </div>
              <Select
                className="full-width-dropdown"
                options={categoryOptions}
                required
                id="category"
                onChange={this._handleCategoryChange}
                value={selectedCategory}
              />
            </Column>
          </ColumnContainer>
          <ColumnContainer className="form-group col-md-6 bmd-form-group">
            <Column>
              <div>
                <FormattedMessage id={intlPath('retiredReason')} />
              </div>
              <Select
                className="full-width-dropdown"
                options={retiredReasonOptions}
                required
                id="retiredReason"
                onChange={this._handleRetiredReasonChange}
                value={selectedRetiredReason}
              />
            </Column>
          </ColumnContainer>
        </div>
      </div>
    )
  }

  render() {
    const { id, title, confirmText, onConfirm } = this.props
    return (
      <div className="modal-content">
        <div className="modal-header border-bottom">
          <h5 className="modal-title" id="exampleModalLongTitle">
            {title}
          </h5>
          <div>
            {onConfirm && (
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  $(`#modal-${id}`).modal('hide') // eslint-disable-line
                  onConfirm()
                }}
              >
                {confirmText}
              </button>
            )}
            <button
              type="button"
              className="btn btn-primary"
              onClick={this.close}
            >
              Cancel
            </button>
          </div>
        </div>
        <div className="modal-body">{this.renderMainContent()}</div>
      </div>
    )
  }
}

SecuredProductDetailsScreen.propTypes = {
  securedProduct: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  confirmText: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  manufacturers: PropTypes.array,
  products: PropTypes.array,
}

export default injectIntl(SecuredProductDetailsScreen)
