/**
 *
 * ErrorModal
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import { HeaderContainer, Header } from '../ProgressIndicatorModal'

const ErrorModal = ({ text }) => (
  <div className="modal-content">
    <HeaderContainer className="border-bottom">
      <Header>{text}</Header>
    </HeaderContainer>
  </div>
)

ErrorModal.propTypes = {
  text: PropTypes.string,
}

export default ErrorModal
