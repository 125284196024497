/*
 *
 * Modal reducer
 *
 */

import { fromJS } from 'immutable'

import { ActionTypes } from './constants'

const initialState = fromJS({
  modals: [],
})

function reducer(state = initialState, { type, obj }) {
  switch (type) {
    case ActionTypes.OPEN_MODAL:
      return state.set(
        'modals',
        state
          .get('modals')
          .splice(0, 1)
          .filter((item) => item.id !== obj.id)
          .concat(obj)
      )
    case ActionTypes.CLOSE_MODAL:
      return state.set(
        'modals',
        state
          .get('modals')
          .splice(0, 1)
          .filter((item) => item.id !== obj.id)
      )
    default:
      return state
  }
}

export default reducer
