import { namespace } from './constants'

// The reason to store an empty array as a const is to reduce the number of page re-renders.
// Whenever a selector returns a different _instance_ of an object, it tells redux to
// re-render the component, even if that new object is equivalent.
const defaultArray = []
const defaultPageData = {
  meta: {
    total: 0,
    pages: 1,
    page: 1,
    per: 100,
  },
  rows: [],
}

export function selectIsOrphanedDevicesLoading(state) {
  return state.get(namespace).get('orphanedDevicesIsLoading') || false
}

export function selectOrphanedDevicesData(state) {
  return state.get(namespace).get('orphanedDevicesData') || defaultPageData
}
