/**
 * Store Manager Sagas
 */
import { all, call, put, takeLatest, select } from 'redux-saga/effects'
import { makeSelectToken } from '../../../containers/App/selectors'
import { makeSelectIsDataExists } from './selectors'
import { ActionTypes } from './constants'
import { loadStores as loadStoresStoresPage } from '../../../containers/StoresPage/sagas'
import {
  fetchStoresPending,
  fetchStoresFulfilled,
  fetchStoresFailed,
} from './actions'
import { errorToast } from '../../../utils/utils'

export function* loadStores() {
  console.log('loadStores')
  const token = yield select(makeSelectToken())
  try {
    const isDataExists = yield select(makeSelectIsDataExists())

    if (!isDataExists) {
      yield put(fetchStoresPending())
      yield call(downloadStoresData, token)
    }

    yield put(fetchStoresFulfilled())

    if (isDataExists) {
      yield call(downloadStoresData, token)
    }
  } catch (error) {
    yield put(fetchStoresFailed(error))
    errorToast('Load stores failed')
  }
}

export function* downloadStoresData() {
  yield call(loadStoresStoresPage)
}

export default function* root() {
  yield all([yield takeLatest(ActionTypes.LOAD_STORES, loadStores)]) // TODO: remove
}
