import { ActionTypes } from './constants'

/**
 * Post Password Failed
 *
 * @returns {Object}
 */
export function postPasswordFailed(error) {
  return {
    type: ActionTypes.POST_PASSWORD_FAILED,
    payload: error,
  }
}

/**
 * Post Password Fulfilled
 *
 * @returns {Object}
 */
export function postPasswordFulfilled() {
  return {
    type: ActionTypes.POST_PASSWORD_FULFILLED,
  }
}

/**
 * Post Password Success
 *
 * @returns {Object}
 */
export function postPasswordSuccess(data) {
  return {
    type: ActionTypes.POST_PASSWORD_SUCCESS,
    payload: data,
  }
}

/**
 * Post Password Pending
 *
 * @returns {Object}
 */
export function postPasswordPending() {
  return {
    type: ActionTypes.POST_PASSWORD_PENDING,
  }
}

/**
 * Edit Password
 *
 * @returns {Object}
 */
export function edit(o) {
  return {
    type: ActionTypes.EDIT_PASSWORD,
    payload: o,
  }
}

/**
 * Post Password
 *
 * @returns {Object}
 */
export function post(query) {
  return {
    type: ActionTypes.POST_PASSWORD,
    payload: query,
  }
}
