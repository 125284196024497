import keyMirror from 'fbjs/lib/keyMirror'

/**
 * @constant {Object} ActionTypes
 * @memberof Constants
 */
export const ActionTypes = keyMirror({
  UPDATE_LODE: undefined,
  UPDATE_LODE_PENDING: undefined,
  UPDATE_LODE_FULFILLED: undefined,
  UPDATE_LODE_FAILED: undefined,
})
