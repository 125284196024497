import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { batchActions } from 'redux-batched-actions'
import { errorToast } from '../../utils/utils'
import { makeSelectToken } from '../../containers/App/selectors'
import { getLayoutTableViewData, getAreas, getFixtures } from '../../api'
import {
  layoutTableViewLoadingAction,
  layoutTableDataReducerAction,
  areasFilterReducerAction,
  fixturesFilterReducerAction,
} from './actions'
import { ActionTypes } from './constants'

function* fetchLayoutTableViewData({ payload }) {
  const { storeID, filters, loadPage } = payload
  yield put(layoutTableViewLoadingAction(true)) // loading is true, so show the loading spinner

  // This is a list of reducer actions we'll yield to when we're done loading all the data
  // eslint-disable-next-line redux-saga/yield-effects
  let reducerActionsList = [put(layoutTableViewLoadingAction(false))]

  const token = yield select(makeSelectToken())
  let tableDataResponse = null
  if (loadPage) {
    // the first time this is run (on page load), also fetch the areas
    // and fixtures list for the filters concurrently.
    const [areasResponse, fixturesResponse, tableResponse] = yield all([
      call(getAreas, token, storeID),
      call(getFixtures, token, storeID),
      call(getLayoutTableViewData, token, storeID, filters),
    ])
    tableDataResponse = tableResponse

    let filterDataErrors = false // TODO
    if (filterDataErrors) {
      errorToast('Failed to load areas or fixtures for filter list')
    } else {
      /* eslint-disable redux-saga/yield-effects */
      reducerActionsList = reducerActionsList.concat([
        put(areasFilterReducerAction(areasResponse)),
        put(fixturesFilterReducerAction(fixturesResponse)),
      ])
      /* eslint-enable redux-saga/yield-effects */
    }
  } else {
    // don't bother loading the areas & fixtures
    tableDataResponse = yield call(getLayoutTableViewData, token, storeID, filters)
  }

  const tableDataErrors = false // TODO
  if (tableDataErrors) {
    errorToast('Failed to load table data')
  } else {
    // eslint-disable-next-line redux-saga/yield-effects
    reducerActionsList.push(put(layoutTableDataReducerAction(tableDataResponse)))
  }

  // batchActions is a way to run multiple reducers to update multiple
  // parts of the redux store but only fire a single re-render when they're
  // all done, for page efficiency.
  yield all(batchActions(reducerActionsList))
}

export default function* root() {
  yield all([
    yield takeLatest(ActionTypes.FETCH_LAYOUT_TABLE_VIEW_DATA, fetchLayoutTableViewData),
  ])
}
