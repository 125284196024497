/**
 *
 * Regions Preloader
 *
 */

import React from 'react'
import ContentLoader from 'react-content-loader'
import uuid from 'uuid'

function RegionsPreloader() {
  const Loader = (props) => {
    const random = Math.random() * (1 - 0.7) + 0.7
    const loaderWidth = window.innerWidth
    const firstColumnWidth = loaderWidth * 0.15
    const secondСolumnWidth = loaderWidth * 0.06
    const thirdСolumnWidth = loaderWidth * 0.68
    return (
      <ContentLoader
        height={36}
        width={loaderWidth}
        speed={2}
        primaryColor="#d9d9d9"
        secondaryColor="#ecebeb"
        {...props}
      >
        <rect
          x="5"
          y="8"
          rx="6"
          ry="6"
          width={firstColumnWidth * random}
          height="14"
        />
        <rect
          x={firstColumnWidth + 10}
          y="8"
          rx="6"
          ry="6"
          width={secondСolumnWidth * random}
          height="14"
        />
        <rect
          x={secondСolumnWidth + firstColumnWidth + 20}
          y="8"
          rx="6"
          ry="6"
          width={thirdСolumnWidth * random}
          height="14"
        />
        <rect
          x={secondСolumnWidth + firstColumnWidth + thirdСolumnWidth + 30}
          y="8"
          rx="6"
          ry="6"
          width={secondСolumnWidth * random}
          height="14"
        />

        <rect x="0" y="32" rx="6" ry="6" width={loaderWidth} height=".3" />
      </ContentLoader>
    )
  }

  const RegionsPreloader = () => {
    const height = window.innerHeight
    const countRows = parseInt(height / 50)
    return (
      <div>
        <React.Fragment>
          {Array(2)
            .fill('')
            .map(() => (
              <Loader
                key={`loader_${uuid.v4()}`}
                primaryColor="#d6d4d7"
                secondaryColor="#c4c2c5"
              />
            ))}
        </React.Fragment>
        <React.Fragment>
          {Array(countRows)
            .fill('')
            .map((e, i) => (
              <Loader
                key={`loader_${uuid.v4()}`}
                style={{ opacity: Number(2 / i).toFixed(1) }}
              />
            ))}
        </React.Fragment>
      </div>
    )
  }

  return <RegionsPreloader />
}

export default RegionsPreloader
