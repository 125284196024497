/**
 *
 * Home Screen Preloader
 *
 */

import React from 'react'
import ContentLoader from 'react-content-loader'
import uuid from 'uuid'
import { HeaderHeight } from '../../../utils/utils'

function HomeScreenPreloader() {
  const HomeScreenPreloader = (props) => {
    const loaderWidth = window.innerWidth
    const height = window.innerHeight
    const storesCount = parseInt(loaderWidth / 240, 10)
    const sliderHeight = 160
    const widthFirstBlock = 190
    const widthSecondBlock = 350
    const widthThirdBlock = 430
    const margin = 10
    const sliderX = (loaderWidth - storesCount * 225) / 2 // subtract from the width of the screen - the width of the slider and divide by two, we get the starting coordinate of the preloader
    return (
      <ContentLoader
        height={height - HeaderHeight}
        width={loaderWidth}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
        {...props}
      >
        <rect
          x={(loaderWidth - 990) / 2}
          y="180"
          rx="0"
          ry="0"
          width={widthFirstBlock}
          height={height - sliderHeight}
        />
        <rect
          x={(loaderWidth - 990) / 2 + widthFirstBlock + margin}
          y="180"
          rx="0"
          ry="6"
          width={widthSecondBlock}
          height={height - sliderHeight}
        />
        <rect
          x={
            (loaderWidth - 990) / 2 +
            widthFirstBlock +
            widthSecondBlock +
            2 * margin
          }
          y="180"
          rx="0"
          ry="0"
          width={widthThirdBlock}
          height={height - sliderHeight}
        />
        <React.Fragment>
          {Array(storesCount)
            .fill('')
            .map((e, i) => (
              <rect
                key={`loader_${uuid.v4()}`}
                x={sliderX + 230 * i}
                y="2"
                rx="0"
                ry="0"
                width="220"
                height={sliderHeight}
              />
            ))}
        </React.Fragment>
      </ContentLoader>
    )
  }

  return <HomeScreenPreloader />
}

export default HomeScreenPreloader
