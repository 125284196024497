import { createSelector } from 'reselect'
import { createSelector as createOrmSelector } from 'redux-orm'
import { orm } from 'mti-jsclient-shared'
import {
  getPermission,
  getPermissionAnyResource,
  getPermissionResources,
  hasRole,
  hasRoleAnyResource,
} from '../../utils/mtiUtils'
import MTIRolesPermissionsTable from '../../csv/MTIRolesPermissionsTable.csv'
import { createKey } from '../Keys/keys'
import { formatUserData } from '../UsersPage/users'

const selectRoute = state => state.get('route')

const makeSelectLocation = () =>
  createSelector(selectRoute, routeState => routeState.get('location').toJS())

const selectUser = (userState, appState) => {
  const user = userState.get('data')
  const isMTIUser = selectIsMTIUserFromRoles((user || {}).mtiRoles)
  if (isMTIUser) {
    return user
  }
  const me = appState.get('me')
  const users = appState.get('users') || []
  const regions = appState.get('regions')

  // merge user (which is used in other parts of the app and contains incomplete data), data from
  // the 'me' endpoint, and finally any users data from the users endpoint, if any of those exist
  const userData = (users.users || []).find(({ id }) => user.id === id)
  const usersData = formatUserData({
    ...users,
    ...{ users: [{...userData, ...user, ...me}], ...{ regions } },
  })
  return usersData.length ? usersData[0].toJS() : user
}

const userSelector = state => state.get('user')

const makeSelectData = () =>
  createSelector(userSelector, appSelector, (userState, appState) =>
    selectUser(userState, appState)
  )

const selectIsMtiAdmin = userState => {
  const user = userState.get('data')
  const isMtiAdmin =
    ((user || {}).mtiRoles || []).filter(({ name }) => name === 'MTI Admin')
      .length > 0
  return isMtiAdmin
}

export const selectIsCorporateUser = createSelector(userSelector, userState => {
  const orgCorporateRoles = ['Organization Administrator', 'Regional Manager', 'Support Analyst', 'Store Technician']
  // Maybe also add User Administrator here
  const userData = userState.get('data')
  return !!((userData || {}).mtiRoles || []).find(r => orgCorporateRoles.includes(r.name))
})

const makeSelectIsMtiAdmin = () =>
  createSelector(userSelector, userState => selectIsMtiAdmin(userState))

const selectMe = appState => appState.get('me') || []

const makeSelectMe = () =>
  createSelector(appSelector, appState => selectMe(appState))

const selectMeLoading = appState => appState.get('meLoading')

const makeSelectMeLoading = () =>
  createSelector(appSelector, appState => selectMeLoading(appState))

const selectPersonae = appState => appState.get('personae') || []

const makeSelectPersonae = () =>
  createSelector(appSelector, appState => selectPersonae(appState))

const selectPersonaeLoading = appState => appState.get('personaeLoading')

const makeSelectPersonaeLoading = () =>
  createSelector(appSelector, appState => selectPersonaeLoading(appState))

const mqttSelector = state => state.get('mqtt')

const makeSelectSubscribedTopic = () =>
  createSelector(mqttSelector, mqttState => mqttState.get('topic'))

const appSelector = state => state.get('app')

const makeSelectImages = () =>
  createSelector(appSelector, appState => appState.get('images'))

const makeSelectSecurityDeviceIdentifySerialNumber = () =>
  createSelector(appSelector, appState =>
    appState.get('securityDeviceIdentifySerialNumber').toArray()
  )

const makeSelectAppRegions = () =>
  createSelector(appSelector, appState => appState.get('regions'))

const makeSelectMtiPermissionAccessData = useCase =>
  createSelector(userSelector, appSelector, (userState, appState) => {
    const orgId = (userState.get('data') || {}).organizationId
    const mtiRolesPermission = selectMtiRolesPermission(useCase)
    if (!mtiRolesPermission) {
      console.warn('Check permission use case name')
      return undefined
    }
    const mtiRoles = (userState.get('data') || {}).mtiRoles
    const regions = (appState.get('regions') || []).map(r => r.toJS())

    return {
      mtiRoles,
      mtiRolesPermission: mtiRolesPermission,
      organizationId: orgId,
      regions,
    }
  })

// TODO: ideally, we would just set a boolean on the user object itself, to avoid checking for each
// role, but that would require a larger change due to how models work in Gen2.
const MTI_USER_ROLES = ['MTI Admin', 'Super User', 'Field Technician', 'Tier One Support']

export const selectIsMTIUserFromRoles = roles => (
  Array.isArray(roles) ? !!roles.find(r => MTI_USER_ROLES.includes(r.name)) : false
)

export const selectIsMTIUser = createSelector(userSelector, userState => {
  const roles = (userState.get('data') || {}).mtiRoles
  return selectIsMTIUserFromRoles(roles)
})

const makeSelectHasMtiRoleAnyResource = role => (
  createSelector(userSelector, userState => {
    const data = userState.get('data') || {}
    const orgId = data.organizationId
    const mtiRoles = data.mtiRoles
    const regions = data.regions
    const hasOrgRole = hasRole(mtiRoles, role, orgId, regions)
    const hasResourceRole = hasRoleAnyResource(mtiRoles, role)
    return hasOrgRole || hasResourceRole
  })
)

export const selectIsUserAdmin = makeSelectHasMtiRoleAnyResource('User Administrator')

const makeSelectHasMtiRole = (role, resourceId) =>
  createSelector(userSelector, userState => {
    const orgId = (userState.get('data') || {}).organizationId
    const mtiRoles = (userState.get('data') || {}).mtiRoles
    const regions = (userState.get('data') || {}).regions
    const hasOrgRole = hasRole(mtiRoles, role, orgId, regions)
    const hasResourceRole = hasRole(mtiRoles, role, resourceId, regions)
    return hasOrgRole || hasResourceRole
  })

const selectMtiRolesPermission = useCase =>
  MTIRolesPermissionsTable.find(row => row['Use Case'] === useCase)

const makeSelectMtiPermissionAnyResource = useCase =>
  createSelector(userSelector, userState => {
    const orgId = (userState.get('data') || {}).organizationId
    const mtiRolesPermission = selectMtiRolesPermission(useCase)
    if (!mtiRolesPermission) console.warn('Check permission use case name')
    const mtiRoles = (userState.get('data') || {}).mtiRoles
    // Organization level access
    const orgPermission = getPermission({ mtiRoles, mtiRolesPermission, resourceId: orgId, resourceType: 'Organization' })
    // Other than Organization level access
    const resourcePermission = getPermissionAnyResource(mtiRoles, mtiRolesPermission)
    return orgPermission || resourcePermission
  })

export const selectHasProvisionPermission = makeSelectMtiPermissionAnyResource('Provision')

const makeSelectMtiPermission = (useCase, storeId) =>
  createSelector(userSelector, userState => {
    if (!storeId) {
      const orgId = (userState.get('data') || {}).organizationId
      const mtiRolesPermission = selectMtiRolesPermission(useCase)
      if (!mtiRolesPermission) console.warn('Check permission use case name')
      const mtiRoles = (userState.get('data') || {}).mtiRoles
      // Organization level access
      const orgPermission = getPermission({ mtiRoles, mtiRolesPermission, resourceId: orgId, resourceType: 'Organization' })
      // Other than Organization level access
      const resourcePermission = getPermissionAnyResource(
        mtiRoles,
        mtiRolesPermission
      )
      return orgPermission || resourcePermission
    }
    const orgId = (userState.get('data') || {}).organizationId
    const mtiRolesPermission = selectMtiRolesPermission(useCase)
    if (!mtiRolesPermission) console.warn('Check permission use case name')
    const mtiRoles = (userState.get('data') || {}).mtiRoles
    // Organization level access
    const orgPermission = getPermission({ mtiRoles, mtiRolesPermission, resourceId: orgId, resourceType: 'Organization' })
    if (orgPermission) return orgPermission
    const storePermission = getPermission({ mtiRoles, mtiRolesPermission, resourceId: storeId, resourceType: 'Store' })
    if (storePermission) return storePermission
    const regions = userState.get('data')?.regions
    const regionalPermission = getPermission({
      mtiRoles,
      mtiRolesPermission,
      storeId,
      regions,
    })
    return regionalPermission
  })

const makeSelectMtiPermissionResources = role =>
  createSelector(userSelector, userState => {
    const mtiRoles = (userState.get('data') || {}).mtiRoles
    const regions = (userState.get('data') || {}).regions
    const resourcePermissions = getPermissionResources(mtiRoles, role, regions)
    return resourcePermissions
  })

const makeSelectIsLoading = () =>
  createSelector(userSelector, userState => userState.get('isLoading'))

const makeSelectError = () =>
  createSelector(userSelector, userState => userState.get('error'))

const selectUserError = createSelector(userSelector, userState => ({
  message: userState.get('error'),
  body: userState.get('errorBody'),
}))

const makeSelectToken = () =>
  createSelector(userSelector, userState => {
    try {
      return userState.get('data').token
    } catch (e) {
      return undefined
    }
  })
const selectToken = makeSelectToken()
const tokenSelector = selectToken

const makeSelectSaltToken = () =>
  createSelector(userSelector, userState => {
    try {
      return userState.get('data').saltToken
    } catch (e) {
      return undefined
    }
  })

const makeSelectTokenId = () =>
  createSelector(userSelector, userState => {
    try {
      return userState.get('data').tokenId
    } catch (e) {
      return undefined
    }
  })

const makeSelectUserId = () =>
  createSelector(userSelector, userState => {
    try {
      return userState.get('data').id
    } catch (e) {
      return undefined
    }
  })

const permissionsSelector = state => state.get('permissions')

const makeSelectPermissions = () =>
  createSelector(permissionsSelector, permissionsState =>
    permissionsState.get('permissions')
  )

const selectTags = state => state.get('tags')

const makeSelectTaggings = () =>
  createSelector(selectTags, tags => tags.get('taggings'))

const makeSelectTagsEditIsLoading = () =>
  createSelector(selectTags, tags => tags.get('editIsLoading'))

const makeSelectTags = () =>
  createSelector(selectTags, tags => tags.get('tags'))

const makeSelectEditedTaggings = () =>
  createSelector(selectTags, tags =>
    (tags.get('editedTaggings') || { toArray: () => [] })
      .toArray()
      .map(t => t.toJS())
  )

// Load Organization
/*
 * Organization Details are nested in the store as
 * store = {
 *   organization: Map({
 *     organization: Map({
 *       organizations: [
 *         { ... details here ... }
 *       ]
 *     })
 *   })
 * }
 */
const organizationSelector = state => state.get('organization')
const orgOrgSelector = createSelector(organizationSelector, org => org && org.get('organization'))
const organizationDetailsSelector =
  createSelector(orgOrgSelector, org => (org && org.organizations && org.organizations[0]) || {})
const organizationLoadedSelector =
  createSelector(organizationDetailsSelector, deets => Object.keys(deets).length > 0)

const makeSelectOrganizationLoading = () =>
  createSelector(organizationSelector, orgState => orgState.get('isLoading'))

const makeSelectOrganizationError = () =>
  createSelector(organizationSelector, orgState => orgState.get('error'))

const makeSelectOrganization = () =>
  createSelector(organizationSelector, orgState =>
    orgState.get('organization')
  )

const selectOrganizationID = state => {
  let orgID = null
  try {
    orgID = state.get('organization').get('organization').organizations[0].id
  } catch { console.warn('unable to get org id from state.') }
  return orgID
}

const makeSelectOrganizationId = () =>
  createSelector(
    organizationSelector,
    orgState =>
      (((orgState.get('organization') || {}).organizations || [])[0] || {}).id
  )

const makeSelectSubdomain = () =>
  // TODO: temporary
  createSelector(
    organizationSelector,
    orgState =>
      (((orgState.get('organization') || {}).organizations || [])[0] || {})
        .subdomain
  )

const makeSelectOrganizationDetails = () =>
  createSelector(organizationSelector, orgState => {
    const state = orgState.get('organization') || {}
    const organizationData = (state.organizations || [])[0]
    const address = (state.addresses || [])[0]
    return { ...organizationData, address }
  })



// Edit Organization
const selectOrganizationEdit = state => state.get('organizationEdit')

const makeSelectOrganizationEdit = () =>
  createSelector(
    selectOrganizationEdit,
    organization => (organization ? organization.toJS() : organization)
  )

const makeSelectOrganizationPatchLoading = () =>
  createSelector(selectOrganizationEdit, organization =>
    organization.get('patchLoading')
  )

const makeSelectOrganizationPatchFailed = () =>
  createSelector(selectOrganizationEdit, organization =>
    organization.get('patchFailed')
  )

const makeSelectOrganizationManagement = () =>
  createSelector(selectOrganizationEdit, organization =>
    organization.get('management')
  )

const dbStateSelector = state => state.get('orm')

const selectStore = createOrmSelector(
  orm,
  dbStateSelector,
  (state, storeId) => storeId,
  (session, storeId) => getStore(session, storeId)
)

const getStore = (session, storeId) => {
  try {
    return session.Store.withId(storeId).ref
  } catch (e) {
    return undefined
  }
}

const selectPositionById = pId =>
  createOrmSelector(orm, session => {
    try {
      return session.Position.withId(pId).ref
    } catch (e) {
      return undefined
    }
  })

const makeSelectPosition = pId =>
  createSelector(dbStateSelector, ormState =>
    selectPositionById(pId)(ormState)
  )

const selectSecurityDeviceById = sdId =>
  createOrmSelector(orm, session => {
    try {
      return session.SecurityDevice.withId(sdId).ref
    } catch (e) {
      return undefined
    }
  })

const makeSelectSecurityDevice = sdId =>
  createSelector(dbStateSelector, ormState =>
    selectSecurityDeviceById(sdId)(ormState)
  )

const selectStoreIsInDefultFloorModeById = storeId =>
  createOrmSelector(orm, session => {
    try {
      if (!session.Store.hasId(storeId)) {
        return false
      }
      const store = session.Store.withId(storeId).ref
      const floorsExist = (store.floors || [])
        .map(fId => session.Floor.hasId(fId))
        .filter(is => is)
      if (floorsExist.length === 1) {
        return true
      }
      return false
    } catch (e) {
      return false
    }
  })

const makeSelectStoreIsInDefultFloorMode = storeId =>
  createSelector(dbStateSelector, ormState =>
    selectStoreIsInDefultFloorModeById(storeId)(ormState)
  )

const selectStoreIsInDefultAreaModeById = storeId =>
  createOrmSelector(orm, session => {
    try {
      if (!session.Store.hasId(storeId)) {
        return false
      }
      const store = session.Store.withId(storeId).ref
      const floorsExist = (store.floors || [])
        .map(fId => session.Floor.hasId(fId))
        .filter(is => is)
      const areasExist = (store.areas || [])
        .map(aId => session.Area.hasId(aId))
        .filter(is => is)
      if (floorsExist.length === 1 && areasExist.length === 1) {
        return true
      }
      return false
    } catch (e) {
      return false
    }
  })

const makeSelectStoreIsInDefultAreaMode = storeId =>
  createSelector(dbStateSelector, ormState =>
    selectStoreIsInDefultAreaModeById(storeId)(ormState)
  )

const selectKeys = state => state.get('keys')

const makeSelectKeys = storeId =>
  createSelector(appSelector, selectKeys, (app, keys) => {
    const users = app.get('users')
    const regions = app.get('regions')
    const usersExtended = formatUserData({ ...users, ...{ regions } })
    let keysByStore = keys.get('keys').get(storeId)
    if (!keysByStore) return
    for (let i = 0; i < (keysByStore || {}).size; i++) {
      keysByStore = keysByStore.update(i, item => {
        const user = usersExtended.find(({ id }) => id === item.userId)
        if (user) {
          return createKey({ ...item.toJS(), user })
        } else {
          return item
        }
      })
    }
    return keysByStore
  })

const makeSelectKeysFailed = () =>
  createSelector(selectKeys, keys => keys.get('failed'))

const makeSelectKeysLoading = () =>
  createSelector(selectKeys, keys => keys.get('loading'))

const selectUserEdit = state => state.get('userEdit')

const selectIsEditingUser = state => {
  const editState = state.get('userEdit')
  return (editState.get('failed') || editState.get('loading'))
}

const makeSelectUserManagement = () =>
  createSelector(selectUserEdit, userEdit => userEdit.get('management'))

const makeSelectUserDetails = () =>
  createSelector(selectUserEdit, userEdit => userEdit.get('userDetails'))

const makeSelectUser = () =>
  createSelector(selectUserEdit, user => (user ? user.toObject() : user))
const userEditSelector = makeSelectUser()

const makeSelectUserEditFailed = () =>
  createSelector(selectUserEdit, userEdit => userEdit.get('failed'))

const makeSelectUserEditLoading = () =>
  createSelector(selectUserEdit, userEdit => userEdit.get('loading'))

const selectSecuredProductEdit = state => state.get('securedProductEdit')

const makeSelectSelectedSecuredProduct = () =>
  createSelector(selectSecuredProductEdit, securedProductEdit =>
    securedProductEdit.get('selectedSecuredProduct')
  )

const makeSelectSecuredProductModalTypeToShow = () =>
  createSelector(selectSecuredProductEdit, securedProductEdit =>
    securedProductEdit.get('securedProductModalTypeToShow')
  )

const makeSelectPatchSecuredProductFailed = () =>
  createSelector(selectSecuredProductEdit, securedProductEdit =>
    securedProductEdit.get('patchSecuredProductFailed')
  )

const makeSelectPatchSecuredProductLoading = () =>
  createSelector(selectSecuredProductEdit, securedProductEdit =>
    securedProductEdit.get('patchSecuredProductLoading')
  )

const selectScheduleEdit = state => state.get('scheduleEdit')

const makeSelectScheduleManagement = () =>
  createSelector(selectScheduleEdit, scheduleEdit =>
    scheduleEdit.get('management')
  )

const makeSelectScheduleDetails = () =>
  createSelector(selectScheduleEdit, scheduleEdit =>
    scheduleEdit.get('scheduleDetails')
  )

const makeSelectSchedule = () =>
  createSelector(
    selectScheduleEdit,
    schedule => (schedule ? schedule.toJS() : schedule)
  )

const makeSelectScheduleEditFailed = () =>
  createSelector(selectScheduleEdit, scheduleEdit =>
    scheduleEdit.get('failed')
  )

const makeSelectScheduleEditLoading = () =>
  createSelector(selectScheduleEdit, scheduleEdit =>
    scheduleEdit.get('loading')
  )

const selectStoreEdit = state => state.get('storeEdit')

const makeSelectStoreEdit = () =>
  createSelector(selectStoreEdit, store => (store ? store.toJS() : store))

const makeSelectPatchLoading = () =>
  createSelector(selectStoreEdit, store => store.get('patchLoading'))

const makeSelectPatchFailed = () =>
  createSelector(selectStoreEdit, store => store.get('patchFailed'))

const makeSelectPostLoading = () =>
  createSelector(selectStoreEdit, store => store.get('postLoading'))

const makeSelectPostFailed = () =>
  createSelector(selectStoreEdit, store => store.get('postFailed'))

const makeSelectStoreManagement = () =>
  createSelector(selectStoreEdit, store => store.get('management'))

const makeSelectStoreDetails = () =>
  createSelector(selectStoreEdit, store => store.get('storeDetails'))

const makeSelectRegions = () =>
  createSelector(appSelector, app => app.get('regions'))

const selectRegionEdit = state => state.get('regionEdit')

const makeSelectRegionManagement = () =>
  createSelector(selectRegionEdit, regionEdit => regionEdit.get('management'))

const makeSelectRegionDetails = () =>
  createSelector(selectRegionEdit, regionEdit =>
    regionEdit.get('regionDetails')
  )

const makeSelectRegion = () =>
  createSelector(
    selectRegionEdit,
    region => (region ? region.toJS() : region)
  )

const makeSelectRegionEditFailed = () =>
  createSelector(selectRegionEdit, regionEdit => regionEdit.get('failed'))

const makeSelectRegionEditLoading = () =>
  createSelector(selectRegionEdit, regionEdit => regionEdit.get('loading'))

const makeSelectRegionFound = () =>
  createSelector(selectRegionEdit, regionEdit =>
    regionEdit.get('regionFound')
  )

//
const makeSelectFetchAndUpsertStoreLoading = storeId =>
  createSelector(appSelector, app =>
    app.get('storeUpsertLoading').get(storeId.toString())
  )

const makeSelectFetchAndUpsertStoreFailed = storeId =>
  createSelector(appSelector, app =>
    app.get('storeUpsertFailed').get(storeId.toString())
  )

//
const makeSelectFetchAndUpsertGeometryLoading = storeId =>
  createSelector(appSelector, app =>
    app.get('geometryUpsertLoading').get(storeId.toString())
  )

const makeSelectFetchAndUpsertGeometryFailed = storeId =>
  createSelector(appSelector, app =>
    app.get('geometryUpsertFailed').get(storeId.toString())
  )

//
const selectBaseSnByPuckSn = puckSn =>
  createOrmSelector(orm, session => {
    try {
      const baseId = (session.SecurityDevice.all()
        .filter(({ serialNumber }) => serialNumber === puckSn)
        .toRefArray() || [])[0].baseId
      if (!baseId) return undefined
      const baseSN = session.SecurityDevice.withId(baseId).ref.serialNumber
      return baseSN
    } catch (e) {
      return undefined
    }
  })

const makeSelectBaseSnByPuckSn = puckSn =>
  createSelector(dbStateSelector, ormState =>
    selectBaseSnByPuckSn(puckSn)(ormState)
  )

const selectHubsReducer = state => state.get('hubsReducer')

const makeSelectHubByStore = storeId =>
  createSelector(selectHubsReducer, state => {
    const hubs = state.get('hubs')
    if (!hubs || !storeId) return undefined
    return Object.values(hubs).find(hub => hub.storeId === storeId)
  })

const makeSelectHubByStoreExist = storeId =>
  createSelector(selectHubsReducer, state => {
    const hubs = state.get('hubs')
    if (!hubs || !storeId) return false
    return !!Object.values(hubs).find(hub => hub.storeId === storeId)
  })

const makeSelectHub = hubIdentifier =>
  createSelector(selectHubsReducer, state => {
    const hubs = state.get('hubs')
    if (!hubs) return undefined
    return hubs.hasOwnProperty(hubIdentifier) ? hubs[hubIdentifier] : undefined // eslint-disable-line
  })

const makeSelectIsHubFetchingByStoreId = storeId =>
  createSelector(selectHubsReducer, state => {
    const hubsLoadingStatuses = state.get('hubsLoadingStatuses')
    if (!hubsLoadingStatuses) return false
    return hubsLoadingStatuses.hasOwnProperty(storeId) // eslint-disable-line
      ? hubsLoadingStatuses[storeId].loading
      : false
  })

const makeSelectIsHubFetchErrorByStoreId = storeId =>
  createSelector(selectHubsReducer, state => {
    const hubsLoadingStatuses = state.get('hubsLoadingStatuses')
    if (!hubsLoadingStatuses) return undefined
    return hubsLoadingStatuses.hasOwnProperty(storeId) // eslint-disable-line
      ? hubsLoadingStatuses[storeId].error
      : undefined
  })

const makeSelectPingStatusLoading = () =>
  createSelector(selectHubsReducer, state => state.get('pingStatusLoading'))

const makeSelectPingStatusError = () =>
  createSelector(selectHubsReducer, state => state.get('pingStatusError'))

const makeSelectHubStatusLoading = () =>
  createSelector(selectHubsReducer, state => state.get('hubStatusLoading'))

const makeSelectHubStatusError = () =>
  createSelector(selectHubsReducer, state => state.get('hubStatusError'))

// Note toObject() keeps members immutable and preserves instance methods
// like humanize()
// Don't imagine we'll need to instantiate this more than once.
const assignableRolesSelector = createSelector(
  state => state.get('assignableRoles'),
  substate => substate.toObject()
)

export {
  makeSelectLocation,
  makeSelectData,
  makeSelectIsMtiAdmin,
  makeSelectMe,
  makeSelectMeLoading,
  makeSelectPersonae,
  makeSelectPersonaeLoading,
  appSelector,
  makeSelectImages,
  makeSelectAppRegions,
  makeSelectMtiPermissionAccessData,
  makeSelectHasMtiRoleAnyResource,
  makeSelectHasMtiRole,
  makeSelectMtiPermissionAnyResource,
  makeSelectMtiPermission,
  makeSelectMtiPermissionResources,
  makeSelectError,
  selectUserError,
  makeSelectPermissions,
  makeSelectIsLoading,
  makeSelectToken,
  selectToken,
  tokenSelector, // for backwards compatibility
  makeSelectTokenId,
  makeSelectUserId,
  makeSelectTaggings,
  makeSelectTags,
  makeSelectTagsEditIsLoading,
  makeSelectRegions,
  makeSelectEditedTaggings,
  // Load Organization
  makeSelectOrganizationLoading,
  makeSelectOrganizationDetails,
  makeSelectOrganizationError,
  makeSelectOrganization,
  makeSelectOrganizationId,
  selectOrganizationID,
  makeSelectSubdomain,
  organizationDetailsSelector,
  organizationLoadedSelector,
  // Edit Organization
  makeSelectOrganizationEdit,
  makeSelectOrganizationPatchLoading,
  makeSelectOrganizationPatchFailed,
  makeSelectOrganizationManagement,
  //
  makeSelectSecurityDeviceIdentifySerialNumber,
  makeSelectPosition,
  makeSelectSecurityDevice,
  makeSelectStoreIsInDefultFloorMode,
  makeSelectStoreIsInDefultAreaMode,
  makeSelectKeys,
  makeSelectKeysFailed,
  makeSelectKeysLoading,
  makeSelectUser,
  userEditSelector,
  selectIsEditingUser,
  makeSelectUserManagement,
  makeSelectUserDetails,
  makeSelectUserEditFailed,
  makeSelectUserEditLoading,
  makeSelectSchedule,
  makeSelectScheduleManagement,
  makeSelectScheduleDetails,
  makeSelectScheduleEditFailed,
  makeSelectScheduleEditLoading,
  makeSelectStoreEdit,
  makeSelectPatchFailed,
  makeSelectPatchLoading,
  makeSelectPostFailed,
  makeSelectPostLoading,
  makeSelectStoreManagement,
  makeSelectStoreDetails,
  makeSelectSubscribedTopic,
  makeSelectRegion,
  makeSelectRegionManagement,
  makeSelectRegionDetails,
  makeSelectRegionEditFailed,
  makeSelectRegionEditLoading,
  makeSelectRegionFound,
  //
  makeSelectFetchAndUpsertStoreLoading,
  makeSelectFetchAndUpsertStoreFailed,
  //
  makeSelectFetchAndUpsertGeometryLoading,
  makeSelectFetchAndUpsertGeometryFailed,
  //
  makeSelectBaseSnByPuckSn,
  //
  makeSelectPingStatusLoading,
  makeSelectPingStatusError,
  makeSelectSaltToken,
  makeSelectHubByStore,
  makeSelectIsHubFetchErrorByStoreId,
  makeSelectIsHubFetchingByStoreId,
  makeSelectHub,
  makeSelectHubStatusError,
  makeSelectHubStatusLoading,
  makeSelectHubByStoreExist,
  //
  makeSelectSelectedSecuredProduct,
  makeSelectSecuredProductModalTypeToShow,
  makeSelectPatchSecuredProductFailed,
  makeSelectPatchSecuredProductLoading,
  //
  assignableRolesSelector,
  selectStore,
}
