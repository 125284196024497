import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import moment from 'moment'
import ConfirmModal from 'containers/ModalContainer/ConfirmModal'
import { ActionTypes } from '../App/constants'

import {
  makeSelectMtiPermission,
  makeSelectScheduleManagement,
  makeSelectScheduleDetails,
  makeSelectScheduleEditFailed,
  makeSelectScheduleEditLoading,
} from '../App/selectors'
import {
  openModal,
  closeModal,
  notifyModal,
  editSchedule,
  clearSchedule,
  postSchedule,
  deleteSchedule,
  patchSchedule,
  resetScheduleManagement,
} from '../App/actions'
import ScheduleModal from './ScheduleModal'
import CreateEditScheduleModal from './CreateEditScheduleModal'
import { scheduleDateFormat } from './utils'

const permText = (text) =>
  `You don't have appropriate rights to access ${text}.\nPlease contact your store manager.`

export class ScheduleManagement extends React.PureComponent {
  state = {
    isNeeded: false,
  }

  scheduleEditLoading = () => this.props.scheduleEditLoading

  componentDidUpdate(prevProps) {
    const {
      scheduleManagement,
      scheduleDetails,
      resetScheduleManagement,
      scheduleEditFailed,
      scheduleEditLoading,
    } = this.props
    if (scheduleManagement === ActionTypes.SCHEDULE_DETAILS) {
      this.openScheduleDetailsModal(scheduleDetails)
      resetScheduleManagement()
      return
    }

    if (scheduleManagement === ActionTypes.CREATE_SCHEDULE) {
      this.openCreateScheduleModal()
      resetScheduleManagement()
      return
    }

    if (
      !scheduleEditFailed &&
      !scheduleEditLoading &&
      prevProps.scheduleEditLoading &&
      this.createScheduleModal
    ) {
      this.createScheduleModal.close()
    }

    if (scheduleEditFailed && this.createScheduleModal) {
      this.createScheduleModal.setServerValidity(scheduleEditFailed)
    }
  }

  openScheduleDetailsModal = (schedule) => {
    const {
      hasShowSchedulePermission,
      hasCreateSchedulePermission,
      hasEditSchedulePermission,
      hasDestroySchedulePermission,
      openModal,
      closeModal,
      clearSchedule,
      deleteSchedule,
      storeId,
    } = this.props
    if (
      this.noPermission(hasShowSchedulePermission, permText('View Schedule'))
    ) {
      return
    }

    clearSchedule()
    openModal({
      id: 'schedule',
      type: 'bootstrap',
      content: (
        <ScheduleModal
          id="schedule"
          schedule={schedule}
          hasCreateSchedulePermission={hasCreateSchedulePermission}
          isScheduleEditLocked={!hasEditSchedulePermission}
          title={scheduleDateFormat(schedule.effectiveOn)}
          dangerText="DELETE SCHEDULE"
          confirmText="EDIT SCHEDULE"
          onDanger={() =>
            setTimeout(() => {
              if (
                this.noPermission(
                  hasDestroySchedulePermission,
                  permText('Delete Schedule')
                )
              ) {
                return
              }
              openModal({
                id: 'delete',
                type: 'bootstrap',
                appearance: 'modal-sm',
                content: (
                  <ConfirmModal
                    id="delete"
                    cancelText="CANCEL"
                    confirmText="DELETE SCHEDULE"
                    onClose={() => closeModal({ id: 'delete' })}
                    onConfirm={() =>
                      deleteSchedule({ id: schedule.id, storeId })
                    }
                  />
                ),
              })
            }, 500)
          }
          onConfirm={() => {
            closeModal({ id: 'schedule' })
            clearSchedule()
            setTimeout(() => this.openEditExistingScheduleModal(schedule), 500)
          }}
          onClose={() => {
            closeModal({ id: 'schedule' })
          }}
        />
      ),
    })
  }

  noPermission = (hasPermission, text) => {
    const { notifyModal } = this.props
    if (!hasPermission) notifyModal(text)
    return !hasPermission
  }

  openEditExistingScheduleModal = (schedule) => {
    const {
      hasEditSchedulePermission,
      openModal,
      closeModal,
      editSchedule,
      patchSchedule,
      storeId,
      effectiveOnError,
    } = this.props
    if (
      this.noPermission(hasEditSchedulePermission, permText('Edit Schedule'))
    ) {
      return
    }

    openModal({
      id: 'edit-schedule',
      type: 'bootstrap',
      content: (
        <CreateEditScheduleModal
          ref={(ref) => (this.createScheduleModal = ref)}
          id="edit-schedule"
          schedule={schedule}
          onChange={(o) => editSchedule(o)}
          title="Edit Schedule"
          confirmText="SAVE SCHEDULE"
          onClose={() => closeModal({ id: 'edit-schedule' })}
          onConfirm={() => patchSchedule({ id: schedule.id, storeId })}
          effectiveOnError={effectiveOnError}
          scheduleEditLoading={this.scheduleEditLoading}
        />
      ),
    })
  }

  openCreateScheduleModal = () => {
    const {
      hasCreateSchedulePermission,
      openModal,
      closeModal,
      postSchedule,
      clearSchedule,
      editSchedule,
      storeId,
      effectiveOnError,
    } = this.props
    if (
      this.noPermission(
        hasCreateSchedulePermission,
        permText('Create Schedule')
      )
    ) {
      return
    }
    clearSchedule()
    const schedule = { effectiveOn: moment() }
    editSchedule({ effectiveOn: schedule.effectiveOn })
    openModal({
      id: 'add-schedule',
      type: 'bootstrap',
      content: (
        <CreateEditScheduleModal
          ref={(ref) => (this.createScheduleModal = ref)}
          id="add-schedule"
          schedule={schedule}
          onChange={(o) => editSchedule(o)}
          title="Add Schedule"
          confirmText="CREATE SCHEDULE"
          onClose={() => closeModal({ id: 'add-schedule' })}
          onConfirm={() => postSchedule({ storeId })}
          effectiveOnError={effectiveOnError}
          scheduleEditLoading={this.scheduleEditLoading}
        />
      ),
    })
  }

  render() {
    if (this.state.isNeeded) {
      return null
    }
    return <div />
  }
}

ScheduleManagement.propTypes = {
  scheduleManagement: PropTypes.string,
  scheduleDetails: PropTypes.object,
  scheduleEditLoading: PropTypes.bool,
  scheduleEditFailed: PropTypes.object,
  hasCreateSchedulePermission: PropTypes.bool,
  hasShowSchedulePermission: PropTypes.bool,
  hasEditSchedulePermission: PropTypes.bool,
  hasDestroySchedulePermission: PropTypes.bool,
  notifyModal: PropTypes.func,
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
  postSchedule: PropTypes.func,
  patchSchedule: PropTypes.func,
  clearSchedule: PropTypes.func,
  deleteSchedule: PropTypes.func,
  editSchedule: PropTypes.func,
  resetScheduleManagement: PropTypes.func,
  storeId: PropTypes.string.isRequired,
  effectiveOnError: PropTypes.func,
}

const mapStateToProps = createStructuredSelector({
  scheduleManagement: makeSelectScheduleManagement(),
  scheduleDetails: makeSelectScheduleDetails(),
  scheduleEditLoading: makeSelectScheduleEditLoading(),
  scheduleEditFailed: makeSelectScheduleEditFailed(),
  hasCreateSchedulePermission: (state, ownProps) =>
    makeSelectMtiPermission('Schedule: Create New Objects', ownProps.storeId)(
      state,
      ownProps
    ),
  hasShowSchedulePermission: (state, ownProps) =>
    makeSelectMtiPermission('Schedule: Read/Show Details', ownProps.storeId)(
      state,
      ownProps
    ),
  hasEditSchedulePermission: (state, ownProps) =>
    makeSelectMtiPermission(
      'Schedule: Update Details of Existing Records',
      ownProps.storeId
    )(state, ownProps),
  hasDestroySchedulePermission: (state, ownProps) =>
    makeSelectMtiPermission(
      'Schedule: Destroy Existing Records',
      ownProps.storeId
    )(state, ownProps),
})

const mapDispatchToProps = {
  notifyModal,
  openModal,
  closeModal,
  postSchedule,
  patchSchedule,
  clearSchedule,
  deleteSchedule,
  editSchedule,
  resetScheduleManagement,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)
export default compose(withConnect)(ScheduleManagement)
