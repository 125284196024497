import React from 'react'
import { Icon } from '../../ListOfLinks'
import cubeWhiteIcon from '../../../images/cube_white.png'

const selector = '#appDrawer'

function getDrawer() {
  const drawer = $(selector).data('bmd.drawer') // eslint-disable-line
  if (drawer) {
    return drawer
  }

  $(selector).bootstrapMaterialDesign() // eslint-disable-line
  return getDrawer()
}

function toggle() {
  const drawer = getDrawer()

  if (drawer) {
    drawer.toggle()
  }
}

export function closeDrawer() {
  const drawer = getDrawer()

  if (drawer) {
    drawer.hide()
  }
}

const DrawerButton = () => {
  const pathname = window.location.pathname

  // /organizations/462/MTI%20QA - pass
  // /organizations/462/MTI%20QA/regions - not pass
  // /organizations/470/Avery's%20QA%20Testground/users - not pass
  const isOrg = /^\/organizations\/\d+\/[\S][^/]+$/.test(pathname)
  if (pathname === '/' || pathname === '/privacyPolicy' || isOrg) {
    return null
  }

  return (
    <button onClick={toggle} className="navbar-toggler" type="button">
      <Icon src={cubeWhiteIcon} />
    </button>
  )
}

export default DrawerButton
