import React from 'react'
import PropTypes from 'prop-types'
import ReactTable from 'react-table'
import Throttler from '../../utils/throttler'

const DEFAULT_PAGE_SIZE = 20
const THROTTLE_INTERVAL = 500

// Wraps React Table, but manages paging and stuff for the server-side.
export default class ServerManagedTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      pageSize: props.meta?.pageSize || DEFAULT_PAGE_SIZE,
      page: props.meta?.currentPage || 1,
      sortAttribute: props.sortAttribute,
      sortDirection: props.sortDirection,
      filters: props.filters || {},
    }
    this.throttler = new Throttler(this._fetchData, THROTTLE_INTERVAL)
  }

  // Convenience wrapper for the throttled API caller function.
  fetchData = () => this.throttler.call()

  // The ACTUAL API caller function. Not meant to be called directly,
  // but by the fetchData() wrapper.
  _fetchData = () => this.props.loadData(this.state)

  // There's a little funkiness in ReactTable where it will try to
  // re-fetch the data while we're still trying to update the state,
  // resulting in multiple calls, which may return out of order. This
  // just wraps setState() and fetchData() with a little anti-bounce
  // parameter to keep it from getting ahead of itself.
  updateQueryParams = newData => {
    this.setState(newData, () => {
      this.fetchData()
    })
  }

  handleSortedChange = newSorted => {
    const { id, desc } = newSorted[0]
    const { transformSorts } = this.props
    const newData = {
      sortAttribute: transformSorts ? transformSorts(id) : id,
      sortDirection: desc ? 'desc' : 'asc',
    }
    this.updateQueryParams(newData)
  }

  handlePageSizeChange = (pageSize, pageIndex) => {
    const newData = {
      page: pageIndex + 1, // ReactTable is 0-based. Kaminari is 1-based.
      pageSize,
    }
    this.updateQueryParams(newData)
  }

  handlePageChange = pageIndex => {
    const newData = {
      page: pageIndex + 1, // ReactTable is 0-based. Kaminari is 1-based.
    }
    this.updateQueryParams(newData)
  }

  defaultTransformFilters = (id, value) => ({ [id]: value })

  transformedFilters = filters => {
    const { transformFilters } = this.props
    const fn = transformFilters || this.defaultTransformFilters
    return filters.reduce(
      (acc, val) => Object.assign(acc, fn(val.id, val.value)),
      {},
    )
  }

  handleFilteredChange = filters => {
    const newData = {
      filters: this.transformedFilters(filters),
    }
    this.updateQueryParams(newData)
  }

  render() {
    const {
      className,
      style,
      data,
      columns,
      meta,
      onRowClick,
    } = this.props
    const currentPage = (meta?.currentPage || 1) - 1
    const pageSize = (meta?.perPage || DEFAULT_PAGE_SIZE)
    const totalPages = Math.ceil(meta?.total / meta?.perPage) || 0

    const onClick = (state, rowInfo) => ({
      onClick: (e, handleOriginal) => {
        if (onRowClick) {
          onRowClick(rowInfo.original)
        }
        if (handleOriginal) {
          handleOriginal()
        }
      },
    })

    return (
      <ReactTable
        className={className}
        style={style}
        data={data}
        columns={columns}

        manual
        pages={totalPages}
        pageSize={pageSize}
        page={currentPage}

        onSortedChange={this.handleSortedChange}
        onPageChange={this.handlePageChange}
        onPageSizeChange={this.handlePageSizeChange}
        onFilteredChange={this.handleFilteredChange}

        getTdProps={onClick}
      />
    )
  }
}

ServerManagedTable.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  data: PropTypes.array,
  meta: PropTypes.shape({
    currentPage: PropTypes.number,
    perPage: PropTypes.number,
    total: PropTypes.number,
  }),
  columns: PropTypes.array,
  loadData: PropTypes.func,
  transformFilters: PropTypes.func,
  transformSorts: PropTypes.func,
  sortAttribute: PropTypes.string,
  sortDirection: PropTypes.oneOf(['asc', 'desc']),
  filters: PropTypes.array,
  onRowClick: PropTypes.func,
}
