/**
 * Stores Sagas
 */
import { call, put, select } from 'redux-saga/effects'
import { modelActionCreators as mac } from 'mti-jsclient-shared'
import { getStores, orgIdUrl } from '../../api'
import {
  tokenSelector,
  makeSelectIsMtiAdmin,
} from '../../containers/App/selectors'
import { storeImages } from '../../containers/App/actions'
import { selectStores, selectLoading } from './selectors'
import {
  fetchStoresPending,
  fetchStoresFulfilled,
  fetchStoresFailed,
} from './actions'
import { errorToast } from '../../utils/utils'

export function* upsertFloorData(data) {
  const { floors, areas, fixtures, images } = data
  yield images && put(storeImages(images))
  yield floors && put(mac.upsertFloors(floors))
  yield areas && put(mac.upsertAreas(areas))
  yield fixtures && put(mac.upsertFixtures(fixtures))
}

/**
 * Load Stores
 *
 * Reusable saga for fetching data required for displaying
 * - list of stores,
 * - store details modal dialog with regions and tags,
 * - creation of navigation link to store canvas.
 *
 * Data will be stored in ORM and in redux store.
 * Data can be selected by /StoresPage/selectors -> makeSelectStores
 */
export function* loadStoresData() {
  const token = yield select(tokenSelector)
  // TODO: We will use include property >> // TODO: to create proper navigation link we should get ids and names of floors and areas
  // of course it has drawbacks, loading speed will be significantly reduced if stores > 10
  // please consider navigation async creation or other ways to reduce the loading time
  // const floors = yield all(
  //   (stores || []).map(({ id }) => { console.log('id', id); return call(getFloors, token, id) })
  // )
  const data = yield call(getStores, token, 'address,region,floors')
  const { stores, addresses, floors } = data
  yield stores && put(mac.upsertStores(stores))
  yield addresses && put(mac.upsertStoreAddresses(addresses))
  yield floors && put(mac.upsertFloors(floors))
}

export function* loadStores() {
  const token = yield select(tokenSelector)
  if (!token) {
    console.log('Skipping get Stores')
    return
  } else {
    // console.log('get Stores')
  }
  const isMtiAdmin = yield select(makeSelectIsMtiAdmin())
  if (isMtiAdmin && !orgIdUrl()) return
  const isFetchStoresPending = yield select(selectLoading)
  if (isFetchStoresPending) return
  const storesLoaded = yield select(selectStores)
  const isStoresLoaded = storesLoaded && storesLoaded.length > 1
  try {
    if (!isStoresLoaded) {
      yield put(fetchStoresPending())
    }

    yield call(loadStoresData)
    yield put(fetchStoresFulfilled())
  } catch (error) {
    console.error(error)
    yield put(fetchStoresFailed(error))
    errorToast('Load stores failed')
  }
}
