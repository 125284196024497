import React from 'react'
import { alphabeticalCompare } from 'mti-jsclient-shared'
/**
 * Status filter for the Keys react-table
 *
 * @param filter
 * @param row
 * @returns {boolean}
 */
export function keyStatusFilter(filter, row) {
  const status = ((row || {})._original || {}).status // eslint-disable-line
  if (filter.value === 'all') {
    return true
  }
  if (filter.value === 'true') {
    return status === 1
  }
  return status === 0
}

export function keyUserNameFilter(filter, row) {
  const testString = ((row || {})._original || {}).userName
  const reg = new RegExp(filter.value, 'i')
  return reg.test(testString)
}

export function keyUserNameAndSerial(value) {
  const obj = value || {}
  return `${obj.userName}-${obj.serialNumber}`
}
/**
 * Status component for filtering of the Keys react-table
 *
 * @param filter
 * @method onChange
 * @returns object
 */
export function keyStatusComponent(filter, onChange) {
  return (
    <select
      onChange={(event) => onChange(event.target.value)}
      style={{ width: '100%' }}
      value={filter ? filter.value : 'all'}
    >
      <option value="all">Show All</option>
      <option value="true">Activated</option>
      <option value="false">Deactivated</option>
    </select>
  )
}

/**
 * User name sort for the Keys react-table
 *
 * @param a
 * @param b
 * @returns number
 */
export function keyUserSort(argA, argB) {
  const undef = ' '
  const a = (argA || {}).userName || undef
  const b = (argB || {}).userName || undef

  if (a === undef && b === undef) {
    return 0
  } else if (a === undef && b !== undef) {
    return 1
  } else if (a !== undef && b === undef) {
    return -1
  }

  const strA = keyUserNameAndSerial(argA)
  const strB = keyUserNameAndSerial(argB)

  return alphabeticalCompare(strA, strB)
}
