import { createSelector as createOrmSelector } from 'redux-orm'
import { orm, getMissingSecuredProductsByStore } from 'mti-jsclient-shared'

const dbStateSelector = (state) => state.get('orm')

const selectStoreId = (state, storeId) => storeId

const selectMissingSecuredProductsForStore = createOrmSelector(
  orm,
  dbStateSelector,
  selectStoreId,
  (session, storeId) => {
    try {
      if (storeId) {
        return getMissingSecuredProductsByStore(session, storeId)
      }
      return []
    } catch (e) {
      console.log(e)
      return []
    }
  }
)

const selectMissingSecuredProductsByFloorId = createOrmSelector(
  orm,
  dbStateSelector,
  selectMissingSecuredProductsForStore,
  selectStoreId,
  (session, securedProducts, storeId) => {
    let securedProductsByFloorId = {}

    if (securedProducts.length) {
      const store = session.Store.withId(storeId)

      store.floorIds.toModelArray().forEach((floor) => {
        let fixtureSecuredProducts = []
        floor.areaIds.toModelArray().forEach((area) => {
          area.fixtureIds.toModelArray().forEach((fixture) => {
            fixtureSecuredProducts.push(
              getSecuredProductsForFixture(fixture, securedProducts)
            )
          })
        })
        securedProductsByFloorId[floor.id] = fixtureSecuredProducts.flat()
      })
    }
    return securedProductsByFloorId
  }
)

const selectMissingSecuredProductsByAreaId = createOrmSelector(
  orm,
  dbStateSelector,
  selectMissingSecuredProductsForStore,
  (state, storeId, floorId) => floorId, //state and storeId arguments are used by `selectMissingSecuredProductsForStore`
  (session, securedProducts, floorId) => {
    let securedProductsByAreaId = {}

    if (securedProducts.length) {
      const floor = session.Floor.withId(floorId)

      floor.areaIds.toModelArray().forEach((area) => {
        let fixtureSecuredProducts = []
        area.fixtureIds.toModelArray().forEach((fixture) => {
          fixtureSecuredProducts.push(
            getSecuredProductsForFixture(fixture, securedProducts)
          )
        })
        securedProductsByAreaId[area.id] = fixtureSecuredProducts.flat()
      })
    }
    return securedProductsByAreaId
  }
)

const selectMissingSecuredProductsByFixtureId = createOrmSelector(
  orm,
  dbStateSelector,
  selectMissingSecuredProductsForStore,
  (state, storeId, areaId) => areaId, //state and storeId arguments are used by `selectMissingSecuredProductsForStore`
  (session, securedProducts, areaId) => {
    if (securedProducts.length) {
      let securedProductsByFixtureId = {}
      const area = session.Area.withId(areaId)

      area.fixtureIds.toModelArray().forEach((fixture) => {
        securedProductsByFixtureId[fixture.id] = getSecuredProductsForFixture(
          fixture,
          securedProducts
        )
      })

      return securedProductsByFixtureId
    }
    return {}
  }
)

const getSecuredProductsForFixture = (fixture, securedProducts) => {
  const positionIds = fixture.positionIds.toRefArray().map((pos) => pos.id)
  return securedProducts.filter((securedProduct) =>
    positionIds.includes(securedProduct.positionId)
  )
}

const selectStoreHasMissingSecuredProducts = createOrmSelector(
  orm,
  dbStateSelector,
  selectMissingSecuredProductsForStore,
  (session, securedProducts) => securedProducts && securedProducts.length > 0
)

export {
  selectMissingSecuredProductsForStore,
  selectMissingSecuredProductsByFixtureId,
  selectMissingSecuredProductsByAreaId,
  selectMissingSecuredProductsByFloorId,
  selectStoreHasMissingSecuredProducts,
}
