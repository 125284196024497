/*
 *
 * Organization actions
 *
 */

import { ActionTypes } from '../constants'

export function loadOrganization() {
  return {
    type: ActionTypes.LOAD_ORGANIZATION_REQUEST,
  }
}

export function organizationLoaded(organization) {
  return {
    type: ActionTypes.LOAD_ORGANIZATION_SUCCESS,
    payload: organization,
  }
}

export function organizationLoadError(error) {
  return {
    type: ActionTypes.LOAD_ORGANIZATION_FAILURE,
    payload: error,
  }
}
