import { createSelector } from 'reselect'

const selectExceptions = (state) => state.get('exceptions')

const makeSelectExceptions = (storeId) =>
  createSelector(selectExceptions, (exceptions) => {
    let exceptionsArr = []
    const obj = exceptions.get('exceptions').toJS()
    if (storeId) {
      const storeExceptions = obj[(storeId || -1).toString()]
      if (!storeExceptions) return []
      return exceptionsArr.concat(storeExceptions)
    }
    Object.keys(obj).forEach((key) => {
      const storeExceptions = obj[key]
      exceptionsArr = exceptionsArr.concat(storeExceptions)
    })
    return exceptionsArr
  })

const makeSelectAsyncLoadingStatus = () =>
  createSelector(selectExceptions, (exceptions) => ({
    total: exceptions.get('storesToLoadCount'),
    currentName: (exceptions.get('currentStoreLoading') || {}).name,
    currentNumber: exceptions.get('currentIndexLoading') + 1,
  }))

const makeSelectAsyncLoadingCanceling = () =>
  createSelector(selectExceptions, (exceptions) =>
    exceptions.get('asyncLoadingCanceling')
  )

const makeSelectAsyncLoadingErrors = () =>
  createSelector(selectExceptions, (exceptions) => {
    let errors = []
    const obj = exceptions.get('errors').toJS()
    Object.keys(obj).forEach((key) => {
      const storeError = obj[key]
      errors = errors.concat(storeError)
    })
    return errors
  })

export {
  makeSelectExceptions,
  makeSelectAsyncLoadingStatus,
  makeSelectAsyncLoadingCanceling,
  makeSelectAsyncLoadingErrors,
}
