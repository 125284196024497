import { userKey } from '../../containers/App/localAuth'

let login
let logout

const lastStartKey = 'MTI:lastStart'
const lastStopKey = 'MTI:lastStop'

export const handleBeforeunload = () => {
  const lastStart = localStorage.getItem(lastStartKey)
  const lastStartSegments = lastStart.split(':').map((v) => parseInt(v, 10))
  const instance = lastStartSegments[1]
  const newInstance = instance > 0 ? instance - 1 : 0
  localStorage.setItem(
    lastStartKey,
    [lastStartSegments[0], newInstance].join(':')
  )
  localStorage.setItem(lastStopKey, JSON.stringify(new Date().valueOf()))
}

export const handleStorageEvent = (event) => {
  const keyPrefix = 'MTI_AUTH_SYNC'

  if (event.key === userKey && event.newValue) {
    if (typeof login === 'function') {
      login()
      return
    }
  }

  if (event.key && event.key.indexOf(keyPrefix) === 0) {
    if (event.oldValue === event.newValue) {
      return
    }

    switch (event.newValue) {
      case 'LOGIN':
        if (typeof login === 'function') {
          login()
        }
        break
      case 'LOGOUT':
        if (typeof logout === 'function') {
          logout()
        }
        break
      default:
        break
    }
  }
}

export const onLogin = (callback) => {
  login = callback
}

export const onLogout = (callback) => {
  logout = callback
}

export const logoutUser = () => {
  const stop = localStorage.getItem(lastStopKey)
  const startString = localStorage.getItem(lastStartKey)
  const startSegments = startString.split(':').map((v) => parseInt(v, 10))
  const start = startSegments[0]
  const instance = startSegments[1]

  if (!stop) {
    return false
  }

  if (instance > 1) {
    return false
  }

  // console.log(start - stop)
  return start - stop > 1000
}

export const syncLogin = () => {
  localStorage.setItem('MTI_AUTH_SYNC', 'LOGIN')
}

export const syncLogout = () => {
  localStorage.setItem('MTI_AUTH_SYNC', 'LOGOUT')
}

export function updateInstance() {
  var oldStart = localStorage.getItem(lastStartKey)
  var instance = 0
  if (oldStart) {
    var segments = oldStart.split(':')
    instance = segments[1] && parseInt(segments[1], 10)
  }
  localStorage.setItem(lastStartKey, [segments[0], instance + 1].join(':'))

  if (logoutUser()) {
    localStorage.removeItem(userKey)
  }
}
