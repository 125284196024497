import React from 'react'
import PropTypes from 'prop-types'
import EditStoreTagsScreen from './index.screen'

class EditStoreTagsModal extends React.Component {
  setServerValidity(error) {
    if (this.editStoreTagsModal) {
      this.editStoreTagsModal.setServerValidity(error)
    }
  }

  close() {
    if (this.editStoreTagsModal) this.editStoreTagsModal.close()
  }

  setLoading(loading) {
    if (this.editStoreTagsModal) this.editStoreTagsModal.setLoading(loading)
  }

  render() {
    const {
      id,
      storeId,
      taggings,
      tags,
      onChange,
      title,
      onClose,
      onConfirm,
    } = this.props

    return (
      <EditStoreTagsScreen
        ref={(ref) => (this.editStoreTagsModal = ref)}
        id={id}
        storeId={storeId}
        taggings={taggings}
        tags={tags}
        onChange={onChange}
        title={title}
        onClose={onClose}
        onConfirm={onConfirm}
      />
    )
  }
}

EditStoreTagsModal.propTypes = {
  id: PropTypes.string.isRequired,
  storeId: PropTypes.number.isRequired,
  taggings: PropTypes.array.isRequired,
  tags: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
}

export default EditStoreTagsModal
