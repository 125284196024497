/*
 *
 * TemplateFixture actions
 *
 */

import { ActionTypes } from './constants'

/**
 * Clear Cached Data
 *
 * @returns {Object}
 */
export function clearCachedData() {
  return {
    type: ActionTypes.TEMPLATE_FIXTURE_CLEAR_CACHED_DATA,
    payload: null,
  }
}

/**
 * Prepare template fixture canvas
 *
 * @returns {Object}
 */
export function prepareTemplateFixture(screen, isStatic, tId) {
  return {
    type: ActionTypes.PREPARE_TEMPLATE_FIXTURE_CANVAS,
    payload: {
      isStatic,
      screen,
      tId,
    },
  }
}

/**
 * Fetch Canvas Fulfilled
 *
 * @returns {Object}
 */
export function fetchTemplateFixtureCanvasFulfilled(
  data,
  selectedObject,
  isStatic
) {
  return {
    type: ActionTypes.FETCH_TEMPLATE_FIXTURE_CANVAS_FULFILLED,
    payload: {
      canvas: data,
      selectedObject,
      isStatic,
    },
  }
}

/**
 * Save template fixture
 *
 * @returns {Object}
 */
export function saveTemplateFixture(payload) {
  return {
    type: ActionTypes.SAVE_TEMPLATE_FIXTURE_CANVAS,
    payload,
  }
}

/**
 * Template saved
 *
 * @returns {Object}
 */
export function templateSaved(payload) {
  return {
    type: ActionTypes.TEMPLATE_FIXTURE_SAVED,
    payload,
  }
}

/**
 * Store fixture objects prototypes
 *
 * @param prototypes
 * @returns {Object}
 */
export function storePrototypes(prototypes) {
  return {
    type: ActionTypes.STORE_TEMPLATE_FIXTURE_PROTOTYPES,
    payload: prototypes,
  }
}

/**
 * Store Params
 *
 * @returns {Object}
 */
export function storeParams(params) {
  return {
    type: ActionTypes.STORE_PARAMS,
    payload: params,
  }
}

/**
 * Set template fixture error
 *
 * @param error
 * @returns {Object}
 */
export function setTemplateFixtureCanvasError(error) {
  return {
    type: ActionTypes.TEMPLATE_FIXTURE_CANVAS_ERROR,
    payload: error,
  }
}

/**
 * Set Loading
 *
 * @returns {Object}
 */
export function setLoading(loading) {
  return {
    type: ActionTypes.STORE_TEMPLATE_FIXTURE_LOADING,
    payload: loading,
  }
}

/**
 * Update Inheritance
 *
 * @returns {Object}
 */
export function updateInheritance(inheritance) {
  return {
    type: ActionTypes.TEMPLATE_FIXTURE_UPDATE_INHERITANCE,
    payload: inheritance,
  }
}

/**
 * Update cloneable copyable
 *
 * @returns {Object}
 */
export function updateCloneableCopyable(cloneableCopyable) {
  return {
    type: ActionTypes.TEMPLATE_FIXTURE_UPDATE_CC,
    payload: cloneableCopyable,
  }
}

/**
 * Template Fixture Changed
 *
 * @returns {Object}
 */
export function templateFixtureChanged(payload) {
  return {
    type: ActionTypes.TEMPLATE_FIXTURE_CHANGED,
    payload,
  }
}

/**
 * Template Fixture Name Changed
 *
 * @returns {Object}
 */
export function templateFixtureNameChanged(payload) {
  return {
    type: ActionTypes.TEMPLATE_FIXTURE_NAME_CHANGED,
    payload,
  }
}

/**
 * Area Canvas Fixture Name Toggled
 *
 * @returns {Object}
 */
export function templateFixtureNameToggled(payload) {
  return {
    type: ActionTypes.TEMPLATE_FIXTURE_NAME_TOGGLED,
    payload,
  }
}

/**
 * Area Canvas Figure Begin Transformation
 *
 * @returns {Object}
 */
export function templateFixtureBeginTransformation(payload) {
  return {
    type: ActionTypes.TEMPLATE_FIXTURE_BEGIN_TRANSFORMATION,
    payload,
  }
}

/**
 * Area Canvas Figure Size Changed
 *
 * @returns {Object}
 */
export function templateFixtureSizeChanged(payload) {
  return {
    type: ActionTypes.TEMPLATE_FIXTURE_SIZE_CHANGED,
    payload,
  }
}

/**
 * Create Fixture Rule
 *
 * @param {Number} fixtureId
 * @param {Object} rule
 */
export function createFixtureRule(fixtureId, rule) {
  return {
    type: ActionTypes.CREATE_FIXTURE_RULE,
    payload: { fixtureId, rule },
  }
}

/**
 * Edit Fixture Rule
 *
 * @param {Number} fixtureId
 * @param {Object} rule
 */
export function editFixtureRule(fixtureId, rule) {
  return {
    type: ActionTypes.EDIT_FIXTURE_RULE,
    payload: { fixtureId, rule },
  }
}

/**
 * Delete Fixture Rule
 *
 * @param {Number} fixtureId
 * @param {Object} rule
 */
export function deleteFixtureRule(fixtureId, rule) {
  return {
    type: ActionTypes.DELETE_FIXTURE_RULE,
    payload: { fixtureId, rule },
  }
}

/**
 * Delete All Fixture Rules
 *
 * @param {Object} payload
 */
export function deleteAllFixtureRules(payload) {
  return {
    type: ActionTypes.DELETE_ALL_FIXTURE_RULES,
    payload,
  }
}
