import { all, call, put, takeLatest, select } from 'redux-saga/effects'
import { ActionTypes } from './constants'
import {
  updateNodesPending,
  updateNodesFulfilled,
  updateNodesFailed,
} from './actions'
import { runSaltStateAppyFirmware600 } from '../../../api'
import { getFirmwareName } from '../../../utils/utils'
import { makeSelectSaltToken } from '../../App/selectors'

function* updateNodes({ payload }) {
  const { hub: { identifier }, firmware: { name }, versionToIgnore } = payload
  const firmwareName = getFirmwareName(name)
  const saltToken = yield select(makeSelectSaltToken())

  try {
    yield put(updateNodesPending())
    const args = payload.partNumber
      ? `-m ${payload.partNumber} ${firmwareName} ${versionToIgnore}`
      : `-s ${payload.serialNumber} ${firmwareName} ${versionToIgnore}${
        payload.isSecondaryDeviceEnabled ? ' -a' : ''
      }`
    const kwarg = {
      pillar: { args },
    }

    const { return: [response] } = yield call(
      runSaltStateAppyFirmware600,
      saltToken,
      identifier,
      kwarg
    )

    const data = Object.values(response).pop()
    const keys = Object.keys(data)
    const dataAsArray = keys.map((k) => ({ ...data[k], key: k }))

    yield put(updateNodesFulfilled(dataAsArray))
  } catch (error) {
    yield put(updateNodesFailed(error))
  }
}

export default function* root() {
  yield all([yield takeLatest(ActionTypes.UPDATE_NODES, updateNodes)])
}
