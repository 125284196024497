import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const TemplateNameBreadcrumb = ({ match: { url, key, params: { tName } } }) => (
  <Link key={key} className="breadcrumb-item" to={url}>
    {decodeURIComponent(tName)}
  </Link>
)

TemplateNameBreadcrumb.propTypes = {
  match: PropTypes.object,
}

export default TemplateNameBreadcrumb
