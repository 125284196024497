import { ActionTypes } from './constants'

export function fetchMinions() {
  return {
    type: ActionTypes.FETCH_MINIONS,
  }
}

export function fetchMinionsPending() {
  return {
    type: ActionTypes.FETCH_MINIONS_PENDING,
  }
}

export function fetchMinionsFulfilled(minions) {
  return {
    type: ActionTypes.FETCH_MINIONS_FULFILLED,
    payload: { minions },
  }
}

export function fetchMinionsFailed(error) {
  return {
    type: ActionTypes.FETCH_MINIONS_FAILED,
    payload: { error },
  }
}
