import { fromJS } from 'immutable'
import { ActionTypes } from './constants'

const initialState = fromJS({
  orphanedDevicesIsLoading: false,
  orphanedDevicesData: null,
})

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case ActionTypes.LOADING_ORPHANED_DEVICES:
      return state.set('orphanedDevicesIsLoading', payload.isLoading)
    case ActionTypes.ORPHANED_DEVICES_DATA_REDUCER:
      return state.set('orphanedDevicesData', payload)
    default:
      return state
  }
}
