/*
 * Reports Constants
 */

import keyMirror from 'fbjs/lib/keyMirror'

/**
 * @constant {Object} ActionTypes
 * @memberof Constants
 */
export const ActionTypes = keyMirror({
  FETCH_REPORTS_FAILED: undefined,
  FETCH_REPORTS_FULFILLED: undefined,
  FETCH_REPORTS_PENDING: undefined,
  LOAD_REPORTS: undefined,
  LOAD_REPORTS_ASYNC: undefined,
  LOAD_REPORTS_ASYNC_COUNT: undefined,
  LOAD_REPORTS_ASYNC_CURRENT: undefined,
  LOAD_REPORTS_ASYNC_FINISHED: undefined,
  LOAD_REPORTS_ASYNC_CANCEL: undefined,
  REPORTS_CLEAR: undefined,
  CANCEL_LOADING: undefined,
})
