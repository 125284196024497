import { modelActionCreators as mac } from 'mti-jsclient-shared'
import mtiJsclientShared from '../../utils/mtiJsclientShared'
import { all, call, put, takeLatest, select } from 'redux-saga/effects'
import _ from 'lodash'
import {
  getSecurityDevice,
  postPosition,
  patchPosition,
  deletePosition,
  getManufacturers,
  getSecuredProducts,
  assignSecurityDevice,
  assignPortSecuredProduct,
  addSecuredProduct,
  deleteSecuredProduct,
  getTemplate,
} from '../../api'
import { fetchAndUpsert, upsert } from '../App/sagas/ormSaga'
import {
  upsertManufacturers,
  upsertSecuredProducts,
  updateSecuredProducts,
  createDefaultFloorAndArea,
} from '../../containers/App/actions'
import { makeSelectToken } from '../../containers/App/selectors'
import {
  errorToast,
  loadingType as loading,
  successToast,
} from '../../utils/utils'
import {
  filterLayoutPositionGeometry,
  apiFixGeometryResourceId,
} from '../../utils/mtiUtils'
import {
  makeSelectFixtureExist,
  makeSelectFixture,
  makeSelectPositionById,
  makeSelectPrototypes,
} from './selectors'
import {
  makeSelectTemplateExist,
  makeSelectFixtureTemplate,
} from '../TemplatePositions/selectors'
import { ActionTypes } from './constants'
import {
  storePrototypes,
  storeParams,
  postFixtureCanvasPending,
  postFixtureCanvasFinished,
  postFixtureCanvasFailed,
  fetchFixtureCanvasPending,
  fetchFixtureCanvasFulfilled,
  fetchFixtureCanvasFailed,
  autoPlacementFulfilled,
  setLoading,
  loadProductsWithManufacturers,
} from './actions'
import {
  toFabricSync,
  getPrototypes as getStaticPrototypes,
  updateWithSviType,
  addTransparency,
} from './utils'
import { fromFabricPosition } from './utilsSave'
import { getAutoplacement, setTypeBasedOnAspect } from './utilsAutoplacement'
import { getEditPrototypes } from './utilsEdit'

// TODO: The job of this saga should be to check if the fixture,
// position and geometry data needed for the layout page is in the orm
// already, and if not, then request it from the API.
// We can even combine those API requests so that they only save to
// the state once, after all of them have returned, if we want.
// Everything else should be organized in other sagas, or moved to
// selectors or the render logic itself.
export function* loadFixture({ payload }) {
  const {
    screen, // This stores the dimensions of the canvas, which is currently hardcoded to a constant size.
    isStatic, // This represents whether we're in read-only mode or edit mode
    // Just set true value to enable background fetch again
    isBackgroundOrmFetch = false, // TODO This is never set to true (or false) anywhere else in the app.
    selectedId = -1,
    sId: storeId,
    fxId: fixtureId,
  } = payload

  const token = yield select(makeSelectToken())
  try {
    yield put(setLoading(loading.silent))

    // TODO: Fabric loads in the SVGs asynchronously, which means they
    // need to be loaded in via a saga, saved on the redux store,
    // and then loaded into the component via a selector.
    // I think the loading of the prototypes should be done in a
    // separate saga that can be referenced by all pages using this same
    // logic, in addition to the selector that gets them being memoized
    const prototypes = addTransparency(yield getPrototypes())
    yield put(storePrototypes(prototypes))
    yield put(storeParams({ screen, isStatic }))

    // if the fixture, its positions, and their geometry are not yet loaded, fetch them from the API.
    const isFixtureExist = yield select(makeSelectFixtureExist(fixtureId))
    if (!isFixtureExist) {
      yield put(setLoading(loading.visible))
      yield put(fetchFixtureCanvasPending(storeId))
      yield call(fetchAndUpsert, { payload: { sId: storeId } })
    }

    // TODO: This should probably be done in a separate saga, for better organization
    if (isStatic) {
      yield put(loadProductsWithManufacturers(storeId))
    }

    // TODO: The fixture should be a property of the fixture page
    // component and loaded in via a selector. If it's missing, then the
    // component knows to show the loading graphic instead.
    const fixture = yield select(makeSelectFixture(fixtureId))
    try {
      const { parentId: tId } = fixture
      if (tId) {
        if (!(yield select(makeSelectTemplateExist(tId)))) {
          //
          const data = yield call(getTemplate, token, tId)
          const store = filterLayoutPositionGeometry(
            apiFixGeometryResourceId(data, 'fixtures')
          )
          yield call(upsert, { payload: store })
        }
      }
    } catch (e) {
      console.log(e)
    }

    // TODO: This should be done in the render logic, after all the
    // necessary data is loaded via selectors
    const canvas = toFabricSync(
      fixture,
      screen,
      isStatic,
      selectedId,
      prototypes,
      mtiJsclientShared
    )

    yield put(
      fetchFixtureCanvasFulfilled(
        storeId,
        canvas,
        selectedId,
        (fixture || {}).positions,
        updateWithSviType(fixture),
        isStatic
      )
    )

    yield put(setLoading(loading.off))
    // TODO: Deal with ORM background fetch, right now it causes mismatch in states
    // For example:
    // 1. User disarmed position
    // 2. ORM fetch started (getting of store data takes >30 sec)
    // 3. User armed position within 10 sec after disarming
    // 4. After ~30sec when ORM fetch request is completed position state will go back to the disarmed state

    // In regards to the above TODO left by SVI, I believe the solve would be
    // instead to have the page load the data it needs on page load
    // (fetching the fixture, geometries, etc.) and then save the data to
    // the state. Then, In the reducers, if the data received is older
    // than the data in the orm, we ignore it. This way, old data will
    // never overwrite newer data.

    // Instead, the mapStateToProps function should return the data
    // needed to render the canvas, not the actual canvas, and so when
    // that data changes, the render function will be called again. And
    // the logic to convert the data to something fabric will understand
    // should be done in the render logic.
    if (isFixtureExist && isStatic && isBackgroundOrmFetch) {
      yield call(fetchAndUpsert, { payload: { sId: storeId } })
    }

    // TODO: This can be put in its own saga, for better organization
    // I also believe it's unnecessary to call it here, as it's
    // already called on the floor and area pages, and if a fixture
    // already exists, that means this has already been done, and if the
    // fixture doesn't exist, then the saga would never make it this far
    // anyway.
    yield put(createDefaultFloorAndArea(storeId))
  } catch (error) {
    console.log(error)
    const res = error.response
    const errorObj = res ? yield call([res, res.json]) : error
    yield put(fetchFixtureCanvasFailed(errorObj))
    yield put(setLoading(loading.off))
    errorToast('Load fixtures failed')
  }
}

function* getPrototypes() {
  const protos = yield getStaticPrototypes()
  const protosEdit = yield getEditPrototypes()
  protos.positions = { ...protos.positions, ...protosEdit.positions }
  return protos
}

export function* fetchProductsWithManufacturersAndUpdateOrm({ payload }) {
  const { sId } = payload
  const token = yield select(makeSelectToken())
  const { manufacturers } = yield call(getManufacturers, token)
  const data = yield call(getSecuredProducts, token, sId)
  yield put(upsertManufacturers(manufacturers))
  yield put(upsertSecuredProducts(data))
}

export function* saveAllPositions({ payload }) {
  const { canvasObject: { objects = [] } } = payload
  yield put(setLoading(loading.visible))
  for (let i = 1; i < objects.length; i += 1) {
    const object = objects[i]
    yield call(savePosition, { payload: { ...payload, selectedId: object.id } })
  }
  yield put(setLoading(loading.off))
  yield call(loadFixture, {
    payload: { ...payload, isStatic: true },
  })
}

export function* savePosition({ payload }) {
  const {
    canvasObject: { objects = [] },
    sId,
    fxId,
    selectedId,
    screen,
  } = payload
  const token = yield select(makeSelectToken())
  try {
    // yield put(setLoading(loading.visible))
    // yield put(postFixtureCanvasPending(sId))
    const position = fromFabricPosition(
      objects.find(({ id }) => id === selectedId),
      screen
    )
    if (!position) {
      console.error('savePosition: No Position')
      return
    }

    let data
    if (isNaN(position.id)) {
      const { parentId: tId } = objects[0] || {}
      const template = yield select(makeSelectFixtureTemplate(tId))
      position.parentId = getNewParentId(objects, template)

      data = yield call(postPosition, token, position, fxId)
      position.oldId = position.id
      position.id = data.positions[0].id
      successToast('Position Created')
    } else {
      data = yield call(patchPosition, token, position, fxId)
      successToast('Position Updated')
    }
    yield call(upsert, { payload: { ...data, stores: undefined } }) // undefine fixture, cause it doesn't have geometryId // undefine store, cause it doesn't have all the data

    yield put(
      postFixtureCanvasFinished({
        isStatic: false,
        sId,
        pId: position.id,
        oldPId: position.oldId,
        parentId: position.parentId,
      })
    )
    // yield put(setLoading(loading.off))
  } catch (error) {
    console.log(error)
    const res = error.response
    const errorObj = res ? yield call([res, res.json]) : error
    yield put(postFixtureCanvasFailed(errorObj))
    yield put(setLoading(loading.off))
    errorToast('Save position failed')
  }
}

function getNewParentId(objects, template) {
  const parentIds = ((objects || []).slice(1) || []).map(
    ({ parentId }) => parentId
  )
  const tParentIds = ((template || {}).positions || []).map(({ id }) => id)
  const unusedParentIds = _.difference(tParentIds, parentIds)
  const newParentId = unusedParentIds[0]
  return newParentId
}

export function* assignSecurityDeviceToPosition({ payload }) {
  const { sId, selectedPosition = {}, selectedDevice = {} } = payload
  const token = yield select(makeSelectToken())

  try {
    yield put(setLoading(loading.visible))
    yield put(postFixtureCanvasPending(sId))
    const data = yield call(
      assignSecurityDevice,
      token,
      selectedPosition,
      selectedDevice
    )
    if (selectedDevice) {
      successToast('Security Device Assigned')
    } else {
      successToast('Security Device Unassigned')
    }
    console.log(mac)
    console.warn('assignSecurityDevice response: ', data)
    // TODO: update ORM with logic to handle assignSecurityDevice response
    // Case 1
    // yield call(upsert, { payload: data })

    // Case 2
    /*
    yield data.positions && put(mac.upsertPositions(data.positions))
    yield data.positionRules &&
      put(mac.upsertPositionRules(data.positionRules))
    yield data.securityDevices &&
      put(mac.upsertSecurityDevices(data.securityDevices))
    */

    // Case 3
    // meanwhile we have to fetch the securityDevice
    // /*
    // const device = yield call(getSecurityDevice, token, securityDevice.id)
    // yield call(upsert, { payload: device })
    // */

    const selectedPositionWithNewSecurityDevice = yield select(
      makeSelectPositionById(selectedPosition.id)
    )
    yield put(
      postFixtureCanvasFinished({
        isStatic: true,
        pId: selectedPosition.id,
        selectedPosition: selectedPositionWithNewSecurityDevice,
      })
    )
    yield put(setLoading(loading.off))
  } catch (error) {
    console.log(error)
    const res = error.response
    const errorObj = res ? yield call([res, res.json]) : error
    yield put(postFixtureCanvasFailed(errorObj))
    yield put(setLoading(loading.off))
    errorToast('Assign security device to position failed')
  }
}

export function* assignSecuredProductToSecurityDevicePort({ payload }) {
  console.log('assignSecuredProductToSecurityDevicePort', payload)
  const {
    sId,
    securityDevice = {},
    port = {},
    selectedProduct,
    selectedPosition = {},
  } = payload
  const token = yield select(makeSelectToken())

  try {
    yield put(setLoading(loading.visible))
    yield put(postFixtureCanvasPending(sId))

    if (selectedProduct) {
      yield call(
        assignPortSecuredProduct,
        token,
        securityDevice,
        port,
        selectedProduct
      )
      successToast('Secured Product Assigned')
    }
    yield put(upsertSecuredProducts(yield call(getSecuredProducts, token, sId)))
    // TODO: API: skip this step after API will return full store object after assigning SecuredProduct and upsert it into orm
    yield call(upsert, { payload: yield call(getSecurityDevice, token, securityDevice.id) })

    yield call(loadFixture, {
      payload: { ...payload, isStatic: true },
    })
    yield put(
      postFixtureCanvasFinished({
        isStatic: true,
        pId: selectedPosition.id,
        selectedPosition,
      })
    )
    yield put(setLoading(loading.off))
  } catch (error) {
    const res = error.response
    const errorObj = res ? yield call([res, res.json]) : error
    yield put(postFixtureCanvasFailed(errorObj))
    yield put(setLoading(loading.off))
    errorToast('Assign secured product to security device port failed')
  }
}

export function* addNewProduct({ payload }) {
  console.log('addNewProduct', payload)
  const {
    productId,
    otherProductName,
    manufacturerId,
    productShouldCharge,
    otherManufacturerName,
    cb,
    sId,
  } = payload
  const token = yield select(makeSelectToken())

  if (!(otherProductName && (manufacturerId || otherManufacturerName))) {
    yield call(cb)
    return
  }

  try {
    yield put(setLoading(loading.visible))
    const data = yield call(addSecuredProduct, token, {
      manufacturerId,
      otherManufacturerName,
      otherProductName,
      productId,
      storeId: sId,
      usbCapable: productShouldCharge,
    })
    successToast('Secured Product Added')
    yield put(upsertSecuredProducts(data))
    yield put(setLoading(loading.off))
    yield call(cb)
  } catch (error) {
    const res = error.response
    const errorObj = res ? yield call([res, res.json]) : error
    yield put(postFixtureCanvasFailed(errorObj))
    yield put(setLoading(loading.off))
    errorToast('Add secured product failed')
  }
}

export function* deleteProduct({ payload }) {
  const { selectedProduct, selectedPosition, sId } = payload
  const token = yield select(makeSelectToken())

  try {
    yield put(setLoading(loading.visible))
    yield call(deleteSecuredProduct, token, selectedProduct)
    successToast('Secured Product Deleted')
    yield put(updateSecuredProducts(yield call(getSecuredProducts, token, sId)))
    yield call(loadFixture, {
      payload: { ...payload, isStatic: true },
    })
    yield put(
      postFixtureCanvasFinished({
        isStatic: true,
        pId: selectedPosition.id,
        selectedPosition,
      })
    )
    yield put(setLoading(loading.off))
  } catch (error) {
    const res = error.response
    const errorObj = res ? yield call([res, res.json]) : error
    yield put(postFixtureCanvasFailed(errorObj))
    yield put(setLoading(loading.off))
    errorToast('Delete secured product failed')
  }
}

export function* addAndAssignPortNewProduct({ payload }) {
  const {
    productId,
    otherProductName,
    productShouldCharge,
    manufacturerId,
    otherManufacturerName,
    selectedPosition,
    cb,
    sId,
    securityDevice = {},
    port,
  } = payload
  const token = yield select(makeSelectToken())

  if (
    !(productId || otherProductName) &&
    !(manufacturerId || otherProductName)
  ) {
    yield call(cb)
    return
  }

  try {
    yield put(setLoading(loading.visible))
    yield put(postFixtureCanvasPending(sId))
    const data = yield call(addSecuredProduct, token, {
      manufacturerId,
      otherManufacturerName,
      otherProductName,
      productId,
      storeId: sId,
      usbCapable: productShouldCharge,
    })
    successToast('Secured Product Added')
    const { securedProducts: [product] } = data

    yield call(assignPortSecuredProduct, token, securityDevice, port, product)
    successToast('Secured Product Assigned')

    yield put(upsertSecuredProducts(yield call(getSecuredProducts, token, sId)))
    // TODO: API: skip this step after API will return full store object after assigning SecuredProduct and upsert it into orm
    yield call(upsert, { payload: yield call(getSecurityDevice, token, securityDevice.id) })

    yield call(loadFixture, {
      payload: { ...payload, isStatic: true },
    })
    yield put(
      postFixtureCanvasFinished({
        isStatic: true,
        pId: selectedPosition.id,
        selectedPosition,
      })
    )
    yield put(setLoading(loading.off))
    yield call(cb)
  } catch (error) {
    console.error(error)
    const res = error.response
    const errorObj = res ? yield call([res, res.json]) : error
    yield put(postFixtureCanvasFailed(errorObj))
    yield put(setLoading(loading.off))
    errorToast('Assign secured product failed')
  }
}

export function* removePosition({ payload }) {
  const { canvasObject: { objects = [] }, sId, selectedId } = payload
  const position = objects.find(({ id }) => id === selectedId)
  const token = yield select(makeSelectToken())
  try {
    if (!isNaN(position.id)) {
      // yield put(setLoading(loading.visible))
      // yield put(postFixtureCanvasPending(sId))
      const data = yield call(deletePosition, token, position)
      successToast('Position Deleted')
      yield put(mac.deletePositionWithId(position.id))
      yield call(upsert, { payload: data })
      yield put(postFixtureCanvasFinished({ isStatic: false, sId, data }))
    }
    // yield call(loadFixture, {
    //   payload: { ...payload, selectedId: -1, isStatic: true },
    // })
    // yield put(setLoading(loading.off))
  } catch (error) {
    console.log(error)
    const res = error.response
    const errorObj = res ? yield call([res, res.json]) : error
    yield put(postFixtureCanvasFailed(errorObj))
    yield put(setLoading(loading.off))
    errorToast('Delete position failed')
  }
}

export function* autoplacement({ payload }) {
  const { screen, count } = payload
  let prototypes = yield select(makeSelectPrototypes())
  if (!prototypes) {
    prototypes = yield getPrototypes()
  }
  const fixture = setTypeBasedOnAspect(payload.fixture)
  const canvas = yield getAutoplacement(
    fixture,
    screen,
    count,
    false,
    prototypes
  )
  yield put(autoPlacementFulfilled(canvas))
}

/**
 * Fixture Sagas
 */
export default function* root() {
  yield all([
    yield takeLatest(ActionTypes.SAVE_FIXTURE_POSITION, savePosition),
    yield takeLatest(ActionTypes.SAVE_FIXTURE_ALL_POSITIONS, saveAllPositions),
    yield takeLatest(
      ActionTypes.FIXTURE_CANVAS_POSITION_REMOVE,
      removePosition
    ),
    yield takeLatest(
      ActionTypes.FIXTURE_CANVAS_POSITION_COUNT_CHANGED,
      autoplacement
    ),

    yield takeLatest(
      ActionTypes.LOAD_PRODUCTS_WITH_MANUFACTURERS,
      fetchProductsWithManufacturersAndUpdateOrm
    ),
    yield takeLatest(
      ActionTypes.ASSIGN_SECURITY_DEVICE,
      assignSecurityDeviceToPosition
    ),
    yield takeLatest(
      ActionTypes.ASSIGN_PORT_SECURED_PRODUCT,
      assignSecuredProductToSecurityDevicePort
    ),
    yield takeLatest(ActionTypes.ADD_NEW_PRODUCT, addNewProduct),
    yield takeLatest(ActionTypes.DELETE_PRODUCT, deleteProduct),
    yield takeLatest(
      ActionTypes.ADD_AND_ASSIGN_PORT_NEW_PRODUCT,
      addAndAssignPortNewProduct
    ),
  ])
}
