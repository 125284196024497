import { all, call, put, takeLatest, select } from 'redux-saga/effects'
import {
  postPositionAction as postAction,
  postSecurityDeviceAction as postSecurityDeviceCommand,
} from '../../../api'
import { makeSelectToken } from '../selectors'
import { ActionTypes } from '../constants'
import {
  postPositionActionPending,
  postPositionActionFailed,
  postPositionActionFulfilled,
  postSecurityDeviceActionPending,
  postSecurityDeviceActionFailed,
  postSecurityDeviceActionFulfilled,
} from '../actions'
import { errorToast, successToast } from '../../../utils/utils'

export function* postPositionAction({ payload }) {
  const { id, action } = payload
  const token = yield select(makeSelectToken())
  try {
    yield put(postPositionActionPending())
    yield call(postAction, token, id, action)
    successToast(`Position action ${action}`)
    yield put(postPositionActionFulfilled())
  } catch (error) {
    yield put(postPositionActionFailed(error))
    errorToast('Save position failed')
  }
}

export function* postSecurityDeviceAction({ payload }) {
  const { id, action } = payload
  const token = yield select(makeSelectToken())
  try {
    yield put(postSecurityDeviceActionPending())
    yield call(postSecurityDeviceCommand, token, id, action)
    successToast(`Security Device action ${action}`)
    yield put(postSecurityDeviceActionFulfilled())
  } catch (error) {
    yield put(postSecurityDeviceActionFailed(error))
    errorToast('Security Device action failed')
  }
}

export default function* root() {
  yield all([
    yield takeLatest(ActionTypes.POST_POSITION_ACTION, postPositionAction),
    yield takeLatest(
      ActionTypes.POST_SECURITY_DEVICE_ACTION,
      postSecurityDeviceAction
    ),
  ])
}
