import React from 'react'
import PropTypes from 'prop-types'
import StatusListScreen from './index.screen'
import StatusList from './index.home.screen'
import { statuses } from './mock'

// eslint-disable-next-line
class StatusPage extends React.Component {
  render() {
    const { history, height, isOTAHome } = this.props

    if (isOTAHome) {
      return (
        <StatusList
          height={height}
          statuses={statuses.statuses}
          onSeeAll={() => history.push('/admin/ota/status')}
          onDevicesSelection={(devices) =>
            console.warn('onDevicesSelection', devices)
          }
        />
      )
    }

    return (
      <StatusListScreen
        statuses={statuses.statuses}
        onDevicesSelection={(devices) =>
          console.warn('onDevicesSelection', devices)
        }
      />
    )
  }
}

StatusPage.propTypes = {
  history: PropTypes.object.isRequired,
  height: PropTypes.number,
  isOTAHome: PropTypes.bool,
}

export default StatusPage
