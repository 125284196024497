import { createSelector } from 'reselect'

const selectForgotPasswordState = (state) => state.get('forgotPassword')

export const makeSelectFailed = () =>
  createSelector(selectForgotPasswordState, (state) => state.get('failed'))

export const makeSelectLoading = () =>
  createSelector(selectForgotPasswordState, (state) => state.get('loading'))

export const makeSelectForgotPasswordEmail = () =>
  createSelector(selectForgotPasswordState, (state) => state.get('email'))
