import { Map } from 'immutable'
import { ActionTypes } from './constants'

export const initialState = new Map({
  currentManufacturerId: null,
  products: null,
  failed: null,
  loading: false,
})

function reducer(state = initialState, { payload, type }) {
  switch (type) {
    case ActionTypes.FETCH_MANUFACTURER_PRODUCTS_FULFILLED: {
      return state
        .set('products', payload.products)
        .set('failed', null)
        .set('loading', false)
    }
    case ActionTypes.FETCH_MANUFACTURER_PRODUCTS_PENDING:
      return state.set('failed', null).set('loading', true)
    case ActionTypes.FETCH_MANUFACTURER_PRODUCTS_FAILED:
      return state.set('failed', payload).set('loading', false)
    case ActionTypes.LOAD_MANUFACTURER_PRODUCTS:
      return state
        .set('currentManufacturerId', payload.manufacturerId)
        .set('storeId', payload.storeId)
    default:
      return state
  }
}

export default reducer
