import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ReactTable from 'react-table'
import { Link } from 'react-router-dom'
import moment from 'moment'
import styles from '../../styles/App.css'
import {
  HeaderContainer,
  Header,
  renderFilterInput,
  renderHeaderCell,
} from '../UsersPage/index.screen'
import { fullTextFilter, getTableHeight } from '../../utils/utils'
import { figureType } from '../TemplatePositions/utils'
import { templateShapeComponent } from './utils'
import ProgressIndicator from '../../components/ProgressIndicator'
import TemplatesPreloader from '../../components/Preloaders/TemplatesPreloader'

const HeaderButtonsContainer = styled.div`
  margin-right: 12px;
`

export const CellContainer = styled.div`
  overflow: hidden;
`

const headerStyle = {
  display: 'flex',
  height: 48,
  whiteSpace: 'normal',
  alignItems: 'center',
  justifyContent: 'center',
}

class TemplatesListScreen extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    window.setTimeout(() => this.handleResize(), 10)
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  /* eslint-disable no-undef */
  getColumns = () => {
    const { onTemplate, onDeleteTemplate, onViewLinkedFixtures } = this.props
    const columns = [
      {
        minWidth: 250,
        sortable: true,
        filterable: true,
        headerStyle,
        Header: renderHeaderCell('Template Name'),
        accessor: 'name',
        Cell: ({ original }) => (
          <CellContainer>
            <Link
              className={styles.link}
              to={'#'}
              onClick={() => onTemplate(original)}
            >
              {original.name}
            </Link>
          </CellContainer>
        ),
        Filter: ({ filter, onChange }) =>
          renderFilterInput({
            placeholder: 'Filter',
            filter,
            onChange,
          }),
      },
      {
        id: 'type',
        minWidth: 120,
        sortable: true,
        filterable: true,
        headerStyle,
        accessor: 'type',
        Header: renderHeaderCell('Template Shape'),
        filterMethod: (filter, row) => {
          if (filter.value === 'all') {
            return true
          }
          return filter.value === row.type
        },
        Filter: ({ filter, onChange }) =>
          templateShapeComponent(filter, onChange),
        Cell: ({ original: { type } }) => (
          <CellContainer> {figureType[type] || type}</CellContainer>
        ),
      },
      {
        width: 140,
        sortable: true,
        filterable: true,
        style: { justifyContent: 'center' },
        headerStyle,
        Header: renderHeaderCell('# of Positions', true),
        accessor: 'positionsCount',
        Cell: ({ original: { positionsCount } }) => (
          <CellContainer>{positionsCount || ''}</CellContainer>
        ),
        Filter: ({ filter, onChange }) =>
          renderFilterInput({
            placeholder: 'Filter',
            filter,
            onChange,
          }),
      },
      {
        width: 135,
        sortable: true,
        filterable: true,
        style: { justifyContent: 'center' },
        headerStyle,
        Header: renderHeaderCell('Date created', true),
        accessor: 'createdAt',
        Cell: ({ original: { createdAt } }) => (
          <CellContainer>
            {createdAt
              ? moment(createdAt)
                .utcOffset(0)
                .format('MM/DD/YYYY')
              : ''}
          </CellContainer>
        ),
        Filter: ({ filter, onChange }) =>
          renderFilterInput({
            placeholder: 'Filter',
            filter,
            onChange,
          }),
      },
      {
        width: 90,
        style: { justifyContent: 'center' },
        headerStyle,
        sortable: false,
        Header: 'Linked Fixtures',
        Cell: ({ original }) => (
          // original.childIds ? (
          <CellContainer>
            <Link
              className={styles.link}
              to={'#'}
              onClick={() => onViewLinkedFixtures(original)}
            >
              View
            </Link>
          </CellContainer>
        ),
        // ) : (
        // <div>...</div>)
      },
      {
        width: 90,
        style: { justifyContent: 'center' },
        headerStyle,
        sortable: false,
        Header: 'Delete Template',
        Cell: ({ original }) => (
          <CellContainer>
            <Link
              className={styles.link}
              to={'#'}
              onClick={() => onDeleteTemplate(original)}
            >
              Delete
            </Link>
          </CellContainer>
        ),
      },
    ]
    return columns
  }

  handleResize = () => this.setState({ tableHeight: getTableHeight() })

  render() {
    const { templates, onCreateTemplate, loading } = this.props
    const { tableHeight } = this.state
    const columns = this.getColumns()

    return (
      <div>
        <HeaderContainer className="border-bottom">
          <Header>Templates</Header>
          <HeaderButtonsContainer>
            <button
              type="button"
              className="btn btn-primary btn-raised"
              onClick={onCreateTemplate}
              name="createTemplate"
            >
              Add New Template
            </button>
          </HeaderButtonsContainer>
        </HeaderContainer>
        {loading ? (
          <React.Fragment>
            <TemplatesPreloader />
            <ProgressIndicator />
          </React.Fragment>
        ) : (
          <ReactTable
            className="full-screen"
            style={{ height: tableHeight || 'auto' }}
            ref={(ref) => {
              this.reactTable = ref
            }}
            data={templates || []}
            columns={columns}
            defaultFilterMethod={fullTextFilter}
            showPaginationBottom
            defaultSorted={[
              {
                id: 'createdAt',
                desc: true,
              },
            ]}
          />
        )}
      </div>
    )
  }
}

TemplatesListScreen.propTypes = {
  templates: PropTypes.array.isRequired,
  onTemplate: PropTypes.func.isRequired,
  onCreateTemplate: PropTypes.func.isRequired,
  onDeleteTemplate: PropTypes.func.isRequired,
  onViewLinkedFixtures: PropTypes.any.isRequired,
}

export default TemplatesListScreen
