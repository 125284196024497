import React from 'react'
import PropTypes from 'prop-types'
import Checkbox from '../../../../components/Checkbox'

const CheckboxFilter = (props) => {
  const { name, label, isChecked, onChange } = props
  return (
    <Checkbox
      name={`${name}-report-checkbox`}
      label={label}
      checked={isChecked}
      onChange={({ target: { checked } }) => onChange({ [name]: checked })}
    />
  )
}

export default CheckboxFilter

CheckboxFilter.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
}
