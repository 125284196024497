import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import {
  makeSelectIsHubFetchingByStoreId,
  makeSelectHubByStore,
  makeSelectIsHubFetchErrorByStoreId,
} from '../../App/selectors'
import { fetchHub } from '../../App/actions'
import ProgressIndicatorModal from '../../../components/ProgressIndicatorModal'
import ErrorModal from '../../../components/ErrorModal'

class HubDetails extends React.Component {
  componentDidMount() {
    const { selectedStore: { id }, fetchHub: doFetchHub } = this.props
    doFetchHub(id)
  }

  render() {
    const { hub, isHubFetching, hubFetchError } = this.props

    if (isHubFetching) {
      return <ProgressIndicatorModal text="HUB data is Loading..." />
    }

    if (hubFetchError) {
      return <ErrorModal text={`HUB Loading Error: ${hubFetchError.message}`} />
    }

    if (!hub) return null

    return this.props.render({ hub })
  }
}

HubDetails.propTypes = {
  render: PropTypes.func.isRequired,
  selectedStore: PropTypes.object.isRequired,
  hub: PropTypes.object,
  isHubFetching: PropTypes.bool,
  hubFetchError: PropTypes.object,
  fetchHub: PropTypes.func,
}

const mapStateToProps = createStructuredSelector({
  hub: (state, ownProps) =>
    makeSelectHubByStore(ownProps.selectedStore.id)(state, ownProps),
  isHubFetching: (state, ownProps) =>
    makeSelectIsHubFetchingByStoreId(ownProps.selectedStore.id)(
      state,
      ownProps
    ),
  hubFetchError: (state, ownProps) =>
    makeSelectIsHubFetchErrorByStoreId(ownProps.selectedStore.id)(
      state,
      ownProps
    ),
})

const mapDispatchToProps = {
  fetchHub,
}

export default connect(mapStateToProps, mapDispatchToProps)(HubDetails)
