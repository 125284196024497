import 'whatwg-fetch'

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response) {
  if (response.status === 204 || response.status === 205) {
    return null
  }
  return response.json()
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response
  }

  if (response.status === 403) {
    const error = new Error('Invalid permissions')
    error.response = response
    throw error
  }

  if (response.status === 401) {
    const token = localStorage.getItem('__user')
    if (token) {
      localStorage.removeItem('__user')
      localStorage.setItem('loggedOutDueToInactivity', 'true')
      window.location.href = '/'
    }
    const error = new Error('Unauthorized')
    error.response = response
    throw error
  }

  const error = new Error(response.statusText)
  error.response = response
  throw error
}

/**
 * Returns rejected promise after a number of milliseconds
 *
 * @param {number} milliseconds
 */
function timeout(milliseconds = 10000) {
  return new Promise((_, reject) =>
    setTimeout(() => reject(new Error('Timeout')), milliseconds)
  )
}

/**
 * Request url with a timeout, returning a promise
 *
 * @param {string} url
 * @param {object} options
 * @param {number} milliseconds
 */
export function requestWithTimeout(url, options, milliseconds) {
  return Promise.race([request(url, options), timeout(milliseconds)])
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export default function request(url, options) {
  return fetch(url, options)
    .then(checkStatus)
    .then(parseJSON)
}
