const DEFAULT_THROTTER_INTERVAL = 100
export default class Throttler {
  constructor(fn, interval = DEFAULT_THROTTER_INTERVAL) {
    this.interval = interval
    this.fn = fn
    this.timer = null
  }

  call(...args) {
    this._args = args
    const msSinceLastRequest = Date.now() - (this._lastRequest || 0)
    const tooSoon = msSinceLastRequest < this.interval

    if (tooSoon) {
      if (!this.timer) {
        this.timer = setTimeout(
          () => this.makeRequest(),
          this.interval - msSinceLastRequest
        )
      }
      return
    }

    this.makeRequest()
  }

  makeRequest() {
    this._lastRequest = Date.now()
    this.fn(...this._args)
    this.timer = null
  }
}
