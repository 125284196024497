import { createSelector } from 'reselect'

const selectStoresReducer = (state) => state.get('otaStoresList')

export const makeSelectStoresByOrganization = () =>
  createSelector(selectStoresReducer, (storeState) => {
    const selectedOrganizationId = storeState.get('selectedOrganizationId')
    const allStores = storeState.get('stores')
    if (selectedOrganizationId) {
      return allStores.filter(
        ({ organizationId }) =>
          organizationId === Number(selectedOrganizationId)
      )
    }
    return allStores
  })

export const makeSelectOrganizations = () =>
  createSelector(selectStoresReducer, (storeState) =>
    storeState.get('organizations')
  )

export const makeSelectSelectedOrganization = () =>
  createSelector(selectStoresReducer, (storeState) =>
    storeState.get('selectedOrganizationId')
  )

export const makeSelectOTAStoresExist = () =>
  createSelector(selectStoresReducer, (state) => {
    const stores = state.get('stores')
    return stores ? stores.length > 0 : false
  })

export const makeSelectLoading = () =>
  createSelector(selectStoresReducer, (state) => state.get('loading'))

export const makeSelectError = () =>
  createSelector(selectStoresReducer, (state) => state.get('failed'))
