import {
  DeviceType,
  ArmState,
  PositionRuleStatus,
  HealthStatus,
} from 'mti-jsclient-shared'

export default {
  DeviceType,
  ArmState,
  PositionRuleStatus,
  HealthStatus,
}
