import React from 'react'
import ReactTable from 'react-table';
import { FormattedMessage, injectIntl } from 'react-intl'
import { HeaderContainer } from '../UsersPage/index.screen'

class StoresDashboardScreen extends React.PureComponent {
  _fetchData(reactTableState) {
    // convert table filters to something the api will understand

    // sorting
    let sorting = {
      'sort[attribute]': 'state',
      'sort[direction]': 'asc',
    }
    const sortChoice = reactTableState.sorted[0]
    if (sortChoice) {
      sorting['sort[direction]'] = sortChoice.desc ? 'desc' : 'asc'
      sorting['sort[attribute]'] = sortChoice.id // name, state
    }

    // pagination
    const pagination = {
      per: reactTableState.pageSize,
      page: reactTableState.page+1,
    }

    // current filter options allowed by API: state, name
    const filters = { }
    reactTableState.filtered.forEach(f => filters[f.id === 'state' ? 'states' : f.id] = (f.value == -1) ? undefined : f.value)

    // dispatch a call to the API to update the table
    this.props.onQuery({
      ...filters,
      ...sorting,
      ...pagination,
    })
  }

  render() {
    const {
      data: {
        stores,
        meta: pageData, // total, pages, page, per
      },
      isLoading,
      defaultPageSize,
    } = this.props
    const columns = this._createColumnSchema()

    // our API is inconsistant
    const per = pageData?.per || pageData?.perPage || defaultPageSize
    const pageCount = pageData?.pages || Math.ceil((pageData?.total || 0) / per)

    return (
      <div>
        <HeaderContainer className="border-bottom">
        </HeaderContainer>
        <ReactTable
          className="-striped"
          columns={columns}
          data={stores}
          manual // Forces table not to paginate or sort automatically, so we can handle it server-side
          multiSort={false}
          pages={pageCount} // Display the total number of pages
          defaultPageSize={defaultPageSize}
          loading={isLoading} // Display the loading overlay when we need it
          onFetchData={(state, instance) => this._fetchData(state, instance)} // Request new data when things change
        />
      </div>
    )
  }

  _createColumnSchema() {
    const { intl } = this.props
    return [{
      id: 'name',
      Header: intl.formatMessage({ id: 'stores.name' }),
      filterable: false,
      sortable: true,
      accessor: 'name',
    },{
      id: 'state',
      Header: intl.formatMessage({ id: 'stores.status' }),
      accessor: 'state',
      sortable: true,
      filterable: true,
      Filter: props => (
        <select
          onChange={event => props.onChange(event.target.value)}
          style={{ width: '100%' }}
        >
          <option value={-1}>{intl.formatMessage({ id: 'all' })}</option>
          {['created', 'disabled', 'offline', 'online', 'updating'].map(state => (
            <option key={`resource-${state}`} value={state}>{intl.formatMessage({ id: `stores.statuses.${state}` })}</option>
          ))}
        </select>
      ),
    }]
  }
}

export default injectIntl(StoresDashboardScreen)
