/*
 *
 * FloorPage constants
 *
 */

import keyMirror from 'fbjs/lib/keyMirror'

/**
 * @constant {Object} ActionTypes
 * @memberof Constants
 */
export const ActionTypes = keyMirror({
  STORE_FLOOR_PROTOTYPES: undefined,
  STORE_PARAMS: undefined,
  POST_FLOOR_CANVAS_FAILED: undefined,
  POST_FLOOR_CANVAS_FINISHED: undefined,
  POST_FLOOR_CANVAS_PENDING: undefined,
  FETCH_FLOOR_CANVAS_FAILED: undefined,
  FETCH_FLOOR_CANVAS_FULFILLED: undefined,
  FETCH_FLOOR_CANVAS_PENDING: undefined,
  LOAD_FLOOR_CANVAS: undefined,
  SAVE_FLOOR_CANVAS: undefined,
  SAVE_FLOOR_AREA: undefined,
  FLOOR_CANVAS_CHANGED: undefined,
  FLOOR_CANVAS_AREA_REMOVE: undefined,
  FLOOR_CANVAS_AREA_CHANGED: undefined,
  FLOOR_THUMBNAIL_FROM_CANVAS_CHANGED: undefined,
})
