import React from 'react'
import PropTypes from 'prop-types'
import uuid from 'uuid'
import styled, { css } from 'styled-components'
import _ from 'lodash'
import { DeviceType } from 'mti-jsclient-shared'
import mtiJsclientShared from '../../utils/mtiJsclientShared'
import SidebarTitle from '../../components/SidebarTitle'
import CanvasUpdate from '../../components/CanvasUpdate'
import FixtureCanvas from '../../components/FixtureCanvas'
import PromptTable from '../../components/PromptTable'
import Issue from '../../components/Issue'
import Input from '../../components/Input'
import InfoLabel from '../../components/InfoLabel'
import {
  HealthStatusCell,
} from '../../components/DeviceStatus'
import SidebarSection from '../../components/SidebarSection'
import EmptyCanvasLabel from '../../components/EmptyCanvasLabel'
import {
  SidebarBody,
  TopContainer,
  BorderedButton,
  FlatBorderedButton,
} from '../../../app/styles/styled'

import styles from '../../styles/App.css'
import { friendlyDeviceType } from '../../utils/mtiUtils'
import { defaultName } from '../../utils/mtiCanvasUtils'
import {
  invalidUrlCharactersMessage,
  safeUrlCharactersPattern,
} from '../../utils/utils'
import { displayAlerts } from '../AlertContainer/alerts'

const DeviceStatusList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  & > section {
    margin-right: 20px;
    flex: 1;
  }
`

const SidebarRow = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  & > * {
    margin-right: 18px;
    &:last-child {
      margin: 0;
    }
  }
`

const TopBorderedContainer = styled.div`
  border-top: 1px solid #d9d9d9;
`

const CellContainer = styled(TopBorderedContainer)`
  ${props => props.withoutBorder ? 'border-top: none;' : ''}
  margin-left: 25px;
  margin-bottom: 3px;
`

class FixtureScreen extends React.PureComponent {
  state = {
    isSaveDisabled: false,
  }

  componentWillUnmount() {
    this.hideDeleteDisabledTooltip()
  }

  deleteBtnRef = null
  hideDeleteDisabledTooltip = () => {
    if (this.deleteBtnRef && this.deleteBtnRef.popover) {
      this.deleteBtnRef.popover('dispose')
    }
  }

  onChangeValidity = (isValid) => {
    this.setState({ isSaveDisabled: !isValid })
  }

  onNameChanged = (name) => {
    const { onNameChanged } = this.props
    onNameChanged(name)
  }

  onPositionCountChanged = (count) => {
    const { onPositionCountChanged } = this.props
    onPositionCountChanged(count)
  }

  getSelectedPositionName = () => {
    const { selectedPosition = {}, selectedPositionName } = this.props
    return (
      selectedPositionName ||
      (selectedPosition ? selectedPosition.mtiName : defaultName.position)
    )
  }

  getSelectedPositionObject() {
    const { canvasObject, selectedId } = this.props
    return (
      selectedId !== -1 &&
      selectedId !== 0 &&
      canvasObject.objects.find(({ id }) => id === selectedId)
    )
  }

  getPromptText() {
    const {
      isStatic,
      canvasObject,
      selectedId,
      selectedPosition: { securityDevice } = {},
    } = this.props
    const hasPositions =
      canvasObject && canvasObject.objects && canvasObject.objects.length > 1
    const hasSelected = selectedId !== -1 && selectedId !== 0
    const hasSecurityDevice = !!securityDevice
    const hasSecurityDeviceLock =
      (securityDevice || {}).deviceType === DeviceType.LOCK

    // 'Use the # of Positions control to create multiple positions in balanced locations'
    let data = []
    if (isStatic) {
      if (hasSelected) {
        if (!hasSecurityDevice || hasSecurityDeviceLock) {
          data = [
            {
              func: 'EDIT SELECTED POSITION',
              desc: 'Click on EDIT POSITION in SIDE PANEL',
            },
            {
              func: 'EDIT SECURITY DEVICE',
              desc: 'Click on EDIT button near SECURITY DEVICE in SIDE PANEL',
            },
            {
              func: 'DESELECT POSITION',
              desc: 'Click on empty space OR another POSITION in CANVAS',
            },
            {
              func: 'WORK ON ISSUES',
              desc: 'Click on POSITION in SIDE PANEL',
            },
          ]
        } else if (hasSecurityDevice) {
          data = [
            {
              func: 'EDIT SELECTED POSITION',
              desc: 'Click on EDIT POSITION in SIDE PANEL',
            },
            {
              func: 'EDIT SECURITY DEVICE',
              desc: 'Click on EDIT button near SECURITY DEVICE in SIDE PANEL',
            },
            {
              func: 'EDIT ASSIGNED PRODUCT',
              desc: 'Click on EDIT button near ASSIGNED PRODUCT in SIDE PANEL',
            },
            {
              func: 'DESELECT POSITION',
              desc: 'Click on empty space OR another POSITION in CANVAS',
            },
            {
              func: 'WORK ON ISSUES',
              desc: 'Click on POSITION in SIDE PANEL',
            },
          ]
        }
      } else if (!hasSelected) {
        data = hasPositions
          ? [
            {
              func: 'CREATE NEW POSITION',
              desc: 'Click on CREATE NEW POSITION in SIDE PANEL',
            },
            {
              func: 'EDIT EXISTING POSITION',
              desc: 'Select position in CANVAS',
            },
            {
              func: 'WORK ON ISSUES',
              desc: 'Click on POSITION in SIDE PANEL',
            },
          ]
          : [
            {
              func: 'CREATE NEW POSITION',
              desc: 'Click on CREATE NEW POSITION in SIDE PANEL',
            },
          ]
      }
    } else if (!isStatic) {
      if (hasSelected) {
        const isNew = isNaN((this.getSelectedPositionObject() || {}).id)
        data = isNew
          ? [
            {
              func: 'CREATE NEW POSITION',
              desc:
                  'Use SIDE PANEL to set attributes of POSITION and click on SAVE',
            },
            {
              func: 'CANCEL',
              desc: 'Click on CANCEL in SIDE PANEL',
            },
          ]
          : [
            {
              func: 'MODIFY POSITION',
              desc:
                  'Use SIDE PANEL to set attributes of POSITION and click on SAVE',
            },
            {
              func: 'DELETE POSITION',
              desc: 'Click on DELETE in SIDE PANEL',
            },
            {
              func: 'CANCEL',
              desc: 'Click on CANCEL in SIDE PANEL',
            },
          ]
      } else if (!hasSelected) {
        data = [
          {
            func: 'CREATE NEW POSITION',
            desc:
              'Click in CANVAS to start creating NEW POSITION and drag to move',
          },
          {
            func: 'CANCEL',
            desc: 'Click on CANCEL in SIDE PANEL',
          },
        ]
      }
    }
    return data
  }

  isAllowableDeviceType = (type) => type.toLowerCase() !== DeviceType.LOCK

  renderSecurityItem() {
    const { selectedPosition = {}, openPosition } = this.props
    const { id, path, name, deviceType, securityDevice } =
      selectedPosition || {}
    if (!selectedPosition) return <div />
    const position = selectedPosition
    if (!position) return <div />
    const { status } = position || {}

    return (
      <Issue
        issue={{
          id,
          title: path,
          device: name,
          status: (status || {}).type,
          statusTitle: (status || {}).title,
          armStateAction: ((status || {}).states || {}).armStateAction,
          armStateActionTitle: ((status || {}).states || {})
            .armStateActionTitle,
          deviceType,
          securityDeviceId: (securityDevice || {}).id,
          hideGoTo: true,
        }}
        openPosition={openPosition}
      />
    )
  }

  renderSecurityList(positions, openPosition) {
    return (
      positions &&
      positions.map((i) => (
        <Issue
          ref={(issue) => {
            this[`issue${i.id}`] = issue
          }}
          onMouseEnter={(id) => {
            if (this.canvas) this.canvas.onOver(id)
          }}
          onMouseLeave={(id) => {
            if (this.canvas) this.canvas.onOut(id)
          }}
          key={i.id}
          issue={{
            id: i.id,
            title: i.path,
            device: i.name,
            status: i.status.type,
            statusTitle: i.status.title,
            armStateAction: i.status.states.armStateAction,
            armStateActionTitle: i.status.states.armStateActionTitle,
            deviceType: i.deviceType,
            securityDeviceId: (i.securityDevice || {}).id,
          }}
          openPosition={openPosition}
        />
      ))
    )
  }

  renderSidebarHeaderForPositions() {
    const {
      fixtureName = '',
      isStatic,
      hasEditPermission,
      onEdit,
      onEditDenied,
      onCancel,
      onSaveAll,
      selectedId,
      isAutoplacement,
      canvasObject: { objects } = {},
    } = this.props

    if (isStatic) {
      return (
        <SidebarTitle
          title={
            selectedId !== -1 && selectedId !== 0
              ? this.getSelectedPositionName()
              : fixtureName
          }
          titleTo={null}
        >
          <button
            type="button"
            className="btn btn-raised"
            onClick={() => (hasEditPermission ? onEdit() : onEditDenied())}
            name="newPosition"
          >
            Create New Position(s)
          </button>
        </SidebarTitle>
      )
    }
    if (isAutoplacement) {
      const hasPositions = objects && objects.length > 1
      return (
        <SidebarTitle
          title={
            selectedId !== -1 && selectedId !== 0
              ? this.getSelectedPositionName()
              : 'Fixture'
          }
          titleTo={null}
        >
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => onCancel()}
          >
            Cancel
          </button>
          {hasPositions && (
            <button
              type="button"
              className="btn btn-raised"
              onClick={() => onSaveAll()}
            >
              Save All
            </button>
          )}
        </SidebarTitle>
      )
    }
    return (
      <SidebarTitle
        title={
          selectedId !== -1 && selectedId !== 0
            ? this.getSelectedPositionName()
            : 'Fixture'
        }
        titleTo={null}
      >
        <button
          type="button"
          className="btn btn-raised"
          onClick={() => onCancel()}
          name="done"
        >
          Done
        </button>
      </SidebarTitle>
    )
  }

  renderSidebarForPositions() {
    const { positions, openPosition } = this.props

    return (
      <div className="h-100 flex-column d-flex">
        <SidebarBody className="h-100 flex-column d-flex">
          <TopContainer>
            {this.renderSidebarHeaderForPositions()}
            {this.renderSecurityList(positions, openPosition)}
          </TopContainer>
        </SidebarBody>
      </div>
    )
  }

  renderEditSidebarForPositions() {
    const {
      selectedPositionCount = 0,
      selectedAutoplacementMax,
      isAutoplacement,
    } = this.props

    return (
      <React.Fragment>
        {this.renderSidebarHeaderForPositions()}
        <SidebarSection>
          {isAutoplacement && (
            <Input
              name={'positionCount'}
              label={'# Positions'}
              value={selectedPositionCount}
              type={'number'}
              min={'0'}
              max={selectedAutoplacementMax}
              onChange={(e) => {
                this.onPositionCountChanged(parseInt(e.target.value, 10))
              }}
            />
          )}
        </SidebarSection>
      </React.Fragment>
    )
  }

  renderDeviceAndBaseControls() {
    const { selectedPosition = {}, onSecurityDevice } = this.props
    const { securityDevice, attachedDevice, attachedDeviceType } =
      selectedPosition || {}
    console.log(
      'assigned securityDevice',
      (securityDevice || {}).id,
      (securityDevice || {}).serialNumber
    )

    const friendlyAttachedDeviceType = friendlyDeviceType(attachedDeviceType)
    return (
      <React.Fragment>
        <SidebarRow>
          <Input
            name={'securityDevice'}
            label={'Security Device'}
            value={
              securityDevice
                ? `${friendlyDeviceType(
                  securityDevice.deviceType
                )} (Serial Number: ${securityDevice.serialNumber})`
                : 'None'
            }
            readOnly
          />
          <button
            type="button"
            className="btn btn-raised"
            onClick={() => onSecurityDevice(securityDevice || {})}
            name="editSecurityDevice"
          >
            Edit
          </button>
        </SidebarRow>
        {attachedDeviceType && (
          <Input
            name={'attachedDevice'}
            label={`Attached ${friendlyAttachedDeviceType}`}
            value={
              attachedDevice
                ? `Serial Number: ${attachedDevice.serialNumber}`
                : `${friendlyAttachedDeviceType} Not Identified`
            }
            readOnly
          />
        )}
      </React.Fragment>
    )
  }

  renderSecurePlugPorts() {
    const { selectedPosition = {}, onAssignPortProduct } = this.props
    const { securityDevice, portsState = [] } = selectedPosition

    return portsState.map((port = {}, index) => (
      <SidebarRow key={`port_${uuid.v4()}`}>
        <Input
          name={'securePlugPort'}
          label={`Port ${index + 1} - Assigned Product`}
          value={
            port.securedProductId
              ? (port.securedProduct || {}).productModel
              : 'None'
          }
          readOnly
        />
        <button
          type="button"
          className="btn btn-raised"
          onClick={() =>
            onAssignPortProduct(securityDevice, port.securedProduct, null, port)
          }
          name="editAssignedProduct"
        >
          Edit
        </button>
      </SidebarRow>
    ))
  }

  renderSecurePlugSection() {
    const { selectedPosition = {}, onSecurityDevice } = this.props
    const {
      securityDevice,
      securityDevice: {
        serialNumber: securitySerial,
        deviceType: securityDeviceType,
      } = {},
      ramDevice: { serialNumber: ramSerial } = {},
    } = selectedPosition

    return (
      <SidebarSection>
        <SidebarRow>
          <Input
            name={'securityDevice'}
            label={'Security Device'}
            value={
              securitySerial
                ? `${_.upperFirst(
                  _.lowerCase(securityDeviceType)
                )} (Serial Number: ${securitySerial})`
                : 'None'
            }
            readOnly
          />
          <button
            type="button"
            className="btn btn-raised"
            onClick={() => onSecurityDevice(securityDevice)}
          >
            Edit
          </button>
        </SidebarRow>
        <Input
          name={'attachedRam'}
          label={'Attached Remote Access Module (RAM)'}
          value={
            ramSerial ? `Serial Number: ${ramSerial}` : 'RAM Not Identified'
          }
          readOnly
        />
        {this.renderSecurePlugPorts()}
      </SidebarSection>
    )
  }

  renderCXFlexSection() {
    const { selectedPosition = {}, onSecurityDevice } = this.props
    const {
      securityDevice,
      securityDevice: { serialNumber: securitySerial } = {},
      ramDevice: { serialNumber: ramSerial } = {},
    } = selectedPosition

    return (
      <SidebarSection>
        <SidebarRow>
          <Input
            name={'securityDevice'}
            label={'Security Device'}
            value={
              securitySerial
                ? `CX Flex (Serial Number: ${securitySerial})`
                : 'None'
            }
            readOnly
          />
          <button
            type="button"
            className="btn btn-raised"
            onClick={() => onSecurityDevice(securityDevice)}
          >
            Edit
          </button>
        </SidebarRow>
        <Input
          name={'attachedRam'}
          label={'Attached Remote Access Module (RAM)'}
          value={
            ramSerial ? `Serial Number: ${ramSerial}` : 'RAM Not Identified'
          }
          readOnly
        />
        {this.renderCXFlexPorts()}
      </SidebarSection>
    )
  }

  renderCXFlexPorts() {
    const { selectedPosition = {}, onAssignPortProduct } = this.props
    const { securityDevice, portsState = [] } = selectedPosition

    // Though CX Flex has 10 ports, we're only supporting the first one at the moment
    // return portsState.map((port = {}, index) => (
    return portsState.map((port = {}, index) => (
      <SidebarRow key={`port_${uuid.v4()}`}>
        <Input
          name={'cxFlexPort'}
          label={`Port ${index + 1} - Assigned Product`}
          value={
            port.securedProductId
              ? (port.securedProduct || {}).productModel
              : 'None'
          }
          readOnly
        />
        <button
          type="button"
          className="btn btn-raised"
          onClick={() =>
            onAssignPortProduct(securityDevice, port.securedProduct, null, port)
          }
          name="editAssignedProduct"
        >
          Edit
        </button>
      </SidebarRow>
    ))
  }

  renderSidebarSection() {
    const { selectedPosition = {}, onAssignPortProduct } = this.props
    const {
      armDevice,
      armDevice: { serialNumber, deviceType } = {},
      baseDevice: { serialNumber: baseSerial } = {},
      portsState = [],
    } = selectedPosition

    const securedProduct = (portsState[0] || {}).securedProduct || {}
    const { productModel, productManufacturer, usbCapable } = securedProduct

    const isSecurePlug = deviceType === DeviceType.SECURE_PLUG
    const isCXFlex =
      deviceType === DeviceType.CX_FLEX ||
      deviceType === DeviceType.CX_FLEX_ONE_PORT ||
      deviceType === DeviceType.CX_FLEX_FOUR_PORT

    if (isSecurePlug) {
      return this.renderSecurePlugSection()
    } else if (isCXFlex) {
      return this.renderCXFlexSection()
    } else {
      return (
        <SidebarSection>
          {this.renderDeviceAndBaseControls()}
          {(serialNumber || baseSerial) &&
            deviceType &&
            this.isAllowableDeviceType(deviceType) && (
            <SidebarRow>
              <Input
                name={'assignedProduct'}
                label={'Assigned Product'}
                value={productModel || 'None'}
                readOnly
              />
              <button
                type="button"
                className="btn btn-raised"
                onClick={() => onAssignPortProduct(armDevice, securedProduct)}
                name="editAssignedProduct"
              >
                  Edit
              </button>
            </SidebarRow>
          )}
          {productManufacturer && (
            <Input
              name={'manufacturerName'}
              label={'Manufacturer'}
              value={productManufacturer}
              readOnly
            />
          )}
          {usbCapable && <InfoLabel>Product should charge</InfoLabel>}
        </SidebarSection>
      )
    }
  }

  renderSidebarForSelectedPosition() {
    const {
      isStatic,
      hasEditPermission,
      selectedPosition = {},
      onEdit,
      onEditDenied,
      onCancel,
    } = this.props
    const position = selectedPosition

    return (
      <React.Fragment>
        <SidebarTitle title={this.getSelectedPositionName()} titleTo={null}>
          {isStatic ? (
            <button
              type="button"
              className="btn btn-raised"
              onClick={() => (hasEditPermission ? onEdit() : onEditDenied())}
              name="editPosition"
            >
              Edit Position
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => onCancel()}
            >
              Cancel
            </button>
          )}
        </SidebarTitle>
        <SidebarBody className="h-100 flex-column d-flex">
          <TopContainer>
            {this.renderSecurityItem()}
            {this.renderSecurityStatusSince(position)}
            {this.renderDeviceStatusList(position)}
          </TopContainer>
          <TopBorderedContainer>{this.renderSidebarSection()}</TopBorderedContainer>
        </SidebarBody>
      </React.Fragment>
    )
  }

  renderSecurityStatusSince = (selectedPosition) => {
    if (!selectedPosition || !selectedPosition.status) return null
    const { status: { since, title } } = selectedPosition

    return (
      <CellContainer withoutBorder>
        <InfoLabel>{`${title} since: ${since || 'Unknown'}`}</InfoLabel>
      </CellContainer>
    )
  }

  renderDeviceStatusList = (selectedPosition) => (
    <DeviceStatusList>
      {this.renderPositionLatchState(selectedPosition)}
      {this.renderPositionHealthState(selectedPosition)}
    </DeviceStatusList>
  )

  renderPositionLatchState = (selectedPosition) => {
    const { armDevice } = selectedPosition || {}
    return (
      armDevice &&
      armDevice.deviceType === DeviceType.PUCK_V2 && (
        <CellContainer name="latchStatus">
          <InfoLabel>{`Latched Status: ${
            armDevice.puckLatched ? 'Latched' : 'Unlatched'
          }`}</InfoLabel>
        </CellContainer>
      )
    )
  }

  renderPositionHealthState = (selectedPosition) => {
    const {
      status: {
        states: {
          healthStateAction,
          healthStateTitle,
          healthStateType,
          healthStateSince,
          healthStateMessage,
        } = {},
      } = {},
    } =
      selectedPosition || {}

    return (
      <HealthStatusCell
        state={healthStateType}
        status={healthStateTitle}
        since={healthStateSince}
        action={healthStateAction}
        reason={healthStateMessage}
      />
    )
  }

  createDeleteBtnTooltip = (msg) => {
    this.deleteBtnRef.popover('dispose').popover({
      placement: 'bottom',
      trigger: 'hover',
      html: true,
      content: msg,
    })
  }

  renderEditSidebarForSelectedPosition() {
    const {
      selectedId,
      onCancel,
      selectedPositionName,
      onPositionRemove,
      onPositionSave,
    } = this.props

    const position = this.getSelectedPositionObject()
    const isCreation = position ? isNaN(position.id) : false
    const deleteDisabled = position != null && position.parentId != null
    const deleteDisabledText =
      'You cannot delete positions tied to the template.'

    // For the delete button, instead of actually disabling the
    // button, we just add a class, because bootstrap tooltips don't
    // work on disabled buttons.
    return (
      <div>
        <SidebarTitle>
          <BorderedButton
            type="button"
            className="btn btn-primary w-100 mr-2"
            onClick={() => onCancel()}
          >
            Cancel
          </BorderedButton>
          {!isCreation && (
            <FlatBorderedButton
              ref={(ref) => {
                if (deleteDisabled) {
                  this.deleteBtnRef = $(ref) // eslint-disable-line
                  this.createDeleteBtnTooltip(deleteDisabledText)
                }
              }}
              type="button"
              className={`${
                deleteDisabled ? 'disabled ' : ''
              }flat-bordered-button w-100 mr-2`}
              onClick={() => {
                if (deleteDisabled) {
                  return
                }
                onPositionRemove(selectedId)
                if (this.canvas) this.canvas.onDefault()
              }}
            >
              Delete
            </FlatBorderedButton>
          )}
          <button
            type="button"
            className="btn btn-raised w-100"
            disabled={this.state.isSaveDisabled}
            onClick={() => {
              onPositionSave(selectedId)
              if (this.canvas) this.canvas.onDefault()
            }}
          >
            Save
          </button>
        </SidebarTitle>
        <SidebarSection>
          <Input
            name={'positionName'}
            label={'Position Name'}
            value={selectedPositionName}
            onChange={(e) => {
              this.onNameChanged(e.target.value)
            }}
            pattern={safeUrlCharactersPattern}
            validationMessage={invalidUrlCharactersMessage}
            onChangeValidity={this.onChangeValidity}
          />
        </SidebarSection>
      </div>
    )
  }

  render() {
    const {
      canvasObject,
      selectedId,
      canvasSize,
      isStatic,
      isSilent,
      selectedAutoplacementMax,
      onChanged,
      onMaxPositions,
      positions,
      prototypes,
      onMessage,
      onPositionSave,
      onSaveAll,
      alerts,
    } = this.props
    const hasPositions = positions && positions.length !== 0
    const hasCanvasPositions =
      canvasObject && canvasObject.objects && canvasObject.objects.length > 1
    const hasNoGeometry =
      hasCanvasPositions &&
      canvasObject.objects[1] &&
      !canvasObject.objects[1].left
    return (
      <article className={styles.storeContainer}>
        <div className={styles.canvasContainer}>
          <PromptTable data={this.getPromptText()} />
          {isStatic && displayAlerts(alerts)}
          <div className={styles.fixtureCanvasFrame}>
            {!isSilent &&
              !hasPositions &&
              isStatic && (
              <EmptyCanvasLabel>
                  This Fixture has no Position data
              </EmptyCanvasLabel>
            )}
            {!isSilent &&
              hasNoGeometry &&
              isStatic && (
              <EmptyCanvasLabel>
                  Probably this Fixture has been created with different
                  environment
              </EmptyCanvasLabel>
            )}
            <div style={{ backgroundColor: '#D8D8D8' }}>
              <CanvasUpdate
                ref={(canvas) => {
                  this.canvas = (canvas || {}).canvas
                }}
                component={FixtureCanvas}
                size={canvasSize}
                canvasObject={canvasObject}
                selectedId={selectedId}
                prototypes={prototypes}
                mtiJsclientShared={mtiJsclientShared}
                maxPositions={selectedAutoplacementMax}
                isEditable={!isStatic}
                onChanged={onChanged}
                onMessage={onMessage}
                onMaxPositions={onMaxPositions}
                onMouseEnter={(id) => {
                  if (this[`issue${id}`]) this[`issue${id}`].onOver()
                }}
                onMouseLeave={(id) => {
                  if (this[`issue${id}`]) this[`issue${id}`].onOut()
                }}
                onCanvasPositionSave={(id) => onPositionSave(id)}
                onSaveAll={() => onSaveAll()}
              />
            </div>
          </div>
        </div>
        <div className={styles.rightStoreColumn}>
          <div className="h-100 flex-column d-flex">
            {isStatic // eslint-disable-line
              ? selectedId !== -1 && selectedId !== 0
                ? this.renderSidebarForSelectedPosition()
                : this.renderSidebarForPositions()
              : selectedId !== -1 && selectedId !== 0
                ? this.renderEditSidebarForSelectedPosition()
                : this.renderEditSidebarForPositions()}
          </div>
        </div>
      </article>
    )
  }
}

FixtureScreen.propTypes = {
  canvasSize: PropTypes.object.isRequired,
  canvasObject: PropTypes.object.isRequired,
  selectedId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  positions: PropTypes.any,
  prototypes: PropTypes.object,
  selectedPosition: PropTypes.object,
  selectedPositionName: PropTypes.string,
  selectedPositionCount: PropTypes.number,
  selectedAutoplacementMax: PropTypes.number,
  hasEditPermission: PropTypes.bool,
  fixtureName: PropTypes.string,
  isStatic: PropTypes.bool.isRequired,
  isSilent: PropTypes.bool,
  isAutoplacement: PropTypes.bool.isRequired,
  onChanged: PropTypes.func.isRequired,
  onNameChanged: PropTypes.func.isRequired,
  onMessage: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onEditDenied: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onPositionRemove: PropTypes.func.isRequired,
  onPositionSave: PropTypes.func.isRequired,
  onSaveAll: PropTypes.func.isRequired,
  onPositionCountChanged: PropTypes.func.isRequired,
  onMaxPositions: PropTypes.func.isRequired,
  onSecurityDevice: PropTypes.func.isRequired,
  onAssignPortProduct: PropTypes.func.isRequired,
  openPosition: PropTypes.func.isRequired,
}

export default FixtureScreen
