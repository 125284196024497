import { createSelector as createOrmSelector } from 'redux-orm'
import {
  orm,
  getStoreSecurityDeviceExceptionsById,
  getMissingSecuredProductsByStore,
} from 'mti-jsclient-shared'
import { getStorePositions } from '../../FloorsPage/selectors'

const dbStateSelector = (state) => state.get('orm')

const getPositionsListByStoreId = (storeId) =>
  createOrmSelector(orm, dbStateSelector, (session) => {
    try {
      if (!storeId) {
        return []
      }
      const store = session.Store.withId(storeId)
      const sortedPositions = getStorePositions(store)
      return sortedPositions
    } catch (e) {
      return []
    }
  })

const getSecurityDevicesWithExceptionsByStoreId = (storeId) =>
  createOrmSelector(orm, dbStateSelector, (session) => {
    try {
      if (storeId) {
        return getStoreSecurityDeviceExceptionsById(session, storeId)
      }
      return []
    } catch (e) {
      return []
    }
  })

const selectStoreId = (state, storeId) => storeId

const selectMissingSecuredProducts = createOrmSelector(
  orm,
  dbStateSelector,
  selectStoreId,
  (session, storeId) => {
    try {
      if (storeId) {
        return getMissingSecuredProductsByStore(session, storeId)
      }
      return []
    } catch (e) {
      console.log(e)
      return []
    }
  }
)

export {
  getPositionsListByStoreId,
  getSecurityDevicesWithExceptionsByStoreId,
  selectMissingSecuredProducts,
}
