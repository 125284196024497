import React from 'react'
import PropTypes from 'prop-types'
import ReactTable from 'react-table'
import moment from 'moment'
import styled from 'styled-components'
import { fullTextFilter, getTableHeight } from '../../utils/utils'
import {
  CellContainer,
  Header,
  HeaderContainer,
  renderFilterInput,
  renderHeaderCell,
} from '../UsersPage/index.screen'
import { scheduleDateFormat } from './utils'
import SchedulesPreloader from '../../components/Preloaders/SchedulesPreloader'

const formatOpenCloseTime = (data, day) => {
  const open = data[`${day}Open`]
  const close = data[`${day}Close`]

  if (!open || !close) {
    return 'Closed'
  }

  const openText = moment(open).format('HH:mm')
  const closeText = moment(close).format('HH:mm')
  return `${openText} - ${closeText}`
}

const renderMultiLineCell = (data) => {
  const text = [
    `Sun: ${formatOpenCloseTime(data, 'sunday')}`,
    `Mon: ${formatOpenCloseTime(data, 'monday')}`,
    `Tue: ${formatOpenCloseTime(data, 'tuesday')}`,
    `Wed: ${formatOpenCloseTime(data, 'wednesday')}`,
    `Thu: ${formatOpenCloseTime(data, 'thursday')}`,
    `Fri: ${formatOpenCloseTime(data, 'friday')}`,
    `Sat: ${formatOpenCloseTime(data, 'saturday')}`,
  ]

  return text
}

const SmallText = styled.span`
  @media (max-width: 1250px) {
    font-size: 13px;
  }
  @media (max-width: 1140px) {
    font-size: 11px;
  }
`

export class SchedulesPageScreen extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    window.setTimeout(() => this.handleResize(), 10)
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }
  /* eslint-disable no-undef */
  handleResize = () => this.setState({ tableHeight: getTableHeight() })

  render() {
    const { schedules, isAddScheduleLocked, onSchedule, loading } = this.props
    const { tableHeight } = this.state

    const columns = [
      {
        id: 'effectiveOn',
        sortable: true,
        width: 120,
        filterable: true,
        Header: renderHeaderCell('Effective On', true),
        Cell: ({ original, value }) => (
          <CellContainer>
            <a
              tabIndex="-1"
              role="button"
              className="pl-2"
              onClick={() => onSchedule(original)}
            >
              {value}
            </a>
          </CellContainer>
        ),
        accessor: ({ effectiveOn }) => scheduleDateFormat(effectiveOn),
        Filter: ({ filter, onChange }) =>
          renderFilterInput({
            placeholder: 'Filter',
            filter,
            onChange,
            id: 'effectiveOn',
          }),
      },
      {
        id: 'description',
        sortable: false,
        filterable: false,
        Header: renderHeaderCell('Description'),
        accessor: (original) => renderMultiLineCell(original).join(', '),
        Cell: ({ value }) => (
          <CellContainer>
            <SmallText>{value}</SmallText>
          </CellContainer>
        ),
      },
      {
        id: 'createdAt',
        sortable: true,
        width: 120,
        filterable: true,
        Header: renderHeaderCell('Created At', true),
        accessor: ({ createdAt }) => scheduleDateFormat(createdAt),
        Filter: ({ filter, onChange }) =>
          renderFilterInput({
            placeholder: 'Filter',
            filter,
            onChange,
            id: 'createdAt',
          }),
      },
    ]

    const { addSchedule } = this.props

    return (
      <div>
        <HeaderContainer className="border-bottom pr-3">
          <Header>Schedules</Header>
          <div>
            {!isAddScheduleLocked && (
              <button
                className="btn btn-primary btn-raised"
                onClick={addSchedule}
                name="addSchedule"
              >
                ADD NEW SCHEDULE
              </button>
            )}
          </div>
        </HeaderContainer>
        {loading ? (
          <SchedulesPreloader />
        ) : (
          <ReactTable
            className="full-screen"
            style={{ height: tableHeight || 'auto' }}
            data={schedules}
            columns={columns}
            defaultFilterMethod={fullTextFilter}
          />
        )}
      </div>
    )
  }
}

SchedulesPageScreen.propTypes = {
  schedules: PropTypes.array,
  isAddScheduleLocked: PropTypes.bool,
  onSchedule: PropTypes.func,
  addSchedule: PropTypes.func,
  loading: PropTypes.bool,
}

export default SchedulesPageScreen
