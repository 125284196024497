import React from 'react'
import PropTypes from 'prop-types'
import PositionRulesScreen from './PositionRules.screen'
import {
  SOURCE_ATTRIBUTES,
  PositionRuleSourceAttributesModel,
} from './ruleConstants'

class PositionRules extends React.PureComponent {
  constructor(props) {
    super(props)
    const { rule, products } = props

    let selectedProducts = []
    let deviceType = ''
    if (
      rule &&
      rule.parameter &&
      rule.sourceAttribute === SOURCE_ATTRIBUTES.security_device_type
    ) {
      deviceType = rule.parameter
    } else if (
      rule &&
      rule.parameter &&
      (rule.sourceAttribute === SOURCE_ATTRIBUTES.product_ids ||
        rule.sourceAttribute === SOURCE_ATTRIBUTES.live_product_ids)
    ) {
      selectedProducts = rule.parameter.map((productId) =>
        products.find(({ id }) => id === productId)
      )
    }

    this.state = {
      ruleType: rule ? rule.sourceAttribute : null,
      condition: rule ? rule.condition : null,
      param: null,
      selectedProducts,
      deviceType,
    }
  }

  /* eslint-disable no-undef */
  onChange = (data) => {
    this.setState({ ...data })
  }

  onAddSelectedProducts = (products) => {
    const { selectedProducts } = this.state
    const productsToAdd = [...new Set([...selectedProducts, ...products])]
    this.setState({
      selectedProducts: productsToAdd,
    })
  }

  onRemoveSelectedProducts = (products) => {
    this.setState({ selectedProducts: products })
  }

  onConfirm = () => {
    const { onConfirm } = this.props
    const { ruleType, condition } = this.state
    const { paramType } = PositionRuleSourceAttributesModel[ruleType]
    const data = this.getComplianceData()
    const rule = {
      condition,
      sourceAttribute: ruleType,
      parameterType: paramType,
      parameter: data,
    }

    onConfirm(rule)
  }

  getComplianceData = () => {
    const { ruleType, selectedProducts, deviceType } = this.state
    if (ruleType === SOURCE_ATTRIBUTES.security_device_type) {
      return deviceType
    }

    return selectedProducts.map(({ id }) => id)
  }

  render() {
    const {
      id,
      products,
      onClose,
      onDelete,
      onAddPortComplianceRules,
    } = this.props
    const {
      ruleType,
      condition,
      param,
      selectedProducts,
      deviceType,
    } = this.state

    return (
      <PositionRulesScreen
        id={id}
        ruleType={ruleType}
        condition={condition}
        products={products}
        selectedProducts={selectedProducts}
        deviceType={deviceType}
        param={param}
        onConfirm={this.onConfirm}
        onClose={onClose}
        onChange={this.onChange}
        onAddSelectedProducts={this.onAddSelectedProducts}
        onRemoveSelectedProducts={this.onRemoveSelectedProducts}
        onDelete={onDelete}
        onAddPortComplianceRules={onAddPortComplianceRules}
      />
    )
  }
}

PositionRules.propTypes = {
  id: PropTypes.string.isRequired,
  products: PropTypes.array.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  rule: PropTypes.object,
  onDelete: PropTypes.func,
  onAddPortComplianceRules: PropTypes.func,
}

export default PositionRules
