import keyMirror from 'fbjs/lib/keyMirror'

/**
 * @constant {Object} ActionTypes
 * @memberof Constants
 */
export const ActionTypes = keyMirror({
  EDIT_EMAIL: undefined,
  CLEAR_EMAIL: undefined,
  POST_FORGOT_PASSWORD_FAILED: undefined,
  POST_FORGOT_PASSWORD_FULFILLED: undefined,
  POST_FORGOT_PASSWORD_PENDING: undefined,
  POST_FORGOT_PASSWORD: undefined,
})
