import { Namespace } from './actions'

// The reason to store an empty array as a const is to reduce the number of page re-renders.
// Whenever a selector returns a different _instance_ of an object, it tells redux to
// re-render the component, even if that new object is equivalent.
const defaultPageData = {
  meta: {
    total: 0,
    pages: 1,
    page: 1,
    per: 100,
  },
  rows: [],
}

export const selectOrgID = state => {
  const orgAPIResponse = state.get('organization').get('organization') || {}
  const org = (orgAPIResponse.organizations || [])[0] || { }
  return org.id
}

export function selectLoading(state) {
  return state.get(Namespace).get('Loading') || false
}

export function selectData(state) {
  return state.get(Namespace).get('Data') || defaultPageData
}
