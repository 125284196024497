import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ReactTable from 'react-table'
import checkboxHOC from 'react-table/lib/hoc/selectTable'
import moment from 'moment'
import Checkbox from '../../components/Switch'
import AssignUser from '../../components/AssignUser'
import TrComponent from '../../components/TrComponent'
import {
  CellContainer,
  HeaderContainer,
  Header,
  renderFilterInput,
  renderHeaderCell,
} from '../UsersPage/index.screen'
import { fullTextFilter, getTableHeight } from '../../utils/utils'
import ShowBackupKeys from '../../components/BackupKeys/Keys/ShowKeys'
import {
  keyStatusFilter,
  keyStatusComponent,
  keyUserSort,
  keyUserNameFilter,
} from './utils'
import ProgressIndicator from '../../components/ProgressIndicator'
import TablePreloader from '../../components/Preloaders/TablePreloader'

const CheckboxTable = checkboxHOC(ReactTable)

const HeaderButtonsContainer = styled.div`
  margin-right: 12px;
`

class KeysPageScreen extends React.Component {
  constructor(props) {
    super(props)
    const columns = this.getColumns()
    this.state = {
      columns,
      selection: [],
      selectAll: false,
      showBackupKeys: props.showBackupKeys,
    }
  }

  componentDidMount() {
    window.setTimeout(() => this.handleResize(), 10)
    window.addEventListener('resize', this.handleResize)
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.keys) === JSON.stringify(this.props.keys)) {
      return
    }

    const columns = this.getColumns()
    /* eslint-disable react/no-did-update-set-state */
    this.setState({
      columns,
      selection: [],
      selectAll: false,
    })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  compareByKey = (a, b, key) => a[key] - b[key]

  sortStickyBackupKeys = (a, b, isDesc, compare = keyUserSort) => {
    if (isDesc) {
      if (a.backupKey || b.backupKey) {
        return -1
      }
      return typeof compare === 'string'
        ? this.compareByKey(a, b, compare)
        : compare(a, b)
    } else {
      const result = (b.backupKey || false) - (a.backupKey || false)
      if (result === 0) {
        return typeof compare === 'string'
          ? this.compareByKey(a, b, compare)
          : compare(a, b)
      }
      return result
    }
  }

  expiresColumn = {
    id: 'expirationDate',
    sortable: true,
    filterable: true,
    Header: renderHeaderCell('Expires', true),
    accessor: ({ expirationDate, backupKey }) => ({
      expirationDate,
      backupKey,
    }),
    Filter: ({ filter, onChange }) =>
      renderFilterInput({
        placeholder: 'Filter',
        filter,
        onChange,
      }),
    Cell: ({ original: { expirationDate, backupKey } }) => {
      if (!backupKey) {
        return expirationDate
          ? moment(expirationDate).format('MM/DD/YYYY H:mm')
          : ''
      } else {
        return (
          <span className="text-muted small text-center w-100">
            {'NO EXPIRATION'}
          </span>
        )
      }
    },
    filterMethod: (filter, { expirationDate }) =>
      fullTextFilter(filter, expirationDate),
    sortMethod: (a, b, isDesc) =>
      this.sortStickyBackupKeys(a, b, isDesc, 'expirationDate'),
  }

  getColumns = () => {
    const {
      onActivate,
      onAssign,
      onUnassign,
      onProfile,
      showExpirationDate,
    } = this.props
    const columns = [
      {
        id: 'serialNumber',
        filterable: true,
        Header: renderHeaderCell('Serial Number', true),
        accessor: ({ serialNumber, backupKey }) => ({
          serialNumber,
          backupKey,
        }),
        width: 200,
        Cell: (
          { original: { serialNumber, userName } } // eslint-disable-line
        ) => (
          <CellContainer id={serialNumber} name={`key_${userName}`}>
            {serialNumber}
          </CellContainer>
        ),
        Filter: ({ filter, onChange }) =>
          renderFilterInput({
            placeholder: 'Filter',
            filter,
            onChange,
            id: 'serialNumber',
          }),
        filterMethod: (filter, { serialNumber }) =>
          fullTextFilter(filter, serialNumber),
        sortMethod: (a, b, isDesc) =>
          this.sortStickyBackupKeys(a, b, isDesc, 'serialNumber'),
      },
      {
        filterable: true,
        Header: renderHeaderCell('User'),
        Cell: ({ original }) => {
          if (original.backupKey) {
            return (
              <span className="text-muted small text-center w-100">
                {"CAN'T BE ASSIGNED"}
              </span>
            )
          }

          return (
            <AssignUser
              item={original}
              onAssign={onAssign}
              onUnassign={onUnassign}
              onProfile={onProfile}
            />
          )
        },
        id: 'userName',
        accessor: ({ serialNumber, userName, backupKey }) => ({
          serialNumber,
          userName,
          backupKey,
        }),
        filterMethod: (filter, row) => keyUserNameFilter(filter, row),
        // sortMethod: (a, b) => keyUserSort(a, b),
        Filter: ({ filter, onChange }) =>
          renderFilterInput({
            placeholder: 'Filter',
            filter,
            onChange,
            id: 'user',
          }),
        sortMethod: (a, b, isDesc) =>
          this.sortStickyBackupKeys(a, b, isDesc, keyUserSort),
      },
      {
        id: 'status',
        sortable: true,
        filterable: true,
        accessor: ({ status, backupKey }) => ({ status, backupKey }),
        Header: renderHeaderCell('Status'),
        className: 'justify-content-center',
        width: 140,
        Cell: ({ original }) => {
          // eslint-disable-line
          const { status, serialNumber, backupKey } = original
          if (backupKey) return <span />

          return (
            <CellContainer>
              <Checkbox
                onChange={(checked) => onActivate(original, checked)}
                checked={status === 1}
                id={`${serialNumber}_toggle`}
              />
            </CellContainer>
          )
        },
        filterMethod: (filter, row) => keyStatusFilter(filter, row),
        Filter: ({ filter, onChange }) => keyStatusComponent(filter, onChange),
        sortMethod: (a, b, isDesc) =>
          this.sortStickyBackupKeys(a, b, isDesc, 'status'),
      },
      {
        id: 'createdAt',
        sortable: true,
        filterable: true,
        Header: renderHeaderCell('First Added', true),
        accessor: ({ createdAt, backupKey }) => ({ createdAt, backupKey }),
        Cell: ({ original: { createdAt } }) => (
          <CellContainer>{createdAt}</CellContainer>
        ),
        Filter: ({ filter, onChange }) =>
          renderFilterInput({
            placeholder: 'Filter',
            filter,
            onChange,
            id: 'firstAdded',
          }),
        filterMethod: (filter, { createdAt }) =>
          fullTextFilter(filter, createdAt),
        sortMethod: (a, b, isDesc) =>
          this.sortStickyBackupKeys(a, b, isDesc, 'createdAt'),
      },
      {
        id: 'updatedAt',
        sortable: true,
        filterable: true,
        Header: renderHeaderCell('Last Seen', true),
        accessor: ({ updatedAt, backupKey }) => ({ updatedAt, backupKey }),
        Cell: ({ original: { updatedAt } }) => (
          <CellContainer>{updatedAt}</CellContainer>
        ),
        Filter: ({ filter, onChange }) =>
          renderFilterInput({
            placeholder: 'Filter',
            filter,
            onChange,
            id: 'lastSeen',
          }),
        filterMethod: (filter, { updatedAt }) =>
          fullTextFilter(filter, updatedAt),
        sortMethod: (a, b, isDesc) =>
          this.sortStickyBackupKeys(a, b, isDesc, 'updatedAt'),
      },
    ]

    if (showExpirationDate) {
      columns.splice(3, 0, this.expiresColumn)
    }

    return columns
  }

  getSelectedKeys = () => {
    const { keys } = this.props
    const { selection } = this.state
    return keys.filter(
      (key) => !!selection.find((id) => id === key.serialNumber)
    ) // eslint-disable-line
  }

  handleResize = () => this.setState({ tableHeight: getTableHeight() })

  toggleAll = () => {
    /*
    Selection of visible (filtered) rows.
    */
    const selectAll = !this.state.selectAll
    const selection = []
    if (selectAll) {
      // we need to get at the internals of ReactTable
      const wrappedInstance = this.checkboxTable.getWrappedInstance()
      // the 'sortedData' property contains the currently accessible records based on the filter and sort
      const currentRecords = wrappedInstance.getResolvedState().sortedData
      // we just push all the IDs onto the selection array
      currentRecords.forEach((item) => {
        selection.push(item._original.serialNumber) // eslint-disable-line
      })
    }
    this.setState({ selectAll, selection })
  }

  isSelected = (key) => this.state.selection.includes(key)

  toggleSelection = (key) => {
    // start off with the existing state
    let selection = [...this.state.selection]
    const keyIndex = selection.indexOf(key)
    // check to see if the key exists
    if (keyIndex >= 0) {
      // it does exist so we will remove it using destructing
      selection = [
        ...selection.slice(0, keyIndex),
        ...selection.slice(keyIndex + 1),
      ]
    } else {
      // it does not exist so add it
      selection.push(key)
    }
    // update the state
    this.setState({ selection })
  }

  render() {
    const {
      onAddKey,
      onCreateUser,
      onActivateAll,
      onDeleteAll,
      loading,
      isBackupKeyEnabled,
      backupKeysDisabledMessage,
      hasDestroyKeyPermission,
      hasDestroyBackupKeyPermission,
      keys,
    } = this.props

    const { toggleSelection, toggleAll, isSelected } = this
    const {
      selectAll,
      columns,
      selection,
      tableHeight,
      showBackupKeys,
    } = this.state

    let displayedKeys = (keys || []).filter((key) => {
      if (showBackupKeys) {
        return true
      }

      return !key.backupKey
    })

    const checkboxProps = {
      selectAll,
      isSelected,
      toggleSelection,
      toggleAll,
      selectType: 'checkbox',
      getTrProps: (s, r) => {
        // background color change
        const selected = this.isSelected(
          ((r || {}).original || {}).serialNumber
        ) // eslint-disable-line
        return {
          style: {
            backgroundColor: selected ? 'lightgreen' : 'inherit',
            // color: selected ? 'white' : 'inherit',
          },
        }
      },
    }

    const showBatchActions = selection && selection.length !== 0
    const selectedKeys = showBatchActions ? this.getSelectedKeys() : []
    const containsBackupKeys =
      selectedKeys.filter(({ backupKey }) => backupKey).length > 0
    const deleteEnabled =
      showBatchActions &&
      hasDestroyKeyPermission &&
      (!containsBackupKeys || hasDestroyBackupKeyPermission)

    const width = window.innerWidth
    return (
      <div>
        <HeaderContainer className="border-bottom">
          <Header>Keys</Header>
          <HeaderButtonsContainer>
            <ShowBackupKeys
              disabled={!isBackupKeyEnabled}
              checked={showBackupKeys}
              onChange={(checked) => {
                this.setState({ showBackupKeys: checked })
              }}
              message={backupKeysDisabledMessage}
            />
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => onActivateAll(selectedKeys, true)}
              disabled={!showBatchActions || containsBackupKeys}
              aria-disabled={!showBatchActions || containsBackupKeys}
            >
              Activate selected
            </button>
            <button
              style={{ color: '#FFFFFF !important' }}
              type="button"
              className="btn btn-primary"
              onClick={() => onActivateAll(selectedKeys, false)}
              disabled={!showBatchActions || containsBackupKeys}
              aria-disabled={!showBatchActions || containsBackupKeys}
            >
              Deactivate selected
            </button>
            <button
              style={{ color: '#FFFFFF !important' }}
              type="button"
              className="btn btn-primary"
              onClick={() => onDeleteAll(selectedKeys)}
              disabled={!deleteEnabled}
              aria-disabled={!deleteEnabled}
            >
              Delete selected
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={onCreateUser}
              disabled={showBatchActions}
              aria-disabled={showBatchActions}
            >
              Add New User
            </button>
            <button
              type="button"
              className="btn btn-primary btn-raised"
              onClick={onAddKey}
              disabled={showBatchActions}
              aria-disabled={showBatchActions}
              name="addKey"
            >
              Add Key
            </button>
          </HeaderButtonsContainer>
        </HeaderContainer>
        {loading ? (
          <React.Fragment>
            <TablePreloader columnsCount={6} loaderWidth={width} />
            <ProgressIndicator text={'Loading...'} />
          </React.Fragment>
        ) : (
          <CheckboxTable
            style={{ height: tableHeight || 'auto' }}
            ref={(r) => {
              this.checkboxTable = r
            }}
            data={displayedKeys}
            columns={columns}
            className="full-screen -striped -highlight"
            defaultFilterMethod={fullTextFilter}
            defaultPageSize={100}
            showPaginationBottom
            TrComponent={TrComponent}
            keyField="serialNumber"
            defaultSorted={[
              {
                id: 'userName',
                desc: false,
              },
            ]}
            {...checkboxProps}
          />
        )}
      </div>
    )
  }
}

KeysPageScreen.propTypes = {
  keys: PropTypes.any,
  onAddKey: PropTypes.func.isRequired,
  onActivate: PropTypes.func.isRequired,
  onAssign: PropTypes.func.isRequired,
  onUnassign: PropTypes.func.isRequired,
  onProfile: PropTypes.func.isRequired,
  onCreateUser: PropTypes.func.isRequired,
  onActivateAll: PropTypes.func.isRequired,
  onDeleteAll: PropTypes.func.isRequired,
  hasDestroyBackupKeyPermission: PropTypes.bool.isRequired,
  hasDestroyKeyPermission: PropTypes.bool.isRequired,
  showExpirationDate: PropTypes.bool,
  isBackupKeyEnabled: PropTypes.bool,
  backupKeysDisabledMessage: PropTypes.string.isRequired,
}

export default KeysPageScreen
