import { createSelector } from 'reselect'

const selectApp = (state) => state.get('app')

const makeSelectSchedules = () =>
  createSelector(selectApp, (app) => app.get('schedules'))

const makeSelectFailed = () =>
  createSelector(selectApp, (app) => app.get('schedulesFailed'))

const makeSelectLoading = () =>
  createSelector(selectApp, (app) => app.get('schedulesLoading'))

export { selectApp, makeSelectSchedules, makeSelectFailed, makeSelectLoading }
