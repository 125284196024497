/**
 *
 * CanvasUpdate component
 *
 */

import React from 'react'

class CanvasUpdate extends React.Component {
  shouldComponentUpdate() {
    if (this.canvas && this.canvas.isInteractive()) {
      return false
    }
    return true
  }

  render() {
    return (
      <this.props.component
        ref={(canvas) => (this.canvas = canvas)}
        {...this.props}
      />
    )
  }
}

export default CanvasUpdate
