import {
  sviFigureType,
  getMtiSviFixtureType,
  sviFigureSizeType,
  defaultName,
} from '../../utils/mtiCanvasUtils'
import { toFabricFixtureSync } from './utils'

export function applyTemplateForCanvasObject(
  object,
  template,
  screen,
  isStatic,
  isMobile,
  prototypes,
  mtiJsclientShared
) {
  if (!object) return object
  if (!template) return object
  const o = fromFabricFixtureSave(addMtiType(object), screen)
  const { id } = o
  const { xCenter, yCenter } = o // layoutPosition
  const {
    width: tWidth,
    height: tHeight,
    size: tSize,
    type: tType,
    theta: tTheta,
  } = template.layoutPosition
  const fixture = toFabricFixtureSync(
    {
      ...template,
      id,
      parentId: template.id,
      layoutPosition: {
        width: tWidth, // width,
        height: tHeight, // height: tHeight / tWidth * height,
        theta: tTheta,
        xCenter,
        yCenter,
        size: tSize,
        type: tType,
      },
    },
    screen,
    isStatic,
    isMobile,
    prototypes,
    mtiJsclientShared
  )
  return fixture
}

export function fromFabricFixtureSave(o, screen) {
  if (!o) return o
  console.log('o.sviType, o.mtiType', o.sviType, o.mtiType)

  const w = o.width * o.scaleX
  const h = o.height * o.scaleY
  const cX = o.left
  const cY = o.top
  let x = cX / screen.width
  let y = cY / screen.width
  if (x > 1) x = 0.99
  if (y > 1) y = 0.99

  const attrs = {
    id: o.id,
    height: h / screen.width,
    width: w / screen.width,
    theta: o.angle,
    xCenter: x,
    yCenter: y,
    name: o.name || defaultName.fixture,
    type: o.mtiType,
    sviType: o.sviType || sviFigureType.custom,
    sviSizeType: o.sviSizeType || sviFigureSizeType.custom,
    angle: o.angle || 0,
    positionsCount: o.positionsCount || 0,
    fixtureIssuesCount: o.fixtureIssuesCount || 0,
    isFurniture: o.isFurniture || false,
    nameHasShown: o.nameHasShown === undefined ? true : !!o.nameHasShown,
    applyFutureTemplateUpdates: o.applyFutureTemplateUpdates,
    parentId: o.parentId,
  }

  return attrs
}

// We use new svi types for fixture canvas object.
// For existing logic support, we need to provide mti analog of the type
export function addMtiType(o) {
  if (!o) return o
  const { mtiType } = getMtiSviFixtureType(o.sviType)
  console.log('addMtiType to fixture o.sviType, mtiType', o.sviType, mtiType)
  return { ...o, mtiType }
}
