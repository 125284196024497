import React from 'react'
import PropTypes from 'prop-types'
import Issue from '../../../components/Issue'
import { createStoreSecuredProductsLink } from '../../../utils/mtiUtils'
import { SecuredProductCategory } from 'mti-jsclient-shared'

export function SecuredProductsExceptions({
  securedProducts,
  onGoToSecuredProducts,
}) {
  const goToSecuredProducts = () => {
    // onGoToSecuredProducts function is passed here as a prop
    // then called with navigateToPath to avoid having to repeat
    // this function in each container component or having to pass
    // store and history props to each view component
    onGoToSecuredProducts(navigateToPath)
  }

  const navigateToPath = (store, history) => {
    const { path } = createStoreSecuredProductsLink(store)
    history.replace({
      pathname: `/${path}`,
      state: { category: SecuredProductCategory.MISSING },
    })
  }

  if (securedProducts) {
    return securedProducts.map(securedProduct => <Issue
      key={`secured-product-exception-${securedProduct.id}`}
      issue={{
        id: securedProduct.id,
        title: securedProduct.name,
        device: securedProduct.manufacturerName,
        statusTitle: securedProduct.status.typeDisplay,
      }}
      openPosition={goToSecuredProducts}
    />)
  }
  return []
}

SecuredProductsExceptions.propTypes = {
  securedProducts: PropTypes.array,
  onGoToSecuredProducts: PropTypes.func.isRequired,
}
