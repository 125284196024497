/*
 *
 * Region reducer
 *
 */

import { fromJS } from 'immutable'
import { ActionTypes } from './constants'
import { createRegions } from '../RegionsPage/regions'

const initialState = fromJS({
  regions: null,
  failed: null,
  loading: false,
})

function regionEditReducer(state = initialState, { type, payload }) {
  switch (type) {
    case ActionTypes.REGION_DETAILS:
      return state
        .set('regionDetails', payload)
        .set('management', ActionTypes.REGION_DETAILS)
    case ActionTypes.CREATE_REGION:
      return state
        .set('regionDetails', undefined)
        .set('management', ActionTypes.CREATE_REGION)
    case ActionTypes.RESET_REGION_MANAGEMENT:
      return state.set('regionDetails', undefined).set('management', undefined)
    case ActionTypes.DELETE_REGION_FINISHED:
      return state.set('loading', false).set('failed', null)
    case ActionTypes.DELETE_REGION_PENDING:
      return state.set('failed', null).set('loading', true)
    case ActionTypes.DELETE_REGION_FAILED:
      return state.set('failed', payload).set('loading', false)
    case ActionTypes.POST_REGION_FULFILLED:
      return state.set('failed', null).set('loading', false)
    case ActionTypes.POST_REGION_PENDING:
      return state.set('failed', null).set('loading', true)
    case ActionTypes.POST_REGION_FAILED:
      return state.set('failed', payload).set('loading', false)
    case ActionTypes.PATCH_REGION_FULFILLED:
      return state.set('failed', null).set('loading', false)
    case ActionTypes.PATCH_REGION_PENDING:
      return state.set('failed', null).set('loading', true)
    case ActionTypes.PATCH_REGION_FAILED:
      return state.set('failed', payload).set('loading', false)
    case ActionTypes.FIND_REGION_FULFILLED: {
      const regions = createRegions(payload.regions)
      const region = (regions || [{}])[0] || {}
      if (!region.id) {
        console.warn('REGION NOT FOUND')
        return state
      }
      return state
        .set('regionFound', region)
        .set('loading', false)
        .set('failed', null)
    }
    case ActionTypes.FIND_REGION_PENDING:
      return state.set('failed', null).set('loading', true)
    case ActionTypes.FIND_REGION_FAILED:
      return state.set('failed', payload).set('loading', false)
    case ActionTypes.FIND_REGION_FOUND_HANDLED:
      return state.set('regionFound', undefined)
    case ActionTypes.REGION_EDIT: {
      const regionsState = state
        .set('assignedStores', [])
        .set('removedStores', [])

      return state.mergeDeep(regionsState, payload)
    }
    case ActionTypes.REGION_CLEAR:
      return state.delete('name').delete('storeIds')
    default:
      return state
  }
}

export default regionEditReducer
