import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import InfoIcon from '../../../../images/svg/buttons/info.svg'
import Checkbox from '../../../Checkbox'
import ErrorIcon from '../../../../images/svg/buttons/error.svg'

const Container = styled.div``
const Label = styled.span`
  color: #666;
  font-size: 0.875rem;
  line-height: 0.875rem;
  height: 0.875rem;
  vertical-align: top;
  display: inline-block;
  padding-top: 6px;
`
const CheckboxContainer = styled.div`
  line-height: 0.875rem;
  height: 0.875rem;
  vertical-align: top;
  padding-top: 4px;
`

class AddBackupKey extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      checked: props.checked || false,
    }
  }

  tooltipIcon = () => {
    const { maxBackupKeys } = this.props
    if (maxBackupKeys) {
      return this.warnIcon()
    }

    return this.infoIcon()
  }

  warnIcon() {
    const text = this.tooltipText()
    return (
      <img
        ref={(ref) => this.tooltip(ref, text)}
        className="ml-2 mb-2"
        src={ErrorIcon}
        height="26"
        alt={text}
      />
    )
  }

  infoIcon() {
    const text = this.tooltipText()
    return (
      <img
        ref={(ref) => this.tooltip(ref, text)}
        className="ml-2 mb-2"
        src={InfoIcon}
        height="26"
        alt={text}
      />
    )
  }

  tooltip = (element, msg) => {
    $(element) // eslint-disable-line
      .popover('dispose')
      .popover({
        placement: 'bottom',
        trigger: 'hover focus',
        html: true,
        content: msg,
      })
  }
  onChange = ({ target: { checked } }) => {
    this.setState({ checked })
    this.props.onChange(checked)
  }

  tooltipText = () => {
    const { keysDisabled, maxBackupKeys, disabled, isKeysLimited } = this.props

    if (disabled) {
      if (isKeysLimited) {
        return `Number of Backup Keys for this store is limited to ${maxBackupKeys}.<br><br>To add a new key, please delete an existing one or <br> contact your Organization Administrator <br> to increase the limit.`
      }
      return 'Key In Use'
    } else if (keysDisabled) {
      return 'Manager Keys are NOT enabled for this Store.<p>Contact your Regional Manager or Organization Administrator to enable Manager Keys</p>'
    } else {
      return [
        '<div>- Backup Key does <span class="font-weight-bold">NOT</span> have expiration time</div>',
        '<div>- Backup Key can <b>NOT</b> be edited or changed</div>',
        '<div>- Backup Key can <b>NOT</b> be deactivated temporally</div>',
        '<div>- Backup Key can be created by a Store Manager and up</div>',
        '<div>- Backup Key can be deleted by an Organization Admin</div>',
      ].join('')
    }
  }

  labelText = () => {
    const { keysDisabled, maxBackupKeys, isKeysLimited } = this.props
    const { checked } = this.state

    if (isKeysLimited) {
      return `Only ${maxBackupKeys} Backup keys allowed`
    }

    if (keysDisabled) {
      return 'Backup Keys are NOT enabled'
    }

    if (checked) {
      return 'Backup key - Uncheck for Staff key'
    }

    return 'Check to set as Backup key'
  }

  render() {
    const { disabled, id } = this.props
    const { checked } = this.state
    return (
      <Container className="d-inline-block ml-2">
        <CheckboxContainer className="d-inline-block">
          <Checkbox
            name={`set-backup-key-${id}`}
            onChange={this.onChange}
            checked={checked}
            disabled={disabled}
          />
        </CheckboxContainer>
        <Label disabled={disabled} className="mx-1">
          {this.labelText()}
        </Label>
        <span
          ref={(ref) => (this.container = ref)}
          className="align-items-center d-inline-flex"
        />
        {this.tooltipIcon()}
      </Container>
    )
  }
}

AddBackupKey.propTypes = {
  keysDisabled: PropTypes.bool,
  maxBackupKeys: PropTypes.number,
  isKeysLimited: PropTypes.bool,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  id: PropTypes.number.isRequired,
}

export default AddBackupKey
