import React from 'react'
import PropTypes from 'prop-types'
import ReactTable from 'react-table'
import styled from 'styled-components'
import TrComponent from '../../components/TrComponent'
import Checkbox from '../../components/Switch'
import AssignUser from '../../components/AssignUser'
import {
  CellContainer,
  renderFilterInput,
  renderHeaderCell,
} from '../UsersPage/index.screen'
import { fullTextFilter } from '../../utils/utils'
import {
  keyStatusFilter,
  keyStatusComponent,
  keyUserSort,
  keyUserNameFilter,
} from './utils'

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 120px;
`

export const TextTop = styled.span`
  font-size: 14px;
  text-align: left;
  width: 120px;
`

export const TextBottom = styled.span`
  font-size: 12px;
  text-align: left;
  width: 120px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

/* eslint-disable react/prop-types, no-underscore-dangle */
const KeysPageScreen = ({
  keys,
  height,
  onActivate,
  onAssign,
  onUnassign,
  onProfile,
}) => {
  const columns = [
    {
      sortable: true,
      filterable: true,
      Header: renderHeaderCell('User'),
      Cell: ({ original }) => (
        <Column>
          <TextTop>
            <AssignUser
              item={original}
              onAssign={onAssign}
              onUnassign={onUnassign}
              onProfile={onProfile}
            />
          </TextTop>
          <TextBottom>{(original || {}).serialNumber}</TextBottom>
        </Column>
      ),
      id: 'userName',
      accessor: ({ serialNumber, userName }) => ({ serialNumber, userName }),
      filterMethod: (filter, row) => keyUserNameFilter(filter, row),
      sortMethod: (a, b) => keyUserSort(a, b),
      Filter: ({ filter, onChange }) =>
        renderFilterInput({
          placeholder: 'Filter',
          filter,
          onChange,
          id: 'userName',
        }),
    },
    {
      sortable: true,
      filterable: true,
      className: 'justify-content-center',
      Header: renderHeaderCell('Status'),
      Cell: ({ original }) => {
        const { id, status } = original
        return (
          <CellContainer>
            <Checkbox
              id={`${id}`}
              checked={status === 1}
              onChange={(checked) => onActivate(original, checked)}
            />
          </CellContainer>
        )
      },
      filterMethod: (filter, row) => keyStatusFilter(filter, row),
      Filter: ({ filter, onChange }) => keyStatusComponent(filter, onChange),
    },
    {
      sortable: true,
      filterable: true,
      Header: renderHeaderCell('Last Seen', true),
      accessor: 'updatedAt',
      Cell: ({ original: { updatedAt } }) => (
        <CellContainer>{updatedAt}</CellContainer>
      ),
      Filter: ({ filter, onChange }) =>
        renderFilterInput({
          placeholder: 'Filter',
          filter,
          onChange,
          id: 'updatedAt',
        }),
    },
  ]
  return (
    <div>
      <ReactTable
        className="full-screen"
        style={{ height: height || 'auto' }}
        data={keys}
        columns={columns}
        defaultFilterMethod={fullTextFilter}
        TrComponent={TrComponent}
        defaultSorted={[
          {
            id: 'userName',
            desc: false,
          },
        ]}
      />
    </div>
  )
}

KeysPageScreen.propTypes = {
  keys: PropTypes.any,
  onActivate: PropTypes.func.isRequired,
  onAssign: PropTypes.func.isRequired,
  onUnassign: PropTypes.func.isRequired,
  onProfile: PropTypes.func.isRequired,
}

export default KeysPageScreen
