import React from 'react'
import PropTypes from 'prop-types'
import AssignProductScreen from './assignProduct.screen'

class AssignProduct extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      name: props.name,
      selectedProduct: props.selectedProduct,
      products: props.products,
    }
  }

  /* eslint-disable no-undef */
  onChange = (data) => {
    this.setState({ ...data })
  }

  onConfirm = () => {
    const { onConfirm } = this.props
    const { selectedProduct } = this.state
    onConfirm(selectedProduct)
  }

  onUnassign = () => {
    const { onConfirm } = this.props
    onConfirm(null)
  }

  render() {
    const {
      id,
      onClose,
      manufacturers,
      onAddNewProduct,
      onDeleteProduct,
      selectedPosition,
    } = this.props
    const { name, selectedProduct, products } = this.state

    return (
      <AssignProductScreen
        id={id}
        name={name}
        products={products}
        manufacturers={manufacturers}
        selectedProduct={selectedProduct}
        selectedPosition={selectedPosition}
        onUnassign={this.onUnassign}
        onConfirm={this.onConfirm}
        onClose={onClose}
        onChange={this.onChange}
        onAddNewProduct={onAddNewProduct}
        onDeleteProduct={onDeleteProduct}
      />
    )
  }
}

AssignProduct.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onAddNewProduct: PropTypes.func.isRequired,
  onDeleteProduct: PropTypes.func.isRequired,
  selectedProduct: PropTypes.object,
  manufacturers: PropTypes.array,
  products: PropTypes.array,
  selectedPosition: PropTypes.object,
}

export default AssignProduct
