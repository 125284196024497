import React from 'react'
import PropTypes from 'prop-types'
import DoublePasswordsInput from '../../components/DoublePassword'
import DoublePincodeInput from '../../components/DoublePincode'
import styled from 'styled-components'
import NotifyModal from '../ModalContainer/NotifyModal'
import { setServerError, setServerErrorDismiss } from '../../utils/validation'

export const ModalBody = styled.div`
  padding: 10px;
`
export class PasswordSetScreen extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {}
  }

  setValidationErrors(validationErrors) {
    if (validationErrors.password) {
      const msg = validationErrors.password[0]
      const element = this.password
      setServerError(element, msg)
    }
    if (validationErrors.pincode) {
      const msg = validationErrors.pincode[0]
      const element = this.pincode
      setServerError(element, msg)
    }
    if (validationErrors.passwordResetToken) {
      const msg = validationErrors.passwordResetToken[0]
      const element = this.pincode
      setServerError(element, msg)
    }
  }

  setServerValidity(error) {
    if (error && error.error) {
      const validationErrors = error.error.validationErrors
      if (validationErrors) {
        this.setValidationErrors(validationErrors)
      } else {
        // another server error
        console.log(error)
      }
    }
  }

  infoTooltip = (element, msg) => {
    $(element) // eslint-disable-line
      .popover('dispose')
      .popover({
        placement: 'bottom',
        trigger: 'hover focus',
        content: msg,
      })
  }

  showModal = () => {
    const { openModal, closeModal, onReturnToLogin } = this.props
    openModal({
      id: 'password-created',
      type: 'bootstrap',
      appearance: 'modal-sm',
      content: (
        <NotifyModal
          id={'password-created'}
          notifyText="Congratulations! Your password and pincode were successfully created!"
          confirmText={'RETURN TO LOGIN PAGE'}
          onClose={() => {
            closeModal({ id: 'password-created' })
            onReturnToLogin()
          }}
        />
      ),
    })
  }

  showSuccess = () => {
    const { onReturnToLogin } = this.props
    return (
      <div className="d-flex align-items-center flex-column">
        <div className="card mt-4 w-75">
          <div className="card-body">
            <h6 className="card-title">
              Congratulations! Your password and pincode were successfully
              created!
            </h6>
            <button
              type="submit"
              className="btn btn-primary btn-raised"
              style={{ marginTop: '1rem' }}
              onClick={onReturnToLogin}
            >
              RETURN TO LOGIN PAGE
            </button>
          </div>
        </div>
      </div>
    )
  }

  renderMobileRedirect = () => {
    const { mobileUri } = this.props
    return (
      <div
        className="rounded p-3 mb-5 bg-light text-dark"
        style={{ width: '200px', margin: 'auto' }}
      >
        <div>Reset Password in Your MTI Mobile App</div>
        <a
          href={mobileUri}
          className="btn btn-primary active"
          role="button"
          aria-pressed="true"
        >
          Open mobile app
        </a>
      </div>
    )
  }

  renderMain() {
    const { onChange, onConfirm /*, isMobile*/ } = this.props
    return (
      <div className="text-dark text-center center-block">
        <form
          style={{ width: '400px' }}
          className="form-signin needs-validation was-validated rounded p-3 bg-light m-auto"
          noValidate=""
          onSubmit={(e) => {
            e.preventDefault()
            onConfirm()
          }}
        >
          <p style={{ margin: '2rem', fontWeight: 'bold' }}>
            Set your password and pincode
          </p>
          <div className="mb-3-mti">
            <DoublePasswordsInput
              ref={(ref) => {
                this.password = ref
                setServerErrorDismiss(ref)
              }}
              placeholder=""
              required="required"
              onChange={(password) => onChange({ password: password })}
            />
          </div>
          <div className="mb-3-mti">
            <DoublePincodeInput
              ref={(ref) => {
                this.pincode = ref
                setServerErrorDismiss(ref)
              }}
              placeholder=""
              required="required"
              onChange={(pincode) => onChange({ pincode })}
            />
          </div>

          <button
            type="submit"
            className="btn btn-primary btn-raised"
            style={{ marginTop: '1rem' }}
          >
            {'SAVE PASSWORD AND PINCODE'}
          </button>
        </form>

        {/*{isMobile && <h5 className="modal-title">OR</h5>}
        {isMobile && this.renderMobileRedirect()}*/}
      </div>
    )
  }
  render() {
    if (this.props.success) {
      return this.showSuccess()
    }
    return <div className="text-white">{this.renderMain()}</div>
  }
}

PasswordSetScreen.propTypes = {
  onChange: PropTypes.func,
  onConfirm: PropTypes.func.isRequired,
  ratioTooltip: PropTypes.object,
  mobileUri: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
  onReturnToLogin: PropTypes.func,
  success: PropTypes.object,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
}

export default PasswordSetScreen
