import { all, call, put, takeLatest, select } from 'redux-saga/effects'
import { ActionTypes } from './constants'
import { makeSelectToken } from '../../App/selectors'
import {
  fetchStoresListPending,
  fetchStoresListFulfilled,
  fetchStoresListFailed,
} from './actions'
import { getStores } from '../../../api'
import { makeSelectOTAStoresExist } from './selectors'

function* fetchStores() {
  const token = yield select(makeSelectToken())
  try {
    const storesExist = yield select(makeSelectOTAStoresExist())
    if (!storesExist) {
      yield put(fetchStoresListPending())
      const { stores, organizations } = yield call(
        getStores,
        token,
        'organization'
      )
      yield put(fetchStoresListFulfilled(stores, organizations))
    }
  } catch (error) {
    yield put(fetchStoresListFailed(error))
  }
}

export default function* root() {
  yield all([yield takeLatest(ActionTypes.FETCH_STORES_LIST, fetchStores)])
}
