import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import cube from '../../images/cube.png'

export const Icon = styled.img`
  width: 30px;
  height: 30px;
`

function renderItem(item, onItem, history) {
  const { name, navigateTo, storeName, historyState, icon } = item
  if (storeName) {
    return renderSection(storeName)
  }

  return (
    <div key={name}>
      <div
        tabIndex="-1"
        role="menuitem"
        onClick={() =>
          history.push({ pathname: navigateTo, state: historyState })
        }
        style={{ width: '100%' }}
      >
        <button
          type="button"
          className="list-group-item list-group-item-action"
          style={{
            width: '100%',
          }}
          onClick={(event) => {
            if (onItem) onItem(event, item)
          }}
        >
          <Icon
            src={icon || cube}
            style={{
              marginRight: 10,
            }}
          />
          {name}
        </button>
      </div>
    </div>
  )
}

function renderSection(storeName) {
  return (
    <div
      key={storeName}
      className="card-header text-left font-weight-bold text-truncate border-top mt-4"
    >
      {storeName}
    </div>
  )
}

function ListOfLinks({ items, headerTitle, onItem, height, history }) {
  return (
    <div className="card" style={{ height: '100%' }}>
      <div className="card-header text-left font-weight-bold">
        {headerTitle}
      </div>
      <div style={{ height: height, overflow: 'auto' }}>
        {items.map((item) => renderItem(item, onItem, history))}
      </div>
    </div>
  )
}

ListOfLinks.propTypes = {
  items: PropTypes.array.isRequired,
  headerTitle: PropTypes.string.isRequired,
  onItem: PropTypes.func,
  history: PropTypes.any.isRequired,
}

export default ListOfLinks
