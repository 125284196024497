import { createSelector } from 'reselect'
import { createSelector as createOrmSelector } from 'redux-orm'
import { orm, fixtureCanvasById, extendPosition } from 'mti-jsclient-shared'
import mtiJsclientShared from '../../utils/mtiJsclientShared'
import { getAutoPlacementMax } from '../../utils/mtiUtils'
import { toFabricSync } from './utils'

const dbStateSelector = (state) => state.get('orm')

const selectIsFixtureById = (fxId) =>
  createOrmSelector(orm, (session) => {
    try {
      return session.Fixture.hasId(fxId)
    } catch (e) {
      return false
    }
  })

const makeSelectFixtureExist = (fxId) =>
  createSelector(
    dbStateSelector,
    (ormState) =>
      selectIsFixtureById(fxId)(ormState) &&
      !!selectFixtureById(fxId)(ormState).layoutPosition
  )

const selectFixtureById = (fxId) =>
  createOrmSelector(orm, (session, fState) => {
    try {
      const fixture = fixtureCanvasById(session, fState, fxId, true)

      return fixture
    } catch (e) {
      return {}
    }
  })

const selectIsReadyById = (fxId) =>
  createOrmSelector(orm, (session) => {
    try {
      if (!session.Fixture.hasId(fxId)) {
        return false
      }
      const fixture = session.Fixture.withId(fxId)
      if (!session.FixtureLayoutPosition.hasId(fixture.ref.layoutPositionId)) {
        return false
      }
      return true
    } catch (e) {
      return false
    }
  })

const makeSelectIsReady = (fxId) =>
  createSelector(dbStateSelector, (ormState) =>
    selectIsReadyById(fxId)(ormState)
  )

export const getRulesLoading = (fixtureState) =>
  (
    (fixtureState || { get: () => undefined }).get('rulesLoading') || {
      toArray: () => [],
    }
  ).toArray()

export const addLoadingStateForRules = (rules, rulesLoading) =>
  (rules || []).map((rule) => ({
    ...rule,
    loading: (rulesLoading || []).includes(rule.id),
  }))

const makeSelectFixture = (fxId) =>
  createSelector(dbStateSelector, (ormState) =>
    selectFixtureById(fxId)(ormState)
  )

const makeSelectPositionsByFixture = (fxId) =>
  createSelector(dbStateSelector, (ormState) => {
    const fixture = selectFixtureById(fxId)(ormState)
    return (fixture || {}).positions
  })

const selectCanvas = (state) => state.get('fixtureCanvas')

const ormFixtureStateSelector = (state) => ({
  orm: state.get('orm'),
  fixture: state.get('fixtureCanvas'),
})

const makeSelectCanvasObject = (fxId) =>
  createSelector(ormFixtureStateSelector, (ormFixture) => {
    try {
      const isStatic = ormFixture.fixture.get('isStatic')
      const prototypes = ormFixture.fixture.get('prototypes')

      if (!isStatic || !prototypes) {
        return ormFixture.fixture.get('canvasObject').toJS()
      }

      const fixture = selectFixtureById(fxId)(ormFixture.orm)
      const screen = ormFixture.fixture.get('screen')
      const { canvasObject } = toFabricSync(
        fixture,
        screen,
        isStatic,
        -1,
        prototypes,
        mtiJsclientShared
      )
      return canvasObject
    } catch (e) {
      return {}
    }
  })

/*
const makeSelectCanvasObject = (fxId) =>
  createSelector(ormFixtureStateSelector, (ormFixture) => {
    const canvas = ormFixture.fixture.get('canvasObject').toJS()
    const prototypes = ormFixture.fixture.get('prototypes')
    const fixture = selectFixtureById(fxId)(ormFixture.orm)
    return mergeStatus(canvas, fixture, prototypes)
  })
*/

const makeSelectPrototypesMobile = () =>
  createSelector(selectCanvas, (canvas) => canvas.get('prototypesMobile'))

const makeSelectPrototypes = () =>
  createSelector(selectCanvas, (canvas) => canvas.get('prototypes'))

const makeSelectCanvasSelectedId = () =>
  createSelector(
    selectCanvas,
    (canvasState) => canvasState && canvasState.get('selectedId')
  )

const makeSelectCanvasSelectedObject = () =>
  createSelector(selectCanvas, (canvasState) =>
    canvasState
      .get('canvasObject')
      .get('objects')
      .findIndex((item) => item.get('id') === canvasState.get('selectedId'))
  )

const makeSelectChosenPosition = () =>
  createSelector(selectCanvas, dbStateSelector, (canvasState, ormState) => {
    try {
      const selectedId = canvasState.get('selectedId')
      const objects = canvasState.get('canvasObject').get('objects')
      const object = objects.find((item) => item.get('id') === selectedId)
      if (!object) {
        // console.warn('selected position should have canvas object')
        return
      }
      const pId = object.get('id')
      const position = selectPositionById(pId)(ormState)
      return position
    } catch (e) {
      return
    }
  })

const makeSelectFailed = () =>
  createSelector(selectCanvas, (canvas) => canvas.get('failed'))

const makeSelectLoading = () =>
  createSelector(selectCanvas, (canvas) => canvas.get('loading'))

const makeSelectIsStatic = () =>
  createSelector(selectCanvas, (canvas) =>
    (canvas || { get: () => true }).get('isStatic')
  )

const getCanvasData = (canvas) => {
  const selectedId = canvas.get('selectedId')
  const objects = canvas.get('canvasObject').get('objects')
  return { selectedId, objects }
}

const makeSelectPositionName = () =>
  createSelector(selectCanvas, (canvas) => {
    const { selectedId, objects } = getCanvasData(canvas)
    if (selectedId === -1 || !objects.count()) return ''
    const p = objects.find((i) => i.get('id') === selectedId)
    return p ? p.get('name') : ''
  })

const makeSelectPositionCount = () =>
  createSelector(selectCanvas, (canvas) => {
    const canvasObject = canvas.get('canvasObject')
    if (!canvasObject) return 0
    const objects = canvasObject.get('objects')
    if (!objects) return 0
    const positionCount = objects.toJS().length - 1 // 0 object is fixture at background
    return positionCount
  })

const makeSelectAutoPlacementMax = () =>
  createSelector(selectCanvas, (canvas) => {
    const canvasObject = canvas.get('canvasObject')
    if (!canvasObject) return 0
    const objects = canvasObject.get('objects')
    if (!objects || objects.toJS().length === 0) return 0
    return getAutoPlacementMax(objects.toJS()[0].mtiType) // 0 object is fixture at background
  })

const makeSelectFixtureOrmed = () =>
  createSelector(selectCanvas, (canvas) => canvas.get('fixture'))

const makeSelectSecurityDevices = (sId) =>
  createSelector(dbStateSelector, (ormState) =>
    createOrmSelector(orm, (session) => {
      try {
        return session.SecurityDevice.all()
          .filter((d) => d.storeId.toString() === sId)
          .toRefArray()
      } catch (e) {
        return []
      }
    })(ormState)
  )

const selectPositionById = (pId) =>
  createOrmSelector(orm, (session) => {
    try {
      if (!session.Position.hasId(pId)) return undefined
      const position = session.Position.withId(pId)
      const fixture = session.Fixture.withId(position.ref.fixtureId)
      return extendPosition(position, session, fixture.ref)
    } catch (e) {
      return undefined
    }
  })

const makeSelectPositionById = (pId) =>
  createSelector(dbStateSelector, (ormState) =>
    selectPositionById(pId)(ormState)
  )

const makeSelectSecuredProducts = (sId) =>
  createSelector(dbStateSelector, (ormState) =>
    createOrmSelector(orm, (session) => {
      try {
        return session.SecuredProduct.all()
          .filter((d) => d.storeId.toString() === sId)
          .toModelArray()
          .map((product) => ({
            ...product.ref,
            manufacturer: product.manufacturerId
              ? product.manufacturerId.ref
              : {},
          }))
      } catch (e) {
        return []
      }
    })(ormState)
  )

const makeSelectSecuredProductsWithPositionData = (
  sId,
  getExtraSecuredProductData
) =>
  createSelector(dbStateSelector, (ormState) =>
    createOrmSelector(orm, (session) => {
      try {
        if (!session.Store.hasId(sId)) return []
        const { name: storeName } = session.Store.withId(sId).ref
        return session.SecuredProduct.all()
          .filter((d) => d.storeId.toString() === sId)
          .toModelArray()
          .map((securedProductModel) => {
            const positionModel = securedProductModel.positionId
            const position = positionModel && positionModel.ref
            const fixture = positionModel && positionModel.fixtureId.ref
            const fixtureData = fixture && {
              fixtureId: fixture.id,
              fixtureName: fixture.name,
            }
            let extraSecuredProductData = {}
            if (getExtraSecuredProductData) {
              extraSecuredProductData = getExtraSecuredProductData(
                securedProductModel
              )
            }
            return {
              ...securedProductModel.ref,
              manufacturer: (securedProductModel.manufacturerId || {}).ref,
              position: {
                ...position,
                ...fixtureData,
                storeId: sId,
                storeName,
              },
              ...extraSecuredProductData,
            }
          })
      } catch (e) {
        return []
      }
    })(ormState)
  )

const makeSelectAllProducts = () =>
  createSelector(dbStateSelector, (ormState) =>
    createOrmSelector(orm, (session) => {
      try {
        return session.Product.all()
          .toModelArray()
          .map((product) => ({
            ...product.ref,
            manufacturer: product.manufacturerId
              ? product.manufacturerId.ref
              : {},
          }))
      } catch (e) {
        return []
      }
    })(ormState)
  )

const makeSelectManufacturers = () =>
  createSelector(dbStateSelector, (ormState) =>
    createOrmSelector(orm, (session) => {
      try {
        return session.Manufacturer.all().toRefArray()
      } catch (e) {
        return []
      }
    })(ormState)
  )

const makeSelectFixtureMobile = () =>
  createSelector(selectCanvas, (canvas) => canvas.get('fixtureMobile'))

export {
  selectFixtureById,
  makeSelectFixtureExist,
  makeSelectFixture,
  makeSelectPrototypesMobile,
  makeSelectPrototypes,
  makeSelectCanvasObject,
  makeSelectCanvasSelectedId,
  makeSelectCanvasSelectedObject,
  makeSelectFailed,
  makeSelectLoading,
  makeSelectIsStatic,
  makeSelectPositionsByFixture,
  makeSelectChosenPosition,
  makeSelectPositionName,
  makeSelectPositionCount,
  makeSelectAutoPlacementMax,
  makeSelectFixtureOrmed,
  makeSelectSecurityDevices,
  makeSelectSecuredProducts,
  makeSelectManufacturers,
  makeSelectAllProducts,
  makeSelectPositionById,
  makeSelectSecuredProductsWithPositionData,
  makeSelectIsReady,
  makeSelectFixtureMobile,
}
