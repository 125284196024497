import React from 'react'
import PropTypes from 'prop-types'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { makeSelectFirmwares } from '../../FirmwaresPage/selectors'
import SelectFirmwareScreen from './index.screen'
import { getFirmwareVersion } from '../../../utils/utils'

class SelectFirmwareModal extends React.Component {
  constructor(props) {
    super(props)

    const firmwaresWithMockedData = props.firmwares.map((f) => ({
      name: f.name,
      uploaded: 'Date',
      uploadedBy: 'Random Name',
    }))

    this.state = {
      firmares: firmwaresWithMockedData,
      selectedFirmware: null,
      firmwareVersionToIgnore: '',
    }
  }

  /* eslint-disable no-undef */
  onVersionChange = (version) => {
    this.setState(version)
  }

  onUpdate = () => {
    const { onUpdate } = this.props
    const { selectedFirmware, firmwareVersionToIgnore } = this.state
    onUpdate({
      firmware: selectedFirmware,
      versionToIgnore: firmwareVersionToIgnore,
    })
  }

  isSelected = (firmware) => this.state.selectedFirmware === firmware

  toggleSelection = (firmware) => {
    if (!this.isSelected(firmware)) {
      const version = getFirmwareVersion(firmware.name)
      this.setState({
        selectedFirmware: firmware,
        firmwareVersionToIgnore: version,
      })
    }
  }

  render() {
    const { id, onCancel } = this.props
    const { firmares, selectedFirmware, firmwareVersionToIgnore } = this.state

    return (
      <SelectFirmwareScreen
        id={id}
        firmwares={firmares}
        onCancel={onCancel}
        onUpdate={this.onUpdate}
        isSelected={this.isSelected}
        toggleSelection={this.toggleSelection}
        selectedFirmware={selectedFirmware}
        firmwareVersionToIgnore={firmwareVersionToIgnore}
        onVersionChange={this.onVersionChange}
      />
    )
  }
}

SelectFirmwareModal.propTypes = {
  id: PropTypes.string.isRequired,
  firmwares: PropTypes.array.isRequired,
  onCancel: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
}

const mapStateToProps = createStructuredSelector({
  firmwares: makeSelectFirmwares(),
})

export default connect(mapStateToProps)(SelectFirmwareModal)
