/* eslint-disable consistent-return */
import { Record } from 'immutable'

export const Region = new Record({
  id: null,
  name: null,
  storeIds: null,
  stores: null,
  storesCount: null,
  createdAt: null,
  updatedAt: null,
})

export function createRegions(regions, stores) {
  if (!regions) return []
  return regions.map(data => createRegion(data, stores))
}

export function createRegion(region, stores) {
  const assignedStores = storesInRegion(region.storeIds, stores)
  const attrs = {
    id: region.id,
    name: region.name,
    storesCount: region.storesCount,
    storeIds: region.storeIds,
    stores: assignedStores,
    createdAt: region.createdAt,
    updatedAt: region.updatedAt,
  }
  return new Region(attrs)
}

export const Store = new Record({
  id: null,
  name: null,
  regionId: null,
  regionName: null,
  address: null,
})

export function createStores({ stores, regions, addresses }) {
  if (!stores) return []
  return stores.map(data => {
    const attrs = {
      id: data.id,
      name: data.name,
      regionId: data.regionId,
      regionName: regionName(data.regionId, regions),
      address: storeAddress(data.addressId, addresses),
    }
    return new Store(attrs)
  })
}

function storesInRegion(storeIds, stores) {
  if (!storeIds || !stores) {
    return
  }

  return (stores || []).filter(s => storeIds.includes(s.id))
}

export function regionName(regionId, regions) {
  if (!regionId || !regions) {
    return
  }

  const region = (regions || []).find(r => r.id === regionId)
  if (!region) {
    return
  }

  return region.name
}

function storeAddress(addressId, addresses) {
  const address = addresses.find(({ id }) => id === addressId)
  return (
    address || {
      address1: '1234 QA st',
      address2: '',
      address3: '',
      addresseeId: 389,
      addresseeType: 'Store',
      city: 'Beaverton',
      country: 'Bahamas',
      id: 1096,
      state: 'Inagua',
      zip: '97006',
    }
  )
}
