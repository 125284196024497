import React from 'react'
import PropTypes from 'prop-types'
import ReactTable from 'react-table'
import { fullTextFilter } from '../../utils/utils'
import {
  CellContainer,
  HeaderContainer,
  Header,
  renderHeaderCell,
  renderFilterInput,
} from '../UsersPage/index.screen'

class FirmwaresListScreen extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  /* eslint-disable no-undef */
  handleClick = () => this.fileInput.click()

  render() {
    const {
      height,
      firmwares,
      onFirmwareSelection,
      onUploadNewFirmwareFile,
      onSeeAll,
    } = this.props
    const { headerHeight } = this.state

    const columns = [
      {
        id: 'name',
        accessor: 'name',
        Header: () => renderHeaderCell('Name'),
        Cell: ({ original }) => (
          <CellContainer onClick={() => onFirmwareSelection(original)}>
            {original.name}
          </CellContainer>
        ),
        filterable: true,
        Filter: ({ filter, onChange }) =>
          renderFilterInput({ placeholder: 'Filter', filter, onChange }),
      },
    ]
    return (
      <div>
        <HeaderContainer className="border-bottom">
          <Header>Firmwares</Header>
          <div>
            <input
              style={{ display: 'none' }}
              ref={(fileInput) => (this.fileInput = fileInput)}
              type="file"
              onChange={onUploadNewFirmwareFile}
            />
            <button
              style={{ marginTop: '16px' }}
              onClick={this.handleClick}
              type="button"
              className="btn btn-primary btn-raised"
            >
              Upload New
            </button>
          </div>
          <button
            style={{ marginTop: '16px' }}
            onClick={onSeeAll}
            type="button"
            className="btn btn-primary"
          >
            See All
          </button>
        </HeaderContainer>
        <ReactTable
          className="full-screen"
          style={{
            height: height && headerHeight ? height - headerHeight : 'auto',
          }}
          data={firmwares}
          columns={columns}
          defaultFilterMethod={fullTextFilter}
        />
      </div>
    )
  }
}

FirmwaresListScreen.propTypes = {
  height: PropTypes.number,
  firmwares: PropTypes.array.isRequired,
  onFirmwareSelection: PropTypes.func.isRequired,
  onUploadNewFirmwareFile: PropTypes.func.isRequired,
  onSeeAll: PropTypes.func.isRequired,
}

export default FirmwaresListScreen
