/*
 *
 * TemplatePositions actions
 *
 */

import { ActionTypes } from './constants'

/**
 * Store Prototypes
 *
 * @returns {Object}
 */
export function storePrototypes(prototypes) {
  return {
    type: ActionTypes.STORE_FIXTURE_TEMPLATE_PROTOTYPES,
    payload: prototypes,
  }
}

/**
 * Store Params
 *
 * @returns {Object}
 */
export function storeParams(params) {
  return {
    type: ActionTypes.STORE_FIXTURE_TEMPLATE_PARAMS,
    payload: params,
  }
}

/**
 * Fixture Template Canvas Changed
 *
 * @returns {Object}
 */
export function fixtureTemplateCanvasChanged(payload) {
  return {
    type: ActionTypes.FIXTURE_TEMPLATE_CANVAS_CHANGED,
    payload,
  }
}

/**
 * Fixture Canvas Name Changed
 *
 * @returns {Object}
 */
export function fixtureTemplateCanvasNameChanged(payload) {
  return {
    type: ActionTypes.FIXTURE_TEMPLATE_CANVAS_NAME_CHANGED,
    payload,
  }
}

/**
 * Post Fixture Template Finished
 *
 * @returns {Object}
 */
export function postFixtureTemplateFinished(payload) {
  return {
    type: ActionTypes.POST_FIXTURE_TEMPLATE_FINISHED,
    payload,
  }
}

/**
 * Load Canvas
 *
 * @returns {Object}
 */
export function loadCanvas(screen, isStatic, selectedId, tId) {
  return {
    type: ActionTypes.LOAD_FIXTURE_TEMPLATE_CANVAS,
    payload: {
      isStatic,
      screen,
      selectedId,
      tId,
    },
  }
}

export function fixtureTemplateDataPending() {
  return {
    type: ActionTypes.FIXTURE_TEMPLATE_DATA_PENDING,
  }
}

export function fixtureTemplateDataFailed(error) {
  return {
    type: ActionTypes.FIXTURE_TEMPLATE_DATA_FAILED,
    payload: { error },
  }
}

/**
 * Fetch Canvas Fulfilled
 *
 * @returns {Object}
 */
export function fetchFixtureTemplateCanvasFulfilled(
  canvasId,
  canvas,
  selectedId,
  positions,
  fixture,
  isStatic
) {
  return {
    type: ActionTypes.FETCH_FIXTURE_TEMPLATE_CANVAS_FULFILLED,
    payload: {
      canvas,
      selectedId,
      positions,
      fixture,
      isStatic,
    },
  }
}

/**
 * Save Position
 *
 *
 */
export function savePosition(payload) {
  return {
    type: ActionTypes.SAVE_FIXTURE_TEMPLATE_POSITION,
    payload,
  }
}

/**
 * Remove Position
 *
 * @returns {Object}
 */
export function removeObject(payload) {
  return {
    type: ActionTypes.FIXTURE_TEMPLATE_CANVAS_POSITION_REMOVE,
    payload,
  }
}

/**
 * Select Position
 *
 * @param selectedPosition
 * @returns {object}
 */
export function selectPosition(selectedPosition) {
  return {
    type: ActionTypes.SELECT_POSITION,
    payload: { selectedPosition },
  }
}

export function fixtureTemplateDataFulfilled() {
  return {
    type: ActionTypes.FIXTURE_TEMPLATE_DATA_FULFILLED,
  }
}

export function prepareDataForFixtureTemplate(templateId) {
  return {
    type: ActionTypes.PREPARE_DATA_FOR_FIXTURE_TEMPLATE,
    payload: { templateId },
  }
}

export function saveTemplatePosition(position, templateId) {
  return {
    type: ActionTypes.SAVE_TEMPLATE_POSITION,
    payload: { position, templateId },
  }
}

export function deleteTemplatePosition(position) {
  return {
    type: ActionTypes.DELETE_TEMPLATE_POSITION,
    payload: { position },
  }
}

/**
 * Create Position Rule
 *
 * @param {Number} positionId
 * @param {Object} rule
 */
export function createPositionRule(positionId, rule, tId, screen) {
  return {
    type: ActionTypes.CREATE_POSITION_RULE,
    payload: { positionId, rule, tId, screen },
  }
}

/**
 * Edit Position Rule
 *
 * @param {Number} positionId
 * @param {Object} rule
 */
export function editPositionRule(positionId, rule, tId, screen) {
  return {
    type: ActionTypes.EDIT_POSITION_RULE,
    payload: { positionId, rule, tId, screen },
  }
}

/**
 * Delete Position Rule
 *
 * @param {Number} positionId
 * @param {Object} rule
 */
export function deletePositionRule(positionId, rule, tId, screen) {
  return {
    type: ActionTypes.DELETE_POSITION_RULE,
    payload: { positionId, rule, tId, screen },
  }
}

/**
 * Delete All Position Rules
 *
 * @param {Object} position
 */
export function deleteAllPositionRules(position, tId, screen) {
  return {
    type: ActionTypes.DELETE_ALL_POSITION_RULES,
    payload: { position, tId, screen },
  }
}

/**
 * Update Fixture Rule Failed
 *
 * @returns {Object}
 */
export function updateRuleFailed(error) {
  return {
    type: ActionTypes.UPDATE_RULE_FAILED,
    payload: error,
  }
}

/**
 * Update Fixture Rule Finished
 *
 * @returns {Object}
 */
export function updateRuleFinished(ruleId) {
  return {
    type: ActionTypes.UPDATE_RULE_FINISHED,
    payload: {
      ruleId,
    },
  }
}

/**
 * Update Fixture Rule Pending
 *
 * @returns {Object}
 */
export function updateRulePending(ruleId) {
  return {
    type: ActionTypes.UPDATE_RULE_PENDING,
    payload: {
      ruleId,
    },
  }
}

/**
 * Template Canvas Position Count Changed
 *
 * @returns {Object}
 */
export function templateCanvasPositionCountChanged(payload) {
  return {
    type: ActionTypes.TEMPLATE_CANVAS_POSITION_COUNT_CHANGED,
    payload,
  }
}

/**
 * Auto Placement Fulfilled
 *
 * @returns {Object}
 */
export function templateAutoPlacementFulfilled(canvas) {
  return {
    type: ActionTypes.TEMPLATE_AUTO_PLACEMENT_FULFILLED,
    payload: { canvas },
  }
}

/**
 * Save all Position
 *
 * @returns {Object}
 */
export function saveAll(payload) {
  return {
    type: ActionTypes.SAVE_TEMPLATE_FIXTURE_ALL_POSITIONS,
    payload,
  }
}
