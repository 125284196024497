import { ActionTypes } from './constants'

/**
 * Update Lode Failed
 *
 * @returns {Object}
 */
export function updateLodeFailed(error) {
  return {
    type: ActionTypes.UPDATE_LODE_FAILED,
    payload: { error },
  }
}

/**
 * Update Lode Fulfilled
 *
 * @returns {Object}
 */
export function updateLodeFulfilled(status) {
  return {
    type: ActionTypes.UPDATE_LODE_FULFILLED,
    payload: { status },
  }
}

/**
 * Update Lode Pending
 *
 * @returns {Object}
 */
export function updateLodePending() {
  return {
    type: ActionTypes.UPDATE_LODE_PENDING,
  }
}

/**
 * Update Lode
 *
 * @returns {Object}
 */
export function updateLode(data) {
  return {
    type: ActionTypes.UPDATE_LODE,
    payload: data,
  }
}
