/**
 *
 * Select Filters Preloader
 *
 */

import React from 'react'
import ContentLoader from 'react-content-loader'
import PropTypes from 'prop-types'
import uuid from 'uuid'

function SelectFiltersPreloader({ isLogs, multiTable }) {
  const SelectFiltersPreloader = (props) => {
    const tableY = isLogs ? 260 : 175
    const heightLoader = isLogs ? 540 : 460
    const widthLoader = 800
    const tableWidth = multiTable ? 200 : 680
    return (
      <ContentLoader
        height={heightLoader}
        width={widthLoader}
        speed={2}
        primaryColor="#f3f3f3"
        secondaryColor="#ecebeb"
        {...props}
      >
        <rect x="30" y="15" rx="0" ry="0" width="200" height="30" />
        <rect x="650" y="15" rx="0" ry="0" width="130" height="30" />
        <rect x="510" y="15" rx="0" ry="0" width="130" height="30" />
        <rect x="0" y="55" rx="0" ry="0" width="800" height="2" />
        <rect x="30" y="75" rx="6" ry="6" width="250" height="15" />
        <rect x="350" y="70" rx="0" ry="0" width="25" height="25" />
        <rect x="490" y="70" rx="0" ry="0" width="25" height="25" />
        <rect x="630" y="70" rx="0" ry="0" width="25" height="25" />
        <rect x="380" y="75" rx="6" ry="6" width="90" height="15" />
        <rect x="520" y="75" rx="6" ry="6" width="90" height="15" />
        <rect x="660" y="75" rx="6" ry="6" width="90" height="15" />
        {isLogs ? (
          <React.Fragment>
            <rect
              x="30"
              y={tableY - 150}
              rx="0"
              ry="0"
              width="740"
              height="2"
            />
            <rect
              x="30"
              y={tableY - 130}
              rx="6"
              ry="6"
              width="250"
              height="15"
            />
            <rect
              x="30"
              y={tableY - 105}
              rx="6"
              ry="6"
              width="250"
              height="15"
            />
            <rect
              x="350"
              y={tableY - 130}
              rx="0"
              ry="0"
              width="140"
              height="25"
            />
            <rect
              x="510"
              y={tableY - 130}
              rx="0"
              ry="0"
              width="140"
              height="25"
            />
          </React.Fragment>
        ) : (
          <div />
        )}
        {multiTable ? (
          <React.Fragment>
            <rect x="30" y={tableY - 70} rx="0" ry="0" width="740" height="2" />
            <rect
              x="90"
              y={tableY - 50}
              rx="6"
              ry="6"
              width="150"
              height="15"
            />
            <rect
              x="325"
              y={tableY - 50}
              rx="6"
              ry="6"
              width="150"
              height="15"
            />
            <rect
              x="590"
              y={tableY - 50}
              rx="6"
              ry="6"
              width="150"
              height="15"
            />

            <rect
              x="30"
              y={tableY - 20}
              rx="0"
              ry="0"
              width={tableWidth + 35}
              height="2"
            />
            <rect
              x="280"
              y={tableY - 20}
              rx="0"
              ry="0"
              width={tableWidth + 35}
              height="2"
            />
            <rect
              x="550"
              y={tableY - 20}
              rx="0"
              ry="0"
              width={tableWidth + 35}
              height="2"
            />

            <rect x="30" y={tableY} rx="0" ry="0" width="20" height="20" />
            <rect
              x="60"
              y={tableY}
              rx="6"
              ry="6"
              width={tableWidth}
              height="20"
            />

            {Array(7)
              .fill('')
              .map((e, i) => (
                <React.Fragment key={`loader_${uuid.v4()}`}>
                  <rect
                    x="280"
                    y={tableY + 40 * i}
                    rx="0"
                    ry="0"
                    width="20"
                    height="20"
                  />
                  <rect
                    x="320"
                    y={tableY + 40 * i}
                    rx="6"
                    ry="6"
                    width={tableWidth}
                    height="20"
                  />
                  <rect
                    x="540"
                    y={tableY + 40 * i}
                    rx="0"
                    ry="0"
                    width="20"
                    height="20"
                  />
                  <rect
                    x="580"
                    y={tableY + 40 * i}
                    rx="6"
                    ry="6"
                    width={tableWidth}
                    height="20"
                  />
                  <rect
                    x="280"
                    y={tableY + 30 + 40 * i}
                    rx="0"
                    ry="0"
                    width={tableWidth + 35}
                    height="2"
                  />
                  <rect
                    x="550"
                    y={tableY + 30 + 40 * i}
                    rx="0"
                    ry="0"
                    width={tableWidth + 35}
                    height="2"
                  />
                </React.Fragment>
              ))}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <rect x="30" y={tableY - 70} rx="0" ry="0" width="740" height="2" />
            <rect
              x="325"
              y={tableY - 50}
              rx="6"
              ry="6"
              width="150"
              height="15"
            />
            <rect x="30" y={tableY - 20} rx="0" ry="0" width="740" height="2" />
            {Array(7)
              .fill('')
              .map((e, i) => (
                <React.Fragment key={`loader_${uuid.v4()}`}>
                  <rect
                    x="50"
                    y={tableY + 40 * i}
                    rx="0"
                    ry="0"
                    width="20"
                    height="20"
                  />
                  <rect
                    x="90"
                    y={tableY + 40 * i}
                    rx="6"
                    ry="6"
                    width={tableWidth}
                    height="20"
                  />
                  <rect
                    x="30"
                    y={tableY + 30 + 40 * i}
                    rx="0"
                    ry="0"
                    width="740"
                    height="2"
                  />
                </React.Fragment>
              ))}
          </React.Fragment>
        )})
      </ContentLoader>
    )
  }
  return <SelectFiltersPreloader />
}

SelectFiltersPreloader.propTypes = {
  isLogs: PropTypes.bool,
  multiTable: PropTypes.bool,
}

export default SelectFiltersPreloader
