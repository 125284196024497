import React from 'react'
import PropTypes from 'prop-types'
import { getTableHeight, PageHeaderHeight } from '../../../utils/utils'
import OTAHomeScreen from './index.screen'

const HomePage = ({ history }) => (
  <OTAHomeScreen
    history={history}
    height={getTableHeight() + PageHeaderHeight}
  />
)

HomePage.propTypes = {
  history: PropTypes.object.isRequired,
}

export default HomePage
