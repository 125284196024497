import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import SecuredProductsScreen from './index.screen'
import injectSaga from '../../utils/injectSaga'
import storeSaga from '../App/sagas/ormSaga'
import { securedProductDetailsAction } from '../App/actions'
import { loadSecuredProducts } from './actions'
import SecuredProductManagement from './securedProductManagement'
import {
  selectFailed,
  selectLoading,
  selectSecuredProductsByStoreId,
} from './selectors'
import { SecuredProductCategory } from 'mti-jsclient-shared'

const securedProductCategories = {
  ...SecuredProductCategory,
  ALL: 'all',
}
class SecuredProducts extends React.Component {
  constructor(props) {
    super(props)
    let category = securedProductCategories.CURRENT
    if (props.location.state) {
      category = props.location.state.category
    }
    this.state = { selectedCategory: category }
  }

  componentDidMount() {
    const { match: { params: { sId } } } = this.props
    if (sId) {
      this.props.loadSecuredProducts(sId)
    }
  }

  onSecuredProductSelection = (securedProduct) => {
    this.props.securedProductDetailsAction(securedProduct)
  }

  onChangeCategory = (category) => {
    this.setState({ selectedCategory: category.value })
  }

  render() {
    const { failed, loading, securedProducts } = this.props
    const { selectedCategory } = this.state
    if (failed) return <div>{`Error: ${failed.message}`}</div>

    return (
      <div>
        <SecuredProductManagement />
        <SecuredProductsScreen
          loading={loading}
          securedProducts={securedProducts}
          onSecuredProductSelection={this.onSecuredProductSelection}
          onChangeCategory={this.onChangeCategory}
          categories={securedProductCategories}
          selectedCategory={selectedCategory}
        />
      </div>
    )
  }
}

SecuredProducts.propTypes = {
  match: PropTypes.object,
  securedProducts: PropTypes.array,
  loadSecuredProducts: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  failed: PropTypes.object,
}

const mapStateToProps = (state, ownProps) => {
  let storeId = ownProps.match.params.sId
  return {
    loading: selectLoading(state),
    failed: selectFailed(state),
    securedProducts: selectSecuredProductsByStoreId(state, storeId),
  }
}

const mapDispatchToProps = {
  loadSecuredProducts,
  securedProductDetailsAction,
}

const withStoreSaga = injectSaga({
  key: 'storeWithNotifications',
  saga: storeSaga,
})
const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withStoreSaga, withConnect)(SecuredProducts)
