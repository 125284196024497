/*
 *
 * Store reducer
 *
 */

import { Map } from 'immutable'
import { ActionTypes } from './constants'

export const initialState = new Map({
  patchLoading: false,
  patchFailed: null,
  postLoading: false,
})

function storeEditReducer(state = initialState, { type, payload }) {
  switch (type) {
    case ActionTypes.STORE_DETAILS:
      return state
        .set('storeDetails', payload)
        .set('management', ActionTypes.STORE_DETAILS)
    case ActionTypes.CREATE_STORE:
      return state
        .set('storeDetails', undefined)
        .set('management', ActionTypes.CREATE_STORE)
    case ActionTypes.RESET_STORE_MANAGEMENT:
      return state.set('storeDetails', undefined).set('management', undefined)
    case ActionTypes.STORE_EDIT:
      return state.mergeDeep(state, payload)
    case ActionTypes.STORE_CLEAR:
      return state
        .delete('name')
        .delete('branchCode')
        .delete('storePhone')
        .delete('contactName')
        .delete('contactEmail')
        .delete('contactPhone')
        .delete('timeZone')
        .delete('regionId')
        .delete('address') // .country .state .address1 .city .address2 .address3 .zip
        .delete('imageUrl')
        .delete('image')
        .delete('allowedKeyTypes')
    case ActionTypes.PATCH_STORE_PENDING:
      return state.set('patchFailed', null).set('patchLoading', true)
    case ActionTypes.PATCH_STORE_FULFILLED:
      return state.set('patchFailed', null).set('patchLoading', false)
    case ActionTypes.PATCH_STORE_FAILED:
      return state.set('patchFailed', payload).set('patchLoading', false)
    case ActionTypes.POST_STORE_PENDING:
      return state.set('postFailed', null).set('postLoading', true)
    case ActionTypes.POST_STORE_FULFILLED:
      return state.set('postFailed', null).set('postLoading', false)
    case ActionTypes.POST_STORE_FAILED:
      return state.set('postFailed', payload).set('postLoading', false)
    default:
      return state
  }
}

export default storeEditReducer
