import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import { makeSelectMtiPermission } from '../App/selectors'
import { loadSchedules } from './actions'
import {
  makeSelectSchedules,
  makeSelectFailed,
  makeSelectLoading,
} from './selectors'
import SchedulesPageScreen from './index.screen'
import ProgressIndicator from '../../components/ProgressIndicator'
import SchedulesPageModalScreen from './index.modal.screen'
import ScheduleManagement from './scheduleManagement'
import {
  scheduleDetailsAction,
  createScheduleAction,
} from '../App/actions/scheduleEdit'
import FetchFailedAlert from '../../components/FetchFailed'
import moment from 'moment'

export class SchedulesPage extends React.Component {
  componentWillMount() {
    const { match } = this.props
    const storeId = ((match || {}).params || {}).sId
    if (storeId) {
      this.props.loadSchedules({ storeId })
    }
  }

  effectiveOnError = (date, scheduleId) => {
    const selectedDate = date.clone()
    selectedDate.startOf('hour')
    const currentDate = moment().startOf('hour')
    if (selectedDate.isBefore(currentDate)) {
      return 'The schedule must be the current schedule or occur in the future to be altered'
    }

    const { schedules } = this.props
    const effectiveOnDates = schedules
      .filter(({ id }) => scheduleId !== id)
      .map(({ effectiveOn }) => effectiveOn)

    const sameDates = effectiveOnDates.filter((moment) =>
      moment.isSame(date, 'date')
    )

    return sameDates.length ? 'Has already been taken' : false
  }

  render() {
    const {
      schedules,
      loading,
      failed,
      // height,
      scheduleIndexListRecords,
      scheduleCreateNewObjects,
      isModalUserSelect,
      scheduleDetailsAction,
      createScheduleAction,
      onClose,
      id,
      match,
    } = this.props

    if (loading)
      return (
        <React.Fragment>
          <SchedulesPageScreen loading={loading} />
          <ProgressIndicator text={'Loading...'} />
        </React.Fragment>
      )
    if (failed) return <FetchFailedAlert failed={failed} />
    if (!schedules) return null
    const schedulesChecked = scheduleIndexListRecords ? schedules : []

    if (isModalUserSelect) {
      return (
        <SchedulesPageModalScreen
          id={id}
          schedules={schedulesChecked}
          onClose={() => onClose()}
        />
      )
    }

    return (
      <div>
        <ScheduleManagement
          storeId={((match || {}).params || {}).sId}
          effectiveOnError={this.effectiveOnError}
        />
        <SchedulesPageScreen
          schedules={schedulesChecked}
          isAddScheduleLocked={!scheduleCreateNewObjects}
          onSchedule={(schedule) => scheduleDetailsAction(schedule)}
          addSchedule={createScheduleAction}
        />
      </div>
    )
  }
}

SchedulesPage.propTypes = {
  match: PropTypes.object,
  loadSchedules: PropTypes.func.isRequired,
  scheduleDetailsAction: PropTypes.func.isRequired,
  createScheduleAction: PropTypes.func.isRequired,
  // height: PropTypes.number,
  schedules: PropTypes.array,
  loading: PropTypes.bool,
  failed: PropTypes.object,
  onClose: PropTypes.func,
  id: PropTypes.string,
  isModalUserSelect: PropTypes.bool,
  scheduleCreateNewObjects: PropTypes.bool,
  scheduleIndexListRecords: PropTypes.bool,
}

function getStoreId(ownProps) {
  return ((ownProps.match || {}).params || {}).sId
}

const mapStateToProps = createStructuredSelector({
  schedules: makeSelectSchedules(),
  loading: makeSelectLoading(),
  failed: makeSelectFailed(),
  scheduleCreateNewObjects: (state, ownProps) =>
    makeSelectMtiPermission(
      'Schedule: Create New Objects',
      getStoreId(ownProps)
    )(state, ownProps),
  scheduleIndexListRecords: (state, ownProps) =>
    makeSelectMtiPermission(
      'Schedule: Index (list) Records',
      getStoreId(ownProps)
    )(state, ownProps),
})

const mapDispatchToProps = {
  loadSchedules,
  scheduleDetailsAction,
  createScheduleAction,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect)(SchedulesPage)
