/**
 * Passwords Sagas
 */
import { all, call, put, takeLatest, select } from 'redux-saga/effects'

import { ActionTypes } from './constants'
import {
  postPasswordPending,
  postPasswordFulfilled,
  postPasswordFailed,
  postPasswordSuccess,
} from './actions'
import { makeSelectPassword, makeSelectPincode } from './selectors'
import { errorToast } from '../../utils/utils'
import { resetPassword } from '../../api'

export function* post({ payload }) {
  const password = yield select(makeSelectPassword())
  const pincode = yield select(makeSelectPincode())
  if (password) {
    try {
      yield put(postPasswordPending())
      const token = payload.split('=')[1]
      const data = yield call(
        resetPassword,
        token,
        password.toJS().password,
        pincode.toJS().pincode
      )
      yield put(postPasswordFulfilled())
      yield put(postPasswordSuccess(data))
    } catch (error) {
      console.log(error)
      // react-boilerplate way to get the error body
      const res = error.response
      const errorObj = res ? yield call([res, res.json]) : error
      yield put(postPasswordFailed(errorObj))
      errorToast('Reset password failed')
    }
  }
}

export default function* root() {
  yield all([yield takeLatest(ActionTypes.POST_PASSWORD, post)])
}
