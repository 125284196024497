import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { fetchAndUpsertStore } from '../../App/actions/orm'
import {
  makeSelectFetchAndUpsertStoreLoading as makeSelectLoading,
  makeSelectFetchAndUpsertStoreFailed as makeSelectFailed,
  selectStore,
} from '../../App/selectors'
import {
  getPositionsListByStoreId,
  getSecurityDevicesWithExceptionsByStoreId,
} from './selectors'
import NotificationsScreen from './index.screen'
import ProgressIndicator from '../../../components/ProgressIndicator'
import { positionPath } from '../../../utils/utils'
import FetchFailedAlert from '../../../components/FetchFailed'
import NotificationPreloader from '../../../components/Preloaders/NotificationPreloader'
import { loadSecuredProducts } from '../../SecuredProducts/actions'
import { selectMissingSecuredProductsForStore } from '../../ExceptionsList/SecuredProducts/selectors'

export class Notifications extends React.PureComponent {
  componentDidMount() {
    const { storeId } = this.props
    if (storeId) {
      this.props.fetchAndUpsertStore(storeId)
      this.props.loadSecuredProducts(storeId)
    }
  }

  componentDidUpdate({ storeId }) {
    if (this.props.storeId !== storeId) {
      this.props.fetchAndUpsertStore(this.props.storeId)
      this.props.loadSecuredProducts(this.props.storeId)
    }
  }

  /* eslint-disable no-undef */
  onOpenPosition = ({ positionId }) => {
    const { positions, history } = this.props
    const position = positions.find(({ id }) => id === positionId)
    positionPath(position, history)
  }

  navigateToSecuredProducts = (navigateToPath) => {
    const { store, history } = this.props
    navigateToPath(store, history)
  }

  render() {
    const {
      loading,
      failed,
      positions,
      height,
      securityDevices,
      missingSecuredProducts,
    } = this.props
    const foregroundLoading = loading && (!positions || positions.length === 0)

    if (foregroundLoading)
      return (
        <React.Fragment>
          <NotificationPreloader />
          <ProgressIndicator text={'Loading...'} />
        </React.Fragment>
      )
    if (failed) return <FetchFailedAlert failed={failed} />

    return (
      <NotificationsScreen
        securityDevices={securityDevices}
        positions={positions}
        securedProducts={missingSecuredProducts}
        height={height}
        openPosition={this.onOpenPosition}
        onGoToSecuredProducts={this.navigateToSecuredProducts}
      />
    )
  }
}

Notifications.propTypes = {
  storeId: PropTypes.number,
  positions: PropTypes.array,
  fetchAndUpsertStore: PropTypes.func,
  loading: PropTypes.bool,
  failed: PropTypes.object,
  history: PropTypes.object.isRequired,
  height: PropTypes.number,
}

const mapStateToProps = (state, ownProps) => {
  const getPositions = getPositionsListByStoreId(ownProps.storeId)
  const getLoading = makeSelectLoading(ownProps.storeId)
  const getFailed = makeSelectFailed(ownProps.storeId)
  const getSecurityDevices = getSecurityDevicesWithExceptionsByStoreId(
    ownProps.storeId
  )

  return (state, ownProps) => ({
    positions: getPositions(state),
    loading: getLoading(state),
    failed: getFailed(state),
    securityDevices: getSecurityDevices(state),
    missingSecuredProducts: selectMissingSecuredProductsForStore(
      state,
      ownProps.storeId
    ),
    store: selectStore(state, ownProps.storeId),
  })
}

const mapDispatchToProps = {
  fetchAndUpsertStore,
  loadSecuredProducts,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect)(Notifications)
