import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { pingHub } from '../../App/actions'
import UpdateHubScreen from './index.screen'
import {
  makeSelectHubStatusError,
  makeSelectHubStatusLoading,
} from '../../App/selectors'

class UpdateHub extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      lodeVersion: null,
      partNumber: '150-00006',
      serialNumber: null,
      isLodeVersionEnabled: false,
      partNumberSelected: true,
      isSecondaryDeviceEnabled: false,
    }
  }

  /* eslint-disable no-undef */
  onChange = (changedData) => {
    this.setState({ ...changedData })
  }

  onLodeUpdate = () => {
    const { onLodeUpdate, hub } = this.props
    const { isLodeVersionEnabled, lodeVersion } = this.state
    isLodeVersionEnabled
      ? onLodeUpdate({ hub, version: lodeVersion })
      : onLodeUpdate({ hub })
  }

  onNodesUpdate = () => {
    const { onNodesUpdate, hub } = this.props
    const {
      partNumberSelected,
      partNumber,
      serialNumber,
      isSecondaryDeviceEnabled,
    } = this.state
    partNumberSelected
      ? onNodesUpdate({ hub, partNumber })
      : onNodesUpdate({ hub, serialNumber, isSecondaryDeviceEnabled })
  }

  onPingHub = () => {
    const { pingHub: doPingHub, hub: { identifier } } = this.props
    doPingHub(identifier)
  }

  render() {
    const {
      id,
      selectedStore,
      hub,
      statusFetching,
      statusFetchError,
      onClose,
      onReboot,
      onRestart,
    } = this.props

    return (
      <UpdateHubScreen
        id={id}
        selectedStore={selectedStore}
        hub={hub}
        inputData={this.state}
        statusFetching={statusFetching}
        statusFetchError={statusFetchError}
        onClose={onClose}
        onNodesUpdate={this.onNodesUpdate}
        onLodeUpdate={this.onLodeUpdate}
        onChange={this.onChange}
        onReboot={onReboot}
        onRestart={onRestart}
        onPing={this.onPingHub}
      />
    )
  }
}

UpdateHub.propTypes = {
  id: PropTypes.string.isRequired,
  selectedStore: PropTypes.object.isRequired,
  hub: PropTypes.object.isRequired,
  onReboot: PropTypes.func.isRequired,
  onRestart: PropTypes.func.isRequired,
  onNodesUpdate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onLodeUpdate: PropTypes.func.isRequired,
  statusFetching: PropTypes.bool,
  statusFetchError: PropTypes.object,
  pingHub: PropTypes.func,
}

const mapStateToProps = createStructuredSelector({
  statusFetching: makeSelectHubStatusLoading(),
  statusFetchError: makeSelectHubStatusError(),
})

const mapDispatchToProps = {
  pingHub,
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateHub)
