import React from 'react'
import PropTypes from 'prop-types'
import uuid from 'uuid'
import styles from '../../styles/App.css'
import CanvasUpdate from '../../components/CanvasUpdate'
import FloorCanvas from '../../components/FloorCanvas'
import PromptTable from '../../components/PromptTable'
import SidebarTitle from '../../components/SidebarTitle'
import Input from '../../components/Input'
import Checkbox from '../../components/Checkbox'
import Issue from '../../components/Issue'
import Item from '../../components/ListItems/item'
import SidebarSection from '../../components/SidebarSection'
import EmptyCanvasLabel from '../../components/EmptyCanvasLabel'
import { defaultName, incrementalObjectName } from '../../utils/mtiCanvasUtils'
import { getAreaName } from './utils'

import { BorderedButton, SidebarBody, TopContainer } from '../../styles/styled'
import {
  invalidUrlCharactersMessage,
  safeUri,
  safeUrlCharactersPattern,
} from '../../utils/utils'
import {
  ActionButton,
  ButtonsContainer,
  CellContainer,
  Dropdown,
  DropdownMenu,
} from '../../components/Issue/DropdownButton'
import { displayAlerts } from '../AlertContainer/alerts'
import { SecuredProductsExceptions } from '../ExceptionsList/SecuredProducts'

class FloorScreen extends React.PureComponent {
  state = {
    isSaveDisabled: false,
  }

  onChangeValidity = (isValid) => {
    this.setState({ isSaveDisabled: !isValid })
  }

  onNameChange = (name) => {
    const { selectedAreaHasShown, selectedAreaAngle, onAreaChange } = this.props
    onAreaChange({
      areaName: name,
      areaLabelHasShown: selectedAreaHasShown,
      areaAngle: selectedAreaAngle,
    })
  }

  onToggle = (hasShown) => {
    const { selectedAreaName, selectedAreaAngle, onAreaChange } = this.props
    onAreaChange({
      areaName: selectedAreaName,
      areaLabelHasShown: hasShown,
      areaAngle: selectedAreaAngle,
    })
  }

  onAngleChange = (angle) => {
    const { selectedAreaName, selectedAreaHasShown, onAreaChange } = this.props
    onAreaChange({
      areaName: selectedAreaName,
      areaLabelHasShown: selectedAreaHasShown,
      areaAngle: angle,
    })
  }

  getSelectedAreaObject() {
    const { canvasObject, selectedId } = this.props
    return (
      selectedId !== -1 &&
      canvasObject.objects.find(({ id }) => id === selectedId)
    )
  }

  getPromptText() {
    const { isStatic, canvasObject, selectedId, isDefaultAreaMode } = this.props
    const hasAreas =
      canvasObject && canvasObject.objects && canvasObject.objects.length !== 0
    const hasSelected = selectedId !== -1
    let data = []
    if (isStatic) {
      if (hasSelected) {
        data = [
          {
            func: 'EDIT SELECTED AREA',
            desc: 'Click on EDIT AREA in SIDE PANEL',
          },
          {
            func: 'DESELECT AREA',
            desc: 'Click on empty space OR another AREA in CANVAS',
          },
          {
            func: 'WORK ON ISSUES',
            desc: 'Click on POSITION in SIDE PANEL',
          },
        ]
      } else if (!hasSelected) {
        data = hasAreas
          ? [
            {
              func: 'CREATE NEW AREA',
              desc: 'Click on CREATE NEW AREA in SIDE PANEL',
            },
            {
              func: 'EDIT EXISTING AREA',
              desc: 'Select area in CANVAS',
            },
            {
              func: 'WORK ON ISSUES',
              desc: 'Click on POSITION in SIDE PANEL',
            },
          ]
          : [
            {
              func: 'CREATE NEW AREA',
              desc: 'Click on CREATE NEW AREA in SIDE PANEL',
            },
          ]
      }
    } else if (!isStatic) {
      if (hasSelected) {
        if (isDefaultAreaMode) {
          data = [
            {
              func: 'CREATE NEW AREA',
              desc:
                'Use SIDE PANEL to set attributes of AREA and click on SAVE',
            },
            {
              func: 'RETURN TO FIXTURES',
              desc: 'Click on CANCEL in SIDE PANEL',
            },
          ]
        } else if (!isDefaultAreaMode) {
          const isNew = isNaN((this.getSelectedAreaObject() || {}).id)
          data = isNew
            ? [
              {
                func: 'CREATE NEW AREA',
                desc:
                    'Use SIDE PANEL to set attributes of AREA and click on SAVE',
              },
              {
                func: 'CANCEL',
                desc: 'Click on CANCEL in SIDE PANEL',
              },
            ]
            : [
              {
                func: 'MODIFY AREA',
                desc:
                    'Use SIDE PANEL to set attributes of AREA and click on SAVE',
              },
              {
                func: 'DELETE AREA',
                desc: 'Click on DELETE in SIDE PANEL',
              },
              {
                func: 'CANCEL',
                desc: 'Click on CANCEL in SIDE PANEL',
              },
            ]
        }
      } else if (!hasSelected) {
        data = [
          {
            func: 'CREATE NEW AREA',
            desc: 'Click and drag in CANVAS to start creating NEW AREA',
          },
          {
            func: 'CANCEL',
            desc: 'Click on CANCEL in SIDE PANEL',
          },
        ]
      }
    }
    return data
  }

  // Unselect active object and create image blob
  canvasImageBlobSave = (callback) => {
    this.canvas.discardActiveObject()
    this.canvasImageBlob(callback)
  }

  // Remove active object and create image blob
  canvasImageBlobDelete = (callback) => {
    this.canvas.removeActiveObject()
    this.canvasImageBlob(callback)
  }

  canvasImageBlob = (callback) => {
    this.canvas.canvasContainer.objectsCanvas.toBlob((blob) => {
      callback(blob)
    })
  }

  renderSidebarForSelectedAreaEdit() {
    const {
      canvasObject,
      selectedId,
      selectedAreaHasShown,
      selectedAreaName,
      selectedAreaAngle,
      isDefaultAreaMode,
      onCancel,
      onRemove,
      onAreaSave,
    } = this.props

    const area =
      selectedId !== -1 &&
      canvasObject.objects.find(({ id }) => id === selectedId)
    const isCreation = area ? isNaN(area.id) : false

    return (
      <div>
        <SidebarTitle>
          <BorderedButton
            type="button"
            className="btn btn-primary w-100 mr-2"
            onClick={() => {
              onCancel()
              if (this.canvas) this.canvas.onDefault()
            }}
          >
            Cancel
          </BorderedButton>
          {!isCreation &&
            !isDefaultAreaMode && (
            <BorderedButton
              type="button"
              className="btn btn-primary w-100 mr-2"
              onClick={() =>
                this.canvasImageBlobDelete((blob) => {
                  onRemove(area.id, blob)
                  if (this.canvas) this.canvas.onDefault()
                })
              }
            >
                Delete
            </BorderedButton>
          )}
          <button
            type="button"
            className="btn btn-raised w-100"
            disabled={this.state.isSaveDisabled}
            onClick={() =>
              this.canvasImageBlobSave((blob) => {
                onAreaSave(area.id, blob)
                if (this.canvas) this.canvas.onDefault()
              })
            }
          >
            Save
          </button>
        </SidebarTitle>
        <SidebarSection>
          <Input
            name={'areaName'}
            label={'Area name'}
            value={selectedAreaName}
            onChange={(e) => {
              this.onNameChange(e.target.value)
            }}
            pattern={safeUrlCharactersPattern}
            validationMessage={invalidUrlCharactersMessage}
            onChangeValidity={this.onChangeValidity}
          />
          <Checkbox
            name={'areaHasShown'}
            label={'Display area name'}
            checked={selectedAreaHasShown}
            onChange={(e) => {
              this.onToggle(e.target.checked)
            }}
          />
        </SidebarSection>
        <SidebarSection>
          <Input
            name={'areaAngle'}
            label={'Area Angle'}
            value={selectedAreaAngle}
            type={'number'}
            min={0}
            max={360}
            onChange={(e) => {
              const value = parseInt(e.target.value, 10)
              this.onAngleChange(value === 360 ? 0 : value)
            }}
            pattern={safeUrlCharactersPattern}
            validationMessage={invalidUrlCharactersMessage}
            onChangeValidity={this.onChangeValidity}
          />
        </SidebarSection>
      </div>
    )
  }

  renderSidebarForSelectedAreaSecurity() {
    const {
      path,
      canvasObject,
      selectedId,
      positions: { positionsByAreaId = [] } = {},
      isStatic,
      onEdit,
      openPosition,
      openFixture,
      missingSecuredProductsByAreaId,
    } = this.props
    const area =
      selectedId !== -1 &&
      canvasObject.objects.find(({ id }) => id === selectedId)
    const areaName = incrementalObjectName(
      this.props.canvasObject.objects,
      defaultName.area
    )
    const aTitle = area ? getAreaName(area) : areaName
    const aTo = area && `${path}/areas/${area.id}/${safeUri(area.name)}`
    return (
      <div className="h-100 flex-column d-flex">
        <SidebarTitle title={aTitle} titleTo={aTo}>
          {isStatic && (
            <button
              type="button"
              className="btn btn-raised"
              onClick={() => onEdit()}
            >
              Edit Area
            </button>
          )}
        </SidebarTitle>
        <SidebarBody className="h-100 flex-column d-flex">
          <TopContainer>
            {this.renderSecurityList(
              area ? positionsByAreaId[area.id] : [],
              true,
              openPosition,
              missingSecuredProductsByAreaId[area.id]
            )}
          </TopContainer>
        </SidebarBody>
      </div>
    )
  }

  renderSidebarForSelectedArea() {
    const { isStatic } = this.props
    return isStatic
      ? this.renderSidebarForSelectedAreaSecurity()
      : this.renderSidebarForSelectedAreaEdit()
  }

  renderSidebarForFloor() {
    const {
      positions: { positionsFloor = [] } = {},
      areas,
      floor,
      isStatic,
      onEdit,
      onCancel,
      openPosition,
      missingSecuredProductsForFloor,
    } = this.props
    return (
      <div className="h-100 flex-column d-flex">
        <SidebarTitle title={floor && floor.name}>
          <div />
          {isStatic ? (
            <button
              type="button"
              className="btn btn-raised"
              onClick={() => onEdit()}
            >
              Create New Area
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-raised"
              onClick={() => onCancel()}
            >
              Exit Edit Mode
            </button>
          )}
        </SidebarTitle>
        <SidebarBody className="h-100 flex-column d-flex">
          <TopContainer>
            {isStatic
              ? this.renderSecurityList(
                positionsFloor,
                false,
                openPosition,
                missingSecuredProductsForFloor
              )
              : this.renderAreasList(areas)}
          </TopContainer>
        </SidebarBody>
      </div>
    )
  }

  renderAreasList(areas) {
    return (
      areas &&
      areas.map((i) => (
        <Item
          key={uuid.v4()}
          onMouseEnter={(id) => {
            if (this.canvas) this.canvas.onOver(id)
          }}
          onMouseLeave={(id) => {
            if (this.canvas) this.canvas.onOut(id)
          }}
          item={{
            id: uuid.v4(),
            title: 'Area',
            subTitle: `${i.name} - ${i.fixturesCount} Fixtures`,
          }}
        />
      ))
    )
  }

  // TODO: Add positions exceptions component in app/containers/ExceptionsList
  // positions exceptions is used in several containers
  renderSecurityList(positions, isAreaSelected, openPosition, securedProducts) {
    const noIssuesText = isAreaSelected
      ? 'There are no Security or Health Issues for the selected Area'
      : 'There are no Security or Health Issues for any of these Areas'

    const hasPositionExceptions = positions && positions.length
    const hasSecuredProductsExceptions =
      securedProducts && securedProducts.length
    if (!hasPositionExceptions && !hasSecuredProductsExceptions) {
      return (
        <div style={{ textAlign: 'center', margin: '10px' }}>
          <span>{noIssuesText}</span>
        </div>
      )
    }

    const { onGoToSecuredProducts } = this.props
    let exceptionsList = []

    const positionsExceptions = positions.map((i) => (
      <Issue
        key={`position-exception-${i.id}`}
        issue={{
          id: i.id,
          title: i.path,
          device: i.name,
          status: i.status.type,
          statusTitle: i.status.title,
          armStateAction: i.status.states.armStateAction,
          armStateActionTitle: i.status.states.armStateActionTitle,
          deviceType: i.deviceType,
          securityDeviceId: i.securityDevice,
        }}
        openPosition={openPosition}
      />
    ))

    const securedProductsExceptions = SecuredProductsExceptions({
      securedProducts: securedProducts || [],
      onGoToSecuredProducts,
    })

    exceptionsList = exceptionsList.concat(
      positionsExceptions,
      securedProductsExceptions
    )
    return exceptionsList
  }

  render() {
    const {
      canvasObject,
      selectedId,
      canvasSize,
      isStatic,
      prototypes,
      onCanvasChanged,
      onOpenArea,
      onMessage,
      onAreaSave,
      alerts,
    } = this.props
    const hasAreas =
      canvasObject && canvasObject.objects && canvasObject.objects.length !== 0
    const hasNoGeometry =
      hasAreas && canvasObject.objects[0] && !canvasObject.objects[0].left
    return (
      <article className={styles.storeContainer}>
        <div className={styles.canvasContainer}>
          <PromptTable data={this.getPromptText()} />
          {isStatic && displayAlerts(alerts)}
          <div className={styles.floorCanvasFrame}>
            {hasNoGeometry &&
              isStatic && (
              <EmptyCanvasLabel>
                  Probably this Floor has been created with different
                  environment
              </EmptyCanvasLabel>
            )}
            <div
              className={styles.canvas}
              style={{
                backgroundColor: 'white',
                backgroundImage: `url('${canvasObject.image}')`,
              }}
            >
              <CanvasUpdate
                ref={(canvas) => {
                  this.canvas = (canvas || {}).canvas
                }}
                component={FloorCanvas}
                canvasObject={canvasObject}
                selectedId={selectedId}
                size={canvasSize}
                isEditable={!isStatic}
                prototypes={prototypes}
                onChanged={onCanvasChanged}
                onOpenArea={onOpenArea}
                onMessage={onMessage}
                onCanvasAreaSave={(id) =>
                  this.canvasImageBlobSave((blob) => {
                    onAreaSave(id, blob)
                  })
                }
              />
            </div>
          </div>
        </div>
        <div className={styles.rightStoreColumn}>
          {selectedId !== -1
            ? this.renderSidebarForSelectedArea()
            : this.renderSidebarForFloor()}
        </div>
      </article>
    )
  }
}

FloorScreen.propTypes = {
  path: PropTypes.string.isRequired,
  canvasSize: PropTypes.object.isRequired,
  canvasObject: PropTypes.object.isRequired,
  positions: PropTypes.object,
  areas: PropTypes.array.isRequired,
  floor: PropTypes.object,
  selectedId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isStatic: PropTypes.bool,
  isDefaultAreaMode: PropTypes.bool,
  prototypes: PropTypes.object,
  onCanvasChanged: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onAreaChange: PropTypes.func.isRequired,
  onAreaSave: PropTypes.func.isRequired,
  onOpenArea: PropTypes.func.isRequired,
  goToArea: PropTypes.func.isRequired,
  onMessage: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  selectedAreaName: PropTypes.string.isRequired,
  selectedAreaAngle: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  selectedAreaHasShown: PropTypes.bool.isRequired,
  openPosition: PropTypes.func.isRequired,
  openFixture: PropTypes.func.isRequired,
  missingSecuredProductsForFloor: PropTypes.array,
  missingSecuredProductsByAreaId: PropTypes.object,
  onGoToSecuredProducts: PropTypes.func,
}

export default FloorScreen
