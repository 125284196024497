import _ from 'lodash'
import hexRgb from 'hex-to-rgba'
import {
  Color,
  positionType,
  mtiFixtureType,
  sviFigureType,
  toSviCoordStructure,
  defaultName,
} from '../../utils/mtiCanvasUtils'
import { getObject as getFixtureObject } from '../AreaPage/objectUtils'
import { getObject, selectionType, getSelectionObject } from './objectUtils'
import { getEditTypeByDevice } from './utilsEditTypes'
import {
  getGeometry,
  extendedQuarterCircleAspectRatio,
} from '../AreaPage/utils'
// import { states } from './spStates.test'

// Used for padding around the fixture
// we scale down the canvas content to have a bit of the padding around
const scaleFactor = 0.94
const scaleFactorLocks = 0.76
const scaleOutlineDelta = 0.06
const cableTypeNone = 0

export function toFabricSync(
  fixture,
  screen,
  isStatic,
  selectedPositionId,
  prototypes,
  mtiJsclientShared // This is for mobile and web compatibility. We don't want to keep shared client inside static web component for the purpose of lightness. In the same same time I think we can treat this code as reliable but temporary
) {
  if (!fixture) return fixture
  const scalingFactor = fixture.hasLocks ? scaleFactorLocks : scaleFactor

  let fixtureObject = toFabricFixtureSync(
    updateWithSviType(fixture),
    screen,
    prototypes
  )
  // Keep positions sorted, arm / disarm actions may change the order in orm
  const positions = (fixture.positions || []).sort((a, b) => a.id - b.id)
  let positionObjects = (positions || []).map((p) => {
    const positionObject = toFabricPositionSync(
      p,
      screen,
      isStatic,
      p.id === selectedPositionId,
      fixture.name,
      prototypes,
      mtiJsclientShared
    )
    return positionObject
  })
  fixtureObject = addPaddingFixture(fixtureObject, scalingFactor)
  positionObjects = addPaddingPositions(positionObjects, scalingFactor, screen)
  positionObjects = alignVertically(positionObjects, screen)
  const protoPositions = (prototypes || {}).positions || {}
  return {
    canvasObject: {
      objects: [fixtureObject, ...positionObjects],
      selection: {
        [selectionType.round]: scaleSelection(
          protoPositions[selectionType.round],
          scalingFactor + scaleOutlineDelta
        ),
        [selectionType.square]: scaleSelection(
          protoPositions[selectionType.square],
          scalingFactor + scaleOutlineDelta
        ),
        [selectionType.securePlug]: scaleSelection(
          protoPositions[selectionType.securePlug],
          scalingFactor + scaleOutlineDelta
        ),
        [selectionType.cxFlexFourPort]: scaleSelection(
          protoPositions[selectionType.cxFlexFourPort],
          scalingFactor + scaleOutlineDelta
        ),
      },
      scalingFactor,
    },
  }
}

export function scaleSelection(protoSelection, factor) {
  const o = _.cloneDeep(protoSelection)
  o.set('width', o.get('width') / factor)
  o.set('height', o.get('height') / factor)
  o.set('scaleFactor', factor)
  return o
}

export function addPaddingFixture(o, factor) {
  const attrs = {
    scaleX: o.scaleX * factor,
    scaleY: o.scaleY * factor,
  }
  return { ...o, ...attrs }
}

export function addPaddingPositions(objects, factor, screen) {
  const fxWidth = screen.width * factor
  const fxHeight = screen.height * factor
  const marginH = (screen.width - screen.width * factor) / 2
  const marginV = (screen.height - screen.height * factor) / 2

  const center = {
    x: screen.width / 2,
    y: screen.height / 2,
  }

  return objects.map((o) => {
    let left = o.left
    let top = o.top

    // this part prevents using of padding for the positions located within the fixture bounds
    // WIP: it could be replaced by smarter more complex solution
    if (
      _.inRange(left, marginH, fxWidth + marginH) &&
      _.inRange(top, marginV, fxHeight + marginV)
    ) {
      return {
        ...o,
        ...{ scaleX: o.scaleX * factor, scaleY: o.scaleY * factor },
      }
    }

    if (left <= center.x) {
      if (top <= center.y) {
        // left top
        left = center.x - (center.x - left) * factor
        top = center.y - (center.y - top) * factor
      } else {
        // left bottom
        left = center.x - (center.x - left) * factor
        top = center.y + (top - center.y) * factor
      }
    } else if (top <= center.y) {
      // right top
      left = center.x + (left - center.x) * factor
      top = center.y - (center.y - top) * factor
    } else {
      // right bottom
      left = center.x + (left - center.x) * factor
      top = center.y + (top - center.y) * factor
    }

    const attrs = {
      scaleX: o.scaleX * factor,
      scaleY: o.scaleY * factor,
      left,
      top,
    }

    return { ...o, ...attrs }
  })
}

export function addXYPadding(objects, fX, fY, screen) {
  const center = {
    x: screen.width / 2,
    y: screen.height / 2,
  }
  return objects.map((o) => {
    let left = o.left
    let top = o.top
    if (left <= center.x) {
      if (top <= center.y) {
        // left top
        left = center.x - (center.x - left) * fX
        top = center.y - (center.y - top) * fY
      } else {
        // left bottom
        left = center.x - (center.x - left) * fX
        top = center.y + (top - center.y) * fY
      }
    } else if (top <= center.y) {
      // right top
      left = center.x + (left - center.x) * fX
      top = center.y - (center.y - top) * fY
    } else {
      // right bottom
      left = center.x + (left - center.x) * fX
      top = center.y + (top - center.y) * fY
    }
    const attrs = {
      left,
      top,
    }
    return { ...o, ...attrs }
  })
}

export const lockMovement = {
  lockMovementX: true,
  lockMovementY: true,
}

export function toFabricPositionSync(
  position,
  screen,
  isStatic,
  isSelected,
  fixtureName,
  prototypes,
  mtiJsclientShared
) {
  const isSelectedInEditMode = !isStatic && isSelected
  const { status: { type: mtiType = 'Unknown' } = {} } = position
  const p = position

  return toFabricPositionSviSync(
    toSviCoordStructure(p),
    screen,
    isStatic,
    mtiType,
    isSelectedInEditMode,
    fixtureName,
    prototypes,
    mtiJsclientShared
  )
}

export function getFabricPosition(
  o,
  prototypes,
  isSelectedInEditMode,
  mtiJsclientShared = {}
) {
  //const { status: { type: mtiType = 'Unknown' } = {}, deviceType } = o // TODO: SP: uncomment after SP state processing implemented
  // type comes from the `armStateType` of the `armStateDataForDevice` function inside of the shared repo at `src/armState/armStateData.js`
  const { status: { type = 'Unknown' } = {}, wantedDeviceType } = o
  let mtiType = type
  const { ArmState = {} } = mtiJsclientShared
  if (mtiType === ArmState.OTHER_STATE) {
    console.error(
      o.name,
      'ArmState has not been calculated properly and is',
      mtiType
    )
    if (o.status) {
      o.status.type = 'Unknown'
    }
    mtiType = 'Unknown'
  }
  let deviceType = o.deviceType
  const { DeviceType = {} } = mtiJsclientShared
  const isSecurePlug = DeviceType.SECURE_PLUG === deviceType
  const isCXFlexFourPort = DeviceType.CX_FLEX_FOUR_PORT === deviceType
  let fabricObject
  if (isSelectedInEditMode) {
    const editModeType = getEditTypeByDevice(o, mtiJsclientShared)
    fabricObject = prototypes.positions[editModeType].toObject()
    fabricObject.selectedForEdit = true
  } else {
    if (isSecurePlug) {
      fabricObject = configureSecurePlugPrototype(o, prototypes.positions)
      return fabricObject
    }
    const isLock = DeviceType.LOCK === deviceType
    const issuesCount = getIssuesCountHC(o, mtiJsclientShared)

    if (isCXFlexFourPort) {
      fabricObject = configureCXFlexPrototype(
        o,
        prototypes.positions,
        issuesCount
      )
      return fabricObject
    }

    // TODO: consider simplification by using only 'HC' resources
    let protoName = `${_.upperFirst(_.camelCase(mtiType))}${
      isLock ? 'Lock' : ''
    }${issuesCount > 0 ? 'HC' : ''}`
    if (!prototypes.positions[protoName] && protoName.endsWith('HC')) {
      protoName = protoName.replace('HC', '')
    }
    if (!prototypes.positions[protoName] && protoName.endsWith('Lock')) {
      protoName = protoName.replace(new RegExp('Lock' + '$'), '')
    }
    if (!prototypes.positions[protoName]) {
      protoName = 'Unknown'
    }
    if (positionType.missingSecurityDevice === protoName) {
      protoName = getProtoNameForWantedDeviceType(
        wantedDeviceType,
        mtiJsclientShared
      )
    }
    fabricObject = _.cloneDeep(prototypes.positions[protoName])
    fabricObject = setPrototypeCounterHC(fabricObject, issuesCount)
  }
  return fabricObject
}

export function toFabricPositionSviSync(
  o,
  screen,
  isStatic,
  mtiType,
  isSelectedInEditMode,
  fixtureName,
  prototypes,
  mtiJsclientShared
) {
  if (!o) return o
  const { id, name, parentId, deviceType, wantedDeviceType } = o
  // const { /* width: w, height: h, */ xCenter: xC, yCenter: yC } = o
  // const width = w * screen.width
  // const height = h * screen.width
  let xC = o.xCenter
  let yC = o.yCenter
  if (!xC || xC < 0 || xC > 1 || !yC || yC < 0 || yC > 0.666) {
    xC = 0.5
    yC = 0.333
  }
  const xCenter = xC * screen.width
  const yCenter = yC * screen.width
  // Position has origin at center center
  const pLeft = xCenter
  const pTop = yCenter
  // const angle = o.theta

  const fabricObject = getFabricPosition(
    o,
    prototypes,
    isSelectedInEditMode,
    mtiJsclientShared
  )
  // const pScaleX = screen.width / 8 / fabricObject.width
  const pScaleY = screen.width / 8 / fabricObject.height

  const attrs = {
    ...(isStatic ? lockMovement : {}),
    lockScalingX: true,
    lockScalingY: true,
    hasControls: false,
    hasRotatingPoint: false,
    hasBorders: false,
    scaleX: pScaleY,
    scaleY: pScaleY,
    left: pLeft,
    top: pTop,
    originX: 'center',
    originY: 'center',
    mtiType,
    wantedDeviceType,
    deviceType,
    angle: 0,
    id,
    name,
    parentId,
    contextType: 'position',
  }

  return { ...fabricObject, ...attrs }
}

export const transform = (
  initVector,
  angle,
  scaleVector,
  translationVector
) => {
  const rad = angle * (Math.PI / 180)
  const { x: scaleX, y: scaleY } = scaleVector
  const { x: transX, y: transY } = translationVector
  const { x: oldX, y: oldY } = initVector

  // Rotate, scale and translate
  // [(rotation_martix) x (scale_matrix) + translation_vector] x (x, y)
  const newX =
    oldX * Math.cos(rad) * scaleX - Math.sin(rad) * scaleY * oldY + transX
  const newY =
    oldX * Math.sin(rad) * scaleX + Math.cos(rad) * scaleY * oldY + transY

  return {
    x: newX,
    y: newY,
  }
}

/**
 * Return a default angle to display a fixture at
 * @param {string} type MTI object type
 */
export const defaultFixtureAngle = (type) => {
  if (type === mtiFixtureType.quarterCircle) {
    return 45
  } else if (
    type === mtiFixtureType.extendedQuarterCircle ||
    type === sviFigureType.extendedRightQuarterCircle ||
    type === sviFigureType.extendedLeftQuarterCircle
  ) {
    return -90
  }

  return 0
}

export function toFabricFixtureSync(o, screen, prototypes) {
  if (!o) return o
  const { id, parentId, type } = o
  const mtiType =
    type === mtiFixtureType.extendedQuarterCircle
      ? sviFigureType.extendedLeftQuarterCircle
      : type
  const fabricObject = prototypes[mtiType]
  if (!fabricObject) {
    throw new Error(`Type ${mtiType} - no object`)
  }
  const geometry = getGeometry(o, screen)
  if (!geometry) {
    throw new Error(`Fixture ${id} - no geometry`)
  }
  const { width, height, left, top } = geometry

  const fixtureAngle = defaultFixtureAngle(type)

  let scaledWidth = width
  let scaledHeight = height
  if (
    type === mtiFixtureType.extendedQuarterCircle ||
    type === sviFigureType.extendedRightQuarterCircle ||
    type === sviFigureType.extendedLeftQuarterCircle
  ) {
    scaledWidth = screen.width / extendedQuarterCircleAspectRatio
    scaledHeight = screen.width
  }

  const scaleX = scaledWidth / fabricObject.width
  const scaleY = scaledHeight / fabricObject.height

  const attrs = {
    scaleX,
    scaleY,
    angle: fixtureAngle,
    left,
    top,
    originX: 'center',
    originY: 'center',
    strokeWidth: 0,
    fill: '#FFFFFF',
    selectable: false,
    mtiType,
    id,
    parentId,
    contextType: 'fixture',
    flipY: type === sviFigureType.extendedRightQuarterCircle,
  }
  return { ...fabricObject, ...attrs }
}

export function alignVertically(objects, screen, icRatio = 3 / 2) {
  let dY = (screen.width / icRatio - screen.height) / 2
  if (dY === 0) {
    return objects
  }
  dY /= 1.06 // correction coef
  return objects.map((o) => {
    const attrs = {
      top: o.top - dY,
    }
    return { ...o, ...attrs }
  })
}

export function getSelectedObjectIndex(canvas, selectedPosition) {
  if (!canvas) return -1
  if (!selectedPosition) {
    console.warn('selectedPosition should be defined')
    return -1
  }
  const { canvasObject: { objects = [] } = {} } = canvas

  return objects.findIndex((o) => o.id === selectedPosition.id)
}

export function getBounds(objects) {
  const icObject = objects[0]
  const { left: leftIc, top: topIc } = icObject
  const bounds = { left: leftIc, top: topIc, right: leftIc, bottom: topIc }
  objects.map((o) => {
    const left = o.left
    const top = o.top
    if (left < bounds.left) {
      bounds.left = left
    } else if (left > bounds.right) {
      bounds.right = left
    }
    if (top > bounds.top) {
      bounds.top = top
    } else if (top < bounds.bottom) {
      bounds.bottom = top
    }
    return bounds
  })
  return bounds
}

export function centerInBox(objects, bounds, boundingBox) {
  const boundsCenter = getBoxCenter(bounds)
  const boundingBoxCenter = getBoxCenter(boundingBox)
  const dX = boundingBoxCenter.x - boundsCenter.x
  const dY = boundingBoxCenter.y - boundsCenter.y
  return objects.map((o) => {
    const attrs = {
      left: o.left + dX,
      top: o.top + dY,
    }
    return { ...o, ...attrs }
  })
}

export function getBoxCenter(box) {
  return {
    x: (box.right - box.left) / 2 + box.left,
    y: (box.top - box.bottom) / 2 + box.bottom,
  }
}

export function centerInFixture(objects, fixture) {
  if (!objects || objects.length === 0) return objects
  const bounds = getBounds(objects)
  const { left, top, width, height, scaleX, scaleY } = fixture
  const widthReal = width * scaleX
  const heightReal = height * scaleY
  const boundingBox = {
    left: left - widthReal / 2,
    top: top - heightReal / 2,
    right: left + widthReal / 2,
    bottom: top + heightReal / 2,
  }
  return centerInBox(objects, bounds, boundingBox)
}

export function updateWithSviType(fixture) {
  if (fixture.layoutPosition && fixture.layoutPosition.type) {
    return { ...fixture, type: fixture.layoutPosition.type }
  }
  return fixture
}

export function getObjectAttrs(object, id, screen, origX, origY) {
  // const pScaleX = screen.width / 8 / object.width
  const pScaleY = screen.width / 8 / object.height
  return {
    id,
    lockScalingX: true,
    lockScalingY: true,
    hasControls: false,
    hasRotatingPoint: false,
    hasBorders: false,
    scaleX: pScaleY,
    scaleY: pScaleY,
    left: origX,
    top: origY,
    originX: 'center',
    originY: 'center',
    angle: 0,
    name: defaultName.position,
    contextType: 'position',
  }
}

export function toSelectionAttrs(attrs) {
  return {
    ...attrs,
    name: '',
    scaleX: attrs.scaleX * 1.22,
    scaleY: attrs.scaleY * 1.22,
    contextType: 'selection',
    evented: 'false',
    selectable: 'false',
    isEditable: 'fasle',
  }
}

export async function getPrototypes() {
  return {
    // Fixtures
    [mtiFixtureType.table]: await getFixtureObject(mtiFixtureType.table),
    [mtiFixtureType.circle]: await getFixtureObject(mtiFixtureType.circle),
    [mtiFixtureType.quarterCircle]: await getFixtureObject(
      mtiFixtureType.quarterCircle
    ),
    [mtiFixtureType.wall]: await getFixtureObject(mtiFixtureType.wall),
    [mtiFixtureType.wallCap]: await getFixtureObject(mtiFixtureType.wallCap),
    [sviFigureType.custom]: await getFixtureObject(sviFigureType.custom),
    [sviFigureType.narrowRectangle]: await getFixtureObject(
      sviFigureType.narrowRectangle
    ),
    [sviFigureType.wideRectangle]: await getFixtureObject(
      sviFigureType.wideRectangle
    ),
    [sviFigureType.square]: await getFixtureObject(sviFigureType.square),
    [sviFigureType.circle]: await getFixtureObject(sviFigureType.circle),
    [sviFigureType.quarterCircle]: await getFixtureObject(
      sviFigureType.quarterCircle
    ),
    [sviFigureType.extendedRightQuarterCircle]: await getFixtureObject(
      sviFigureType.extendedRightQuarterCircle
    ),
    [sviFigureType.extendedLeftQuarterCircle]: await getFixtureObject(
      sviFigureType.extendedLeftQuarterCircle
    ),
    // Positions
    positions: {
      [positionType.alarming]: await getObject(positionType.alarming),
      [positionType.alarmingHC]: await getObject(positionType.alarmingHC),
      [positionType.silentAlarming]: await getObject(
        positionType.silentAlarming
      ),
      [positionType.silentAlarmingHC]: await getObject(
        positionType.silentAlarmingHC
      ),
      [positionType.alarmingLock]: await getObject(positionType.alarmingLock),
      [positionType.alarmingLockHC]: await getObject(
        positionType.alarmingLockHC
      ),
      [positionType.silentAlarmingLock]: await getObject(
        positionType.silentAlarmingLock
      ),
      [positionType.silentAlarmingLockHC]: await getObject(
        positionType.silentAlarmingLockHC
      ),
      [positionType.armed]: await getObject(positionType.armed),
      [positionType.locked]: await getObject(positionType.locked),
      [positionType.disarmed]: await getObject(positionType.disarmed),
      [positionType.disarmedHC]: await getObject(positionType.disarmedHC),
      [positionType.unlocked]: await getObject(positionType.unlocked),
      [positionType.open]: await getObject(positionType.open),
      [positionType.unlockedHC]: await getObject(positionType.unlockedHC),
      [positionType.unlockedLockHC]: await getObject(positionType.unlockedHC),
      [positionType.unhealthy]: await getObject(positionType.unhealthy),
      [positionType.warning]: await getObject(positionType.warning),
      [positionType.nonCompliant]: await getObject(positionType.nonCompliant),
      [positionType.unhealthyLock]: await getObject(positionType.unhealthyLock),
      [positionType.warningLock]: await getObject(positionType.warningLock),
      [positionType.nonCompliantLock]: await getObject(
        positionType.nonCompliantLock
      ),
      [positionType.unpowered]: await getObject(positionType.unpowered),
      [positionType.silenced]: await getObject(positionType.silenced),
      [positionType.securePlug]: await getObject(positionType.securePlug),
      [positionType.cxFlexFourPort]: await getObject(
        positionType.cxFlexFourPort
      ),
      [positionType.cxFlexFourPortHC]: await getObject(
        positionType.cxFlexFourPortHC
      ),
      [positionType.missingSecurePlugDevice]: await getObject(
        positionType.missingSecurePlugDevice
      ),
      [positionType.missingCXFlexDevice]: await getObject(
        positionType.missingCXFlexDevice
      ),
      [positionType.missingLockDevice]: await getObject(
        positionType.missingLockDevice
      ),
      [positionType.missingSecurityDevice]: await getObject(
        positionType.missingSecurityDevice
      ),
      [positionType.cxFlexProductDisconnected]: await getObject(
        positionType.cxFlexProductDisconnected
      ),
      ['Unknown']: await getObject(positionType.missingSecurityDevice),
      [selectionType.round]: await getSelectionObject(
        selectionType.round,
        false
      ),
      [selectionType.square]: await getSelectionObject(
        selectionType.square,
        false
      ),
      [selectionType.securePlug]: await getSelectionObject(
        selectionType.securePlug,
        false
      ),
      [selectionType.cxFlexFourPort]: await getSelectionObject(
        selectionType.cxFlexFourPort,
        false
      ),
    },
  }
}

function configureSecurePlugPrototype(o, prototypes) {
  const sp = _.cloneDeep(prototypes[positionType.securePlug])

  const { color, state, ports } = getSecuredPlugState(o)
  // TODO: SP: fix left for resource or ignore
  let deltaLeft = 0
  let deltaTop = 0
  switch (state) {
    case positionType.armed:
    case positionType.unhealthy:
      deltaLeft = 4
      deltaTop = -2
      break
    case positionType.disarmed:
      deltaTop = -2
      break
    case positionType.unpowered:
      deltaLeft = 2
      break
    case positionType.silentAlarming:
      deltaLeft = 4
      break
  }
  // Outline
  sp.objects[0].stroke = color // Outline
  // Icon
  const protoStatusIconPath = sp.objects[2]
  const statusIconPath = getSecuredPlugStatusIconPath(state, prototypes)
  statusIconPath.left = protoStatusIconPath.left + deltaLeft
  statusIconPath.top = protoStatusIconPath.top + deltaTop
  sp.objects[1].fill = color // Status Icon Rect
  sp.objects[2] = statusIconPath // Status Icon Path
  // Ports
  sp.objects[3].fill = ports[0] // Port 1
  sp.objects[4].fill = ports[1] // Port 2
  sp.objects[5].fill = ports[2] // Port 3
  sp.objects[6].fill = ports[3] // Port 4
  sp.objects[7].fill = ports[4] // Port 5
  sp.objects[8].fill = ports[5] // Port 6
  return sp
}

function getSecuredPlugStatusIconPath(state, prototypes) {
  const finalState = state === 'Unknown' ? positionType.securePlug : state
  const statusIcon = _.cloneDeep(prototypes[finalState])
  let statusIconPath
  switch (finalState) {
    case positionType.unhealthy:
      // For unhealthy state Health and Compliance icon is used, it has different numeration
      statusIconPath = statusIcon.objects[0].objects[1]
      break
    default:
      statusIconPath = statusIcon.objects[1]
  }
  return statusIconPath
}

function getSecuredPlugState(o) {
  // console.log('Secure Plug data >> ', o)
  const { status: { type: mtiType = 'Unknown' } = {}, portsState = [] } = o
  let protoName = `${_.upperFirst(_.camelCase(mtiType))}`
  const portsColor = portsState.map((port) => {
    if (!port) {
      console.error('No port defined for Secure Plug device, unexpected')
      return Color.gray
    }
    const { color } = port
    if (!color) {
      console.error(
        'No color defined for port of Secure Plug device, unexpected'
      )
      return Color.gray
    }
    return port.color
  })
  return {
    state: protoName,
    color: getSecuredPlugColor(protoName),
    ports: portsColor,
  }
}

function getSecuredPlugColor(type) {
  switch (type) {
    case positionType.alarming:
    case positionType.silenced:
    case positionType.disarmed:
      return hexRgb(`${Color.red}DD`)
    case positionType.unhealthy:
    case positionType.nonCompliant:
      return hexRgb(`${Color.yellow}DD`)
    case positionType.armed:
    case positionType.unpowered:
      return hexRgb(`${Color.green}DD`)
    default:
      return hexRgb(`${Color.red}DD`) // Smth is not right, returning red
  }
}

function configureCXFlexPrototype(o, prototypes, issuesCount) {
  let cf
  let cfProto
  if (issuesCount > 0) {
    // icon with issues consists of two objects (cx flex icon [0] and text [1])
    cfProto = _.cloneDeep(prototypes[positionType.cxFlexFourPortHC])
    cf = cfProto.objects[0]
  } else {
    cf = _.cloneDeep(prototypes[positionType.cxFlexFourPort])
  }

  const { color, state } = getCXFlexState(o)
  const { portsState: ports } = o
  let deltaLeft = 0
  let deltaTop = 0
  switch (state) {
    case positionType.armed:
    case positionType.unhealthy:
      deltaLeft = -2
      deltaTop = -2
      break
    case positionType.disarmed:
      deltaLeft = -6
      deltaTop = -2
      break
    case positionType.alarming:
      deltaLeft = -6
      break
    case positionType.silentAlarming:
      deltaLeft = -2
      break
  }
  // Outline
  cf.objects[0].fill = color // container #Rectangle-12
  cf.objects[0].stroke = color // container outline #Rectangle-12
  // Icon
  const protoStatusIconPath = cf.objects[2]
  const statusIconPath = getCXFlexStatusIconPath(state, prototypes)
  statusIconPath.left = protoStatusIconPath.left + deltaLeft
  statusIconPath.top = protoStatusIconPath.top + deltaTop
  cf.objects[2] = statusIconPath // Status Icon Path

  // Ports
  for (var i=0; i<4; i++) {
    cf.objects[i+3].fill = ports[i].color || Color.gray
  }

  if (issuesCount > 0) {
    const textDeltaTop = -14
    let textObject = cfProto.objects[1]
    textObject.top = textObject.top + textDeltaTop
    textObject.text = issuesCount.toString()
    textObject.fullText = issuesCount.toString()
    return cfProto
  }
  return cf
}

function getCXFlexStatusIconPath(state, prototypes) {
  let finalState = state === 'Unknown' ? positionType.cxFlexFourPort : state
  const statusIcon = _.cloneDeep(prototypes[finalState])
  let statusIconPath
  switch (finalState) {
    case positionType.unhealthy:
      // For unhealthy state Health and Compliance icon is used, it has different numeration
      statusIconPath = statusIcon.objects[0].objects[1]
      break
    default:
      statusIconPath = statusIcon.objects[1]
  }
  return statusIconPath
}

function getCXFlexState(o) {
  const { status: { type: mtiType = 'Unknown' } = {} } = o
  let protoName = `${_.upperFirst(_.camelCase(mtiType))}`
  return {
    state: protoName,
    color: getCXFlexColor(protoName),
  }
}

function getCXFlexColor(type) {
  switch (type) {
    case positionType.alarming:
    case positionType.silenced:
    case positionType.disarmed:
      return hexRgb(`${Color.red}DD`)
    case positionType.unhealthy:
    case positionType.nonCompliant:
      return hexRgb(`${Color.yellow}DD`)
    case positionType.armed:
      return hexRgb(`${Color.green}DD`)
    default:
      return hexRgb(`${Color.red}DD`) // Smth is not right, returning red
  }
}

function getProtoNameForWantedDeviceType(
  wantedDeviceType,
  mtiJsclientShared = {}
) {
  const { DeviceType = {} } = mtiJsclientShared
  switch (wantedDeviceType) {
    case DeviceType.SECURE_PLUG:
      return positionType.missingSecurePlugDevice
    case DeviceType.CX_FLEX_FOUR_PORT:
      return positionType.missingCXFlexDevice
    case DeviceType.LOCK:
      return positionType.missingLockDevice
    case DeviceType.RAM:
    case DeviceType.PUCK:
      return positionType.missingSecurityDevice
    default:
      return positionType.missingSecurityDevice
  }
}

function setPrototypeCounterHC(positionPrototype, issuesCount) {
  if (
    !positionPrototype ||
    !positionPrototype.objects ||
    !positionPrototype.objects.length
  ) {
    return positionPrototype
  }

  const issuesCountString = issuesCount.toString()
  positionPrototype.objects[1].text = issuesCountString
  positionPrototype.objects[1].fullText = issuesCountString

  return positionPrototype
}

// TODO: should be moved to the shared library in some way
function getIssuesCountHC(position, mtiJsclientShared) {
  const { rules, status: { states: { healthStateType } } } = position
  const { PositionRuleStatus } = mtiJsclientShared
  const complianceIssuesCount = rules.reduce(
    (count, item) =>
      item.status === PositionRuleStatus.VIOLATION ? count + 1 : count,
    0
  )

  const healthIssuesCount = (() => {
    const { HealthStatus = {} } = mtiJsclientShared
    switch (healthStateType) {
      case HealthStatus.HEALTHY:
        return 0
      case HealthStatus.UNHEALTHY:
      case HealthStatus.WARNING:
      case HealthStatus.MISSING_SECURITY_DEVICE:
      case HealthStatus.UNKNOWN:
        return 1
      default:
        return 0
    }
  })()

  return complianceIssuesCount + healthIssuesCount
}

export function addTransparency(getPrototypes) {
  const prototypes = getPrototypes
  const protoPositions = (prototypes || {}).positions
  Object.keys(protoPositions).forEach((key) => {
    if (prototypes.positions[key].objects) {
      if (prototypes.positions[key].objects.length > 3) {
        //for all SecurePlug
        prototypes.positions[key].objects.map(
          (e, i) =>
            prototypes.positions[key].objects[i].fill &&
            (prototypes.positions[key].objects[i].fill = hexRgb(
              `${protoPositions[key].objects[i].fill}DD`
            ))
        )
      } else {
        if (prototypes.positions[key].objects[0].objects)
          prototypes.positions[key].objects[0].objects[0].fill = hexRgb(
            `${protoPositions[key].objects[0].objects[0].fill}DD`
          )
        else
          prototypes.positions[key].objects[0].fill = hexRgb(
            `${protoPositions[key].objects[0].fill}DD`
          )
      }
    } else
      prototypes.positions[key].fill = hexRgb(`${protoPositions[key].fill}DD`)
  })
  return prototypes
}

// TODO: Deprecated
export function getSelectionType(type, mtiJsclientShared = {}) {
  const { ArmState = {} } = mtiJsclientShared
  switch (type) {
    case ArmState.LOCKED:
    case ArmState.ULOCKED:
      return selectionType.square
    default:
      return selectionType.round
  }
}

export function getSelectionByType(
  { deviceType, wantedDeviceType },
  objects,
  mtiJsclientShared = {}
) {
  const { DeviceType = {} } = mtiJsclientShared
  switch (deviceType || wantedDeviceType) {
    case DeviceType.SECURE_PLUG:
    case positionType.missingSecurePlugDevice:
      return objects[selectionType.securePlug]
    case DeviceType.CX_FLEX_FOUR_PORT:
    case positionType.missingCXFlexDevice:
      return objects[selectionType.cxFlexFourPort]
    case DeviceType.LOCK:
    case positionType.missingLockDevice:
      return objects[selectionType.square]
    default:
      return objects[selectionType.round]
  }
}
