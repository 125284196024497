import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose } from 'redux'
import { makeSelectTemplatesLoading } from '../../Templates/selectors'
import SelectTemplateScreen from './selectTemplate.screen'
import { loadTemplates } from '../../Templates/actions'
import { makeSelectTemplateFixtures as makeSelectTemplates } from '../selectors'

class SelectTemplate extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      selectedTemplate: props.existingTemplate,
    }
  }

  componentDidMount() {
    this.props.loadTemplates()
  }

  onSelection = (data) => this.setState({ ...data })

  onConfirm = () => {
    const { onConfirm } = this.props
    const { selectedTemplate } = this.state
    onConfirm(selectedTemplate)
  }

  render() {
    const {
      id,
      onClose,
      templates,
      existingTemplate,
      onResetTemplate,
      templatesLoading,
    } = this.props
    const { selectedTemplate } = this.state

    return (
      <SelectTemplateScreen
        id={id}
        templates={templates}
        selectedTemplate={selectedTemplate}
        existingTemplate={existingTemplate}
        onConfirm={this.onConfirm}
        onClose={onClose}
        onResetTemplate={onResetTemplate}
        onSelection={this.onSelection}
        templatesLoading={templatesLoading}
      />
    )
  }
}

SelectTemplate.propTypes = {
  id: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onResetTemplate: PropTypes.func,
  templates: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  existingTemplate: PropTypes.object,
  loadTemplates: PropTypes.func.isRequired,
  templatesLoading: PropTypes.bool,
}

SelectTemplate.defaultProps = {
  templates: [],
  existingTemplate: {},
}

const mapStateToProps = createStructuredSelector({
  templates: makeSelectTemplates(),
  templatesLoading: makeSelectTemplatesLoading(),
})

const mapDispatchToProps = {
  loadTemplates,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect)(SelectTemplate)
