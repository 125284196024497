import { ActionTypes } from './constants'

export function layoutTableDataReducerAction(tableViewApiResponse) {
  return {
    type: ActionTypes.LAYOUT_TABLE_DATA_REDUCER,
    payload: tableViewApiResponse,
  }
}

export function layoutTableViewLoadingAction(isLoading) {
  return {
    type: ActionTypes.LOADING_TABLE_VIEW,
    payload: { isLoading },
  }
}

export function areasFilterReducerAction(areasApiResponse) {
  return {
    type: ActionTypes.AREAS_FILTER_REDUCER,
    payload: areasApiResponse,
  }
}

export function fixturesFilterReducerAction(fixturesApiResponse) {
  return {
    type: ActionTypes.FIXTURES_FILTER_REDUCER,
    payload: fixturesApiResponse,
  }
}

export function fetchLayoutTableViewData(storeID, filters, loadPage = false) {
  return {
    type: ActionTypes.FETCH_LAYOUT_TABLE_VIEW_DATA,
    payload: { storeID, filters, loadPage },
  }
}
