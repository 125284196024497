import uuid from 'uuid'
import {
  getNearestAspectRatio,
  defaultName,
  fixtureRatio,
  mtiFixtureType,
  sviFigureType,
} from '../../utils/mtiCanvasUtils'
import { getAutoPlacementCoords } from '../AreaPage/objectAutoplacementUtils'
import { getEditObject } from './objectEditUtils'
import { editType } from './utilsEditTypes'
import {
  getGeometry,
  extendedQuarterCircleAspectRatio,
} from '../AreaPage/utils'
import {
  toFabricFixtureSync,
  addPaddingFixture,
  defaultFixtureAngle,
  transform,
  addXYPadding,
  addPaddingPositions,
  lockMovement,
} from './utils'

// Used for padding around the fixture
// we scale down the canvas content to have a bit of the padding around
const scaleFactor = 0.94
const scaleFactorLocks = 0.76

export async function getAutoplacement(
  fixture,
  screen,
  placementCount = 0,
  isStatic = false,
  prototypes
) {
  if (!fixture) return fixture
  // const placementCount = 1
  // fixture.type = 'square'
  // fixture.type = 'wideRectangle'
  // fixture.type = 'narrowRectangle'
  // fixture.type = 'custom'
  // fixture.type = 'table'
  // fixture.type = 'circle'
  // fixture.type = 'quarterCircle'
  // fixture.type = 'extendedQuarterCircle'
  const scalingFactor = fixture.hasLocks ? scaleFactorLocks : scaleFactor

  let fixtureObject = toFabricFixtureSync(fixture, screen, prototypes)

  fixtureObject = addPaddingFixture(fixtureObject, scalingFactor)

  let positionAutoPlacement = await getAutoPlacement(
    fixture,
    screen,
    isStatic,
    placementCount
  )

  positionAutoPlacement = centerInFixture(positionAutoPlacement, fixtureObject)

  positionAutoPlacement = addPaddingPositions(
    positionAutoPlacement,
    scalingFactor,
    screen
  )

  return {
    canvasObject: {
      objects: [fixtureObject, ...positionAutoPlacement],
    },
  }
}

export async function getAutoPlacement(fixture, screen, isStatic, count) {
  if (count === 0) return []
  let autoPlacementObject = await getAutoPlacementCoords(fixture.type, count)
  // Adds temporary id for positions in autoplacement mode
  autoPlacementObject = autoPlacementObject.map((p) => ({
    ...p,
    id: uuid.v4(),
  }))
  //
  let positionAutoPlacement = await Promise.all(
    autoPlacementObject.map(async (p, i) => {
      const positionObject = toFabricPositionA(
        p,
        fixture,
        screen,
        true,
        isStatic,
        i
      )
      return positionObject
    })
  )
  positionAutoPlacement = positionAutoplacement(
    positionAutoPlacement,
    fixture.type,
    screen
  )
  return positionAutoPlacement
}

export function positionAutoplacement(objects, fType, screen) {
  if (
    fType === mtiFixtureType.extendedQuarterCircle ||
    fType === sviFigureType.extendedQuarterCircle ||
    fType === sviFigureType.extendedRightQuarterCircle ||
    fType === sviFigureType.extendedLeftQuarterCircle ||
    fType === mtiFixtureType.quarterCircle ||
    fType === sviFigureType.quarterCircle
  ) {
    return positionAutoplacementNoSymmetry(objects, fType, screen)
  }
  const center = {
    x: screen.width / 2,
    y: screen.height / 2,
  }
  console.log('screen', screen)
  console.log('center', center)
  // center positions in screen canvas
  const bounds = getBounds(objects)
  const boundingBox = {
    left: 0,
    top: 0,
    right: screen.width,
    bottom: screen.height,
  }
  const centered = centerInBox(objects, bounds, boundingBox)

  // update bounds
  const boundsCenter = getBoxCenter(bounds)
  const boundingBoxCenter = getBoxCenter(boundingBox)
  const dX = boundingBoxCenter.x - boundsCenter.x
  const dY = boundingBoxCenter.y - boundsCenter.y
  bounds.left = bounds.left + dX - center.x
  bounds.right = bounds.right + dX - center.x
  bounds.top = bounds.top + dY - center.y
  bounds.bottom = bounds.bottom + dY - center.y
  console.log('updated bounds', bounds)

  let factorX = screen.width / (bounds.right - bounds.left)
  let factorY = screen.height / (bounds.bottom - bounds.top) * -1
  if (!isFinite(factorX)) factorX = 1
  if (!isFinite(factorY)) factorY = 1

  // correction for some of the fixture types
  const correction = { x: 0, y: 0 }
  if (
    fType === mtiFixtureType.extendedQuarterCircle ||
    fType === sviFigureType.extendedRightQuarterCircle ||
    fType === sviFigureType.extendedLeftQuarterCircle
  ) {
    correction.x = -1 * screen.width / 8
    correction.y = screen.height / 6
    if (objects.length === 1) {
      correction.x = -1 * screen.width / 3
      correction.y = screen.height / 3
    }
    if (objects.length === 2) {
      factorY = 0.9 * screen.height / (bounds.bottom - bounds.top) * -1
    }
  }
  if (fType === mtiFixtureType.circle || fType === sviFigureType.circle) {
    factorY = 0.75 * screen.height / (bounds.bottom - bounds.top) * -1
    factorX = factorY
    if (objects.length === 1) {
      correction.x = screen.width / 3
    } else if (objects.length === 2) {
      correction.x = -1 * screen.width / 8.5
    } else if (objects.length === 3) {
      correction.x = -screen.height / 40
      correction.y = screen.height / 5
      factorX *= 0.65
    } else if (objects.length === 5) {
      correction.x = -screen.height / 160
      correction.y = screen.height / 28
      factorX *= 0.9
      factorY = factorX
    } else if (objects.length === 6) {
      correction.x = -screen.height / 40
    } else if (objects.length === 7) {
      correction.x = -screen.height / 240
      correction.y = screen.height / 46
      factorX *= 0.98
      factorY = factorX
    } else if (objects.length === 10) {
      correction.x = -screen.height / 120
    }
  }
  if (fType === mtiFixtureType.table || fType === sviFigureType.wideRectangle) {
    factorX = 0.9 * screen.width / (bounds.right - bounds.left)
    factorY = 0.75 * screen.height / (bounds.bottom - bounds.top) * -1
    correction.x = -1 * screen.width / 20
    if (fType === sviFigureType.square) {
      correction.x = -1 * screen.width / 32
      factorX = factorY
      if (objects.length === 1) {
        correction.x = screen.height / 20
      }
    }
  }
  if (fType === sviFigureType.square) {
    factorY = 0.75 * screen.height / (bounds.bottom - bounds.top) * -1
    factorX = factorY
    if (objects.length === 3) {
      factorX *= 0.85
    }
  }
  if (fType === sviFigureType.narrowRectangle) {
    factorX = 0.85 * screen.width / (bounds.right - bounds.left)
    factorY = 0
  }
  if (fType === sviFigureType.wideRectangle) {
    factorX = 0.85 * screen.width / (bounds.right - bounds.left)
    factorY = 0.24 * screen.height / (bounds.bottom - bounds.top) * -1
  }
  if (!isFinite(factorX)) factorX = 1
  if (!isFinite(factorY)) factorY = 1

  // update position coordinates to fit in screen canvas
  // handle custom screen aspect ratio
  const newRatio = screen.height / screen.width
  const yFactorFactor = newRatio / (2 / 3)
  factorY /= yFactorFactor
  if (!isFinite(factorX)) factorX = 1
  if (!isFinite(factorY)) factorY = 1

  return centered.map((o) => {
    let left = o.left
    let top = o.top
    if (left <= center.x) {
      if (top <= center.y) {
        // left top
        left = center.x - (center.x - left) * factorX + correction.x
        top = center.y - (center.y - top) * factorY + correction.y
      } else {
        // left bottom
        left = center.x - (center.x - left) * factorX + correction.x
        top = center.y + (top - center.y) * factorY + correction.y
      }
    } else if (top <= center.y) {
      // right top
      left = center.x + (left - center.x) * factorX + correction.x
      top = center.y - (center.y - top) * factorY + correction.y
    } else {
      // right bottom
      left = center.x + (left - center.x) * factorX + correction.x
      top = center.y + (top - center.y) * factorY + correction.y
    }

    // out of bounds check
    const padding = { x: screen.width / 40, y: screen.height / 40 }
    if (left <= 0) {
      left = 0 + padding.x
    } else if (left > screen.width) {
      left = screen.width - o.width - padding.x
    }
    if (top <= 0) {
      top = 0 + padding.y
    } else if (top > screen.height) {
      top = screen.height - o.height - padding.y
    }

    const attrs = {
      left,
      top,
    }
    return { ...o, ...attrs }
  })
}

export function positionAutoplacementNoSymmetry(objects, fType, screen) {
  const bounds = getBounds(objects)
  const boundingBox = {
    left: 0,
    top: 0,
    right: screen.width,
    bottom: screen.height,
  }
  const boundsCenter = getBoxCenter(bounds)
  // const boundingBoxCenter = getBoxCenter(boundingBox)
  let objs = setScatter(objects, bounds, boundsCenter, boundingBox)
  if (
    fType === mtiFixtureType.extendedQuarterCircle ||
    fType === sviFigureType.extendedQuarterCircle ||
    fType === sviFigureType.extendedRightQuarterCircle ||
    fType === sviFigureType.extendedLeftQuarterCircle
  ) {
    objs = addXYPadding(objs, 0.85, 0.78, screen)
  }
  if (
    fType === mtiFixtureType.quarterCircle ||
    fType === sviFigureType.quarterCircle
  ) {
    objs = addXYPadding(objs, 0.7, 0.2, screen)
  }
  return objs
}

export function setScatter(objects, bounds, boundsCenter, boundingBox) {
  const boundsScatter = getScatter(bounds, 1)
  const boundingBoxScatter = getScatter(boundingBox, -1)
  let fX = boundingBoxScatter.x / boundsScatter.x
  let fY = boundingBoxScatter.y / boundsScatter.y
  if (!isFinite(fX)) fX = 1
  if (!isFinite(fY)) fY = 1
  return objects.map((o) => {
    let left = o.left
    let top = o.top
    if (left <= boundsCenter.x) {
      if (top <= boundsCenter.y) {
        // left top
        left = boundsCenter.x - (boundsCenter.x - left) * fX
        top = boundsCenter.y - (boundsCenter.y - top) * fY
      } else {
        // left bottom
        left = boundsCenter.x - (boundsCenter.x - left) * fX
        top = boundsCenter.y - (boundsCenter.y - top) * fY
      }
    } else if (top <= boundsCenter.y) {
      // right top
      left = boundsCenter.x - (boundsCenter.x - left) * fX
      top = boundsCenter.y - (boundsCenter.y - top) * fY
    } else {
      // right bottom
      left = boundsCenter.x - (boundsCenter.x - left) * fX
      top = boundsCenter.y - (boundsCenter.y - top) * fY
    }

    const attrs = {
      left,
      top,
    }
    return { ...o, ...attrs }
  })
}

export function getScatter(box, k) {
  return {
    x: box.right - box.left,
    y: (box.top - box.bottom) * k, // y-coord can be flipped
  }
}

export function getBounds(objects) {
  const icObject = objects[0]
  const { left: leftIc, top: topIc } = icObject
  const bounds = { left: leftIc, top: topIc, right: leftIc, bottom: topIc }
  objects.map((o) => {
    const left = o.left
    const top = o.top
    if (left < bounds.left) {
      bounds.left = left
    } else if (left > bounds.right) {
      bounds.right = left
    }
    if (top > bounds.top) {
      bounds.top = top
    } else if (top < bounds.bottom) {
      bounds.bottom = top
    }
    return bounds
  })
  return bounds
}

export function centerInBox(objects, bounds, boundingBox) {
  const boundsCenter = getBoxCenter(bounds)
  const boundingBoxCenter = getBoxCenter(boundingBox)
  const dX = boundingBoxCenter.x - boundsCenter.x
  const dY = boundingBoxCenter.y - boundsCenter.y
  return objects.map((o) => {
    const attrs = {
      left: o.left + dX,
      top: o.top + dY,
    }
    return { ...o, ...attrs }
  })
}

export function getBoxCenter(box) {
  return {
    x: (box.right - box.left) / 2 + box.left,
    y: (box.top - box.bottom) / 2 + box.bottom,
  }
}

export function centerInFixture(objects, fixture) {
  if (!objects || objects.length === 0) return objects
  const bounds = getBounds(objects)
  const { left, top, width, height, scaleX, scaleY } = fixture
  const widthReal = width * scaleX
  const heightReal = height * scaleY
  const boundingBox = {
    left: left - widthReal / 2,
    top: top - heightReal / 2,
    right: left + widthReal / 2,
    bottom: top + heightReal / 2,
  }
  return centerInBox(objects, bounds, boundingBox)
}

const toFabricPositionA = async (
  position,
  fixture,
  screen,
  isAbsolute,
  isStatic,
  i
) => {
  // const fixture = position.parent
  const { width, height, left, top } = getGeometry(fixture, screen)

  const angle = defaultFixtureAngle(fixture.type)

  let scaleX = width / fixture.layoutPosition.width
  const scaleY = height / fixture.layoutPosition.height

  // TODO: API returns width 400 height 200 for quater circle and extended quarter circle
  //  even though it looks like a different figures
  if (fixture.type === mtiFixtureType.quarterCircle) {
    scaleX = width / fixture.layoutPosition.height
  }
  if (
    fixture.type === mtiFixtureType.extendedQuarterCircle ||
    fixture.type === sviFigureType.extendedRightQuarterCircle ||
    fixture.type === sviFigureType.extendedLeftQuarterCircle
  ) {
    scaleX =
      width / fixture.layoutPosition.height * extendedQuarterCircleAspectRatio
  }

  // Translation
  // This is not required if isAbsolute = true
  const transX = left
  const transY = top

  const { x: oldX, y: oldY } = position

  let positionCoordinate = { x: oldX, y: oldY }
  // console.log(width, height, scaleX, scaleY, positionCoordinate)
  // Absolute position is relative to Canvas.
  //
  // Why have translation:
  // MTI coordinates come in relative to fixture they are in
  // therefore we need a translation transformation to change the basis.
  //
  // Why have rotation:
  // Moreover, if we decide to rotate the fixture we will also need to apply
  // rotation transformation to position coordinates
  positionCoordinate = transform(
    { x: oldX, y: oldY },
    angle,
    { x: scaleX, y: scaleY },
    { x: transX, y: transY }
  )
  const name = position.name || `${defaultName.position} ${i + 1}`
  const mtiType = position.state ? position.state.state : 'Unknown'
  const fabricObject = await getEditObject(editType.filled)
  // console.log('fabricObject', fabricObject)

  const pScaleX = screen.width / 8 / fabricObject.width
  const pScaleY = screen.width / 8 / fabricObject.height
  /*
  positionCoordinate = adjustInside(
    positionCoordinate,
    screen,
    pScaleX * fabricObject.width,
    pScaleY * fabricObject.height
  )
  */
  const attrs = {
    ...(isStatic ? lockMovement : {}),
    lockScalingX: true,
    lockScalingY: true,
    hasControls: false,
    hasRotatingPoint: false,
    hasBorders: false,
    scaleX: pScaleX,
    scaleY: pScaleY,
    left: positionCoordinate.x,
    top: positionCoordinate.y,
    originX: 'center',
    originY: 'center',
    mtiType,
    name,
    // When positionining relative to group SVG of position will rotate with group
    // which will lead to a rotated lock icon. We want to counter rotate the position
    // when the group is rotated to keep the position still.
    angle: isAbsolute ? 0 : -angle,
    id: position.id,
    contextType: 'position',
  }
  return { ...fabricObject, ...attrs }
}

export async function toFabricAutoPlacement(o, screen) {
  if (!o) return o
  const mtiType = o.type
  const fabricObject = await getAutoPlacementCoords(mtiType, o.id)
  console.log('fabricObject', fabricObject)
  const { width, height, left, top } = getGeometry(o, screen)

  const fixtureAngle = defaultFixtureAngle(o.type)

  let scaledWidth = width
  let scaledHeight = height
  if (
    o.type === mtiFixtureType.extendedQuarterCircle ||
    o.type === sviFigureType.extendedRightQuarterCircle ||
    o.type === sviFigureType.extendedLeftQuarterCircle
  ) {
    scaledWidth = screen.width / extendedQuarterCircleAspectRatio
    scaledHeight = screen.width
  }

  const scaleX = scaledWidth / fabricObject.width
  const scaleY = scaledHeight / fabricObject.height

  const attrs = {
    scaleX,
    scaleY,
    angle: fixtureAngle,
    left,
    top,
    originX: 'center',
    originY: 'center',
    strokeWidth: 0,
    fill: '#FFFFFF',
    selectable: false,
    mtiType,
    id: o.id,
    name: o.name,
    contextType: 'fixture',
  }
  return { ...fabricObject, ...attrs }
}

export function setTypeBasedOnAspect(fixtureToCheck) {
  const fixture = fixtureToCheck
  if (fixture.type === sviFigureType.custom) {
    const nearestAspect = getNearestAspectRatio(fixture)

    switch (nearestAspect) {
      case 1.5:
      case 2:
      case fixtureRatio.wideRectangle:
        fixture.type = sviFigureType.wideRectangle
        break
      case 3:
      case fixtureRatio.narrowRectangle:
        fixture.type = sviFigureType.narrowRectangle
        break
      default:
        fixture.type = sviFigureType.custom
        break
    }
    return fixture
  }
  return fixture
}
