/*
 * Manufacturer Products Constants
 */

import keyMirror from 'fbjs/lib/keyMirror'

/**
 * @constant {Object} ActionTypes
 * @memberof Constants
 */
export const ActionTypes = keyMirror({
  FETCH_MANUFACTURER_PRODUCTS_FAILED: undefined,
  FETCH_MANUFACTURER_PRODUCTS_FULFILLED: undefined,
  FETCH_MANUFACTURER_PRODUCTS_PENDING: undefined,
  LOAD_MANUFACTURER_PRODUCTS: undefined,
})
