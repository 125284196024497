import { ActionTypes } from './constants'

/**
 * Fetch Stores List Failed
 *
 * @returns {Object}
 */
export function fetchStoresListFailed(error) {
  return {
    type: ActionTypes.FETCH_STORES_LIST_FAILED,
    payload: { error },
  }
}

/**
 * Fetch Stores List Fulfilled
 *
 * @returns {Object}
 */
export function fetchStoresListFulfilled(stores, organizations) {
  return {
    type: ActionTypes.FETCH_STORES_LIST_FULFILLED,
    payload: { stores, organizations },
  }
}

/**
 * Fetch Stores List Pending
 *
 * @returns {Object}
 */
export function fetchStoresListPending() {
  return {
    type: ActionTypes.FETCH_STORES_LIST_PENDING,
  }
}

/**
 * Fetch Stores List
 *
 * @returns {Object}
 */
export function fetchStoresList() {
  return {
    type: ActionTypes.FETCH_STORES_LIST,
  }
}

/**
 * Select Organization
 *
 * @returns {Object}
 */
export function selectOrganization(organizationId) {
  return {
    type: ActionTypes.SELECT_ORGANIZATION,
    payload: { organizationId },
  }
}
