import { all, call, takeLatest, takeEvery, select, put } from 'redux-saga/effects'

import { ActionTypes } from './constants'
import { tokenSelector } from '../../containers/App/selectors'
import {
  patchProvisionHub, getHubs,
} from '../../api'
import { errorToast, successToast } from '../../utils/utils'

function* fetchHubs({ payload }) {
  try {
    const token = yield select(tokenSelector)
    const data = yield call(getHubs, token, payload.storeId) || {}
    yield put({ type: ActionTypes.FETCH_HUBS_RESPONSE, payload: data.connectHubs })
  } catch (error) {
    console.error(error)
  }
}

/**
 * @param {*} payload
 */
function* provisionHub({ payload }) {
  const { storeId, hubId, message } = payload
  const token = yield select(tokenSelector)
  try {
    yield call(patchProvisionHub, token, storeId, hubId)
    successToast(message.success)
  } catch (error) {
    console.error(error)
    errorToast(message.failure)
  }
}

export default function* root() {
  yield all([
    yield takeEvery(
      ActionTypes.PROVISION_HUB, provisionHub,
    ),
    yield takeLatest(ActionTypes.FETCH_HUBS_REQUEST, fetchHubs,
    ),
  ])
}
