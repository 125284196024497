import { createSelector } from 'reselect'
import { createSelector as createOrmSelector } from 'redux-orm'
import {
  orm,
  sortedPositionsByState,
  countIssuesForFloorWithSplittedAlarming,
} from 'mti-jsclient-shared'
import { filterIssues, getPositionItemData } from 'utils/mtiUtils'
import { toObjectsSync } from './utils'
import { appSelector } from '../App/selectors'

const dbStateSelector = (state) => state.get('orm')

const selectIsStoreById = (sId) =>
  createOrmSelector(orm, (session) => {
    try {
      if (!session.Store.hasId(sId)) {
        return false
      }
      const store = session.Store.withId(sId)
      if (store.ref.floorsCount === 0) {
        return false
      }
      const floorsExist = store.ref.floors.map((fId) =>
        session.Floor.hasId(fId)
      )
      const hasAllFloors = floorsExist.every((exists) => exists === true)
      if (!hasAllFloors) {
        return false
      }
      return true
    } catch (e) {
      return false
    }
  })

const makeSelectStoreExist = (sId) =>
  createSelector(dbStateSelector, (ormState) =>
    selectIsStoreById(sId)(ormState)
  )

const selectStore = createOrmSelector(
  orm,
  dbStateSelector,
  (state, storeId) => storeId,
  (session, storeId) => getStore(session, storeId)
)

const selectStoreById = (sId) =>
  createOrmSelector(orm, (session) => getStore(session, sId))

const getStore = (session, storeId) => {
  try {
    const store = session.Store.withId(storeId)
    const storeObj = {
      ...store.ref,
    }

    const floors = store.floorIds.toModelArray()
    const floorsExtended = floors
      .map((f) => ({
        ...f.ref,
        parent: storeObj,
        state: countIssuesForFloorWithSplittedAlarming(f, true),
      }))
      .sort((a, b) => a.order - b.order)

    return {
      ...storeObj,
      floors: floorsExtended,
    }
  } catch (e) {
    return {}
  }
}

const makeSelectStore = (sId) =>
  createSelector(dbStateSelector, (ormState) => selectStoreById(sId)(ormState))

export function getStorePositions(store) {
  if (!store) return []
  const { id: storeId, name: storeName } = store
  let storePositions = []
  store.floorIds.toModelArray().map((floor) => {
    const floorPsitions = floor.fixtureIds
      .toModelArray()
      .map((fixture) => fixture.positionIds.toModelArray())
    storePositions = storePositions.concat(floorPsitions)
  })
  const storeFlatterdPositions = [].concat(...storePositions)
  const storeSortedPositions = sortedPositionsByState(storeFlatterdPositions)
  const storePositionsExtended = filterIssues(
    storeSortedPositions
      .map((p) => getPositionItemData(p, storeId, storeName))
      .filter((n) => n)
  )
  return storePositionsExtended
}

const selectPositionsByStoreId = (sId) =>
  createOrmSelector(orm, (session) => {
    try {
      // Issues for store
      const store = session.Store.withId(sId)
      const { id: storeId, name: storeName } = store
      const storePositionsExtended = getStorePositions(store)

      // Issues for floor selected
      const positionsWithStatusesByFloorId = {}
      store.floorIds.toModelArray().map((floor) => {
        const floorPsitions = floor.fixtureIds
          .toModelArray()
          .map((fixture) => fixture.positionIds.toModelArray())
        const floorPsitionsFlatterd = [].concat(...floorPsitions)
        const floorPsitionsSorted = sortedPositionsByState(
          floorPsitionsFlatterd
        )
        const floorPsitionsSortedExtended = filterIssues(
          floorPsitionsSorted
            .map((p) => getPositionItemData(p, storeId, storeName))
            .filter((n) => n)
        )
        positionsWithStatusesByFloorId[
          floor.ref.id
        ] = floorPsitionsSortedExtended
        return floorPsitionsSortedExtended
      })
      return {
        positionsStore: storePositionsExtended,
        positionsByFloorId: positionsWithStatusesByFloorId,
      }
    } catch (e) {
      return {}
    }
  })

const makeSelectPositionsByStore = (sId) =>
  createSelector(dbStateSelector, (ormState) =>
    selectPositionsByStoreId(sId)(ormState)
  )

const selectFloorsPage = (state) => state.get('floorsPage')

const makeSelectFloors = () =>
  createSelector(selectFloorsPage, (state) => state.get('floors'))

const ormStoreStateSelector = (state) => ({
  orm: state.get('orm'),
  store: state.get('floorsPage'),
})

const makeSelectCanvasObject = (sId) =>
  createSelector(ormStoreStateSelector, appSelector, (ormStore, appState) => {
    try {
      const isStatic = ormStore.store.get('isStatic')

      if (!isStatic) {
        return ormStore.store.get('canvasObject')
      }

      const store = selectStoreById(sId)(ormStore.orm)
      const images = appState.get('images')
      const { selectedId } = ormStore.store.get('canvasObject') || {}
      const { canvasObject } = toObjectsSync(
        store,
        images,
        isStatic,
        selectedId || -1
      )
      return canvasObject
    } catch (e) {
      return {}
    }
  })
/*
const makeSelectCanvasObject = () =>
  createSelector(selectFloorsPage, (state) => state.get('canvasObject'))
*/
const makeSelectCurrentStoreId = () =>
  createSelector(selectFloorsPage, (state) => state.get('currentStoreId'))

const makeSelectIsStatic = () =>
  createSelector(selectFloorsPage, (state) =>
    (state || { get: () => true }).get('isStatic')
  )

const makeSelectedFloorName = () =>
  createSelector(selectFloorsPage, (state) => {
    const selectedObj = state.get('selectedObject')
    const objects = state.get('canvasObject').objects

    if (selectedObj === -1 || !objects.length) {
      return ''
    }
    const obj = objects.find((i, idx) => idx === selectedObj)
    if (!obj) return ''
    return obj.name
  })

const makeSelectedFloorOrder = () =>
  createSelector(selectFloorsPage, (state) => {
    const selectedObj = state.get('selectedObject')
    const objects = state.get('canvasObject').objects

    if (selectedObj === -1 || !objects.length) {
      return ''
    }
    const obj = objects.find((i, idx) => idx === selectedObj)
    if (!obj) return ''
    return obj.order
  })

const makeSelectedFloorImage = () =>
  createSelector(selectFloorsPage, (state) => {
    const selectedObj = state.get('selectedObject')
    const objects = state.get('canvasObject').objects

    if (selectedObj === -1 || !objects.length) {
      return ''
    }
    const obj = objects.find((i, idx) => idx === selectedObj)
    if (!obj) return ''
    return obj.image
  })

const makeSelectedFloorShouldDisplayBackground = () =>
  createSelector(selectFloorsPage, (state) => {
    const selectedObj = state.get('selectedObject')
    const objects = state.get('canvasObject').objects

    if (selectedObj === -1 || !objects.length) {
      return false
    }
    const obj = objects.find((i, idx) => idx === selectedObj)
    if (!obj) return false
    return obj.shouldDisplayBackground
  })

const makeSelectCanvasSelectedObject = () =>
  createSelector(selectFloorsPage, (state) => state.get('selectedObject'))

const makeSelectFailed = () =>
  createSelector(selectFloorsPage, (state) => state.get('failed'))

const makeSelectLoading = () =>
  createSelector(selectFloorsPage, (state) => state.get('loading'))

export {
  makeSelectStoreExist,
  makeSelectIsStatic,
  makeSelectedFloorName,
  makeSelectedFloorOrder,
  makeSelectedFloorImage,
  makeSelectedFloorShouldDisplayBackground,
  makeSelectPositionsByStore,
  makeSelectFailed,
  makeSelectLoading,
  makeSelectCanvasObject,
  makeSelectCanvasSelectedObject,
  makeSelectFloors,
  makeSelectStore,
  makeSelectCurrentStoreId,
  selectStore,
}
