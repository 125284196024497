import { List } from 'immutable'
import moment from 'moment'
import { safeUri } from '../../utils/utils'

export function getDateFormatted(date) {
  const { startDate, endDate } = date
  return {
    startDate: startDate ? startDate.format('YYYY-MM-DD') : null,
    endDate: endDate ? endDate.format('YYYY-MM-DD') : null,
  }
}

export function mergeExceptions(combination, health, operations) {
  const {
    health: cHealth,
    operations: cOperations,
  } = combination
  const results = []
  if (cHealth) {
    results.push(
      ...((health || {}).results || []).map((r) => ({
        ...r,
        sviException: 'health',
      }))
    )
  }
  if (cOperations) {
    results.push(
      ...((operations || {}).results || []).map((r) => ({
        ...r,
        sviException: 'operations',
      }))
    )
  }
  return List(results)
}

export function formattedDuration(milliseconds) {
  const duration = moment.duration(milliseconds)
  const str = `${duration.hours()}h:${duration.minutes()}m:${duration.seconds()}.${duration.milliseconds()}s`

  return addDateToDuration(duration, str)
}

export function formattedDurationWithMilliseconds(seconds, milliseconds) {
  if (seconds === 0 && milliseconds === 0) {
    /* A duration of 0s means either that
     * the event is still ongoing, or that
     * it's a momentary thing, like a key activation,
     * that doesn't have a duration.
     */
    return ''
  }
  const duration = moment.duration(seconds)
  const str = `${duration.hours()}h:${duration.minutes()}m:${duration.seconds()}.${milliseconds}s`

  return addDateToDuration(duration, str)
}

function addDateToDuration(duration, str) {
  if (duration.days()) {
    str = `${duration.days()}d ${str}`
  }

  if (duration.months()) {
    str = `${duration.months()}m${str}`
  }

  if (duration.years()) {
    str = `${duration.years()}y${str}`
  }

  return str
}

export function positionLink(original) {
  const { position } = original
  return `${fixtureLink(original)}?positionId=${
    (position || {}).id
  }&positionName/${safeUri((position || {}).name)}`
}

export function fixtureLink(original) {
  const { area, fixtureId, fixtureName } = original
  return `${storeLink(original)}/areas/${(area || {}).id}/${safeUri(
    (area || {}).name
  )}/fixtures/${fixtureId}/${safeUri(fixtureName)}`
}

export function areaLink(original) {
  const { area } = original
  return `${storeLink(original)}/areas/${(area || {}).id}/${safeUri(
    (area || {}).name
  )}`
}

export function storeLink(original) {
  const { storeName, storeId, floor } = original
  return `/stores/${storeId}/${safeUri(storeName)}/floors/${floor.id}/${safeUri(
    floor.name
  )}`
}

export function storeLinkFromPosition(position) {
  const { storeName, storeId, floorName, floorId } = position
  return `/stores/${storeId}/${safeUri(storeName)}/floors/${floorId}/${safeUri(
    floorName
  )}`
}

export function areaLinkFromPosition(position) {
  const { areaName, areaId } = position
  return `${storeLinkFromPosition(position)}/areas/${areaId}/${safeUri(
    areaName
  )}`
}

export function fixtureLinkFromPosition(position) {
  const { areaId, areaName, fixtureId, fixtureName } = position
  return `${storeLinkFromPosition(position)}/areas/${areaId}/${safeUri(
    areaName
  )}/fixtures/${fixtureId}/${safeUri(fixtureName)}`
}

export function positionLinkFromPosition(position) {
  const { name, id } = position
  return `${fixtureLinkFromPosition(
    position
  )}?positionId=${id}&positionName/${safeUri(name)}`
}

export function showStoreLink(original) {
  const { storeName, storeId } = original
  return `/stores/${storeId}/${safeUri(storeName)}/show`
}
