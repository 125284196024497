/**
 * @module Actions/User
 * @desc User Actions
 */
import { ActionTypes } from '../constants'

/**
 * Restore user
 *
 * @returns {Object}
 */
export function restoreUser() {
  return {
    type: ActionTypes.USER_RESTORE_REQUEST,
    payload: null,
  }
}

/**
 * Login
 *
 * @returns {Object}
 */
export function login(data) {
  return {
    type: ActionTypes.USER_LOGIN_REQUEST,
    payload: data,
  }
}

/**
 * Admin Login
 *
 * @returns {Object}
 */
export function adminLogin(data) {
  return {
    type: ActionTypes.ADMIN_LOGIN_REQUEST,
    payload: data,
  }
}

/**
 * OTP Submission
 *
 * @returns {Object}
 */
export function submitOTP(data) {
  return {
    type: ActionTypes.OTP_REQUEST,
    payload: data,
  }
}

/**
 * Logout
 *
 * @returns {Object}
 */
export function logout() {
  return {
    type: ActionTypes.USER_LOGOUT_REQUEST,
  }
}

/**
 * Login Error
 *
 * @returns {Object}
 */
export function loginError(error) {
  return {
    type: ActionTypes.USER_LOGIN_FAILURE,
    payload: { message: error },
  }
}

/**
 * Login Success
 *
 * @returns {Object}
 */
export function loginSuccess(user) {
  return {
    type: ActionTypes.USER_LOGIN_SUCCESS,
    payload: user,
  }
}

/**
 * Logout Error
 *
 * @returns {Object}
 */
export function logoutError(error) {
  return {
    type: ActionTypes.USER_LOGOUT_FAILURE,
    payload: error,
  }
}
