import { createSelector } from 'reselect'
import { createSelector as createOrmSelector } from 'redux-orm'
import { orm } from 'mti-jsclient-shared'

const emptyArray = []
const dbStateSelector = (state) => state.get('orm')
const selectApp = (state) => state.get('app')
const selectFailed = createSelector(selectApp, (app) =>
  app.get('securedProductsFailed')
)
const selectLoading = createSelector(selectApp, (app) =>
  app.get('securedProductsLoading')
)

function loadData(session, storeId) {
  let result = { error: null, data: emptyArray }
  try {
    if (!session.Store.hasId(storeId)) return result
    const { name: storeName } = session.Store.withId(storeId).ref
    let sps = session.SecuredProduct.all()
    sps = sps
      .filter((d) => d.storeId.toString() === storeId)
      .toModelArray()
      .map((securedProductModel) => {
        const positionModel = securedProductModel.positionId
        const position = positionModel && positionModel.ref
        const fixture = positionModel && positionModel.fixtureId.ref
        const fixtureData = fixture && {
          fixtureId: fixture.id,
          fixtureName: fixture.name,
        }
        return {
          ...securedProductModel.ref,
          manufacturer: (securedProductModel.manufacturerId || {}).ref,
          position: {
            ...position,
            ...fixtureData,
            storeId: storeId,
            storeName,
          },
          productName: securedProductModel.productName(),
          manufacturerName: securedProductModel.manufacturerName(),
        }
      })
    result.data = sps
    return result
  } catch (e) {
    console.warn(e)
    result.error = e
    return result
  }
}

const selectArg1 = (state, arg1) => arg1

const selectIsAuxiliaryDataLoaded = createOrmSelector(
  orm,
  dbStateSelector,
  selectArg1,
  (session, storeId) => {
    if (!session.Store.hasId(storeId)) return false
    if (!session.Position.all().length) return false

    // finally, if there's an error fetching the data, then it's probably because not all the data is loaded
    const result = loadData(session, storeId)
    if (result.error != null) return false

    return true
  }
)

const selectSecuredProductsByStoreId = createOrmSelector(
  orm,
  dbStateSelector,
  selectArg1,
  (session, storeId) => {
    let result = loadData(session, storeId)
    return result.data
  }
)

export { selectFailed, selectLoading, selectSecuredProductsByStoreId, selectIsAuxiliaryDataLoaded }
