import { createSelector as createOrmSelector } from 'redux-orm'
import { orm } from 'mti-jsclient-shared'
import { getAllAlerts } from './alerts'

const dbStateSelector = (state) => state.get('orm')

const makeSelectAlertsByStoreId = (storeId) =>
  createOrmSelector(orm, dbStateSelector, (session) => {
    try {
      if (storeId) {
        let store = session.Store.withId(storeId)
        const alerts = getAllAlerts(store, session)
        return alerts
      }
      return []
    } catch (e) {
      return []
    }
  })

export { makeSelectAlertsByStoreId }
