import { all, call, put, takeLatest, select } from 'redux-saga/effects'
import { modelActionCreators } from 'mti-jsclient-shared'
import { delay } from 'redux-saga'
import { postStore, patchStore } from '../../../api'
import { upsert } from './ormSaga'
import { makeSelectToken, makeSelectStoreEdit } from '../selectors'
import { ActionTypes } from '../constants'
import {
  patchStorePending,
  patchStoreFulfilled,
  patchStoreFailed,
  postStorePending,
  postStoreFulfilled,
  postStoreFailed,
  storeDetailsAction,
} from '../actions'
import { errorToast, getBase64File, successToast } from '../../../utils/utils'
import { makeSelectStores } from '../../StoresPage/selectors'
import { loadRegions } from '../../RegionsPage/sagas'

/*
Current response of postStore request has regions, but there is no data about storeIds.
"regions": [
  {
    "meta": {
      "links": {
        "self": {
          "href": "https://api.sviconnectedstore.com/api/regions/21"
        }
      }
    },
    "id": 21,
    "name": "North Central",
    "createdAt": "2018-07-13T01:37:20.746Z",
    "updatedAt": "2018-07-13T01:37:20.746Z",
    "storesCount": 4
  }
],
As a workaround we have to loadRegions that has updated storeIds in response
*/
export function* post() {
  const store = yield select(makeSelectStoreEdit())
  const token = yield select(makeSelectToken())
  try {
    yield put(postStorePending())
    const modifiedStore = yield call(modifyStoreWithBase64Image, store)
    const data = yield call(postStore, token, modifiedStore)
    yield call(upsert, { payload: data })
    yield call(loadRegions)

    const id = data.stores[0].id
    const storesLoaded = yield select(makeSelectStores())
    const storeLoaded = storesLoaded.find(({ id: sId }) => sId === id)
    yield put(postStoreFulfilled())
    yield delay(500)
    yield put(storeDetailsAction(storeLoaded)) // Open store details modal
    successToast('Store Created')
  } catch (error) {
    // react-boilerplate way to get the error body
    const res = error.response
    const errorObj = (res && (yield call([res, res.json]))) || error
    yield put(postStoreFailed(errorObj))
    errorToast('Create store failed', errorObj)
  }
}

export function* patch({ payload: id }) {
  const store = yield select(makeSelectStoreEdit())
  if (store) {
    const token = yield select(makeSelectToken())
    try {
      yield put(patchStorePending())
      const modifiedStore = yield call(modifyStoreWithBase64Image, store)
      const data = yield call(patchStore, token, id, modifiedStore)
      yield put(modelActionCreators.updateStore(data.stores[0]))
      yield call(upsert, { payload: data })
      yield call(loadRegions)

      const storesLoaded = yield select(makeSelectStores())
      const storeLoaded = storesLoaded.find(({ id: sId }) => sId === id)
      yield put(patchStoreFulfilled())
      yield delay(500)
      yield put(storeDetailsAction(storeLoaded)) // Open store details modal
      successToast('Store Updated')
    } catch (error) {
      // react-boilerplate way to get the error body
      console.log('error', error)
      const res = error.response
      const errorObj = (res && (yield call([res, res.json]))) || error
      yield put(patchStoreFailed(errorObj))
      errorToast('Update store failed', errorObj)
    }
  }
}

function* modifyStoreWithBase64Image(store) {
  if (!store.image) return store

  const { image } = store
  const base64ImageFile = yield call(getBase64File, image)
  return {
    ...store,
    image: { file: base64ImageFile, fileName: image.name },
  }
}

export default function* root() {
  yield all([
    yield takeLatest(ActionTypes.POST_STORE, post),
    yield takeLatest(ActionTypes.PATCH_STORE, patch),
  ])
}
