import { fabric } from 'fabric'

import Rect from '../../images/svg/fixtures/rect.svg'
import Circle from '../../images/svg/fixtures/circle.svg'
import QuarterCircle from '../../images/svg/fixtures/quarter-circle.svg'
import ExtQuarterCircle from '../../images/svg/fixtures/extended-quarter-circle.svg'
import ExtQuarterCircle90 from '../../images/svg/fixtures/extended-quarter-circle-90.svg'
import ExtQuarterCircle180 from '../../images/svg/fixtures/extended-quarter-circle-180.svg'
import ExtQuarterCircle270 from '../../images/svg/fixtures/extended-quarter-circle-270.svg'

const params = {
  originX: 'center',
  originY: 'center',
  left: 0,
  top: 0,
  scaleX: 1,
  scaleY: 1,
  opacity: 1,
}

/**
 * Get SVG icon and transform to fabric object
 *
 * @param icon
 * @returns {Promise<any>}
 */
export function getIcon(icon) {
  return new Promise((resolve) => {
    fabric.loadSVGFromURL(icon, (objects, options) => {
      const loadedObjects = fabric.util.groupSVGElements(objects, options)
      resolve(loadedObjects)
    })
  })
}

/**
 * Get rect geometry object
 *
 * @param options
 * @returns {Promise<*>}
 */
export async function getRectObj(options = {}) {
  const fxObj = await getIcon(Rect)

  return Object.assign(fxObj, params, options)
}

/**
 * Get circle geometry object
 *
 * @param options
 * @returns {Promise<*>}
 */
export async function getCircleObj(options = {}) {
  const fxObj = await getIcon(Circle)

  return Object.assign(fxObj, params, options)
}

/**
 * Get quarter circle geometry object
 *
 * @param options
 * @returns {Promise<*>}
 */
export async function getQuarterCircleObj(options = {}) {
  const fxObj = await getIcon(QuarterCircle)

  return Object.assign(fxObj, params, options)
}

/**
 * Get extended quarter circle geometry object
 *
 * @param angle
 * @param options
 * @returns {Promise<*>}
 */
export async function getExtendedQuarterCircleObj(angle, options = {}) {
  let fxObj = null

  switch (angle) {
    case '90':
      fxObj = await getIcon(ExtQuarterCircle90)
      break
    case '180':
      fxObj = await getIcon(ExtQuarterCircle180)
      break
    case '270':
      fxObj = await getIcon(ExtQuarterCircle270)
      break
    default:
      fxObj = await getIcon(ExtQuarterCircle)
      break
  }

  return Object.assign(fxObj, params, options)
}
