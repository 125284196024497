/*
 *
 * AreaPage actions
 *
 */

import { ActionTypes } from './constants'

/**
 * Store Prototypes
 *
 * @returns {Object}
 */
export function storePrototypes(prototypes) {
  return {
    type: ActionTypes.STORE_AREA_PROTOTYPES,
    payload: prototypes,
  }
}

/**
 * Store Params
 *
 * @returns {Object}
 */
export function storeParams(params) {
  return {
    type: ActionTypes.STORE_PARAMS,
    payload: params,
  }
}

/**
 * Apply Template
 *
 * @returns {Object}
 */
export function applyTemplate(template) {
  return {
    type: ActionTypes.AREA_CANVAS_APPLY_TEMPLATE,
    payload: template,
  }
}

/**
 * Post Canvas Failed
 *
 * @returns {Object}
 */
export function postAreaCanvasFailed(error) {
  return {
    type: ActionTypes.POST_AREA_CANVAS_FAILED,
    payload: error,
  }
}

/**
 * Post Canvas Fulfilled
 *
 * @returns {Object}
 */
export function postAreaCanvasFinished(payload) {
  return {
    type: ActionTypes.POST_AREA_CANVAS_FINISHED,
    payload,
  }
}

/**
 * Post Canvas Pending
 *
 * @returns {Object}
 */
export function postAreaCanvasPending(canvasId) {
  return {
    type: ActionTypes.POST_AREA_CANVAS_PENDING,
    payload: {
      canvasId,
    },
  }
}

/**
 * Fetch Canvas Failed
 *
 * @returns {Object}
 */
export function fetchAreaCanvasFailed(error) {
  return {
    type: ActionTypes.FETCH_AREA_CANVAS_FAILED,
    payload: error,
  }
}

/**
 * Fetch Canvas Fulfilled
 *
 * @returns {Object}
 */
export function fetchAreaCanvasFulfilled(canvasId, data, selectedId, isStatic) {
  return {
    type: ActionTypes.FETCH_AREA_CANVAS_FULFILLED,
    payload: {
      canvas: data,
      selectedId,
      isStatic,
    },
  }
}

/**
 * Fetch Canvas Pending
 *
 * @returns {Object}
 */
export function fetchAreaCanvasPending(canvasId) {
  return {
    type: ActionTypes.FETCH_AREA_CANVAS_PENDING,
    payload: {
      canvasId,
    },
  }
}

/**
 * Area Canvas Changed
 *
 * @returns {Object}
 */
export function areaCanvasChanged(payload) {
  return {
    type: ActionTypes.AREA_CANVAS_CHANGED,
    payload,
  }
}

/**
 * Area Canvas Name Changed
 *
 * @returns {Object}
 */
export function areaCanvasNameChanged(payload) {
  return {
    type: ActionTypes.AREA_CANVAS_NAME_CHANGED,
    payload,
  }
}

/**
 * Area Canvas Is Furniture Changed
 *
 * @returns {Object}
 */
export function areaCanvasIsFurnitureChanged(payload) {
  return {
    type: ActionTypes.AREA_CANVAS_IS_FURNITURE_CHANGED,
    payload,
  }
}

/**
 * Area Canvas Fixture Name Toggled
 *
 * @returns {Object}
 */
export function areaCanvasFixtureNameToggled(payload) {
  return {
    type: ActionTypes.AREA_CANVAS_FIXTURE_NAME_TOGGLED,
    payload,
  }
}

/**
 * Area Canvas Fixture Apply Future Template Updates Toggled
 *
 * @returns {Object}
 */
export function areaCanvasFixtureApplyFutureTemplateUpdatesToggled(payload) {
  return {
    type: ActionTypes.AREA_CANVAS_APPLY_FUTURE_TEMPLATE_UPDATES_TOGGLED,
    payload,
  }
}

/**
 * Area Canvas Figure Begin Transformation
 *
 * @returns {Object}
 */
export function areaCanvasFigureBeginTransformation(payload) {
  return {
    type: ActionTypes.AREA_CANVAS_FIGURE_BEGIN_TRANSFORMATION,
    payload,
  }
}

/**
 * Area Canvas Figure Begin Custom Rotation
 *
 * @returns {Object}
 */
export function areaCanvasFigureBeginCustomRotation(payload) {
  return {
    type: ActionTypes.AREA_CANVAS_FIGURE_BEGIN_CUSTOM_ROTATION,
    payload,
  }
}

/**
 * Area Canvas Figure Size Changed
 *
 * @returns {Object}
 */
export function areaCanvasFigureSizeChanged(payload) {
  return {
    type: ActionTypes.AREA_CANVAS_FIGURE_SIZE_CHANGED,
    payload,
  }
}

/**
 * Area Canvas Figure Unselect Template
 *
 * @returns {Object}
 */
export function areaCanvasFigureUnselectTemplate() {
  return {
    type: ActionTypes.AREA_CANVAS_FIGURE_UNSELECT_TEMPLATE,
  }
}

/**
 * Load Canvas
 *
 * @returns {Object}
 */
export function loadCanvas(screen, isStatic, selectedId, sId, fId, aId) {
  return {
    type: ActionTypes.LOAD_AREA_CANVAS,
    payload: {
      isStatic,
      selectedId,
      screen,
      sId,
      fId,
      aId,
    },
  }
}

/**
 * Save Canvas
 *
 *
 */
export function saveCanvas(canvas) {
  return {
    type: ActionTypes.SAVE_AREA_CANVAS,
    payload: {
      canvas,
    },
  }
}

/**
 * Save Fixture
 *
 *
 */
export function saveFixture(payload) {
  return {
    type: ActionTypes.SAVE_AREA_FIXTURE,
    payload,
  }
}

/**
 * Remove fixture
 *
 * @returns {Object}
 */
export function removeObject(payload) {
  return {
    type: ActionTypes.AREA_CANVAS_FIXTURE_REMOVE,
    payload,
  }
}

/**
 * Set Loading
 *
 * @returns {Object}
 */
export function setLoading(loading) {
  return {
    type: ActionTypes.STORE_AREA_LOADING,
    payload: loading,
  }
}

/**
 * Delete All Compliance Fixture Rules
 *
 * @param {Object} position
 */
export function deleteAllComplianceRules(fixture) {
  return {
    type: ActionTypes.DELETE_ALL_COMPLIANCE_RULES,
    payload: { fixture },
  }
}

/**
 * Reset Fixture Template
 *
 * @param {Object} templateId
 */
export function resetFixtureTemplate() {
  return {
    type: ActionTypes.RESET_FIXTURE_TEMPLATE,
  }
}

/**
 * Load Fixture Template
 *
 * @param {Object} templateId
 */
export function loadFixtureTemplate(templateId) {
  return {
    type: ActionTypes.LOAD_FIXTURE_TEMPLATE,
    payload: { templateId },
  }
}

/**
 * Load Fixture Template Pending
 *
 */
export function loadFixtureTemplatePending() {
  return {
    type: ActionTypes.LOAD_FIXTURE_TEMPLATE_PENDING,
  }
}

/**
 * Load Fixture Template Fulfilled
 *
 * @param {Object} template
 */
export function loadFixtureTemplateFulfilled(template) {
  return {
    type: ActionTypes.LOAD_FIXTURE_TEMPLATE_FULFILLED,
    payload: { template },
  }
}

/**
 * Load Fixture Template Failed
 *
 * @param {Object} errors
 */
export function loadFixtureTemplateFailed(errors) {
  return {
    type: ActionTypes.LOAD_FIXTURE_TEMPLATE_FAILED,
    payload: { errors },
  }
}
