import { Map } from 'immutable'
import { ActionTypes } from '../constants'

const initialState = new Map({
  hubs: {},
  hubsLoadingStatuses: {},
  hubStatusLoading: false,
  hubStatusError: null,
})

export default function(state = initialState, { type, payload }) {
  switch (type) {
    case ActionTypes.FETCH_HUB_FULFILLED: {
      const { storeId, hub } = payload
      const hubs = state.get('hubs')
      const newHubs = { ...hubs, ...hub }

      const hubsLoadingStatuses = {
        ...state.get('hubsLoadingStatuses'),
        [storeId]: { loading: false, error: null },
      }
      return state
        .set('hubs', newHubs)
        .set('hubsLoadingStatuses', hubsLoadingStatuses)
        .set('hubStatusLoading', false)
        .set('hubStatusError', null)
    }
    case ActionTypes.FETCH_HUB_PENDING: {
      const { storeId } = payload
      const hubsLoadingStatuses = {
        ...state.get('hubsLoadingStatuses'),
        [storeId]: { loading: true, error: null },
      }
      return state.set('hubsLoadingStatuses', hubsLoadingStatuses)
    }
    case ActionTypes.FETCH_HUB_FAILED: {
      const { storeId, error } = payload
      const hubsLoadingStatuses = {
        ...state.get('hubsLoadingStatuses'),
        [storeId]: { loading: false, error },
      }

      return state.set('hubsLoadingStatuses', hubsLoadingStatuses)
    }

    // Ping
    case ActionTypes.PING_HUB_PENDING: {
      const { hubIdentifier } = payload
      const hubs = state.get('hubs')
      const modifiedHub = { ...hubs[hubIdentifier], pingStatus: 'Pinging' }
      const newHubs = { ...hubs, [hubIdentifier]: modifiedHub }
      return state.set('hubs', newHubs)
    }
    case ActionTypes.PING_HUB_FAILED: {
      const { hubIdentifier } = payload
      const hubs = state.get('hubs')
      const modifiedHub = { ...hubs[hubIdentifier], pingStatus: 'Error' }
      const newHubs = { ...hubs, [hubIdentifier]: modifiedHub }
      return state.set('hubs', newHubs)
    }
    case ActionTypes.PING_HUB_FULFILLED: {
      const { hubIdentifier, pingStatus } = payload
      const hubs = state.get('hubs')
      const modifiedHub = { ...hubs[hubIdentifier], pingStatus }
      const newHubs = { ...hubs, [hubIdentifier]: modifiedHub }
      return state.set('hubs', newHubs)
    }

    // Hub Status
    case ActionTypes.FETCH_HUB_STATUS_PENDING:
      return state.set('hubStatusLoading', true).set('hubStatusError', null)
    case ActionTypes.FETCH_HUB_STATUS_FAILED:
      return state
        .set('hubStatusLoading', false)
        .set('hubStatusError', payload.error)
    case ActionTypes.FETCH_HUB_STATUS_FULFILLED: {
      const { hubIdentifier, otauStatus } = payload
      const hubs = state.get('hubs')
      const modifiedHub = { ...hubs[hubIdentifier], otauStatus }
      const newHubs = { ...hubs, [hubIdentifier]: modifiedHub }
      return state
        .set('hubs', newHubs)
        .set('hubStatusLoading', false)
        .set('hubStatusError', null)
    }
    default:
      return state
  }
}
