import React from 'react'
import PropTypes from 'prop-types'
import placeholder from 'images/icon-user-placeholder.png'
import EditDisabled from '../../../components/EditDisabled'
import UserRole from '../../../components/UserRole'
import { Avatar } from '../../../components/AvatarPicker'

const renderContent = user => {
  const {
    firstName,
    lastName,
    email,
    employeeIdentifier,
    phoneNumber,
    avatarUrl,
    roleKey,
    resourceType,
    resourceIds,
  } = user
  return (
    <div className="m-3">
      <div className="container">
        <div className="row">
          <div className="col-xs-6 w-25">
            <div className="thumbnail mt-4">
              <Avatar src={avatarUrl || placeholder} alt="Lights" />
            </div>
          </div>
          <div className="col-xs-6 w-75">
            <div style={{ textAlign: 'left' }}>
              <div className="form-row">
                <div className="form-group col-md-6 bmd-form-group">
                  <EditDisabled header={'First name'} text={firstName || ''} />
                </div>
                <div className="form-group col-md-6 bmd-form-group">
                  <EditDisabled header={'Last name'} text={lastName || ''} />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6 bmd-form-group">
                  <EditDisabled header={'Email'} text={email || ''} />
                </div>
                <div className="form-group col-md-6 bmd-form-group">
                  <EditDisabled
                    header={'Phone number'}
                    text={phoneNumber || ''}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6 bmd-form-group">
                  <EditDisabled
                    header={'Employee ID'}
                    text={employeeIdentifier || ''}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <UserRole
          roleKey={roleKey}
          resourceIDs={resourceIds}
          resourceType={resourceType}
        />
      </div>
    </div>
  )
}

function UserScreen({
  id,
  user,
  title,
  dangerText,
  confirmText,
  onDanger,
  onClose,
  onConfirm,
  isUserEditLocked,
  isEditProfileOnly,
  resetPasswordText,
  onResetPassword,
}) {
  return (
    <div className="modal-content">
      <div className="modal-header border-bottom">
        <h5 className="modal-title" id="exampleModalLongTitle">
          {title}
        </h5>
        <div style={{ flex: 1, marginLeft: 20 }}>
          {!isEditProfileOnly &&
            !isUserEditLocked &&
            onDanger && (
            <button
              type="button"
              disabled
              className="btn btn-primary"
              onClick={() => {
                  $(`#modal-${id}`).modal('hide') // eslint-disable-line
                onDanger()
              }}
            >
              {dangerText}
            </button>
          )}
        </div>
        <div>
          {!isUserEditLocked && (
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                $(`#modal-${id}`).modal('hide') // eslint-disable-line
                onResetPassword()
              }}
            >
              {resetPasswordText}
            </button>
          )}
          {!isUserEditLocked &&
            onConfirm && (
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                  $(`#modal-${id}`).modal('hide') // eslint-disable-line
                onConfirm()
              }}
            >
              {confirmText}
            </button>
          )}
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              $(`#modal-${id}`).modal('hide') // eslint-disable-line
              onClose()
            }}
            name="close"
          >
            Close
          </button>
        </div>
      </div>
      <div className="modal-body">{renderContent(user)}</div>
    </div>
  )
}

UserScreen.propTypes = {
  user: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  dangerText: PropTypes.string,
  confirmText: PropTypes.string,
  resetPasswordText: PropTypes.string,
  onDanger: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
  isUserEditLocked: PropTypes.bool,
  isEditProfileOnly: PropTypes.bool,
  onResetPassword: PropTypes.func,
}

export default UserScreen
