import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import TimezonePicker from '../../../components/TimezonePicker'
import CountryRegion from '../../../components/CountryRegion'
import { emailValidationPattern } from '../../../utils/utils'
import KeyExpirationInput from '../../../components/KeyExpirationInput'
import { Overlay } from '../../../styles/styled'
import ProgressIndicator from '../../../components/ProgressIndicator'
import Select from '../../../components/Select'
import SubmitWithHint, {
  defaultRequiredMessage,
} from '../../../components/SubmitWithHint'
import BackupKeys from './BackupKeys'
import { DeviceConfigurationList } from '../../../components/DeviceConfiguration'

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const BorderLabel = styled.span`
  width: 100%;
  padding-top: 10px;
`

export const ModalBody = styled.div`
  padding: 10px 10px 10px 10px;
`

export const ColumnContainer = styled.div`
  padding-top: 0px;
`

class EditOrganizationDetailsScreen extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showValidationHint: false,
    }
    this.validationRefs = []
  }

  componentWillUnmount() {
    this.validationRefs = []
  }

  setServerErrorDismiss(element) {
    const $element = $(element) // eslint-disable-line
    $element.keyup(() => {
      element.setCustomValidity('')
      $element.attr('data-content', '')
      $element.popover('hide')
    })
  }

  popoverOnElement($element, msg) {
    $element.attr('data-placement', 'bottom')
    $element.attr('data-content', msg)
    $element.popover('show')
    setTimeout(() => {
      $element.attr('data-content', '')
      $element.popover('disable')
    }, 3000)
  }

  setServerError(element, msg, name) {
    if (!element) {
      return
    }

    if (element.isCustom && element.setCustomValidity) {
      if (element.popoverElement) {
        this.popoverOnElement(element.popoverElement(), msg)
      } else {
        element.setCustomValidity(msg, name)
      }
      return
    }

    element.setCustomValidity(msg)
    const $element = $(element) // eslint-disable-line
    this.popoverOnElement($element, msg)
  }

  addValidationRef = (ref, name) => {
    this.validationRefs.push({ ref, name })
  }

  setValidationErrors(validationErrors) {
    this.validationRefs.forEach(({ ref, name }) => {
      const error = validationErrors[name]
      if (error) {
        this.setServerError(ref, error[0], name)
      }
    })
  }

  setServerValidity(error) {
    if (error && error.error) {
      const { validationErrors } = error.error
      if (validationErrors) {
        this.setState({ serverValidationFailed: true })
        this.setValidationErrors(validationErrors)
      } else {
        // another server error
        console.log(error)
      }
    }
  }

  keyTypeMappings = {
    'VERSA': ['VERSA'],
    'VERSA, BLE': ['VERSA', 'BLE'],
  }

  // Normalize the allowedKeyTypes array.
  getAllowedKeyTypesValue = () => {
    let keyTypes = this.props.organization.allowedKeyTypes || ['VERSA'] // Default to VERSA.
    const order = ['VERSA', 'BLE']
    keyTypes.sort((a, b) => order.indexOf(a) - order.indexOf(b)); // Sort according to order.
    return keyTypes.join(', ') // Return joined array.
  }

  handleDropdownChange = (e) => {
    const selectedLabel = e.target.value
    const updatedAllowedKeyTypes = this.keyTypeMappings[selectedLabel]

    this.props.onChange({ allowedKeyTypes: updatedAllowedKeyTypes })
  }

  keyTypeOptions = [
    { value: "VERSA", label: "VERSA" },
    { value: "VERSA, BLE", label: "VERSA, BLE" },
  ]

  /**
   * Client side validation for custom components
   *
   * @returns {boolean}
   */
  isValid = () => {
    if (this.formRef.checkValidity()) {
      this.setState({ showValidationHint: false })
      return true
    } else {
      this.formRef.reportValidity()
      this.setState({ showValidationHint: true })
      return false
    }
  }

  highlightInvalid = () => {}

  close = () => {
    const { id, onClose } = this.props
    $(`#modal-${id}`).modal('hide') // eslint-disable-line
    onClose()
  }

  renderMainContent = () => {
    const {
      organization: {
        id,
        name,
        subdomain,
        contactName,
        contactEmail,
        timeZone,
        supportPhone,
        contactPhone,
        keyExpTimeInterval,
        keyExpTimeIntervalEnabled,
        maxBackupKeys,
        backupKeyEnabled,
        allowedKeyTypes,
      },
      onChange,
    } = this.props
    return (
      <div className="col-xs-6">
        <div className="form-row">
          <ColumnContainer className="form-group col-md-8 bmd-form-group">
            <Column>
              <div>Organization name</div>
              <input
                ref={(ref) => {
                  this.organizationName = ref
                  this.setServerErrorDismiss(ref)
                  this.addValidationRef(this.organizationName, 'name')
                }}
                type="text"
                className="form-control"
                id="organizationName"
                placeholder=""
                required="required"
                defaultValue={name}
                onChange={({ target: { value } }) => onChange({ name: value })}
                onInvalid={defaultRequiredMessage}
                onInput={defaultRequiredMessage}
              />
              <div className="invalid-feedback">Please enter a valid name.</div>
            </Column>
          </ColumnContainer>
          <ColumnContainer className="form-group col-md-4 bmd-form-group">
            <Column>
              <div>Subdomain</div>
              <BorderLabel className="form-control">
                {subdomain || ''}
              </BorderLabel>
            </Column>
          </ColumnContainer>
        </div>

        <div className="form-row">
          <ColumnContainer className="form-group col-md-4 bmd-form-group">
            <Column>
              <div>Contact name</div>
              <input
                ref={(ref) => {
                  this.contactName = ref
                  this.setServerErrorDismiss(ref)
                  this.addValidationRef(this.contactName, 'contactName')
                }}
                type="text"
                className="form-control"
                id="contactName"
                placeholder=""
                required="required"
                defaultValue={contactName}
                onChange={({ target: { value } }) =>
                  onChange({ contactName: value })
                }
                onInvalid={defaultRequiredMessage}
                onInput={defaultRequiredMessage}
              />
              <div className="invalid-feedback">
                Please enter a valid contact name.
              </div>
            </Column>
          </ColumnContainer>
          <ColumnContainer className="form-group col-md-4 bmd-form-group">
            <Column>
              <div>Contact email</div>
              <input
                ref={(ref) => {
                  this.contactEmail = ref
                  this.setServerErrorDismiss(ref)
                  this.addValidationRef(this.contactEmail, 'contactEmail')
                }}
                type="email"
                className="form-control"
                id="contactEmail"
                placeholder=""
                required="required"
                pattern={emailValidationPattern}
                onKeyUp={() =>
                  (this.contactEmail.value = this.contactEmail.value.toLowerCase())
                }
                defaultValue={contactEmail}
                onChange={({ target: { value } }) =>
                  onChange({ contactEmail: value })
                }
                onInvalid={defaultRequiredMessage}
                onInput={defaultRequiredMessage}
              />
              <div className="invalid-feedback">
                Please enter a valid contact email.
              </div>
            </Column>
          </ColumnContainer>
          <ColumnContainer className="form-group col-md-4 bmd-form-group">
            <Column>
              <div>Contact phone</div>
              <input
                ref={ref => this.setServerErrorDismiss(ref)}
                type="text"
                id="contactPhone"
                placeholder=""
                className="form-control"
                defaultValue={contactPhone}
                onChange={e => onChange({ contactPhone: e.target.value })}
              />
            </Column>
          </ColumnContainer>
        </div>

        <div className="form-row">
          <ColumnContainer className="form-group col-md-4 bmd-form-group">
            <Column>
              <div>Staff Key Expiration</div>
              <KeyExpirationInput
                name="storeKeyExpiration"
                checked={keyExpTimeIntervalEnabled}
                interval={keyExpTimeInterval}
                onChange={(checked) =>
                  onChange({ keyExpTimeIntervalEnabled: checked })
                }
                onChangeInterval={(interval) =>
                  onChange({ keyExpTimeInterval: interval })
                }
              />
            </Column>
          </ColumnContainer>
          <ColumnContainer className="form-group col-md-4 bmd-form-group">
            <Column>
              <div>Time zone</div>
              <TimezonePicker
                ref={(ref) => {
                  this.timeZone = ref
                  this.addValidationRef(this.timeZone, 'timeZone')
                  this.setServerErrorDismiss(ref)
                }}
                value={timeZone}
                onChange={({ value }) => onChange({ timeZone: value })}
              />
              <div className="invalid-feedback">
                Please enter a valid time zone.
              </div>
            </Column>
          </ColumnContainer>
          <ColumnContainer className="form-group col-md-4 bmd-form-group">
            <Column>
              <div>Support phone</div>
              <input
                ref={ref => this.setServerErrorDismiss(ref)}
                id="supportPhone"
                placeholder=""
                className="form-control"
                required="required"
                onInvalid={defaultRequiredMessage}
                onInput={defaultRequiredMessage}
                defaultValue={supportPhone}
                onChange={e => onChange({ supportPhone: e.target.value })}
              />
            </Column>
          </ColumnContainer>
        </div>

        <div className="form-row">
          <BackupKeys
            container={ColumnContainer}
            column={Column}
            onChange={onChange}
            backupKeyEnabled={backupKeyEnabled}
            maxBackupKeys={maxBackupKeys}
          />
          <ColumnContainer className="form-group col-md-4 bmd-form-group">
            <Column>
              <div>Allowed Key Types</div>
              <Select
                name="allowedKeyTypes"
                value={this.getAllowedKeyTypesValue()}
                onChange={this.handleDropdownChange}>
                {this.keyTypeOptions.map(option => (
                  <option key={option.value} value={option.value}>{option.label}</option>
                ))}
              </Select>
            </Column>
          </ColumnContainer>
        </div>

        <div className="form-row">
          <DeviceConfigurationList
            onChange={onChange}
            resourceType="Organization"
            resourceId={id}
          />
        </div>
      </div>
    )
  }

  renderAddresses = () => {
    const {
      organization: {
        address: {
          address1,
          address2,
          address3,
          city,
          country,
          state,
          zip,
        } = {},
      },
      onChange,
    } = this.props

    return (
      <div className="col-xs-6">
        <ColumnContainer className="form-group bmd-form-group">
          <div className="form-row">
            <CountryRegion
              ref={(ref) => {
                this.countryRegion = ref
                this.setServerErrorDismiss(ref)
                this.addValidationRef(this.countryRegion, 'address.state')
                this.addValidationRef(this.countryRegion, 'address.country')
              }}
              container={Column}
              country={country}
              region={state}
              selectCountry={(changedCountry) =>
                onChange({ address: { country: changedCountry } })
              }
              selectRegion={(region) =>
                onChange({ address: { state: region } })
              }
            />
          </div>
        </ColumnContainer>
        <div className="form-row">
          <ColumnContainer className="form-group col-md-8 bmd-form-group">
            <Column>
              <div>Address 1</div>
              <input
                ref={(ref) => {
                  this.address1 = ref
                  this.setServerErrorDismiss(ref)
                  this.addValidationRef(this.address1, 'address.address1')
                }}
                type="text"
                className="form-control"
                id="address1"
                placeholder=""
                required="required"
                defaultValue={address1}
                onChange={({ target: { value } }) =>
                  onChange({ address: { address1: value } })
                }
              />
            </Column>
          </ColumnContainer>
          <ColumnContainer className="form-group col-md-4 bmd-form-group">
            <Column>
              <div>City</div>
              <input
                ref={(ref) => {
                  this.city = ref
                  this.setServerErrorDismiss(ref)
                  this.addValidationRef(this.city, 'address.city')
                }}
                type="text"
                className="form-control"
                id="city"
                placeholder=""
                required="required"
                defaultValue={city}
                onChange={({ target: { value } }) =>
                  onChange({ address: { city: value } })
                }
              />
            </Column>
          </ColumnContainer>
        </div>

        <div className="form-row">
          <ColumnContainer className="form-group col-md-8 bmd-form-group">
            <Column>
              <div>Address 2</div>
              <input
                ref={(ref) => {
                  this.address2 = ref
                  this.setServerErrorDismiss(ref)
                }}
                type="text"
                className="form-control"
                id="address2"
                placeholder=""
                required=""
                defaultValue={address2}
                onChange={({ target: { value } }) =>
                  onChange({ address: { address2: value } })
                }
              />
            </Column>
          </ColumnContainer>
          <ColumnContainer className="form-group col-md-4 bmd-form-group">
            <Column>
              <div>Postal code</div>
              <input
                ref={(ref) => {
                  this.zip = ref
                  this.setServerErrorDismiss(ref)
                  this.addValidationRef(this.zip, 'address.zip')
                }}
                type="text"
                className="form-control"
                id="zip"
                placeholder=""
                required="required"
                defaultValue={zip}
                onChange={({ target: { value } }) =>
                  onChange({ address: { zip: value } })
                }
              />
            </Column>
          </ColumnContainer>
        </div>

        <div className="form-row">
          <ColumnContainer className="form-group col-md-8 bmd-form-group">
            <Column>
              <div>Address 3</div>
              <input
                ref={(ref) => {
                  this.address3 = ref
                  this.setServerErrorDismiss(ref)
                }}
                type="text"
                className="form-control"
                id="address3"
                placeholder=""
                required=""
                defaultValue={address3}
                onChange={({ target: { value } }) =>
                  onChange({ address: { address3: value } })
                }
              />
            </Column>
          </ColumnContainer>
        </div>
      </div>
    )
  }

  render() {
    const { organization: { name }, onConfirm, loading } = this.props

    return (
      <div className="modal-content">
        <form
          className="needs-validation was-validated"
          ref={(ref) => (this.formRef = ref)}
          noValidate
          onSubmit={(e) => {
            e.preventDefault()
            // Custom components validation check
            if (this.isValid()) {
              onConfirm()
              this.setState({ serverValidationFailed: false })
            } else {
              this.highlightInvalid()
            }
          }}
        >
          <div className="modal-header border-bottom">
            <h5 className="modal-title" id="exampleModalLongTitle">
              {name}
            </h5>
            <div>
              <SubmitWithHint
                text={'Save'}
                showHint={this.state.showValidationHint}
                onClick={this.isValid}
                onFocus={this.isValid}
                className="btn btn-primary btn-raised"
              />
              <button
                type="button"
                className="btn btn-primary"
                onClick={this.close}
              >
                Close
              </button>
            </div>
          </div>
          <ModalBody className="modal-body">
            {this.renderMainContent()}
          </ModalBody>
          <ModalBody className="modal-body">{this.renderAddresses()}</ModalBody>
          {loading && (
            <React.Fragment>
              <Overlay />
              <ProgressIndicator />
            </React.Fragment>
          )}
        </form>
      </div>
    )
  }
}

EditOrganizationDetailsScreen.propTypes = {
  id: PropTypes.string.isRequired,
  organization: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  loading: PropTypes.bool,
}

export default EditOrganizationDetailsScreen
