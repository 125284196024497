import React from 'react'
import PropTypes from 'prop-types'
import { getTableHeight, PageHeaderHeight } from '../../utils/utils'
import OTAHomeScreen from './index.screen'

const HomePage = ({ history }) => (
  <div className="Home-Page" style={{ flex: 1 }}>
    <OTAHomeScreen
      history={history}
      height={getTableHeight() + PageHeaderHeight}
    />
  </div>
)

HomePage.propTypes = {
  history: PropTypes.object.isRequired,
}

export default HomePage
