/*
 * Firmwares
*/

import { Map } from 'immutable'
import { ActionTypes } from '../constants'

export const initialState = new Map({
  fetchFailed: null,
  fetchLoading: false,
  uploadFailed: null,
  uploadLoading: false,
  deleteFailed: null,
  deleteLoading: false,
  firmwares: [],
})

function reducer(state = initialState, { payload, type }) {
  switch (type) {
    case ActionTypes.FETCH_FIRMWARES_FULFILLED:
      return state
        .set('fetchLoading', false)
        .set('fetchFailed', null)
        .set('firmwares', payload.firmwares)
    case ActionTypes.FETCH_FIRMWARES_PENDING:
      return state.set('fetchFailed', null).set('fetchLoading', true)
    case ActionTypes.FETCH_FIRMWARES_FAILED:
      return state.set('fetchFailed', payload.error).set('fetchLoading', false)

    case ActionTypes.UPLOAD_FIRMWARE_FULFILLED: {
      const { firmware } = payload
      const firmwares = [...state.get('firmwares'), firmware].sort()

      return state
        .set('uploadFailed', null)
        .set('uploadLoading', false)
        .set('firmwares', firmwares)
    }
    case ActionTypes.UPLOAD_FIRMWARE_FAILED:
      return state
        .set('uploadFailed', payload.error)
        .set('uploadLoading', false)
    case ActionTypes.UPLOAD_FIRMWARE_PENDING:
      return state.set('uploadFailed', null).set('uploadLoading', true)

    case ActionTypes.DELETE_FIRMWARE_FULFILLED: {
      const { deletedFirmware } = payload
      const firmwares = state.get('firmwares')
      const newFirmwares = firmwares.filter((item) => item !== deletedFirmware)
      return state
        .set('deleteFailed', null)
        .set('deleteLoading', false)
        .set('firmwares', newFirmwares)
    }
    case ActionTypes.DELETE_FIRMWARE_PENDING:
      return state.set('deleteFailed', null).set('deleteLoading', true)
    case ActionTypes.DELETE_FIRMWARE_FAILED:
      return state
        .set('deleteFailed', payload.error)
        .set('deleteLoading', false)
    default:
      return state
  }
}

export default reducer
