/**
 *
 * Multicard Column
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import uuid from 'uuid'
import { PageHeaderHeight } from '../../utils/utils'

const headerHeight = PageHeaderHeight
const marginVertical = 6
const paddingBottom = 10

const MulticardColumn = ({ items, height, minWidth }) => (
  <div
    className="col-md-auto col"
    style={{
      marginLeft: marginVertical,
      marginRight: marginVertical,
      minWidth,
    }}
  >
    {(items || []).map((item, i) => {
      const { component, title } = item
      if (!component || !component.props) return <div />
      const cardHeight = height / items.length
      const contentHeight = title
        ? cardHeight - paddingBottom - headerHeight
        : cardHeight - paddingBottom
      const offsetTop = i * cardHeight
      const offsetBottom = (items.length - i - 1) * cardHeight + paddingBottom
      const content = React.cloneElement(component, { height: contentHeight })
      return (
        <div className="panel panel-default" key={uuid.v4()}>
          <div
            className="card"
            style={{
              minWidth,
              position: 'absolute',
              left: 0,
              top: offsetTop,
              bottom: offsetBottom,
              overflow: 'auto',
            }}
          >
            {title && (
              <div
                className="card-header text-left font-weight-bold"
                style={{
                  height: headerHeight,
                }}
              >
                {title}
              </div>
            )}
            {content}
          </div>
        </div>
      )
    })}
  </div>
)

MulticardColumn.propTypes = {
  items: PropTypes.array.isRequired,
  height: PropTypes.number,
  minWidth: PropTypes.number,
}

export default MulticardColumn
