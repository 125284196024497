/**
 *
 * TemplateFixture
 *
 */

import _ from 'lodash'
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose } from 'redux'

import injectSaga from '../../utils/injectSaga'
import injectReducer from '../../utils/injectReducer'
import {
  makeSelectCanvasObject,
  makeSelectCanvasSelectedObject,
  makeSelectLoading,
  makeSelectFailed,
  makeSelectIsStatic,
  makeSelectPrototypes,
  makeSelectSavedTemplate,
  makeSelectInheritanceRule,
  makeSelectCloneable,
  makeSelectCopyable,
  makeSelectTemplateFixtureAngle,
  makeSelectTemplateFixtureIsFurniture,
  makeSelectTemplateFixtureName,
  makeSelectTemplateFixtureNameShown,
  makeSelectTemplateFixtureSviSizeType,
  makeSelectTemplateFixtureSviType,
  makeSelectTemplateRules,
  makeSelectTemplateNames,
  makeSelectCreatedTemplateFixtureName,
} from './selectors'
import reducer from './reducer'
import saga from './saga'
import {
  prepareTemplateFixture,
  saveTemplateFixture,
  updateInheritance,
  updateCloneableCopyable,
  templateFixtureChanged,
  templateFixtureNameChanged,
  templateFixtureBeginTransformation,
  templateFixtureSizeChanged,
  deleteAllFixtureRules,
  createFixtureRule,
  editFixtureRule,
  deleteFixtureRule,
} from './actions'
import { openModal, closeModal, notifyModal } from '../App/actions'
import FixtureRulesModal from './modals/FixtureRules'
import TemplateFixtureScreen from './index.screen'
import ProgressIndicator from '../../components/ProgressIndicator'
import { makeSelectAllProducts } from '../FixturePage/selectors'
import { SOURCE_ATTRIBUTES } from './modals/ruleConstants'
import ConfirmModal from '../ModalContainer/ConfirmModal'
import FetchFailedAlert from '../../components/FetchFailed'
import { orgUrl } from '../../utils/mtiUtils'
import { safeUri } from '../../utils/utils'

export const size = {
  width: 600,
  height: 400,
}

const unsupportedMessage =
  'This rule type is not supported and can not be edited.'

export class TemplateFixture extends React.PureComponent {
  componentDidMount() {
    this.loadCanvas(null, this.props.isStatic)
  }

  componentDidUpdate(prevProps) {
    const prevTId = prevProps.match.params.tId
    const thisTId = this.props.match.params.tId
    if (prevTId !== thisTId) {
      this.loadCanvas(
        {
          tId: thisTId,
        },
        this.props.isStatic
      )
      return
    }

    // Navigation to Template Fixture with positions screen
    const { savedTemplate } = this.props
    if (savedTemplate && !prevProps.savedTemplate) {
      this.onNextScreen(savedTemplate)
    }
  }

  deleteRulesConfirmation = (text, callback) => {
    const { openModal, closeModal } = this.props
    openModal({
      id: 'delete-compliance-rules',
      type: 'bootstrap',
      appearance: 'modal-sm',
      content: (
        <ConfirmModal
          questionText={text}
          id={'delete-compliance-rules'}
          cancelText={'CANCEL'}
          confirmText={'DELETE'}
          onClose={() => closeModal({ id: 'delete-compliance-rules' })}
          onConfirm={callback}
        />
      ),
    })
  }

  deleteOneRuleConfirmation = (callback) => {
    const text = 'Are you sure you want to delete this compliance rule?'
    this.deleteRulesConfirmation(text, callback)
  }

  deleteAllRulesConfirmation = (callback) => {
    const text = 'Are you sure you want to delete all compliance rules?'
    this.deleteRulesConfirmation(text, callback)
  }

  onDeleteAllPositionRules = (position) => {
    this.deleteAllRulesConfirmation(() => {
      this.props.deleteAllFixtureRules(position)
    })
  }

  onTemplateChange = (editedData) => {
    const {
      templateFixtureNameChanged: onNameChanged,
      templateFixtureBeginTransformation: onBeginTransformation,
      templateFixtureSizeChanged: onSizeChanged,
      updateCloneableCopyable: onUpdateCloneableCopyable,
    } = this.props
    const { name, size: sizeType, type, cloneable, copyable } = editedData

    if (cloneable || copyable) {
      onUpdateCloneableCopyable({ cloneable, copyable })
    }

    if (_.isString(name)) {
      onNameChanged(name)
    }

    if (sizeType) {
      onSizeChanged(sizeType)
    }

    if (type) {
      onBeginTransformation(type)
    }
  }

  onNextScreen = ({ id, name }) => {
    const { history } = this.props
    history.push(
      `${orgUrl()}/templates/${id || 'createdId'}/${safeUri(name)}/positions`
    )
  }

  onSaveTemplate = () => {
    const {
      saveTemplateFixture: doSaveTemplateFixture,
      canvasObject,
      selectedObject,
      cloneable,
      copyable,
    } = this.props
    const templateData =
      canvasObject.objects.length > 0
        ? canvasObject.objects[selectedObject]
        : undefined
    if (!templateData) return

    doSaveTemplateFixture({
      template: {
        ...templateData,
        cloneable,
        copyable,
        template: true,
      },
      screen: size,
    })
  }

  onAddFixtureComplianceRule = () => {
    const {
      products,
      canvasObject,
      selectedObject,
      openModal: doOpenModal,
      closeModal: doCloseModal,
      createFixtureRule: doCreateFixtureRule,
    } = this.props
    const id = 'add-rule'

    if (canvasObject.objects.length > 0) {
      const fixture = canvasObject.objects[selectedObject]
      doOpenModal({
        id,
        type: 'bootstrap',
        content: (
          <FixtureRulesModal
            id={id}
            products={products}
            onConfirm={(rule) => {
              doCreateFixtureRule(fixture.id, rule)
              doCloseModal({ id })
            }}
            onClose={() => doCloseModal({ id })}
          />
        ),
      })
    }
  }

  onEditFixtureComplianceRule = (rule) => {
    const {
      products,
      canvasObject,
      selectedObject,
      openModal: doOpenModal,
      closeModal: doCloseModal,
      notifyModal: doNotifyModal,
      editFixtureRule: doEditFixtureRule,
      deleteFixtureRule: doDeleteFixtureRule,
    } = this.props
    const id = 'edit-rule'

    if (
      rule.sourceAttribute === SOURCE_ATTRIBUTES.positions ||
      rule.sourceAttribute === SOURCE_ATTRIBUTES.product_ids ||
      rule.sourceAttribute === SOURCE_ATTRIBUTES.live_product_ids
    ) {
      if (canvasObject.objects.length > 0) {
        const fixture = canvasObject.objects[selectedObject]
        doOpenModal({
          id,
          type: 'bootstrap',
          content: (
            <FixtureRulesModal
              id={id}
              rule={rule}
              products={products}
              onConfirm={(editedRule) => {
                doEditFixtureRule(fixture.id, { ...editedRule, id: rule.id })
                doCloseModal({ id })
              }}
              onDelete={() => {
                setTimeout(() => {
                  this.deleteOneRuleConfirmation(() =>
                    doDeleteFixtureRule(fixture.id, rule)
                  )
                }, 500)
              }}
              onClose={() => doCloseModal({ id })}
            />
          ),
        })
      }
    } else {
      doNotifyModal(unsupportedMessage)
    }
  }

  getObject(canvasObject, index) {
    return ((canvasObject || {}).objects || [])[index]
  }

  loadCanvas(params, isStatic) {
    const newParams = params || this.props.match.params
    const { prepareTemplateFixture: doPrepareTemplateFixture } = this.props
    doPrepareTemplateFixture(size, isStatic, newParams.tId)
  }

  render() {
    const {
      history,
      canvasObject,
      selectedObject,
      templateFixtureChanged: onChanged,
      templateFixtureNameChanged: onNameChanged,
      updateInheritance: onUpdateInheritance,
      fixtureComplianceRules,
      loading,
      failed,
      isStatic,
      prototypes,
      selectedTemplateFixtureName,
      selectedTemplateFixtureSviType,
      selectedTemplateFixtureSviSizeType,
      selectedTemplateFixtureSviAngle,
      selectedTemplateFixtureIsFurniture,
      selectedTemplateFixtureNameHasShown,
      inheritanceRule: {
        size: isFixtureSizeAvailable,
        angle: isFixtureRotationAvailable,
      },
      cloneable,
      copyable,
      templateNames,
      templateName,
    } = this.props

    if (loading) {
      return <ProgressIndicator text={'Loading...'} />
    }

    if (failed) return <FetchFailedAlert failed={failed} />

    if (!prototypes) return <div />

    const isTemplateCreated = !isNaN(
      (this.getObject(canvasObject, selectedObject) || {}).id
    )
    return (
      <TemplateFixtureScreen
        isFixtureSizeAvailable={isFixtureSizeAvailable}
        isFixtureRotationAvailable={isFixtureRotationAvailable}
        cloneable={cloneable}
        copyable={copyable}
        onCancel={() => history.goBack()}
        onTemplateChange={this.onTemplateChange}
        onSizeCheck={(checked) => onUpdateInheritance({ size: checked })}
        onRotationCheck={(checked) => onUpdateInheritance({ angle: checked })}
        canvasObject={canvasObject}
        selectedObject={selectedObject}
        prototypes={prototypes}
        fixtureComplianceRules={fixtureComplianceRules}
        canvasSize={size}
        isStatic={isStatic}
        onChanged={onChanged}
        onNameChanged={onNameChanged}
        onOpenFixture={() => {}}
        onMessage={() => {}}
        onEdit={() => {}}
        isTemplateCreated={isTemplateCreated}
        saveTemplateFixture={this.onSaveTemplate}
        selectedTemplateFixtureName={selectedTemplateFixtureName}
        selectedTemplateFixtureSviType={selectedTemplateFixtureSviType}
        selectedTemplateFixtureSviSizeType={selectedTemplateFixtureSviSizeType}
        selectedTemplateFixtureSviAngle={selectedTemplateFixtureSviAngle}
        selectedTemplateFixtureIsFurniture={selectedTemplateFixtureIsFurniture}
        selectedTemplateFixtureNameHasShown={
          selectedTemplateFixtureNameHasShown
        }
        onDeleteAllFixtureComplianceRules={this.onDeleteAllPositionRules}
        onAddFixtureComplianceRule={this.onAddFixtureComplianceRule}
        onEditFixtureComplianceRule={this.onEditFixtureComplianceRule}
        templateNames={templateNames}
        templateName={templateName}
      />
    )
  }
}

TemplateFixture.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
  canvasObject: PropTypes.object.isRequired,
  selectedObject: PropTypes.number,
  loading: PropTypes.any,
  failed: PropTypes.object,
  isStatic: PropTypes.bool,
  prototypes: PropTypes.object,
  savedTemplate: PropTypes.object,
  inheritanceRule: PropTypes.object,
  cloneable: PropTypes.bool,
  copyable: PropTypes.bool,
  updateInheritance: PropTypes.func,
  updateCloneableCopyable: PropTypes.func,
  prepareTemplateFixture: PropTypes.func,
  saveTemplateFixture: PropTypes.func,
  templateFixtureChanged: PropTypes.func,
  templateFixtureNameChanged: PropTypes.func,
  templateFixtureBeginTransformation: PropTypes.func,
  templateFixtureSizeChanged: PropTypes.func,
  selectedTemplateFixtureName: PropTypes.string,
  selectedTemplateFixtureSviType: PropTypes.string,
  selectedTemplateFixtureSviSizeType: PropTypes.string,
  selectedTemplateFixtureSviAngle: PropTypes.string,
  selectedTemplateFixtureIsFurniture: PropTypes.bool,
  selectedTemplateFixtureNameHasShown: PropTypes.bool,
  fixtureComplianceRules: PropTypes.any,
  deleteAllFixtureRules: PropTypes.func,
  products: PropTypes.array,
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  notifyModal: PropTypes.func.isRequired,
  createFixtureRule: PropTypes.func.isRequired,
  editFixtureRule: PropTypes.func.isRequired,
  deleteFixtureRule: PropTypes.func.isRequired,
  templatesNames: PropTypes.array,
  templateName: PropTypes.string,
}

const mapStateToProps = createStructuredSelector({
  canvasObject: (state, ownProps) =>
    makeSelectCanvasObject(ownProps.match.params.tId)(state, ownProps),
  selectedObject: makeSelectCanvasSelectedObject(),
  loading: makeSelectLoading(),
  failed: makeSelectFailed(),
  isStatic: makeSelectIsStatic(),
  prototypes: makeSelectPrototypes(),
  savedTemplate: makeSelectSavedTemplate(),
  inheritanceRule: makeSelectInheritanceRule(),
  cloneable: makeSelectCloneable(),
  copyable: makeSelectCopyable(),
  selectedTemplateFixtureName: makeSelectTemplateFixtureName(),
  selectedTemplateFixtureSviType: makeSelectTemplateFixtureSviType(),
  selectedTemplateFixtureSviSizeType: makeSelectTemplateFixtureSviSizeType(),
  selectedTemplateFixtureIsFurniture: makeSelectTemplateFixtureIsFurniture(),
  selectedTemplateFixtureNameHasShown: makeSelectTemplateFixtureNameShown(),
  selectedTemplateFixtureSviAngle: makeSelectTemplateFixtureAngle(),
  fixtureComplianceRules: (state, ownProps) =>
    makeSelectTemplateRules(ownProps.match.params.tId)(state, ownProps),
  products: makeSelectAllProducts(),
  templateNames: makeSelectTemplateNames(),
  templateName: (state, ownProps) =>
    makeSelectCreatedTemplateFixtureName(ownProps.match.params.tId)(
      state,
      ownProps
    ),
})

const mapDispatchToProps = {
  prepareTemplateFixture,
  saveTemplateFixture,
  updateInheritance,
  updateCloneableCopyable,
  templateFixtureChanged,
  templateFixtureNameChanged,
  templateFixtureBeginTransformation,
  templateFixtureSizeChanged,
  deleteAllFixtureRules,
  openModal,
  closeModal,
  notifyModal,
  createFixtureRule,
  editFixtureRule,
  deleteFixtureRule,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const withReducer = injectReducer({ key: 'templateFixture', reducer })
const withSaga = injectSaga({ key: 'templateFixture', saga })

export default compose(withReducer, withSaga, withConnect)(TemplateFixture)
