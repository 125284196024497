import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { ExportToCsv } from 'export-to-csv'
import { createStructuredSelector } from 'reselect'
import { hasAccess, createSmartStoreLayoutLink } from '../../utils/mtiUtils'
import {
  notifyModal,
  storeDetailsAction,
  createStoreAction,
} from '../App/actions'
import {
  makeSelectMtiPermissionAccessData,
  makeSelectMtiPermissionAnyResource,
  makeSelectMtiPermissionResources,
} from '../App/selectors'
import { loadStores } from './actions'
import {
  makeSelectStores,
  makeSelectFailed,
  makeSelectLoading,
} from './selectors'
import StoresPageScreen, { getStringTags } from './index.screen'
import StoresListForHomePage from './storesListForHomePage.screen'
import StoresPageModalScreen from './index.modal.screen'
import StoreManagement from './storeManagement'
import moment from 'moment'
import FetchFailedAlert from '../../components/FetchFailed'
import { makeSelectRegions } from '../RegionsPage/selectors'
import { regionName } from '../RegionsPage/regions'
import { CSVOptions } from '../../utils/utils'
import { withRouter } from 'react-router-dom'

const permText = (text) =>
  `You don't have appropriate rights to access ${text}.\nPlease contact your store manager.`

export class StoresPage extends React.Component {
  componentDidMount() {
    const { hasViewStoresPermission, history } = this.props
    if (this.noPermission(hasViewStoresPermission, permText('the Stores'))) {
      history.push('/')
      return
    }
    this.loadStores()
  }

  /* eslint-disable no-undef */
  onStoreSelection = (store) => {
    const {
      showStorePermissionData,
      storeLayoutPermissionData,
      storeDetailsAction: doStoreDetailsAction,
      history,
    } = this.props
    const hasShowStorePermission = hasAccess({
      ...showStorePermissionData,
      resourceId: store.id,
      resourceType: 'Store',
      regionId: store.regionId,
    })
    const hasShowStoreLayoutPermission = hasAccess({
      ...storeLayoutPermissionData,
      resourceId: store.id,
      resourceType: 'Store',
      regionId: store.regionId,
    })

    if (!hasShowStorePermission && hasShowStoreLayoutPermission) {
      history.push(createSmartStoreLayoutLink(store).path)
      return
    }

    if (this.noPermission(hasShowStorePermission, permText('View Store'))) {
      return
    }
    doStoreDetailsAction(store)
  }

  openCreateStoreModal = () => {
    const {
      hasCreateStorePermission,
      createStoreAction: doCreateStoreAction,
    } = this.props
    if (this.noPermission(hasCreateStorePermission, permText('Create Store'))) {
      return
    }
    doCreateStoreAction()
  }

  loadStores = () => {
    this.props.loadStores()
  }

  noPermission = (hasPermission, text) => {
    const { notifyModal: doNotifyModal } = this.props
    if (!hasPermission) doNotifyModal(text)
    return !hasPermission
  }

  fileName() {
    const date = moment().format('MM-DD-YYYY-HH-mm-ss')
    return `Stores_${date}`
  }

  onExportAsCSV = (stores) => {
    const data = this.onExportStores(stores)
    const options = CSVOptions(this.fileName())
    const csvExporter = new ExportToCsv(options)

    csvExporter.generateCsv(data)
  }

  onExportStores = (data) => {
    const storesData = data.map((r) => [
      r.id,
      r.regionName,
      r.address.state,
      r.address.city,
      r.name,
      r.contactName,
      r.contactEmail,
      getStringTags(r.tags),
    ])

    const columnName = [
      'id',
      'regionName',
      'state',
      'city',
      'storeName',
      'contactName',
      'contactEmail',
      'tags',
    ]

    storesData.unshift(columnName)

    return storesData
  }

  render() {
    const {
      stores,
      loading,
      failed,
      isHomePage,
      height,
      isSelectStoreModal,
      onStoreSelected,
      id,
      onClose,
      hasViewStoresPermission,
      regions,
    } = this.props

    if (failed) return <FetchFailedAlert failed={failed} />
    if (!stores) return null

    stores.forEach((store) => {
      store.regionName = regionName(store.regionId, regions)
    })

    const storesChecked = hasViewStoresPermission ? stores : []

    if (isSelectStoreModal) {
      return (
        <StoresPageModalScreen
          id={id}
          stores={storesChecked}
          onStore={onStoreSelected}
          onClose={() => onClose()}
        />
      )
    }

    if (isHomePage) {
      return (
        <div>
          <StoreManagement history={this.props.history} />
          <StoresListForHomePage
            loading={loading}
            stores={storesChecked}
            height={height}
            onStoreSelection={this.onStoreSelection}
          />
        </div>
      )
    }

    return (
      <div>
        <StoreManagement history={this.props.history} />
        <StoresPageScreen
          loading={loading}
          stores={storesChecked}
          onStoreSelection={this.onStoreSelection}
          onAddNewStore={this.openCreateStoreModal}
          onExportAsCSV={() => this.onExportAsCSV(storesChecked)}
        />
      </div>
    )
  }
}

StoresPage.propTypes = {
  history: PropTypes.any,
  loadStores: PropTypes.func.isRequired,
  isHomePage: PropTypes.bool,
  height: PropTypes.number,
  stores: PropTypes.array,
  regions: PropTypes.array,
  loading: PropTypes.bool,
  failed: PropTypes.object,
  notifyModal: PropTypes.func,
  storeDetailsAction: PropTypes.func,
  createStoreAction: PropTypes.func,
  isSelectStoreModal: PropTypes.bool,
  onStoreSelected: PropTypes.func,
  onClose: PropTypes.func,
  id: PropTypes.string,
  hasCreateStorePermission: PropTypes.bool,
  showStorePermissionData: PropTypes.object,
  storeLayoutPermissionData: PropTypes.object,
  hasViewStoresPermission: PropTypes.bool,
}

const mapStateToProps = createStructuredSelector({
  stores: makeSelectStores(),
  regions: makeSelectRegions(),
  loading: makeSelectLoading(),
  failed: makeSelectFailed(),
  hasCreateStorePermission: makeSelectMtiPermissionAnyResource(
    'Store: Create New Objects'
  ),
  showStorePermissionData: makeSelectMtiPermissionAccessData(
    'Store: Read/Show Details'
  ),
  storeLayoutPermissionData: makeSelectMtiPermissionAccessData('Store: Layout'),
  hasViewStoresPermission: makeSelectMtiPermissionAnyResource(
    'Store: Index (list) Records'
  ),
  listStoreManagerRole: makeSelectMtiPermissionResources('Store Manager'),
  listRegionalManagerRole: makeSelectMtiPermissionResources('Regional Manager'),
})

const mapDispatchToProps = {
  loadStores,
  notifyModal,
  storeDetailsAction,
  createStoreAction,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect)(withRouter(StoresPage))
