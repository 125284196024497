import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import styles from '../../styles/App.css'
import stylesInput from '../../components/Input/css/style.css'
import DropdownButton from '../DropdownButton'
import ActionButton from '../DropdownButton/actionButton'

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
`

class AssignUser extends React.PureComponent {
  render() {
    const { item, onAssign, onUnassign, onProfile } = this.props
    const { userName, userId, backupKey } = item

    if (backupKey) {
      return (
        <span className={stylesInput.readonly}>
          <label className="m-0">Assign</label>
        </span>
      )
    }

    if (!userId) {
      return (
        <Link
          className={styles.link}
          to={'#'}
          onClick={() => onAssign(item)}
          name={userId ? userName : 'Assign'}
        >
          {userId ? userName : 'Assign'}
        </Link>
      )
    }

    const userActions = (
      <ButtonsContainer>
        {userId && (
          <ActionButton onClick={() => onProfile(item)} text={'Profile'} />
        )}
        {userId && (
          <ActionButton onClick={() => onUnassign(item)} text={'Unassign'} />
        )}
        {!userId && (
          <ActionButton onClick={() => onAssign(item)} text={'Assign'} />
        )}
      </ButtonsContainer>
    )

    return (
      <DropdownButton dropdownChildren={userActions}>
        <Link className={styles.link} to={'#'}>
          {userId ? userName : 'Assign'}
        </Link>
      </DropdownButton>
    )
  }
}

AssignUser.propTypes = {
  item: PropTypes.object.isRequired,
  onAssign: PropTypes.func.isRequired,
  onUnassign: PropTypes.func.isRequired,
  onProfile: PropTypes.func.isRequired,
}

export default AssignUser
