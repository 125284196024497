import { ActionTypes } from '../constants'

/**
 * Fetch Firmwares Failed
 *
 * @returns {Object}
 */
export function fetchFirmwaresFailed(error) {
  return {
    type: ActionTypes.FETCH_FIRMWARES_FAILED,
    payload: { error },
  }
}

/**
 * Fetch Firmwares Fulfilled
 *
 * @returns {Object}
 */
export function fetchFirmwaresFulfilled(firmwares) {
  return {
    type: ActionTypes.FETCH_FIRMWARES_FULFILLED,
    payload: {
      firmwares,
    },
  }
}

/**
 * Fetch Firmwares Pending
 *
 * @returns {Object}
 */
export function fetchFirmwaresPending() {
  return {
    type: ActionTypes.FETCH_FIRMWARES_PENDING,
  }
}

/**
 * Load Firmwares
 *
 * @returns {Object}
 */
export function loadFirmwares() {
  return {
    type: ActionTypes.LOAD_FIRMWARES,
  }
}

/**
 * Upload Firmware
 *
 * @returns {Object}
 */
export function uploadFirmware(firmware) {
  return {
    type: ActionTypes.UPLOAD_FIRMWARE,
    payload: { firmware },
  }
}

/**
 * Upload Firmware Pending
 *
 * @returns {Object}
 */
export function uploadFirmwarePending() {
  return {
    type: ActionTypes.UPLOAD_FIRMWARE_PENDING,
  }
}

/**
 * Upload Firmware Fulfilled
 *
 * @returns {Object}
 */
export function uploadFirmwareFulfilled(firmware) {
  return {
    type: ActionTypes.UPLOAD_FIRMWARE_FULFILLED,
    payload: { firmware },
  }
}

/**
 * Upload Firmware Failed
 *
 * @returns {Object}
 */
export function uploadFirmwareFailed(error) {
  return {
    type: ActionTypes.UPLOAD_FIRMWARE_FAILED,
    payload: { error },
  }
}

/**
 * Delete Firmware
 *
 * @returns {Object}
 */
export function deleteFirmware(firmware) {
  return {
    type: ActionTypes.DELETE_FIRMWARE,
    payload: { firmware },
  }
}

/**
 * Delete Firmware Pending
 *
 * @returns {Object}
 */
export function deleteFirmwarePending() {
  return {
    type: ActionTypes.DELETE_FIRMWARE_PENDING,
  }
}

/**
 * Delete Firmware Fulfilled
 *
 * @returns {Object}
 */
export function deleteFirmwareFulfilled(deletedFirmware) {
  return {
    type: ActionTypes.DELETE_FIRMWARE_FULFILLED,
    payload: { deletedFirmware },
  }
}

/**
 * Delete Firmware Failed
 *
 * @returns{Object}
 */
export function deleteFirmwareFailed(error) {
  return {
    type: ActionTypes.DELETE_FIRMWARE_FAILED,
    payload: { error },
  }
}
