/**
 *
 * Linked Fixtures Reducer
 *
 */

import { Map } from 'immutable'
import { ActionTypes } from './constants'

const initialState = new Map({
  loading: false,
  error: null,
})

function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case ActionTypes.FETCH_LINKED_FIXTURES_PENDING:
      return state.set('failed', null).set('loading', true)
    case ActionTypes.FETCH_LINKED_FIXTURES_FULFILLED:
      return state.set('failed', null).set('loading', false)
    case ActionTypes.FETCH_LINKED_FIXTURES_FAILED:
      return state.set('failed', payload.error).set('loading', false)
    case ActionTypes.LOAD_LINKED_FIXTURES:
    default:
      return state
  }
}

export default reducer
