/**
 *
 * Notification Preloader
 *
 */

import React from 'react'
import ContentLoader from 'react-content-loader'
import uuid from 'uuid'

function NotificationPreloader() {
  const Loader = (props) => (
    <ContentLoader
      height={62}
      width={350}
      speed={2}
      primaryColor="#f3f3f3"
      secondaryColor="#ecebeb"
      {...props}
    >
      <rect x="20" y="10" rx="6" ry="6" width="180" height="10" />
      <rect x="20" y="25" rx="6" ry="6" width="180" height="10" />
      <rect x="20" y="40" rx="6" ry="6" width="180" height="10" />
      <rect x="225" y="10" rx="6" ry="6" width="100" height="10" />
      <rect x="225" y="25" rx="6" ry="6" width="100" height="10" />
      <rect x="225" y="40" rx="6" ry="6" width="100" height="10" />

      <rect x="0" y="60" rx="0" ry="0" width="350" height="1" />
    </ContentLoader>
  )
  const NotificationPreloader = () => (
    <React.Fragment>
      {Array(10)
        .fill('')
        .map(() => <Loader key={`loader_${uuid.v4()}`} />)}
    </React.Fragment>
  )

  return <NotificationPreloader />
}

export default NotificationPreloader
