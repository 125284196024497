import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import { notifyModal, closeModal, openModal } from '../App/actions'
import { makeSelectMtiPermissionAnyResource } from '../App/selectors'
import TemplatesListScreen from './index.screen'
import { loadTemplates, deleteTemplate } from './actions'
import {
  makeSelectTemplateFixtures,
  makeSelectTemplatesFailed,
  makeSelectTemplatesLoading,
} from './selectors'
import templatesSaga from './sagas'
import injectSaga from '../../utils/injectSaga'
import LinkedFixtures from './LinkedFixturesModal'
import FetchFailedAlert from '../../components/FetchFailed'
import DeleteTemplateWithLinkedFixtures from './DeleteTemplateWithLinkedFixtures'
import { orgUrl } from '../../utils/mtiUtils'
import { safeUri } from '../../utils/utils'

const permText = (text) =>
  `You don't have appropriate rights to access ${text}.\nPlease contact your store manager.`

class TemplatesList extends React.Component {
  componentDidMount() {
    const { hasViewTemplatesPermission, history } = this.props
    if (
      this.noPermission(hasViewTemplatesPermission, permText('View Templates'))
    ) {
      history.push('/')
      return
    }
    this.props.loadTemplates()
  }

  onTemplate = (template) => {
    this.props.history.push(
      `${orgUrl()}/templates/${template.id}/${safeUri(template.name)}/positions`
    )
  }

  onCreateTemplate = () => {
    const { hasCreateTemplatePermission } = this.props
    if (
      this.noPermission(
        hasCreateTemplatePermission,
        permText('Create Template')
      )
    ) {
      return
    }
    this.props.history.push(`${orgUrl()}/templates/create`)
  }

  onViewLinkedFixtures = (template) => {
    const { openModal: doOpenModal, closeModal: doCloseModal } = this.props
    doOpenModal({
      id: 'linked-fixtures',
      type: 'bootstrap',
      content: (
        <LinkedFixtures
          id={'linked-fixtures'}
          template={template}
          onClose={() => doCloseModal({ id: 'linked-fixtures' })}
        />
      ),
    })
  }

  onDeleteTemplate = (template) => {
    const { deleteTemplate: doDeleteTemplate } = this.props
    doDeleteTemplate(template)
  }

  onDeleteTemplateWithLinkedFixtures = (template) => {
    const { openModal: doOpenModal, closeModal: doCloseModal } = this.props
    const id = 'delete-linked-fixtures'
    doOpenModal({
      id: 'delete-linked-fixtures',
      type: 'bootstrap',
      appearance: 'modal-sm',
      content: (
        <DeleteTemplateWithLinkedFixtures
          id={id}
          template={template}
          onViewLinkedFixtures={this.onViewLinkedFixtures}
          onDeleteTemplate={this.onDeleteTemplate}
          onClose={() => doCloseModal({ id })}
        />
      ),
    })
  }

  noPermission = (hasPermission, text) => {
    const { notifyModal: doNotifyModal } = this.props
    if (!hasPermission) doNotifyModal(text)
    return !hasPermission
  }

  render() {
    const {
      templates,
      loading,
      failed,
      hasViewTemplatesPermission,
    } = this.props

    if (failed) return <FetchFailedAlert failed={failed} />
    const templatesChecked = hasViewTemplatesPermission ? templates : []

    return (
      <TemplatesListScreen
        loading={loading}
        templates={templatesChecked}
        onTemplate={this.onTemplate}
        onCreateTemplate={this.onCreateTemplate}
        onViewLinkedFixtures={this.onViewLinkedFixtures}
        onDeleteTemplate={this.onDeleteTemplateWithLinkedFixtures}
      />
    )
  }
}

TemplatesList.propTypes = {
  templates: PropTypes.array,
  loading: PropTypes.bool,
  failed: PropTypes.object,
  loadTemplates: PropTypes.func,
  deleteTemplate: PropTypes.func,
  notifyModal: PropTypes.func,
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
  history: PropTypes.object,
  hasCreateTemplatePermission: PropTypes.bool,
  hasViewTemplatesPermission: PropTypes.bool,
}

const mapStateToProps = createStructuredSelector({
  templates: makeSelectTemplateFixtures(),
  loading: makeSelectTemplatesLoading(),
  failed: makeSelectTemplatesFailed(),
  hasCreateTemplatePermission: makeSelectMtiPermissionAnyResource(
    'Template: Create New Objects'
  ),
  hasViewTemplatesPermission: makeSelectMtiPermissionAnyResource(
    'Template: Index (list) Records'
  ),
})

const mapDispatchToProps = {
  loadTemplates,
  notifyModal,
  openModal,
  closeModal,
  deleteTemplate,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const withSaga = injectSaga({ key: 'templates', saga: templatesSaga })

export default compose(withSaga, withConnect)(TemplatesList)
