import { all, call, put, takeLatest, select } from 'redux-saga/effects'
import {
  getFirmwares,
  postFirmware,
  deleteFirmware as deleteFirmwareRequest,
} from '../../../api'
import { makeSelectToken } from '../selectors'
import { ActionTypes } from '../constants'
import {
  fetchFirmwaresFailed,
  fetchFirmwaresPending,
  fetchFirmwaresFulfilled,
  uploadFirmwarePending,
  uploadFirmwareFulfilled,
  uploadFirmwareFailed,
  deleteFirmwarePending,
  deleteFirmwareFailed,
  deleteFirmwareFulfilled,
} from '../actions'
import { getBase64File, getFirmwareFolderName } from '../../../utils/utils'
import { makeSelectFirmwaresExist } from '../../FirmwaresPage/selectors'

function* loadFirmwares() {
  const token = yield select(makeSelectToken())
  try {
    const firmwaresExist = yield select(makeSelectFirmwaresExist())
    if (!firmwaresExist) {
      yield put(fetchFirmwaresPending())
      const { firmwareImages } = yield call(getFirmwares, token)
      const firmwares = firmwareImages.map((f) => ({ name: f }))
      yield put(fetchFirmwaresFulfilled(firmwares))
    }
  } catch (error) {
    yield put(fetchFirmwaresFailed(error))
  }
}

function* uploadFirmware({ payload: { firmware } }) {
  const token = yield select(makeSelectToken())
  try {
    yield put(uploadFirmwarePending())
    const base64Firmware = yield call(getBase64File, firmware)
    const folderName = yield call(getFirmwareFolderName, firmware.name)
    const name = `${folderName ? `${folderName}/` : ''}${firmware.name}`

    const { firmwareImages: [newFirmware] } = yield call(
      postFirmware,
      token,
      name,
      base64Firmware
    )
    yield put(uploadFirmwareFulfilled({ name: newFirmware }))
  } catch (error) {
    yield put(uploadFirmwareFailed(error))
  }
}

function* deleteFirmware({ payload: { firmware } }) {
  const token = yield select(makeSelectToken())
  try {
    yield put(deleteFirmwarePending())
    yield call(deleteFirmwareRequest, token, firmware.name)
    yield put(deleteFirmwareFulfilled(firmware))
  } catch (error) {
    yield put(deleteFirmwareFailed(error))
  }
}

// function* downloadFirmwareFile({payload: {name}}) {
// const filepath = `/${name}`
// const { firmwareImage: { filename, data } } = yield call(getFile, token, filepath)
// console.log('Downloaded: ', filename, data)
// }

export default function* root() {
  yield all([
    yield takeLatest(ActionTypes.LOAD_FIRMWARES, loadFirmwares),
    yield takeLatest(ActionTypes.UPLOAD_FIRMWARE, uploadFirmware),
    yield takeLatest(ActionTypes.DELETE_FIRMWARE, deleteFirmware),
  ])
}
