/*
 *
 * FloorsPage reducer
 *
 */

import { Map } from 'immutable'
import { ActionTypes } from './constants'

export const initialState = Map({
  currentStoreId: null,
  selectedObject: -1,
  canvasObject: {
    objects: [],
  },
  isStatic: true,
})

function reducer(state = initialState, { payload, type }) {
  switch (type) {
    case ActionTypes.POST_FLOORS_CANVAS_FINISHED:
      return state
        .set('loading', false)
        .set('failed', null)
        .set('isStatic', true)
        .update('canvasObject', (canvasObject) => {
          const os = canvasObject.objects
          const selectedObject = state.get('selectedObject')
          const o = os[selectedObject]
          if (selectedObject !== -1) {
            os[selectedObject] = { ...o, id: payload.fId || (o || {}).id }
          }
          return {
            ...canvasObject,
            objects: os,
            selectedId: -1,
            selectedIndex: -1,
          }
        })
        .set('selectedObject', -1)
    case ActionTypes.POST_FLOORS_CANVAS_PENDING:
      return state.set('failed', null).set('loading', true)
    case ActionTypes.POST_FLOORS_CANVAS_FAILED:
      return state.set('failed', payload).set('loading', false)
    case ActionTypes.FETCH_FLOORS_FULFILLED: {
      const {
        canvas: {
          canvasObject = {},
          canvasObject: { selectedIndex } = {},
        } = {},
        store,
        sObject,
        isStatic,
      } = payload
      const selectedObject =
        sObject === undefined || sObject === -1 ? selectedIndex : sObject
      return state
        .set('canvasObject', canvasObject)
        .set('selectedObject', selectedObject)
        .set('store', store)
        .set('loading', false)
        .set('failed', null)
        .set('isStatic', isStatic)
    }
    case ActionTypes.FETCH_FLOORS_PENDING:
      return state.set('failed', null).set('loading', true)
    case ActionTypes.FETCH_FLOORS_FAILED:
      return state.set('failed', payload).set('loading', false)
    case ActionTypes.FLOORS_CANVAS_CHANGED: {
      const isStaticPayload = payload.isStatic
      const prevSelectedIdx = state.get('selectedObject')
      const isStatic = !isStaticPayload
        ? isStaticPayload
        : state.get('isStatic')
      // Deselect already selected floor
      const selectedIdx =
        prevSelectedIdx === payload.selectedObject && isStatic
          ? -1
          : payload.selectedObject

      return state
        .set('canvasObject', payload.canvasObject)
        .set('selectedObject', selectedIdx)
        .set('isStatic', isStatic)
    }
    case ActionTypes.LOAD_FLOORS:
      return state.set('currentStoreId', payload.storeId)
    case ActionTypes.FLOORS_CANVAS_FLOOR_REMOVE:
      return state
        .update('canvasObject', (canvasObject) => {
          const os = canvasObject.objects
          return {
            ...canvasObject,
            objects: os.filter(
              (obj, index) => index !== payload.selectedObject
            ),
            selectedId: -1,
            selectedIndex: -1,
          }
        })
        .set('selectedObject', -1)
    case ActionTypes.FLOORS_CANVAS_FLOOR_CHANGED: {
      return state.update('canvasObject', (canvasObject) => {
        const os = canvasObject.objects
        const selectedObject = state.get('selectedObject')
        const o = os[selectedObject]
        os[selectedObject] = {
          ...o,
          name: payload.floorName,
          order: payload.floorOrder,
          image: payload.floorImage,
          shouldDisplayBackground: payload.floorShouldDisplayBackground,
        }
        return { ...canvasObject, objects: os }
      })
    }
    case ActionTypes.FLOORS_CANVAS_FLOOR_CREATE: {
      const selectedObject = state.get('canvasObject').objects.length
      return state
        .update('canvasObject', (canvasObject) => {
          const os = canvasObject.objects.concat([payload])
          return { ...canvasObject, objects: os }
        })
        .set('selectedObject', selectedObject)
        .set('isStatic', false)
    }
    default:
      return state
  }
}

export default reducer
