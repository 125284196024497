import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import EditDisabled from '../../../components/EditDisabled'
import Checkbox from '../../../components/Checkbox'
import Radio from '../../../components/Radio'
import Select from '../../../components/Select'
import {
  ModalBody,
  ColumnContainer,
} from '../../StoresPage/CreateEditStoreModal/index.screen'
import {
  CheckboxAndInputContainer,
  CheckboxContainer,
} from '../../TemplateFixture/index.screen'
import ProgressIndicatorModal from '../../../components/ProgressIndicatorModal'
import ErrorModal from '../../../components/ErrorModal'
import {
  ButtonsContainer,
  Button,
  DropDownButtonContainer,
} from '../StoresList/index.screen'
import { Icon } from '../PingHub/index.screen'
import { Dropdown } from '../../../components/Issue/DropdownButton'
import styles from '../../../components/Select/css/style.css'

import MoreIcon from '../../../images/svg/buttons/more.svg'
import RebootIcon from '../../../images/svg/buttons/reboot.svg'
import RestartIcon from '../../../images/svg/buttons/restart.svg'
import PingIcon from '../../../images/svg/buttons/ping.svg'
import CloseIcon from '../../../images/svg/buttons/close.svg'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`

const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  margin-left: 40px;
  height: ${(props) => props.height};
`

const HeaderButtonsContainer = styled.div`
  display: flex;
  flex: 3;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 8px;
`

const PingContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-around;
  align-items: center;
`

const PingStatus = styled.p`
  margin-bottom: 0px;
`

const SecondaryDeviceCheckboxContainer = styled.div`
  margin-top: 10px;
`

const HeaderTitle = styled.h5`
  flex: 5;
`

const OTAStatusContainerWithSeparator = styled.div`
  border-top: 1px solid #dee2e6 !important;
  padding-top: 20px;
`

class UpdateHubScreen extends React.Component {
  /* eslint-disable no-undef */
  onNodesUpdate = () => {
    this.onClose()
    this.props.onNodesUpdate()
  }

  onLodeUpdate = () => {
    this.onClose()
    this.props.onLodeUpdate()
  }

  onClose = () => {
    $(`#modal-${this.props.id}`).modal('hide') // eslint-disable-line
  }

  renderHubStatus = () => {
    const { hub: { otauStatus }, statusFetching, statusFetchError } = this.props

    if (statusFetching) {
      return <ProgressIndicatorModal text="Status Loading..." />
    }

    if (statusFetchError) {
      return <ErrorModal text={`Fetch Error: ${statusFetchError.message}`} />
    }
    if (otauStatus === false) {
      return <ErrorModal text="HUB is Offline" />
    }
    if (!otauStatus) return null

    return (
      <OTAStatusContainerWithSeparator>
        <pre>{otauStatus}</pre>
      </OTAStatusContainerWithSeparator>
    )
  }

  renderMainContent = () => {
    const {
      hub,
      inputData: {
        lodeVersion,
        partNumber,
        serialNumber,
        isLodeVersionEnabled,
        partNumberSelected,
        isSecondaryDeviceEnabled,
      },
      onChange,
    } = this.props

    return (
      <div className="row">
        <div className="col-xs-6 w-75" style={{ flex: 1 }}>
          <div className="form-row">
            <ColumnContainer className="form-group col-md-4 bmd-form-group">
              <EditDisabled header={'HUB ID'} text={hub.identifier} />
            </ColumnContainer>
            <ColumnContainer className="form-group col-md-4 bmd-form-group">
              <CheckboxAndInputContainer>
                <CheckboxContainer>
                  <Checkbox
                    name={'enableLodeVersion'}
                    checked={isLodeVersionEnabled}
                    onChange={({ target: { checked } }) => {
                      onChange({ isLodeVersionEnabled: checked })
                    }}
                  />
                </CheckboxContainer>
                <Container>
                  <label htmlFor="lodeVersion">
                    Input specific version of Lode
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="lodeVersion"
                    placeholder=""
                    required=""
                    disabled={!isLodeVersionEnabled}
                    defaultValue={lodeVersion}
                    onChange={({ target: { value } }) =>
                      onChange({ lodeVersion: value })
                    }
                  />
                </Container>
              </CheckboxAndInputContainer>
            </ColumnContainer>
            <ColumnContainer className="form-group col-md-4 bmd-form-group">
              <ButtonContainer height={'100%'}>
                <button
                  type="button"
                  className="btn btn-primary btn-raised"
                  style={{ width: '150px' }}
                  onClick={this.onLodeUpdate}
                  disabled={isLodeVersionEnabled ? !lodeVersion : false}
                >
                  Update Lode
                </button>
              </ButtonContainer>
            </ColumnContainer>
          </div>

          <div className="form-row">
            <ColumnContainer className="form-group col-md-4 bmd-form-group">
              <CheckboxAndInputContainer>
                <Radio
                  id="partNumberRadio"
                  name="partNumberRadio"
                  checked={partNumberSelected}
                  onChange={({ target: { checked } }) =>
                    onChange({ partNumberSelected: checked })
                  }
                />
                <Select
                  customStyle={styles.fixZeroPadding}
                  disabled={!partNumberSelected}
                  name="partNumberSelector"
                  label="Select Part Number"
                  value={partNumber}
                  onChange={({ target: { value } }) =>
                    onChange({ partNumber: value })
                  }
                >
                  <option value={'150-00006'}>GATEWAY</option>
                  <option value={'150-00011'}>FM PUCK</option>
                  <option value={'150-00116'}>DRAWER LOCK</option>
                  <option value={'150-00149'}>GLASS LOCK</option>
                  <option value={'150-00197'}>PLUNGER LOCK</option>
                  <option value={'150-00101'}>REPEATER</option>
                  <option value={'150-00132'}>UNIFIED KEY</option>
                  <option value={'150-00154'}>AP AM</option>
                  <option value={'150-00153'}>AP PUCK</option>
                  <option value={'150-00177'}>RAM</option>
                  <option value={'150-00106'}>SP POWER STRIP</option>
                  <option value={'150-00198'}>SP POWER STRIP 2</option>
                  <option value={'150-00179'}>SP RFID</option>
                  <option value={'150-00191'}>CX FLEX SINGLE PORT</option>
                </Select>
              </CheckboxAndInputContainer>
            </ColumnContainer>
            <ColumnContainer className="form-group col-md-4 bmd-form-group">
              <CheckboxAndInputContainer>
                <Radio
                  id="serialNumberRadio"
                  name="serialNumberRadio"
                  checked={!partNumberSelected}
                  onChange={({ target: { checked } }) =>
                    onChange({ partNumberSelected: !checked })
                  }
                />
                <Container>
                  <label htmlFor="serialNumber">Input Serial Number</label>
                  <input
                    type="text"
                    className="form-control"
                    id="serialNumber"
                    placeholder=""
                    required=""
                    disabled={partNumberSelected}
                    defaultValue={serialNumber}
                    onChange={({ target: { value } }) =>
                      onChange({ serialNumber: value })
                    }
                  />
                </Container>
              </CheckboxAndInputContainer>
              <SecondaryDeviceCheckboxContainer>
                <Checkbox
                  name={'secondaryDevice'}
                  disabled={partNumberSelected}
                  label={'Secondary Device'}
                  checked={isSecondaryDeviceEnabled}
                  onChange={({ target: { checked } }) =>
                    onChange({ isSecondaryDeviceEnabled: checked })
                  }
                />
              </SecondaryDeviceCheckboxContainer>
            </ColumnContainer>
            <ColumnContainer className="form-group col-md-4 bmd-form-group">
              <ButtonContainer height={'80%'}>
                <button
                  type="button"
                  className="btn btn-primary btn-raised"
                  style={{ width: '150px' }}
                  onClick={this.onNodesUpdate}
                  disabled={partNumberSelected ? !partNumber : !serialNumber}
                >
                  Update Nodes
                </button>
              </ButtonContainer>
            </ColumnContainer>
          </div>
          {this.renderHubStatus()}
        </div>
      </div>
    )
  }

  renderPingButtonAndStatus = () => {
    const { onPing, hub: { pingStatus } } = this.props
    /* eslint-disable no-nested-ternary */
    const pingStatusStr =
      typeof pingStatus === 'boolean'
        ? pingStatus ? 'Online' : 'Offline'
        : pingStatus

    return (
      <PingContainer>
        <Icon src={PingIcon} onClick={onPing} />
        <PingStatus>{pingStatusStr}</PingStatus>
      </PingContainer>
    )
  }

  renderMoreButton = () => {
    const { onReboot, onRestart } = this.props
    return (
      <Dropdown className="btn-group dropup">
        <DropDownButtonContainer
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <Icon src={MoreIcon} />
        </DropDownButtonContainer>
        <div
          className="dropdown-menu dropdown-menu-right"
          aria-labelledby="dropdownMenuButton"
        >
          <ButtonsContainer>
            <Button
              onClick={() => {
                this.onClose()
                onReboot()
              }}
            >
              <Icon src={RebootIcon} />
              Reboot
            </Button>
            <Button
              onClick={() => {
                this.onClose()
                onRestart()
              }}
            >
              <Icon src={RestartIcon} />
              Restart
            </Button>
          </ButtonsContainer>
        </div>
      </Dropdown>
    )
  }

  render() {
    const { selectedStore, onClose } = this.props

    return (
      <div className="modal-content">
        <div className="modal-header border-bottom">
          <HeaderTitle>{selectedStore.name}</HeaderTitle>
          <HeaderButtonsContainer>
            {this.renderPingButtonAndStatus()}
            {this.renderMoreButton()}
            <Icon
              src={CloseIcon}
              onClick={() => {
                this.onClose()
                onClose()
              }}
            />
          </HeaderButtonsContainer>
        </div>
        <div>
          <ModalBody className="modal-body">
            {this.renderMainContent()}
          </ModalBody>
        </div>
      </div>
    )
  }
}

UpdateHubScreen.propTypes = {
  id: PropTypes.string.isRequired,
  selectedStore: PropTypes.object.isRequired,
  hub: PropTypes.object.isRequired,
  inputData: PropTypes.object.isRequired,
  statusFetching: PropTypes.bool,
  statusFetchError: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onReboot: PropTypes.func.isRequired,
  onNodesUpdate: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onRestart: PropTypes.func.isRequired,
  onPing: PropTypes.func.isRequired,
  onLodeUpdate: PropTypes.func.isRequired,
}

export default UpdateHubScreen
