import React from 'react'
import PropTypes from 'prop-types'

class TrComponent extends React.Component {
  constructor() {
    super()
    this.state = {
      background: null,
    }
  }
  render() {
    const { children, className, style, ...rest } = this.props
    const { props: { children: { props: { loading } = {} } = {} } = {} } =
      children[3] || children[1] || {}
    const nextBgnColor = loading ? 'grey' : null
    return (
      <div
        ref={() => {
          if (this.state.background !== nextBgnColor) {
            this.setState({
              background: nextBgnColor,
            })
          }
        }}
        className={`rt-tr ${className}`}
        style={{
          ...style,
          ...this.state,
        }}
        {...rest}
      >
        {children}
      </div>
    )
  }
}

TrComponent.propTypes = {
  children: PropTypes.array,
  className: PropTypes.string,
  style: PropTypes.object,
}

export default TrComponent
