/*
 *
 * TemplatePositions selectors
 *
 */

import moment from 'moment'
import { createSelector } from 'reselect'
import { createSelector as createOrmSelector } from 'redux-orm'
import {
  orm,
  DeviceType,
  ArmState,
  PositionRuleStatus,
  HealthStatus,
} from 'mti-jsclient-shared'
import {
  selectFixtureById,
  addLoadingStateForRules,
  getRulesLoading,
} from '../FixturePage/selectors'
import { toFabricSync } from '../FixturePage/utils'
import { getAutoPlacementMax } from '../../utils/mtiUtils'

/**
 * Direct selector to the templatePositions state domain
 */
const selectTemplatePositionsDomain = (state) => state.get('templatePositions')
const dbStateSelector = (state) => state.get('orm')
const ormFixtureTemplateStateSelector = (state) => ({
  orm: state.get('orm'),
  fixture: state.get('templatePositions'),
})

const makeSelectFixtureTemplate = (templateId) =>
  createSelector(
    dbStateSelector,
    selectTemplatePositionsDomain,
    (ormState, tFixtureState) =>
      selectFixtureById(templateId)(ormState, tFixtureState)
  )

const makeSelectFixtureOrmed = () =>
  createSelector(ormFixtureTemplateStateSelector, (ormFixture) =>
    ormFixture.fixture.get('fixture')
  )

const makeSelectPrototypes = () =>
  createSelector(ormFixtureTemplateStateSelector, (ormFixture) =>
    ormFixture.fixture.get('prototypes')
  )

const makeSelectCanvasObject = (fxId) =>
  createSelector(ormFixtureTemplateStateSelector, (ormFixture) => {
    try {
      const isStatic = ormFixture.fixture.get('isStatic')

      if (!isStatic) {
        return ormFixture.fixture.get('canvasObject').toJS()
      }

      const prototypes = ormFixture.fixture.get('prototypes')
      const fixture = selectFixtureById(fxId)(ormFixture.orm)
      const screen = ormFixture.fixture.get('screen')
      const { canvasObject } = toFabricSync(
        fixture,
        screen,
        isStatic,
        -1,
        prototypes,
        {
          DeviceType,
          ArmState,
          PositionRuleStatus,
          HealthStatus,
        }
      )
      return canvasObject
    } catch (e) {
      return {}
    }
  })

const makeSelectSelectedPosition = () =>
  createSelector(ormFixtureTemplateStateSelector, (ormFixture) => {
    try {
      const canvasObject = ormFixture.fixture.get('canvasObject').toJS()
      const selectedId = ormFixture.fixture.get('selectedId')
      if (selectedId === -1 || selectedId === 0 || canvasObject.length < 1)
        return undefined

      return selectPositionById(selectedId)(ormFixture.orm, ormFixture.fixture)
    } catch (error) {
      return undefined
    }
  })

const selectPositionById = (positionId) =>
  createOrmSelector(orm, (session, fState) => {
    try {
      const positionModel = session.Position.withId(positionId)
      const rules = positionModel.ruleIds.toRefArray().map((r) => ({
        ...r,
        updatedAt: moment(r.updatedAt).format('MMM D YYYY hh:mm A'),
      }))
      const rulesLoading = getRulesLoading(fState)
      return {
        ...positionModel.ref,
        rules: addLoadingStateForRules(rules, rulesLoading),
      }
    } catch (error) {
      return undefined
    }
  })

const makeSelectCanvasSelectedId = () =>
  createSelector(selectTemplatePositionsDomain, (canvasState) =>
    canvasState.get('selectedId')
  )

const makeSelectPositionsByFixtureTemplate = (fxId) =>
  createSelector(dbStateSelector, (ormState) => {
    const fixture = selectFixtureById(fxId)(ormState)
    return (fixture || {}).positions
  })

const selectIsTemplateById = (templateId) =>
  createOrmSelector(orm, (session) => session.Fixture.hasId(templateId))

const makeSelectTemplateExist = (templateId) =>
  createSelector(dbStateSelector, (ormState) =>
    selectIsTemplateById(templateId)(ormState)
  )

const makeSelectFailed = () =>
  createSelector(selectTemplatePositionsDomain, (stores) =>
    stores.get('failed')
  )

const makeSelectLoading = () =>
  createSelector(selectTemplatePositionsDomain, (stores) =>
    stores.get('loading')
  )

const makeSelectIsStatic = () =>
  createSelector(selectTemplatePositionsDomain, (canvas) =>
    canvas.get('isStatic')
  )

const getCanvasData = (canvas) => {
  const selectedId = canvas.get('selectedId')
  const objects = canvas.get('canvasObject').get('objects')
  return { selectedId, objects }
}

const makeSelectPositionName = () =>
  createSelector(selectTemplatePositionsDomain, (canvas) => {
    const { selectedId, objects } = getCanvasData(canvas)
    if (selectedId === -1 || selectedId === 0 || !objects.count()) return ''
    const p = objects.find((i) => i.get('id') === selectedId)
    return p ? p.get('name') : ''
  })

const makeSelectPositionCount = () =>
  createSelector(selectTemplatePositionsDomain, (canvas) => {
    const canvasObject = canvas.get('canvasObject')
    if (!canvasObject) return 0
    const objects = canvasObject.get('objects')
    if (!objects) return 0
    const positionCount = objects.toJS().length - 1 // 0 object is fixture at background
    return positionCount
  })

const makeSelectAutoPlacementMax = () =>
  createSelector(selectTemplatePositionsDomain, (canvas) => {
    const canvasObject = canvas.get('canvasObject')
    if (!canvasObject) return 0
    const objects = canvasObject.get('objects')
    if (!objects || objects.toJS().length === 0) return 0
    return getAutoPlacementMax(objects.toJS()[0].mtiType) // 0 object is fixture at background
  })

export {
  makeSelectFailed,
  makeSelectLoading,
  makeSelectFixtureTemplate,
  makeSelectCanvasObject,
  makeSelectCanvasSelectedId,
  makeSelectPositionsByFixtureTemplate,
  makeSelectIsStatic,
  makeSelectPositionName,
  makeSelectPositionCount,
  makeSelectAutoPlacementMax,
  makeSelectTemplateExist,
  makeSelectSelectedPosition,
  makeSelectFixtureOrmed,
  makeSelectPrototypes,
}
