/**
 *
 * SidebarSection
 *
 */

import styled from 'styled-components'

export const SidebarSection = styled.section`
  padding: 25px;
  text-align: left;
`

export const SidebarInputSection = styled(SidebarSection)`
  padding: 0 25px;
`

export default SidebarSection
