/* This file contains the data handling for "assignable roles." Since the saga and reducers
 * are very small, it makes sense to put it all in one place. It might make more sense to break
 * it out if it gets bigger or more complicated.
 *
 * Example 1: Getting the saga.
 * import { AssignableRole } from '../../components/AssignableRoles'
 * const withAssignableRolesSaga = injectSaga({
 *    key: 'assignableRoles',
 *    saga: AssignableRole.saga
 * })
 *
 * Example 2: Getting the reducer.
 * import { AssignableRole } from '../../components/AssignableRoles'
 * const withAssignableRolesReducer = injectReducer({
 *    key: 'assignableRoles',
 *    saga: AssignableRole.reducer
 * })
 */
import { Record, List, Seq } from 'immutable'
import { takeLatest, select, put, call } from 'redux-saga/effects'
import { tokenSelector } from '../../containers/App/selectors'
import { getAssignableRoles } from '../../api'

/**
 * Represents a simplified resource or "location" for an assignable
 * role. A resource might be an Organization, a Region, or a Store.
 * Its type is implied by the persona itself, so each list contains
 * only objects of a single type. As long as we don't mix lists,
 * it isn't necessary to track the resource type.
 */
class RoleResource extends (new Record({
  id: null,
  name: null,
})) {
  static fromAPI(objJs) {
    const { id, name } = objJs
    return new RoleResource({ id, name })
  }
  static listFromAPI(objJs) {
    return List(objJs.map((js) => RoleResource.fromAPI(js)))
  }
}

const AssignableRolesState = new Record({
  status: 'PENDING',
  list: List(),
  message: '',
})

/**
 * Represents one of the roles that the logged-in user can assign
 * to another user. The name is an internal name, but the
 * humanize() method uses a simple heuristic to make it look like
 * text. No I18n.
 *
 * The rank member is used for sorting. Higher ranks should be
 * listed before lower ranks.
 *
 * This class also encapsulates the saga and reducer methods
 * for the sake of giving them a namespace.
 */
class AssignableRole extends (new Record({
  id: null,
  key: null,
  name: null,
  rank: 0,
  resourceType: null,
  resources: List(),
})) {
  /** Turn a JSON structure into an AssignableRole
   * instance.
   */
  static fromAPI(jsObj) {
    return new AssignableRole({
      id: jsObj.id,
      key: jsObj.key,
      name: jsObj.name,
      rank: jsObj.rank,
      resourceType: jsObj.resourceType,
      resources: RoleResource.listFromAPI(jsObj.resources),
    })
  }

  /** @param js
   *   Example:
   *   [
   *     {
   *       "id": 1,
   *       "key": "organization_administrator",
   *       "name": "Organization Administrator",
   *       "rank": 4,
   *       "resourceType": "Organization",
   *       "resources": [{
   *         "id": 1,
   *         "name": "Ultra L.L.C."
   *       }]
   *     },
   *     {
   *       "id": 3,
   *       "key": "store_manager",
   *       "name": "Store Manager",
   *       "rank": 2,
   *       "resourceType": "Store"
   *       "resources": [
   *         {
   *           "id": 1,
   *           "name": "Kanto Store"
   *         },
   *         {
   *           "id": 2,
   *           "name": "Johto Store"
   *         }
   *       ]
   *     }
   *   }
   */
  static listFromAPI(js) {
    return Seq(js)
      .map((entry) => AssignableRole.fromAPI(entry))
      .sortBy((entry) => entry.rank)
      .reverse()
      .toList()
  }

  /** A very simple reducer that manages a PENDING, FAILED and FULFILLED
   * state, and keeps an immutable list of results.
   */
  static reducer(state = AssignableRolesState(), { type, payload }) {
    const acts = AssignableRole.actions
    switch (type) {
      case acts.ASSIGNABLE_ROLES_PENDING:
        return state
          .set('list', List())
          .set('message', '')
          .set('status', 'PENDING')
      case acts.ASSIGNABLE_ROLES_FAILED:
        return state
          .set('list', List())
          .set('status', 'FAILED')
          .set('message', payload)
      case acts.ASSIGNABLE_ROLES_FULFILLED:
        return state
          .set('status', 'FULFILLED')
          .set('message', '')
          .set('list', AssignableRole.listFromAPI(payload))
      default:
        return state
    }
  }

  /** An extremely simple saga that just issues the API call and
   * puts the results.
   */
  static *loadSaga() {
    yield put(AssignableRole.actionMakers.pending())

    const token = yield select(tokenSelector)
    try {
      const response = yield call(getAssignableRoles, token)
      yield put(AssignableRole.actionMakers.fulfilled(response))
    } catch (error) {
      yield put(AssignableRole.actionMakers.failed(error.toString()))
    }
  }

  /** Adds a listener for the single LOAD action.
   */
  static *saga() {
    yield takeLatest(
      AssignableRole.actions.ASSIGNABLE_ROLES_LOAD,
      AssignableRole.loadSaga
    )
  }
}

/** Action Constants. Pin them to the namespace for easy reference. */
const acts = (AssignableRole.actions = {
  ASSIGNABLE_ROLES_LOAD: 'ASSIGNABLE_ROLES_LOAD',
  ASSIGNABLE_ROLES_PENDING: 'ASSIGNABLE_ROLES_PENDING',
  ASSIGNABLE_ROLES_FAILED: 'ASSIGNABLE_ROLES_FAILED',
  ASSIGNABLE_ROLES_FULFILLED: 'ASSIGNABLE_ROLES_FULFILLED',
})

/** Simple Action Makers. Pin them to the namespace for easy reference. */
AssignableRole.actionMakers = {
  load: () => ({ type: acts.ASSIGNABLE_ROLES_LOAD, payload: null }),
  pending: () => ({ type: acts.ASSIGNABLE_ROLES_PENDING, payload: null }),
  failed: (message) => ({
    type: acts.ASSIGNABLE_ROLES_FAILED,
    payload: message,
  }),
  fulfilled: (list) => ({
    type: acts.ASSIGNABLE_ROLES_FULFILLED,
    payload: list,
  }),
}

export default AssignableRole
