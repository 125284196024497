import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import Highlighter from 'react-highlight-words'
import styles from '../../../styles/App.css'
import Radio from '../../../components/Radio'
import { fullTextFilter } from '../../../utils/utils'
import {
  renderFilterInput,
  renderHeaderCell,
} from '../../UsersPage/index.screen'
import { TableInModal } from '../../../styles/styled'
import Checkbox from '../../../components/Switch'
import { friendlyDeviceType } from '../../../utils/mtiUtils'

export const ModalBody = styled.div`
  padding: 10px 10px 10px 10px;
`

export const ModalBodyPure = styled.div`
  padding: 0 !important;
`

const processDevicesData = (devices) =>
  devices.map((device) => {
    const isPositionAssigned = !!device.positionId
    let isParentAssigned = false
    let isBaseAssigned = false

    if (device.parentId) {
      const parent = devices.find((item) => item.id === device.parentId)
      isParentAssigned = !!(parent && parent.positionId)
    }

    if (device.baseId) {
      const base = devices.find((item) => item.id === device.baseId)
      isBaseAssigned = !!(base && base.positionId)
    }

    return {
      ...device,
      isPositionAssigned,
      isParentAssigned,
      isBaseAssigned,
      assigned: isPositionAssigned || isParentAssigned || isBaseAssigned,
    }
  })

const filterUnassigned = (devices, showUnassigned) => {
  if (showUnassigned) {
    return devices.filter(({ assigned }) => !assigned && devices)
  } else {
    return devices
  }
}

/* eslint-disable react/prop-types, no-underscore-dangle */
const renderMainContent = (
  selectedDevice,
  existingDevice,
  devices,
  onSelection,
  onIdentify,
  showUnassigned
) => {
  const selectedIds = selectedDevice.map(({ id }) => id)
  const existingIds = existingDevice.map(({ id }) => id)
  const columns = [
    {
      id: 'assign',
      width: 75,
      style: { justifyContent: 'center' },
      accessor: (d) => existingIds && !existingIds.includes(d.id) && d.assigned,
      Cell: ({ original: { id, assigned }, original }) => (
        <Radio
          id={`device-${id}`}
          name={'security-device'}
          onChange={() => onSelection({ selectedDevice: original })}
          checked={selectedIds && selectedIds.includes(id)}
          disabled={selectedIds && !selectedIds.includes(id) && assigned}
        />
      ),
    },
    {
      id: 'deviceType',
      Header: renderHeaderCell('Device type'),
      accessor: ({ deviceType }) => friendlyDeviceType(deviceType),
      width: 125,
      filterable: true,
      Filter: ({ filter, onChange }) =>
        renderFilterInput({ placeholder: 'Filter', filter, onChange }),
      Cell: ({ original }) => {
        const { deviceType } = original
        return <span>{friendlyDeviceType(deviceType)}</span>
      },
    },
    {
      Header: renderHeaderCell('Serial number', true),
      accessor: 'serialNumber',
      filterable: true,
      Cell: ({ original }) => {
        const { serialNumber } = original
        return (
          <Highlighter
            highlightClassName={styles.highlight}
            searchWords={['.{8}$']}
            textToHighlight={serialNumber || ''}
          />
        )
      },
      Filter: ({ filter, onChange }) =>
        renderFilterInput({ placeholder: 'Filter', filter, onChange }),
    },
    {
      Header: 'Identify',
      sortable: false,
      width: 75,
      Cell: ({ original }) => (
        <Link
          to={'#'}
          onClick={() => onIdentify(original)}
          style={{ color: '#04a9f4' }}
        >
          Identify
        </Link>
      ),
    },
  ]

  return (
    <TableInModal
      className="full-screen"
      data={filterUnassigned(processDevicesData(devices), showUnassigned)}
      columns={columns}
      pageSizeOptions={[5, 10, 20, 25, 50, 100]}
      defaultPageSize={25}
      defaultFilterMethod={fullTextFilter}
      defaultSorted={[
        {
          id: 'deviceType',
          desc: false,
        },
      ]}
    />
  )
}

const SecurityDeviceScreen = ({
  id,
  devices,
  selectedDevice,
  existingDevice,
  onConfirm,
  onUnassign,
  onClose,
  onSelection,
  onIdentify,
  onShowUnassignedChanged,
  showUnassigned,
}) => (
  <div className="modal-content">
    <div className="modal-header border-bottom">
      <h5 className="modal-title" id="exampleModalLongTitle">
        Select Security Device
      </h5>
      <div
        style={{
          flex: 1,
          textAlign: 'right',
          margin: '5px',
          marginRight: '10px',
        }}
      >
        Show all devices
      </div>
      <div>
        <Checkbox
          id={'assignedDevices'}
          label={'Show all devices'}
          checked={!showUnassigned}
          onChange={onShowUnassignedChanged}
        />
        <button
          type="button"
          className="btn btn-primary"
          style={{ marginBottom: '20px' }}
          onClick={() => {
            $(`#modal-${id}`).modal('hide') // eslint-disable-line
            onClose()
          }}
        >
          Cancel
        </button>
        {!_.isEmpty((existingDevice || [{}])[0]) && (
          <button
            type="button"
            className="btn btn-raised mr-2"
            style={{ marginBottom: '20px' }}
            onClick={() => {
              $(`#modal-${id}`).modal('hide') // eslint-disable-line
              onUnassign()
            }}
          >
            Unassign
          </button>
        )}
        <button
          type="button"
          className="btn btn-raised"
          style={{ marginBottom: '20px' }}
          onClick={() => {
            $(`#modal-${id}`).modal('hide') // eslint-disable-line
            onConfirm()
          }}
        >
          Assign
        </button>
      </div>
    </div>
    <ModalBodyPure className="modal-body">
      <div className={styles.modalContentWrapper}>
        {renderMainContent(
          selectedDevice,
          existingDevice,
          devices,
          onSelection,
          onIdentify,
          showUnassigned
        )}
      </div>
    </ModalBodyPure>
  </div>
)

SecurityDeviceScreen.propTypes = {
  id: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onUnassign: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSelection: PropTypes.func.isRequired,
  onIdentify: PropTypes.func.isRequired,
  devices: PropTypes.array,
  selectedDevice: PropTypes.array,
  existingDevice: PropTypes.array,
}

export default SecurityDeviceScreen
