/*
 *
 * FloorsPage constants
 *
 */

import keyMirror from 'fbjs/lib/keyMirror'

/**
 * @constant {Object} ActionTypes
 * @memberof Constants
 */
export const ActionTypes = keyMirror({
  STORE_PARAMS: undefined,
  POST_FLOORS_CANVAS_FAILED: undefined,
  POST_FLOORS_CANVAS_FINISHED: undefined,
  POST_FLOORS_CANVAS_PENDING: undefined,
  FETCH_FLOORS_FAILED: undefined,
  FETCH_FLOORS_FULFILLED: undefined,
  FETCH_FLOORS_PENDING: undefined,
  // LOAD_FLOORS_CANVAS: undefined,
  LOAD_FLOORS: undefined,
  SAVE_FLOORS_CANVAS: undefined,
  SAVE_FLOORS_FLOOR: undefined,
  FLOORS_CANVAS_CHANGED: undefined,
  FLOORS_CANVAS_FLOOR_REMOVE: undefined,
  FLOORS_CANVAS_FLOOR_CHANGED: undefined,
  FLOORS_CANVAS_FLOOR_CREATE: undefined,
  FLOORS_CANVAS_FLOOR_IMAGE_UPLOAD: undefined,
})
