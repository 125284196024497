import { fabric } from 'fabric'
import { textSize } from 'utils/screen'
const canvas = new fabric.Canvas()
const context = canvas.getContext('2d')

/**
 * Mutating util function used for preventing the inner figures resizing
 * when user scales group object
 *
 * @param group
 * @param objects
 * @param inverted
 */
export const resizeToScale = (group, objects = [], inverted = false) => {
  const xKey = inverted ? 'scaleY' : 'scaleX'
  const yKey = inverted ? 'scaleX' : 'scaleY'

  objects.forEach((obj) => {
    if (group.scaleX < 1) {
      obj.set({ [xKey]: 1 + (1 - group.scaleX) })
    } else {
      obj.set({ [xKey]: 1 / group.scaleX })
    }

    if (group.scaleY < 1) {
      obj.set({ [yKey]: 1 + (1 - group.scaleY) })
    } else {
      obj.set({ [yKey]: 1 / group.scaleY })
    }
  })
}

/**
 * Mutating util function used for keeping origin object size inside a group
 *
 * @param group
 * @param objects
 * @param inverted
 */
export const keepOriginSize = (group, objects = [], inverted = false) => {
  const scaleX = group.width / (group.width * group.scaleX)
  const scaleY = group.height / (group.height * group.scaleY)

  objects.forEach((obj) => {
    obj.set({
      scaleX: inverted ? scaleY : scaleX,
      scaleY: inverted ? scaleX : scaleY,
    })
  })
}

/**
 * Mutating function for getting proportional font-size based on parent group object
 *
 * @param group
 * @param textObj
 * @param baseFontSize
 */
export const resizeFontToScale = (group, textObj, baseFontSize) => {
  resizeToScale(group, [textObj])
  textObj.set({
    fontSize: baseFontSize * Math.min(group.scaleY, group.scaleX),
  })
}

/**
 * Mutating function for the proportional scaling of object inside a group
 *
 * @param group
 * @param obj
 */
export const resizeImgToScale = (group, obj) => {
  const hRatio = obj.width / obj.height
  const wRatio = obj.height / obj.width
  const groupH = group.height * group.scaleY
  const groupW = group.width * group.scaleX

  let oW = obj.width * group.scaleX
  let oH = obj.height * group.scaleY

  // FIXME: scale twitching
  if (groupH > groupW) {
    oH = oW * wRatio
  } else {
    oW = oH * hRatio
  }

  const scaleY = oH / (obj.height * group.scaleY)
  const scaleX = oW / (obj.width * group.scaleX)

  obj.set({ scaleX, scaleY })
}

/**
 * Get truncated text length and box size
 * based on actual text length and container width
 *
 * @param text
 * @param width
 * @param minLabelW
 * @param padding
 * @returns {{ textLength, textBoxWidth: number }}
 */
export const getTruncatedTextParams = (
  text = '',
  width,
  minLabelW = 10,
  padding = 0
) => {
  const textFamily = 'Arial'
  context.font = `${textSize}px ${textFamily}`
  const textWidth = context.measureText(text).width
  let textBoxWidth = textWidth + padding * 2
  let textLength = text.length

  if (textBoxWidth > width && textBoxWidth > minLabelW) {
    textBoxWidth = width - 2 * padding
  }

  if (textBoxWidth < minLabelW) {
    textBoxWidth = minLabelW
  }

  const factor = textBoxWidth / textWidth
  textLength = Math.floor(textLength * factor) - 2

  return {
    textLength,
    textBoxWidth,
    textSize,
    textFamily,
  }
}

/**
 * Mutating function to keep the stroke size the same
 * when user scales group object
 *
 * @param e group object
 */
export const resizeStrokeToScale = (e) => {
  /* eslint-disable no-underscore-dangle */
  const myObject = e.target

  if (!myObject._objects) {
    return
  }

  myObject._objects.forEach((obj) => {
    obj.set({ strokeWidth: 2 / Math.sqrt(myObject.scaleX * myObject.scaleY) })
  })
  /* eslint-enable no-underscore-dangle */
}

/**
 * Set minimum allowed size for the objects
 *
 * @param objects
 * @param minW
 * @param minH
 */
export const setMinSize = (objects = [], minW, minH) => {
  objects.forEach((obj) => {
    if (obj.width < minW) {
      obj.set({ width: minW })
    }

    if (obj.height < minH) {
      obj.set({ height: minH })
    }
  })
}

/**
 * Set maximum allowed size for the object
 *
 * @param objects
 * @param maxW
 * @param maxH
 */
export const setMaxSize = (objects = [], maxW, maxH) => {
  objects.forEach((obj) => {
    if (obj.width > maxW) {
      obj.set({ width: maxW })
    }

    if (obj.height > maxH) {
      obj.set({ height: maxH })
    }
  })
}

/**
 * Truncate a stri...
 *
 * @param n length
 * @param str
 * @returns {string}
 */
export const truncate = (n, str) =>
  str.substr(0, n - 1) + (str.length >= n ? '…' : '')

export function getMaxSize(width, height, canvasWidth, canvasHeight) {
  let fixedWidth = width
  let fixedHeight = height
  const maxWidth = width > height ? canvasWidth : canvasWidth / 2
  const maxHeight = width > height ? canvasHeight / 2 : canvasHeight
  if (!isNaN(maxWidth) && width > maxWidth) {
    fixedWidth = maxWidth
  }
  if (!isNaN(maxHeight) && height > maxHeight) {
    fixedHeight = maxHeight
  }
  return {
    width: fixedWidth,
    height: fixedHeight,
  }
}

/**
 * Apply Max Min Scale for the group
 *
 * @param group
 * @param actualWidth
 * @param actualHeight
 * @param maxW
 * @param maxH
 * @param minW
 * @param minH
 */
export function applyMaxMinScale(
  group,
  actualWidth,
  actualHeight,
  maxW,
  maxH,
  minW,
  minH
) {
  if (!isNaN(maxW) && actualWidth > maxW) {
    // dividing maxW by the group.width gives us our 'max scale'
    group.set({
      scaleX: maxW / group.width,
    })
    group.lockScalingX = true
  }
  if (!isNaN(maxH) && actualHeight > maxH) {
    group.set({
      scaleY: maxH / group.height,
    })
    group.lockScalingY = true
  }
  if (actualWidth < minW) {
    setMinSize([group, group.item(0)], minW, minH)
    group.lockScalingX = true
  }
  if (actualHeight < minH) {
    setMinSize([group, group.item(0)], minW, minH)
    group.lockScalingY = true
  }
}
