/*
 * Reports reducer
 */
import { fromJS, Map } from 'immutable'
import { ActionTypes } from './constants'

const initialState = fromJS({
  reports: Map(),
  errors: Map(),
})

function reducer(state = initialState, { payload, type }) {
  switch (type) {
    case ActionTypes.FETCH_REPORTS_FULFILLED: {
      const { storeId, reports } = payload
      return state.set(
        'reports',
        state.get('reports').set((storeId || -1).toString(), reports)
      )
    }
    case ActionTypes.FETCH_REPORTS_FAILED:
      return state.set(
        'errors',
        state.get('errors').set((payload.storeId || -1).toString(), payload)
      )
    case ActionTypes.LOAD_REPORTS_ASYNC_COUNT:
      return state.set('storesToLoadCount', payload)
    case ActionTypes.LOAD_REPORTS_ASYNC_CURRENT:
      return state
        .set('currentStoreLoading', payload.store)
        .set('currentIndexLoading', payload.index)
    case ActionTypes.LOAD_REPORTS_ASYNC_FINISHED:
      return state
        .set('currentStoreLoading', null)
        .set('storesToLoadCount', 0)
        .set('currentIndexLoading', 0)
    case ActionTypes.LOAD_REPORTS_ASYNC_CANCEL:
      return state.set('asyncLoadingCanceling', true)
    case ActionTypes.REPORTS_CLEAR:
      return state
        .set('reports', Map())
        .set('asyncLoadingCanceling', false)
        .set('errors', Map())
    default:
      return state
  }
}

export default reducer
