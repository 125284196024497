import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import {
  makeSelectMtiPermissionAnyResource,
  selectIsEditingUser,
} from '../App/selectors'
import {
  notifyModal,
  userDetailsAction,
  createUserAction,
  changeUserStatusAction,
} from '../App/actions'
import { loadUsers } from './actions'
import {
  usersSelector,
  failedSelector,
  loadingSelector,
  metaSelector,
} from './selectors'
import { selectStoreIDFromURL } from '../../utils/utils'
import UsersPageScreen from './index.screen'
import ProgressIndicator from '../../components/ProgressIndicator'
import UsersListForHomePage from './usersListForHomePage.screen'
import UsersPageModalScreen from './index.modal.screen'
import UserManagement from './userManagement'
import FetchFailedAlert from '../../components/FetchFailed'
import Throttler from '../../utils/throttler'

const permText = text => `You don't have appropriate rights to access ${text}.\nPlease contact your store manager.`
const apiThrottleTimeout = 500

export class UsersPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showInactiveUsers: false,
      query: {
        per: 20,
        page: 1,
        attribute: null,
        direction: null,
        filters: {
          store_id: selectStoreIDFromURL() || undefined,
        },
      },
    }
    this.apiThrottler = new Throttler(
      newQuery => {
        const { showInactiveUsers, query } = this.state
        this.props.loadUsers(showInactiveUsers, newQuery || query)
      },
      apiThrottleTimeout
    )
  }

  componentWillMount() {
    const { userIndexListRecords, history } = this.props
    if (this.noPermission(userIndexListRecords, permText('View Users'))) {
      history.push('/')
      return
    }
    this.loadUsers()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isEditingUser && !this.props.isEditingUser) {
      // if the user finished creating or editing an existing user, then refresh the list
      this.loadUsers()
    }
  }

  loadUsers(query) {
    this.apiThrottler.call(query)
  }

  /* eslint-disable no-undef */
  noPermission = (hasPermission, text) => {
    const { notifyModal: doNotifyModal } = this.props
    if (!hasPermission) doNotifyModal(text)
    return !hasPermission
  }

  showInactiveUsers(shouldShow) {
    this.setState({ showInactiveUsers: shouldShow }, this.loadUsers)
  }

  handleFetchData = parameters => {
    const {
      page,
      pageSize,
      sortAttribute,
      sortDirection,
    } = parameters

    let filters = parameters.filters
    const storeID = selectStoreIDFromURL()
    filters.store_id = filters.store_id || (storeID ? storeID : undefined)

    const payload = {
      page,
      per: pageSize,
      attribute: sortAttribute,
      direction: sortDirection,
      filters,
    }
    this.props.loadUsers(this.state.showInactiveUsers, payload)
  }

  render() {
    const {
      users,
      meta,
      loading,
      failed,
      isHomePage,
      height,
      isModalUserSelect,
      onAssignSelectedUser,
      changeUserStatusAction,
      onClose,
      id,
      userCreateNewObjects,
      userIndexListRecords,
      createUserAction: doCreateUserAction,
      userDetailsAction: doUserDetailsAction,
    } = this.props

    if (loading && isHomePage) return <ProgressIndicator text={'Loading...'} />
    if (failed) return <FetchFailedAlert failed={failed} />
    const usersChecked = userIndexListRecords ? (users || []) : []

    if (isHomePage) {
      return (
        <div>
          <UserManagement />
          <UsersListForHomePage
            users={usersChecked}
            height={height}
            onUser={user => doUserDetailsAction(user)}
          />
        </div>
      )
    }

    if (isModalUserSelect) {
      return (
        <UsersPageModalScreen
          loading={loading}
          id={id}
          users={usersChecked}
          meta={meta}
          onUser={user => onAssignSelectedUser(user)}
          onClose={() => onClose()}
          onFetchData={this.handleFetchData}
        />
      )
    }

    return (
    <div>
      <UserManagement />
      <UsersPageScreen
        loading={loading}
        users={usersChecked}
        meta={meta}
        isAddUserLocked={!userCreateNewObjects}
        onUser={user => doUserDetailsAction(user)}
        changeUserStatus={(user, newStatus) => changeUserStatusAction(user, newStatus, this.state.showInactiveUsers)}
        onCreateUser={() => doCreateUserAction()}
        showInactiveUsers={this.state.showInactiveUsers}
        onShowInactiveUsersToggled={shouldShow => this.showInactiveUsers(shouldShow)}
        onFetchData={this.handleFetchData}
      />
    </div>
    )
  }
}

UsersPage.propTypes = {
  match: PropTypes.object,
  loadUsers: PropTypes.func.isRequired,
  isHomePage: PropTypes.bool,
  height: PropTypes.number,
  users: PropTypes.array,
  loading: PropTypes.bool,
  failed: PropTypes.object,
  meta: PropTypes.object,
  notifyModal: PropTypes.func,
  userDetailsAction: PropTypes.func,
  changeUserStatusAction: PropTypes.func,
  createUserAction: PropTypes.func,
  isModalUserSelect: PropTypes.bool,
  onAssignSelectedUser: PropTypes.func,
  onClose: PropTypes.func,
  id: PropTypes.string,
  userCreateNewObjects: PropTypes.bool,
  userIndexListRecords: PropTypes.bool,
  isEditingUser: PropTypes.bool,
}

const mapStateToProps = createStructuredSelector({
  users: usersSelector,
  loading: loadingSelector,
  failed: failedSelector,
  isEditingUser: selectIsEditingUser,
  meta: metaSelector,
  userCreateNewObjects: makeSelectMtiPermissionAnyResource(
    'User: Create New Objects'
  ),
  userIndexListRecords: makeSelectMtiPermissionAnyResource(
    'User: Index (list) Records'
  ),
})

const mapDispatchToProps = {
  loadUsers,
  notifyModal,
  userDetailsAction,
  createUserAction,
  changeUserStatusAction,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect)(UsersPage)
