import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { dottedBackground } from '../../styles/styled'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 100%;
  ${(props) =>
    props.disabled
      ? `
  border-bottom-color: transparent;
  ${dottedBackground}`
      : 'border-bottom: 1px solid #bbb;'};
`
export const Column = styled.div`
  display: flex;
  flex-direction: column;
`

export const Left = styled.div`
  flex: 1;
`

export const Right = styled.div``

const BorderLabel = styled.label`
  width: ${(props) => (props.labelWidth ? `${props.labelWidth}px` : '100%')};
  min-height: 26px;
  display: inline-block;
  color: #000;
  margin: 5px 0 0 !important;
  line-height: 26px !important;
  text-decoration: none;
  &::before {
    content: '\00a0';
  }
`

const BorderLabelGrey = styled.label`
  width: 100%;
  min-height: 26px;
  display: inline-block;
  color: #888;
  margin: 5px 0 !important;
  line-height: 26px !important;
  text-decoration: none;
  &::before {
    content: '\00a0';
  }
`

const defaultAdornment = ({ onClick, text, className }) => {
  const defaultClass = 'btn btn-primary btn-raised'
  return (
    <button
      type="button"
      className={`${defaultClass} ${className}`}
      onClick={onClick}
    >
      {text || 'OK'}
    </button>
  )
}

export const EditDisabled = ({ header, text, grey, labelWidth }) => {
  const greyLabel = () => {
    if (labelWidth) {
      const Label = styled(BorderLabelGrey)`
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: ${(props) => `${props.labelWidth}px`};
      `

      return (
        <Label className="text-truncate" labelWidth={labelWidth} id={text}>
          {text}
        </Label>
      )
    }

    return <BorderLabelGrey id={text}>{text}</BorderLabelGrey>
  }

  return (
    <Column>
      {header && <label htmlFor={header}>{header}</label>}
      {grey ? (
        greyLabel()
      ) : (
        <BorderLabel
          className={labelWidth ? 'text-truncate' : ''}
          labelWidth={labelWidth}
          id={text}
        >
          {text}
        </BorderLabel>
      )}
    </Column>
  )
}

EditDisabled.propTypes = {
  header: PropTypes.string,
  text: PropTypes.string.isRequired,
  grey: PropTypes.bool,
  labelWidth: PropTypes.number,
}

const GroupAdornment = ({ children, Adornment, AdornmentProps, disabled }) => {
  const adornment = () => {
    if (Adornment) {
      return Adornment
    }
    const { onClick, text, className } = AdornmentProps || {}
    return defaultAdornment({ onClick, text, className })
  }

  return (
    <Container disabled={disabled}>
      <Left>{children}</Left>
      <Right>{adornment()}</Right>
    </Container>
  )
}

GroupAdornment.propTypes = {
  children: PropTypes.node,
  Adornment: PropTypes.node,
  AdornmentProps: PropTypes.object,
  disabled: PropTypes.bool,
}

export default GroupAdornment
