/**
 *
 * FloorTile
 *
 */

import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { alertTypes } from '../../utils/mtiCanvasUtils'
import { getAreaColor } from '../FloorCanvas/area'
import circleAlarmHC from '../../images/svg/positions/Circle-Alarm-and-health-and-compliance.svg'
import circleUnlockedHC from '../../images/svg/positions/Circle-Unlocked-Health-and-compliance.svg'
import circleHC from '../../images/svg/positions/Circle-Health-and-compliance.svg'

const Tile = styled.div`
  display: flex;
  flex-direction: column;
  width: 216px;
  height: 162px;
  border: 1px solid rgba(0, 0, 0, 0.24);
  background-color: ${(props) => `${props.weakColor}`};
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.24);
  cursor: pointer;
  flex-shrink: 0;
  outline: ${(props) =>
    props.selected ? `4px solid ${props.mainColor}` : 'none'};
`

const FloorPreview = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 100%;
  height: 124px;
  background-image: url('${(props) => props.preview || undefined}');
  background-repeat: no-repeat;
  background-size: cover;
`

const Caption = styled.div`
  display: flex;
  width: 100%;
  height: 38px;
  padding: 0 10px;
  align-items: center;
  justify-content: left;
  flex-shrink: 0;
`

const Title = styled.span`
  text-transform: uppercase;
  color: #4184f3;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const AlertIcon = styled.img`
  width: 60px;
  height: 60px;
  object-fit: contain;
  overflow: hidden;
`

const AlertNumber = styled.span`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 34px;
  color: white;
  background-color: ${(props) => `${props.weakColor}`};
`

function getIcon(type) {
  switch (type) {
    case alertTypes.alert:
      return circleAlarmHC
    case alertTypes.lowAlert:
      return circleUnlockedHC
    case alertTypes.warning:
      return circleHC
    default:
      return undefined
  }
}

function getFloorColor(type, isStatic) {
  if (!isStatic) {
    return getAreaColor('edit')
  }
  switch (type) {
    case alertTypes.alert:
    case alertTypes.lowAlert:
    case alertTypes.warning:
      return getAreaColor(type)
    default:
      return {
        weakColor: 'rgba(0,0,0,0)',
        mainColor: 'rgba(3,169,244,0.9)',
      }
  }
}

function FloorTile({
  id,
  title,
  alertType,
  alertCount,
  previewImgUrl,
  selected,
  isStatic,
  onSelect,
  onClickFloor,
}) {
  const icon = getIcon(alertType)
  const { mainColor, weakColor } = getFloorColor(alertType, isStatic)
  return (
    <Tile
      onClick={onSelect}
      selected={selected}
      mainColor={mainColor}
      weakColor={weakColor}
    >
      <FloorPreview
        preview={previewImgUrl}
        onClick={() => {
          if (id) {
            onClickFloor(id, title)
          }
        }}
      >
        {isStatic && icon && <AlertIcon src={icon} />}
        {isStatic &&
          icon && <AlertNumber weakColor={weakColor}>{alertCount}</AlertNumber>}
      </FloorPreview>
      <Caption>
        <Title>{title}</Title>
      </Caption>
    </Tile>
  )
}

FloorTile.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  previewImgUrl: PropTypes.string,
  alertType: PropTypes.string,
  alertCount: PropTypes.string,
  isStatic: PropTypes.bool,
  onSelect: PropTypes.func,
  onClickFloor: PropTypes.func,
}

FloorTile.defaultProps = {
  onSelect: () => {},
}

export default FloorTile
