/*
 *
 * FixturePage actions
 *
 */

import { ActionTypes } from './constants'

/**
 * Store Prototypes
 *
 * @returns {Object}
 */
export function storePrototypes(prototypes) {
  return {
    type: ActionTypes.STORE_FIXTURE_PROTOTYPES,
    payload: prototypes,
  }
}

/**
 * Store Params
 *
 * @returns {Object}
 */
export function storeParams(params) {
  return {
    type: ActionTypes.STORE_PARAMS,
    payload: params,
  }
}

/**
 * Post Canvas Failed
 *
 * @returns {Object}
 */
export function postFixtureCanvasFailed(error) {
  return {
    type: ActionTypes.POST_FIXTURE_CANVAS_FAILED,
    payload: error,
  }
}

/**
 * Post Canvas Fulfilled
 *
 * @returns {Object}
 */
export function postFixtureCanvasFinished(payload) {
  return {
    type: ActionTypes.POST_FIXTURE_CANVAS_FINISHED,
    payload,
  }
}

/**
 * Post Canvas Pending
 *
 * @returns {Object}
 */
export function postFixtureCanvasPending(canvasId) {
  return {
    type: ActionTypes.POST_FIXTURE_CANVAS_PENDING,
    payload: {
      canvasId,
    },
  }
}

/**
 * Fetch Canvas Failed
 *
 * @returns {Object}
 */
export function fetchFixtureCanvasFailed(error) {
  return {
    type: ActionTypes.FETCH_FIXTURE_CANVAS_FAILED,
    payload: error,
  }
}

/**
 * Fetch Canvas Fulfilled
 *
 * @returns {Object}
 */
export function fetchFixtureCanvasFulfilled(
  canvasId,
  canvas,
  selectedId,
  positions,
  fixture,
  isStatic
) {
  return {
    type: ActionTypes.FETCH_FIXTURE_CANVAS_FULFILLED,
    payload: {
      canvas,
      selectedId,
      positions,
      fixture,
      isStatic,
    },
  }
}

/**
 * Fetch Canvas Pending
 *
 * @returns {Object}
 */
export function fetchFixtureCanvasPending(canvasId) {
  return {
    type: ActionTypes.FETCH_FIXTURE_CANVAS_PENDING,
    payload: {
      canvasId,
    },
  }
}

/**
 * Fixture Canvas Changed
 *
 * @returns {Object}
 */
export function fixtureCanvasChanged(payload) {
  return {
    type: ActionTypes.FIXTURE_CANVAS_CHANGED,
    payload,
  }
}

/**
 * Fixture Canvas Name Changed
 *
 * @returns {Object}
 */
export function fixtureCanvasNameChanged(payload) {
  return {
    type: ActionTypes.FIXTURE_CANVAS_NAME_CHANGED,
    payload,
  }
}

/**
 * Fixture Canvas Position Count Changed
 *
 * @returns {Object}
 */
export function fixtureCanvasPositionCountChanged(payload) {
  return {
    type: ActionTypes.FIXTURE_CANVAS_POSITION_COUNT_CHANGED,
    payload,
  }
}

/**
 * Load Canvas
 *
 * @returns {Object}
 */
export function loadCanvas(screen, isStatic, selectedId, storeId, fixtureId) {
  return {
    type: ActionTypes.LOAD_FIXTURE_CANVAS,
    payload: {
      isStatic,
      screen,
      selectedId,
      sId: storeId,
      fxId: fixtureId,
    },
  }
}

/**
 * Save All
 *
 *
 */
export function saveAll(payload) {
  return {
    type: ActionTypes.SAVE_FIXTURE_ALL_POSITIONS,
    payload,
  }
}

/**
 * Save Position
 *
 *
 */
export function savePosition(payload) {
  return {
    type: ActionTypes.SAVE_FIXTURE_POSITION,
    payload,
  }
}

/**
 * Load Products With Manufacturers
 *
 * @returns {object}
 */
export function loadProductsWithManufacturers(sId) {
  return {
    type: ActionTypes.LOAD_PRODUCTS_WITH_MANUFACTURERS,
    payload: { sId },
  }
}

/**
 * Assign Security Device action
 *
 * @param payload
 * @returns {object}
 */
export function assignSecurityDevice(payload) {
  return {
    type: ActionTypes.ASSIGN_SECURITY_DEVICE,
    payload,
  }
}

/**
 * Assign Port Security Product action
 *
 * @param payload
 * @returns {object}
 */
export function assignPortSecuredProduct(payload) {
  return {
    type: ActionTypes.ASSIGN_PORT_SECURED_PRODUCT,
    payload,
  }
}

/**
 * Add new secured product action
 *
 * @param payload
 * @returns {object}
 */
export function addNewProduct(payload) {
  return {
    type: ActionTypes.ADD_NEW_PRODUCT,
    payload,
  }
}

/**
 * Add and assign new secured product to port action
 *
 * @param payload
 * @returns {object}
 */
export function addAndAssignPortSecuredProduct(payload) {
  return {
    type: ActionTypes.ADD_AND_ASSIGN_PORT_NEW_PRODUCT,
    payload,
  }
}

/**
 * Delete secured product action
 *
 * @param payload
 * @returns {object}
 */
export function deleteProduct(payload) {
  return {
    type: ActionTypes.DELETE_PRODUCT,
    payload,
  }
}

/**
 * Remove Position
 *
 * @returns {Object}
 */
export function removeObject(payload) {
  return {
    type: ActionTypes.FIXTURE_CANVAS_POSITION_REMOVE,
    payload,
  }
}

/**
 * Auto Placement Fulfilled
 *
 * @returns {Object}
 */
export function autoPlacementFulfilled(canvas) {
  return {
    type: ActionTypes.AUTO_PLACEMENT_FULFILLED,
    payload: { canvas },
  }
}

/**
 * Set Loading
 *
 * @returns {Object}
 */
export function setLoading(loading) {
  return {
    type: ActionTypes.STORE_FIXTURE_LOADING,
    payload: loading,
  }
}

/**
 * Select Position
 *
 * @param selectedPosition
 * @returns {object}
 */
export function selectPosition(selectedPosition) {
  return {
    type: ActionTypes.SELECT_POSITION,
    payload: { selectedPosition },
  }
}

/**
 * saveFixtureFromMobile
 *
 * @returns {Object}
 */
export function saveFixtureFromMobile(fixture) {
  return {
    type: ActionTypes.SAVE_FIXTURE_FROM_MOBILE,
    payload: fixture,
  }
}
