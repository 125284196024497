/**
 *
 * Templates actions
 *
 */

import { ActionTypes } from '../App/constants'
import { ActionTypes as ActionTypesLocal } from './constants'

/**
 * Load Templates
 *
 * @returns {Object}
 */
export function loadTemplates(force, tId) {
  return {
    type: ActionTypes.LOAD_TEMPLATES,
    payload: { force, tId },
  }
}

/**
 * Fetch Templates Pending
 *
 * @returns {Object}
 */
export function fetchTemplatesPending() {
  return {
    type: ActionTypes.FETCH_TEMPLATES_PENDING,
  }
}

/**
 * Fetch Templates Fulfilled
 *
 * @returns {Object}
 */
export function fetchTemplatesFulfilled() {
  return {
    type: ActionTypes.FETCH_TEMPLATES_FULFILLED,
  }
}

/**
 * Fetch Templates Failed
 *
 * @returns {Object}
 */
export function fetchTemplatesFailed(error) {
  return {
    type: ActionTypes.FETCH_TEMPLATES_FAILED,
    payload: { error },
  }
}

/**
 * Fetch Templates Start Time
 *
 * @returns {Object}
 */
export function fetchTemplatesStartTime(time) {
  return {
    type: ActionTypes.FETCH_TEMPLATES_START_TIME,
    payload: { time },
  }
}

/**
 * Delete Template
 *
 * @returns {Object}
 */
export function deleteTemplate(payload) {
  return {
    type: ActionTypesLocal.DELETE_TEMPLATE,
    payload,
  }
}
