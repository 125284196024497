import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Select from '../../../components/Select'
import Checkbox from '../../../components/Checkbox'
import ProgressOverlay from '../../../components/ProgressOverlay'

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const Header = styled.h5`
  padding-top: 10px;
  padding-bottom: 10px;
`

export const ModalBody = styled.div`
  padding: 10px 10px 10px 10px;
`

export const ColumnContainer = styled.div`
  padding-top: 0px;
`

export const StoreImageContainer = styled.div`
  height: 250px;
  background-color: lightgray;
`

class EditStoreTagsScreen extends React.Component {
  setServerErrorDismiss(element) {
    // eslint-disable-next-line
    $(element).keyup(() => {
      element.setCustomValidity('')
      $(element).attr('data-content', '') // eslint-disable-line
      $(element).popover('hide') // eslint-disable-line
    })
  }

  setServerError(element, msg) {
    element.setCustomValidity(msg)
    $(element).attr('data-placement', 'bottom') // eslint-disable-line
    $(element).attr('data-content', msg) // eslint-disable-line
    $(element).popover('show') // eslint-disable-line
    setTimeout(() => {
      $(element).attr('data-content', '') // eslint-disable-line
      $(element).popover('disable') // eslint-disable-line
    }, 3000)
  }

  setValidationErrors(validationErrors) {
    if (validationErrors.name) {
      const msg = validationErrors.name[0]
      const element = this.storeName
      this.setServerError(element, msg)
    }
  }

  setServerValidity(error) {
    console.log(error)
  }

  close = () => {
    const { id, onClose } = this.props
    $(`#modal-${id}`).modal('hide') // eslint-disable-line
    onClose()
  }

  setLoading(loading) {
    if (this.progressOverlay) this.progressOverlay.setLoading(loading)
  }

  renderContent = () => {
    const { storeId, taggings, tags, onChange } = this.props
    const taggingsTagset = ((taggings || [])[0] || []).filter(
      (tagging) => (((tagging || {}).tag || [])[0] || {}).tagsetId
    )
    const taggingsTag = ((taggings || [])[0] || []).filter(
      (tagging) => !(((tagging || {}).tag || [])[0] || {}).tagsetId
    )

    return (
      <div className="container">
        <div>
          {tags.tagsets.map((tagsetCustomField) => {
            const taggingTagset = tagsetCustomField.tags
              .map((tag) =>
                taggingsTagset.find((tagging) => tagging.tagId === tag.id)
              )
              .filter((tagging) => !!tagging)[0]
            const taggingTagName = (((taggingTagset || {}).tag || [])[0] || {})
              .name
            const tagset = tagsetCustomField
            const { id } = tagset || {}
            const orgTagsForTagset = tags.tagsets.find(
              (orgTagset) => orgTagset.id === tagset.id
            ).tags
            orgTagsForTagset.splice(0, 0, { id: -1, name: null })

            return (
              <ColumnContainer
                className="form-group col-md-10 bmd-form-group"
                key={id}
              >
                <Select
                  name={tagset.name}
                  label={tagset.name}
                  value={taggingTagName}
                  disabled={false}
                  onChange={(e) => {
                    const tagName = e.target.value
                    tags.tagsets
                      .find((orgTagset) => orgTagset.id === tagset.id)
                      .tags.filter((orgTag) => {
                        const isChecked = orgTag.name === tagName
                        if (orgTag.id !== -1) {
                          onChange({
                            tagging: {
                              storeId,
                              tagId: orgTag.id,
                              taggingId: (taggingTagset || {}).id,
                              isChecked,
                            },
                          })
                        }
                        return isChecked
                      })
                  }}
                >
                  {orgTagsForTagset.map((orgTag, i) => (
                    // eslint-disable-next-line
                    <option key={i} value={orgTag.name}>
                      {orgTag.name}
                    </option>
                  ))}
                </Select>
              </ColumnContainer>
            )
          })}
        </div>
        <br />
        <div>
          {tags.tags.map((tag) => {
            const taggingTag = taggingsTag.find(
              (tagging) => tagging.tagId === tag.id
            )
            const { id } = tag || {}

            return (
              <ColumnContainer
                className="form-group col-md-10 bmd-form-group"
                key={id}
              >
                <Checkbox
                  name={tag.name}
                  label={tag.name}
                  checked={!!taggingTag}
                  onChange={(e) => {
                    const isChecked = e.target.checked
                    onChange({
                      tagging: {
                        storeId,
                        tagId: tag.id,
                        taggingId: (taggingTag || {}).id,
                        isChecked,
                      },
                    })
                  }}
                />
              </ColumnContainer>
            )
          })}
        </div>
      </div>
    )
  }

  render() {
    const { title, onConfirm } = this.props

    return (
      <div className="modal-content">
        <form
          style={{ textAlign: 'left' }}
          className="needs-validation was-validated"
          noValidate=""
          onSubmit={(e) => {
            e.preventDefault()
            onConfirm()
          }}
        >
          <div className="modal-header border-bottom">
            <h5 className="modal-title" id="exampleModalLongTitle">
              {title}
            </h5>
            <div>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  // $(`#modal-${id}`).modal('hide') // eslint-disable-line
                  onConfirm()
                }}
              >
                Save
              </button>
              <button
                type="button"
                className="btn btn-primary btn-raised"
                onClick={this.close}
              >
                Close
              </button>
            </div>
          </div>
          <ModalBody className="modal-body">{this.renderContent()}</ModalBody>
        </form>
        <ProgressOverlay ref={(ref) => (this.progressOverlay = ref)} />
      </div>
    )
  }
}

EditStoreTagsScreen.propTypes = {
  id: PropTypes.string.isRequired,
  storeId: PropTypes.number.isRequired,
  taggings: PropTypes.array.isRequired,
  tags: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  loading: PropTypes.bool,
}

export default EditStoreTagsScreen
