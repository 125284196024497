import React from 'react'
import PropTypes from 'prop-types'
import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'
import { compose } from 'redux'

import { loadStores } from '../actions'
import {
  makeSelectStores,
  makeSelectFailed,
  makeSelectLoading,
} from '../selectors'
import StoresSelector from '../../../../components/StoresSelection'
import ProgressIndicator from '../../../../components/ProgressIndicator'
import FetchFailedAlert from '../../../../components/FetchFailed'
import HomeScreenPreloader from '../../../../components/Preloaders/HomeScreenPreloader'

export class StoresSelection extends React.Component {
  constructor(props) {
    super(props)
    const index = (props.stores || [])
      .map((x) => x.id)
      .indexOf(props.storeId || -1)
    this.state = { selectIndex: index > -1 ? index : 0 }
  }

  componentDidMount() {
    this.props.loadStores()
  }

  componentDidUpdate(prevProps) {
    const { stores, storeId } = this.props
    if (prevProps.storeId !== storeId) {
      const index = (stores || []).map((x) => x.id).indexOf(storeId)
      /* eslint-disable react/no-did-update-set-state */
      this.setState({ selectIndex: index })
      return
    }
    if (stores && stores.length > 0) {
      const { selectIndex } = this.state
      this.props.onSelect(stores[selectIndex])
    }
  }

  /* eslint-disable no-undef */
  onSelect = (index) => {
    this.setState({
      selectIndex: index,
    })
    const { stores } = this.props

    this.props.onSelect(stores[index])
  }

  render() {
    const { stores, loading, failed } = this.props
    const { selectIndex } = this.state

    if (loading)
      return (
        <React.Fragment>
          <HomeScreenPreloader storesCount={stores.length} />
          <ProgressIndicator text={'Loading...'} />
        </React.Fragment>
      )
    if (failed) return <FetchFailedAlert failed={failed} />

    if (!stores || stores.length === 1) {
      return null
    }

    return (
      <StoresSelector
        stores={stores}
        onSelect={this.onSelect}
        activeIndex={selectIndex}
      />
    )
  }
}

StoresSelection.propTypes = {
  stores: PropTypes.array,
  storeId: PropTypes.number,
  loadStores: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  failed: PropTypes.object,
}

const mapStateToProps = createStructuredSelector({
  stores: makeSelectStores(),
  loading: makeSelectLoading(),
  failed: makeSelectFailed(),
})

const mapDispatchToProps = {
  loadStores,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect)(StoresSelection)
