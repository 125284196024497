import uuid from 'uuid'
import {
  propsToInclude,
  getSelectionObject as getSelectionProto,
} from '../../containers/FixturePage/objectUtils'
import {
  getObjectAttrs,
  toSelectionAttrs,
  getSelectionType,
  getSelectionByType,
} from '../../containers/FixturePage/utils'
import {
  getEditTypeByDevice,
  getEditUnselectedTypeByDevice,
} from '../../containers/FixturePage/utilsEditTypes'
import { createText } from './text'

export function getObjectSync(type, screen, origX, origY, prototypes) {
  const { positions: protoPositions } = prototypes
  const object = protoPositions[type]
  object.set(getObjectAttrs(object, uuid.v4(), screen, origX, origY))
  return object
}

export function getSelectionObjectSync(object, id, type, screen, origX, origY) {
  if (!object) return object
  object.set(
    toSelectionAttrs(
      getObjectAttrs(object, `selection-${id}`, screen, origX, origY)
    )
  )
  return object
}

export async function getSelectionObject(type, screen, origX, origY) {
  const object = await getSelectionProto(type, false)
  object.set(
    toSelectionAttrs(getObjectAttrs(object, uuid.v4(), screen, origX, origY))
  )
  return object
}

export const handleOver = (o, size, objects, mtiJsclientShared = {}) => {
  if (!o || !objects) return undefined
  // console.log('handleOver', o.id)

  const { name, left, top, width, height, id, mtiType } = o
  // Create selection outline for the position
  const selectionObject = getSelectionObjectSync(
    getSelectionByType(o, objects, mtiJsclientShared),
    id,
    getSelectionType(mtiType, mtiJsclientShared),
    size,
    left,
    top
  )
  // Create description text under the position
  const selectionScaleFactor = selectionObject.scaleFactor || 1
  const scaleFactor =
    selectionScaleFactor < 1 ? selectionScaleFactor * 1.1 : selectionScaleFactor
  const verticalGap = height * scaleFactor * 2
  const label = createText(name, left, top, width, height, verticalGap)

  if (!selectionObject) return { label, selectionObject }
  selectionObject.selectable = false
  selectionObject.evented = false
  return { label, selectionObject }
}

export const handleOut = (o) => {
  if (!o) return false
  // console.log('handleOut', o.id)
  return true
}

export function mutatePosition(from, to) {
  propsToInclude.forEach((key) => {
    to[key] = from[key]
  })
  to['scaleX'] = from['scaleX']
  to['scaleY'] = from['scaleY']
  return to
}

export function unselectEdit(
  prevSelectedId,
  canvas,
  size,
  prototypes,
  mtiJsclientShared
) {
  const objects = canvas.getObjects()
  const prevSelectedIndex = objects.findIndex((x) => x.id == prevSelectedId)
  const prevSelectedObject = objects[prevSelectedIndex]
  const type = getEditUnselectedTypeByDevice(
    prevSelectedObject,
    undefined,
    mtiJsclientShared
  )
  const unselectedObject = getObjectSync(
    type,
    size,
    prevSelectedObject.left,
    prevSelectedObject.top,
    prototypes
  )
  const unselectedMutated = mutatePosition(prevSelectedObject, unselectedObject)
  unselectedMutated.selectedForEdit = false
  canvas.remove(prevSelectedObject)
  canvas.insertAt(unselectedMutated, prevSelectedIndex, false)
}

export function selectEdit(
  thisSelectedId,
  canvas,
  size,
  prototypes,
  mtiJsclientShared
) {
  const objects = canvas.getObjects()
  const thisSelectedIndex = objects.findIndex((x) => x.id == thisSelectedId)
  const thisSelectedObject = objects[thisSelectedIndex]
  const type = getEditTypeByDevice(thisSelectedObject, mtiJsclientShared)
  const filledObject = getObjectSync(
    type,
    size,
    thisSelectedObject.left,
    thisSelectedObject.top,
    prototypes
  )
  const filledMutated = mutatePosition(thisSelectedObject, filledObject)
  filledMutated.selectedForEdit = true
  canvas.remove(thisSelectedObject)
  canvas.insertAt(filledMutated, thisSelectedIndex, false)
}
