import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'
import {
  makeSelectLinkedFixturesForTemplate,
  makeSelectLinkedFixturesLoading,
  makeSelectLinkedFixturesFailed,
} from './selectors'
import LinkedFixturesScreen from './index.screen'
import injectReducer from '../../../utils/injectReducer'
import injectSaga from '../../../utils/injectSaga'
import { loadLinkedFixturesForTemplate } from './actions'
import linkedFixturesReducer from './reducer'
import linkedFixturesSaga from './sagas'
import FetchFailedAlert from '../../../components/FetchFailed'
import { makeSelectRegions } from '../../RegionsPage/selectors'

class LinkedFixtures extends React.Component {
  componentDidMount() {
    const { template: { id } } = this.props
    this.props.loadLinkedFixturesForTemplate(id)
  }

  render() {
    const { id, linkedFixtures, loading, failed, onClose, regions } = this.props
    const regionsList = regions
      ? regions.map((region) => ({
        name: region.name,
        id: region.id,
        stores: region.stores.map((store) => store.id),
      }))
      : []

    if (!linkedFixtures || loading) {
      return <LinkedFixturesScreen loading={loading} />
    }
    if (failed) return <FetchFailedAlert failed={failed} />

    console.log('linkedFixtures', linkedFixtures)
    return (
      <LinkedFixturesScreen
        id={id}
        regions={regionsList}
        fixtures={linkedFixtures}
        onClose={onClose}
      />
    )
  }
}

LinkedFixtures.propTypes = {
  id: PropTypes.string.isRequired,
  template: PropTypes.object.isRequired,
  linkedFixtures: PropTypes.array,
  regions: PropTypes.array,
  loading: PropTypes.bool,
  failed: PropTypes.object,
  loadLinkedFixturesForTemplate: PropTypes.func,
  onClose: PropTypes.func.isRequired,
}

const mapStateToProps = createStructuredSelector({
  linkedFixtures: (state, ownProps) =>
    makeSelectLinkedFixturesForTemplate(ownProps.template.id)(state, ownProps),
  loading: makeSelectLinkedFixturesLoading(),
  failed: makeSelectLinkedFixturesFailed(),
  regions: makeSelectRegions(),
})

const mapDispatchToProps = {
  loadLinkedFixturesForTemplate,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const withReducer = injectReducer({
  key: 'linkedFixtures',
  reducer: linkedFixturesReducer,
})
const withSaga = injectSaga({
  key: 'linkedFixtures',
  saga: linkedFixturesSaga,
})

export default compose(withReducer, withSaga, withConnect)(LinkedFixtures)
