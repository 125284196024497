import React from 'react'
import * as ReactSwitch from 'react-switch'
import PropTypes from 'prop-types'

class Switch extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = { checked: props.checked }
  }

  /* eslint "react/no-did-update-set-state":0 */
  componentDidUpdate(props, state) {
    if (state.checked !== this.props.checked) {
      this.setState({ checked: this.props.checked })
    }
  }

  handleChange = (checked) => {
    this.setState({ checked })
    this.props.onChange(checked)
  }

  render() {
    const { id, className, disabled } = this.props
    return (
      <ReactSwitch
        onColor="#4E92DF"
        onChange={this.handleChange}
        checked={this.state.checked}
        className={className}
        disabled={disabled}
        id={`${id}`}
      />
    )
  }
}

Switch.propTypes = {
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
}

export default Switch
