import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Container, Col } from '../Bootstrap'

import HelpIcon from '../HelpIcon'
import ConfirmModal from '../../containers/ModalContainer/ConfirmModal'
import { openModal, closeModal } from '../../containers/App/actions'
import { provisionHub, fetchHubs } from './actions'
import { selectHasProvisionPermission } from '../../containers/App/selectors'

import * as selector from './selectors'

const intlPath = string => `components.hub.${string}`

class ProvisioningButton extends React.PureComponent {

  onClick() {
    this.showConfirm()
  }

  showConfirm() {
    const { openModal, closeModal, provisionHub, storeId, intl, hubs } = this.props
    const { id: hubId, identifier } = hubs[0]
    const id = 'confirm-provision-modal'
    openModal({
      id: id,
      type: 'bootstrap',
      appearance: 'modal-md',
      content: (
        <ConfirmModal
          questionText={<FormattedMessage id={'help.hub.provisioningMode'} />}
          id={id}
          cancelText="CANCEL"
          confirmText={<FormattedMessage id={intlPath('provisionCommand')} />}
          onClose={() => closeModal({ id })}
          onConfirm={() => {
            provisionHub({
              storeId,
              hubId,
              message: {
                success: intl.formatMessage({ id: intlPath`provisioning` }, { identifier }),
                failure: intl.formatMessage({ id: intlPath`provisioningFailed` }),
              },
            })
          }}
          hideCloseInHeader
        />
      ),
    })
  }

  componentDidMount() {
    this.props.fetchHubs({ storeId: this.props.storeId })
  }

  render() {
    if (this.props.hasProvisionPermission) return (
      <Container>
        <Col span={5}>
          <button
            type="button"
            onClick={() => this.onClick()}
            name="provisionHub"
            className="btn btn-primary btn-raised"
            disabled={this.props.hubs.length < 1}
          >
            <FormattedMessage id={intlPath('provisionCommand')} />
          </button>
          <HelpIcon helpKey={'hub.provisioningMode'} />
        </Col>
      </Container>
    )
    return null
  }
}

ProvisioningButton.propTypes = {
  storeId: PropTypes.number.isRequired,
  hubId: PropTypes.number,
  hasProvisionPermission: PropTypes.bool,
  provisionHub: PropTypes.func,
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
}

const mapStateToProps = createStructuredSelector({
  hasProvisionPermission: selectHasProvisionPermission,
  hubs: selector.selectHubs,
})

const mapDispatchToProps = {
  provisionHub,
  fetchHubs,
  openModal,
  closeModal,
}

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(ProvisioningButton)
)
