import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { createStructuredSelector } from 'reselect'
import { selectAreasCount } from '../selectors'

const AreaNameBreadcrumb = ({
  areasCount,
  match: { key, params: { aName } }, // url,
}) =>
  areasCount > 1 ? (
    <Link
      key={key}
      className="breadcrumb-item"
      to={'#'}
      onClick={(e) => e.preventDefault()}
    >
      {decodeURIComponent(aName)}
    </Link>
  ) : null

AreaNameBreadcrumb.propTypes = {
  match: PropTypes.object,
  areasCount: PropTypes.number,
}

const mapStateToProps = createStructuredSelector({
  areasCount: (state, ownProps) =>
    selectAreasCount(ownProps.match.params.sId)(state, ownProps),
})

export default connect(mapStateToProps)(AreaNameBreadcrumb)
