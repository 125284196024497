import React from 'react'
import PropTypes from 'prop-types'
import CreateEditUserScreen, { days } from './index.screen'
import moment from 'moment'

export class CreateEditScheduleModal extends React.PureComponent {
  schedule = {}

  constructor(props) {
    super(props)
    if (props.schedule.id) {
      this.schedule = props.schedule.toJS()
    }
  }

  setServerValidity(error) {
    if (this.createScheduleModal) {
      this.createScheduleModal.setServerValidity(error)
    }
  }

  onChange = (value) => {
    const { onChange } = this.props
    onChange(value)
    this.schedule = { ...this.schedule, ...value }
  }

  close() {
    if (this.createScheduleModal) this.createScheduleModal.close()
  }

  daysError = () => {
    let error = {}
    let errors = false

    for (const i in days) {
      const day = days[i]
      const openKey = `${day}Open`
      const closeKey = `${day}Close`
      const openTime = this.schedule[openKey]
      const closeTime = this.schedule[closeKey]

      if (openTime || closeTime) {
        if (!openTime) {
          error[openKey] = [["Can't be blank"]]
          errors = true
          break
        } else if (!closeTime) {
          error[closeKey] = [["Can't be blank"]]
          errors = true
          break
        } else {
          const openDate = moment(`1970-01-01 ${openTime}`)
          const closeDate = moment(`1970-01-01 ${closeTime}`)

          if (closeDate.isSameOrBefore(openDate)) {
            error[closeKey] = [['Closing time must be later than opening time']]
            errors = true
            break
          }
        }
      }
    }

    return { error: { validationErrors: error }, errors }
  }

  render() {
    const {
      id,
      schedule,
      title,
      confirmText,
      onClose,
      onConfirm,
      effectiveOnError,
      scheduleEditLoading,
    } = this.props
    return (
      <CreateEditUserScreen
        ref={(ref) => {
          this.createScheduleModal = ref
        }}
        id={id}
        schedule={schedule}
        onChange={this.onChange}
        title={title}
        confirmText={confirmText}
        onClose={onClose}
        onConfirm={onConfirm}
        effectiveOnError={effectiveOnError}
        daysError={this.daysError}
        scheduleEditLoading={scheduleEditLoading}
      />
    )
  }
}

CreateEditScheduleModal.propTypes = {
  onChange: PropTypes.func,
  id: PropTypes.string.isRequired,
  schedule: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  confirmText: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  effectiveOnError: PropTypes.func,
  scheduleEditLoading: PropTypes.func,
}

export default CreateEditScheduleModal
