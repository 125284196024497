import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ReactTable from 'react-table'
import styles from '../../../styles/App.css'
import {
  renderHeaderCell,
  HeaderContainer,
  Header,
  renderFilterInput,
  CellContainer,
} from '../../UsersPage/index.screen'
import { fullTextFilter, safeUri } from '../../../utils/utils'
import { orgUrl } from '../../../utils/mtiUtils'
import ProgressIndicator from '../../../components/ProgressIndicator'
import TablePreloader from '../../../components/Preloaders/TablePreloader'

const HeaderButtonsContainer = styled.div`
  margin-right: 12px;
`

class LinkedFixturesScreen extends React.Component {
  /* eslint-disable no-undef, react/sort-comp */
  fixtureLink = (fixture) => {
    const {
      storeId,
      storeName,
      floorId,
      floorName,
      areaId,
      areaName,
      id,
      name,
    } = fixture
    const components = [
      '',
      'stores',
      storeId,
      storeName,
      'floors',
      floorId,
      floorName,
      'areas',
      areaId,
      areaName,
      'fixtures',
      id,
      name,
    ]
    const uriComponents = components.map(safeUri)
    return `${orgUrl()}${uriComponents.join('/')}`
  }

  regionName = (storeId) => {
    const { regions } = this.props
    const region = regions.find((region) => region.stores.includes(storeId))
    if (region) {
      return region.name
    }
    return ''
  }

  onClose = () => {
    const { onClose, id } = this.props
    $(`#modal-${id}`).modal('hide') // eslint-disable-line
    onClose()
  }

  getColumns = () => [
    {
      id: 'regionName',
      sortable: true,
      filterable: true,
      Header: renderHeaderCell('Region'),
      accessor: ({ storeId }) => this.regionName(storeId),
      Filter: ({ filter, onChange }) =>
        renderFilterInput({
          placeholder: 'Filter',
          filter,
          onChange,
        }),
    },
    {
      sortable: true,
      filterable: true,
      Header: renderHeaderCell('Store'),
      accessor: 'storeName',
      Cell: ({ original: { storeName } }) => (
        <CellContainer>{storeName}</CellContainer>
      ),
      Filter: ({ filter, onChange }) =>
        renderFilterInput({
          placeholder: 'Filter',
          filter,
          onChange,
        }),
    },
    {
      sortable: true,
      filterable: true,
      Header: renderHeaderCell('Area'),
      accessor: 'areaName',
      Filter: ({ filter, onChange }) =>
        renderFilterInput({
          placeholder: 'Filter',
          filter,
          onChange,
        }),
    },
    {
      sortable: true,
      filterable: true,
      Header: renderHeaderCell('Fixture'),
      accessor: 'name',
      Cell: ({ original: { name } }) => <CellContainer>{name}</CellContainer>,
      Filter: ({ filter, onChange }) =>
        renderFilterInput({
          placeholder: 'Filter',
          filter,
          onChange,
        }),
    },
    {
      width: 70,
      style: { justifyContent: 'center' },
      Header: renderHeaderCell('GoTo'),
      Cell: ({ original }) => (
        <a
          className={styles.link}
          target="_blank"
          href={this.fixtureLink(original)}
        >
          Open
        </a>
      ),
    },
  ]

  render() {
    const { fixtures, loading } = this.props
    const columns = this.getColumns()

    return (
      <div className="modal-content">
        <HeaderContainer className="border-bottom">
          <Header>Linked Fixtures</Header>
          <HeaderButtonsContainer>
            <button
              type="button"
              className="btn btn-primary"
              onClick={this.onClose}
            >
              Close
            </button>
          </HeaderButtonsContainer>
        </HeaderContainer>
        {loading ? (
          <React.Fragment>
            <TablePreloader
              columnsCount={5}
              loaderWidth={750}
              heightLoader={570}
              isModal
            />
            <ProgressIndicator text={'Loading...'} />
          </React.Fragment>
        ) : (
          <ReactTable
            data={fixtures || []}
            columns={columns}
            defaultFilterMethod={fullTextFilter}
            defaultPageSize={10}
          />
        )}
      </div>
    )
  }
}

LinkedFixturesScreen.propTypes = {
  id: PropTypes.string.isRequired,
  fixtures: PropTypes.array.isRequired,
  regions: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  cloneable: PropTypes.bool,
}

export default LinkedFixturesScreen
