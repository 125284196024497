/**
 *
 * Linked Fixtures constants
 *
 */

import keyMirrow from 'fbjs/lib/keyMirror'

/**
 * @constant {Object} ActionTypes
 * @memberof Constants
 */
export const ActionTypes = keyMirrow({
  LOAD_LINKED_FIXTURES: undefined,
  FETCH_LINKED_FIXTURES_PENDING: undefined,
  FETCH_LINKED_FIXTURES_FULFILLED: undefined,
  FETCH_LINKED_FIXTURES_FAILED: undefined,
})
