import { ActionTypes } from '../constants'

/**
 * Store Details Action
 *
 * @returns {Object}
 */
export function storeDetailsAction(store) {
  return {
    type: ActionTypes.STORE_DETAILS,
    payload: store,
  }
}

/**
 * Store Details Action
 *
 * @returns {Object}
 */
export function createStoreAction() {
  return {
    type: ActionTypes.CREATE_STORE,
  }
}

/**
 * Reset Store Management
 *
 * @returns {Object}
 */
export function resetStoreManagement() {
  return {
    type: ActionTypes.RESET_STORE_MANAGEMENT,
  }
}

/**
 * Edit Store
 *
 * @returns {Object}
 */
export function editStore(o) {
  return {
    type: ActionTypes.STORE_EDIT,
    payload: o,
  }
}

/**
 * Clear Store
 *
 * @returns {Object}
 */
export function clearStore() {
  return {
    type: ActionTypes.STORE_CLEAR,
  }
}

/**
 * Post Store Failed
 *
 * @returns {Object}
 */
export function postStoreFailed(error) {
  return {
    type: ActionTypes.POST_STORE_FAILED,
    payload: error,
  }
}

/**
 * Post Store Fulfilled
 *
 * @returns {Object}
 */
export function postStoreFulfilled() {
  return {
    type: ActionTypes.POST_STORE_FULFILLED,
  }
}

/**
 * Post Store Pending
 *
 * @returns {Object}
 */
export function postStorePending() {
  return {
    type: ActionTypes.POST_STORE_PENDING,
  }
}

/**
 * Post Store
 *
 * @returns {Object}
 */
export function postStore() {
  return {
    type: ActionTypes.POST_STORE,
  }
}

/**
 * Patch Store Failed
 *
 * @returns {Object}
 */
export function patchStoreFailed(error) {
  return {
    type: ActionTypes.PATCH_STORE_FAILED,
    payload: error,
  }
}

/**
 * Patch Store Fulfilled
 *
 * @returns {Object}
 */
export function patchStoreFulfilled() {
  return {
    type: ActionTypes.PATCH_STORE_FULFILLED,
  }
}

/**
 * Patch Store Pending
 *
 * @returns {Object}
 */
export function patchStorePending() {
  return {
    type: ActionTypes.PATCH_STORE_PENDING,
  }
}

/**
 * Patch Store
 *
 * @returns {Object}
 */
export function patchStore(id) {
  return {
    type: ActionTypes.PATCH_STORE,
    payload: id,
  }
}
