/**
 *
 * TemplatePositions
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose } from 'redux'

import { openModal, closeModal, notifyModal } from '../App/actions'
import injectSaga from '../../utils/injectSaga'
import injectReducer from '../../utils/injectReducer'
import ConfirmModal from '../../containers/ModalContainer/ConfirmModal'
import {
  makeSelectCanvasObject,
  makeSelectCanvasSelectedId,
  makeSelectFailed,
  makeSelectLoading,
  makeSelectFixtureTemplate,
  makeSelectPositionsByFixtureTemplate,
  makeSelectIsStatic,
  makeSelectPrototypes,
  makeSelectPositionName,
  makeSelectPositionCount,
  makeSelectAutoPlacementMax,
  makeSelectSelectedPosition,
  makeSelectFixtureOrmed,
} from './selectors'
import reducer from './reducer'
import saga from './saga'
import {
  loadCanvas,
  savePosition,
  removeObject,
  fixtureTemplateCanvasNameChanged,
  fixtureTemplateCanvasChanged,
  createPositionRule,
  editPositionRule,
  deletePositionRule,
  deleteAllPositionRules,
  templateCanvasPositionCountChanged,
  saveAll,
} from './actions'
import {
  createFixtureRule,
  editFixtureRule,
  deleteFixtureRule,
  deleteAllFixtureRules,
} from '../TemplateFixture/actions'
import PortRulesModal from '../TemplateFixture/modals/PortRules'

import TemplatePositionsScreen from './index.screen'
import PositionRulesModal from '../TemplateFixture/modals/PositionRules'
import FixtureRulesModal from '../TemplateFixture/modals/FixtureRules'
import {
  SOURCE_ATTRIBUTES,
  NUMBER_OF_PORTS,
} from '../TemplateFixture/modals/ruleConstants'
import { makeSelectAllProducts } from '../FixturePage/selectors'
import ProgressIndicator from '../../components/ProgressIndicator'
import TemplatesDetailsPreloader from '../../components/Preloaders/TemplatesDetailsPreloader'
import LinkedFixtures from '../Templates/LinkedFixturesModal'
import { isSourceAttribute, isPortRule } from './utils'
import FetchFailedAlert from '../../components/FetchFailed'
import { orgUrl } from '../../utils/mtiUtils'
import { safeUri } from '../../utils/utils'

export const size = {
  width: 600,
  height: 400,
}

const unsupportedMessage =
  'This rule type is not supported and can not be edited.'

export class TemplatePositions extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      canvasObject: { objects: [] },
      isAutoplacement: true,
    }
  }

  componentDidMount() {
    this.loadCanvas(null, this.props.isStatic, -1)
  }

  componentDidUpdate(prevProps, prevState) {
    /* eslint-disable react/no-did-update-set-state */
    const prevTId = prevProps.match.params.tId
    const thisTId = this.props.match.params.tId
    if (prevTId !== thisTId) {
      this.loadCanvas(
        {
          tId: thisTId,
        },
        this.props.isStatic,
        this.props.selectedId
      )
      this.setState({ canvasObject: { objects: [] } })
    } else if (!this.props.loading) {
      if (
        JSON.stringify(this.props.canvasObject) !==
        JSON.stringify(prevState.canvasObject)
      ) {
        this.setState({ canvasObject: this.props.canvasObject })
      }
    }
  }

  deleteRulesConfirmation = (text, callback) => {
    const { openModal, closeModal } = this.props
    openModal({
      id: 'delete-compliance-rules',
      type: 'bootstrap',
      appearance: 'modal-sm',
      content: (
        <ConfirmModal
          questionText={text}
          id={'delete-compliance-rules'}
          cancelText={'CANCEL'}
          confirmText={'DELETE'}
          onClose={() => closeModal({ id: 'delete-compliance-rules' })}
          onConfirm={callback}
        />
      ),
    })
  }

  deleteOneRuleConfirmation = (callback) => {
    const text = 'Are you sure you want to delete this compliance rule?'
    this.deleteRulesConfirmation(text, callback)
  }

  deleteAllRulesConfirmation = (callback) => {
    const text = 'Are you sure you want to delete all compliance rules?'
    this.deleteRulesConfirmation(text, callback)
  }

  onSavePosition(canvasObject, selectedId) {
    this.props.savePosition({
      canvasObject,
      tId: this.props.match.params.tId,
      selectedId: selectedId,
      screen: size,
    })
    this.setState({ isAutoplacement: false })
  }

  onSaveAll(canvasObject) {
    this.props.saveAll({
      canvasObject,
      tId: this.props.match.params.tId,
      screen: size,
    })
    this.setState({ isAutoplacement: false })
  }

  checkCountAutoplacement(count) {
    const { selectedAutoplacementMax } = this.props
    if (isNaN(count)) {
      return 0
    } else if (count < 0) {
      return 0
    } else if (count > selectedAutoplacementMax) {
      return selectedAutoplacementMax
    }
    return count
  }

  onEditTemplate = () => {
    const { history, template: { id, name } } = this.props
    history.push(`${orgUrl()}/templates/${id}/${safeUri(name)}`)
  }

  onAddPositionRule = (position) => {
    const {
      products,
      openModal: doOpenModal,
      closeModal: doCloseModal,
      createPositionRule: doCreatePositionRule,
      template: { id },
    } = this.props
    const modalId = 'add-position-rule'

    doOpenModal({
      id: modalId,
      type: 'bootstrap',
      content: (
        <PositionRulesModal
          id={modalId}
          products={products}
          onConfirm={(rule) => {
            doCreatePositionRule(position.id, rule, id, size)
            doCloseModal({ id: modalId })
          }}
          onClose={() => doCloseModal({ id: modalId })}
          onAddPortComplianceRules={this.onAddPortRules}
        />
      ),
    })
  }

  onEditPositionRule = (position, rule) => {
    const {
      products,
      openModal: doOpenModal,
      closeModal: doCloseModal,
      notifyModal: doNotifyModal,
      editPositionRule: doEditPositionRule,
      deletePositionRule: doDeletePositionRule,
      template: { id },
    } = this.props
    const modalId = 'edit-position-rule'

    if (isSourceAttribute(rule.sourceAttribute)) {
      doOpenModal({
        id: modalId,
        type: 'bootstrap',
        content: (
          <PositionRulesModal
            id={modalId}
            rule={rule}
            products={products}
            onConfirm={(editedRule) => {
              doEditPositionRule(
                position.id,
                { ...editedRule, id: rule.id },
                id,
                size
              )
              doCloseModal({ id: modalId })
            }}
            onDelete={() => {
              setTimeout(() => {
                this.deleteOneRuleConfirmation(() =>
                  doDeletePositionRule(position.id, rule, id, size)
                )
              }, 1000)
            }}
            onClose={() => doCloseModal({ id: modalId })}
            onAddPortComplianceRules={this.onAddPortRules}
          />
        ),
      })
    } else {
      doNotifyModal(unsupportedMessage)
    }
  }

  onDeleteAllPositionRules = (position) => {
    const { template: { id } } = this.props
    this.deleteAllRulesConfirmation(() => {
      this.props.deleteAllPositionRules(position, id, size)
    })
  }

  onAddPortRules = (deviceType) => {
    const {
      openModal: doOpenModal,
      closeModal: doCloseModal,
      products,
      selectedPosition,
      createPositionRule: doCreatePositionRule,
      editPositionRule: doEditPositionRule,
      deletePositionRule: doDeletePositionRule,
      template: { id },
    } = this.props

    const portRules = selectedPosition.rules.filter(({ sourceAttribute }) =>
      isPortRule(sourceAttribute)
    )

    const modalId = 'add-port-rules'

    doOpenModal({
      id: modalId,
      type: 'bootstrap',
      content: (
        <PortRulesModal
          id={modalId}
          numberOfPorts={NUMBER_OF_PORTS[deviceType]}
          onConfirm={(rule) => {
            doCreatePositionRule(selectedPosition.id, rule, id, size)
            doCloseModal({ id: modalId })
          }}
          onEdit={(rule) => {
            doEditPositionRule(
              selectedPosition.id,
              { ...rule, id: rule.id },
              id,
              size
            )
            doCloseModal({ id: modalId })
          }}
          onDelete={(rule) => {
            setTimeout(() => {
              this.deleteOneRuleConfirmation(() =>
                doDeletePositionRule(selectedPosition.id, rule, id, size)
              )
            }, 1000)
          }}
          onClose={() => doCloseModal({ id: modalId })}
          products={products}
          portRules={portRules}
        />
      ),
    })
  }

  onRemovePosition(canvasObject, selectedId) {
    const { openModal: doOpenModal, closeModal: doCloseModal } = this.props
    doOpenModal({
      id: 'delete',
      type: 'bootstrap',
      appearance: 'modal-sm',
      content: (
        <ConfirmModal
          id={'delete'}
          cancelText={'CANCEL'}
          confirmText={'DELETE POSITION'}
          onClose={() => doCloseModal({ id: 'delete' })}
          onConfirm={() => {
            this.props.removeObject({
              canvasObject,
              tId: this.props.match.params.tId,
              selectedId: selectedId,
              screen: size,
            })
            this.setState({
              isAutoplacement: canvasObject.objects.length === 2,
            })
          }}
        />
      ),
    })
  }

  onViewLinkedFixtures = (template) => {
    const { openModal: doOpenModal, closeModal: doCloseModal } = this.props
    doOpenModal({
      id: 'linked-fixtures',
      type: 'bootstrap',
      content: (
        <LinkedFixtures
          id={'linked-fixtures'}
          template={template}
          onClose={() => doCloseModal({ id: 'linked-fixtures' })}
        />
      ),
    })
  }

  onAddFixtureRule = () => {
    const {
      products,
      template,
      openModal: doOpenModal,
      closeModal: doCloseModal,
      createFixtureRule: doCreateFixtureRule,
    } = this.props
    const id = 'add-fixture-rule'

    doOpenModal({
      id,
      type: 'bootstrap',
      content: (
        <FixtureRulesModal
          id={id}
          products={products}
          onConfirm={(rule) => {
            doCreateFixtureRule(template.id, rule)
            doCloseModal({ id })
          }}
          onClose={() => doCloseModal({ id })}
        />
      ),
    })
  }

  onEditFixtureRule = (rule) => {
    const {
      products,
      template,
      openModal: doOpenModal,
      closeModal: doCloseModal,
      notifyModal: doNotifyModal,
      editFixtureRule: doEditFixtureRule,
      deleteFixtureRule: doDeleteFixtureRule,
    } = this.props
    const id = 'edit-fixture-rule'

    if (
      rule.sourceAttribute === SOURCE_ATTRIBUTES.positions ||
      rule.sourceAttribute === SOURCE_ATTRIBUTES.product_ids ||
      rule.sourceAttribute === SOURCE_ATTRIBUTES.live_product_ids
    ) {
      doOpenModal({
        id,
        type: 'bootstrap',
        content: (
          <FixtureRulesModal
            id={id}
            rule={rule}
            products={products}
            onConfirm={(editedRule) => {
              doEditFixtureRule(template.id, { ...editedRule, id: rule.id })
              doCloseModal({ id })
            }}
            onDelete={() => {
              setTimeout(() => {
                this.deleteOneRuleConfirmation(() =>
                  doDeleteFixtureRule(template.id, rule)
                )
              }, 1000)
            }}
            onClose={() => doCloseModal({ id })}
          />
        ),
      })
    } else {
      doNotifyModal(unsupportedMessage)
    }
  }

  onDeleteAllFixtureRules = () => {
    this.deleteAllRulesConfirmation(() => {
      const {
        template,
        deleteAllFixtureRules: doDeleteAllFixtureRules,
      } = this.props
      doDeleteAllFixtureRules({
        fixtureId: template.id,
        fixtureComplianceRules: template.rules,
      })
    })
  }

  onMessage(msg) {
    const { notifyModal: doNotifyModal } = this.props
    doNotifyModal(msg)
  }

  getObjectIndex(canvasObject, position) {
    return (canvasObject || {}).objects.findIndex(
      (i) => (i || {}).id === (position || {}).id
    )
  }

  loadCanvas(params, isStatic, selectedId) {
    const newParams = params || this.props.match.params
    this.props.loadCanvas(size, isStatic, selectedId, newParams.tId)
  }

  render() {
    const {
      selectedId,
      failed,
      loading,
      isStatic,
      positions,
      prototypes,
      selectedPositionName,
      selectedPositionCount,
      selectedAutoplacementMax,
      template,
      fixtureTemplateCanvasChanged: onChanged,
      fixtureTemplateCanvasNameChanged: onNameChanged,
      selectedPosition,
      templateCanvasPositionCountChanged: onCountChanged,
      selectedFixtureOrmed,
    } = this.props
    const { canvasObject, isAutoplacement } = this.state

    if (loading)
      return (
        <React.Fragment>
          <TemplatesDetailsPreloader />
          <ProgressIndicator text={'Loading...'} />
        </React.Fragment>
      )

    if (failed) return <FetchFailedAlert failed={failed} />

    return (
      <TemplatePositionsScreen
        canvasSize={size}
        canvasObject={canvasObject}
        selectedId={selectedId}
        selectedPosition={selectedPosition}
        selectedPositionName={selectedPositionName}
        selectedPositionCount={selectedPositionCount}
        selectedAutoplacementMax={selectedAutoplacementMax}
        isStatic={isStatic}
        isSilent={false}
        positions={positions}
        prototypes={prototypes}
        onChanged={(o) => onChanged(o)}
        onNameChanged={onNameChanged}
        onMessage={(msg) => this.onMessage(msg)}
        onMaxPositions={() => console.warn('onMaxPositions')}
        onEdit={(p) => this.loadCanvas(null, false, p.id)}
        onCancel={() => {
          this.loadCanvas(null, true, -1)
        }}
        onPositionRemove={(id) => this.onRemovePosition(canvasObject, id)}
        onPositionSave={(id) => this.onSavePosition(canvasObject, id)}
        onListItem={(p) =>
          onChanged({
            canvasObject,
            isStatic,
            selectedId: p.id,
          })
        }
        template={template}
        onEditMode={() => {
          this.loadCanvas(null, false, -1)
        }}
        onEditTemplate={this.onEditTemplate}
        onEditFixtureComplianceRule={this.onEditFixtureRule}
        onAddFixtureComplianceRule={this.onAddFixtureRule}
        onDeleteAllFixtureComplianceRules={this.onDeleteAllFixtureRules}
        onLinkedFixtures={this.onViewLinkedFixtures}
        onPositionChange={this.onPositionChange}
        onPositionChangeCancel={this.onPositionChangeCancel}
        onDeletePosition={this.onDeletePosition}
        onAddPositionComplianceRule={this.onAddPositionRule}
        onEditPositionComplianceRule={this.onEditPositionRule}
        onDeleteAllPositionComplianceRules={this.onDeleteAllPositionRules}
        onAddPortComplianceRules={this.onAddPortRules}
        isAutoplacement={
          !(positions && positions.length > 0) && isAutoplacement
        }
        onSaveAll={() => this.onSaveAll(canvasObject)}
        onPositionCountChanged={(count) => {
          onCountChanged({
            count: this.checkCountAutoplacement(count),
            fixture: selectedFixtureOrmed,
            screen: size,
          })
        }}
      />
    )
  }
}

TemplatePositions.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object,
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
  products: PropTypes.array,
  loadCanvas: PropTypes.func.isRequired,
  failed: PropTypes.object,
  loading: PropTypes.bool,
  isStatic: PropTypes.bool,
  canvasObject: PropTypes.object.isRequired,
  selectedId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  positions: PropTypes.any,
  prototypes: PropTypes.object,
  selectedPosition: PropTypes.object,
  selectedPositionName: PropTypes.string,
  selectedPositionCount: PropTypes.number,
  selectedAutoplacementMax: PropTypes.number,
  removeObject: PropTypes.func.isRequired,
  template: PropTypes.object,
  fixtureTemplateCanvasChanged: PropTypes.func.isRequired,
  fixtureTemplateCanvasNameChanged: PropTypes.func.isRequired,
  savePosition: PropTypes.func.isRequired,
  notifyModal: PropTypes.func,
  createFixtureRule: PropTypes.func.isRequired,
  editFixtureRule: PropTypes.func.isRequired,
  deleteFixtureRule: PropTypes.func.isRequired,
  deleteAllFixtureRules: PropTypes.func.isRequired,
  createPositionRule: PropTypes.func.isRequired,
  editPositionRule: PropTypes.func.isRequired,
  deletePositionRule: PropTypes.func.isRequired,
  deleteAllPositionRules: PropTypes.func.isRequired,
  selectedFixtureOrmed: PropTypes.object,
}

const mapStateToProps = createStructuredSelector({
  canvasObject: (state, ownProps) =>
    makeSelectCanvasObject(ownProps.match.params.tId)(state, ownProps),
  selectedId: makeSelectCanvasSelectedId(),
  positions: (state, ownProps) =>
    makeSelectPositionsByFixtureTemplate(ownProps.match.params.tId)(
      state,
      ownProps
    ),
  prototypes: makeSelectPrototypes(),
  selectedPositionName: makeSelectPositionName(),
  selectedPositionCount: makeSelectPositionCount(),
  selectedAutoplacementMax: makeSelectAutoPlacementMax(),
  isStatic: makeSelectIsStatic(),
  failed: makeSelectFailed(),
  loading: makeSelectLoading(),
  products: makeSelectAllProducts(),
  selectedPosition: makeSelectSelectedPosition(),
  template: (state, ownProps) =>
    makeSelectFixtureTemplate(ownProps.match.params.tId)(state, ownProps),
  selectedFixtureOrmed: makeSelectFixtureOrmed(),
})

const mapDispatchToProps = {
  openModal,
  closeModal,
  loadCanvas,
  fixtureTemplateCanvasNameChanged,
  fixtureTemplateCanvasChanged,
  notifyModal,
  savePosition,
  removeObject,
  createFixtureRule,
  editFixtureRule,
  deleteFixtureRule,
  deleteAllFixtureRules,
  createPositionRule,
  editPositionRule,
  deletePositionRule,
  deleteAllPositionRules,
  templateCanvasPositionCountChanged,
  saveAll,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

const withReducer = injectReducer({ key: 'templatePositions', reducer })
const withSaga = injectSaga({ key: 'templatePositions', saga })

export default compose(withReducer, withSaga, withConnect)(TemplatePositions)
