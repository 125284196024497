import { DeviceConfiguration } from './model'
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

// Composed components
import { Container, Row, Col } from '../Bootstrap'
import DeviceConfigurationParameterFormRow from './DeviceConfigurationParameterFormRow'
import HelpIcon from '../HelpIcon'

// Copy
import { FormattedMessage } from 'react-intl'

// Behaviors
import { closeModal, openModal } from '../../containers/App/actions'

const intlPath = (strings) =>
  `components.deviceConfiguration.form.${strings[0]}`

class DeviceConfigurationForm extends React.Component {
  /**
   * @constructor
   * @param {DeviceConfiguration} deviceConfiguration - The model object to display/edit.
   * @param {object} previousModal - A reference to the modal descriptor (as passed to
   *   the `openModal()` action) to return to after this form has completed.
   */
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleOverrideChange = this.handleOverrideChange.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    const { deviceConfiguration } = props
    this.state = { deviceConfiguration }
  }

  /** Renders the individual form rows for each DeviceConfigurationParameters **/
  rows() {
    const { allowOverride } = this.props
    const { deviceConfiguration: devConf } = this.state
    const onChange =
      devConf.resourceType === DeviceConfiguration.resourceTypes.ORGANIZATION ||
      allowOverride
        ? this.handleChange
        : null
    return devConf.deviceConfigurationParameters.map((dcParam) => (
      <DeviceConfigurationParameterFormRow
        deviceConfigurationParameter={dcParam}
        allowOverride={allowOverride}
        showOverride={this.showOverride()}
        deviceType={devConf.deviceType}
        firmwareVersion={devConf.firmwareVersion}
        key={dcParam.id}
        onChange={onChange}
        onOverrideChange={this.handleOverrideChange}
      />
    ))
  }

  /** Respond to changes reported by the individual row renderers.
   * @param {string} name - The name of the changed parameters.
   * @param {number} value - The (numeric) value to assign to the parameter.
   */
  handleChange(name, value) {
    const { deviceConfiguration: devConf } = this.state
    const newConf = devConf.setParam(name, value)
    this.setState({ deviceConfiguration: newConf })
  }

  handleOverrideChange(name, override) {
    const { deviceConfiguration: devConf } = this.state
    const newConf = devConf.overrideParam(name, override)
    this.setState({ deviceConfiguration: newConf })
  }

  /** Closes the modal and re-opens the previous modal. */
  close() {
    const {
      deviceConfiguration: devConf,
      sendCloseModal,
      sendOpenModal,
      previousModal,
    } = this.props
    sendCloseModal(devConf.editFormId())
    sendOpenModal(previousModal)
  }

  /** When the Save button is pressed, persist the object and
   * return to the previous modal. */
  handleSave() {
    const { deviceConfiguration: devConf } = this.state
    const { update } = this.props
    update(devConf)
    this.close()
  }

  /** When the Cancel button is pressed, return to the previous
   * modal without persisting the object. */
  handleCancel() {
    this.close()
  }

  /** Whether any control has been updated.
   * @returns {boolean}
   */
  isPristine() {
    const { deviceConfiguration: given } = this.props
    const { deviceConfiguration: current } = this.state
    return given === current
  }

  orgMode() {
    const { deviceConfiguration: { resourceType } } = this.state
    return resourceType === DeviceConfiguration.resourceTypes.ORGANIZATION
  }

  showOverride() {
    return this.props.allowOverride && !this.orgMode()
  }

  allowSave() {
    return this.orgMode() || this.props.allowOverride
  }

  actionButtons() {
    if (this.allowSave()) {
      return (
        <React.Fragment>
          <a
            className="btn btn-primary"
            onClick={this.handleCancel}
            role="button"
            tabIndex={0}
          >
            <FormattedMessage id="cancel" />
          </a>
          <div className="dropdown">
            <button
              type="button"
              className="btn btn-primary dropdown-toggle"
              data-toggle="dropdown"
              disabled={this.isPristine()}
            >
              <FormattedMessage id="save" />
            </button>
            <div className="dropdown-menu">
              <p className="text-muted m-3">
                <FormattedMessage id={intlPath`saveWarning`} />
              </p>
              <p className="text-danger m-3">
                <FormattedMessage id={intlPath`saveConfirm`} />
              </p>
              <a
                className="dropdown-item"
                onClick={this.handleSave}
                role="button"
                tabIndex={0}
              >
                <FormattedMessage id={intlPath`saveAndBroadcast`} />
              </a>
            </div>
          </div>
        </React.Fragment>
      )
    }

    return (
      <React.Fragment>
        <small className="text-muted">
          <FormattedMessage id={intlPath`saveDisabled`} />
        </small>
        <a
          className="btn btn-primary"
          onClick={this.handleCancel}
          role="button"
          tabIndex={0}
        >
          <FormattedMessage id="ok" />
        </a>
      </React.Fragment>
    )
  }

  render() {
    const {
      deviceConfiguration: { deviceType, firmwareVersion: fwVersion },
    } = this.state
    return (
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">
            <FormattedMessage id={intlPath`title`} />
          </h4>
          <h5 className="modal-title text-muted">
            <FormattedMessage
              id={intlPath`subtitle`}
              values={{ deviceType, fwVersion }}
            />
          </h5>
        </div>
        <div className="modal-body device-configuration-form">
          <Container>
            <Row>
              <Col span={4}>
                <h4>
                  Parameter
                  {this.showOverride() && (
                    <HelpIcon helpKey="deviceConfigurations.overridesAllowed" />
                  )}
                </h4>
              </Col>
              <Col span={8}>
                <h4>Value</h4>
              </Col>
            </Row>
            {this.rows()}
          </Container>
        </div>
        <div className="modal-footer">{this.actionButtons()}</div>
      </div>
    )
  }
}

DeviceConfigurationForm.propTypes = {
  deviceConfiguration: PropTypes.object.isRequired,
  previousModal: PropTypes.object,
}

const mapStateToProps = (state) => ({
  allowOverride: state.get('organization').get('organization').organizations[0]
    .allowDeviceConfigOverride,
})

const mapDispatchToProps = {
  sendCloseModal: closeModal,
  sendOpenModal: openModal,
  update: DeviceConfiguration.actionMakers.updateDevConf,
}

export default connect(mapStateToProps, mapDispatchToProps)(
  DeviceConfigurationForm
)
