import { Map } from 'immutable'
import { ActionTypes } from './constants'

export const initialState = new Map({
  updateStatus: null,
  error: null,
  loading: false,
})

function reducer(state = initialState, { payload, type }) {
  switch (type) {
    case ActionTypes.UPDATE_LODE_FULFILLED:
      return state
        .set('updateStatus', payload.status)
        .set('loading', false)
        .set('error', null)
    case ActionTypes.UPDATE_LODE_PENDING:
      return state.set('error', null).set('loading', true)
    case ActionTypes.UPDATE_LODE_FAILED:
      return state.set('error', payload.error).set('loading', false)
    case ActionTypes.UPDATE_LODE:
      return state.set('updateStatus', null)
    default:
      return state
  }
}

export default reducer
