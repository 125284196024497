/**
 *
 * InfoLabel
 *
 */

import styled from 'styled-components'

export default styled.span`
  display: inline-block;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.38);
  line-height: 14px;
`
