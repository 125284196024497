import { getEditObject } from './objectEditUtils'
import { editType } from './utilsEditTypes'

export async function getEditPrototypes() {
  return {
    // Positions
    positions: {
      [editType.empty]: await getEditObject(editType.empty, false),
      [editType.unselected]: await getEditObject(editType.unselected, false),
      [editType.unselectedSecurePlug]: await getEditObject(
        editType.unselectedSecurePlug,
        false
      ),
      [editType.unselectedCXFlexFourPort]: await getEditObject(
        editType.unselectedCXFlexFourPort,
        false
      ),
      [editType.unselectedLock]: await getEditObject(
        editType.unselectedLock,
        false
      ),
      [editType.filled]: await getEditObject(editType.filled, false),
      [editType.filledSecurePlug]: await getEditObject(
        editType.filledSecurePlug,
        false
      ),
      [editType.filledLock]: await getEditObject(editType.filledLock, false),
      [editType.selectedLock]: await getEditObject(
        editType.selectedLock,
        false
      ),
      [editType.filledCXFlexFourPort]: await getEditObject(
        editType.filledCXFlexFourPort,
        false
      ),
      [editType.selected]: await getEditObject(editType.selected, false),
    },
  }
}
