import { ActionTypes } from '../constants'

/**
 * Post Position Action Failed
 *
 * @returns {Object}
 */
export function postPositionActionFailed(error) {
  return {
    type: ActionTypes.POST_POSITION_ACTION_FAILED,
    payload: error,
  }
}

/**
 * Post Position Action Fulfilled
 *
 * @returns {Object}
 */
export function postPositionActionFulfilled(data) {
  return {
    type: ActionTypes.POST_POSITION_ACTION_FULFILLED,
    payload: {
      stores: data,
    },
  }
}

/**
 * Post Position Action Pending
 *
 * @returns {Object}
 */
export function postPositionActionPending() {
  return {
    type: ActionTypes.POST_POSITION_ACTION_PENDING,
  }
}

/**
 * Post Position Action
 *
 * @returns {Object}
 */
export function postPositionAction(payload) {
  return {
    type: ActionTypes.POST_POSITION_ACTION,
    payload,
  }
}
