import { ActionTypes } from '../constants'

/**
 * Post Security Device Action Failed
 *
 * @returns {Object}
 */
export function postSecurityDeviceActionFailed(error) {
  return {
    type: ActionTypes.POST_SECURITY_DEVICE_ACTION_FAILED,
    payload: error,
  }
}

/**
 * Post Security Device Action Fulfilled
 *
 * @returns {Object}
 */
export function postSecurityDeviceActionFulfilled(data) {
  return {
    type: ActionTypes.POST_SECURITY_DEVICE_ACTION_FULFILLED,
    payload: {
      stores: data,
    },
  }
}

/**
 * Post Security Device Action Pending
 *
 * @returns {Object}
 */
export function postSecurityDeviceActionPending() {
  return {
    type: ActionTypes.POST_SECURITY_DEVICE_ACTION_PENDING,
  }
}

/**
 * Post Security Device Action
 *
 * @returns {Object}
 */
export function postSecurityDeviceAction(payload) {
  return {
    type: ActionTypes.POST_SECURITY_DEVICE_ACTION,
    payload,
  }
}
