import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import IOTDashboardScreen from './index.screen'
import { selectOrganizationID, selectToken } from '../App/selectors'
import { getIOTStatus } from '../../api'
import { deepEqual } from '../../utils/utils'

const defaultPageSize = 25
const defaultState = {
  data: [],
  pages: 0,
  loading: false,
}

export class IOTDashboard extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = defaultState
  }

  // This is what will be called by the react-table when it's mounted (for the first page), in
  // addition to whenever the filters/sorts/page changes.
  // The react-table keeps track of its own state for the filters and sorting and even what page
  // it's on, so we simply need to pass it the defaultPageSize and the total number of pages.
  _fetch(query) {
    const {
      token,
      orgID,
      fetchIOTStatusData,
    } = this.props

    this.setState({ loading: true })

    getIOTStatus(token, orgID, query).then(response => {
      try {
        const data = response.reports.map(r => {
          const store = response.stores[r.storeID]
          let row = { name: store.name }
          r.devices.forEach(d => {
            let lodeType = null
            switch (d.type) {
            case 'DoorLockController': lodeType = 'doorController'; break
            case 'ConnectHub': lodeType = 'meshController'; break
            case 'Pinpad': lodeType = 'pinpad'; break
            }

            // if a device of this type already exists that is online, ignore any new ones
            if (row[lodeType]?.online) return

            row[lodeType] = {
              online: d.status !== 'offline',
              since: moment(d.since)
                .format('MM/DD/YY hh:mma'),
            }
          })
          return row
        })

        this.setState({ data, pages: response.meta.pages, loading: false })
      } catch (e) {
        console.error(e)
        this.setState(defaultState)
      }
    })
  }

  render() {
    const {
      data,
      pages,
      loading,
    } = this.state

    return (
      <IOTDashboardScreen
        data={data}
        pages={pages}
        loading={loading}
        defaultPageSize={defaultPageSize}
        onFilter={query => this._fetch(query)}
      />
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  orgID: selectOrganizationID(state),
  token: selectToken(state),
})

export default connect(mapStateToProps)(IOTDashboard)
