import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

export const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  height: 64px;
  margin-right: 25px;
  margin-left: 25px;
  border-bottom: 1px solid #d8d8d8;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 10px;
`

export const TextTop = styled.span`
  font-size: 11px;
  text-align: left;
`

export const TextBottom = styled.span`
  font-size: 14px;
  text-align: left;
  padding-top: 2px;
`

function Item({ item, onMouseEnter, onMouseLeave }) {
  const { id, title, subTitle } = item
  return (
    <ItemContainer
      onMouseEnter={() => {
        if (onMouseEnter) onMouseEnter(id)
      }}
      onMouseLeave={() => {
        if (onMouseLeave) onMouseLeave(id)
      }}
    >
      <Column>
        <TextTop>{title}</TextTop>
        <TextBottom>{subTitle}</TextBottom>
      </Column>
    </ItemContainer>
  )
}

Item.propTypes = {
  item: PropTypes.object.isRequired,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
}

export default Item
