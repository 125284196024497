import { createSelector } from 'reselect'

const selectManufacturerProducts = (state) => state.get('manufacturer-products')

const makeSelectCurrentManufacturer = () =>
  createSelector(selectManufacturerProducts, (stores) =>
    stores.get('currentManufacturerId')
  )

const makeSelectManufacturerProducts = () =>
  createSelector(selectManufacturerProducts, (stores) => stores.get('products'))

const makeSelectFailed = () =>
  createSelector(selectManufacturerProducts, (stores) => stores.get('failed'))

const makeSelectLoading = () =>
  createSelector(selectManufacturerProducts, (stores) => stores.get('loading'))

export {
  selectManufacturerProducts,
  makeSelectManufacturerProducts,
  makeSelectFailed,
  makeSelectLoading,
  makeSelectCurrentManufacturer,
}
