import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ListOfLinks from '../../../components/ListOfLinks'
import Notifications from '../Notifications'
import KeysPage from '../../Keys'
import UsersPage from '../../UsersPage'
import { injectIntl } from 'react-intl'

const noPermissionTextKeys =
  "You don't have appropriate rights to access the Keys List. \nPlease contact your store manager."

const noPermissionTextUsers =
  "You don't have appropriate rights to access the Users List. \nPlease contact your store manager."

export const Container = styled.div`
  height: 100%;
`

export const Column = styled.div`
  margin-left: 6px;
  margin-right: 6px;
  width: ${(props) => props.width};
`

export const ColumnsContainer = styled.div`
  height: 100%;
`

const Card = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  overflow: auto;
  width: ${(props) => props.width};
`

const NoPermissionLabel = styled.span`
  margin-top: 20px;
  margin-right: 30px;
  margin-left: 30px;
  font-size: 20px;
`

const StoreManagerScreen = ({
  quickActionsItems,
  storeId,
  onItem,
  height,
  history,
  hasStoreManagerRole,
  hasStoreAssociateRole,
  hasRegionalManagerRole,
  hasViewKeysPermission,
  hasViewUsersPermission,
  intl,
}) => (
  <Container style={{ height: height ? height + 55 : 'auto' }}>
    <ColumnsContainer className="row no-gutters justify-content-md-center">
      <Column className="col-md-auto col">
        <ListOfLinks
          items={quickActionsItems}
          headerTitle={intl.formatMessage({
            id: 'components.navigation.title',
          })}
          onItem={onItem}
          height={height}
          history={history}
        />
      </Column>
      <Column width="350px" className="col-md-auto">
        <div className="card" style={{ height: '100%' }}>
          <div className="card-header text-left font-weight-bold">
            Notifications
          </div>
          <div style={{ height: height || 'auto', overflow: 'auto' }}>
            <Notifications
              storeId={storeId}
              height={height}
              history={history}
            />
          </div>
        </div>
      </Column>
      <Column width="431px" className="col-md-auto">
        <Card width="431px" className="card">
          <div className="card-header text-left font-weight-bold">
            {hasStoreManagerRole ? 'Keys' : 'Users'}
          </div>
          {hasStoreManagerRole &&
            (hasViewKeysPermission ? (
              <KeysPage
                isHomePage
                match={{
                  params: { sId: storeId ? storeId.toString() : storeId },
                }}
                height={height}
                history={history}
              />
            ) : (
              <NoPermissionLabel>{noPermissionTextKeys}</NoPermissionLabel>
            ))}
          {(hasStoreAssociateRole || hasRegionalManagerRole) &&
            (hasViewUsersPermission ? (
              <UsersPage isHomePage height={height} history={history} />
            ) : (
              <NoPermissionLabel>{noPermissionTextUsers}</NoPermissionLabel>
            ))}
        </Card>
      </Column>
    </ColumnsContainer>
  </Container>
)

StoreManagerScreen.propTypes = {
  quickActionsItems: PropTypes.array.isRequired,
  storeId: PropTypes.number,
  hasViewKeysPermission: PropTypes.bool,
  hasViewUsersPermission: PropTypes.bool,
  hasStoreManagerRole: PropTypes.bool,
  hasStoreAssociateRole: PropTypes.bool,
  hasRegionalManagerRole: PropTypes.bool,
  onItem: PropTypes.func,
  height: PropTypes.number,
  history: PropTypes.object,
}

export default injectIntl(StoreManagerScreen)
