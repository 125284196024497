import React from 'react'
import PropTypes from 'prop-types'

export default class FileSelectionButton extends React.PureComponent {
  handleClick = () => this.fileInput.click()

  render() {
    const { onFileSelection, title } = this.props
    return (
      <div className="d-flex justify-content-center">
        <input
          className="d-none"
          ref={(fileInput) => (this.fileInput = fileInput)}
          type="file"
          onChange={onFileSelection}
        />
        <button
          onClick={this.handleClick}
          type="button"
          className="btn btn-primary btn-raised"
        >
          {title}
        </button>
      </div>
    )
  }
}

FileSelectionButton.propTypes = {
  title: PropTypes.string.isRequired,
  onFileSelection: PropTypes.func.isRequired,
}
