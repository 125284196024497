import { createSelector } from 'reselect'
import { createSelector as createOrmSelector } from 'redux-orm'
import { orm } from 'mti-jsclient-shared'

const dbStateSelector = state => state.get('orm')

const selectOrganizationName = state => {
  const orgAPIResponse = state.get('organization').get('organization') || {}
  const org = (orgAPIResponse.organizations || [])[0] || { }
  return org.name || 'My Organization'
}

const getStore = (session, storeId) => {
  try {
    return session.Store.withId(storeId).ref
  } catch (e) {
    return undefined
  }
}

const selectStoreNames = createOrmSelector(
  orm,
  dbStateSelector,
  (state, storeIDs) => storeIDs,
  (session, storeIDs) => {
    const stores = (storeIDs || []).map(id => getStore(session, id)).filter(s => s)
    return stores.map(s => s.name)
  }
)

const selectRegionNames = (state, resourceIDs) => {
  if (!resourceIDs) { return [] }
  const regions = state.get('app').get('regions') || []
  return regions.filter(r => resourceIDs.includes(r.id)).map(r => r.name)
}

export const selectRoleResourceNames = (state, resourceType, resourceIDs) => {
  switch (resourceType) {
    case 'Organization':
      return [selectOrganizationName(state)]
    case 'Region':
      return selectRegionNames(state, resourceIDs)
    case 'Store':
      return selectStoreNames(state, resourceIDs)
    default: return ['Unknown Resource Type']
  }
}
