import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import injectReducer from '../../utils/injectReducer'
import injectSaga from '../../utils/injectSaga'
import { deepEqual } from '../../utils/utils'
import { namespace } from './constants'
import saga from './sagas'
import reducer from './reducer'
import { fetchLayoutTableViewData } from './actions'
import LayoutTableViewScreen from './index.screen'
import {
  selectIsTableViewLoading,
  selectLayoutTableViewData,
  selectAreasFilterList,
  selectFixturesFilterList,
} from './selectors'

const defaultPageSize = 25
export class LayoutTableViewPage extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      filters: { },
    }
  }

  componentDidMount() {
    const {
      storeID,
      fetchLayoutTableViewData,
    } = this.props

    // This will fetch the areas and fixtures list for the filter
    // dropdowns, in addition to setting the isLoading property, and the
    // default table view data.
    fetchLayoutTableViewData(storeID, { per: defaultPageSize }, true)
  }

  // This is what will be called whenever the user clicks to go to the
  // next page (which will be a type of filter on the filters object), or
  // if they change the filters in some way, causing the page to refresh.
  _loadPage(filters) {
    const {
      storeID,
      fetchLayoutTableViewData,
    } = this.props

    if (!deepEqual(this.state.filters, filters)) {
      this.setState({ filters })
      fetchLayoutTableViewData(storeID, filters)
    }
  }

  render() {
    const {
      isTableViewLoading, // This is a boolean representing whether we're currently fetching data
      tableViewData, // The latest API response
      areasFilterList, // The list of areas, already filtered by the store id
      fixturesFilterList, // The list of fixtures, already filtered by the store id
    } = this.props

    return (
      <LayoutTableViewScreen
        loading={isTableViewLoading}
        onFilter={filters => this._loadPage(filters)}
        tableViewData={tableViewData}
        areasFilterList={areasFilterList}
        fixturesFilterList={fixturesFilterList}
        defaultPageSize={defaultPageSize}
      />
    )
  }
}

LayoutTableViewPage.propTypes = {
  storeID: PropTypes.number.isRequired,
}

const mapStateToProps = (state, ownProps) => ({
  storeID: Number(ownProps.match.params.sId),
  isTableViewLoading: selectIsTableViewLoading(state),
  tableViewData: selectLayoutTableViewData(state),
  areasFilterList: selectAreasFilterList(state),
  fixturesFilterList: selectFixturesFilterList(state),
})

const mapDispatchToProps = {
  fetchLayoutTableViewData,
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)
const withReducer = injectReducer({ key: namespace, reducer })
const withSaga = injectSaga({ key: namespace, saga })

export default compose(withReducer, withSaga, withConnect)(LayoutTableViewPage)
