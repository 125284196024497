/**
 *
 * AreaCanvas component
 *
 */

import React from 'react'
import PropTypes from 'prop-types'
import { fabric } from 'fabric'
import { debounce } from 'lodash'
import {
  LongPressMilliseconds,
  LongTapResolver,
  handleSingleCreateEdit,
} from '../../utils/mtiUtils'
import {
  sviFigureType,
  sviFigureSizeType,
  incrementalObjectName,
  defaultName,
} from '../../utils/mtiCanvasUtils'
import Canvas from '../CanvasObject'
import {
  minW,
  minH,
  propertiesToInclude,
  slicingFixture,
  createIcObject,
  updateIcObject,
  updateIcAngle,
  renderLabel,
} from './fixture'
import { getMaxSize } from './sizeUtils'
import { setOverlapMethod } from '../FabricCanvas/utilsOld'
import {
  onScaleNewMeasurer,
  onModifiedMeasurer,
  onScaleMeasurer,
  getInitialCoords,
} from '../FabricCanvas/measurerOld'
import { restoreCanvasOverlapEnabled } from '../../containers/App/localStorageOptions'
import { resizeToScale, applyMaxMinScale } from '../FloorCanvas/utils'

const canvas = new fabric.Canvas()

let isDown

class AreaCanvas extends React.PureComponent {
  /* eslint-disable no-undef, react/sort-comp, no-underscore-dangle */

  initCanvas = (isEditable) => {
    const isCanvasOverlapEnabled = restoreCanvasOverlapEnabled()
    const { onChanged, size } = this.props
    const el = this.canvasContainer.objectsCanvas
    let rect
    let origX
    let origY
    let numObjectsOnCanvas

    const longTapResolver = new LongTapResolver()

    canvas.initialize(el, {
      width: size.width,
      height: size.height,
      selection: false,
      backgroundColor: null,
    })

    if (isEditable) {
      let initialCoords = {}
      canvas.off('mouse:down')
      canvas.on('mouse:down', async (o) => {
        isDown = true
        initialCoords = (o.target || {}).oCoords || getInitialCoords(o)
        const { selectedObject } = this.props
        numObjectsOnCanvas = canvas.getObjects().length

        if (
          handleSingleCreateEdit(
            o,
            selectedObject,
            canvas,
            (msg) => this.onMessage(msg),
            () =>
              onChanged({
                canvasObject: canvas.toObject(propertiesToInclude),
                selectedObject,
                isStatic: false,
              }),
            'Fixture'
          )
        ) {
          isDown = false
          return
        }

        longTapResolver.mouseDown(o.target)
        const pointer = canvas.getPointer(o.e)

        origX = pointer.x
        origY = pointer.y

        const fixtureName = incrementalObjectName(
          this.props.canvasObject.objects,
          defaultName.fixture
        )

        rect = await slicingFixture(
          createIcObject(fixtureName, origX / size.width, origY / size.width),
          pointer,
          origX,
          origY
        )

        canvas.add(rect)
      })

      canvas.onBeforeScaleRotate = function lock(o) {
        o.set({ lockScalingX: false, lockScalingY: false })
      }

      canvas.off('mouse:move')
      canvas.on('mouse:move', (o) => {
        // return
        if (!isDown || !rect) return

        const { x, y } = canvas.getPointer(o.e)
        if (origX > x || origY > y) return

        onScaleNewMeasurer(
          { ...o, target: rect },
          canvas,
          size.width,
          size.height,
          initialCoords,
          true
        )

        if (origX > x) {
          rect.set({ left: Math.abs(x) })
        }

        if (origY > y) {
          rect.set({ top: Math.abs(y) })
        }

        const width = Math.abs(origX - x)
        const height = Math.abs(origY - y)

        const { width: fixedWidth, height: fixedHeight } = getMaxSize(
          width,
          height
        )

        const clone = fabric.util.object.clone(rect) // get cloned object
        clone.set({
          originX: 'left',
          originY: 'top',
          width: fixedWidth,
          height: fixedHeight,
        })

        const isInterXHandled = false
        const isInterYHandled = false
        const newWidth = isInterXHandled ? clone.width : fixedWidth
        const newHeight = isInterYHandled ? clone.height : fixedHeight
        const newLeft = isInterXHandled ? clone.left : rect.left
        const newTop = isInterYHandled ? clone.top : rect.top
        rect.set({
          originX: 'left',
          originY: 'top',
          left: newLeft,
          top: newTop,
          width: newWidth,
          height: newHeight,
        })
        rect.item(0).set({
          scaleX: newWidth / rect.item(0).width,
          scaleY: newHeight / rect.item(0).height,
        })
        renderLabel(rect, !isEditable)

        canvas.renderAll()
      })

      canvas.off('object:scaling')
      canvas.on('object:scaling', (o) => {
        const group = o.target
        group.objectCaching = false
        if (isCanvasOverlapEnabled) {
          onScaleMeasurer(
            o,
            canvas,
            size.width,
            size.height,
            initialCoords,
            true
          )
        }

        const actualW = group.scaleX * group.width
        const actualH = group.scaleY * group.height
        group.set({
          width: actualW,
          height: actualH,
          scaleX: 1,
          scaleY: 1,
        })
        const bgn = o.target.item(0)
        bgn.set({
          scaleX: actualW / bgn.width,
          scaleY: actualH / bgn.height,
        })

        const { width: maxW, height: maxH } = getMaxSize(actualW, actualH)

        const label = o.target.item(1)
        const text = o.target.item(2)

        applyMaxMinScale(group, actualW, actualH, maxW, maxH, minW, minH)
        resizeToScale(group, [label, text])
        renderLabel(group, !isEditable)

        group.set({
          sviType: sviFigureType.custom,
          sviSizeType: sviFigureSizeType.custom,
          __sviTypeOrigin: group.__sviTypeOrigin || group.sviType,
        })
        group.layoutPosition.type = sviFigureType.custom
      })

      canvas.off('object:rotating')
      canvas.on('object:rotating', (options) => {
        const step = 5
        const angle = Math.round(options.target.angle / step) * step
        const angleFixed = angle === 360 ? 0 : angle
        options.target.angle = angleFixed
        updateIcAngle(options.target)
      })
    } else {
      // Consider this code if something is going wrong with EDIT <-> VIEW switch
      canvas.off('mouse:down')
      canvas.off('mouse:move')
      canvas.off('object:scaling')
      canvas.off('object:moving')

      canvas.off('mouse:down')
      canvas.on('mouse:down', (e) => {
        longTapResolver.mouseDown(e.target)

        setTimeout(() => {
          if (!longTapResolver.isLongTap(e.target)) {
            return
          }
          this.onOpenFixture(e.target)
        }, LongPressMilliseconds)
      })

      /**
       * uncomment to use click and drag functionality to create new fixture
       * skip for mobile
       */
      // if (!isMobile) {
      //   canvas.off('mouse:down')
      //   canvas.on('mouse:down', async (o) => {
      //     if (o && o.target) return
      //     isDown = true
      //     const pointer = canvas.getPointer(o.e)
      //     origX = pointer.x
      //     origY = pointer.y
      //     rect = fixtureRect({
      //       name: defaultName.fixture,
      //       deviceCount: '0',
      //       fixtureIssuesCount: '0',
      //       type: null,
      //       isEdit: true,
      //       isMobile: false,
      //       origX,
      //       origY,
      //       sviType: sviFigureType.custom,
      //       prototypes,
      //     })
      //     rect.set({ scaleX: 0, scaleY: 0 })
      //     canvas.add(rect)
      //   })
      //   canvas.off('object:moving')
      //   canvas.on('object:moving', (o) => {
      //     const { target } = o
      //     originScaleTop = target.top
      //     originScaleLeft = target.left
      //   })
      //   canvas.off('mouse:move')
      //   canvas.on('mouse:move', (o) => {
      //     // return
      //     if (!(isDown && rect)) return
      //     const { x, y } = canvas.getPointer(o.e)
      //     if (origX > x) {
      //       rect.set({ left: Math.abs(x) })
      //     }
      //     if (origY > y) {
      //       rect.set({ top: Math.abs(y) })
      //     }
      //     const width = Math.abs(origX - x)
      //     const height = Math.abs(origY - y)
      //     rect.set({
      //       originX: 'left',
      //       originY: 'top',
      //       scaleX: width / originFixtureRectWidth,
      //       scaleY: height / originFixtureRectHeight,
      //     })
      //     canvas.renderAll()
      //   })
      // }
    }

    canvas.off('mouse:up')
    canvas.on('mouse:up', (e) => {
      onModifiedMeasurer(canvas) // remove rulers from canvas
      longTapResolver.mouseUp()
      isDown = false
      let isStaticLocal = !isEditable
      let selectedObject = canvas.getObjects().indexOf(canvas.getActiveObject())
      // Remove zero-sized objects (when user accidentally clicked on canvas)
      if (rect && !(rect.scaleX > 0.05 && rect.scaleY > 0.05)) {
        canvas.remove(rect)
        rect = undefined
      } else if (rect) {
        isStaticLocal = false
        canvas.setActiveObject([...canvas.getObjects()].pop(), e)
        // Convert origins from {left, top} to {center, center}
        rect.set({
          top: rect.scaleY * rect.height / 2 + rect.top,
          left: rect.scaleX * rect.width / 2 + rect.left,
          originX: 'center',
          originY: 'center',
        })
        updateIcObject(rect, size)

        rect = undefined
        canvas.renderAll()

        if (numObjectsOnCanvas === canvas.getObjects().length) {
          selectedObject = -1 // prevent existing object selecting if a new object was not created
        } else {
          selectedObject = canvas.getObjects().indexOf(canvas.getActiveObject())
        }
      }

      onChanged({
        canvasObject: canvas.toObject(propertiesToInclude),
        selectedObject: selectedObject,
        isStatic: isStaticLocal,
      })
    })

    canvas.off('mouse:dblclick')
    canvas.on('mouse:dblclick', (e) => {
      this.onOpenFixture(e.target)
    })

    if (isCanvasOverlapEnabled) {
      setOverlapMethod('measurer', canvas, size.width, size.height)
    }
    this.canvasContainer.loadAndRender()
  }

  constructor(props) {
    super(props)
    this.onOpenFixture = debounce(this.onOpenFixture, 100)
  }

  componentDidUpdate(prevProps) {
    const { isEditable } = this.props
    if (prevProps.isEditable !== isEditable) {
      this.initCanvas(isEditable)
    }
  }

  componentDidMount() {
    const { isEditable } = this.props
    this.initCanvas(isEditable)
  }

  onMessage(msg) {
    const { onMessage } = this.props
    if (onMessage) onMessage(msg)
  }

  onOpenFixture(obj) {
    const { onOpenFixture } = this.props
    if (onOpenFixture) onOpenFixture(obj)
  }

  isInteractive() {
    return isDown
  }

  render() {
    const { canvasObject, selectedObject } = this.props

    return (
      <Canvas
        ref={(ref) => {
          this.canvasContainer = ref
        }}
        canvasObject={canvasObject}
        selectedObject={selectedObject}
        fabricInstance={canvas}
      />
    )
  }
}

AreaCanvas.propTypes = {
  selectedObject: PropTypes.number,
  canvasObject: PropTypes.object.isRequired,
  size: PropTypes.object.isRequired,
  isEditable: PropTypes.bool.isRequired,
  onChanged: PropTypes.func.isRequired,
  prototypes: PropTypes.object,
  onOpenFixture: PropTypes.func,
  onMessage: PropTypes.func,
}

export default AreaCanvas
