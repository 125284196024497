import React from 'react'
import PropTypes from 'prop-types'
import ReactTable from 'react-table'
import styled from 'styled-components'
import {
  CellContainer,
  renderFilterInput,
  renderHeaderCell,
} from '../UsersPage/index.screen'
import { getStringTags, NameTitle } from './index.screen'
import { fullTextFilter } from '../../utils/utils'
import TooltipCell from '../../components/TooltipCell'
import TablePreloader from '../../components/Preloaders/TablePreloader'
import ProgressIndicator from '../../components/ProgressIndicator'

export const TagsContainer = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

/* eslint-disable react/prop-types, no-underscore-dangle */
const StoresPageScreen = ({ stores, onStoreSelection, height, loading }) => {
  const columns = [
    {
      filterable: true,
      Header: renderHeaderCell('Name'),
      accessor: 'name',
      Cell: ({ original: { name } }) => (
        <TooltipCell
          text={name}
          render={(tooltip) => (
            <CellContainer ref={(ref) => tooltip.target(ref)}>
              <NameTitle>{name}</NameTitle>
            </CellContainer>
          )}
        />
      ),
      Filter: ({ filter, onChange }) =>
        renderFilterInput({
          placeholder: 'Filter',
          filter,
          onChange,
          id: 'name',
        }),
    },
    {
      id: 'regionName',
      filterable: true,
      filterMethod: (filter, row) =>
        (row[filter.id] || '')
          .toLowerCase()
          .includes(filter.value.toLowerCase()),
      Header: renderHeaderCell('Region'),
      accessor: ({ regionName }) => regionName,
      Cell: ({ original: { regionName } }) => (
        <TooltipCell
          text={regionName || ''}
          render={(tooltip) => (
            <CellContainer ref={(ref) => tooltip.target(ref)}>
              {regionName || ''}
            </CellContainer>
          )}
        />
      ),
      Filter: ({ filter, onChange }) =>
        renderFilterInput({
          placeholder: 'Filter',
          filter,
          onChange,
          id: 'regionName',
        }),
    },
    {
      filterable: true,
      Header: renderHeaderCell('State'),
      accessor: 'address.state',
      Cell: ({ original: { address } }) => (
        <CellContainer>{address.state}</CellContainer>
      ),
      Filter: ({ filter, onChange }) =>
        renderFilterInput({
          placeholder: 'Filter',
          filter,
          onChange,
          id: 'state',
        }),
    },
    {
      filterable: true,
      Header: renderHeaderCell('City'),
      accessor: 'address.city',
      Cell: ({ original: { address } }) => (
        <CellContainer>{address.city}</CellContainer>
      ),
      Filter: ({ filter, onChange }) =>
        renderFilterInput({
          placeholder: 'Filter',
          filter,
          onChange,
          id: 'city',
        }),
    },
    {
      Header: renderHeaderCell('Tags'),
      Cell: ({ original: { tags } }) => (
        <TooltipCell
          text={getStringTags(tags)}
          render={(tooltip) => (
            <TagsContainer ref={(ref) => tooltip.target(ref)}>
              {tooltip.text()}
            </TagsContainer>
          )}
        />
      ),
      id: 'tags',
      sortable: true,
      filterable: true,
      accessor: ({ tags }) => getStringTags(tags),
      Filter: ({ filter, onChange }) =>
        renderFilterInput({
          placeholder: 'Filter',
          filter,
          onChange,
          id: 'tags',
        }),
    },
  ]

  return (
    <div>
      {loading ? (
        <div
          className="full-screen"
          style={{ height: height || 'auto', width: 550 }}
        >
          <TablePreloader
            columnsCount={5}
            loaderWidth={550}
            heightLoader={height}
          />
          <ProgressIndicator text={'Loading...'} />
        </div>
      ) : (
        <ReactTable
          className="full-screen"
          style={{ height: height || 'auto' }}
          data={stores}
          columns={columns}
          defaultFilterMethod={fullTextFilter}
          getTdProps={(state, rowInfo) => ({
            onClick: (e, handleOriginal) => {
              onStoreSelection(rowInfo.original)

              if (handleOriginal) {
                handleOriginal()
              }
            },
          })}
        />
      )}
    </div>
  )
}

StoresPageScreen.propTypes = {
  stores: PropTypes.array.isRequired,
  onStoreSelection: PropTypes.func.isRequired,
  height: PropTypes.number,
}

export default StoresPageScreen
