/**
 * Organizations Sagas
 */
import { call, put, select } from 'redux-saga/effects'
import { getOrganizations } from 'api'
import { tokenSelector } from '../../../App/selectors'
import {
  fetchOrganizationsPending,
  fetchOrganizationsFulfilled,
  fetchOrganizationsFailed,
} from './actions'
import { organizationsSelector, loadingSelector } from './selectors'
import { errorToast } from '../../../../utils/utils'

export function* loadOrganizationsData() {
  const token = yield select(tokenSelector)
  const { organizations } = yield call(getOrganizations, token)
  return organizations
}

export function* loadOrganizations() {
  const token = yield select(tokenSelector)
  if (!token) {
    console.log('No token. Declining to fetch organizations.')
    return
  }

  const isFetchOrganizationsPending = yield select(loadingSelector)
  if (isFetchOrganizationsPending) return
  const organizationsLoaded = yield select(organizationsSelector)
  const areOrganizationsLoaded =
    organizationsLoaded && organizationsLoaded.length > 0
  try {
    if (!areOrganizationsLoaded) {
      yield put(fetchOrganizationsPending())
    }

    const data = yield call(loadOrganizationsData)
    yield put(fetchOrganizationsFulfilled(data))
  } catch (error) {
    console.error(error)
    yield put(fetchOrganizationsFailed(error))
    errorToast('Load Organizations failed')
  }
}
