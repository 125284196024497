import React from 'react'
import PropTypes from 'prop-types'
import CountryRegion from './index.screen'

class CountryRegionContainer extends React.PureComponent {
  constructor(props) {
    super(props)
    const country = props.country || 'United States'
    const region = props.region || 'Alabama'
    this.state = { country, region, validation: {} }

    if (!props.country) {
      this.props.selectCountry(country)
    }

    if (!props.region) {
      this.props.selectRegion(region)
    }
  }

  onSelectCountry = (val) => {
    this.setState({ country: val, validation: {} }, () => {
      this.props.selectCountry(val)
    })
  }

  onSelectRegion = (val) => {
    this.setState({ region: val, validation: {} }, () => {
      this.props.selectRegion(val)
    })
  }

  setCustomValidity(msg, name) {
    if (msg === '') {
      // Dismiss or keep as is
    } else {
      if (name === 'address.state') {
        // `RegionDropdown` component uses method `shouldComponentUpdate` where it updates when value changes only
        // need to set `region` value to empty string to update css class
        this.setState((state) => ({
          validation: { ...state.validation, ...{ [name]: msg } },
          region: '',
        }))
      } else if (name === 'address.country') {
        this.setState((state) => ({
          validation: { ...state.validation, ...{ [name]: msg } },
        }))
      } else {
        console.warn('validation server message:', msg)
      }
    }
  }

  isCustom() {
    return true
  }

  render() {
    const { country, region, validation } = this.state
    return (
      <CountryRegion
        country={country}
        region={region}
        validation={validation}
        selectCountry={this.onSelectCountry}
        selectRegion={this.onSelectRegion}
        container={this.props.container}
      />
    )
  }
}

CountryRegionContainer.propTypes = {
  country: PropTypes.string,
  region: PropTypes.string,
  selectCountry: PropTypes.func.isRequired,
  selectRegion: PropTypes.func.isRequired,
  container: PropTypes.object,
}

export default CountryRegionContainer
