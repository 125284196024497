/* eslint-disable */
import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'

import { closeModal } from '../App/actions'
import styles from '../../styles/App.css'

class MyPortal extends React.PureComponent {
  constructor(props) {
    super(props)
    this.el = document.createElement('div')
  }

  componentDidMount() {
    document.body.appendChild(this.el)
  }

  componentWillUnmount() {
    document.body.removeChild(this.el)
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.el)
  }
}

class Modal extends Component {
  onClose() {
    if (this.props.item.onClose) {
      this.props.item.onClose()
      this.props.onClose(this.props.item)
    } else {
      this.props.onClose(this.props.item)
    }
  }
  render() {
    const { type } = this.props.item
    if (type === 'confirmation') {
      const { text } = this.props.item
      return (
        <div className={styles.modalWrapper}>
          <div className={styles.modal}>
            <div className={styles.textModal}>{text}</div>
            <div className={styles.buttons}>
              <button
                className={styles.modalButton}
                onClick={() => this.onConfirm()}
              >
                Confirm
              </button>
              <button
                className={styles.modalButton}
                onClick={() => this.onClose()}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )
    } else if (type === 'custom') {
      const { content } = this.props.item
      return (
        <div className={styles.modalWrapper}>
          <div className={styles.modal}>
            <button className={styles.close} onClick={() => this.onClose()}>
              &times;
            </button>
            {content}
          </div>
        </div>
      )
    } else if (type === 'material') {
      const { content, confirmText } = this.props.item
      return (
        <div className={styles.modalWrapper}>
          <div className={styles.modalMaterial}>
            {content}
            <div className={styles.buttonsMaterial}>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => this.onClose()}
              >
                CANCEL
              </button>
              <button
                type="button"
                className="btn btn-raised btn-success"
                onClick={() => this.onConfirm()}
              >
                {confirmText}
              </button>
            </div>
          </div>
        </div>
      )
    } else if (type === 'bootstrap') {
      const {
        id,
        content,
        appearance,
        content: { props: { onClose } = {} } = {},
      } = this.props.item
      return (
        <div
          ref={(ref) => {
            $(ref).modal('show')
            $(ref).on('hidden.bs.modal', () => {
              onClose && onClose()
              this.onClose()
            })
          }}
          className="modal fade"
          id={`modal-${id}`}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLongTitle"
          aria-hidden="true"
        >
          <div
            className={`modal-dialog modal-confirmation ${appearance ||
              'modal-lg'} modal-dialog-centered`}
            role="document"
          >
            {content}
          </div>
        </div>
      )
    }
    return <div />
  }
}

class Modals extends Component {
  render() {
    const modals =
      this.props.modals &&
      this.props.modals.map((item, i) => (
        <MyPortal key={i}>
          <Modal
            item={item}
            onClose={(item) => this.props.dispatch(closeModal(item))}
          />
        </MyPortal>
      ))
    return <div className={styles.modals}>{modals}</div>
  }
}

const ModalContainer = connect(
  (state) => ({
    modals: state.get('modal').get('modals'),
  }),
  (dispatch) => ({
    dispatch,
  })
)(Modals)

export default ModalContainer
