import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import ReactTable from 'react-table'
import { Link } from 'react-router-dom'
import styles from '../../styles/App.css'
import {
  CellContainer,
  renderFilterInput,
  renderHeaderCell,
} from './index.screen'

/* eslint-disable react/prop-types, no-underscore-dangle */
function UsersPageScreen({ users, onUser, height }) {
  const columns = [
    {
      filterable: true,
      Header: renderHeaderCell('Name'),
      accessor: 'name',
      Cell: ({ original }) => (
        <CellContainer>
          <Link
            className={styles.link}
            to={'#'}
            onClick={() => onUser(original)}
          >
            {original.name}
          </Link>
        </CellContainer>
      ),
      Filter: ({ filter, onChange }) =>
        renderFilterInput({
          placeholder: 'Filter',
          filter,
          onChange,
          id: 'name',
        }),
    },
    {
      Header: renderHeaderCell('Last signed in at', true),
      accessor: 'lastSignedInAt',
      Cell: ({ original: { lastSignedInAt } }) => (
        <span>
          {lastSignedInAt &&
            moment(lastSignedInAt)
              .utcOffset(0)
              .format('MM/DD/YYYY H:mm')}
        </span>
      ),
    },
    {
      filterable: true,
      Header: renderHeaderCell('Role'),
      accessor: 'rolesTitle',
      Filter: ({ filter, onChange }) =>
        renderFilterInput({
          placeholder: 'Filter',
          filter,
          onChange,
          id: 'role',
        }),
    },
  ]

  return (
    <div>
      <ReactTable
        className="full-screen"
        style={{ height: height || 'auto' }}
        data={users || []}
        columns={columns}
      />
    </div>
  )
}

UsersPageScreen.propTypes = {
  users: PropTypes.array.isRequired,
  onUser: PropTypes.func.isRequired,
  height: PropTypes.number,
}

export default UsersPageScreen
