import React from 'react'
import PropTypes from 'prop-types'
import PortRulesScreen from './PortRules.screen'
import { PortRulesSourceAttributesModel } from './ruleConstants'

class PortRules extends React.PureComponent {
  constructor(props) {
    super(props)
    const { portRules, numberOfPorts, products } = props

    let mappedPortRules = this.mapPortRules(portRules)
    let ruleTypes = Array(numberOfPorts).fill(null)
    let conditions = Array(numberOfPorts).fill(null)
    let selectedProducts = Array(numberOfPorts).fill([])

    for (let i = 0; i < mappedPortRules.length; i++) {
      let rule = mappedPortRules[i]
      if (rule) {
        ruleTypes[i] = rule.sourceAttribute
        conditions[i] = rule.condition

        let productIds = rule.parameter[i + 1]
        if (!Array.isArray(productIds)) {
          productIds = [productIds]
        }
        selectedProducts[i] = productIds.map((productId) =>
          products.find(({ id }) => id === productId)
        )
      }

      this.state = {
        mappedPortRules,
        ruleTypes,
        conditions,
        selectedProducts,
      }
    }
  }

  onConfirm = (port, editing = false) => {
    const { onConfirm, onEdit } = this.props
    const {
      ruleTypes,
      conditions,
      selectedProducts,
      mappedPortRules,
    } = this.state

    const data = this.getComplianceData(selectedProducts[port])
    const condition = conditions[port]
    const ruleType = ruleTypes[port]
    const { paramType } = PortRulesSourceAttributesModel[ruleType]

    let rule
    if (editing) {
      rule = {
        ...mappedPortRules[port],
        condition,
        sourceAttribute: ruleType,
        parameterType: paramType,
        parameter: { [port + 1]: data },
      }
      onEdit(rule)
    } else {
      rule = {
        condition,
        sourceAttribute: ruleType,
        parameterType: paramType,
        parameter: { [port + 1]: data },
      }
      onConfirm(rule)
    }
  }

  getComplianceData = (selectedProducts) => selectedProducts.map(({ id }) => id)

  onDelete = (port) => {
    const { onDelete } = this.props
    const { mappedPortRules } = this.state

    const portRule = mappedPortRules[port]

    onDelete(portRule)
  }

  onChangeRuleType = (data) => {
    const { ruleTypes } = this.state

    let ruleTypesTemp = [...ruleTypes]
    let port = data.port

    ruleTypesTemp[port] = data.ruleType

    this.setState({ ruleTypes: ruleTypesTemp })
  }

  onChangeCondition = (data) => {
    const { conditions } = this.state

    let conditionsTemp = [...conditions]
    let port = data.port

    conditionsTemp[port] = data.condition

    this.setState({ conditions: conditionsTemp })
  }

  onAddSelectedProducts = (data) => {
    const { selectedProducts } = this.state

    let selectedProductsTemp = [...selectedProducts]
    let port = data.port

    selectedProductsTemp[port] = selectedProductsTemp[port].concat(
      data.selectedProducts
    )

    this.setState({
      selectedProducts: selectedProductsTemp,
    })
  }

  onRemoveSelectedProducts = (data) => {
    const { selectedProducts } = this.state

    let selectedProductsTemp = [...selectedProducts]
    let port = data.port

    selectedProductsTemp[port] = data.selectedProducts

    this.setState({
      selectedProducts: selectedProductsTemp,
    })
  }

  mapPortRules = (rules) => {
    const { numberOfPorts } = this.props

    let mappedRules = Array(numberOfPorts)

    for (let i = 0; i < numberOfPorts; i++) {
      if (rules[i]) {
        let portNumberRule = Object.keys(rules[i].parameter)[0]
        mappedRules[portNumberRule - 1] = rules[i]
      }
    }
    return mappedRules
  }

  render() {
    const { id, onClose, numberOfPorts, products } = this.props
    const {
      mappedPortRules,
      conditions,
      ruleTypes,
      selectedProducts,
    } = this.state

    return (
      <PortRulesScreen
        id={id}
        onConfirm={this.onConfirm}
        onClose={onClose}
        onDelete={this.onDelete}
        numberOfPorts={numberOfPorts}
        products={products}
        onChange={this.onChange}
        onAddSelectedProducts={this.onAddSelectedProducts}
        onRemoveSelectedProducts={this.onRemoveSelectedProducts}
        ruleTypes={ruleTypes}
        rules={mappedPortRules}
        conditions={conditions}
        selectedProducts={selectedProducts}
        onChangeRuleType={this.onChangeRuleType}
        onChangeCondition={this.onChangeCondition}
      />
    )
  }
}

PortRules.propTypes = {
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  numberOfPorts: PropTypes.number.isRequired,
  products: PropTypes.array.isRequired,
  portRules: PropTypes.array.isRequired,
}

export default PortRules
