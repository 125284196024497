import { fromJS } from 'immutable'
import { ActionTypes } from './actions'

const initialState = fromJS({
  Loading: false,
  Data: null,
})

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case ActionTypes.Request:
      return state.set('Loading', true)
    case ActionTypes.Cache:
      return state.set('Loading', false).set('Data', payload)
    default:
      return state
  }
}
