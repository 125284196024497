import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { ModalBody } from '../../StoresPage/CreateEditStoreModal/index.screen'

export const ButtonsContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`

export const WarningContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  margin: 20px 0px;
`

const RebootWarning = ({ id, onCancel, onReboot }) => (
  <div className="modal-content">
    <div className="modal-header border-bottom">
      <ButtonsContainer>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            $(`#modal-${id}`).modal('hide') // eslint-disable-line
            onCancel()
          }}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-primary btn-raised"
          onClick={() => {
            $(`#modal-${id}`).modal('hide') // eslint-disable-line
            onReboot()
          }}
        >
          Yes, I want to Reboot
        </button>
      </ButtonsContainer>
    </div>
    <div>
      <ModalBody className="modal-body">
        <WarningContainer>
          WARNING: This action will reboot the hub
        </WarningContainer>
      </ModalBody>
    </div>
  </div>
)

RebootWarning.propTypes = {
  id: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onReboot: PropTypes.func.isRequired,
}

export default RebootWarning
