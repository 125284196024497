export const storeCallWithoutIncludeKey = '__storeCallWithoutInclude'
export const initialGeometryCall = '__initialGeometryCall'
export const canvasOverlapEnabled = '__canvasOverlapEnabled'

/**
 * Restore store call with include
 * @returns {boolean}
 */
export function restoreStoreCallWithInclude() {
  try {
    const withInclude = !JSON.parse(
      localStorage.getItem(storeCallWithoutIncludeKey)
    )
    return withInclude
  } catch (e) {
    return true
  }
}

/**
 * Save store call with include
 * @param isStoreCallWithInclude
 * @returns {boolean}
 */
export function saveStoreCallWithInclude(isStoreCallWithInclude) {
  try {
    const isStoreCallWithoutIncludeStr = JSON.stringify(!isStoreCallWithInclude)
    localStorage.setItem(
      storeCallWithoutIncludeKey,
      isStoreCallWithoutIncludeStr
    )
    return true
  } catch (e) {
    return false
  }
}

/**
 * Restore initial geometry call of store details
 * @returns {boolean}
 */
export function restoreInitialGeometryCall() {
  try {
    const isInitialGeometryCall = JSON.parse(
      localStorage.getItem(initialGeometryCall)
    )
    return isInitialGeometryCall || false
  } catch (e) {
    return false
  }
}

/**
 * Save initial geometry call of store details
 * @param isStoreCallWithInclude
 * @returns {boolean}
 */
export function saveInitialGeometryCall(isInitialGeometryCall) {
  try {
    const isInitialGeometryCallStr = JSON.stringify(isInitialGeometryCall)
    localStorage.setItem(initialGeometryCall, isInitialGeometryCallStr)
    return true
  } catch (e) {
    return false
  }
}

/**
 * Restore Canvas Overlap Enabled
 * @returns {boolean}
 */
export function restoreCanvasOverlapEnabled() {
  try {
    const isCanvasOverlapEnabled = JSON.parse(
      localStorage.getItem(canvasOverlapEnabled)
    )
    return isCanvasOverlapEnabled === false ? false : true
  } catch (e) {
    return true
  }
}

/**
 * Save Canvas Overlap Enabled
 * @param isCanvasOverlapEnabled
 * @returns {boolean}
 */
export function saveCanvasOverlapEnabled(isCanvasOverlapEnabled) {
  try {
    const isCanvasOverlapEnabledStr = JSON.stringify(isCanvasOverlapEnabled)
    localStorage.setItem(canvasOverlapEnabled, isCanvasOverlapEnabledStr)
    return true
  } catch (e) {
    return false
  }
}
