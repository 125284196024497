import React from 'react'

export class CustomInput extends React.PureComponent {
  render() {
    return (
      <input
        type="text"
        readOnly
        onClick={this.props.onClick}
        value={this.props.value}
      />
    )
  }
}
