import { Map } from 'immutable'
import { ActionTypes } from './constants'

export const initialState = new Map({
  failed: null,
  loading: false,
  email: null,
})

export default function reducer(state = initialState, { payload, type }) {
  switch (type) {
    case ActionTypes.POST_FORGOT_PASSWORD_FULFILLED:
      return state.set('failed', null).set('loading', false)
    case ActionTypes.POST_FORGOT_PASSWORD_PENDING:
      return state.set('failed', null).set('loading', true)
    case ActionTypes.POST_FORGOT_PASSWORD_FAILED:
      return state.set('failed', payload.error).set('loading', false)
    case ActionTypes.EDIT_EMAIL:
      return state.set('email', payload.email)
    case ActionTypes.CLEAR_EMAIL:
      return state.set('email', null)
    default:
      return state
  }
}
