import { ActionTypes } from './constants'

/**
 * Post Forgot Password Failed
 *
 * @returns {Object}
 */
export function postForgotPasswordFailed(error) {
  return {
    type: ActionTypes.POST_FORGOT_PASSWORD_FAILED,
    payload: { error },
  }
}

/**
 * Post Forgot Password Fulfilled
 *
 * @returns {Object}
 */
export function postForgotPasswordFulfilled() {
  return {
    type: ActionTypes.POST_FORGOT_PASSWORD_FULFILLED,
  }
}

/**
 * Post Forgot Password Pending
 *
 * @returns {Object}
 */
export function postForgotPasswordPending() {
  return {
    type: ActionTypes.POST_FORGOT_PASSWORD_PENDING,
  }
}

/**
 * Edit Email
 *
 * @returns {Object}
 */
export function editEmail(email) {
  return {
    type: ActionTypes.EDIT_EMAIL,
    payload: { email },
  }
}

/**
/**
 * Clear Email
 *
 * @returns {Object}
 */
export function clearEmail() {
  return {
    type: ActionTypes.CLEAR_EMAIL,
  }
}

/**
 * Post Forgot Password
 *
 * @returns {Object}
 */
export function postForgotPassword(history) {
  return {
    type: ActionTypes.POST_FORGOT_PASSWORD,
    payload: { history },
  }
}
